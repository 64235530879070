import useAxios from "axios-hooks";
import { DefaultButton } from "../../Button";
import { useLayoutEffect } from "react";
import { showToast } from "../../ReactToast";
import QRCode from "react-qr-code";
const RightSection = ({ setShowCancelCheckout, id, is_seller }) => {
  const [{ data, response }] = useAxios(
    {
      url: `/orders/${id}/barcode/`,
      method: "post",
    },
    {
      manual: is_seller,
    }
  );
  const [{ ...haveArrivedData }, haveArrived] = useAxios(
    {
      url: `/orders/${id}/have_arrived/`,
      method: "post",
    },
    { manual: true }
  );

  useLayoutEffect(() => {
    if (response?.response?.status === 400) {
      showToast("error", response.response.data.detail);
    }
  }, [data, response]);

  return (
    <div className="c-box-right">
      {data && (
        <>
          <h2>Scan the product when you arrive</h2>
          <div className="image">
            <div
              style={{
                height: "auto",
                margin: "0 auto",
                maxWidth: 84,
                width: "100%",
              }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={data.barcode}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
          <div className="code">
            <h2>Scan code or enter Key</h2>
            <p>{data.barcode}</p>
          </div>
        </>
      )}

      <div className="arrived-btn">
        <DefaultButton
          customStyle={{
            fontSize: 12,
            backgroundColor: "#9B9B9B79",
            maxWidth: 250,
            margin: "auto",
          }}
          onClick={async () => {
            const arrived = await haveArrived();
            if (arrived.status === 200)
              return showToast(
                "success",
                `${
                  is_seller ? "Buyer" : "Seller"
                } will be notified of your arrival!`
              );
            // console.log(arrived.response);
            showToast(
              "error",
              arrived.response.data.order || "An error occurred!"
            );
          }}>
          I have arrived
        </DefaultButton>
        <div
          className="c-right-cancel"
          onClick={setShowCancelCheckout.bind(this, true)}>
          cancel transaction
        </div>
      </div>
    </div>
  );
};

export default RightSection;
