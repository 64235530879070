import './index.scss'
import profileImg from "../../../../../../assets/img/Ellipse 357.png";
import { Rating, RatingView } from "react-simple-star-rating";
import moment from "moment";

const ReplyItem = ({reviewItem}) => {
    return(
        <div className="reply_item">
            <div className="reviews-card-body">
                <div className="rcb-heading">
                    <div className='img_holder'>
                        <img src={reviewItem.owner.profile_image.file ? reviewItem.owner.profile_image.file : profileImg} alt="" />
                    </div>
                    {/* <div className="rcb-star">
                        <Rating
                        allowHover={false}
                        ratingValue={reviewItem.rating}
                        size={20}
                        readonly
                        label
                        fillColor="orange"
                        emptyColor="gray"
                        className="rating-star"
                        />
                    </div> */}
                    <h4>{reviewItem.owner.full_name}</h4>
                    <h6>{moment(reviewItem.created_at).format('DD MMMM YYYY')}</h6>
                </div>
                <div className="rcb-comments">
                    <p>
                        {" "}
                        {reviewItem.body}
                    </p>
                    
                </div>
            </div>
        </div>
    )
}

export default ReplyItem