import React, { useState, useEffect } from "react";
import { Link  } from 'react-router-dom'
import { useDispatch } from "react-redux";
import "./index.scss";
import {
  FaSearch,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import useCarts from "../../../../hooks/carts"

const SummaryContent = () => {

  const {
    cartSummaryData,
    handleCartSummary,
  } = useCarts();

  useEffect(() => {
    handleCartSummary();
    console.log(cartSummaryData?.data);
  }, []);

  return (
    <div className="summary-container">
        <div className="order-header">
          <p className="order-text">Order Summary </p>
          <div className="proceed">
            <Link to="/cart-payment">
                <p>Proceed to Payment</p>
            </Link>
          </div>
        </div>

      <div className="summary-details">
        <div className="cash-summary">
          <div className="sub-total">
            <p className="text-bold">Sub total</p>
            <p>${cartSummaryData?.data?.sub_total}</p>
          </div>
          <div className="sub-total">
            <p className="text-bold">Service Charge</p>
            <p>${cartSummaryData?.data?.service_charge}</p>
          </div>
          <div className="sub-total">
            <p className="text-bold">Tax</p>
            <p>${cartSummaryData?.data?.tax}</p>
          </div>
        </div>
        <div className="total">
          <p>Total</p>
          <p>${cartSummaryData?.data?.total}</p>
        </div>
      </div>
    </div>
  );
}

export default SummaryContent;
