import Search from "../Search/Search";
import "./header.scss";
import { Message, Notification } from "../../assets/svg/NavBarIcons";
import Flag from "../../assets/img/Image 1.png";
import Logo from "../../Logo";
import Down from "../../assets/svg/Down";
import { Link, NavLink, useHistory } from "react-router-dom";
import { FaCreditCard, FaPowerOff } from "react-icons/all";
import { useState } from "react";
import { languages } from "../../utils/feedLanguages";
import ReactCountryFlag from "react-country-flag";
import useDeviceType from "../../hooks/deviceType";
import { Fragment } from "react";
import { FaBars, FaSearch, FaShoppingCart } from "react-icons/fa";
import { useSelector } from "react-redux";
import testAvatar from "../../assets/img/Ellipse 4_.png";
import useAuth from "../../hooks/auth";
import { useEffect } from "react";
import VuesaxLinearSmsComponent from "../../assets/iconsax/VuesaxLinearSmsComponent";
import VuesaxLinearShoppingCartComponent from "../../assets/iconsax/VuesaxLinearShoppingCartComponent";
import VuesaxLinearNotificationComponent from "../../assets/iconsax/VuesaxLinearNotificationComponent";
const Header = ({ menuVisibility }) => {
  const [isOptionsVisible, setIsOptionsVisible] = useState();
  const [isLangSelectionVisible, setIslangSelection] = useState();
  const setIsMobileMenuVisible = menuVisibility[1];
  const { isMobile, isDesktop } = useDeviceType();
  const { handleLogout, logout, logoutData } = useAuth();
  const { user } = useSelector((state) => state.currentUser);
  const history = useHistory();
  // console.log(user);
  useEffect(() => {
    logoutData.data && handleLogout();
  }, [logoutData.data]);
  return (
    <div className="header">
      {/* stled circle @ header */}
      <div className="styled__circles"></div>
      <div className="styled__circles"></div>
      <div className="default__container">
        <nav>
          <div className="profile__">
            {user && (
              <Fragment>
                <div className="pic">
                  <img
                    src={user.profile_image?.file || testAvatar}
                    alt="avatar"
                  />
                </div>
                <div className="group__">
                  <div
                    className="more"
                    onClick={(e) => setIsOptionsVisible(!isOptionsVisible)}>
                    {/* more icon */}
                    <Down />
                  </div>
                  <div
                    className="full__name"
                    // onClick={history
                    //   .push()
                    //   .bind(
                    //     this,
                    //     `/feeds/timeline/${user.username}`
                    //   )}
                  >{`${user.first_name} ${user.last_name}`}</div>
                  {isOptionsVisible && (
                    <div className="header__options">
                      <ul>
                        <li>
                          <NavLink to="/payments">
                            <span>
                              <FaCreditCard />
                            </span>
                            Payments
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="#" onClick={logout}>
                            <span>
                              <FaPowerOff />
                            </span>
                            Logout
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </div>
          <div className="other__">
            {isDesktop && (
              <Fragment>
                <div
                  className="icon"
                  onClick={(e) => setIslangSelection(!isLangSelectionVisible)}>
                  <img src={Flag} alt="" />
                </div>
                {isLangSelectionVisible && (
                  <div className="language__options">
                    {languages.map((data, idx) => {
                      return (
                        <Link to="" key={idx}>
                          {" "}
                          <ReactCountryFlag
                            countryCode={data.countryCode}
                          />{" "}
                          <span>{data.language}</span>
                        </Link>
                      );
                    })}
                  </div>
                )}
                <div className="icon">
                  <VuesaxLinearShoppingCartComponent style={{ fontSize: 35 }} />
                  <small className="badge">30</small>
                </div>
                <div className="icon">
                  <VuesaxLinearSmsComponent style={{ fontSize: 35 }} />
                  <small className="badge">20</small>
                </div>
                <div className="icon">
                  <VuesaxLinearNotificationComponent style={{ fontSize: 35 }} />
                  <small className="badge">50+</small>
                </div>
                <Search iconWidth={"24px"} color={"rgba(255,255,255, 0.4)"} />
              </Fragment>
            )}
            <div className="logo">
              {/* logo */}
              <Logo width={isMobile && 40} />
            </div>
          </div>
        </nav>
        {/* mobile widgets */}

        {isMobile && (
          <div className="mobile__widgets">
            <div className="bars" onClick={(e) => setIsMobileMenuVisible(true)}>
              <FaBars size={20} color="var(--bg-white)" />
            </div>
            <div className="cart">
              <FaShoppingCart color="var(--bg-white)" size={20} />
              <small className="badge">5</small>
            </div>
            <div className="icon">
              <Message width={20} />
              <small className="badge">20</small>
            </div>
            <div className="icon">
              <Notification width={20} />
              <small className="badge">50+</small>
            </div>
            <div className="search__icon">
              <FaSearch color="var(--bg-orange)" size={20} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
