import { showToast } from "../components/ReactToast";

const useDeliveryLocationSort = () => {
  //     const locations =
  //   "wuye, abuja, Nigeria * maitama, abuja, Nigeria * wuse, abuja, Nigeria * abia, Nigeria * airport road, abuja, nigeria";

  // adding a location when a stand alone city exists, throws an error
  //adding a city where locations exists rmoves the cities and adds location!
  const checkIfSupportedAddress = async (address) => {
    if (!address)
      throw new Error(
        JSON.stringify({
          message: "address cannot be empty",
        })
      );
    if (address.split(",").length < 2)
      throw new Error(
        JSON.stringify({
          message: "invalid address format",
        })
      );

    return true;
  };

  const getCity = (address = "") => {
    const splitAdd = address.split(",");
    const city = `${splitAdd[splitAdd.length - 2]},${
      splitAdd[splitAdd.length - 1]
    }`
      .trim()
      .toLowerCase();
    return city;
  };

  const addLocation = async (
    locations = "",
    address = "",
    onlyCityMode = true,
    city = ""
  ) => {
    try {
      await checkIfSupportedAddress(address);
      const locationsToArray = locations.split("*");

      if (onlyCityMode) {
        if (city.trim() === "") {
          throw new Error(
            JSON.stringify({
              message: "Select a city first",
            })
          );
        }

        const a = getCity(address);

        if (city.toLowerCase().trim() !== a) {
          throw new Error(
            JSON.stringify({
              message: "Please select places within the same city",
            })
          );
        }
      } else {
        const a = getCity(address),
          b = getCity(locationsToArray[0]);
        if (
          locationsToArray.filter((location) => location.trim() !== "").length >
          0
        ) {
          //   console.log(locationsToArray);

          if (a !== b) {
            throw new Error(
              JSON.stringify({
                message: "Please select places within the same city",
              })
            );
          }
        }
      }
      const addressSplit = address.split(",");

      if (addressSplit.length === 2) {
        //type city

        //if found double
        if (
          locationsToArray.find(
            (location) =>
              location.trim().toLowerCase() === address.toLowerCase().trim()
          )
        ) {
          throw new Error(JSON.stringify({ message: "city already exists" }));
        }

        //if found locations with same city
        if (
          locationsToArray.find((location) =>
            location.trim().toLowerCase().includes(address.trim().toLowerCase())
          )
        ) {
          //generate a new array with all deleted and a single city added!
          const newLocationArray = locationsToArray.filter(
            (location) =>
              !location
                .trim()
                .toLowerCase()
                .includes(address.trim().toLowerCase())
          );
          newLocationArray.push(address);

          //generate new text
          let text = "";
          for (let x of newLocationArray) {
            text = text.trim() !== "" ? text + " * " + x.trim() : x.trim();
          }

          //new text, must replace the entire location text
          return text;
        }

        //add to the location text
        return locations.trim() !== "" ? `${locations} * ${address}` : address;
      } else {
        //type not city
        // if double
        if (
          locationsToArray.find(
            (location) =>
              location.toLowerCase().trim() === address.toLowerCase().trim()
          )
        )
          throw new Error(
            JSON.stringify({
              message: "location already exists",
            })
          );

        //if city alone exists!
        if (
          locationsToArray.find((location) => {
            const l = location.toLowerCase().trim();
            const a = getCity(address);
            console.log(
              a,
              l.split(",").map((e) => e.trim())
            );
            //if location contains one with length two and location includes city in address
            return (
              l.split(",").map((e) => e.trim()).length === 2 && l.includes(a)
            );
          })
        ) {
          throw new Error(
            JSON.stringify({
              message: "a city already exists",
            })
          );
        }

        //address passed
        // add  to the locations text
        return locations.trim() !== "" ? `${locations} * ${address}` : address;
      }
    } catch (err) {
      console.log(JSON.parse(err.message));
      showToast("error", JSON.parse(err.message).message);
    }
  };

  const handleRemoveAddress = (location = "", address = "") => {
    const locationArray = location.split("*").map((item) => item.trim());
    const newArray = locationArray.filter(
      (add) => add.toLowerCase() !== address.toLowerCase().trim()
    );

    //rebuild string

    let text = "";
    for (let x of newArray) {
      text = text.trim() !== "" ? text + " * " + x.trim() : x.trim();
    }

    //new text, must replace the entire location text
    return text;
  };

  const compareCity = (address, city = "") => {
    const derivedCity = getCity(address);
    if (derivedCity === city.toLowerCase().trim()) return true;
    else return false;
  };
  return {
    addLocation,
    handleRemoveAddress,
    compareCity,
  };
};

export default useDeliveryLocationSort;
