import {
  UPDATE_LIKES,
  UPDATE_SAVE,
  DELETE_POST,
  INCREMENT_COMMENT_COUNT,
  CREATE_POST,
} from "./singlePostV2.types";

const defaultPost = false;

const singlePostV2Reducer = (state = defaultPost, { type, payload }) => {
  switch (type) {
    case CREATE_POST:
      return { ...payload };
    case UPDATE_LIKES:
      return (
        state && {
          ...state,
          num_of_likes: payload.count,
          is_liked: payload.status,
        }
      );
    case INCREMENT_COMMENT_COUNT:
      return {
        ...state,
        num_of_comments: state.num_of_comments + 1,
      };
    case UPDATE_SAVE:
      return (
        payload.id === state.id && {
          ...state,
          is_saved: payload.is_saved,
        }
      );
    default:
      return state;
  }
};

export default singlePostV2Reducer;
