// import React from 'react'
import React, { useEffect, createContext, useState, useContext } from "react";
import ModalContext from "../../../hooks/modalContexts";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link  } from "react-router-dom";
import qs from "query-string";
import { toggleRightBar } from "../../../appstore/navigationDisplayToggle/index.action";
import { FaBell, FaArrowLeft } from "react-icons/fa";
import { BsThreeDotsVertical } from "react-icons/bs";
import "./Connect.scss";
import FeedContainer from "../../../components/FeedContainer";
import RecievedCard from "./RecievedCard";
import Recieved from './ManageConnects/Requested/Recievedcard'
import Comment from "../../../assets/icons/comment-alt-regular.svg";
import Caution from "../../../assets/icons/exclamation-solid.svg";
import Notifications from "../../../components/NotificationContainer/notification";
import greeting from "../../../utils/greeting";
import { Users, Suggested, connectUser } from "./ConnectData";
import ConnectedSection from "./ConnectedSection";
import useConnect from "../../../hooks/connectHooks";
import usePages from "../../../hooks/createPage"
import ExploreContext  from '../../../hooks/exploreContext'
import LoaderPurple from "../../../assets/loaders/LoaderPurple"

function Connect() {
  const history = useHistory();
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const user = currentUser.user;
  const { search } = useLocation();
  const { id } = qs.parse(search);
  const { handleConnectModal, handleAcceptModal } = useContext(ModalContext);
  const location = useLocation()

  const {
    handleGetConnectRequest,
    connectRequestData,
    handleLikeProfile,
    handleHideProfile,
    hideProfileData,
    handleLike,
    likeData,
    handleRespondConnectRequest,
    handleGetSingleProfile,
    singleProfileData,
    respondConnectRequestData,
    handleGetConnectProfileSuggested,
    connectProfileData,
    handleGetConnectProfile,
    connectProfileDataSuggested,

    connectRequestReceivedTrueData,
    handleGetConnectRequestReceivedTrue,
    connectRequestConnectedTrueData,
    handleGetConnectRequestConnectedTrue,
    connectRequestSentTrueData,
    handleGetConnectRequestSentTrue,
    handleRespondConnectRequestCard,
    respondConnectRequestCardData,

    handleGetConnectRequestConnectedTrueHome,
    connectRequestConnectedTrueDataHome
    
  } = useConnect();

  const {
    singlePageData,
    handleGetSinglePage,
  } = usePages();

  const {
    acceptModalData,
    setAcceptModalData,
    modalData,
    setModalData,
    acceptedCard,
    declinedCard,
    currentId,
    setCurrentId,
    setMessageData,

    requestReceived, 
    setRequestReceived,
    requestConnectedHome, 
    setRequestConnectedHome,
    requestSent, 
    setRequestSent,
    connectHomeLoad,
    setConnectHomeLoad,
    acceptColor,
    setAcceptColor,
  } = useContext(ExploreContext)

 
  // const initLike = {
  //   id: user.user_id,
  //   status: true
  // }
  // const [likeConnect, setLikeConnect] = useState(initLike)
  useEffect(() => {
    handleGetSinglePage(id);
  }, [id]);

useEffect(() => {
  handleGetConnectProfile();

  handleGetConnectRequestReceivedTrue(id)
  handleGetConnectRequestConnectedTrue()
  handleGetConnectRequestSentTrue()
  handleGetConnectRequestConnectedTrueHome(id)
}, [])

useEffect(() => {
  setRequestReceived(connectRequestReceivedTrueData?.data?.results)

}, [connectRequestReceivedTrueData?.data])

useEffect(() => {
  setRequestConnectedHome(connectRequestConnectedTrueDataHome?.data?.results)

}, [connectRequestConnectedTrueDataHome?.data])

useEffect(() => {
  setRequestSent(connectRequestSentTrueData?.data?.results)
}, [connectRequestSentTrueData?.data])



  useEffect(() => {
    const currentProId = connectProfileData?.data?.results?.[0]?.id
    if(currentProId){
        handleGetConnectProfileSuggested(currentProId)
    }
  }, [connectProfileData?.data?.results?.[0]?.id])

  useEffect(() => {
    dispatch(toggleRightBar(false));
  }, []);

  useEffect(() => {
    handleGetConnectRequest();
    console.log(connectRequestData?.data?.results);
    console.log("##############")
  }, []);

  // useEffect(() => {
  //   if(respondConnectRequestData?.data){
  //     history.push(`/businessseller/manage-connect/?id=${id}`)
  //   }
  // }, [respondConnectRequestData?.data])


  useEffect(() => {
    if(hideProfileData.data){
      console.log(hideProfileData.data)
      handleGetConnectRequest();
      // handleGetConnectRequest();
      // handleGetConnectProfile();
    }
  }, [hideProfileData.data]);

  useEffect(() => {
    if(likeData.data){
      handleGetConnectRequest();
    }
  }, [likeData.data])

  // useEffect(() => {
  //   if(acceptedCard){
  //     handleGetConnectRequestConnectedTrue()
  //     handleGetConnectRequestReceivedTrue()
  //     setRequestReceived(connectRequestReceivedTrueData?.data?.results)
  //     setRequestConnected(connectRequestConnectedTrueData?.data?.results)
  //   }
  // }, [acceptedCard])
  
  // useEffect(() => {
  //   if(declinedCard){
  //     handleGetConnectRequestConnectedTrue()
  //     handleGetConnectRequestReceivedTrue()
  //     setRequestReceived(connectRequestReceivedTrueData?.data?.results)
  //     setRequestConnected(connectRequestConnectedTrueData?.data?.results)
  //   }
  // }, [declinedCard])


  // Recall both Connect home request (recieved and conneted) endpoint after accept or decline
    useEffect(() => {
    if(connectHomeLoad === true){
      // window.location.reload(false);
      handleGetConnectRequestConnectedTrue();
      handleGetConnectRequestReceivedTrue(id);
    }
    }, [connectHomeLoad])

    useEffect(() => {
      setConnectHomeLoad(false)
    }, [])

   
    // console.log(connectHomeLoad)

  // Reload connect after accept and decline
  // useEffect(() => {
  //   handleGetConnectRequestConnectedTrue();
  //   handleGetConnectRequestReceivedTrue();
  // }, [respondConnectRequestData?.data])


//   useEffect(() => {
//   // console.log(respondConnectRequestData?.data?.status)
//    if(respondConnectRequestData?.response?.response?.status === 200){
//        window.location.reload(false);
//      } 
       
// }, [respondConnectRequestData]);

console.log(respondConnectRequestData)

//   const handleSubmit = () => {
//     // e.preventDefault();
//     if(handleLikeProfile(likeConnect)){
//       console.log("=====SUCCESS========");
//     };
// };

// useEffect(() => {
//   if(hideProfileData.data){
//       handleGetConnectRequest();
//       handleGetConnectProfile();
//   }
// }, [hideProfileData.data])

// After Annie update (View all button)
// && !connectRequestData?.data?.results?.status === "Pending"
  return (
    <FeedContainer>
      <section className="connect">
      <div className='welcome-back'>
        <Link
            onClick={history.goBack}
            className='back-btn'
                >
            <FaArrowLeft />
        </Link>
         <h1 className='connect-greeting'>
          {greeting()} <span className="user">{singlePageData?.data?.name?.split(" ")[0]} </span>
        </h1>
      </div>
        <section className="connect-container">
          <div className="connect-top">
            <div>
              <h2>Connect</h2>
            </div>
            <div>
              <Link to={`/businessseller/show-profile/?id=${id}`}>
                <button>My connects profiles</button>
              </Link>
            </div>
          </div>
          <div className="connect-wrap">
            <div className="connect-wrap-left">
              <Notifications />
            </div>
            <div className="connect-wrap-right">
              <div className="right-top">
                <Link to={`/businessseller/manage-connect/?id=${id}`}>
                  <h2>Manage</h2>
                </Link>
                <Link to="/businessseller/transaction-history">
                  <p className="trans-history">Transaction History</p>
                </Link>
              </div>
              <div className="connect-manage">
                <p className="top-text">Received Connection Requests</p>
                <div className="connect-manage-wrap">
                  {requestReceived <= 0 && <div> You currently do no have any connect request  </div>}
                  {connectRequestReceivedTrueData.loading && <LoaderPurple /> }

                  {!connectRequestReceivedTrueData.loading && connectRequestReceivedTrueData.error && (
                    <p>An error</p>
                  )}

                  {!connectRequestReceivedTrueData.loading &&
                    !connectRequestReceivedTrueData.error &&
                    requestReceived?.slice(0, 4).reverse().map((recieved, idx) => (
                      <Recieved key={recieved.id} {...recieved}
                        recieved={recieved}
                        handleHideProfile={handleHideProfile}
                        hideProfileData={hideProfileData}
                        handleLike={handleLike}
                        id={id}
                        // currentProfile={currentProfile}
                        acceptModalData={acceptModalData}
                        setAcceptModalData={setAcceptModalData}
                        handleRespondConnectRequest={handleRespondConnectRequest}
                        currentId={currentId}
                        setCurrentId={setCurrentId}
                        acceptColor={acceptColor}
                        setAcceptColor={setAcceptColor}
                        respondConnectRequestCardData={respondConnectRequestCardData}
                        handleRespondConnectRequestCard={handleRespondConnectRequestCard}
                        handleGetConnectRequestReceivedTrue={handleGetConnectRequestReceivedTrue}
                        handleGetConnectRequestConnectedTrue={handleGetConnectRequestConnectedTrue}
                      />
                      ))}
                </div>
                {connectRequestReceivedTrueData?.data?.count > 4 ? (
                  <div className="connect-manage-view">
                    <Link to={`/businessseller/manage-connect/?id=${id}`}>
                      <p>View All</p>
                    </Link>
                  </div>
                ) : null}
              </div>
              <div>
                <ConnectedSection
                  id={id}
                  handleHideProfile={handleHideProfile}
                  hideProfileData={hideProfileData}
                  handleLike={handleLike}
                  modalData={modalData}
                  setModalData={setModalData}
                  handleGetSingleProfile={handleGetSingleProfile}
                  singleProfileData={singleProfileData}
                  currentId={currentId}
                  setCurrentId={setCurrentId}
                  likeData={likeData}
                  connectProfileDataSuggested={connectProfileDataSuggested}
                  handleGetConnectProfileSuggested={handleGetConnectProfileSuggested}
                  requestConnectedHome={requestConnectedHome}
                  connectRequestConnectedTrueDataHome={connectRequestConnectedTrueDataHome}
                  setMessageData={setMessageData}
                  />
              </div>
            </div>
          </div>
        </section>
      </section>
    </FeedContainer>
  );
}

export default Connect;
