import React, { useEffect, useState } from "react";
import { NavLink, Redirect } from "react-router-dom";
import image from "../../../assets/icons/Group 142.png";
import useAuth from "../../../hooks/auth";
import greeting from "../../../utils/greeting";
import Loader from "../../loader";
import "./FormSignIn.scss";
import { useSelector } from "react-redux";

function FormSignIn() {
  const intialValues = {
    username: "",
    password: "",
  };

  const { handleLogin, loginData } = useAuth();

  const [formValues, setFormValues] = useState(intialValues);
  const [formErrors, setFormErrors] = useState({});
  // const [isSubmitting, setIsSubmitting] = useState(false);
  const { user } = useSelector((state) => state.currentUser);
  // setGreeting({ greeting: "Good evening" });

  //clear errors
  console.log(user, "if user");
  useEffect(() => {
    if (Object.keys(formErrors).length) {
      setTimeout(() => {
        setFormErrors({});
      }, 5000);
    }
  }, [formErrors]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    !Object.keys(validate(formValues)).length && handleLogin(formValues);
  };

  const validate = (values) => {
    let errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.username) {
      errors.username = "please enter username";
    }

    if (!values.password) {
      errors.password = "please enter your password";
    }

    // if (!values.email) {
    //   errors.email = "please enter email";
    // } else if (!regex.test(values.email)) {
    //   errors.email = "Invalid email format";
    // }
    return errors;
  };

  //handle login error from server

  useEffect(() => {
    if (loginData.error?.response) {
      loginData.error.response.data.non_field_errors &&
        setFormErrors({
          ...formErrors,
          username: loginData.error.response.data.non_field_errors,
        });
    }
  }, [loginData.error]);

  return user ? (
    <Redirect
      to={
        user.default_landing_page.toLowerCase() === "social"
          ? "/feeds/trending"
          : "/explore"
      }
    />
  ) : (
    <div className="signInForm">
      <div id="roundedCircleBottom"></div>
      <div id="roundedCircleBottomRight"></div>

      <div className="main-content">
        <div className="left-img-container">
          <img src={image} alt="" />
        </div>

        <div className="form-container">
          <div className="form-card">
            <div className="form-content">
              <h5 className="form-title-account">
                {greeting()} <span>Prof </span>
              </h5>
              <h1 className="form-title">Welcome back!</h1>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  {/* <label for="">Email/Username </label> */}
                  <input
                    className="form-input"
                    type="text"
                    name="username"
                    placeholder="JonathanDoe@gmail.com"
                    value={formValues.username}
                    onChange={handleInputChange}
                  />
                  {formErrors.username && (
                    <span className="error">{formErrors.username}</span>
                  )}
                </div>

                <div className="input-group">
                  {/* <label for="">Password </label> */}
                  <input
                    placeholder="Password"
                    className="form-input"
                    type="password"
                    name="password"
                    value={formValues.password}
                    onChange={handleInputChange}
                  />
                  {formErrors.password && (
                    <span className="error">{formErrors.password}</span>
                  )}
                </div>

                <div class="terms-container">
                  <NavLink
                    exact
                    to="/forgotpassword"
                    activeClassName="active"
                    className="forgot-password-link">
                    Forgot Password?
                  </NavLink>
                </div>

                <div className="submit-box">
                  {!loginData.loading ? (
                    <button className="btn-submit" type="submit">
                      Sign In
                    </button>
                  ) : (
                    <Loader text="Chill..." />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSignIn;
