import render from "react-render-html";
import { useLocation, useHistory, useParams } from "react-router-dom";
import { handlePostText } from "../../utils/formatText";
import CaretAdjust from "contenteditablecaret";
import HeartComponent from "../../assets/iconsax/active/HeartComponent";
import VuesaxBoldHeartComponent from "../../assets/iconsax/VuesaxBoldHeartComponent";
import { FaReply } from "react-icons/all";
import qs from "query-string";
import usePosts from "../../hooks/posts";
import { useSelector } from "react-redux";
import moment from "moment";
const kFormatter = (num) => {
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
    : Math.sign(num) * Math.abs(num);
};
const CommentComponent = ({
  showReplyButton = true,
  comment,
  commentBox,
  setReply = () => {},
  setPlaceholder = () => {},
}) => {
  const history = useHistory();
  const { handleLikeComment } = usePosts();
  const { id } = qs.parse(history.location.search);
  console.log(id);
  const { postId } = useParams();
  // console.log(params);

  const handleClickReply = () => {
    commentBox.current.scrollIntoView(false);
    // commentBox.current.innerHTML = `@${comment.owner.username} `;
    commentBox.current.focus();

    // CaretAdjust(commentBox.current, `@${comment.owner.username} `, true, false);
    setPlaceholder(`@${comment.owner.username}`);
    setReply(comment.id);
  };
  const handleLikeIconClick = () => {
    handleLikeComment(postId || id, comment.id);
  };
  // console.log(comment, "comment", id);
  return (
    <div
      className="comment"
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/comment/${comment.id}`);
      }}>
      {/* <h5>View all comments</h5> */}
      {/* <h5>reply</h5> */}
      <div className="section__">
        <div className="avatar" onClick={(e) => e.stopPropagation()}>
          <img
            src={
              comment.page?.featured_image?.file ||
              comment.owner.profile_image?.file
            }
            alt="user"
          />
        </div>
        <div className="detail" onClick={(e) => e.stopPropagation()}>
          <div
            className="name"
            onClick={(e) => {
              comment.page
                ? history.push(
                    `/${
                      comment.page.type.toLowerCase() === "business"
                        ? "businessseller"
                        : "privateseller"
                    }/profile/${comment.page.id}`
                  )
                : history.push(`/feeds/timeline/${comment.owner.username}`);
            }}>
            {comment.page?.name || comment.owner.full_name}
          </div>
          <small className="last__seen">
            {moment(comment.created_at).fromNow()}
          </small>
          <div className="main__comment">
            {render(handlePostText(comment.body))}
            {showReplyButton && (
              <span className="reply" onClick={handleClickReply}>
                Reply
              </span>
            )}
          </div>
        </div>
        <div className="reply-actions">
          <div className="reply-count">
            <FaReply /> {kFormatter(comment.reply_count)}
          </div>
          <div
            className="like___icon"
            onClick={(e) => {
              e.stopPropagation();
              handleLikeIconClick();
            }}>
            {comment.has_like ? (
              <HeartComponent width={"15.5px"} />
            ) : (
              <VuesaxBoldHeartComponent />
            )}
          </div>
        </div>
      </div>
      <div className="replies">
        {/* {console.log(comment)} */}
        {comment.replies?.map((data) => (
          <CommentComponent
            comment={data}
            commentBox={commentBox}
            key={data.id}
            setReply={setReply}
            setPlaceholder={setPlaceholder}
            showReplyButton={false}
          />
        ))}
      </div>
    </div>
  );
};
export default CommentComponent;
