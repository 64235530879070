import { House } from "@material-ui/icons";
import { useEffect, useState } from "react";
import Cart from "../../assets/svg/cart";
import CaseBrief from "../../assets/svg/caseBrief";
import { Location } from "../../assets/svg/CreateMomentIcons";
import Happy from "../../assets/svg/happy";
import useInterests from "../../hooks/interests";
import Cards from "./cards";
import "./index.scss";
import { useHistory } from "react-router-dom";
import Loader from "../loader";

const Interests = () => {
  const history = useHistory();
  const [active, setActive] = useState(1);
  const { fetchInterest, interestData, handleAddInterests, addInterestsData } =
    useInterests();
  const [data, setData] = useState({
    interests: [],
    locations: [],
    jobs: [],
    businesses: [],
    products: [],
  });

  console.log(data);

  const handleDataAddition = (id, categ) => {
    const category = data[categ];
    //check if exist to either remove from list or add
    if (category.includes(id)) {
      const newArray = category
        .map((item) => item !== id && item)
        .filter((data) => data && data);
      setData({ ...data, [categ]: newArray });
    } else {
      setData({ ...data, [categ]: [...category, id] });
    }
  };
  useEffect(() => {
    fetchInterest({});
  }, []);

  useEffect(() => {
    if (addInterestsData.error) {
      //handle error, coming soon!!
    }

    if (addInterestsData.data) {
      history.push("/explore");
    }
  }, [addInterestsData.data, addInterestsData.error]);
  return (
    <div className="interests">
      <div className="container">
        <div className="pageHeader">
          <h2>Explore your interest</h2>
          <p>
            Explore lots of interests and opportunities around you, Connect and
            have fun the easy way
          </p>
        </div>
        <div className="tabHeaders">
          <div
            className={`${active === 1 && "active"} tabHeader`}
            onClick={() => setActive(1)}>
            <Happy />
            <span>Interests</span>
            {active===1 && (
              <div className="underline"></div>
            )}
          </div>
          <div
            className={`${active === 2 && "active"} tabHeader`}
            onClick={() => setActive(2)}>
            <Location />
            <span>Location</span>
            {active===2 && (
              <div className="underline"></div>
            )}
          </div>
          <div
            className={`${active === 3 && "active"} tabHeader`}
            onClick={() => setActive(3)}>
            <CaseBrief />
            <span>Jobs around you</span>
            {active===3 && (
              <div className="underline"></div>
            )}
          </div>
          <div
            className={`${active === 4 && "active"} tabHeader`}
            onClick={() => setActive(4)}>
            <House />
            <span>Business</span>
            {active===4 && (
              <div className="underline"></div>
            )}
          </div>
          <div
            className={`${active === 5 && "active"} tabHeader`}
            onClick={() => setActive(5)}>
            <Cart />
            <span>Products</span>
            {active===5 && (
              <div className="underline"></div>
            )}
          </div>
        </div>
        {active === 1 && (
          <div className="tabContainer">
            {interestData.data?.interests.map((interestOption) => {
              return (
                <Cards
                  interestOption={{ ...interestOption, categ: "interests" }}
                  key={interestOption.id}
                  setData={handleDataAddition}
                  isSelected={data.interests.includes(interestOption.id)}
                />
              );
            })}
          </div>
        )}
        {active === 2 && (
          <div className="tabContainer">
            {interestData.data?.locations.map((interestOption) => {
              return (
                <Cards
                  interestOption={{ ...interestOption, categ: "locations" }}
                  key={interestOption.id}
                  setData={handleDataAddition}
                  isSelected={data.locations.includes(interestOption.id)}
                />
              );
            })}
          </div>
        )}
        {active === 3 && (
          <div className="tabContainer">
            {interestData.data?.jobs.map((interestOption) => {
              return (
                <Cards
                  interestOption={{ ...interestOption, categ: "jobs" }}
                  key={interestOption.id}
                  setData={handleDataAddition}
                  isSelected={data.jobs.includes(interestOption.id)}
                />
              );
            })}
          </div>
        )}
        {active === 4 && (
          <div className="tabContainer">
            {interestData.data?.businesses.map((interestOption) => {
              return (
                <Cards
                  interestOption={{ ...interestOption, categ: "businesses" }}
                  key={interestOption.id}
                  setData={handleDataAddition}
                  isSelected={data.businesses.includes(interestOption.id)}
                />
              );
            })}
          </div>
        )}
        {active === 5 && (
          <div className="tabContainer">
            {interestData.data?.products.map((interestOption) => {
              return (
                <Cards
                  interestOption={{ ...interestOption, categ: "products" }}
                  key={interestOption.id}
                  setData={handleDataAddition}
                  isSelected={data.products.includes(interestOption.id)}
                />
              );
            })}
          </div>
        )}
        <div className="navigation">
          <div className="navButtons">
            {active === 5 ? (
              <p onClick={(e) => handleAddInterests(data)}>Done</p>
            ) : (
              <p
                onClick={() => {
                  setActive(active + 1);
                }}>
                Next
              </p>
            )}
            <p onClick={(e) => handleAddInterests()}>Skip</p>
          </div>
        </div>
      </div>
      {/* <Loader /> */}
    </div>
  );
};
export default Interests;
