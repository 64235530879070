const TaskSquare = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33.357"
      height="33.357"
      viewBox="0 0 33.357 33.357"
    >
      <g
        id="vuesax_outline_task-square"
        data-name="vuesax/outline/task-square"
        transform="translate(-492 -316)"
      >
        <g id="task-square" transform="translate(492 316)">
          <path
            id="Vector"
            d="M8.339,2.085h-7.3A1.05,1.05,0,0,1,0,1.042,1.05,1.05,0,0,1,1.042,0h7.3a1.042,1.042,0,0,1,0,2.085Z"
            transform="translate(16.151 11.286)"
            fill={fill || "#fbb03b"}
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M2.081,5.209A1.031,1.031,0,0,1,1.345,4.9L.3,3.86A1.042,1.042,0,0,1,1.776,2.387l.306.306L4.472.3A1.042,1.042,0,0,1,5.945,1.776L2.818,4.9A1.041,1.041,0,0,1,2.081,5.209Z"
            transform="translate(7.815 9.218)"
            fill={fill || "#fbb03b"}
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M8.339,2.085h-7.3A1.05,1.05,0,0,1,0,1.042,1.05,1.05,0,0,1,1.042,0h7.3a1.042,1.042,0,0,1,0,2.085Z"
            transform="translate(16.151 21.015)"
            fill={fill || "#fbb03b"}
          />
          <path
            id="Vector-4"
            data-name="Vector"
            d="M2.081,5.209A1.031,1.031,0,0,1,1.345,4.9L.3,3.86A1.042,1.042,0,0,1,1.776,2.387l.306.306L4.472.3A1.042,1.042,0,0,1,5.945,1.776L2.818,4.9A1.041,1.041,0,0,1,2.081,5.209Z"
            transform="translate(7.815 18.948)"
            fill={fill || "#fbb03b"}
          />
          <path
            id="Vector-5"
            data-name="Vector"
            d="M0,0H33.357V33.357H0Z"
            fill="none"
            opacity="0"
          />
          <path
            id="Vector-6"
            data-name="Vector"
            d="M19.111,29.883H10.772C3.225,29.883,0,26.658,0,19.111V10.772C0,3.225,3.225,0,10.772,0h8.339c7.547,0,10.772,3.225,10.772,10.772v8.339C29.883,26.658,26.658,29.883,19.111,29.883Zm-8.339-27.8c-6.407,0-8.687,2.279-8.687,8.687v8.339c0,6.407,2.279,8.687,8.687,8.687h8.339c6.407,0,8.687-2.279,8.687-8.687V10.772c0-6.407-2.279-8.687-8.687-8.687Z"
            transform="translate(1.737 1.737)"
            fill={fill || "#fbb03b"}
          />
        </g>
      </g>
    </svg>
  );
};

export default TaskSquare;
