import React, { useState, Component } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";
class NumberInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      number: props.initial || 0,
    };
    this.handleChange = this.handleChange.bind(this);
    this.inputRef = React.createRef();
    this.handleAdditionOrSubtraction =
      this.handleAdditionOrSubtraction.bind(this);
  }
  handleAdditionOrSubtraction = (e) => {
    if (e === "add") {
      this.setState({ ...this.state, number: this.state.number + 1 });
    } else {
      if (this.state.number <= 0) {
      } else this.setState({ ...this.state, number: this.state.number - 1 });
    }
  };
  handleChange = (e) => {
    const value = e.target.value;
    const isNumber = /^[0-9]/.test(value);
    if (isNumber) {
      this.setState({
        ...this.state,
        number: Number(value.replace(/^0+/, "")),
      });
    }
    if (value === "") {
      this.setState({ ...this.state, number: 0 });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    prevState.number !== this.state.number &&
      this.props.onChange &&
      this.props.onChange(this.state.number);
  }
  render() {
    return (
      <div className="number-input">
        <input
          className="number-content"
          value={this.state.number}
          defaultValue={this.state.numbernumber}
          onChange={this.handleChange}
          type="number"
          ref={this.inputRef}
        />

        <div className="number-icons">
          <div
            className="up-icon"
            onClick={(e) => this.handleAdditionOrSubtraction("add")}>
            <FaAngleUp />
          </div>
          <div className="down-icon" onClick={this.handleAdditionOrSubtraction}>
            <FaAngleDown />
          </div>
        </div>
      </div>
    );
  }
}

export default NumberInput;
