import React, { useContext, useEffect } from "react";
import "./psSummaryModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import Photo from "../../../assets/img/shoe.png";
import profileImg from "../../../assets/img/Ellipse 543@2x.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import { FaPen } from "react-icons/fa";
import CloseBtn from "../../../components/closeBtn";
import useCreateListings from "../../../hooks/createListings";
import LoaderWhite from "../../../assets/loaders/LoaderWhite";
import usePages from "../../../hooks/createPage";
import moment from "moment";
import { useHistory } from "react-router";

const PrivateSellerSummaryModal = () => {
  const { handlePrivateSellerSummaryModal, privateSellerSummaryModal } =
    useContext(ModalContext);
  const { handleCreateListing, loading, createListingData } =
    useCreateListings();
  const { handleGetSinglePage, singlePageData } = usePages();
  const { data } = privateSellerSummaryModal;
  // console.log(data);
  useEffect(() => {
    handleGetSinglePage(privateSellerSummaryModal.data.page);
  }, []);
  // console.log(privateSellerSummaryModal);
  const history = useHistory();
  useEffect(() => {
    if (createListingData.data && singlePageData.data) {
      history.push(
        "/privateseller/dashboard" + "?id=" + singlePageData.data.id
      );
    }
  }, [createListingData.data, singlePageData.data]);
  return (
    <div
      onClick={() => {
        handlePrivateSellerSummaryModal("RESET_DATA");
      }}
      className="psModal">
      <div className="psModalContainer">
        <div
          onClick={() => {
            handlePrivateSellerSummaryModal("RESET_DATA");
          }}
          className="closeIcon">
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="psModalContainered">
          <div className="psModal-header">
            <h4> Summary</h4>

            {!loading ? (
              <button
                className="btn-post"
                onClick={handleCreateListing.bind(this, {
                  data: privateSellerSummaryModal.data,
                  images: privateSellerSummaryModal.images,
                })}>
                Post
              </button>
            ) : (
              <button className="btn-post">
                <LoaderWhite
                  style={{
                    width: 20,
                    height: "auto",
                  }}
                />
              </button>
            )}
          </div>
          <div className="psModal-body">
            <div className="summary-container-one">
              <div className="img-sm-box">
                <Slick dots={true} slidesToShow={1}>
                  {privateSellerSummaryModal.rawImages?.map(
                    (data, idx) => (
                      <img key={idx} src={URL.createObjectURL(data)} alt="" />
                    )
                    // console.log(data)
                  )}
                </Slick>
              </div>
              <div className="desc-sm-box">
                <div className="orc-detail-box">
                  {singlePageData.data?.featured_image && (
                    <img
                      src={singlePageData.data.featured_image.file}
                      alt="avatar"
                    />
                  )}
                  <div className="orc-detail-box-user">
                    <h4>{singlePageData.data?.name}</h4>
                    <h6>{singlePageData.data?.location || "No location"}</h6>
                  </div>
                </div>
                <h3> {data.title} </h3>
                <h4> Product Description </h4>
                <p>{data.description}</p>
              </div>
            </div>
            <div className="summary-container-two">
              <div className="sct-box-one">
                <h5> Price detail </h5>
                <div className="sct-price-detail">
                  <div className="sct-pd-box">
                    <div>
                      <h6>
                        Product price: <span>${data.stocks[0].price}</span>
                      </h6>
                      <h6>
                        Quantity: <span>1</span>
                      </h6>
                    </div>
                  </div>
                </div>

                {data.allow_referral && (
                  <>
                    <h5>Referral</h5>
                    {data.who_can_refer === "ANYBODY" ? (
                      <div className="sct-referral">
                        <h6>
                          Anyone:{" "}
                          <span>
                            {" "}
                            $
                            {
                              privateSellerSummaryModal.data.referrals[0]
                                .price_value
                            }
                          </span>
                        </h6>
                      </div>
                    ) : (
                      <div className="sct-influencer">
                        <h6> Influencer </h6>
                        <div className="item__scroll">
                          {privateSellerSummaryModal.data.referrals.map(
                            (data, idx) => (
                              <div className="scti-details" key={idx}>
                                <h6>
                                  {idx + 1} <span> {data.username}</span>
                                </h6>
                                <p>
                                  price: <span>${data.price_value} </span>
                                </p>
                                <p>
                                  REf code: <span>{data.code}</span>
                                </p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div
                className="sct-box-two"
                style={{
                  maxHeight: 400,
                  overFlow: "auto",
                }}>
                <h5>End date</h5>
                <div className="sct-date">
                  <h6>
                    End date:{" "}
                    <span>
                      {moment(
                        privateSellerSummaryModal.data.listing_end_date
                      ).format("MMMM DD, YYYY")}{" "}
                    </span>
                  </h6>
                  <h6>
                    Time:{" "}
                    <span>
                      {" "}
                      {moment(
                        privateSellerSummaryModal.data.listing_end_date
                      ).format("HH:MMa")}{" "}
                    </span>
                  </h6>
                </div>

                {/* <div className="sct-request">
                  <h6>Request:</h6>
                  <span>Auto Accept </span>
                </div> */}
                {data.delivery_locations?.map((item) => {
                  return (
                    <React.Fragment key={item.tempId}>
                      <h5> Delivery Details</h5>
                      {/* <div className="sctd-box-one">
                        <h6>
                          Delivery Price: <span>Free</span>
                        </h6>
                      </div> */}
                      <div className="sct-delivery-details">
                        <div className="item__scroll">
                          {item.locations.map((location, idx) => (
                            <div className="sct-dd-container" key={idx}>
                              <div className="sctd-box-two">
                                <div className="box-item-one">
                                  <h5> Location </h5>
                                  <h6>{item.city.address} </h6>
                                </div>
                                <div className="box-item-two">
                                  <h5> Delivery Mode</h5>
                                  <h6>
                                    {" "}
                                    {location.delivery_mode === "PICK_UP"
                                      ? "pick up"
                                      : "door delivery"}
                                  </h6>
                                </div>
                                <div className="box-item-three">
                                  <h5> Delivery Address </h5>
                                  <h6> {location.address} </h6>
                                </div>
                                <div className="box-item-four">
                                  <h5> Delivery Fee </h5>
                                  <h6> {location.delivery_fee} </h6>
                                </div>
                              </div>
                              {/* <div className="sct-dd-footer">
                                <FaPen />
                              </div> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrivateSellerSummaryModal;
