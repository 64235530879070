import { Line } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
// import { chartData, chartOptions } from "../chartDetails";
//  import { Chart as ChartJS } from "chart.js/auto";

const AreaChart = ({ data }) => {
  const chartDate = data?.summary?.chart?.sort(function (a, b) {
    return a.month - b.month;
  });

  let chWidth, chHeight, gradient, chWidth2, chHeight2, gradient2;
  function getGradientGreen(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient || chWidth !== chartWidth || chHeight !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      chWidth = chartWidth;
      chHeight = chartHeight;
      gradient = ctx.createLinearGradient(
        0,
        chartArea.top,
        0,
        chartArea.height
      );
      gradient.addColorStop(0, "rgba(0,193,58, 0.5)");
      gradient.addColorStop(0.5, "rgba(0,193,58, 0.25)");
      gradient.addColorStop(1, "rgba(0,193,58, 0)");
    }

    return gradient;
  }

  function getGradientRed(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (!gradient2 || chWidth2 !== chartWidth || chHeight2 !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      chWidth2 = chartWidth;
      chHeight2 = chartHeight;
      gradient2 = ctx.createLinearGradient(
        0,
        chartArea.top,
        0,
        chartArea.height
      );
      gradient2.addColorStop(0, "rgba(206, 0, 0, 0.5)");
      gradient2.addColorStop(0.5, "rgba(206, 0, 0, 0.25)");
      gradient2.addColorStop(1, "rgba(206, 0, 0, 0)");
    }

    return gradient2;
  }

  const chartData = {
    labels: chartDate?.map((item) => {
      if (item.month === 1) {
        return "Jan";
      }
      if (item.month === 2) {
        return "Feb";
      }
      if (item.month === 3) {
        return "Mar";
      }
      if (item.month === 4) {
        return "Apr";
      }
      if (item.month === 5) {
        return "May";
      }
      if (item.month === 6) {
        return "Jun";
      }
      if (item.month === 7) {
        return "Jul";
      }
      if (item.month === 8) {
        return "Aug";
      }
      if (item.month === 9) {
        return "Sep";
      }
      if (item.month === 10) {
        return "Oct";
      }
      if (item.month === 11) {
        return "Nov";
      }
      if (item.month === 12) {
        return "Dec";
      }
    }),
    datasets: [
      {
        label: "Total Revenue",
        fill: true,
        // fillColor: gradient,
        lineTension: 0.1,
        // backgroundColor: gradient,
        borderColor: "rgba(0,193,58,1.0)",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;

          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradientGreen(ctx, chartArea);
        },
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: "rgba(0,193,58,1.0)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 0.5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "rgba(0,193,58,1.0)",
        pointHoverBorderColor: "rgba(220,220,220,1)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: data?.summary?.chart.map((item) => item.count),
        // data: [10, 35, 30, 55, 45, 55, 37],
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
    //   animation: {
    //     easing: 'easeInOutQuad',
    //     duration: 520
    // },
    animation: {
      x: {
        duration: 3500,
        from: 0,
      },
      y: {
        duration: 2000,
        from: 500,
      },
    },
  };
  /*
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   *
   **-------------------  RED CHART   *****/

  return (
    <div>
      <Line data={chartData} options={chartOptions} height={75} width={200} />
    </div>
  );
};

export default AreaChart;
