import useAxios from "axios-hooks";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";

const useBusinessSellerDashboard = () => {
  const [{ ...listingData }, getListingData] = useAxios();

  const currentUser = useSelector((state) => state.currentUser);

  const handleGetListingData = ({ id }) => {
    getListingData({
      method: "get",
      url: "/orders/",
      params: {
        page: id,
        status: "PENDING",
        sudo: "true",
      },
    });
  };

  const [{ ...bookingRequestsData }, getBookngRequests] = useAxios();
  const handleGetBookingRequests = (id) => {
    getBookngRequests({
      method: "get",
      url: "/bookings/get_booking_request/",
      params: {
        // service__page: id,
        customer: currentUser?.user?.id,
        page: id,
      },
    });
  };

  const [{ ...queueRequestData }, getQueueRequests] = useAxios();
  const handleGetQueueRequest = (id) => {
    getQueueRequests({
      method: "get",
      url: "/bookings/",
      params: {
        queue_status: "PENDING",
        queue_tab: "requested",
        is_queue: "1",
        service__page: id,
      },
    });
  };

  const [{ ...updateQueueData }, updateQueue] = useAxios();
  const handleUpdateQueue = ({ id, status }) => {
    updateQueue({
      method: "get",
      url: `/bookings/${id}/update_queue_status/`,
      params: {
        queue_status: status,
      },
    });
  };

  return {
    listingData,
    handleGetListingData,
    bookingRequestsData,
    handleGetBookingRequests,
    queueRequestData,
    handleGetQueueRequest,
    updateQueueData,
    handleUpdateQueue,
  };
};
export default useBusinessSellerDashboard;
