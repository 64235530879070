export const CircleAdd = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "12.591"}
    height={height || "12.591"}
    viewBox="0 0 12.591 12.591">
    <g
      id="Icon_feather-plus-circle"
      data-name="Icon feather-plus-circle"
      transform="translate(-2.5 -2.5)">
      <path
        id="Path_332"
        data-name="Path 332"
        d="M14.591,8.8A5.8,5.8,0,1,1,8.8,3,5.8,5.8,0,0,1,14.591,8.8Z"
        transform="translate(0 0)"
        fill="none"
        stroke={fill || "#fbb03b"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_333"
        data-name="Path 333"
        d="M18,12v4.636"
        transform="translate(-9.205 -5.523)"
        fill="none"
        stroke={fill || "#fbb03b"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Path_334"
        data-name="Path 334"
        d="M12,18h4.636"
        transform="translate(-5.523 -9.205)"
        fill="none"
        stroke={fill || "#fbb03b"}
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
    </g>
  </svg>
);

export const Pin = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "9.848"}
    height={height || "14.51"}
    viewBox="0 0 9.848 14.51">
    <path
      id="Icon_metro-pin"
      data-name="Icon metro-pin"
      d="M17.127,14.471l-4.843-2.8a1.242,1.242,0,1,0-1.243,2.152l4.842,2.8a1.243,1.243,0,0,0,1.243-2.152Zm-3.994-3.023L16.9,13.622l1.281-3.565-2.6-1.5Zm-2.777,9.159,3.6-4.377L12.345,15.3ZM19.737,8.085,16.509,6.221a.932.932,0,0,0-.932,1.614L18.806,9.7a.932.932,0,1,0,.931-1.614Z"
      transform="translate(-10.356 -6.096)"
      fill={fill || "#fbb03b"}
    />
  </svg>
);
export const Edit = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "11.514"}
    height={height || "11.514"}
    viewBox="0 0 11.514 11.514">
    <path
      id="Icon_material-edit"
      data-name="Icon material-edit"
      d="M4.5,13.612v2.4H6.9l7.074-7.074-2.4-2.4Zm11.327-6.53a.637.637,0,0,0,0-.9l-1.5-1.5a.637.637,0,0,0-.9,0l-1.17,1.17,2.4,2.4Z"
      transform="translate(-4.5 -4.496)"
      fill={fill || "#490057"}
    />
  </svg>
);
export const MoreOptionsIcon = ({ width, height, fill }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "16"}
    height={height || "4"}
    viewBox="0 0 16 4">
    <circle
      id="Ellipse_261"
      data-name="Ellipse 261"
      cx="2"
      cy="2"
      r="2"
      fill={fill || "#787878"}
    />
    <circle
      id="Ellipse_262"
      data-name="Ellipse 262"
      cx="2"
      cy="2"
      r="2"
      transform="translate(6)"
      fill={fill || "#787878"}
    />
    <circle
      id="Ellipse_263"
      data-name="Ellipse 263"
      cx="2"
      cy="2"
      r="2"
      transform="translate(12)"
      fill={fill || "#787878"}
    />
  </svg>
);
