import React from "react";
import Styled from "styled-components";
import { Postbtn } from "./userDashboard/userDashboard";
import { Elipses, Item } from "./postBody";
import { MoreHoriz } from "@material-ui/icons";
import { Avatar } from "./dashboardnav";
import { Container, Row, Col, DropdownToggle, DropdownMenu } from "reactstrap";
import { colors } from "../../color";

const Card = Styled.div`
    height: 227px;
    width: 100%;
    background: #9300b125;
    border-radius: 10px;
`;
const Span = Styled.span`
    font-size: 10px;
    color: #787878;
    text-align: center;
`;
class FeedCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropdown: false };
  }
  toggle = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };
  render() {
    return (
      <Card>
        <div className="d-flex justify-content-end align-items-center pr-3 pt-1">
          <Elipses isOpen={this.state.dropdown} toggle={this.toggle}>
            <DropdownToggle
              style={{
                background: "transparent",
                border: "none",
                outline: "none",
              }}
            >
              <MoreHoriz style={{ color: `${colors.LIGHT_GREY}` }} />
            </DropdownToggle>
            <DropdownMenu style={{
               Width: '60px !important'
            }} >
              <Item>Copy Link</Item>
              <Item>Report Post</Item>
            </DropdownMenu>
          </Elipses>
        </div>
        <Container className="pl-4 pr-5">
          <Row>
            <Col className="d-flex justify-content-between" xl={7}>
              <Row className="d-flex justify-content-between align-items">
                <Col
                  style={{ flexDirection: "column" }}
                  className="d-flex align-items-center"
                  xl={3}
                >
                  <Avatar
                    style={{ height: "80px", width: "80px" }}
                    className=" rounded-circle bg-dark mt-3 ml-3"
                  />
                </Col>
                <Col xl={9}>
                  <p className="profile-name">Akinola Doe</p>
                  <p className="text-grey" style={{ fontSize: "12px" }}>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less normal distribution of letters.
                  </p>
                </Col>
              </Row>
            </Col>
            <Col className="pt-1" xl={5}>
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between"
              >
                <p className="profile-text">
                  105
                  <br />
                  <Span>Post</Span>
                </p>
                <p className="profile-text">
                  7080 <br />
                  <Span>Followers</Span>
                </p>
                <p className="profile-text">
                  1500 <br />
                  <Span>Following</Span>
                </p>
                <p className="profile-text">
                  1500 <br />
                  <Span>Supporting</Span>
                </p>
              </div>
              <Postbtn
                className="border-radius-10"
                style={{ height: "29px", fontSize: "12px", color: "white" }}
              >
                Follow
              </Postbtn>
            </Col>
          </Row>
        </Container>
      </Card>
    );
  }
}

export default FeedCard;
