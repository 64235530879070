const EditIcon = () => {
  return (
    <svg
      id="edit"
      xmlns="http://www.w3.org/2000/svg"
      width="20.5"
      height="20.5"
      viewBox="0 0 10.5 10.5"
    >
      <path
        id="Vector"
        d="M3.938,0H3.063C.875,0,0,.875,0,3.063V5.688C0,7.875.875,8.75,3.063,8.75H5.688c2.188,0,3.063-.875,3.063-3.062V4.813"
        transform="translate(0.875 0.875)"
        fill="none"
        stroke="#490057"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="0.7"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M3.934.556.486,4A1.187,1.187,0,0,0,.2,4.581L.009,5.9a.623.623,0,0,0,.744.744L2.07,6.454a1.225,1.225,0,0,0,.578-.289L6.095,2.717a1.329,1.329,0,0,0,0-2.161A1.329,1.329,0,0,0,3.934.556Z"
        transform="translate(3.084 0.765)"
        fill="none"
        stroke="#490057"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="0.7"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0A3.126,3.126,0,0,0,2.161,2.161"
        transform="translate(6.523 1.816)"
        fill="none"
        stroke="#490057"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="0.7"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M0,0H10.5V10.5H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};
export default EditIcon;
