export const colors = {
  COMPANY_COLOR: "#490057",
  LIGHT_PURPLE: "#520661f2",
  COMPANY_COLOR_LITE: "#A303A0",
  ORANGE: "#FBB03B",
  GREY: "#787878",
  LIGHT_GREY: "#9B9B9B",
  WHITE: "#FFFFFF",
  BLACK: "#000000",
  ERROR: "#FF0000",
  BRAND_GRADIENT:
    "#0000 linear-gradient(266deg,#a303a0,#490057) 0 0 no-repeat padding-box",
};
