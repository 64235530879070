import { NotificationImportantTwoTone } from "@material-ui/icons";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader";
import useDeviceType from "../../../hooks/deviceType";
import useSettings from "../../../hooks/settings";
import NotificationOptions from "../../../utils/notificationOptions";
import NotificationItem from "./notificationItem";

const NotificationSettings = ({ secondaryTab, currentUser }) => {
  const { isMobile, isDesktop } = useDeviceType();
  const { notificationPreferences, handleNotificationPreferences } =
    useSettings();
  console.log("notif", notificationPreferences);
  console.log("user", currentUser?.social_settings?.notification);
  const [notificationSet, setNotificationSet] = useState([
    {
      value:
        currentUser?.social_settings.notification[
          "Notify me when someone likes my post"
        ],
      notificationTitle: "Notify me when someone likes my post",
      title: "Notify me when someone likes my post",
    },
    {
      value:
        currentUser?.social_settings?.notification["Notify me when follows me"],
      notificationTitle: "Notify me when someone follows me",
      title: "Notify me when follows me",
    },
    {
      value:
        currentUser?.social_settings?.notification[
          "Notify me when someone comments on my post"
        ],
      notificationTitle: "Notify me when someone comments on my post",
      title: "Notify me when someone comments on my post",
    },
    {
      value:
        currentUser?.social_settings?.notification[
          "Notify me when I receive a message"
        ],
      notificationTitle: "Notify me when I receive a message",
      title: "Notify me when I receive a message",
    },
    {
      value:
        currentUser?.social_settings?.notification[
          "Notify me when seller order request is fulfilled"
        ],
      notificationTitle: "Notify me when seller order request is fulfilled",
      title: "Notify me when seller order request is fulfilled",
    },

    {
      value:
        currentUser?.social_settings?.notification[
          "Notify me when someone tags me"
        ],
      notificationTitle: "Notify me when someone tags me",
      title: "Notify me when someone tags me",
    },
  ]);
  const [dataToSend, setDataToSend] = useState({});
  useEffect(() => {
    const data = {};
    notificationSet.map((set) => {
      data[set.title] = set.value;
    });
    setDataToSend(data);
    console.log("triggered");
  }, [notificationSet]);
  console.log("datatosend", dataToSend);
  return (
    <div className="secondaryTabContent notification">
      {isDesktop && <h4>{secondaryTab}</h4>}
      <ul className="notificationOptions">
        {notificationSet.map((notificationOption, idx) => {
          return (
            <NotificationItem
              idx={idx}
              notificationSet={notificationSet}
              setNotificationSet={setNotificationSet}
              notificationOption={notificationOption}
            />
          );
        })}
      </ul>
      <div className="save">
        <div
          onClick={() => {
            handleNotificationPreferences(dataToSend);
          }}
          className="saveButton"
        >
          {!notificationPreferences?.loading ? <span>Save</span> : <Loader />}
        </div>
      </div>
    </div>
  );
};
export default NotificationSettings;
