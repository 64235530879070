/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearDocumentCopyComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 15.082 15.082" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <path
      className="a"
      d="M9.426,4.022V5.907c0,2.514-1.005,3.519-3.519,3.519H3.519C1.005,9.426,0,8.421,0,5.907V3.519C0,1.005,1.005,0,3.519,0H5.4"
      transform="translate(1.257 4.399)"
    />
    <path
      className="a"
      d="M4.022,4.022H2.011C.5,4.022,0,3.519,0,2.011V0Z"
      transform="translate(6.661 4.399)"
    />
    <path className="a" d="M0,0H2.514" transform="translate(7.289 1.257)" />
    <path
      className="a"
      d="M0,1.885A1.883,1.883,0,0,1,1.885,0H3.532"
      transform="translate(4.399 1.257)"
    />
    <path
      className="a"
      d="M1.766,0V3.89A1.767,1.767,0,0,1,0,5.656"
      transform="translate(12.059 5.027)"
    />
    <path
      className="a"
      d="M3.77,3.77H1.885C.471,3.77,0,3.3,0,1.885V0Z"
      transform="translate(10.054 1.257)"
    />
    <path className="b" d="M0,0H15.082V15.082H0Z" />
  </svg>
);

export default VuesaxLinearDocumentCopyComponent;
