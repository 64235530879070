import React, { useContext } from "react";
import {FaTimes} from "react-icons/fa";
import "./productSuccessModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import tickIcon from "../../../assets/explore/tick-circle.svg";


export const ProductSuccessModal = () => {
  const {setProductSuccessModal, productSuccessModal} = useContext(ModalContext);

  const swapSuccessModal = () => {
    setProductSuccessModal(false)
    window.location.reload();
  }

  return (
    <>
      <div className="applySuccessModal">
        <div className='applySuccessModalWrap'>
          <div className="modalCloseWrap">
            <div onClick={() => setProductSuccessModal(false)} className="applySuccessModalClose">
              <FaTimes/>
            </div>
          </div>

          <div className="applySuccessModal-body">

            <div className="applySuccessModal-content">
              <img src={tickIcon} className="success-icon" alt=""/>

              <div className="modal-msg-title">Congratulations!</div>
              <div className="modal-msg-body">Product added to cart!</div>

              <button className="modal-cta-btn" onClick={() => swapSuccessModal()}>Done</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}