import {
  UPDATE_LIKES,
  UPDATE_SAVE,
  DELETE_POST,
  INCREMENT_COMMENT_COUNT,
  CREATE_POST,
} from "./singlePostV2.types";

export const updateLikes = (payload) => ({
  type: UPDATE_LIKES,
  payload,
});
export const updateSave = (payload) => ({
  type: UPDATE_SAVE,
  payload,
});
export const deletePost = (payload) => ({
  type: DELETE_POST,
  payload,
});
export const incrementCommentCount = (payload) => ({
  type: INCREMENT_COMMENT_COUNT,
  payload,
});
export const createSinglePost = (payload) => ({
  type: CREATE_POST,
  payload,
});
