import "./myPages.scss";
// import { CircleAdd, MoreOptionsIcon, Pin } from "../../assets/svg/Extras";
import { Search } from "../../assets/svg/NavBarIcons";
import { FaTimes, FaPlus, FaPlusCircle, FaEllipsisV } from "react-icons/fa";
import {
  Menu,
  // MenuList,
  // MenuButton,
  // MenuItem,
  // MenuItems,
  // MenuPopover,
  // MenuLink,
} from "@reach/menu-button";
import "@reach/menu-button/styles.css";
import { useHistory } from "react-router-dom";
import useCreatePage from "../../hooks/createPage";
import { useEffect } from "react";
import { DebounceInput } from "react-debounce-input";
const MyPages = () => {
  const { handleGetPages, getPagesData } = useCreatePage();
  const history = useHistory();

  useEffect(() => {
    handleGetPages({});
  }, []);

  // console.log(getPagesData.data);

  const handleSearch = (e) => {
    const { value } = e.target;
    handleGetPages({ search: value, sudo: true });
  };
  return (
    <div className="my__pages">
      <div className="my__pages__header">
        <h2>My Pages</h2>
        <div className="closeIcon">
          <span
            onClick={(e) => {
              history.push("/welcomepage");
            }}>
            {" "}
            <FaPlus />{" "}
          </span>
        </div>
      </div>
      <form className="my__pages__search">
        {/* icon to be placed */}
        <div className="search__icon">
          <Search width="18px" height="36px" fill="var(--text-grey)" />
        </div>
        <DebounceInput
          debounceTimeout={500}
          type="text"
          placeholder="Search Pages"
          onChange={handleSearch}
        />
      </form>
      <div className="existing__pages">
        {getPagesData.data?.results.map((page, idx) => (
          <div className="wrap" key={idx}>
            <div className="page">
              <div className="avatar">
                {page.featured_image && (
                  <img src={page.featured_image.file} alt="avatar" />
                )}
              </div>
              <div
                className="business__name"
                onClick={(e) =>
                  history.push(
                    `${
                      page.type.toLowerCase() === "business"
                        ? "/businessseller"
                        : "/privateseller"
                    }/profile/${page.id}`
                  )
                }>
                {page.name}
                <small>{page.type}</small>
              </div>
              <div className="icons">
                <div className="menu">
                  <Menu>
                    <FaEllipsisV />
                    {/* <MenuList>
                      <MenuItem>Download</MenuItem>
                      <MenuLink to="/">home</MenuLink>
                    </MenuList> */}
                  </Menu>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* options */}
      </div>
    </div>
  );
};
export default MyPages;
