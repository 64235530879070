export const RESET_SINGLE_POST_DATA = "RESET_SINGLE_POST_DATA",
  CREATE_SINGLE_POST_DATA = "CREATE_SINGLE_POST_DATA",
  ADD_COMMENT = "ADD_COMMENT",
  ADD_REPLY = "ADD_REPLY",
  NEW_COMMENT_LIST = "NEW_COMMENT_LIST",
  NEW_REPLY_LIST = "NEW_REPLY_LIST",
  UPDATE_SINGLE_POST = "UPDATE_SINGLE_POST",
  UPDATE_SINGLE_POST_COMMENT = "UPDATE_SINGLE_POST_COMMENT",
  UPDATE_COMMENT_LIST = "UPDATE_COMMENT_LIST",
  UPDATE_COMMENT = "UPDATE_COMMENT",
  UPDATE_POST_LIKE = "UPDATE_POST_LIKE",
  UPDATE_COMMENT_LIKE = "UPDATE_COMMENT_LIKE";
