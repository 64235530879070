/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearWarningComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 15.082 15.082" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <path className="a" d="M0,0V3.3" transform="translate(7.541 4.87)" />
    <path
      className="a"
      d="M11.418,4.135v4.3a2,2,0,0,1-.987,1.716L6.7,12.3a1.988,1.988,0,0,1-1.979,0L.987,10.149A1.979,1.979,0,0,1,0,8.433v-4.3A2,2,0,0,1,.987,2.419L4.719.264A1.988,1.988,0,0,1,6.7.264l3.733,2.155A1.987,1.987,0,0,1,11.418,4.135Z"
      transform="translate(1.829 1.257)"
    />
    <path className="b" d="M0,0H15.082V15.082H0Z" />
    <path className="a" d="M0,0V.063" transform="translate(7.541 10.18)" />
  </svg>
);

export default VuesaxLinearWarningComponent;
