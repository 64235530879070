const BookingAdminSettingItem = ({
  bookingAdminSetting,
  specificData,
  setSpecificData,
  idx,
  activeTab,
}) => {
  return (
    <li>
      <span>{bookingAdminSetting.title}</span>
      <div
        className={`switch ${bookingAdminSetting.value === false && "off"}`}
        onClick={() => {
          console.log(activeTab);
          setSpecificData({
            ...specificData,
            Booking: specificData.Booking.map((data) => {
              let indexNo = specificData.Booking.indexOf(data);
              if (indexNo === idx) {
                return data.value
                  ? { ...data, value: false }
                  : { ...data, value: true };
              }
              return data;
            }),
          });
        }}
      >
        <span
          className={`${bookingAdminSetting.value === false && "offSpan"}`}
        ></span>
      </div>
    </li>
  );
};
export default BookingAdminSettingItem;
