import React, { useState } from "react";
import "./index.scss";
import {
  FaGlobe,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaCommentAlt,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import DropdownMenu from "./dropdownMenu";
import profileImg from "../../../assets/img/Ellipse 4.png";
import Shirt from "../../../assets/img/Component 672 –1.PNG";

const ProfileBodyMV = () => {
  const [rating, setRating] = useState(0);
  const rate = 5;

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    //logic
  };

  return (
    <div className="profile-body-container">
      <div className="pbc-card-top-header">
        <div className="profile-pic-container">
          <img src={profileImg} alt="" />
        </div>
        <div className="user-info-container">
          <div className="inbox-msg">
            <FaEnvelope />
          </div>
        </div>

        <div className="user-status-section">
          <div className="detail__one">
            <h1> Jonathan Deo </h1>
            <h6>Username</h6>
          </div>
          <div className="detail__two">
            <p>
              <span>
                {" "}
                <FaMapMarkerAlt />
              </span>
              Madagaska, Bringiton, adm, Bringiton, adm.
            </p>
            <p>
              <span>
                {" "}
                <FaPhoneAlt />
              </span>
              +123 9092 90982{" "}
            </p>
          </div>
          <div className="detail__three">
            <p>
              <span>
                <FaCommentAlt />{" "}
              </span>
              Email@gmal.com
            </p>
            <p>
              <span>
                {" "}
                <FaGlobe />
              </span>
              www.website.com{" "}
            </p>
          </div>
        </div>
      </div>

      {/*----------------SELLER ITEMS ----------------------*/}
      <div className="seller-items-container">
        <div className="si-card-holder">
          <div className="si-card">
            <div className="si-card-body">
              <div className="refer-box">
                <span>
                  20% <br /> Discount
                </span>
              </div>
              <div className="si-card-heading">
                <h6>Coperate shirts for men</h6>
                <p>private seller </p>
              </div>
              <img className="seller-img" src={Shirt} alt="" />
            </div>
            <div className="si-card-footer">
              <div className="sicf-price-container">
                <p className="sicf-price">$400</p>
                <p className="sicf-price-slash">$500</p>
              </div>

              <div className="sic-footer-card">
                <DropdownMenu
                // {...{
                //   id: item.id,
                //   handleDeleteListing,
                //   handleShareListing,
                //   shareListingData,
                //   deletedListingData,
                // }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileBodyMV;
