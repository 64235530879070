import React, { useState } from "react";
import "./explore.scss";
import "../../../app.scss";
import FeedContainer from "../../../components/FeedContainer";
import AllListing from "./components/allListing/allListing";
import Products from "./components/products/products";
import Services from "./components/services/services";
import Jobs from "./components/jobs/jobs";
import avatar from "../../../assets/img/Ellipse 4.png";
import { connect } from "react-redux";
// import {
//   FaTimes,
//   FaEnvelope,
//   FaFlag,
//   FaEnvelopeOpen,
//   FaStar,
//   FaTrashAlt,
//   FaPlus,
//   FaSearch,
//   FaEllipsisH,
//   FaSmile,
//   FaPaperPlane,
//   FaPaperclip,
//   FaShoppingCart,
// } from "react-icons/fa";
import ReusableDropDown from "../../../components/reusableDropdown";
import {toggleRightBar} from "../../../appstore/navigationDisplayToggle/index.action";

const Explore = () => {
  const [category, setCategory] = useState("all");
  console.log(category);
  const options = [
    "Best Match",
    "Price [Low - High]",
    "Price [High - Low]",
    "Highest Rated]",
  ];
  const [rightSide, setRightSide] = useState(true);
  const toggleRightSide = () => {
    console.log(rightSide)
    setRightSide(!rightSide);
  }

  const turnOnRightSide = () => {
    setRightSide(true);
  }

  const switchTabs = (tab) => {
    if (tab.category !== category.category) {
      setCategory(tab)
      setRightSide(true);
    }
  }

  return (
    <>
      <FeedContainer>
        <section className={`explore ${rightSide ? '' : 'no-right'}`}>
          <div className="tabs-box">
            <div className={`explore-tabs ${rightSide ? '' : 'no-right'}`}>
              <div
                  className={`tab-single ${
                      category.category === "all" || category === "all"
                          ? "active"
                          : ""
                  }`}
                  onClick={() => switchTabs({ category: "all" })}>
                <h5>All listings</h5>
              </div>

              <div
                  className={`tab-single ${
                      category.category === "products" ? "active" : ""
                  }`}
                  onClick={() => switchTabs({ category: "products" })}>
                <h5>Products</h5>
              </div>

              <div
                  className={`tab-single ${
                      category.category === "services" ? "active" : ""
                  }`}
                  onClick={() => switchTabs({ category: "services" })}>
                <h5>Services</h5>
              </div>

              <div
                  className={`tab-single ${
                      category.category === "jobs" ? "active" : ""
                  }`}
                  onClick={() => switchTabs({ category: "jobs" })}>
                <h5>Jobs</h5>
              </div>
            </div>
          </div>

          {/*<div className="sort">*/}
          {/*  <p className="sort-label">Sort</p>*/}
          {/*  <ReusableDropDown*/}
          {/*    options={options}*/}
          {/*    style={{ minWidth: 180, zIndex: 2 }}*/}
          {/*  />*/}

            {/*<select className="sort-select">*/}
            {/*  <option value="">Best Match</option>*/}
            {/*  <option value="">Price [Low - High]</option>*/}
            {/*  <option value="">Price [High - Low]</option>*/}
            {/*  <option value="">Highest Rated]</option>*/}
            {/*</select>*/}
          {/*</div>*/}

          <div className="">
            {/*<AllListing/>*/}
            {category.category === "all" || category === "all" ? <AllListing action={toggleRightSide}/> : null}
            {category.category === "products" ? <Products action={toggleRightSide}/> : null}
            {category.category === "services" ? <Services action={toggleRightSide}/> : null}
            {category.category === "jobs" ? <Jobs action={toggleRightSide}/> : null}
          </div>
        </section>
      </FeedContainer>
    </>
  );
};

export default Explore;
