import React from "react";
// import "./allItems.scss";
import haircut from "../../../../../../../assets/explore/haircut.jpg";
import ExploreCard from '../../../../../../../components/cards/exploreCards';


const AllItems = (props) => {
  const bagUrl = "https://static7.depositphotos.com/1029541/705/i/950/depositphotos_7054712-stock-photo-shopping-bag.jpg";

  const renameProductType = (type) => {
    switch(type) {
      case "product":
        return "Product";
      case "service":
        return "Service";
      case "jobpost":
        return "Job";
      default:
        return "Product";
    }
  }

  const genCtaText = (type) => {
    switch(type) {
      case "product":
        return "Buy Now";
      case "service":
        return "Book Now";
      case "jobpost":
        return "Apply Now";
      default:
        return "Buy Now";
    }
  }


  return (
    <>
      {
        props?.items?.results?.length > 0 &&
        props?.items?.results?.map((product, id) => (
          <>
            <ExploreCard
              key={id}
              img={product?.gallary[0]?.file ? product?.gallary[0]?.file : bagUrl}
              title={product.title}
              location={product.location ? product.location.address : "Unknown"}
              type={renameProductType(product.data_type)}
              isReferrable={product.can_refer}
              discount={product.discount ? product.discount : null}
              ctaText={genCtaText(product.data_type)}
              price={product.deposit_amount ? product.deposit_amount : null}
              origin="AllListings"
              action={() => props.productAction(product)}
            />
          </>
        ))
      }
    </>
  )
}

export default AllItems;