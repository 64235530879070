const rebuild = (data = {}) => {
  if (data) {
    let keys = Object.keys(data);
    return keys.map((key) => ({
      value: data[key],
      title: key,
    }));
  }
};

export default rebuild;
