import "./style.scss";
import { IoMdClose } from "react-icons/all";
import { useMemo } from "react";
const SelectedPersons = ({ data = [], onRemove = () => {} }) => {
  const maxCount = useMemo(() => 5, []);

  return (
    <div className="selected-persons">
      {data.slice(0, maxCount).map((person) => {
        return (
          <div className="persons" key={person.id}>
            {person.first_name} {person.last_name}
            <div
              className="cancel-persons"
              onClick={onRemove.bind(this, person.id)}>
              <IoMdClose />
            </div>
          </div>
        );
      })}
      {data.length > maxCount && (
        <div className="persons">+{data.length - maxCount}</div>
      )}
    </div>
  );
};
export default SelectedPersons;
