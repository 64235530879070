import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  FaGlobe,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaCommentAlt,
} from "react-icons/fa";
// import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import { useHistory, useParams } from "react-router-dom";
import { Rating, RatingView } from "react-simple-star-rating";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
// import profileImg from "../../../assets/img/Ellipse 4.png";
// import Shirt from "../../../assets/img/Component 672 –1.PNG";
import greeting from "../../../utils/greeting";
import TimelineTab from "./timelineTab";
import ListingTab from "./listingTab";
import JobsTab from "./jobsTab";
import qs from "query-string";
import usePages from "../../../hooks/createPage";
import numberFormat from "format-number";
import { useSelector } from "react-redux";
import BackArrow from "../../../assets/svg/backArrow";

const BsProfileBody = () => {
  const history = useHistory();
  const {
    singlePageData,
    handleGetSinglePage,
    // user,
    handlePageSupport,
    supportData,
    makeReviewData,
    handleCreatePageReview,
    handleView,
  } = usePages();
  const { data } = singlePageData;
  const [supporting, setSupporting] = useState(false);
  const { pageId: id } = useParams();
  const [rating, setRating] = useState(0);
  const { user } = useSelector((state) => state.currentUser);
  // const rate = 4.5;
  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);
    //logic

    handleCreatePageReview({ rating: rate, page: id, body: "nice" });
  };
  const [profileTab, setProfileTab] = useState("timeline");
  // console.log(id);
  useEffect(() => {
    handleGetSinglePage(id);
    handleView(id);
  }, [id]);

  useEffect(() => {
    if (supportData.data) {
      setSupporting(supportData.data.status);
    }
  }, [supportData.data]);

  useEffect(() => {
    if (makeReviewData.data) {
      setRating(makeReviewData.data.rating);
    }
    if (singlePageData.data) {
      setRating(singlePageData.data.rating);
    }

    console.log(singlePageData.data, makeReviewData.data);
  }, [makeReviewData.data, singlePageData.data]);
  return id ? (
    singlePageData.data ? (
      <div className="bs-profile-body-container">
        <h1 className="welcome-title">
          <span
            onClick={history.goBack}
            style={{ cursor: "pointer", marginRight: "20px" }}>
            <BackArrow />
          </span>
          {"   "}
          {greeting()} <span>{singlePageData.data.name.split(" ")[0]} </span>
        </h1>
        <div className="pbc-card">
          <h5 className="sub-title"> Profile</h5>
          <div className="pbc-card-top-header">
            <div className="profile-pic-container">
              {data.featured_image && (
                <img src={data.featured_image.file} alt="" />
              )}
            </div>
            <div className="user-info-container">
              <div className="detail__one">
                <h1> {data.name} </h1>
                <h6>@{data.username}</h6>
              </div>
              <div className="detail__two">
                <p>
                  <span>
                    {" "}
                    <FaMapMarkerAlt />
                  </span>
                  {data.location?.address || "No location!"}
                </p>
                <p>
                  <span>
                    {" "}
                    <FaPhoneAlt />
                  </span>
                  {data.phone_number}{" "}
                </p>
              </div>
              <div className="detail__three">
                <p>
                  <span>
                    <FaCommentAlt />{" "}
                  </span>
                  {data.email}
                </p>
                <p>
                  <span>
                    {" "}
                    <FaGlobe />
                  </span>
                  {data.website}{" "}
                </p>
              </div>
              {singlePageData.data?.owner !== user.id && (
                <div className="inbox-msg">
                  <button
                    className="btn-accept btn-green"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      transition: "0.5s all",
                      ...(data.is_supporting && {
                        background: "var(--bg-white)",
                        color: "var(--dark-purple-bg)",
                      }),
                      ...(supporting && {
                        background: "var(--bg-white)",
                        color: "var(--dark-purple-bg)",
                      }),
                    }}
                    onClick={(e) => handlePageSupport(id)}>
                    {data.is_supporting || supporting
                      ? "Supporting"
                      : "Support"}
                  </button>
                </div>
              )}
            </div>
            <div className="user-status-section">
              <div className="status-content">
                <h5> {data.description}</h5>
              </div>

              <div className="profile-rating-container">
                <div className="cpb-container">
                  <CircularProgressbarWithChildren value={rating} maxValue={5}>
                    <h4>{`${rating.toFixed(1)}`}</h4>
                    <p> out of 5 </p>
                  </CircularProgressbarWithChildren>
                </div>
                <div className="rating-bar-container">
                  <div className="rbc-star">
                    <Rating
                      onClick={handleRating}
                      ratingValue={rating}
                      size={20}
                      label
                      transition
                      fillColor="orange"
                      emptyColor="gray"
                      className="rating-star"
                    />

                    <h6>{rating.toFixed(1)}</h6>
                  </div>
                  <div className="rbc-review">
                    <p>{numberFormat()(data.review_count)} Review</p>
                  </div>
                </div>
              </div>

              {/* <div className="msg-end-box">
                <div className="inbox-msg">
                  <FaEnvelope />
                </div>
                <div>
                  <button className="btn-accept btn-yellow">Book</button>
                  <button className="btn-accept">Queue</button>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="profileTabBody">
          <div className="profileTabs">
            <p
              className={`${profileTab === "timeline" ? "active" : ""}`}
              onClick={() => setProfileTab("timeline")}>
              Timeline
            </p>
            <p
              className={`${profileTab === "listing" ? "active" : ""}`}
              onClick={() => setProfileTab("listing")}>
              Listing
            </p>
            <p
              className={`${profileTab === "jobs" ? "active" : ""}`}
              onClick={() => setProfileTab("jobs")}>
              Jobs
            </p>
          </div>
          <div className="tab-content">
            {profileTab === "timeline" && (
              <TimelineTab pageID={id} isOwn={data?.owner === user.id} />
            )}
            {profileTab === "listing" && <ListingTab pageID={id} />}
            {profileTab === "jobs" && <JobsTab pageID={id} />}
          </div>
        </div>
      </div>
    ) : singlePageData.loading ? (
      <div>loading...</div>
    ) : (
      "empty!"
    )
  ) : (
    history.goBack()
  );
};

export default BsProfileBody;
