import { useContext, useEffect, useState, useRef, useCallback } from "react";
import "./createbookingModal.scss";
import { FaChevronDown } from "react-icons/fa";
import ModalContext from "../../../hooks/modalContexts";
import CloseBtn from "../../../components/closeBtn";
import GoogleMapReact from "google-map-react";
// import GooglePlacesAutocomplete from "react-google-places-autocomplete";
// import GooglePlacesAutocomplete from "react-google-autocomplete";
import config from "../../../config/config.json";
import GoogleMaps from "../../maps";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import useGetLocation from "../../../hooks/getLocation";
import { HiLocationMarker } from "react-icons/all";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";
import { showToast } from "../../ReactToast";
import toast, { Toaster } from "react-hot-toast";
import { ElectricalServicesOutlined } from "@mui/icons-material";

const BookingLocationModal = () => {
  const { setBookingLocationModal, serviceLocationList } =
    useContext(ModalContext);

  const [serviceLocations, setServiceLocations] = serviceLocationList;
  const [city, setCity] = useState(null);
  const [similarCity, setSimilarCity] = useState(false);
  const [cityAddress, setCityAddress] = useState(null);
  const [formError, setFormError] = useState("");
  const [locationError, setLocationError] = useState(false);
  const { state, err, setState } = useGetLocation();
  console.log("this is ", err);
  console.log("this is a", state);
  console.log("this is a locationError", locationError);
  const [markers, setMarkers] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: config["map-key"],
    libraries: ["places"],
  });

  const [serviceLocationForm, setServiceLocationForm] = useState({
    name: "",
    image: "",
    address: cityAddress,
    point: {
      type: "Point",
      coordinates: [],
    },
  });

  console.log(cityAddress);
  console.log("A lng", state?.coords?.longitude);
  console.log("A service location", serviceLocationForm);

  const locationDropdownItem = [
    { label: "Geo Fencing" },
    { label: "City Wide" },
  ];

  const [selectedItem, setSelectedItem] = useState(
    locationDropdownItem[0].label
  );
  const [isOpen, setOpen] = useState(false);
  const toggleDropdown = (e) => setOpen(!isOpen);

  const handleSubmitServiceLocation = (e) => {
    e.preventDefault();
    setFormError("");
    const searchWord = e;
    const searchWord2 = cityAddress?.split(" ")?.slice(-2)?.join(" ");

    const newFilter2 = serviceLocations?.filter((location) => {
      return location.address.includes(searchWord2);
    });

    newFilter2?.forEach((location) => {
      if (
        location?.address?.length !== cityAddress?.address?.length &&
        location?.address === searchWord2
      ) {
        setLocationError(true);
        showToast(
          "error",
          `you cant add a place in this city ${searchWord2} it already exists`
        );
      } else if (
        location?.address?.length !== e?.address?.length &&
        cityAddress === searchWord2
      ) {
        setLocationError(true);
        showToast(
          "error",
          `you cant add this city ${searchWord2} some places already exists`
        );
      }
      // else if (location?.address === cityAddress) {
      //   setLocationError(true);
      //   showToast("error", `${cityAddress} already exists`);
      // }
      // else if (
      //   location?.address?.length === e?.address?.length &&
      //   location?.address === e?.address
      // ) {
      //   setLocationError(true);
      //   showToast(
      //     "error",
      //     `you cant add a place in this city ${searchWord2} it already exists`
      //   );
      // }
      else if (
        location?.address?.length !== e?.address?.length &&
        location?.address !== searchWord2
      ) {
        setLocationError(false);
      } else if (
        location?.address?.length !== e?.address?.length &&
        searchWord !== searchWord2
      ) {
        setLocationError(false);
      }
    });

    if (serviceLocationForm.address === null) {
      setLocationError(true);
      setFormError("Please enter address");
      showToast("error", "please set service location");
    } else {
      !locationError &&
      setServiceLocations([...serviceLocations, serviceLocationForm]);    }

    if (newFilter2.length === 0) {
      setLocationError(false);
    }
   
  };
console.log(formError)
  useEffect(() => {
    if (formError.length) {
      setTimeout(() => {
        setFormError("");
      }, 3000);
    }
  }, [formError]);

  const onAddressChange = (e) => {
    setServiceLocationForm({
      ...serviceLocationForm,
      address: e,
    });
  };

  const handleFilter = (e) => {
    const searchWord = e;
    const searchWord2 = e.split(" ").slice(-2).join(" ");
    const searchWord3 = e.split(" ").slice(-3).join(" ");
    // const newFilter = serviceLocations.filter((location) => {
    //   return location.address.includes(searchWord2).split(" ", -3);
    // });

    const newFilter2 = serviceLocations.filter((location) => {
      return location.address.includes(searchWord2);
    });

    // if (newFilter2.length > 0) {
    //   showToast("error", `${searchWord2} already exists`);
    // } else console.log("===includes", false);

    newFilter2?.forEach((location) => {
      if (
        location?.address?.length !== e?.address?.length &&
        location?.address === searchWord2
      ) {
        setLocationError(true);
        console.log("true1");
        showToast(
          "error",
          `you cant add a place in this city ${searchWord2} it already exists`
        );
      } else if (
        location?.address?.length !== e?.address?.length &&
        searchWord === searchWord2
      ) {
        setLocationError(true);
        console.log("true2");
        showToast(
          "error",
          `you cant add this city ${searchWord2} some places already exists`
        );
      }
      // else if (location?.address === searchWord) {
      //   setLocationError(true);
      //   console.log("true3")
      //   showToast("error", `${searchWord} already exists`);
      // } else if (
      //   location?.address?.length === e?.address?.length &&
      //   location?.address === e?.address
      // ) {
      //   setLocationError(true);
      //   console.log("true4")
      //   showToast(
      //     "error",
      //     `you cant add a place in this city ${searchWord2} it already exists`
      //   );
      //   // setLocationError(false);
      // }
      else if (
        location?.address?.length !== e?.address?.length &&
        location?.address !== searchWord2
      ) {
        setLocationError(false);
      } else if (
        location?.address?.length !== e?.address?.length &&
        searchWord !== searchWord2
      ) {
        setLocationError(false);
      }
    });
    if (newFilter2.length === 0) {
      setLocationError(false);
    }
    console.log("===searchWord2", searchWord2);
    console.log("===searchWord3", searchWord3);
    // console.log("===newFilter", newFilter);
    console.log("===newFilter2", newFilter2);
  };

  console.log("===setLocationError", locationError);

  return (
    <div
      onClick={(e) => setBookingLocationModal(false)}
      className="cbModalContainer"
    >
      <div className="cbModal-content">
        <div
          onClick={(e) => setBookingLocationModal(false)}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="cbModalContainered"
        >
          <div className="cbModal-header">
            <h4> Set Service Address </h4>
          </div>
          <div className="cbModal-body">
            <Toaster />
            <form>
              <div className="top-address-location">
                <p> Set Location </p>
                <div className="tal-add-location">
                  {!isLoaded && <p className="loading-txt">Loading...</p>}

                  {isLoaded && (
                    <>
                      <div className="tal-set-location">
                        {/* <input
                      className="form-input"
                      type="text"
                      name="address"
                      value={serviceLocationForm.address || ""}
                      placeholder="Los Angeles"
                      onChange={handleLocationInputChange}
                    /> */}

                        <>
                          <Autocomplete
                            apiKey={config["map-key"]}
                            selectProps={{
                              cityAddress,
                              onChange: async ({ label }) => {
                                onAddressChange(label);
                                handleFilter(label);
                                console.log(label);
                                const data = await geocodeByAddress(label);
                                const { lat, lng } = await getLatLng(data[0]);
                                setState({
                                  ...state,
                                  coords: {
                                    ...state.coords,
                                    longitude: lng,
                                    latitude: lat,
                                  },
                                });
                                setServiceLocationForm({
                                  ...serviceLocationForm,
                                  address: label,
                                  point: {
                                    type: "Point",
                                    coordinates: [lat, lng],
                                  },
                                });
                                setCityAddress(label);
                                setCity({ lat, lng });
                                console.log(city);
                              },
                              // setCity,
                            }}
                            onLoadFailed={(error) =>
                              console.error(
                                "Could not inject Google script",
                                error
                              )
                            }
                          />
                        </>

                        {/* <small className="error">{formError}</small> */}
                      </div>
                      <div className="tal-select-location">
                        {/* <div className="dropbox">
                          <div className="dropdown-container">
                            <div className="dd-dropdown">
                              <div className="dropdown">
                                <div
                                  className="dropdown-header"
                                  onClick={toggleDropdown}
                                >
                                  {selectedItem}

                                  <FaChevronDown className="dropdown-icon" />
                                </div>
                              </div>
                              {isOpen && (
                                <div className="dropdown-body ">
                                  <div
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      setSelectedItem(
                                        locationDropdownItem[0].label
                                      );
                                      setOpen();
                                    }}
                                  >
                                    Geo Fencing
                                  </div>
                                  <div
                                    className="dropdown-item"
                                    onClick={(e) => {
                                      setSelectedItem(
                                        locationDropdownItem[1].label
                                      );
                                      setOpen();
                                    }}
                                  >
                                    City Wide
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="map-container">
                <div className="map-select">
                  {/* <>
                  {state?.coords && (
                    <GoogleMapReact
                      bootstrapURLKeys={{
                        key: config["map-key"],
                        libraries: ["places", "geometry"],
                      }}
                      center={{
                        lat: state?.coords?.latitude,
                        lng: state?.coords?.longitude,
                      }}
                      defaultZoom={13}
                      // yesIWantToUseGoogleMapApiInternals={true}
                      // onGoogleApiLoaded={({ map, maps }) => {
                      //   setMapAPI([map, maps]);
                      //   console.log(map, maps)
                      // }}
                      // onClick={handleAddMarkers}
                    >
                      <></>
                      {/* {markers?.map((data, idx) => {
                        return (
                          <HiLocationMarker
                            size={18}
                            className="selected-location"
                            key={idx}
                            lng={data.lng}
                            lat={data.lat}
                            onClick={(e) => {
                              const newMarker = markers
                                .map((marker) => marker !== data && marker)
                                .filter((marker) => marker && marker);
                              setMarkers(newMarker);
                              // onAddMarker(
                              //   newMarker.map(({ lat, lng }) => [lng, lat])
                              // );
                            }}
                          />
                        );
                      })} 
                    </GoogleMapReact>
                  )}
                  </> */}

                  {isLoaded && (
                    <>
                      {state?.coords && (
                        <GoogleMap
                          center={{
                            lat: state?.coords?.latitude,
                            lng: state?.coords?.longitude,
                          }}
                          zoom={13}
                          mapContainerClassName="map-select"
                        >
                          {city && <Marker position={city} />}
                        </GoogleMap>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="footer-btn">
                {/* <button
                  className="locate"
                  onClick={() => {
                    navigator.geolocation.getCurrentPosition(
                      (position) => {
                        panTo({
                          lat: position.coords.latitude,
                          lng: position.coords.longitude,
                        });
                      },
                      () => null
                    );
                  }}
                >
                  <img src="/compass.svg" alt="compass" />
                  Locate
                </button> */}
                <button
                  type="submit"
                  // style={{ cursor: locationError && "not-allowed" }}
                  className={"btn-post"}
                  // disabled={locationError}
                  onClick={handleSubmitServiceLocation}
                >
                  Done
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingLocationModal;
