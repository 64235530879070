import useAxios from "axios-hooks";
const useGallery = () => {
  const [{ ...galleryData }, getGallery] = useAxios(
    {
      url: "/uploads/grouped_list/",
      method: "GET",
    },
    { manual: true }
  );

  const handleGetData = ({
    next,
    limit_grp = 3,
    offset_grp = 0,
    created_at_gte,
    created_at_lte,
    type,
  }) => {
    getGallery({
      ...(next && { url: next }),
      ...(!next && {
        params: {
          limit_grp,
          offset_grp,
          created_at_gte,
          created_at_lte,
          type,
        },
      }),
    });
  };
  const [{ ...deleteMediaData }, deleteMedia] = useAxios(
    {
      url: "/uploads/bulk/",
      method: "delete",
    },
    {
      manual: true,
    }
  );

  return {
    galleryData,
    handleGetData,
    deleteMedia,
    deleteMediaData,
  };
};

export default useGallery;
