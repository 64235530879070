import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.scss";
import {
  FaSearch,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../assets/img/shopImg.png";
import "react-datepicker/dist/react-datepicker.css";
import { FaSortAmountDownAlt, FaCalendarAlt } from "react-icons/fa";
import CustomCheckBox from "../../../components/customCheckBox";
import CustomCheckBoxTop from "./TopCheck";
import NumberInput from "./NumberInput/numberComponent";
import { IoCloseCircleOutline, MdModeEdit } from "react-icons/all";
import {
  IoMdClose,
  FaLuggageCart,
  ImCheckboxChecked,
  ImCheckboxUnchecked,
  IoMdCloseCircleOutline,
  RiCheckboxBlankLine,
  RiCheckboxBlankFill,
} from "react-icons/all";
import useCarts from "../../../hooks/carts";
import moment from "moment";

const MiddleCart = () => {
  const [checkAll, setCheckAll] = useState("false");
  const [checkOne, setCheckOne] = useState("false");
  const [checkTwo, setCheckTwo] = useState("false");
  const [checkThree, setCheckThree] = useState("false");

  const {
    cartListData,
    handleCartList,
    handleDeleteBookingCart,
    handleUpdateCartQuantity,
  } = useCarts();

  useEffect(() => {
    handleCartList();
    console.log(cartListData?.data);
  }, []);
  const handleCheckAll = () => {
    setCheckAll(!checkAll);
  };

  const handleCheckOne = () => {
    setCheckOne(!checkOne);
  };

  const handleCheckTwo = () => {
    setCheckTwo(!checkTwo);
  };

  return (
    <>
      {!cartListData.loading && !cartListData.error && cartListData?.data && (
        <div className="cart-container middle-cart">
          <div className="cart-hearder">
            <span onClick={(e) => handleCheckAll()}>
              {!checkAll ? <RiCheckboxBlankFill /> : <RiCheckboxBlankLine />}
            </span>
            <div className="top-text">
              <p>Seller</p>
              <h3>
                {" "}
                <img src={profileImg} className="seller-img" /> Username
              </h3>
            </div>
          </div>
          <div className="cart">
            <div className="cart-body-container">
              <div className="hbc-card-body">
                {/*--------- CARD CONTENTS ------------------*/}
                <div className="card-cb-contents">
                  {cartListData?.data?.results?.map((cart, idx) => (
                    <>
                      {cart?.service && (
                        <div className="hbc-cbc-card">
                          <div className="hbc-cbcc-body">
                            <div className="hcb-view-two">
                              <div className="checkbox">
                                <span onClick={(e) => handleCheckOne()}>
                                  {!checkOne || !checkAll ? (
                                    <RiCheckboxBlankFill />
                                  ) : (
                                    <RiCheckboxBlankLine />
                                  )}
                                </span>
                              </div>
                              <div className="cbcc-img-holder">
                                <img
                                  src={cart?.service?.gallary[0].file}
                                  alt=""
                                />
                              </div>
                              <div className="cbcc-item-detail-one">
                                <h4>{cart?.service?.title} </h4>
                                <h5>
                                  Price: <span>${cart?.price}</span>
                                </h5>
                              </div>
                            </div>
                            <div className="card-view-three">
                              <div className="cbcc-item-detail-two">
                                <h4 className="date-posted">Booking date </h4>
                                <h5>
                                  {moment(cart?.time_slot?.opening_time).format(
                                    "Do MMM YYYY, h:mm a"
                                  )}
                                </h5>
                              </div>
                            </div>
                            <div className="hcb-view-five border-green">
                              <div className="cbcc-item-detail-two">
                                <h4>Service Location</h4>
                                <h5>{`${cart?.delivery_location?.address} `}</h5>
                              </div>
                              <div className="cancel-edit">
                                {/* <span className="cart-cancel">
                        <MdModeEdit />
                      </span> */}
                                <span
                                  className="cart-edit"
                                  onClick={() =>
                                    handleDeleteBookingCart(cart.id)
                                  }
                                >
                                  <IoCloseCircleOutline />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MiddleCart;
