/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearPeopleComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-620 -252)">
      <path
        className="a"
        d="M2.67,5.16a.605.605,0,0,0-.19,0,2.585,2.585,0,1,1,.19,0Z"
        transform="translate(635.33 254)"
      />
      <path
        className="a"
        d="M.03,4.94a5.635,5.635,0,0,0,3.94-.72A1.911,1.911,0,0,0,3.97.8,5.67,5.67,0,0,0,0,.09"
        transform="translate(636.94 261.5)"
      />
      <path
        className="a"
        d="M2.49,5.16a.605.605,0,0,1,.19,0,2.585,2.585,0,1,0-.19,0Z"
        transform="translate(623.48 254)"
      />
      <path
        className="a"
        d="M5,4.94a5.635,5.635,0,0,1-3.94-.72,1.911,1.911,0,0,1,0-3.42A5.67,5.67,0,0,1,5.027.09"
        transform="translate(622.003 261.5)"
      />
      <path
        className="a"
        d="M2.67,5.16a.605.605,0,0,0-.19,0,2.585,2.585,0,1,1,.19,0Z"
        transform="translate(629.33 261.47)"
      />
      <path
        className="a"
        d="M1.058.795a1.911,1.911,0,0,0,0,3.42,5.677,5.677,0,0,0,5.82,0,1.911,1.911,0,0,0,0-3.42A5.723,5.723,0,0,0,1.058.795Z"
        transform="translate(628.032 268.985)"
      />
      <path className="b" d="M0,0H24V24H0Z" transform="translate(620 252)" />
    </g>
  </svg>
);

export default VuesaxLinearPeopleComponent;
