
const SuccessThick = () => {
  return(
    <svg id="tick-circle" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 109 109">
      <path id="Vector" d="M45.417,90.833A45.417,45.417,0,1,0,0,45.417,45.55,45.55,0,0,0,45.417,90.833Z" transform="translate(9.083 9.083)" fill="none" stroke="#28be55" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
      <path id="Vector-2" data-name="Vector" d="M0,12.853,12.853,25.706,38.6,0" transform="translate(35.198 41.647)" fill="none" stroke="#28be55" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
      <path id="Vector-3" data-name="Vector" d="M0,0H109V109H0Z" fill="none" opacity="0"/>
    </svg>
  )
}

export default SuccessThick
