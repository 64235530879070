export const RESET_SINGLE_COMMENT_DATA = "RESET_SINGLE_COMMENT_DATA",
  CREATE_SINGLE_COMMENT_DATA = "CREATE_SINGLE_COMMENT_DATA",
  ADD_COMMENT = "ADD_COMMENT",
  ADD_REPLY = "ADD_REPLY",
  NEW_COMMENT_LIST = "NEW_COMMENT_LIST",
  NEW_REPLY_LIST = "NEW_REPLY_LIST",
  UPDATE_SINGLE_COMMENT = "UPDATE_SINGLE_COMMENT",
  UPDATE_SINGLE_COMMENT_COMMENT = "UPDATE_SINGLE_COMMENT_COMMENT",
  UPDATE_REPLY_LIST = "UPDATE_REPLY_LIST",
  UPDATE_REPLY = "UPDATE_REPLY";
