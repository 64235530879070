import React, { useState } from "react";
import { Col, Row, Container, Card, TabContent, TabPane } from "reactstrap";
import {
  Tabs,
  Tab as ExploreTab,
  makeStyles,
  withStyles,
  Typography,
} from "@material-ui/core";
import {
  GpsFixed,
  ShoppingCart,
  BusinessCenter,
  AccountBalance,
  SentimentVerySatisfied,
} from "@material-ui/icons";
import Styled from "styled-components";
import { Header } from "../styles";
import { colors } from "../color";

// images
import addcircles from "../assets/icons/addcircle.png";
import image1 from "../assets/icons/image1.png";
import image2 from "../assets/icons/image2.png";
import image3 from "../assets/icons/image3.png";
import image4 from "../assets/icons/image4.png";
import image5 from "../assets/icons/image5.png";
import image6 from "../assets/icons/image6.png";
import image7 from "../assets/icons/image7.png";
import image8 from "../assets/icons/image8.png";
import image9 from "../assets/icons/image9.png";
import image10 from "../assets/icons/image10.png";

// responsive
import media from "../responsive";
import "../components/forms/index.css";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  tabText: {
    color: `${colors.COMPANY_COLOR}`,
    "&focused": {
      // color: 'red'
    },
    focused: {},
  },
}));

const CardText = Styled.p`
    color: ${colors.COMPANY_COLOR};
    font-weight: 500;
    font-size: 14px;
`;
const ExploreCard = Styled(Card)`
    width: 230px;
    height: 250px;
    border-radius: 12px;
    border: none;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 9px 15px;
`;

const CardBtn = Styled.img`
    cursor: pointer;
    transition: all ease 0.3s;
    width: 17px;
    &:hover {
        transform: scale(1.1);
    }
`;
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: `${colors.LIGHT_GREY}`,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      color: `${colors.COMPANY_COLOR}`,
    },
  },
}))((props) => <ExploreTab disableRipple {...props} />);

const SubDiv = Styled.div`
    width: 35%;
    ${media.mobile`
        width: 90%;
        text-align: center;
    `}
`;

const CardImg = Styled.img`
    width: 100%;
    object-fit: cover;
`;
const cardData = [
  { img: image1 },
  { img: image3 },
  { img: image2 },
  { img: image4 },
  { img: image5 },
  { img: image6 },
  { img: image7 },
  { img: image8 },
  { img: image9 },
  { img: image10 },
];

const ExplorePage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();

  const handleChange = (e, val) => {
    setActiveTab(val);
  };
  return (
    <React.Fragment>
      <Col className="m-auto" style={{ width: "95%" }}></Col>
      <Row className="mt-5">
        <Col xl={12}>
          <div>
            <Header className="text-center" style={{ fontSize: "50px" }}>
              {" "}
              Explore your interest
            </Header>
          </div>
        </Col>
        <Col xl={12}>
          <SubDiv className="m-auto">
            <p
              style={{ fontWeight: "500", color: `${colors.COMPANY_COLOR}` }}
              className="text-center">
              Explore lots of interests and opportunities around you, Connect
              and have fun the easy way
            </p>
          </SubDiv>
        </Col>
        <Col className="m-auto" xl={9}>
          <div className="mt-4 ">
            <Tabs
              TabIndicatorProps={{
                style: { background: `${colors.COMPANY_COLOR}`, height: "4px" },
              }}
              variant="standard"
              centered
              onChange={handleChange}
              value={activeTab}
              style={{
                borderBottom: `2px solid ${colors.GREY}`,
                width: "100%",
              }}>
              <StyledTab
                className={classes.tabText}
                label={
                  <div className="d-flex justify-content-between">
                    <SentimentVerySatisfied />
                    Interest
                  </div>
                }
              />
              <StyledTab
                className={classes.tabText}
                label={
                  <div>
                    <GpsFixed /> Location
                  </div>
                }
              />
              <StyledTab
                className={classes.tabText}
                label={
                  <div>
                    <BusinessCenter />
                    Jobs around you
                  </div>
                }
              />
              <StyledTab
                className={classes.tabText}
                label={
                  <div>
                    <AccountBalance /> Business
                  </div>
                }
              />
              <StyledTab
                className={classes.tabText}
                label={
                  <div>
                    <ShoppingCart />
                    Products
                  </div>
                }
              />
            </Tabs>
            <Typography className={classes.padding} />
          </div>
        </Col>
        <Col xl={12}>
          {/* the interest stuff */}
          <TabContent activeTab={activeTab}>
            <TabPane tabId={0}>
              <Row
                className="m-auto"
                style={{
                  width: "90%",
                  overflowY: "scroll",
                  scrollbarWidth: "thin",
                  scrollbarGutter: "revert",
                }}>
                {cardData.map((data, i) => (
                  <div key={i} className="m-auto d-flex justify-content-center">
                    <Col
                      className="mb-5  mr-2 d-flex justify-content-center"
                      xl={2.1}>
                      <ExploreCard>
                        <Container
                          className="justify-content-between align-items-center"
                          style={{ display: "flex", flexDirection: "column" }}>
                          <div
                            className="mt-2"
                            style={{
                              height: "190px",
                              width: "210px",
                              borderRadius: "12px",
                            }}>
                            <CardImg src={data.img} alt="" />
                          </div>
                        </Container>
                        <Container>
                          <div className="mt-2 d-flex justify-content-between align-items-center">
                            <CardText className="mt-2">Art</CardText>
                            <CardBtn src={addcircles} alt="" />
                            {/* <button className="rounded-circle" style={{height: "19px", width: '19px', border: 'none', background: `${colors.ORANGE}`}}><i className="fa fa-plus" aria-hidden="true"></i></button> */}
                          </div>
                        </Container>
                      </ExploreCard>
                    </Col>
                  </div>
                ))}
              </Row>
            </TabPane>
          </TabContent>
        </Col>
      </Row>
    </React.Fragment>
  );
};
export default ExplorePage;
