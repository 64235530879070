import React from "react";
import "./resetPassword.scss";
import { connect } from "react-redux";
import NavBar from "../../components/layout/navbar";
import logo2 from "../../assets/icons/logo2.png";
import { colors } from "../../color";
import { useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import useAuth from "../../hooks/auth";
import { useEffect } from "react";
import Loader from "../../components/loader";
const ForgotPassword = () => {
  const { search } = useLocation();
  const [data, setData] = useState({
    uid: new URLSearchParams(search).get("uid"),
    token: new URLSearchParams(search).get("token"),
    email: new URLSearchParams(search).get("email"),
  });

  const history = useHistory();
  const { handlePasswordChange, changePassData } = useAuth();
  const [error, setError] = useState({});

  useEffect(() => {
    if (changePassData.error) {
      setError(changePassData.error.response.data);
      setTimeout(() => {
        setError({});
      }, 3000);
      return;
    }
    if (changePassData.data) {
      history.push("/signin?success=password");
    }
  }, [changePassData.error, changePassData.data]);

  console.log(error);
  return (
    data.token &&
    data.uid && (
      <>
        <main>
          <div className="container-fluid">
            <NavBar theme="dark" />
          </div>

          <div className="container">
            <div className="orb1" />
            <div className="orb2" />

            <div className="reset-content">
              <h4 className="reset-heading">Almost there</h4>
              <h5 className="reset-subheading">Get back your account</h5>

              <p className="reset-address">{data.email}</p>

              <div className="reset-form">
                <div className="form-group">
                  <label className="reset-email-label">
                    Enter new Password
                  </label>
                  <input
                    type="password"
                    className="reset-form-input"
                    onChange={(e) =>
                      setData({ ...data, new_password1: e.target.value })
                    }
                  />
                  {error.new_password1 && (
                    <span
                      style={{
                        display: "block",
                        fontSize: "12px",
                        color: "red",
                        marginTop: -20,
                        marginBottom: 20,
                        paddingLeft: 5,
                      }}>
                      {error.new_password1}
                    </span>
                  )}
                </div>

                <div className="form-group">
                  <label className="reset-email-label">Confirm</label>
                  <input
                    type="password"
                    className="reset-form-input"
                    onChange={(e) =>
                      setData({ ...data, new_password2: e.target.value })
                    }
                  />
                  {error.new_password2 && (
                    <span
                      style={{
                        display: "block",
                        fontSize: "12px",
                        color: "red",
                        marginTop: -20,
                        marginBottom: 20,
                        paddingLeft: 5,
                      }}>
                      {error.new_password2}
                    </span>
                  )}
                </div>

                {!changePassData.loading ? (
                  <button
                    className="button-alt reset-button"
                    onClick={(e) => handlePasswordChange(data)}>
                    Reset
                  </button>
                ) : (
                  <Loader
                    text="chill..."
                    outerStyle={{
                      marginRight: "auto",
                      width: "fit-content",
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </main>
      </>
    )
  );
};

export default ForgotPassword;
