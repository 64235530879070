const RebuildAltered = (data, keyToSort) => {
  const realData = data?.[`${keyToSort}`];
  if (realData) {
    const keys = Object.keys(realData);
    return keys?.map((key) => ({
      value: realData?.[key],
      title: key,
    }));
  }
};
export default RebuildAltered;
