const TimelineAdminSettingItem = ({timelineAdminSetting, specificData, setSpecificData, idx,activeTab}) => {
    return(
        <li>
            <span>{timelineAdminSetting.title}</span>
            <div className={`switch ${timelineAdminSetting.value === false  && 'off'}`} 
            onClick={()=>{
                console.log(activeTab)
                setSpecificData({...specificData, Timeline:specificData.Timeline.map((data)=>{
                    let indexNo = specificData.Timeline.indexOf(data)
                    if(indexNo === idx){
                        return data.value ? {...data, value: false}: {...data, value: true}
                    }
                    return data
                })})
            }}>
                <span className={`${timelineAdminSetting.value === false && 'offSpan'}`} ></span>
            </div>
        </li>
    )
}
export default TimelineAdminSettingItem