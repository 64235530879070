import moment from "moment";
import { useHistory } from "react-router-dom";
const PostMatrics = ({
  location,
  time,
  profile_image,
  full_name,
  username,
  likes,
  comment,
  page,
}) => {
  const history = useHistory();
  return (
    <div className="post__comment__section">
      <div className="avatar">
        <img src={profile_image?.file} alt={username} />
      </div>
      <div className="name">
        <div
          className="full__name"
          onClick={(e) =>
            page
              ? history.push(
                  `/${
                    page.type.toLowerCase() === "business"
                      ? "businessseller"
                      : "privateseller"
                  }/profile/${page.id}`
                )
              : history.push(`/feeds/timeline/${username}`)
          }>
          {full_name}
        </div>
        <ul className="other__detail">
          <li>{location || "Abuja"}</li>
          <li>{moment(time).fromNow()} </li>
          <li>{likes} Likes</li>
          <li>{comment} Replies</li>
        </ul>
      </div>
    </div>
  );
};

export default PostMatrics;
