import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import FeedContainer from "../../../../components/FeedContainer";
import greeting from "../../../../utils/greeting";
import "./index.scss";
import { toggleRightBar, toggleLeftBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import { useHistory } from "react-router-dom";
import { FaDownload, FaInfoCircle } from "react-icons/fa";
import { IoMdCheckmarkCircle, BsArrowRight, MdReport } from "react-icons/all"
import Back from "../../../../assets/svg/arrow-right (2).svg"
import VisaOutline from "../../../../assets/svg/visa-outline-large.svg"
import MasterOutline from "../../../../assets/svg/mastercard-color_large.svg"
import PayPalOutline from "../../../../assets/svg/paypal-color-large-1.svg"
import Visa from "../../../../assets/svg/visa-color_large.svg"
import Master from "../../../../assets/svg/mastercard-light-large.svg"
import MasterActive from "../../../../assets/svg/mastercard-light-color.svg"
import PayPal from "../../../../assets/svg/paypal-nocolor-large.svg"
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';

const PaymentPage = () => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState("false");
  const [activeCard, setActiveCard] = useState("visa");

  const handleLocation = () =>{
    setLocation("false")
  }
  //toggle right bar off
  useEffect(() => {
    dispatch(toggleRightBar(false));
    return () => dispatch(toggleRightBar(true));
  }, []);

  useEffect(() => {
    dispatch(toggleLeftBar(false));
    return () => dispatch(toggleLeftBar(true));
  }, []);

  const {
    meta, getCardNumberProps, getExpiryDateProps, getCVCProps
  } = usePaymentInputs();

  return (
    <FeedContainer>
      <div className="cart-payment">
          <div className="cart-p-header">
            <h5>Payment</h5>
              <Link to='/carts'>
                <p> <img className="visa" src={Back} /> Back to cart</p>
              </Link>
          </div>

          <div className="cart__section">
            <div className="cart-left-container">
                <p className="summary">Summary</p>
                <div className="cart-summary-container">
                  <div className="summary-details">
                    <div className="cash-summary">
                      <div className="sub-total">
                        <p className="text-bold">Sub total</p>
                        <p>$1800</p>
                      </div>
                      <div className="sub-total">
                        <p className="text-bold">Service Charge</p>
                        <p>$1800</p>
                      </div>
                      <div className="sub-total">
                        <p className="text-bold">Tax</p>
                        <p>$1800</p>
                      </div>
                    </div>
                    <div className="total">
                      <p>Total</p>
                      <p>$1800</p>
                    </div>
                  </div>
                </div>
                {location === "true" &&
                <button className="done-button"
                  onClick={handleLocation}
                  >
                  Done</button>
                }
              </div>
              {location === "false" &&
              <div className="adding-card">
                <p className="select-payment-mtd">Select payment method</p>
                <div
                  className={activeCard === "visa" ? "visa-select-active" : "visa-select"}
                  onClick={() => {
                    setActiveCard("visa");
                  }}
                  >
                  <img src={VisaOutline} />
                  <p>Pay with visa **** 6748</p>
                  {activeCard === "visa" ? <IoMdCheckmarkCircle className="visa-mark"/> : " "}
                </div>

                <div
                  className={activeCard === "master" ? "visa-select-active" : "visa-select white-select"}
                  onClick={() => {
                    setActiveCard("master");
                  }}
                  >
                  <img src={MasterOutline} />
                  <p>Pay with mastercard **** 6758</p>
                  {activeCard === "master" ? <IoMdCheckmarkCircle className="mast-mark"/> : " "}
                </div>

                <div
                  className={activeCard === "paypal" ? "visa-select-active" : "visa-select white-select"}
                  onClick={() => {
                    setActiveCard("paypal");
                  }}
                  >
                  <img src={PayPalOutline} />
                  <p>Pay with paypal **** 5899</p>
                  {activeCard === "paypal" ? <IoMdCheckmarkCircle className="pay-mark"/> : " "}
                </div>

                <div className="add-edit">
                  <button
                    onClick={(e) => setLocation("true")}
                    >
                    Add New</button>
                    <Link to='/payment'>
                      <button className="button-edit">Edit Payment Details</button>
                    </Link>
                </div>
                <div className="visa-select pay-go">
                  <h5>$2899</h5>
                  <p>Pay Now</p>
                  <BsArrowRight />
                </div>
              </div>
                }
                {location === "true" &&
                <div className="adding-card">
                  <p>Payment method</p>
                    <div className="payment-card-icons">
                      <img className="visa" src={Visa} />
                      <img className="img" src={Master} />
                      <img className="img" src={PayPal} />
                    </div>

                    <div className="card-input">
                      <div className="name">
                        <h5 className="name-card-owner">Name of card older</h5>
                        <form className="input">
                          <input type="text" placeholder="Howard Pinsky" />
                        </form>
                      </div>

                      <div className="name">
                        <h5>Credit card number</h5>
                        <form className="input">
                          <input
                            {...getCardNumberProps()}
                            placeholder="1234  3478  3495  2345"
                            inputRef={getCardNumberProps().ref}
                            />
                        </form>
                      </div>

                      <div className="expire-cvv">
                        <div className="name">
                          <h5>Expiration</h5>
                          <form className="input">
                            <input {...getExpiryDateProps()} placeholder="02/25" />
                          </form>
                        </div>

                        <div className="name name-cvv">
                          <h5>CVV <MdReport /> </h5>
                          <form className="input">
                            <input {...getCVCProps()} placeholder="123" />
                          </form>
                        </div>
                      </div>

                    </div>

                  <div className="visa-select pay-go">
                    <h5>$2899</h5>
                    <p>Pay Now</p>
                    <BsArrowRight />
                  </div>
                </div>
              }
          </div>
      </div>
    </FeedContainer>
  );
};
export default PaymentPage;
