import { useState } from "react";
import { Chart } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import { FaChevronRight } from "react-icons/fa";
import "./index.scss";



const TotalEarningsChartQueue = ({ title, referralInteraction }) => {
  const chartDate = referralInteraction?.total_earning_listing?.chart?.sort(function(a, b){return a.month-b.month})
  const barChartData = {
    labels: chartDate?.map(item => item?.date),
    datasets: [
      {
        type: "bar",
        label: "first",
        fill: true,
        // fillColor: gradient,
        tension: 0.4,
        //backgroundColor: "rgba(251, 176, 59, 1)",
        backgroundColor: function (context) {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
  
          if (!chartArea) {
            // This case happens on initial chart load
            return;
          }
          return getGradientPurple(ctx, chartArea);
        },
        barPercentage: 0.5,
        barThickness: 30,
        maxBarThickness: 30,
        minBarLength: 20,
        pointStyle: "circle",
        pointRadius: 6,
        data: referralInteraction?.total_earning_listing?.chart?.map(item => item?.total), 
      },
  
      {
        type: "line",
        label: "second",
        fill: false,
        tension: 0.4,
        backgroundColor: "#fff",
        borderColor: "#fff",
        pointBorderColor: "#fff",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 0.5,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "#fff",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        data: [90, 50, 20, 50, 60, 150],
      },
    ],
  };
  
  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        //   backgroundColor: "rgba(255, 255, 255, 1)",
        //   bodyColor: "rgba(126, 132, 163, 1)",
        callbacks: {},
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          offset: false,
          display: false,
        },
        ticks: {
          color: " rgba(255, 255, 255, 1)",
          fontSize: 14,
          // backDropColor: "rgba(153, 70, 171, 1)",
          // backdropPadding: 8,
          // showLabelBackdrop: true,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
  
    animation: {
      easing: "easeOutBack",
  
      y: {
        duration: 3000,
        from: 500,
      },
    },
  };
  
  let chWidth, chHeight, gradient;
  function getGradientPurple(ctx, chartArea) {
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.top - chartArea.bottom;
    if (!gradient || chWidth !== chartWidth || chHeight !== chartHeight) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      chWidth = chartWidth;
      chHeight = chartHeight;
      gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.height);
      gradient.addColorStop(0, "rgba(153, 70, 171, 0.5)");
      gradient.addColorStop(0.5, "rgba(153, 70, 171, 0.25)");
      gradient.addColorStop(1, "rgba(153, 70, 171, 0)");
    }
  
    return gradient;
  }

  const [activeTab, setActiveTab] = useState("tab1");

  return (
    <div className="total-earnings-container">
      <div className="tec-header">
        {activeTab === "tab1" && (
          <>
            <h5>Total Earnings</h5>
            <span
              onClick={() => {
                setActiveTab("tab2");
              }}
            >
              <FaChevronRight />
            </span>
          </>
        )}
        {activeTab === "tab2" && (
          <>
            <h5>Refunded Amount</h5>
            <span
              onClick={() => {
                setActiveTab("tab1");
              }}
            >
              <FaChevronRight />
            </span>
          </>
        )}
      </div>
      <h6>{title}</h6>
      {activeTab === "tab1" && (
        <>
          <h3>${referralInteraction?.total_earning_listing?.total.toFixed(0)}</h3>
        </>
      )}

      {activeTab === "tab2" && (
        <>
          <h3>${referralInteraction?.total_earning_listing?.total.toFixed(0)}</h3>
        </>
      )}
      {activeTab === "tab1" && (
        <Chart
          type="bar"
          data={barChartData}
          options={barChartOptions}
          height={130}
          width={200}
        />
      )}
      {activeTab === "tab2" && (
        <Chart
          type="bar"
          data={barChartData}
          options={barChartOptions}
          height={130}
          width={200}
        />
      )}
    </div>
  );
};
export default TotalEarningsChartQueue;
