import { useState, useContext, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../index.scss";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import { FaSortAmountDownAlt, FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import CustomDropdown from "../../customDropdown/customDropdown";
import CustomCheckBox from "../CheckBox";
import ModalContext from "../../../hooks/modalContexts";
import useBookingRequest from "../useBookingRequest";
import useBooking from "../useBooking";
import moment from "moment";

const ManageAppointment = () => {
  /* datepicker */
  const [startDate, setStartDate] = useState(new Date());
  const [count, setCount] = useState(0);
  const { handleBookingListModal } = useContext(ModalContext);

  const { handleGetBookings, bookingsData } = useBookingRequest();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleGetBookings();
      console.log(bookingsData.data);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const personnelList = [
    { label: "Mr John (Fin Consultant)" },
    { label: "Mr John (Fin Consultant)" },
  ];

  const servicesList = [{ label: "Manicure" }, { label: "Manicure" }];

  const handlePersonnelChange = () => {
    setPersonnelValue(personnelList.label);
  };

  const handleServicesChange = () => {
    setServicesValue(servicesList.label);
  };

  const [personnelValue, setPersonnelValue] = useState(personnelList[0].label);
  const [servicesValue, setServicesValue] = useState(servicesList[0].label);

  return (
    <div className="manageappointment-body-container">
      {/* <h5 className="sub-title"> Manage Appointment</h5> */}
      <button
        className="add-new"
        onClick={() => handleBookingListModal("ADD_DATA", "true")}
      >
        Add new customer to list
      </button>
      <div className="ma-top-header">
        <div>
          <h4>Select Date </h4>
          <div className="datepicker-container">
            <FaCalendarAlt />

            <DatePicker
              className="custom-date"
              selected={startDate}
              onSelect=""
              onChange={(date) => setStartDate(date)}
              dateFormat="MMM d, yyyy h:mm aa"
              placeholderText="select date"
              showTimeInput
              timeInputLabel="Time"
            />
          </div>
        </div>
        <div className="m-lr-6">
          <h4>Select Personnel </h4>
          <div className="dropbox">
            <CustomDropdown
              items={personnelList}
              value={personnelValue}
              setValue={setPersonnelValue}
              onChange={handlePersonnelChange}
            />
          </div>
        </div>
        <div>
          <h4>Services </h4>
          <div className="dropbox">
            <CustomDropdown
              items={servicesList}
              value={servicesValue}
              setValue={setServicesValue}
              onChange={handleServicesChange}
            />
          </div>
        </div>
        <div className="sort-box">
          <p>Sort By</p>
          <div className="sort-input">
            <span> Earliest to Latest </span>
            <FaSortAmountDownAlt color="var(--bg-orange)" />
          </div>
        </div>
      </div>

      {bookingsData.loading && <p>Loading...</p>}
      {!bookingsData.loading && bookingsData.error && <p>An error occured </p>}
      {!bookingsData.loading && !bookingsData.error && !bookingsData.data && (
        <h2 className="empty-item"> No Booking Request Ready For Checkout</h2>
      )}

      {!bookingsData.loading &&
        !bookingsData.error &&
        bookingsData?.data?.results?.map((service, idx) => (
          <div key={idx}>
            {service.status === "ACCEPT" && (
              <div className="ma-cbc-card">
                <div className="ma-cbcc-body">
                  <div className="item__detail-box">
                    <div className="cb-1">
                      <label className="check-label" htmlFor="001">
                        <CustomCheckBox
                          style={{
                            minWidth: 18,
                            height: 18,
                            borderColor: "var(--text-grey)",
                          }}
                        />
                        {idx + 1}
                      </label>
                    </div>
                    <div className="orc-detail-box">
                      <img
                        src={service.service.page?.featured_image || profileImg}
                        alt=""
                      />
                      <div className="orc-detail-box-user">
                        <h4>{service.service.page.name}</h4>
                        <h6>@{service.service.page.username} </h6>
                      </div>
                    </div>
                  </div>
                  <div className="item-detail-two">
                    <h5>
                      {moment(service?.booking_date).format("h:mm a")} -{" "}
                      {moment(service?.booking_date).format("h:mm a")}
                    </h5>
                    <p>in 22 hours </p>
                  </div>

                  <div className="cbcc-item__box">
                    <div className="item-detail-one">
                      <h4>{service.service.title}</h4>
                    </div>
                  </div>
                  <div className="item-btn-box">
                    <button className="btn-checkout">Checkout</button>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
    </div>
  );
};

export default ManageAppointment;
