import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import qs from "query-string";
import {
  addComment,
  addCommentReplies,
  bulkInsertComment,
  clearComments,
  initialComments,
  likeCommentReplies,
  updateCommentLike,
} from "../appstore/commentV2/commentV2.action";

import {
  addNewPost,
  createdNewPost,
  newPostLoading,
  storeNewPost,
  updatePost,
  updatePostLoading,
  deletePost as deletePostAction,
  updateLastComment,
  updatePostLike,
} from "../appstore/posts/index.action";
import {
  addReplies,
  directRepliesToComment,
  initReplies,
  updateReplyLikes,
} from "../appstore/repliesV2/repliesV2.action";
import { deleteSavedPost } from "../appstore/savedPosts/index.action";

import {
  createSingleComment,
  updateSingleCommentLike,
} from "../appstore/singleCommentV2/singleCommentV2.actions";
import {
  createSinglePost,
  incrementCommentCount,
  updateLikes,
  updateSave,
} from "../appstore/singlePostV2/singlePostV2.actions";
import useUploads from "./uploads";
import { useContext } from "react";
import SocialContext from "./socialContext";
const { default: useAxios } = require("axios-hooks");

const usePosts = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { focus, pageId } = useParams();
  const socialContext = useContext(SocialContext);
  const visitor = socialContext?.visitor;
  const { handleUploads } = useUploads();
  const { user } = useSelector((state) => state.currentUser);
  const [postToBeDeleted, setPostToBeDeleted] = useState();
  const [{ ...postsData }, makePost] = useAxios(
    {
      method: "post",
      url: "/posts/",
    },
    {
      manual: true,
    }
  );
  const [postMakeLoading, setPostMakeLoading] = useState(false);

  const handleMakingPost = async (formData, data) => {
    setPostMakeLoading(true);
    const newUploaded = {};
    try {
      if (formData?.has("files")) {
        const { data: uploadedData } = await handleUploads(formData);
        newUploaded.results = uploadedData.results;
        console.log(uploadedData);
      }
      await makePost({
        data: {
          ...data,
          ...(newUploaded.results?.length && {
            media: newUploaded.results.map((data) => data.id),
          }),
          ...(pageId && { page: pageId }),
        },
      });
      setPostMakeLoading(false);
    } catch (err) {
      console.log(err);
      setPostMakeLoading(false);
    }
  };

  useEffect(() => {
    postsData.data && dispatch(createdNewPost(postsData.data));
  }, [postsData.data]);

  //watch postsdata loading

  useEffect(() => {
    dispatch(newPostLoading(postsData.loading));
  }, [postsData.loading]);

  const [{ ...getPostsData }, getPost] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetPosts = (
    postType = "timeline",
    action,
    limit = 4,
    is_business = false,
    owner,
    page
  ) => {
    getPost({
      url: action || `/posts/`,
      ...(!action && {
        params: {
          page,
          limit,
          ...(!postType && { owner: user.id }),
          is_business,
          featured:
            focus?.toLowerCase() === "timeline" && visitor ? null : postType,
          owner,
        },
      }),
    }).catch((error) => {});
  };

  //handle post data received
  useEffect(() => {
    if (getPostsData.data) {
      const { next, previous } = getPostsData.data || {};
      const firstPage = next && next.includes("page=2");

      if (firstPage || (!next && !previous)) {
        //treat as fresh data
        dispatch(storeNewPost(getPostsData.data));
        return;
      }
      if (!firstPage) {
        dispatch(addNewPost(getPostsData.data));
        return;
      }
    }
  }, [getPostsData.data]);

  //watch for loading

  useEffect(() => {
    dispatch(updatePostLoading(getPostsData.loading));
  }, [getPostsData.loading]);

  const [{ ...saveData }, save] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleSave = (id) => {
    save({
      url: `/posts/${id}/save/`,
    }).catch((error) => {});
  };
  const [{ ...likeData }, like] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );
  const handleLike = (id) => {
    like({
      url: `/posts/${id}/likes/`,
    }).catch((error) => {});
  };

  useEffect(() => {
    likeData.data &&
      dispatch(updatePostLike(likeData.data)) &&
      // dispatch(updateSinglePostLike(likeData.data)) &&
      dispatch(updateLikes(likeData.data));
  }, [likeData.data]);

  //updating saved data
  useEffect(() => {
    saveData.data &&
      dispatch(updatePost(saveData.data)) &&
      dispatch(updateSave(saveData.data)) &&
      dispatch(deleteSavedPost(saveData.data.id));

    //remove save
  }, [saveData.data]);

  const [{ ...makeCommentData }, makeComment] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleMakeComment = (post_id, text, parent, page) => {
    makeComment({
      url: `${post_id ? `/posts/${post_id}` : ""}/comments/`,
      data: {
        body: text,
        ...(parent && { reply_to: parent }),
        ...(page && { page }),
      },
    }).catch((err) => {});
  };

  useEffect(() => {
    //check if its a comment directly to a post or a reply to a comment and update
    if (makeCommentData.data) {
      !makeCommentData.data.reply_to
        ? dispatch(updateLastComment(makeCommentData.data)) &&
          dispatch(addComment(makeCommentData.data)) &&
          dispatch(incrementCommentCount())
        : dispatch(addCommentReplies(makeCommentData.data));
    }
    // dispatch(updateSinglePostComment(makeCommentData.data)) &&
    // dispatch(addReply(makeCommentData.data)) &&
    // dispatch(addCommentReply(makeCommentData.data));
  }, [makeCommentData.data]);

  const [{ ...deletePostData }, deletePost] = useAxios(
    {
      method: "delete",
    },
    {
      manual: true,
    }
  );

  const handleDelete = (id) => {
    setPostToBeDeleted(id);
    deletePost({
      url: `/posts/${id}/`,
    }).catch((err) => {});
  };

  useEffect(() => {
    deletePostData.response?.status === 200 &&
      dispatch(deletePostAction(postToBeDeleted));

    //on delete success go back to feeds or business profile depending on current page
    // && history.push("/feeds");
    const { page } = qs.parse(history.location.search);
    setPostToBeDeleted();
    const isPostExpanded = history.location.pathname
      .toLowerCase()
      .includes("post");
    if ((page || isPostExpanded) && postToBeDeleted) {
      history.goBack();
    }
  }, [deletePostData.response?.status]);

  const [{ ...hidePostData }, hidePost] = useAxios(
    {
      method: "patch",
    },
    {
      manual: true,
    }
  );
  const handleHidePost = (id) => {
    hidePost({
      url: `/posts/${id}/`,
      data: {
        hidden: true,
      },
    }).catch((error) => {});
  };
  useEffect(() => {
    hidePostData.data && dispatch(updatePost(hidePostData.data));
  }, [hidePostData.data]);

  const [{ ...singlePostData }, getSinglePost] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetSinglePost = ({ id, url }) => {
    getSinglePost({
      url: url || `/posts/${id}/`,
      // params: {
      //   page_size,
      // },
    }).catch((err) => {});
  };

  useEffect(() => {
    if (singlePostData.data) {
      //check if its fresh data later
      // dispatch(createSinglePostData(singlePostData.data));
      dispatch(createSinglePost(singlePostData.data));
    }
  }, [singlePostData.data]);

  //handle update single post data

  const [{ ...comments }, getComments] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetComments = (post_id, next, page_size = 5) => {
    getComments({
      url: next || `/posts/${post_id}/comments/`,
      ...(!next && {
        params: {
          page_size,
        },
      }),
    })
      .then()
      .catch((err) => {});
  };

  const [{ ...singleCommentData }, getSingleComment] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetSingleComment = async (id) => {
    try {
      getSingleComment({
        url: `/comments/${id}/`,
      });
    } catch (error) {}
  };
  useEffect(() => {
    // console.log(comments.data?.previous);
    comments.data &&
      dispatch(
        !comments.data.previous
          ? initialComments(comments.data.results)
          : bulkInsertComment(comments.data.results)
      );
  }, [comments.data]);

  useEffect(() => {
    if (singleCommentData.data) {
      console.log(singleCommentData.data);
      dispatch(createSingleComment(singleCommentData.data));
    }
  }, [singleCommentData.data]);

  const [{ ...likeCommentData }, likeComment] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleLikeComment = (post_id, id) => {
    likeComment({
      url: `/comments/${id}/likes/`,
    })
      .then((e) => {})
      .catch((err) => {});
  };

  useEffect(() => {
    likeCommentData.data &&
      dispatch(updateCommentLike(likeCommentData.data)) &&
      dispatch(likeCommentReplies(likeCommentData.data));
    // dispatch(updateComment(likeCommentData.data)) &&
    // dispatch(updateReply(likeCommentData.data)) &&
    // dispatch(updateSingleComment(likeCommentData.data));
  }, [likeCommentData.data]);

  const [{ ...likeCommentDataV2 }, likeCommentV2] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleLikeCommentV2 = async (id) => {
    try {
      await likeCommentV2({
        url: `comments/${id}/likes/`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (likeCommentDataV2.data) {
      //update redux for single comments and replies
      dispatch(updateSingleCommentLike(likeCommentDataV2.data)) &&
        dispatch(updateReplyLikes(likeCommentDataV2.data));
      // console.log(likeCommentDataV2.data);
    }
  }, [likeCommentDataV2.data]);

  const [{ ...replyDatav2 }, makeReplyv2] = useAxios(
    {
      method: "post",
      url: "/comments/",
    },
    {
      manual: true,
    }
  );
  const handleMakeReplyV2 = async (reply_to, body) => {
    try {
      await makeReplyv2({
        data: { body, reply_to },
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (replyDatav2.data) {
      dispatch(addReplies(replyDatav2.data)) &&
        dispatch(directRepliesToComment(replyDatav2.data));
    }
  }, [replyDatav2.data]);
  const [{ ...repliesComment }, getRepliesComments] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetRepliesComments = (commentId, next, page_size = 5) => {
    getRepliesComments({
      url: next || `/comments/`,
      ...(!next && {
        params: {
          page_size,
          reply_to: commentId,
        },
      }),
    })
      .then()
      .catch((err) => {});
  };
  useEffect(() => {
    // console.log(repliesComment.data);
    if (repliesComment.data) {
      if (repliesComment.data.next?.includes("2"))
        dispatch(initReplies(repliesComment.data.results));
      if (!repliesComment.data.next && !repliesComment.data.previous)
        dispatch(initReplies(repliesComment.data.results));
      //if next is not equal to 2 then insert data and not clear all
    }
  }, [repliesComment.data]);
  return {
    postsData,
    handleMakingPost,
    handleGetPosts,
    getPostsData,
    // getPostsDataCommunity,
    // handleGetPostsCommunity,
    handleLike,
    handleSave,
    handleMakeComment,
    handleDelete,
    makeCommentData,
    handleHidePost,
    handleGetSinglePost,
    handleGetComments,
    comments,
    handleGetSingleComment,
    handleLikeComment,
    handleLikeCommentV2,
    handleGetRepliesComments,
    handleMakeReplyV2,
    replyDatav2,
    postMakeLoading,
    singlePostData,
  };
};

export default usePosts;
