import React, { useState, useEffect, useContext, useRef } from 'react'
import { FilterOptions } from './ProfileOptions'
import ModalContext from '../../../../../../hooks/modalContexts'
import DropdownIndicator from './CreateFontAwesomeIndicator'
import Select from 'react-select';
import "./index.scss"
import {
  FaSortAmountDownAlt,
  FaChevronDown,
} from 'react-icons/fa'
import useConnect from "../../../../../../hooks/connectHooks";

const ToProfileFilter = ({
  profileForm,
  categoryData,
  handleCategoryData,
  setProfileForm,
  singleProfileData,
  initialSingleProfile,
  editData,
  setEditData
}) => {
  const [category, setCategory] = useState("All");

  const {connectProfileTitle} = useContext(ModalContext)
  const [titleData, setTitleData] = connectProfileTitle

 useEffect(() => {
  setProfileForm({
      ...profileForm,
      title: titleData
    });
  }, [titleData]);

  return(
    <div className="pro_filter">
      <div className="filter_sort_head">
        <div className="filter">
            <Select
                components={{
                  IndicatorSeparator: ()=> null,
                  DropdownIndicator
                }}

                onChange={(e) => {
                          setEditData({
                            ...editData,
                            connect_to_category:{
                                name: `${e.name}`,
                            },
                          })
                        }
                      }
                options={categoryData?.data?.results?.map((item) => {
                          // console.log(item.name);
                          return { ...item, label: item.name };
                        })}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={singleProfileData?.data?.connect_to_category?.name ? singleProfileData?.data?.connect_to_category?.name :
                    initialSingleProfile ? initialSingleProfile.connect_to_category?.name : ""}
                // name={profileForm.business_category}
                />
          </div>
        </div>
    </div>
  )
}

export default ToProfileFilter
