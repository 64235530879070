import React from "react";
import {
  Col,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  Search,
  Email,
  ExpandMore,
  Notifications,
  Payment,
  PowerSettingsNew,
} from "@material-ui/icons";
import { colors } from "../../color";
import Styled from "styled-components";
import "../forms/index.css";

// images
import logo from "../../assets/icons/logo(2).png";
import happy from "../../assets/icons/happy-people.png";
import { MessageModal } from "../modal/customsignupmodal";

const Main = Styled.div`
    height: 120px;
    border-radius: 0px 0px 25px 25px;
    background: ${colors.COMPANY_COLOR};
`;
// const CountrySelect = Styled(ReactFlagsSelect)`
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     width: 100px;
//     border: none;
//     outline: none;
// `;

export const Avatar = Styled.div`
    height: 62px;
    width: 62px;
    border: 2px solid #A303A0;
    background: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;
export const Avatarbtn = Styled(Dropdown)`
    background: none;
    color: white;
    font-weight: 500;
    border: none;
    outline: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Input = Styled.input`
    background: transparent;
    height: 100%;
    width: 100%;
    border: none;
    outline: none;
    margin-left: 10px;
    color: white;
    margin-right: 10px;
`;

const Container = Styled.div`
    width: 90%;
    margin: auto;
`;

const Item = Styled(DropdownItem)`
  color: ${colors.COMPANY_COLOR};
  fontSize: 10px;
  display: flex;
  width: 100%;
  border: none;
  height: 35px;
`;

const style = {
  color: `${colors.ORANGE}`,
  fontSize: "20px",
  marginRight: "10px",
};

const routes = [
  {
    name: "All Messages",
    to: "",
  },
  {
    name: "Unread",
    to: "",
  },
  {
    name: "Flag",
    to: "",
  },
  {
    name: "Draft",
    to: "",
  },
  {
    name: "Starred",
    to: "",
  },
  {
    name: "Recycle Bin",
    to: "",
  },
];

const messages = [
  {
    sender: "Keanu Stewart",
    to: "/dashboard/keanu",
  },
  {
    sender: "Keanu Stewart",
    to: "/dashboard/keanu2",
  },
  {
    sender: "Keanu Stewart",
    to: "/dashboard/keanu3",
  },
  {
    sender: "Keanu Stewart",
    to: "/dashboard/keanu4",
  },
  {
    sender: "Keanu Stewart",
    to: "/dashboard/keanu5",
  },
  {
    sender: "Keanu Stewart",
    to: "/dashboard/keanu6",
  },
  {
    sender: "Keanu Stewart",
    to: "/dashboard/keanu7",
  },
];

class DashboardNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropDownToggle: false, country: "", message_modal: false };
  }

  toggle = () => {
    this.setState({ dropDownToggle: !this.state.dropDownToggle });
  };

  message_toggle = () => {
    this.setState({ message_modal: !this.state.message_modal });
  };
  render() {
    const { message_modal } = this.state;
    return (
      <React.Fragment>
        {/* Message modal that shows the recieved messages */}
        {/* Modal @params {isOpen} bool */}
        {/* Modal @params {toggle_modal} func */}
        <MessageModal
          isOpen={message_modal}
          toggle_modal={this.message_toggle}
          routes={routes}
          messages={messages}
        />
        <Main>
          <Container>
            <Col xl={12} className="pt-4">
              <Row className="d-flex align-items-center">
                <Col xl={4} className="d-flex justify-content-start">
                  <Avatar className="rounded-circle" img={happy}></Avatar>
                  <Avatarbtn
                    isOpen={this.state.dropDownToggle}
                    toggle={this.toggle}
                  >
                    {" "}
                    <DropdownToggle
                      style={{
                        background: "transparent",
                        border: "none",
                        outline: "none",
                      }}
                    >
                      <ExpandMore
                        className="rounded-pill"
                        style={{
                          color: `${colors.ORANGE}`,
                        }}
                      />
                      Akinola
                    </DropdownToggle>
                    <DropdownMenu
                      style={{
                        borderRadius: "15px",
                        height: "85px",
                        width: "14vw",
                      }}
                    >
                      <Item>
                        <Payment style={style} />
                        <p>Payment</p>
                      </Item>
                      <Item>
                        <PowerSettingsNew style={style} />
                        <p>Log out</p>
                      </Item>
                    </DropdownMenu>
                  </Avatarbtn>
                </Col>
                <Col xl={8} className="d-flex justify-content-end">
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ width: "55%" }}
                  >
                    {/* <CountrySelect
                      countries={["US", "GB", "FR", "DE", "IT"]}
                      selected={this.state.country}
                      placeholder=""
                      onSelect={(code) => this.setState({ country: code })}
                    /> */}
                    <Email
                      onClick={this.message_toggle}
                      style={{ color: "white", cursor: "pointer" }}
                    />
                    <Notifications
                      style={{ color: "white", cursor: "pointer" }}
                    />
                    <div className="dashboard-input-div">
                      <Search
                        fontSize="large"
                        style={{ color: `${colors.ORANGE}` }}
                      />
                      <Input placeholder="Search" />
                    </div>
                    <img
                      className="ml-4"
                      width="45"
                      height="20"
                      src={logo}
                      alt=""
                    />
                  </div>
                </Col>
              </Row>
            </Col>
          </Container>
        </Main>
      </React.Fragment>
    );
  }
}

export default DashboardNav;
