import react from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { userLogin } from "../appstore/actions/action";
import { logOut } from "../services/auth/index";

class Logout extends react.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = async () => {
    const { userLogin } = this.props;
    try {
      await logOut(userLogin);
    } catch (e) {
      throw "error" || e;
    }
  };
  render() {
    return <Redirect to="/signin" />;
  }
}

export default connect(null, { userLogin })(Logout);
