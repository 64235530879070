import CircleIcon from "../../../assets/svg/circleIcon";
import shopIcon from "../../../assets/img/blogo.png";
import StarIcon from "../../../assets/svg/star";
import MapLocation from "../../../assets/svg/mapLocation";
import People from "../../../assets/svg/people";
import { useEffect, useState } from "react";
import NewLocation from "../../../assets/svg/newLocation";
import Refer from "../../../assets/svg/refer";
import ReactStars from "react-stars";
import moment from "moment";

const FullQueueItem = ({ handleViewFull, data }) => {
  const [active, setActive] = useState("closed");
  console.log(data, "data");
  const [queueDate, setQueueDate] = useState();
  useEffect(() => {
    if (data) {
      setQueueDate(moment(data?.created_at).format("DD MMM YYYY"));
    }
    console.log(data, "date");
  }, [data]);
  return (
    <div className="fullQueueItem">
      <div className="description">
        <h4>Descriptions</h4>
        <p>{data?.service?.description}</p>
      </div>
      <div className="warning">
        <div className="warningSign">
          <span>i</span>
        </div>
        <p>
          This displays live information by seller about situation of things in
          the queue from start to finish.
        </p>
      </div>
      <div className="cardContainer">
        <div className="cardContent">
          <div className="itemProfile">
            <div className="pro">
              <img
                src={
                  data?.page?.featured_image
                    ? data?.page?.featured_image
                    : shopIcon
                }
                alt=""
                className="src"
              />
              <div className="name">
                <h4>{data.page.name}</h4>
                <p>
                  {data?.page?.username ? data?.page?.username : "@Username"}
                </p>
              </div>
            </div>

            <div onClick={(e) => e.stopPropagation()} className="other">
              <div className="queueItemReview">
                <div className="top">
                  <div className="stars">
                    <ReactStars
                      count={5}
                      size={17}
                      color2={"#FBB03B"}
                      value={data?.service?.rating}
                    />
                  </div>
                  <span>{data?.service?.rating}.0</span>
                </div>
                <p>{data?.service?.rating_count} Reviews</p>
              </div>
              <div className="location">
                <div className="top">
                  <NewLocation />
                  <p>{data?.page?.location?.address}</p>
                </div>
                <p>Posted on {queueDate ? queueDate : ""}</p>
              </div>
              <div className="tag">
                <p>{data?.page?.type} PAGE</p>
              </div>
            </div>
          </div>
          <div className="itemType">
            <div className="itemLeft">
              <h3>{data.service.title}</h3>
              <p>{data.page.location.address}</p>
            </div>
            <div className="itemRight">
              <div className={`status ${!active && "red"}`}>
                {active ? (
                  <CircleIcon fill="#00C13A" />
                ) : (
                  <CircleIcon fill="red" />
                )}
                <p className={!active && "blue"}>
                  {active ? "Active" : "Closed"}
                </p>
              </div>
              {active && data.service.can_refer && (
                <div className="refer">
                  <Refer /> <span>Refer</span>
                </div>
              )}
            </div>
          </div>
          <div className="table">
            {active && (
              <>
                <div className="table-row">
                  <p>Queue No</p>
                  <p>Client Name</p>
                </div>
                <div className="table-row">
                  <div className="queueNo">
                    <div className="green"></div>
                    <span>{data.queue_number}</span>
                  </div>
                  <div className="clientName">{data.owner.full_name}</div>
                  <div className="completed">
                    <span>{data.status}</span>
                    <CircleIcon fill={"#00C13A"} />
                  </div>
                </div>
              </>
            )}
            <div className="boxes">
              <div className="box">
                <p>
                  <span>{active ? "Average waiting time" : "Time joined"}</span>
                  <span>
                    {active
                      ? data.service["average service time"]
                        ? data.service["average service time"]
                        : "0"
                      : "0"}
                  </span>
                </p>
                <p>
                  <span>{active ? "Average service time" : "Time left"}</span>
                  <span>
                    {data.service["average waiting time"]
                      ? data.service["average service time"]
                      : "0"}
                  </span>
                </p>
              </div>
              <div className="box">
                <p>
                  <span>{active ? "Queue No." : "Service time"}</span>
                  <span>{active ? data.queue_number : "20mins"}</span>
                </p>
                <p>
                  <span>
                    {active ? "Estimate waiting time:" : "Waiting time:"}
                  </span>
                  <span>20 Min</span>
                </p>
              </div>
            </div>
          </div>
          {!active && (
            <>
              {" "}
              <div className="review">
                <div className="top">
                  <h5>Rate this seller</h5>
                  <div className="rating">
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <StarIcon />
                    <span>4.5</span>
                  </div>
                </div>

                <p>1.4k Reviews</p>
                <div
                  className="commentBox"
                  placeholder="Comment"
                  contentEditable="true"
                ></div>
                <div className="submit">
                  <a href="">Submit</a>
                </div>
              </div>
              <p onClick={() => handleViewFull()} className="back">
                Back
              </p>
            </>
          )}
        </div>
      </div>
      {active && (
        <div className="actionBtns">
          <p onClick={() => handleViewFull()}>Back</p>
          <div>Leave Queue</div>
        </div>
      )}
    </div>
  );
};
export default FullQueueItem;
