import { useContext } from 'react'
import './info.scss'
import ModalContext from '../../../../../hooks/modalContexts'
import { FaTimes,} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all";

const InfoModal = () => {
      const {handleInfoItemModal,  infoItemModal} = useContext(ModalContext)

    return(
        <div
        onClick={()=>{handleInfoItemModal('RESET_INFO_TO_DISPLAY')}}
        className="InfoModal"
        >
         <div
         onClick={(e)=> e.stopPropagation()}
         className="jobModalContainer"
         >
            <div
            onClick={()=>{handleInfoItemModal('RESET_INFO_TO_DISPLAY')}}
            className="closeIcon"
            >
             <span> <IoMdClose /> </span>
            </div>
            <p className='preferred'> Preferred Contact </p>
            <div className="preferred-phone">
              <form className="phone">
                <div className="phone-text">
                    <h5>Phone</h5>
                </div>
                <input type="text" value={infoItemModal?.contact_details?.CALL} readOnly/>
                <BiCopy />
              </form>
            </div>
            <div className="preferred-email">
              <form className="email">
                <div className="search__icon">
                  <h5>Email</h5>
                </div>
                <input type="text" value={infoItemModal?.contact_details?.EMAIL} readOnly/>
                <BiCopy />
              </form>
              <div className='message-button'>
                <button>Direct message</button>
              </div>
            </div>
            </div>
        </div>
    )
}
export default InfoModal
