import profilePic from "../../../assets/img/profilePic.png";
import Bookmark from "../../../assets/svg/bookmark";
import Forward from "../../../assets/svg/forward";
import MapLocation from "../../../assets/svg/mapLocation";
import People from "../../../assets/svg/people";
import StarIcon from "../../../assets/svg/star";
import ThreeDots from "../../../assets/svg/threeDots";
import shoeImage from "../../../assets/img/shoe.png";
import Down from "../../../assets/svg/Down";
import SortIcon from "../../../assets/svg/sortIcon";
import Search from "../../../components/Search/Search";
import { BiSearch } from "react-icons/bi";
import { referralUtils } from "../utils/queue";
import NewLocation from "../../../assets/svg/newLocation";
import { useEffect, useRef, useState } from "react";
import Refer from "../../../assets/svg/refer.png";
import DatePicker from "react-multi-date-picker";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ReactStars from "react-stars";
import useActivity from "../../../hooks/activity";
import moment from "moment";

const FullReferralItem = ({ handleViewFull, referralItem }) => {
  const { referralCommissions, handleGetReferralCommission } = useActivity();
  const [pop, setPop] = useState(false);
  const [value, setValue] = useState(new Date());
  const [valueEnd, setValueEnd] = useState(new Date());
  const imageCount = [
    { shoePic: shoeImage },
    { shoePic: shoeImage },
    { shoePic: shoeImage },
    { shoePic: shoeImage },
  ];
  const ref = useRef();
  console.log(referralItem, "hey");
  useEffect(() => {
    referralItem?.id && handleGetReferralCommission(referralItem?.id);
  }, []);
  return (
    <div className="activityReferralItem">
      <div className="productDetails">
        <div className="details">
          <div className="profile">
            <img
              src={
                referralItem?.page?.featured_image
                  ? referralItem?.page?.featured_image
                  : profilePic
              }
              alt=""
            />
            <div>
              <h6>{referralItem?.page?.name}</h6>
              <p>{referralItem?.page?.username}</p>
            </div>
          </div>
          <div className="coloredDiv">
            <div className="ratings">
              <div className="rating">
                <div className="stars">
                  <ReactStars
                    value={referralItem?.page?.rating}
                    count={5}
                    edit={false}
                  />
                </div>
                <span>{referralItem?.page?.rating}.0</span>
              </div>
              <p className="reviewCount">
                {referralItem?.page?.review_count}{" "}
                {(referralItem?.page?.review_count > 1 && "reviews") ||
                  (referralItem?.page?.review_count <= 1 && "review")}
              </p>
            </div>
            <div className="location">
              <p>
                <NewLocation />
                <span>{referralItem?.page?.location?.address}</span>
              </p>
              <p>Posted on 20/10/2021</p>
            </div>
            <div className="pageType">{referralItem?.page?.type} Page</div>
            {/* <div onClick={() => setPop(!pop)} className="threeDotDivs">
              <ThreeDots fill={"#490057"} height={20} width={8} />
              {pop && (
                <div className="popUp">
                  <a href="">
                    <Forward />
                    <span>Share</span>
                  </a>
                  <a href="">
                    <img src={Refer} alt="" />
                    <span>Refer</span>
                  </a>
                </div>
              )}
            </div> */}
          </div>
        </div>
        <div className="referralDiv">
          <div>
            <p>Total Commission earned</p>
            <p>${referralItem?.commission_earned}</p>
          </div>
          <div>
            <p>No. Referred </p>
            <p>{referralItem?.referred_count}</p>
          </div>
          <div>
            <p>No. Purchased</p>
            <p>{referralItem?.referred_purchased_count}</p>
          </div>
        </div>
        {/* <div className="carouselDiv">
                    <div className="imageDiv">
                        <img src={shoeImage} alt="" />
                        <img src={shoeImage} alt="" />
                        <img src={shoeImage} alt="" />
                        <img src={shoeImage} alt="" />
                    </div>
                </div> */}
        <Slider
          ref={ref}
          className="imageCarousel referralCarousel"
          dots
          children={referralItem?.gallary?.map((image) => {
            return <img src={image?.file} />;
          })}
          infinite={false}
          slidesPerRow={1}
          slidesToShow={3.5}
        >
          {/* <img src={shoePic} alt="" />
                        <img src={shoePic} alt="" />
                        <img src={shoePic} alt="" /> */}
        </Slider>
      </div>
      <div className="productDescription">
        <h5>Product Description</h5>
        <p>{referralItem?.description}</p>
      </div>
      {/* <div className="filterDiv">
        <h5>Show</h5>
        <div className="drop">
          <span>All</span>
          <Down />
        </div>
        <h4>Show Date</h4>
        <div className="calendar">
          <DatePicker format="DD/MM/YYYY" value={value} onChange={setValue} />
          <span>To</span>
          <DatePicker
            format="DD/MM/YYYY"
            value={valueEnd}
            onChange={setValueEnd}
          />
        </div>
        <div className="sort">
          <SortIcon />
          <BiSearch fill="#787878" />
        </div>
      </div> */}
      {/* <div className="tableDivs">
        <div className="tableDivsHeader">
          <h6 className="one">S.No</h6>
          <h6 className="two">Username </h6>
          <h6 className="three">Quantity</h6>
          <h6 className="four">Time</h6>
          <h6 className="five">Commision</h6>
        </div>
        <ul className="tableList">
          {referralCommissions?.data?.results?.map(
            (referralCommission, idx) => {
              return (
                <li>
                  <p className="one">{idx}</p>
                  <p className="two">{referralCommission?.username}</p>
                  <p className="three">{referralCommission?.quantity}</p>
                  <p className="four">2nd Feb 2021</p>
                  <p className="five">$20</p>
                </li>
              );
            }
          )}
        </ul>
      </div> */}
      <table className="tableDivs">
        <tr>
          <th>S.No</th>
          <th>Username</th>
          <th>Quantity</th>
          <th>Time</th>
          <th>Commision</th>
        </tr>
        {referralCommissions?.data?.results?.map((referralCommission, idx) => {
          return (
            <tr>
              <td className="one">{idx}</td>
              <td className="two">{referralCommission?.username}</td>
              <td className="three">{referralCommission?.quantity}</td>
              <td className="four">
                {moment(referralCommission?.time).format("DD MMM YYYY")}
              </td>
              <td className="five">${referralCommission?.commission}</td>
            </tr>
          );
        })}
      </table>
      <p onClick={() => handleViewFull()} className="back">
        Back
      </p>
    </div>
  );
};
export default FullReferralItem;
