import React, { useState, useEffect, useRef } from "react";
import { FaAngleUp, FaAngleDown } from "react-icons/fa";

const NumberInput = ({ handleUpdateCartQuantity, cart }) => {
  const max = 100;
  const min = 1;
  const inputRef = useRef(null);
  const [number, setNumber] = useState({
    quantity: 1,
  });

  useEffect(() => {
    handleUpdateCartQuantity(cart.id, number.quantity);
  }, [number]);
  
  console.log(number.quantity);

  return (
    <div className="number-input">
      <input
        className="number-content"
        value={number.quantity}
        // defaultValue={number}
        onChange={(e) => {
          setNumber({ ...number, quantity: Number(e.target.value) });
        }}
        type="number"
        ref={inputRef}
        min="1"
      />

      <div className="number-icons">
        <div
          className="up-icon"
          onClick={(e) => {
            if (number.quantity < max) {
              setNumber({ ...number, quantity: number.quantity + 1 });
            } else {
              setNumber({ ...number, quantity: min });
            }
          }}
        >
          <FaAngleUp />
        </div>
        <div
          className="down-icon"
          onClick={(e) => {
            if (number.quantity > 1) {
              setNumber({ ...number, quantity: number.quantity - 1 });
            } else {
              setNumber({ ...number, quantity: max });
            }
          }}
        >
          <FaAngleDown />
        </div>
      </div>
    </div>
  );
};

export default NumberInput;
