import React, { useEffect, useRef, useState } from "react";
import FeedContainer from "../../components/FeedContainer";
// import ReusableDropDown from "../../components/reusableDropdown";
import GallerySortDropdown from "../../components/reusableDropdown/gallerySort";
import { IoIosImage, FiPlayCircle, FaAngleDown } from "react-icons/all";
import "./gallery.scss";
import DatePicker from "react-datepicker";
import GallerySlide from "./gallerySlide";
import useGallery from "../../hooks/gallery";
import NoData from "../../components/NoData";
import moment from "moment";
const Gallery = () => {
  const { handleGetData, galleryData } = useGallery();
  const [currentOption, setCurrentOption] = useState("all");
  const [sortingActive, setSortingActive] = useState(false);
  console.log(currentOption);
  const sortingOptions = [
    {
      name: "All",
    },
    {
      name: "Photos",
      img: IoIosImage,
    },
    {
      name: "Videos",
      img: FiPlayCircle,
    },
  ];
  // const galleryContainer = useRef(null);
  const lastItem = useRef(null);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [state, setState] = useState([]);

  useEffect(() => {
    if (currentOption.toLowerCase() === "all") {
      handleGetData({});
      setSortingActive(true);
    }
    if (currentOption.toLowerCase() === "videos") {
      handleGetData({ type: "video" });
      setSortingActive(true);
    }
    if (currentOption.toLowerCase() === "photos") {
      handleGetData({ type: "image" });
      setSortingActive(true);
    }
  }, [currentOption]);

  useEffect(() => {
    if (galleryData.data?.results) {
      if (startDate && endDate) {
        setState([...galleryData.data.results]);
      } else {
        if (sortingActive) {
          setState([...galleryData.data.results]);
        } else {
          setState([...state, ...galleryData.data.results]);
        }
      }
    }
    setSortingActive(false);
  }, [galleryData.data]);

  useEffect(() => {
    if (startDate && endDate) {
      handleGetData({
        created_at_gte: startDate,
        created_at_lte: endDate,
      });
    }
  }, [startDate, endDate]);

  const delData = (data = []) => {
    const newState = state.map((item) => {
      return {
        ...item,
        data: {
          ...item.data,
          results: item.data.results
            .map((item) => {
              if (data.includes(item.id)) {
                return false;
              } else return item;
            })
            .filter((data) => data && data),
        },
      };
    });
    setState(newState);
  };

  useEffect(() => {
    let observer;
    if (galleryData.data?.next && lastItem?.current) {
      observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting) {
            console.log("in view now");
            handleGetData({ next: galleryData.data.next });
          }
        },
        {
          root: null,
          threshold: 0.25,
        }
      );
      observer.observe(lastItem.current);
    }

    return () => {
      if (lastItem?.current) {
        observer?.unobserve(lastItem.current);
        observer?.disconnect();
      }
    };
  }, [lastItem?.current, galleryData.data?.next]);
  return (
    <FeedContainer>
      <div className="gallery-container">
        <h1>Gallery</h1>
        <div className="header__gallery">
          <div className="show__sec">
            <p>Show</p>
            <GallerySortDropdown
              options={sortingOptions}
              getData={setCurrentOption}
            />
          </div>
          <div className="date__sec">
            <p>Show Date</p>
            {/*<p>From</p>*/}
            <div className="picker">
              <DatePicker
                // selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={(dates) => {
                  console.log(dates);
                  setStartDate(dates[0]);
                  setEndDate(dates[1]);
                }}
                selectsRange
                customInput={
                  <div className="custom-date-box">
                    {" "}
                    {moment(startDate).format("MMM Do yyyy")} -{" "}
                    {moment(endDate || startDate).format("MMM Do yyyy")}{" "}
                  </div>
                }
                // dateFormat={"MMM do yyyy"}
                // value={moment(date1).format("MMM Do YYYY")}
              />
              <div className="icon__date"> {/*<FaAngleDown />{" "}*/}</div>
            </div>
            {/* <p>To</p>
            <div className="picker">
              <DatePicker
                selected={date2}
                onChange={(date) => setDate2(date)}
                dateFormat={"MMM do yyyy"}
              />
              <div className="icon__date"> <FaAngleDown />{" "}</div>
            </div> */}
          </div>
          {/*<div className="search__bar">*/}
          {/*  <SmallSearch />*/}
          {/*</div>*/}
        </div>
        <div className="main__gallery__section">
          {state.map((item, idx) => {
            return <GallerySlide key={idx} data={item} removeData={delData} />;
          })}
          {!!galleryData.data?.next && (
            <div ref={lastItem} style={{ padding: 100 }}></div>
          )}
          {!!!state.length && <NoData>No Data</NoData>}
        </div>
      </div>
    </FeedContainer>
  );
};

export default Gallery;
