import ReactStars from 'react-stars'

const ReviewItem = ({reviewDataItem}) =>{
    return(
        <div className="reviewItem">
            <div className="reviewItem_profile">
                <img src={reviewDataItem.profilePic} alt="" />
                <ReactStars
                    count={5}                   
                    size={17}
                    color2={'#FBB03B'} 
                    value={4}
                    className='stars'
                />
                <p>
                    {reviewDataItem.name}
                </p>
                <span className='date'>
                    {reviewDataItem.date}
                </span>
            </div>
            <div className="reviewItem_details">
                <p>
                    is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the 
                </p>
            </div>
        </div>
    )
}
export default ReviewItem