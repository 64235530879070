import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import useUploads from "./uploads";
const usePages = () => {
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);
  const user = currentUser.user;
  const { handleUploads } = useUploads();
  const [loading, setLoading] = useState(false);
  const [{ ...createPageData }, createPage] = useAxios(
    {
      url: "/pages/",
      method: "post",
    },
    {
      manual: true,
    }
  );

  const [{ ...openingHoursData }, addOpeningHours] = useAxios(
    {
      url: "/pages/bulk/availability/",
      method: "post",
    },
    {
      manual: true,
    }
  );
  const handlePageCreation = async ({ data, file, availability }) => {
    setLoading(true);
    const upload = async () => {
      const formData = new FormData();
      formData.append("files", file);
      return (await handleUploads(formData)).data?.results[0].id;
    };
    const page = await createPage({
      params: {
        owner: user.user_id,
      },
      data: { ...data, ...(file && { featured_image: await upload() }) },
    });

    // console.log(page.data);
    // create availability hours
    page.data &&
      (await addOpeningHours({
        data: availability.map((data) => ({
          ...data,
          page: page.data.id,
          start_time: data.start_time + ":00",
          closing_time: data.closing_time + ":00",
        })),
      }));
    setLoading(false);
  };

  const [{ ...categoryData }, getCategory] = useAxios({
    url: "/categories",
  });
  useEffect(() => {
    if (openingHoursData.response?.status === 201) {
      // console.log(createPageData.data);
      if (createPageData.data.type.toLowerCase() === "business") {
        history.push("/businessseller/dashboard?id=" + createPageData.data.id);
      } else {
        history.push("/privateseller/dashboard?id=" + createPageData.data.id);
      }
    }
    return () => {};
  }, [openingHoursData.data]);

  const [{ ...getPagesData }, getPages] = useAxios(
    {
      url: "/pages/",
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetPages = ({ owner, type, sudo = true, ...rest }) => {
    getPages({
      params: {
        type,
        owner,
        sudo,
        ...rest,
      },
    });
  };

  const [{ ...singlePageData }, getSinglePage] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetSinglePage = async (id) => {
    id &&
      getSinglePage({
        url: `/pages/${id}/`,
      });
  };
  const [{ ...supportData }, support] = useAxios(
    {
      method: "post",
    },
    { manual: true }
  );

  const handlePageSupport = (id) => {
    support({
      url: `/pages/${id}/supports/`,
    });
  };

  const [{ ...makeReviewData }, createPageReview] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleCreatePageReview = ({ page, body, rating }) => {
    createPageReview({
      url: `/pages/${page}/reviews/`,
      data: {
        body,
        rating,
      },
    }).catch((err) => console.log(err));
  };

  const [{ ...pagesToProfileDisplay }, addPagesToProfileDisplay] = useAxios(
    {
      method: "post",
      url: "/users/display_pages/",
    },
    { manual: true }
  );

  const handleAddPagesToDisplay = async (ids = []) => {
    return ids.length && (await addPagesToProfileDisplay({ data: { ids } }));
  };

  const [{ ...removedPagesData }, removePagesToDisplay] = useAxios(
    {
      method: "delete",
      url: "/users/display_pages/",
    },
    {
      manual: true,
    }
  );

  const handleRemovePagesToDisplay = async (ids = []) => {
    return (
      ids.length &&
      removePagesToDisplay({
        data: {
          ids,
        },
      })
    );
  };

  const [{ ...viewData }, view] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleView = async (id) => {
    return await view({
      url: `/pages/${id}/clicks/`,
    });
  };
  const [{ ...seenData }, seen] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleSeen = async (id) => {
    return await seen({
      url: `/pages/${id}/views/`,
    });
  };
  return {
    createPageData,
    handlePageCreation,
    user,
    loading,
    getCategory,
    categoryData,
    handleGetPages,
    getPagesData,
    handleGetSinglePage,
    singlePageData,
    supportData,
    handlePageSupport,
    makeReviewData,
    handleCreatePageReview,
    pagesToProfileDisplay,
    handleAddPagesToDisplay,
    removedPagesData,
    handleRemovePagesToDisplay,
    handleView,
    handleSeen,
  };
};

export default usePages;
