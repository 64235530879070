import { useContext, useState } from 'react'
import { Link,  useHistory } from 'react-router-dom'
import ModalContext from '../../../../hooks/modalContexts'
import './profile.scss'
import profilePic from '../../../../assets/img/connectProfilePic.png'
import { Heart } from '../../../../assets/svg/PostCardIcon'
import MsgIcon from '../../../../assets/svg/msgIcon'
import Phone from '../../../../assets/svg/phone'
import { FaTimes,} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all";
import ExploreContext  from '../../../../hooks/exploreContext'

const ConnectProfileModal = () => {
    const {handleConnectProfileModal, connectProfileModal, connectProfileTitle} = useContext(ModalContext)
    const {
      handleEditPage,
      handleInputChange,
      titleForm,
      setTitleForm,
      pageId,
      setPageId,
      setLeftbarFormTitle
    } = useContext(ExploreContext)

    const [formErrors, setFormErrors] = useState({});
    const [titleData, setTitleData] = connectProfileTitle

    let history = useHistory();

    console.log(pageId.id)

    return(
      <div
      onClick={()=>{handleConnectProfileModal('RESET_PROFILE_TO_DISPLAY')}}
      className="profileModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="profileModalContainer"
       >
          <div
          onClick={()=>{handleConnectProfileModal('RESET_PROFILE_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
          <p className='enter-profile'> Enter connect profile title </p>
          <div className="enter-profile-form">
            <form className="form">
              <input
                name="title"
                type="text"
                value={titleForm.title}
                onChange={handleInputChange}
                placeholder="Meat dealer"
                />
              <Link to={`/businessseller/create-profile/?id=${pageId.id}`} className='button'
                onClick={()=>{setLeftbarFormTitle(true)}}
                >
                  <button type='submit'>Save</button>
              </Link>
            </form>
          </div>

          </div>
      </div>
    )
}
export default ConnectProfileModal
