import item from "../../assets/img/Rectangle 1890.PNG";

export const Suggested = [
  {
    id: 1,
    Image: item,
    Item: "Vegan foods food and food Vargan Foods",
    OrderDate: 'Order Date',
    Qty: 'Qty',
    TotalAmount: 'Total Amount',
    Price: "$20",
    QuantityNumber: "2",
  },

]
