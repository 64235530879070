import React, { useEffect, useState } from "react";
import DropdownIndicator from './CreateFontAwesomeIndicator'
import Select from 'react-select';
import "./index.scss"
import {
  FaSortAmountDownAlt,
  FaChevronDown,
} from 'react-icons/fa'
import {toSentenceCase} from "../../../../../../utils/helpers"

const ServicePersonnelFilter = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.options) {
      for (let i=0; i<props.options.length; i++) {
        let newOption = {};
        newOption.label = props.options[i]?.label?.full_name;
        newOption.value = props.options[i]?.value;

        let oldOptions = options;
        oldOptions.push(newOption); 

        setOptions(oldOptions); 
      }
    }
  }, [props.options])

  // useEffect(() => {
  //   setOptions([]);
  // }, [])

  return(
    <div className="personnel_filter">
      <div className="filter_sort_head">
        <div className="filter">
            <Select
                components={{
                  IndicatorSeparator: ()=> null,
                  DropdownIndicator
                }}
                options={options}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={props.placeholder}
                onChange={(option) => props.action(option)}
                />
          </div>
        </div>
    </div>
  )
}

export default ServicePersonnelFilter
