import React, {useState} from "react";
import "./messageBubble.scss";
import moment from "moment";
import angleDown from "../../assets/messaging/arrow-down.svg";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import starredMenu from "../../assets/messaging/filled/star-menu.svg";
import flaggedMenu from "../../assets/messaging/filled/flag-menu.svg";
import deleteMenu from "../../assets/messaging/filled/trash.svg";

const MessageBubble = (props) => {
  const handleOptionsMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(false);
  };

  const handleStar = (id, id2) => {
    console.log(props);
    props.starAction(id);
    handleClose();
  }

  const handleFlag = (id) => {
    props.flagAction(id);
    handleClose();
  }

  const handleDelete = (id) => {
    props.deleteAction(id);
    handleClose();
  }

  const [anchorEl, setAnchorEl] = useState(false);
  const open = Boolean(anchorEl);

  return (
    <>
      <div className={`msg-single ${props.mine ? "mine" : ""}`}>
        {
          props.media ?
          props.media.map((mediafile, id) => (
            <div className="media-box" key={id}>
              <img src={mediafile.file} alt="" />
            </div>
          ))
          : null
        }
          <p className="text">{props?.message?.message}</p>
        <div className="date">
          {moment(props?.date)?.format("ll")} - {moment(props?.date)?.format("LT")}

          <div className="msg-icons">
            <div className="mark-icons">
              {props?.message.is_starred && <img alt="" src={starredMenu} className='mark-icon' />}
              {props?.message.is_flagged && <img alt="" src={flaggedMenu} className='mark-icon' />}
            </div>

            <img src={angleDown}
              alt=""
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleOptionsMenu}
            />

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              <MenuItem onClick={() => handleStar(props.message.id, props.id)}>
                <img alt="" src={starredMenu} className='menu-icon' />&nbsp;
                <p className="menu-text">{props?.message.is_starred ? 'Unstar Message' : 'Star Message'}</p>
              </MenuItem>

              <MenuItem onClick={() => handleFlag(props.message.id ? props.message.id : props.id)}>
                <img alt="" src={flaggedMenu} className='menu-icon' />&nbsp;
                <p className="menu-text">{props?.message.is_flagged ? 'Unflag Message' : 'Flag Message'}</p>
              </MenuItem>

              <MenuItem onClick={() => handleDelete(props.message.id ? props.message.id : props.id)}>
                <img alt="" src={deleteMenu} className='menu-icon' />&nbsp;
                <p className="menu-text">Delete Message</p>
              </MenuItem>
            </Menu>
          </div>

        </div>
      </div>
    </>
    )
}

export default MessageBubble;