import "./index.scss";
import EditIcon from "../../assets/svg/editIcon";

const PostButton = ({ onClick = () => {}, ...rest }) => {
  return (
    <div className="post-btn-container" onClick={onClick} {...rest}>
      <button className="edit-btn">
        <EditIcon />
      </button>
      <button className="post-btn"> Create a Post</button>
    </div>
  );
};

export default PostButton;
