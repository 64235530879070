import { createContext, useState } from "react";

const ModalContext = createContext({});

//i am sorry, this section might look ugly... lol
export const useModalContext = () => {
  //queue listings
  const [queueListingModal, setQueueListingModal] = useState([]);

  const handleQueueListingModal = (type, payload) => {
    switch (type) {
      case "SET_DATA":
        setQueueListingModal(payload);
        break;
      case "ADD_DATA":
        setQueueListingModal([
          ...queueListingModal
            .map((data) => data.id !== payload.id && data)
            .filter((data) => data && data),
          payload,
        ]);
        break;

      case "RESET_DATA":
        setQueueListingModal([]);
        break;

      case "SET_SELECTION":
        const data = queueListingModal
          .map((item) => item.id === payload.id && payload)
          .filter((data) => data && data)[0];
        handleQueueListingModal("ADD_DATA", data);
        break;

      default:
        break;
    }
  };
  const [checkoutModal, setCheckoutModal] = useState(true);
  const handleCheckoutModal = (type, payload) => {
    switch (type) {
      case "SET_DATA":
        setCheckoutModal(payload);
        break;

      case "RESET_DATA":
        setCheckoutModal();
        break;

      default:
        break;
    }
  };
  const [queueItemModal, setQueueItemModal] = useState();
  const handleQueueItemModal = (type, payload) => {
    switch (type) {
      case "ADD_QUEUE_ITEM":
        setQueueItemModal(payload);
        break;

      case "RESET_QUEUE_ITEM":
        setQueueItemModal();
        break;

      default:
        setQueueItemModal();
        break;
    }
  };
  const [myJobItemModal, setMyJobItemModal] = useState();
  const handleMyJobItemModal = (type, payload) => {
    switch (type) {
      case "ADD_JOB_TO_DISPLAY":
        setMyJobItemModal(payload);
        break;

      case "RESET_JOB_TO_DISPLAY":
        setMyJobItemModal();
        break;
      default:
        break;
    }
  };

  const [jobItemModal, setJobItemModal] = useState();
  const handleJobItemModal = (type, payload) => {
    console.log(payload);
    switch (type) {
      case "ADD_JOB_TO_DISPLAY":
        setJobItemModal(payload);
        break;

      case "RESET_JOB_TO_DISPLAY":
        setJobItemModal();
        break;

      default:
        break;
    }
  };

  const [orderItemModal, setOrderItemModal] = useState();
  const handleOrderItemModal = (type, payload) => {
    switch (type) {
      case "ADD_TO_DISPLAY_ORDER":
        setOrderItemModal(payload);
        break;

      case "RESET_TO_DISPLAY_ORDER":
        setOrderItemModal();
        break;

      default:
        break;
    }
  };

  //would still get back to this to sort how data will be added
  const [galleryModal, setGalleryModal] = useState();

  const [jobModal, setJobModal] = useState();
  const handleJobModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setJobModal(payload);
        break;

      case "RESET_DATA":
        setJobModal();
        break;

      default:
        break;
    }
  };

  const [reviewModal, setReviewModal] = useState();
  const handleReviewModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setReviewModal(payload);
        break;

      case "RESET_DATA":
        setReviewModal(payload);
        break;

      default:
        break;
    }
  };
  //for postModal
  const postModalVisibilityStatus = useState(false);

  const [infoItemModal, setInfoItemModal] = useState();
  const handleInfoItemModal = (type, payload) => {
    switch (type) {
      case "ADD_INFO_TO_DISPLAY":
        setInfoItemModal(payload);
        break;

      case "RESET_INFO_TO_DISPLAY":
        setInfoItemModal();
        break;

      default:
        break;
    }
  };

  const [userModal, setUserModal] = useState({
    data: [],
    loading: false,
    type: "followers",
  });

  const handleUserModal = (type, payload, modalType) => {
    switch (type) {
      case "SET_DATA":
        setUserModal(payload);
        break;
      case "ADD_DATA":
        setUserModal({
          ...userModal,
          data: [...userModal.data, payload],
          type: modalType,
        });
        break;
      case "SET_TYPE":
        setUserModal({ ...userModal, type: payload });
        break;

      default:
        setUserModal({
          data: [],
          loading: false,
          type: "followers",
        });
        break;
    }
  };
  const [bookingModal, setBookingModal] = useState();
  const handleBookingModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setBookingModal(payload);
        break;

      case "RESET_DATA":
        setBookingModal();
        break;

      default:
        break;
    }
  };

  const [businessSummaryModal, setBusinessSummaryModal] = useState();
  const handleSummaryModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setBusinessSummaryModal(payload);
        break;

      case "RESET_DATA":
        setBusinessSummaryModal();
        break;

      default:
        break;
    }
  };

  const [connectProfileModal, setConnectProfileModal] = useState();
  const handleConnectProfileModal = (type, payload) => {
    switch (type) {
      case "ADD_PROFILE_TO_DISPLAY":
        setConnectProfileModal(payload);
        break;

      case "RESET_PROFILE_TO_DISPLAY":
        setConnectProfileModal();
        break;
      default:
        return;
    }
  };
  const [privateSellerSummaryModal, setPrivateSellerSummaryModal] = useState();
  const handlePrivateSellerSummaryModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setPrivateSellerSummaryModal(payload);
        break;

      case "RESET_DATA":
        setPrivateSellerSummaryModal();
        break;

      default:
        break;
    }
  };

  const [acceptModal, setAcceptModal] = useState();
  const handleAcceptModal = (type, payload) => {
    switch (type) {
      case "ADD_ACCEPT_TO_DISPLAY":
        setAcceptModal(payload);
        break;
      case "RESET_ACCEPT_TO_DISPLAY":
        setAcceptModal();
        break;
      default:
        break;
    }
  };

  const [connectModal, setConnectModal] = useState();
  const handleConnectModal = (type, payload) => {
    switch (type) {
      case "ADD_CONNECT_TO_DISPLAY":
        setConnectModal(payload);
        break;
      case "RESET_CONNECT_TO_DISPLAY":
        setConnectModal();
        break;
      default:
        return;
    }
  };
  const [businessSummary2Modal, setBusinessSummary2Modal] = useState();
  const handleSummary2Modal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setBusinessSummary2Modal(payload);
        break;

      case "RESET_DATA":
        setBusinessSummary2Modal();
        break;

      default:
        break;
    }
  };

  const [messageModal, setMessageModal] = useState();
  const handleMessageModal = (type, payload) => {
    switch (type) {
      case "ADD_MESSAGE_TO_DISPLAY":
        setMessageModal(payload);
        break;
      case "RESET_MESSAGE_TO_DISPLAY":
        setMessageModal();
        break;
      default:
        return;
    }
  };
  const [queueListingsModal, setQueueListingsModal] = useState();
  const handleQueueListingsModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setQueueListingsModal(payload);
        break;

      case "RESET_DATA":
        setQueueListingsModal();
        break;

      default:
        break;
    }
  };

  const [successModal, setSuccessModal] = useState();
  const handleSuccessModal = (type, payload) => {
    switch (type) {
      case "ADD_SUCCESS_TO_DISPLAY":
        setSuccessModal(payload);
        break;
      case "RESET_SUCCESS_TO_DISPLAY":
        setSuccessModal();
        break;
      default:
        return;
    }
  };
  const [bookingListModal, setBookingListModal] = useState();
  const handleBookingListModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setBookingListModal(payload);
        break;

      case "RESET_DATA":
        setBookingListModal();
        break;

      default:
        break;
    }
  };

  //for now, when integrating api, things would change a lot
  const [sendAsMessage, setSendAsMessage] = useState(false);
  const [jobApplyModal, setJobApplyModal] = useState(false);
  const [applySuccessModal, setApplySuccessModal] = useState(false);
  const [bookSuccessModal, setBookSuccessModal] = useState(false);
  const [productSuccessModal, setProductSuccessModal] = useState(false);
  const [updateSuccessModal, setUpdateSuccessModal] = useState(false);
  const [updateFailModal, setUpdateFailModal] = useState(false);
  const [productOrderModal, setProductOrderModal] = useState(false);
  const [privateSellerDelivery, setPrivateSellerDelivery] = useState(false);
  const [businessSellerDelivery, setBusinessSellerDelivery] = useState(false);

  const [referModal, setReferModal] = useState(false);
  const [retractModal, setRetractModal] = useState();
  const handleRetractModal = (type, payload) => {
    switch (type) {
      case "ADD_RETRACT_TO_DISPLAY":
        setRetractModal(payload);
        break;
      case "RESET_RETRACT_TO_DISPLAY":
        setRetractModal();
        break;
      default:
        return;
    }
  };

  const [incomingModal, setIncomingModal] = useState();
  const handleIncomingModal = (type, payload) => {
    switch (type) {
      case "ADD_INCOMING_TO_DISPLAY":
        setIncomingModal(payload);
        break;
      case "RESET_INCOMING_TO_DISPLAY":
        setIncomingModal();
        break;
      default:
        return;
    }
  };
  const [acceptedModal, setAcceptedModal] = useState();
  const handleAcceptedModal = (type, payload) => {
    switch (type) {
      case "ADD_ACCEPTED_TO_DISPLAY":
        setAcceptedModal(payload);
        break;
      case "RESET_ACCEPTED_TO_DISPLAY":
        setAcceptedModal();
        break;
      default:
        return;
    }
  };

  const [autoAcceptModal, setAutoAcceptModal] = useState();
  const handleAutoAcceptModal = (type, payload) => {
    switch (type) {
      case "ADD_AUTOACCEPT_TO_DISPLAY":
        setAutoAcceptModal(payload);
        break;
      case "RESET_AUTOACCEPT_TO_DISPLAY":
        setAutoAcceptModal();
        break;
      default:
        return;
    }
  };

  const [discountModal, setDiscountModal] = useState();
  const [stockDiscount, setStockDiscount] = useState();
  const handleDiscountModal = (type, payload) => {
    switch (type) {
      case "ADD_DISCOUNT_TO_DISPLAY":
        setDiscountModal(payload);
        break;
      case "RESET_DISCOUNT_TO_DISPLAY":
        setDiscountModal();
        break;
      default:
        return;
    }
  };
  const [mediaBox, setMediaBox] = useState({ media: [] });
  const handleMediaBox = (type, payload) => {
    switch (type) {
      case "RESET":
        setMediaBox({ media: [] });
        break;
      case "CREATE_MEDIA":
        setMediaBox({ media: payload });
        break;
      default:
        setMediaBox(mediaBox);
    }
  };

  const [totalRevenueModal, setTotalRevenueModal] = useState();
  const handleTotalRevenueModal = (type, payload) => {
    switch (type) {
      case "ADD_DATA":
        setTotalRevenueModal(payload);
        break;

      case "RESET_DATA":
        setTotalRevenueModal();
        break;

      default:
        break;
    }
  };

  const [walletModal, setWalletModal] = useState();
  const handleWalletModal = (type, payload) => {
    switch (type) {
      case "ADD_WALLET_TO_DISPLAY":
        setWalletModal(payload);
        break;
      case "RESET_WALLET_TO_DISPLAY":
        setWalletModal();
        break;
      default:
        return;
    }
  };

  const [editProfileModal, setEditProfileModal] = useState();
  const handleEditProfileModal = (type, payload) => {
    switch (type) {
      case "ADD_EDIT_PROFILE_TO_DISPLAY":
        setEditProfileModal(payload);
        break;

      case "RESET_EDIT_PROFILE_TO_DISPLAY":
        setEditProfileModal();
        break;
      default:
        return;
    }
  };

  const [completedOrderModal, setCompletedOrderModal] = useState(false);
  const [incompleteOrderModal, setInCompleteOrderModal] = useState(false);
  const [cancelledOrderModal, setCancelledOrderModal] = useState(false);
  const [preOrderMetModal, setPreOrderMetModal] = useState(false);
  const [preOrderNotMetModal, setPreOrderNotMetModal] = useState(false);
  const [clicksAnalyticModal, setClicksAnalyticModal] = useState(false);
  const [likesAnalyticModal, setLikesAnalyticModal] = useState(false);
  const [sharesAnalyticModal, setSharesAnalyticModal] = useState(false);
  const [referAnalyticModal, setReferAnalyticModal] = useState(false);
  const [supportAnalyticModal, setSupportAnalyticModal] = useState(false);
  const [pageOrderModal, setPageOrderModal] = useState(false);
  const [referralOrderModal, setReferralOrderModal] = useState(false);
  const [jobAnalyticModal, setJobAnalyticModal] = useState(false);
  const [queueAnalyticModal, setQueueAnalyticModal] = useState(false);
  const [pageViewsAnalyticModal, setPageViewsAnalyticModal] = useState(false);
  const [customerConversionModal, setCustomerConversionModal] = useState(false);
  const [totalConnectionsModal, setTotalConnectionsModal] = useState(false);
  const [totalAppointmentModal, setTotalAppointmentModal] = useState(false);
  const [totalPageVisitModal, setTotalPageVisitModal] = useState(false);
  const [totalCommissionsModal, setTotalCommissionsModal] = useState(false);
  const [bookingLocationModal, setBookingLocationModal] = useState(false);
  const [postPersonnel, setPostPersonnel] = useState(false);
  const [toggleEditPersonnel, setToggleEditPersonnel] = useState(false);
  const [isEditPersonnel, setIsEditPersonnel] = useState(null);
  const [toggleEditPersonnelPost, setToggleEditPersonnelPost] = useState(false);
  const [businessHours, setBusinessHours] = useState([]);
  const [serviceToggleSubmit, setServiceToggleSubmit] = useState(true);
  const [bookingPSform, setBookingPSform] = useState("");
  const [totalApplicationModal, setTotalApplicationModal] = useState(false);
  const [customerConversionConnectModal, setCustomerConversionConnectModal] =
    useState(false);
  const [referralTotalModal, setReferralTotalModal] = useState(false);

  const personnelFormList = useState([]);
  const profileTitleForm = useState("");
  const deliveryListState = useState([]);
  const serviceLocationList = useState([]);
  const deliveryToBeEditedState = useState();
  const connectProfileTitle = useState("");
  const editProfileTitle = useState("");

  const createListingData = useState();
  const checkoutModalV2 = useState(/*{ id: "2eb89b94f2" }*/);
  const personnelFormValue = useState({});

  const personnelInitialFormState2 = useState({});

  const [successConnectModal, setSuccessConnectModal] = useState();
  const handleSuccessConnectModal = (type, payload) => {
    switch (type) {
      case "ADD_SUCCESS_CONNECT_TO_DISPLAY":
        setSuccessConnectModal(payload);
        break;
      case "RESET_SUCCESS_CONNECT_TO_DISPLAY":
        setSuccessConnectModal();
        break;
      default:
        return;
    }
  };

  const [declineConnectModal, setDeclineConnectModal] = useState();
  const handleDeclineConnectModal = (type, payload) => {
    switch (type) {
      case "ADD_DECLINE_CONNECT_TO_DISPLAY":
        setDeclineConnectModal(payload);
        break;
      case "RESET_DECLINE_CONNECT_TO_DISPLAY":
        setDeclineConnectModal();
        break;
      default:
        return;
    }
  };

  const [successConnectModalCard, setSuccessConnectModalCard] = useState();
  const handleSuccessConnectModalCard = (type, payload) => {
    switch (type) {
      case "ADD_SUCCESS_CONNECTCARD_TO_DISPLAY":
        setSuccessConnectModalCard(payload);
        break;
      case "RESET_SUCCESS_CONNECTCARD_TO_DISPLAY":
        setSuccessConnectModalCard();
        break;
      default:
        return;
    }
  };

  const [declineConnectModalCard, setDeclineConnectModalCard] = useState();
  const handleDeclineConnectModalCard = (type, payload) => {
    switch (type) {
      case "ADD_DECLINE_CONNECTCARD_TO_DISPLAY":
        setDeclineConnectModalCard(payload);
        break;
      case "RESET_DECLINE_CONNECTCARD_TO_DISPLAY":
        setDeclineConnectModalCard();
        break;
      default:
        return;
    }
  };

  const [retractSuccessConnectModal, setRetractSuccessConnectModal] =
    useState();
  const handleRetractSuccessConnectModal = (type, payload) => {
    switch (type) {
      case "ADD_RETRACT_SUCCESS_CONNECT_TO_DISPLAY":
        setRetractSuccessConnectModal(payload);
        break;
      case "RESET_RETRACT_SUCCESS_CONNECT_TO_DISPLAY":
        setRetractSuccessConnectModal();
        break;
      default:
        return;
    }
  };
  const [messageSentModal, setMessageSentModal] = useState();
  const handleMessageSentModal = (type, payload) => {
    switch (type) {
      case "ADD_MESSAGE_SENT_DISPLAY":
        setMessageSentModal(payload);
        break;
      case "RESET_MESSAGE_SENT_DISPLAY":
        setMessageSentModal();
        break;
      default:
        return;
    }
  };

  //add admin modal

  const [adminModal, setAdminModal] = useState();

  return {
    queueListingModal,
    checkoutModal,
    handleCheckoutModal,
    queueItemModal,
    handleQueueItemModal,
    myJobItemModal,
    handleMyJobItemModal,
    orderItemModal,
    handleOrderItemModal,
    galleryModal,
    setGalleryModal,
    jobModal,
    jobItemModal,
    handleJobItemModal,
    postModalVisibilityStatus,
    handleInfoItemModal,
    infoItemModal,
    userModal,
    handleUserModal,
    handleBookingModal,
    bookingModal,
    businessSummaryModal,
    handleSummaryModal,
    sendAsMessage,
    setSendAsMessage,
    handleConnectProfileModal,
    connectProfileModal,
    handleAcceptModal,
    acceptModal,
    handleConnectModal,
    connectModal,
    messageModal,
    handleMessageModal,
    handleSuccessModal,
    successModal,
    jobApplyModal,
    setJobApplyModal,
    applySuccessModal,
    setApplySuccessModal,
    bookSuccessModal,
    setBookSuccessModal,
    productSuccessModal,
    setProductSuccessModal,
    updateSuccessModal,
    setUpdateSuccessModal,
    updateFailModal,
    setUpdateFailModal,
    productOrderModal,
    setProductOrderModal,
    privateSellerDelivery,
    setPrivateSellerDelivery,
    businessSellerDelivery,
    setBusinessSellerDelivery,
    businessSummary2Modal,
    handleSummary2Modal,
    queueListingsModal,
    handleQueueListingsModal,
    privateSellerSummaryModal,
    handlePrivateSellerSummaryModal,
    bookingListModal,
    handleBookingListModal,
    reviewModal,
    handleReviewModal,
    referModal,
    setReferModal,
    retractModal,
    handleRetractModal,
    incomingModal,
    handleIncomingModal,
    acceptedModal,
    handleAcceptedModal,
    autoAcceptModal,
    handleAutoAcceptModal,
    discountModal,
    handleDiscountModal,
    mediaBox,
    handleMediaBox,
    totalRevenueModal,
    handleTotalRevenueModal,
    completedOrderModal,
    setCompletedOrderModal,
    incompleteOrderModal,
    setInCompleteOrderModal,
    cancelledOrderModal,
    setCancelledOrderModal,
    preOrderMetModal,
    setPreOrderMetModal,
    preOrderNotMetModal,
    setPreOrderNotMetModal,
    clicksAnalyticModal,
    setClicksAnalyticModal,
    likesAnalyticModal,
    setLikesAnalyticModal,
    sharesAnalyticModal,
    setSharesAnalyticModal,
    referAnalyticModal,
    setReferAnalyticModal,
    supportAnalyticModal,
    setSupportAnalyticModal,
    pageOrderModal,
    setPageOrderModal,
    referralOrderModal,
    setReferralOrderModal,
    jobAnalyticModal,
    setJobAnalyticModal,
    queueAnalyticModal,
    setQueueAnalyticModal,
    pageViewsAnalyticModal,
    setPageViewsAnalyticModal,
    customerConversionModal,
    setCustomerConversionModal,
    totalConnectionsModal,
    setTotalConnectionsModal,
    totalAppointmentModal,
    setTotalAppointmentModal,
    totalPageVisitModal,
    setTotalPageVisitModal,
    totalCommissionsModal,
    setTotalCommissionsModal,
    handleWalletModal,
    walletModal,
    bookingLocationModal,
    setBookingLocationModal,
    deliveryListState,
    personnelFormList,
    serviceLocationList,
    deliveryToBeEditedState,
    profileTitleForm,
    connectProfileTitle,
    stockDiscount,
    setStockDiscount,
    editProfileModal,
    handleEditProfileModal,
    editProfileTitle,
    createListingData,
    checkoutModalV2,
    personnelFormValue,
    postPersonnel,
    setPostPersonnel,
    toggleEditPersonnel,
    setToggleEditPersonnel,
    isEditPersonnel,
    setIsEditPersonnel,
    personnelInitialFormState2,
    toggleEditPersonnelPost,
    setToggleEditPersonnelPost,
    businessHours,
    setBusinessHours,
    serviceToggleSubmit,
    setServiceToggleSubmit,
    bookingPSform,
    setBookingPSform,
    handleSuccessConnectModal,
    successConnectModal,
    handleDeclineConnectModal,
    declineConnectModal,
    handleRetractSuccessConnectModal,
    retractSuccessConnectModal,
    totalApplicationModal,
    setTotalApplicationModal,
    customerConversionConnectModal,
    setCustomerConversionConnectModal,
    referralTotalModal,
    setReferralTotalModal,
    messageSentModal,
    setMessageSentModal,
    handleMessageSentModal,

    handleDeclineConnectModalCard,
    handleSuccessConnectModalCard,
    successConnectModalCard,
    declineConnectModalCard,
    adminModal,
    setAdminModal,
  };
};
export default ModalContext;
