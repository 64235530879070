import { useContext } from "react";
import "./summary.scss";
import ModalContext from "../../../hooks/modalContexts";
import Photo from "../../../assets/img/shoe.png";
import profileImg from "../../../assets/img/Ellipse 543@2x.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import { FaPen } from "react-icons/fa";
import CloseBtn from "../../../components/closeBtn";

const BusinessSummaryModal2 = () => {
  const { handleSummary2Modal } = useContext(ModalContext);
  const testArray = [1, 2, 3];

  const testArray2 = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  return (
    <div
      onClick={() => {
        handleSummary2Modal("RESET_DATA");
      }}
      className="bsModal"
    >
      <div className="bsModalContainer">
        <div
          onClick={() => {
            handleSummary2Modal("RESET_DATA");
          }}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="bsModalContainered"
        >
          <div className="bsModal-header">
            <h4> Summary</h4>
            <button className="btn-post">Post</button>
          </div>
          <div className="bsModal-body">
            <div className="summary-container-one">
              <div className="img-sm-box">
                <Slick dots={true} slidesToShow={1}>
                  {testArray.map((data, idx) => (
                    <img src={Photo} alt="" />
                  ))}
                </Slick>
              </div>
              <div className="desc-sm-box">
                <div className="orc-detail-box">
                  <img src={profileImg} alt="" />
                  <div className="orc-detail-box-user">
                    <h4> Best men's shoe</h4>
                    <h6>Jamaica</h6>
                  </div>
                </div>
                <h3> Shoe and more </h3>
                <h4> Product Description </h4>
                <p>
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsa
                  at saepe vero alias, tenetur quo, animi qui itaque, veritatis
                  hic officiis voluptatem quisquam fuga laborum et dignissimos
                  distinctio beatae! Accusantium inventore ipsam accusamus
                  quaerat obcaecati enim quisquam, cum eligendi, odio cupiditate
                  id expedita sint sunt voluptates veritatis laboriosam, quo
                  sed?
                </p>
              </div>
            </div>
            <div className="summary-container-two">
              <div className="sct-box-one">
                <h5> Price detail </h5>
                <div className="sct-price-detail">
                  <div className="sct-pd-box">
                    <div>
                      <p>Pre Order</p>
                      <h6>
                        Min Purchase Qty: <span>$200</span>
                      </h6>
                      <h6>
                        Stock Qty: <span>200</span>
                      </h6>
                    </div>
                    <div>
                      <h6>
                        Price: <span> $2000 </span>
                      </h6>
                      <h6>
                        Deals: <span> Nil </span>
                      </h6>
                      <h6>
                        Discount: <span> 20% </span>
                      </h6>
                    </div>
                  </div>

                  <div className="sctp-footer">
                    <p>
                      Minimum qty of 200 units will be ordered before June 21
                      2012 before order is processed
                    </p>
                  </div>
                </div>

                <h5>Referral</h5>
                <div className="sct-referral">
                  <h6>
                    Anyone: <span> $40</span>
                  </h6>
                </div>

                <div className="sct-influencer">
                  <h6> Influencer </h6>
                  <div className="item__scroll">
                    {testArray2.map((data, idx) => (
                      <div className="scti-details" key={idx}>
                        <h6>
                          {idx + 1} <span> Charles E</span>
                        </h6>
                        <p>
                          price: <span>$20 </span>
                        </p>
                        <p>
                          REf code: <span>Charlie2298</span>
                        </p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="sct-box-two">
                <h5>End date</h5>
                <div className="sct-date">
                  <h6>
                    End date: <span> July 27, 2021</span>
                  </h6>
                  <h6>
                    Time: <span> 12:00am </span>
                  </h6>
                </div>

                <div className="sct-request">
                  <h6>Request:</h6>
                  <span>Auto Accept </span>
                </div>
                <h5> Delivery Details</h5>

                <div className="sctd-box-one">
                  <h6>
                    Delivery Price: <span>Free</span>
                  </h6>
                </div>
                <div className="sct-delivery-details">
                  <div className="item__scroll">
                    {testArray2.map((data, idx) => (
                      <div className="sct-dd-container">
                        <div className="sctd-box-two">
                          <div className="box-item-one">
                            <h5> Location </h5>
                            <h6> Alaska </h6>
                          </div>
                          <div className="box-item-two">
                            <h5> Delivery Mode</h5>
                            <h6> Door delivery</h6>
                          </div>
                          <div className="box-item-three">
                            <h5> Delivery Address </h5>
                            <h6> No. 29 Thames avenue New york cit..</h6>
                          </div>
                          <div className="box-item-four">
                            <h5> Time </h5>
                            <h6> 12 May 2021 9:00AM</h6>
                          </div>
                        </div>
                        <div className="sct-dd-footer">
                          <FaPen />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BusinessSummaryModal2;
