import React, { createContext, useState, useContext,  useEffect } from "react"
import ModalContext from '../../../../../hooks/modalContexts'
import { Link } from 'react-router-dom'
import { FaEye, FaHeart } from 'react-icons/fa'
import person from '../../../../../assets/img/Ellipse 320.PNG'
import moment from "moment"
import LoaderPurple from "../../../../../assets/loaders/LoaderPurple"


const SuggestCard = ({
  suggested,
  handleGetSingleProfile,
  singleProfileData,
  modalData,
  setModalData,
  handleLike,
  id,
  likeData,
  handleHideProfile,
  connectTo,
  setConnectTo,
  currentId,
  setCurrentId,
  setLikeSuggestedCard,
  like,
  currentProfile,
  handleSendConnectRequest,
  sendConnectRequestData,
}) => {
  const [isOpened, setOpened] = useState('false')
  const [isActive, setActive] = useState('false')
  // const [modalData, setModalData] = useState()
  const {handleConnectModal, handleSuccessModal} = useContext(ModalContext)

  const handleShowAndModal = () => {
    handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
    handleShow();
  }
  const handleShow = () => {
    setOpened(!isOpened)
  }

  const handleToggle = () => {
    if(likeData?.data){
      setActive(!isActive)
    }
  }

  useEffect(() => {
    if (singleProfileData?.data) {
      console.log(singleProfileData?.data?.page?.name);
      // dispatch(createSingleComment(singleCommentData.data));
      setModalData(singleProfileData?.data)
    }
  }, [singleProfileData?.data]);

  const initialSendConnectState = {
    connect_profile: currentProfile,
    page: connectTo,
    note: ""
}
const [sendConnect, setSendConnect] = useState(initialSendConnectState)
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setSendConnect({ ...sendConnect, [name]: value });
};

const submitFormData = (e) => {
  e.preventDefault();
  handleSendConnectRequest(sendConnect);
  if(sendConnectRequestData){
    console.log("=====SUCCESS========");
    console.log(sendConnectRequestData)
  };
};

useEffect(() => {
  if(sendConnectRequestData?.data){
    handleSuccessModal('ADD_SUCCESS_TO_DISPLAY', 'true');
    // handleConnectModal('RESET_CONNECT_TO_DISPLAY');
    // setSuggested(state => state?.filter(state => state?.id !== sendConnectRequestData?.data?.id && state?.status !== "Pending" && true))
  }
}, [sendConnectRequestData?.data])

console.log(suggested)
// const page_id = id

if(!suggested) return <LoaderPurple />
  return (
    <>
    {!currentProfile ? "" : 
      <div className='connect-manage-card favorite-suggested-cards'
      >
        <div className='connect-manage-card-left'>
          <div className='connect-manage-card-user'>
            <div className='connect-manage-card-user-head'>
              <div>
                <img src={
                  suggested?.featured_image ? suggested?.featured_image : person
                  } alt='' />
              </div>
              <div>
                <h2>{suggested?.name}</h2>
                <p>@{suggested?.username}</p>
              </div>
            </div>
            <div className='connect-manage-iconss'>
              <div className='connect-manage-eye'
                 onClick={() =>{
                  handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
                  // handleShow();
                  // handleGetSingleProfile(suggested?.id)
                  setModalData(suggested)
                  setConnectTo(suggested?.id)
                }}
                >
                <FaEye />
              </div>
              <div
                onClick={() =>{
                  // handleToggle();
                  // toggleHeart();
                  handleLike(currentProfile, suggested.id);
                  console.log("SUGGESTED ID" + currentProfile + "Page ID" + suggested.id)
                  like();
                  setCurrentId(suggested.id);
                  setLikeSuggestedCard(suggested);
                }}
                className={
                  suggested.is_like === false
                    ? 'connect-manage-heart'
                    : 'connect-manage-heart favey'
                }
              >
                <FaHeart />
              </div>
            </div>
          </div>
          <div className='connect-manage-card-text'>
            <div className='connect-manage-card-text-wrap'>
              <h1>{suggested?.title}</h1>
              <h3>{suggested?.location?.address}</h3>
              <p className='connect-manage-card-p1'>{suggested?.description}</p>
            </div>
            <div className='connectcard-button-flex'>
              <p className='connect-manage-card-p2'>
                {/*{moment(suggested?.expire_on).format("MMMM Do YYYY")}*/}
              </p>
              <div>
                <div
                  className={isOpened ? 'connect-button-flex' : 'connect-hide'}
                >
                  <button
                    // onClick={handleShow}
                    className='connect-connect'
                    onClick={() =>{
                      handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
                      // handleShow();
                      // handleGetSingleProfile(suggested?.id)
                      setModalData(suggested)
                      setConnectTo(suggested?.id)
                    }}
                    >
                    Connect
                  </button>
                </div>
                <div
                  className={isOpened ? 'connect-show' : 'connect-show appear'}
                >
                  <p>Connected</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        }
    </>
  )
}

export default SuggestCard
