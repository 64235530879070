import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "./pageSetup.scss";
// import Person from "../../assets/img/Rectangle 1830.png";
import logowhite from "../../assets/icons/meeney__white-logo.png";
// import ReactFlagsSelect from "react-flags-select";
import useCreatePage from "../../hooks/createPage";
import { useHistory } from "react-router-dom";
import { showToast } from "../../components/ReactToast";
import { Toaster } from "react-hot-toast";

const BusinessPageSetup = ({ setPage, mainFormData, setMainFormData }) => {
  const history = useHistory();
  const { user } = useCreatePage();
  const [formValues, setFormValues] = useState({
    username: user.username,
    email: mainFormData.email || user.email,
    name: mainFormData.name || "",
    description: mainFormData.description || "",
    phone_number: mainFormData.phone_number || user.phone_number || "",
    website: mainFormData.website || "",
  });
  // console.log(formValues);
  // const [selected, setSelected] = useState("");

  const handlePhoneInput = (value) => {
    if (!value) {
      setFormValues({ ...formValues, phone_number: "" });
      return null;
    }
    setFormValues({ ...formValues, phone_number: value });
  };

  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  const handleGoToPage2 = (e) => {
    try {
      e.preventDefault();
      if (formValues.name.trim() === "") {
        throw "Business name cannot be empty";
      }
      if (formValues.phone_number.trim() === "") {
        throw "Please provide a phone number";
      }
      if (formValues.username.trim() === "") {
        throw "username cannot be left empty";
      }
      if (formValues.email.trim() === "") {
        throw "Please provide an email address";
      }
      if (formValues.description.trim() === "") {
        throw "description cannot be left empty";
      }
      if (
        !new RegExp(
          "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
        ).test(formValues.website)
      ) {
        throw "please input a valid url";
      }
      setPage(2);
    } catch (err) {
      showToast("error", err);
    }
  };
  useEffect(() => {
    setMainFormData({ ...mainFormData, ...formValues });
  }, [formValues]);

  return (
    <div className="pageSetup-container">
      <Toaster />
      <div className="wp-top-banner">
        <img className="img-logo" alt="meeney logo" src={logowhite} />
      </div>
      <div className="ps-left-container">
        <div className="top-ps-header w-63">
          <h2> Let's create your business account together</h2>
          <p>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard
          </p>
        </div>

        <div className="ps-lc-form">
          <form onSubmit="">
            <div className="input-group">
              {/* <label htmlFor="">Business name </label> */}
              <input
                placeholder="Business name"
                className="form-input"
                type="text"
                name="name"
                value={formValues.name}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group">
              {/* <label htmlFor="">Description </label> */}
              <input
                placeholder="Description"
                className="form-input"
                type="text"
                name="description"
                value={formValues.description}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group">
              {/* <label htmlFor="">Phone</label> */}
              <PhoneInput
                international
                defaultCountry="US"
                placeholder="(201) 555-55555"
                value={formValues.phone_number}
                onChange={(value) => handlePhoneInput(value)}
              />
            </div>
            {/* <div className="input-group">
                <PhoneInput
                  international
                  defaultCountry="US"
                  placeholder="(201) 555-55555"
                  value=""
                  onChange={(value) => handlePhoneInput(value)}
                />
              </div> */}

            <div className="input-group">
              {/* <label htmlFor="">Email </label> */}
              <input
                placeholder="Enter your email address"
                className="form-input"
                type="text"
                name="email"
                value={formValues.email}
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              {/* <label htmlFor="">Create a username </label> */}
              <input
                placeholder="Username"
                className="form-input"
                type="text"
                name="username"
                value={formValues.username}
                // disabled
                onChange={handleInputChange}
              />
            </div>
            <div className="input-group">
              {/* <label htmlFor="">Website </label> */}
              <input
                placeholder="Enter website"
                className="form-input"
                type="text"
                name="website"
                value={formValues.website}
                onChange={handleInputChange}
              />
            </div>

            <div className="link-opt">
              <button
                className="btn-back-link"
                onClick={(e) => {
                  history.goBack();
                }}>
                Back
              </button>
              <button className="btn-start-link" onClick={handleGoToPage2}>
                Next
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className=" img-bg">
        <img className="img-logo" alt="meeney logo" src={logowhite} />
      </div>
    </div>
  );
};

export default BusinessPageSetup;
