import { useState, useEffect } from "react";
import BookingRequestBody from "../../components/Bookings/BookingRequest/bookingRequestBody";
import ManageAppointment from "../../components/Bookings/BookingRequest/manageAppointment";
import FeedContainer from "../../components/FeedContainer";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import AppointmentHistoryBody from "../../components/Bookings/BookingRequest/appointmentHistory";
import qs from "query-string";

const BookingRequest = () => {
  const [activeTab, setActiveTab] = useState("tab1");
  const { user } = useSelector((state) => state.currentUser);
  const { search } = useLocation();
  const { id } = qs.parse(search);

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };

  const handleTab3 = () => {
    // update the state to tab2
    setActiveTab("tab3");
  };

  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  return (
    <FeedContainer>
      <div className="booking-request-container">
        <div className="bkr-header">
          <h1 className="welcome-title">
            {greeting()} <span>{`${user.first_name} `} </span>
          </h1>
          {/* <div className="bkr-btn-box">
            <Link to="/booking" className="bkr-btn">
              Edit Booking
            </Link>
          </div> */}
        </div>
        <div className="bkr-card">
          <h5 className="sub-title"> Manage Appointment </h5>
          <div className="select-top-box">
            <div className="lbc-select-box">
              <div className="tabs">
                {/* ----------  nav tab -------- */}
                <ul className="nav-tab">
                  <li
                    className={activeTab === "tab1" ? "active" : ""}
                    onClick={handleTab1}
                  >
                    Checkout
                  </li>
                  <li
                    className={activeTab === "tab2" ? "active" : ""}
                    onClick={handleTab2}
                  >
                    Requested
                  </li>
                  <li
                    className={activeTab === "tab3" ? "active" : ""}
                    onClick={handleTab3}
                  >
                    History
                  </li>
                </ul>
              </div>
            </div>
            <div className="bkr-btn-box">
              <Link to={`/createbooking/?id=${id}`} className="bkr-btn">
                Booking Settings
              </Link>
            </div>
          </div>

          <div className="outlet">
            {/* ---------------- tab contents -------------- */}
            {activeTab === "tab1" && <ManageAppointment />}
            {activeTab === "tab2" && <BookingRequestBody />}
            {activeTab === "tab3" && <AppointmentHistoryBody />}
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};

export default BookingRequest;
