import React, {useEffect, createContext, useState, useContext  } from "react";
import ModalContext from '../../../../../hooks/modalContexts'
import { useLocation, useHistory, Link  } from "react-router-dom";
import qs from "query-string";
import './index.scss'
import profilePic from '../../../../../assets/img/connectProfilePic.png'
import { Heart } from '../../../../../assets/svg/PostCardIcon'
import MsgIcon from '../../../../../assets/svg/msgIcon'
import Phone from '../../../../../assets/svg/phone'
import { FaTimes, FaHeart} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all"
import useConnect from "../../../../../hooks/connectHooks"
import ExploreContext  from '../../../../../hooks/exploreContext'

const ConnectMeModal = () => {

    const {connectModal, handleConnectModal, handleSuccessModal} = useContext(ModalContext)
    const { search } = useLocation();
    const { id } = qs.parse(search);
    const {
        handleSendConnectRequest,
        sendConnectRequestData,
        singleProfileData,
        handleGetConnectProfileSuggested,
        handleLikedPages,
      } = useConnect();

      const {
        modalData,
        setModalData,
        pageId,
        setPageId,
        connectFrom,
        connectTo,
        currentProfile,
        setSuggested,

        connectManageLoad, 
        setConnectManageLoad,

        requestSentFromSuggest, 
        setRequestSentFromSuggest

      } = useContext(ExploreContext)

      const initialSendConnectState = {
          connect_profile: currentProfile,
          page: connectTo,
          note: ""
      }
      const [sendConnect, setSendConnect] = useState(initialSendConnectState)
      console.log(initialSendConnectState)

      // {
      //     connect_profile: currentProfile,
      //     page: connectTo,
      //     note:""
      // }

      // useEffect(() => {
      //   if(pageId.id){
      //     setSendConnect({
      //         connect_profile_from: id,
      //         connect_profile_to:"ac977f0469",
      //         note: ""
      //     })
      //   }
      // }, [pageId.id])

      // console.log(pageId.id)
      console.log(modalData)

      const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSendConnect({ ...sendConnect, [name]: value });
      };

    const submitFormData = (e) => {
        e.preventDefault();
        handleSendConnectRequest(sendConnect);
    };

    useEffect(() => {
        if(sendConnectRequestData?.data){
          setConnectManageLoad(true)
          setRequestSentFromSuggest(sendConnectRequestData?.data)
          handleGetConnectProfileSuggested(currentProfile);
          handleLikedPages(currentProfile)
          // setSuggested(state => state?.filter(state => state?.id !== sendConnectRequestData?.data?.id && true))
        }
    }, [sendConnectRequestData?.data])


console.log(sendConnect);

if(!modalData) return <div>Loading</div>
    return(
      <div
        onClick={()=>{handleConnectModal('RESET_CONNECT_TO_DISPLAY')}}
      className="acceptModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="acceptModalContainer"
       >
          <div
            onClick={()=>{handleConnectModal('RESET_CONNECT_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
            <div className="profileArea">
                <div className="connectProfile">
                      <img src={
                        modalData?.featured_image === null
                        ? profilePic
                        : modalData?.featured_image
                        } alt=''
                        />

                    <div className="name">
                        <h4>
                            {modalData?.name}
                        </h4>
                        <p>
                            @{modalData?.username}
                        </p>
                    </div>
                    <div className="icons">
                        <FaHeart  className={
                          modalData?.is_like === true
                          ? 'heart favey'
                          : 'heart'
                        }/>
                    </div>
                </div>
                <div className="description">
                    <h4>
                      {modalData?.title}
                    </h4>
                    <p className='address'>
                        {modalData?.location?.address}
                    </p>
                    <p>
                        {modalData?.description}
                    </p>
                </div>
                <div className="contact">
                    <p>
                        <MsgIcon />
                        <span>
                          {modalData?.email}
                        </span>
                    </p>
                    <p>
                        <Phone />
                        <span>
                        {modalData?.phone_number}
                        </span>
                    </p>
                </div>
                <div className="locations">
                    <h4>
                        Service Locations:
                    </h4>
                    <ul className="service">
                      {modalData?.connect_profile?.service_locations.map((location) => (
                      <li>
                        {location.address}
                      </li>
                    ))
                      }
                    </ul>
                    </div>
                </div>
                <form className="form" onSubmit={submitFormData}>
                  <div className="submittedNote">
                    <p>
                        Add a note
                    </p>
                    <div className="describe-form">
                      <textarea
                        className="text-area"
                        name="note"
                        type="text"
                        value={sendConnect.note}
                        onChange={handleInputChange}
                        placeholder="Add a note here"
                        >
                      </textarea>
                    </div>
                </div>
                <div className="permissions connect">
                      <button className="connect-accept-color"
                        type='submit'
                        >
                            Connect me
                      </button>
                </div>
              </form>
          </div>
      </div>
    )
}
export default ConnectMeModal
