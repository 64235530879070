// import React from 'react'
import React, {useEffect, createContext, useState, useContext  } from "react";
import ModalContext from '../../../hooks/modalContexts'
import ExploreContext  from '../../../hooks/exploreContext'
import { useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../appstore/navigationDisplayToggle/index.action";
import { Link } from 'react-router-dom'
import { FaBell } from 'react-icons/fa'
import { BsThreeDotsVertical } from 'react-icons/bs'
import './Connect.scss'
import FeedContainer from '../../../components/FeedContainer'
import ConnectCard from './RecievedCard'
import Comment from '../../../assets/icons/comment-alt-regular.svg'
import Caution from '../../../assets/icons/exclamation-solid.svg'
import Notifications from "../../../components/NotificationContainer/notification"
import greeting from "../../../utils/greeting";
import {Users, Suggested, connectUser} from "./ConnectData"
import useConnect from "../../../hooks/connectHooks";
import item from "../../../assets/img/Rectangle 1890.PNG";
import ConnectedCards from "./ConnectedCards"
import LoaderPurple from "../../../assets/loaders/LoaderPurple"

function ConnectedSection({
  id,
  handleHideProfile,
  hideProfileData,
  handleLike,
  modalData,
  setModalData,
  handleGetSingleProfile,
  singleProfileData,
  currentId,
  setCurrentId,
  likeData,
  connectProfileDataSuggested,
  handleGetConnectProfileSuggested,
  connectRequestConnectedTrueDataHome,
  requestConnectedHome,
  setMessageData,
}) {
  const history = useHistory();
  const dispatch = useDispatch()
  const {handleConnectModal} = useContext(ModalContext)

  const {
    handleGetConnectProfile,
    connectProfileData,
    handleGetConnectProfileHome,
    connectProfileDataHome,
    // connectProfileDataSuggested,
    // handleGetConnectProfileSuggested,
  } = useConnect();
  const {
    connect,
    setConnect,
  } = useContext(ExploreContext)

  useEffect(() => {
    handleGetConnectProfileHome();
    console.log(connectProfileDataSuggested?.data?.results);
    console.log("##############")
  }, []);

  // useEffect(() => {
  //   if(hideProfileData.data){
  //     connectProfileDataHome();
  //     // handleGetConnectRequest();
  //     // handleGetConnectProfile();
  //   }
  // }, [hideProfileData.data]);

  useEffect(() => {
    if(likeData.data){
      handleGetConnectProfileHome();
    }
  }, [likeData.data])

  return (
    <>
      <div className='connect-suggestion'>
            <h1>Connected Requests</h1>
               <div className='connect-suggestion-wrap'>
                {requestConnectedHome <= 0 && <div> You are not connected to any page yet, check your suggestions and start sending request  </div>}
                  {connectRequestConnectedTrueDataHome.loading && <LoaderPurple />}

                  {!connectRequestConnectedTrueDataHome.loading && connectRequestConnectedTrueDataHome.error && (
                    <p>An error</p>
                  )}

                  {!connectRequestConnectedTrueDataHome.loading &&
                    !connectRequestConnectedTrueDataHome.error &&
                    requestConnectedHome?.slice(0, 4).map((connected, index) => (
                        <ConnectedCards
                          connected={connected}
                          handleHideProfile={handleHideProfile}
                          handleLike={handleLike}
                          modalData={modalData}
                          setModalData={setModalData}
                          handleGetSingleProfile={handleGetSingleProfile}
                          singleProfileData={singleProfileData}
                          currentId={currentId}
                          setCurrentId={setCurrentId}
                          likeData={likeData}
                          setMessageData={setMessageData}
                        />
                      ))}
                  </div>
                </div>
              {/* <div className='connect-suggestion-view'> */}
              {connectRequestConnectedTrueDataHome?.data?.count > 4 ? (
                  <div className="connect-suggestion-view">
                    <Link to={`/businessseller/manage-connect/?id=${id}`}>
                      <p>View All</p>
                    </Link>
                  </div>
                ) : null}
            {/* <Link to={`/businessseller/manage-connect/?id=${id}`}>
                <p>View All to Connect</p>
          </Link> */}
    {/* </div> */}
    </>
  )
}

export default ConnectedSection
