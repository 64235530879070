import { useEffect } from "react";
import { createContext, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";

const SocialContext = createContext();

export function SocialProvider({ children }) {
  const { username } = useParams();

  const { user } = useSelector((state) => state.currentUser);
  const [socialDetails, setSocialDetails] = useState([]);
  const [visitor, setVisitor] = useState(
    username?.toLowerCase() === user.username.toLowerCase() ? false : true
  );
  const addSocialDetails = (user) => {
    setSocialDetails((prevState) => [{ user }]);
  };

  useEffect(() => {
    setVisitor(
      username?.toLowerCase() === user.username.toLowerCase() ? false : true
    );
  }, [username, user.username]);

  return (
    <SocialContext.Provider
      value={{
        socialDetails,
        addSocialDetails,
        visitor,
        setVisitor,
        username,
      }}>
      {children}
    </SocialContext.Provider>
  );
}

export default SocialContext;
