import React, {  useState, useContext  } from "react";
import VisaCardLogo from "../../../../assets/img/visa-inside-card.png"
import Right from "../../../../assets/svg/arrow-right (1).svg"
import Left from "../../../../assets/svg/arrow-right.svg"
import "./currentCard.scss"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

const VisaCard = () => {

  const sliderSettings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  const currentCards = [
    {
      imageSrc: VisaCard,
      owner: 'Howard Pinsky',
      dots: '....',
      number: '3294',
    },
    {
      imageSrc: VisaCard,
      owner: 'Web Well',
      dots: '....',
      number: '3294',
    },
    {
      imageSrc: VisaCard,
      owner: 'John Doe',
      dots: '....',
      number: '3294',
    },
  ]

  return(
    <div className="current-card-carousel">
        <div className="current-card visa-card" >
          <div className="visalogo">
              <img src={VisaCardLogo} />
          </div>
              <div className="card-number">
                <p className="dots">....</p>
                <p className="dots">....</p>
                <p className="dots">....</p>
                <p className="num">3299</p>
            </div>
              <h3>Howard Pinsky</h3>
        </div>
    </div>
  )
}

export default VisaCard
