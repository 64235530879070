import {
  USER_LOGIN,
  USER_REGISTER,
  LOGIN_ERROR,
  PASSWORD_RESET,
  REG_ERROR,
  USER_ACTIVATE,
  TOGGLE,
} from "../actions";

const initialState = {
  isLoginError: "",
  password_reset_message: "",
  isRegisteredError: null,
  isRegistered: false,
  toggle: 0,
  activated: "",
  payLoad: "",
  signIn: {
    isSignedIn: false,
    payload: {},
  },
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_LOGIN:
      return {
        signIn: {
          ...state,
          isSignedIn: action.isSignedIn,
          payload: action.payload,
        },
      };
    case LOGIN_ERROR:
      return {
        ...state,
        isLoginError: action.payload,
      };
    case USER_REGISTER:
      return {
        ...state,
        isRegistered: action.isRegistered,
        payLoad: action.payLoad,
      };
    case USER_ACTIVATE:
      return {
        ...state,
        activated: action.payload,
      };
    case REG_ERROR:
      return {
        ...state,
        isRegisteredError: action.payload,
      };
    case PASSWORD_RESET:
      return {
        ...state,
        password_reset_message: action.payload,
      };
    case TOGGLE:
      return {
        ...state,
        toggle: action.payload,
      };
    default:
      return state;
  }
};
