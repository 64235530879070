import React, {  useState, useContext  } from "react";
import PaypalLogo from "../../../../assets/svg/paypal-color-large(1).svg"
import Right from "../../../../assets/svg/arrow-right (1).svg"
import Left from "../../../../assets/svg/arrow-right.svg"
import Edit from "../../../../assets/svg/edit.svg"
import Trash from "../../../../assets/svg/trash-white.svg"
import "./paypalCard.scss"

const CurrentPayPalCard = () => {

  return(
    <div className="paypal-card-wallet">
      <div className="current-card-wallet" >
        <div className="wallet-edit">
          <div className="img-delete">
            <img src={Trash} />
          </div>
        </div>
        <div className="paypal-logo">
            <img src={PaypalLogo} />
              <h3 className="paypal-name">Samuel Jackson</h3>
        </div>

      </div>

    </div>
  )
}

export default CurrentPayPalCard
