import { useEffect, useState } from "react";
import { LoaderIcon } from "react-hot-toast";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/loader";
import useSettings from "../../../../../hooks/settings";
import { BusinessPageNotificationUtils } from "../../../utils/businessPageNotification";
import rebuild from "../priavateSellerComponents/rebuild";
import RebuildAltered from "../priavateSellerComponents/rebuildAltered";
import rebuildForSubmission from "../priavateSellerComponents/rebuildForSubmission";
import RebuildObj from "../priavateSellerComponents/rebuildObj";
import BookingBusinessNotificationItem from "./bookingBusinessNotificationItem";
import GeneralBusinessNotificationItem from "./generalBusinessNotificationItem";
import JobBusinessNotificationItem from "./jobBusinessNotificationItem";
import ListingBusinessNotificationItem from "./listingBusinessNotificationItem";
import QueueBusinessNotificationItem from "./queueBusinessNotificationItem";

const BusinessPageNotification = ({ businessCount, activeState }) => {
  const {
    handlePrivateSellerNotificationPatchData,
    privateSellerNotificationPatchData,
    handleGetPageSpecificData,
    pageSpecificData,
  } = useSettings();

  const currentUserId = useSelector(
    (state) => state.currentUser.user?.pages[businessCount]?.id
  );

  const currentUserDat = useSelector(
    (state) =>
      state.currentUser.user.pages[businessCount]?.business_settings
        ?.notification
  );

  const [dataEntered, setDataEntered] = useState({});

  const [active, setActive] = useState(1);

  const [dataToSend, setDataToSend] = useState({});

  useEffect(() => {
    handleGetPageSpecificData(currentUserId);
  }, [activeState, businessCount, privateSellerNotificationPatchData?.data]);

  useEffect(() => {
    pageSpecificData?.data &&
      setDataEntered({
        ...dataEntered,
        general: RebuildObj(
          pageSpecificData?.data?.business_settings?.notification,
          "Notif"
        ),
        job: RebuildAltered(
          pageSpecificData?.data?.business_settings?.notification,
          "Job"
        ),
        listing: RebuildAltered(
          pageSpecificData?.data?.business_settings?.notification,
          "Listing"
        ),
        queue: RebuildAltered(
          pageSpecificData?.data?.business_settings?.notification,
          "Queue"
        ),
        booking: RebuildAltered(
          pageSpecificData?.data?.business_settings?.notification,
          "Booking"
        ),
      });
  }, [businessCount, pageSpecificData?.data]);

  console.log("this is current", dataEntered);

  useEffect(() => {
    setDataToSend(rebuildForSubmission(dataEntered));
  }, [dataEntered]);

  console.log(dataToSend, "the data");
  console.log(pageSpecificData?.data, "the page");

  return pageSpecificData?.data ? (
    <div className="businessPage_notification">
      <div className="generalBusiness_notification">
        <ul>
          {dataEntered?.general?.map((generalNotificationItem, idx) => {
            return (
              <GeneralBusinessNotificationItem
                idx={idx}
                generalNotificationItem={generalNotificationItem}
                dataEntered={dataEntered}
                setDataEntered={setDataEntered}
              />
            );
          })}
        </ul>
      </div>
      <div className="otherNotificationSettings">
        <div className="tabs">
          <p
            className={`${active === 1 && "active"}`}
            onClick={() => setActive(1)}
          >
            Job
          </p>
          <p
            className={`${active === 2 && "active"}`}
            onClick={() => setActive(2)}
          >
            Listing
          </p>
          <p
            className={`${active === 3 && "active"}`}
            onClick={() => setActive(3)}
          >
            Queue
          </p>
          <p
            className={`${active === 4 && "active"}`}
            onClick={() => setActive(4)}
          >
            Booking
          </p>
        </div>
        <div className="tabContent">
          <ul>
            {active === 1 &&
              dataEntered.job?.map((jobNotificationItem, idx) => {
                return (
                  <JobBusinessNotificationItem
                    idx={idx}
                    jobNotificationItem={jobNotificationItem}
                    dataEntered={dataEntered}
                    setDataEntered={setDataEntered}
                  />
                );
              })}
            {active === 2 &&
              dataEntered.listing?.map((listingNotificationItem, idx) => {
                return (
                  <ListingBusinessNotificationItem
                    listingNotificationItem={listingNotificationItem}
                    idx={idx}
                    dataEntered={dataEntered}
                    setDataEntered={setDataEntered}
                  />
                );
              })}
            {active === 3 &&
              dataEntered.queue?.map((queueNotificationItem, idx) => {
                return (
                  <QueueBusinessNotificationItem
                    queueNotificationItem={queueNotificationItem}
                    idx={idx}
                    dataEntered={dataEntered}
                    setDataEntered={setDataEntered}
                  />
                );
              })}
            {active === 4 &&
              dataEntered.booking?.map((bookingNotificationItem, idx) => {
                return (
                  <BookingBusinessNotificationItem
                    bookingNotificationItem={bookingNotificationItem}
                    idx={idx}
                    dataEntered={dataEntered}
                    setDataEntered={setDataEntered}
                  />
                );
              })}
          </ul>
        </div>
      </div>
      <div className="save">
        (
        <span
          className="saveButton"
          onClick={() => {
            console.log("clivk");
            handlePrivateSellerNotificationPatchData({
              id: currentUserId,
              data: dataToSend,
              type: "business",
            });
          }}
        >
          {privateSellerNotificationPatchData.loading ? (
            <Loader />
          ) : (
            <span>Save</span>
          )}
        </span>
        )
      </div>
    </div>
  ) : (
    <Loader />
  );
};
export default BusinessPageNotification;
