import { useContext, useState, useEffect } from "react";
import "./index.scss";
import ModalContext from "../../../../hooks/modalContexts";
import CloseBtn from "../../../closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { useLocation } from "react-router-dom";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import SalesGrowthChart from "../../../Analytics/components/salesGrowthConnectModal";
import { Line } from "react-chartjs-2";
import {
  chartData2,
  chartOptions2,
} from "../../../Analytics/chartDetails";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import AreaChart from "../../../Analytics/Charts/areaChartPurchaseListing";
import useAnalytics from "../../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../../hooks/exploreContext'
import LoaderPurple from "../../../../assets/loaders/LoaderPurple"

const CustomerConversionConnectModal = () => {
  // /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const { setCustomerConversionConnectModal } = useContext(ModalContext);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)


  const { search } = useLocation();
  const { id } = qs.parse(search);

  const {
    analyticsConnectCustomerConvertedData,
    handleGetAnalyticsConnectCustomerConverted,

    analyticsConnectChartData,
    handleGetAnalyticsConnectChart,
  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsConnectCustomerConverted(id)
    handleGetAnalyticsConnectChart(id)
    // handleGetAnalyticsGeneral(id)
  }, [])

  const testArray = [1, 2, 3, 4, 5, 6, 7];

const customerConvertedConnect = analyticsConnectCustomerConvertedData?.data?.results
const connectChart =  analyticsConnectChartData?.data

  return (
    <div
      className="generalAnalyticModal"
      onClick={(e) => setCustomerConversionConnectModal(false)}
    >
      <div className="generalAnalyticModalContainer">
        <div
          onClick={(e) => setCustomerConversionConnectModal(false)}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="generalAnalyticModalContainered"
        >
          <div className="generalAnalyticModal-header">
            <h4> Customers Converted </h4>
            <div className="datepicker-container">
            <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsConnectCustomerConverted(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="generalAnalyticModal-body">
            <div className="gam-content-one">
              <div className="item__scroll2">
                {analyticsConnectCustomerConvertedData?.loading && analyticsConnectCustomerConvertedData?.data?.count !== 0 && <LoaderPurple />}
                {customerConvertedConnect?.map((data, idx) => (
                  <div className="lcob-details">
                    <div className="details-box-one">
                      <h4>{idx + 1}</h4>
                         <img src={data?.featured_image.file ? data?.featured_image.file : itemImg} alt="Listing"  />
                      <div className="details-box-two">
                      <h5>{data?.job_title}</h5>
                      </div>
                    </div>

                    <div className="lcob-chart2">
                        <p>
                          {data?.summary.summary.percentage}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                        </p>
                      <div>
                      <AreaChart data={data}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="amb-statistics">
              <SalesGrowthChart title="Connect Growth" connectChart={connectChart}  />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CustomerConversionConnectModal;