import "./sendAsMessage.scss";
import { IoMdClose } from "react-icons/all";
import SearchBar from "../../searchBar";
import SelectedPersons from "../../selectedPersons";
import CustomSelect from "../../customSelect";
import { DefaultButton } from "../../Button";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import ModalContext from "../../../hooks/modalContexts";
import useUser from "../../../hooks/user";
import { useRef } from "react";
import LoaderPurple from "../../../assets/loaders/LoaderPurple";
import useAxios from "axios-hooks";
const SendAsMessage = () => {
  const { setSendAsMessage, sendAsMessage } = useContext(ModalContext);
  const { handleGetRelatedUsers, relatedUsersData } = useUser();
  const [selected, setSelected] = useState([]);
  const [users, setUsers] = useState([]);
  const handleAdd = useCallback(
    (user) => {
      const person = selected.find((person) => person.id === user.id);
      if (person) {
        // remove person
        setSelected((state) => state.filter((data) => data.id !== user.id));
      } else {
        //add person
        console.log(selected);
        setSelected((state) => [user, ...state]);
      }
    },
    [relatedUsersData.data, selected]
  );
  const toBeObserved = useRef();
  const availableUsersRef = useRef();

  const observer = useMemo(() => {
    return new IntersectionObserver(
      ([entries]) => {
        console.log(entries.isIntersecting);
        if (relatedUsersData.data?.next && entries.isIntersecting) {
          handleGetRelatedUsers(relatedUsersData.data?.next);
          observer.unobserve(toBeObserved.current);
        }
      },
      {
        root: availableUsersRef.current,
        rootMargin: "0px",
        threshold: 1.0,
      }
    );
  }, [relatedUsersData.data]);

  useEffect(() => {
    if (relatedUsersData.data) {
      setUsers((state) => {
        const notExistsInList = relatedUsersData.data.results.filter(
          (data) => !state.map((data) => data.id).includes(data.id)
        );
        return [...state, ...notExistsInList];
      });
    }
    if (
      (relatedUsersData.data?.next || !relatedUsersData.data) &&
      toBeObserved.current
    ) {
      observer.observe(toBeObserved.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [relatedUsersData.data]);

  useEffect(() => {
    handleGetRelatedUsers(null, { limit: 4 });
  }, []);

  const [{ ...messagingData }, sendMessage] = useAxios(
    {
      method: "post",
      url: "/messenger/bulk_message/",
    },
    {
      manual: true,
    }
  );

  const handleSendBulk = useCallback(
    (e) => {
      e.preventDefault();
      sendMessage({
        data: {
          receivers: selected.map((data) => data.id),
          attachments: sendAsMessage,
          // message: "",
        },
      });
    },
    [sendAsMessage, selected]
  );
  return (
    <div
      className="send-as-message-gallery"
      onClick={(e) => setSendAsMessage()}>
      <form
        className="main"
        onClick={(e) => e.stopPropagation()}
        onSubmit={handleSendBulk}>
        {!messagingData.loading && !messagingData.data && (
          <>
            <div className="box-header">
              <h2>Send As Message</h2>
              <SearchBar
                searchfunction={(value) => {
                  //clear users
                  if (value.length) {
                    observer.disconnect();
                    setUsers([]);
                  }
                  handleGetRelatedUsers(null, { limit: 5, search: value });
                }}
              />
              <div className="cancel-x" onClick={(e) => setSendAsMessage()}>
                <IoMdClose />
              </div>
            </div>
            <SelectedPersons
              data={selected}
              onRemove={(value) => {
                setSelected((state) =>
                  state.filter((data) => data.id !== value)
                );
              }}
            />
            {/* <div className="select-all">
          Select all
          <CustomSelect />
        </div> */}
            <div className="available-users" ref={availableUsersRef}>
              {users.map((data) => {
                return (
                  <div className="persons" key={data.id}>
                    <div className="avatar">
                      <img src="" alt="" />
                    </div>
                    <div className="name">
                      <div className="full-name">
                        {data.first_name} {data.last_name}
                      </div>
                      <div className="username">@{data.username}</div>
                    </div>
                    <div className="select-wrap">
                      <CustomSelect
                        controlled={true}
                        onClick={handleAdd.bind(this, data)}
                        defaultState={selected
                          .map((data) => data.id)
                          .includes(data.id)}
                      />
                    </div>
                  </div>
                );
              })}
              <span ref={toBeObserved} style={{ padding: 5 }}>
                {relatedUsersData.loading && (
                  <LoaderPurple style={{ width: 10, height: 10 }} />
                )}
              </span>
            </div>
            <div className="send-btn">
              <DefaultButton
                customStyle={{
                  borderRadius: 30,
                  backgroundColor: "var(--dark-purple-bg)",
                  padding: "5px 15px",
                  fontSize: 14,
                  marginTop: 20,
                  display: "block",
                  marginLeft: "auto",
                  marginRight: 14,
                }}
                text="Send"
              />
            </div>
          </>
        )}
        {messagingData.loading && (
          <LoaderPurple style={{ width: 40, height: 40 }} />
        )}
        {messagingData.data && (
          <div className="sent">
            <p>Sent</p>
            <DefaultButton
              customStyle={{
                padding: "5px 8px",
                fontSize: 12,
              }}
              onClick={(e) => {
                e.preventDefault();
                setSendAsMessage();
              }}>
              Done
            </DefaultButton>
          </div>
        )}
      </form>
    </div>
  );
};
export default SendAsMessage;
