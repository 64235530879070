import {
  TOGGLE_FULL_LEFT_BAR,
  TOGGLE_FULL_RIGHT_BAR,
  TOGGLE_MY_PAGES,
  TOGGLE_SUGGESTED_LISTINGS,
  TOGGLE_SUGGESTED_USERS,
} from "./index.types";

const defaultSettings = {
  rightBar: true,
  leftBar: true,
  suggestedUsers: true,
  suggestedListings: true,
  myPages: false,
};

const navToggle = (state = defaultSettings, { type, payload }) => {
  switch (type) {
    case TOGGLE_FULL_LEFT_BAR:
      return {
        ...state,
        leftBar: payload,
      };
    case TOGGLE_FULL_RIGHT_BAR:
      return {
        ...state,
        rightBar: payload,
      };
    case TOGGLE_SUGGESTED_LISTINGS:
      return {
        ...state,
        suggestedListings: payload,
      };
    case TOGGLE_SUGGESTED_USERS:
      return {
        ...state,
        suggestedUsers: payload,
      };
    case TOGGLE_MY_PAGES:
      return {
        ...state,
        myPages: payload,
      };
    default:
      return state;
  }
};

export default navToggle;
