import {
  CREATE_SINGLE_COMMENT_DATA,
  NEW_COMMENT_LIST,
  NEW_REPLY_LIST,
  ADD_COMMENT,
  ADD_REPLY,
  RESET_SINGLE_COMMENT_DATA,
  UPDATE_SINGLE_COMMENT,
  UPDATE_SINGLE_COMMENT_COMMENT,
  UPDATE_REPLY_LIST,
  UPDATE_REPLY,
} from "./index.types";

const defaultData = {
  comment: false,
};

const singleComment = (state = defaultData, { type, payload }) => {
  switch (type) {
    case CREATE_SINGLE_COMMENT_DATA:
      return {
        ...state,
        comment: payload,
      };
    case RESET_SINGLE_COMMENT_DATA:
      return {
        ...state,
        comment: false,
      };
    case UPDATE_SINGLE_COMMENT:
      return state.comment && state.comment?.id === payload.id
        ? {
            ...state,
            comment: {
              ...payload,
              recent_replies: state.comment.recent_replies,
            },
          }
        : state;
    case UPDATE_SINGLE_COMMENT_COMMENT:
      return state.comment && state.comment?.id === payload.comment
        ? {
            ...state,
            comment: {
              ...state.comment,
              recent_replies: [
                !payload.parent && payload,
                ...state.comment.recent_replies,
              ].filter((data) => data && data),
            },
          }
        : state;

    case NEW_COMMENT_LIST:
      return {
        ...state,
        comment: {
          ...state.comment,
          recent_replies: payload,
        },
      };
    case UPDATE_REPLY_LIST:
      return {
        ...state,
        comment: {
          ...state.comment,
          recent_replies: [...state.comment.recent_replies, ...payload],
        },
      };
    case ADD_REPLY:
      return payload.parent === state.comment.id
        ? {
            ...state,
            comment: {
              ...state.comment,
              recent_replies: [payload, ...state.comment.recent_replies],
            },
          }
        : state;
    case UPDATE_REPLY:
      return {
        ...state,
        comment: {
          ...state.comment,
          recent_replies: state.comment.recent_replies.map((data) =>
            payload.id === data.id ? payload : data
          ),
        },
      };
    default:
      return state;
  }
};

export default singleComment;
