import { ADD_DATA, RESET_DATA } from "./index.types";
const defaultState = null;

const toBeEdited = (state = defaultState, { type, value }) => {
  switch (type) {
    case ADD_DATA:
      return value;
    case RESET_DATA:
      return null;
    default:
      return state;
  }
};

export default toBeEdited;
