import moment from "moment";
import React, { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router";
import CalendarIcon from "../../../../../assets/svg/calendarIcon";
import DelIcon from "../../../../../assets/svg/delIcon";
import DeliveryIcon from "../../../../../assets/svg/deliveryIcon";
import Pen from "../../../../../components/pen";
import ModalContext from "../../../../../hooks/modalContexts";

const DeliveryCard = ({
  fill = "var(--text-light-grey)",
  data = {},
  id,
  deliveryListState = [],
}) => {
  const { pathname } = useLocation();
  const [deliveryList, setDeliveryList] = deliveryListState;
  const [deliveryMode, setDeliveryMode] = useState({
    door: false,
    pickUp: false,
  });
  const {
    deliveryToBeEditedState,
    setPrivateSellerDelivery,
    setBusinessSellerDelivery,
  } = useContext(ModalContext);
  const [toBeEdited, setToBeEdited] = deliveryToBeEditedState;
  useEffect(() => {
    if (data?.locations?.length) {
      data?.locations
        .map((data) => data?.delivery_mode === "PICK_UP" && data)
        .filter((data) => data && data).length &&
        setDeliveryMode({ ...deliveryMode, pickUp: true });
      data?.locations
        .map((data) => data?.delivery_mode === "DOOR_DELIVERY" && data)
        .filter((data) => data && data).length &&
        setDeliveryMode({ ...deliveryMode, door: true });
    }
  }, [data.locations]);
  // console.log(deliveryMode);

  //trigger modal open once item to be updated has data
  useEffect(() => {
    if (toBeEdited) {
      pathname.toLowerCase().includes("private")
        ? setPrivateSellerDelivery(true)
        : setBusinessSellerDelivery(true);
    }
  }, [toBeEdited]);
  return (
    <div
      style={{
        background: fill,
      }}
      className="deliveryCard">
      <div className="deliveryCard_head">
        <DeliveryIcon />
        <div className="deliveryCard_head_title">
          <h4>{data?.city?.address}</h4>
          <p>
            {/* {data?.delivery_mode === "PICK_UP" ? "pick up" : "door delivery"} */}
            {deliveryMode?.pickUp && "Pick up"}{" "}
            {deliveryMode?.door && deliveryMode.pickUp && `and Door delivery`}{" "}
            {deliveryMode?.door && !deliveryMode.pickUp && "Door delivery"}
          </p>
        </div>
      </div>
      <p className="closingDate">
        <CalendarIcon />
        <span>Closing date</span>
      </p>
      <div className="deliveryCard_bottom">
        {/* <p>{moment(data?.dates[0].end).format("LL LT")}</p>
        <p>{data?.time}</p> */}
        <span
          onClick={(e) => {
            //remove from delivery list
            setDeliveryList(
              deliveryList
                ?.map((data, idx) => {
                  if (idx !== id) return data;
                  return false;
                })
                .filter((data) => data && data)
            );
          }}>
          <DelIcon fill={"white"} />
        </span>

        <span
          onClick={(e) => {
            console.log(data);
            //set to be edited
            setToBeEdited(data);
          }}>
          <Pen fill={"white"} />
        </span>
      </div>
    </div>
  );
};
export default DeliveryCard;
