import { useContext, useEffect, useState, useRef } from "react";
import "./bookingRequestModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import CustomDropdown from "../../customDropdown/customDropdown";
// import CustomDropdown from "./brDropdown";
import profileImg from "../../../assets/img/profilePic.png";
import "react-datepicker/dist/react-datepicker.css";
import { IoMdCloseCircleOutline } from "react-icons/all";
import { BiInfoCircle } from "react-icons/bi";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import CloseBtn from "../../../components/closeBtn";
import { DebounceInput } from "react-debounce-input";
import useBooking from "../../Bookings/useBooking";
import useBookingRequest from "../../Bookings/useBookingRequest";
import moment from "moment";
import { FaChevronDown, FaChevronUp, FaAngleDown } from "react-icons/fa";
import Select from "react-select";

const BookingListModal = () => {
  const { handleBookingListModal } = useContext(ModalContext);

  const {
    handleGetPages,
    getPagesData,
    handleGetServices,
    servicesData,
    handleGetPersonnels,
    personnelData,
    handleGetServiceLocations,
    serviceLocationsData,
    handleGetPersonnelTimeSlot,
    personnelTimeSlotData,
    pageServicesData,
    handleGetPageServices,
  } = useBooking();

  const { createBookingRequestData, handleCreateBookingRequest } =
    useBookingRequest();
  const servicedropdown = servicesData?.data?.results;
  const [isOpen, setOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(servicedropdown);

  const [value, setValue] = useState("");

  console.log(value);
  const handleServiceChange = () => {
    setValue(servicedropdown.title);
    setServiceId(servicedropdown.id);
  };

  useEffect(() => {
    setValue(value);
  }, [value]);

  const toggleDropdown = (e) => setOpen(!isOpen);

  const [serviceId, setServiceId] = useState("");
  console.log("new service id", serviceId);
  console.log("new service id", servicedropdown);
  const [text, setText] = useState("");
  const [personnelId, setPersonnelId] = useState("");
  const [serviceDate, setServiceDate] = useState(new Date());
  // const [value, setValue] = useState(servicedropdown[0]?.title);

  // console.log(value);

  // const handleServiceChange = () => {
  //   setValue(servicedropdown.title);
  //   setBookingRequestForm({
  //     ...bookingRequestForm,
  //     booking: {
  //       ...bookingRequestForm.booking,
  //       service: `${servicedropdown[0].id}`,
  //     },
  //   });
  //   // handleGetPersonnels(e.id);
  // };

  const initialFormState = {
    customer: text,
    booking: {
      service: null,
      service_price: "",
      service_date: serviceDate,
      delivery_location: {
        address: "4508 Wisoky Isle",
        point: {
          type: "Point",
          coordinates: [14.391, -59.1824],
        },
      },
      time_slot: {
        opening_time: "",
        closing_time: "",
      },
      personnel: "",
    },
  };

  const [bookingRequestForm, setBookingRequestForm] =
    useState(initialFormState);

  const [filteredData, setFilteredData] = useState([]);
  const [formErrors, setFormErrors] = useState("");
  const [personnel, setPersonnel] = useState("");

  const handleSearch = (e) => {
    const { value } = e.target;
    handleGetPages({ search: value, sudo: false });
  };

  const handleChange = (text) => {
    let matches = [];
    if (text.length > 0) {
      matches = getPagesData?.data?.results?.filter((user) => {
        const regex = new RegExp(`${text}`, "gi");
        return user.username.match(regex);
      });
    }
    // const searchUser = e.target.value;
    // const newFilter = getPagesData?.data?.results?.filter((user) => {
    //   return user.username.toLowerCase().includes(searchUser.toLowerCase());
    // });
    console.log(matches);
    setFilteredData(matches);
    setBookingRequestForm({ ...bookingRequestForm, customer: text });
  };

  const handleChange2 = (event) => {
    let value = event.target.value.toLowerCase();
    let results = [];

    results = getPagesData?.data?.results?.filter((user) => {
      return user.username.search(value) !== -1;
    });

    setFilteredData(results);
  };

  const handleFilter = (event) => {
    const searchWord = event.target.value;
    const newFilter = getPagesData?.data?.results?.filter((user) => {
      return user?.username?.toLowerCase().includes(searchWord.toLowerCase());
      //console.log(user.username)
    });

    if (searchWord === "") {
      setFilteredData([]);
    } else {
      setFilteredData(newFilter);
      setBookingRequestForm({ ...bookingRequestForm, customer: searchWord });
    }
  };
  const handleSuggestion = (text) => {
    setText(text);
    setBookingRequestForm({ ...bookingRequestForm, customer: text });
    setFilteredData([]);
  };

  useEffect(() => {
    handleGetPages();
    handleGetServices();
    handleDateSelect();
  }, []);
  console.log(getPagesData?.data?.results);

  const validate = (values) => {
    let errors = {};

    if (!values.username) {
      errors.username = "please enter personnel username";
    }
    // if (!values.title) {
    //   errors.title = "please enter personnel title";
    // }
    return errors;
  };

  // useEffect(() => {
  //   if (getPagesData.data?.results?.filter((e) => text !== e.username)) {
  //     setFormErrors("username not found");
  //   } else setFormErrors("This username found");

  //   console.log(formErrors);
  // }, [text, getPagesData.data?.results]);

  console.log(bookingRequestForm);
  console.log(personnelTimeSlotData);

  const handleDateSelect = () => {
    const allPersonnel = servicesData.data?.results?.forEach(
      (personnelList) => {
        const allp2 = personnelList.personnel.forEach((personnel) => {
          console.log(personnel);
          personnel.availability.forEach((data) => {
            console.log(data);
            const availabilityDate = data.day;
            console.log(availabilityDate);
          });
        });
      }
    );
  };
  useEffect(() => {
    handleDateSelect();
    // setBookingRequestForm((prevState) => ({
    //   ...prevState,
    //   booking: {
    //     ...bookingRequestForm.booking,
    //     service_date: serviceDate,
    //   },
    // }));
  }, []);

  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    setBookingRequestForm((prevState) => ({
      ...prevState,
      booking: {
        ...bookingRequestForm.booking,
        delivery_location: {
          ...bookingRequestForm.booking.delivery_location,
          [name]: value,
        },
      },
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateBookingRequest(bookingRequestForm);
    // if (formErrors) {
    // setFormErrors(validate(personnelForm));
    // } else
    // !Object.keys(validate(personnelForm)).length &&
    // setUserPersonnel([...userPersonnel, personnelForm]);
  };

  return (
    <div
      onClick={() => {
        handleBookingListModal("RESET_DATA");
      }}
      className="blModalContainer"
    >
      <div className="blModal-content">
        <div
          onClick={() => {
            handleBookingListModal("RESET_DATA");
          }}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="blModalContainered"
        >
          <div className="blModal-header">
            <h4>
              {" "}
              Send booking request{" "}
              {formErrors && <small className="error">{formErrors}</small>}
            </h4>
          </div>
          <div className="blModal-body">
            <div className="input-group">
              <label htmlFor="">Search an existing user </label>
              {/* <input
                className="form-input"
                type="text"
                placeholder="Search..."
                onChange={handleFilter}
              /> */}
              <DebounceInput
                debounceTimeout={500}
                className="form-input"
                type="text"
                placeholder="Search username..."
                value={text}
                onChange={handleFilter}
                // onBlur={() => {
                //   setTimeout(() => {
                //     setSuggestions([]);
                //   }, 100);
                // }}
              />
              <br />
            </div>
            {filteredData.length > 0 && (
              <div className="suggestion-box">
                {filteredData.length !== 0 &&
                  filteredData.map((suggestion, idx) => (
                    <div
                      className="bl-box"
                      key={idx}
                      onClick={() => handleSuggestion(suggestion.username)}
                    >
                      <img
                        src={suggestion.featured_image?.file || profileImg}
                        alt=""
                      />
                      <div className="blb-details">
                        <h5> {suggestion.name} </h5>
                        <h6> @{suggestion.username} </h6>
                      </div>
                      <IoMdCloseCircleOutline />
                    </div>
                  ))}
              </div>
            )}
            {/*---------  SCHEDULE CONTENTS ------------------*/}

            {servicesData.loading && <p>Loading..</p>}

            {servicesData?.data?.results && (
              <>
                <div className="schedule-container">
                  <h4> Schedule With </h4>
                  <div className="select-drop">
                    <div className="dropbox">
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                          FaAngleDown,
                        }}
                        onChange={(e) => {
                          setBookingRequestForm({
                            ...bookingRequestForm,
                            booking: {
                              ...bookingRequestForm.booking,
                              service: e?.id,
                              service_price: e?.booking_fee?.id,
                            },
                          });
                          handleGetPersonnels(e.id);
                          handleGetServiceLocations(e.id);
                        }}
                        options={servicesData?.data?.results?.map((item) => {
                          // console.log(item.name);
                          return { ...item, label: item.title };
                        })}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder={"select service"}
                        // name={profileForm.business_category}
                      />
                    </div>

                    {/* {personnelData?.data?.results && ( */}
                    <div className="dropbox">
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                          FaAngleDown,
                        }}
                        onChange={(e) => {
                          setBookingRequestForm({
                            ...bookingRequestForm,
                            booking: {
                              ...bookingRequestForm.booking,
                              personnel: e.id,
                            },
                          });
                          handleGetPersonnelTimeSlot(e.id, e.available_from);
                          setPersonnelId(e.id);
                        }}
                        options={personnelData?.data?.results?.map((item) => {
                          return { ...item, label: item?.user?.username };
                        })}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder={"select personnel"}
                        // name={profileForm.business_category}
                      />
                    </div>
                    {/* )} */}
                  </div>
                </div>

                {/*---------  SET DATE/TIME CARD CONTENTS ------------------*/}
                <div className="setdate-container">
                  <h4> Set Date/Time</h4>
                  <div className="sdc-box">
                    <div className="calendar">
                      <Calendar
                        value={serviceDate}
                        onChange={(date) => {
                          setServiceDate(date);
                          handleDateSelect();
                          setBookingRequestForm({
                            ...bookingRequestForm,
                            booking: {
                              ...bookingRequestForm.booking,
                              service_date: date,
                            },
                          });
                          handleGetPersonnelTimeSlot(
                            personnelId,
                            moment(date).format("YYYY-MM-DD")
                          );
                        }}
                      />
                    </div>
                    <div className="time">
                      <div className="time-header">
                        {!personnelTimeSlotData.loading &&
                          !personnelTimeSlotData.error &&
                          personnelTimeSlotData?.data?.count > 0 && (
                            <>
                              <h4>
                                {`${moment(serviceDate).format("dddd")}`}{" "}
                              </h4>
                              <h5>09:00am - 6:00pm </h5>{" "}
                            </>
                          )}
                      </div>
                      <div className="slide-container">
                        {!personnelTimeSlotData.loading &&
                          !personnelTimeSlotData.error &&
                          personnelTimeSlotData?.data?.count === 0 && (
                            <h3>Personnel is not available</h3>
                          )}
                        <div className="item__scroll">
                          <div className="time-body">
                            {!personnelTimeSlotData.loading &&
                              !personnelTimeSlotData.error &&
                              personnelTimeSlotData?.data?.results?.map(
                                (personnel, idx) => (
                                  <>
                                    <h6
                                      className="active"
                                      key={idx}
                                      onClick={() => {
                                        setBookingRequestForm({
                                          ...bookingRequestForm,
                                          booking: {
                                            ...bookingRequestForm.booking,
                                            time_slot: {
                                              opening_time: personnel.opening_time,
                                              closing_time: personnel.closing_time,
                                            },
                                          },
                                        });
                                      }}
                                    >
                                      {`${moment(personnel.opening_time).format(
                                        "h:mm a"
                                      )}`}
                                    </h6>
                                  </>
                                )
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------  ADDRESS CARD CONTENTS ------------------*/}
                <div className="svl-container">
                  <p>Service Location </p>

                  <div className="svlc-box">
                    <div className="dropbox">
                      <Select
                        components={{
                          IndicatorSeparator: () => null,
                          FaAngleDown,
                        }}
                        onChange={(e) => {
                          setBookingRequestForm({
                            ...bookingRequestForm,
                            booking: {
                              ...bookingRequestForm.booking,
                              personnel: e.id,
                            },
                          });
                        }}
                        options={serviceLocationsData?.data?.results?.map(
                          (item) => {
                            return { ...item, label: item?.address };
                          }
                        )}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder={"location"}
                      />
                    </div>
                    <div className="svl-address">
                      <input
                        className="svl-input"
                        type="text"
                        name="address"
                        value={
                          bookingRequestForm.booking.delivery_location.address
                        }
                        placeholder="Service Address"
                        onChange={handleAddressChange}
                      />
                      <span>
                        <BiInfoCircle />
                        You cannot add your address, this booking must be in
                        sellers location
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}

            {!servicesData.loading &&
              !servicesData.error &&
              !servicesData?.data?.results && (
                <p className="error-txt">
                  No Services Available, Please create a service
                </p>
              )}

            <div className="blModal-footer">
              <button
                onClick={() => {
                  handleBookingListModal("RESET_DATA");
                }}
                className="btn-back"
                disabled={createBookingRequestData.loading}
              >
                Back
              </button>

              <button className="btn-post" onClick={handleSubmit}>
                {createBookingRequestData.loading
                  ? "Loading..."
                  : "Book Appointment"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingListModal;
