import {
  IoIosPerson,
  MdExplore,
  FaList,
  MdRoomService,
  MdQueue,
  IoBriefcase,
  IoChatbubbles,
} from "react-icons/all";

import VuesaxLinearArchiveMinusComponent from "../assets/leftBarIcons/VuesaxLinearArchiveMinusComponent";
import VuesaxLinearGalleryComponent from "../assets/leftBarIcons/VuesaxLinearGalleryComponent";
import VuesaxLinearHomeComponent from "../assets/leftBarIcons/VuesaxLinearHomeComponent";
import VuesaxLinearPeopleComponent from "../assets/leftBarIcons/VuesaxLinearPeopleComponent";
import VuesaxLinearSettingComponent from "../assets/leftBarIcons/VuesaxLinearSettingComponent";
import VuesaxBoldSearchFavoriteComponent from "../assets/leftBarIcons/VuesaxBoldSearchFavoriteComponent";
import { useLocation, useParams } from "react-router";
import qs from "query-string";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import usePages from "./createPage";

const useMenu = () => {
  const { accountType, user } = useSelector((state) => state.currentUser);
  const { pathname, search } = useLocation();
  const { singlePageData, handleGetSinglePage } = usePages();

  const [menu, setMenu] = useState([]);
  const { id } = qs.parse(search);
  const { pageId } = useParams();
  console.log(pageId);
  useEffect(() => {
    handleGetSinglePage(pageId || id);
  }, [pageId, id]);
  useEffect(() => {
    const isPrivateSeller = pathname.toLowerCase().includes("privateseller");
    const socialMenu = [
      {
        name: "feeds",
        icon: VuesaxLinearHomeComponent,
        link: "/feeds/trending",
      },
      {
        name: "gallery",
        icon: VuesaxLinearGalleryComponent,
        link: "/gallery",
      },
      {
        name: "settings",
        icon: VuesaxLinearSettingComponent,
        link: "/settings",
      },
      {
        name: "referral",
        icon: VuesaxLinearPeopleComponent,
        link: "/ref",
      },
      {
        name: "saved",
        icon: VuesaxLinearArchiveMinusComponent,
        link: "/saved",
      },
    ];

    const businessMenu = [
      ...(pageId || id
        ? [
            user.id === singlePageData.data?.owner && {
              name: "Dashboard",
              icon: MdExplore,
              link: `/${
                isPrivateSeller ? "privateseller" : "businessseller"
              }/dashboard/?id=${pageId || id}`,
            },
            {
              name: "Profile",
              icon: IoIosPerson,
              link: `/${
                isPrivateSeller ? "privateseller" : "businessseller"
              }/profile/${pageId || id}`,
            },
            {
              name: "Listings",
              link: `/${
                isPrivateSeller ? "privateseller" : "businessseller"
              }/listings/${pageId || id}`,
              icon: FaList,
            },
            ...(singlePageData.data?.type.toLowerCase() === "business"
              ? [
                  {
                    name: "Bookings",
                    icon: MdRoomService,
                    link: `/booking/?id=${pageId || id}`,
                    // link: `/${
                    //   isPrivateSeller ? "privateseller" : "businessseller"
                    // }/bookings/?id=${pageId || id}`,
                    type: "business",
                  },
                  {
                    name: "Queueing",
                    icon: MdQueue,
                    link: `/${
                      isPrivateSeller ? "privateseller" : "businessseller"
                    }/queue-list/?id=${id}`,
                    // }/Queueing/?id=${pageId || id}`,  //radiance's changes
                    type: "business",
                  },
                  {
                    name: "Job",
                    icon: IoBriefcase,
                    link: `/${
                      isPrivateSeller ? "privateseller" : "businessseller"
                    }/Job/?id=${pageId || id}`,
                    type: "business",
                  },
                ]
              : []),
            user.id === singlePageData.data?.owner && {
              name: "Analytics",
              icon: IoBriefcase,
              link: `/${
                isPrivateSeller ? "privateseller" : "businessseller"
              }/analytics/?id=${pageId || id}`,
              type: "business",
            },

            singlePageData.data?.type.toLowerCase() === "business" && {
              name: "Connect",
              icon: IoChatbubbles,
              link: `/${
                isPrivateSeller ? "privateseller" : "businessseller"
              }/connect/?id=${pageId || id}`,
              type: "business",
            },
          ]
        : []),
      {
        name: "Explore",
        icon: MdExplore,
        link: "/explore",
        // mobile: true,
        type: "business",
      },

      {
        name: "Settings",
        icon: VuesaxLinearSettingComponent,
        link: "/settings",
        type: "general",
      },
      {
        name: "Refferal",
        icon: VuesaxLinearPeopleComponent,
        link: "/ref",
        type: "general",
      },
      // {
      //   name: "Saved",
      //   icon: VuesaxLinearArchiveMinusComponent,
      //   link: "/saved",
      //   type: "general",
      // },
      pathname.includes("explore") && {
        name: "my activity",
        icon: VuesaxLinearGalleryComponent,
        link: "/activity",
      },
    ].filter((item) => item && item);
    accountType === "business" ? setMenu(businessMenu) : setMenu(socialMenu);
  }, [id, pageId, singlePageData.data]);

  return { menu };
};

export default useMenu;
