import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { DefaultButton } from "../../Button";
import useAxios from "axios-hooks";
import moment from "moment";
import LoaderPurple from "../../../assets/loaders/LoaderPurple";
import { showToast } from "../../ReactToast";
import { NotificationCacheContext } from ".";

const MinRequest = ({ min = 0, id, isRequest = false, is_seller }) => {
  const { pendingReq, setPendingReq, newRequest, setNewRequest } = useContext(
    NotificationCacheContext
  );
  const [{ data, error, loading: requestLoading, response }, getMoreTime] =
    useAxios(
      {
        method: "post",
      },
      { manual: true }
    );
  const [
    {
      data: acceptDeclineData,
      error: acceptDeclineError,
      loading: acceptDeclineLoading,
      response: acceptDeclineResponse,
    },
    acceptDecline,
  ] = useAxios(
    {
      method: "post",
    },
    { manual: true }
  );

  const requestTime = useCallback(() => {
    getMoreTime({
      url: `/orders/${id}/extend_time_request/`,
      data: {
        duration: moment().add(min, "minutes").format("HH:mm:ss"),
        notes: `Requesting for some more time`,
      },
    });
  }, []);

  const acceptRejectTime = useCallback((status = "Accept") => {
    acceptDecline({
      url: `/orders/${id}/extend_time_respond/${pendingReq.id}/`,
      method: "post",
      data: {
        status,
      },
    });
  });
  useEffect(() => {
    if (data) {
      showToast("success", `Successfully requested for time`);
      setNewRequest();
      return;
    }
    if (response) {
      showToast(
        "error",
        "An unknown error occurred while requesting for extra mins"
      );
      console.log("error");
    }
  }, [data, response]);

  useEffect(() => {
    if (acceptDeclineData) {
      showToast(
        "success",
        `Successfully ${acceptDeclineData.status} extra mins requested`
      );
      setNewRequest();
      return;
    }
    if (acceptDeclineResponse) {
      showToast(
        "error",
        "An unknown error occurred while responding to extra mins requested"
      );
    }
  }, [acceptDeclineData, acceptDeclineResponse]);

  const getTimeDiff = () => {
    return moment(pendingReq.duration, "HH:mm:ss").diff(moment(), "minutes") > 0
      ? moment(pendingReq.duration, "HH:mm:ss").diff(moment(), "minutes")
      : 0;
  };
  return (
    <div className="pop-container">
      <div className="min-request">
        <div className="timer-icon">
          <img src="/image/timer.svg" alt="timer" />
        </div>

        {isRequest ? (
          <p>
            {is_seller ? "Buyer" : "Seller"} is requesting for {getTimeDiff()}{" "}
            more minutes
          </p>
        ) : (
          <p>
            You are about to request for {min} more minutes{" "}
            <span>please note you can only perform this action once</span>
          </p>
        )}
        {requestLoading || acceptDeclineLoading ? (
          <div className="loader">
            <LoaderPurple style={{ width: 20, height: 20 }} />
          </div>
        ) : isRequest ? (
          <div className="accept-decline">
            <DefaultButton
              onClick={() => acceptRejectTime("Accept")}
              customStyle={{ borderRadius: 20, backgroundColor: "green" }}>
              Accept
            </DefaultButton>
            <DefaultButton
              customStyle={{
                borderRadius: 20,
                backgroundColor: "rgba(155, 155, 155, 0.2)",
                color: "#787878",
                border: "1px solid #787878",
              }}
              onClick={() => {
                acceptRejectTime("Declined");
              }}>
              Decline
            </DefaultButton>
          </div>
        ) : (
          <div className="accept-decline">
            <DefaultButton
              customStyle={{ borderRadius: 20, backgroundColor: "green" }}
              onClick={requestTime.bind(this)}>
              Send anyways
            </DefaultButton>
            <DefaultButton
              customStyle={{
                borderRadius: 20,
                backgroundColor: "rgba(155, 155, 155, 0.2)",
                color: "#787878",
                border: "1px solid #787878",
              }}
              onClick={() => setNewRequest()}>
              Cancel
            </DefaultButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default MinRequest;
