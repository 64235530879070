import moment from "moment";
import * as yup from "yup";

export const sign_in_schema = yup.object().shape({
  username: yup.string().required("*this field is required"),
  password: yup.string().required("*password is required"),
});

export const sign_up_schema = yup.object().shape({
  first_name: yup.string().trim().required("* first name is required"),
  last_name: yup.string().trim().required("*last name is required"),
  email: yup.string().required("*must be a valid email").email(),
  password1: yup
    .string()
    .required("*password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/,
      "min length 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    ),
  password2: yup
    .string()
    .oneOf([yup.ref("password1"), null], "passwords must match")
    .required(),
  username: yup
    .string()
    .max(16)
    .min(4)
    .required("*username is required")
    .matches(/^[a-z0-9]+$/, "only numbers and lowercase letters allowed"),
  gender: yup.string().required("*gender is required"),
  date_of_birth: yup
    .string()
    .test("DOB", "user must be 12 years and above", (value) => {
      return moment().diff(moment(value), "years") >= 12;
    })
    .required("*date of birth is a required field"),
  language: yup.string().required("*language is required"),
});

export const forgot_password_schema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("*email is required for password recovery"),
});

export const confirm_password_schema = yup.object().shape({
  new_password1: yup
    .string()
    .required("*password is required")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@.#$%^&*])(?=.{8,})/,
      "min length 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
    ),
  new_password2: yup
    .string()
    .oneOf([yup.ref("new_password1"), null], "passwords must match")
    .required(),
});
