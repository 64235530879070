import React, {  useState, useContext  } from "react";
import "./cards.scss"
import Visa from "../../../assets/svg/visa-color_large.svg"
import Master from "../../../assets/svg/mastercard-light-large.svg"
import PayPal from "../../../assets/svg/paypal-nocolor-large.svg"
import Back from "../../../assets/svg/arrow-right (2).svg"
import { FaPlus } from 'react-icons/fa'
import { MdReport } from 'react-icons/all'
import Slick from "react-slick"
import Slider from "react-slick"
// import VisaCardEdit from "./VisaCardEdit"
import MasterCard from "./Cards/MasterCard"
import MasterCardEdit from "./Cards/MasterCardEdit"
import Right from "../../../assets/svg/arrow-right (1).svg"
import Left from "../../../assets/svg/arrow-right.svg"
import Cards from 'react-credit-cards'
import { css } from 'styled-components';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={Left}
      className={className}
      style={{ ...style, display: "block", width: "30px", height: "30px", right: "10px", top: "100px" }}
      onClick={onClick}
      />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
  <img
    src={Right}
    className={className}
    style={{ ...style, display: "block", width: "30px", height: "30px", left: "-20px", top: "100px" }}
    onClick={onClick}
    />
  );
}

const MasterPaymentCards = ({handleActiveTabMaster}) => {

  const carouselSettings = {
    dots: false,
    infinite: false,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
     prevArrow: <PrevArrow />
  };
  const {
    meta, getCardNumberProps, getExpiryDateProps, getCVCProps
  } = usePaymentInputs();

  return(
      <div>
        <div className="cards-container">
          <div className="new-card-cont">
            <p>Add new credit card</p>
            <div className="new-card">
              <div className="add">
                <FaPlus />
              </div>
            </div>
          </div>
          <div className="current-card-cont">
            <div className="slick-crs">
              <p className="add-new add-new-edit">Current credit card</p>
              <Slick {...carouselSettings} className="slick">
                <MasterCardEdit handleEdit={handleActiveTabMaster} />
                <MasterCardEdit handleEdit={handleActiveTabMaster} />
              </Slick>
            </div>
          </div>
        </div>
        <div className="card-edit-info card-edit-info-visa">
          <div className="left">
            <div className="name">
              <h5>Name of card older</h5>
              <p className="name-card-owner"> Howard Pinsky </p>
            </div>
            <div className="name number-top">
              <h5>Credit card number</h5>
              <p className="name-card-num name-card-owner">**** **** **** 6985</p>
            </div>
          </div>
          <div className="left">
            <div className="name">
              <h5>Expiration</h5>
              <p  className="name-card-owner">****</p>
            </div>

            <div className="name number-top">
              <h5>CVV <MdReport /> </h5>
              <p className="name-card-owner">***</p>
            </div>
          </div>

          <div className="right">
            <div className="billing">
              <h3>Billing History</h3>
              <p>Last payment on </p>
              <p>01/02/2023</p>
            </div>
          </div>
        </div>
      </div>
  )
}

export default MasterPaymentCards
