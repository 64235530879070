import { useState, useEffect, useRef } from "react";
import useReferral from "../../hooks/referrals";
import qs from "query-string";
import { useLocation } from "react-router";
import moment from "moment";
import Loader from "../../components/loader";

const Listings = () => {
  const { search } = useLocation();

  const { id } = qs.parse(search);
  const [selected, setSelected] = useState();
  const [selectedAffiliate, setSelectedAffiliate] = useState();

  const {
    handleGetAllProducts,
    allProducts,
    handleGetProductAffiliates,
    productAffiliates,
    handleGetAffiliateCommissions,
    affiliateCommission,
  } = useReferral();
  useEffect(() => {
    handleGetAllProducts(id);
  }, []);
  useEffect(() => {
    allProducts?.data?.results &&
      setSelected(allProducts?.data?.results?.[0]?.id);
  }, [allProducts?.data?.results]);
  useEffect(() => {
    selected && handleGetProductAffiliates({ productId: selected });
  }, [selected]);

  const handleSum = () => {
    let arr = affiliateCommission?.data?.results?.map((data) => {
      return data?.commission;
    });
    return arr?.reduce((total, num) => {
      return total + num;
    });
  };
  return (
    <div className="main__listing">
      <div className="pre">
        <h2>Listing</h2>
        <div className="item__scroll">
          {allProducts?.data?.results?.map((dataItem, idx) => (
            <div
              // key={data.id}
              className={`names ${selected === dataItem?.id && "active"}`}
              onClick={(e) => {
                setSelected(dataItem?.id);
                // handleGetProductAffiliates(dataItem?.id);
              }}
            >
              <h5> {dataItem?.title} </h5>
            </div>
          ))}
        </div>
      </div>
      <div className="left">
        <h1>Affiliate</h1>
        <div className="item__scroll">
          {productAffiliates?.data?.results?.length === 0 && (
            <p
              style={{
                padding: "10px 0",
                fontSize: "18px",
              }}
            >
              No affiliates
            </p>
          )}
          {productAffiliates?.loading && <Loader />}
          {productAffiliates?.data?.results?.map((data, idx) => (
            // add selected to class to indicate selected item
            <div
              className={`names names-affliate ${
                selectedAffiliate === data?.id && "selected"
              }`}
              onClick={() => {
                handleGetAffiliateCommissions(data?.id);
                setSelectedAffiliate(data?.id);
              }}
            >
              <h5> {data?.influencer?.full_name} </h5>
            </div>
          ))}
        </div>
      </div>

      <div className="right__contents">
        <div className="total">
          Total commission paid <span>${handleSum()}</span>
        </div>
        {affiliateCommission?.data?.results && (
          <div className="item__scroll">
            <table>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Username</th>
                  <th>Quantity</th>
                  <th>Time</th>
                  <th>Commission</th>
                </tr>
              </thead>
              <tbody>
                {affiliateCommission?.data?.results?.map((data, idx) => (
                  <tr key={idx}>
                    <td>{idx + 1}</td>
                    <td>{data?.username}</td>
                    <td>{data?.quantity}</td>
                    <td>{moment(data?.time).format("DD MMM YYYY")}</td>
                    <td>${data?.commission}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Listings;
