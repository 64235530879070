/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearShoppingCartComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 36.623 36.623" {...props}>
    <defs>
      <style>
        {
          ".a-cart-icon,.b-cart-icon{fill:none;}.a-cart-icon{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-0.001 0)">
      <g transform="translate(0.001 0)">
        <path
          className="a-cart-icon"
          d="M0,0H2.655A2.823,2.823,0,0,1,5.463,3.052L4.2,18.25a4.266,4.266,0,0,0,4.257,4.624H24.705a4.39,4.39,0,0,0,4.288-3.983l.824-11.445a4.231,4.231,0,0,0-4.288-4.593H5.829"
          transform="translate(3.052 3.052)"
        />
        <path
          className="a-cart-icon"
          d="M3.815,1.907A1.907,1.907,0,1,1,1.907,0,1.907,1.907,0,0,1,3.815,1.907Z"
          transform="translate(22.889 29.756)"
        />
        <path
          className="a-cart-icon"
          d="M3.815,1.907A1.907,1.907,0,1,1,1.907,0,1.907,1.907,0,0,1,3.815,1.907Z"
          transform="translate(10.682 29.756)"
        />
        <path
          className="a-cart-icon"
          d="M0,0H18.311"
          transform="translate(13.734 12.208)"
        />
        <path className="b-cart-icon" d="M0,0H36.623V36.623H0Z" />
      </g>
    </g>
  </svg>
);

export default VuesaxLinearShoppingCartComponent;
