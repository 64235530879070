import { useEffect, useState } from "react";

const CustomCheckBox = ({
  getValue = () => {},
  onChange = () => {},
  onClick = () => {},
  style,
  size = 10,
  defaultValue = false,
  customIsCheckedColor = "var(--dark-purple-bg)",
  changed = () => {},
}) => {
  const [isChecked, setIsChecked] = useState(defaultValue);
  // console.log(isChecked);
  useEffect(() => {
    getValue(isChecked);
    onChange({ value: isChecked });
  }, [isChecked]);
  return (
    <div
      style={{
        display: "inline-block",
        border: "1px solid var(--dark-purple-bg)",
        minWidth: size,
        height: size,
        borderRadius: 1,
        backgroundColor: isChecked ? customIsCheckedColor : "transparent",
        cursor: "pointer",
        ...(style && style),
      }}
      className="customCheckBox"
      onClick={(e) => {
        setIsChecked(!isChecked);
        changed(!isChecked);
        onClick(!isChecked);
      }}></div>
  );
};

export default CustomCheckBox;
