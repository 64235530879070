import React, {useState, useContext, useEffect} from "react";
import {FaTimes, FaChevronDown, FaUpload, FaStar, FaLocationArrow, FaEdit, FaTrash} from "react-icons/fa";
import EditIcon from '@mui/icons-material/Edit';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import "./updateFailModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import logo from "../../../assets/explore/saaslogo.png";
import locationicondark from "../../../assets/explore/locationdark.svg";
import phoneicon from "../../../assets/explore/callcontact.svg";
import mailicon from "../../../assets/explore/smscontact.svg";
import failIcon from "../../../assets/explore/close.png";


export const UpdateFailModal = () => {
    const {setUpdateFailModal} = useContext(ModalContext);

    return (
        <>
            <div className="applySuccessModal">
                <div className='applySuccessModalWrap'>
                    <div className="modalCloseWrap">
                        <div onClick={() => setUpdateFailModal(false)} className="applySuccessModalClose">
                            <FaTimes/>
                        </div>
                    </div>

                    <div className="applySuccessModal-body">

                        <div className="applySuccessModal-content">
                            <img src={failIcon} className="fail-icon"/>

                            <div className="modal-msg-title">Error!</div>
                            <div className="modal-msg-body">Update failed</div>

                            <button className="modal-fail-btn" onClick={() => setUpdateFailModal(false)}>Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}