import React, {useState, useContext, useEffect} from "react";
import {FaTimes, FaChevronDown, FaUpload, FaStar, FaLocationArrow, FaEdit, FaTrash} from "react-icons/fa";
import EditIcon from '@mui/icons-material/Edit';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import "./applySuccessModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import logo from "../../../assets/explore/saaslogo.png";
import locationicondark from "../../../assets/explore/locationdark.svg";
import phoneicon from "../../../assets/explore/callcontact.svg";
import mailicon from "../../../assets/explore/smscontact.svg";
import tickIcon from "../../../assets/explore/tick-circle.svg";


export const UpdateSuccessModal = () => {
    const {setUpdateSuccessModal} = useContext(ModalContext);
    const {setUpdateFailModal} = useContext(ModalContext);

    const swapSuccessModal = () => {
        setUpdateSuccessModal(false)
        setUpdateFailModal(true)
    }


    return (
        <>
            <div className="applySuccessModal">
                <div className='applySuccessModalWrap'>
                    <div className="modalCloseWrap">
                        <div onClick={() => setUpdateSuccessModal(false)} className="applySuccessModalClose">
                            <FaTimes/>
                        </div>
                    </div>

                    <div className="applySuccessModal-body">

                        <div className="applySuccessModal-content">
                            <img src={tickIcon} className="success-icon"/>

                            <div className="modal-msg-title">Successful!</div>
                            <div className="modal-msg-body">Details updated successfully</div>

                            <button className="modal-cta-btn" onClick={() => swapSuccessModal()}>Done</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}