import React, { useState, useContext } from "react";
import { FaTimes, FaChevronDown, FaUpload } from "react-icons/fa";
import "./PostModal.scss";
import Image from "../.../../../assets/img/Ellipse 358.png";
import Upload from "./FileUpload/Upload";
import ModalContext from "../../hooks/modalContexts";
import { useSelector } from "react-redux";
import ReusableDropDown from "../reusableDropdown";
import VuesaxLinearVideoComponent from "../../assets/svg/VuesaxLinearVideoComponent";
import VuesaxLinearLocationComponent from "../../assets/svg/VuesaxLinearLocationComponent";
import GalleryIconComponent from "../../assets/svg/GalleryIconComponent";
import { formatText } from "../../utils/formatText";
import usePosts from "../../hooks/posts";
import { useRef } from "react";
import { useEffect } from "react";
import renderHTML from "react-render-html";
import LoaderPurple from "../../assets/loaders/LoaderPurple";
import { DefaultButton } from "../Button";
import VuesaxOutlineDirectboxSendComponent from "../../assets/svg/VuesaxOutlineDirectboxSendComponent";

function PostModal() {
  const [modals, SetModals] = useState("caption");
  const { user } = useSelector((state) => state.currentUser);
  const context = useContext(ModalContext);
  const [isActive, setActive] = context.postModalVisibilityStatus;
  const [uploadState, setUploadState] = useState({});
  const [inputRef, getInputRefFromComponent] = useState(null);
  const handleToggle = () => {
    setActive(!isActive);
  };
  const [postText, setPostText] = useState("");
  const [unprocessedText, setUnprocessed] = useState("");
  // console.log(uploadState);
  const postOptions = ["Make Public", "Followers Only"];
  const [data, setData] = useState({
    videos: null,
    images: null,
    text: null,
    media: [],
  });
  const [postPrivacy, setPostPrivacy] = useState("");
  const handleInput = (e) => {
    setData({ ...data, text: formatText(e.target.innerHTML) });
    setUnprocessed(e.target.innerHTML);
  };

  const { postsData, handleMakingPost, postMakeLoading } = usePosts();

  const handleSubmission = (e) => {
    e.preventDefault();
    if (!data.media.length && !data.text) {
      // tell user this ain't accepted
      return;
    }
    const formData = new FormData();
    // data.text && formData.append("text", data.text);
    if (data.media.length) {
      Array.from(data.media).forEach((data) => formData.append("files", data));
      formData.append("purpose", "POST");

      console.log(data);
    }
    // formData.append(
    //   "visible_to",
    //   postPrivacy.toLocaleLowerCase() === "followers only" ? 1 : 0
    // );
    const dataToBeSent = {
      ...(data.text && { text: data.text }),
      ...(data.postPrivacy && {
        visible_to:
          postPrivacy.toLocaleLowerCase() === "followers only" ? 1 : 0,
      }),
    };
    handleMakingPost(formData, dataToBeSent);
  };

  //monitor switch between media addition or just post text
  useEffect(() => {
    setPostText(unprocessedText);
  }, [modals]);
  // console.log(modals);

  useEffect(() => {
    uploadState.file && setData({ ...data, media: [...uploadState.file] });
  }, [uploadState.file]);

  useEffect(() => {
    postsData.data && context.postModalVisibilityStatus[1]();
  }, [postsData.data]);
  console.log(data);
  return (
    <>
      <section className={"PostModal-post"}>
        {modals === "upload" && (
          <section className="PostModal__background">
            <div className="PostModal__header">
              <div className="_col-1">
                <div className="create-a-post-section">
                  <h1>Create a post</h1>
                  <ReusableDropDown
                    options={postOptions}
                    onChange={setPostPrivacy}
                  />
                </div>
                {!!uploadState.file?.length && (
                  <DefaultButton
                    customStyle={{
                      padding: "12px 18px",
                      borderRadius: "40px",
                      fontSize: 20,
                      marginRight: 10,
                      display: "flex",
                      alignItems: "center",
                      gridGap: 10,
                    }}
                    onClick={(e) => inputRef.click()}>
                    <VuesaxOutlineDirectboxSendComponent
                      style={{ fontSize: 24 }}
                    />
                    Pictures / Videos
                  </DefaultButton>
                )}
              </div>
              <div className="PostModal__user">
                <div className="PostModal__name">
                  <div className="image">
                    <img src={Image} alt="" />
                  </div>
                  <div className="PostModal__details">
                    <h2>
                      {user.first_name} {user.last_name}
                    </h2>
                    <p>Abuja</p>
                  </div>
                </div>
                <div onClick={handleToggle} className="PostModal__close">
                  <FaTimes />
                </div>
              </div>
            </div>
            <div className="PostModal__upload">
              <form action="" className="form-2" onSubmit={handleSubmission}>
                <div
                  className="PostModal__media"
                  style={{
                    ...(uploadState.file?.length <= 0 && {
                      minHeight: 300,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }),
                  }}>
                  {!uploadState.file?.length && (
                    <div className="PostModal__media-close">
                      <div
                        className="PostModal__media-close-up"
                        onClick={() => {
                          SetModals("caption");
                        }}>
                        <FaTimes />
                      </div>
                    </div>
                  )}
                  <div>
                    <div>
                      <Upload
                        getState={setUploadState}
                        getInputRef={getInputRefFromComponent}
                      />
                    </div>
                  </div>
                </div>
                <div className="PostModal__caption">
                  <div>
                    <div
                      className="text-field"
                      contentEditable={true}
                      onInput={handleInput}>
                      {renderHTML(postText)}
                    </div>
                  </div>
                  <div>
                    {!postMakeLoading ? (
                      <button>Post</button>
                    ) : (
                      <LoaderPurple style={{ width: 50, height: 50 }} />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </section>
        )}
        {modals === "caption" && (
          <section className="PostModal__background">
            <div className="PostModal__header">
              <div className="_col-1">
                <div className="create-a-post-section">
                  {/* <h1>Create a post</h1> */}

                  {/* <select name="" id="">
                      <option value="">All</option>
                      <option value="">Make Public</option>
                      <option value="">My Timeline only</option>
                    </select> */}

                  <ReusableDropDown
                    options={postOptions}
                    onChange={setPostPrivacy}
                  />
                </div>
              </div>
              <div className="PostModal__user">
                <div className="PostModal__name">
                  {/* <div className="image">
                    <img src={Image} alt="" />
                  </div> */}
                  {/* <div className="PostModal__details">
                    <h2>
                      {" "}
                      {user.first_name} {user.last_name}
                    </h2>
                    <p>Abuja</p>
                  </div> */}
                </div>
                <div onClick={handleToggle} className="PostModal__close">
                  <FaTimes />
                </div>
              </div>
            </div>
            <div className="PostModal__upload">
              <form action="" onSubmit={handleSubmission}>
                <div className="PostModal__caption caption">
                  <div>
                    <div
                      className="text-field"
                      contentEditable={true}
                      onInput={handleInput}>
                      {renderHTML(postText)}
                    </div>
                  </div>
                  <div className="PostModal__caption-icon">
                    <div className="PostModal__icus">
                      <div
                        onClick={() => {
                          SetModals("upload");
                        }}>
                        <GalleryIconComponent />

                        {/* <img src={galleryIcon} alt="" width={18} /> */}
                        <p>Image</p>
                      </div>
                      <div
                        onClick={() => {
                          SetModals("upload");
                        }}>
                        <VuesaxLinearVideoComponent />
                        <p>Video</p>
                      </div>
                      <div
                        onClick={() => {
                          SetModals("upload");
                        }}>
                        <VuesaxLinearLocationComponent />
                        <p>Location</p>
                      </div>
                    </div>
                    {postMakeLoading ? (
                      <LoaderPurple
                        style={{ width: 50, height: 50, margin: " 0 0 0 auto" }}
                      />
                    ) : (
                      <button>Post</button>
                    )}
                  </div>
                </div>
              </form>
            </div>
          </section>
        )}
      </section>
    </>
  );
}

export default PostModal;
