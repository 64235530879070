import React, { useContext, useEffect, useState } from "react";
import "./createlisting.scss";
import { FaCalendarAlt, FaCopy, FaClock, FaTimesCircle } from "react-icons/fa";
import { IoCloseCircleOutline, MdInfoOutline } from "react-icons/all";
import PlusIcon from "../../../assets/svg/Plus";
// import DeliveryIcon from "../../../assets/svg/deliveryIcon";
// import Pen from "../../../components/pen";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Toggle from "../../../components/reusableToggle/index";
import CustomCheckBox from "../../customCheckBox";
import { BiInfoCircle } from "react-icons/bi";
// import CustomDropdown from "../../customDropdown/customDropdown";
import ModalContext from "../../../hooks/modalContexts";
import ReusableDropDown from "../../reusableDropdown";
import ReferralContents from "../../createListingsComponents/Referral/referralContents";
// import MediaContents from "../../createListingsComponents/Media/mediaContents";
import DeliveryCard from "../../../pages/Settings/component/Business/priavateSellerComponents/deliveryCard";
import Slider from "react-slick";
import classNames from "classnames";
import MediaContentsMultiple from "./MediaContentsMultiple";
// import moment from "moment";
import { showToast } from "../../ReactToast";
import { Toaster } from "react-hot-toast";
// import { Form } from "formik";
const CreateListingBody = ({ category, id = "" }) => {
  const {
    setPrivateSellerDelivery,
    deliveryListState,
    handlePrivateSellerSummaryModal,
    createListingData,
  } = useContext(ModalContext);
  const [deliveryList, setDeliveryList] = deliveryListState;
  const [contactSeller, setContactSeller] = useState(false);
  const [rawImages, setRawImages] = useState();
  // console.log("picking up changes? ::", deliveryList);
  const [imagesFormData, setImagesFormData] = useState(new FormData());
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: deliveryList.length > 1 ? 3 : 1,
    slidesToScroll: 1,
  };
  const [referral, setReferral] = useState({ value: false });

  const [body, setBody] = useState({
    page: id,
    title: "",
    category: {
      name: "",
    },
    subcategory: { name: "" },
    description: "",
    tags: [],
    accept_order_automatically: false,
    deposit_type: "FIXED",
    deposit_amount: 0,
    free_cancelation_date: new Date(),
    listing_end_date: new Date(),
    who_can_refer: "ANYBODY",
    stocks: [],
    referrals: [
      {
        influencer_type: "ANYBODY",
        price_type: "FIXED",
        price_value: 0,
      },
    ],
    allow_referral: referral.value,
    delivery_locations: [],
    contact_seller: false,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    // console.log(name, value);
    setBody({ ...body, [name]: value });
  };

  useEffect(() => {
    setBody({ ...body, delivery_locations: deliveryList });
  }, [deliveryList]);
  // console.log(body);

  const handleSubmitData = () => {
    const data = {
      ...body,
    };

    //rebuild delivery locations
    data.delivery_locations = data.delivery_locations.map((item) => {
      return {
        ...item,
        locations: item.locations.map((location) => {
          return {
            ...location,
            dates: location.dates.map((date) => {
              const start = new Date(date.start);
              const end = new Date(date.end);
              //start and end replacement
              return {
                ...date,
                date: start.toISOString().split("T")[0],
                opening_time: `${start.getHours()}:${start.getMinutes()}:${start.getMilliseconds()}`,
                closing_time: `${end.getHours()}:${end.getMinutes()}:${end.getMilliseconds()}`,
              };
            }),
          };
        }),
      };
    });

    if (!data.allow_referral) delete data.referrals;
    if (data.contact_seller) {
      delete data.delivery_locations;
      delete data.referrals;
      delete data.allow_referral;
    }

    try {
      if (!imagesFormData.has("files")) {
        throw "please upload an image";
      }
      // if (imagesFormData.values(). > 10) {
      //   throw new Error(
      //     JSON.stringify({ images: "cannot upload morethan ten images" })
      //   );
      // }
      if (data.title.trim() === "") {
        throw "Title cannot be empty";
      }
      if (data.category.name?.trim() === "") {
        throw "Category cannot be empty";
      }
      if (data.subcategory.name?.trim() === "") {
        throw "Subcategory cannot be empty";
      }
      if (data.description.trim() === "") {
        throw "description cannot be empty";
      }
      if (!data.tags.length) {
        throw "tags cannot be empty";
      }
      if (!data.stocks[0].price) {
        throw "price cannot be empty";
      }
      if (!data.deposit_amount) {
        throw "deposit amount cannot be empty";
      }
      if (data.allow_referral) {
        data.referrals.forEach((data) => {
          if (!data.price_value) {
            throw "please make sure no price is left empty";
          }
        });
      }
      if (!data.contact_seller && !data.delivery_locations.length) {
        throw "delivery locations cannot be empty";
      }
      handlePrivateSellerSummaryModal("ADD_DATA", {
        data,
        images: imagesFormData,
        rawImages,
      });
    } catch (err) {
      console.log(err);
      showToast("error", err);
    }
  };

  useEffect(() => {
    category?.results?.length &&
      setBody({
        ...body,
        category: { name: category.results[0].name },
        subcategory: { name: category.results[0].name },
      });
  }, [category?.results?.length]);
  useEffect(() => {
    createListingData[1](body);
  }, [body]);
  return (
    <div className="createlisting-body-container">
      <Toaster />
      <div className="clbc-card-body">
        {/*---------1  LIST INFORMATION CARD CONTENTS ------------------*/}
        <div className="clbc-cb-contents">
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>1</span> List Information
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <div className="ccb__contents">
                <div className="input-group">
                  <label htmlFor="">List Title: </label>
                  <input
                    className="form-input"
                    type="text"
                    name="title"
                    onChange={handleChange}
                  />
                </div>
                <div className="input-group  category-group">
                  <div className="list-category-container">
                    <label htmlFor="">List Category:</label>
                    <div className="dropbox">
                      {category && (
                        <ReusableDropDown
                          options={category.results.map((item) => item.name)}
                          onChange={(e) =>
                            setBody({
                              ...body,
                              category: {
                                name: e,
                              },
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="sub-category-container">
                    <label htmlFor="">Sub Category:</label>
                    <div className="dropbox">
                      {category && (
                        <ReusableDropDown
                          options={category.results.map((item) => item.name)}
                          onChange={(e) =>
                            setBody({
                              ...body,
                              subcategory: { name: e },
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-group desc-container">
                  <label htmlFor="">Description:</label>
                  <textarea
                    className="form-input"
                    name=""
                    id=""
                    cols="100"
                    rows="5"
                    onInput={(e) => {
                      // console.log(e.target.value, e.target.name);
                      setBody({
                        ...body,
                        description: e.target.value,
                      });
                    }}></textarea>
                </div>
                <div className="input-group">
                  <label htmlFor="" className="tag-cover">
                    Tag:
                    <input
                      className="form-input form-input-tag"
                      type="text"
                      name="tags"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          if (e.target.value.trim() !== "") {
                            //add to tags
                            setBody({
                              ...body,
                              tags: [...body.tags, e.target.value],
                            });

                            e.target.value = "";
                          }
                        }
                      }}
                    />{" "}
                  </label>
                  <div className="added-tags">
                    {body.tags.map((item, idx) => (
                      <div className="tag-item" key={idx}>
                        <IoCloseCircleOutline
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            //close selection
                            setBody({
                              ...body,
                              tags: body.tags
                                .map((item, _idx) => _idx !== idx && item)
                                .filter((data) => data && data),
                            });
                          }}
                        />{" "}
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tag-selection-container"></div>
              </div>
            </div>
          </div>

          {/*---------2  PRICE/PAYMENTS CARD CONTENTS ------------------*/}

          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4 className="toggle-2">
                <span>2</span> Accept Order Automatically{" "}
                <div className="toggle-section">
                  <Toggle
                    size={30}
                    onChange={(e) =>
                      setBody({ ...body, accept_order_automatically: e.value })
                    }
                  />{" "}
                  <BiInfoCircle className="info-circle" size={20} />
                </div>
              </h4>
            </div>
          </div>
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>3</span> Payment
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <div className="ccb__contents_price">
                <div className="price-box-one">
                  <div className="input-group ctn-one">
                    <label htmlFor="">Set Price: $</label>
                    <input
                      className="form-input-sm"
                      type="text"
                      name="price"
                      onChange={(e) =>
                        setBody({
                          ...body,
                          stocks: [{ price: Number(e.target.value) }],
                        })
                      }
                    />
                  </div>
                  <div className="input-group  ctn-two">
                    <label htmlFor="">Min Deposit: </label>
                    <div className="dropbox">
                      <ReusableDropDown
                        upIcon={true}
                        options={["fixed", "percentage"]}
                        // current={"fixed"}
                        onChange={(e) => {
                          setBody({ ...body, deposit_type: e.toUpperCase() });
                        }}
                      />
                    </div>
                    <div className="min-dep-field">
                      <span>
                        {body.deposit_type.toLowerCase() === "fixed"
                          ? "$"
                          : "%"}
                      </span>
                      <input
                        type={"number"}
                        onChange={(e) => {
                          try {
                            const amount = Number(e.target.value);
                            const stockPrice = body.stocks[0]?.price || 0;
                            if (stockPrice <= 0) throw "input a valid Price";
                            const percentage =
                              body.deposit_type.toLowerCase() === "percentage"
                                ? amount
                                : (amount / stockPrice) * 100;
                            if (percentage > 10)
                              throw "minimum deposit cannot be greater than 10 percent of price";
                            setBody({
                              ...body,
                              deposit_amount: amount,
                            });
                          } catch (err) {
                            showToast("error", err);
                          }
                        }}
                        value={body.deposit_amount}
                        name="deposit_amount"
                      />
                    </div>
                    <BiInfoCircle className="infoIcon" />
                  </div>
                  <div className="input-group ctn-three">
                    <label htmlFor="">Free Cancelation Date </label>
                    <div className="datepicker-container">
                      <FaCalendarAlt />

                      <DatePicker
                        className="custom-date"
                        selected={body.free_cancelation_date}
                        // onSelect={(e) => console.log(e)}
                        onChange={(date) => {
                          try {
                            if (new Date(date) < Date.now())
                              throw "date can only be greater or equal to today";
                            if (
                              new Date(date) > new Date(body.listing_end_date)
                            )
                              throw "free cancelation date cannot be greater than listing end date";

                            setBody({ ...body, free_cancelation_date: date });
                          } catch (err) {
                            showToast("error", err);
                          }
                        }}
                        dateFormat="MMM d, yyyy h:mm aa"
                        placeholderText="select date"
                        showTimeInput
                        timeInputLabel="Time"
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="price-box-two">
                  <div className="input-group">
                    <label htmlFor="">Set Stock Qty: </label>
                    <div className="dropbox">
                      <ReusableDropDown />
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Min Purchase Qty: </label>
                    <div className="dropbox">
                      <ReusableDropDown />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/*---------3  LISTINGS END DATE CARD CONTENTS ------------------*/}

          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>4</span> Listing End Date
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <div className="ccb__contents_led">
                <div className="input-group">
                  <label htmlFor="">Date: </label>
                  <div className="datepicker-container">
                    <FaCalendarAlt />
                    <DatePicker
                      className="custom-date"
                      selected={body.listing_end_date}
                      onChange={(date) => {
                        try {
                          if (
                            new Date(date) >
                            new Date().setDate(new Date().getDate() + 14)
                          )
                            throw "date cannot exceed 14 days from today";
                          if (new Date(date) < Date.now())
                            throw "date can only be today or greater and a max of 14 days";
                          if (
                            new Date(date) <
                            new Date(body.free_cancelation_date)
                          )
                            throw "free cancelation date cannot be greater than listing end date";
                          setBody({ ...body, listing_end_date: date });
                        } catch (err) {
                          showToast("error", err);
                        }
                      }}
                      dateFormat="MMM d, yyyy"
                    />
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="">Time: </label>
                  <div className="timepicker-container">
                    <FaClock />

                    <DatePicker
                      className="custom-date"
                      selected={body.listing_end_date}
                      dateFormat="h:mm aa"
                      onChange={(date) =>
                        setBody({
                          ...body,
                          listing_end_date: date,
                        })
                      }
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*---------4  PRICE/PAYMENTS CARD CONTENTS ------------------*/}

          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>5</span> Delivery detail
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <div className="ccb__contents_delivery">
                <div className="input-group">
                  <p>Create delivery mode. </p>
                  <div
                    className={classNames({
                      "disabled-item": body.contact_seller,
                    })}>
                    <label htmlFor="">Use saved: </label>
                    <ReusableDropDown />
                  </div>
                  <div className="contact-seller">
                    <MdInfoOutline />
                    Contact Seller
                    <CustomCheckBox
                      size={15}
                      getValue={(e) => setBody({ ...body, contact_seller: e })}
                    />
                  </div>
                </div>

                <div
                  className={classNames("deliveryTabContent", {
                    "disabled-item": body.contact_seller,
                  })}>
                  <Slider {...carouselSettings}>
                    <div
                      className="box add"
                      onClick={(e) => setPrivateSellerDelivery(true)}>
                      <div className="circle">
                        <PlusIcon />
                      </div>
                    </div>
                    {deliveryList.map((data, idx) => (
                      <DeliveryCard
                        key={idx}
                        id={idx}
                        data={data}
                        deliveryListState={[deliveryList, setDeliveryList]}
                      />
                    ))}
                  </Slider>
                </div>
              </div>
            </div>
          </div>

          {/*---------5  MEDIA CARD CONTENTS ------------------*/}

          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>6</span> Media
              </h4>
            </div>
            <p className="media-sub-heading">
              Add photos to display your product{" "}
              <span>
                Add photos with different angles to display show your products,
                you can upload a maximum of 10 photos
              </span>
            </p>
            <MediaContentsMultiple
              onChange={(images) => {
                if (!images.length) return;
                let x = 0;
                const formData = new FormData();
                while (x < images.length) {
                  formData.append("files", images[x]);
                  x++;
                }
                setImagesFormData(formData);
                setRawImages(images);
              }}
            />
          </div>

          {/*---------6  REFERRAL CARD CONTENTS ------------------*/}
          <div
            className={classNames("referral-switch ", {
              "disabled-item": contactSeller,
            })}>
            Referral{" "}
            <Toggle
              onChange={(e) => setBody({ ...body, allow_referral: e.value })}
            />
          </div>

          <div
            className={classNames("clbc-cbc-card", {
              "disabled-item": body.contact_seller || !body.allow_referral,
            })}>
            <div className="clbc-card-header">
              <h4>
                <span>7</span> Referral
              </h4>
            </div>
            <ReferralContents showHeader={false} state={[body, setBody]} />
          </div>

          {/*---------7  ACCEPT CARD CONTENTS ------------------*/}

          {/* <div className="clbc-cbc-card">
            <div className="clbc-card-header acb-contents">
              <span>8</span>
              <div className="accept-box">
                <label htmlFor="switch" className="">
                  Accept order automatically
                </label>
                <Toggle size={30} />
                <BiInfoCircle />
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="controls">
        <span>Back</span>

        <span className="preview" onClick={handleSubmitData}>
          Preview & Post
        </span>
      </div>
    </div>
  );
};

export default CreateListingBody;
