import { useCallback, useEffect } from "react";
import { useMemo, useState } from "react";
import CustomCheckBox from "../../components/customCheckBox";
import Time from "../../components/MinsComponent";

const OpeningHours = ({ onChange = () => {} }) => {
  const [daysArray, setDays] = useState([
    {
      day: "Sun",
      value: 0,
      from: "12:00",
      to: "18:00",
    },
    {
      day: "Mon",
      value: 1,
      from: "12:00",
      to: "18:00",
    },
    {
      day: "Tue",
      value: 2,
      from: "12:00",
      to: "18:00",
    },
    {
      day: "Wed",
      value: 3,
      from: "12:00",
      to: "18:00",
    },
    {
      day: "Thu",
      value: 4,
      from: "12:00",
      to: "18:00",
    },
    {
      day: "Fri",
      value: 5,
      from: "12:00",
      to: "18:00",
    },
    {
      day: "Sat",
      value: 6,
      from: "12:00",
      to: "18:00",
    },
  ]);
  const handleChange = useCallback(({ day, type, time }) => {
    console.log(day, type, time);
    setDays((prevState) => {
      return prevState.map((days) => {
        if (days.day === day) {
          return {
            ...days,
            [type]: time,
          };
        } else return days;
      });
    });
  }, []);
  const [selected, setSelected] = useState([]);

  const handleSelected = useCallback((e) => {
    setSelected((state) => {
      return state.includes(e)
        ? state.filter((num) => {
            if (num.toString() != e.toString()) {
              return true;
            }
          })
        : [...state, e];
    });
  }, []);

  useEffect(() => {
    console.log(selected, daysArray);
    onChange({ selected, daysArray });
    console.log(selected);
  }, [selected, daysArray]);
  return (
    <div className="opening-hours">
      {daysArray.map(({ day, from, to, value }) => {
        return (
          <div className="day-box" key={value}>
            <div className="day">
              <CustomCheckBox changed={handleSelected.bind(this, value)} />{" "}
              <h6>{day}</h6>
            </div>
            <div className="from">
              <span>From</span>
              <span>
                <input
                  type="time"
                  name={`from-${day}`}
                  defaultValue={from}
                  onChange={(e) =>
                    handleChange({
                      day,
                      time: e.target.value,
                      type: "from",
                    })
                  }
                />
              </span>
            </div>
            <div className="to">
              <span>To</span>
              <span>
                <input
                  type="time"
                  name={`to-${day}`}
                  defaultValue={to}
                  onChange={(e) =>
                    handleChange({
                      day,
                      time: e.target.value,
                      type: "to",
                    })
                  }
                />
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default OpeningHours;
