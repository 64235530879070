import { useEffect, useState } from "react";
import "./index.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
// import DataItem1 from "./dropdownItems";

const CustomDropdown = ({
  delivery_id,
  itemy,
  click,
  items,
  filter,
  filter_key,
  data,
  history_condition,
  onChange = () => {},
}) => {
  const [isOpen, setOpen] = useState(false);
  // const [items, setItem] = useState([]);
  const [selectedItem, setSelectedItem] = useState(
    itemy ? itemy : items[0].label
  );
  useEffect(() => {
    if (itemy) {
      setSelectedItem(itemy);
    }
  }, [itemy, data]);
  const toggleDropdown = (e) => setOpen(!isOpen);
  useEffect(() => {
    onChange(selectedItem);
  }, [selectedItem]);
  return (
    <div className="dropdown-container">
      <div className="dd-dropdown">
        <div className="dropdown">
          <div className="dropdown-header" onClick={toggleDropdown}>
            {selectedItem}
            <FaChevronDown className="dropdown-icon" />
          </div>
        </div>
        {isOpen && (
          <div className="dropdown-body ">
            {items.map((item, idx) => (
              <div
                className="dropdown-item"
                key={idx}
                onClick={(e) => {
                  setSelectedItem(item.label);
                  setOpen();
                  console.log(item, "Item");
                  click && click(delivery_id);
                  // filter &&
                  //   item.filter === "Processing" &&
                  //   filter({
                  //     processing: item.value,
                  //     tab: filter_key,
                  //   });
                  // filter &&
                  //   item.filter === "Out_for_delivery" &&
                  //   filter({
                  //     out_for_delivery: item.value,
                  //     tab: filter_key,
                  //   });
                  // filter &&
                  //   filter({
                  //     tab: filter_key,
                  //   });
                  filter &&
                    filter({
                      tab: filter_key,
                      processing: item.filter === "Processing" && item.value,
                      out_for_delivery:
                        item.filter === "Out_for_delivery" && item.value,
                    });
                  filter &&
                    history_condition &&
                    item.key !== "all" &&
                    filter({
                      tab: filter_key,
                      history_condition: item.key,
                    });
                  filter &&
                    history_condition &&
                    item.key === "all" &&
                    filter({
                      tab: filter_key,
                    });
                }}
              >
                {item.label}
                {/* <div className="dd-icon">{item.icon}</div> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
