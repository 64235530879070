import React, { useState } from "react";
import "../../app.scss";
import { NavLink, useLocation } from "react-router-dom";
import "./navbar.scss";
import logo from "../../assets/icons/logo.png";
import logowhite from "../../assets/icons/meeney__white-logo.png";
import { FaTimes, FaBars } from "react-icons/fa";

function Navbar(props) {
  const [click, setClick] = useState(true);
  const location = useLocation();
  const signInPage = location.pathname === "/signin";
  const handleClick = () => setClick(!click);
  return (
    <>
      <nav
        className={
          "navbar " +
          (props.theme === "dark" ? "navbar-dark" : "") +
          (props.theme === "light" ? "navbar-light" : "")
        }>
        <div className="nav-container">
          <NavLink exact to="/" className="nav-logo">
            {props.theme === "dark" ? (
              <img className="img-logo" src={logowhite} alt="" />
            ) : (
              <img className="img-logo" src={logo} alt="" />
            )}
            {/*<img className="img-logo" src={logo} alt="" />*/}
          </NavLink>
          <div className="nav-item-container">
            <ul className={click ? "nav-menu active" : "nav-menu"}>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/about"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}>
                  About
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/blog"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}>
                  Blog
                </NavLink>
              </li>
              <li className="nav-item m-nav-item">
                <NavLink
                  exact
                  to={location.pathname === "/signin" ? "/signup" : "/signin"}
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}>
                  {location.pathname === "/signin" ? "Sign up" : "Sign in"}
                </NavLink>
              </li>
              <li className="nav-item m-nav-item">
                <NavLink
                  exact
                  to="/signup"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}>
                  SignUp
                </NavLink>
              </li>
              <li className="nav-item m-nav-item">
                <NavLink
                  exact
                  to="/explore"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}>
                  Explore
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  exact
                  to="/help"
                  activeClassName="active"
                  className="nav-links"
                  onClick={handleClick}>
                  Help
                </NavLink>
              </li>

              <li className="nav-item nav-link2 l-nav-item">
                <span>
                  {!signInPage ? "Already a member?" : "Not a member?"}
                </span>

                <NavLink
                  exact
                  to={location.pathname === "/signin" ? "/signup" : "/signin"}
                  activeClassName="active"
                  className="nav-links btn-signIn"
                  onClick={handleClick}>
                  {location.pathname === "/signin" ? "Sign up" : "Sign in"}
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="nav-icon" onClick={handleClick}>
            <i className={click ? <FaTimes /> : <FaBars />}>
              <FaBars />
            </i>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
