import { useState, useContext } from "react";
import { DefaultButton } from "../../../components/Button";
import List from "./list";
import AnnouncementCard from "./card";
import useExplore from "../../../hooks/explore";
import ExploreContext from "../../../hooks/exploreContext";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import moment from "moment/moment";
import LoaderPurpleBg from "../../../assets/loaders/LoaderPurpleBg";
import { useHistory } from "react-router-dom";

const QueueList = () => {
  const thisUser = useSelector(state => state.currentUser.user);
  const { handleGetQueueOrders } = useExplore();
  const { queueOrders, getQueueListComplete } = useContext(ExploreContext);
  const [pageLoading, setPageLoading] = useState(false);
  const { location } = useHistory();
  const pageId = location.search.substring(location.search.indexOf('=') + 1);

  useEffect(() => {
    setPageLoading(true);
    handleGetQueueOrders(pageId);
  }, []);


  useEffect(() => {
    if (getQueueListComplete) setPageLoading(false);
  }, [getQueueListComplete]);

  const empty = false;
  const [isOptionVisible, setIsOptionsVisible] = useState();

  const tempNum = [
    {
      done: true,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
      {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
    {
      done: false,
    },
  ];

  const testArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [announcementText, setAnnouncementText] = useState("");

  return (
    <div className="queue__list">
      {!empty ? (
        <div className="list__wrap">
          <div className="list">
            <div className="list__header">
              <div className="left__section">
                <h1>Tea Shop</h1>
                <h5>Address of the business</h5>
              </div>
              <div className="right__section">
                <div className="available__time">
                  <h1>Date</h1> <h5>{moment(Date()).format("LL")}</h5>
                </div>
                <div className="date">
                  <h1> Total Queue No </h1> <h5> {queueOrders?.data?.count ? queueOrders?.data?.count : 0} </h5>
                </div>
              </div>
            </div>
            <div className="list__items item__Scroll">
              <div className="list__item__header">
                <h6 className="one">Que.No</h6>
                <h6 className="two">Client name</h6>
                <h6>Status</h6>
              </div>
              <div className="item__scroll">
                {
                  pageLoading ?
                  <LoaderPurpleBg style={{ width: 70, height: 70 }} />
                  :
                  queueOrders?.data?.results?.map((order, idx) => (
                    <List
                      data={{ ...order, idx }}
                      key={idx}
                      handleOptions={{ setIsOptionsVisible, isOptionVisible }}
                    />
                  ))
                }
                
              </div>
              
            </div>
            <div className="business__status">
              <div className="active">
                <span></span> Active
              </div>
            </div>
          </div>
          <div>
            <form className="announcement">
              <h3>Announcement</h3>
              <textarea
                className="editor"
                placeholder="Type announcement here..."
                value={announcementText}
                onChange={(e) => setAnnouncementText(e.target.value)}
              />
              <DefaultButton
                customStyle={{ backgroundColor: " var(--dark-purple-bg)" }}
                
              >
                Send
              </DefaultButton>
            </form>
            <div className="item__scroll2">
              {testArray.map((data, idx) => (
                <AnnouncementCard />
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="empty">
          <p>No Queue Available</p>
        </div>
      )}
    </div>
  );
};

export default QueueList;
