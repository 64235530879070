import React, { useContext, useState } from "react";
// import "./createlisting.scss";
import toast, { Toaster } from "react-hot-toast";
import { FaCalendarAlt, FaCopy, FaClock, FaTimesCircle } from "react-icons/fa";
import PlusIcon from "../../../assets/svg/Plus";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomCheckBox from "../../customCheckBox";
import { BiInfoCircle } from "react-icons/bi";
import Toggle from "../../reusableToggle";
import ModalContext from "../../../hooks/modalContexts";
import ReferralContents from "../../createListingsComponents/Referral/referralContents";
import Payment from "../../createListingsComponents/Payment/paymentContents";
import MediaContents from "../../createListingsComponents/Media/mediaContents";
import ReusableDropDown from "../../reusableDropdown";
import { IoCloseCircleOutline } from "react-icons/io5";
import Slider from "react-slick";
import DeliveryCard from "../../../pages/Settings/component/Business/priavateSellerComponents/deliveryCard";
import { MdInfoOutline } from "react-icons/md";
import { MdModeEdit } from "react-icons/all";
import MediaContentsMultiple from "../../PrivateSeller/Listings/MediaContentsMultiple";
import classNames from "classnames";
import Deals from "./deals";
import usePages from "../../../hooks/createPage";
import { useEffect } from "react";
import { showToast } from "../../ReactToast";
import useExplore from "../../../hooks/explore";
import useCreateListings from "../../../hooks/createListings";
import { useHistory, useParams } from "react-router";

const BsEditListing = ({ productId }) => {
  const history = useHistory();
  const { pageId } = useParams();
  const { handleGetProduct, getProductData } = useExplore();
  const [contactSeller, setContactSeller] = useState();
  const { setBusinessSellerDelivery, deliveryListState, handleSummaryModal } =
    useContext(ModalContext);
  const [deliveryList] = deliveryListState;
  const {
    handleUpdateListing,
    updateListingData,
    handleUpdateStock,
    createStockData,
    handleCreateStock,
  } = useCreateListings();

  const [addStock, setAddStock] = useState(false);
  //   const [referral, setReferral] = useState({ value: false });
  const [initialStock, setInitialStock] = useState();
  const [imagesFormData, setImagesFormData] = useState(new FormData());

  const handleAddToStock = (data) => {
    const hasSameData = body.stocks.find(
      (item) => (item.tempId || item.id) === (data.tempId || data.id) && item
    );
    if (hasSameData) {
      setBody({
        ...body,
        stocks: body.stocks?.map((item) => {
          if ((item.tempId || item.id) === (data.tempId || data.id)) {
            return data;
          } else {
            return item;
          }
        }),
      });
    } else {
      setBody({
        ...body,
        stocks: [...body.stocks, data],
      });
    }
  };
  const carouselSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: deliveryList.length > 1 ? 3 : 1,
    slidesToScroll: 1,
  };
  const [rawImages, setRawImages] = useState();

  const { categoryData } = usePages();
  const [body, setBody] = useState(false);
  const handleInput = (e) => {
    setBody({
      ...body,
      [e.target.name]: e.target.value,
    });
  };
  const handleEditStock = (tempId) => {
    if (!addStock) {
      const data = body.stocks.find(
        (item) => (item.tempId || item.id) === tempId && item
      );
      setInitialStock({ ...data, size: data.size || data.option?.size });
      setBody({
        ...body,
        stocks: body.stocks.filter(
          (item) => (item.tempId || item.id) !== tempId && item
        ),
      });
      setAddStock(true);
    } else {
      showToast("error", "complete or cancel ongoing edit");
    }
  };
  const handleDeleteStock = (tempId) => {
    setBody({
      ...body,
      stocks: body.stocks.filter(
        (item) => (item.tempId || item.id) !== tempId && item
      ),
    });
  };

  useEffect(() => {
    categoryData.data &&
      setBody({
        ...body,
        category: categoryData.data.results[0].id,
        subcategory: categoryData.data.results[0].id,
      });
  }, [categoryData.data]);

  useEffect(() => {
    setBody({ ...body, delivery_locations: deliveryList });
  }, [deliveryList]);

  const handleSubmitData = () => {
    const data = {
      ...body,
    };

    //delete unnecessary fields;
    delete data.gallary;
    delete data.delivery_mode;
    delete data.discount;
    delete data.stocks;
    delete data.referrals;
    delete data.allow_referral;

    data.delivery_locations = data.delivery_locations?.map((item) => {
      return {
        ...item,
        locations: item.locations.map((location) => {
          delete location.city;
          return location;
        }),
      };
    });

    //fix necessary fields
    data.page = data.page.id;

    if (!data.allow_referral) delete data.referrals;
    if (data.contact_seller) delete data.delivery_locations;
    try {
      //   if (!imagesFormData.has("files")) {
      //     throw new Error(JSON.stringify({ message: "please upload an image" }));
      //   }
      // if (imagesFormData.values(). > 10) {
      //   throw new Error(
      //     JSON.stringify({ images: "cannot upload morethan ten images" })
      //   );
      // }
      if (data.title.trim() === "") {
        throw new Error(JSON.stringify({ message: "Title cannot be empty" }));
      }
      if (data.category.name?.trim() === "") {
        throw new Error(
          JSON.stringify({ message: "Category cannot be empty" })
        );
      }
      if (data.subcategory.name?.trim() === "") {
        throw new Error(
          JSON.stringify({ message: "Subcategory cannot be empty" })
        );
      }
      if (data.description.trim() === "") {
        throw new Error(
          JSON.stringify({ message: "description cannot be empty" })
        );
      }
      if (!data.tags.length) {
        throw new Error(JSON.stringify({ message: "tags cannot be empty" }));
      }
      // if (!data.stocks[0].price) {
      //   throw new Error(JSON.stringify({ message: "price cannot be empty" }));
      // }
      if (!data.deposit_amount) {
        throw new Error(
          JSON.stringify({ message: "deposit amount cannot be empty" })
        );
      }

      if (!data.contact_seller && !data.delivery_locations.length) {
        throw new Error(
          JSON.stringify({
            message: "delivery locations cannot be empty",
          })
        );
      }
      handleUpdateListing(data, { pause: "all" });
    } catch (err) {
      // console.log(JSON.parse(err.message).message);
      console.log(err);
      showToast(
        "error",
        err.message
          ? JSON.parse(err.message).message
          : "An unknown error occurred!"
      );
    }
  };

  useEffect(() => {
    handleGetProduct(productId, { pause: "all" });
  }, []);
  useEffect(() => {
    if (getProductData.data?.id) {
      setBody({
        ...getProductData.data,
        sales_option: getProductData.data.allow_risk_managment
          ? "Pre order"
          : "Buy now",
      });
    }
  }, [getProductData.data]);

  useEffect(() => {
    if (updateListingData.data) {
      history.push(`/businessseller/profile/${pageId}`);
    }
  }, [updateListingData.data]);

  useEffect(() => {
    //update possible stocks adjustments

    if (body?.stocks && !addStock) {
      try {
        const { stocks } = body;
        const stocksWithUpdate = stocks.filter((stocks) => stocks.tempId);
        Promise.all(
          stocksWithUpdate.map(async (stock) => {
            console.log(stock);
            const { tempId } = stock;

            if (typeof tempId === "number") {
              //new stock
              return await (
                await handleCreateStock({
                  ...stock,
                  product: productId,
                })
              ).data;
            } else {
              //edited stock
              return await (
                await handleUpdateStock(stock)
              ).data;
            }
          })
        ).then((data) => {
          // console.log(data);
          data.filter((data) => data && data).length &&
            showToast("success", "Stocks Updated!");
        });
      } catch (err) {
        showToast("error", "An error occurred");
      }
    }
  }, [body?.stocks]);
  return body?.id ? (
    <div className="createlisting-body-container">
      <Toaster />
      <div className="clbc-card-body">
        {/*---------1  LIST INFORMATION CARD CONTENTS ------------------*/}
        <div className="clbc-cb-contents">
          <div className="sales-options">
            Sales Options{" "}
            <ReusableDropDown
              options={["Pre order", "Buy now"]}
              current={body.allow_risk_managment ? "Pre order" : "Buy now"}
              onChange={(e) => {
                // console.log(e);
                setBody({
                  ...body,
                  sales_option:
                    e.toLowerCase() === "buy now" ? "BUY_NOW" : "PRE_ORDER",
                });
              }}
            />
          </div>
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>1</span> List Information
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <div className="ccb__contents">
                <div className="input-group">
                  <label htmlFor="">List Title: </label>
                  <input
                    className="form-input"
                    type="text"
                    value={body.title}
                    name="title"
                    onChange={handleInput}
                  />
                </div>
                <div className="input-group  category-group">
                  <div className="list-category-container">
                    <label htmlFor="">List Category:</label>
                    <div className="dropbox">
                      {categoryData.data?.results && (
                        <ReusableDropDown
                          options={categoryData.data.results.map(
                            (item) => item.name
                          )}
                          current={body.category.name}
                          onChange={(e) =>
                            setBody({
                              ...body,
                              category: (categoryData.data?.results.find(
                                (item) => e === item.name && item
                              )).id,
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                  <div className="sub-category-container">
                    <label htmlFor="">Sub Category:</label>
                    <div className="dropbox">
                      {categoryData.data?.results && (
                        <ReusableDropDown
                          options={categoryData.data.results.map(
                            (item) => item.name
                          )}
                          current={body.subcategory.name}
                          onChange={(e) =>
                            setBody({
                              ...body,
                              subcategory: (categoryData.data?.results.find(
                                (item) => e === item.name && item
                              )).id,
                            })
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="input-group desc-container">
                  <label htmlFor="">Description:</label>
                  <textarea
                    className="form-input"
                    name="description"
                    id=""
                    cols="100"
                    rows="5"
                    value={body.description}
                    onChange={handleInput}></textarea>
                </div>
                <div className="input-group">
                  <label htmlFor="" className="tag-cover">
                    Tag:
                    <input
                      className="form-input form-input-tag"
                      type="text"
                      name="tag"
                      onKeyUp={(e) => {
                        if (e.key === "Enter") {
                          if (e.target.value.trim() !== "") {
                            //add to tags
                            setBody({
                              ...body,
                              tags: [...body.tags, e.target.value],
                            });

                            e.target.value = "";
                          }
                        }
                      }}
                    />{" "}
                  </label>
                  <div className="added-tags">
                    {body.tags?.map((item, idx) => (
                      <div className="tag-item" key={idx}>
                        <IoCloseCircleOutline
                          style={{ cursor: "pointer" }}
                          onClick={(e) => {
                            //close selection
                            setBody({
                              ...body,
                              tags: body.tags
                                .map((item, _idx) => _idx !== idx && item)
                                .filter((data) => data && data),
                            });
                          }}
                        />{" "}
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                <div className="tag-selection-container"></div>
              </div>
            </div>
          </div>
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4 className="toggle-2">
                <span>2</span> Stock Details
              </h4>
            </div>

            <div className="deals-cover">
              <div className="deals-wrap scroll-style-visible">
                {body.stocks?.map((item) => {
                  return (
                    <div
                      className="box-deals preview"
                      key={item.tempId || item.id}>
                      <div className="size-section">
                        <p>
                          Size: <span>{item.size || item.option?.size}</span>
                        </p>
                        <p>
                          Min Purchase Qty:{" "}
                          <span>{item.minimum_purchase_quantity}</span>
                        </p>
                      </div>
                      <div className="price-section">
                        <p>
                          Price: <span>${item.price}</span>
                        </p>
                        <p>
                          Set Stock Qty: <span>{item.quantity}</span>
                        </p>
                      </div>
                      {!!item.deals?.length && (
                        <div className="deals-detail">
                          <button>{item.deals[0].name}</button>
                          <span>
                            {item.deals[0].deals_and_discount_type
                              ?.toLowerCase()
                              .includes("fixed") && "$"}
                            {Number(item.deals[0].constant.fix_price) ||
                              Number(item.deals[0].constant.percentage)}
                            {item.deals[0].deals_and_discount_type
                              ?.toLowerCase()
                              .includes("percentage") && "%"}{" "}
                            off
                          </span>
                        </div>
                      )}
                      <span
                        className="deal-cancel"
                        style={{ cursor: "pointer" }}
                        onClick={handleDeleteStock.bind(
                          this,
                          item.tempId || item.id
                        )}>
                        <IoCloseCircleOutline />
                      </span>
                      <span
                        className="deal-edit"
                        style={{ cursor: "pointer" }}
                        onClick={handleEditStock.bind(
                          this,
                          item.tempId || item.id
                        )}>
                        <MdModeEdit />
                      </span>
                    </div>
                  );
                })}
                {!addStock && (
                  <span
                    onClick={setAddStock.bind(this, true)}
                    style={{ cursor: "pointer" }}>
                    Add
                  </span>
                )}
                {addStock && (
                  <Deals
                    initials={initialStock}
                    onDone={(data) => {
                      console.log(data);
                      handleAddToStock(data);
                      setInitialStock();
                      setAddStock();
                    }}
                  />
                )}
              </div>
            </div>
          </div>
          {/*---------2  PRICE/PAYMENTS CARD CONTENTS ------------------*/}
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>3</span> Payment
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <Payment heading={false} bodyState={[body, setBody]} />
            </div>
          </div>
          {body.sales_option?.toLowerCase().includes("pre") && (
            <div className="clbc-cbc-card">
              <div className="clbc-card-header acb-contents">
                <span>4</span>
                <div className="accept-box">
                  <label htmlFor="switch" className="">
                    Risk Management
                  </label>
                </div>
              </div>
              <div className="min-qty">
                <Toggle
                  size={30}
                  defaultState={body.allow_risk_managment}
                  onChange={(e) =>
                    setBody({ ...body, allow_risk_managment: e.value })
                  }
                />
                <span
                  className={classNames({
                    "disabled-item": !body.allow_risk_managment,
                  })}>
                  Min Qty required
                </span>
                <input
                  type={"number"}
                  className={classNames({
                    "disabled-item": !body.allow_risk_managment,
                  })}
                  value={body.risk_min_qty}
                  name="risk_min_qty"
                  onChange={handleInput}
                />
                <BiInfoCircle />
                <div className="last-order-date">
                  Last Order Date
                  <div className="date">
                    <FaCalendarAlt />{" "}
                    <DatePicker
                      // className="custom-date"
                      selected={new Date(body.last_order_date || new Date())}
                      onChange={(date) => {
                        if (new Date(date) >= Date.now()) {
                          setBody({ ...body, last_order_date: date });
                        } else {
                          showToast(
                            "error",
                            "date can only be greater or equal today"
                          );
                        }
                      }}
                      dateFormat="MMM d, yyyy hh:mm a"
                      showTimeSelect={true}
                    />
                  </div>
                  <BiInfoCircle />
                </div>
              </div>
            </div>
          )}
          {/*---------3  LISTINGS END DATE CARD CONTENTS ------------------*/}
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>
                  {body.sales_option?.toLowerCase().includes("pre") ? 5 : 4}
                </span>{" "}
                Listing End Date
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <div className="ccb__contents_led">
                <div className="input-group">
                  <label htmlFor="">Date: </label>
                  <div className="datepicker-container">
                    <FaCalendarAlt />

                    <DatePicker
                      className="custom-date"
                      selected={new Date(body.listing_end_date || new Date())}
                      onChange={(date) => {
                        if (new Date(date) >= Date.now()) {
                          setBody({ ...body, listing_end_date: date });
                        } else {
                          showToast(
                            "error",
                            "date can only be greater or equal today"
                          );
                        }
                      }}
                      dateFormat="MMM d, yyyy"
                    />
                  </div>
                </div>
                <div className="input-group">
                  <label htmlFor="">Time: </label>
                  <div className="timepicker-container">
                    <FaClock />

                    <DatePicker
                      className="custom-date"
                      selected={new Date(body.listing_end_date || new Date())}
                      dateFormat="h:mm aa"
                      onChange={(date) => {
                        if (new Date(date) >= Date.now()) {
                          setBody({
                            ...body,
                            listing_end_date: date,
                          });
                        } else {
                          showToast(
                            "error",
                            "date can only be greater or equal today"
                          );
                        }
                      }}
                      showTimeSelect
                      showTimeSelectOnly
                      timeIntervals={15}
                      timeCaption="Time"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* accept order auto switch */}
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4 className="toggle-2">
                <span>
                  {body.sales_option?.toLowerCase().includes("pre") ? 6 : 5}
                </span>{" "}
                Accept Order Automatically{" "}
                <div className="toggle-section">
                  <Toggle
                    size={30}
                    defaultState={body.accept_order_automatically}
                    onChange={(e) =>
                      setBody({ ...body, accept_order_automatically: e.value })
                    }
                  />{" "}
                  <BiInfoCircle className="info-circle" />
                </div>
              </h4>
            </div>
          </div>
          {/*---------4  PRICE/PAYMENTS CARD CONTENTS ------------------*/}
          <div className="clbc-cbc-card">
            <div className="clbc-card-header">
              <h4>
                <span>
                  {body.sales_option?.toLowerCase().includes("pre") ? 7 : 6}
                </span>{" "}
                Delivery detail
              </h4>
            </div>
            <div className="clbc-cbcc-body">
              <div className="ccb__contents_delivery">
                <div className="input-group">
                  <p>Create delivery mode. </p>
                  <div
                    className={classNames({ "disabled-item": contactSeller })}>
                    <label htmlFor="">Use saved: </label>
                    <div className="dropbox">
                      <ReusableDropDown />
                    </div>
                  </div>
                  <div className="contact-seller">
                    <MdInfoOutline />
                    Contact Seller
                    <CustomCheckBox size={15} getValue={setContactSeller} />
                  </div>
                </div>
                <div
                  className={classNames("deliveryTabContent", {
                    "disabled-item": contactSeller,
                  })}>
                  <Slider {...carouselSettings}>
                    <div
                      className="box add"
                      onClick={(e) => setBusinessSellerDelivery(true)}>
                      <div className="circle">
                        <PlusIcon />
                      </div>
                    </div>
                    {body.delivery_locations.map((item, idx) => {
                      return (
                        <DeliveryCard
                          deliveryListState={deliveryListState}
                          key={idx}
                          id={idx}
                          data={item}
                        />
                      );
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
          {/*---------5  MEDIA CARD CONTENTS ------------------*/}
          <div className="clbc-cbc-card disabled-item">
            <div className="clbc-card-header">
              <h4>
                <span>
                  {body.sales_option?.toLowerCase().includes("pre") ? 8 : 7}
                </span>{" "}
                Media
              </h4>
            </div>
            <p className="media-sub-heading">
              Add photos to display your product{" "}
              <span>
                Add photos with different angles to display show your products,
                ypu can upload a maximum of 10 photos
              </span>
            </p>
            <MediaContentsMultiple
              onChange={(images) => {
                if (!images.length) return;
                let x = 0;
                const formData = new FormData();
                while (x < images.length) {
                  formData.append("files", images[x]);
                  x++;
                }
                setImagesFormData(formData);
                setRawImages(images);
              }}
            />
          </div>
          {/*---------6  REFERRAL CARD CONTENTS ------------------*/}
          <div
            className={classNames("referral-switch disabled-item", {
              "disabled-item": contactSeller,
            })}>
            Referral{" "}
            <Toggle
              onChange={(e) => setBody({ ...body, allow_referral: e.value })}
            />
          </div>

          <div
            className={classNames("clbc-cbc-card disabled-item", {
              "disabled-item": contactSeller || !body.allow_referral,
            })}>
            <div className="clbc-card-header">
              <h4>
                <span>
                  {body.sales_option?.toLowerCase().includes("pre") ? 9 : 8}
                </span>{" "}
                Referral
              </h4>
            </div>

            <ReferralContents showHeader={false} state={[body, setBody]} />
          </div>

          {/*---------7  ACCEPT CARD CONTENTS ------------------*/}
        </div>
      </div>
      <div className="controls">
        <span onClick={history.goBack.bind()}>Back</span>
        {!updateListingData.loading ? (
          <span className="preview" onClick={handleSubmitData}>
            Save
          </span>
        ) : (
          <span className="preview">saving</span>
        )}
      </div>
    </div>
  ) : (
    <div className="">...</div>
  );
};

export default BsEditListing;
