import React, { createContext, useState, useContext,  useEffect } from "react"
import ModalContext from '../../../../../hooks/modalContexts'
import { Link } from 'react-router-dom'
import { FaEye, FaHeart } from 'react-icons/fa'
import person from '../../../../../assets/img/Ellipse 320.PNG';
import moment from "moment";

const ConnectCard = ({
  favorite,
  handleHideProfile,
  handleGetSingleProfile,
  singleProfileData,
  modalData,
  setModalData,
  handleLike,
  likeData,
  connectTo,
  setConnectTo,
  currentId,
  setCurrentId,
  setLikeSuggestedCard,
  currentProfile,
  acceptColor,
  setAcceptColor
}) => {
  const [isOpened, setOpened] = useState('false')
  const [isActive, setActive] = useState('false')
  const handleShow = () => {
    setOpened(!isOpened)
  }
  const {handleConnectModal} = useContext(ModalContext)

  const handleToggle = () => {
    if(likeData?.data){
      setActive(!isActive)
    }
  }

  useEffect(() => {
    if (singleProfileData?.data) {
      console.log(singleProfileData?.data?.page?.name);
      // dispatch(createSingleComment(singleCommentData.data));
      setModalData(singleProfileData?.data)
    }
  }, [singleProfileData?.data]);


  return (
    <>
    {favorite?.is_like === true && currentProfile &&
      <div className='connect-manage-card'>
        <div className='connect-manage-card-left'>
          <div className='connect-manage-card-user'>
            <div className='connect-manage-card-user-head'>
              <div>
                <img src={
                    favorite?.featured_image === null
                  ? person
                  : favorite?.featured_image
                } alt='' />
              </div>
              <div>
                <h2>{favorite?.name}</h2>
                <p>@{favorite?.username}</p>
              </div>
            </div>
            <div className='connect-manage-iconss'>
              <div className='connect-manage-eye'
                 onClick={() =>{
                  handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
                  // handleShow();
                  // handleGetSingleProfile(favorite?.id)
                  // setConnectTo(favorite?.id)
                  setModalData(favorite)
                  setConnectTo(favorite?.id)
                }}
                >
                <FaEye />
              </div>
              <div
                onClick={() =>{
                  handleLike(currentProfile, favorite?.id);
                  setCurrentId(favorite?.id)
                  setLikeSuggestedCard(favorite)
                }}
                className={
                  favorite?.is_like === true
                  ? 'connect-manage-heart favey'
                  : 'connect-manage-heart'
                }
                >
                <FaHeart />
              </div>
            </div>
          </div>
          <div className='connect-manage-card-text'>
            <div className='connect-manage-card-text-wrap'>
              <h1>{favorite?.title}</h1>
              <h3>{favorite?.location?.address}</h3>
              <p className='connect-manage-card-p1'>{favorite?.description}</p>
            </div>
            <div className='connectcard-button-flex'>
              <p className='connect-manage-card-p2'>
                {/*{moment(favorite.expire_on).format("MMMM Do YYYY")}*/}
              </p>
              <div>
                <div
                  className={isOpened ? 'connect-button-flex' : 'connect-hide'}
                >
                  <button
                    className='connect-connect'
                    onClick={() =>{
                      handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
                      // handleShow();
                      // handleGetSingleProfile(favorite?.id)
                      // setConnectTo(favorite?.id)
                      setModalData(favorite)
                      setConnectTo(favorite?.id)
                    }}
                     >
                    Connect
                  </button>
                </div>
                <div
                  className={isOpened ? 'connect-show' : 'connect-show appear'}
                >
                  <p>Connected</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </>
  )
}

export default ConnectCard
