import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useContext } from "react";
import MessageContext from "./messageContext";
import useUploads from "./uploads";

const useMessaging = () => {
	const { 
		setAllFollowers,
		setAllFollowing,
		setAllSupporting,
    setCurrentContactMessages,
    setCurrentMessagesLoading,
    setMessageStarred,
    setMessageFlagged,
    setFlaggedMessages,
    setStarredMessages,
    setUserChatList,
    setMessageDeleted,
    setChatGroupLoading,
	} = useContext(MessageContext);

  const {handleUploads} = useUploads();


  // Get My Followers
  const [{ ...getFollowersData }, getFollowers] = useAxios({
    method: "GET",
  });
  const handleGetFollowers = () => {
    getFollowers({
      url: `/users/followers`
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getFollowersData.data) {
      setAllFollowers(getFollowersData);
    }
  }, [getFollowersData.data]);


  // Get My Following
  const [{ ...getFollowingData }, getFollowing] = useAxios({
    method: "GET",
  });
  const handleGetFollowing = () => {
    getFollowing({
      url: `/users/following`
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getFollowingData.data) {
      setAllFollowing(getFollowingData);
    }
  }, [getFollowingData.data]);


  // Get My Supporting
  const [{ ...getSupportingData }, getSupporting] = useAxios({
    method: "GET",
  });
  const handleGetSupporting = () => {
    getSupporting({
      url: `/users/supporting`
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getSupportingData.data) {
      setAllSupporting(getSupportingData);
    }
  }, [getSupportingData.data]);

  // Fetch Contact Messages
  const [{ ...fetchContactMessagesData }, fetchContactMessages] = useAxios({
    method: "GET",
  });
  const handleFetchContactMessages = (contact) => {
    setCurrentMessagesLoading(true);
    fetchContactMessages({
      url: `/messenger/${contact.username}/fetch/`
    }).catch((error) => console.log(error))
    .finally(() => setCurrentMessagesLoading(false));
  };
  useEffect(() => {
    if (fetchContactMessagesData.data) {
      setCurrentContactMessages(fetchContactMessagesData);
    }
  }, [fetchContactMessagesData.data]);


  // upload Message Media
  const handleUploadMessageMedia = async (files) => {
    const newUploaded = [];
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();

        formData.append("files", files[i]);
        const uploadedData = await handleUploads(formData);

        console.log(uploadedData.data);
        // return;
        newUploaded.push(uploadedData.data?.results[0]?.id);
      }

      console.log(newUploaded);
    }
    
    return newUploaded;
  }


  // star message
  const [{...starMessageData}, starMessage] = useAxios({
    method: "POST",
  });
  const handleStarMessage = (messageId) => {
    starMessage({
      url: `https://dev.api.meeney.com/v1/messenger/${messageId}/starred/`
    }).catch((error) => console.log(error))
  };
  useEffect(() => {
    if(starMessageData.data) {
      setMessageStarred(true);
      setTimeout(() => {
        setMessageStarred(false);
      }, 2000);
    }
  }, [starMessageData.data]);
    

  // flag message
  const [{...flagMessageData}, flagMessage] = useAxios({
    method: "POST",
  });
  const handleFlagMessage = (messageId) => {
    flagMessage({
      url: `https://dev.api.meeney.com/v1/messenger/${messageId}/flagged/`
    }).catch((error) => console.log(error))
  };
  useEffect(() => {
    if(flagMessageData.data) {
      setMessageFlagged(true);
      setTimeout(() => {
        setMessageFlagged(false);
      }, 2000);
    }
  }, [flagMessageData.data]);
    
    
  // get flagged messages
  const [{...flaggedMessagesData}, flaggedMessages] = useAxios({
    method: "GET",
  });
  const handleGetFlaggedMessages = (messageId) => {
    setChatGroupLoading(true);
    flaggedMessages({
      url: `https://dev.api.meeney.com/v1/messenger/flagged_list/` 
    }).catch((error) => console.log(error))
  };
  useEffect(() => {
    if(flaggedMessagesData.data) {
      setChatGroupLoading(false);
      setFlaggedMessages(flaggedMessagesData.data.results);
    }
  }, [flaggedMessagesData.data]);
    
    
  // get starred messages
  const [{...starredMessagesData}, starredMessages] = useAxios({
    method: "GET",
  });
  const handleGetStarredMessages = (messageId) => {
    setChatGroupLoading(true);
    starredMessages({
      url: `https://dev.api.meeney.com/v1/messenger/starred_list/` 
    }).catch((error) => console.log(error))
  };
  useEffect(() => {
    if(starredMessagesData.data) {
      setChatGroupLoading(false);
      setStarredMessages(starredMessagesData.data.results);
    }
  }, [starredMessagesData.data]);
    
    
  // get chat list
  const [{...chatListData}, chatList] = useAxios({
    method: "GET",
  });
  const handleGetChatList = () => {
    setChatGroupLoading(true);
    chatList({
      url: `https://dev.api.meeney.com/v1/users/chatlist/` 
    }).catch((error) => console.log(error))
  };
  useEffect(() => {
    if(chatListData.data) {
      setChatGroupLoading(false);
      let listRaw = chatListData.data.results;
      const sortedDesc = listRaw?.sort(
        (objA, objB) => new Date(objA.last_message.timestamp).getTime() - new Date(objB.last_message.timestamp).getTime(),
      );

      setUserChatList(sortedDesc);
    }
  }, [chatListData.data]);

    
  // delete message
  const [{...deleteMessageData}, deleteMessage] = useAxios({
    method: "DELETE",
  });
  const handleDeleteMessage = (id) => {
    deleteMessage({
      url: `messenger/${id}/`
    }).catch((error) => console.log(error))
  };
  useEffect(() => {
    if(deleteMessageData.data) {
      setMessageDeleted(deleteMessageData.data.id);
    }
  }, [deleteMessageData.data]);



	return {
    handleGetFollowers,
		handleGetFollowing,
		handleGetSupporting,
    handleUploadMessageMedia,
    handleFetchContactMessages,
    handleStarMessage,
    handleFlagMessage,
    handleGetFlaggedMessages,
    handleGetStarredMessages,
    handleGetChatList,
    handleDeleteMessage,
	}
};

export default useMessaging;