import "./mins.scss";
import { useState, useRef, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const Mins = ({ onChange = () => {}, max = 120, min = 1, number, setNumber }) => {
//   const [number, setNumber] = useState(12);
  const inputRef = useRef(null);
  const [am, setAm] = useState(true);

//   useEffect(() => {
//     onChange(number);
//   }, [number]);
  return (
    <div className="mins">
      <div
        className="preview"
        onClick={(e) => {
          inputRef.current.focus();
          console.log("clicked");
        }}>
        {number} <span onClick={(e) => setAm(!am)}> min </span>
      </div>
      <div className="icons">
        <FaAngleUp
          onClick={(e) => {
            if (number < max) {
              setNumber(number + 1);
            } else {
              setNumber(min);
            }
          }}
        />
        <FaAngleDown
          onClick={(e) => {
            if (number > 1) {
              setNumber(number - 1);
            } else {
              setNumber(max);
            }
          }}
        />
      </div>
      <input
        type="number"
        value={number}
        style={{ opacity: 0, position: "absolute" }}
        ref={inputRef}
        onChange={(e) => setNumber(e.target.value)}
        min={min}
        max={max}
      />
    </div>
  );
};

export default Mins;
