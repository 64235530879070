import "./postCard.scss";
import { MoreOptionsIcon } from "../../assets/svg/Extras";
import { useHistory, useParams } from "react-router-dom";
import postCardOptions from "../../utils/postCardOptions";
import { useState, useEffect, Fragment, useRef } from "react";
import PostMatrics from "./postMatrics";
import render from "react-render-html";
import useDeviceType from "../../hooks/deviceType";
import { useDispatch, useSelector } from "react-redux";
import VuesaxBoldHeartComponent from "../../assets/iconsax/VuesaxBoldHeartComponent";
import VuesaxLinearMessageComponent from "../../assets/iconsax/VuesaxLinearMessageComponent";
import VuesaxLinearArchiveMinusComponent from "../../assets/iconsax/VuesaxLinearArchiveMinusComponent";
import VuesaxLinearSendComponent from "../../assets/iconsax/VuesaxLinearSendComponent";
import usePosts from "../../hooks/posts";
import HeartComponent from "../../assets/iconsax/active/HeartComponent";
import ActiveBookmark from "../../assets/iconsax/active/ActiveBookmark";
import { formatText, handlePostText } from "../../utils/formatText";
import { resetSinglePostData } from "../../appstore/singlPostData/index.action";
import MediaBox from "../mediaBox";
import { DefaultButton } from "../Button";
const PostCard = ({
  optionVisibility,
  text,
  media,
  num_of_comments,
  num_of_likes,
  is_saved,
  is_liked,
  id,
  tags,
  owner,
  comments,
  replies,
  created_at,
  modified_on,
  published,
  hidden,
  post_type,
  isOwner,
  page,
  product,
  pageData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { pageId } = useParams();
  const { user } = useSelector((state) => state.currentUser);
  const commentBox = useRef(null);
  const [isOptionsVisible, setIsOptionsVisible] = optionVisibility;
  const { isMobile } = useDeviceType();
  const [comment, setComment] = useState("");
  // const { handleMediaBox } = useContext(ModalContext);
  // const options =
  const {
    handleLike,
    handleSave,
    handleDelete,
    handleMakeComment,
    makeCommentData,
    handleHidePost,
  } = usePosts();
  const selectedComment = comments.length && comments[comments.length - 1];
  //edit post texts for hashtags and mentions

  const handleOptionsClick = (name = "") => {
    if (name.toLowerCase() === "delete post") {
      handleDelete(id);
    }
    if (name.toLowerCase() === "hide") {
      handleHidePost(id);
    }
    //include if save
  };
  const handleComment = () => {
    const page = pageData?.owner === user.id && pageId;
    if (comment.trim() !== "") {
      handleMakeComment(id, formatText(comment), undefined, page);
    }
  };
  console.log(pageId, pageData);
  //reset comment box
  useEffect(() => {
    if (makeCommentData.data) {
      commentBox.current.innerHTML = "";
      setComment("");
    }
  }, [makeCommentData.data]);

  const checkIfSingle = () => {
    if (
      (!product?.gallary.length && !media?.length) ||
      (!product?.description && !text)
    )
      return "single";
    return;
  };

  const handlePostSelection = () => {
    dispatch(resetSinglePostData());
    history.push(`/post/${id}/${pageId ? pageId : ""}`);
  };
  return (
    <div
      className={`post__card ${checkIfSingle()}`}
      onClick={(e) => isOptionsVisible === id && setIsOptionsVisible(null)}>
      {/* {isMobile && <PostMatrics postData={postData} avatar={avatar} />} */}
      {!text && !product?.description && (
        <PostMatrics
          {...{
            ...owner,
            likes: num_of_likes,
            comment: num_of_comments,
            time: created_at,
            page: pageData,
          }}
        />
      )}
      {media.length ? (
        <div
          style={{
            ...(checkIfSingle() === "single" && { margin: "15px 0" }),
            borderRadius: 15,
            overflow: "hidden",
          }}
          onClick={(e) => {
            // handleMediaBox("CREATE_MEDIA", media);
          }}>
          <MediaBox media={media} />
        </div>
      ) : (
        ""
      )}
      {product?.gallary.length ? (
        <div
          style={{
            ...(checkIfSingle() === "single" && { margin: "15px 0" }),
            borderRadius: 15,
            overflow: "hidden",
          }}
          onClick={(e) => {
            // handleMediaBox("CREATE_MEDIA", media);
          }}>
          <MediaBox media={product.gallary} />
        </div>
      ) : (
        ""
      )}
      <div
        className="section__2"
        style={{
          ...(!media.length &&
            !product?.gallary.length && {
              minWidth: "100%",
            }),
        }}>
        {!isMobile && (text || product?.description) && (
          <PostMatrics
            {...{
              ...owner,
              likes: num_of_likes,
              comment: num_of_comments,
              time: created_at,
              page,
            }}
          />
        )}
        {(product?.description || text) && (
          <div
            className="post"
            style={{
              ...(!media.length && {
                fontSize: 23,
                lineHeight: "30px",
                marginTop: "20px",
              }),
            }}>
            {product && (
              <span
                style={{
                  display: "block",
                  color: "#490057",
                  fontWeight: 500,
                  textTransform: "capitalize",
                }}>
                {product.title}{" "}
                <span style={{ fontWeight: 300 }}>
                  ${product.deposit_amount}
                </span>
              </span>
            )}
            {render(handlePostText(product?.description || text, page))}
          </div>
        )}
        {!!comments.length && (
          <div className="comment">
            <h5 onClick={handlePostSelection}>View all comments</h5>
            {/* <h5>reply</h5> */}
            <div className="section__">
              <div className="avatar">
                <img
                  src={selectedComment.owner.profile_image?.file}
                  alt="user"
                />
              </div>
              <div className="detail">
                <div
                  className="name"
                  onClick={() =>
                    selectedComment.page
                      ? history.push(
                          `/${
                            selectedComment.page.type.toLowerCase() ===
                            "business"
                              ? "businessseller"
                              : "privateseller"
                          }/profile/${selectedComment.page.id}`
                        )
                      : history.push(
                          `/feeds/timeline/${selectedComment.owner.username}`
                        )
                  }>
                  {selectedComment.page?.name || selectedComment.owner.username}
                </div>
                <small className="last__seen">
                  {/* Last Seen {selectedComment.lastSeen} */}
                </small>
                <div className="main__comment">
                  {render(handlePostText(selectedComment.body))}
                  <p className="reply">Reply</p>
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="post-actions">
          <div className="icon" onClick={(e) => handleLike(id)}>
            {is_liked ? <HeartComponent /> : <VuesaxBoldHeartComponent />}
          </div>
          <div className="icon" onClick={handlePostSelection}>
            <VuesaxLinearMessageComponent />
          </div>

          {!product ? (
            <>
              <div
                ref={commentBox}
                contentEditable={true}
                className="comment__box"
                data-placeholder="Write a comment"
                onClick={handlePostSelection}></div>

              <div className="icon share__" onClick={(e) => handleComment()}>
                <VuesaxLinearSendComponent />
              </div>
            </>
          ) : (
            <div style={{ marginLeft: "auto" }}>
              <DefaultButton
                text={product.pre_order ? "Join Queue" : "Buy Now"}
                customStyle={{ fontSize: 12, marginRight: 10 }}
              />
              <DefaultButton
                text={"Support"}
                customStyle={{ fontSize: 12, backgroundColor: "#490057" }}
              />
            </div>
          )}
        </div>
      </div>
      <div className="extra__options">
        <div className="more__options" onClick={(e) => setIsOptionsVisible(id)}>
          <MoreOptionsIcon />
        </div>
      </div>
      {!isMobile && isOptionsVisible === id && (
        <ul className="option__">
          {postCardOptions.map((opt, idx) => (
            <Fragment key={idx}>
              {!isOwner && opt.private ? undefined : (
                <li
                  onClick={(e) => handleOptionsClick(opt.name)}
                  style={{
                    ...(isOwner &&
                      (opt.name.toLowerCase() === "hide" ||
                        opt.name.toLowerCase() === "report post") && {
                        display: "none",
                      }),
                  }}>
                  {/* some icon */}
                  <opt.icon />
                  <p> {opt.name}</p>
                </li>
              )}
            </Fragment>
          ))}
          <li onClick={(e) => handleSave(id)}>
            {is_saved ? (
              <ActiveBookmark />
            ) : (
              <VuesaxLinearArchiveMinusComponent />
            )}{" "}
            <p>{is_saved ? "Saved" : "Save"}</p>
          </li>
        </ul>
      )}

      {/* {console.log(media)} */}
    </div>
  );
};

export default PostCard;
