/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearHomeComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-620 -188)">
      <path
        className="a"
        d="M7.02.823l-5.39,4.2A4.759,4.759,0,0,0,0,8.343v7.41a4.225,4.225,0,0,0,4.21,4.22H15.79A4.223,4.223,0,0,0,20,15.763V8.483a4.723,4.723,0,0,0-1.8-3.45L12.02.7A4.487,4.487,0,0,0,7.02.823Z"
        transform="translate(622 190.017)"
      />
      <path className="a" d="M0,3V0" transform="translate(632 202.99)" />
      <path className="b" d="M0,0H24V24H0Z" transform="translate(620 188)" />
    </g>
  </svg>
);

export default VuesaxLinearHomeComponent;
