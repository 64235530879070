import Autocomplete from "react-google-autocomplete";
import { useEffect, useRef, useState } from "react";
import PhoneInput from "react-phone-number-input";
import { useSelector } from "react-redux";
import profilePic from "../../../../../assets/img/Ellipse 4.png";
import AddIcon from "../../../../../assets/svg/addIcon";
import CheckIcon from "../../../../../assets/svg/checkIcon";
import FormDown from "../../../../../assets/svg/formDown";
import GreenAddIcon from "../../../../../assets/svg/greenAddIcon";
import Loader from "../../../../../components/loader";
import useCreatePage from "../../../../../hooks/createPage";
import useSettings from "../../../../../hooks/settings";
import useUploads from "../../../../../hooks/uploads";
import rebuild from "../priavateSellerComponents/rebuild";

const BusinessPageDisplay = ({ businessCount }) => {
  const currentUser = useSelector((state) => state.currentUser.user);

  const {
    handleGetPageSpecificData,
    pageSpecificData,
    handlePatchFeaturedImage,
    featuredImageData,
    handlePatchPageData,
    patchedPageData,
  } = useSettings();

  const { handleUploads, handleDeleteData, deletedData } = useUploads();

  const [formdata, setFormData] = useState({});

  useEffect(() => {
    handleGetPageSpecificData(currentUser?.pages[businessCount]?.id);
  }, [
    businessCount,
    featuredImageData?.data,
    deletedData?.data,
    patchedPageData?.data,
  ]);

  useEffect(() => {
    setFormData(pageSpecificData?.data);
  }, [pageSpecificData?.data, businessCount]);

  const ref = useRef();
  const handleClick = () => {
    ref.current.click();
  };

  const [featuredImage, setFeaturdImage] = useState();

  const { getCategory, categoryData } = useCreatePage();

  const [fileId, setFileId] = useState();

  useEffect(async () => {
    const formData = new FormData();
    formData.append("files", featuredImage);
    setFileId(await handleUploads(formData));
  }, [featuredImage]);

  useEffect(() => {
    handlePatchFeaturedImage(
      fileId?.data?.results[0]?.id,
      currentUser?.pages[businessCount]?.id,
      "featured"
    );
  }, [fileId?.data?.results[0]?.id]);

  const [currentCategory, setCurrentCategory] = useState();

  useEffect(() => {
    // categoryData?.data?.results?.map((categoryDataItem, idx) => {
    //   if (pageSpecificData?.data?.category?.name === categoryDataItem?.name) {
    //     setCurrentCategory(categoryDataItem?.name);
    //   }
    // });
    setCurrentCategory(pageSpecificData?.data?.category?.name);
  }, [pageSpecificData?.data]);

  const [dropped, setDropped] = useState(false);

  const [hours, setHours] = useState();

  useEffect(() => {
    setHours(rebuild(formdata?.opening_hours));
  }, [formdata]);

  const [dataToSubmit, setDataToSubmit] = useState();
  useEffect(() => {
    setDataToSubmit({
      name: formdata?.name,
      description: formdata?.description,
      phone_number: formdata?.phone_number,
      email: formdata?.email,
      username: formdata?.username,
      website: formdata?.website,
      category: formdata?.category,
      address: formdata?.address,
      location: formdata?.location,
    });
  }, [formdata]);
  console.log(categoryData, "the data");
  console.log(pageSpecificData, "the img");
  console.log(dataToSubmit, "hours");

  return (
    <div className="businessPage_display">
      {pageSpecificData?.loading || (!pageSpecificData?.data && <Loader />)}
      {!pageSpecificData?.loading && pageSpecificData?.data && (
        <form action="">
          <div className="form_top">
            <div className="form_top_left">
              <label htmlFor="">
                <p>Business name</p>
                <input
                  type="text"
                  value={formdata?.name}
                  onChange={(e) =>
                    setFormData({ ...formdata, name: e.target.value })
                  }
                />
              </label>
              <label className="description" htmlFor="">
                <p>Description</p>
                <textarea
                  contentEditable="true"
                  onInput={(e) =>
                    setFormData({
                      ...formdata,
                      description: e.target.value,
                    })
                  }
                  value={formdata?.description}
                  style={{
                    width: "100%",
                    height: "100px",
                    background: "#f2f2f2",
                    border: "none",
                    resize: "none",
                    borderRadius: "10px",
                    padding: "10px 20px",
                  }}
                ></textarea>
              </label>
              <label htmlFor="">
                <p>Business phone</p>
                <PhoneInput
                  international
                  defaultCountry="US"
                  placeholder="(201) 555-55555"
                  onChange={(e) =>
                    setFormData({ ...formdata, phone_number: e })
                  }
                  value={formdata?.phone_number}
                />
              </label>
            </div>
            <div className="form_top_right">
              <div className="centerDiv">
                <img
                  src={formdata?.featured_image?.file || profilePic}
                  alt=""
                />
                <div onClick={() => handleClick()}>
                  <span>Change profile picture</span>
                </div>
                <div
                  onClick={() => handleDeleteData(formdata?.featured_image?.id)}
                >
                  <span>Delete image</span>
                </div>
                <input
                  onChange={(e) => setFeaturdImage(e.target.files[0])}
                  ref={ref}
                  type="file"
                  accept="image/png, image/jpg"
                />
              </div>
            </div>
          </div>
          <div className="form_bottom">
            <label htmlFor="">
              <p>Email</p>
              <div className="marked_div">
                <input
                  type="email"
                  onChange={(e) => {
                    setFormData({ ...formdata, email: e.target.value });
                  }}
                  value={formdata?.email}
                />
                <CheckIcon />
              </div>
            </label>
            <label htmlFor="">
              <p>Create a username</p>
              <div className="marked_div">
                <input
                  type="text"
                  onChange={(e) => {
                    setFormData({ ...formdata, username: e.target.value });
                  }}
                  value={formdata?.username}
                />
                <CheckIcon />
              </div>
            </label>
            <label htmlFor="">
              <p>Website</p>
              <input
                type="text"
                onChange={(e) => {
                  setFormData({ ...formdata, website: e.target.value });
                }}
                value={formdata?.website}
              />
            </label>
            <div className="category_location">
              <label htmlFor="">
                <p>Select business category</p>
                <div
                  className="marked_div dropdown"
                  onClick={() => setDropped(!dropped)}
                >
                  <span>
                    {currentCategory
                      ? currentCategory
                      : "Please select category"}
                  </span>
                  <FormDown />
                  {categoryData && dropped && (
                    <ul className="dropdown_menu">
                      {categoryData?.data?.results?.map((categoryItem, idx) => {
                        return (
                          <li
                            onClick={() => {
                              setCurrentCategory(categoryItem.name);
                              setFormData({
                                ...formdata,
                                category: categoryItem.id,
                              });
                            }}
                          >
                            {categoryItem.name}
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
              </label>
              <label htmlFor="">
                <p>Set Location</p>
                {/* <input type="text" /> */}
                <Autocomplete
                  onPlaceSelected={(place) => {
                    console.log(place);
                    setDataToSubmit({
                      ...dataToSubmit,
                      location: {
                        address: place?.formatted_address,
                      },
                    });
                  }}
                  options={{
                    types: "address",
                  }}
                />
              </label>
            </div>
            <label htmlFor="">
              <p>Business address</p>
              <div
                className="address"
                onInput={(e) =>
                  setDataToSubmit({
                    ...dataToSubmit,
                    location: {
                      address: e.target.innerHTML,
                    },
                  })
                }
              >
                {formdata?.location?.address}
              </div>
            </label>
            <label htmlFor="">
              <p>Opening Hours</p>
              {hours?.map((hourItem, idx) => {
                return (
                  <div className="hours">
                    <p>Day</p>
                    <div className="dateBox">
                      <span>{hourItem.title}</span>
                    </div>
                    <p>From</p>
                    <div>
                      <span>
                        {hourItem.value.from}
                        {hourItem.value.from > 11 ? " pm" : " am"}
                      </span>
                    </div>
                    <p>To</p>
                    <div>
                      <span>
                        {hourItem.value.to}
                        {hourItem.value.to > 11 ? " pm" : " am"}
                      </span>
                    </div>
                  </div>
                );
              })}
              <div className="add">
                <GreenAddIcon />
                <span>Add</span>
              </div>
            </label>
            <div className="save">
              <span
                onClick={() =>
                  handlePatchPageData({
                    data: dataToSubmit,
                    id: currentUser?.pages[businessCount]?.id,
                  })
                }
              >
                {patchedPageData.loading ? <Loader /> : <span>Save</span>}
              </span>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};
export default BusinessPageDisplay;
