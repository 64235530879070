import MyPages from "../MyPages";
import Options from "../Options";
import SwitchAccounts from "../SwithAccount/SwitchAccounts";
import "./left.scss";
import PageAdmins from "../pageAdmins/pageAdmins";
import { useSelector } from "react-redux";

const LeftSectionFeed = ({ viewContent, menuVisibility }) => {
  const { accountType } = useSelector((state) => state.currentUser);
  const [isMobileMenuVisible, setIsMobileMenuVisible] = menuVisibility;
  return (
    <div
      className={`left__wrap ${isMobileMenuVisible && "reveal__mobile__menu"}`}
      onClick={(e) => setIsMobileMenuVisible(false)}>
      <div className="left" onClick={(e) => e.stopPropagation()}>
        <SwitchAccounts />
        {/* <PageAdmins /> */}
        <Options />
        {accountType === "business" && <MyPages />}
      </div>
    </div>
  );
};

export default LeftSectionFeed;
