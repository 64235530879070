import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { keyframes } from "styled-components";

import Navbar from "../layout/navbar";
import { colors } from "../../color";
import media from "../../responsive";

import image from "../../assets/icons/meeney__landing-girl.png";
import logo from "../../assets/icons/meeney__white-logo.png";
import msgLogo from "../../assets/icons/msg.png";
import thumbLogo from "../../assets/icons/thumb.png";
import mobileLogo from "../../assets/icons/mobile.png";
import communityLogo from "../../assets/icons/community.png";
import shopLogo from "../../assets/icons/shop.png";
import standLogo from "../../assets/icons/stand.png";
import jobLogo from "../../assets/icons/job.png";
import appleLogo from "../../assets/icons/apple.png";
import androisLogo from "../../assets/icons/androis.png";
import referLogo from "../../assets/img/job-search-concept-magnifying-glass-with-case-question-marks.png";
import PointCard from "./pointCard/pointCard";
import AOS from "aos";
import "aos/dist/aos.css";
import "./index.scss";

AOS.init();

const GetStartedDiv = styled.div`
  background: ${colors.COMPANY_COLOR};
  width: 220px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 30px;
  border: 1px solid #fff;
  cursor: pointer;
  ${media.mobile`
  margin: auto
  `}
  ${media.tablet`
  margin: auto
  `}
`;

const Getstarted = styled(Link)`
  color: ${colors.WHITE};
  font-weight: bold;
  &:hover {
    color: ${colors.WHITE};
  }
`;

const ButtonLink = styled(Link)`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 55px;
  border-radius: 30px;
  background: ${colors.COMPANY_COLOR};
  color: ${colors.WHITE};
  font-weight: bold;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
    color: ${colors.WHITE};
  }

  @media (min-width: 768px) {
    width: 250px;
  }
`;

const ButtonWhiteLink = styled.a`
  display: block;
  margin: 0 auto;
  width: 100%;
  height: 55px;
  border-radius: 30px;
  background: ${colors.WHITE};
  color: ${colors.COMPANY_COLOR};
  border: 1px solid ${colors.COMPANY_COLOR};
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    text-decoration: none;
    color: ${colors.COMPANY_COLOR};
  }
`;

const HeaderText = styled.p`
  font-size: 4rem;
  width: 100%;
  color: ${colors.ORANGE};
  line-height: 4rem;
  letter-spacing: 0.1rem;
  margin-bottom: 0.3em;
  ${media.mobile`
        text-align: center;
        font-size: 40px;
        width: 90%;
        margin: auto;
        margin-top: 10px;
        line-height: 2.5rem;
    `}
  ${media.tablet`
        text-align: center;
        font-size: 40px;
        width: 90%;
        margin: auto;
        margin-top: 20px;
        line-height: 2.5rem;
    `}
`;
const SubText = styled.p`
  ${media.mobile`
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
    `}
  ${media.tablet`
        text-align: center;
        margin-top: 20px;
        font-size: 18px;
    `}
`;
const SubDiv = styled.div`
  width: 45%;
  font-size: 18px;
  color: #fff;
  ${media.mobile`
        width: 90%;
        margin: auto;
    `}
  ${media.tablet`
        width: 90%;
        margin: auto;
    `}
`;
const LogoImg = styled.img`
  max-height: 700px;
  top: 20px;
  z-index: 99;
  ${media.mobile`
        width: 100%;
    `}
  ${media.tablet`
        margin-top: 10px;
    `}
`;

const Grid = styled.div`
  width: 90%;
  margin: 3rem auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  grid-gap: 1rem;

  @media (min-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: 1280.99px) {
    grid-template-columns: repeat(4, 1fr);
  }
`;

const DownloadRow = styled.div`
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
  width: 100%;
  height: 100px;

  @media (min-width: 1280.99px) {
    justify-content: flex-start;
  }
`;

const DownloadLinksRow = styled.div`
  padding: 0 10%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @media (min-width: 1280.99px) {
    align-items: flex-start;
  }
`;

const LinksGrid = styled.div`
  width: 100%;
  margin: 3rem 0;
  display: grid;
  grid-gap: 0.2rem;

  @media (min-width: 769px) {
    grid-template-columns: repeat(2, 1fr);
    width: 600px;
  }
`;

const DownloadTextRow = styled.div`
  padding: 0 10%;
  box-sizing: border-box;
  width: 100%;

  @media (min-width: 769px) {
    width: 600px;
    margin: 0 auto;
    padding: 0;
  }

  @media (min-width: 1280.99px) {
    align-items: flex-start;
    padding: 0 0 0 10%;
    margin: 0;
  }
`;
const phoneAnimation = keyframes`

`;

const MobileImg = styled.img`
  height: 300px;
  width: 300px;
  display: block;
  margin: 0 auto;

  @media (min-width: 1280.99px) {
    position: absolute;
    width: unset;
    height: unset;
    max-height: 800px;
    right: 30px;
    top: -20px;
  }
`;
// top: -120px;
// right: 100px;
// overflow: hidden;

// @media (max-width: 1440px) and (min-width: 1000px) {
//   max-height: 500px
// }

// @media (max-width: 999.99px) {
//   max-height: 400px;
//   margin: 10px auto;
//   top: 150px;
//   right: auto;
// }

// @media (max-width: 768px) {
//   max-height: 300px;
//   width: 300px;
//   margin: 0 auto;
// }

const Section = styled.div`
  ${media.mobile`
        margin-top: 50px;
        width: 90%;
        margin: auto;
        text-align: center;
    `}
  ${media.tablet`
        margin-top: 50px;
        width: 90%;
        margin: auto;
        text-align: center;
    `}
`;

export default class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Col
          className="m-auto generalContain"
          style={{
            width: "100%",
            padding: "0 10% 0",
            background: colors.BRAND_GRADIENT,
            maxHeight: "750px",
          }}>
          <Navbar
            source={logo}
            isRegister
            isSignIn
            color={colors.WHITE}
            signInColor="white"
            backgroundColor={colors.COMPANY_COLOR}
            theme="dark"
          />
          <Col
            xl={12}
            style={{
              padding: "5.5rem 0",
            }}>
            <Row className="d-flex jusify-content-between ">
              <Col xl={6} sm={12}>
                <div className="align-items-center">
                  <img src={msgLogo} alt="meeney_msg" />
                  <HeaderText className="pt-4 ml-md-5 ml-sm-0">
                    Your New
                    <br />
                    Social Life.
                  </HeaderText>
                  <SubDiv
                    style={{
                      width: "100%",
                    }}
                    className="text-start ml-md-5 ml-sm-0">
                    <SubText
                      style={{
                        fontSize: "20px",
                      }}>
                      Life feels better when you <br /> connect with people,
                      especially ones that matter to you
                    </SubText>
                  </SubDiv>
                </div>

                <img src={thumbLogo} alt="meeney_msg" />

                <Section className="ml-md-5 ml-sm-0 mt-5">
                  <p
                    style={{
                      fontWeight: "normal",
                      fontSize: "16px",
                      color: "#fff",
                    }}>
                    Not a member ?{" "}
                    <a
                      href="/signup"
                      style={{
                        color: "#FBB03B",
                      }}>
                      Sign up
                    </a>
                  </p>
                  <GetStartedDiv className="d-flex justify-content-center">
                    <Getstarted
                      style={{ textDecoration: "none" }}
                      to="/explore">
                      Explore Meeney
                    </Getstarted>
                  </GetStartedDiv>
                </Section>
              </Col>
              <Col
                style={{}}
                className="d-flex justify-content-center align-items-center mt-3 "
                xl={6}
                sm={12}>
                <LogoImg data-aos="fade-left" src={image} alt="" />
              </Col>
            </Row>
          </Col>
          {/* </Row> */}
        </Col>

        <Col
          data-aos="fade-up"
          data-aos-delay="250"
          className="m-auto"
          style={{
            width: "100%",
            padding: "3rem 10%",
            background: colors.WHITE,
          }}>
          <Row
            style={{
              width: "100%",
              textAlign: "center",
              marginTop: 60,
            }}>
            <p
              style={{
                fontSize: "2.5rem",
                lineHeight: "2.8rem",
                margin: "0 auto",
                color: "#9B9B9B",
              }}>
              Explore. Connect. Grow.
            </p>
          </Row>
          <Row
            style={{
              width: "100%",
              textAlign: "center",
            }}>
            <p
              style={{
                fontSize: "1.75rem",
                lineHeight: "1.9rem",
                margin: "0 auto",
                color: "#9B9B9B",
              }}>
              Do more just in one space
            </p>
          </Row>

          <Grid>
            <PointCard
              image={communityLogo}
              header="Connect with the community"
            />
            <PointCard
              image={shopLogo}
              header="Sell anything as an Individual"
            />
            <PointCard image={standLogo} header="Sell as a Business Owner" />
            <PointCard image={jobLogo} header="Find Jobs around you" />
            <PointCard image={referLogo} header="Refer and earn" />
          </Grid>

          <ButtonLink href="/explore">
            <span>Take Me There!</span>
          </ButtonLink>
        </Col>

        <Row
          data-aos="fade-up"
          data-aos-delay="250"
          style={{
            position: "relative",
            display: "block",
            padding: "7rem 0",
            margin: "0",
            width: "100%",
            boxSizing: "border-box",
          }}>
          <DownloadRow>
            <p
              style={{
                textAlign: "center",
                fontSize: "2rem",
                lineHeight: "2rem",
                margin: 0,
                color: colors.COMPANY_COLOR,
              }}>
              Download on your mobile
            </p>
          </DownloadRow>

          <DownloadLinksRow>
            <LinksGrid>
              <a href="/" style={{ textAlign: "left" }}>
                <img
                  src={appleLogo}
                  style={{ maxWidth: "100%" }}
                  alt="app store"
                />
              </a>
              <a href="/" style={{ textAlign: "left" }}>
                <img
                  src={androisLogo}
                  style={{ maxWidth: "100%" }}
                  alt="play store"
                />
              </a>
            </LinksGrid>
          </DownloadLinksRow>

          <DownloadTextRow>
            <p style={{ width: "100%", color: "#787878", fontSize: "14px" }}>
              Contrary to popular belief, Lorem Ipsum is not simply random text.
              It has roots in a piece of classical Latin literature from 45 BC,
              making it over 2000 years old. Richard McClintock, a Latin
            </p>
          </DownloadTextRow>

          <DownloadLinksRow>
            <LinksGrid style={{ gridGap: "1rem" }}>
              <ButtonWhiteLink>Download for Mac</ButtonWhiteLink>
              <ButtonWhiteLink>Download for Windows</ButtonWhiteLink>
            </LinksGrid>
          </DownloadLinksRow>

          <MobileImg src={mobileLogo} />
        </Row>
      </React.Fragment>
    );
  }
}
