import {
  CREATE_SINGLE_COMMENT,
  CLEAR_SINGLE_COMMENT,
  UPDATE_SINGLE_COMMENT_LIKE,
  INCREMENT_REPLY_COUNT,
} from "./singleCommentV2.types";

export const createSingleComment = (payload) => ({
  type: CREATE_SINGLE_COMMENT,
  payload,
});
export const clearSingleComment = (payload) => ({
  type: CLEAR_SINGLE_COMMENT,
  payload,
});
export const updateSingleCommentLike = (payload) => ({
  type: UPDATE_SINGLE_COMMENT_LIKE,
  payload,
});
export const incrementReplyCount = (payload) => ({
  type: INCREMENT_REPLY_COUNT,
  payload,
});
