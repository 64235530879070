import { Modal, ModalBody, Container, Col, Row } from "reactstrap";
import Styled from "styled-components";
import { Clear, Search } from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { colors } from "../../color";
import { SearchDiv } from "../dashboard/gallery/gallery";
import { Postbtn } from "../dashboard/userDashboard/userDashboard";
import { Sidelink, Ul } from "../dashboard/sideBar";
import { MdEmail } from "react-icons/md";
import { GiCancel } from "react-icons/gi";
import propTypes from "prop-types";

export const activeClass = "active";
export const Messagelink = Styled((props) => (
  <NavLink {...props} activeClassName={activeClass} />
))`
  color: ${colors.BLACK};
  font-wight: 500;
  font-size: 15px;
  display: flex;
  height: 65px;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  transition: ease 0.3s;
  &:hover {
    background: ${colors.WHITE};
    color: ${colors.BLACK};
  }
  &.${activeClass} {
     border-left: 10px solid ${colors.ORANGE};
    background: ${colors.WHITE};
  }
`;
const MessageDiv = Styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  padding-left: 10px;
  padding-right: 10px;
  bottom: 0px;
  height: 80px;
  width: 90%;
  border-radius: 10px;
  border: 1px solid ${colors.LIGHT_GREY};
`;
const ModalHeader = Styled.p`
    color: ${colors.COMPANY_COLOR};
    font-weight: bold;
    font-size: 20px;
`;
const Input = Styled.input`
    background: none;
    border: none;
    outline: none;
    width: 80%;
    color: white;
`;
const Btn = Styled.button`
  color: ${(props) => props.color};
  background: ${(props) => props.bg};
  width: 46%;
  border: none;
  height: 40px;
  outline: none;
  font-size: 13px;
  font-weight: 600;
`;

const CustomModal = ({ visible, toggle, src, text, color }) => {
  return (
    <>
      <Modal centered isOpen={visible} toggle={toggle}>
        <Container>
          <ModalBody>
            <Col lg={9} className="m-auto">
              <div className="d-flex justify-content-center">
                <img width="150" src={src} alt="" />
              </div>
              <div>
                <p
                  className="text-center h4 mb-5 pt-3 "
                  style={{ color: `${color}`, fontSize: "16px" }}
                >
                  {text}
                </p>
              </div>
            </Col>
          </ModalBody>
        </Container>
      </Modal>
    </>
  );
};

const SendAsMessageModal = ({ isOpen, toggle_modal }) => {
  return (
    <Modal isOpen={isOpen} toggle={toggle_modal}>
      <ModalBody>
        <div className="d-flex justify-content-between">
          <ModalHeader>Send as Message</ModalHeader>
          <SearchDiv className="d-flex align-items-center search-div rounded-pill">
            <Search
              className="pl-2"
              style={{ fontSize: "33px", color: `${colors.ORANGE}` }}
            />
            <Input className="pl-2 pr-3" type="text" placeholder="Search" />
          </SearchDiv>
          <Clear
            onClick={toggle_modal}
            style={{
              color: `${colors.COMPANY_COLOR}`,
              fontSize: "24px",
              cursor: "pointer",
            }}
          />
        </div>
        <Col xl={12} className="m-auto">
          <div>
            <p className="text-start pt-3">List based on delivery location</p>
          </div>
        </Col>
        <div className="d-flex justify-content-end">
          <Postbtn
            style={{
              height: "35px",
              fontSize: "13px",
              color: "white",
            }}
          >
            Send
          </Postbtn>
        </div>
      </ModalBody>
    </Modal>
  );
};

const MessageModal = ({ isOpen, toggle_modal, routes, messages }) => {
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={toggle_modal}
      style={{ maxHeight: "10px" }}
    >
      <Col>
        <Row>
          <Col style={{ background: `${colors.COMPANY_COLOR}` }} xl={3}>
            <div className="d-flex justify-content-start pt-4">
              <GiCancel
                onClick={toggle_modal}
                size="22px"
                style={{
                  color: `${colors.WHITE}`,
                  cursor: "pointer",
                }}
              />
            </div>
            <Container className="pt-5">
              <div className="d-flex justify-content-between align-items-center">
                <p
                  className="pt-2"
                  style={{
                    fontSize: "22px",
                    fontWeight: "600",
                    color: `${colors.ORANGE}`,
                  }}
                >
                  Messages
                </p>
                <div
                  className="rounded-circle d-flex align-items-center justify-content-center"
                  style={{
                    border: `1px solid ${colors.WHITE}`,
                    height: "30px",
                    width: "30px",
                  }}
                >
                  <MdEmail style={{ color: `${colors.WHITE}` }} />
                </div>
              </div>
            </Container>
            <Ul className="pt-1" style={{ flexDirection: "column" }}>
              {routes.map((route, i) => (
                <li key={i}>
                  <Sidelink
                    exact={true}
                    to={route.to}
                    style={{ textDecoration: "none" }}
                  >
                    {route.icon}
                    {route.name}
                  </Sidelink>
                </li>
              ))}
            </Ul>
          </Col>
          <Col className="pt-4 pb-4" xl={9}>
            <Container>
              <Row>
                <Col xl={5}>
                  <div
                    style={{ height: "60px", background: "#A4A4A4" }}
                    className="d-flex justify-content-between align-items-center rounded mb-2"
                  >
                    <Btn
                      className="ml-2 rounded"
                      bg={colors.COMPANY_COLOR}
                      color={colors.WHITE}
                    >
                      Social Messages
                    </Btn>
                    <Btn className="mr-2 rounded" color={colors.COMPANY_COLOR}>
                      Business Messages
                    </Btn>
                  </div>
                  <div
                    className="rounded mt-3 pt-3 pb-5"
                    style={{ background: "#A4A4A4" }}
                  >
                    {messages.map((message, i) => (
                      <Messagelink
                        style={{ textDecoration: "none" }}
                        to={message.to}
                        key={i}
                      >
                        {message.sender}
                      </Messagelink>
                    ))}
                  </div>
                </Col>
                <Col xl={7}>
                  <MessageDiv>
                    <p>hellow</p>
                  </MessageDiv>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};

SendAsMessageModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggle_modal: propTypes.func.isRequired,
};

CustomModal.propTypes = {
  visible: propTypes.bool.isRequired,
  toggle: propTypes.func,
  src: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  color: propTypes.string.isRequired,
};

MessageModal.propTypes = {
  isOpen: propTypes.bool.isRequired,
  toggle_modal: propTypes.func.isRequired,
};
export { CustomModal, SendAsMessageModal, MessageModal };
