import { useContext, useEffect, useRef, useState } from "react";
import ModalContext from "../../../hooks/modalContexts";
import shoePic from "../../../assets/img/shoe.png";
import CircleIcon from "../../../assets/svg/circleIcon";
import Forward from "../../../assets/svg/forward";
import People from "../../../assets/svg/people";
import Review from "./review";
import Bookmark from "../../../assets/svg/bookmark";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CloseBtn from "../../../components/closeBtn";
import moment from "moment";
import useActivity from "../../../hooks/activity";
import usePrivateSellerDashboard from "../../../hooks/privateSellerDashboard";

const OrderItemModal = () => {
  const { handleOrderItemModal, orderItemModal } = useContext(ModalContext);
  const [adjustCarousel, setAdjustCarousel] = useState(0);
  const [index, setIndex] = useState(1);
  const [refLoad, setRefLoad] = useState();
  useEffect(() => {
    setRefLoad(ref.current.props.children);
  }, []);
  console.log(refLoad?.length);

  const [orderLeft, setOrderLeft] = useState(true);
  const imageCount = [
    { shoePic: shoePic },
    { shoePic: shoePic },
    { shoePic: shoePic },
    { shoePic: shoePic },
  ];
  const ref = useRef();
  console.log(ref?.current?.props.children);
  console.log(index);
  //   const { handleUpdateOrderStatus } = usePrivateSellerDashboard();
  const { handleCancelOrder, cancelOrderData } = useActivity();
  return (
    <div
      onClick={() => handleOrderItemModal("RESET_TO_DISPLAY_ORDER")}
      className="orderitemModalContainer"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="orderItemModalContain"
      >
        <CloseBtn
          onClick={() => {
            handleOrderItemModal("RESET_TO_DISPLAY_ORDER");
          }}
          className="closeIcon"
        ></CloseBtn>
        <div className="orderItemModal">
          <Slider
            ref={ref}
            className="imageCarousel"
            children={orderItemModal?.product?.gallary?.map((image) => {
              return <img src={image?.file} />;
            })}
            infinite={false}
            slidesPerRow={1}
            slidesToShow={2.5}
          >
            {/* <img src={shoePic} alt="" />
                        <img src={shoePic} alt="" />
                        <img src={shoePic} alt="" /> */}
          </Slider>

          <div className="controls">
            <span
              onClick={() => {
                ref.current.slickPrev();
                setIndex(index > 1 ? index - 1 : index);
              }}
              className="prev"
            >
              {"<"}
            </span>
            {refLoad?.map((circle, idx) => {
              return (
                <span>
                  <CircleIcon
                    width={"10px"}
                    height={"10px"}
                    fill={idx === index - 1 && "#787878"}
                  />
                </span>
              );
            })}
            <span
              onClick={() => {
                ref.current.slickNext();
                setIndex(index < refLoad.length ? index + 1 : index);
              }}
              className="next"
            >
              {">"}
            </span>
          </div>
          <div className="description">
            <h6>{orderItemModal?.product?.title}</h6>
            <p>{orderItemModal?.product?.description}</p>
            <div className="actionsArea">
              <div>
                <Bookmark />
                <Forward />
                <People />
              </div>
            </div>
          </div>

          <div className="address">
            <div>
              <h4>Delivery mode:</h4>
              <p>{orderItemModal?.delivery_mode}</p>
            </div>
            <div>
              <h4>Delivery address:</h4>
              <p>
                {
                  orderItemModal?.product?.delivery_locations?.[0]?.city
                    ?.address
                }
              </p>
            </div>
          </div>
          <div className="priceDateDiv">
            <div className="price">
              <h4>Price</h4>
              <p>${orderItemModal?.price}</p>
            </div>
            <div className="centerDiv">
              <div>
                <h4>Service Address</h4>
                <p>{orderItemModal?.product?.page?.location?.address}</p>
              </div>
              <div>
                <h4>Date:</h4>
                <p>
                  {moment(orderItemModal?.order_date).format("DD MMMM YYYY")}
                </p>
              </div>
              <div>
                <h4>Time:</h4>
                <p>{moment(orderItemModal?.order_date).format("hh : ss a")}</p>
              </div>
            </div>
          </div>
          <div className="orderValues">
            <div>
              <p>
                <span>Product Price</span>
                <span>${orderItemModal?.stock?.price}</span>
              </p>
              <p>
                <span>Discount</span>
                <span>
                  {orderItemModal?.product?.discount?.constant?.percentage} %
                </span>
              </p>
            </div>
            <div>
              <p>
                <span>Quantity</span>
                <span>{orderItemModal?.quantity}</span>
              </p>
              <p>
                <span>Deals</span>
                <span>Nil</span>
              </p>
            </div>
            <div>
              <p>
                <span>Delivery</span>
                <span>200</span>
              </p>
              <p>
                <span>Total</span>
                <span>${orderItemModal?.price}</span>
              </p>
            </div>
          </div>
          {orderItemModal?.product?.pre_order && (
            <div className="ordersLeft">
              <div className="orderLeft">
                <div className="warningSign">
                  <span>i</span>
                </div>
                <span className="span">
                  {orderItemModal?.product?.order_left} order(s) left
                </span>
              </div>
              <div className="orderRight">
                Seller requires {orderItemModal?.product?.order_left} by{" "}
                {moment(orderItemModal?.product?.last_order_date).format(
                  "DD MMMM YYYY"
                )}{" "}
                before order is processed.
              </div>
            </div>
          )}
          {orderItemModal?.condition === "Processing" && (
            <div className="orderStatus">
              <h6>Order Status</h6>
              <div className="orderStatus">
                <div className="top">
                  <h5>Order date</h5>
                  <p>Delivery</p>
                </div>
                <div className="progressDiv">
                  <div className="progressBar">
                    <div className="yellowBar"></div>
                    <div className="greenDiv">
                      <div className="circle">
                        <CircleIcon fill="#00C13A" width="16px" height="16px" />
                      </div>
                      <p>
                        {moment(
                          orderItemModal?.product?.free_cancelation_date
                        ).format("DD MMMM YYYY")}
                      </p>
                    </div>
                    <div className="white">
                      <div className="yellowCircle">
                        <CircleIcon fill="#FFFFFF" width="16px" height="16px" />
                      </div>
                      <p>Today </p>
                    </div>
                  </div>
                  <div className="end"></div>
                  <div className="dateDiv">
                    <p>
                      {moment(orderItemModal?.order_date).format(
                        "DD MMMM YYYY"
                      )}
                    </p>
                    <p>May 12, 2021</p>
                  </div>
                </div>
                <p className="warning">
                  Cancel order anytime before{" "}
                  {moment(
                    orderItemModal?.product?.free_cancelation_date
                  ).format("DD MMMM YYYY")}
                  . will Incur loss of deposit
                </p>
              </div>
              <p
                className="cancele"
                onClick={() => handleCancelOrder(orderItemModal?.id)}
              >
                Cancel Order
              </p>
            </div>
          )}
          {orderItemModal?.condition === "Delivered" && (
            <Review tab={"orders"} id={orderItemModal?.id} />
          )}
        </div>
      </div>
    </div>
  );
};
export default OrderItemModal;
