import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/loader";
import useSettings from "../../../../../hooks/settings";
import { businessNotificationSettings } from "../../../utils/notificationSettingsList";
import BusinessNotificationItem from "./businessNotificationItem";

const NotificationSettings = ({}) => {
  const currentUser = useSelector((state) => state.currentUser.user);
  const [businessNotificationData, setBusinessNotificationData] = useState([
    {
      value:
        currentUser?.settings?.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when there is open queue for Business I support"],
      title: "Notify me when there is open queue for Business I support",
    },
    {
      value:
        currentUser?.settings.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when my booking appointment has been accepted"],
      title: "Notify me when my booking appointment has been accepted",
    },
    {
      value:
        currentUser?.settings.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when my booking appointment has been declined"],
      title: "Notify me when my booking appointment has been declined",
    },
    {
      value:
        currentUser?.settings.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when there is open queue for Business I support"],
      title: "Notify me when there is open queue for Business I support",
    },
    {
      value:
        currentUser?.settings.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when of booking appointments"],
      title: "Notify me when of booking appointments",
    },
    {
      value:
        currentUser?.settings.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when of future available booking appointment slots"],
      title: "Notify me when of future available booking appointment slots",
    },
    {
      value:
        currentUser?.settings.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when a seller cancels a listing"],
      title: "Notify me when a seller cancels a listing",
    },
    {
      value:
        currentUser?.settings.activities["My Activity"][
          "Notification Settings"
        ]["Notify me when a purchase is completed"],
      title: "Notify me when a purchase is completed",
    },
  ]);
  const [dataToSend, setDataToSend] = useState();
  useEffect(() => {
    const data = {};
    businessNotificationData.map((businessActivityNotificationItem) => {
      data[businessActivityNotificationItem.title] =
        businessActivityNotificationItem.value;
    });
    setDataToSend(data);
  }, [businessNotificationData]);

  const { handlePatchBusinessActivityData, businessMyActivityData } =
    useSettings();
  return (
    <div className="businessSetting_notification">
      <h2 className="notification_pageTitle">Notification Settings</h2>
      <ul>
        {businessNotificationData.map((businessNotificationDataItem, idx) => {
          return (
            <BusinessNotificationItem
              businessNotificationDataItem={businessNotificationDataItem}
              businessNotificationData={businessNotificationData}
              setBusinessNotificationData={setBusinessNotificationData}
              idx={idx}
            />
          );
        })}
      </ul>
      <div className="save">
        <div
          onClick={() => {
            handlePatchBusinessActivityData(dataToSend);
          }}
          className="saveButton"
        >
          {!businessMyActivityData?.loading ? <span>Save</span> : <Loader />}
        </div>
      </div>
    </div>
  );
};
export default NotificationSettings;
