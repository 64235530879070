import React, {  useState, useContext  } from "react";
import { Link, useHistory } from 'react-router-dom'
import ModalContext from '../../../../hooks/walletContext'
import "./index.scss"
import Cards from 'react-credit-cards'
import { css } from 'styled-components';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import PayPal from "../../../../assets/svg/paypal-color-large(1).svg"
import { RiBankLine} from "react-icons/all"
import PayPalEdit from "./PaypalEdit"

const EditPage = ({handleBackToWithDrawal, handleTabEditBank, activeEditTab, setActiveEditTab}) => {

  const history = useHistory();
  const [activeTab, setActiveTab] = useState("bank");
  // const { activeEditTab, changeEditTab, setActiveEditTab } = useContext(ModalContext);

    const {
      meta, getCardNumberProps, getExpiryDateProps, getCVCProps
    } = usePaymentInputs();

  return(
    <div className="edit-wallet-cont">
      <p className="edit-text">Edit withdrawal method</p>
      <div className="img-icons">
        <img
            className={activeEditTab === "paypal" ? "active" : "img"}
            src={PayPal}
            onClick={() => {
            setActiveEditTab("paypal");
            }}
          />
          <div
            className={activeEditTab === "bank" ? "paypal-active" : "paypal-cont"}
            onClick={() => {
            setActiveEditTab("bank");
            }}
            >
            <div className="bank">
              <RiBankLine />
              <p className='act'>Bank Account</p>
            </div>
          </div>
        </div>

        {activeEditTab === "bank" &&
        <div className="wallet-input">
            <div className="number-name">
                <div className="name">
                  <h5>Bank Name</h5>
                  <form className="input">
                    <input type="text" placeholder="Chase Bank" />
                  </form>
                </div>
                <div className="name">
                  <h5>Bank County</h5>
                  <form className="input input-1st-right">
                    <input type="text" placeholder="3688 Barton Street. Hamilton · Ontario(ON)" />
                  </form>
                </div>
              </div>

              <div className="number-name">
                  <div className="name">
                    <h5>Bank City</h5>
                    <form className="input input-2nd-right">
                      <input type="text" placeholder="America" />
                    </form>
                  </div>
                  <div className="name">
                    <h5>Province</h5>
                    <form className="input input input-2nd-right">
                      <input type="text" placeholder="Bank Name" />
                    </form>
                  </div>
                  <div className="name">
                    <h5>Institution</h5>
                    <form className="input input-2nd-right">
                      <input type="text" placeholder="Bank Name" />
                    </form>
                  </div>
                  <div className="name">
                    <h5>Swift/BIC</h5>
                    <form className="input input-4th-right">
                      <input type="text" placeholder="Bank Name" />
                    </form>
                  </div>
                </div>

                <div className="number-name">
                    <div className="name">
                      <h5>Bank Transit Code</h5>
                      <form className="input">
                        <input type="text" placeholder="Samson" />
                      </form>
                    </div>
                    <div className="name">
                      <h5>Bank Account Number </h5>
                      <form className="input input-1st-right">
                        <input type="text" placeholder="Matthew" />
                      </form>
                    </div>
                  </div>

                  <div className="number-name">
                      <div className="name">
                        <h5>Account Holders Full Name</h5>
                        <form className="input input-3rd-left">
                          <input type="text" placeholder="3688 Barton Street. Hamilton · Ontario(ON)" />
                        </form>
                      </div>
                  </div>

                  <div className="number-name">
                      <div className="name">
                        <h5>Bank branch Address</h5>
                        <form className="input input-3rd-left">
                          <input type="text" placeholder="3688 Barton Street. Hamilton · Ontario(ON)" />
                        </form>
                      </div>
                  </div>

            </div>
          }
          {activeEditTab === "paypal" && <PayPalEdit /> }
            <button
              onClick={handleBackToWithDrawal}
              className="done-button">Done
            </button>
      </div>
  )
}

export default EditPage
