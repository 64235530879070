import { useContext, useState, useEffect } from "react";
import "./queueListingsModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import Photo from "../../../assets/img/Component 672 –1.PNG";
import { FaPen, FaSearch } from "react-icons/fa";
import CustomCheckBox from "../../../components/customCheckBox";
import { Search as Icon } from "../../../assets/svg/NavBarIcons";
import CloseBtn from "../../../components/closeBtn";
import Checkbox from "./checkBox";
import { catalogues } from "./checkBox";
import useExplore from "../../../hooks/explore";
import ExploreContext from "../../../hooks/exploreContext";
import moment from "moment";

const QueueListingsModal = () => {
  const { handleGetProducts } = useExplore();
  const { allProducts, setNewQueueListings } = useContext(ExploreContext);

  useEffect(() => {
    handleGetProducts({});
  }, []);

  useEffect(() => {
    console.log(allProducts[0]?.product?.data);
  }, [allProducts])

  const { handleQueueListingsModal } = useContext(ModalContext);
  const testArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [search, setSearch] = useState("tab1");

  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [list, setList] = useState([]);

  const handleTab1 = () => {
    setSearch("tab1");
  };

  const handleTab2 = () => {
    setSearch("tab2");
  };
  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  //select All checkboxes

  const [lists, setLists] = useState([]);

  const [productList, setProductList] = useState([]);

  const handleChange = (product) => {
    // const { name, checked } = e.target;
    let tempList = productList;
    if (product === "allSelect") {
      setProductList(allProducts[0]?.product?.data?.results)
    } 
    else if (!tempList.some(e => e.id === product.id)) {
      tempList.push(product);
      setProductList(tempList);
    } 
    else {
      for (let i=0; i<tempList.length; i++) {
        if (tempList[i].id === product.id) {
          tempList.splice(i, 1);
          setProductList(tempList);
        }
      }
    }
  };

  const saveAndClose = () => {
    // save selected to context
    setNewQueueListings(productList);

    // then close modal
    handleQueueListingsModal("RESET_DATA");
  }

  useEffect(() => {
    setLists(catalogues);
  }, []);

  //....................END

  return (
    <div
      onClick={() => {
        handleQueueListingsModal("RESET_DATA");
      }}
      className="qlModal"
    >
      <div className="qlModalContainer">
        <div
          onClick={() => {
            handleQueueListingsModal("RESET_DATA");
          }}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="qlModalContainered"
        >
          <div className="qlModal-header">
            <h4> Link Listing</h4>
            <button className="btn-post" onClick={() => saveAndClose()}>Save</button>
          </div>
          <div className="qlModal-body">
            <div className="bkr-top-card">
              {search === "tab1" && (
                <>
                  <div
                    className={`tc-box-one ${search === "tab1" && ""}`}
                    onClick={handleTab1}
                  >
                    <div className="cb-1">
                      <label className="cb1-label">
                        <input
                          type="checkbox"
                          className=""
                          name="allSelect"
                          checked={
                            lists.filter((list) => list?.isChecked !== true)
                              .length < 1
                          }
                          onChange={() => handleChange('allSelect')}
                        />
                        <span className="checkmark"></span>
                        Select listings
                      </label>
                    </div>
                  </div>
                  <div
                    className={`search_icon ${
                      search === "tab2" && "search_active"
                    }`}
                    onClick={handleTab2}
                  >
                    <Icon width={20} fill="#b8b8b8ff" />
                  </div>
                </>
              )}

              {search === "tab2" && (
                <>
                  <div
                    className={`listing-close ${
                      search === "tab1" && "tc-box-one"
                    }`}
                    onClick={handleTab1}
                  >
                    <div className="cb-1">
                      <label className="cb1-label">
                        <input
                          type="checkbox"
                          className=""
                          name="allSelect"
                          checked={
                            lists.filter((list) => list?.isChecked !== true)
                              .length < 1
                          }
                          onChange={() => handleChange}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div
                    className={`search_icon ${
                      search === "tab2" && "search_active"
                    }`}
                    onClick={handleTab2}
                  >
                    <Icon width={20} fill="#b8b8b8ff" />
                    <input type="search" name="" id="" />
                  </div>
                </>
              )}
            </div>
            <div className="item__scroll">
              {
                allProducts[0]?.product?.data?.count>0 &&
                allProducts[0]?.product?.data?.results?.map((product, id) => (
                  <div className="bkr-cbc-card" key={product.id}>
                    <div className="bkr-cbcc-body">
                      <div className="item__detail-box">
                        <div className="cb-1">
                          
                          <label className="cb1-label">
                            <input
                              type="checkbox"
                              className=""
                              name={id}
                              // checked={product?.isChecked || false}
                              onChange={() => handleChange(product)}
                            />
                            <span className="checkmark"></span>
                            {id+1}
                          </label>
                        </div>
                        <div className="orc-detail-box">
                          <img src={product?.gallary[0]?.file} alt="" />
                          <div className="orc-detail-box-user">
                            <h4>{product.title}</h4>
                            <h6> Posted on {moment(product.created_at).format('ll')}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="orc-detail-box-price">
                        <h4> Price</h4>
                        <h6> ${product.deposit_amount}</h6>
                      </div>
                    </div>
                  </div>
                ))
              }
              
            </div>
            <div className="qlModal-footer"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QueueListingsModal;
