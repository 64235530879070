import { useState, useEffect, useRef, useContext } from "react";
import "./queueBody.scss";
import { Link } from "react-router-dom";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../assets/img/Rectangle 1890.PNG";
import NotificationContainer from "../../../components/NotificationContainer/notification";
import FeedContainer from "../../../components/FeedContainer";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../../utils/greeting";
import QueueDropdown from "./dropdown";
import useExplore from "../../../hooks/explore";
import ExploreContext from "../../../hooks/exploreContext";
import currentUser from "../../../appstore/userData/index.reducer";
import moment from "moment";
import LoaderPurpleBg from "../../../assets/loaders/LoaderPurpleBg";

const dropdownData = [
  { label: "Move to top" },
  { label: "Move down" },
  { label: "Set waiting time" },
];

const QueueBody = () => {
  const { handleGetRequestedQueue, handleGetQueueCheckout, handleGetJobNotifications } = useExplore();
  const { requestedQueue, jobNotifications, queueCheckout } = useContext(ExploreContext);
  const thisUser = useSelector(state => state.currentUser.user);

  const testArray = [1, 2, 3, 4];
  const [cardQueue, setCardQueue] = useState();
  const [pageLoading, setPageLoading] = useState(false);

  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  useEffect(() => {
    setPageLoading(true);
    handleGetRequestedQueue(thisUser?.id);
    handleGetQueueCheckout(thisUser?.id);
    handleGetJobNotifications();
  }, []);
 
  useEffect(() => {
    let queue = requestedQueue?.data?.results;
    setCardQueue(queue?.slice(0, 4));
    if (queue) setPageLoading(false);
  }, [requestedQueue]);
  
  return (
    <FeedContainer>
      <div className="queue-container">
        <div className="qk-header">
          <h1 className="welcome-title">
            {greeting()} <span>Jonathan </span>
          </h1>
          {/* <div className="bk-btn-box">
            <Link to="/queue" className="bk-btn">
              Edit Queue
            </Link>
          </div> */}
        </div>
        <div className="qk-card">
          <div className="qkc-header">
            <h5 className="sub-title"> Queue </h5>
            <Link className="sub-title-btn" to="/queue_history">
              View History
            </Link>
          </div>
          <div className="queue-body-container">
            <div className="qdbc-card-body">
              <div className="dbc-container-one">
                {/* -------- NOTIFICATION CARD --------*/}
                <NotificationContainer />
              </div>
              <div className="dbc-container-two">
                <div className="mb-header">
                  <h2>Manage Queue </h2>
                  <div className="bk-btn-box">
                    <Link to="/edit_queue" className="bk-btn">
                      Queue Settings
                    </Link>
                  </div>
                </div>
                <p>Requested</p>

                {
                  pageLoading ?
                    <LoaderPurpleBg style={{ width: 70, height: 70 }} />
                    :
                    <>
                      {/*--------- CARD CONTENTS ------------------*/}
                      <div className="lbc-cb-contents">
                        {
                          cardQueue?.length>0 &&
                          cardQueue?.map((item, index) => (
                            <div className="lbc-cbc-card" key={index}>
                              <div className="lbc-cbcc-body">
                                <div className="orc-detail-box">
                                  <img src={item?.owner?.profile_image?.file} alt="" />
                                  <div className="orc-detail-box-user">
                                    <h4>{item?.owner?.full_name}</h4>
                                    <h6>@{item?.owner?.username}</h6>
                                  </div>
                                  {
                                    item?.queue_status === "AUTO_ACCEPT" &&
                                    <small>Auto Accept</small>
                                  }
                                </div>
                                <div className="cbcc-item__box">
                                  <div className="cbcc-img-holder">
                                    <img src={item?.gallary?.length > 0 ? item?.gallary[0]?.file : itemImg } alt="" />
                                  </div>
                                  <div className="cbcc-item-detail-one">
                                    <h4>{item?.service?.title}</h4>
                                    <h5>Queue Date:</h5>
                                    <span>{moment(item?.service_date).format("lll")}</span>
                                    {/* <h5>
                                Date: <span>Aug 20, 2021</span>
                              </h5> */}
                                  </div>
                                </div>
                                <div className="lbc-cbcc-footer">
                                  <p>Expires in {moment(item?.service_date).fromNow()}</p>
                                  {
                                    item?.queue_status !== "AUTO_ACCEPT" &&
                                    <div className="btn-box">
                                      <button className="btn-accept">Accept</button>
                                      <button className="btn-decline">Decline</button>
                                    </div>
                                  }
                                </div>
                              </div>
                            </div>
                          ))
                        }
                        <div className="lbc-contents-footer">
                          <button className="footer-view-btn"> view all</button>
                        </div>
                      </div>

                      {/*------------ MANAGING QUEUE CONTAINER ------------------*/}
                      <div className="manage-queue-container ">
                        <h2> Checkout </h2>
                        <div className="mb-card">
                          <h3>Today's Delivery</h3>
                          <div className="mb-card-body">
                            {testArray.map((data, idx) => (
                              <div className="mbc-item-box">
                                <div className="orc-detail-box">
                                  {/* <img src={profileImg} alt="" />
                              <div className="orc-detail-box-user">
                                <h4>Maria Sponge</h4>
                                <h6>@username</h6>
                                <p>Aug 20, 2021 10am-12am </p>
                              </div> */}
                                  <div className="cbcc-item__box">
                                    <div className="cbcc-img-holder">
                                      <img src={itemImg} alt="" />
                                    </div>
                                    <div className="cbcc-item-detail-one">
                                      <h4>Veegan foods food and food.</h4>
                                      <h5>Queue Date:</h5>
                                      <span>August 20, 2021 10am - 12am</span>
                                    </div>
                                  </div>
                                  <div className="btn-box">
                                    <div className="sub-btn-box">
                                      <div className="active">
                                        <span></span> Active
                                      </div>
                                      <QueueDropdown />
                                    </div>
                                    <button className="btn-checkout">Checkout</button>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                        <div className="lbc-contents-footer">
                          <button className="footer-view-btn"> view all</button>
                        </div>
                      </div>
                    </>
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};

export default QueueBody;
