import React, { useState, useContext, useEffect } from "react";
import "../index.scss";
import { FaChevronDown } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/all";
import useBooking from "../useBooking";
import ModalContext from "../../../hooks/modalContexts";

const ListServices = ({
  id,
  setBookingForm,
  toggleService,
  handleGetSingleService,
  singleServiceData,
  setUpdateBookingService,
  updateBookingService,
  bookingForm,
  setRadio,
}) => {
  const {
    handleGetPageServices,
    pageServicesData,
    allServices,
    handleDeleteService,
    handleGetServicePersonnel,
    servicePersonnelData,
  } = useBooking();
  const {
    personnelFormList,
    serviceToggleSubmit,
    setServiceToggleSubmit,
    bookingPSform,
    setBookingPSform,
  } = useContext(ModalContext);
  const [userPersonnel, setUserPersonnel] = personnelFormList;

  const newUserPersonnel = userPersonnel?.map((personnel, idx) => {
    if (personnel?.unavailable_dates?.length === 0) {
      delete personnel.unavailable_dates;
      return personnel;
    }
  });

  console.log("qqqqqqqqqq", newUserPersonnel);

  // const apiResult = JSON.stringify(servicesData.data.count);
  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleGetPageServices(id);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (updateBookingService) {
      if (bookingForm?.location_to_use?.length > 1) {
        setRadio("1");
      }
    }
  }, [updateBookingService]);

  useEffect(() => {
    if (singleServiceData?.response?.status === 200) {
      setUpdateBookingService({ ...singleServiceData?.data });
    }
  }, [singleServiceData?.response?.status, singleServiceData?.data]);

  useEffect(() => {
    if (servicePersonnelData?.response?.status === 200) {
      if (servicePersonnelData?.data) {
        setUserPersonnel(servicePersonnelData?.data.results);
      }
    }

    return () => {};
  }, [servicePersonnelData?.response?.status, servicePersonnelData?.data]);

  
  useEffect(() => {
    if (!serviceToggleSubmit) {
      if (bookingForm.id) {
        setBookingPSform(bookingForm.id);
      }
    }
  }, [bookingForm]);

  console.log("bookingPSform", bookingPSform);

  useEffect(() => {
    if (updateBookingService) {
      setBookingForm({
        id: updateBookingService?.id,
        title: updateBookingService?.title,
        category: "booking",
        booking_fee: {
          amount: updateBookingService?.booking_fee?.amount,
          type: updateBookingService?.booking_fee?.type,
        },
        accept_order_automatically:
          updateBookingService?.accept_order_automatically,
        payment: updateBookingService?.payment,
        description: updateBookingService?.description,
        allow_discount: updateBookingService?.allow_discount,
        discount: {
          name: "discount",
          systemType: "BOOKING",
          dealAndDiscountType: "BOOK_BEFORE_N_DATE_AND_PAY_X_FIXED",
          constant: {
            fix_price: 1,
            percentage: 1,
          },
          exp_date: updateBookingService?.discount?.exp_date,
        },
        allow_referral: updateBookingService?.allow_referral,
        referrals: updateBookingService?.referrals,
        // price_value_anybody: updateBookingService?.price_value_anybody,
        allow_deposit: updateBookingService?.allow_deposit,
        location_to_use: updateBookingService?.location_to_use,
        service_locations: updateBookingService?.service_locations,
        // personnel: updateBookingService?.personnel,
        // gallary: [updateBookingService?.gallary[0]?.id],
        file: singleServiceData?.data?.gallary[0]?.file,
      });
    }

    return () => {};
  }, [updateBookingService]);

  const editService = (service) => {
    handleGetSingleService(service);
    handleGetServicePersonnel(service);
    toggleService();
    setServiceToggleSubmit(false);
    // if (singleServiceData.data) {
    //   setBookingForm({ ...singleServiceData?.data });
    // }
    // setTimeout(() => {
    //   singleServiceData?.data && setBookingForm({ ...singleServiceData?.data });

    // }, 1000);

    console.log(singleServiceData?.data);
  };
  // const deleteBooking = (id) => {
  //   setBookings(bookings.filter((bookingForm) => bookingForm.id !== id));
  // };

  console.log("servicePersonnelData------", servicePersonnelData);

  return (
    <>
      {pageServicesData.loading && <p>Loading..</p>}

      {!pageServicesData.loading && pageServicesData.error && (
        <h5> An Error Occured </h5>
      )}

      {!pageServicesData.loading && !pageServicesData.error && (
        // <p>{apiResult.results} {console.log(apiResult.results)}</p>
        <></>
      )}

      {!pageServicesData.loading &&
        !pageServicesData.error &&
        pageServicesData?.data?.count > 0 &&
        pageServicesData?.data?.results?.map((service, id) => (
          <div className="cbc-cbc-card" key={service.id}>
            <div className="cbc-cbcc-body">
              <div className="ccb__contents">
                <h4>{service.title} </h4>
                <h5>
                  Booking fee: <span>${service.booking_fee?.amount}</span>
                </h5>
                <div>
                  <button
                    className="view-more-btn"
                    onClick={() => editService(service.id)}
                  >
                    View more <FaChevronDown />
                  </button>
                  <IoMdCloseCircleOutline
                    onClick={() => handleDeleteService(service.id)}
                    className="close-icon"
                  />
                </div>
              </div>
            </div>
          </div>
        ))}

      {!pageServicesData.loading &&
        !pageServicesData.error &&
        !pageServicesData.data && <h2 className="no-booking"> No Booking </h2>}

      <div>
        {/* {bookings.length > 0 ? (
            bookings.map((bookingForm) => (
              <div className="cbc-cbc-card" key={bookingForm.id}>
                <div className="cbc-cbcc-body">
                  <div className="ccb__contents">
                    <h4>{bookingForm.title} </h4>
                    <h5>
                      Booking fee: <span>{bookingForm.fee}</span>
                    </h5>
                    <div>
                      <button
                        className="view-more-btn"
                        onClick={() => {
                          toggleService();
                          // editBookingRow(bookingForm);
                        }}
                      >
                        View more <FaChevronDown />
                      </button>
                      <IoMdCloseCircleOutline
                        onClick={() => deleteBooking(bookingForm.id)}
                        className="close-icon"
                      />
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <></>
          )} */}
      </div>
    </>
  );
};

export default ListServices;
