import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  setAccountType,
  setCurrentUser,
} from "../appstore/userData/index.action";
import decode from "jwt-decode";
const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const currentUser = useSelector((state) => state.currentUser);
  const [{ ...loginData }, login] = useAxios(
    {
      url: "/auth/login/",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const [{ ...registrationData }, register] = useAxios(
    {
      url: "/auth/register/",
      method: "POST",
    },
    {
      manual: true,
    }
  );
  const [{ ...logoutData }, logout] = useAxios(
    {
      url: "/auth/logout/",
      method: "post",
    },
    {
      manual: true,
    }
  );

  const [{ ...initPasswordChangeData }, initPasswordChange] = useAxios(
    {
      url: "/auth/password/reset/",
      method: "post",
    },
    {
      manual: true,
    }
  );
  const [{ ...changePassData }, changePass] = useAxios(
    {
      url: "/auth/password/reset/confirm/",
      method: "post",
    },
    {
      manual: true,
    }
  );
  const [{ ...meData }, fetchMeData] = useAxios(
    {
      url: "/users/me/",
      method: "get",
    },
    {
      manual: true,
    }
  );
  // console.log(data, "here");

  const handlePasswordChangeInit = (email) => {
    initPasswordChange({
      data: { email },
    }).catch((error) => console.log(error));
  };

  const handlePasswordChange = (data) => {
    changePass({
      data,
    }).catch((error) => console.log(error));
  };
  const handleLogin = ({ username, password }) => {
    login({
      data: {
        username,
        password,
      },
    }).catch((err) => console.log(err));
  };

  const logUserIn = (loginData) => {
    window.localStorage.clear();
    // dispatch(setCurrentUser(loginData.data));
    window.localStorage.setItem("token", loginData.data.access_token);
    window.localStorage.setItem("refresh", loginData.data.refresh_token);
    // const userData = decode(loginData.data.access_token);
    // console.log('decoded', userData)

    // dispatch(
    //   setCurrentUser({
    //     ...userData,
    //     accountType: userData.default_landing_page.toLowerCase(),
    //   })
    // );
    fetchMeData();
  };

  //getting user/me

  useEffect(() => {
    if (meData.data) {
      const { data } = meData;
      console.log(data.default_landing_page.toLowerCase());
      dispatch(
        setCurrentUser({
          ...currentUser,
          user: data,
          accountType: data.default_landing_page.toLowerCase(),
          ...(loginData.data && loginData.data),
        })
      );
    }
  }, [meData.data]);

  // console.log("all user", currentUser);
  const handleRegistration = (data) => {
    register({
      data,
    }).catch((error) => console.log(error));
  };

  useEffect(() => {
    if (loginData.data) {
      logUserIn(loginData);
    }
    if (registrationData.data) {
      logUserIn(registrationData, "registration");
    }
  }, [loginData.data, registrationData.data]);

  const handleLogout = () => {
    dispatch({ type: "USER_LOGOUT" });
    window.localStorage.clear();
    window.location.href = "/signin";
  };

  // const [{ ...getUserDetailData }, getUserData] = useAxios(
  //   {
  //     url: "/auth/user/",
  //     method: "get",
  //   },
  //   {
  //     manual: true,
  //   }
  // );

  // //change in getuserData

  // useEffect(() => {
  //   if (getUserDetailData.data) {
  //     // dispatch(setCurrentUser(getUserDetailData.data));
  //   }
  // }, [getUserDetailData.data]);

  return {
    handleLogin,
    loginData,
    handleRegistration,
    registrationData,
    handleLogout,
    handlePasswordChange,
    handlePasswordChangeInit,
    initPasswordChangeData,
    changePassData,
    logout,
    logoutData,
  };
};

export default useAuth;
