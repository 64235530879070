import { useState, useContext } from "react";
import { MdDeleteSweep } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import { BsStarFill } from "react-icons/bs";
import "./jobDown.scss";
import CustomCheckBox from "./CheckBoxes/JobDownCheckBox";
import OpenCustomCheckBox from "./CheckBoxes/OpenDownCheckBox";
import InfoIcon from "../../../../assets/icons/info_icon_up.png";
import ModalContext from "../../../../hooks/modalContexts";
import { toSentenceCaseUnderscore } from "../../../../utils/helpers";
import useExplore from "../../../../hooks/explore";
import ExploreContext from "../../../../hooks/exploreContext";

const JobDown = ({ application }) => {
  const { handleInfoItemModal } = useContext(ModalContext);
  const { applicationSaveLoading } = useContext(ExploreContext)
  const { handleDownloadApplicationDocument, handleDeleteApplication } = useExplore();
  const [isActive, setActive] = useState("false");
  const handleToggle = () => {
    setActive(!isActive);
  };
  const [showInfo, setShowInfo] = useState(false);
  const [attachmentIndex, setAttachmentIndex] = useState(null);

  const handleDownloadDoc = (application, index) => {
    setAttachmentIndex(index);
    handleDownloadApplicationDocument(application?.files[0]?.id)
  }
  console.log(application);
  
  return (
    <div className="jobDown ">
      <div className="jobDown-head not-read">
        <div className="jobDown-head-left">
          <div
            onClick={handleToggle}
            className={
              isActive
                ? "jobDown-head-left-star"
                : "jobDown-head-left-star gold"
            }
          >
            <BsStarFill />
          </div>
          <div className="jobDown-head-left-box">
            <CustomCheckBox
              style={{
                minWidth: 18,
                height: 18,
                borderColor: "var(--text-grey)",
                }}
              />
          </div>
          <div
            className="jobDown-head-left-wrap"
            onClick={() => setShowInfo(!showInfo)}
          >
            <div>
              <img src={application?.owner?.profile_image?.file} alt="" />
            </div>
            <div>
              <h2>{application?.owner?.full_name}</h2>
              <p>@{application?.owner?.username}</p>
            </div>
          </div>
        </div>
        <div className="jobDown-head-right">
          <img src={InfoIcon} className="jobDown-head-right-info up"
            onClick={() =>{
              handleInfoItemModal('ADD_INFO_TO_DISPLAY', application)
            }}
            alt=""
            />
          <div className="download" onClick={() => handleDeleteApplication(application?.id)}>
            <MdDeleteSweep />
          </div>
          <a href="#">
            <FaDownload />
          </a>
        </div>
      </div>
      {showInfo && (
        <div className="jobDown-body">
          {
            application?.attachments.map((attachment, index) => (
              <div className="jobDown-body-wrap" key={index}>
                <div className="jobDown-body-wrap-box">
                  <OpenCustomCheckBox
                    style={{
                      minWidth: 18,
                      height: 18,
                      borderColor: "var(--text-grey)",
                      }}
                    />
                  <label htmlFor="">{toSentenceCaseUnderscore(attachment.type)}</label>
                </div>
                <div>
                  <p>{attachment.name}</p>
                </div>
                {
                  applicationSaveLoading && attachmentIndex === index ?
                    <p className="jobDown-body-wrap-loading">Loading...</p>
                    :
                    <div className="jobDown-body-wrap-view">
                      <p>view</p>
                      <FaDownload onClick={() => handleDownloadDoc(attachment, index)} />
                    </div>
                }
                
              </div>
            ))
          }
        </div>
      )}
    </div>
  );
};

export default JobDown;
