const Logo = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width || "69.8"}
    height={height || "29.88"}
    viewBox="0 0 69.8 29.88">
    <g id="Group_25" data-name="Group 25" transform="translate(-1710.2 -144)">
      <path
        id="Path_159"
        data-name="Path 159"
        d="M45.061,0C31.125,0,27.315,23.829,26.92,29.88H37.047c.7-8.014,3.415-18.589,8.057-18.7,6.83-.171,6.99,14.428,7.321,18.7H62.552a65.873,65.873,0,0,0-2.134-13.649C56.394,2.134,49.511,0,45.061,0Z"
        transform="translate(1717.448 144)"
        fill="#fff"
      />
      <path
        id="Path_160"
        data-name="Path 160"
        d="M34.4,3.422c-2.625-1.345-10.362-4.546-17.565-.864S4.67,15.95,2.29,29.823h10.3c1.761-8.729,4.642-15.1,8.409-17.074,3.105-1.622,6.83-.213,8.334.47a35.664,35.664,0,0,1,5.069-9.8Z"
        transform="translate(1715.795 144.057)"
        fill="#fff"
      />
      <path
        id="Path_161"
        data-name="Path 161"
        d="M9.967,4.984A4.984,4.984,0,1,0,4.984,9.967,4.984,4.984,0,0,0,9.967,4.984Z"
        transform="translate(1710.2 144)"
        fill="#fbb03b"
      />
    </g>
  </svg>
);

export default Logo;
