import { useState, useContext } from "react";
import "./generalAnalytics.scss";
import { BiInfoCircle, BiUpArrowAlt } from "react-icons/bi";
import BigEye from "../../../../assets/svg/bigEye";
import { pageInteractions } from "../../../../components/Analytics/generalData";
import AreaChart from "../../../../components/Analytics/Charts/areaChart";
import AreaChartClicks from "../../../../components/Analytics/Charts/areaChartClicks";
import AreaChartLikes from "../../../../components/Analytics/Charts/areaChartLikes";
import AreaChartShares from "../../../../components/Analytics/Charts/areaChartShares";
import AreaChartRefer from "../../../../components/Analytics/Charts/areaChartRefer";
import AreaChartSupporter from "../../../../components/Analytics/Charts/areaChartSupporter";
import { Line } from "react-chartjs-2";
import WalletMoney from "../../../../assets/svg/wallet-money";
import RateIcon from "../../../../assets/svg/rateIcon";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import { Chart as ChartJS } from "chart.js/auto";
import {
  chartData2,
  chartOptions2,
} from "../../../../components/Analytics/chartDetails";
import BarChart from "../../../../components/Analytics/Charts/barChart";
import ChartStats from "../../../../components/Analytics/components/chartStats";
import ModalContext from "../../../../hooks/modalContexts";
import moment from "moment";


const GeneralAnalytics = ({general, generalChart, startDate, endDate}) => {
  const [activeTab, setActiveTab] = useState("city");
  const [activeTab2, setActiveTab2] = useState("city1");
  const { handleTotalRevenueModal } = useContext(ModalContext);

  const testArray = [1, 2, 3, 4];
  const { setClicksAnalyticModal } = useContext(ModalContext);
  const { setLikesAnalyticModal } = useContext(ModalContext);
  const { setSharesAnalyticModal } = useContext(ModalContext);
  const { setReferAnalyticModal } = useContext(ModalContext);
  const { setSupportAnalyticModal } = useContext(ModalContext);
  const { setPageOrderModal } = useContext(ModalContext);
  const { setReferralOrderModal } = useContext(ModalContext);
  const { setPageViewsAnalyticModal } = useContext(ModalContext);
  const { setCustomerConversionModal } = useContext(ModalContext);


  return (
    <div className="general-analytics-container">
      <div className="gac-dashboard">
        <div className="gac-dashboard-body">
          <div className="gacd-content-one">
            <WalletMoney />
            <h4 onClick={() => handleTotalRevenueModal("ADD_DATA", "true")}>
              Total Revenue
            </h4>
          </div>
          <div className="gacd-content-two">
            <AreaChart general={general}/>
            <div className={general?.clicks?.summary.direction === "UP" ? "icb-chart rate-text-green" : "icb-chart rate-text-red"}>
              <h6>
              {general?.total_revenue?.summary.percentage.toFixed(1)}%{" "}
                <span>
                 {general?.total_revenue?.summary.direction === "UP" ?  <ArrowNarrowUp /> : <ArrowNarrowDown />}
                </span>
              </h6>
              <p>in less than one week</p>
            </div>
          </div>
          <div className="gacd-content-three">
            <h3>${general?.total_revenue?.total_number.toFixed(1)}</h3>
          </div>
        </div>
      </div>
      <div className="gac-page-interaction">
        <h4>Page Interactions </h4>
        <div className="interaction-contents">
          <div
            className="ic-box active"
            onClick={(e) => setClicksAnalyticModal(true)}
          >
            <h5>Clicks</h5>
            <p>
              {moment(startDate).format("MMM Do")} -{" "}
              {moment(endDate).format("MMM Do")}{" "}
            </p>

            <h6>{general?.clicks?.total_number}</h6>
            <p>Total number of clicks</p>
            <div className={general?.clicks?.summary.direction === "UP" ? "icb-chart rate-text-green" : "icb-chart rate-text-red"}>
            {general?.clicks?.summary.percentage.toFixed(1)}%
              <span>
                {general?.clicks?.summary.direction === "UP" ?  <ArrowNarrowUp /> : <ArrowNarrowDown />}
              </span>
            </div>
            <AreaChartClicks general={general} />
          </div>
          {/* {pageInteractions.map((list, idx) => ( */}
          <div className="ic-box" onClick={(e) => setLikesAnalyticModal(true)}>
            <h5>Likes</h5>
            <p>
              {moment(startDate).format("MMM Do")} -{" "}
              {moment(endDate).format("MMM Do")}{" "}
            </p>

            <h6>{general?.likes?.total_number}</h6>
            <p>Total number of likes</p>
            <div className={general?.clicks?.summary.direction === "UP" ? "icb-chart rate-text-green" : "icb-chart rate-text-red"}>
            {general?.likes?.summary.percentage.toFixed(1)}%
              <span>
                {general?.likes?.summary.direction === "UP" ?  <ArrowNarrowUp /> : <ArrowNarrowDown />}
              </span>
            </div>
            <AreaChartLikes general={general}/>
          </div>
          {/* ))} */}
          <div className="ic-box" onClick={(e) => setSharesAnalyticModal(true)}>
            <h5>Shares</h5>
            <p>
              {moment(startDate).format("MMM Do")} -{" "}
              {moment(endDate).format("MMM Do")}{" "}
            </p>

            <h6>{general?.shares?.total_number}</h6>
            <p>Total number of shares</p>
            <div className={general?.clicks?.summary.direction === "UP" ? "icb-chart rate-text-green" : "icb-chart rate-text-red"}>
              {general?.shares?.summary.percentage.toFixed(1)}%
              <span>
                {general?.shares?.summary.direction === "UP" ?  <ArrowNarrowUp /> : <ArrowNarrowDown />}
              </span>
            </div>
            <AreaChartShares general={general} />
          </div>
          <div className="ic-box" onClick={(e) => setReferAnalyticModal(true)}>
            <h5>Refer</h5>
            <p>
              {moment(startDate).format("MMM Do")} -{" "}
              {moment(endDate).format("MMM Do")}{" "}
            </p>

            <h6>{general?.refer?.total_number}</h6>
            <p>Total number of refer</p>
            <div className={general?.clicks?.summary.direction === "UP" ? "icb-chart rate-text-green" : "icb-chart rate-text-red"} >
            {general?.refer?.summary.percentage.toFixed(1)}%
              <span>
                {general?.refer?.summary.direction === "UP" ?  <ArrowNarrowUp /> : <ArrowNarrowDown />}
              </span>
            </div>
            <AreaChartRefer general={general} />
          </div>
          <div
            className="ic-box"
            onClick={(e) => setSupportAnalyticModal(true)}
          >
            <h5>Supporter</h5>
            <p>
              {moment(startDate).format("MMM Do")} -{" "}
              {moment(endDate).format("MMM Do")}{" "}
            </p>

            <h6>{general?.supporters?.total_number}</h6>
            <p>Supporters</p>
            <div className={general?.supporters?.summary.direction === "UP" ? "icb-chart rate-text-green" : "icb-chart rate-text-red"} >
              {general?.supporters?.summary.percentage.toFixed(1)}%
              <span>
                {general?.supporters?.summary.direction === "UP" ?  <ArrowNarrowUp /> : <ArrowNarrowDown />}
              </span>
            </div>
          <AreaChartSupporter general={general} />
          </div>
        </div>
      </div>
      <div className="demographic-contents">
        <div className="dmc-card">
          <div className="tabs">
            {/* ----------  nav tab -------- */}
            <ul className="nav-tab">
              <li
                className={activeTab === "city" ? "active" : ""}
                onClick={() => {
                  setActiveTab("city");
                }}
              >
                City
              </li>
              <li
                className={activeTab === "age" ? "active" : ""}
                onClick={() => {
                  setActiveTab("age");
                }}
              >
                Age
              </li>
            </ul>
            <div className="tab-title">Demographics</div>
          </div>
          {activeTab === "city" && (
            <div className="tabs2">
              {/* ----------  nav tab -------- */}
              <ul className="nav-tab">
                <li
                  className={activeTab2 === "city1" ? "active" : ""}
                  onClick={() => {
                    setActiveTab2("city1");
                  }}
                >
                  Canada
                </li>
                <li
                  className={activeTab2 === "city2" ? "active" : ""}
                  onClick={() => {
                    setActiveTab2("city2");
                  }}
                >
                  Canada
                </li>
                <li
                  className={activeTab2 === "city3" ? "active" : ""}
                  onClick={() => {
                    setActiveTab2("city3");
                  }}
                >
                  Canada
                </li>
              </ul>
            </div>
          )}
          <BarChart general={general} generalChart={generalChart}/>
        </div>
        <div className="dmc-stats">
          <ChartStats
            image={<WalletMoney />}
            title="Total orders made on page"
            statPercent={general?.total_order?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={general?.total_order?.total_number}
            onClick={(e) => setPageOrderModal(true)}
          />
          <ChartStats
            image={<BigEye />}
            title="Views on page"
            statPercent={general?.views?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={general?.views?.total_number}
            onClick={(e) => setPageViewsAnalyticModal(true)}
          />
          <ChartStats
            image={<RateIcon />}
            title="Customer conversion rate"
            statPercent={general?.customer_conversion_rate.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={general?.customer_conversion_rate?.total_number.toFixed(1)}
            onClick={(e) => setCustomerConversionModal(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralAnalytics;

