import cx from "classnames";
import { IoMdLocate } from "react-icons/io";
import useDeliveryLocationSort from "../../../hooks/delivveryLocationSort";
import { DefaultButton } from "../../Button";
import LocationAddress from "../../locationAddress";
import GoogleMaps from "../../maps";
import { showToast } from "../../ReactToast";
import Dates from "./dates";
const CityWide = ({
  defaultState,
  doorDeliveryModeState = [],
  locationsState = [],
  city,
}) => {
  const [doorDeliveryMode, setDoorDeliveryMode] = doorDeliveryModeState;
  const { addLocation, handleRemoveAddress } = useDeliveryLocationSort();
  console.log(doorDeliveryMode);
  const [locations, setLocations] = locationsState;

  const handleDoneInputingData = () => {
    //make sure all required fields have been filled!
    try {
      if (!doorDeliveryMode.free_delivery) {
        if (Number(doorDeliveryMode.delivery_fee) <= 0) {
          throw new Error(
            JSON.stringify({
              delivery_fee: " delivery fee cannot be $0 or less",
            })
          );
        }
      }
      if (
        doorDeliveryMode.door_delivery_type === "GEO_FENCING" &&
        doorDeliveryMode.address.trim() === ""
      ) {
        throw new Error(
          JSON.stringify({
            address: "address cannot be empty!",
          })
        );
      }
      if (!doorDeliveryMode.dates.length) {
        throw new Error(JSON.stringify({ dates: "dates cannot be empty!" }));
      }
      setLocations([...locations, doorDeliveryMode]);
      setDoorDeliveryMode(defaultState);
      // setEdit(!edit);
    } catch (err) {
      console.log(err);
      showToast(
        "error",
        JSON.parse(err.message)[Object.keys(JSON.parse(err.message))[0]]
      );
    }
  };

  // console.log(doorDeliveryMode);
  return (
    <div className="city-wide">
      <div
        className={cx("city-wide-btn", {
          "set-range":
            doorDeliveryMode.door_delivery_type.toLowerCase() !== "city_wide",
        })}>
        {doorDeliveryMode.door_delivery_type.toLowerCase() === "city_wide"
          ? "City wide"
          : "Set delivery range"}
      </div>
      <div className="city-wide-item">
        <Dates
          // doorDelivery={doorDelivery}
          deliveryState={[doorDeliveryMode, setDoorDeliveryMode]}>
          {doorDeliveryMode.door_delivery_type.toLowerCase() ===
            "geo_fencing" && (
            <>
              <div className="select-range">
                <span>Search for locations</span>
              </div>
            </>
          )}
        </Dates>
        {doorDeliveryMode.door_delivery_type.toLowerCase() ===
          "geo_fencing" && (
          <>
            <GoogleMaps
              showMarker={false}
              map={false}
              onAddressChange={async (address) => {
                const location = await addLocation(
                  doorDeliveryMode.address,
                  address,
                  true,
                  city.address
                );
                setDoorDeliveryMode({
                  ...doorDeliveryMode,
                  ...(location && { address: location }),
                });
              }}
              onAddMarker={(e) => {
                // setDoorDeliveryMode({
                //   ...doorDeliveryMode,
                //   geom: { ...doorDeliveryMode.geom, coordinates: e },
                // });
              }}
            />
            {doorDeliveryMode.address
              .split("*")
              .filter((address) => address.trim() !== "")
              .map((address, idx) => {
                return (
                  <LocationAddress
                    address={address}
                    key={idx}
                    id={idx + 1}
                    locationRemover={(e) => {
                      setDoorDeliveryMode({
                        ...doorDeliveryMode,
                        address: handleRemoveAddress(
                          doorDeliveryMode.address,
                          address
                        ),
                      });
                    }}
                  />
                );
              })}
          </>
        )}
      </div>
      <div className="done-delivery-btn" onClick={handleDoneInputingData}>
        <DefaultButton
          customStyle={{
            fontSize: 13,
            padding: "2px 15px",
            minWidth: "fit-content",
            backgroundColor: "var(--green)",
          }}>
          Done
        </DefaultButton>
      </div>
    </div>
  );
};
export default CityWide;
