import CircleIcon from "../../../assets/svg/circleIcon";
import JobItem from "./jobItem";
import { useState, useEffect, useContext, useR } from "react";
import DatePicker from "react-multi-date-picker";
import { BiSearch } from "react-icons/bi";
import Down from "../../../assets/svg/Down";
import SortIcon from "../../../assets/svg/sortIcon";
import useActivity from "../../../hooks/activity";
import Loader from "../../../components/loader";

const MyJobs = ({
  handleViewFull,
  active,
  jobs,
  handleGetJobsData,
  loading,
}) => {
  const [filters, setFilters] = useState({});
  const [value, setValue] = useState();
  const [valueEnd, setValueEnd] = useState();
  console.log(jobs, "the data");
  useEffect(() => {
    handleGetJobsData({ ...filters });
  }, [filters]);
  useEffect(() => {
    value &&
      valueEnd &&
      setFilters({
        ...filters,
        created_at__gte: value,
        created_at__lte: valueEnd,
      });
  }, [value, valueEnd]);

  const [sorted, setSorted] = useState();
  return (
    <div className="myJobs">
      <div className="queueHeader">
        <div
          className="showdate"
          style={{
            marginLeft: 0,
          }}
        >
          <p className="showdate-title">Show Date</p>
          <div className="calendar">
            <DatePicker format="DD/MM/YYYY" value={value} onChange={setValue} />
            <span>To</span>
            <DatePicker
              format="DD/MM/YYYY"
              value={valueEnd}
              onChange={setValueEnd}
            />
          </div>
        </div>
        <div className="activitySearch">
          <BiSearch />
          <input type="text" />
        </div>
      </div>
      <div className="sort">
        {
          <div className="sortSection">
            <p>Sort by</p>
            {!sorted ? (
              <div
                onClick={() => {
                  setSorted(!sorted);
                  setFilters({ ...filters, ordering: "-created_at" });
                }}
              >
                <span>Earliest to Latest</span>
                <SortIcon />
              </div>
            ) : (
              <div
                onClick={() => {
                  setSorted(!sorted);
                  setFilters({ ...filters, ordering: "created_at" });
                }}
              >
                <span>Latest to Earliest</span>
                <SortIcon />
              </div>
            )}
          </div>
        }
      </div>
      <div className="queueList">
        {loading && <Loader />}

        {!loading &&
          jobs?.map((job) => (
            <JobItem handleViewFull={handleViewFull} job={job} />
          ))}
      </div>
    </div>
  );
};
export default MyJobs;
