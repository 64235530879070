import React, {useContext, useEffect} from "react"
import "./addPhotos.scss"
import { FaPlus, AiFillPlusCircle, FaTimes, FaEdit, FaPen, FaPenAlt, FaPencilAlt  } from 'react-icons/fa'
import {
  Images
} from "./Images"
import Edit from "../../../../assets/icons/edit.svg";
import ExploreContext from "../../../../hooks/exploreContext";
import {MdFileUpload} from "react-icons/md";

const AddPhotos = (props) => {
  const {setJobPostImage, jobPostImage, jobCreateComplete} = useContext(ExploreContext);

  const hiddenImgInput = React.useRef(null);
  const handleClickUploadImg = event => {
    hiddenImgInput.current.click();
    props.addedPhoto();
  }
  const handleInputChange = event => {
    const fileUploaded = event.target.files[0];
    setJobPostImage(fileUploaded);
  }
  useEffect(() => {
    if (jobCreateComplete) {
      setJobPostImage([]);
    }
  }, [jobCreateComplete])

  return(
    <div className="add_photo_container">
        <section className="add-image-section">
          <div className="add-photo-card">
            <div className="right_text">
              <p className="add-photo-header">Create thumbnail</p>
              <p className="add-photo-sub-header">The photo display on the job listing thumbnail</p>
            </div>
          </div>
            <div className="add_slide_images">

                <input
                  id="btn-resume"
                  name="btn-resume"
                  style={{ display: 'none' }}
                  type="file"
                  className='upload-btn'
                  ref={hiddenImgInput}
                  accept={'image/png, image/jpeg, .image/svg'}
                  onChange={handleInputChange}
                />
              {
                jobPostImage?.size ? 
                <>
                  {/* <div className="edit_bg">
                    <img src={Edit} className="edit_icon"/>
                  </div> */}
                  <div className="loop_slide_images">
                    
                        <div className="loop_slide_images">
                          <img src={jobPostImage.size ? URL.createObjectURL(jobPostImage) : null} />
                          <div className="delete_icon_bg" onClick={handleClickUploadImg}>
                            <div className="delete_icon">
                              <span> <FaPencilAlt /> </span>
                            </div>
                          </div>
                        </div>
                      
                  </div>
                </>
                :
                <div className="thumbnail_input">
                  <button type="button" className="upload-btn" onClick={handleClickUploadImg}>
                    <MdFileUpload /> Upload File
                  </button>
                </div>
              }
              
            </div>
        </section>
    </div>
  )
}

export default AddPhotos;
