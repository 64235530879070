/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearArchiveMinusComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-364 -188)">
      <path className="a" d="M5,0H0" transform="translate(373.5 198.65)" />
      <path
        className="a"
        d="M13.5,0H3.86A3.869,3.869,0,0,0,0,3.86V17.95c0,1.8,1.29,2.56,2.87,1.69l4.88-2.71a2.118,2.118,0,0,1,1.87,0l4.88,2.71c1.58.88,2.87.12,2.87-1.69V3.86A3.884,3.884,0,0,0,13.5,0Z"
        transform="translate(367.32 190)"
      />
      <path className="b" d="M0,0H24V24H0Z" transform="translate(364 188)" />
    </g>
  </svg>
);

export default VuesaxLinearArchiveMinusComponent;
