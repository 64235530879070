const ArrowNarrowDown = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.636"
      height="19.636"
      viewBox="0 0 19.636 19.636"
    >
      <g id="ic-arrow-narrow-up" transform="translate(6.909 15.29) rotate(180)">
        <path
          id="Path_397"
          data-name="Path 397"
          d="M0,0H19.636V19.636H0Z"
          transform="translate(-12.727 -4.347)"
          fill="none"
        />
        <line
          id="Line_23"
          data-name="Line 23"
          y2="12.427"
          transform="translate(-2.831 -0.82)"
          fill="none"
          stroke="#c10000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_24"
          data-name="Line 24"
          x1="3.682"
          y1="3.682"
          transform="translate(-2.813 -0.785)"
          fill="none"
          stroke="#c10000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_25"
          data-name="Line 25"
          y1="3.682"
          x2="3.682"
          transform="translate(-6.531 -0.785)"
          fill="none"
          stroke="#c10000"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};
export default ArrowNarrowDown;
