import {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
} from "react";
import "./createbookingModal.scss";
import CustomDropdown from "../../customDropdown/customDropdown";
import CustomCheckBox from "../../Bookings/createBooking/checkBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaPlus, FaCalendarAlt, FaInfoCircle } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/all";
import moment from "moment";
import { showToast } from "../../ReactToast";

const SpecificDates = ({
  availabilityForm,
  setAvailabilityForm,
  setMDate,
  mDate,
  setMDate2,
  mDate2,
  handlePersonnelHours,
  id,
  personnelHoursData,
  item,
  idx,
  business_opening_hours2,
  acceptedTimeRange,
  toggleEditPersonnelPost,
  handleUpdatePersonnelAvailability,
  personnelForm,
  handleRemovePersonnelAvailability,
  handleAddPersonnelAvailability,
  addPersonnelAvailabilityData,
}) => {
  const handleAvailabilityInputChange = (e, idx) => {
    const newFormValues = [...availabilityForm];
    newFormValues[idx][e.target.name] = e.target.value;
    setAvailabilityForm(newFormValues);
    console.log(`this is${e} and ${idx}`);
    console.log("an event", e);
  };

  const [test, setTest] = useState([]);
  const [specificPersonnelsId, setSpecificPersonnelsId] = useState([]);

  const [date2, setDate2] = useState([]);
  console.log(date2);
  console.log("date2....", date2?.mDate);
  const newHoursValues = [...availabilityForm];
  const newFormValues12 = business_opening_hours2;

  const handleAvailabilityDateChange = (date, idx) => {
    const newFormValues = [...availabilityForm];
    newFormValues[idx] = { ...newFormValues[idx], date: date };
    setAvailabilityForm({ newFormValues });
  };

  const removeAvailabilityFormFields = (idx) => {
    const newFormValues = [...availabilityForm];
    newFormValues.splice(idx, 1);
    setAvailabilityForm(newFormValues);
  };

  // const timeValidation = (idx, e) => {
  //   const newFormValues = [...availabilityForm];
  //   if (e.target.validationMessage !== "") {
  //     showToast("error", `${e.target.validationMessage}`);
  //   }
  // };

  // const handlePersonnelTimeChange = (id, idx) => {
  //   setTest(availabilityForm);
  //   delete test[idx].publish;
  //   handlePersonnelHours(id, test[idx]);
  // };

  console.log("test-------", test);

  const timeValidation2 = (idx) => {
    const newFormValues = business_opening_hours2;
    // newFormValues[idx] = newFormValues2[idx]
    if (personnelHoursData?.response?.response?.status === 400) {
      showToast(
        "error",
        `Duration must be within business working hours ${newFormValues12[idx]?.start}:00 to ${newFormValues12[idx]?.end}:00 `
      );
    }
  };
  console.log("personnelHoursData, ", personnelHoursData);

  useEffect(() => {
    if (personnelHoursData?.response?.response?.status === 400) {
      showToast("error", `Duration must be within business working hours`);
    }
  }, [personnelHoursData?.response]);

  const handleGetDay = (weekDayName, idx) => {
    const newState = [...availabilityForm];
    if (weekDayName === "Monday") {
      newState[idx].day = 0;
      newState[idx].opening_time = acceptedTimeRange[0]?.opening_time;
      newState[idx].closing_time = acceptedTimeRange[0]?.closing_time;
    }
    if (weekDayName === "Tuesday") {
      newState[idx].day = 1;
      newState[idx].opening_time = acceptedTimeRange[1]?.opening_time;
      newState[idx].closing_time = acceptedTimeRange[1]?.closing_time;
    }
    if (weekDayName === "Wednesday") {
      newState[idx].day = 2;
      newState[idx].opening_time = acceptedTimeRange[2]?.opening_time;
      newState[idx].closing_time = acceptedTimeRange[2]?.closing_time;
    }
    if (weekDayName === "Thursday") {
      newState[idx].day = 3;
      newState[idx].opening_time = acceptedTimeRange[3]?.opening_time;
      newState[idx].closing_time = acceptedTimeRange[3]?.closing_time;
    }
    if (weekDayName === "Friday") {
      newState[idx].day = 4;
      newState[idx].opening_time = acceptedTimeRange[4]?.opening_time;
      newState[idx].closing_time = acceptedTimeRange[4]?.closing_time;
    }
    if (weekDayName === "Saturday") {
      newState[idx].day = 5;
      newState[idx].opening_time = acceptedTimeRange[5]?.opening_time;
      newState[idx].closing_time = acceptedTimeRange[5]?.closing_time;
    }
    if (weekDayName === "Sunday") {
      newState[idx].day = 6;
      newState[idx].opening_time = acceptedTimeRange[6]?.opening_time;
      newState[idx].closing_time = acceptedTimeRange[6]?.closing_time;
    }

    return setAvailabilityForm(newState);
  };

  const handleSetTime = (e, idx, idx2, item) => {
    const newFormValues = [...availabilityForm];
    // newFormValues[idx][e.target.name] = e.target.value;
    // setAvailabilityForm(newFormValues);
    // handleAvailabilityInputChange(e, idx);

    const [hour, mins] = e.target.value.split(":");
    const [acceptedHour, acceptedMins] =
      acceptedTimeRange[idx2].opening_time.split(":");
    const [acceptedHour2, acceptedMins2] =
      acceptedTimeRange[idx2].closing_time.split(":");
    const [acceptedHour3, acceptedMins3] =
      newFormValues[idx].closing_time.split(":");

    //converting full time to mins
    const currentTime = Number(hour) * 60 + Number(mins);
    const acceptedTime = Number(acceptedHour) * 60 + Number(acceptedMins);
    const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
    const acceptedTime3 = Number(acceptedHour3) * 60 + Number(acceptedMins3);

    const startDuration =
      acceptedTimeRange[idx2].opening_time.length > 5
        ? acceptedTimeRange[idx2].opening_time.slice(0, -3)
        : acceptedTimeRange[idx2].opening_time;

    const endDuration =
      acceptedTimeRange[idx2].closing_time.length > 5
        ? acceptedTimeRange[idx2].closing_time.slice(0, -3)
        : acceptedTimeRange[idx2].closing_time;

    if (
      currentTime < acceptedTime ||
      currentTime >= acceptedTime2 ||
      currentTime >= acceptedTime3
    ) {
      //error
      showToast(
        "error",
        `Duration must be within business working hours ${startDuration} to ${endDuration}`
      );
      //reset time input
      // setTime(acceptedTimeRange.start);
    } else {
      // setTime(target.value);
      // newFormValues[idx][e.target.name] = e.target.value;
      // setAvailabilityForm(newFormValues);
      // console.log("correct time")
      handleAvailabilityInputChange(e, idx);
      // upadateAvailability(e, idx)
    }
  };
  const handleSetTime1 = (e, idx, idx2, item) => {
    const newFormValues = [...availabilityForm];
    // newFormValues[idx][e.target.name] = e.target.value;
    // setAvailabilityForm(newFormValues);
    // handleAvailabilityInputChange(e, idx);

    const [hour, mins] = e.target.value.split(":");
    const [acceptedHour, acceptedMins] =
      acceptedTimeRange[idx2].opening_time.split(":");
    const [acceptedHour2, acceptedMins2] =
      acceptedTimeRange[idx2].closing_time.split(":");
    const [acceptedHour3, acceptedMins3] =
      newFormValues[idx].opening_time.split(":");

    //converting full time to mins
    const currentTime = Number(hour) * 60 + Number(mins);
    const acceptedTime = Number(acceptedHour) * 60 + Number(acceptedMins);
    const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
    const acceptedTime3 = Number(acceptedHour3) * 60 + Number(acceptedMins3);

    const startDuration =
      acceptedTimeRange[idx2].opening_time.length > 5
        ? acceptedTimeRange[idx2].opening_time.slice(0, -3)
        : acceptedTimeRange[idx2].opening_time;

    const endDuration =
      acceptedTimeRange[idx2].closing_time.length > 5
        ? acceptedTimeRange[idx2].closing_time.slice(0, -3)
        : acceptedTimeRange[idx2].closing_time;

    if (
      currentTime <= acceptedTime ||
      currentTime > acceptedTime2 ||
      currentTime <= acceptedTime3
    ) {
      //error
      showToast(
        "error",
        `Duration must be within business working hours ${startDuration} to ${endDuration}`
      );
      //reset time input
      // setTime(acceptedTimeRange.start);
    } else {
      // setTime(target.value);
      // newFormValues[idx][e.target.name] = e.target.value;
      // setAvailabilityForm(newFormValues);
      // console.log("correct time")
      handleAvailabilityInputChange(e, idx);
      // upadateAvailability(e, idx)
    }
  };

  const handleSetTime2 = (e, idx, idx2) => {
    const newFormValues = [...availabilityForm];

    console.log(e.target.value);
    const [hour, mins] = e.target.value.split(":");
    const [acceptedHour, acceptedMins] =
      newFormValues[idx].opening_time.split(":");
    const [acceptedHour2, acceptedMins2] =
      acceptedTimeRange[idx2].closing_time.split(":");
    const [breakAcceptedHour2, breakAcceptedMins2] =
      newFormValues[idx].break_end.split(":");

    //converting full time to mins
    const currentTime = Number(hour) * 60 + Number(mins);
    const acceptedTime = Number(acceptedHour) * 60 + Number(acceptedMins);
    const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
    const breakAcceptedTime2 =
      Number(breakAcceptedHour2) * 60 + Number(breakAcceptedMins2);

    const startDuration = acceptedTimeRange[idx2].opening_time.slice(0, -3);

    if (
      currentTime <= acceptedTime ||
      currentTime >= acceptedTime2 ||
      currentTime >= breakAcceptedTime2
    ) {
      showToast(
        "error",
        `Break time must be within ${newFormValues[idx].opening_time} to ${newFormValues[idx].break_end} `
      );
    } else {
      // newFormValues[idx][e.target.name] = e.target.value;
      // setAvailabilityForm(newFormValues);
      handleAvailabilityInputChange(e, idx);
    }
  };

  const handleSetTime3 = (e, idx, idx2) => {
    const newFormValues = [...availabilityForm];

    const [hour, mins] = e.target.value.split(":");

    const [acceptedHour2, acceptedMins2] =
      acceptedTimeRange[idx2].closing_time.split(":");
    const [breakAcceptedHour, breakAcceptedMins] =
      newFormValues[idx].break_start.split(":");
    const [breakAcceptedHour3, breakAcceptedMins3] =
      newFormValues[idx].closing_time.split(":");

    //converting full time to mins
    const currentTime = Number(hour) * 60 + Number(mins);
    const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
    const breakAcceptedTime =
      Number(breakAcceptedHour) * 60 + Number(breakAcceptedMins);
    const breakAcceptedTime3 =
      Number(breakAcceptedHour3) * 60 + Number(breakAcceptedMins3);

    const endDuration = acceptedTimeRange[idx2].closing_time.slice(0, -3);

    if (
      currentTime <= breakAcceptedTime ||
      currentTime >= acceptedTime2 ||
      currentTime >= breakAcceptedTime3
    ) {
      showToast(
        "error",
        `Break time must be within ${newFormValues[idx].break_start} to ${newFormValues[idx].closing_time} `
      );
    } else {
      // newFormValues[idx][e.target.name] = e.target.value;
      // setAvailabilityForm(newFormValues);
      handleAvailabilityInputChange(e, idx);
    }
  };
  // const datee2 = []

  const rmvDate = availabilityForm?.map((item, idx) => {
    return new Date(item?.date);
  });
  console.log(rmvDate);

  const handleAvailabilityUpdate = (pid, aid, data) => {
    if (toggleEditPersonnelPost) {
      handleUpdatePersonnelAvailability(pid, aid, data);
    }
  };

  const addSpecificDatesFields2 = async (pid, aid, data) => {
    if (toggleEditPersonnelPost) {
      await handleAddPersonnelAvailability(
        personnelForm.id,
        availabilityForm[idx]
      );
      if (addPersonnelAvailabilityData?.response?.response?.status === 200) {
        availabilityForm.push(addPersonnelAvailabilityData?.data?.id);
      }
      if (addPersonnelAvailabilityData?.response?.response?.status === 400) {
        handleAvailabilityUpdate(pid, aid, data);
        if (specificPersonnelsId.id)
          handleAvailabilityUpdate(pid, specificPersonnelsId[idx].id, data);
      }
    }
  };

  useEffect(() => {
    if (addPersonnelAvailabilityData?.response?.response?.status === 200) {
      setSpecificPersonnelsId([
        ...specificPersonnelsId,
        addPersonnelAvailabilityData?.data,
      ]);
    }
  }, [addPersonnelAvailabilityData?.response]);

  console.log("addPersonnelAvailabilityData", addPersonnelAvailabilityData);
  console.log("specificPersonnelsId", specificPersonnelsId);

  const upadateAvailability = (e, idx) => {
    const newArr = availabilityForm.map((item, i) => {
      if (idx === i) {
        return { ...item, [e.target.name]: e.target.value };
      } else {
        return item;
      }
    });
    setAvailabilityForm(newArr);
  };
  return (
    <>
      {/* {availabilityForm.map((item, idx) => ( */}
      <div className="item__content_availability" key={idx}>
        <div className="datepicker-container">
          <FaCalendarAlt />

          <DatePicker
            // minDate={new Date()}
            className="custom-date"
            selected={new Date(availabilityForm[idx].date)}
            onChange={(date) => {
              const newFormValues = [...availabilityForm];
              const weekDayName = moment(date).format("dddd");
              newFormValues[idx].date = `${moment(date).format("YYYY-MM-DD")}`;
              setAvailabilityForm(newFormValues);
              //
              const newFormValues2 = [...availabilityForm];
              newFormValues[idx].mDate = date;
              setAvailabilityForm(newFormValues2);
              handleGetDay(weekDayName, idx);
            }}
            dateFormat="MMM d, yyyy"
            placeholderText="select date"
            excludeDates={rmvDate}
          />
        </div>
        <div className="item__one">
          <p>
            Open <span>from</span>
          </p>
          <input
            type="time"
            className="form-input-sm"
            name="opening_time"
            value={item.opening_time}
            onChange={(e) => {
              // handleAvailabilityInputChange(e, idx);
              handleSetTime(e, idx, item.day);
              // timeValidation(idx, e);
              // handlePersonnelTimeChange(id, idx);
              // timeValidation2(idx);
              // handleAvailabilityUpdate(
              //   personnelForm.id,
              //   personnelForm.availability[idx].id,
              //   availabilityForm[idx]
              // );
              addSpecificDatesFields2(
                personnelForm.id,
                personnelForm.availability[idx].id,
                availabilityForm[idx]
              );
            }}
          />
          <span>to</span>
          <input
            type="time"
            className="form-input-sm"
            name="closing_time"
            value={item.closing_time}
            onChange={(e) => {
              // handleAvailabilityInputChange(e, idx);
              handleSetTime1(e, idx, item.day);
              // upadateAvailability(e, idx);
              // timeValidation(idx, e);
              // handlePersonnelTimeChange(id, idx);
              timeValidation2(idx);
              addSpecificDatesFields2(
                personnelForm.id,
                personnelForm.availability[idx].id,
                availabilityForm[idx]
              );
            }}
          />
        </div>
        <div className="item__one">
          <p>
            Break Time <span>from</span>
          </p>
          <input
            type="time"
            className="form-input-sm"
            name="break_start"
            min={item.opening_time}
            max={item.closing_time}
            value={item.break_start}
            onChange={(e) => {
              // handleAvailabilityInputChange(e, idx);
              handleSetTime2(e, idx, item.day);
              // timeValidation(idx, e);
              timeValidation2(idx);
              addSpecificDatesFields2(
                personnelForm.id,
                personnelForm.availability[idx].id,
                availabilityForm[idx]
              );
            }}
          />
          <span>to</span>
          <input
            type="time"
            className="form-input-sm"
            name="break_end"
            min={item.break_start}
            max={item.closing_time}
            value={item.break_end}
            onChange={(e) => {
              // handleAvailabilityInputChange(e, idx);
              handleSetTime3(e, idx, item.day);
              // timeValidation(idx, e);
              addSpecificDatesFields2(
                personnelForm.id,
                personnelForm.availability[idx].id,
                availabilityForm[idx]
              );
            }}
          />
        </div>

        <IoMdCloseCircleOutline
          className="close-icon"
          onClick={() => {
            removeAvailabilityFormFields(idx);
            handleRemovePersonnelAvailability(personnelForm.id, {
              ids: [item.id],
            });
          }}
        />
      </div>
      {/* ))} */}
    </>
  );
};
export default SpecificDates;
