import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import useSettings from "../../../../../hooks/settings";
import AddDeliveryCard from "./addDeliveryCard";
import DeliveryCard from "./deliveryCard";

const PrivateSellerDeliverySettings = ({ active, businessCount }) => {
  const [deliveryData, setDeliveryData] = useState([
    {
      location: "Los Angeles",
      deliveryType: "Door Delivery",
      date: "Jul 26, 2012",
      time: "10:00am",
      fill: "#066CBA",
    },
    {
      location: "Los Angeles",
      deliveryType: "Door Delivery",
      date: "Jul 26, 2012",
      time: "10:00am",
      fill: "#43AB62",
    },
    {
      location: "Los Angeles",
      deliveryType: "Door Delivery",
      date: "Jul 26, 2012",
      time: "10:00am",
      fill: "#F25151",
    },
  ]);
  const currentUser = useSelector((state) => state.currentUser.user.pages);

  const { handleGetPageSpecificData, pageSpecificData } = useSettings();

  const [currentPageDataId, setCurrentPageDataId] = useState();

  useEffect(() => {
    currentUser?.map((currentPage) => {
      if (
        currentPage.type === "PRIVATE" &&
        currentUser?.[businessCount]?.type === "PRIVATE"
      ) {
        setCurrentPageDataId(currentPage.id);
      } else {
        setCurrentPageDataId(currentUser?.[businessCount]?.id);
      }
    });
  }, [active]);

  useEffect(() => {
    handleGetPageSpecificData(currentPageDataId);
  }, [currentPageDataId]);

  console.log(pageSpecificData, "id");
  return (
    <div className="privateSeller_delivery">
      <AddDeliveryCard />
      {pageSpecificData?.data
        ? pageSpecificData?.data?.delivery_settings?.map((deliveryDataItem) => {
            return (
              <DeliveryCard
                data={deliveryDataItem}
                fill={deliveryDataItem.fill}
              />
            );
          })
        : deliveryData?.map((deliveryDataItem) => {
            return (
              <DeliveryCard
                data={deliveryDataItem}
                fill={deliveryDataItem.fill}
              />
            );
          })}
    </div>
  );
};
export default PrivateSellerDeliverySettings;
