import React from "react";
import Styled from "styled-components";
import {
  Search,
  ExpandMore,
  CropOriginal,
  PlayCircleOutline,
} from "@material-ui/icons";

import { colors } from "../../../color";
import { SuggestToggle } from "../pagetoggle";
import BusinessBody from "./galleryBody";
import {
  Col,
  Container,
  Row,
  Dropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from "reactstrap";
import "../../forms/index.css";

// temporary name ...
const Top = Styled.div`
    height: 55px;
    width: 100%;
    background: white;
`;
export const SearchDiv = Styled.div`
    background: #efefef;
    width: 150px;
    height: 30px;
`;
const P = Styled.p`
    color: ${colors.COMPANY_COLOR};
    font-weight: bold;
    font-size: 12px;
`;
export const Input = Styled.input`
    background: transparent;
    border: none;
    font-size: 10px;
    padding-left: 8px;
    outline: none;
`;
const DateInput = Styled.input`
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 90px;
    background: #efefef;
    border: none;
    font-size: 13px;
    padding-left: 10px;
`;

const DateText = Styled.p`
    color: ${colors.LIGHT_GREY};
    font-weight: bold;
    font-size: 10px;
`;
const Item = Styled(DropdownItem)`
    color: ${colors.COMPANY_COLOR};
    font-size: 12px;
    font-weight: bold;
`;
// temporary component name
const Filter = Styled(DropdownToggle)`
    background: none;
`;

const iconStyle = {
  fontSize: "15px",
  fontWeight: "500",
};
class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: false, isOpen: false };
  }
  toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  render() {
    return (
      <Col xl={10} style={{ margin: "auto" }}>
        <Row>
          <Col xl={9}>
            <Top className="border-radius-10 d-flex align-items-center">
              <Container className="d-flex justify-content-between">
                <div className="d-flex justify-content-between align-items-center width-20">
                  <P className="pt-3">Show</P>
                  <Dropdown
                    style={{ background: "none" }}
                    isOpen={this.state.isOpen}
                    toggle={this.toggle}
                    className="border-radius-10"
                  >
                    <Filter
                      className="d-flex  justify-content-between align-items-center"
                      style={{
                        fontSize: "12px",
                        background: "none",
                        height: "30px",
                        width: "120px",
                        color: `${colors.COMPANY_COLOR}`,
                      }}
                    >
                      All
                      <ExpandMore style={{ color: `${colors.ORANGE}` }} />
                    </Filter>
                    <DropdownMenu
                      style={{
                        width: "100%",
                        borderRadius: "10%",
                        height: "100px",
                      }}
                    >
                      <Item>All</Item>
                      <Item className="d-flex justify-content-between">
                        Photos <CropOriginal style={iconStyle} />
                      </Item>
                      <Item className="d-flex justify-content-between">
                        Videos
                        <PlayCircleOutline style={iconStyle} />
                      </Item>
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div className="d-flex align-items-center width-40">
                  <P className="pr-2 pt-3">Show Date</P>
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ width: "200px" }}
                  >
                    <DateText className="pt-3 pr-1">From</DateText>
                    <DateInput
                      placeholder="date"
                      className="rounded"
                      type="date"
                    />
                    <DateText className="pt-3 pr-1 pl-1">to</DateText>
                    <DateInput className="rounded" type="date" />
                  </div>
                </div>
                <SearchDiv className="d-flex align-items-center border-radius-15 mt-2">
                  <Search className="pl-2" style={{ fontSize: "25px" }} />
                  <Input type="text" placeholder="Search bookings" />
                </SearchDiv>
              </Container>
            </Top>
            <BusinessBody />
          </Col>
          <Col xl={3}>
            <p
              className="pt-3"
              style={{ fontWeight: " 700", fontSize: "20px" }}
            >
              Suggested Listings
            </p>
            <div
              className="border-radius-10"
              style={{ background: "#9300b125", height: "400px" }}
            >
              <h6>third</h6>
            </div>
            <SuggestToggle />
            <div
              className="mt-3 border-radius-10"
              style={{ background: "#9300b125", height: "400px" }}
            ></div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default Gallery;
