import {
  SET_ACCOUNT_TYPE,
  SET_CURRENT_USER,
  SET_USER_TOKEN,
} from "./index.type";

export const setCurrentUser = (payload) => ({
  type: SET_CURRENT_USER,
  payload,
});
export const setAccountType = (payload) => ({
  type: SET_ACCOUNT_TYPE,
  payload,
});
export const setUserToken = (payload) => ({
  type: SET_USER_TOKEN,
  payload,
});
