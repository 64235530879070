import React, { useState, useContext, useEffect } from "react";
import Edit from "../../../assets/icons/edit.svg";
import "./referralContents.scss";
import { FaCopy, IoMdCloseCircleOutline } from "react-icons/all";
import CustomDropdown from "../../customDropdown/customDropdown";
import CustomCheckBox from "../../customCheckBox";
import genCode from "unique-string-generator";
import classNames from "classnames";
const ReferralContents = ({ showHeader = true, state = [] }) => {
  const [radio2, setRadio2] = useState("anyone");
  const [formValues, setFormValues] = useState([{ name: "", price: "" }]);
  const [body, setBody] = state;
  const data = [{ label: "Fixed price $" }, { label: "Percentage %" }];
  const [copied, setCopied] = useState(false);
  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  // const addFormFields = () => {
  //   setFormValues([...formValues, { name: "", price: "" }]);
  // };

  const removeFormFields = (idx) => {
    setBody({
      ...body,
      referrals: body.referrals
        .map((data, id) => {
          return idx !== id && data;
        })
        .filter((data) => data && data),
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // alert(JSON.stringify(formValues));
  };
  useEffect(() => {
    if (copied !== null) {
      setTimeout(() => {
        setCopied(null);
      }, 1000);
    }
  }, [copied]);

  console.log(body.referrals);
  return (
    <div className="ref-contents-container">
      <div className="cbc-cbc-card cbg-light">
        <div className="cbc-cbcc-body">
          <div className="ccb__contents_ref">
            <div className="ref__topbox">
              {showHeader && (
                <div className="cb-1">
                  <label className="check-label" htmlFor="001">
                    <CustomCheckBox
                      style={{
                        minWidth: 18,
                        height: 18,
                        borderColor: "var(--dark-purple-bg)",
                        marginRight: 10,
                      }}
                    />
                    Referral
                  </label>
                </div>
              )}
            </div>
            <div className="ref-box-one">
              <h5> Who can refer: </h5>
              <div className="input-group">
                <div className="rf-radio-one">
                  <label className="rfr-label">
                    <input
                      type="radio"
                      name="refer"
                      value="influencer"
                      onChange={(e) => {
                        setBody({
                          ...body,
                          referrals: [],
                          who_can_refer: "INFLUENCER",
                        });
                        setRadio2(e.target.value);
                      }}
                    />
                    <span className="checkmark"></span>
                    Influencer
                  </label>
                </div>
                <div className="rf-radio-two">
                  <label className="rfr-label">
                    <input
                      type="radio"
                      name="refer"
                      value="anyone"
                      checked={radio2 === "anyone"}
                      onChange={(e) => {
                        const defaultAnybody = {
                          influencer_type: "ANYBODY",
                          price_type: "",
                          price_value: 0,
                        };
                        setRadio2(e.target.value);
                        setBody({
                          ...body,
                          referrals: [defaultAnybody],
                          who_can_refer: "ANYBODY",
                        });
                      }}
                    />
                    <span className="checkmark"></span>
                    Anyone
                  </label>
                </div>
              </div>
            </div>
            {radio2 === "anyone" && (
              <div className="ref-box-two">
                <div className="input-group ">
                  <label htmlFor="">Anyone: </label>
                  <div className="dropbox">
                    <CustomDropdown
                      items={data}
                      onChange={(e) => {
                        setBody({
                          ...body,
                          referrals: [
                            {
                              ...(body.referrals?.length
                                ? body.referrals[0]
                                : {}),
                              price_type: e
                                ?.toLowerCase()
                                .includes("percentage")
                                ? "PERCENTAGE"
                                : "FIXED",
                            },
                          ],
                        });
                      }}
                    />
                  </div>
                  <input
                    className="form-input-sm mlr-2"
                    type="text"
                    placeholder="$ 90"
                    name="list_title"
                    value={`${
                      body.referrals?.length &&
                      body.referrals[0]?.price_type
                        ?.toLowerCase()
                        .includes("percentage")
                        ? "%"
                        : "$"
                    }${
                      body.referrals?.length
                        ? Number(body.referrals[0]?.price_value || 0)
                        : 0
                    }`}
                    onChange={(e) => {
                      setBody({
                        ...body,
                        referrals: [
                          {
                            ...body.referrals[0],
                            price_value: Number(
                              e.target.value.replace("$", "").replace("%", "")
                            ),
                          },
                        ],
                      });
                    }}
                  />
                </div>
              </div>
            )}

            {radio2 === "influencer" && (
              <div className="ref-box-three">
                <div className="box-three-header">
                  <h5>Influencer</h5>
                  <button
                    type="button"
                    className="add-new"
                    onClick={() =>
                      setBody({
                        ...body,
                        referrals: [
                          ...body.referrals,
                          {
                            influencer_type: "INFLUENCER",
                            username: "",
                            price_type: "FIXED",
                            price_value: 0,
                          },
                        ],
                      })
                    }>
                    Add Influencer
                  </button>
                </div>

                <div className="item__scroll">
                  {body.referrals.map(
                    (
                      {
                        influencer_type,
                        username,
                        price_type,
                        price_value,
                        code,
                      },
                      idx
                    ) => (
                      <div className="box-three-card" key={idx}>
                        <div className="item__one">
                          <input
                            className="form-input"
                            typFe="text"
                            placeholder="Username"
                            name="list_title"
                            value={username || ""}
                            onChange={(e) =>
                              setBody({
                                ...body,
                                referrals: body.referrals.map((data, id) => {
                                  return idx === id
                                    ? {
                                        ...data,
                                        username: e.target.value,
                                        code:
                                          e.target.value.trim() !== ""
                                            ? genCode.UniqueString()
                                            : undefined,
                                      }
                                    : data;
                                }),
                              })
                            }
                          />
                          <div className="dropbox">
                            <CustomDropdown
                              items={data}
                              onChange={(e) => {
                                console.log(e);
                                setBody({
                                  ...body,
                                  referrals: body.referrals.map((data, id) => {
                                    return idx === id
                                      ? {
                                          ...data,
                                          price_type: e
                                            .toLowerCase()
                                            .includes("percentage")
                                            ? "PERCENTAGE"
                                            : "FIXED",
                                        }
                                      : data;
                                  }),
                                });
                              }}
                            />
                          </div>
                          <input
                            className="form-input-sm mlr-2"
                            type="text"
                            placeholder="$ 90"
                            name="list_title"
                            value={`${
                              price_type === "PERCENTAGE" ? "%" : "$"
                            }${price_value}`}
                            onChange={(e) =>
                              setBody({
                                ...body,
                                referrals: body.referrals.map((data, id) => {
                                  return idx === id
                                    ? {
                                        ...data,
                                        price_value: Number(
                                          e.target.value
                                            .replace("$", "")
                                            .replace("%", "")
                                        ),
                                      }
                                    : data;
                                }),
                              })
                            }
                          />
                          {code && (
                            <span
                              className={classNames({
                                "copy-code": copied === idx,
                              })}
                              onClick={() => {
                                window.navigator.clipboard.writeText(code);
                                setCopied(idx);
                              }}>
                              {copied === idx ? "Copied" : "Copy code"}
                            </span>
                          )}
                          <span className="cc-code">
                            <FaCopy /> {code}
                          </span>
                        </div>

                        <button
                          type="button"
                          className="remove"
                          onClick={() => removeFormFields(idx)}>
                          <IoMdCloseCircleOutline className="close-icon" />
                        </button>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReferralContents;
