import React, {useEffect, createContext, useState, useContext  } from "react";
import ModalContext from '../../../../hooks/modalContexts'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import "./index.scss";
import {
  FaSearch,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { Refferal } from "../../../../assets/svg/FeedOptionsIcon";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { FaSortAmountDownAlt, FaCalendarAlt } from "react-icons/fa";
import CustomDropdown from "../../../../components/customDropdown/customDropdown";
import CustomCheckBox from "../../../../components/customCheckBox";
import FeedContainer from '../../../../components/FeedContainer'
import { toggleRightBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../../../utils/greeting";
import useConnect from "../../../../hooks/connectHooks";
import item from "../../../../assets/img/Rectangle 1890.PNG";
import person from '../../../../assets/img/Ellipse 320.PNG';
import moment from "moment";

const TransactionCards = () => {
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch()
  const history = useHistory();

  /* paginate */
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };
const {
  handleIncomingModal,
  handleAcceptedModal,
  handleAutoAcceptModal,
  handleDiscountModal,
} = useContext(ModalContext)

const { handleGetConnectProfile, connectProfileData} = useConnect();

useEffect(() => {
  handleGetConnectProfile();
  console.log(connectProfileData?.data?.results);
  console.log("##############")
}, []);

  return (
    <div className="transactions">
      <div className="transaction-body-container">
        <div className="hbc-card-body">

          {/*--------- CARD CONTENTS ------------------*/}
          <div className="card-cb-contents">
            {connectProfileData.loading && <p> Loading... </p>}

            {!connectProfileData.loading && connectProfileData.error && (
              <p>An error</p>
            )}
            {!connectProfileData.loading &&
              !connectProfileData.error &&
              connectProfileData?.data?.results
                ?.slice(0, 4)
                .map((suggest) => (
              <div key={suggest.id} className="hbc-cbc-card"
                onClick={() =>{
                  // handleIncomingModal('ADD_INCOMING_TO_DISPLAY', 'true')
                }}
                >
                <div className="hbc-cbcc-body">

                  <div className="hcb-view-one">
                    <div className="orc-detail-box">
                      <div className="orc-db">
                        <img src={
                            suggest.page.featured_image === null ? person
                          : suggest.page.featured_image
                        } alt="" />
                        <div className="orc-detail-box-user">
                          <h4>{suggest.page.name}</h4>
                          <h6>@{suggest.page.username}</h6>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="hcb-view-two">
                    <div className="cbcc-img-holder">
                      <img src={
                          suggest.page.featured_image === null ? item
                        : suggest.page.featured_image
                      } alt=""
                      />
                    </div>
                    <div className="cbcc-item-detail-one">
                      <h4>Vegan foods </h4>
                      <h5>Price</h5>
                      <p>$20</p>
                    </div>
                  </div>

                  <div className="card-view-three">
                    <div className="cbcc-item-detail-two">
                      <h4 className="date-posted">Date posted</h4>
                      <h5>{moment(suggest.page.created_at).format("MMMM Do YYYY")}</h5>
                      <h4 className="time">Time</h4>
                      <h5>{moment(suggest.page.created_at).format('HH:mm:ss A')}</h5>
                    </div>
                  </div>

                  <div className="hcb-view-five border-green">
                    <div className="cbcc-item-detail-two">
                      <h4>Delivery Address</h4>
                      <h5>No. 29 Thames avenue New york city</h5>
                      <h4>Delivery Mode </h4>
                      <h5>Door delivery</h5>
                      <h4>Delivery on</h4>
                      <h5>Door 12 May 2021 10:10am</h5>
                    </div>
                  </div>

                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  </div>
  )
}

export default TransactionCards;
