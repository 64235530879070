import React from "react";
import FormSignIn from './formComponents/FormSignIn';
import './SignIn.scss';
import Navbar from '../../components/layout/navbar';



function SignIn() {
  return (
    <div className="signInContainer">
    <div id="roundedCircleTop"></div>
       
<Navbar/>
<FormSignIn/>

    </div>
  );
}

export default SignIn;
