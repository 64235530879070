import React, { Component } from "react";
import { Col } from "reactstrap";

import NavBar from "../components/layout/navbar";
import logo from "../assets/icons/logo.png";

import { colors } from "../color";
export default class HelpPage extends Component {
  render() {
    return (
      <React.Fragment>
        <Col className="m-auto" style={{ width: "95%" }}>
          <NavBar
            signInColor="black"
            isSignIn
            isRegister
            source={logo}
            color={colors.BLACK}
            backgroundColor={colors.COMPANY_COLOR}
          />
          <h5>Help!!!!!!!!!!!!!!!!!!!!!!! </h5>
        </Col>
      </React.Fragment>
    );
  }
}
