import React from "react";
import Styled from "styled-components";
import { colors } from "../../../color";
import { Col, Row } from "reactstrap";
import clothes from "../../../assets/icons/happy-people.png";
import CustomCard from "./constants";
import { ExpandMore } from "@material-ui/icons";
import {
  Dropdown,
  DropdownToggle,
} from "reactstrap";

export const Sort = Styled.p`
  color: ${colors.COMPANY_COLOR};
  font-weight: 700;
  font-size: 14px;
`;
export const DropDownBtn = Styled(Dropdown)`
  width: 200px;
  border-radius: 7px;
  height: 41px;
  background: ${colors.WHITE};
  border: none;
`;
export const dummyData = [
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "$500",
    type: "product",
    location: "Jamaica",
    picture: clothes,
    discount: "20% discount",
  },
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "$500",
    type: "product",
    location: "Jamaica",
    picture: clothes,
    discount: "50% discount",
  },
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "$500",
    type: "product",
    location: "Jamaica",
    picture: clothes,
  },
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "$500",
    type: "service",
    location: "Jamaica",
    picture: clothes,
  },
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "$500",
    type: "service",
    location: "Jamaica",
    picture: clothes,
  },
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "$500",
    type: "product",
    location: "Jamaica",
    picture: clothes,
    discount: "20% discount",
  },
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "Full time",
    type: "job",
    location: "Jamaica",
    picture: clothes,
  },
  {
    description: "Corporate shirts for men",
    seller: "Walter James",
    price: "Full time",
    type: "job",
    location: "Jamaica",
    picture: clothes,
  },
];

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col>
        <Col xl={5}>
          <div className="d-flex justify-content-between align-items-center">
            <Sort>Sort</Sort>
            <DropDownBtn>
              <DropdownToggle
                style={{ width: "100%", background: "transparent", height: '100%' }}
                className="d-flex justify-content-between align-items-center"
              >
                <p style={{ color: `${colors.COMPANY_COLOR}`, margin: 'auto' }}>
                  Price Low-High
                </p>{" "}
                <ExpandMore style={{ color: `${colors.ORANGE}` }} />
              </DropdownToggle>
            </DropDownBtn>
          </div>
        </Col>
        <Row>
          {dummyData.map((data, i) =>
            data.type === "product" ? (
              <Col className="mt-4" xl={4} key={i}>
                <CustomCard
                  picture={data.picture}
                  seller={data.seller}
                  type={data.type}
                  discount={data.discount}
                  price={data.price}
                  description={data.description}
                  location={data.location}
                />
              </Col>
            ) : null
          )}
        </Row>
      </Col>
    );
  }
}

export default Products;
