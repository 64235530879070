import React, {useEffect, createContext, useState, useContext  } from "react";
import ModalContext from '../../../../../hooks/modalContexts'
import './index.scss'
import profilePic from '../../../../../assets/img/connectProfilePic.png'
import { Heart } from '../../../../../assets/svg/PostCardIcon'
import MsgIcon from '../../../../../assets/svg/msgIcon'
import Phone from '../../../../../assets/svg/phone'
import { FaTimes, FaHeart} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all"
import ExploreContext  from '../../../../../hooks/exploreContext'
import useConnect from "../../../../../hooks/connectHooks"

const ConnectProfileModal = () => {
    const {
      acceptModal, 
      handleAcceptModal, 
      handleSuccessConnectModal, 
      handleDeclineConnectModal
    } = useContext(ModalContext)

    const {
      acceptModalData,
      setAcceptModalData,
      acceptedCard,
      setAcceptedCard,
      declinedCard,
      setDeclinedCard,
      acceptSuccessModal, 
      setAcceptSuccessModal,
      acceptColor,
      setAcceptColor,
    } = useContext(ExploreContext)

    const {
        handleRespondConnectRequest,
        respondConnectRequestData,
        singleProfileData,
        handleGetConnectRequestConnectedTrue,
        handleGetConnectRequestReceivedTrue
      } = useConnect();


      const initialAcceptConnectState = {
          status:"Accept"
      }

      const initialDeclineConnectState = {
          status:"Decline"
      }

      const [respondConnect, setRespondConnect] = useState(initialAcceptConnectState)
      const [declineConnect, setDeclinedConnect] = useState(initialDeclineConnectState)

      const requestAccept = () => {
          handleRespondConnectRequest(acceptModalData?.id, respondConnect)

          // Next action UseEffect from connectectHook 
      };

      const requestDecline = () => {
          handleRespondConnectRequest(acceptModalData?.id, declineConnect);
          console.log("=====DECLINED========");
      };


    console.log(acceptModalData?.status)
    console.log(acceptModalData?.id)

if(!acceptModalData) return <div>Loading</div>
    return(
      <div
        onClick={()=>{handleAcceptModal('RESET_ACCEPT_TO_DISPLAY')}}
      className="acceptModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="acceptModalContainer"
       >
          <div
            onClick={()=>{handleAcceptModal('RESET_ACCEPT_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
            <div className="profileArea">
                <div className="connectProfile">
                    <img
                      src={acceptModalData?.page?.featured_image ? acceptModalData?.page?.featured_image : profilePic 
                      } alt=""
                      />
                    <div className="name">
                        <h4>
                            {acceptModalData?.page?.name}
                        </h4>
                        <p>
                            @{acceptModalData?.page?.username}
                        </p>
                    </div>
                    <div className="icons">
                        <FaHeart className={
                          acceptModalData?.connect_profile?.page?.is_like === true
                          ? 'heart favey'
                          : 'heart'
                        } />
                    </div>
                </div>
                <div className="description">
                    <h4 className="owner">
                        {acceptModalData?.connect_profile?.title}
                    </h4>
                    <p className='address'>
                        {acceptModalData?.page?.location?.address}
                    </p>
                    <p>
                        {acceptModalData?.page?.description}
                    </p>
                </div>
                <div className="contact">
                    <p>
                        <MsgIcon />
                        <span>
                            {acceptModalData?.page?.email}
                        </span>
                    </p>
                    <p>
                        <Phone />
                        <span>
                        {acceptModalData?.page?.phone_number}
                        </span>
                    </p>
                </div>
                <div className="locations">
                    <h4>
                        Service Locations:
                    </h4>
                    <ul className="service">
                      {acceptModalData?.connect_profile?.service_locations.map((location) => (
                      <li>
                        {location.address}
                      </li>
                    ))
                      }
                    </ul>
                    </div>
                </div>
                <div className="form">
                <div className="submittedNote">
                    <p>
                        Note
                    </p>
                    <div className="describe-form">
                      <div className="text-content">
                        {acceptModalData?.note}
                      </div>
                    </div>
                </div>
                <div className="permissions">
                      <button className="connect-accept"
                        onClick={() => {
                          requestAccept();
                        }}
                        >
                            Accept
                      </button>
                      <button className="connect-decline"
                        onClick={() => {
                          requestDecline();
                        }}
                        >
                            Decline
                      </button>
                </div>
              </div>
          </div>
      </div>
    )
}
export default ConnectProfileModal
