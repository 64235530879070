import { useEffect } from "react";
import useActivity from "../../../../hooks/activity";
import useBusinessSellerDashboard from "../../../../hooks/businessSellerDashboard";
import Loader from "../../../loader";
import BookingReqItem from "./bookingReqItem";
import RequestedItem from "./requestItem";

const Bookings = ({ id }) => {
  const { handleGetBookingRequests, bookingRequestsData } =
    useBusinessSellerDashboard();
  useEffect(() => {
    handleGetBookingRequests(id);
  }, []);
  const { handleUpdateBooking, updateBookingStatusData } = useActivity();
  useEffect(() => {
    updateBookingStatusData?.data && handleGetBookingRequests(id);
  }, [updateBookingStatusData]);
  return (
    <div className="bookings">
      <h6 className="orcb-title"> Bookings </h6>
      {bookingRequestsData?.data?.results.length === 0 && (
        <h5>You have no Booking requests</h5>
      )}
      {bookingRequestsData?.loading && <Loader />}
      {bookingRequestsData?.data?.results?.map((data, id) => (
        <BookingReqItem data={data} handleUpdateBooking={handleUpdateBooking} />
      ))}
    </div>
  );
};
export default Bookings;
