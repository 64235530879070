import { useState, useEffect } from "react";
import useDeviceType from "../../hooks/deviceType";
import FeedContainer from "../../components/FeedContainer";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import { useParams } from "react-router-dom";
import greeting from "../../utils/greeting";
import q from "query-string";
import usePages from "../../hooks/createPage";
import BsEditListing from "../../components/BusinessSeller/Listings/editListingBody";
const BusinessSellerEditListing = () => {
  const { pageId, productId } = useParams();
  const { handleGetSinglePage, singlePageData } = usePages();
  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    handleGetSinglePage(pageId);
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  return (
    <FeedContainer>
      {singlePageData.data && (
        <div className="bs-createlistings-container">
          <div className="lbc-header">
            <h1 className="welcome-title">
              {greeting()}! <span>{singlePageData.data.name} </span>
            </h1>
          </div>
          <div className="lbc-card">
            <h5 className="sub-title"> Edit Listing</h5>
            <BsEditListing id={pageId} productId={productId} />
          </div>
        </div>
      )}
    </FeedContainer>
  );
};

export default BusinessSellerEditListing;
