import { useState, useContext } from "react";
import JobHistoryData, { Options } from './JobHistoryData'
import { Link } from "react-router-dom";
import profileImg from "../../assets/img/Ellipse 320.PNG";
import itemImg from "../../assets/img/Rectangle 1890.PNG";
import "./index.scss";
import Select from 'react-select';
import DropdownIndicator from '../cards/jobCards/FontAwesomeIndicator'
import CustomCheckBox from "./CheckBox";
import ModalContext from "../../hooks/modalContexts"
import ExploreContext from "../../hooks/exploreContext";
import {
  FaChevronRight,
  FaChevronLeft,
  FaAngleDown,
  FaSortAmountDownAlt
} from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { toSentenceCaseUnderscore, truncateStringEllipsis } from "../../utils/helpers";
import moment from "moment";

const JobHistoryBody = () => {
  const { handleJobItemModal } = useContext(ModalContext);

  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  // const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };

  const {myJobPosts} = useContext(ExploreContext); 

  return (
    <div className="job_History_container">
      <div className="bkr-top-header">
        <div className="filter">
          <p>Filter</p>
          <Select
              components={{
                IndicatorSeparator: ()=> null,
                DropdownIndicator
              }}
              options={Options}
              className="react-select-container"
              classNamePrefix="react-select"
              placeholder={"All"}
              />
        </div>
        <div className="sort">
          <p>Sort by</p>
          <div className="input">
            Earliest to Latest<FaSortAmountDownAlt color="var(--bg-orange)" />
          </div>
        </div>
      </div>
      {
        myJobPosts?.data?.results?.length > 0 && 
        myJobPosts.data.results.map((job, index) => (
          <div className="bkr-cbc-card" key={index}>
            <div className="bkr-cbcc-body">
              <div className="item__detail-box">
                <div className="cb-1">
                    <label className="check-label" htmlFor="001">
                    <CustomCheckBox
                      style={{
                        minWidth: 18,
                        height: 18,
                        borderColor: "var(--text-grey)",

                        }}
                      />
                    {job.list}
                  </label>
                </div>
                <div className="orc-detail-box">
                  <img src={job?.gallary[0]?.file} alt="" />
                  <div className="orc-detail-box-user">
                    <h4>{job?.page?.name}</h4>
                    <h6>@{job?.page?.username}</h6>
                  </div>
                </div>
              </div>
              <div className="cbcc-item__box">
                <div className="item-detail-one">
                  <h4>{truncateStringEllipsis(job?.title, 25)}</h4>
                </div>
                <div className="item-detail-two">
                  <h4>
                    Job Type:<span> {toSentenceCaseUnderscore(job?.type)}</span>
                </h4>
                  <h4>
                    Job Salary: <span>${job?.min_salary} - ${job?.max_salary} {toSentenceCaseUnderscore(job?.payment_interval)}</span>
                </h4>
                </div>
                <div className="item-detail-three">
                    <h5 className="deadline">
                      Posted {moment(job?.created_on).format("LL")} <p>Ends {moment(job?.end_on).fromNow()}</p>
                    </h5>
                </div>
              </div>
              <div className="item-btn-box"
                >
                <button className="item-link"
                  onClick={() =>{
                    handleJobItemModal('ADD_JOB_TO_DISPLAY', job)
                  }}
                  >
                      View Full
                </button>
              </div>
            </div>
          </div>
        ))
      }

      <div className="bkr-footer">
        <div className="paginate-container">
        </div>{" "}
      </div>
    </div>
  );
};

export default JobHistoryBody;
