import "./style.scss";
import { IoMdClose } from "react-icons/all";
const CloseBtn = ({ onClick = () => {}, style }) => {
  return (
    <div
      className="cancel"
      onClick={(e) => onClick()}
      style={{
        ...style,
        cursor: "pointer",
      }}
    >
      <IoMdClose />
    </div>
  );
};

export default CloseBtn;
