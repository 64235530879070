import toast, { Toaster } from "react-hot-toast";

export const showToast = (type, message) => {
  const successToast = () => {
    toast.success(message, {
      style: {
        border: "1px solid #56b39d",
        padding: "16px",
        background: "#43AB62",
        color: "#fff",
        borderRadius: "10px",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#43AB62" + "",
      },
    });
  };

  const errorToast = () => {
    toast.error(message, {
      style: {
        border: "1px solid #DC2626",
        padding: "16px",
        background: "#DC2626",
        color: "#fff",
        borderRadius: "10px",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#DC2626",
      },
    });
  };

  const warningToast = () => {
    toast.error(message, {
      style: {
        border: "1px solid #FFCC00",
        padding: "16px",
        background: "#FFCC00",
        color: "#000",
        borderRadius: "10px",
      },
      iconTheme: {
        primary: "#000",
        secondary: "#FFCC00",
      },
    });
  };

  const infoToast = () => {
    toast.success(message, {
      style: {
        border: "1px solid #FFCC00",
        padding: "16px",
        background: "#066CBA",
        color: "#FFFAEE",
        borderRadius: "10px",
      },
      iconTheme: {
        primary: "#FFFAEE",
        secondary: "#066CBA",
      },
    });
  };

  switch (type) {
    case "success":
      return successToast();
    case "error":
      return errorToast();
    case "warning":
      return warningToast();
    case "info":
      return infoToast();
    default:
      return infoToast();
  }
};
