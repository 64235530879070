import { Search as Icon } from "../../assets/svg/NavBarIcons";
const Search = ({ iconWidth, color }) => {
  return (
    <div className="search">
      <div className="search__icon">
        {/* search icon */}
        <Icon width={iconWidth} fill={color} />
      </div>
      <input type="search" placeholder="Search" />
    </div>
  );
};
export default Search;
