import RequestedItem from "./requestItem";
import usePrivateSellerDashboard from "../../../../hooks/privateSellerDashboard";
import { useEffect } from "react";
import useBusinessSellerDashboard from "../../../../hooks/businessSellerDashboard";
import Loader from "../../../loader";

const Listings = ({ id }) => {
  const { handleUpdateOrderStatus, updateOrderStatusData } =
    usePrivateSellerDashboard();
  const { listingData, handleGetListingData } = useBusinessSellerDashboard();

  useEffect(() => {
    handleGetListingData({ id: id });
  }, []);
  useEffect(() => {
    updateOrderStatusData?.data && handleGetListingData({ id: id });
  }, [updateOrderStatusData?.data]);
  return (
    <div className="listings">
      <h6 className="orcb-title"> Listings </h6>
      {listingData?.data?.results.length === 0 && (
        <h5>You have no listing requests</h5>
      )}
      {listingData?.loading && <Loader />}
      {listingData?.data?.results?.map((data, idx) => (
        <RequestedItem
          data={data}
          handleUpdateOrderStatus={handleUpdateOrderStatus}
        />
      ))}
    </div>
  );
};
export default Listings;
