import { useState, useEffect, useContext } from "react";
import "./index.scss";
import ReactPaginate from "react-paginate";
import { Link, useLocation } from "react-router-dom";
import { FaChevronRight, FaChevronLeft, FaBell } from "react-icons/fa";
import profileImg from "../../assets/img/Ellipse 320.PNG";
import itemImg from "../../assets/img/Rectangle 1890.PNG";
import NotificationContainer from "../NotificationContainer/notification";
import useBookingRequest from "./useBookingRequest";
// import ReactMomentCountDown from "react-moment-countdown";
import moment from "moment";
import qs from "query-string";
import useBooking from "./useBooking";

const BookingBody = () => {
  const testArray = [1, 2, 3, 4];
  const {
    handleGetBookings,
    bookingsData,
    allBookings,
    acceptBookingData,
    handleAcceptBooking,
    declineBookingData,
    handleDeclineBooking,
    handleUpdateBookingAcceptStatus,
    handleUpdateBookingDeclineStatus,
  } = useBookingRequest();

  const [currentTime, setCurrentTime] = useState(moment());
  const { search } = useLocation();
  const { id } = qs.parse(search);

  useEffect(() => {
    handleGetBookings();
    console.log(bookingsData.data?.results);
  }, []);
  console.log(bookingsData.data?.results);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentTime(moment());
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, []);

  const dateInFuture = new Date("2022-8-31");

  const empty = bookingsData?.data?.results?.filter((service) => {
    return service.status === "PENDING";
  });
  const bookingCheckout = bookingsData?.data?.results?.filter((service) => {
    return service.status === "ACCEPT";
  });

  return (
    <div className="booking-body-container">
      <div className="dbc-card-body">
        <div className="dbc-container-one">
          {/* -------- NOTIFICATION CARD --------*/}
          <NotificationContainer />
        </div>
        <div className="dbc-container-two">
          <div className="mb-header">
            <h2>Manage Booking </h2>

            <div className="bk-btn-box">
              <Link to={`/createbooking/?id=${id}`} className="bk-btn">
                Booking setting
              </Link>
            </div>
          </div>
          <p>Requested</p>

          {/*--------- CARD CONTENTS ------------------*/}
          <div className="lbc-cb-contents">
            {bookingsData.loading && <p>Loading..</p>}
            {!bookingsData.loading && bookingsData.error && (
              <p>An Error Occured </p>
            )}

            {!bookingsData.loading && !bookingsData.error && (
              // <p>{apiResult.results} {console.log(apiResult.results)}</p>
              <></>
            )}

            {!bookingsData.loading &&
              !bookingsData.error &&
              empty?.slice(0, 4).map((service, idx) => (
                <>
                  {service?.status === "PENDING" && (
                    <div className="lbc-cbc-card">
                      <div className="lbc-cbcc-body">
                        <div className="orc-detail-box">
                          <img
                            src={
                              service.service.page?.featured_image || profileImg
                            }
                            alt=""
                          />
                          <div className="orc-detail-box-user">
                            <h4>{service.service.page.name}</h4>
                            <h6>@{service.service.page.username} </h6>
                          </div>
                          {/* {service.booking.service.accept_order_automatically ===
                          true && <small>Auto Accept</small>} */}
                        </div>
                        <div className="cbcc-item__box">
                          <div className="cbcc-img-holder">
                            <img
                              src={service?.service?.gallary[0] || itemImg}
                              alt=""
                            />
                          </div>
                          <div className="cbcc-item-detail-one">
                            <h4>{service.service.title}</h4>
                            <h5>Booking Date:</h5>
                            <span>
                              {moment(service.service.service_date).format(
                                "MMMM Do YYYY, h:mm"
                              )}
                            </span>
                            {/* <h5>
                              Date: <span>Aug 20, 2021</span>
                            </h5> */}
                          </div>
                        </div>
                        <div className="lbc-cbcc-footer">
                          <p>
                            {/* {service.booking.booking_expire} */}
                            {/* {moment.duration(
                            service.booking.booking_expire.diff(currentTime)
                              .hours
                          )}
                          hrs
                          {moment.duration(
                            service.booking.booking_expire.diff(currentTime)
                              .minutes
                          )} */}
                            {/* min */}
                            Expires in
                            {/* <ReactMomentCountDown toDate={dateInFuture} />
                            <ReactMomentCountDown
                              toDate={service.booking_expire}
                              sourceFormatMask="YYYY-MM-DD HH:mm:ss"
                            /> */}{" "}
                            {moment(service.booking_expire).format(
                              "YYYY-MM-DD h:mm"
                            )}
                          </p>
                          {service.status === "PENDING" && (
                            <div className="btn-box">
                              <button
                                className="btn-accept"
                                onClick={(e) => {
                                  handleUpdateBookingAcceptStatus(service.id);
                                  console.log(
                                    "accept booking",
                                    acceptBookingData
                                  );
                                }}
                              >
                                Accept{" "}
                              </button>
                              <button
                                className="btn-decline"
                                onClick={(e) => {
                                  handleUpdateBookingDeclineStatus(service.id);
                                  console.log(
                                    "decline booking",
                                    declineBookingData
                                  );
                                }}
                              >
                                Decline
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}

            {!bookingsData.loading &&
              !bookingsData.error &&
              empty?.length < 1 && <h2 className="empty-item"> No Booking Request</h2>}

            <>
              {/* ui code */}
              {/* <>
              <div className="lbc-cbc-card">
                <div className="lbc-cbcc-body">
                  <div className="orc-detail-box">
                    <img src={profileImg} alt="" />
                    <div className="orc-detail-box-user">
                      <h4>Maria Sponge</h4>
                      <h6>@username</h6>
                    </div>
                  </div>
                  <div className="cbcc-item__box">
                    <div className="cbcc-img-holder">
                      <img src={itemImg} alt="" />
                    </div>
                    <div className="cbcc-item-detail-one">
                      <h4>Veegan foods food and food.</h4>
                      <h5>Booking Date:</h5>
                      <span>August 20, 2021 10am - 12am</span>
                    </div>
                  </div>
                  <div className="lbc-cbcc-footer">
                    <p>Expires in 48hr 15mins</p>
                    <div className="btn-box">
                      <button className="btn-accept">Accept</button>
                      <button className="btn-decline">Decline</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="lbc-cbc-card">
                <div className="lbc-cbcc-body">
                  <div className="orc-detail-box">
                    <img src={profileImg} alt="" />
                    <div className="orc-detail-box-user">
                      <h4>Maria Sponge</h4>
                      <h6>@username</h6>
                    </div>
                    <small>Auto Accept</small>
                  </div>
                  <div className="cbcc-item__box">
                    <div className="cbcc-img-holder">
                      <img src={itemImg} alt="" />
                    </div>
                    <div className="cbcc-item-detail-one">
                      <h4>Veegan foods food and food.</h4>
                      <h5>Booking Date:</h5>
                      <span>August 20, 2021 10am - 12am</span>
                    </div>
                  </div>
                  <div className="lbc-cbcc-footer"></div>
                </div>
              </div>

              <div className="lbc-cbc-card">
                <div className="lbc-cbcc-body">
                  <div className="orc-detail-box">
                    <img src={profileImg} alt="" />
                    <div className="orc-detail-box-user">
                      <h4>Maria Sponge</h4>
                      <h6>@username</h6>
                    </div>
                  </div>
                  <div className="cbcc-item__box">
                    <div className="cbcc-img-holder">
                      <img src={itemImg} alt="" />
                    </div>
                    <div className="cbcc-item-detail-one">
                      <h4>Veegan foods food and food.</h4>
                      <h5>Booking Date:</h5>
                      <span>August 20, 2021 10am - 12am</span>
                    </div>
                  </div>
                  <div className="lbc-cbcc-footer">
                    <p>Expires in 48hr 15mins</p>
                    <div className="btn-box">
                      <button className="btn-accept">Accept</button>
                      <button className="btn-decline">Decline</button>
                    </div>
                  </div>
                </div>
              </div>
            </> */}
            </>

            {bookingsData?.data?.results?.some(
              (service) => service?.status === "PENDING"
            ) && (
              <div className="lbc-contents-footer">
                <Link
                  to={`/bookingrequest/?id=${id}`}
                  className="footer-view-btn"
                >
                  view all
                </Link>
              </div>
            )}
          </div>

          {/*------------ MANAGING BOOKING CONTAINER ------------------*/}

          <div className="manage-booking-container">
            {!bookingsData.loading &&
              !bookingsData.error &&
              bookingsData?.data?.results.filter(
                (service) => service?.status === "ACCEPT"
              ) && (
                <>
                  <h2> Checkout </h2>
                  <div className="mb-card">
                    <h3>Today's Delivery</h3>
                    <div className="mb-card-body">
                      {bookingCheckout
                        ?.slice(0, 4)
                        .map((service, idx) => (
                          <>
                            {service?.status === "ACCEPT" && (
                              <div className="mbc-item-box">
                                <div className="orc-detail-box">
                                  {/* <img src={profileImg} alt="" />
                                  <div className="orc-detail-box-user">
                                    <h4>Maria Sponge</h4>
                                    <h6>@username</h6>
                                    <p>Aug 20, 2021 10am-12am </p>
                                  </div> */}
                                  <div className="cbcc-item__box">
                                    <div className="cbcc-img-holder">
                                      <img
                                        src={
                                          service.service.page
                                            ?.featured_image || itemImg
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="cbcc-item-detail-one">
                                      <h4>{service?.service?.title}</h4>
                                      <h5>Booking Date:</h5>
                                      <p>
                                        {moment(
                                          service?.service?.booking_date
                                        ).format("MMMM Do YYYY, h:mm")}
                                      </p>
                                    </div>
                                  </div>
                                  <div className="btn-box">
                                    <div className="active">
                                      <span></span> Active
                                    </div>
                                    <button className="btn-checkout">
                                      Checkout
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        ))}
                    </div>
                  </div>

                  <div className="lbc-contents-footer">
                    <Link
                      to={`/bookingrequest/?id=${id}`}
                      className="footer-view-btn"
                    >
                      {" "}
                      view all
                    </Link>
                  </div>
                </>
              )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingBody;
