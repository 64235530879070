import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import "./index.scss";
import {
  FaSearch,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { Refferal } from "../../../../assets/svg/FeedOptionsIcon";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { FaSortAmountDownAlt, FaCalendarAlt } from "react-icons/fa";
import CustomDropdown from "../../../../components/customDropdown/customDropdown";
import CustomCheckBox from "../../../../components/customCheckBox";
import FeedContainer from '../../../../components/FeedContainer'
import { toggleRightBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../../../utils/greeting";
import TransactionCards from "./HistoryCards"

const TransactionHistory = () => {
  const data = [
    { label: "All", icon: "." },
    { label: "Active", icon: "." },
    { label: "Completed", icon: "." },
    { label: "Uncompleted", icon: "." },
    { label: "Cancelled", icon: "." },
  ];
  /* datepicker */
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch()
  const history = useHistory();
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  /* paginate */
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };

  useEffect(()=>{
      dispatch(toggleRightBar(false))
  },[])


  return (
    <FeedContainer>
    <div className="transactions">
      <h1 className='transactions-greeting'>
        {greeting()} <span className="user">Jonathan</span>
      </h1>
      <h2 className='transactions-hist'>Transaction History</h2>
      <div className="transaction-body-container">

        <div className="hbc-card-body">

          {/*----------- HISTORY HEADER ------------------*/}

          <div className="hbc-cb-header">
            <div className="gh-card">
              <div className="gh-cardBody">
                <div className="status-box">
                  <p className="gh-cb-Title">Show status</p>
                  <div className="dropbox">
                    <CustomDropdown items={data} />
                  </div>
                </div>

                <div className="date-search-box">
                  <p className="gh-cb-Title">Show Date</p>
                  {/* <p className="gh-cb-SubTitle">from</p> */}

                  <div className="datepicker-container">
                    <DatePicker
                      className="custom-date"
                      // onChange={(update) => {
                      //   setDateRange(update);
                      // }}
                      onChange={onChange}
                      selectsRange
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MMM d, yyyy"
                      // placeholderText="select date"
                    />

                    <FaChevronDown className="dropdown-icon" />
                  </div>
                  <p className="gh-cb-Title">To</p>
                  {/* <p className="gh-cb-SubTitle">from</p> */}

                  <div className="datepicker-container">
                    <DatePicker
                      className="custom-date"
                      // onChange={(update) => {
                      //   setDateRange(update);
                      // }}
                      onChange={onChange}
                      selectsRange
                      selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      dateFormat="MMM d, yyyy"
                      // placeholderText="select date"
                    />
                    <FaChevronDown className="dropdown-icon" />
                  </div>
                  <div className="sort-box">
                    <p>Sort By</p>
                    <div className="sort-input">
                      <span> Earliest to Latest </span>
                      <FaSortAmountDownAlt color="var(--bg-orange)" />
                    </div>
                  </div>
                  <div className="l-searchBox">
                    <span className="">
                      <FaSearch className="search-icon" />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
            <TransactionCards />
          {/* <div className="paginate-container">
            <ReactPaginate
              previousLabel={<FaChevronLeft />}
              nextLabel={<FaChevronRight />}
              pageCount={pageCount}
              pageRange={2}
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              onPageChange={handlePageChange}
              containerClassname={"pg-holder"}
              previousLinkClassName={"page"}
              breakClassName={"pg-page"}
              nextLinkClassName={"pg-page"}
              pageClassName={"pg-page"}
              disabledClassName={"disabled"}
              activeClassName={"active"}
            />
          </div> */}
        </div>
      </div>
      <Link
          onClick={history.goBack}
           className='back-btn'
              >
          <h5>Back</h5>
      </Link>
    </div>
  </FeedContainer>
  );
};

export default TransactionHistory;
