import {
  STORE_FRESH_POST_DATA,
  DELETE_POST,
  UPDATE_PREV,
  UPDATE_NEXT,
  NEW_POST_LOADING,
  UPDATE_LOADING,
} from "./index.types";

export const storeNewPost = (payload) => ({
  type: STORE_FRESH_POST_DATA,
  payload,
});

export const deleteSavedPost = (payload) => ({
  type: DELETE_POST,
  payload,
});

export const updatePrev = (payload) => ({
  type: UPDATE_PREV,
  payload,
});

export const updateNext = (payload) => ({
  type: UPDATE_NEXT,
  payload,
});

export const updatePostLoading = (payload) => ({
  type: UPDATE_LOADING,
  payload,
});

export const newPostLoading = (payload) => ({
  type: NEW_POST_LOADING,
  payload,
});

//the rest of the actions will automatically kick in because exactly the same types were used inposts
//ie: liking and unliking posts, saving and unsaving posts
