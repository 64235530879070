import SuggestedListings from "../SuggestedListings";
import SuggestedUsers from "../SuggestedUsers";
import "./rightFeed.scss";
import RightbarSuggestedListing from "../SuggestedListings/suggestedListing";

const RightFeed = ({ viewContents }) => {
  return (
    <div className="right">
      {viewContents.suggestedListings && <RightbarSuggestedListing />}
      {/* {<SuggestedListings />} */}
      {viewContents.suggestedUsers && <SuggestedUsers />}
    </div>
  );
};

export default RightFeed;
