import FeedContainer from "../../components/FeedContainer";
import ReusableDropDown from "../../components/reusableDropdown";
import DatePicker from "react-datepicker";
import "./saved.scss";
import { useState } from "react";
import moment from "moment";
import { FaSearch } from "react-icons/fa";
import Social from "./social";
import classNames from "classnames";
import AllListing from "./allListing/allListing";


const Saved = () => {
  const options = ["all", "products", "jobs", "service"];
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [currentPage, setCurrentPage] = useState(0);
  return (
    <FeedContainer>
      <div className="saved">
        <div className="saved-header">
          <h1>Saved</h1>
          <div className="saved-nav">
            {!!currentPage && (
              <div className="all-list">
                <p>Show</p>
                <ReusableDropDown options={options} />
              </div>
            )}
            <div className="date">
              <p>Show Date</p>
              <DatePicker
                selected={dateRange.startDate}
                selectsRange={true}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                onChange={(dates) =>
                  setDateRange({
                    startDate: dates[0],
                    endDate: dates[1],
                  })
                }
                customInput={
                  <div className="custom-date-box">
                    {moment(dateRange.startDate).format("MMM Do yyyy")} -{" "}
                    {moment(dateRange.endDate || dateRange.startDate).format(
                      "MMM Do yyyy"
                    )}
                  </div>
                }
              />
            </div>
            <div className="saved-search">
              <FaSearch />
            </div>
          </div>
        </div>
        <div className="saved-tab">
          <div
            className={classNames("social", { active: !currentPage })}
            onClick={(e) => setCurrentPage(0)}>
            Social
          </div>
          <div
            className={classNames("business", { active: currentPage })}
            onClick={(e) => setCurrentPage(1)}>
            Business
          </div>
        </div>
        {!!!currentPage && <Social />}
        {currentPage === 1 && <AllListing />}
      </div>
    </FeedContainer>
  );
};

export default Saved;
