import React, {  useState, useContext  } from "react";
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import "./index.scss";
import {
  FaSearch,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../assets/img/shopImg.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { FaSortAmountDownAlt, FaCalendarAlt } from "react-icons/fa";
import CustomDropdown from "../../../components/customDropdown/customDropdown";
import CustomCheckBox from "../../../components/customCheckBox";
import DropMenu from "./DropMenu"

const PaymentCards = () => {
  const [endDate, setEndDate] = useState(null);

  const dispatch = useDispatch()
  const history = useHistory();

  /* paginate */
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };

  return (
      <div className="transaction-body-container">
        <div className="hbc-card-body">
          <div className="payment-header">
            <h3>Payment History</h3>
            <div className="date">
              <p>July 22 - July 23 </p>
            </div>
          </div>
          {/*--------- CARD CONTENTS ------------------*/}
          <div className="card-cb-contents">
            <div className="hbc-cbc-card"
              >
              <div className="hbc-cbcc-body">
                <div className="hcb-view-one">
                  <div className="orc-detail-box">
                    <div className="orc-db">
                      <img src={profileImg} alt="" />
                      <div className="orc-detail-box-user">
                        <h4>Maria Sponge</h4>
                        <h6>@username</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="hcb-view-two">
                  <div className="cbcc-img-holder">
                    <img src={itemImg} alt="" />
                  </div>
                  <div className="cbcc-item-detail-one">
                    <h4>Barbing and Shaving </h4>
                    <h5>Price</h5>
                    <p>$20</p>
                  </div>
                </div>
                <div className="card-view-three">
                  <div className="cbcc-item-detail-two">
                    <h4 className="date-posted">Date Posted</h4>
                    <h5>25th Jul, 2020</h5>
                    <h4 className="time">Time</h4>
                    <h5>10am-12:pm</h5>
                  </div>
                </div>
                <div className="hcb-view-five border-green">
                  <div className="cbcc-item-detail-two">
                    <h4>Delivery Address</h4>
                    <h5>No. 29 Thames avenue New york city</h5>
                    <h4>Delivery Mode </h4>
                    <h5>Door delivery</h5>
                    <h4>Delivery on</h4>
                    <h5>12 May 2021 10:10am</h5>
                  </div>
                  <button className="view-button">View Receipt</button>
                </div>
                <DropMenu  className="dropmenu"/>
              </div>
            </div>
            {/*--------- CARD CONTENTS ------------------*/}

            <div className="hbc-cbc-card"

              >
              <div className="hbc-cbcc-body">
                <div className="hcb-view-one">
                  <div className="orc-detail-box">
                    <div className="orc-db">
                      <img src={profileImg} alt="" />
                      <div className="orc-detail-box-user">
                        <h4>Maria Sponge</h4>
                        <h6>@username</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hcb-view-two">
                  <div className="cbcc-img-holder">
                    <img src={itemImg} alt="" />
                  </div>
                  <div className="cbcc-item-detail-one">
                    <h4>Barbing and Shaving </h4>
                    <h5>Price</h5>
                    <p>$20</p>
                  </div>
                </div>

                <div className="card-view-three">
                  <div className="cbcc-item-detail-two">
                    <h4 className="date-posted">Date Posted</h4>
                    <h5>25th Jul, 2020</h5>
                    <h4 className="time">Time</h4>
                    <h5>10am-12:pm</h5>
                  </div>
                </div>

                <div className="hcb-view-five border-yellow">
                  <div className="cbcc-item-detail-two">
                    <h4>Delivery Address</h4>
                    <h5>No. 29 Thames avenue New york city</h5>
                    <h4>Delivery Mode </h4>
                    <h5>Door delivery</h5>
                    <h4>Delivery on</h4>
                    <h5> 12 May 2021 10:10am</h5>
                  </div>
                  <button className="view-button">View Receipt</button>
                </div>
                <DropMenu  className="dropmenu"/>
              </div>
            </div>
            {/*--------- CARD CONTENTS ------------------*/}

            <div className="hbc-cbc-card"
              >
              <div className="hbc-cbcc-body">
                <div className="hcb-view-one">
                  <div className="orc-detail-box">
                    <div className="orc-db">
                      <img src={profileImg} alt="" />
                      <div className="orc-detail-box-user">
                        <h4>Maria Sponge</h4>
                        <h6>@username</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hcb-view-two">
                  <div className="cbcc-img-holder">
                    <img src={itemImg} alt="" />
                  </div>
                  <div className="cbcc-item-detail-one">
                    <h4>Barbing and Shaving  </h4>
                    <h5>Price</h5>
                    <p>$20</p>
                  </div>
                </div>

                <div className="card-view-three">
                  <div className="cbcc-item-detail-two">
                    <h4 className="date-posted">Date Posted</h4>
                    <h5>25th Jul, 2020</h5>
                    <h4 className="time">Time</h4>
                    <h5>10am-12:pm</h5>
                  </div>
                </div>

                <div className="hcb-view-five border-red">
                  <div className="cbcc-item-detail-two">
                    <h4>Delivery Address</h4>
                    <h5>No. 29 Thames avenue New york city</h5>
                    <h4>Delivery Mode </h4>
                    <h5>Door delivery</h5>
                    <h4>Delivery on</h4>
                    <h5>12 May 2021 10:10am</h5>
                  </div>
                    <button className="view-button">View Receipt</button>
                </div>
                <DropMenu  className="dropmenu"/>
              </div>
            </div>
            {/*--------- CARD CONTENTS ------------------*/}

            <div className="hbc-cbc-card"
              >
              <div className="hbc-cbcc-body">
                <div className="hcb-view-one">
                  <div className="orc-detail-box">
                    <div className="orc-db">
                      <img src={profileImg} alt="" />
                      <div className="orc-detail-box-user">
                        <h4>Maria Sponge</h4>
                        <h6>@username</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hcb-view-two">
                  <div className="cbcc-img-holder">
                    <img src={itemImg} alt="" />
                  </div>
                  <div className="cbcc-item-detail-one">
                    <h4>Barbing and Shaving  </h4>
                    <h5>Price</h5>
                    <p>$20</p>
                  </div>
                </div>

                <div className="card-view-three">
                  <div className="cbcc-item-detail-two">
                    <h4 className="date-posted">Date Posted</h4>
                    <h5>25th Jul, 2020</h5>
                    <h4 className="time">Time</h4>
                    <h5>10am-12:pm</h5>
                  </div>
                </div>

                <div className="hcb-view-five border-red">
                  <div className="cbcc-item-detail-two">
                    <h4>Delivery Address</h4>
                    <h5>No. 29 Thames avenue New york city</h5>
                    <h4>Delivery Mode </h4>
                    <h5>Door delivery</h5>
                    <h4>Delivery on</h4>
                    <h5>12 May 2021 10:10am</h5>
                  </div>
                    <button className="view-button">View Receipt</button>
                </div>
                <DropMenu  className="dropmenu"/>
              </div>
            </div>

            {/*--------- CARD CONTENTS ------------------*/}

            <div className="hbc-cbc-card"

              >
              <div className="hbc-cbcc-body">
                <div className="hcb-view-one">
                  <div className="orc-detail-box">
                    <div className="orc-db">
                      <img src={profileImg} alt="" />
                      <div className="orc-detail-box-user">
                        <h4>Maria Sponge</h4>
                        <h6>@username</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hcb-view-two">
                  <div className="cbcc-img-holder">
                    <img src={itemImg} alt="" />
                  </div>
                  <div className="cbcc-item-detail-one">
                    <h4>Barbing and Shaving  </h4>
                    <h5>Price</h5>
                    <p>$20</p>
                  </div>
                </div>

                <div className="card-view-three">
                  <div className="cbcc-item-detail-two">
                    <h4 className="date-posted">Date Posted</h4>
                    <h5>25th Jul, 2020</h5>
                    <h4 className="time">Time</h4>
                    <h5>10am-12:pm</h5>
                  </div>
                </div>

                <div className="hcb-view-five border-red">
                  <div className="cbcc-item-detail-two">
                    <h4>Delivery Address</h4>
                    <h5>No. 29 Thames avenue New york city</h5>
                    <h4>Delivery Mode </h4>
                    <h5>Door delivery</h5>
                    <h4>Delivery on</h4>
                    <h5>12 May 2021 10:10am</h5>
                  </div>
                    <button className="view-button">View Receipt</button>
                </div>
                <DropMenu  className="dropmenu"/>
              </div>
            </div>

            {/*--------- CARD CONTENTS ------------------*/}

            <div className="hbc-cbc-card"
              >
              <div className="hbc-cbcc-body">
                <div className="hcb-view-one">
                  <div className="orc-detail-box">
                    <div className="orc-db">
                      <img src={profileImg} alt="" />
                      <div className="orc-detail-box-user">
                        <h4>Maria Sponge</h4>
                        <h6>@username</h6>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="hcb-view-two">
                  <div className="cbcc-img-holder">
                    <img src={itemImg} alt="" />
                  </div>
                  <div className="cbcc-item-detail-one">
                    <h4>Barbing and Shaving </h4>
                    <h5>Price</h5>
                    <p>$20</p>
                  </div>
                </div>

                <div className="card-view-three">
                  <div className="cbcc-item-detail-two">
                    <h4 className="date-posted">Date Posted</h4>
                    <h5>25th Jul, 2020</h5>
                    <h4 className="time">Time</h4>
                    <h5>10am-12:pm</h5>
                  </div>
                </div>

                <div className="hcb-view-five border-black">
                  <div className="cbcc-item-detail-two">
                    <h4>Delivery Address</h4>
                    <h5>No. 29 Thames avenue New york city</h5>
                    <h4>Delivery Mode </h4>
                    <h5>Door delivery</h5>
                    <h4>Delivery on</h4>
                    <h5>12 May 2021 10:10am</h5>
                  </div>
                  <button className="view-button">View Receipt</button>
                </div>
                <DropMenu  className="dropmenu"/>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default PaymentCards;
