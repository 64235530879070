import React, { useState, useEffect } from "react";
import "./index.scss";
import TopCart from "./TopCart"
import MiddleCart from "./MiddleCart"
import BottomCart from "./BottomCart"
import SummaryContent from "./Order"

const CartPage = () => {


  return (
    <div className="cart-container">
        <p className="cart-header-text">Your Cart</p>
      <div className="cart-history">
          <div className="cart-arrangement">
            <TopCart />
            <MiddleCart />
            {/* <BottomCart /> */}
            <div className="back-btn">
              <h5>Back</h5>
            </div>
          </div>
          <div className="order-summary-container">
            <SummaryContent />
          </div>
      </div>

    </div>
  );
}

export default CartPage;
