import { useContext, useState } from 'react'
import ModalContext from '../../../../hooks/modalContexts'
import './index.scss'
import { FaTimes,} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all";
import SuccessCheck from "../../../../assets/svg/successThick"

const WalletModal = () => {
    const {handleWalletModal, walletModal} = useContext(ModalContext)

    return(
      <div
      onClick={()=>{handleWalletModal('RESET_WALLET_TO_DISPLAY')}}
      className="walletModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="walletModalContainer"
       >
          <div
          onClick={()=>{handleWalletModal('RESET_WALLET_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
          <div className="enter-wallet">
            <SuccessCheck/>
          </div>
          <div className="success-text">
            Withdrawal Successful
          </div>
            <div className='success-save-button'>
              <button>OK</button>
            </div>
          </div>
      </div>
    )
}
export default WalletModal
