import { useState } from "react";
import { useSelector } from "react-redux";
import useSettings from "../../../../../hooks/settings";
import BusinessPageDisplay from "./businessPageDisplay";
import BusinessPageNotification from "./businessPageNotification";
import BusinessPagePage from "./businessPagePage";
const BusinessPage = ({ businessCount, activeState, setActiveState }) => {
  const currentUserData = useSelector(
    (state) => state.currentUser?.user?.pages[businessCount]?.id
  );
  const { handleGetPageAdmins, pageAdmins } = useSettings();
  return (
    <div className="businessSeller_body">
      <div className="businessSeller_tabs">
        <p
          onClick={() => setActiveState(1)}
          className={`${activeState === 1 && "active"}`}
        >
          Display Settings
          {activeState === 1 && <span className="underline"></span>}
        </p>
        <p
          onClick={() => setActiveState(2)}
          className={`${activeState === 2 && "active"}`}
        >
          Notification Settings
          {activeState === 2 && <span className="underline"></span>}
        </p>
        <p
          onClick={() => {
            setActiveState(3);
            handleGetPageAdmins(currentUserData);
          }}
          className={`${activeState === 3 && "active"}`}
        >
          Page Settings
          {activeState === 3 && <span className="underline"></span>}
        </p>
      </div>
      <div className="businessSeller_tabContent">
        {activeState === 1 && (
          <BusinessPageDisplay businessCount={businessCount} />
        )}
        {activeState === 2 && (
          <BusinessPageNotification
            businessCount={businessCount}
            activeState={activeState}
          />
        )}
        {activeState === 3 && pageAdmins?.data && (
          <BusinessPagePage
            handleGetPageAdmins={handleGetPageAdmins}
            currentUserDataForGet={currentUserData}
            pageAdmins={pageAdmins}
            businessCount={businessCount}
          />
        )}
      </div>
    </div>
  );
};
export default BusinessPage;
