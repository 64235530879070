import {
  CREATE_NEW_COMMENT,
  UPDATE_LOADING,
  UPDATE_POST_LIKE,
} from "./index.types";
import {
  UPDATE_POST,
  ADD_NEW_POSTS,
  STORE_FRESH_POST_DATA,
  DELETE_POST,
  UPDATE_PREV,
  UPDATE_NEXT,
  CREATED_NEW_POST,
  RESET_POSTS,
  NEW_POST_LOADING,
  UPDATE_LAST_COMMENT,
  UPDATE_POST_COMMENT,
  UPDATE_REPLIES,
} from "./index.types";

export const updatePost = (payload) => ({
  type: UPDATE_POST,
  payload,
});

export const addNewPost = (payload) => ({
  type: ADD_NEW_POSTS,
  payload,
});

export const storeNewPost = (payload) => ({
  type: STORE_FRESH_POST_DATA,
  payload,
});

export const deletePost = (payload) => ({
  type: DELETE_POST,
  payload,
});

export const updatePrev = (payload) => ({
  type: UPDATE_PREV,
  payload,
});

export const updateNext = (payload) => ({
  type: UPDATE_NEXT,
  payload,
});

export const createdNewPost = (payload) => ({
  type: CREATED_NEW_POST,
  payload,
});
export const resetPosts = (payload) => ({
  type: RESET_POSTS,
  payload,
});

export const updatePostLoading = (payload) => ({
  type: UPDATE_LOADING,
  payload,
});

export const newPostLoading = (payload) => ({
  type: NEW_POST_LOADING,
  payload,
});

export const updateLastComment = (payload) => ({
  type: UPDATE_LAST_COMMENT,
  payload,
});

export const updateComment = (payload) => ({
  type: UPDATE_POST_COMMENT,
  payload,
});

export const UpdateReplies = (payload) => ({
  type: UPDATE_REPLIES,
  payload,
});
export const createNewComment = (payload) => ({
  type: CREATE_NEW_COMMENT,
  payload,
});
export const updatePostLike = (payload) => ({
  type: UPDATE_POST_LIKE,
  payload,
});
