/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearNotificationComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 36.623 36.623" {...props}>
    <defs>
      <style>
        {
          ".a-notification-icon,.b-notification-icon,.c-notification-icon,.d{fill:none;}.a-notification-icon,.b-notification-icon,.c-notification-icon{stroke:#fff;stroke-width:2px;}.a-notification-icon,.b-notification-icon{stroke-linecap:round;}.b-notification-icon{stroke-linejoin:round;}.d-notification-icon{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <path
          className="a-notification-icon"
          d="M12.278,0A9.163,9.163,0,0,0,3.122,9.156v4.41a7.292,7.292,0,0,1-.87,3.143L.5,19.624a2.929,2.929,0,0,0,1.648,4.471,31.925,31.925,0,0,0,20.249,0,3.055,3.055,0,0,0,1.648-4.471l-1.755-2.915a7.493,7.493,0,0,1-.855-3.143V9.156A9.183,9.183,0,0,0,12.278,0Z"
          transform="translate(6.064 4.441)"
        />
        <path
          className="b-notification-icon"
          d="M5.646,1.923a9.238,9.238,0,0,0-1.465-.305A10.306,10.306,0,0,0,0,1.923a3.034,3.034,0,0,1,5.646,0Z"
          transform="translate(15.519 2.96)"
        />
        <path
          className="c-notification-icon"
          d="M9.156,0A4.591,4.591,0,0,1,4.578,4.578,4.594,4.594,0,0,1,1.343,3.235,4.594,4.594,0,0,1,0,0"
          transform="translate(13.764 29.085)"
        />
        <path className="d-notification-icon" d="M0,0H36.623V36.623H0Z" />
      </g>
    </g>
  </svg>
);

export default VuesaxLinearNotificationComponent;
