/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/
import pathToFile from "./heart.png";
import React from "react";

const HeartComponent = (props) => <img src={pathToFile} {...props} alt="" />;

export default HeartComponent;
