import { createContext, useContext, useEffect, useState } from "react";

const SocketContext = createContext()

export const useSocketContext = () =>{
    const token = localStorage.getItem('token')

    const [page, setPage] = useState()
    const [webSocket, setWebSocket] = useState()
    useEffect(()=>{
        try{
            const ws = new WebSocket(`wss://dev.api.meeney.com/v1/ws/?token=${token}${page ? '&page=' + page : ''}`)
            setWebSocket(ws)
        }catch(err){
            console.log(err)
        }
        return () => {
            webSocket?.close();
        }
    }, [page])
    
    return{
        webSocket,
        setPage,
        page
    }
}
export default SocketContext