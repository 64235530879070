import moment from "moment";
import profileImg from "../../../../assets/img/Ellipse 357.png";
import useActivity from "../../../../hooks/activity";
// import PrivateSellerDashboard from "../../../../pages/PrivateSeller/PrivateSellerManagement/dashboard";

const BookingReqItem = ({ data, handleUpdateBooking }) => {
  return (
    <div className="orc-item-box">
      <div className="orc-ib-one">
        <div className="orc-detail-box">
          <img
            style={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
            src={
              data?.booking?.owner?.profile_image?.file
                ? data?.booking?.owner?.profile_image?.file
                : profileImg
            }
            alt=""
          />
          <div className="orc-detail-box-user">
            <h4>{data?.booking?.owner?.full_name}</h4>
            <h6>{data?.booking?.owner?.username}</h6>
          </div>
        </div>
        <h5>{data?.product?.title}</h5>
        <h6>
          Booked on {moment(data?.booking?.booking_date).format("DD MMM YYYY")}
        </h6>
        <p>
          Expires on{" "}
          {moment(data?.booking?.booking_expire).format("DD MMM YYYY")}
        </p>
      </div>

      <div className="orc-ib-two">
        <button
          className="btn-accept"
          onClick={() =>
            handleUpdateBooking({ id: data?.id, status: "ACCEPT" })
          }
        >
          Accept
        </button>
        <button
          className="btn-decline"
          onClick={() =>
            handleUpdateBooking({ id: data?.id, status: "DECLINE" })
          }
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default BookingReqItem;
