import BusinessLogo from "../../../assets/img/blogo.png";
import ShopImg from "../../../assets/img/shopImg.png";
import StarIcon from "../../../assets/svg/star";
import CircleIcon from "../../../assets/svg/circleIcon";
import { useState } from "react";
import NewLocation from "../../../assets/svg/newLocation";
import ThreeDots from "../../../assets/svg/threeDots";
import Forward from "../../../assets/svg/forward";
import People from "../../../assets/svg/people";
import Refer from "../../../assets/svg/refer.png";
import moment from "moment";
import ReactStars from "react-stars";

const ReferralItem = ({
  handleViewFull,
  referralItem,
  active,
  data,
  setDataIndex,
  idx,
}) => {
  const [pop, setPop] = useState(false);
  return (
    <div
      onClick={() => {
        handleViewFull();
        setDataIndex(idx);
      }}
      className="queueItem"
    >
      <div className="topPart">
        <div className="profile">
          <img
            className="logo"
            src={
              referralItem?.page?.featured_image
                ? referralItem?.page?.featured_image
                : BusinessLogo
            }
            alt="logo"
          />
          <div>
            <p>{referralItem?.page?.name}</p>
            <p className="bottom">{referralItem?.page?.username}</p>
          </div>
        </div>
        <img
          src={
            referralItem?.gallary?.[0]?.file
              ? referralItem?.gallary?.[0]?.file
              : ShopImg
          }
          alt=""
        />
      </div>
      <div className="bottomPart">
        <div onClick={(e) => e.stopPropagation()} className="other">
          <div className="queueItemReview">
            <div className="top">
              <div className="stars">
                <ReactStars
                  value={referralItem?.page?.rating}
                  count={5}
                  edit={false}
                />
              </div>
              <span>{referralItem?.page?.rating}.0</span>
            </div>
            <p>
              {referralItem?.page?.review_count}{" "}
              {(referralItem?.page?.review_count > 1 && "reviews") ||
                (referralItem?.page?.review_count <= 1 && "review")}
            </p>
          </div>
          <div className="location">
            <div className="top">
              <NewLocation />
              <p>{referralItem?.page?.location?.address}</p>
            </div>
            <p>
              Posted on {moment(referralItem?.created_at).format("DD MMM YYYY")}
            </p>
          </div>
          <div className="tag">
            <p>{referralItem?.page?.type} Page</p>
          </div>
          {/* <div onClick={() => setPop(!pop)} className="threeDotDivs">
            <ThreeDots fill={"#490057"} height={20} width={8} />
            {pop && (
              <div className="popUp">
                <a href="">
                  <Forward />
                  <span>Share</span>
                </a>
                <a href="">
                  <img src={Refer} alt="" />
                  <span>Refer</span>
                </a>
              </div>
            )}
          </div> */}
        </div>
        <div className="queueActions">
          <div className="leftActivity">
            <div
              className="queueDetails"
              style={{
                justifyContent: "space-between",
              }}
            >
              {/* <div className="queueTitle">
                <p className="topTitle">Time</p>
                {active !== "dumdum" && (
                  <p className="bottomTitle">
                    Referred on{" "}
                    {moment(referralItem?.created_at).format("DD MMM YYYY")}
                  </p>
                )}
              </div> */}

              <div
                className="time"
                style={{
                  flexBasis: "fit-content",
                }}
              >
                {active !== "dumdum" && (
                  <>
                    {/* <div className="queueTime">
                      <p>Time</p>
                      <p>10am-12:pm</p>
                    </div>
                    <div className="price">
                      <p>$20</p>
                    </div> */}
                    <div className="status">
                      {referralItem.active ? (
                        <CircleIcon fill="#00C13A" />
                      ) : (
                        <CircleIcon fill="red" />
                      )}

                      <p className={!referralItem.active && "red"}>
                        {referralItem.active ? "Active" : "Inactive"}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rightActivity">
            <div
              className={`topActions ${
                active === "referrals" && "whenReferrals"
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {
                <div className="referred">
                  <div className="img">
                    <img src={Refer} />
                  </div>
                  <span>{referralItem?.referred_count} referred</span>
                </div>
              }
              {active !== "dumdum" && (
                <div
                  onClick={() => {
                    handleViewFull();
                    setDataIndex(idx);
                  }}
                  className="create"
                >
                  {/* <Eye fill= {'#fbb03b'}/> */}
                  <span>View full</span>
                </div>
              )}
            </div>
            <div className="bottomOptions"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReferralItem;
