import { createContext, useState } from "react";

const MessageContext = createContext();

export function MessageProvider({children}) {
    const [allFollowers, setAllFollowers] = useState([]);
    const addFollowers = (followers) => {
        setAllFollowers((prevState => [...prevState, {followers}]));
    };

    const [allFollowing, setAllFollowing] = useState([]);

    const [allSupporting, setAllSupporting] = useState([]);

    const [messageSendLoading, setMessageSendLoading] = useState(false);

    const [currentContactMessages, setCurrentContactMessages] = useState();

    const [currentMessagesLoading, setCurrentMessagesLoading] = useState(false);

    const [messageStarred, setMessageStarred] = useState(false);
    
    const [messageFlagged, setMessageFlagged] = useState(false);
    
    const [flaggedMessages, setFlaggedMessages] = useState();
    const [chatGroupLoading, setChatGroupLoading] = useState();
    
    const [starredMessages, setStarredMessages] = useState();
    
    const [userChatList, setUserChatList] = useState([]);

    const [messageDeleted, setMessageDeleted] = useState(null);

    return (
        <MessageContext.Provider value={{
            allFollowers,
            setAllFollowers,
            addFollowers,

            allFollowing,
            setAllFollowing,

            allSupporting,
            setAllSupporting,

            messageSendLoading,
            setMessageSendLoading,

            currentContactMessages,
            setCurrentContactMessages,
            currentMessagesLoading,
            setCurrentMessagesLoading,

            messageStarred,
            setMessageStarred,

            messageFlagged,
            setMessageFlagged,

            flaggedMessages,
            setFlaggedMessages,

            starredMessages,
            setStarredMessages,

            userChatList,
            setUserChatList,

            messageDeleted,
            setMessageDeleted,

            chatGroupLoading,
            setChatGroupLoading
        }}>{children}</MessageContext.Provider>
    )
}

export default MessageContext;