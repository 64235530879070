import GoogleMapReact from "google-map-react";
import { useEffect, useRef, useState } from "react";
import useGetLocation from "../../hooks/getLocation";
import { HiLocationMarker } from "react-icons/all";
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import "./style.scss";
const GoogleMaps = ({
  onAddMarker = () => {},
  onAddressChange = () => {},
  showMarker = true,
  map = true,
}) => {
  const { state, err, setState } = useGetLocation();
  const [markers, setMarkers] = useState([]);

  const handleAddMarkers = (data) => {
    const newMarker = [...markers, data];
    setMarkers(newMarker);
    onAddMarker(newMarker.map(({ lat, lng }) => [lng, lat]));
  };

  useEffect(() => {
    // if (state?.coords)
    //   setMarkers([
    //     ...markers,
    //     { lng: state.coords.longitude, lat: state.coords.latitude },
    //   ]);
  }, [state]);

  useEffect(() => {
    return () => setMarkers([]);
  }, []);
  console.log(markers);

  const [mapAPI, setMapAPI] = useState();
  console.log(showMarker);
  return (
    <div
      style={{
        minWidth: "100% ",
        minHeight: map ? 400 : "fit-content",
        // paddingRight: 20,
      }}>
      <Autocomplete
        // apiKey={config["map-key"]}
        selectProps={{
          onChange: async ({ label }) => {
            // console.log(data);
            onAddressChange(label);

            const data = await geocodeByAddress(label);
            const { lat, lng } = await getLatLng(data[0]);

            setMarkers([
              ...markers,
              {
                lat,
                lng,
              },
            ]);
            setState(
              (state) =>
                state && {
                  ...state,
                  coords: { ...state.coords, longitude: lng, latitude: lat },
                }
            );
          },
        }}
      />
      {/* <span onClick={(e) => createPolygon()}>create polygon</span> */}
      {state?.coords && map && (
        <div className="map" style={{ height: 500 }}>
          <GoogleMapReact
            bootstrapURLKeys={{
              // key: config["map-key"],
              libraries: ["places", "geometry"],
            }}
            center={{
              lat: state.coords.latitude,
              lng: state.coords.longitude,
            }}
            defaultZoom={13}
            yesIWantToUseGoogleMapApiInternals={true}
            onGoogleApiLoaded={({ map, maps }) => setMapAPI([map, maps])}
            onClick={handleAddMarkers}>
            {showMarker &&
              markers.map((data, idx) => {
                return (
                  <HiLocationMarker
                    size={55}
                    className="selected-location"
                    key={idx}
                    lng={data.lng}
                    lat={data.lat}
                    onClick={(e) => {
                      const newMarker = markers
                        .map((marker) => marker !== data && marker)
                        .filter((marker) => marker && marker);
                      setMarkers(newMarker);
                      onAddMarker(newMarker.map(({ lat, lng }) => [lng, lat]));
                    }}
                  />
                );
              })}
          </GoogleMapReact>
        </div>
      )}
    </div>
  );
};

const MyApp = () => {};
export default GoogleMaps;
