/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearLocationComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-428 -188)">
      <path
        className="a"
        d="M6.24,3.12A3.12,3.12,0,1,1,3.12,0,3.12,3.12,0,0,1,6.24,3.12Z"
        transform="translate(436.88 195.19)"
      />
      <path
        className="a"
        d="M.243,6.49c1.97-8.66,14.8-8.65,16.76.01,1.15,5.08-2.01,9.38-4.78,12.04a5.193,5.193,0,0,1-7.21,0C2.253,15.88-.907,11.57.243,6.49Z"
        transform="translate(431.377 190)"
      />
      <path
        className="b"
        d="M0,0H24V24H0Z"
        transform="translate(452 212) rotate(180)"
      />
    </g>
  </svg>
);

export default VuesaxLinearLocationComponent;
