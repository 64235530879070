import React, { useEffect, useState, useRef, useContext, useMemo } from "react";
import "./messaging.scss";
import "../../app.scss";
import emptyList from "../../assets/img/emptychat.png";
import emptyContact from "../../assets/img/emptycontact.png";
import emptyMessages from "../../assets/img/bubbles.png";
import usedeviceType from "../../hooks/deviceType";
import Header from "../../components/Header";
import Conversation from "../../components/Messaging/conversation";
import Message from "../../components/Messaging/message";
import toast, { Toaster } from "react-hot-toast";
import { showToast } from '../../components/ReactToast';
import social from '../../assets/messaging/filled/share.svg'
import socialActive from '../../assets/messaging/filled/share-active.svg'
import business from '../../assets/messaging/filled/briefcase.svg'
import businessActive from '../../assets/messaging/filled/briefcase-active.svg'
import allChats from '../../assets/messaging/filled/messages.svg'
import allChatsActive from '../../assets/messaging/filled/messages-active.svg'
import flagged from '../../assets/messaging/filled/flag.svg'
import flaggedActive from '../../assets/messaging/filled/flag-active.svg'
import starred from '../../assets/messaging/filled/star.svg'
import starredMenu from '../../assets/messaging/filled/star-menu.svg'
import starredActive from '../../assets/messaging/filled/star-active.svg'
import chatAvatar from '../../assets/img/avatar.png'
import menu from '../../assets/messaging/ellipsis.svg'
import edit from '../../assets/messaging/filled/edit.svg'
import send from '../../assets/messaging/filled/send.svg'
import image from '../../assets/messaging/filled/img.svg'
import danger from '../../assets/messaging/filled/danger.svg'
import slash from '../../assets/messaging/filled/slash.svg'
import pin from '../../assets/messaging/map-pin.svg'
import pinActive from '../../assets/messaging/map-pin-active.svg'
import cart from '../../assets/messaging/filled/cart.svg'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import warning from "react-redux/lib/utils/warning";
import ContactCard from "../../components/messages/contactCard";
import { RiPieChart2Fill } from "react-icons/ri";
import MessageContext from "../../hooks/messageContext";
import useMessaging from "../../hooks/messaging";
import { truncateStringEllipsis } from "../../utils/helpers";
import moment from "moment";
import MessageBubble from "../../components/messages/messageBubble";
import LoaderPurpleBg from "../../assets/loaders/LoaderPurpleBg";
import { useSelector } from "react-redux";


const Messaging = () => {
  const {
    allFollowers,
    allFollowing,
    allSupporting,
    messageSendLoading,
    setMessageSendLoading,
    currentContactMessages,
    currentMessagesLoading,
    messageStarred,
    messageFlagged,
    flaggedMessages,
    starredMessages,
    userChatList,
    setUserChatList,
    messageDeleted,
    chatGroupLoading,
  } = useContext(MessageContext);

  const {
    handleGetFollowers,
    handleGetFollowing,
    handleUploadMessageMedia,
    handleFetchContactMessages,
    handleStarMessage,
    handleFlagMessage,
    handleGetFlaggedMessages,
    handleGetStarredMessages,
    handleGetChatList,
    handleDeleteMessage,
  } = useMessaging();
  const [showMessageAttachment, setShowMessageAttachment] = useState(false);
  const toggleMessageAttachment = () => { setShowMessageAttachment({ showMessageAttachment: !showMessageAttachment }); };


  const [isMobileMenuVisble, setIsMobileMenuVisible] = useState();
  // const [chatList, setChatList] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMesage] = useState(null);
  const [myNewMessage, setMyNewMessage] = useState(null);
  const [myMessage, setMyMessage] = useState('');
  const [newMsgMedia, setNewMsgMedia] = useState([]);
  const [reversedList, setReversedList] = useState([]);

  const thisUser = useSelector(state => state.currentUser.user);

  const scrollRef = useRef();

  const token = localStorage.getItem("token");

  const baseSocketURL = "127.0.0.1:8000/v1";

  useEffect(() => {
    handleGetFollowers();
    handleGetFollowing();
    handleGetChatList();
  }, []);

  const wbs = useMemo(() => new WebSocket(`wss://${baseSocketURL}ws/?token=${token}`), [token]);

  useEffect(() => {
    const handler = (event) => {
      if (event?.data) {
        const json = JSON.parse(event?.data);

        if (json.event === "message.send.result") {
          setMessageSendLoading(false);
          setMessages(prev=>[...prev, json.data]);
          setMyNewMessage(json.data);

          setMyMessage('');
          setNewMsgMedia([]);
        }

        if (json.event === "message.new") {
            setNewMesage(json.data);
        }
      }
    }

    wbs.addEventListener('message', handler, { passive: true });
    return () => wbs.removeEventListener('message', handler,);
  }, [wbs])

  useEffect(() => {
    const chatListCopy = userChatList.map(obj => {
      if (obj.id === newMessage.sender.id) {
        return {...obj, last_message: newMessage};
      }
      return obj;
    });
    
    const sortedList = chatListCopy?.sort(
      (objA, objB) => new Date(objA.last_message.timestamp).getTime() - new Date(objB.last_message.timestamp).getTime(),
    );

    setUserChatList(sortedList);
    
    if (newMessage?.sender.id === currentChat?.id) {
      setMessages(prev=>[...prev, newMessage]);
    } else {
      console.log(userChatList);
    }

  }, [newMessage]);

  useEffect(() => {
    const chatListCopy = userChatList.map(obj => {
      if (obj.id === myNewMessage.receiver.id) {
        console.log(myNewMessage);
        return {...obj, last_message: myNewMessage};
      }
      return obj;
    });
    
    const sortedList = chatListCopy?.sort(
      (objA, objB) => new Date(objA.last_message.timestamp).getTime() - new Date(objB.last_message.timestamp).getTime(),
    );

    setUserChatList(sortedList);
  }, [myNewMessage]);

  useEffect(() => {
    currentContactMessages?.data?.count > 0 ?
      setMessages(currentContactMessages?.data?.results?.reverse()) : setMessages();
  }, [currentContactMessages])

  const startContactChat = (contact) => {
    if (currentChat?.username === contact.username) {
      // same contact
      return;
    }

    setMessages();
    handleFetchContactMessages(contact)
    setCurrentChat(contact);

    if (userChatList.length > 0) {
      const hasId = userChatList?.some(function (arr) {
        return arr.id === contact.id;
      })

      if (!hasId) {
        // doesn't have id
        let newChatList = [...userChatList, contact];
        console.log(newChatList);
        setUserChatList(newChatList);
      }
    } else {
      let newChatList = [contact];
      setUserChatList(newChatList);
      // chatList.push(contact);
    }

  }

  const showSelectedMessage = (chat) => {
    setMessages([]);
    if (thisUser.id === chat.sender.id) {
      setCurrentChat(chat.receiver);
    } else {
      setCurrentChat(chat.sender);
    }

    let thisMessage = {};
    thisMessage.message = chat?.message;
    thisMessage.media = chat?.attachments;
    thisMessage.date = chat?.created_at;
    thisMessage.id = chat?.id;
    thisMessage.is_flagged = chat?.is_flagged;
    thisMessage.is_starred = chat?.is_starred;

    let thisReceiver = chat?.receiver;
    thisMessage.receiver = thisReceiver;

    let displayedMessage = [];
    displayedMessage.push(thisMessage);
    setMessages(displayedMessage);
  }

  const handleEnterKey = (event) => {
    if (event.key === 'Enter') {
      sendContactMessage();
    }
  }

  const sendContactMessage = async () => {
    if ((myMessage === '' || myMessage === ' ') && newMsgMedia?.length === 0) {
      console.log("empty message");
      return;
    }

    setMessageSendLoading(true);

    let uploadedFiles = [];
    if (newMsgMedia?.length > 0) {
      uploadedFiles = await handleUploadMessageMedia(newMsgMedia);
      console.log(uploadedFiles);
    }

    const sendMessage = {
      event: "message.send",
      data: {
        receiver: currentChat.username,
        message: myMessage,
      }
    };
    if (uploadedFiles?.length > 0) {
      console.log("attaching");
      sendMessage.data.attachments = uploadedFiles;
    }


    wbs.send(JSON.stringify(sendMessage));
  }

  const hiddenMediaInput = useRef(null);
  const handleClickUploadMedia = event => {
    hiddenMediaInput.current.click();
  }

  const handleInputChange = event => {
    const fileSelected = event.target.files[0];
    let oldMsgMedia = [];
    oldMsgMedia.push(fileSelected);
    setNewMsgMedia(oldMsgMedia);
  }

  const handleMessageFlag = (id) => {
    handleFlagMessage(id);
  }

  const handleMessageUnFlag = (id) => {
    handleFlagMessage(id);
  }

  const handleMessageStar = (id) => {
    handleStarMessage(id);
  }

  const handleMessageUnStar = (id) => {
    handleStarMessage(id);
  }

  const handleMessageDelete = (id) => {
    handleDeleteMessage(id);
  }

  useEffect(() => {
    if (messageStarred) showToast("success", "Message Starred!");
  }, [messageStarred]);

  useEffect(() => {
    if (messageFlagged) showToast("success", "Message Flagged!");
  }, [messageFlagged]);

  useEffect(() => {
    const found = messages.some(el => el.id === messageDeleted);

    if (found) showToast("success", "Message Deleted!");
    const newMessages = messages.filter(object => {
      return object.id !== messageDeleted;
    });

    setMessages(newMessages);
  }, [messageDeleted]);

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
  }, [messages]);

  const getStarredMessages = () => {

  }

  const [messageType, setMessageType] = useState('social')
  const [chatGroup, setChatGroup] = useState('all')
  const [currentBiz, setCurrentBiz] = useState(1)
  const [contactList, setContactList] = useState('followers')

  const switchMessageType = () => {
    if (messageType === 'social') {
      setMessageType('business')
    } else {
      setMessageType('social')
    }
  }

  const switchChatGroup = (group) => {
    setUserChatList([]);
    setChatGroup(group);
    if (group === 'all') {
      handleGetChatList();
    }

    if (group === 'flagged') {
      handleGetFlaggedMessages();
    }

    if (group === 'starred') {
      handleGetStarredMessages();
    }
  }

  const switchCurrentBiz = (id) => {
    setCurrentBiz(id)
  }

  const switchContactList = (list) => {
    setContactList(list)
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchor2El, setAnchor2El] = React.useState(null);
  const [anchor3El, setAnchor3El] = React.useState(null);
  const open = Boolean(anchorEl);
  const open1 = Boolean(anchor2El);
  const open2 = Boolean(anchor3El);

  const handleContactMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleActiveChatMenu = (event) => {
    setAnchor2El(event.currentTarget);
  };

  const handleBizListMenu = (event) => {
    setAnchor3El(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClose2 = () => {
    setAnchor2El(null);
  };

  const handleClose3 = () => {
    setAnchor3El(null);
  };

  useEffect(() => {
    if (userChatList?.length > 0) {
      setReversedList(userChatList?.reverse());
    }
  }, [userChatList])

  useEffect(() => {
    if (chatGroup === 'flagged') {
      setUserChatList(flaggedMessages);
    }
    if (chatGroup === 'starred') {
      setUserChatList(starredMessages);
    }
  }, [flaggedMessages, starredMessages, chatGroup])

  return (
    <>
      <Header menuVisibility={[isMobileMenuVisble, setIsMobileMenuVisible]} />

      <Toaster
        position="top-right"
        reverseOrder={false}
      />

      <section className="messages">
        <div className="msg-container">
          <div className="left">
            <div className="left-header">
              <h2 className='heading'>Messages</h2>

              <div className="switch">
                <div className={`social ${messageType === 'social' ? 'active' : ''}`}
                  onClick={() => switchMessageType()}>
                  {
                    messageType === 'social' ?
                      <img alt="" src={socialActive} />
                      :
                      <img alt="" src={social} />
                  }
                </div>
                <div className={`business ${messageType === 'business' ? 'active' : ''}`}
                  onClick={() => switchMessageType()}>
                  {
                    messageType === 'business' ?
                      <img alt="" a src={businessActive} />
                      :
                      <img alt="" src={business} />
                  }
                </div>
              </div>
            </div>

            <div className="msg-search">
              <input type="text" placeholder='Search your chats...' />
            </div>

            <div className="msg-tabs">
              <div className={`all-msg tab-item ${chatGroup === 'all' ? 'active' : ''}`}
                onClick={() => switchChatGroup('all')}>
                {
                  chatGroup === 'all' ?
                    <>
                      <img alt="" src={allChatsActive} />
                      <p>All Chats</p>
                    </>
                    :
                    <img alt="" src={allChats} />
                }

              </div>

              <div className={`flagged tab-item ${chatGroup === 'flagged' ? 'active' : ''}`}
                onClick={() => switchChatGroup('flagged')}>
                {
                  chatGroup === 'flagged' ?
                    <>
                      <img alt="" src={flaggedActive} />
                      <p>Flagged</p>
                    </>
                    :
                    <img alt="" src={flagged} />
                }
              </div>

              <div className={`starred tab-item ${chatGroup === 'starred' ? 'active' : ''}`}
                onClick={() => switchChatGroup('starred')}>
                {
                  chatGroup === 'starred' ?
                    <>
                      <img alt="" src={starredActive} />
                      <p>Starred</p>
                    </>
                    :
                    <img alt="" src={starred} />
                }
              </div>
            </div>

            <div className="divider"></div>

            <div className="chat-list">
              {
                chatGroupLoading ?
                  <div className="get-chat-group-load">
                    <LoaderPurpleBg style={{ width: 50, height: 50 }} />
                  </div>

                  :
                  <>
                    {
                      reversedList?.length > 0 && chatGroup === 'all' &&
                      reversedList.map((chat, id) => (
                        <ContactCard
                          key={id}
                          avatar={chat.profile_image?.file ? chat.profile_image.file : chatAvatar}
                          username={chat?.first_name + ' ' + chat?.last_name}
                          lastMsg={chat?.last_message ? truncateStringEllipsis(chat?.last_message?.message, 50) : ''}
                          msgDate={chat.last_message ? moment(chat.last_message.created_at).format("L") : ''}
                          active={chat.id === currentChat?.id ? true : false}
                          contact={chat}
                          clickAction={() => startContactChat(chat)}
                        />
                      ))
                    }

                    {
                      reversedList?.length > 0 && chatGroup === 'flagged' &&
                      reversedList.map((chat, id) => (
                        <ContactCard
                          key={id}
                          avatar={chat.profile_image?.file ? chat.profile_image.file : chatAvatar}
                          username={thisUser?.username === chat.sender?.username ? "You" : chat.sender?.full_name}
                          lastMsg={chat?.message ? truncateStringEllipsis(chat?.message, 50) : ''}
                          msgDate={chat.message ? moment(chat.created_at).format("L") : ''}
                          active={chat.id === messages[0]?.id || chat.id === messages[0]?.id ? true : false}
                          contact={chat}
                          clickAction={() => showSelectedMessage(chat)}
                        />
                      ))
                    }

                    {
                      reversedList?.length > 0 && chatGroup === 'starred' &&
                      reversedList.map((chat, id) => (
                        <ContactCard
                          key={id}
                          avatar={chat.profile_image?.file ? chat.profile_image.file : chatAvatar}
                          username={thisUser?.username === chat.sender?.username ? "You" : chat.sender?.full_name}
                          lastMsg={chat?.message ? truncateStringEllipsis(chat?.message, 50) : ''}
                          msgDate={chat.message ? moment(chat.created_at).format("L") : ''}
                          active={chat.id === messages[0]?.id || chat.id === messages[0]?.id ? true : false}
                          contact={chat}
                          clickAction={() => showSelectedMessage(chat)}
                        />
                      ))
                    }

                    {
                      reversedList.length === 0 &&
                      <div className="empty-chatlist">
                        <img src={emptyList} alt="" />
                        <h5>Your Inbox is empty</h5>
                        <p>Select a contact to start chatting with.</p>
                      </div>
                    }
                  </>
              }

            </div>
          </div>

          <div className="middle">
            {
              messageType === 'business' ?
                <div className="biz-box">
                  <div className="biz-list">
                    <div className="add-biz">
                      <img src={edit}
                        alt=""
                        aria-controls={open2 ? 'biz-list-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open2 ? 'true' : undefined}
                        onClick={handleBizListMenu}
                      />

                      <Menu
                        id="biz-list-menu"
                        anchorEl={anchor3El}
                        open={open2}
                        onClose={handleClose3}
                        MenuListProps={{
                          'aria-labelledby': 'biz-list-menu',
                        }}
                      >
                        <div className="biz-list-select">
                          <h3 className="biz-list-heading">My Pages</h3>

                          <div className="biz-single" onClick={handleClose3}>
                            <div className="biz-details">
                              <h4 className="biz-title">My Phone Store</h4>
                              <p className="biz-type">Private Seller</p>
                            </div>

                            <img src={pinActive} alt="" />
                          </div>

                          <div className="biz-single" onClick={handleClose3}>
                            <div className="biz-details">
                              <h4 className="biz-title">Sneaker Shop</h4>
                              <p className="biz-type">Private Seller</p>
                            </div>

                            <img src={pinActive} alt="" />
                          </div>

                          <div className="biz-single" onClick={handleClose3}>
                            <div className="biz-details">
                              <h4 className="biz-title">Sneaker Shop 2</h4>
                              <p className="biz-type">Private Seller</p>
                            </div>

                            <img src={pin} alt="" />
                          </div>

                          <div className="biz-single" onClick={handleClose3}>
                            <div className="biz-details">
                              <h4 className="biz-title">Thrift Store</h4>
                              <p className="biz-type">Private Seller</p>
                            </div>

                            <img src={pin} alt="" />
                          </div>

                          <div className="biz-single" onClick={handleClose3}>
                            <div className="biz-details">
                              <h4 className="biz-title">Thrift Store 2</h4>
                              <p className="biz-type">Private Seller</p>
                            </div>

                            <img src={pin} alt="" />
                          </div>

                          <div className="biz-single" onClick={handleClose3}>
                            <div className="biz-details">
                              <h4 className="biz-title">Thrift Store 3</h4>
                              <p className="biz-type">Private Seller</p>
                            </div>

                            <img src={pin} alt="" />
                          </div>
                        </div>
                      </Menu>
                    </div>

                    <div className={`biz-single ${currentBiz === 1 ? 'active' : ''}`}
                      onClick={() => switchCurrentBiz(1)}>
                      <p className="bizname">My Phone Store</p>
                    </div>

                    <div className={`biz-single ${currentBiz === 2 ? 'active' : ''}`}
                      onClick={() => switchCurrentBiz(2)}>
                      <p className="bizname">Sneaker Shop</p>
                    </div>

                    <div className={`biz-single ${currentBiz === 3 ? 'active' : ''}`}
                      onClick={() => switchCurrentBiz(3)}>
                      <p className="bizname">Sneaker Shop 2</p>
                    </div>

                    <div className={`biz-single ${currentBiz === 4 ? 'active' : ''}`}
                      onClick={() => switchCurrentBiz(4)}>
                      <p className="bizname">Thrift Store</p>
                    </div>

                    <div className={`biz-single ${currentBiz === 5 ? 'active' : ''}`}
                      onClick={() => switchCurrentBiz(5)}>
                      <p className="bizname">Thrift Store 2</p>
                    </div>

                    <div className={`biz-single ${currentBiz === 6 ? 'active' : ''}`}
                      onClick={() => switchCurrentBiz(6)}>
                      <p className="bizname">Thrift Store 3</p>
                    </div>
                  </div>
                </div>
                :
                null
            }

            <div className="msg-board">
              {
                currentChat ?
                  <>
                    <div className="msg-header">
                      <div className="user">
                        <img alt="" src={currentChat?.profile_image?.file ? currentChat.profile_image.file : chatAvatar} />

                        <h3 className='contact-name'>{currentChat?.first_name} {currentChat?.last_name}</h3>
                      </div>

                      <img src={menu}
                        alt=""
                        aria-controls={open1 ? 'active-chat-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open1 ? 'true' : undefined}
                        onClick={handleActiveChatMenu}
                      />

                      <Menu
                        id="active-chat-menu"
                        anchorEl={anchor2El}
                        open={open1}
                        onClose={handleClose2}
                        MenuListProps={{
                          'aria-labelledby': 'active-chat-menu',
                        }}
                      >

                        <MenuItem onClick={handleClose2}>
                          <img src={slash} alt="" className='menu-icon' />&nbsp;
                          <p className="menu-text">Block User</p>
                        </MenuItem>
                        <MenuItem onClick={handleClose2}>
                          <img src={danger} alt="" className='menu-icon' />&nbsp;
                          <p className="menu-text">Report User</p>
                        </MenuItem>
                      </Menu>
                    </div>

                    <div className="divider"></div>
                  </>

                  : null
              }

              {
                currentChat ?
                  <div className={`messages ${messageType === 'social' ? 'social' : ''}`}>
                    {
                      currentMessagesLoading ?
                        <div className="get-messages-load">
                          <LoaderPurpleBg style={{ width: 70, height: 70 }} />
                        </div>
                        :
                        <div className='msg-wrap'>
                          {
                            messages?.length > 0 ?
                              messages.map((message, index) => (
                                <MessageBubble
                                  message={message}
                                  media={message?.attachments}
                                  date={message?.created_at}
                                  mine={message?.receiver?.id === currentChat.id ? true : false}
                                  key={index}
                                  flagAction={handleMessageFlag}
                                  starAction={handleMessageStar}
                                  deleteAction={handleMessageDelete}
                                />
                              ))
                              : null
                          }
                        </div>
                    }

                  </div>
                  :
                  <div className="messages no-chat">
                    <img src={emptyMessages} alt="" />
                    <p>Pick a contact to start a conversation.</p>
                  </div>
              }

            </div>

            {
              currentChat ?
                <div className="msg-input">
                  <input
                    id="btn-media"
                    name="btn-media"
                    style={{ display: 'none' }}
                    type="file"
                    className='upload-btn'
                    ref={hiddenMediaInput}
                    accept={'image/png, image/jpeg, .image/svg'}
                    onChange={handleInputChange}
                  />

                  {
                    newMsgMedia?.length > 0 &&
                    <div className="media-preview">
                      <img src={URL.createObjectURL(newMsgMedia[0])} alt="" />
                    </div>
                  }
                  {
                    newMsgMedia.length === 0 &&
                    <div className="attach-image" onClick={() => handleClickUploadMedia()}>
                      <img alt="" src={image} />
                    </div>
                  }

                  <div className="msg-text">
                    <input className={`${messageType === 'business' ? '' : 'social'}`}
                      type="text"
                      placeholder='Type your message here...'
                      onChange={(e) => setMyMessage(e.target.value)}
                      value={myMessage}
                      onKeyDown={(event) => handleEnterKey(event)}
                    />
                  </div>

                  <div className="send-msg"
                    onClick={() => sendContactMessage()}
                  >
                    {
                      messageSendLoading ?
                        <LoaderPurpleBg style={{ width: 30, height: 30 }} />
                        :
                        <img alt="" src={send} />
                    }
                  </div>

                  {
                    messageType === 'business' ?
                      <div className="custom-order">
                        <img alt="" src={cart} />
                      </div>
                      :
                      null
                  }

                </div>
                : null
            }

          </div>

          <div className="right">
            <div className="right-header">
              <h2 className="contact-title">My Contacts</h2>
            </div>

            <div className="contacts-tab">
              <div className={`tab-single ${contactList === 'followers' ? 'active' : ''}`}
                onClick={() => { setContactList('followers') }}>
                Followers
              </div>
              <div className={`tab-single ${contactList === 'following' ? 'active' : ''}`}
                onClick={() => { setContactList('following') }}>
                Following
              </div>
              <div className={`tab-single ${contactList === 'supporting' ? 'active' : ''}`}
                onClick={() => { setContactList('supporting') }}>
                Supporting
              </div>
            </div>

            <div className="contact-list">
              {
                contactList === "following" ?
                  allFollowing?.data?.count > 0 ?
                    allFollowing?.data?.results?.map((contact, index) => (
                      <div className="contact-single" key={index} onClick={() => startContactChat(contact)}>
                        <img src={contact.profile_image?.file ? contact.profile_image?.file : chatAvatar} alt="" />
                        <p className="contact-name">{contact.full_name}</p>
                      </div>
                    ))
                    :
                    <div className="empty-contactList">
                      <img src={emptyContact} alt="" />
                      <h5>No Contacts</h5>
                    </div>
                  : null
              }

              {
                contactList === "followers" ?
                  allFollowers?.count > 0 ?
                    allFollowers?.results?.map((contact, index) => (
                      <div className="contact-single" key={index} onClick={() => startContactChat(contact)}>
                        <img src={contact.profile_image?.file ? contact.profile_image?.file : chatAvatar} alt="" />
                        <p className="contact-name">{contact.full_name}</p>
                      </div>
                    ))
                    :
                    <div className="empty-contactList">
                      <img src={emptyContact} alt="" />
                      <h5>No Contacts</h5>
                    </div>
                  : null
              }

              {
                contactList === "supporting" ?
                  allSupporting?.count > 0 ?
                    allSupporting?.results?.map((contact, index) => (
                      <div className="contact-single" key={index} onClick={() => startContactChat(contact)}>
                        <img src={contact.profile_image?.file ? contact.profile_image?.file : chatAvatar} alt="" />
                        <p className="contact-name">{contact.full_name}</p>
                      </div>
                    ))
                    :
                    <div className="empty-contactList">
                      <img src={emptyContact} alt="" />
                      <h5>No Contacts</h5>
                    </div>
                  : null
              }

            </div>
          </div>
        </div>
      </section>

    </>
  )
};

export default Messaging;