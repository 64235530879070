import "./style.scss";
import { useState, useRef, useEffect } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
const Time = ({ onChange = () => {}, initialNumber = 12, isAM = true }) => {
  const [number, setNumber] = useState(initialNumber);
  const inputRef = useRef(null);
  const [am, setAm] = useState(isAM);

  useEffect(() => {
    onChange(am ? `${number}am` : `${number}pm`);
  }, [am, number]);
  return (
    <div className="mins">
      <div
        className="preview"
        onClick={(e) => {
          inputRef.current.focus();
          console.log("clicked");
        }}>
        {number} <span onClick={(e) => setAm(!am)}>{am ? "am" : "pm"}</span>
      </div>
      <div className="icons">
        <FaAngleUp
          onClick={(e) => {
            if (number < 12) {
              setNumber(number + 1);
            } else {
              setNumber(1);
            }
          }}
        />
        <FaAngleDown
          onClick={(e) => {
            if (number > 1) {
              setNumber(number - 1);
            } else {
              setNumber(12);
            }
          }}
        />
      </div>
      <input
        type="number"
        value={number}
        style={{ opacity: 0, position: "absolute" }}
        ref={inputRef}
        onChange={(e) => setNumber(e.target.value)}
        min={1}
        max={12}
      />
    </div>
  );
};

export const Mins = ({
  onChange = () => {},
  max = 120,
  min = 1,
  defaultMin = 12,
}) => {
  const [number, setNumber] = useState(defaultMin);
  const inputRef = useRef(null);
  const [am, setAm] = useState(true);

  useEffect(() => {
    onChange(number);
  }, [number]);
  return (
    <div className="mins">
      <div
        className="preview"
        onClick={(e) => {
          inputRef.current.focus();
          console.log("clicked");
        }}>
        {number} <span onClick={(e) => setAm(!am)}> min </span>
      </div>
      <div className="icons">
        <FaAngleUp
          onClick={(e) => {
            if (number < max) {
              setNumber(number + 1);
            } else {
              setNumber(min);
            }
          }}
        />
        <FaAngleDown
          onClick={(e) => {
            if (number > 1) {
              setNumber(number - 1);
            } else {
              setNumber(max);
            }
          }}
        />
      </div>
      <input
        type="number"
        value={number}
        style={{ opacity: 0, position: "absolute" }}
        ref={inputRef}
        onChange={(e) => setNumber(e.target.value)}
        min={min}
        max={max}
      />
    </div>
  );
};

export default Time;
