const NewEmail = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="19.054" height="19.054" viewBox="0 0 19.054 19.054">
  <g id="vuesax_outline_sms" data-name="vuesax/outline/sms" transform="translate(-556 -252)">
    <g id="sms" transform="translate(556 252)">
      <path id="Vector" d="M12.5,14.687H4.565C1.667,14.687,0,13.02,0,10.122V4.565C0,1.667,1.667,0,4.565,0H12.5c2.9,0,4.565,1.667,4.565,4.565v5.557C17.069,13.02,15.4,14.687,12.5,14.687ZM4.565,1.191c-2.271,0-3.374,1.1-3.374,3.374v5.557c0,2.271,1.1,3.374,3.374,3.374H12.5c2.271,0,3.374-1.1,3.374-3.374V4.565c0-2.271-1.1-3.374-3.374-3.374Z" transform="translate(0.992 2.183)" fill="#490057"/>
      <path id="Vector-2" data-name="Vector" d="M4.567,3.673a2.951,2.951,0,0,1-1.858-.627L.224,1.061A.593.593,0,1,1,.963.132L3.448,2.116a1.894,1.894,0,0,0,2.231,0L8.164.132A.586.586,0,0,1,9,.227a.586.586,0,0,1-.1.834L6.417,3.045A2.914,2.914,0,0,1,4.567,3.673Z" transform="translate(4.96 6.545)" fill="#490057"/>
      <path id="Vector-3" data-name="Vector" d="M0,0H19.054V19.054H0Z" fill="none" opacity="0"/>
    </g>
  </g>
</svg>


    )
}
export default NewEmail