import React, { useState, useContext, useEffect } from "react";
import "../index.scss";
import { FaCopy } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/all";
import CustomDropdown from "../../customDropdown/customDropdown";
import genCode from "unique-string-generator";
import classNames from "classnames";

const BookingInfluencer = ({
  influencer,
  setInfluencer,
  bookingForm,
  setBookingForm,
}) => {
  const priceType = [{ label: "Fixed price $" }, { label: "Percentage %" }];

  const [influencerValue, setInfluencerValue] = useState(priceType[0].label);
  const [copied, setCopied] = useState(false);

  const handleInfluencerChange = (input, idx, e) => {
    setInfluencerValue(priceType.label);

    // setInfluencer({
    //   ...influencer,
    //   price_type: "PERCENTAGE",
    // });
    // const newFormValues = [...influencer];
    // newFormValues[idx][input] = "PERCENTAGE";
    // setInfluencer(newFormValues);
  };

  const handleInfluencerPrice = (idx, e) => {
    const newFormValues = [...influencer];
    newFormValues[idx][e.target.name] = Number(
      e.target.value.replace(/\D/g, "")
    );
    setInfluencer(newFormValues);
  };

  const addInfluencerFormFields = () => {
    setInfluencer([
      ...influencer,
      {
        username: "",
        influencer_type: "INFLUENCER",
        price_type: "FIXED",
        price_value: 1,
      },
    ]);
  };

  const handleInfluencerInputChange = (idx, e) => {
    const newFormValues = [...influencer];
    newFormValues[idx][e.target.name] = e.target.value;
    setInfluencer(newFormValues);
    // code:
    // e.target.value.trim() !== ""
    //   ? genCode.UniqueString()
    //   : undefined,
  };

  const removeInfluencerFormFields = (idx) => {
    const newFormValues = [...influencer];
    newFormValues.splice(idx, 1);
    setInfluencer(newFormValues);
  };

  return (
    <>
      {/*---------  INFLUENCER CARD CONTENTS ------------------*/}
      <div className="ref-box-three">
        <div className="box-three-header">
          <h5>Influencer</h5>
          <button
            type="button"
            className="add-new"
            onClick={() => addInfluencerFormFields()}
          >
            Add Influencer
          </button>
        </div>

        <div className="item__scroll">
          {influencer.map((influencer, idx) => (
            <div className="box-three-card" key={idx}>
              <div className="item__one">
                <input
                  className="form-input"
                  type="text"
                  placeholder="Charles E"
                  name="username"
                  value={influencer.username || ""}
                  onChange={(e) => {
                    console.log("this is an event", e, idx);
                    handleInfluencerInputChange(idx, e);
                  }}
                />
                <div className="dropbox">
                  <CustomDropdown
                    items={priceType}
                    value={influencerValue}
                    setValue={setInfluencerValue}
                    // onChange={handleInfluencerChange}
                    onChange={(e) => {
                      setBookingForm({
                        ...bookingForm,
                        referrals: bookingForm.referrals.map((data, id) => {
                          return idx === id
                            ? {
                                ...data,
                                price_type: e
                                  .toLowerCase()
                                  .includes("percentage")
                                  ? "PERCENTAGE"
                                  : "FIXED",
                              }
                            : data;
                        }),
                      });
                    }}
                  />
                </div>

                <input
                  className="form-input-sm"
                  type="number"
                  min="1"
                  max={bookingForm.booking_fee.amount - 1}
                  name="price_value"
                  placeholder={
                    influencerValue === priceType[0].label ? "$90" : "$10"
                  }
                  onChange={(e) => {
                    // influencerValue === priceType[0].label
                    //   ? handleInfluencerPrice("FIXED")
                    //   : handleInfluencerPrice("PERCENTAGE");
                    handleInfluencerPrice(idx, e);
                  }}
                  value={influencer.price_value}
                />
                <span
                  className={classNames({
                    "copy-code": copied === idx,
                  })}
                  onClick={() => {
                    window.navigator.clipboard.writeText(
                      genCode.UniqueString()
                    );
                    setCopied(idx);
                  }}
                >
                  {copied === idx ? "Copied" : "Copy code"}
                </span>
                <span className="cc-code">
                  <FaCopy /> {genCode.UniqueString()}
                </span>
              </div>

              <button
                type="button"
                className="remove"
                onClick={() => removeInfluencerFormFields(idx)}
              >
                <IoMdCloseCircleOutline className="close-icon" />
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default BookingInfluencer;
