import { useEffect, useState } from "react";
import "./index.scss";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../assets/img/Rectangle 1890.PNG";
import CustomDropdown from "../../customDropdown/customDropdown";
import CustomCheckBox from "../../customCheckBox";
import CircleIcon from "../../../assets/svg/circleIcon";
import { BiInfoCircle } from "react-icons/bi";
import RequestedItem from "./components/requestedItem";
import usePrivateSellerDatas from "../../../hooks/privateSellerRequestsCheckoutHistory";
import usePrivateSellerDashboard from "../../../hooks/privateSellerDashboard";
import Loader from "../../loader";

const BsRequestedTab = () => {
  // const [isOpen, setOpen] = useState(false);
  // const toggleView = () => setOpen(!isOpen);

  // const testArray = [1, 2];
  // const testArray2 = [1, 2, 3, 4];

  // const data = [
  //   { id: 0, label: "All" },
  //   { id: 1, label: "Requests" },
  //   { id: 2, label: "Processing" },
  //   { id: 3, label: "Out for delivery" },
  //   { id: 4, label: "Buyer" },
  // ];

  // const data2 = [
  //   { id: 0, label: "All" },
  //   { id: 1, label: "Criteria met" },
  //   { id: 2, label: "Criteria not met" },
  // ];

  const {
    allOrdersData,
    handleGetCustomizedOrders,
    handleBulkUpdate,
    bulkUpdateOrderStatusData,
  } = usePrivateSellerDashboard();

  useEffect(() => {
    handleGetCustomizedOrders({ tab: "Requested" });
  }, []);

  useEffect(() => {
    if (bulkUpdateOrderStatusData.data)
      handleGetCustomizedOrders({ tab: "Requested" });
  }, [bulkUpdateOrderStatusData.data]);
  const [multiSelect, setMultiSelect] = useState([]);

  const handleMultiSelect = ({ value }) => {
    console.log("clicked");
    if (value) {
      const arr = allOrdersData?.data?.results?.map((data) => data.id);
      setMultiSelect([...arr]);
      console.log(arr, "MULTIIII");
    }
  };
  return (
    <div className="requestedTabBody">
      <div className="tab-content">
        <div className="buynow-tab">
          {/* <div className="filter-box">
            <p>Filter</p>
            <div className="dropbox">
              <CustomDropdown items={data} />
            </div>
          </div> */}
          {allOrdersData?.loading && <Loader />}
          {allOrdersData?.data?.results?.length > 0 && (
            <div className="bkr-top-card">
              <div className="tc-box-one">
                <div className="cb-1">
                  <label className="check-label" htmlFor="000">
                    <CustomCheckBox
                      style={{
                        minWidth: 18,
                        height: 18,
                        borderColor: "var(--text-grey)",
                        borderRadius: 5,
                        marginRight: 10,
                      }}
                      onChange={handleMultiSelect}
                    />
                    Select all
                  </label>
                </div>
              </div>
              <div className="item-btn-box">
                <button
                  className="btn-accept"
                  onClick={() =>
                    handleBulkUpdate({
                      ids: multiSelect && multiSelect,
                      status: "ACCEPT",
                    })
                  }>
                  Accept
                </button>
                <button
                  className="btn-decline"
                  onClick={() =>
                    handleBulkUpdate({
                      ids: multiSelect && multiSelect,
                      status: "DECLINE",
                    })
                  }>
                  Decline
                </button>
              </div>
            </div>
          )}
          {allOrdersData?.data?.results?.length === 0 &&
            !allOrdersData?.loading && <p>No requests</p>}
          {allOrdersData?.data &&
            allOrdersData?.data?.results?.map((data, idx) => (
              <RequestedItem
                handleGetCustomizedOrders={handleGetCustomizedOrders}
                data={data}
                idx={idx}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default BsRequestedTab;
