import React, {  useState, useContext  } from "react";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/shopImg.png";
import "./index.scss"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import MainHistoryData from "./Data"

const MainHistory = () => {

  return(
    <div className="main-history">
      {MainHistoryData.map(data => (
        <div className="history-container">
          <div className="user">
            <img src={data.avatar} alt="" />
            <p>{data.name}</p>
          </div>

          <div className="date">
            <p> <span>Date: </span>{data.date}</p>
          </div>

          <div className="ref">
            <p>Ref: {data.ref}</p>
          </div>
          <div className="amount">
            <p>Amount</p>
            <div className="amount-price witdrw">
              {data.amount}
            </div>

          </div>

        </div>
      ))}
      {MainHistoryData.map(data => (
        <div className="history-container">
          <div className="user">
            <img src={data.avatar} alt="" />
            <p>{data.name}</p>
          </div>

          <div className="date">
            <p> <span>Date: </span>{data.date}</p>
          </div>

          <div className="ref">
            <p>Ref: {data.ref}</p>
          </div>
          <div className="amount">
            <p>Amount</p>
            <div className="amount-price">
              {data.amount}
            </div>

          </div>

        </div>
      ))}
    </div>
  )
}

export default MainHistory
