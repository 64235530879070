const NewGlobe = () => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="16.781" height="16.781" viewBox="0 0 16.781 16.781">
  <g id="vuesax_outline_global" data-name="vuesax/outline/global" transform="translate(-172 -252)">
    <g id="global" transform="translate(172 252)">
      <path id="Vector" d="M7.516,15.033a7.516,7.516,0,1,1,7.516-7.516A7.523,7.523,0,0,1,7.516,15.033Zm0-13.984a6.468,6.468,0,1,0,6.468,6.468A6.475,6.475,0,0,0,7.516,1.049Z" transform="translate(0.874 0.874)" fill="#490057"/>
      <path id="Vector-2" data-name="Vector" d="M1.537,13.635h-.7a.528.528,0,0,1-.524-.524.521.521,0,0,1,.51-.524,20.62,20.62,0,0,1,0-11.537A.521.521,0,0,1,.313.524.528.528,0,0,1,.837,0h.7a.536.536,0,0,1,.427.217.525.525,0,0,1,.07.475,19.541,19.541,0,0,0,0,12.257.525.525,0,0,1-.07.475A.551.551,0,0,1,1.537,13.635Z" transform="translate(4.756 1.573)" fill="#490057"/>
      <path id="Vector-3" data-name="Vector" d="M.526,13.64a.47.47,0,0,1-.168-.028.521.521,0,0,1-.329-.664A19.541,19.541,0,0,0,.03.691.524.524,0,0,1,1.022.356a20.5,20.5,0,0,1,0,12.914A.52.52,0,0,1,.526,13.64Z" transform="translate(9.962 1.567)" fill="#490057"/>
      <path id="Vector-4" data-name="Vector" d="M6.817,2.063a20.4,20.4,0,0,1-5.768-.825A.525.525,0,0,1,0,1.224v-.7A.536.536,0,0,1,.217.1.525.525,0,0,1,.692.029a19.541,19.541,0,0,0,12.257,0,.525.525,0,0,1,.475.07.517.517,0,0,1,.217.427v.7a.528.528,0,0,1-.524.524.521.521,0,0,1-.524-.51A20.484,20.484,0,0,1,6.817,2.063Z" transform="translate(1.573 9.963)" fill="#490057"/>
      <path id="Vector-5" data-name="Vector" d="M13.113,2.064a.47.47,0,0,1-.168-.028,19.541,19.541,0,0,0-12.257,0,.524.524,0,0,1-.664-.329.533.533,0,0,1,.336-.664,20.5,20.5,0,0,1,12.914,0,.521.521,0,0,1,.329.664A.51.51,0,0,1,13.113,2.064Z" transform="translate(1.571 4.753)" fill="#490057"/>
      <path id="Vector-6" data-name="Vector" d="M0,0H16.781V16.781H0Z" fill="none" opacity="0"/>
    </g>
  </g>
</svg>

    )
}
export default NewGlobe