import { useState, useRef } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import { IoIosPerson, IoIosLink } from "react-icons/all";
import DatePicker from "react-multi-date-picker";
import "./previewQueue.scss";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import { Link } from "react-router-dom";
import itemImg from "../../../assets/img/Rectangle 1890.PNG";
import { IoMdCloseCircleOutline } from "react-icons/all";

const PreviewQueue = () => {
  const testArray = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const slotArray = [1, 2, 3, 4, 5, 6, 7];

  const datePicker = useRef(null);
  const [value, onChange] = useState(new Date());
  return (
    <div className="preview__queue">
      <Link to="/edit_queue" className="eq-btn">
        Edit Queue
      </Link>
      <div className="content">
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the 1500s, when an unknown printer took a galley of type and
        scrambled it to make a type specimen book. It has survived not only five
        centuries, but also the leap into electronic typesetting, remaining
        essentially unchanged. It was popularised in the 1960s with the release
        of Letraset sheets containing Lorem Ipsum passages, and more recently
        with desktop publishing software like Aldus PageMaker including versions
        of Lorem Ipsum.
      </div>
      <div className="dates">
        <div className="schedule">
          <h3>
            <FaCalendarAlt /> Queue Schedule
          </h3>
          <div className="calendar">
            {/* <DatePicker
              multiple={true}
              ref={datePicker}
              style={{
                padding: 20,
              }}
              className="date__picker"
            />
            <div
              className="down"
              onClick={(e) => {
                datePicker.current.focus();
                datePicker.current.click();
              }}>
              <FaAngleDown />
            </div> */}

            <Calendar onChange={onChange} value={value} />
          </div>
        </div>
        <div className="queue__hours">
          <h3>Queue hours</h3>
          <div className="day__time">
            {testArray.map((data, idx) => (
              <div className="items" key={idx}>
                <div className="day">{data}</div>
                <div className="time">
                  <div className="from">08:00 am</div> <p>-</p>
                  <div className="to">11:00 pm</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="queue__slot">
          <div className="slot__header">
            <IoIosPerson size={30} />
            <p>Maximum queue slot:</p>
            <span>12</span>
          </div>
          <div className="slot__body">
            <div className="top__group">
              <IoIosLink />
              <p>Linked Listing:</p>
              <span> 3</span>
            </div>

            <div className="item__scroll">
              {slotArray.map((data, idx) => (
                <div className="qmbc-item-box">
                  <div className="orc-detail-box">
                    <div className="cbcc-item__box">
                      <div className="cbcc-img-holder">
                        <img src={itemImg} alt="" />
                      </div>
                      <div className="cbcc-item-detail-one">
                        <h4> Food and carbage</h4>
                        <h6>
                          Price: <span> $10</span>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {/*<div className="footer">
        <div className="group__1">
          <IoIosPerson size={30} />
          <p>Maximum queue slot:</p>
          <span>12</span>
        </div>
        <div className="group__2">
          <IoIosLink />
          <p>Link to listing:</p>
          <span>2 Items</span>
        </div>
       </div>*/}
      <button className="bk-btn">Done</button>
    </div>
  );
};

export default PreviewQueue;
