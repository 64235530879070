import { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import GroupUserIcon from "../../../../assets/svg/groupUserIcon"
import TimeIcon from "../../../../assets/svg/timeIcon"
import UserIcon from "../../../../assets/svg/userIcon"
import { BusinessPages } from "../../utils/businessPages"
import BusinessPage from "./businessSellerComponents/businessPage"
import NotificationSettings from "./myActvityComponents/notificationSettings"
import PrivateSellerSettings from "./priavateSellerComponents/privateSellerSettings"

const BusinessSettings =({}) => {
    const [pageTitle, setPageTitle] = useState('My Activity')
    const [active, setActive] = useState(1)
    const [isPrivate, setIsPrivate] = useState({
        value: false,
        naming: ''
    })
    const currentUser = useSelector(state=>state.currentUser.user)
    useEffect(()=>{
        currentUser.pages?.map((page) => {
            if(page.type === 'PRIVATE'){
                setIsPrivate({...isPrivate, value: true, naming: page.name})
            }
        })
    }, [])
    const [businessCount, setBusinessCount] = useState()
    useEffect(()=>{
        setBusinessCount(active - 3)
    }, [active])
    console.log(businessCount);
    const [activeState, setActiveState] = useState(1)

    return(
        <div className="businessSettings">
            <h2 className="pageTitle">
                {pageTitle}
            </h2>
            <div className="businessSettings_container">
                <div className="businessSettings_menu">
                    <ul>
                        <li onClick={()=> {
                            setActive(1)
                            setActiveState(1)
                        }} className={ `businessSettings_activity ${active === 1 && 'active'}`}>
                            <div className="yellowDiv"></div>
                            <div className="listContent ">
                                {active === 1 ? <TimeIcon /> : <TimeIcon fill={'#490057'} />} <span>My Activity</span>
                            </div>
                        </li>
                        {isPrivate.value && <li onClick={()=> {
                            setActive(2)
                            setActiveState(1)
                        }} className={`${active === 2 && 'active'} businessSettings_activity normal`}>
                            <div className="yellowDiv"></div>
                            <div className="listContent ">
                                {active === 2 ? <UserIcon fill={'#FBB03B'}/>: <UserIcon fill={'#490057'} />} <span>{isPrivate.naming}</span>
                            </div>
                        </li>}
                        {
                            currentUser.pages?.map((businessPage, idx)=>{
                                if(businessPage.type === 'BUSINESS') return <li className={`${active === idx+3 && 'active'} businessSettings_activity normal`} onClick={()=> {
                                    setActive(idx + 3)
                                    setActiveState(1)
                                }}>
                                    <div className="yellowDiv"></div>
                                    <div className="listContent ">
                                        {active === idx+3 ? <GroupUserIcon fill={'#FBB03B'}/> : <GroupUserIcon fill={'#490057'} />} <span>{businessPage.name}</span>
                                    </div>
                                </li>
                            })
                        }
                    </ul>
                </div>
                <div className="businessSettings_body">
                    {active === 1 && <NotificationSettings />}
                    {active === 2 && <PrivateSellerSettings activeState={activeState} setActiveState={setActiveState}/>}
                    {active > 2 && <BusinessPage activeState={activeState} businessCount={businessCount} setActiveState={setActiveState}/>}
                </div>
            </div>
        </div>
    )
}
export default BusinessSettings