import React, { useState, useContext, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import "./index.scss";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../assets/img/shopImg.png";
import "react-datepicker/dist/react-datepicker.css";
import CustomCheckBox from "../../../components/customCheckBox";
import CustomCheckBoxTop from "./TopCheck";
import NumberInput from "./NumberInput/numberComponent";
import { IoCloseCircleOutline, MdModeEdit } from "react-icons/all";
import { RiCheckboxBlankLine, RiCheckboxBlankFill } from "react-icons/all";
import useCarts from "../../../hooks/carts";
import moment from "moment";

const TopCart = () => {
  const [number, setNumber] = useState({
    quantity: 1,
  });

  const {
    cartListData,
    handleCartList,
    handleDeleteCart,
    handleUpdateCartQuantity,
  } = useCarts();

  console.log(number.quantity);
  const [isCheckAll, setIsCheckAll] = useState(false);
  const [isCheck, setIsCheck] = useState([]);
  // const [isCheck, setIsCheck] = useState(false);
  const [list, setList] = useState([]);
  const [lists, setLists] = useState([]);

  const cartItems = cartListData?.data?.results?.map((item) => {
    return item;
  });

  useEffect(() => {
    if (cartItems?.length) {
      setList(cartItems);
    }
  }, [cartItems?.length]);

  console.log("cartItems=======", cartItems);
  console.log("list=========", list);

  const handleSelectAll = (e) => {
    setIsCheckAll(!isCheckAll);
    setIsCheck(list.map((li) => li.id));
    if (isCheckAll) {
      setIsCheck([]);
    }
  };

  const handleClick = (e) => {
    const { id, checked } = e.target;
    setIsCheck([...isCheck, id]);
    if (!checked) {
      setIsCheck(isCheck.filter((item) => item !== id));
    }
  };

  // const handleChange = (e) => {
  //   const { name, checked } = e.target;
  //   if (name === "allSelect") {
  //     let tempList = lists.map((list) => {
  //       return { ...list, isChecked: checked };
  //     });
  //     setLists(tempList);
  //   } else {
  //     let tempList = lists.map((list) =>
  //       list.id === name ? { ...list, isChecked: checked } : list
  //     );
  //     setLists(tempList);
  //   }
  // };

  useEffect(() => {
    handleCartList();
    console.log(cartListData?.data);
  }, []);

  useEffect(() => {
    setLists(cartItems);
  }, [cartListData?.data]);

  console.log(cartItems);

  const test = (id) => {
    if (id) {
    }
  };

  // console.log(cartItems);

  return (
    <>
      {cartListData.loading && <p style={{ paddingBottom: 400 }}>Loading..</p>}
      {!cartListData.loading && cartListData.error && <p>An Error Occured </p>}

      {!cartListData.loading && !cartListData.error && cartListData?.data && (
        <div className="cart-container">
          <div className="cart-hearder">
            {/* <span onClick={(e) => handleCheckAll()}>
              {!checkAll ? <RiCheckboxBlankFill /> : <RiCheckboxBlankLine />}
            </span> */}
            <div className="cb-1">
              <label className="cb1-label">
                <input
                  type="checkbox"
                  className=""
                  // name="allSelect"
                  // checked={
                  //   lists?.filter((list) => list?.isChecked !== true)?.length < 1
                  // }
                  // onChange={() => handleChange("allSelect")}
                  name="selectAll"
                  id="selectAll"
                  onChange={handleSelectAll}
                  checked={isCheckAll}
                />
                <span className="checkmark"></span>
              </label>
            </div>
            <div className="top-text">
              <p>Seller</p>
              <h3>
                <img src={profileImg} className="seller-img" alt="" />{" "}
                {cartListData?.data?.results?.page?.username}
              </h3>
            </div>
          </div>
          <div className="cart">
            <div className="cart-body-container">
              <div className="hbc-card-body">
                {/*--------- CARD CONTENTS -------------------*/}
                <div className="card-cb-contents">
                  {list?.map((cart, idx) => (
                    <>
                      {cart?.product && (
                        <div className="hbc-cbc-card" key={idx}>
                          <div className="hbc-cbcc-body">
                            <div className="hcb-view-two">
                              <div className="checkbox">
                                <div className="cb-1">
                                  <label className="cb1-label">
                                    <input
                                      type="checkbox"
                                      className=""
                                      id={cart?.id}
                                      name={cart?.id}
                                      // checked={cart?.isChecked || false}
                                      // onChange={handleChange}
                                      onChange={handleClick}
                                      checked={isCheck?.includes(cart.id)}
                                    />
                                    <span className="checkmark"></span>
                                    {/* {id+1} */}
                                  </label>
                                </div>
                                {/* <span onClick={(e) => handleCheckOne()}>
                                  {!checkOne || !checkAll ? (
                                    <RiCheckboxBlankFill />
                                  ) : (
                                    <RiCheckboxBlankLine />
                                  )}
                                </span> */}
                              </div>
                              <div className="cbcc-img-holder">
                                <img
                                  src={cart?.product?.gallary[0].file}
                                  alt=""
                                />
                              </div>
                              <div className="cbcc-item-detail-one">
                                <h4>{cart?.product?.title}</h4>
                                <h5>
                                  Price: <span>${cart?.price} </span>{" "}
                                </h5>
                              </div>
                            </div>
                            <div className="card-view-three">
                              <div className="cbcc-item-detail-two">
                                <h4 className="date-posted">Purchased on</h4>
                                <h5>
                                  {moment(cart?.created_at).format(
                                    "Do MMM YYYY, h:mm a"
                                  )}
                                </h5>

                                <div className="input">
                                  <span className="qty"> Qty:</span>
                                    <NumberInput
                                      // number={number}
                                      // setNumber={setNumber}
                                      // onChange={(e) => {
                                      //   // setNumber({ ...number, quantity: e });
                                      //   handleUpdateCartQuantity(cart.id, e);
                                      // }}
                                      handleUpdateCartQuantity={
                                        handleUpdateCartQuantity
                                      }
                                      cart={cart}
                                    />
                                </div>
                              </div>
                            </div>
                            <div className="hcb-view-five border-green">
                              <div className="cbcc-item-detail-two">
                                <h4>Delivery Address</h4>
                                <h5>{`${cart?.buyer_location?.address} ${cart?.buyer_location?.city} ${cart?.buyer_location?.country}`}</h5>
                                <h4>Delivery Mode </h4>
                                <h5>{cart?.delivery_mode}</h5>
                              </div>
                              <div className="cancel-edit">
                                {/* <span className="cart-cancel">
                            <MdModeEdit />
                          </span> */}
                                <span
                                  className="cart-edit"
                                  onClick={() => handleDeleteCart(cart.id)}
                                >
                                  <IoCloseCircleOutline />
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopCart;
