import { useState, useContext, useEffect } from "react";
import "../index.scss";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../assets/img/Rectangle 1890.PNG";
import { FaSortAmountDownAlt } from "react-icons/fa";
import CustomCheckBox from "../CheckBox";
import ModalContext from "../../../hooks/modalContexts";
import useBookingRequest from "../useBookingRequest";
import useBooking from "../useBooking";
import moment from "moment";

const BookingRequestBody = () => {
  const { handleBookingListModal } = useContext(ModalContext);

  const {
    handleGetBookings,
    bookingsData,
    handleUpdateBookingAcceptStatus,
    acceptBookingData,
    handleUpdateBookingDeclineStatus,
    declineBookingData,
    allBookings,
  } = useBookingRequest();

  // const { acceptBookingData, handleAcceptBooking } = useBooking();

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleGetBookings();
      console.log(bookingsData.data);
    }
    return () => {
      isMounted = false;
    };
  }, []);

  const allBookingRequest = bookingsData?.data?.results?.filter((service) => {
    return (
      service.status === "PENDING" ||
      service.status === "IN_BOOKING_REQUEST" ||
      service.status === "ACCEPT"
    );
  });

  return (
    <div className="bookingreq-body-container">
      <div className="bkr-top-header">
        <button
          className="add-new"
          onClick={() => handleBookingListModal("ADD_DATA", "true")}
        >
          Add new customer to list
        </button>
        <div className="sort-box">
          <p>Sort By</p>
          <div className="sort-input">
            <span> Earliest to Latest </span>
            <FaSortAmountDownAlt color="var(--bg-orange)" />
          </div>
        </div>
      </div>
      <div className="bkr-top-card">
        <div className="tc-box-one">
          <div className="cb-1">
            <label className="check-label" htmlFor="000">
              <CustomCheckBox
                style={{
                  minWidth: 18,
                  height: 18,
                  borderColor: "var(--text-grey)",
                }}
              />
              Select bookings
            </label>
          </div>
        </div>
        <div className="tc-box-two">
          {bookingsData?.data?.results?.status === "PENDING" && (
            <>
              <button className="btn-accept">Accept all</button>
              <button className="btn-decline">Decline</button>
            </>
          )}
        </div>
      </div>

      {bookingsData.loading && <p>Loading...</p>}
      {!bookingsData.loading && bookingsData.error && <p>An error occured </p>}
      {!bookingsData.loading && !bookingsData.error && !bookingsData.data && (
        <h2 className="empty-item"> No Booking Request</h2>
      )}

      {!bookingsData.loading && !bookingsData.error && (
        // <p>{apiResult.results} {console.log(apiResult.results)}</p>
        <></>
      )}

      {!bookingsData.loading &&
        !bookingsData.error &&
        allBookingRequest?.map((service, idx) => (
          <div className="bkr-cbc-card" key={idx}>
            <div className="bkr-cbcc-body">
              <div className="item__detail-box">
                <div className="cb-1">
                  <label className="check-label" htmlFor="001">
                    <CustomCheckBox
                      style={{
                        minWidth: 18,
                        height: 18,
                        borderColor: "var(--text-grey)",
                      }}
                    />
                    {idx + 1}
                  </label>
                </div>
                <div className="orc-detail-box">
                  <img
                    src={service.service.page?.featured_image || profileImg}
                    alt=""
                  />
                  <div className="orc-detail-box-user">
                    <h4>{service.service.page.name}</h4>
                    <h6>@{service.service.page.username} </h6>
                  </div>
                </div>
              </div>
              <div className="cbcc-item__box">
                <div className="item-detail-one">
                  <h4>{service.service.title}</h4>
                </div>
                <div className="item-detail-two">
                  <h5>
                    Time:
                    <span>{moment(service.service_date).format("h:mm a")}</span>
                  </h5>
                  <h5>
                    Date:
                    <span>
                      {moment(service.service_date).format("MMMM Do YYYY")}
                    </span>
                  </h5>
                </div>
              </div>

              <div className="item-btn-box">
                {service.status === "IN_BOOKING_REQUEST" && (
                  <p className="sent-red">Request sent </p>
                )}
                {service.status === "ACCEPT" && (
                  <p className="accept-green">Request Accepted </p>
                )}
                {service.status === "PENDING" && (
                  <>
                    <button
                      className="btn-accept"
                      onClick={(e) => {
                        handleUpdateBookingAcceptStatus(service.id);
                      }}
                    >
                      Accept
                    </button>
                    <button
                      className="btn-decline"
                      onClick={(e) => {
                        handleUpdateBookingDeclineStatus(service.id);
                      }}
                    >
                      Decline
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      {/* {allBookings[0] ? (
        allBookings[0].service.data.count > 0 &&
        allBookings[0].service.data.results.map((service, idx) => (
         <>
         </>
        ))
      ) : (
        <> </>
      )} */}
    </div>
  );
};

export default BookingRequestBody;
