import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/shopImg.png";

const MainHistoryData = [
  {
  id: 1,
  avatar: itemImg,
  name: "Product Name",
  date: "2nd Feb 2021 08:00am",
  ref: "#00920Q8089",
  amount: "$20",
  sent: "9029 **** ****",
},

{
id: 2,
avatar: itemImg,
name: "Product Name",
date: "2nd Feb 2021 08:00am",
ref: "#00920Q8089",
amount: "$20",
sent: "9029 **** ****",
},
{
id: 3,
avatar: itemImg,
name: "Product Name",
date: "2nd Feb 2021 08:00am",
ref: "#00920Q8089",
amount: "$20",
sent: "9029 **** ****",
},
]

export default MainHistoryData
