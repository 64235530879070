import { useState, useEffect, useContext, useR } from "react";
import { BiSearch } from "react-icons/bi";
import CircleIcon from "../../../assets/svg/circleIcon";
import SortIcon from "../../../assets/svg/sortIcon";
import Search from "../../../components/Search/Search";

import Location from "../../../assets/svg/location";
import MapLocation from "../../../assets/svg/mapLocation";
import ArrowUp from "../../../assets/svg/arrowUp";
import People from "../../../assets/svg/people";
import Forward from "../../../assets/svg/forward";
import Bookmark from "../../../assets/svg/bookmark";
import { Eye } from "../../../assets/svg/PostCardIcon";
// import CircleIcon from "../../../assets/svg/circleIcon"
import QueueUtils from "../utils/queue";
import QueueItem from "./queueItem";
import { setModal } from "../../../appstore/userModal/index.action";
import ModalContext from "../../../hooks/modalContexts";
import { orderUtils, referralUtils, bookingUtils } from "../utils/queue";
import DatePicker from "react-multi-date-picker";
import BookingItem from "./bookingItem";
import OrderItem from "./orderItem";
import ReferralItem from "./referralItem";
import { ActivityContext } from "../../../hooks/activity";
import { Spinner } from "reactstrap";
import Loader from "../../../components/loader";

const ActivityQueue = ({
  active,
  handleViewFull,
  Down,
  data,
  loading,
  setDataIndex,
  handler,
  handleGetActivityQueueAndBooking,
}) => {
  const [filter, setFilter] = useState("All");
  const [dropped, setDropped] = useState(false);
  const { handleOrderItemModal } = useContext(ModalContext);
  const [value, setValue] = useState();
  const [valueEnd, setValueEnd] = useState();
  const [filters, setFilters] = useState({});
  console.log(active, value, valueEnd, "DATAAAA");

  useEffect(() => {
    active === "queue" &&
      value &&
      valueEnd &&
      setFilters({
        ...filters,
        created_at__gte: value,
        created_at__lte: valueEnd,
        tab: "myqueueing",
      });
    active === "bookings" &&
      value &&
      valueEnd &&
      setFilters({
        ...filters,
        created_at__gte: value,
        created_at__lte: valueEnd,
        tab: "mybooking",
      });

    active !== "queue" &&
      active !== "bookings" &&
      value &&
      valueEnd &&
      setFilters({
        ...filters,
        created_at__gte: value,
        created_at__lte: valueEnd,
      });
  }, [value, valueEnd]);

  useEffect(() => {
    console.log(filters, "butt click", active);
    active === "queue" &&
      filters &&
      handleGetActivityQueueAndBooking({ ...filters, tab: "myqueueing" });
    active === "bookings" &&
      filters &&
      handleGetActivityQueueAndBooking({ ...filters, tab: "mybooking" });
    active !== "queue" &&
      active !== "bookings" &&
      filters &&
      handler({
        ...filters,
      });
  }, [filters]);
  useEffect(() => {
    setValue();
    setValueEnd();
  }, [active]);
  // const { handleGetActivityQueueAndBooking } = useContext(ActivityContext);
  const [sorted, setSorted] = useState();
  // useEffect(() => {handleQueueItemModal("ADD_QUEUE_ITEM", "something")}, [])
  return (
    <div className="queueSection">
      <h4 className="title">
        {active === "queue" && "My Queue"}
        {active === "bookings" && "My bookings"}
        {active === "order" && "My Order"}
      </h4>
      <div className="queueHeader">
        {active !== "jobs" && (
          <div className="show">
            <p>Show</p>
            <div className="dropdownContainer">
              <div
                onClick={() => setDropped(!dropped)}
                className="dropdownControl"
              >
                <span>{filter}</span>
                <Down />
              </div>
              {dropped && (
                <ul className="dropdownContent">
                  <li
                    onClick={() => {
                      setFilter("All");
                      setDropped(!dropped);
                      setFilters({
                        ...filters,
                        tab:
                          (active === "queue" && "myqueueing") ||
                          (active === "bookings" && "mybooking"),
                      });
                    }}
                  >
                    <span>All</span>
                    <CircleIcon />
                  </li>
                  {active === "queue" && (
                    <>
                      <li
                        onClick={() => {
                          setFilter("Active");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            tab: active === "queue" && "myqueueing",
                            condition: "active",
                          });
                        }}
                      >
                        <span>Active</span>
                        <CircleIcon fill="#00C13A" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("Closed");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            tab: active === "queue" && "myqueueing",
                            condition: "closed",
                          });
                        }}
                      >
                        <span>Closed</span>
                        <CircleIcon fill="#C10000" />
                      </li>
                    </>
                  )}
                  {active === "bookings" && (
                    <>
                      <li
                        onClick={() => {
                          setFilter("Active");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            tab: active === "bookings" && "mybooking",
                            condition: "active",
                          });
                        }}
                      >
                        <span>Active</span>
                        <CircleIcon fill="#00C13A" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("Closed");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            tab: active === "bookings" && "mybooking",
                            condition: "closed",
                          });
                        }}
                      >
                        <span>Closed</span>
                        <CircleIcon fill="#C10000" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("incoming request");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            tab: active === "bookings" && "mybooking",
                            condition: "incoming request",
                          });
                        }}
                      >
                        <span>Incoming request</span>
                        <CircleIcon fill="#066CBA" />
                      </li>
                    </>
                  )}
                  {active === "order" && (
                    <>
                      <li
                        onClick={() => {
                          setFilter("Processing");
                          setDropped(!dropped);
                          setFilters({ ...filters, condition: "Processing" });
                        }}
                      >
                        <span>Processing</span>
                        <CircleIcon fill="#FBB03B" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("Out for delivery");
                          setFilters({
                            ...filters,
                            condition: "Out for Delivery",
                          });
                          setDropped(!dropped);
                        }}
                      >
                        <span>Out for delivery</span>
                        <CircleIcon fill="#00C13A" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("Delivered");
                          setDropped(!dropped);
                          setFilters({ ...filters, condition: "Delivered" });
                        }}
                      >
                        <span>Delivered</span>
                        <CircleIcon fill="#000000" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("Cancelled");
                          setDropped(!dropped);
                          setFilters({ ...filters, condition: "Cancelled" });
                        }}
                      >
                        <span>Cancelled</span>
                        <CircleIcon fill="#C10000" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("Pending Approval");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            condition: "Pending Approval",
                          });
                        }}
                      >
                        <span>Pending Approval</span>
                        <CircleIcon fill={"#066CBA"} />
                      </li>
                    </>
                  )}
                  {active === "referrals" && (
                    <>
                      <li
                        onClick={() => {
                          setFilter("Inactive");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            condition: "inactive",
                          });
                        }}
                      >
                        <span>Inactive</span>
                        <CircleIcon fill="#C10000" />
                      </li>
                      <li
                        onClick={() => {
                          setFilter("Active");
                          setDropped(!dropped);
                          setFilters({
                            ...filters,
                            condition: "active",
                          });
                        }}
                      >
                        <span>Active</span>
                        <CircleIcon fill="#00C13A" />
                      </li>
                    </>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
        <div className="showdate">
          <p className="showdate-title">Show Date</p>
          <div className="calendar">
            <DatePicker format="DD/MM/YYYY" value={value} onChange={setValue} />
            <span>To</span>
            <DatePicker
              format="DD/MM/YYYY"
              value={valueEnd}
              onChange={setValueEnd}
            />
          </div>
        </div>
        <div className="activitySearch">
          <BiSearch />
          <input type="text" />
        </div>
      </div>
      <div className="sort">
        {
          <div className="sortSection">
            <p>Sort by</p>
            {!sorted ? (
              <div
                onClick={() => {
                  console.log("butt click", filters);
                  setSorted(!sorted);

                  active === "queue" &&
                    setFilters({
                      ...filters,
                      tab: "myqueueing",
                      ordering: "-created_at",
                    });
                  active === "bookings" &&
                    setFilters({
                      ...filters,
                      tab: "mybooking",
                      ordering: "-created_at",
                    });

                  active !== "queue" &&
                    active !== "bookings" &&
                    setFilters({ ...filters, ordering: "-created_at" });
                }}
              >
                <span>Earliest to Latest</span>
                <SortIcon />
              </div>
            ) : (
              <div
                onClick={() => {
                  setSorted(!sorted);
                  active === "queue" &&
                    setFilters({
                      ...filters,
                      tab: "myqueueing",
                      ordering: "created_at",
                    });
                  active === "bookings" &&
                    setFilters({
                      ...filters,
                      tab: "mybooking",
                      ordering: "created_at",
                    });
                  active !== "queue" &&
                    active !== "bookings" &&
                    setFilters({ ...filters, ordering: "created_at" });
                }}
              >
                <span>Latest to Earliest</span>
                <SortIcon />
              </div>
            )}
          </div>
        }
      </div>
      <div className="queueList">
        {loading && <Loader />}
        {active === "queue" &&
          !loading &&
          data?.map((queue, idx) => (
            <QueueItem
              idx={idx}
              setDataIndex={setDataIndex}
              data={queue}
              queue={queue}
              active={active}
              handleViewFull={handleViewFull}
            />
          ))}

        {active === "bookings" &&
          !loading &&
          data?.map((booking) => (
            <BookingItem
              booking={booking}
              active={active}
              handleViewFull={handleViewFull}
            />
          ))}
        {active === "order" &&
          !loading &&
          data?.map((orderItem) => {
            return (
              <OrderItem
                handleOrderItemModal={handleOrderItemModal}
                orderItem={orderItem}
                handleViewFull={handleViewFull}
                active={active}
                handler={handler}
              />
            );
          })}
        {active === "referrals" &&
          !loading &&
          data?.map((referralItem, idx) => {
            return (
              <ReferralItem
                referralItem={referralItem}
                handleViewFull={handleViewFull}
                active={active}
                data={data}
                setDataIndex={setDataIndex}
                idx={idx}
              />
            );
          })}
      </div>
      <div className="pageNavigation">
        {/* <span className='prev'>{'<'}</span>
                <span>1</span>
                <span>2</span>
                <span>3</span>
                <span>4</span>
                <span>5</span>
                <span>6</span>
                <span className='next'>{'>'}</span> */}
        <p>View more</p>
      </div>
    </div>
  );
};
export default ActivityQueue;
