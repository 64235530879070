import "./referModal.scss";
import { IoMdClose } from "react-icons/all";
import SearchBar from "../../searchBar";
import SelectedPersons from "../../selectedPersons";
import CustomSelect from "../../customSelect";
import { DefaultButton } from "../../Button";
import { useContext } from "react";
import ModalContext from "../../../hooks/modalContexts";

const ReferModal = () => {
	const context = useContext(ModalContext);
	const exampleUsers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
	return (
		<div
			className="send-as-message"
			onClick={(e) => context.setSendAsMessage()}>
			<form className="main" onClick={(e) => e.stopPropagation()}>
				<div className="box-header">
					<h2>Send As Message</h2>
					<SearchBar />
					<div className="cancel-x" onClick={(e) => context.setReferModal()}>
						<IoMdClose />
					</div>
				</div>
				<SelectedPersons />
				<div className="select-all">
					Select all
					<CustomSelect />
				</div>
				<div className="available-users">
					{exampleUsers.map((data) => {
						return (
							<div className="persons" key={data}>
								<div className="avatar">
									<img src="" alt="" />
								</div>
								<div className="name">
									<div className="full-name">Olakunle Jame</div>
									<div className="username">@username</div>
								</div>
								<div className="select-wrap">
									<CustomSelect />
								</div>
							</div>
						);
					})}
				</div>
				<div className="send-btn">
					<DefaultButton
						customStyle={{
							borderRadius: 30,
							backgroundColor: "var(--dark-purple-bg)",
							padding: "5px 15px",
							fontSize: 14,
							marginTop: 20,
							display: "block",
							marginLeft: "auto",
							marginRight: 14,
						}}
						text="Send"
					/>
				</div>
			</form>
		</div>
	);
};

export default ReferModal;
