import React, { useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import './card.scss'
import Select from 'react-select';
import DropdownIndicator from './FontAwesomeIndicator'
import ExploreContext from '../../../hooks/exploreContext';
import { useEffect } from 'react';
import moment from "moment";
import { toSentenceCase } from '../../../utils/helpers';
import Loader from "../../../components/loader";

const JobCads = () => {
  const {myJobPosts, jobsLoading} = useContext(ExploreContext); 
  const [cardJobs, setCardJobs] = useState();

  useEffect(() => {
    let posts = myJobPosts?.data?.results;
    setCardJobs(posts?.slice(0, 4));
  }, [myJobPosts]);

  return (
    <section className="card_container">
      {
        jobsLoading ?
          <Loader text="Loading..." />
          :
          cardJobs?.length > 0 &&
          cardJobs.map((job, index) => (
            <div className='job-card' key={index}>
              <div className='job-card-left'>
                <div className='job-card-user'>
                  <div className='job-card-user-head'>
                    <div>
                      <img src={job?.gallary[0]?.file} alt='' />
                    </div>
                    <div>
                      <h2>{job?.page?.name}</h2>
                      <p>@{job?.page?.username}</p>
                    </div>
                    <p className="active_status">{toSentenceCase(job?.status)}</p>
                  </div>
                </div>
                <div className='job-card-text'>
                  <h1>{job?.title}</h1>
                  <h3 className='status'><strong>  {toSentenceCase(job?.type)}: </strong>  <span className="salary">${job?.min_salary} - ${job?.max_salary} {toSentenceCase(job?.payment_interval)}</span> </h3>
                  <p className='job-card-p1'>{job?.page?.description}</p>
                  <p className='job-card-p2'>Posted: {moment(job?.created_at).format("LL")}</p>
                  <p className='job-card-p3'>Ends: {moment(job?.end_on).format("LL")}</p>
                </div>
              </div>
              <div className='job-card-right'>
                <div></div>
                <div className='crud_op'>
                  <Select
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator
                    }}
                    options={job?.Options}
                    className="react-select-container"
                    classNamePrefix="react-select"
                    placeholder={"Delete"}
                  />
                </div>
              </div>
            </div>
          ))        
      }

      <Link to="/all-jobs">
          <p className='view'>View all</p>
      </Link>
    </section>
  )
}

export default JobCads
