import React, {  useState, useContext  } from "react";
import PaypalLogo from "../../../../assets/svg/paypal-color-large(1).svg"
import Right from "../../../../assets/svg/arrow-right (1).svg"
import Left from "../../../../assets/svg/arrow-right.svg"
import Edit from "../../../../assets/svg/edit.svg"
import Trash from "../../../../assets/svg/trash-white.svg"
import "./walletPaypal.scss"

const PayPalCard = ({handleEditPaypal, handlePaypalPage}) => {

  return(
      <div className="current-card" >
        <div className="visa-edit">
          <div className="img-delete">
            <img src={Trash} />
          </div>
          <div className="img-edit"
            onClick={handlePaypalPage}
            >
            <img src={Edit} />
          </div>
        </div>
        <div className="paypal-logo">
            <img src={PaypalLogo} />
              <h3 className="paypal-name">Samuel Jackson</h3>
        </div>
    </div>
  )
}

export default PayPalCard
