const CaseBrief = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20.25"
      height="20.25"
      viewBox="0 0 20.25 20.25">
      <path
        id="Icon_awesome-briefcase"
        data-name="Icon awesome-briefcase"
        d="M12.656,15.991a.682.682,0,0,1-.633.723h-3.8a.682.682,0,0,1-.633-.723v-2.17H0V20.33A2.1,2.1,0,0,0,1.9,22.5H18.352a2.1,2.1,0,0,0,1.9-2.17V13.821H12.656Zm5.7-9.4H15.188V4.42a2.1,2.1,0,0,0-1.9-2.17H6.961a2.1,2.1,0,0,0-1.9,2.17v2.17H1.9A2.1,2.1,0,0,0,0,8.759v3.616H20.25V8.759A2.1,2.1,0,0,0,18.352,6.589Zm-5.7,0H7.594V5.143h5.063Z"
        transform="translate(0 -2.25)"
        fill={fill || "#9b9b9b"}
      />
    </svg>
  );
};
export default CaseBrief;
