import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import image from "../../../assets/icons/Group 142.png";
import "./FormSignUp.scss";
import PhoneInput, {
  isPossiblePhoneNumber,
  parsePhoneNumber,
} from "react-phone-number-input";
import { Visibility, VisibilityOff, CalendarToday } from "@material-ui/icons";
import useAuth from "../../../hooks/auth";
import Loader from "../../loader";
// import { FaCalendar } from "react-icons/fa";
import ReactFlagsSelect from "react-flags-select";

function FormSignUp() {
  const intialValues = {
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    date_of_birth: "",
    phone_number: "",
    gender: "MALE",
    password: "",
    confirm_password: "",
    country: "",
    terms_of_service: false,
  };

  const [selected, setSelected] = useState("");

  const [passwordType, setPasswordType] = useState(false);
  const [confirmPasswordType, setConfirmPasswordType] = useState(false);

  const [formValues, setFormValues] = useState(intialValues);

  const { handleRegistration, registrationData } = useAuth();
  const [formErrors, setFormErrors] = useState({});
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handlePhoneInput = (value) => {
    if (!value) {
      return null;
    }
    if (value.length > 4) {
      setFormValues({ ...formValues, phone_number: value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    !Object.keys(validate(formValues)).length && handleRegistration(formValues);
  };

  const validate = (values) => {
    let errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const emailFormatRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const passwrodRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    if (!values.first_name) {
      errors.first_name = "please enter first name";
    }

    if (!values.last_name) {
      errors.last_name = "please enter last name";
    }

    if (!values.username) {
      errors.username = "please enter username";
    }

    if (!values.phone_number) {
      errors.phone_number = "please enter phone number";
    }

    if (values.password !== values.confirm_password) {
      errors.confirm_password = "password does not match";
    }

    if (!values.date_of_birth) {
      errors.date_of_birth = "please enter date of birth";
    }
    if (!values.email) {
      errors.email = "please enter email";
    } else if (!emailFormatRegex.test(values.email.toLowerCase())) {
      errors.email = "Invalid email format";
    }
    if (!values.password) {
      errors.password = "please enter password";
    } else if (!passwrodRegex.test(values.password)) {
      errors.password =
        "min 8 characters, 1 letter, 1 number and 1 special character!";
    } else if (new RegExp(values.username).test(values.password)) {
      errors.password = "Password is similar to username";
    }
    if (
      new Date().getFullYear() - new Date(values.date_of_birth).getFullYear() <
      13
    ) {
      errors.date_of_birth = "Age must be above 12 years";
    }
    return errors;
  };

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmitting) {
  //     submitForm();
  //   }
  // }, [formErrors]);

  // for password
  const toggle_password = () => {
    // setPasswordType({ passwordType: !passwordType });
    setPasswordType((prevState) => !prevState);
  };

  // for confirm password
  const toggle_password2 = () => {
    // setPasswordType({ passwordType: !passwordType });
    setConfirmPasswordType((prevState) => !prevState);
  };

  //parse phone number for country
  console.log(formValues);
  useEffect(() => {
    if (formValues.phone_number) {
      const phoneNumber = parsePhoneNumber(formValues.phone_number);
      setFormValues({ ...formValues, country: phoneNumber?.country });
    }
  }, [formValues.phone_number]);

  //handle checkboxes

  const handleCheckboxes = (field, value) => {
    setFormValues({ ...formValues, [field]: value });
  };

  //clear form errors after seconds

  useEffect(() => {
    Object.keys(formErrors).length &&
      setTimeout(() => {
        setFormErrors({});
      }, 3000);
  }, [formErrors]);

  //handle errors from server

  useEffect(() => {
    if (registrationData.error)
      setFormErrors(registrationData.error?.response.data);
  }, [registrationData.error]);
  return (
    <div className="signUpForm">
      <div id="roundedCircleBottom"></div>

      <div className="main-content">
        <div className="left-img-container">
          <img src={image} alt="" />
        </div>

        <div className="form-container">
          <div className="form-card">
            <div className="form-content">
              <h2 className="form-title">Hi! sign up to meeney</h2>

              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  {/* <label htmlFor="">First name </label> */}
                  <input
                    placeholder="First Name"
                    className="form-input"
                    type="text"
                    name="first_name"
                    value={formValues.first_name}
                    onChange={handleInputChange}
                  />
                  {formErrors.first_name && (
                    <span className="error">{formErrors.first_name}</span>
                  )}
                </div>

                <div className="input-group">
                  {/* <label htmlFor="">Last name </label> */}
                  <input
                    placeholder="Last Name"
                    className="form-input"
                    type="text"
                    name="last_name"
                    value={formValues.last_name}
                    onChange={handleInputChange}
                  />
                  {formErrors.last_name && (
                    <span className="error">{formErrors.last_name}</span>
                  )}
                </div>

                <div className="input-group">
                  {/* <label htmlFor="">Email </label> */}
                  <input
                    className="form-input"
                    placeholder="Email"
                    // type="email"
                    name="email"
                    value={formValues.email}
                    onChange={handleInputChange}
                  />
                  {formErrors.email && (
                    <span className="error">{formErrors.email}</span>
                  )}
                </div>

                <div className="input-group">
                  {/* <label htmlFor="">Create Username </label> */}
                  <input
                    className="form-input"
                    type="text"
                    name="username"
                    placeholder="username"
                    value={formValues.username}
                    onChange={handleInputChange}
                  />
                  {formErrors.username && (
                    <span className="error">{formErrors.username}</span>
                  )}
                </div>

                <div className="input-group">
                  <ReactFlagsSelect
                    selected={selected}
                    onSelect={(code) => setSelected(code)}
                    placeholder="Country"
                    className="form-input fi-location"
                  />

                  {/* {formErrors.username && (
                    <span className="error">{formErrors.username}</span>
                  )} */}
                </div>

                <div className="create-password-container">
                  <label htmlFor="">Create Password </label>

                  <div className="input-group relative">
                    {/* <label htmlFor="">Create Password </label> */}
                    <input
                      placeholder="password"
                      className="form-input input-white"
                      // type="password"
                      name="password"
                      value={formValues.password}
                      onChange={handleInputChange}
                      type={passwordType ? "text" : "password"}
                    />
                    {passwordType ? (
                      <Visibility
                        onClick={() =>
                          setPasswordType((prevState) => !prevState)
                        }
                        className="pwd-toggle-icon"
                        fontSize="small"
                      />
                    ) : (
                      <VisibilityOff
                        onClick={toggle_password}
                        className="pwd-toggle-icon"
                        fontSize="small"
                      />
                    )}

                    {/* <img 
                      className="icon"
                      alt={passwordType ? 'Hide' : "Show"}
                      src={passwordType ? (<Visibility/>) : (<VisibilityOff/>)}
                      onClick={() => setPasswordType(prevState => !prevState )}
                      /> */}
                    {formErrors.password && (
                      <span className="error error__2">
                        {formErrors.password}
                      </span>
                    )}
                  </div>

                  <div className="input-group relative">
                    {/* <label htmlFor="">Confirm Password </label> */}
                    <input
                      placeholder="confirm password"
                      className="form-input input-white"
                      type={confirmPasswordType ? "text" : "password"}
                      name="confirm_password"
                      value={formValues.confirm_password}
                      onChange={handleInputChange}
                    />
                    {confirmPasswordType ? (
                      <Visibility
                        onClick={() =>
                          setConfirmPasswordType((prevState) => !prevState)
                        }
                        className="pwd-toggle-icon"
                        fontSize="small"
                      />
                    ) : (
                      <VisibilityOff
                        onClick={toggle_password2}
                        className="pwd-toggle-icon"
                        fontSize="small"
                      />
                    )}
                    {formErrors.confirm_password && (
                      <span className="error error__2">
                        {formErrors.confirm_password}
                      </span>
                    )}
                  </div>
                </div>

                <div className="input-group-flex">
                  <div className="input-group-md-one">
                    <div className="phone-label">
                      {/* <label htmlFor="">Phone Number</label> */}
                      {/* <span className="optional"> Optional </span> */}
                    </div>
                    <PhoneInput
                      international
                      defaultCountry="US"
                      placeholder="(201) 555-55555"
                      value={formValues.phone_number}
                      onChange={(value) => handlePhoneInput(value)}
                    />
                    {/* <span className="error">
                      {formValues.phone &&
                      isPossiblePhoneNumber(formValues.phone)
                        ? "Valid"
                        : "invalid number"}
                    </span> */}
                  </div>

                  <div className="input-group-md-two relative">
                    {/* <label htmlFor="">Date of Birth </label> */}
                    <input
                      name="date_of_birth"
                      className="form-input"
                      type="date"
                      // placeholder="11-11-2021"
                      value={formValues.date_of_birth}
                      onChange={handleInputChange}
                    />
                    {/* <FaCalendar
                      className="calender-icon"
                        
                      /> */}
                    {formErrors.date_of_birth && (
                      <span className="error error__2">
                        {formErrors.date_of_birth}
                      </span>
                    )}
                  </div>
                </div>

                <div className="input-group">
                  <label htmlFor="">Gender </label>
                  <div className="input-group-gender">
                    <div className="gender-male">
                      <label className="gender-label">
                        <input
                          type="radio"
                          name="gender"
                          checked={formValues.gender === "MALE"}
                          onChange={(e) => handleCheckboxes("gender", "MALE")}
                        />
                        <span className="checkmark"></span>
                        Male
                      </label>
                    </div>

                    <div className="gender-female">
                      <label className="gender-label">
                        <input
                          type="radio"
                          name="gender"
                          checked={formValues.gender === "FEMALE"}
                          onChange={(e) => handleCheckboxes("gender", "FEMALE")}
                        />
                        <span className="checkmark"></span>
                        Female
                      </label>
                    </div>

                    <div className="gender-other">
                      <label className="gender-label">
                        <input
                          type="radio"
                          name="gender"
                          checked={formValues.gender === "OTHER"}
                          onChange={(e) => handleCheckboxes("gender", "OTHER")}
                        />
                        <span className="checkmark"></span>
                        Other
                      </label>
                    </div>
                  </div>
                </div>

                <div className="terms-container">
                  <div className="terms-group-box">
                    <label className="gender-label">
                      <input
                        type="checkbox"
                        name="terms"
                        id="terms"
                        onChange={(e) => {
                          formValues.terms_of_service
                            ? setFormValues({
                                ...formValues,
                                terms_of_service: false,
                              })
                            : handleCheckboxes("terms_of_service", true);
                        }}
                      />
                      <span className="checkmark"></span>
                    </label>
                    <label htmlFor="terms">
                      I agree to the terms and conditions
                    </label>
                  </div>
                  <div className="submit-box">
                    {!registrationData.loading ? (
                      <button className="btn-submit" type="submit">
                        Sign Up
                      </button>
                    ) : (
                      <Loader text="chill.." />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FormSignUp;
