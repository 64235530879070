import "./suggestedListings.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import sampleImg from "../../assets/img/post-2.jpg";
import testPhoto from "../../assets/img/Rectangle 2558@2x.png";
import { FaLocationArrow, FaMapMarkerAlt, FaBookmark } from "react-icons/fa";
import listing from "../../assets/icons/image8.png";
import Progress from "../customProgressbar/progress";
import ArchiveIcon from "../../assets/svg/archiveIcon"

const SuggestedListing = () => {
  const testArray = [1, 2, 3];

  return (
    <div className="suggested__listings">
      <h2>Suggested Listings</h2>
      <div className="suggested-listings-container">
        <Slick dots={true} slidesToShow={1}>
          {testArray.map((data, idx) => (
            <div className="list-card-single">
              <div className="list-card-body">
                <img src={listing} alt="" className="listing-image" />

                <div className="top-details">                 
                  <div className="td-bookmark">
                    <ArchiveIcon />
                  </div>
                </div>

                <div className="listing-details">
                  <div className="listing-inner">
                    <div className="detail-title">Vegetables and prawns</div>

                    <div className="detail-info">
                      <div className="detail-location">
                        <i className="">
                          <FaMapMarkerAlt />
                        </i>
                        <small>Jamaica</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="list-card-footer footer-yellow ">
                <div className="price">
                  <p> $20 </p>
                  <Progress done="70" />
                  <p> 25 Orders Left </p>
                </div>

                <div>
                  <button className="btn-footer btn-white">Pre Order</button>
                </div>
              </div>
            </div>
          ))}
        </Slick>

        <Slick dots={true} slidesToShow={1}>
          {testArray.map((data, idx) => (
            <div className="list-card-single">
              <div className="list-card-body">
                <img src={listing} alt="" className="listing-image" />

                <div className="top-details">
                  <div className="td-title">
                    <p> Deal </p>
                  </div>
                  <div className="td-bookmark">
                    <FaBookmark className="bm-purple" />
                  </div>
                </div>

                <div className="listing-details">
                  <div className="listing-inner">
                    <div className="detail-title">Shoes and Shoes</div>

                    <div className="detail-info">
                      <div className="detail-location">
                        <i className="">
                        <FaMapMarkerAlt />
                        </i>
                        <small>Jamaica</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="list-card-footer footer-purple">
                <div className="price">$200</div>

                <button className="btn-footer btn-yellow">Buy Now</button>
              </div>
            </div>
          ))}
        </Slick>
      </div>
    </div>
  );
};

export default SuggestedListing;
