import { Doughnut } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";


const doughnutData = {
  labels: [
    'withdrawal balance',
    'Nonwithdrawal balance',
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [100, 250],
    backgroundColor: [
      '#066CBA',
      '#3DD598',
    ],
    hoverOffset: 4
  }]
};

const doughnutChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      usePointStyle: true,
      //   backgroundColor: "rgba(255, 255, 255, 1)",
      //   bodyColor: "rgba(126, 132, 163, 1)",
      callbacks: {},
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
      // beginAtZero: true,
    },
  },

  // animation: {
  //   easing: "easeOutBack",
  //
  //   y: {
  //     duration: 3000,
  //     from: 500,
  //   },
  // },
};

const WalletChart = () => {
  return (
    <div>
      <Doughnut
        data={doughnutData}
        options={doughnutChartOptions}
        height={50}
        width={210}
      />
    </div>
  );
};
export default WalletChart;
