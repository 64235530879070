import { useState } from "react";
import { useParams } from "react-router";
import BusinessPageSetup from "./businessPage";
import SecondBusinessPageSetup from "./setupbusinessPage";

const GroupBusinessSetup = () => {
  const [page, setPage] = useState(1);
  const [mainFormData, setMainFormData] = useState({});
  //   console.log(mainFormData);
  return page === 1 ? (
    <BusinessPageSetup {...{ setPage, mainFormData, setMainFormData }} />
  ) : (
    <SecondBusinessPageSetup {...{ setPage, mainFormData, setMainFormData }} />
  );
};
export default GroupBusinessSetup;
