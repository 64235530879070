import { useState, useEffect, useRef } from "react";
import "./queueBody.scss";
import { FaEllipsisV } from "react-icons/fa";

const dropdownData = [
  { label: "Move to top" },
  { label: "Move down" },
  { label: "Set waiting time" },
];

const QueueDropdown = () => {
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(dropdownData);
  const manageQueue = useRef(null);

  const toggleDropdown = () => setOpen(!isOpen);

  //dropdown click outside
  const handleClickOutside = (event) => {
    if (manageQueue.current && !manageQueue.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="options__wrap" ref={manageQueue}>
      <FaEllipsisV className="dropdown-icon" onClick={toggleDropdown} />
      {isOpen && (
        <div className={`dropdown-body ${isOpen && "open"}`}>
          {items.map((item) => (
            <div className="dropdown-item">{item.label}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default QueueDropdown;
