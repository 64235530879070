import useAxios from "axios-hooks";
import { useDispatch } from "react-redux";
import { addListingData } from "../appstore/editListings/index.action";

const useListings = () => {
  const dispatch = useDispatch();
  const [{ ...createListingData }, createListing] = useAxios(
    {
      url: "/products",
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleCreateListing = ({ page, body }) => {
    createListing({
      data: {
        ...body,
        page,
      },
    }).catch((err) => console.log(err));
  };

  const [{ ...shareListingData }, shareListing] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleShareListing = async (id, isPage) => {
    console.log(isPage);
    shareListing({
      url: `/products/${id}/share/`,
      ...(isPage && {
        data: {
          page: isPage,
        },
      }),
    });
  };

  const [{ ...deletedListingData }, deleteListing] = useAxios(
    { method: "delete" },
    { manual: true }
  );

  const handleDeleteListing = (id, data = {}) => {
    deleteListing({
      url: `/products/${id}/`,
      params: {
        ...data,
      },
    });
  };

  const formatListingForEdit = (data) => {
    // console.log(data);
    const toBeEdited = {
      ...data,
      page: data.page.id,
      title: data.title,
      category: {
        name: data.category.name,
      },
      sales_option: "BUY_NOW",
      subcategory: { name: data.subcategory.name },
      description: data.description,
      tags: data.tags,
      accept_order_automatically: data.accept_order_automatically,
      deposit_type: data.deposit_type,
      deposit_amount: data.deposit_amount,
      free_cancelation_date: data.free_cancelation_date,
      listing_end_date: data.listing_end_date,
      who_can_refer: data.who_can_refer,
      stocks: data.stocks,
      referrals: data.referrals,
      allow_referral: data.can_refer,
      delivery_locations: data.delivery_locations,
      contact_seller: data.contact_seller,
      allow_deposit: data.allow_deposit,
    };
    dispatch(addListingData(toBeEdited));
  };
  return {
    handleCreateListing,
    createListingData,
    handleShareListing,
    shareListingData,
    deletedListingData,
    handleDeleteListing,
    formatListingForEdit,
  };
};

export default useListings;
