import "./style.scss";
import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from "react";

const SearchBar = ({
  placeholder = "Search",
  searchfunction = async () => {},
  onResult = () => {},
}) => {
  const [state, setState] = useState("");

  useEffect(() => {
    const debounceFn = setTimeout(async () => {
      //make call
      const result = state.length && (await searchfunction(state));
      onResult(result);
    }, [1000]);

    return () => {
      clearTimeout(debounceFn);
    };
  }, [state]);
  return (
    <div className="search-box">
      <FaSearch className="search-icon" />
      <input
        type="text"
        placeholder={placeholder}
        onChange={(e) => setState(e.target.value)}
      />
    </div>
  );
};
export default SearchBar;
