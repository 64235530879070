const WalletMoney = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <g id="wallet-money" transform="translate(-108 -380)">
        <path
          id="Vector"
          d="M0-.75A.75.75,0,0,1,.75,0c0,2.226,2.114,3.905,4.918,3.905A5.608,5.608,0,0,0,9.193,2.759,3.515,3.515,0,0,0,10.571,0a.75.75,0,0,1,1.5,0V3.214c0,3.129-2.872,5.674-6.4,5.674S-.75,6.343-.75,3.214V0A.75.75,0,0,1,0-.75ZM5.668,7.388c2.569,0,4.683-1.692,4.887-3.835q-.2.195-.42.374A7.108,7.108,0,0,1,5.668,5.405,7.148,7.148,0,0,1,1.189,3.934Q.966,3.754.767,3.559C.974,5.7,3.093,7.388,5.668,7.388Z"
          transform="translate(113.702 406.696)"
          fill="#fbb03b"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M5.668,10.313A6.617,6.617,0,0,1-.037,7.454l0-.007A4.872,4.872,0,0,1-.75,4.908,5.328,5.328,0,0,1,1.153.876,6.8,6.8,0,0,1,5.652-.75,6.871,6.871,0,0,1,10.143.868a5.279,5.279,0,0,1,1.944,4.04,4.872,4.872,0,0,1-.709,2.539A6.593,6.593,0,0,1,5.668,10.313ZM1.243,6.672A5.123,5.123,0,0,0,5.668,8.813a5.1,5.1,0,0,0,4.426-2.141,3.388,3.388,0,0,0,.492-1.764A3.735,3.735,0,0,0,9.178,2.017l-.009-.008A5.371,5.371,0,0,0,5.652.75,5.3,5.3,0,0,0,2.141,2,3.85,3.85,0,0,0,.75,4.908,3.392,3.392,0,0,0,1.243,6.672Z"
          transform="translate(113.684 397.433)"
          fill="#fbb03b"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M5.668-.75a6.87,6.87,0,0,1,4.5,1.623l0,0a5.344,5.344,0,0,1,1.9,4.048V9.278a4.988,4.988,0,0,1-1.936,3.933,7.117,7.117,0,0,1-4.466,1.472,7.137,7.137,0,0,1-4.474-1.478A4.983,4.983,0,0,1-.75,9.278V4.924A5.331,5.331,0,0,1,1.167.875,6.841,6.841,0,0,1,5.668-.75ZM9.183,2.007A5.371,5.371,0,0,0,5.668.75C2.956.75.75,2.623.75,4.924V9.278a3.511,3.511,0,0,0,1.384,2.758,5.637,5.637,0,0,0,3.534,1.147c2.795,0,4.9-1.679,4.9-3.905V4.924A3.865,3.865,0,0,0,9.183,2.007Z"
          transform="translate(113.702 397.417)"
          fill="#fbb03b"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M3.244-.75h3.32A2.37,2.37,0,0,1,8.871,1.615V4.877A2.37,2.37,0,0,1,6.565,7.241H3.434A4.118,4.118,0,0,1-.733,3.6,3.919,3.919,0,0,1,.434.42,3.9,3.9,0,0,1,3.244-.75ZM6.523.75H3.244A2.408,2.408,0,0,0,1.5,1.473l-.013.013A2.425,2.425,0,0,0,.761,3.46v.009a2.368,2.368,0,0,0,.859,1.611,2.814,2.814,0,0,0,1.814.662H6.523a.875.875,0,0,0,.849-.865V1.615A.875.875,0,0,0,6.523.75Z"
          transform="translate(134.712 395.754)"
          fill="#fbb03b"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M22.167,27.667H18.208a.75.75,0,0,1,0-1.5h3.958a7.165,7.165,0,0,0,5.209-1.957A7.165,7.165,0,0,0,29.333,19V17.454H26.98a4.118,4.118,0,0,1-4.167-3.644A4.012,4.012,0,0,1,26.79,9.463h2.543V7.917c0-3.94-2.324-6.657-6.066-7.093L23.231.819A6.445,6.445,0,0,0,22.167.75H7.917A7.714,7.714,0,0,0,6.748.836l-.021,0C3.04,1.3.75,4.014.75,7.917v3.167a.75.75,0,0,1-.75.75.75.75,0,0,1-.75-.75V7.917c0-4.644,2.857-8,7.28-8.565a9.225,9.225,0,0,1,1.386-.1h14.25a8.114,8.114,0,0,1,1.292.086c4.48.529,7.375,3.9,7.375,8.58v2.3a.75.75,0,0,1-.75.75H26.79a2.407,2.407,0,0,0-1.743.723l-.013.013a2.425,2.425,0,0,0-.727,1.973v.009a2.368,2.368,0,0,0,.859,1.611,2.814,2.814,0,0,0,1.814.662h3.1a.75.75,0,0,1,.75.75V19a8.628,8.628,0,0,1-2.4,6.27A8.628,8.628,0,0,1,22.167,27.667Z"
          transform="translate(111.167 385.542)"
          fill="#fbb03b"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M0,0H38V38H0Z"
          transform="translate(146 418) rotate(180)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
export default WalletMoney;
