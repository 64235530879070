const BagCross = () => {
  return (
    <svg
      id="bag-cross"
      xmlns="http://www.w3.org/2000/svg"
      width="29.126"
      height="29.126"
      viewBox="0 0 29.126 29.126"
    >
      <path
        id="Vector"
        d="M3.337,3.337,0,0"
        transform="translate(12.913 17.73)"
        fill="none"
        stroke="#f25151"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M3.337,0,0,3.337"
        transform="translate(12.876 17.767)"
        fill="none"
        stroke="#f25151"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M4.393,0,0,4.405"
        transform="translate(6.298 2.427)"
        fill="none"
        stroke="#f25151"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M0,0,4.393,4.405"
        transform="translate(18.434 2.427)"
        fill="none"
        stroke="#f25151"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M0,2.427C0,.182,1.2,0,2.694,0H21.577c1.493,0,2.694.182,2.694,2.427,0,2.609-1.2,2.427-2.694,2.427H2.694C1.2,4.854,0,5.036,0,2.427Z"
        transform="translate(2.427 7.099)"
        fill="none"
        stroke="#f25151"
        stroke-width="1.5"
      />
      <path
        id="Vector-6"
        data-name="Vector"
        d="M0,0,1.711,10.485C2.1,12.84,3.034,14.563,6.5,14.563h7.318c3.774,0,4.332-1.65,4.769-3.932L20.631,0"
        transform="translate(4.248 12.136)"
        fill="none"
        stroke="#f25151"
        stroke-linecap="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-7"
        data-name="Vector"
        d="M0,0H29.126V29.126H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};

export default BagCross;
