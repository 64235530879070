import Search from "../../../components/Search/Search";
import BusinessLogo from "../../../assets/img/blogo.png";
import Location from "../../../assets/svg/location";
import MapLocation from "../../../assets/svg/mapLocation";
import ArrowUp from "../../../assets/svg/arrowUp";
import ShopImg from "../../../assets/img/shopImg.png";
import People from "../../../assets/svg/people";
import Forward from "../../../assets/svg/forward";
import Bookmark from "../../../assets/svg/bookmark";
import { Eye } from "../../../assets/svg/PostCardIcon";
import CircleIcon from "../../../assets/svg/circleIcon";
import ModalContext from "../../../hooks/modalContexts";
import { useContext, useEffect, useState } from "react";
import NewLocation from "../../../assets/svg/newLocation";
import StarIcon from "../../../assets/svg/star";
import ThreeDots from "../../../assets/svg/threeDots";
import Refer from "../../../assets/svg/refer.png";
import ReactStars from "react-stars";
import moment from "moment";
import Moment from "react-moment";

const QueueItem = ({
  queue,
  active,
  handleViewFull,
  data,
  setDataIndex,
  idx,
}) => {
  const { handleReviewModal } = useContext(ModalContext);
  const [pop, setPop] = useState(false);
  const [queueDate, setQueueDate] = useState();
  useEffect(() => {
    if (data) {
      setQueueDate(moment(data?.service?.createdd_at).format("DD MMM YYYY"));
    }
    console.log(data, "date");
  }, [data]);
  return (
    <div
      onClick={() => {
        handleViewFull();
        setDataIndex(idx);
      }}
      className="queueItem"
    >
      <div className="topPart">
        <div className="profile">
          <img
            className="logo"
            src={
              data?.page?.featured_image
                ? data?.page?.featured_image
                : BusinessLogo
            }
            alt="logo"
          />
          <div>
            <p>{data?.page?.name}</p>
            <p className="bottom">
              {data?.page?.username ? data?.page?.username : "@Username"}
            </p>
          </div>
        </div>
        <img
          src={data?.service?.gallary[0] ? data?.service?.gallary[0] : ShopImg}
          alt=""
        />
      </div>
      <div className="bottomPart">
        <div onClick={(e) => e.stopPropagation()} className="other">
          <div
            className="queueItemReview"
            onClick={() => {
              handleReviewModal("ADD_DATA", true);
              console.log("clicked");
            }}
          >
            <div className="top">
              <div className="stars">
                <ReactStars
                  count={5}
                  size={17}
                  color2={"#FBB03B"}
                  value={data?.service?.rating}
                />
              </div>
              <span>{data?.service?.rating}.0</span>
            </div>
            <p>{data?.service?.rating_count} reviews</p>
          </div>
          <div className="location">
            <div className="top">
              <NewLocation />
              <p>{data?.page?.location?.address}</p>
            </div>
            <p>Posted on {queueDate ? queueDate : "2nd feb"}</p>
          </div>
          <div className="tag">
            <p>{data?.page?.type} PAGE</p>
          </div>
          <div onClick={() => setPop(!pop)} className="threeDotDivs">
            <ThreeDots fill={"#490057"} height={20} width={8} />
            {pop && (
              <div className="popUp">
                <a href="">
                  <Forward />
                  <span>Share</span>
                </a>
                {data?.service?.can_refer && (
                  <a href="">
                    <img src={Refer} alt="" />
                    <span>Refer</span>
                  </a>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="queueActions">
          <div className="leftActivity">
            <div className="queueDetails">
              <div className="queueTitle">
                <p className="topTitle">{data?.description}</p>
                {active !== "referrals" && (
                  <p className="bottomTitle">
                    Queued on{" "}
                    {data?.queue_joined_datetime
                      ? moment(data?.queue_joined_datetime).format(
                          "DD MMM YYYY"
                        )
                      : ""}
                  </p>
                )}
              </div>

              <div className="time">
                {active !== "referrals" && (
                  <>
                    <div className="queueTime">
                      <p>Time</p>
                      <p>
                        {data?.queue_joined_datetime &&
                          moment(data?.queue_joined_datetime).format("hh a")}
                        -
                        {data?.queue_joined_datetime &&
                          moment(data?.queue_left_datetime).format("hh a")}
                      </p>
                    </div>
                    {/* <div className="price">
                      <p>{`$${data?.price ? data?.price : "0"}`}</p>
                    </div> */}
                    <div className="status">
                      {data?.condition === "Closed" ? (
                        <CircleIcon fill="red" />
                      ) : (
                        <CircleIcon fill="#00C13A" />
                      )}

                      <p
                        className={
                          data?.condition === "Closed" ? "red" : "green"
                        }
                      >
                        {data?.condition === "Closed" ? "Closed" : "Active"}
                      </p>
                    </div>
                  </>
                )}
                {active === "referrals" && (
                  <>
                    <div>
                      <p>Price</p>
                      <p>{data?.price ? data?.price : "$20"}</p>
                    </div>
                    <div>
                      <p>Quantity</p>
                      <p>200</p>
                    </div>
                    <div>
                      <p>Total Commission Earned</p>
                      <p>$20</p>
                    </div>
                    <div>
                      <p>No.Referred</p>
                      <p>20</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rightActivity">
            <div
              className={`topActions ${
                active === "referrals" && "whenReferrals"
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {/* {!queue.incoming &&<>
                            {active === 'order' || active ==='referrals' && <Eye fill={'#490057'}/>}
                                <People />
                                <Forward />
                                {active !=='referrals' && <Bookmark />}
                            </>} */}
              {active !== "referrals" && (
                <div
                  onClick={() => {
                    handleViewFull();
                    setDataIndex(idx);
                  }}
                  className="create"
                >
                  {/* <Eye fill= {'#fbb03b'}/> */}
                  <span>View full</span>
                </div>
              )}
              {/* {!queue.incoming && active !=='referrals' &&<div className="requeue">
                                <span>
                                    Queue again
                                </span>
                            </div>} */}
              {queue.incoming && (
                <div className="permission">
                  <a href="" className="accept create">
                    Accept
                  </a>
                  <a href="" className="reject">
                    Decline
                  </a>
                </div>
              )}
            </div>
            <div className="bottomOptions"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QueueItem;
