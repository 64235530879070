import Eye from "../assets/iconsax/postOptionsIcons/VuesaxLinearEyeComponent";
import CopyLink from "../assets/iconsax/postOptionsIcons/VuesaxLinearDocumentCopyComponent";
import Pin from "../assets/iconsax/postOptionsIcons/VuesaxLinearFlagComponent";
import ReportPost from "../assets/iconsax/postOptionsIcons/VuesaxLinearWarningComponent";
import DeletePost from "../assets/iconsax/postOptionsIcons/VuesaxLinearTrashComponent";
import VuesaxLinearArchiveMinusComponent from "../assets/iconsax/VuesaxLinearArchiveMinusComponent";

const postCardOptions = [
  {
    name: "Hide",
    icon: Eye,
  },
  // {
  //   name: "Save",
  //   icon: VuesaxLinearArchiveMinusComponent,
  // },
  {
    name: "Copy",
    icon: CopyLink,
  },
  {
    name: "Pin",
    icon: Pin,
    private: true,
  },
  {
    name: "Report Post",
    icon: ReportPost,
  },
  {
    name: "Delete Post",
    icon: DeletePost,
    private: true,
  },
];
export const profileView = [
  {
    name: "Copy",
    icon: CopyLink,
  },

  {
    name: "Report Post",
    icon: ReportPost,
  },
];

export default postCardOptions;
