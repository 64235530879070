/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxOutlineHeartTickComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>{".heartA{fill:#490057;}.b{fill:none;opacity:0;}"}</style>
    </defs>
    <g transform="translate(-684 -188)">
      <path
        className="heartA"
        d="M5.55,11.1A5.539,5.539,0,0,1,.8,8.4,5.446,5.446,0,0,1,0,5.55a5.55,5.55,0,0,1,11.1,0,5.459,5.459,0,0,1-.81,2.87A5.536,5.536,0,0,1,5.55,11.1Zm0-9.6A4.059,4.059,0,0,0,1.5,5.55,4.062,4.062,0,0,0,5.55,9.6,3.978,3.978,0,0,0,9.01,7.64,3.955,3.955,0,0,0,9.6,5.55,4.059,4.059,0,0,0,5.55,1.5Z"
        transform="translate(695.65 199.65)"
      />
      <path
        className="heartA"
        d="M1.927,3.868a.742.742,0,0,1-.53-.22L.218,2.468a.75.75,0,1,1,1.06-1.06l.67.67L3.978.2A.75.75,0,1,1,5,1.3l-2.56,2.36A.687.687,0,0,1,1.927,3.868Z"
        transform="translate(698.583 203.262)"
      />
      <path
        className="b"
        d="M0,0H24V24H0Z"
        transform="translate(708 212) rotate(180)"
      />
      <path
        className="heartA"
        d="M10.75,19.3a2.65,2.65,0,0,1-.87-.13C7.32,18.3,0,14.06,0,6.34a6.3,6.3,0,0,1,10.75-4.5A6.3,6.3,0,0,1,21.5,6.34a11.743,11.743,0,0,1-1.4,5.57.741.741,0,0,1-.56.39.758.758,0,0,1-.65-.23,4.041,4.041,0,0,0-5.95,5.47.738.738,0,0,1,.17.65.778.778,0,0,1-.42.53c-.4.18-.76.33-1.09.44A2.264,2.264,0,0,1,10.75,19.3ZM6.31,1.5A4.831,4.831,0,0,0,1.5,6.34c0,6.84,6.57,10.62,8.87,11.41a1.467,1.467,0,0,0,.75,0l.09-.03a5.459,5.459,0,0,1-.81-2.87,5.544,5.544,0,0,1,8.79-4.5A10.226,10.226,0,0,0,20,6.34a4.8,4.8,0,0,0-8.65-2.91.774.774,0,0,1-1.2,0A4.812,4.812,0,0,0,6.31,1.5Z"
        transform="translate(685.25 190.35)"
      />
    </g>
  </svg>
);

export default VuesaxOutlineHeartTickComponent;
