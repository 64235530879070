import AddIcon from "../../assets/svg/addIcon";
import { GrSubtractCircle } from "react-icons/all";

const Cards = ({ interestOption, setData, isSelected }) => {
  return (
    <div className="tabContainerItem">
      <div className="top">
        <img src={interestOption.image} alt="" />
      </div>
      <div className="bottom">
        <span>{interestOption.name}</span>
        <span onClick={(e) => setData(interestOption.id, interestOption.categ)}>
          {isSelected ? <GrSubtractCircle /> : <AddIcon />}
        </span>
      </div>
    </div>
  );
};

export default Cards;
