const ListingBusinessNotificationItem = ({listingNotificationItem, dataEntered, setDataEntered, idx}) => {
    return(
        <li>
            <span>{listingNotificationItem.title}</span>
            <div className={`switch ${listingNotificationItem.value === false  && 'off'}`} 
            onClick={()=>{
                
                setDataEntered({...dataEntered, listing:dataEntered.listing.map((data)=>{
                    let indexNo = dataEntered.listing.indexOf(data)
                    if(indexNo === idx){
                        return data.value ? {...data, value: false}: {...data, value: true}
                    }
                    return data
                })})
            }}>
                <span className={`${listingNotificationItem.value === false && 'offSpan'}`} ></span>
            </div>
        </li>
    )
}
export default ListingBusinessNotificationItem