/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxOutlineSendComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 20.239 20.239" {...props}>
    <defs>
      <style>
        {".a-send-icon{fill:#fff;}.b-send-icon{fill:none;opacity:0;}"}
      </style>
    </defs>
    <g transform="translate(-300 -316)">
      <g transform="translate(300 316)">
        <path
          className="a-send-icon"
          d="M9.985,16.254c-1,0-2.4-.7-3.517-4.048l-.607-1.822L4.039,9.777C.7,8.664,0,7.256,0,6.261s.7-2.4,4.039-3.525L11.2.349c1.788-.6,3.28-.422,4.2.489s1.1,2.412.5,4.2L13.51,12.2C12.388,15.554,10.98,16.254,9.985,16.254ZM4.436,3.941c-2.344.784-3.179,1.712-3.179,2.319S2.091,7.8,4.436,8.571l2.125.708a.619.619,0,0,1,.4.4L7.666,11.8c.776,2.344,1.712,3.179,2.319,3.179S11.52,14.145,12.3,11.8l2.387-7.16c.43-1.3.354-2.361-.194-2.909s-1.611-.616-2.9-.186Z"
          transform="translate(2.007 1.987)"
        />
        <path
          className="a-send-icon"
          d="M.63,4.29A.626.626,0,0,1,.183,4.1a.636.636,0,0,1,0-.894L3.2.183a.632.632,0,0,1,.894.894L1.077,4.1A.612.612,0,0,1,.63,4.29Z"
          transform="translate(7.895 7.853)"
        />
        <path className="b-send-icon" d="M0,0H20.239V20.239H0Z" />
      </g>
    </g>
  </svg>
);

export default VuesaxOutlineSendComponent;
