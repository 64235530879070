import { Refferal } from "../../../../assets/svg/FeedOptionsIcon";
import profileImg from "../../../../assets/img/Ellipse 357.png";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import findValue from "../helper/findValue";
import moment from "moment";

const HistoryItem = ({ data }) => {
  return (
    <div className="hbc-cbc-card">
      <div className="hbc-cbcc-body">
        <div className="hcb-view-one">
          <div className="orc-detail-box">
            <div>
              {data?.transactions.reason === "REFERRAL" && (
                <Refferal className="ref-icon" />
              )}
            </div>
            <div className="orc-db">
              <img src={data?.owner?.profile_image?.file} alt="" />
              <div className="orc-detail-box-user">
                <h4>{data?.owner?.full_name}</h4>
                <h6>{data?.owner?.username}</h6>
              </div>
            </div>
          </div>
        </div>

        <div className="hcb-view-two">
          <div className="cbcc-img-holder">
            <img src={data?.product?.gallary?.[0]?.file} alt="" />
          </div>
          <div className="cbcc-item-detail-one">
            <h4>{data?.product?.title}</h4>
            <h5>
              Price: <span>${data?.price}</span>
            </h5>
            {/* <h5>
                    Qty: <span>2 </span>
                  </h5> */}
          </div>
        </div>

        <div className="hcb-view-three">
          <div className="cbcc-item-detail-two">
            <h4>Date Posted</h4>
            <h5>{moment(data?.product?.created_at).format("DD MMMM YYYY")}</h5>
            <h4>Time</h4>
            <h5>{moment(data?.product?.created_at).format("hh:mm a")}</h5>
          </div>
        </div>
        <div className="hcb-view-four">
          <div className="cbcc-item-detail-two">
            <h4>Delivery Address</h4>
            <h5>{data?.buyer_location?.address}</h5>
            <h4>Delivery Mode </h4>
            <h5>{data?.delivery_mode}</h5>
            <h4>Delivery on</h4>
            <h5>
              {data?.order_date &&
                moment(data.order_date).format("DD MMMM YYYY")}
            </h5>
          </div>
        </div>
        {data?.transactions[0].reason === "REFERRAL" && (
          <div className="hcb-view-five border-green">
            <div className="cbcc-item-detail-two">
              <h4>Affiliate marketer</h4>
              <h5>{data?.transactions?.[0]?.owner?.full_name}</h5>
              <h4>Commision </h4>
              <h5>${data?.transactions?.[0]?.amount}</h5>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default HistoryItem;
