import React, { Component, createRef } from "react";
import { FaTimes, FaUpload } from "react-icons/fa";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "swiper/swiper-bundle.css";
import "./Upload.scss";
// import Slick from "react-slick";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

export default class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      file: [],
    };
    this.uploadMultipleFiles = this.uploadMultipleFiles.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.handleImageRemoval = this.handleImageRemoval.bind(this);
    this.getMediaType = this.getMediaType.bind(this);
    this.mediaTypes = this.mediaTypes.bind(this);
    this.inputRef = createRef();
  }
  mediaTypes = () => ({
    image: "image",
    video: "video",
  });
  uploadMultipleFiles(e) {
    FileList.prototype.map = Array.prototype.map;

    this.setState({
      file: [...this.state.file, ...e.target.files],
    });
  }

  uploadFiles(e) {
    e.preventDefault();
    console.log(this.state.file);
  }

  componentDidMount(prevProps, prevState) {
    this.props.getState(this.state);
  }
  componentDidUpdate() {
    this.props.getState(this.state);
    this.props.getInputRef(this.inputRef.current);
    console.log(this.inputRef);
  }

  handleImageRemoval = (item) => {
    const { file } = this.state;
    this.setState({
      file: file
        .map((media) => media !== item && media)
        .filter((data) => data && data),
    });
  };
  getMediaType = (type) => {
    if (
      type === "image/jpeg" ||
      type === "image/png" ||
      type === "image/gif" ||
      type === "image/jpg"
    ) {
      return this.mediaTypes().image;
    } else {
      return this.mediaTypes().video;
    }
  };

  render() {
    const { file } = this.state;
    // console.log(this.state);
    return (
      <>
        {/* <Swiper
          spaceBetween={10}
          slidesPerView={3}
          navigation
          pagination={{ clickable: true }}
          className="upload-swiper">
          {fileArray.map((url) => (
            <SwiperSlide className="Upload__Image">
              <img src={url} alt="..." />
            </SwiperSlide>
          ))}
        </Swiper> */}

        <Carousel showThumbs={false}>
          {file.map((item, idx) => {
            const media = URL.createObjectURL(item);
            const mediaType = this.getMediaType(item.type);
            return (
              <div
                className="media"
                key={idx}
                style={{ backgroundColor: "rgba(0,0,0, 0.3)" }}>
                {mediaType === this.mediaTypes().image ? (
                  <img
                    src={media}
                    alt=""
                    // {...(file.length <= 2 && { width: "100%" })}
                  />
                ) : (
                  <video src={media} controls />
                )}
                <div
                  className="close"
                  onClick={(e) => this.handleImageRemoval(item)}>
                  <FaTimes />
                </div>
              </div>
            );
          })}
        </Carousel>

        {/* <Slick dots={true} slidesToShow={file.length >= 3 ? 2 : 1}>
          {file.map((item, idx) => {
            const media = URL.createObjectURL(item);
            const mediaType = this.getMediaType(item.type);
            return (
              <div className="media" key={idx}>
                {mediaType === this.mediaTypes().image ? (
                  <img
                    src={URL.createObjectURL(item)}
                    alt=""
                    {...(file.length <= 2 && { width: "100%" })}
                  />
                ) : (
                  <video src={URL.createObjectURL(item)} />
                )}
                <div
                  className="close"
                  onClick={(e) => this.handleImageRemoval(item)}>
                  <FaTimes />
                </div>
              </div>
            );
          })}
        </Slick> */}

        <div className="upload-wrap">
          <input
            type="file"
            onChange={this.uploadMultipleFiles}
            multiple
            className="Upload-But"
            id="files"
            accept="image/*, video/*"
            ref={this.inputRef}
          />
          {!file.length && (
            <label htmlFor="files">
              {" "}
              <FaUpload />
              Pictures / Videos
            </label>
          )}
        </div>
      </>
    );
  }
}
