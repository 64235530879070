/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxOutlineDirectboxSendComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 40.801 40.801" {...props}>
    <defs>
      <style>{".a{fill:#490057;}.b{fill:none;opacity:0;}"}</style>
    </defs>
    <g transform="translate(-300 -188)">
      <g transform="translate(300 188)">
        <path
          className="a"
          d="M4.671,12.748A1.284,1.284,0,0,1,3.4,11.473V4.35L2.172,5.574a1.274,1.274,0,0,1-1.8-1.8l3.4-3.4A1.28,1.28,0,0,1,5.164.1a1.26,1.26,0,0,1,.782,1.173v10.2A1.284,1.284,0,0,1,4.671,12.748Z"
          transform="translate(15.73 2.128)"
        />
        <path
          className="a"
          d="M4.671,5.946a1.261,1.261,0,0,1-.9-.374l-3.4-3.4a1.274,1.274,0,0,1,1.8-1.8l3.4,3.4a1.283,1.283,0,0,1,0,1.8A1.261,1.261,0,0,1,4.671,5.946Z"
          transform="translate(19.13 2.129)"
        />
        <path
          className="a"
          d="M23.375,19.55H9.775C0,19.55,0,14.365,0,9.775v-1.7C0,4.284,0,0,8.075,0A5.291,5.291,0,0,1,11.9,1.275a1.38,1.38,0,0,1,.17.153L13.8,3.264a3.93,3.93,0,0,0,5.576,0l1.734-1.836a1.533,1.533,0,0,1,.17-.153A5.218,5.218,0,0,1,25.109,0c8.075,0,8.075,4.284,8.075,8.075v1.7C33.151,16.269,29.87,19.55,23.375,19.55Zm-15.3-17C2.55,2.55,2.55,4.284,2.55,8.075v1.7c0,4.658,0,7.225,7.225,7.225h13.6c5.066,0,7.225-2.159,7.225-7.225v-1.7c0-3.791,0-5.525-5.525-5.525a2.784,2.784,0,0,0-2.21.7L21.216,5a6.372,6.372,0,0,1-9.282,0L10.285,3.247A2.784,2.784,0,0,0,8.075,2.55Z"
          transform="translate(3.825 19.125)"
        />
        <path
          className="a"
          d="M1.275,12.674A1.284,1.284,0,0,1,0,11.4V8C0,4.7,0,.6,6.256.009a1.281,1.281,0,1,1,.238,2.55C2.55,2.916,2.55,4.514,2.55,8v3.4A1.284,1.284,0,0,1,1.275,12.674Z"
          transform="translate(7.225 9.001)"
        />
        <path
          className="a"
          d="M6.364,12.674A1.284,1.284,0,0,1,5.089,11.4V8c0-3.485,0-5.083-3.944-5.457A1.276,1.276,0,0,1,.006,1.148,1.255,1.255,0,0,1,1.4.009C7.656.6,7.656,4.7,7.656,8v3.4A1.311,1.311,0,0,1,6.364,12.674Z"
          transform="translate(25.936 9.001)"
        />
        <path className="b" d="M0,0H40.8V40.8H0Z" />
      </g>
    </g>
  </svg>
);

export default VuesaxOutlineDirectboxSendComponent;
