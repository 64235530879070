import { useState } from "react"
import PrivateSellerDeliverySettings from "./privateSellerDeliverySetting"
import PrivateSellerNotificationSettings from "./privateSellerNotificationSettings"

const PrivateSellerSettings = ({activeState, setActiveState}) => {
    return(
        <div className="privateSeller_body">
            <div className="privateSeller_tabs">
                <p onClick={()=> setActiveState(1)} className={`${activeState === 1 && 'active'}`}>
                    Notification Settings
                    {
                        activeState === 1 && <span className="underline"></span>
                    }
                </p>
                <p onClick={()=> setActiveState(2)} className={`${activeState === 2 && 'active'}`}>
                    Delivery Setting
                    {
                        activeState === 2 && <span className="underline"></span>
                    }
                </p>
            </div>
            <hr />
            <div className="privateSeller_tabContent">
                {
                    activeState === 1 && <PrivateSellerNotificationSettings active= {activeState}/>
                }
                {
                    activeState === 2 && <PrivateSellerDeliverySettings active= {activeState}/>
                }
            </div>
        </div>
    )
}
export default PrivateSellerSettings