import React, { useEffect, createContext, useState, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useAxios from "axios-hooks";
import ModalContext from "./modalContexts";
import ExploreContext from "./exploreContext";

const usePrivateAnalytics = () => {
  //  Privateseller ANALYTICS LISTING Interactions
  const [
    { ...privatesellerAnalyticsInteractionData },
    getPrivatesellerAnalyticsInteraction,
  ] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetPrivatesellerAnalyticsInteraction = async (
    page_id,
    start_date,
    end_date
  ) => {
    await getPrivatesellerAnalyticsInteraction({
      url: `/analytics/private/${page_id}/interactions/`,
      params: {
        sudo: true,
        start_date: start_date,
        end_date: end_date,
      },
    }).catch((error) => console.log(error));
  };

  //  Privateseller ANALYTICS LISTING Interactions
  // const [
  //   { ...privatesellerAnalyticsInteractionDateData },
  //   getPrivatesellerAnalyticsInteractionDate,
  // ] = useAxios(
  //   {
  //     method: "GET",
  //   },
  //   {
  //     manual: true,
  //   }
  // );

  // const handleGetPrivatesellerAnalyticsInteractionDate = async (
  //   page_id,
  //   start_date,
  //   end_date
  // ) => {
  //   await getPrivatesellerAnalyticsInteractionDate({
  //     url: `/analytics/private/${page_id}/interactions/`,
  //     params: {
  //       start_date: start_date,
  //       end_date: end_date,
  //     },
  //   }).catch((error) => console.log(error));
  // };

  //  PRIVATESELLER ANALYTICS CHART
  const [
    { ...privatesellerAnalyticsChartData },
    getPrivatesellerAnalyticsChart,
  ] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetPrivatesellerAnalyticsChart = async (
    page_id,
    start_date,
    end_date
  ) => {
    await getPrivatesellerAnalyticsChart({
      url: `/analytics/private/${page_id}/charts/`,
      params: {
        sudo: true,
        start_date: start_date,
        end_date: end_date,
      },
    }).catch((error) => console.log(error));
  };

  //  PRIVATESELLER ANALYTICS PAGE INTERACTIONS
  const [
    { ...privatesellerAnalyticsPageInteractionData },
    getPrivatesellerAnalyticsPageInteraction,
  ] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetPrivatesellerAnalyticsPageInteraction = async (
    page_id,
    start_date,
    end_date
  ) => {
    await getPrivatesellerAnalyticsPageInteraction({
      url: `/analytics/private/${page_id}/page_interaction/`,
      // url: `/analytics/private/${page_id}/page_interaction/`,
      params: {
        sudo: true,
        start_date: start_date,
        end_date: end_date,
      },
    }).catch((error) => console.log(error));
  };

  return {
    privatesellerAnalyticsInteractionData,
    handleGetPrivatesellerAnalyticsInteraction,
    privatesellerAnalyticsChartData,
    handleGetPrivatesellerAnalyticsChart,
    privatesellerAnalyticsPageInteractionData,
    handleGetPrivatesellerAnalyticsPageInteraction,
    // privatesellerAnalyticsInteractionDateData,
    // handleGetPrivatesellerAnalyticsInteractionDate,
  };
};

export default usePrivateAnalytics;
