import { useState, useContext, useRef } from "react";
import ModalContext from '../../../../../hooks/modalContexts';
import ThreeDots from "../../../../../assets/svg/threeDots"
import "./index.scss";

function MenuCard({
  handleDelete, 
  handleClickOutside,
  pop,
  setPop,
  toggleDropdown,
  dropdownContainer, 
}) {

  const { handleEditProfileModal } = useContext(ModalContext);

 
  return (
    <>

    <div className="menu-dropdown-card" ref={dropdownContainer}>
        <div onClick={toggleDropdown} className="threeDotDivs">
            <ThreeDots fill={'#787878'} height={20} width={8}/>
            {pop && <div className="popUp">
                <div  onClick={() =>{
                    handleEditProfileModal('ADD_EDIT_PROFILE_TO_DISPLAY', 'true');
                    // handleEditPage();
                  }}>
                    <span>
                        Rename
                    </span>
                </div>
                <div >
                    <span onClick={handleDelete}>
                        Delete
                    </span>
                </div>
            </div>}
        </div>
      </div>
    </>
  )
}

export default MenuCard
