import moment from "moment";
import photoPic from "../../../../../assets/img/Ellipse 357.png";
const ViewAll = ({ data }) => {
  const testArray2 = [1, 2, 3, 4];

  return (
    <div className="view-all-contents">
      {data?.map((data, idx) => (
        <div className="vac-box">
          <div className="vac-item-detail">
            <img src={photoPic} alt="" />
            <div className="vac-item-detail-one">
              <h4>{data?.owner?.full_name}</h4>
              <h5>@{data?.owner?.username}</h5>
            </div>
          </div>

          <div className="vac-item-detail-two">
            <div>
              <h6>Pre order on</h6>
              <h5>
                Date:{" "}
                <span> {moment(data?.order_date).format("DD MMM YYYY")}</span>
              </h5>
            </div>
          </div>
          <div className="vac-item-detail-two">
            <div>
              <h6>Qty</h6>
              <p> {data?.quantity} </p>
            </div>
          </div>

          <div className="vac-item-detail-two">
            <div>
              <h6>Price</h6>
              <p> ${data?.price} </p>
            </div>
          </div>
          <div className="vac-btn-box">
            <button className="vac-btn">view detail</button>
          </div>
        </div>
      ))}
    </div>
  );
};
export default ViewAll;
