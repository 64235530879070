import React from "react";
import { Col, Row } from "reactstrap";
import { dummyData, Sort } from "./products";
import CustomCard from "./constants";
import { colors } from "../../../color";
import { DropdownToggle } from "reactstrap";
import { ExpandMore } from "@material-ui/icons";
import { DropDownBtn } from "./products";

class AllListings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col>
        <Col xl={5}>
          <div className="d-flex justify-content-between align-items-center">
            <Sort>Sort</Sort>
            <DropDownBtn>
              <DropdownToggle
                style={{
                  width: "100%",
                  background: "transparent",
                  height: "100%",
                }}
                className="d-flex justify-content-between align-items-center"
              >
                <p style={{ color: `${colors.COMPANY_COLOR}`, margin: "auto" }}>
                  Price Low-High
                </p>{" "}
                <ExpandMore style={{ color: `${colors.ORANGE}` }} />
              </DropdownToggle>
            </DropDownBtn>
          </div>
        </Col>
        <Row>
          {dummyData.map((data, i) => (
            <Col className="mt-4" xl={4} key={i}>
              <CustomCard
                picture={data.picture}
                seller={data.seller}
                type={data.type}
                discount={data.discount}
                price={data.price}
                description={data.description}
                location={data.location}
              />
            </Col>
          ))}
        </Row>
      </Col>
    );
  }
}

export default AllListings;
