export function toSentenceCase(string) {
    const newStr = string.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');
    return newStr;
}

export function toSentenceCaseUnderscore(string) {
    let newStr = string.split(' ')
        .map(w => w[0].toUpperCase() + w.substring(1).toLowerCase())
        .join(' ');

    newStr = newStr.replace("_", " ");
    return newStr;
}


export const encodeBase64 = (data) => {
    return Buffer.from(data).toString('base64');
}
// export const decodeBase64 = (data) => {
//     return Buffer.from(data, 'base64').toString('ascii');
// }

export function base64toBlob(data)  {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data?.substr('data:application/pdf;base64,'?.length);
    console.log(base64WithoutPrefix);

    if (base64WithoutPrefix) {
        const bytes = encodeBase64(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    }
   
};

export function roundTo2DP(num) {
    return Math.round((num + Number.EPSILON) * 100) / 100;
}

export function getExtension(filename) {
    return filename.substring(filename.lastIndexOf('.')+1, filename.length) || filename;
}

export function truncateWordEllipsis( str, n, useWordBoundary ){
    if (str.length <= n) { return str; }
    const subString = str.substr(0, n-1); // the original check
    return (useWordBoundary
        ? subString.substr(0, subString.lastIndexOf(" "))
        : subString) + "...";
}

export function truncateStringEllipsis(str, n){
    return (str.length > n) ? str.substr(0, n-1) + '...' : str;
};

export function substringAfterLastSpace(string) {
    return string.substring(string.lastIndexOf(' ') + 1);
}

const pad = num => ("0" + num).slice(-2);
export const getTimeFromDate = (timestamp) => {
  const date = new Date(timestamp);
  let hours = date.getHours(),
    minutes = date.getMinutes(),
    seconds = date.getSeconds();
  return pad(hours) + ":" + pad(minutes) + ":" + pad(seconds)
}