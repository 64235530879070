import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import FeedContainer from "../../../components/FeedContainer";
import greeting from "../../../utils/greeting";
import "./index.scss";
import { toggleRightBar, toggleLeftBar } from "../../../appstore/navigationDisplayToggle/index.action";
import { useHistory } from "react-router-dom";
import { FaDownload, FaInfoCircle } from "react-icons/fa";
import { IoMdCheckmarkCircle, BsArrowRight, MdReport } from "react-icons/all"
import Back from "../../../assets/svg/arrow-right (2).svg"
import Frame from "../../../assets/svg/frame.svg"
import Cycle from "../../../assets/img/withdraw-cycle.png"
import MainHistory from "./MainHistory"
import MainHistoryCommission from "./MainHistoryCommission"
import WithdrawalPage from "./WithdrawalPage"
import WalletEdit from "./EditWallet/EditPage"
import WalletChart from "../../../components/WalletChart/WalletChart"

import { ImCheckboxChecked, ImCheckboxUnchecked,
  IoMdCloseCircleOutline, RiCheckboxBlankLine, RiCheckboxBlankFill } from "react-icons/all"


const MyWallet = () => {
  const dispatch = useDispatch();
  const [location, setLocation] = useState("false");
  const [activeCard, setActiveCard] = useState("visa");
  const [checkAll, setCheckAll] = useState("false");
  const [checkNonCheck, setCheckNonCheck] = useState("false");
  const [checkCheck, setCheckCheck] = useState("false");

  const [walletLocation, setWalletLocation] = useState("commission");
  const [historyLocation, setHistoryLocation] = useState("false");
  const [withdrawal, setWithdrawal] = useState("false");

  const [backWallet, setBackWallet] = useState("false");


  const handleLocation = () =>{
    setLocation("false")
  }

  const handleBack = () => {
    setBackWallet("back")
  }


  const handleCheckAll = () => {
    setCheckAll(!checkAll)
  }

  const handleCheckNonCheck = () => {
    setCheckNonCheck(!checkNonCheck)
  }

  const handleCheckCheck = () => {
    setCheckCheck(!checkCheck)
  }

  const handleHistoryLocation = () =>{
    setHistoryLocation(!historyLocation)
  }

  //toggle right bar off
  useEffect(() => {
    dispatch(toggleRightBar(false));
    return () => dispatch(toggleRightBar(true));
  }, []);

  useEffect(() => {
    dispatch(toggleLeftBar(false));
    return () => dispatch(toggleLeftBar(true));
  }, []);

  return (
    <FeedContainer>
      <div className="wallet-payment">
          <div className="wallet-p-header">
            <h5>My Wallet</h5>
              <Link to="/wallet"
                onClick={() => {
                  setWithdrawal("false");
                }}
                >
                <p> <img className="visa" src={Back} /> Back </p>
              </Link>
          </div>
          {withdrawal === "false" &&
          <div className="wallet__section">
            <div className="top_section">
              <div className="top_left-withdraw">
                <div className="balances">
                  <div className="able-balance">
                    <div className="withdraw-icon">
                      <img src={Frame} />
                    </div>
                    <div className="withdraw-text">
                      <p>Nonwithdrawal Balance</p>
                    </div>
                    <h3 className="cash">$1000</h3>
                  </div>
                  <div className="able-balance not-balance">
                    <div className="withdraw-icon not-icon">
                      <img src={Frame} />
                    </div>
                    <div className="withdraw-text">
                      <p>Withdrawable Balance</p>
                    </div>
                    <h3 className="cash">$1000</h3>
                  </div>
                </div>
                <button className="withdraw-btn"
                  onClick={(e) => setWithdrawal("withdrawal")}
                  >
                  Withdraw
                </button>
              </div>
              <div className="top_right-chart">
                <div className="chart-top">
                  <p className="chart-top-text">Chart</p>
                  <div className="date">
                    <p className="date-text">July 22 - July 23 </p>
                  </div>
                </div>

                <div className="chart-amount">
                  <div className="figure">
                    <p className="check">
                    <span>
                      <RiCheckboxBlankFill/>
                    </span>
                    Total balance
                    </p>
                    <div className="cash-amount">
                      <p className="cash-text">$2000</p>
                    </div>
                  </div>
                  <div className="chart-round">
                    <div className="non-cashout-amount">
                      $1200
                      <span class="triangle"></span>
                    </div>
                    <WalletChart />
                    <div className="cashout-amount">
                      $500
                        <span class="triangle"></span>
                    </div>
                  </div>
                </div>
                <div className="chart-btm">
                  <div className="check-withdraw">
                    <p className="non-blc-check">
                    <span>
                      <RiCheckboxBlankFill/>
                    </span>
                    Nonwithdrawal balance
                    </p>

                    <p className="blc-check">
                    <span>
                      <RiCheckboxBlankFill/>
                    </span>
                    Withdrawal balance
                    </p>
                  </div>
                  <Link to="/wallet-history">
                    <p
                      className="history"
                      >
                      View History
                    </p>
                  </Link>

                </div>
              </div>
            </div>

            <div className="bottom-section">
              <div className="tab-download">
                <div className="tab">
                  <div
                    className={`booking ${walletLocation === "commission" && "active"}`}
                    onClick={(e) => setWalletLocation("commission")}>
                    Commission earned
                  </div>
                  <div
                    className={`listing ${walletLocation === "business1" && "active"}`}
                    onClick={(e) => setWalletLocation("business1")}>
                    Business page
                  </div>
                  <div
                    className={`listing ${walletLocation === "business2" && "active"}`}
                    onClick={(e) => setWalletLocation("business2")}>
                    Business page 2
                  </div>
                  <div
                    className={`listing ${walletLocation === "business3" && "active"}`}
                    onClick={(e) => setWalletLocation("business3")}>
                    Business page 3
                  </div>
                </div>
              </div>
              {walletLocation === "commission" ? " " :
              <div className="blc-hstr">
                <div className="withdraw-blc">
                  <p>Nonwithdrawal Balance <span>$1000</span> </p>
                </div>
                <div className="withdraw-blc withdraw-blc2">
                  <p>Nonwithdrawal Balance <span>$1000</span></p>
                </div>
              </div>
              }
              {walletLocation === "business1" && <MainHistory />}
              {walletLocation === "commission" && <MainHistoryCommission />}
              {walletLocation === "business2" && <MainHistory />}
              {walletLocation === "business3" && <MainHistory />}
            </div>
          </div>
      }

        {withdrawal === "withdrawal" &&
          <div className="wallet__section">
            <WithdrawalPage />
          </div>
        }
      </div>
    </FeedContainer>
  );
};
export default MyWallet;
