import PostCard from "../PostCards";
import "./posts.scss";
// import postCardData from "../../utils/postCardData";
import { Fragment } from "react/cjs/react.production.min";
import postCardOptions from "../../utils/postCardOptions";
import { useContext, useRef, useState } from "react";
import { useEffect } from "react";
// import SinglePost from "../SinglePost";
import useDeviceType from "../../hooks/deviceType";
import useSkeletonLoader from "../../hooks/FeedSkeletonLoader/index";
import usePosts from "../../hooks/posts";
import { useSelector } from "react-redux";
import { DataContext } from "../MiddleFeed";
import SocialContext from "../../hooks/socialContext";
import NoData from "../NoData";
import { useDispatch } from "react-redux";
import { resetPosts } from "../../appstore/posts/index.action";
import { useParams } from "react-router";
// import
// import {VSend} from "react-icons/all"

const Posts = () => {
  const { handleGetPosts, getPostsData } = usePosts();
  const { focus } = useParams();
  const triggerNext = useRef(null);
  const postRoot = useRef(null);
  const { socialDetails, username } = useContext(SocialContext);
  const [isOptionVisible, setIsOptionVisible] = useState(false);
  const { user } = useSelector((state) => state.currentUser);
  const postCardData = useSelector((state) => state.post);
  const [animatedClass, setAnimatedClass] = useState("");
  const { isMobile } = useDeviceType();
  const { MultipleFeedPosts, FeedPost } = useSkeletonLoader();
  const dispatch = useDispatch();
  useEffect(() => {
    if (isOptionVisible) {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setAnimatedClass("reveal__mobile__more__options");
      }, 200);
    } else {
      setAnimatedClass("");
      document.body.style.overflow = "inherit";
    }
  }, [isOptionVisible]);

  useEffect(() => {
    let observer;
    if (postCardData.next) {
      observer = new IntersectionObserver(
        (entries) => {
          const [entry] = entries;
          if (entry.isIntersecting && socialDetails[0]?.user.data) {
            handleGetPosts(
              focus,
              postCardData.next,
              null,
              null,
              !username ? null : socialDetails[0].user.data.id
            );
          }
        },
        {
          root: null,
          rootMargin: "0px 0px 600px 0px",
          threshold: 0.25,
        }
      );

      if (triggerNext.current) {
        observer.observe(triggerNext.current);
      }
    }
    return () => {
      if (triggerNext.current) {
        observer?.unobserve(triggerNext.current);
        // dispatch(resetPosts());
      }
    };
  }, [triggerNext.current, postCardData.next, socialDetails[0]]);

  console.log(postCardData.next);
  return (
    <Fragment>
      <div className="posts" ref={postRoot}>
        {!!postCardData.newPostLoading && <FeedPost />}
        {postCardData.postData.map((data) => {
          data.isOwner = data.owner.username === user.username;
          // console.log(data);
          return (
            <Fragment>
              {!!!data.hidden && (
                <PostCard
                  key={data.id}
                  postData={data}
                  optionVisibility={[isOptionVisible, setIsOptionVisible]}
                  {...data}
                />
              )}
            </Fragment>
          );
        })}
        {!postCardData.postData.length && !postCardData.loading && (
          <NoData>No Posts Available</NoData>
        )}
        {!!postCardData.loading && <MultipleFeedPosts />}
        {postCardData.next && (
          <div className="trigger-load-more" ref={triggerNext}></div>
        )}
      </div>
      {isOptionVisible && isMobile && (
        <div
          className={`mobile__more__options ${animatedClass}`}
          onClick={(e) => setIsOptionVisible(false)}>
          <ul onClick={(e) => e.stopPropagation()}>
            {postCardOptions.map((item, idx) => {
              return (
                <li>
                  <item.icon width={"20px"} /> <span>{item.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default Posts;
