import { useState } from "react";
import ReactStars from "react-stars";
import StarIcon from "../../../assets/svg/star";
import useActivity from "../../../hooks/activity";

const Review = ({ tab, id }) => {
  const [rate, setRate] = useState(0);
  const [body, setBody] = useState();
  const { handleMakeReview, makeReviewData } = useActivity();
  console.log(body, rate, "BODY");
  return (
    <div className="review">
      <div className="top">
        <h5>Rate this seller</h5>
        <div className="rating">
          <ReactStars
            onChange={(e) => {
              setRate(e);
            }}
            count={5}
            size={17}
            color2={"#FBB03B"}
            value={rate}
          />
          <span>{rate}</span>
        </div>
      </div>

      {/* <p>
                    1.4k Reviews
                </p> */}
      <div
        className="commentBox"
        placeholder="Comment"
        contentEditable="true"
        onInput={(e) => setBody(e.target.innerText)}
      ></div>
      <div className="submit">
        <button
          href=""
          onClick={() =>
            tab &&
            id &&
            rate &&
            body &&
            handleMakeReview({ tab: tab, id: id, body: body, rating: rate })
          }
        >
          Submit
        </button>
      </div>
    </div>
  );
};
export default Review;
