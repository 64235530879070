import CustomDropdown from "../../../customDropdown/customDropdown";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import findValue from "../helper/findValue";
import moment from "moment";
import { useState } from "react";
import { useEffect } from "react";
import usePrivateSellerDashboard from "../../../../hooks/privateSellerDashboard";

const CheckoutItem = ({ handleRefresh, data, idx }) => {
  const { handleToggleDeliveryStatus, toggleDeliveryStatusData } =
    usePrivateSellerDashboard();
  const data2 = [
    { id: 0, label: "Processing" },
    { id: 1, label: "Out for delivery" },
  ];
  //   const [status, setStatus] = useState(data.out_for_delivery);
  const [itemStatus, setItemStatus] = useState();
  const [opening_time, setOpening_time] = useState();
  const [closing_time, setClosing_time] = useState();
  const cutTime = (data) => {
    const splits = data?.split(":");
    return `${splits?.[0]} : ${splits?.[1]}`;
  };
  useEffect(() => {
    if (data?.out_for_delivery) {
      setItemStatus("Out for delivery");
    } else setItemStatus("Processing");

    if (data) {
      setOpening_time(cutTime(data?.delivery_date?.opening_time));
      setClosing_time(cutTime(data?.delivery_date?.closing_time));
    }
  }, [data]);

  // useEffect(() => {
  //   toggleDeliveryStatusData.data && handleRefresh({ tab: "Checkout" });
  // }, [toggleDeliveryStatusData.data]);
  console.log(itemStatus, "STATUSSSSSS");
  return (
    <div
      className={`bkr-cbc-card ${
        data.status === "CHECKOUT" ? "green-border" : "orange-border"
      }`}
    >
      <div className="bkr-cbcc-body">
        <div className="item__detail-box">
          <div className="cb-1">
            <label className="check-label" htmlFor="001">
              {idx + 1}
            </label>
          </div>
          <div className="orc-detail-box">
            <img src={data?.owner?.profile_image?.file} alt="" />
            <div className="orc-detail-box-user">
              <h4>{data?.owner?.full_name}</h4>
              <h6>{data?.owner?.username}</h6>
            </div>
          </div>
        </div>
        <div className="cbcc-item__box">
          <img src={data?.product?.gallary?.[0]?.file} alt="" />
          <div className="item-detail-one">
            <h4>{data?.product?.title}</h4>
          </div>
          <div className="item-detail-two">
            <div>
              <h5>Price</h5>
              <p>${data?.price}</p>
            </div>
          </div>
          <div className="item-detail-two">
            <div>
              <h6>Delivery schedule</h6>
              <h5>
                Date:{" "}
                <span>
                  {" "}
                  {opening_time} - {closing_time}{" "}
                </span>
                <br />
                <span>
                  {data?.order_date &&
                    moment(data.order_date).format("DD MMMM YYYY")}{" "}
                </span>
              </h5>
            </div>
          </div>
        </div>
        <div className="dropbox">
          <CustomDropdown
            delivery_id={data?.id}
            click={handleToggleDeliveryStatus}
            itemy={data?.out_for_delivery ? "Out for delivery" : "Processing"}
            items={data2}
            data={data}
          />
        </div>
        <div className="item-btn-box ">
          <button
            className={`${
              data?.status === "CHECKOUT"
                ? "btn-checkout"
                : "btn-checkout-disabled"
            }`}
          >
            Checkout
          </button>
        </div>
      </div>
    </div>
  );
};
export default CheckoutItem;
