import { IoCloseCircleOutline } from "react-icons/io5";
import { DefaultButton } from "../../../components/Button";
import CustomCheckBox from "../../../components/customCheckBox";
import ModalItem from "./modalItem";

const EditQueueModal = () => {
  const testArray = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19,
  ];
  return (
    <div className="queue__select__listing">
      <div className="wrap">
        <div className="head__section">
          <div className="save__cancel">
            <DefaultButton text={"Save"} />
            <IoCloseCircleOutline />
          </div>
          <h3>Select Listing</h3>
          <div className="select__all">
            <CustomCheckBox
              style={{
                border: "1px solid var(--text-grey)",
                minWidth: 18,
                height: 18,
              }}
            />
            <p>Select bookings</p>
          </div>
          <div className="item__body">
            {testArray.map((data, idx) => (
              <ModalItem />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditQueueModal;
