export const BusinessPagePageUtils = [
    {
        Details: {
            name: 'Maria Sponge',
            username: 'username'
        },
        General: [
            {
                title: 'Queue',
                value: false
            },
            {
                title: 'Booking',
                value: true
            },
            {
                title: 'Listing',
                value: true
            },
            {
                title: 'Jobs',
                value: true
            }
        ],
        Timeline: [
            {
                title: 'Allow admin to create and edit content',
                value: true
            },
            {
                title: 'Allow admin to delete post',
                value: false
            },
            {
                title: 'Allow admin to link contents in the timeline, comments on post',
                value: true
            }
        ],
        Job: [
            {
                title: 'Allow admin to create and edit job',
                value: true
            },
            {
                title: 'Allow admin to delete job post',
                value: false
            },
            {
                title: 'Allow admin manage (extended date)',
                value: true
            },
            {
                title: 'Allow admin to review received applications',
                value: false
            }
        ],
        Listings: [
            {
                title: 'Allow admin to create and edit listing',
                value: true
            },
            {
                title: 'Allow admin to delete listing post',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ],
        Booking: [
            {
                title: 'Allow admin to create and edit booking',
                value: true
            },
            {
                title: 'Allow admin to delete booking',
                value: false
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: true
            }
        ],
        Queue: [
            {
                title: 'Allow admin to create and edit queue',
                value: true
            },
            {
                title: 'Allow admin to delete queque',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ]
    },
    {
        Details: {
            name: 'Maria Sponge',
            username: 'username'
        },
        Timeline: [
            {
                title: 'Allow admin to create and edit content',
                value: true
            },
            {
                title: 'Allow admin to delete post',
                value: false
            },
            {
                title: 'Allow admin to link contents in the timeline, comments on post',
                value: true
            }
        ],
        Job: [
            {
                title: 'Allow admin to create and edit job',
                value: true
            },
            {
                title: 'Allow admin to delete job post',
                value: false
            },
            {
                title: 'Allow admin manage (extended date)',
                value: true
            },
            {
                title: 'Allow admin to review received applications',
                value: false
            }
        ],
        Listings: [
            {
                title: 'Allow admin to create and edit listing',
                value: true
            },
            {
                title: 'Allow admin to delete listing post',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ],
        Booking: [
            {
                title: 'Allow admin to create and edit booking',
                value: true
            },
            {
                title: 'Allow admin to delete booking',
                value: false
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: true
            }
        ],
        Queue: [
            {
                title: 'Allow admin to create and edit queue',
                value: true
            },
            {
                title: 'Allow admin to delete queque',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ]
    },
    {
        Details: {
            name: 'Maria Sponge',
            username: 'username'
        },
        Timeline: [
            {
                title: 'Allow admin to create and edit content',
                value: true
            },
            {
                title: 'Allow admin to delete post',
                value: false
            },
            {
                title: 'Allow admin to link contents in the timeline, comments on post',
                value: true
            }
        ],
        Job: [
            {
                title: 'Allow admin to create and edit job',
                value: true
            },
            {
                title: 'Allow admin to delete job post',
                value: false
            },
            {
                title: 'Allow admin manage (extended date)',
                value: true
            },
            {
                title: 'Allow admin to review received applications',
                value: false
            }
        ],
        Listings: [
            {
                title: 'Allow admin to create and edit listing',
                value: true
            },
            {
                title: 'Allow admin to delete listing post',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ],
        Booking: [
            {
                title: 'Allow admin to create and edit booking',
                value: true
            },
            {
                title: 'Allow admin to delete booking',
                value: false
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: true
            }
        ],
        Queue: [
            {
                title: 'Allow admin to create and edit queue',
                value: true
            },
            {
                title: 'Allow admin to delete queque',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ]
    },
    {
        Details: {
            name: 'Maria Sponge',
            username: 'username'
        },
        Timeline: [
            {
                title: 'Allow admin to create and edit content',
                value: true
            },
            {
                title: 'Allow admin to delete post',
                value: false
            },
            {
                title: 'Allow admin to link contents in the timeline, comments on post',
                value: true
            }
        ],
        Job: [
            {
                title: 'Allow admin to create and edit job',
                value: true
            },
            {
                title: 'Allow admin to delete job post',
                value: false
            },
            {
                title: 'Allow admin manage (extended date)',
                value: true
            },
            {
                title: 'Allow admin to review received applications',
                value: false
            }
        ],
        Listings: [
            {
                title: 'Allow admin to create and edit listing',
                value: true
            },
            {
                title: 'Allow admin to delete listing post',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ],
        Booking: [
            {
                title: 'Allow admin to create and edit booking',
                value: true
            },
            {
                title: 'Allow admin to delete booking',
                value: false
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: true
            }
        ],
        Queue: [
            {
                title: 'Allow admin to create and edit queue',
                value: true
            },
            {
                title: 'Allow admin to delete queque',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ]
    },
    {
        Details: {
            name: 'Maria Sponge',
            username: 'username'
        },
        Timeline: [
            {
                title: 'Allow admin to create and edit content',
                value: true
            },
            {
                title: 'Allow admin to delete post',
                value: false
            },
            {
                title: 'Allow admin to link contents in the timeline, comments on post',
                value: true
            }
        ],
        Job: [
            {
                title: 'Allow admin to create and edit job',
                value: true
            },
            {
                title: 'Allow admin to delete job post',
                value: false
            },
            {
                title: 'Allow admin manage (extended date)',
                value: true
            },
            {
                title: 'Allow admin to review received applications',
                value: false
            }
        ],
        Listings: [
            {
                title: 'Allow admin to create and edit listing',
                value: true
            },
            {
                title: 'Allow admin to delete listing post',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ],
        Booking: [
            {
                title: 'Allow admin to create and edit booking',
                value: true
            },
            {
                title: 'Allow admin to delete booking',
                value: false
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: true
            }
        ],
        Queue: [
            {
                title: 'Allow admin to create and edit queue',
                value: true
            },
            {
                title: 'Allow admin to delete queque',
                value: true
            },
            {
                title: 'Allow admin manage (accept and checkout)',
                value: false
            }
        ]
    }
]