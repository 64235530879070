import "./style.scss";
import { FaLuggageCart, IoMdCloseCircleOutline } from "react-icons/all";
import { DefaultButton } from "../../Button";
import ReusableDropDown from "../../reusableDropdown";
import { useContext, useEffect, useState } from "react";
import CompletedDeliveryInput from "./completeDeliveryInput";
import EditDeliveryInput from "./editDeliveryPickup";
import ModalContext from "../../../hooks/modalContexts";
import CloseBtn from "../../closeBtn";
import CityWide from "./cityWide";
import randomString from "unique-string-generator";
import CustomCheckBox from "../../customCheckBox";
import Places from "../../googlePlaces";
import { Toaster } from "react-hot-toast";
import { useMemo } from "react";
const DeliveryDetail = () => {
  const [doorDeliveryFeeFree, setDoorDeliveryFeeFree] = useState();

  const [customFreePickup, setCustomFreePickup] = useState();
  const {
    setBusinessSellerDelivery,
    deliveryToBeEditedState,
    deliveryListState,
  } = useContext(ModalContext);
  const [toBeEdited, setToBeEdited] = deliveryToBeEditedState;
  console.log(toBeEdited);
  const pickupOptions = ["Fixed Date & Time", "Custom Date & Time"];
  const [selectedOption, setSelectedOption] = useState(pickupOptions[0]);

  const doorDeliveryOptions = ["Geo fencing", "City Wide"];
  const [edit, setEdit] = useState(true);

  const handleDeliveryMode = (item, value) => {
    setDeliveryMode({
      ...deliveryMode,
      [item]: value !== undefined ? value : !deliveryMode[item],
    });
  };
  const [tempId] = useState(
    toBeEdited?.tempId ?? randomString.UniqueNumberId()
  );

  const [city, setCity] = useState(
    toBeEdited?.city || {
      address: "",
      point: {
        type: "Point",
        coordinates: [],
      },
    }
  );
  const defaultDelivery = {
    delivery_fee: 0,
    address: "",
    free_delivery: false,
    dates: [],
    point: {
      type: "Point",
      coordinates: [],
    },
    close_date: new Date(),
    delivery_mode: "PICK_UP",
    custom_dates: {},
    average_time: "",
  };
  const deliveryState = useState(defaultDelivery);

  const defaultDoorDeliveryMode = {
    delivery_fee: 0,
    address: "",
    free_delivery: false,
    dates: [],

    close_date: new Date(),
    door_delivery_type: "CITY_WIDE",
    geom: {
      type: "MultiPolygon",
      coordinates: [],
    },
    delivery_mode: "DOOR_DELIVERY",
  };
  console.log(toBeEdited?.locations);
  const [locations, setLocations] = useState(toBeEdited?.locations || []);
  const [deliveryMode, setDeliveryMode] = useState({
    pickup:
      !locations.find(
        (location) =>
          location.delivery_mode === "PICK_UP" ||
          location.delivery_mode === "DOOR_LOCATION"
      ) && false,
    door:
      !locations.find(
        (location) =>
          location.delivery_mode === "DOOR_DELIVERY" ||
          location.delivery_mode === "DOOR_LOCATION"
      ) && false,
  });
  // console.log(locations, deliveryMode);
  const doorDeliveryModeState = useState(defaultDoorDeliveryMode);
  const [deliveryList, setDeliveryList] = deliveryListState;
  const [delivery, setDelivery] = deliveryState;
  const [doorDeliveryMode, setDoorDeliveryMode] = doorDeliveryModeState;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setDelivery({ ...delivery, [name]: value });
  };
  useEffect(() => {
    const { pickup, door } = deliveryMode;
    if (pickup && door) {
      setDelivery({
        ...delivery,
        delivery_mode: "DOOR_LOCATION",
      });
      return;
    }

    if (pickup) {
      setDelivery({
        ...delivery,
        delivery_mode: "PICK_UP",
      });
      return;
    }

    if (door) {
      setDelivery({
        ...delivery,
        delivery_mode: "DOOR_DELIVERY",
      });
      return;
    }
  }, [deliveryMode]);

  useEffect(() => {
    if (toBeEdited) setToBeEdited();
  }, []);
  //delivery fee for a change!
  useEffect(() => {
    doorDeliveryModeState[1]((state) => ({
      ...state,
      free_delivery: doorDeliveryFeeFree,
    }));
  }, [doorDeliveryFeeFree]);

  return (
    <div
      className="business-seller-delivery-detail-modal"
      onClick={(e) => setBusinessSellerDelivery(false)}>
      <Toaster />
      <div className="wrap">
        {" "}
        <CloseBtn />
        <div className="main" onClick={(e) => e.stopPropagation()}>
          <div className="d-modal-header">
            <h4>
              <FaLuggageCart /> Delivery Details
            </h4>
            <DefaultButton
              onClick={(e) => {
                // deliveryList.length && setPrivateSellerDelivery(false);
                if (city.address.trim() !== "" && locations.length) {
                  //merge data and send to list of cards
                  console.log(deliveryMode.pickup, deliveryMode.door);
                  setDeliveryList((list) => {
                    const newList = list.filter(
                      (item) => item.tempId !== tempId
                    );
                    newList.push({
                      tempId,
                      city,
                      locations,
                    });
                    return newList;
                  });
                  setBusinessSellerDelivery(false);
                }
              }}
              customStyle={{
                borderRadius: 40,
                fontSize: 14,
                padding: "5px 16px",
                backgroundColor: "var(--dark-purple-bg)",
              }}>
              {" "}
              Save
            </DefaultButton>
          </div>
          <div className="delivery-location">
            {/* <p className="new-location">Add new location</p> */}
            <div className="set-location">
              <p>Set location</p>
              <Places
                defaultValue={city.address}
                onPlaceSelected={(place) => {
                  setCity({
                    ...city,
                    address: place.formatted_address,
                    point: {
                      type: "Point",
                      coordinates: [
                        place.geometry.location.lng(),
                        place.geometry.location.lat(),
                      ],
                    },
                  });
                }}
              />
            </div>
            <div className="pickup-select">
              <p>Delivery mode</p>
              <div className="pickup-select-main">
                <div className="pickup">
                  Pickup location{" "}
                  <CustomCheckBox
                    size={12}
                    onChange={(e) => handleDeliveryMode("pickup", e.value)}
                    defaultValue={
                      locations.find(
                        (location) =>
                          location.delivery_mode === "PICK_UP" ||
                          location.delivery_mode === "DOOR_LOCATION"
                      ) && true
                    }
                  />
                </div>
                <div className="door-delivery">
                  Door Delivery{" "}
                  <CustomCheckBox
                    size={12}
                    onChange={(e) => handleDeliveryMode("door", e.value)}
                    defaultValue={
                      locations.find(
                        (location) =>
                          location.delivery_mode === "DOOR_DELIVERY" ||
                          location.delivery_mode === "DOOR_LOCATION"
                      ) && true
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {(deliveryMode.pickup || deliveryMode.door) && (
            <div className="pickups-wrap">
              {edit && deliveryMode.pickup && (
                <p className="p-location">Pickup location</p>
              )}
              <div className="pickup-options-container">
                {edit && deliveryMode.pickup && (
                  <ReusableDropDown
                    options={pickupOptions}
                    current={pickupOptions[0]}
                    onChange={setSelectedOption}
                  />
                )}
                {edit &&
                  deliveryMode.pickup &&
                  selectedOption.toLowerCase() !== "fixed date & time" && (
                    <div className="_wrap">
                      <>
                        <p className="set-fee">Set Delivery Fee:</p>
                        <div className="fee">
                          ${" "}
                          <input
                            type="number"
                            className="input"
                            readOnly={customFreePickup}
                          />
                          <p>
                            Free{" "}
                            <CustomCheckBox
                              size={12}
                              getValue={setCustomFreePickup}
                            />
                          </p>
                        </div>
                      </>
                    </div>
                  )}
              </div>

              {
                <>
                  {locations.map((data, idx) => (
                    <CompletedDeliveryInput
                      data={data}
                      key={idx}
                      id={idx}
                      locationsState={[locations, setLocations]}
                      editState={[edit, setEdit]}
                      deliveryState={[delivery, setDelivery]}
                      city={city}
                      doorDeliveryState={[
                        doorDeliveryMode,
                        setDoorDeliveryMode,
                      ]}
                    />
                  ))}
                </>
              }

              {deliveryMode.pickup && (
                <EditDeliveryInput
                  locationsState={[locations, setLocations]}
                  pickupOptions={pickupOptions}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  privateSeller={true}
                  handleChange={handleChange}
                  deliveryState={[delivery, setDelivery]}
                  deliveryListState={[deliveryList, setDeliveryList]}
                  deliveryMode={deliveryMode}
                  editState={[edit, setEdit]}
                  defaultState={{ defaultDoorDeliveryMode, defaultDelivery }}
                  city={city}
                />
              )}
            </div>
          )}
          {deliveryMode.door &&
            !locations.find(
              (location) => location.delivery_mode === "DOOR_DELIVERY"
            ) && (
              <>
                <div className="door-delivery">
                  <p>Door Delivery</p>
                  <div className="delivery-setup">
                    <ReusableDropDown
                      current={"City Wide"}
                      upIcon={true}
                      options={doorDeliveryOptions}
                      onChange={(e) =>
                        setDoorDeliveryMode((state) => ({
                          ...state,
                          door_delivery_type:
                            e.toLowerCase() === "geo fencing"
                              ? "GEO_FENCING"
                              : "CITY_WIDE",
                        }))
                      }
                    />
                    <p>Set Delivery Fee:</p>
                    <label htmlFor="">
                      ${" "}
                      <input
                        type="text"
                        className="input"
                        readOnly={
                          doorDeliveryFeeFree || doorDeliveryMode.free_delivery
                        }
                      />
                    </label>
                    <div className="free">
                      Free{" "}
                      <CustomCheckBox
                        size={12}
                        getValue={setDoorDeliveryFeeFree}
                        defaultValue={doorDeliveryMode.free_delivery}
                      />
                    </div>
                  </div>
                </div>
                <CityWide
                  defaultState={defaultDoorDeliveryMode}
                  doorDeliveryModeState={[
                    doorDeliveryMode,
                    setDoorDeliveryMode,
                  ]}
                  locationsState={[locations, setLocations]}
                  city={city}
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetail;
