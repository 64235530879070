import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import combineReducers from "./reducers/index";
import storage from "redux-persist/lib/storage";
const config = {
  key: "root",
  storage,
};
// const middleWare = [thunk];
const persistedReducer = persistReducer(config, combineReducers);

const appStore = () => {
  const store = createStore(persistedReducer);
  const persiststore = persistStore(store);
  return {
    store,
    persiststore,
  };
};

export default appStore;
