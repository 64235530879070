import { countries } from "countries-list";
import { useState, useMemo } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleRightBar } from "../../appstore/navigationDisplayToggle/index.action";
import FeedContainer from "../../components/FeedContainer";
import SocialSettings from "../../utils/socialSettings";
import GeneralSettings from "./component/General";
import PrivacySettings from "./component/privacy";
import SecuritySettings from "./component/security";
import NotificationSettings from "./component/notification";
import Clock from "../../assets/svg/clock";
import MyActivity from "../../utils/myActivity";
import useDeviceType from "../../hooks/deviceType";
import "./index.scss";
import useSettings from "../../hooks/settings";
import currentUser from "../../appstore/userData/index.reducer";
import BusinessSettings from "./component/Business/businessSettings";

const Settings = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleRightBar(false));

    return () => dispatch(toggleRightBar(true));
  }, []);
  const [activeMainTab, setActiveMainTab] = useState("social");
  const [secondaryTab, setSecondaryTab] = useState("General Account Settings");
  const [index, setIndex] = useState(0);
  const [currentTab, setCurrentTab] = useState("My Activity");
  const currentUser = useSelector((state) => state.currentUser.user);
  const currentUserWhole = useSelector((state) => state.currentUser);
  return (
    <FeedContainer>
      <div className="settingsPage">
        <div className="maintabs">
          <div
            onClick={() => setActiveMainTab("social")}
            className={`${
              activeMainTab === "social" && "activeMainTab"
            } mainTab`}
          >
            Social
          </div>
          <div
            onClick={() => setActiveMainTab("business")}
            className={`${
              activeMainTab === "business" && "activeMainTab"
            } mainTab`}
          >
            Business
          </div>
        </div>
        {activeMainTab === "social" && (
          <div className="socialSettings">
            <div className="secondaryTabs">
              {SocialSettings.map((socialSetting) => {
                return (
                  <p
                    onClick={() => setSecondaryTab(socialSetting.name)}
                    className={`${
                      secondaryTab === socialSetting.name && "active"
                    }`}
                  >
                    {socialSetting.name}
                    <span></span>
                  </p>
                );
              })}
            </div>
            {secondaryTab === "General Account Settings" && (
              <GeneralSettings
                currentUser={currentUser}
                secondaryTab={secondaryTab}
              />
            )}
            {secondaryTab === "Privacy Settings" && (
              <PrivacySettings
                currentUser={currentUser}
                secondaryTab={secondaryTab}
              />
            )}
            {secondaryTab === "Security Settings" && (
              <SecuritySettings secondaryTab={secondaryTab} />
            )}
            {secondaryTab === "Notification Settings" && (
              <NotificationSettings
                currentUser={currentUser}
                secondaryTab={secondaryTab}
              />
            )}
          </div>
        )}
        {activeMainTab === "business" && <BusinessSettings />}
      </div>
    </FeedContainer>
  );
};
export default Settings;
