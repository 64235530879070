import { useState, useEffect } from "react";
import useDeviceType from "../../hooks/deviceType";
import BsCreateListingBody from "../../components/BusinessSeller/Listings/createListingBody.jsx";
import FeedContainer from "../../components/FeedContainer";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import { useHistory, useLocation } from "react-router-dom";
import greeting from "../../utils/greeting";
import q from "query-string";
import usePages from "../../hooks/createPage";
const BusinnessSellerCreateListing = () => {
  const { isMobile, isDesktop } = useDeviceType();
  const { search } = useLocation();
  const { id } = q.parse(search);
  const { handleGetSinglePage, singlePageData } = usePages();
  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    handleGetSinglePage(id);
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  return (
    <FeedContainer>
      {singlePageData.data && (
        <div className="bs-createlistings-container">
          <div className="lbc-header">
            <h1 className="welcome-title">
              {greeting()}! <span>{singlePageData.data.name} </span>
            </h1>
          </div>
          <div className="lbc-card">
            <h5 className="sub-title"> Create Listing</h5>
            <BsCreateListingBody id={id} />
          </div>
        </div>
      )}
    </FeedContainer>
  );
};

export default BusinnessSellerCreateListing;
