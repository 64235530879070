import { useContext, useState, useEffect } from "react";
import "./orderModal.scss";
import ModalContext from "../../../../hooks/modalContexts";
import CloseBtn from "../../../closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import SalesGrowthChart from "../../../Analytics/components/salesGrowthTotalConnection";
import OrderCompletionModal from "../../../Analytics/components/orderCompletedListingDropdownModal";
import WalletMoney from "../../../../assets/svg/wallet-money";
import { useLocation } from "react-router-dom";
import useAnalytics from "../../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../../hooks/exploreContext'

const TotalConnectionsModal = () => {
  /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const { setTotalConnectionsModal } = useContext(ModalContext);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)

  const { search } = useLocation();
  const { id } = qs.parse(search);

  const {
   // Job Interaction
   analyticsConnectInteraction,
   handleGetAnalyticsConnectInteraction,
  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsConnectInteraction(id)
  }, [])

const connectInteraction = analyticsConnectInteraction?.data

  return (
    <div
      className="orderCompletionModal"
      onClick={(e) => setTotalConnectionsModal(false)}
    >
      <div className="orderCompletionModalContainer">
        <div
          onClick={(e) => setTotalConnectionsModal(false)}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="orderCompletionModalContainered"
        >
          <div className="orderCompletionModal-header">
            <h4> Total Connections </h4>
            <div className="datepicker-container">
            <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsConnectInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="orderCompletionModal-body">
            <OrderCompletionModal
              image={<WalletMoney />}
              title="Total connections"
              statPercent={connectInteraction?.total_connections?.summary.percentage + "%"}
              statTime="in less than a week"
              statTotal={connectInteraction?.total_connections?.total}
              bgColor="rgba(73,0,87,1.0)"
              bgColor2="#580068"
              bgColor3="#6A017E"
              upAndDown={connectInteraction?.total_connections?.summary.direction === "UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
            />

            <div className="amb-statistics">
              <SalesGrowthChart title="Order Statistics" connectInteraction={connectInteraction}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TotalConnectionsModal;
