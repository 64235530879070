import React, { useState, useEffect } from "react";
import "./forgotPassword.scss";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import NavBar from "../../components/layout/navbar";
import logo2 from "../../assets/icons/logo2.png";
import { colors } from "../../color";
import useAuth from "../../hooks/auth";
import Loader from "../../components/loader";

const ForgotPassword = () => {
  const { handlePasswordChangeInit, initPasswordChangeData } = useAuth();
  const [email, setEmail] = useState();
  const [error, setError] = useState({});

  useEffect(() => {
    if (initPasswordChangeData.error) {
      setError(initPasswordChangeData.error.response.data);
      setTimeout(() => {
        setError({});
      }, 3000);
    }
  }, [initPasswordChangeData.error]);

  return (
    <>
      <main>
        <div className="container-fluid">
          <NavBar theme="dark" />
        </div>

        <div className="container">
          <div className="orb1" />
          <div className="orb2" />

          <div className="recovery-content">
            <h4 className="recovery-heading">
              We are with you in this, you will bounce back to your account
              immediately after this step.
            </h4>

            <p className="recovery-text">
              Reset Instructions will be sent to the email below.
              <br />
              <br />
              For security reasons, we do NOT store your password. So rest
              assured that we will never send your password via email.
            </p>

            <div className="recovery-form">
              <label className="recovery-email-label">Enter your email</label>
              <input
                type="text"
                className="recovery-form-input"
                onChange={(e) => setEmail(e.target.value)}
              />
              {error.email && (
                <span
                  style={{
                    display: "block",
                    fontSize: "12px",
                    color: "red",
                    marginTop: -20,
                    marginBottom: 20,
                    paddingLeft: 5,
                  }}>
                  {error.email}
                </span>
              )}
              {!initPasswordChangeData.loading ? (
                <button
                  style={{
                    ...(initPasswordChangeData.data && {
                      backgroundColor: "var(--green)",
                    }),
                  }}
                  className="button-alt"
                  onClick={(e) => handlePasswordChangeInit(email)}>
                  {initPasswordChangeData.data
                    ? initPasswordChangeData.data.detail
                    : "Send Reset Instructions"}
                </button>
              ) : (
                <Loader
                  text="Sending.."
                  outerStyle={{
                    width: "fit-content",
                    marginRight: "auto",
                  }}
                />
              )}
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default ForgotPassword;
