import React, {  useState, useContext  } from "react";
import "../cards.scss"
import Visa from "../../../../assets/svg/visa-color_large.svg"
import Master from "../../../../assets/svg/mastercard-light-large.svg"
import PayPal from "../../../../assets/svg/paypal-color-large.svg"
import Back from "../../../../assets/svg/arrow-right (2).svg"
import { FaPlus } from 'react-icons/fa'
import { MdReport } from 'react-icons/all'
import Slick from "react-slick"
import Slider from "react-slick"
import VisaCardEdit from "../VisaCardEdit"
import Right from "../../../../assets/svg/arrow-right (1).svg"
import Left from "../../../../assets/svg/arrow-right.svg"
import Cards from 'react-credit-cards'
import { css } from 'styled-components';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import CurrentPayPalCard from "./PaypalCard"

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={Left}
      className={className}
      style={{ ...style, display: "block", width: "30px", height: "30px", right: "10px", top: "100px" }}
      onClick={onClick}
      />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
  <img
    src={Right}
    className={className}
    style={{ ...style, display: "block", width: "30px", height: "30px", left: "-20px", top: "100px" }}
    onClick={onClick}
    />
  );
}


const PaypalCard = () => {
  const carouselSettings = {
    dots: false,
    infinite: false,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
     prevArrow: <PrevArrow />
  };
  const {
    meta, getCardNumberProps, getExpiryDateProps, getCVCProps
  } = usePaymentInputs();

  return(
    <div>
      <div className="cards-container">
          <div className="new-card-cont">
            <p>Add new credit card</p>
            <div className="new-card">
              <div className="add">
                <FaPlus />
              </div>
            </div>
          </div>
          <div className="current-card-cont">
            <div className="slick-crs">
              <p className="add-new-paypal">Current credit card</p>
              <Slick {...carouselSettings} className="slick">
                <CurrentPayPalCard />
                <CurrentPayPalCard />
              </Slick>
            </div>
          </div>
        </div>
        <div className="card-edit-info card-edit-paypal">
          <div className="paypal-details">
            <div className="name">
              <p>Billing Address</p>
              <h5 className="name-card-owner"> 6, Brand Nw Street</h5>
            </div>
            <div className="name">
              <p>Town County</p>
              <h5 className="name-card-num">Quebec, Ontario</h5>
            </div>
            <div className="name">
              <p>Email Address</p>
              <h5> admin@meeney.com</h5>
            </div>
          </div>
        </div>
      </div>
  )
}

export default PaypalCard
