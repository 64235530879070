import React from "react";
import { Card, P } from "./generalSettings";
import { Col, Container, Row } from "reactstrap";
import { set_new_password } from "../../forms/forms";

class SecurityPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Card height="700px">
          <Container>
            <Col xl={12}>
              <Col xl={8}>
                <P className="pt-4 pb-3">Security Settings</P>
              </Col>
              <Row>
                <Col xl={3}>
                  <P style={{ fontSize: "15px" }}>Reset Password</P>
                </Col>
                <Col xl={8}>
                  <div className="d-flex justify-content-end">
                    {set_new_password()}
                  </div>
                </Col>
              </Row>
            </Col>
          </Container>
        </Card>
      </div>
    );
  }
}

export default SecurityPage;
