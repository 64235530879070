import { useEffect, useState, useContext } from "react";
import "./queueRequest.scss";
import profileImg from "../../../assets/img/Ellipse 320.PNG";
import {
  FaSortAmountDownAlt,
} from "react-icons/fa";
import CustomCheckBox from "../../../components/customCheckBox";
import useExplore from "../../../hooks/explore";
import ExploreContext from "../../../hooks/exploreContext";
import moment from "moment";
import LoaderPurpleBg from "../../../assets/loaders/LoaderPurpleBg";

const QueueRequest = () => {
  const { handleGetQueueRequests, handleUpdateQueueStatus } = useExplore();
  const { queueRequests } = useContext(ExploreContext);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    handleGetQueueRequests();
  }, []);

  useEffect(() => {
    if (queueRequests?.data?.count > 0) setPageLoading(false);
  }, [queueRequests]);

  const handleAcceptQueueRequest = (id, status) => {
    handleUpdateQueueStatus(id, status);
  }


  return (
    <div className="queue__request">
      <div className="bkr-top-header">
        {/* <button className="add-new">Add new customer to list</button> */}
        <div className="sort-box">
          <p>Sort By</p>
          <div className="sort-input">
            Earliest to Latest <FaSortAmountDownAlt color="var(--bg-orange)" />
          </div>
        </div>
      </div>
      <div className="bkr-top-card">
        <div className="tc-box-one">
          <div className="cb-1">
            <label className="check-label" htmlFor="000">
              <CustomCheckBox
                style={{
                  minWidth: 15,
                  height: 15,
                  borderColor: "var(--text-grey)",
                  marginBottom: -2,
                  marginRight: 10,
                  border: "solid",
                  borderWidth: 1,
                  borderRadius: 2,
                }}
              />
              Select bookings
            </label>
          </div>
        </div>
        <div className="tc-box-two">
          <button className="btn-accept">Accept all</button>
          <button className="btn-decline">Decline</button>
        </div>
      </div>

      {
        pageLoading ?
          <LoaderPurpleBg style={{ width: 70, height: 70 }} />
          :
          queueRequests?.data?.count>0 &&
          queueRequests?.data?.results.map((request, index) => (
            <div className="bkr-cbc-card">
            <div className="bkr-cbcc-body">
              <div className="item__detail-box">
                <div className="cb-1">
                  <label className="check-label" htmlFor="001">
                    <CustomCheckBox
                      style={{
                        minWidth: 15,
                        height: 15,
                        borderColor: "var(--text-grey)",
                        marginBottom: -2,
                        marginRight: 10,
                        border: "solid",
                        borderWidth: 1,
                        borderRadius: 2,
                      }}
                    />
                    {index+1}
                  </label>
                </div>
                <div className="orc-detail-box">
                  <img src={profileImg} alt="" />
                  <div className="orc-detail-box-user">
                    <h4>{request?.owner?.full_name}</h4>
                    <h6>@{request?.owner?.username}</h6>
                  </div>
                </div>
              </div>
              <div className="cbcc-item__box">
                <div className="item-detail-one">
                  <h4>{request?.service?.title}</h4>
                </div>
                <div className="item-detail-two">
                  <h5>
                    Time: <span>{moment(request?.service_date).format('LT')}</span>
                  </h5>
                  <h5>
                    Date: <span>{moment(request?.service_date).format('ll')}</span>
                  </h5>
                </div>
              </div>
              {
                request?.queue_status === "AUTO_ACCEPT" || request?.queue_status === "ACCEPT" ?
                <div className="item-btn-box">
                  <p className=" accept-green">Request Accepted </p>
                </div>
                : null
              }
              {
                request?.queue_status === "PENDING" &&
                 <div className="item-btn-box">
                  <button className="btn-accept" onClick={() => handleAcceptQueueRequest( request.service.id, "ACCEPT")}>Accept</button>
                  <button className="btn-decline" onClick={() => handleAcceptQueueRequest( request.service.id, "DECLINE")}>Decline</button>
                </div>
              }

            </div>
          </div>
          ))
      }

      {/* <div className="bkr-cbc-card">
        <div className="bkr-cbcc-body">
          <div className="item__detail-box">
            <div className="cb-1">
              <label className="check-label" htmlFor="001">
                <CustomCheckBox
                  style={{
                    minWidth: 15,
                    height: 15,
                    borderColor: "var(--text-grey)",
                    marginBottom: -2,
                    marginRight: 10,
                    border: "solid",
                    borderWidth: 1,
                    borderRadius: 2,
                  }}
                />
                001
              </label>
            </div>
            <div className="orc-detail-box">
              <img src={profileImg} alt="" />
              <div className="orc-detail-box-user">
                <h4>Maria Sponge</h4>
                <h6>@username</h6>
              </div>
            </div>
          </div>
          <div className="cbcc-item__box">
            <div className="item-detail-one">
              <h4>Barbing salon, Mans Saloon open all day.</h4>
            </div>
            <div className="item-detail-two">
              <h5>
                Time: <span>10am - 12am</span>
              </h5>
              <h5>
                Date: <span>Aug 20, 2021</span>
              </h5>
            </div>
          </div>
          <div className="item-btn-box">
            <p className=" sent-red">Request sent </p>
          </div>
        </div>
      </div> */}
    
    </div>
  );
};

export default QueueRequest;
