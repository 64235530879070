import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../appstore/userData/index.action";

const useSettings = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.currentUser);
  const token = localStorage.getItem("token");
  //request to patch general user data

  const [{ ...generalUserData }, patchGeneralUserData] = useAxios(
    {
      url: `/users/me`,
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handlePatchGeneralUserData = (data) => {
    patchGeneralUserData({
      data,
    }).catch((error) => console.log(error));
  };

  //use effect to patch user data set in redux

  useEffect(() => {
    if (generalUserData.data) {
      dispatch(setCurrentUser({ ...currentUser, user: generalUserData.data }));
    }
  }, [generalUserData.data]);

  //updating profile picture
  const [{ loading, data: profilePicData, error }, patchProfilePic] = useAxios(
    {
      url: "/users/profile-image/",
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handlePatchProfilePic = (data) => {
    patchProfilePic({
      data: data,
    });
  };

  useEffect(() => {
    if (profilePicData) {
      dispatch(setCurrentUser({ ...currentUser, user: profilePicData }));
    }
  }, [profilePicData]);

  //request to patch notification preferences

  const [{ ...notificationPreferences }, patchNotificationPreferences] =
    useAxios(
      {
        url: "/users/social_settings",
        method: "PATCH",
      },
      {
        manual: true,
      }
    );

  const handleNotificationPreferences = (data) => {
    console.log("datatosend", data);
    patchNotificationPreferences({
      data: {
        notification: data,
      },
    }).catch((error) => console.log(error));
  };

  useEffect(() => {
    console.log("changed");
    if (notificationPreferences.data) {
      dispatch(
        setCurrentUser({
          ...currentUser,
          user: {
            ...currentUser.user,
            social_settings: {
              ...currentUser.user.social_settings,
              notification: notificationPreferences.data.notification,
            },
          },
        })
      );
    }
  }, notificationPreferences.data);

  //request to modify privacy preferences

  const [{ ...privacyPreferenceData }, patchPrivacyPreference] = useAxios(
    {
      url: "/users/social_settings",
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handlePatchPrivacyPreferences = (data) => {
    patchPrivacyPreference({
      data: {
        privacy: data,
      },
    }).catch((error) => console.log(error));
  };

  useEffect(() => {
    if (privacyPreferenceData.data) {
      dispatch(
        setCurrentUser({
          ...currentUser,
          user: {
            ...currentUser.user,
            social_settings: {
              ...currentUser.user.social_settings,
              privacy: privacyPreferenceData.data.privacy,
            },
          },
        })
      );
    }
  }, privacyPreferenceData.data);
  //password change

  const [{ ...passwordData }, postPasswordChange] = useAxios(
    {
      url: "/auth/password/change/",
      method: "POST",
    },
    {
      manual: true,
    }
  );
  useEffect(() => {
    const passwordData = {};
  }, []);
  const handlePostPasswordChange = (data) => {
    postPasswordChange({
      data,
    }).catch((error) => console.log(error));
  };

  // patch user business my activity settings

  const [{ ...businessMyActivityData }, patchBusinessMyActivityData] = useAxios(
    {
      url: "/users/user_settings",
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  // function to make patch request to my activity settings

  const handlePatchBusinessActivityData = (data) => {
    patchBusinessMyActivityData({
      data: {
        activities: {
          "My Activity": {
            "Notification Settings": data,
          },
        },
      },
    });
  };

  // use effect to update redux of business my activity settings

  useEffect(() => {
    if (businessMyActivityData.data)
      dispatch(
        setCurrentUser({
          ...currentUser,
          user: {
            ...currentUser.user,
            settings: {
              ...currentUser.user.settings,
              activities: {
                ...currentUser.user.settings.activities,
                ["My Activity"]:
                  businessMyActivityData.data.activities["My Activity"],
              },
            },
          },
        })
      );
  }, [businessMyActivityData.data]);

  // request to get page admins

  const [{ ...pageAdmins }, getPageAdmins] = useAxios(
    {
      url: `/page-admins`,
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetPageAdmins = (data) => {
    getPageAdmins({
      params: {
        page: `${data}`,
        sudo: "true",
      },
    }).catch((error) => console.log(error));
  };

  // add page admin to a business page

  const [{ ...addedPageAdmin }, addPageAdmin] = useAxios(
    {
      method: "post",
      url: "/page-admins/",
    },
    {
      manual: true,
    }
  );

  const handleAddPageAdmin = ({ data }) => {
    addPageAdmin({
      data: {
        ...data,
      },
    });
  };

  // delete page admin

  const [{ ...deletedPageAdmin }, deletePageAdmin] = useAxios(
    {
      method: "delete",
    },
    {
      manual: true,
    }
  );

  const handleDeletePageAdmin = (id) => {
    deletePageAdmin({
      url: `/page-admins/${id}/`,
    });
  };

  // suspend page admin

  const [{ ...suspendedPageAdmin }, suspendPageAdmin] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleSuspendPageAdmin = (id) => {
    suspendPageAdmin({
      url: `/page-admins/${id}/suspend`,
    });
  };

  // get users by search while adding admin

  const [{ ...users }, getUsers] = useAxios(
    {
      url: "/users/",
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetUsers = (searchParams) => {
    getUsers({
      params: {
        search: searchParams,
      },
    });
  };

  // patch req for private seller notification

  const [
    { ...privateSellerNotificationPatchData },
    patchPrivateSellerNotificationPatchData,
  ] = useAxios();

  const handlePrivateSellerNotificationPatchData = ({ id, data, type }) => {
    if (type === "private") {
      patchPrivateSellerNotificationPatchData({
        url: `pages/${id}/business_settings/`,
        method: "put",
        data: {
          notification: {
            Private: data,
          },
        },
      }).catch((error) => console.log(error));
    }

    if (type === "business") {
      patchPrivateSellerNotificationPatchData({
        url: `pages/${id}/business_settings/`,
        method: "put",
        data: {
          notification: {
            "Notification when Business page subscription is about expire":
              data?.general?.[
                "Notification when Business page subscription is about expire"
              ],
            "Notify me of my admin activity on my page":
              data?.general?.["Notify me of my admin activity on my page"],
            Job: data?.job,
            Listing: data?.listing,
            Queue: data?.queue,
            Booking: data?.booking,
          },
        },
      }).catch((error) => console.log(error));
    }

    if (type === "business_general") {
      patchPrivateSellerNotificationPatchData({
        url: `pages/${id}/business_settings/`,
        method: "put",
        data: {
          general: data,
        },
      });
    }
  };

  // getting delivery data from specific page data

  const [{ ...pageSpecificData }, getPageSpecificData] = useAxios();

  const handleGetPageSpecificData = (id) => {
    getPageSpecificData({
      url: `pages/${id}`,
      method: "get",
      params: {
        sudo: "true",
      },
    });
  };

  // patching business page display setting

  const [{ ...patchedPageData }, patchPageData] = useAxios();

  const handlePatchPageData = ({ data, id }) => {
    patchPageData({
      url: `pages/${id}`,
      method: "patch",
      data: data,
    });
  };

  //patching featured image for business pages

  const [{ ...featuredImageData }, patchFeaturedImage] = useAxios();

  const handlePatchFeaturedImage = (data, id, type) => {
    if (type === "featured") {
      patchFeaturedImage({
        url: `pages/${id}`,
        method: "patch",
        data: {
          featured_image: data,
        },
      });
    }
    if (type === "delete") {
      patchFeaturedImage({
        url: `pages/${id}`,
        method: "patch",
        data: {
          featured_image: "",
        },
      });
    }
    if (type === "category") {
      patchFeaturedImage({
        url: `pages/${id}`,
        method: "patch",
        data: {
          featured_image: data,
        },
      });
    }
  };

  const [{ ...pageAdminSettings }, patchPageAdminSettings] = useAxios();

  const handlePatchPageAdminSettings = (id, data) => {
    patchPageAdminSettings({
      url: `/page-admins/${id}/admin_settings/`,
      method: "put",
      params: {
        sudo: "true",
      },
      data: {
        general: {
          Timeline: data.Timeline,
          Jobs: data.Jobs,
          Listings: data.Listings,
          Booking: data.Booking,
          Queue: data.Queue,
        },
      },
    });
  };
  return {
    handlePatchGeneralUserData,
    generalUserData,
    handleNotificationPreferences,
    notificationPreferences,
    handlePatchPrivacyPreferences,
    privacyPreferenceData,
    passwordData,
    handlePostPasswordChange,
    handlePatchProfilePic,
    handlePatchBusinessActivityData,
    businessMyActivityData,
    handleGetPageAdmins,
    pageAdmins,
    privateSellerNotificationPatchData,
    handlePrivateSellerNotificationPatchData,
    handleGetPageSpecificData,
    pageSpecificData,
    handlePatchFeaturedImage,
    featuredImageData,
    handlePatchPageAdminSettings,
    pageAdminSettings,
    patchedPageData,
    handlePatchPageData,
    users,
    handleGetUsers,
    addedPageAdmin,
    handleAddPageAdmin,
    handleSuspendPageAdmin,
    suspendedPageAdmin,
    deletedPageAdmin,
    handleDeletePageAdmin,
  };
};
export default useSettings;
