import { Search } from "@material-ui/icons";
import { HiOutlineSearchCircle } from "react-icons/hi";
import CloseBtn from "../../../../../components/closeBtn";
import SearchBar from "../../../../../components/searchBar";
import "./adminModal.scss";
import { FaSearch } from "react-icons/fa";
import { useContext, useEffect } from "react";
import ModalContext from "../../../../../hooks/modalContexts";
import useSettings from "../../../../../hooks/settings";
import Loader from "../../../../../components/loader";
import { useState } from "react";
const AdminModal = () => {
  const { adminModal, setAdminModal } = useContext(ModalContext);
  const { handleGetUsers, users, handleAddPageAdmin, addedPageAdmin } =
    useSettings();

  //search function

  const handleSearch = (e) => {
    if (e.target.value?.length > 1) {
      handleGetUsers(e.target.value);
    }
  };

  //useffect to close modal when admin has been added successfully

  useEffect(() => {
    addedPageAdmin?.data && setAdminModal();
  }, [addedPageAdmin]);

  //state that holds selected user id

  const [selectedUser, setSelectedUser] = useState();
  return (
    <div className="admin_modal">
      <div className="inner_container">
        <CloseBtn
          style={{
            position: "static",
            marginLeft: "auto",
          }}
          onClick={() => {
            setAdminModal();
          }}
        />
        <div className="inner_center">
          <div className="admin_modal_top">
            <h4>Add admin</h4>
            <div className="admin_search">
              <FaSearch color="#9B9B9B" />
              <input
                type="text"
                placeholder="Search a user"
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <div className="admin_modal_results">
            {users?.loading && <Loader />}
            <ul>
              {users?.data?.results?.map((userItem, idx) => {
                return (
                  <li
                    className={selectedUser === userItem?.id ? "selected" : ""}
                    onClick={() => setSelectedUser(userItem?.id)}
                  >
                    <div className="profile_picture">
                      {userItem?.profile_image?.file && (
                        <img src={userItem?.profile_image?.file} alt="" />
                      )}
                    </div>
                    <div className="user_details">
                      <h4>{userItem?.full_name}</h4>
                      <p>{`@${userItem?.username}`}</p>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div
            className="admin_action_button"
            onClick={() =>
              selectedUser &&
              handleAddPageAdmin({
                data: {
                  admin: selectedUser,
                  page: adminModal,
                },
              })
            }
          >
            {addedPageAdmin?.loading ? (
              <Loader />
            ) : (
              <button>
                <span>Add</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AdminModal;
