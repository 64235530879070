import React from "react";
import { Col, Container } from "reactstrap";
import { SendOutlined, DeleteSweep } from "@material-ui/icons";
// import { Swiper, SwiperSlide } from "swiper/react";
import Styled from "styled-components";
import { colors } from "../../../color";
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { SendAsMessageModal } from "../../modal/customsignupmodal";
import image from "../../../assets/icons/happy-people.png";

import "swiper/swiper-bundle.css";
import "./index.css";
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

const Btn = Styled.button`
    background: ${colors.COMPANY_COLOR};
    width: 32%;
    border: none;
    outline: none;
    font-size: 10px;
    height: 25px;
    color: white;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
`;
const Card = Styled.div`
    height: 260px;
    width: 230px;
    background: url(${(props) => props.img || "none"});
    background-position: center;
    border-radius: 10px;
    transition: all ease 0.3s;
    cursor: pointer;
    &:hover {
        opacity: 0.6;
        border: 1px solid black;
    }
`;
const Circle = Styled.div`
    height: 20px;
    width: 20px;
    background: white;
    border-radius: 50%;
`;

const iconStyle = {
  fontSize: "18px",
  marginRight: "5px",
};

const Posts = [
  {
    img: image,
    date: "Dec 15 2021",
  },
  {
    img: image,
    date: "Dec 15 2021",
  },
  {
    img: image,
    date: "Dec 15 2021",
  },
  {
    img: image,
    date: "Dec 15 2021",
  },
  {
    img: image,
    date: "Dec 15 2021",
  },
];
class GalleryBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  toggle_modal = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  card = (image) => {
    return (
      <Card img={image}>
        <Container>
          <div className="d-flex justify-content-end pt-2">
            <Circle />
          </div>
        </Container>
      </Card>
    );
  };
  render() {
    return (
      <Col style={{ marginTop: "67px" }}>
        <SendAsMessageModal
          isOpen={this.state.isOpen}
          toggle_modal={this.toggle_modal}
        />
        <div className="d-flex justify-content-between">
          <p>Date</p>
          <div
            className="d-flex justify-content-between"
            style={{ width: "39%" }}>
            <Btn>Share</Btn>
            <Btn onClick={this.toggle_modal}>
              <SendOutlined style={iconStyle} /> Send
            </Btn>
            <Btn>
              <DeleteSweep style={iconStyle} /> Delete
            </Btn>
          </div>
        </div>
        <div>
          {/* <Swiper
            spaceBetween={10}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {Posts.map((data, i) => (
              <SwiperSlide key={i}>
                {this.card(data.img)}
                <p style={{ fontSize: "10px" }}>Posted on {data.date}</p>
              </SwiperSlide>
            ))}
          </Swiper> */}
        </div>
        <div className="d-flex justify-content-between mt-3">
          <p>Date</p>
        </div>
        <div>
          {/* <Swiper
            spaceBetween={31}
            slidesPerView={3}
            navigation
            pagination={{ clickable: true }}
            onSlideChange={() => console.log("slide change")}
            onSwiper={(swiper) => console.log(swiper)}
          >
            {Posts.map((data, i) => (
              <SwiperSlide key={i}>
                {this.card(data.img)}
                <p style={{ fontSize: "10px" }}>Posted on {data.date}</p>
              </SwiperSlide>
            ))}
          </Swiper> */}
        </div>
        <Col xl={7} className="d-flex justify-content-center m-auto">
          <p>hey</p>
        </Col>
      </Col>
    );
  }
}

export default GalleryBody;
