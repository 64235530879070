const RebuildArr = (data) => {
    const obj = {}
    data?.map((dataItem, idx)=> {

        obj[dataItem.title] = dataItem?.value

    })
    return obj
}

export default RebuildArr