import { useContext } from 'react'
import './index.scss'
import ModalContext from '../../hooks/modalContexts'
import { FaTimes,} from 'react-icons/fa'
import { IoMdClose } from "react-icons/all";
import closeBtn from "../closeBtn";
import { toSentenceCaseUnderscore } from '../../utils/helpers';
import moment from "moment";

const JobModal = () => {
    const {handleJobItemModal, jobItemModal} = useContext(ModalContext)

    console.log(jobItemModal);
    return(
        <div
        onClick={()=>{handleJobItemModal('RESET_JOB_TO_DISPLAY')}}
        className="jobModal"
        >
         <div
         onClick={(e)=> e.stopPropagation()}
         className="jobModalContainer"
         >
            <div
            onClick={()=>{handleJobItemModal('RESET_JOB_TO_DISPLAY')}}
            className="closeIcon"
            >
             <span> <IoMdClose /> </span>
            </div>
            <h3> {jobItemModal?.title} <span className="active_status">{toSentenceCaseUnderscore(jobItemModal?.status)}</span></h3>
            <p className='address'> {jobItemModal?.page?.location?.address} </p>
            <p className='address_details'>
                {jobItemModal?.page?.description}
            </p>
             <h5>
                Job Description
            </h5>
            <p className='description'>
                {jobItemModal?.description}
            </p>

                <div className="job_type">
                    <div className="job_small">
                        <p>
                            <span className="left">
                                Job Type:
                            </span>
                            <span> {toSentenceCaseUnderscore(jobItemModal?.type)}</span>
                            <span className="right">
                                Salary Range:
                            </span>
                            <span> ${jobItemModal?.min_salary} - ${jobItemModal?.max_salary}</span>
                        </p>
                    </div>
                </div>
                <div className="job_type">
                    <div className="job_small">
                        <p>
                            <span className="left">
                                Posted:
                            </span>
                            <span> {moment(jobItemModal?.created_at).format("LL")}</span>
                            <span className="right">
                                End:
                            </span>
                            <span> {moment(jobItemModal?.end_on).format("LL")}</span>

                        </p>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default JobModal
