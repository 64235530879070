import { useEffect, useState } from "react"
import Switch from "./switch"
import useSettings from "../../../hooks/settings"

const NotificationItem = ({notificationSet, idx, notificationOption, setNotificationSet}) => {
    const [index, setIndex] = useState()
    return(
        <li>
            <p>{notificationOption.notificationTitle}</p>
            <div className={`switch ${notificationOption.value === false  && 'off'}`} 
        onClick={()=>{
            setIndex(idx)
            // handlePatchPrivacyPreferences(notificationSet)
            setNotificationSet(notificationSet.map((data)=>{
                let indexNo = notificationSet.indexOf(data)
                if(indexNo === idx){
                    return data.value ? {...data, value: false}: {...data, value: true}
                }
                return data
            }))
        }}>
            <span className={`${notificationOption.value === false && 'offSpan'}`} ></span>
        </div>
        </li>
)
}
export default NotificationItem