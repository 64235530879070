import useAxios from "axios-hooks";
import { useEffect, useState } from "react";

const useCarts = () => {
  //
  //
  //
  //GET Cart Summary
  const [{ ...cartSummaryData }, cartSummary] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleCartSummary = async () => {
    await cartSummary({
      url: `/users/cart_summary/`,
    });
  };
  //
  //
  //
  //GET Cart List
  const [{ ...cartListData }, cartList] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleCartList = async () => {
    await cartList({
      url: `/users/cart/`,
    });
  };
  //
  //
  //
  //PATCH update order/cart
  const [{ ...updateCartQuantityData }, updateCartQuantity] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdateCartQuantity = async (id, data) => {
    await updateCartQuantity({
      url: `/orders/${id}/?in_cart=true`,
      data: {
        quantity: data,
      },
    });
  };
  //
  //
  //
  //DELETE Cart/Order
  const [{ ...deleteCartData }, deleteCart] = useAxios(
    {
      method: "delete",
    },
    {
      manual: true,
    }
  );

  const handleDeleteCart = async (id) => {
    await deleteCart({
      url: `/orders/${id}/`,
    });
  };
  //
  //
  //
  //DELETE Booking/Cart
  const [{ ...deleteBookingCartData }, deleteBookingCart] = useAxios(
    {
      method: "delete",
    },
    {
      manual: true,
    }
  );

  const handleDeleteBookingCart = async (id) => {
    await deleteBookingCart({
      url: `/bookings/${id}/`,
    });
  };
  //
  //
  //
  //DELETE Cart BULK
  const [{ ...deleteCartBulkData }, deleteCartBulk] = useAxios(
    {
      method: "delete",
    },
    {
      manual: true,
    }
  );

  const handleDeleteCartBulk = async (id) => {
    await deleteCartBulk({
      url: `/orders/bulk/`,
    });
  };

  return {
    cartSummaryData,
    handleCartSummary,
    cartListData,
    handleCartList,
    deleteCartData,
    handleDeleteCart,
    deleteCartBulkData,
    handleDeleteCartBulk,
    updateCartQuantityData,
    handleUpdateCartQuantity,
    deleteBookingCartData,
    handleDeleteBookingCart
  };
};

export default useCarts;

//ae6a1d7770