import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../appstore/navigationDisplayToggle/index.action";

const useDeviceType = () => {
  const maxMobile = 800;
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState();
  const [isDesktop, setIsDesktop] = useState();

  const handleResize = (width) => {
    if (width <= maxMobile) {
      setIsMobile(true);
      setIsDesktop(false);
    } else {
      setIsDesktop(true);
      setIsMobile(false);
    }
  };

  //   console.log(isMobile, isDesktop);

  useEffect(() => {
    handleResize(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    );

    window.addEventListener("resize", () => {
      handleResize(
        window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth
      );
    });
  }, []);

  // deactivate rightbar and left if mobile or enable
  useEffect(() => {
    isMobile && dispatch(toggleRightBar(false));

    // isDesktop && dispatch(toggleRightBar(true));
  }, [isMobile, isDesktop]);
  return {
    isMobile,
    isDesktop,
  };
};

export default useDeviceType;
