import React, { useState, useEffect } from "react";
import "../index.scss";
import {
  FaSortAmountDownAlt,
  FaCalendarAlt,
  FaChevronDown,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomDropdown from "../../customDropdown/customDropdown";
import useBookingRequest from "../useBookingRequest";
import useBooking from "../useBooking";
import moment from "moment";

const AppointmentHistoryBody = () => {
  /* datepicker */
  const [startDate, setStartDate] = useState(new Date());
  const { handleGetBookings, bookingsData, allBookings } = useBookingRequest();

  const {
    handleCreateBookingRequest,
    handleGetPages,
    getPagesData,
    handleGetServices,
    servicesData,
    handleGetPersonnels,
    personnelData,
  } = useBooking();

  const personnelList = [
    { label: "Mr John (Fin Consultant)" },
    { label: "Mr John (Fin Consultant)" },
  ];

  const servicesList = [{ label: "Manicure" }, { label: "Manicure" }];

  const status = [
    { label: "All", icon: "." },
    { label: "Pending", icon: "." },
    { label: "Completed", icon: "." },
    { label: "Uncompleted", icon: "." },
    { label: "Cancelled", icon: "." },
    { label: "Declined", icon: "." },
  ];

  const [personnelValue, setPersonnelValue] = useState(personnelList[0].label);
  const [servicesValue, setServicesValue] = useState(servicesList[0].label);
  const [statusValue, setStatusValue] = useState(status[0].label);

  const handlePersonnelChange = () => {
    setPersonnelValue(personnelList.label);
  };

  const handleServicesChange = () => {
    setServicesValue(servicesList.label);
    if (servicesList === "All") {
    }
    if (servicesList === "Pending") {
    }
    if (servicesList === "Completed") {
    }
    if (servicesList === "Uncompleted") {
    }
    if (servicesList === "Cancelled") {
    }
    if (servicesList === "Declined") {
    }
  };

  const handleStatusChange = () => {
    setStatusValue(status.label);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      handleGetBookings();
      handleGetServices();
    }
    return () => {
      isMounted = false;
    };
    // console.log(bookingsData.data);
  }, []);

  return (
    <div className="appointmenthistory-body-container">
      {/* <h5 className="sub-title">Appointment History</h5> */}

      <div className="ahbc-card-body">
        <div className="ma-top-header">
          <div>
            <h4>Select Date </h4>
            <div className="datepicker-container">
              <FaCalendarAlt />

              <DatePicker
                className="custom-date"
                selected={startDate}
                onSelect=""
                onChange={(date) => setStartDate(date)}
                dateFormat="MMM d, yyyy h:mm aa"
                placeholderText="select date"
                showTimeInput
                timeInputLabel="Time"
              />
            </div>
          </div>
          <div className="m-lr-6">
            <h4>Select Personnel </h4>
            <div className="dropbox">
              <CustomDropdown
                items={personnelList}
                value={personnelValue}
                setValue={setPersonnelValue}
                onChange={handlePersonnelChange}
              />
            </div>
          </div>
          <div>
            <h4>Services </h4>
            <div className="dropbox">
              {servicesData?.data?.results && (
                <CustomDropdown
                  items={servicesData?.data?.results?.map((item) => {
                    // console.log(item.name);
                    return { ...item, label: item.title };
                  })}
                />
              )}
            </div>
          </div>

          <div className="sort-box">
            {/* <p>Sort By</p> */}
            <div className="sort-input">
              <span> Show status </span>
              <div className="dropbox2">
                <CustomDropdown
                  items={status}
                  value={statusValue}
                  setValue={setStatusValue}
                  onChange={handleStatusChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="select-ah-header">
          <div className="box__one">
            <h4>Select Personel</h4>
            <div className="dropbox">
              <CustomDropdown />
            </div>
          </div>

          <div className="box__two">
            <h4>Date</h4>
            <div className="datepicker-container">
              <DatePicker
                className="custom-date"
                selected={startDate}
                onSelect=""
                onChange={(date) => setStartDate(date)}
                dateFormat="MMM d, yyyy h:mm aa"
                placeholderText="select date"
                showTimeInput
                timeInputLabel="Time"
              />

              <FaChevronDown className="dropdown-icon" />
            </div>
          </div>

          <div className="box__three">
            <CustomDropdown />
          </div>
        </div> */}
        <div className="ahbc-cb-contents">
          {bookingsData.loading && <p>Loading..</p>}

          {!bookingsData.loading && bookingsData.error && (
            <p>an error occured </p>
          )}
          {!bookingsData.loading &&
            !bookingsData.error &&
            !bookingsData.data && (
              <h2 className="empty-item"> No Booking Request</h2>
            )}

          {!bookingsData.loading &&
            !bookingsData.error &&
            bookingsData?.data?.count > 0 &&
            bookingsData?.data?.results?.map((service, id) => (
              <>
                {service?.service_type === "BOOKING" && (
                  <>
                    {service.status === "INCOMPLETE" ||
                    service.status === "COMPLETED" ||
                    service.status === "PENDING" ||
                    service.status === "CANCELED" ||
                    service.status === "DECLINED" ? (
                      <div className="ahbc-cbc-card" key={service.id}>
                        <div className="ahbc-cbcc-body">
                          <div className="ahcb__contents">
                            <h4>
                              {moment(service.service_date).format(
                                "MMMM Do YYYY, h:mm a"
                              )}
                            </h4>
                            <h5>
                              {service.service.page.name}
                              <span>@{service.service.page.username}</span>
                            </h5>
                            {service.status === "COMPLETED" && (
                              <h6 className="txt-green">completed</h6>
                            )}
                            {service.status === "PENDING" && (
                              <h6 className="txt-blue">pending</h6>
                            )}
                            {service.status === "CANCELED" && (
                              <h6 className="txt-red">canceled</h6>
                            )}
                            {service.status === "DECLINED" && (
                              <h6 className="txt-grey">declined</h6>
                            )}
                            {service.status === "INCOMPLETE" && (
                              <h6 className="txt-yellow">incomplete</h6>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            ))}
        </div>
      </div>
    </div>
  );
};

export default AppointmentHistoryBody;
