import React, {useContext, useEffect, useState} from "react"
import "./queueTimer.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const QueueTimer = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [currentDay, setCurrentDay] = useState();

  useEffect(() => {
    switch(props.day) {
      case "monday":
        setCurrentDay(1)
        break;
      case "tuesday":
        setCurrentDay(2)
        break;
      case "wednesday":
        setCurrentDay(3)
        break;
      case "thursday":
        setCurrentDay(4)
        break;
      case "friday":
        setCurrentDay(5)
        break;
      case "saturday":
        setCurrentDay(6)
        break;
      case "sunday":
        setCurrentDay(0)
        break;
      default:
        setCurrentDay(0)
    }
  }, props.day);

  const setSelectedDate = (date) => {
    setStartDate(date);
    console.log(currentDay);
    props.setDate(date, currentDay);

  }

  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={(date) => setSelectedDate(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
      />
    </>
  )
}

export default QueueTimer;