import "./index.scss";
import profileImg from "../../../../../assets/img/Ellipse 357.png";
import moment from "moment";
import usePrivateSellerDashboard from "../../../../../hooks/privateSellerDashboard";
import { useEffect } from "react";

const OrderItem = ({ data, handleGetAllOrders }) => {
  const { handleUpdateOrderStatus, updateOrderStatusData } =
    usePrivateSellerDashboard();

  useEffect(() => {
    updateOrderStatusData.data && handleGetAllOrders();
  }, [updateOrderStatusData.data]);
  return (
    <div className="orc-item-box">
      <div className="orc-ib-one">
        <div className="orc-detail-box">
          <img
            className="img_holder"
            src={
              data?.owner?.profile_image?.file
                ? data?.owner?.profile_image?.file
                : profileImg
            }
            alt=""
          />
          <div className="orc-detail-box-user">
            <h4>{data?.owner.full_name}</h4>
            <h6>{data?.owner.username}</h6>
          </div>
        </div>
        <h5>{data?.product?.title}</h5>
        <h6>Ordered on {moment(data?.order_date).format("DD MMM YYYY")}</h6>
        <p>Expire on {moment(data?.expiring_date).format("DD MMM YYYY")}</p>
      </div>

      <div className="orc-ib-two">
        <button
          className="btn-accept"
          onClick={() => handleUpdateOrderStatus(data?.id, "ACCEPT")}
        >
          Accept
        </button>
        <button
          className="btn-decline"
          onClick={() => handleUpdateOrderStatus(data?.id, "DECLINE")}
        >
          Decline
        </button>
      </div>
    </div>
  );
};
export default OrderItem;
