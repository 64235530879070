import { MoreOptionsIcon } from "../../../assets/svg/Extras";
import avatar from "../../../assets/img/avatar.png";

const List = ({ data, handleOptions }) => {
  const { isOptionVisible, setIsOptionsVisible } = handleOptions;
  return (
    <div
      className={`item ${data.done && "done__item"}`}
      style={{
        ...(isOptionVisible === data.idx && { zIndex: 2 }),
      }}>
      <div className="queue__number">
        <p>{data.idx + 1}</p>
      </div>
      <div className="client__name">
        <div className="avatar__section">
          <div className="avatar">
            <img src={data.owner?.profile_image ? data.owner?.profile_image : avatar} width={"100%"} alt="" />
          </div>
          <div className="name">
            <h4>
              {" "}
              {data.owner.full_name} <span>@{data.owner.username}</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="status">
        <button>{data.status === "COMPLETED" ? "Done" : "Ready"}</button>
      </div>
      <div className="marking"></div>
      <div
        className="options__wrap"
        onClick={(e) => {
          data.idx !== isOptionVisible
            ? setIsOptionsVisible(data.idx)
            : setIsOptionsVisible();
        }}>
        <MoreOptionsIcon />
        {isOptionVisible === data.idx && (
          <div className="options" onClick={(e) => e.stopPropagation()}>
            <span>Move to top</span>
            <span>Move down</span>
            {/* <span>Set waiting time</span> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default List;
