const GroupUserIcon =({fill}) => {
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
  <g id="profile-2user" transform="translate(-172 -252)">
    <path id="Vector" d="M5.35,10.37H5.27a.543.543,0,0,0-.18,0,5.145,5.145,0,1,1,.29,0ZM5.19,1.5a3.686,3.686,0,0,0-.14,7.37,1.665,1.665,0,0,1,.32,0A3.687,3.687,0,0,0,5.19,1.5Z" transform="translate(175.81 253.25)" fill={fill||"#490057"}/>
    <path id="Vector-2" data-name="Vector" d="M.964,8.5a.277.277,0,0,1-.09-.01A.792.792,0,0,1,0,7.83.729.729,0,0,1,.624,7c.12-.01.25-.01.36-.01a2.752,2.752,0,0,0-.15-5.5A.741.741,0,0,1,.084.75.755.755,0,0,1,.834,0a4.259,4.259,0,0,1,4.25,4.25A4.241,4.241,0,0,1,.994,8.5Z" transform="translate(187.576 255.25)" fill={fill || "#490057"}/>
    <path id="Vector-3" data-name="Vector" d="M7.57,10.112a9.878,9.878,0,0,1-5.42-1.5A4.291,4.291,0,0,1,0,5.062a4.336,4.336,0,0,1,2.15-3.57,10.588,10.588,0,0,1,10.84,0,4.314,4.314,0,0,1,2.15,3.55,4.336,4.336,0,0,1-2.15,3.57A9.9,9.9,0,0,1,7.57,10.112ZM2.98,2.752A2.876,2.876,0,0,0,1.5,5.072a2.869,2.869,0,0,0,1.48,2.3,9.037,9.037,0,0,0,9.18,0,2.876,2.876,0,0,0,1.48-2.32,2.869,2.869,0,0,0-1.48-2.3A9.084,9.084,0,0,0,2.98,2.752Z" transform="translate(173.6 264.438)" fill={fill || "#490057"}/>
    <path id="Vector-4" data-name="Vector" d="M0,0H24V24H0Z" transform="translate(172 252)" fill="none" opacity="0"/>
    <path id="Vector-5" data-name="Vector" d="M.744,7.508a.738.738,0,0,1-.73-.6.76.76,0,0,1,.58-.89,4.05,4.05,0,0,0,1.66-.73,1.956,1.956,0,0,0,.88-1.54,1.94,1.94,0,0,0-.87-1.53,4.187,4.187,0,0,0-1.64-.73A.753.753,0,1,1,.954.018a5.581,5.581,0,0,1,2.22,1,3.439,3.439,0,0,1,1.46,2.73,3.483,3.483,0,0,1-1.47,2.74,5.416,5.416,0,0,1-2.26,1A.468.468,0,0,1,.744,7.508Z" transform="translate(189.596 265.242)" fill={fill || "#490057"}/>
  </g>
</svg>

    )
}
export default GroupUserIcon