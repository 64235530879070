import React, { useState } from "react";
import "./index.scss";
import {
  FaCartArrowDown,
  FaEnvelope,
  FaStar,
  FaBell,
  FaPlus,
  FaMoneyCheck,
  FaChevronDown,
  FaHistory,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import { Link } from "react-router-dom";
import { Rating, RatingView } from "react-simple-star-rating";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import profileImg from "../../../assets/img/Ellipse 357.png";
import bannerImg from "../../../assets/img/Rectangle 2096.png";

const DashboardBodyMV = () => {
  const [rating, setRating] = useState(0);
  const [isOpen, setOpen] = useState(false);
  const rate = 4.5;

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    //logic
  };

  return (
    <div className="dashboard-body-container">
      <div className="dbc-header">
        <h1 className="welcome-title">
          Good Morning! <span>Jonathan </span>
        </h1>
        <div className="cl-btn-box">
          <Link to="/privateseller/createlistings" className="cl-btn">
            Create Listing
          </Link>
        </div>
      </div>

      <div className="dbc-card">
        <div className="dbc-card-body">
          {/* -------- SHORTCUT CARD --------*/}

          <div className="dbc-container-five">
            <div className="dbc-banner-container">
              <div className="dbc-banner-title">
                <h1>
                  What would you <br />
                  like to do today?
                </h1>
              </div>
              <img src={bannerImg} alt="" />
            </div>
            <div className="shortcuts-box">
              <>
                <h6>Shortcuts</h6>
              </>
              <div className="sc-box-items">
                <div>
                  <Link to="">
                    {" "}
                    <FaPlus /> Manage Listing{" "}
                  </Link>
                </div>
                <div>
                  <Link to="">
                    <FaCartArrowDown /> View Orders{" "}
                  </Link>
                </div>
                <div>
                  <Link to="">
                    <FaMoneyCheck /> Checkout{" "}
                  </Link>
                </div>
                <div>
                  <Link to="">
                    <FaHistory /> View History{" "}
                  </Link>
                </div>

                <div>
                  <Link to="">
                    <Refferal /> View Refferal{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="dbc-container-one">
            {/* -------- NOTIFICATION CARD --------*/}
            <div className="notification-card">
              <div className="purple-card-header">
                <div
                  className={`accordion-title ${isOpen ? "open" : ""}`}
                  onClick={() => setOpen(!isOpen)}
                >
                  <span>
                    <FaBell />
                  </span>
                  <h3>Notification</h3>
                  <span className="drop-icon">
                    <FaChevronDown />
                  </span>
                </div>
              </div>
              <div className="nc-card-body">
                <div className={`accordion-item ${!isOpen ? "collapsed" : ""}`}>
                  <div>
                    <div className="ncc-item-box">
                      <FaEnvelope />
                      <div className="ncc-ib-one">
                        <h5>New message</h5>
                        <h6>Custom order for shirt</h6>
                      </div>
                      <div className="ncc-ib-two">
                        <h6>Oct 12 2021</h6>
                        <h6>10:10pm</h6>
                      </div>
                    </div>
                    <div className="ncc-item-box">
                      <FaEnvelope />
                      <div className="ncc-ib-one">
                        <h5>New message</h5>
                        <h6>Custom order for shirt</h6>
                      </div>
                      <div className="ncc-ib-two">
                        <h6>Oct 12 2021</h6>
                        <h6>10:10pm</h6>
                      </div>
                    </div>
                    <div className="ncc-footer">
                      <Link className="ncc-btn">show more</Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="dbc-container-four">
            <div className="dbc-container-two">
              {/* -------- ORDER REQUEST CARD --------*/}
              <div className="order-request-card">
                <div className="purple-card-header">
                  <h3>
                    <span>
                      <FaCartArrowDown />
                    </span>
                    Order Request
                  </h3>
                </div>
                <div className="or-card-body">
                  <div className="orc-item-box">
                    <div className="orc-ib-one">
                      <div className="orc-detail-box">
                        <img src={profileImg} alt="" />
                        <div className="orc-detail-box-user">
                          <h4>Rose Kooto</h4>
                          <h6>@username</h6>
                        </div>
                      </div>
                      <h5>Barbing and Shaving</h5>
                      <h6>Ordered on 2nd Feb 2021 10:00pm</h6>
                      <p>Expire in 40 days</p>
                    </div>

                    <div className="orc-ib-two">
                      <button className="btn-accept">Accept</button>
                      <button className="btn-decline">Decline</button>
                    </div>
                  </div>
                  <div className="orc-item-box">
                    <div className="orc-ib-one">
                      <div className="orc-detail-box">
                        <img src={profileImg} alt="" />
                        <div className="orc-detail-box-user">
                          <h4>Rose Kooto</h4>
                          <h6>@username</h6>
                        </div>
                      </div>
                      <h5>Barbing and Shaving</h5>
                      <h6>Ordered on 2nd Feb 2021 10:00pm</h6>
                      <p>Expire in 40 days</p>
                    </div>

                    <div className="orc-ib-two">
                      <button className="btn-accept">Accept</button>
                      <button className="btn-decline">Decline</button>
                    </div>
                  </div>
                  <div className="orc-footer">
                    <button className="orc-btn">show more</button>
                  </div>
                </div>
              </div>

              <div className="dbc-container-three">
                {/* -------- RATING CARD --------*/}

                <div className="ratings-card">
                  <div className="purple-card-header">
                    <h3>
                      <span>
                        <FaStar />
                      </span>
                      Ratings
                    </h3>
                  </div>
                  <div className="rt-card-body">
                    <div className="profile-rating-container">
                      <div className="cpb-container">
                        <CircularProgressbarWithChildren
                          value={rate}
                          maxValue={5}
                        >
                          <h4>{`${rate}`}</h4>
                          <p> out of 5 </p>
                        </CircularProgressbarWithChildren>
                      </div>
                      <div className="rating-bar-container">
                        <div className="rbc-star">
                          <Rating
                            onClick={handleRating}
                            ratingValue={rating}
                            size={20}
                            label
                            transition
                            fillColor="orange"
                            emptyColor="gray"
                            className="rating-star"
                          />

                          <h6>{rating}</h6>
                        </div>
                        <div className="rbc-review">
                          <p>1.4k Review</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- REVIEWS CARD --------*/}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBodyMV;
