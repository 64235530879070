import { useState } from "react"

const PrivacyDrop = ({idx, activity, selectedOption, setSelectedOption}) => {
    return(
        <ul className="activityDropdown">
            {
                activity[idx]?.activityOptions.map(
                    (activityOption, ids)=>{
                    return <li onClick={()=>{
                       idx===0 && setSelectedOption({...selectedOption, 'Who can see your post': activityOption.value})
                       idx===1 && setSelectedOption({...selectedOption, 'Who can comment on your post': activityOption.value})
                       idx===2 && setSelectedOption({...selectedOption, 'Who can see the people, pages and lists you follow': activityOption.value})
                       idx===3 && setSelectedOption({...selectedOption, 'Who can send me a message?': activityOption.value})
                       idx===4 && setSelectedOption({...selectedOption, 'Who can tag me': activityOption.value})
                    }}>{activityOption.title}</li>
                }
                )
            }
            
        </ul>
    )
}
export  default PrivacyDrop