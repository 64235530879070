import moment from "moment";

const findValue =  (data) => {
    if(data?.date === null){
        if(data.day === 0) return "Monday"
        if(data.day === 1) return "Tuesday"; 
        if(data.day === 2) return "Wednesday"
        if(data.day === 3) return "Thursday"
        if(data.day === 4) return "Friday"
        if(data.day === 5) return "Saturday"
        if(data.day === 6) return "Sunday"
    }
    else return moment(data?.date).format('DDD MMM YYYY')
   
}
export default findValue