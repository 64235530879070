import React, { useEffect, useContext } from "react";
import { FaEnvelope, FaBell } from "react-icons/fa";
import "./notification.scss";

import ExploreContext from "../../hooks/exploreContext";
import moment from "moment";

const NotificationContainer = () => {
  const {jobNotifications} = useContext(ExploreContext);

  return (
    <div className="notification-container">
      <div className="notification-card">
        <div className="purple-card-header">
          <span>
            <FaBell />
          </span>
          <h3>Notification</h3>
        </div>
        <div className="nc-card-body">
          {
            jobNotifications?.data?.results?.length > 0 &&
              jobNotifications.data.results.map((notification, id) => (
                <div className="ncc-item-box" key={id}>
                  <FaEnvelope />
                  <div className="ncc-ib-one">
                    <h5>{notification.title}</h5>
                    <h6>{notification.body}</h6>
                    <h6>
                      {moment(notification.created_at).format("lll")}
                    </h6>
                  </div>

                </div>
              )) 
          }
          <div className="ncc-footer">
            <button className="ncc-btn">view all</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationContainer;
