import DiscountIcon from "../../../assets/svg/disountIcon";
import { FaCheckCircle } from "react-icons/fa";
import NumberInput from "./numberComponent";
import { useContext, useEffect, useState } from "react";
import { showToast } from "../../ReactToast";
import ModalContext from "../../../hooks/modalContexts";

const Deals = ({ onDone = () => {}, initials }) => {
  const [data, setData] = useState({
    tempId: initials?.tempId || initials?.id || Date.now(),
    size: initials?.size || "",
    price: initials?.price || 0,
    minimum_purchase_quantity: initials?.minimum_purchase_quantity || 1,
    quantity: initials?.quantity || 0,
  });

  // console.log(data);
  const { handleDiscountModal, discountModal, stockDiscount } =
    useContext(ModalContext);
  console.log(stockDiscount);
  const getDiscountType = (deals) => {
    if (deals.deal_and_discount_type?.toLowerCase().includes("next")) {
      return "Next Purchase";
    } else if (deals.deal_and_discount_type?.toLowerCase().includes("every")) {
      return "Every Purchase After";
    } else if (deals.deal_and_discount_type?.toLowerCase().includes("total")) {
      return "Total Purchase";
    }
  };

  const handleDone = () => {
    console.log(initials, data);
    try {
      if (data.size.trim() === "") {
        throw "size cannot be empty";
      }
      if (data.price <= 0) {
        throw "price cannot be less than $0";
      }
      if (data.minimum_purchase_quantity <= 0) {
        throw "minimum purchase quantity cannot be 0 or less";
      }
      if (data.minimum_purchase_quantity > data.quantity) {
        throw "minimum purchase quantity cannot be greater than quantity";
      }
      if (data.quantity <= 0) {
        throw "quantity cannot be 0 or less";
      }
      //all good
      onDone({
        ...(initials?.deals.length && { deals: initials.deals }),
        ...data,
        option: {
          size: data.size,
          ...(initials?.deals?.length &&
            initials.deals[0].constant?.quantity_to_discount && {
              quantity_to_discount:
                initials.deals[0].constant.quantity_to_discount,
            }),
          ...(data.deals?.length &&
            data.deals[0].constant?.quantity_to_discount && {
              quantity_to_discount: data.deals[0].constant.quantity_to_discount,
            }),
        },
      });
    } catch (err) {
      showToast("error", err);
    }
  };
  //watch deals and discount data updates
  useEffect(() => {
    if (stockDiscount) {
      setData({
        ...data,
        ...stockDiscount,
      });
    }
  }, [stockDiscount]);
  console.log(data);
  const editDeals = () => {
    //formating deal

    if (initials?.deals?.length) {
      const [deals] = initials.deals;
      if (deals.name.toLowerCase() === "discount") {
        const obj = {
          discount: {
            type: deals.constant.percentage ? "PERCENTAGE" : "FIXED",
            amount: deals.constant.percentage || deals.constant.fix_price,
          },
          expiry: new Date(deals.exp_date),
        };
        console.log(obj);
        handleDiscountModal("ADD_DISCOUNT_TO_DISPLAY", {
          tempId: data.tempId || data.id,
          ...obj,
        });
      } else if (deals.name.toLowerCase() === "deals") {
        const obj = {
          deals: {
            quantity_to_buy: deals.constant.quantity_to_buy,
            quantity_to_discount: deals.constant.quantity_to_discount || 1,
            type:
              deals.priceType || deals.constant.percentage
                ? "PERCENTAGE"
                : "FIXED",
            discountTypeOption:
              deals.discountTypeOption || getDiscountType(deals),
            amount: deals.constant.percentage || deals.constant.fix_price,
          },
          expiry: new Date(deals.exp_date),
        };
        console.log(obj);
        handleDiscountModal("ADD_DISCOUNT_TO_DISPLAY", {
          tempId: data.tempId || data.id,
          ...obj,
        });
      }
    } else {
      handleDiscountModal("ADD_DISCOUNT_TO_DISPLAY", { tempId: data.tempId });
    }
  };
  return (
    <div className="box-deals">
      <div className="size-price">
        <p>
          Size:{" "}
          <span
            contentEditable
            className="size"
            onInput={(e) => {
              const value = e.target.textContent;
              setData({ ...data, size: value });
            }}>
            {initials?.size || initials?.option?.size}
          </span>
        </p>
        <p>
          Price:{" "}
          <span
            contentEditable
            onInput={(e) => {
              const value = Number(e.target.textContent.replace("$", ""));
              setData({ ...data, price: value });
            }}>
            {initials?.price}
          </span>
        </p>
      </div>
      <div className="stock-purchase">
        <p>
          Set Stock Qty:{" "}
          <NumberInput
            onChange={(e) => setData({ ...data, quantity: e })}
            initial={data.quantity}
          />
        </p>
        <p>
          Min Purchase Qty:{" "}
          <NumberInput
            onChange={(e) => setData({ ...data, minimum_purchase_quantity: e })}
            initial={data.minimum_purchase_quantity}
          />
        </p>
      </div>
      <div className="set-discount">
        <button onClick={editDeals}>
          <DiscountIcon /> Set Deals and Discount
        </button>
      </div>
      <div
        className="deals-check"
        onClick={handleDone}
        style={{ cursor: "pointer" }}>
        <FaCheckCircle />
      </div>
    </div>
  );
};
export default Deals;
