import { useState, useContext } from "react";
import "./referralAnalytics.scss";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import SalesGrowthChartRef from "../../../../components/Analytics/components/salesGrowthReferral";
import SalesGrowthChartListing from "../../../../components/Analytics/components/salesGrowthRefListing";
import SalesGrowthChartBooking from "../../../../components/Analytics/components/salesGrowthRefBooking";
import SalesGrowthChartQueue from "../../../../components/Analytics/components/salesGrowthRefQueue";
import TotalEarningsChart from "../../../../components/Analytics/components/totalEarningsReferral";
import CustomDropdown from "../../../../components/Analytics/components/customDropdown";
import AreaChart from "../../../../components/Analytics/Charts/areaChart";
import { BiInfoCircle, BiUpArrowAlt } from "react-icons/bi";
import BigEye from "../../../../assets/svg/bigEye";
import WalletMoney from "../../../../assets/svg/wallet-money";
import RateIcon from "../../../../assets/svg/rateIcon";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ChartStats from "../../../../components/Analytics/components/chartStats";
import GroupUserIcon from "../../../../assets/svg/groupUserIcon";
import ModalContext from "../../../../hooks/modalContexts";

const ReferralAnalytics = ({referralInteraction, referralChart }) => {
  const [activeTab, setActiveTab] = useState("listing");

  const testArray = [1, 2, 3, 4, 5, 6, 7];
  const { setTotalPageVisitModal } = useContext(ModalContext);
  const { setReferralOrderModal } = useContext(ModalContext);
  const { setTotalCommissionsModal } = useContext(ModalContext);
  const { setCustomerConversionModal, setReferralTotalModal} = useContext(ModalContext);

  return (
    <div className="referral-analytics-container">
      <div className="rac-card-body">
        <div className="racb-contents-one">
          <SalesGrowthChartRef
            image={<WalletMoney />}
            title="Referral Growth"
            height={250}
            width={700}
            referralChart={referralChart}
          />
        </div>
        <div className="racb-contents-two">
          <TotalEarningsChart title="Referral" referralInteraction={referralInteraction}/>
        </div>
      </div>
      <div className="rac-card-body-two">
        <div className="racbt-contents-one">
          <div className="rco-header">
            <div className="tab-title">Referral</div>
            <div className="tabs">
              {/* ----------  nav tab -------- */}
              <ul className="nav-tab">
                <li
                  className={activeTab === "listing" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("listing");
                  }}
                >
                  Listing
                </li>
                <li
                  className={activeTab === "booking" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("booking");
                  }}
                >
                  Booking
                </li>
                <li
                  className={activeTab === "queue" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("queue");
                  }}
                >
                  Queue
                </li>
              </ul>
            </div>
          </div>
          <div className="rco-body">
            {activeTab === "listing" && 
            <div className="rco-body">
            <SalesGrowthChartListing image={<WalletMoney />} title="Listing" referralChart={referralChart} />
            </div>
            }
            {activeTab === "booking" && 
            <div className="rco-body">
            <SalesGrowthChartBooking image={<WalletMoney />} title="Booking" referralChart={referralChart} />
            </div>
            }
            {activeTab === "queue" && 
            <div className="rco-body">
            <SalesGrowthChartQueue image={<WalletMoney />} title="Queue" referralChart={referralChart} />
            </div>
            }
          </div>
        </div>
        <div className="dmc-stats">
          <ChartStats
            image={<WalletMoney />}
            title="Total Page visit with link"
            statPercent={referralInteraction?.total_page_visit_with_links?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={referralInteraction?.total_page_visit_with_links?.total}
            onClick={(e) => setTotalPageVisitModal(true)}
          />
          <ChartStats
            image={<GroupUserIcon fill="#fbb03b" />}
            title="Total Commission paid"
            statPercent={referralInteraction?.total_commission_paid?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={"$" + referralInteraction?.total_commission_paid?.total.toFixed(0)}
            onClick={(e) => setTotalCommissionsModal(true)}
          />
          <ChartStats
            image={<BigEye />}
            title="Sales from referral"
            statPercent={referralInteraction?.sales_from_referrals?.summary.percentage.toFixed(1) + "%"}            
            statTime="in less than a week"
            statTotal={referralInteraction?.sales_from_referrals?.total}
            onClick={(e) => setReferralTotalModal(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default ReferralAnalytics;
