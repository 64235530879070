import React, { useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import "./ManageJobs.scss"
import FeedContainer from "../../../../components/FeedContainer";
// import JobCards from "../../../../components/cards/jobCards/index"
import AllJobCards from "./AllJobCards/Allcards"
import { FilterOptions } from '../../../../components/cards/jobCards/jobData'
import {
  FaChevronRight,
  FaChevronLeft,
  FaAngleDown,
  FaSortAmountDownAlt
} from "react-icons/fa";
import Select from 'react-select';
import DropdownIndicator from '../../../../components/cards/jobCards/FontAwesomeIndicator'
import greeting from "../../../../utils/greeting";
import useExplore from "../../../../hooks/explore";

const ManageAllJobs = () => {
  const history = useHistory();
  const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(toggleRightBar(false))
    },[])

  const {handleGetMyJobPosts} = useExplore();
  useEffect(() => {
    handleGetMyJobPosts();
  }, []);
  
    return (
      <FeedContainer>
        <div className='manage_all_jobs'>
          <div className="greeting">
            {greeting()} <span className="user">Jonathan</span>
          </div>
          <div className='jobs_container'>
            <div className="jobs_container_heading">
                <h3 className="title">Manage Jobs</h3>
                <div className="bkr-btn-box">
                  <Link to="/received_applications" className="received_applications">
                    View recieved applications
                  </Link>
                  <Link to="/create-job" className="create-job-btn">
                    Create Job
                  </Link>
                </div>
            </div>
            <div className="cards">
              <div className="bkr-top-header">
                <div className="filter">
                  <p>Filter</p>
                    <Select
                        components={{
                          IndicatorSeparator: ()=> null,
                          DropdownIndicator
                        }}
                        options={FilterOptions}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder={"All"}
                        />
                  </div>
                  <div className="sort">
                    <p>Sort by</p>
                    <div className="input">
                        Earliest to Latest<FaSortAmountDownAlt color="var(--bg-orange)" />
                    </div>
                  </div>
                </div>
                <AllJobCards/>
            </div>
            <Link
                 onClick={history.goBack}
                 className='back-btn'
               >
                <p>Back</p>
            </Link>
            </div>
        </div>
        </FeedContainer>
    )
}

export default ManageAllJobs
