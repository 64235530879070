import { useEffect, useState } from "react";
import FeedContainer from "../../components/FeedContainer";
import "./index.scss";
import Down from "../../assets/svg/Down";
import ActivityQueue from "./components/queue";
import FullQueueItem from "./components/fullQueueItem";
import MyJobs from "./components/nyJobs";
import { GrAction } from "react-icons/gr";
import FullReferralItem from "./components/fullReferralItem";
import useActivity, { ActivityContext } from "../../hooks/activity";
import { useSelector } from "react-redux";
const MyActivityPage = () => {
  const [active, setActive] = useState("queue");
  const [activeSign, setActiveSign] = useState(true);
  const handleViewFull = () => {
    activeSign ? setActiveSign(false) : setActiveSign(true);
  };
  const currentUser = useSelector((state) => state.currentUser);

  const {
    activityQueue,
    handleGetActivityQueueAndBooking,
    activityQueueLoading,
    jobsData,
    handleGetJobsData,
    myOrders,
    handleGetMyOrders,
    handleGetMyReferrals,
    myReferrals,
  } = useActivity();

  useEffect(() => {
    active === "queue" &&
      handleGetActivityQueueAndBooking({ tab: "myqueueing" });
  }, []);

  const [dataIndex, setDataIndex] = useState();

  console.log(jobsData?.data?.results, "activity");
  const activityDatas = useActivity();
  console.log(myReferrals, "my refer");
  console.log(dataIndex, "index");
  return (
    <ActivityContext.Provider value={activityDatas}>
      <FeedContainer>
        <div className="myActivityPage">
          <h4 className="title">My Activity</h4>
          <div className="navTabs">
            <div
              onClick={() => {
                setActive("queue");
                setActiveSign(true);
                handleGetActivityQueueAndBooking({ tab: "myqueueing" });
              }}
              className={`navTab ${active === "queue" && "active"}`}
            >
              <span>My Queueing</span>
            </div>
            <div
              onClick={() => {
                setActive("bookings");
                setActiveSign(true);
                handleGetActivityQueueAndBooking({ tab: "mybooking" });
              }}
              className={`navTab ${active === "bookings" && "active"}`}
            >
              <span>My Bookings</span>
            </div>
            <div
              onClick={() => {
                setActive("jobs");
                handleGetJobsData({});
              }}
              className={`navTab ${active === "jobs" && "active"}`}
            >
              <span>My Jobs</span>
            </div>
            <div
              onClick={() => {
                setActive("order");
                handleGetMyOrders({});
              }}
              className={`navTab ${active === "order" && "active"}`}
            >
              <span>My Orders</span>
            </div>
            <div
              onClick={() => {
                setActive("referrals");
                handleGetMyReferrals({});
                setActiveSign(true);
              }}
              className={`navTab ${active === "referrals" && "active"}`}
            >
              <span>My Referrals</span>
            </div>
          </div>
          {active === "queue" && activeSign && (
            <ActivityQueue
              setDataIndex={setDataIndex}
              data={activityQueue?.results}
              loading={activityQueueLoading}
              active={active}
              handleViewFull={handleViewFull}
              Down={Down}
              handleGetActivityQueueAndBooking={
                handleGetActivityQueueAndBooking
              }
            />
          )}
          {active === "queue" && !activeSign && (
            <FullQueueItem
              data={activityQueue?.results[dataIndex]}
              handleViewFull={handleViewFull}
            />
          )}
          {active === "bookings" && activeSign && (
            <ActivityQueue
              active={active}
              handleViewFull={handleViewFull}
              Down={Down}
              data={activityQueue?.results}
              handleGetActivityQueueAndBooking={
                handleGetActivityQueueAndBooking
              }
              loading={activityQueueLoading}
            />
          )}
          {active === "bookings" && !activeSign && (
            <FullQueueItem handleViewFull={handleViewFull} />
          )}
          {active === "jobs" && (
            <MyJobs
              jobs={jobsData?.data?.results}
              Down={Down}
              active={active}
              handleViewFull={handleViewFull}
              handleGetJobsData={handleGetJobsData}
              loading={jobsData?.loading}
            />
          )}
          {active === "order" && (
            <ActivityQueue
              active={active}
              handleViewFull={handleViewFull}
              Down={Down}
              handler={handleGetMyOrders}
              data={myOrders?.data?.results}
              loading={myOrders?.loading}
            />
          )}
          {active === "referrals" && activeSign && (
            <ActivityQueue
              active={active}
              handleViewFull={handleViewFull}
              Down={Down}
              data={myReferrals?.data?.results}
              setDataIndex={setDataIndex}
              handler={handleGetMyReferrals}
              loading={myReferrals?.loading}
            />
          )}
          {active === "referrals" && !activeSign && (
            <FullReferralItem
              handleViewFull={handleViewFull}
              referralItem={myReferrals?.data?.results[dataIndex]}
            />
          )}
        </div>
      </FeedContainer>
    </ActivityContext.Provider>
  );
};

export default MyActivityPage;
