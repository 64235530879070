import { useEffect, useState, useRef, useCallback, useMemo } from "react";
import "./createbookingModal.scss";
import CustomDropdown from "../../customDropdown/customDropdown";
import CustomCheckBox from "../../Bookings/createBooking/checkBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { SignalCellularNullOutlined } from "@mui/icons-material";
import moment from "moment";
import { showToast } from "../../ReactToast";
import toast, { Toaster } from "react-hot-toast";
// eslint-disable-next-line
import TimeInput from "react-time-input";

const AvailabilityHours = ({
  item,
  idx,
  personnelForm,
  setPersonnelForm,
  availabilityForm2,
  setAvailabilityForm2,
  handlePersonnelHours,
  personnelHoursData,
  businessHours,
  acceptedTimeRange,
  // handlePersonnelTimeChange,
  id,
  handleUpdatePersonnelAvailability,
  toggleEditPersonnelPost,
}) => {
  //   const [personnelForm, setPersonnelForm] = useState(initialFormState);
  const daysArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [err, setErr] = useState("no err");
  const [timeErr, setTimeErr] = useState(false);

  const handleCheckBoxClick = (listnumber, event) => {
    setAvailabilityForm2(
      availabilityForm2.map((item, idx) => {
        return {
          ...item,
          publish:
            idx === listnumber ? event.currentTarget.checked : item.publish,
          // day: item.publish ? idx : null,
        };
      })
    );
  };

  const handleCheckBoxClick2 = (idx, e) => {
    const newFormValues = [...availabilityForm2];
    newFormValues[idx][e.target.name] = e.currentTarget.checked;
    setAvailabilityForm2(newFormValues);
  };

  const newHoursValues = [...availabilityForm2];
  // const businessHours = business_opening_hours2;

  const handleAvailabilityInputChange = (idx, e) => {
    const newFormValues = [...availabilityForm2];
    newFormValues[idx][e.target.name] = e.target.value;
    setAvailabilityForm2(newFormValues);
    console.log("mmmmmm", availabilityForm2[idx][e.target.name]);
  };

  const handlePersonnelTimeChange = async (id, idx) => {
    delete newHoursValues[idx].date;
    await handlePersonnelHours(id, newHoursValues[idx]);
    // timeValidation2(idx)
  };

  // const timeValidation = (idx, e) => {
  //   const newFormValues = [...availabilityForm2];
  //   if (e.target.validationMessage !== "") {
  //     showToast("error", `${e.target.validationMessage}`);
  //     setErr("err");
  //   } else setErr("no err");
  // };

  const timeValidation2 = (idx) => {
    // const newFormValues = business_opening_hours2;
    // const newFormValues2 = [...availabilityForm2];
    // newFormValues[idx] = newFormValues2[idx];
    if (personnelHoursData?.response?.response?.status === 400) {
      // showToast(
      //   "error",
      //   `Duration must be within business working hours ${newFormValues[idx]?.start}:00 to ${newFormValues[idx]?.end}:00 `
      // );
      setTimeErr(true);
    } else setTimeErr(false);
  };
  const handleAvailabilityUpdate = (pid, aid, data) => {
    if (toggleEditPersonnelPost) {
      handleUpdatePersonnelAvailability(pid, aid, data);
    }
  };

  console.log("=================", timeErr);

  console.log("=acceptedTimeRange================", acceptedTimeRange);

  const handleSetTime = useCallback(
    (e, item, idx) => {
      const newFormValues = [...availabilityForm2];
      // newFormValues[idx][e.target.name] = e.target.value;
      // setAvailabilityForm2(newFormValues);

      console.log(e.target.value);
      const [hour, mins] = e.target.value.split(":");
      const [acceptedHour, acceptedMins] =
        acceptedTimeRange[idx].opening_time.split(":");
      const [acceptedHour2, acceptedMins2] =
        acceptedTimeRange[idx].closing_time.split(":");
      console.log(acceptedHour);
      const [acceptedHour3, acceptedMins3] =
        newFormValues[idx].closing_time.split(":");

      const [openAcceptedHour, openAcceptedMins] =
        newFormValues[idx].opening_time.split(":");
      const openAcceptedTime =
        Number(openAcceptedHour) * 60 + Number(openAcceptedHour);
      const [breakAcceptedHour, breakAcceptedMins] =
        newFormValues[idx].break_start.split(":");
      const breakAcceptedTime =
        Number(breakAcceptedHour) * 60 + Number(breakAcceptedMins);

      //converting full time to mins
      const currentTime = Number(hour) * 60 + Number(mins);
      const acceptedTime = Number(acceptedHour) * 60 + Number(acceptedMins);
      const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
      const acceptedTime3 = Number(acceptedHour3) * 60 + Number(acceptedMins3);

      const startDuration = 
      acceptedTimeRange[idx].opening_time.length > 5
      ? acceptedTimeRange[idx].opening_time.slice(0, -3)
      : acceptedTimeRange[idx].opening_time;

      const endDuration = 
      acceptedTimeRange[idx].closing_time.length > 5
      ? acceptedTimeRange[idx].closing_time.slice(0, -3)
      : acceptedTimeRange[idx].closing_time;


      if (
        currentTime < acceptedTime ||
        currentTime >= acceptedTime2 ||
        currentTime >= acceptedTime3
      ) {
        //error
        showToast(
          "error",
          `Duration must be within business working hours ${startDuration} to ${endDuration} `
        );
        //reset time input
        // setTime(acceptedTimeRange.start);
      } else {
        // setTime(target.value);
        newFormValues[idx][e.target.name] = e.target.value;
        setAvailabilityForm2(newFormValues);
      }

      if (breakAcceptedTime < openAcceptedTime) {
        newFormValues[idx].break_start = newFormValues[idx].opening_time;
        setAvailabilityForm2(newFormValues);
        console.log("....set all good");
      } else {
        // setTime(target.value);
        // console.log("....all good")
      }
    },
    [item]
  );

  const handleSetTime1 = useCallback(
    (e, item, idx) => {
      const newFormValues = [...availabilityForm2];
      // newFormValues[idx][e.target.name] = e.target.value;
      // setAvailabilityForm2(newFormValues);

      console.log(e.target.value);
      const [hour, mins] = e.target.value.split(":");
      const [acceptedHour, acceptedMins] =
        acceptedTimeRange[idx].opening_time.split(":");
      const [acceptedHour2, acceptedMins2] =
        acceptedTimeRange[idx].closing_time.split(":");
      console.log(acceptedHour);
      const [acceptedHour3, acceptedMins3] =
        newFormValues[idx].opening_time.split(":");

      const [openAcceptedHour, openAcceptedMins] =
        newFormValues[idx].opening_time.split(":");
      const openAcceptedTime =
        Number(openAcceptedHour) * 60 + Number(openAcceptedHour);
      const [breakAcceptedHour, breakAcceptedMins] =
        newFormValues[idx].break_start.split(":");
      const breakAcceptedTime =
        Number(breakAcceptedHour) * 60 + Number(breakAcceptedMins);

      //converting full time to mins
      const currentTime = Number(hour) * 60 + Number(mins);
      const acceptedTime = Number(acceptedHour) * 60 + Number(acceptedMins);
      const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
      const acceptedTime3 = Number(acceptedHour3) * 60 + Number(acceptedMins3);

      const startDuration =
        acceptedTimeRange[idx].opening_time.length > 5
          ? acceptedTimeRange[idx].opening_time.slice(0, -3)
          : acceptedTimeRange[idx].opening_time;
      const endDuration =
        acceptedTimeRange[idx].closing_time.length > 5
          ? acceptedTimeRange[idx].closing_time.slice(0, -3)
          : acceptedTimeRange[idx].closing_time;

      if (
        currentTime <= acceptedTime ||
        currentTime > acceptedTime2 ||
        currentTime <= acceptedTime3
      ) {
        //error
        showToast(
          "error",
          `Duration must be within business working hours ${startDuration} to ${endDuration} `
        );
        //reset time input
        // setTime(acceptedTimeRange.start);
      } else {
        // setTime(target.value);
        newFormValues[idx][e.target.name] = e.target.value;
        setAvailabilityForm2(newFormValues);
      }

      if (breakAcceptedTime < openAcceptedTime) {
        newFormValues[idx].break_start = newFormValues[idx].opening_time;
        setAvailabilityForm2(newFormValues);
        console.log("....set all good");
      } else {
        // setTime(target.value);
        // console.log("....all good")
      }
    },
    [item]
  );

  const handleSetTime2 = useCallback(
    (e, item, idx) => {
      const newFormValues = [...availabilityForm2];

      console.log(e.target.value);
      const [hour, mins] = e.target.value.split(":");
      const [acceptedHour, acceptedMins] =
        newFormValues[idx].opening_time.split(":");
      // acceptedTimeRange[idx].opening_time.split(":");
      const [acceptedHour2, acceptedMins2] =
        acceptedTimeRange[idx].closing_time.split(":");
      const [breakAcceptedHour2, breakAcceptedMins2] =
        newFormValues[idx].break_end.split(":");

      //converting full time to mins
      const currentTime = Number(hour) * 60 + Number(mins);
      const acceptedTime = Number(acceptedHour) * 60 + Number(acceptedMins);
      const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
      const breakAcceptedTime2 =
        Number(breakAcceptedHour2) * 60 + Number(breakAcceptedMins2);

      const startDuration = acceptedTimeRange[idx].opening_time.slice(0, -3);

      if (
        currentTime <= acceptedTime ||
        currentTime >= acceptedTime2 ||
        currentTime >= breakAcceptedTime2
      ) {
        showToast(
          "error",
          `Break time must be withiin ${newFormValues[idx].opening_time} to ${newFormValues[idx].break_end} `
        );
      } else {
        newFormValues[idx][e.target.name] = e.target.value;
        setAvailabilityForm2(newFormValues);
      }
    },
    [item]
  );

  const handleSetTime3 = useCallback(
    (e, item, idx) => {
      const newFormValues = [...availabilityForm2];

      const [hour, mins] = e.target.value.split(":");

      const [acceptedHour2, acceptedMins2] =
        acceptedTimeRange[idx].closing_time.split(":");
      const [breakAcceptedHour, breakAcceptedMins] =
        newFormValues[idx].break_start.split(":");
      const [breakAcceptedHour3, breakAcceptedMins3] =
        newFormValues[idx].closing_time.split(":");

      //converting full time to mins
      const currentTime = Number(hour) * 60 + Number(mins);
      const acceptedTime2 = Number(acceptedHour2) * 60 + Number(acceptedMins2);
      const breakAcceptedTime =
        Number(breakAcceptedHour) * 60 + Number(breakAcceptedMins);
      const breakAcceptedTime3 =
        Number(breakAcceptedHour3) * 60 + Number(breakAcceptedMins3);

      const endDuration = acceptedTimeRange[idx].closing_time.slice(0, -3);

      if (
        currentTime <= breakAcceptedTime ||
        currentTime >= acceptedTime2 ||
        currentTime >= breakAcceptedTime3
      ) {
        showToast(
          "error",
          `Break time must be within ${newFormValues[idx].break_start} to ${newFormValues[idx].closing_time} `
        );
      } else {
        newFormValues[idx][e.target.name] = e.target.value;
        setAvailabilityForm2(newFormValues);
      }
    },
    [item]
  );

  return (
    <div className="item__days_content_availability" key={idx}>
      <div className="days-box">
        {/* {daysArray[item.day]} */}
        <label
          style={{
            cursor: item?.isDisabled && "default",
          }}
          className="cb1-label"
        >
          <input
            type="checkbox"
            checked={item.publish}
            name="publish"
            // disabled={item.isDisabled}
            onChange={(e) => {
              handleCheckBoxClick2(idx, e);
              handleAvailabilityUpdate(
                personnelForm.id,
                personnelForm.availability[idx].id,
                availabilityForm2[idx]
              );
            }}
            // onChange={handleCheckBoxClick.bind(null, idx)}
          />
          <span
            // style={{
            //   backgroundColor: item.isDisabled ? "#aaaaaa66" : " ",
            //   borderColor: item.isDisabled && "#7676764c",
            //   cursor: item.isDisabled && "default",
            // }}
            className="checkmark"
          ></span>
          {item.name || daysArray[item.day]}
        </label>
      </div>
      <div className="item__one">
        <p>
          Open <span>from</span>
        </p>

        <input
          type="time"
          className="form-input-sm"
          name="opening_time"
          // min={item.break_start}
          max={item.closing_time}
          value={item.opening_time}
          disabled={item.publish === false}
          onChange={(e) => {
            // handleAvailabilityInputChange(idx, e);
            handleSetTime(e, item, idx);
            // handlePersonnelHours(id, newHoursValues[idx]);
            handlePersonnelTimeChange(id, idx);
            timeValidation2(idx);
            handleAvailabilityUpdate(
              personnelForm.id,
              personnelForm.availability[idx].id,
              availabilityForm2[idx]
            );
          }}
        />
        <span>to</span>

        <input
          type="time"
          className="form-input-sm"
          name="closing_time"
          value={item.closing_time}
          disabled={item.publish === false}
          onChange={(e) => {
            // handleAvailabilityInputChange(idx, e);
            handleSetTime1(e, item, idx);
            handlePersonnelTimeChange(id, idx);
            // timeValidation2(idx);
            handleAvailabilityUpdate(
              personnelForm.id,
              personnelForm.availability[idx].id,
              availabilityForm2[idx]
            );
          }}
        />
      </div>
      <div className="item__one">
        <p>
          Break Time <span>from</span>
        </p>
        <input
          type="time"
          className="form-input-sm"
          name="break_start"
          min={item.opening_time}
          disabled={item.publish === false}
          max={item.closing_time || item.break_end}
          value={item.break_start}
          onChange={(e) => {
            // handleAvailabilityInputChange(idx, e);
            handleSetTime2(e, item, idx);
            handlePersonnelHours(id, newHoursValues[idx]);
            handleAvailabilityUpdate(
              personnelForm.id,
              personnelForm.availability[idx].id,
              availabilityForm2[idx]
            );
          }}
        />
        <span>to</span>

        <input
          type="time"
          className="form-input-sm"
          name="break_end"
          min={item.break_start}
          max={item.closing_time}
          value={item.break_end}
          disabled={item.publish === false}
          onChange={(e) => {
            // handleAvailabilityInputChange(idx, e);
            handleSetTime3(e, item, idx);
            handlePersonnelHours(id, newHoursValues[idx]);
            handleAvailabilityUpdate(
              personnelForm.id,
              personnelForm.availability[idx].id,
              availabilityForm2[idx]
            );
          }}
        />
      </div>
    </div>
  );
};
export default AvailabilityHours;
