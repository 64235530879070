import React, { useContext, useState, useEffect, useRef } from "react";
import avatar from "../../../../../../../assets/img/Ellipse 4.png";
import ExploreContext from "../../../../../../../hooks/exploreContext";
import ModalContext from "../../../../../../../hooks/modalContexts";
import { FaShare,FaStar } from "react-icons/fa";
import location from "../../../../../../../assets/explore/locationpin.svg";
import moment from "moment";
import { roundTo2DP, toSentenceCase, getTimeFromDate } from "../../../../../../../utils/helpers";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import refericon from "../../../../../../../assets/explore/people.svg";
import saveIcon from "../../../../../../../assets/explore/save.svg";
import { MdMail } from "react-icons/md";
import useExplore from "../../../../../../../hooks/explore";
import PageServicesFilter from "../../../services/PageServicesFilter/Filter";
import ServicePersonnelFilter from "../../../services/PersonnelFilter/Filter";
import DatePicker from "react-datepicker";
import ServicesFilter from "../../../services/ServicesFilter/Filter";
import GooglePlacesAutocomplete, {geocodeByAddress, getLatLng} from "react-google-places-autocomplete";
import { toggleRightBar } from "../../../../../../../appstore/navigationDisplayToggle/index.action";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../../../../../components/loader";
import subDays from "date-fns/subDays";
import {showToast} from "../../../../../../../components/ReactToast";
import {Toaster} from "react-hot-toast";
import ServicePersonnelTimes from "../../../../../../../components/ServicePersonnelTimes";

const SingleService = (props) => {
  const { allProducts, selectedService, pageServices, allServices, serviceLocations, serviceDataLoading, personnelDates, personnelTimesLoading } = useContext(ExploreContext);
  const { setReferModal } = useContext(ModalContext);
  const { handleBookService, handleGetService, handleGetPersonnelDates} = useExplore;
  const [serviceEndTime, setServiceEndTime] = useState(new Date());
  const [selectedLocation, setSelectedLocation] = useState(null);
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [geoCode, setGeoCode] = useState(null);
  const [selectedPersonnel, setSelectedPersonnel] = useState(null);
  const [serviceStartDate, setServiceStartDate] = useState(new Date());
  const [selectedPageService, setSelectedPageService] = useState([]);
  const [servicePersonnel, setServicePersonnel] = useState([]);
  const [currentServicePersonnel, setCurrentServicePersonnel] = useState(null);
  const [listLocations, setListLocations] = useState([]);
  const [serviceLocation, setServiceLocation] = useState([]);
  const [bookService, setBookService] = useState(false);
  const dispatch = useDispatch();
  const [pageLoading, setPageLoading] = useState(true);
  const [serviceInfoLoading, setServiceInfoLoading] = useState(false);
  const [chosenService, setChosenService] = useState(null);
  const [personnelDatesLoading, setPersonnelDatesLoading] = useState();
  const [selectedAppTime, setSelectedAppTime] = useState(null);
  const [serviceLocationName, setServiceLocationName] = useState(null);

  const selectPageService = (option) => {
    setServiceInfoLoading(true);
    setSelectedPageService(option.value);

    let result = allServices[0].service.data.results.filter(obj => {
      return obj.id === option.value
    })

    // Set chosen service
    setChosenService(result[0].id);

    // Get and set Service Personnel
    const picked = result[0].personnel.map((a) => {
      return {value:a.id, label:a.user}
    });
    setCurrentServicePersonnel(picked);
    console.log(result);
    console.log(result[0].personnel);

    // Get and set Service Locations
    const places = result[0].service_locations.map((a) => {
      return {value:a.id, label:a.address}
    });
    setListLocations(places);

    setTimeout(() => {
      setServiceInfoLoading(false);
    }, 1000);
  };

  const selectServicePersonnel = (option) => {
    console.log(option);
    setSelectedPersonnel(option.value);
  };

  const selectServiceLocation = (option) => {
    setServiceLocation(option.value);
    setServiceLocationName(option.label);
  };

  const EndTimeBox = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
      <DatePicker
        selected={serviceEndTime}
        onChange={(date) => setServiceEndTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="End Time"
        dateFormat="h:mm aa"
        inline
      />
    );
  };

  const showAllListings = () => {
    // toggleBookService();
    props.action();
    props.goBack();
  }

  useEffect(() => {
    if (serviceDataLoading === false && allServices[0]?.service?.data?.results) setPageLoading(false);
  }, [serviceDataLoading]);

  const getGeocodeFromAddress = () => {
    let locationPoint = {};

    geocodeByAddress(selectedLocation?.value?.description)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          locationPoint.lat = lat;
          locationPoint.long = lng;
          setGeoCode(locationPoint);
        });
  };

  const changeServiceDate = (date) => {
    setServiceStartDate(date);
    setPersonnelDatesLoading(true);
    
    let rawDate = date.toISOString();
    
    let index = rawDate.indexOf("T");
    let formattedDate = index !== -1 ? rawDate.substring(0, index) : '';

    props.fetchPersonnelDates(selectedPersonnel,formattedDate);
  }

  useEffect(() => {
    if(personnelDates) {
      console.log(personnelDates);
    }
  }, [personnelDates]);

  useEffect(() => {
    if (selectedLocation?.value?.description) {
      getGeocodeFromAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const handleServiceBooking = () => {
    if (!chosenService || !serviceLocationName || !selectedPersonnel || !selectedAppTime?.opening_time) {
      showToast("error", "Please complete form before proceeding.");
      return;
    }
    let payload = {};

    payload.service = chosenService;

    payload.delivery_location = {};
    payload.delivery_location.address = serviceLocationName;
    payload.personnel = selectedPersonnel;

    payload.time_slot = {};
    payload.time_slot.opening_time = selectedAppTime.opening_time;
    payload.time_slot.closing_time = selectedAppTime.closing_time;

    props.serviceAction(payload);
  };

  return (
    <>
      <Toaster
        position={"top-right"}
        reverseOrder={false}
      />
      {
        pageLoading ?
          <Loader text="Loading..." />
          :
          <section className="service-schedule">

            <div className="details-container">
              <div className="position-card">
                <div className="card-body">
                  <div className="card-head">
                    <div className="business">
                      <img src={avatar} className="avatar" alt="" />

                      <div className="biz-details">
                        <div className="biz-name">
                          {selectedService && selectedService.data
                            ? selectedService.data.page.name
                            : "--"}
                        </div>
                        <div className="biz-handle">
                          @
                          {selectedService && selectedService.data
                            ? selectedService.data.page.username
                            : "--"}
                        </div>
                      </div>
                    </div>

                    <div className="biz-page-details">
                      <div className="details-container">
                        <div className="biz-rating">
                          <div className="star-rating">
                            <div className="rating">
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-empty">
                                <FaStar />
                              </i>
                            </div>

                            <div className="rating-value">
                              {selectedService && selectedService.data
                                ? selectedService.data.page.rating
                                : "0"}
                            </div>
                          </div>

                          <div className="rating-number">
                            {selectedService && selectedService.data
                              ? selectedService.data.page.review_count
                              : "0"}{" "}
                            reviews
                          </div>
                        </div>

                        <div className="job-data">
                          <div className="location">
                            <img src={location} height="22px" alt="" />
                            &nbsp;
                            {selectedService?.data?.page?.location?.address}
                          </div>
                          <div className="post-date">
                            Posted on &nbsp;
                            {selectedService && selectedService.data
                              ? moment(selectedService.data.created_on).format(
                                "l"
                              )
                              : "--"}
                          </div>
                        </div>

                        <div className="page-type">
                          <p className="">
                            {selectedService && selectedService.data
                              ? toSentenceCase(selectedService.data.page.type)
                              : "--"}{" "}
                            Page
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contact-details">
                  <div className="contact-phone">
                    <LocalPhoneIcon />{" "}
                    {selectedService && selectedService.data
                      ? selectedService.data.page.phone_number
                      : "--"}
                  </div>

                  <div className="contact-mail">
                    <MailIcon />{" "}
                    {selectedService && selectedService.data
                      ? selectedService.data.page.email
                      : "--"}
                  </div>

                  <div className="contact-site">
                    <LanguageIcon />{" "}
                    {selectedService && selectedService.data
                      ? selectedService.data?.page?.website
                      : "--"}
                  </div>

                  <button
                    className="biz-refer"
                    onClick={() => setReferModal(true)}>
                    <img src={refericon} alt="" />
                    Refer
                  </button>

                  <div className="job-actions">
                    <img src={saveIcon} className="action-icon" alt="" />
                    <i className="action-icon">
                      <FaShare />
                    </i>
                  </div>
                </div>

                <div className="service-details">
                  <div>
                    <div className="service-name">
                      {selectedService && selectedService.data
                        ? selectedService.data.title
                        : "--"}
                    </div>
                  </div>
                </div>

                <div className="service-description">
                  <div className="description">
                    {selectedService && selectedService.data
                      ? selectedService.data.description
                      : "--"}
                  </div>
                </div>

                <div className="service-schedule">
                  <div className="title service-personnel">Schedule with</div>

                  <div className="schedule-items">
                    <PageServicesFilter
                      options={allServices[0].service.data.results}
                      placeholder="Select Service"
                      action={selectPageService}
                    />

                    {
                      serviceInfoLoading ?
                        <div style={{width:"320px", display:"flex"}}>
                          <Loader text="loading..." width={"320px"}/>
                        </div>
                        :
                        <ServicePersonnelFilter
                          options={currentServicePersonnel}
                          placeholder="Select Personnel"
                          action={(e) => selectServicePersonnel(e)}
                        />
                    }
                  </div>

                  <div className="service-calendar">
                    <div className="title">Set date/time</div>

                    <div className="date-time-card">
                      <div className="date-card">
                        
                        <div className="date-box">
                          <DatePicker
                            selected={serviceStartDate}
                            onChange={(date) => changeServiceDate(date)}
                            minDate={subDays(new Date(), 0)}
                            // showTimeSelect
                            inline
                          />
                        </div>

                        <div className="time-box">
                          {
                            personnelTimesLoading ?
                              <div style={{ width: "320px", display: "flex" }}>
                                <Loader text="loading available time..." width={"320px"} />
                              </div>
                              :
                              <ServicePersonnelTimes
                                times = {personnelDates}
                                chooseTime = {setSelectedAppTime}
                              />
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="location-form">
                  <div className="location-select">
                    <div className="location-label">Service Location</div>

                    <div className="location-input">
                      {
                        serviceInfoLoading ?
                          <div style={{width: "320px", display: "flex"}}>
                            <Loader text="loading..." width={"320px"}/>
                          </div>
                          :
                          <>
                            <ServicesFilter
                              options={listLocations}
                              placeholder="Select Location"
                              action={selectServiceLocation}
                              key="name"
                              value="value"
                            />

                            <div className="location-address">
                              <GooglePlacesAutocomplete
                                apiKey={googleApiKey}
                                selectProps={{
                                  selectedLocation,
                                  onChange: setSelectedLocation,
                                }}
                              />
                            </div>
                          </>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="actions">
                <button
                  className="btn-back"
                  onClick={() => showAllListings()}>
                  Back
                </button>
                <button
                  className="btn-done"
                  onClick={() => handleServiceBooking()}>
                  Done
                </button>
              </div>
            </div>

            <div className="explore-right">
              <button className="message-btn">
                <MdMail />
                Message
              </button>

              <div className="service-contact">
                <div className="service-contact-single">
                  <div className="contact-icon">
                    <LocalPhoneIcon />
                  </div>

                  <div className="contact-value">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.phone_number
                      : "--"}
                  </div>
                </div>

                <div className="service-contact-single">
                  <div className="contact-icon">
                    <MailIcon />
                  </div>
                  <div className="contact-value">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.email
                      : "--"}
                  </div>
                </div>

                <div className="service-contact-single">
                  <div className="contact-icon">
                    <LanguageIcon />
                  </div>
                  <div className="contact-value">
                    {selectedService?.data?.page?.website}
                  </div>
                </div>
              </div>

              <div className="book-fee">
                Booking fee{" "}
                <span className="book-cost">
                  ${selectedService?.data?.booking_fee?.amount}
                </span>
              </div>

              <div className="work-hours">
                <div className="hours-title">Business hours of operations</div>

                <div className="work-schedule">
                  <div className="work-day">
                    <div className="day">Mon</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Tue</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Wed</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Thu</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Fri</div>
                    <div className="time">9am - 4pm</div>
                  </div>
                </div>
              </div>
            </div>

          </section>
      }
    </>
  )
}

export default SingleService;