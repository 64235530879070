import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  FaGlobe,
  FaMapMarkerAlt,
  FaEnvelope,
  FaPhoneAlt,
  FaCommentAlt,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import queryString from "query-string";
import { Rating, RatingView } from "react-simple-star-rating";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
// import profileImg from "../../../assets/img/Ellipse 4.png";
import Shirt from "../../../assets/img/Component 672 –1.PNG";
import DropdownMenu from "./dropdownMenu";
import greeting from "../../../utils/greeting";
import usePages from "../../../hooks/createPage";
import useExplore from "../../../hooks/explore";
import moment from "moment";
import useListings from "../../../hooks/listings";
import useCreateListings from "../../../hooks/createListings";
import BackArrow from "../../../assets/svg/backArrow";

const ProfileBodyDV = () => {
  const {
    handleDeleteListing,
    handleShareListing,
    shareListingData,
    deletedListingData,
  } = useListings();

  const { handleUpdateListing, updateListingData } = useCreateListings();
  const history = useHistory();
  const [rating, setRating] = useState(0);
  // const rate = 4.5;
  const { search } = useLocation();
  const { pageId } = useParams();
  const id = pageId || queryString.parse(search)?.id;
  console.log(pageId);
  const { handleGetSinglePage, singlePageData, handleView } = usePages();
  const { data } = singlePageData;
  const { handleGetProducts, getProductsData } = useExplore();
  const [products, setProducts] = useState([]);
  const handleRating = (rate) => {
    setRating(rate);

    //logic
  };

  useEffect(() => {
    handleGetSinglePage(id);
    handleGetProducts({ page: id, rest: { pause: "all" } });
    handleView(id);
  }, [id]);

  useEffect(() => {
    getProductsData.data?.results.length &&
      setProducts((state) => [...state, ...getProductsData.data.results]);
  }, [getProductsData.data]);

  //for deleted products
  useEffect(() => {
    if (deletedListingData.data) {
      const { data } = deletedListingData;
      setProducts((state) => state.filter((product) => product.id !== data.id));
    }
  }, [deletedListingData.data]);
  // console.log(products);
  //paused products or resumed products
  useEffect(() => {
    if (updateListingData.data) {
      const { data } = updateListingData;
      console.log(data);
      setProducts((state) =>
        state.map((product) => (data.id === product.id ? data : product))
      );
    }
  }, [updateListingData.data]);
  return data && !singlePageData.loading ? (
    <div className="profile-body-container">
      <h1 className="welcome-title">
        <span
          onClick={history.goBack}
          style={{ cursor: "pointer", marginRight: "20px" }}>
          <BackArrow />
        </span>
        {"   "}
        {greeting()} <span>{data.name.split(" ")[0]} </span>
      </h1>
      <div className="pbc-card">
        <h5 className="sub-title"> Profile</h5>
        <div className="pbc-card-top-header">
          <div className="profile-pic-container">
            {data.featured_image && (
              <img src={data.featured_image.file} alt="" />
            )}
          </div>
          <div className="user-info-container">
            <div className="detail__one">
              <h1> {data.name} </h1>
              <h6>{data.username}</h6>
            </div>
            <div className="detail__two">
              {data.location && (
                <p>
                  <span>
                    {" "}
                    <FaMapMarkerAlt />
                  </span>
                  {data.location.address}
                </p>
              )}
              {data.phone_number && (
                <p>
                  <span>
                    {" "}
                    <FaPhoneAlt />
                  </span>
                  {data.phone_number}{" "}
                </p>
              )}
            </div>
            <div className="detail__three">
              {data.email && (
                <p>
                  <span>
                    <FaCommentAlt />{" "}
                  </span>
                  {data.email}
                </p>
              )}
              {data.website && (
                <p>
                  <span>
                    {" "}
                    <FaGlobe />
                  </span>
                  {data.website}{" "}
                </p>
              )}
            </div>
            <div className="inbox-msg">
              <FaEnvelope />
            </div>
          </div>
          <div className="user-status-section">
            <div className="status-content">
              <h5> {data.description}</h5>
            </div>

            <div className="profile-rating-container">
              <div className="cpb-container">
                <CircularProgressbarWithChildren
                  value={data.rating}
                  maxValue={5}>
                  <h4>{`${data.rating}`}</h4>
                  <p> out of 5 </p>
                </CircularProgressbarWithChildren>
              </div>
              <div className="rating-bar-container">
                <div className="rbc-star">
                  <Rating
                    onClick={handleRating}
                    ratingValue={data.rating}
                    size={20}
                    label
                    transition
                    fillColor="orange"
                    emptyColor="gray"
                    className="rating-star"
                  />

                  <h6>{data.rating}</h6>
                </div>
                <div className="rbc-review">
                  <p>{data.review_count} Review</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*----------------SELLER ITEMS ----------------------*/}
        {getProductsData.data ? (
          <div className="seller-items-container">
            {products.map((item) => {
              console.log(products);
              return (
                <div className="si-card-holder" key={item.id}>
                  <div className="si-card">
                    <div className="si-card-header">
                      <p>{item.title}</p>
                      <small className="txt-green">
                        {item.order_left ? "open" : "inactive"}{" "}
                      </small>
                    </div>
                    <div className="si-card-body">
                      {/* <div className="refer-box">
                    <span>
                      <Refferal />
                    </span>
                    <Link to=""> Refer </Link>
                  </div> */}
                      <img
                        className="seller-img"
                        src={item.gallary[0].file}
                        alt=""
                      />
                      <div className="abs-order">
                        {" "}
                        <p> {item.pre_prder ? "Pre Order" : "Buy Now"} </p>{" "}
                      </div>
                    </div>
                    <div className="si-card-footer">
                      <div className="sicf-price-container">
                        <p className="sicf-price">${item.deposit_amount}</p>
                        <p className="sicf-price-slash">
                          {item.discont && `$${item.discont}`}
                        </p>
                      </div>

                      <div className="sic-footer-card">
                        <p className="txt-orange">
                          {moment(item.listing_end_date).fromNow()}
                        </p>
                        <DropdownMenu
                          {...{
                            id: item.id,
                            handleDeleteListing,
                            handleShareListing,
                            shareListingData,
                            deletedListingData,
                            pauseListing: handleUpdateListing,
                            ...item,
                            format: () =>
                              history.push(
                                `/${
                                  item.page.type
                                    .toLowerCase()
                                    .includes("business")
                                    ? "businessseller"
                                    : "privateseller"
                                }/edit-listing/${id}/${item.id}`
                              ),
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>Loading!</div>
        )}
      </div>
    </div>
  ) : (
    <div />
  );
};

export default ProfileBodyDV;
