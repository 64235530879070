import {
  BULK_INSERT_COMMENTS,
  ADD_COMMENT,
  UPDATE_COMMENT_LIKE,
  CLEAR_COMMENTS,
  INITIAL_COMMENTS,
  LIKE_COMMENT_REPLIES,
  ADD_REPLIES,
} from "./commentV2.types";

const defaultComments = [];

const commentsReducerV2 = (state = defaultComments, { type, payload }) => {
  switch (type) {
    case BULK_INSERT_COMMENTS:
      return [...payload, ...state];
    case CLEAR_COMMENTS:
      return defaultComments;
    case ADD_COMMENT:
      //add as normal comment
      return [payload, ...state];
    case UPDATE_COMMENT_LIKE:
      return state.map((comment) => {
        //if comment id and payload id are the same then update
        return comment.id === payload.id
          ? {
              ...comment,
              has_like: payload.status,
              likes: payload.count,
            }
          : comment;
      });
    case LIKE_COMMENT_REPLIES:
      //if comments has any reply with same id as payload, then update the reply
      return state.map((comment) => {
        return {
          ...comment,
          replies: comment.replies.map((reply) => {
            return reply.id === payload.id
              ? { ...reply, likes: payload.count, has_like: payload.status }
              : reply;
          }),
        };
      });
    case ADD_REPLIES:
      return state.map((comment) => {
        return payload.reply_to === comment.id
          ? {
              ...comment,
              replies: [payload, ...comment.replies],
            }
          : comment;
      });
    case INITIAL_COMMENTS:
      return payload;
    default:
      return state;
  }
};
export default commentsReducerV2;
