import "./style.scss";
import randomString from "unique-string-generator";
import {
  FaLuggageCart,
  IoMdClose,
  // IoMdCloseCircleOutline,
} from "react-icons/all";
import { DefaultButton } from "../../Button";
import ReusableDropDown from "../../reusableDropdown";
import { useContext, useEffect, useState } from "react";
import CompletedDeliveryInput from "../businessSellerDelivery/completeDeliveryInput";
import EditDeliveryInput from "../businessSellerDelivery/editDeliveryPickup";
import ModalContext from "../../../hooks/modalContexts";
import CityWide from "../businessSellerDelivery/cityWide";
import CustomCheckBox from "../../customCheckBox";
import Places from "../../googlePlaces";
import { showToast } from "../../ReactToast";
import { Toaster } from "react-hot-toast";

const DeliveryDetail = () => {
  const {
    setPrivateSellerDelivery,
    deliveryListState,
    deliveryToBeEditedState,
  } = useContext(ModalContext);
  const [toBeEdited, setToBeEdited] = deliveryToBeEditedState;
  const [tempId] = useState(toBeEdited?.tempId);
  // const [doorDeliveryFeeFree, setDoorDeliveryFeeFree] = useState();
  const pickupOptions = ["Fixed Date & Time", "Custom Date & Time"];
  const [selectedOption, setSelectedOption] = useState(pickupOptions[0]);

  console.log(toBeEdited, tempId);
  const doorDeliveryOptions = ["Geo fencing", "City Wide"];
  const [edit, setEdit] = useState(true);
  const handleDeliveryMode = (item, value) => {
    setDeliveryMode({
      ...deliveryMode,
      [item]: value !== undefined ? value : !deliveryMode[item],
    });
  };
  // console.log(randomId);
  const [city, setCity] = useState(
    toBeEdited?.city || {
      address: "",
      point: {
        type: "Point",
        coordinates: [],
      },
    }
  );
  const defaultDelivery = {
    delivery_fee: 0,
    address: "",
    free_delivery: false,
    dates: [],
    point: {
      type: "Point",
      coordinates: [],
    },
    close_date: new Date(),
    delivery_mode: "PICK_UP",
  };
  const deliveryState = useState(defaultDelivery);

  const defaultDoorDeliveryMode = {
    delivery_fee: 0,
    address: "",
    free_delivery: false,
    dates: [],

    close_date: new Date(),
    door_delivery_type: "CITY_WIDE",
    geom: {
      type: "MultiPolygon",
      coordinates: [],
    },
    delivery_mode: "DOOR_DELIVERY",
  };
  const [locations, setLocations] = useState(toBeEdited?.locations || []);
  const [deliveryMode, setDeliveryMode] = useState({
    pickup:
      locations.find(
        (location) =>
          location.delivery_mode === "PICK_UP" ||
          location.delivery_mode === "DOOR_LOCATION"
      ) && true,
    door:
      locations.find(
        (location) =>
          location.delivery_mode === "DOOR_DELIVERY" ||
          location.delivery_mode === "DOOR_LOCATION"
      ) && true,
  });

  const doorDeliveryModeState = useState(defaultDoorDeliveryMode);
  const [deliveryList, setDeliveryList] = deliveryListState;
  const [delivery, setDelivery] = deliveryState;
  const [doorDeliveryMode, setDoorDeliveryMode] = doorDeliveryModeState;
  //handle delivery mode
  useEffect(() => {
    const { pickup, door } = deliveryMode;
    if (pickup && door) {
      setDelivery({
        ...delivery,
        delivery_mode: "DOOR_LOCATION",
      });
      return;
    }

    if (pickup) {
      setDelivery({
        ...delivery,
        delivery_mode: "PICK_UP",
      });
      return;
    }

    if (door) {
      setDelivery({
        ...delivery,
        delivery_mode: "DOOR_DELIVERY",
      });
      return;
    }
  }, [deliveryMode]);

  console.log(locations);
  //when a delivery has been set up, will be added to the list below

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDelivery({ ...delivery, [name]: value });
  };

  //clear to be edited if exists once modal loads up
  useEffect(() => {
    if (toBeEdited) setToBeEdited();
  }, []);

  // console.log(deliveryMode);
  return (
    <div
      className="delivery-detail-modal"
      onClick={(e) => setPrivateSellerDelivery(false)}>
      <Toaster />
      <div className="wrap">
        {" "}
        <div className="cancel">
          <IoMdClose />
        </div>
        <div className="main" onClick={(e) => e.stopPropagation()}>
          <div className="d-modal-header">
            <h4>
              <FaLuggageCart /> Delivery Details
            </h4>
            <DefaultButton
              onClick={(e) => {
                // deliveryList.length && setPrivateSellerDelivery(false);
                if (city.address.trim() !== "" && locations.length) {
                  //merge data and send to list of cards
                  if (!tempId) {
                    setDeliveryList([
                      ...deliveryList,
                      {
                        tempId: randomString.UniqueNumberId(),
                        city,
                        locations,
                      },
                    ]);
                  } else {
                    setDeliveryList(
                      deliveryList.map((item) => {
                        if (tempId === item.tempId) {
                          return {
                            ...item,
                            city,
                            locations,
                          };
                        } else return item;
                      })
                    );
                  }
                  setPrivateSellerDelivery(false);
                } else {
                  showToast("error", "Select city and Location");
                }
              }}
              customStyle={{
                borderRadius: 40,
                fontSize: 14,
                padding: "5px 16px",
                backgroundColor: "var(--dark-purple-bg)",
              }}>
              {" "}
              Save
            </DefaultButton>
          </div>
          <div className="delivery-location">
            <div className="set-location">
              <p>Set location</p>
              <Places
                defaultValue={city.address}
                onPlaceSelected={(place) => {
                  console.log(place);
                  setCity({
                    ...city,
                    address: place.formatted_address,
                    point: {
                      type: "Point",
                      coordinates: [
                        place.geometry.location.lng(),
                        place.geometry.location.lat(),
                      ],
                    },
                  });
                }}
              />
            </div>
            <div className="pickup-select">
              <p>Delivery mode</p>
              <div className="pickup-select-main">
                <div className="pickup">
                  Pickup location{" "}
                  <CustomCheckBox
                    size={12}
                    onChange={(e) => {
                      handleDeliveryMode("pickup", e.value);
                    }}
                    defaultValue={
                      locations.find(
                        (location) =>
                          location.delivery_mode === "PICK_UP" ||
                          location.delivery_mode === "DOOR_LOCATION"
                      ) && true
                    }
                  />
                </div>
                <div className="door-delivery">
                  Door Delivery{" "}
                  <CustomCheckBox
                    size={12}
                    onChange={(e) => {
                      handleDeliveryMode("door", e.value);
                    }}
                    defaultValue={
                      locations.find(
                        (location) =>
                          location.delivery_mode === "DOOR_DELIVERY" ||
                          location.delivery_mode === "DOOR_LOCATION"
                      ) && true
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          {
            <div className="pickups-wrap">
              {deliveryMode.pickup && (
                <p className="p-location">Pickup location</p>
              )}

              {locations.map((data, idx) => {
                return (
                  <CompletedDeliveryInput
                    data={data}
                    key={idx}
                    id={idx}
                    locationsState={[locations, setLocations]}
                    editState={[edit, setEdit]}
                    deliveryState={[delivery, setDelivery]}
                    city={city}
                    doorDeliveryState={[doorDeliveryMode, setDoorDeliveryMode]}
                  />
                );
              })}

              {deliveryMode.pickup && (
                <EditDeliveryInput
                  locationsState={[locations, setLocations]}
                  pickupOptions={pickupOptions}
                  selectedOption={selectedOption}
                  setSelectedOption={setSelectedOption}
                  privateSeller={true}
                  handleChange={handleChange}
                  deliveryState={[delivery, setDelivery]}
                  deliveryListState={[deliveryList, setDeliveryList]}
                  deliveryMode={deliveryMode}
                  editState={[edit, setEdit]}
                  defaultState={{ defaultDoorDeliveryMode, defaultDelivery }}
                  city={city}
                />
              )}
            </div>
          }
          {deliveryMode.door &&
            !locations.filter(
              (data) => data.delivery_mode === "DOOR_DELIVERY" && data
            ).length && (
              <>
                <div className="door-delivery">
                  <p>Door Delivery</p>
                  <div className="delivery-setup">
                    <ReusableDropDown
                      current={
                        doorDeliveryMode.door_delivery_type?.toLowerCase() ===
                        "city_wide"
                          ? "City Wide"
                          : " Geo Fencing"
                      }
                      upIcon={true}
                      options={doorDeliveryOptions}
                      onChange={(e) => {
                        const doorDelivery = e;
                        if (doorDelivery.toLowerCase() === "geo fencing") {
                          setDoorDeliveryMode({
                            ...doorDeliveryMode,
                            door_delivery_type: "GEO_FENCING",
                          });
                        } else {
                          setDoorDeliveryMode({
                            ...doorDeliveryMode,
                            door_delivery_type: "CITY_WIDE",
                          });
                        }
                      }}
                    />
                    <p>Set Delivery Fee:</p>
                    <label htmlFor="">
                      ${" "}
                      <input
                        type="number"
                        className="input"
                        readOnly={doorDeliveryMode.free_delivery}
                        name="delivery_fee"
                        onChange={(e) => {
                          setDoorDeliveryMode({
                            ...doorDeliveryMode,
                            delivery_fee: e.target.value,
                          });
                        }}
                      />
                    </label>
                    <div className="free">
                      Free{" "}
                      <CustomCheckBox
                        size={12}
                        defaultValue={doorDeliveryMode.free_delivery}
                        getValue={(e) =>
                          setDoorDeliveryMode({
                            ...doorDeliveryMode,
                            free_delivery: e,
                          })
                        }
                      />
                    </div>
                  </div>
                </div>

                <CityWide
                  // doorDelivery={doorDelivery}
                  defaultState={defaultDoorDeliveryMode}
                  doorDeliveryModeState={[
                    doorDeliveryMode,
                    setDoorDeliveryMode,
                  ]}
                  locationsState={[locations, setLocations]}
                  city={city}
                />
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default DeliveryDetail;
