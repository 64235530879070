import { useState, useRef, useEffect } from "react";
import Image from "../../../assets/img/JobImage1.jpeg";
import Edit from "../../../assets/icons/edit.svg";
import { FaTimes } from "react-icons/fa";
import useUploads from "../../../hooks/uploads";

const BookingImage = ({ bookingForm, setBookingForm, serviceToggleSubmit }) => {
  // const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const imageRef = useRef(null);
  const { handleUploads, uploadData } = useUploads();
  const newUploaded = [];

  const handleUpdateImg = async (file) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("files", file);
    const uploadedData = await handleUploads(formData);

    newUploaded.push(uploadedData.data?.results[0]?.id);
    // newUploaded?.length &&
      // setBookingForm({ ...bookingForm, });
    setLoading(false);
    // setShowImg(true)
    console.log(newUploaded)
  };

  useEffect(() => {
    if (uploadData?.data?.results) {
      setBookingForm({
        ...bookingForm,
        file: uploadData?.data?.results[0]?.file,
        gallary: [uploadData?.data?.results[0]?.id] 
      });
    }

    return () => {};
  }, [uploadData?.data]);

  return (
    <div className="add_media_container">
      <section className="add-image-section">
        <div className="add-photo-card">
          <div className="right_text">
            <p className="add-photo-header">Create thumbnail</p>
            <p className="add-photo-sub-header">
              The photo display on the job listing thumbnail
            </p>
          </div>
        </div>
        <div className="add_slide_images">
          <div className="edit_bg">
            <img
              src={Edit}
              alt=""
              className="edit_icon"
              onClick={(e) => imageRef.current.click()}
            />
          </div>
          <div className="loop_slide_images">
            <div className="loop_slide_images">
              {!bookingForm.file ? (
                <img alt="" src={serviceToggleSubmit ? Image : bookingForm?.file} />
              ) : (
                <>
                  <img
                    src={
                      serviceToggleSubmit
                        ? URL.createObjectURL(bookingForm.file)
                        : bookingForm?.file
                    }
                    alt={bookingForm.file.name}
                  />
                  <div className="delete_icon_bg">
                    <div className="delete_icon">
                      <span>
                        <FaTimes />
                      </span>
                    </div>
                  </div>
                </>
              )}
              <input
                ref={imageRef}
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={async (e) => {
                  if (e.target.files) {
                    // const formData = new FormData();
                    // formData.append("files", e.target.files[0]);
                    // const uploadedData = await handleUploads(formData);
                    // newUploaded.push(uploadedData.data?.results[0]?.id);
                    serviceToggleSubmit
                      ? setBookingForm({
                          ...bookingForm,
                          file: e.target.files[0],
                          // gallary: newUploaded,
                        })
                      : handleUpdateImg(e.target.files[0]);
                  } else
                    setBookingForm({
                      ...bookingForm,
                      file: null,
                    });
                  // const [file] = e.target.files;
                  // setFile(URL.createObjectURL(file))
                }}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default BookingImage;
