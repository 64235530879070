import { useState, useContext } from "react";
import ModalContext from '../../../../../hooks/modalContexts';
import "./index.scss";

function CreateButton() {
  const { handleConnectProfileModal } = useContext(ModalContext);

  return (
    <>
    <button
      onClick={() =>{
        handleConnectProfileModal('ADD_PROFILE_TO_DISPLAY', 'true');
        // handleEditPage();
      }}
      >
      Create Profiles
    </button>
    </>
  )
}

export default CreateButton
