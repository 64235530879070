const RebuildObj = (data = {}, keyToSort) =>{
    const keys = Object.keys(data)
    console.log('keys', keys)

    const newData =  keys.map(key => {
        console.log(key)
        if (key.includes(keyToSort)){
            return {
                value: data[key],
                title: key
            }
        }else return false
    }).filter(data => data && data);

    console.log(newData)

    return newData
}
export default RebuildObj