/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearVideoComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-108 -254)">
      <path
        className="a"
        d="M10.53,16.84H4.21C1.05,16.84,0,14.74,0,12.63V4.21C0,1.05,1.05,0,4.21,0h6.32c3.16,0,4.21,1.05,4.21,4.21v8.42C14.74,15.79,13.68,16.84,10.53,16.84Z"
        transform="translate(110 257.58)"
      />
      <path
        className="a"
        d="M2.78,10.667,0,8.717V2.407L2.78.457c1.36-.95,2.48-.37,2.48,1.3v7.62C5.26,11.047,4.14,11.627,2.78,10.667Z"
        transform="translate(124.74 260.433)"
      />
      <path
        className="a"
        d="M3,1.5A1.5,1.5,0,1,1,1.5,0,1.5,1.5,0,0,1,3,1.5Z"
        transform="translate(118 262)"
      />
      <path className="b" d="M0,0H24V24H0Z" transform="translate(108 254)" />
    </g>
  </svg>
);

export default VuesaxLinearVideoComponent;
