import React, {useEffect, createContext, useState, useContext  } from "react";
import { Link, useHistory } from 'react-router-dom'
import './style.scss'
import useConnect from "../../hooks/connectHooks";
import ExploreContext  from '../../hooks/exploreContext'

function PageLeftBar({
  connectProfileData,
  handleGetSingleProfile,
  profileForm,
  setCurrentProfile,
  currentProfile,
  editData,
  editForm,
  handleGetConnectProfileSuggested,
  handleGetConnectProfile,
  connectFrom,
  setConnectFrom,
  getPagesData,
  currentPage,
  setCurrentPage,
  handleGetConnectRequestReceived,
  id
}) {
  const history = useHistory();
  return (
    <div className="left-container">
    <div className='left-connect-bar'>
      <div>
        <h1>My Pages</h1>
        <ul>
          {getPagesData?.data?.results
            ?.slice(0, 8)
            .map((page, index) => (
            <li key={page.id}
              onClick={() =>{
              // handleGetConnectProfileSuggested(page?.id)
              setCurrentPage(page?.id)
              handleGetConnectRequestReceived(page?.id)
              // setConnectFrom(user.id)
              console.log(page.id)
            }}
            >
              <p
                  className={page.id === currentPage ? "active" : ""}
                  >
                  {page.name}
                </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <Link
        onClick={history.goBack}
         className='back-btn'
            >
        <h5>Back</h5>
    </Link>
  </div>
  )
}

export default PageLeftBar
