import React, { useContext, useEffect, useState } from "react";
import "./services.scss";
// import "../../../../app.scss";
import avatar from "../../../../../assets/img/Ellipse 4.png";
import listing from "../../../../../assets/icons/image6.png";
import {
  FaEnvelope,
  FaBookmark,
  FaShare,
  FaEllipsisV,
  FaLocationArrow,
  FaUsers,
  FaStar,
  FaInfoCircle,
} from "react-icons/fa";
import product from "../../../../../assets/explore/product.jpg";
import refericon from "../../../../../assets/explore/people.svg";
import producticon from "../../../../../assets/explore/box-white.svg";
import locationicon from "../../../../../assets/explore/location.svg";
import foodicon from "../../../../../assets/explore/food.png";
import haircut from "../../../../../assets/explore/haircut.jpg";
import ExploreCard from "../../../../../components/cards/exploreCards";
import service from "../../../../../assets/explore/service.jpg";
import { toggleRightBar } from "../../../../../appstore/navigationDisplayToggle/index.action";
import { useDispatch, useSelector } from "react-redux";
import location from "../../../../../assets/explore/locationpin.svg";
import Checkbox from "@mui/material/Checkbox";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import DatePicker from "react-datepicker";
import { Calendar, getAllDatesInRange } from "react-multi-date-picker";
import ReusableDropDown from "../../../../../components/reusableDropdown";
import { MdMail } from "react-icons/md";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import saveIcon from "../../../../../assets/explore/save.svg";
import ModalContext from "../../../../../hooks/modalContexts";
import ExploreContext from "../../../../../hooks/exploreContext";
import useExplore from "../../../../../hooks/explore";
import bag from "../../../../../assets/img/bag.jpg";
import moment from "moment";
import { toSentenceCase, getTimeFromDate } from "../../../../../utils/helpers";
import Moment from "moment";
import ServicePersonnelFilter from "./PersonnelFilter/Filter";
import PageServicesFilter from "./PageServicesFilter/Filter";
import ServicesFilter from "./ServicesFilter/Filter";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import LoaderPurpleBg from "../../../../../assets/loaders/LoaderPurpleBg";

import Loader from "../../../../../components/loader";
import subDays from "date-fns/subDays";
import ServicePersonnelTimes from "../../../../../components/ServicePersonnelTimes";
import { showToast } from "../../../../../components/ReactToast";
import { Toaster } from "react-hot-toast";

const ExploreServices = (props) => {
  const {
    allServices,
    selectedService,
    setPageServices,
    pageServices,
    serviceLocations,
    queueJoined,
    personnelTimesLoading,
    personnelDates,
  } = useContext(ExploreContext);
  const {
    handleGetServices,
    handleGetService,
    handleGetPageServices,
    handleGetServiceLocations,
    handleBookService,
    handleJoinQueue,
    handleCheckCustomerInQueue,
    handleGetPersonnelDates,
  } = useExplore();

  const thisUser = useSelector((state) => state.currentUser.user);

  const { setReferModal } = useContext(ModalContext);
  const [bookService, setBookService] = useState(false);
  const [joinQueue, setJoinQueue] = useState(false);
  const [inQueue, setInQueue] = useState(false);
  const dispatch = useDispatch();
  const [showServices, setShowServices] = useState(false);
  const [showPersonnel, setShowPersonnel] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [serviceDate, setServiceDate] = useState(new Date());
  const [scheduleTab, setScheduleTab] = useState(1);
  const cities = ["London", "New York", "Toronto", "Milan"];
  const [listLocations, setListLocations] = useState([]);
  const [servicePersonnel, setServicePersonnel] = useState([]);
  const [serviceLocation, setServiceLocation] = useState([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState(null);

  const [selectedPageService, setSelectedPageService] = useState([]);

  const [selectedLocation, setSelectedLocation] = useState(null);
  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
  const [geoCode, setGeoCode] = useState(null);
  const [serviceStartDate, setServiceStartDate] = useState(new Date());
  const [serviceStartTime, setServiceStartTime] = useState(new Date());
  const [serviceEndTime, setServiceEndTime] = useState(new Date());
	const [pageLoading, setPageLoading] = useState(false);


  const [serviceInfoLoading, setServiceInfoLoading] = useState(false);
  const [currentServicePersonnel, setCurrentServicePersonnel] = useState(null);
  const [selectedAppTime, setSelectedAppTime] = useState(null);
  const [chosenService, setChosenService] = useState(null);
  const [personnelDatesLoading, setPersonnelDatesLoading] = useState();
  const [serviceLocationName, setServiceLocationName] = useState(null);
  const bagUrl = "https://static7.depositphotos.com/1029541/705/i/950/depositphotos_7054712-stock-photo-shopping-bag.jpg";

  const selectPageService = (option) => {
    setServiceInfoLoading(true);
    setSelectedPageService(option.value);

    let result = allServices[0].service.data.results.filter(obj => {
      return obj.id === option.value
    })

    // Set chosen service
    setChosenService(result[0].id);

    // Get and set Service Personnel
    const picked = result[0].personnel.map((a) => {
      return {value:a.id, label:a.user}
    });
    setCurrentServicePersonnel(picked);
    console.log(result);
    console.log(result[0].personnel);

    // Get and set Service Locations
    const places = result[0].service_locations.map((a) => {
      return {value:a.id, label:a.address}
    });
    setListLocations(places);

    setTimeout(() => {
      setServiceInfoLoading(false);
    }, 1000);
  };


  const changeServiceDate = (date) => {
    setServiceStartDate(date);
    setPersonnelDatesLoading(true);
    
    let rawDate = date.toISOString();
    
    let index = rawDate.indexOf("T");
    let formattedDate = index !== -1 ? rawDate.substring(0, index) : '';

    handleGetPersonnelDates(selectedPersonnel,formattedDate);
  }

  useEffect(() => {
    if(personnelDates) {
      console.log(personnelDates);
    }
  }, [personnelDates]);

  const selectServiceLocation = (option) => {
    setServiceLocation(option.value);
    setServiceLocationName(option.label);
  };

  const EndTimeBox = () => {
    const [startDate, setStartDate] = useState(new Date());
    return (
      <DatePicker
        selected={serviceEndTime}
        onChange={(date) => setServiceEndTime(date)}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="End Time"
        dateFormat="h:mm aa"
        inline
      />
    );
  };

  const toggleBookService = (id) => {
    if (bookService) {
      dispatch(toggleRightBar(true));
      props.action();
    } else {
      dispatch(toggleRightBar(false));
      props.action();
    }
    setBookService(!bookService);

    handleGetService(id);

    // handleGetPageServices(selectedService?.data?.page?.id)
    // handleGetServiceLocations(selectedService?.data?.page?.id)
    // setServicePersonnel(selectedService?.data?.personnel)
  };

  const toggleJoinQueue = (id) => {
    if (joinQueue) {
      dispatch(toggleRightBar(true));
      props.action();
    } else {
      dispatch(toggleRightBar(false));
      props.action();
    }
    setJoinQueue(!joinQueue);

    handleGetService(id);
  };

  useEffect(() => {
    setServicePersonnel(null);
    setServicePersonnel(selectedService?.data?.personnel);
    handleGetPageServices(selectedService?.data?.page?.id);
    handleGetServiceLocations(selectedService?.data?.id);
  }, [selectedService]);

  useEffect(() => {
    let locations = serviceLocations?.data?.results;
    if (serviceLocations?.data?.count > 0) {
      let strippedLocations = [];
      for (let i = 0; i < serviceLocations.data.count; i++) {
        strippedLocations.push({
          name: locations[i].address,
          value: locations[i].id,
        });
      }
      setListLocations(strippedLocations);
    }
  }, [serviceLocations]);

  const toggleInQueue = (action) => {
    // setInQueue(!inQueue);
    let payload = {};
    payload.service = selectedService?.data?.id;
    if (action === "join") {
      handleJoinQueue(payload);
    }
  };

  useEffect(() => {
    if (queueJoined === true) {
      setInQueue(true);
    }
    console.log(queueJoined);
  }, [queueJoined]);

  useEffect(() => {
    if (joinQueue === true) {
      handleCheckCustomerInQueue(selectedService?.data?.id);
    }
  }, [joinQueue]);

  // const selectPageService = (option) => {
  //   setSelectedPageService(option.value);
  // };

  const selectServicePersonnel = (option) => {
    setSelectedPersonnel(option.value);
  };

  // const selectServiceLocation = (option) => {
  //   setServiceLocation(option.value);
  // };

  const togglePersonnel = () => {
    setShowPersonnel(!showPersonnel);
  };

  const toggleServices = () => {
    setShowServices(!showServices);
  };

  const handlePaginateMinus = () => {
    if (scheduleTab > 1) {
      setScheduleTab(scheduleTab - 1);
    }
  };

  const handlePaginatePlus = () => {
    if (scheduleTab < 3) {
      setScheduleTab(scheduleTab + 1);
    }
  };

  const options = [
    "Best Match",
    "Price [Low - High]",
    "Price [High - Low]",
    "Highest Rated]",
  ];

  const timeChart1 = [
    "08:00am",
    "08:30am",
    "09:00am",
    "09:30am",
    "10:00am",
    "10:30am",
    "11:00am",
    "11:30am",
    "12:00pm",
    "12:30pm",
    "01:00pm",
    "01:30pm",
    "02:00pm",
    "02:30pm",
    "03:00pm",
    "03:30pm",
  ];

  const timeChart2 = [
    "04:00pm",
    "04:30pm",
    "05:00pm",
    "05:30pm",
    "06:00pm",
    "06:30pm",
    "07:00pm",
    "07:30pm",
    "08:00pm",
    "08:30pm",
    "09:00pm",
    "09:30pm",
    "10:00pm",
    "10:30pm",
    "11:00pm",
    "11:30pm",
  ];

  const timeChart3 = [
    "00:00am",
    "00:30am",
    "01:00am",
    "01:30am",
    "02:00am",
    "02:30am",
    "03:00am",
    "03:30am",
    "04:00am",
    "04:30am",
    "05:00am",
    "05:30am",
    "06:00am",
    "06:30am",
    "07:00am",
    "07:30am",
  ];

  const [startTime, setStartTime] = useState("09:30am");
  const [endTime, setEndTime] = useState("10:30am");

  const handleBookingTime = (time) => {
    let amOrPm = time.slice(-2);
    let justTime = time.slice(0, -2);

    let startAmOrPm = startTime.slice(0, -2);
    let endAmOrPm = endTime.slice(0, -2);

    let justStartTime = startTime.slice(0, -2);
    let justEndTime = endTime.slice(0, -2);

    if (justTime < justStartTime) {
      if (amOrPm < startAmOrPm) {
      }
      setStartTime(time);
    }
  };

  useEffect(() => {
    if (selectedLocation?.value?.description) {
      getGeocodeFromAddress();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLocation]);

  const getGeocodeFromAddress = () => {
    let locationPoint = {};

    geocodeByAddress(selectedLocation?.value?.description)
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        locationPoint.lat = lat;
        locationPoint.long = lng;
        setGeoCode(locationPoint);
      });
  };

  useEffect(() => {
		setPageLoading(true);
		handleGetServices();
	}, []);

	useEffect(() => {
		if (allServices[0]?.service?.data) setPageLoading(false);
	}, [allServices])
  
  const handleServiceBooking = () => {
    if (!chosenService || !serviceLocationName || !selectedPersonnel || !selectedAppTime?.opening_time) {
      showToast("error", "Please complete form before proceeding.");
      return;
    }
    let payload = {};

    payload.service = chosenService;
    payload.service_date = serviceEndTime;

    payload.delivery_location = {};
    payload.delivery_location.address = serviceLocationName;

    payload.personnel = selectedPersonnel;

    payload.time_slot = {};
    payload.time_slot.opening_time = selectedAppTime.opening_time;
    payload.time_slot.closing_time = selectedAppTime.closing_time;

    handleBookService(payload);
  };

  return (
    <>
      <Toaster
        position={"top-right"}
        reverseOrder={false}
      />

      {!bookService && !joinQueue ? (
        <section className="allListing">
          <div className="sort">
            <p className="sort-label">Sort</p>

            <div className="dropdown-container">
              <ReusableDropDown
                options={options}
                style={{ minWidth: 180, zIndex: 2 }}
              />
            </div>
          </div>

          <div className="list-cards">
            {
              pageLoading ?
                <div style={{ gridArea: "1 / 1 / 2 / 4" }}>
                  <LoaderPurpleBg style={{ width: 70, height: 70 }} />
                </div>
                :
                allServices[0]?.service.data.count > 0 &&
                allServices[0].service.data.results.map((service, id) => {
                  return (
                    <ExploreCard
                      key={service.id}
                      img={
                        service.gallary.length
                          ? service.gallary[0].file
                          : bagUrl
                      }
                      title={service.title}
                      location="Kingston"
                      type="Service"
                      isReferrable="false"
                      discount=""
                      price={service.booking_fee?.amount}
                      ctaText={
                        service.service_type === "QUEUE"
                          ? "Join Queue"
                          : "Book Now"
                      }
                      action={
                        service.service_type === "QUEUE"
                          ? toggleJoinQueue
                          : toggleBookService
                      }
                      serviceId={service.id}
                    />
                  );
                }
              )
            }

          </div>
        </section>
      ) : null}

      {bookService ? (
        <>
          <section className="service-schedule">
            <div className="details-container">
              <div className="position-card">
                <div className="card-body">
                  <div className="card-head">
                    <div className="business">
                      <img src={avatar} className="avatar" alt=""/>

                      <div className="biz-details">
                        <div className="biz-name">
                          {selectedService && selectedService.data
                            ? selectedService.data.page.name
                            : "--"}
                        </div>
                        <div className="biz-handle">
                          @
                          {selectedService && selectedService.data
                            ? selectedService.data.page.username
                            : "--"}
                        </div>
                      </div>
                    </div>

                    <div className="biz-page-details">
                      <div className="details-container">
                        <div className="biz-rating">
                          <div className="star-rating">
                            <div className="rating">
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-empty">
                                <FaStar />
                              </i>
                            </div>

                            <div className="rating-value">
                              {selectedService && selectedService.data
                                ? selectedService.data.page.rating
                                : "0"}
                            </div>
                          </div>

                          <div className="rating-number">
                            {selectedService && selectedService.data
                              ? selectedService.data.page.review_count
                              : "0"}{" "}
                            reviews
                          </div>
                        </div>

                        <div className="job-data">
                          <div className="location">
                            <img src={location} height="22px" alt=""/>
                            &nbsp;
                            {selectedService?.data?.page?.location?.address}
                          </div>
                          <div className="post-date">
                            Posted on &nbsp;
                            {selectedService && selectedService.data
                              ? moment(selectedService.data.created_on).format(
                                  "l"
                                )
                              : "--"}
                          </div>
                        </div>

                        <div className="page-type">
                          <p className="">
                            {selectedService && selectedService.data
                              ? toSentenceCase(selectedService.data.page.type)
                              : "--"}{" "}
                            Page
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="contact-details">
                  <div className="contact-phone">
                    <LocalPhoneIcon />{" "}
                    {selectedService && selectedService.data
                      ? selectedService.data.page.phone_number
                      : "--"}
                  </div>

                  <div className="contact-mail">
                    <MailIcon />{" "}
                    {selectedService && selectedService.data
                      ? selectedService.data.page.email
                      : "--"}
                  </div>

                  <div className="contact-site">
                    <LanguageIcon />{" "}
                    {selectedService && selectedService.data
                      ? selectedService.data.page.email
                      : "--"}
                  </div>

                  <button
                    className="biz-refer"
                    onClick={() => setReferModal(true)}>
                    <img src={refericon} alt=""/>
                    Refer
                  </button>

                  <div className="job-actions">
                    <img src={saveIcon} className="action-icon" alt=""/>
                    <i className="action-icon">
                      <FaShare />
                    </i>
                  </div>
                </div>

                <div className="service-details">
                  <div>
                    <div className="service-name">
                      {selectedService && selectedService.data
                        ? selectedService.data.page.name
                        : "--"}
                    </div>
                  </div>
                </div>

                <div className="service-description">
                  <div className="description">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.description
                      : "--"}
                  </div>
                </div>

                {/* <div className="service-schedule">
                  <div className="title service-personnel">Schedule with</div>

                  <div className="schedule-items">
                    <PageServicesFilter
                      options={pageServices?.data?.results}
                      placeholder="Select Service"
                      action={selectPageService}
                    />

                    <ServicePersonnelFilter
                      options={servicePersonnel}
                      placeholder="Select Personnel"
                      action={selectServicePersonnel}
                    />
                  </div>

                  <div className="service-calendar">
                    <div className="title">Set date/time</div>

                    <div className="date-time-card">
                      <div className="date-card">

                        <div className="date-box">
                          <DatePicker
                            selected={serviceStartDate}
                            onChange={(date) => setServiceStartDate(date)}
                            showTimeSelect
                            inline
                          />
                        </div>

                        <div className="time-box">
                          <EndTimeBox />
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}

                {/* <div className="location-form">
                  <div className="location-select">
                    <div className="location-label">Service Location</div>

                    <div className="location-input">
                      <ServicesFilter
                        options={listLocations}
                        placeholder="Select Location"
                        action={selectServiceLocation}
                        key="name"
                        value="value"
                      />

                      <div className="location-address">
                        <GooglePlacesAutocomplete
                          apiKey={googleApiKey}
                          selectProps={{
                            selectedLocation,
                            onChange: setSelectedLocation,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div> */}

                <div className="service-schedule">
                  <div className="title service-personnel">Schedule with</div>

                  <div className="schedule-items">
                    <PageServicesFilter
                      options={allServices[0].service.data.results}
                      placeholder="Select Service"
                      action={selectPageService}
                    />

                    {
                      serviceInfoLoading ?
                        <div style={{width:"320px", display:"flex"}}>
                          <Loader text="loading..." width={"320px"}/>
                        </div>
                        :
                        <ServicePersonnelFilter
                          options={currentServicePersonnel}
                          placeholder="Select Personnel"
                          action={(e) => selectServicePersonnel(e)}
                        />
                    }
                  </div>

                  <div className="service-calendar">
                    <div className="title">Set date/time</div>

                    <div className="date-time-card">
                      <div className="date-card">
                        
                        <div className="date-box">
                          <DatePicker
                            selected={serviceStartDate}
                            onChange={(date) => changeServiceDate(date)}
                            minDate={subDays(new Date(), 0)}
                            // showTimeSelect
                            inline
                          />
                        </div>

                        <div className="time-box">
                          {
                            personnelTimesLoading ?
                              <div style={{ width: "320px", display: "flex" }}>
                                <Loader text="loading available time..." width={"320px"} />
                              </div>
                              :
                              <ServicePersonnelTimes
                                times = {personnelDates}
                                chooseTime = {setSelectedAppTime}
                              />
                          }
                        </div>
                      </div>

                    </div>
                  </div>
                </div>

                <div className="location-form">
                  <div className="location-select">
                    <div className="location-label">Service Location</div>

                    <div className="location-input">
                      {
                        serviceInfoLoading ?
                          <div style={{width: "320px", display: "flex"}}>
                            <Loader text="loading..." width={"320px"}/>
                          </div>
                          :
                          <>
                            <ServicesFilter
                              options={listLocations}
                              placeholder="Select Location"
                              action={selectServiceLocation}
                              key="name"
                              value="value"
                            />

                            <div className="location-address">
                              <GooglePlacesAutocomplete
                                apiKey={googleApiKey}
                                selectProps={{
                                  selectedLocation,
                                  onChange: setSelectedLocation,
                                }}
                              />
                            </div>
                          </>
                      }
                    </div>
                  </div>
                </div>


              </div>

              <div className="actions">
                <button
                  className="btn-back"
                  onClick={() => toggleBookService()}>
                  Back
                </button>
                <button
                  className="btn-done"
                  onClick={() => handleServiceBooking()}>
                  Done
                </button>
              </div>
            </div>

            <div className="explore-right">
              <button className="message-btn">
                <MdMail />
                Message
              </button>

              <div className="service-contact">
                <div className="service-contact-single">
                  <div className="contact-icon">
                    <LocalPhoneIcon />
                  </div>

                  <div className="contact-value">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.phone_number
                      : "--"}
                  </div>
                </div>

                <div className="service-contact-single">
                  <div className="contact-icon">
                    <MailIcon />
                  </div>
                  <div className="contact-value">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.email
                      : "--"}
                  </div>
                </div>

                <div className="service-contact-single">
                  <div className="contact-icon">
                    <LanguageIcon />
                  </div>
                  <div className="contact-value">
                    {selectedService?.data?.page?.website}
                  </div>
                </div>
              </div>

              <div className="book-fee">
                Booking fee{" "}
                <span className="book-cost">
                  ${selectedService?.data?.booking_fee?.amount}
                </span>
              </div>

              <div className="work-hours">
                <div className="hours-title">Business hours of operations</div>

                <div className="work-schedule">
                  <div className="work-day">
                    <div className="day">Mon</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Tue</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Wed</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Thu</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Fri</div>
                    <div className="time">9am - 4pm</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}

      {joinQueue ? (
        <>
          <section className="queue-details">
            <div className="details-container">
              <div className="position-card">
                <div className="card-body">
                  <div className="card-head">
                    <div className="business">
                      <img src={avatar} className="avatar" alt=""/>

                      <div className="biz-details">
                        <div className="biz-name">
                          {selectedService && selectedService.data
                            ? selectedService.data.page.name
                            : "--"}
                        </div>
                        <div className="biz-handle">
                          @
                          {selectedService && selectedService.data
                            ? selectedService.data.page.username
                            : "--"}
                        </div>
                      </div>
                    </div>

                    <div className="biz-page-details">
                      <div className="details-container">
                        <div className="biz-rating">
                          <div className="star-rating">
                            <div className="rating">
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-full">
                                <FaStar />
                              </i>
                              <i className="star-empty">
                                <FaStar />
                              </i>
                            </div>

                            <div className="rating-value">
                              {selectedService && selectedService.data
                                ? selectedService.data.page.rating
                                : "0"}
                            </div>
                          </div>

                          <div className="rating-number">
                            {selectedService && selectedService.data
                              ? selectedService.data.page.review_count
                              : "0"}{" "}
                            reviews
                          </div>
                        </div>

                        <div className="job-data">
                          <div className="location">
                            <img src={location} height="22px" alt=""/>
                            {selectedService && selectedService.data
                              ? selectedService.data.page.location.address
                              : "--"}
                          </div>
                          <div className="post-date">
                            Posted on{" "}
                            {selectedService && selectedService.data
                              ? Moment(selectedService.data.created_at).format(
                                  "L"
                                )
                              : "--"}
                          </div>
                        </div>

                        <div className="page-type">
                          <p className="">
                            {selectedService && selectedService.data
                              ? toSentenceCase(selectedService.data.page.type)
                              : "--"}{" "}
                            Page
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="contact-details">
                    <div className="contact-phone">
                      <LocalPhoneIcon />{" "}
                      {selectedService && selectedService.data
                        ? selectedService.data.page.phone_number
                        : "--"}
                    </div>

                    <div className="contact-mail">
                      <MailIcon />{" "}
                      {selectedService && selectedService.data
                        ? selectedService.data.page.email
                        : "--"}
                    </div>

                    <div className="contact-site">
                      <LanguageIcon />{" "}
                      {selectedService && selectedService.data
                        ? selectedService.data.page.website
                        : "--"}
                    </div>

                    <div className="job-actions">
                      <img src={saveIcon} className="action-icon" alt=""/>
                      <i className="action-icon">
                        <FaShare />
                      </i>
                    </div>
                  </div>

                  <div className="queue-description">
                    <div className="description-title">Descriptions</div>

                    <div className="description-text">
                      {selectedService && selectedService.data
                        ? selectedService.data.description
                        : "--"}
                    </div>
                  </div>

                  <div className="queue-info">
                    <InfoOutlinedIcon className="info-icon" />

                    <div className="info-text">
                      This displays live information by seller about situation
                      of things in the queue from start to finish, This displays
                      live information by seller about situation of things in
                      the queue from start to finish,
                    </div>
                  </div>

                  <div className="queue-biz-details">
                    <div className="biz-details">
                      <div className="biz-title">Tea Shop</div>
                      <div className="biz-address">Address of the business</div>
                    </div>

                    <div>
                      {inQueue && (
                        <>
                          <div className="status-container">
                            <div className="status-pill">
                              <FiberManualRecordIcon className="status-dot" />
                              Active
                            </div>
                          </div>
                        </>
                      )}

                      {/*<button className="biz-refer">*/}
                      {/*	<img src={refericon}/>*/}
                      {/*	Refer*/}
                      {/*</button>*/}
                    </div>
                  </div>

                  {inQueue && (
                    <div className="queue-table-container">
                      <table className="queue-table">
                        <thead>
                          <tr>
                            <th>Queue No.</th>
                            <th>Client Name</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr className="qt-row">
                            <td className="">
                              <div className="status-row">
                                <div className="row-status-bar"></div>
                                001
                              </div>
                            </td>
                            <td>Jaqueline Moc</td>
                            <td>
                              <div className="table-status">
                                Completed
                                <FiberManualRecordIcon className="table-status-dot" />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  )}

                  <div className="queue-time">
                    <div className="queue-time-card">
                      <div className="qt-item">
                        <div className="qt-title">Average Waiting Time:</div>
                        <div className="qt-time">
                          {selectedService?.average_time?.average_waiting_time
                            ? selectedService?.average_time
                                ?.average_waiting_time
                            : 0}
                          min
                        </div>
                      </div>

                      <div className="qt-item">
                        <div className="qt-title">Average Service Time:</div>
                        <div className="qt-time">
                          {selectedService?.average_time?.average_service_time
                            ? selectedService?.average_time
                                ?.average_service_time
                            : 0}
                          min
                        </div>
                      </div>
                    </div>

                    <div className="queue-time-card">
                      <div className="qt-item">
                        <div className="qt-title">Queue No:</div>
                        <div className="qt-time">20min</div>
                      </div>

                      <div className="qt-item">
                        <div className="qt-title">Estimated Waiting Time:</div>
                        <div className="qt-time">20min</div>
                      </div>
                    </div>
                  </div>

                  {!inQueue && (
                    <div className="status-container">
                      <FiberManualRecordIcon className="status-dot" />
                      Active
                    </div>
                  )}
                </div>
              </div>

              <div className="actions">
                <button className="btn-back" onClick={() => toggleJoinQueue()}>
                  Back
                </button>
                {inQueue && (
                  <button
                    className="btn-leave-queue"
                    onClick={() => toggleInQueue("leave")}>
                    Leave Queue
                  </button>
                )}
                {!inQueue && (
                  <button
                    className="btn-done"
                    onClick={() => toggleInQueue("join")}>
                    Join Queue
                  </button>
                )}
              </div>
            </div>

            <div className="explore-right">
              <button className="message-btn">
                <MdMail />
                Message
              </button>

              <div className="service-contact">
                <div className="service-contact-single">
                  <div className="contact-icon">
                    <LocalPhoneIcon />
                  </div>

                  <div className="contact-value">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.phone_number
                      : ""}
                  </div>
                </div>

                <div className="service-contact-single">
                  <div className="contact-icon">
                    <MailIcon />
                  </div>
                  <div className="contact-value">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.email
                      : ""}
                  </div>
                </div>

                <div className="service-contact-single">
                  <div className="contact-icon">
                    <LanguageIcon />
                  </div>
                  <div className="contact-value">
                    {selectedService && selectedService.data
                      ? selectedService.data.page.website
                      : ""}
                  </div>
                </div>
              </div>

              <div className="work-hours">
                <div className="hours-title">Business hours of operations</div>

                <div className="work-schedule">
                  <div className="work-day">
                    <div className="day">Mon</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Tue</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Wed</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Thu</div>
                    <div className="time">9am - 4pm</div>
                  </div>

                  <div className="work-day">
                    <div className="day">Fri</div>
                    <div className="time">9am - 4pm</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : null}
    </>
  );
};

export default ExploreServices;
