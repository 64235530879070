import { useState, useEffect } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import useDeviceType from "../../hooks/deviceType";
import FeedContainer from "../../components/FeedContainer";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import BsRequestedTab from "../../components/BusinessSeller/Listings/ManageListing/requestedTab";
import BsCheckoutTab from "../../components/BusinessSeller/Listings/ManageListing/checkoutTab";
import BsHistoryTab from "../../components/BusinessSeller/Listings/ManageListing/historyTab";
import qs from "query-string";
import usePages from "../../hooks/createPage";
import LoaderPurple from "../../assets/loaders/LoaderPurple";
import BackArrow from "../../assets/svg/backArrow";

// export const BsRequested = "/businessseller-requested";
// export const BsHistory = "/businessseller-history";
// export const BsCheckout = "/businessseller-checkout";

const BusinessSellerManageListings = () => {
  const { pageId: id, focus } = useParams();
  // const { isMobile, isDesktop } = useDeviceType();
  const [activeTab, setActiveTab] = useState("tab1");
  const history = useHistory();

  const handleTab1 = () => {
    // update the state to tab1
    setActiveTab("tab1");
  };
  const handleTab2 = () => {
    // update the state to tab2
    setActiveTab("tab2");
  };

  const handleTab3 = () => {
    // update the state to tab2
    setActiveTab("tab3");
  };

  const dispatch = useDispatch();
  const { singlePageData, handleGetSinglePage } = usePages();
  //handle needed nav manu
  useEffect(() => {
    handleGetSinglePage(id);
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  const { location, goBack, push } = useHistory();
  console.log(focus);
  return (
    <FeedContainer>
      {singlePageData.loading ? (
        <p style={{ marginLeft: 20 }}>Please wait...</p>
      ) : (
        singlePageData.data && (
          <div className="bs-listings-container">
            <div className="lbc-header">
              <h1 className="welcome-title">
                <span
                  onClick={goBack}
                  style={{ cursor: "pointer", marginRight: "20px" }}>
                  <BackArrow />
                </span>
                {"   "}
                {greeting()}{" "}
                <span> {singlePageData.data.name.split(" ")[0]} </span>
              </h1>
              <div className="cl-btn-box">
                <Link
                  to={`/businessseller/createlistings/?id=${id}`}
                  className="cl-btn">
                  Create Listing
                </Link>
              </div>
            </div>
            <div className="lbc-card">
              <h3 className="sub-title">Manage Listing</h3>
              <div className="lbc-select-box">
                <div className="tabs">
                  {/* ----------  nav tab -------- */}
                  <ul className="nav-tab">
                    <li
                      className={`${!focus && "active"}`}
                      onClick={(e) => push(`/businessseller/listings/${id}`)}>
                      Requested
                    </li>
                    <li
                      className={`${
                        location.pathname.includes("checkout") && "active"
                      }`}
                      onClick={(e) =>
                        push(`/businessseller/listings/${id}/checkout`)
                      }>
                      Checkout
                    </li>
                    <li
                      className={`${
                        location.pathname.includes("history") && "active"
                      }`}
                      onClick={(e) =>
                        push(`/businessseller/listings/${id}/history`)
                      }>
                      History
                    </li>
                  </ul>
                </div>
              </div>
              <div className="outlet">
                {/* ---------------- tab contents -------------- */}

                {!focus && <BsRequestedTab />}
                {location.pathname.includes("checkout") && <BsCheckoutTab />}
                {location.pathname.includes("history") && <BsHistoryTab />}

                {/* {activeTab === "tab1" ? <BsListingsBodyDV /> : <BsListingsHistory /> } */}
              </div>
              <div className="btn-box-footer">
                <Link className="back-link" onClick={history.goBack}>
                  Back
                </Link>
              </div>
            </div>
          </div>
        )
      )}
    </FeedContainer>
  );
};

export default BusinessSellerManageListings;
