import React, {useEffect, createContext, useState, useContext  } from "react";
import { Link, useHistory } from 'react-router-dom'
import './style.scss'
import useConnect from "../../hooks/connectHooks";
import ExploreContext  from '../../hooks/exploreContext'

function ProfileLeftBar({
  connectProfileData,
  handleGetSingleProfile,
  profileForm,
  setCurrentProfile,
  currentProfile,
  editData,
  editForm,
  handleGetConnectProfileSuggested,
  handleGetConnectProfile,
  connectFrom,
  setConnectFrom,
  handleGetConnectRequestSent,
  handleGetConnectRequestConnected,
  handleGetConnectRequestProfileConnectedTrue,
  handleLikedPages,
  setRetractSuccessModal,
  id
}) {
  const history = useHistory();

  return (
    <div className="left-container">
    <div className='left-connect-bar'>
      <div>
        <h1>Connect Profile</h1>
        <ul>
          {connectProfileData?.data?.results
            ?.slice(0, 8)
            .map((user, index) => (
            <li key={user.id}
              onClick={() =>{
              handleGetConnectProfileSuggested(user?.id)
              setCurrentProfile(user?.id)
              handleGetConnectRequestSent(user?.id)
              handleGetConnectRequestConnected(user?.id)
              handleGetConnectRequestProfileConnectedTrue(user?.id)
              handleLikedPages(user?.id)
              // setConnectFrom(user.id)
              console.log(user.id)

              // set retract success modal to false until retract
              setRetractSuccessModal(false)
            }}
            >
              <p
                  className={user.id === currentProfile ? "active" : ""}
                  >
                  {user.title}
                </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <Link
        onClick={history.goBack}
         className='back-btn'
            >
        <h5>Back</h5>
    </Link>
  </div>
  )
}

export default ProfileLeftBar
