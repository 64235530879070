import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from "react-redux";
import { toggleRightBar } from "../../../../../appstore/navigationDisplayToggle/index.action";
import {
  FaPen,
} from 'react-icons/fa'
import {BsPen} from "react-icons/bs"
import {TiDeleteOutline} from "react-icons/ti"
import {  FcPlus} from "react-icons/fc"
import {BsThreeDotsVertical} from "react-icons/bs"
import FeedContainer from '../../../../../components/FeedContainer'
import LeftBar from '../../../../../components/LeftManageConnect'
import { FilterOptions } from './ProfileOptions'
import DropdownIndicator from '../../../../../components/cards/jobCards/FontAwesomeIndicator'
import Select from 'react-select';
import greeting from "../../../../../utils/greeting";
import Filter from "../Filter"
import "./index.scss"
import ModalContext from '../../../../../hooks/modalContexts'
import CreateButton from "./CreateButton"
import DotsDropdown from "../../../../../components/DotsDropdown/DotsDropdown"
import ProfileFilter from "../../ProfileFilter/ProfileFilter"
import ProFilters from "./ProfileFilters/ProFilter"
import ProfileMenu from "./ProfileFilters/ProfileMenu"
import ThreeDots from "../../../../../assets/svg/threeDots"
import useConnect from "../../../../../hooks/connectHooks";
import ExploreContext  from '../../../../../hooks/exploreContext'
import MenuCard from "./MenuCard"

function EditProfile() {
  const [requested, setRequested] = useState('recieved')
  const [connect, setConnect] = useState('connected')
  const [addTag, setAddTag] = useState('false')
  const [tags, setTags] = useState([])
  const [addService, setAddService] = useState("false")
  const [locations, setLocations] = useState([])

  const {
    handleEditPage,
    handleCreatePage,
    titleForm,
    setTitleForm,
    profileForm,
    setProfileForm
  } = useContext(ExploreContext)

  const history = useHistory();
  const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(toggleRightBar(false))
    },[])

  const handleKeyDown = (e) => {
    if(e.key !== "Enter") return
    const value = e.target.value
    if(!value.trim()) return
    setTags([...tags, tagsInput])
    setProfileForm({
      ...profileForm,
      tags:[...tags, tagsInput],
    })
    e.target.value = ""
    setAddTag("false")
    setTagsInput("")

  }

  const handleKeyDownLocation = (e) => {
    if(e.key !== "Enter") return
    const value = e.target.value
    if(!value.trim()) return
    setLocations([...locations, serviceInput])
    setProfileForm({
      ...profileForm,
      service_locations:[...locations, serviceInput],
    })
    e.target.value = ""
    setAddService("false")
    setServiceInput("")
  }

  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index))
    setAddTag("false")
  }

  const removeTagLocation = (index) => {
    setLocations(locations.filter((el, i) => i !== index))
    setAddService("false")
  }

  const [pop, setPop] = useState(false)
  const dropdownContainer = useRef(null);
  const toggleDropdown = () => setPop(!pop);
  //dropdown click outside
  const handleClickOutside = (event) => {
    if (
      dropdownContainer.current &&
      !dropdownContainer.current.contains(event.target)
    ) {
      setPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });


  const {handleConnectProfileModal, connectProfileModal, connectProfileTitle} = useContext(ModalContext)
  // const [titleData, setTitleData] = connectProfileTitle

  // console.log(titleData)

// const [connectTitleData, setConnectTitleData] = useState(personnelFormList);
// const [titleData, setTitleData] = useState(connectProfileTitle);

  // useEffect(() => {
  //     setProfileForm({
  //       ...profileForm,
  //       title: titleData,
  //     });
  //   }, [titleData]);

  // Form initial input


  // const initialFormState = {
  //   title: titleForm.title,
  //   page: "c47e572b95",
  //   service_locations:[
  //       ""
  //   ],
  //   business_category:{
  //       name:""
  //   },
  //   connect_to_category:"",
  //   looking_for:"",
  //   description:"",
  //   tags:[""]
  // }

  // const [profileForm, setProfileForm] = useState(initialFormState);

  const {
    handleGetConnectProfile,
    connectProfileData,
    handleGetSingleProfile
  } = useConnect();

  useEffect(() => {
    handleGetConnectProfile();
    console.log(connectProfileData?.data?.results);
    console.log("##############")
  }, []);

  console.log("profile form ========", profileForm)
  // TitleModal
  {/*const [title, setTitle] = titleList;*/}
  const [title, setTitle] = useState("")
  const {
    categoryData,
    handleCategoryData,
    postProfileData,
    handlePostConnectProfile,
    handleCreateProfilePage,
  } = useConnect();
  const currentUser = useSelector((state) => state.currentUser.user);

  const [serviceInput, setServiceInput] = useState("")
  const [tagsInput, setTagsInput] = useState("")


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileForm({ ...profileForm, [name]: value });
  };

  const handleTagsChange = e => {
      setTagsInput(e.currentTarget.value);
      setProfileForm({
        ...profileForm,
        tags:[...tags, tags],
      })
    }

  const handleServiceChange = (e) => {
    setServiceInput(e.currentTarget.value);
    setProfileForm({
      ...profileForm,
      service_locations:[...locations, locations],
    })
  };

  useEffect(() => {
    setProfileForm({
      ...profileForm,
      title: titleForm.title,
    });
    handleCategoryData();
  }, [title]);

  const submitFormData = (e) => {
    e.preventDefault();
    if(handleCreateProfilePage(profileForm)){
      console.log("=====SUCCESS========");
    };
  };

  const handleLocationInputChange = (idx, e) => {
      const newFormValues = [...locations];
      newFormValues[idx][e.target.name] = e.target.value;
      setLocations(newFormValues);
  };

  // const handleLocationInputChange = (idx, e) => {
  //     const newFormValues = [...locations];
  //     newFormValues[idx][e.target.name] = e.target.value;
  //     setLocations(newFormValues);
  //   };

console.log(profileForm)
console.log("===========TITLE FORM TITLE==========")
    console.log(titleForm.title)

  return (
    <FeedContainer>
      <section className='profile-connect'>
        <h1 className='profile-connect-greeting'>
          {greeting()} <span className="user">Jonathan</span>
        </h1>
        <section className='profile-container'>
          <div className='profile-top'>
            <div>
              <h2>Create and manage your connect profile</h2>
              <p>So business that may need your services can find you</p>
            </div>
          </div>
            <section className='profile_section'>
              <section className='profile__flex'>
                <LeftBar
                  connectProfileData={connectProfileData}
                  handleGetSingleProfile={handleGetSingleProfile}
                  profileForm={profileForm}
                  />
                <form className='profile__flex-right' onSubmit={submitFormData}>
                  <div className="top_item">
                    <div className="left_item">
                      <h4>Select your business category</h4>
                      <div className='edit_profile_filter'>
                        <ProFilters
                          profileForm={profileForm}
                          categoryData={categoryData}
                          handleCategoryData={handleCategoryData}
                          setProfileForm={setProfileForm}
                          className="dropdown"
                        />
                      </div>
                    </div>
                    <MenuCard />
                    </div>

                    <div className="tag-container">
                      <h2>Service Location</h2>
                      <div className="tag-input-container">
                        {locations.map((location, index) => (
                          <div className="tag-item" key={index}>
                             <span className="text">{location}</span>
                              <span className="close" onClick={() => removeTagLocation(index)}><TiDeleteOutline/></span>
                          </div>
                        ))}
                        {addService === "true" ? null :
                        <FcPlus className="plus"
                          onClick={() =>(
                            setAddService("true")
                          )}
                          />
                        }
                        {addService === "true" &&
                          <input
                            onChange={handleServiceChange}
                            value={serviceInput}
                            name="service_locations"
                             type="text"
                             className="tags-input"
                             onKeyDown={handleKeyDownLocation}
                             placeholder="Add a service location" />
                        }
                      </div>

                    </div>
                    <div className="bus_category">
                      <div className="bus_left">
                        <h3>Select the business category you wish to connect with</h3>
                          <div className='category-select'>
                          {/*<ProFilters className="dropdown"/>*/}
                            <ProFilters
                              profileForm={profileForm}
                              categoryData={categoryData}
                              handleCategoryData={handleCategoryData}
                              setProfileForm={setProfileForm}
                              className="dropdown"
                            />
                          </div>
                      </div>
                      <div className="bus_right">
                        <h3>What are you looking for</h3>
                        <input
                          name="looking_for"
                          type="text"
                          value={profileForm.looking_for}
                          onChange={handleInputChange}
                          placeholder="Pof"
                          />
                      </div>
                    </div>
                    <div className="describe">
                      <h2>Describe</h2>
                      <textarea
                        name="description"
                        type="text"
                        value={profileForm.description}
                        onChange={handleInputChange}
                        placeholder="is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book.">
                      </textarea>
                    </div>
                    <div className="tag-container">
                      <h2>Add tags</h2>
                      <div className="tag-input-container">
                        {tags.map((tag, index) => (
                          <div className="tag-item" key={index}>
                             <span className="text">{tag}</span>
                              <span className="close" onClick={() => removeTag(index)}><TiDeleteOutline/></span>
                          </div>
                        ))}
                        {addTag === "true" ? null :
                        <FcPlus className="plus"
                          onClick={() =>(
                            setAddTag("true")
                          )}
                          />
                        }
                        {addTag === "true" &&
                          <input
                            onChange={handleTagsChange}
                            value={tagsInput}
                            type="text"
                            name="connect_to_category"
                            className="tags-input" onKeyDown={handleKeyDown} placeholder="Add a tag" />
                        }
                      </div>
                    </div>
                       <button type='submit' className="save-button">Save</button>
                  </form>
              </section>
            </section>
        </section>
      </section>
    </FeedContainer>
  )
}

export default EditProfile
