import { useContext } from "react";
// import Posts from "../../../components/Posts";
import ModalContext from "../../../hooks/modalContexts";
// import CreateMoment from "../../CreateMoment";
import PostButton from "../../customButton";
import BusinessPost from "../../Posts/businessPost";

const TimelineTab = ({ isOwn }) => {
  const { postModalVisibilityStatus } = useContext(ModalContext);
  const [visibility, setVisibility] = postModalVisibilityStatus;
  return (
    <div>
      {isOwn && (
        <PostButton
          onClick={(e) => {
            setVisibility(1);
          }}
        />
      )}
      {/* <CreateMoment /> */}
      <BusinessPost />
    </div>
  );
};

export default TimelineTab;
