import { useEffect, useRef, useState } from "react";
import {
  FaCalendarAlt,
  IoMdClose,
  IoMdLocate,
  FiPlusCircle,
  FaCheckCircle,
} from "react-icons/all";
import DatePicker from "react-datepicker";
import moment from "moment";
import { showToast } from "../../ReactToast";

const Dates = ({
  showTimeSelect = false,
  timeRange = true,
  deliveryState = [],
  children,
  body,
}) => {
  const pickerRef = useRef(null);
  const pickerRefTime1 = useRef(null);
  const pickerRefTime2 = useRef(null);
  const defaultText = timeRange === true ? "Add date and time" : "Add date";
  const [date, setDate] = useState(null);
  const [time, setTime] = useState({
    start: new Date(new Date().setMinutes(0)),
    end: new Date(new Date().setMinutes(0)),
  });
  const handleDateChange = (newDate) => {
    try {
      // console.log(newDate);
      if (new Date(newDate) < Date.now())
        throw "date cannot be less than or equal to today";
      if (body)
        if (
          new Date(newDate) >
          new Date().setDate(new Date(body.listing_end_date).getDate() + 2)
        )
          throw "date cannot exceed two days from listing end date";
      setDate(newDate);
    } catch (err) {
      showToast("error", err);
    }
  };
  const [delivery, setDelivery] = deliveryState;
  const handleCompleted = () => {
    if (date) {
      // console.log(date);
      if (timeRange) {
        const from = new Date(date);
        from.setHours(time.start.getHours());
        from.setMinutes(time.start.getMinutes());

        const to = new Date(date);
        to.setHours(time.end.getHours());
        to.setMinutes(time.end.getMinutes());
        // setDateList();
        setDelivery({
          ...delivery,
          dates: [
            {
              start: from,
              end: to,
            },
            ...delivery.dates,
          ],
        });
      } else
        setDelivery({
          ...delivery,
          dates: [date, ...delivery.dates],
        });
    }
    setDate(null);
  };

  const removeFromList = (date) => {
    const newList = delivery.dates
      .map((item) => item !== date && item)
      .filter((data) => data && data);
    setDelivery({
      ...delivery,
      dates: newList,
    });
  };

  return (
    <div className="dates">
      <FaCalendarAlt />
      <div className="dates-items">
        <div
          className="date-item selected"
          style={{
            minWidth: "fit-content",
          }}>
          {" "}
          <div
            className="date-cancel"
            style={{
              width: "fit-content",
              display: "flex",
              width: 14,
              height: 14,
            }}>
            {date ? (
              <FaCheckCircle size={14} onClick={handleCompleted} />
            ) : (
              <FiPlusCircle
                size={14}
                onClick={(e) => {
                  // pickerRef.current._();
                  pickerRef.current.setOpen(true);
                }}
              />
            )}
          </div>
          <DatePicker
            // selected={date}
            showTimeSelect={showTimeSelect}
            onChange={handleDateChange}
            ref={pickerRef}
            customInput={<div />}
          />{" "}
          {/* date picker for time 1 */}
          <DatePicker
            onChange={(e) => {
              if (new Date(time.end) > new Date(e)) {
                setTime({ ...time, start: e });
              } else {
                showToast(
                  "error",
                  "start time cannot be greater than end time"
                );
              }
            }}
            showTimeSelect
            showTimeSelectOnly
            ref={pickerRefTime1}
            customInput={<div />}
          />
          {/* date picker for time 2 */}
          <DatePicker
            showTimeSelect
            onChange={(e) => {
              if (new Date(time.start) < new Date(e)) {
                setTime({ ...time, end: e });
              } else {
                showToast(
                  "error",
                  "start time cannot be greater than end time"
                );
              }
            }}
            showTimeSelectOnly
            ref={pickerRefTime2}
            customInput={<div />}
          />
          <span>
            <span
              onClick={(e) => {
                // pickerRef.current._();
                pickerRef.current.setOpen(true);
              }}>
              {date ? `${moment(date).format("MMM DD, YYYY")}` : defaultText}
            </span>
            {date && timeRange && (
              <span className="time-selection-section">
                <DatePicker customInput={<div />} />
                <span
                  className="from"
                  onClick={(e) => pickerRefTime1.current.setOpen(true)}>
                  {moment(time.start).format("hh:mma")}
                </span>{" "}
                -
                <span
                  className="to"
                  onClick={(e) => pickerRefTime2.current.setOpen(true)}>
                  {moment(time.end).format("hh:mma")}
                </span>
              </span>
            )}
          </span>
        </div>

        {delivery.dates.map((date, idx) => {
          return (
            <div className="date-item" key={idx}>
              <div
                className="date-cancel"
                onClick={(e) => removeFromList(date)}>
                <IoMdClose />
              </div>
              {!timeRange && moment(date).format("MMM DD, YYYY ")}
              {timeRange && (
                <span>
                  <span className="date">
                    {moment(date.start).format("MMM DD, YYYY ")}
                  </span>
                  <span className="from">
                    {moment(date.start).format("hh:mma")}
                  </span>{" "}
                  -
                  <span className="to">
                    {moment(date.end).format("hh:mma")}
                  </span>
                </span>
              )}
            </div>
          );
        })}

        {children}
      </div>
    </div>
  );
};

export default Dates;
