import { useContext } from "react";
import "../index.scss";
import ModalContext from "../../../hooks/modalContexts";
import Phone from "../../../assets/svg/phone";
import MsgIcon from "../../../assets/svg/msgIcon";
import profilePic from "../../../assets/img/profilePic.png";
import MapLocation from "../../../assets/svg/mapLocation";
import GlobeIcon from "../../../assets/svg/globeIcon";
import NewEmail from "../../../assets/svg/newEmail";
import NewPhone from "../../../assets/svg/newPhone";
import NewLocation from "../../../assets/svg/newLocation";
import NewGlobe from "../../../assets/svg/newGlobe";
import CloseBtn from "../../../components/closeBtn";
import moment from "moment";
const MyJobModal = () => {
  const { handleMyJobItemModal, myJobItemModal } = useContext(ModalContext);
  return (
    <div
      onClick={() => {
        handleMyJobItemModal("RESET_JOB_TO_DISPLAY");
      }}
      className="jobModal"
    >
      <div className="jobModalContainerz">
        <CloseBtn
          onClick={() => {
            handleMyJobItemModal("RESET_JOB_TO_DISPLAY");
          }}
          className="closeIcon"
        ></CloseBtn>
        <div
          onClick={(e) => e.stopPropagation()}
          className="jobModalContainered"
        >
          <h3>{myJobItemModal?.job_post?.title}</h3>
          <p className="applicationDate">
            Applied on{" "}
            {moment(myJobItemModal?.applied_on).format("DD MMMM YYYY")} at{" "}
            {moment(myJobItemModal?.applied_on).format("hh : ss a")}
          </p>
          <h5>Job Description</h5>
          <p className="description">{myJobItemModal?.job_post?.description}</p>
          <div className="applicantDetails">
            <h5>Preferred contact mode:</h5>
            {myJobItemModal?.contact_mode?.map((item) => (
              <p>{item.toLowerCase()},</p>
            ))}
          </div>
          <div className="postedBy">
            <p className="postDate">
              Posted on{" "}
              {moment(myJobItemModal?.job_post?.created_at).format(
                "DD MMMM YYYY"
              )}{" "}
              <span>
                {moment(myJobItemModal?.job_post?.created_at).format("hh:ss a")}
              </span>
            </p>
            <div className="details">
              <img
                src={
                  myJobItemModal?.job_post?.page?.featured_image
                    ? myJobItemModal?.job_post?.page?.featured_image
                    : profilePic
                }
                alt=""
              />
              <div className="name">
                <p>{myJobItemModal?.job_post?.page?.name}</p>
                <p>{myJobItemModal?.job_post?.page?.username}</p>
              </div>
            </div>
            <div className="contactData">
              <div className="contactLeft">
                <p>
                  <NewLocation />
                  <span>{myJobItemModal?.job_post?.location?.address}</span>
                </p>
                <a>
                  <NewEmail />
                  <span>{myJobItemModal?.contact_details?.EMAIL}</span>
                </a>
              </div>
              <div className="contactRight">
                <a href={myJobItemModal?.job_post?.page?.website}>
                  <NewGlobe />
                  <span>{myJobItemModal?.job_post?.page?.website}</span>
                </a>
                <a>
                  <NewPhone />
                  <span>{myJobItemModal?.contact_details?.CALL}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default MyJobModal;
