import React, {  useState, useContext  } from "react";
import { Link, useHistory } from 'react-router-dom'
import "./cards.scss"
import Visa from "../../../assets/svg/visa-no-color_large.svg"
import Master from "../../../assets/svg/mastercard-light-large.svg"
import PayPal from "../../../assets/svg/paypal-nocolor-large.svg"
import Back from "../../../assets/svg/arrow-right (2).svg"
import { FaPlus } from 'react-icons/fa'
import { MdReport } from 'react-icons/all'
import Slick from "react-slick"
import Slider from "react-slick"
import VisaCard from "./Cards/VisaCard"
import MasterCard from "./Cards/MasterCard"
import MasterCardEdit from "./Cards/MasterCardEdit"
import Right from "../../../assets/svg/arrow-right (1).svg"
import Left from "../../../assets/svg/arrow-right.svg"
import Cards from 'react-credit-cards'
import { css } from 'styled-components';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import VisaCardSummary  from "./VisaCardSummary"
import PaypalContainer from "./Paypal/PaypalContainer"
import MasterPaymentCards from "./MasterPaymentCards"
import VisaScreen from "./VisaScreen"

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={Left}
      className={className}
      style={{ ...style, display: "block", width: "30px", height: "30px", right: "10px", top: "100px" }}
      onClick={onClick}
      />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
  <img
    src={Right}
    className={className}
    style={{ ...style, display: "block", width: "30px", height: "30px", left: "-20px", top: "100px" }}
    onClick={onClick}
    />
  );
}

const PaymentCards = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState("general");
  const [masterEdit, setMasterEdit] = useState("edit");

  const handleActiveTabVisa = () => {
    setActiveTab("general")
  }

  const handleActiveTabMaster = () => {
    setActiveTab("master")
  }

    const carouselSettings = {
      dots: false,
      infinite: false,
      // speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
       prevArrow: <PrevArrow />
    };

    const {
      meta, getCardNumberProps, getExpiryDateProps, getCVCProps
    } = usePaymentInputs();

  return(
    <div className="card-container">
      <Link
          onClick={history.goBack}
              >
            <p className="back-button"> <span><img className="visa" src={Back} /></span> Back</p>
      </Link>
        <div className="payment-card ">
          <div className="card-top">
              <h3>Select Payment Option</h3>
              <div className="payment-card-icons">
                <img className={activeTab === "general" || activeTab === "visaSummary" ? "active" : "img"}
                  src={Visa}
                  onClick={() => {
                    setActiveTab("general");
                  }}
                  />
                <img className={activeTab === "master" || activeTab === "masterSummary" ? "active" : "img"}
                  src={Master}
                  onClick={() => {
                    setActiveTab("master");
                  }}
                  />
                <img
                    className={activeTab === "paypal" ? "active" : "img"}
                    src={PayPal}
                    onClick={() => {
                    setActiveTab("paypal");
                    }}
                    />
              </div>
            </div>
          {activeTab === "general" &&
            <div>
            <div className="cards-container ">
              <div className="new-card-cont">
                <p>Add new credit card</p>
                <div className="new-card">
                  <div className="add">
                    <FaPlus />
                  </div>
                </div>
              </div>
              <div className="current-card-cont">
                <div className="slick-crs">
                  <p className="add-new">Current credit card</p>
                  <Slick {...carouselSettings} className="slick">
                      <VisaCard />
                      <VisaCard />
                  </Slick>
                </div>
              </div>
            </div>
            <div className="card-input">
              <div className="number-name">
                <div className="name owner">
                  <h5>Name of card older</h5>
                  <form className="input">
                    <input type="text" placeholder="Howard Pinsky" />
                  </form>
                </div>

                <div className="name">
                  <h5>Credit card number</h5>
                  <form className="input">
                    <input
                      {...getCardNumberProps()}
                      placeholder="1234  3478  3495  2345"
                      inputRef={getCardNumberProps().ref}
                      />
                  </form>
                </div>

              </div>

              <div className="expire-cvv">
                <div className="name owner">
                  <h5>Expiration</h5>
                  <form className="input">
                    <input {...getExpiryDateProps()} placeholder="02/25" />
                  </form>
                </div>

                <div className="name">
                  <h5 className="cvv-text">CVV <MdReport /> </h5>
                  <form className="input">
                    <input {...getCVCProps()} placeholder="123" />
                  </form>
                </div>
              </div>

              <div className="billing">
                <h3>Billing History</h3>
                <p>Last payment on 01/02/2023</p>
              </div>
            </div>
            <button
              onClick={() => {
                setActiveTab("visaSummary");
              }}
              className="done-button">Done
            </button>
            </div>
          }
          {activeTab === "master" &&
            <div>
            <div className="cards-container">
              <div className="new-card-cont">
                <p>Add new credit card</p>
                <div className="new-card">
                  <div className="add">
                    <FaPlus />
                  </div>
                </div>
              </div>
              <div className="current-card-cont">
                <div className="slick-crs">
                  <p className="add-new">Current credit card</p>
                  <Slick {...carouselSettings} className="slick">
                    <MasterCard />
                    <MasterCard />
                  </Slick>
                </div>
              </div>
            </div>
            <div className="card-input">
              <div className="number-name">
                <div className="name owner">
                  <h5>Name of card older</h5>
                  <form className="input">
                    <input type="text" placeholder="Howard Pinsky" />
                  </form>
                </div>

                <div className="name">
                  <h5>Credit card number</h5>
                  <form className="input">
                    <input
                      {...getCardNumberProps()}
                      placeholder="1234  3478  3495  2345"
                      inputRef={getCardNumberProps().ref}
                      />
                  </form>
                </div>

              </div>

              <div className="expire-cvv">
                <div className="name owner">
                  <h5>Expiration</h5>
                  <form className="input">
                    <input {...getExpiryDateProps()} placeholder="02/25" />
                  </form>
                </div>

                <div className="name">
                  <h5>CVV <MdReport /> </h5>
                  <form className="input">
                    <input {...getCVCProps()} placeholder="123" />
                  </form>
                </div>
              </div>

              <div className="billing">
                <h3>Billing History</h3>
                <p>Last payment on 01/02/2023</p>
              </div>
            </div>
            <button
              onClick={() => {
                setActiveTab("masterSummary");
              }}
              className="done-button">Done
            </button>
            </div>
            }
            {activeTab === "paypal" && <PaypalContainer />}
            {activeTab === "masterSummary" &&  <MasterPaymentCards  handleActiveTabMaster={handleActiveTabMaster} /> }
            {activeTab === "visaSummary" &&  <VisaCardSummary handleActiveTabVisa={handleActiveTabVisa} /> }
      </div>
    </div>
  )
}

export default PaymentCards
