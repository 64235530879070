import {
  UPDATE_REPLY_LIKES,
  INIT_REPLIES,
  ADD_REPLIES,
  BULK_INSERT_REPLIES,
  ADD_DIRECT_REPLIES_TO_COMMENT,
  CLEAR_REPLIES,
} from "./repliesV2.types";

export const initReplies = (payload) => ({
  type: INIT_REPLIES,
  payload,
});
export const addReplies = (payload) => ({
  type: ADD_REPLIES,
  payload,
});
export const updateReplyLikes = (payload) => ({
  type: UPDATE_REPLY_LIKES,
  payload,
});
export const bulkInsertReplies = (payload) => ({
  type: BULK_INSERT_REPLIES,
  payload,
});
export const directRepliesToComment = (payload) => ({
  type: ADD_DIRECT_REPLIES_TO_COMMENT,
  payload,
});
export const clearReplies = (payload) => ({
  type: CLEAR_REPLIES,
  payload,
});
