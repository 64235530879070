import React, { useState, useEffect } from "react";
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import "./index.scss";
import {
  FaSearch,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ReactPaginate from "react-paginate";
import { FaSortAmountDownAlt, FaDownload, FaCalendarAlt } from "react-icons/fa";
import CustomDropdown from "../../../components/customDropdown/customDropdown";
import CustomCheckBox from "../../../components/customCheckBox";
import PaymentCards from "./HistoryCards"

const PaymentHistory = () => {

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const dispatch = useDispatch()
  const history = useHistory();
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  /* paginate */
  const [pageCount, setPageCount] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (selectedObject) => {
    setCurrentPage(selectedObject.selected);
  };

  return (
    <div className="history-container">
        <p className="header_download">Download All <span> <FaDownload/> </span></p>
      <div className="payment-history">
        <div className="payment-body-container">
          <div className="hbc-card-body">
              <PaymentCards />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;
