import { useState } from "react";
import { useStore } from "react-redux";
import Loader from "../../../components/loader";
import useDeviceType from "../../../hooks/deviceType";
import useSettings from "../../../hooks/settings";

const SecuritySettings = ({ secondaryTab }) => {
  const { isMobile, isDesktop } = useDeviceType();
  const [resetData, setResetData] = useState({});
  const { passwordData, handlePostPasswordChange } = useSettings();
  console.log(passwordData);
  const [errors, setErrors] = useState();
  const validate = (values) => {
    let errors = {};
    // const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const passwrodRegex =
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
    if (values.new_password1 !== values.new_password2) {
      errors.new_password2 = "password does not match";
    }
    if (!values.old_password) {
      errors.old_password = '"please enter password"';
    }
    if (!values.new_password1) {
      errors.new_password1 = "please enter password";
    }
    if (!values.new_password2) {
      errors.new_password2 = "please enter password";
    } else if (!passwrodRegex.test(values.new_password1)) {
      errors.new_password1 =
        "min 8 characters, 1 letter, 1 number and 1 special character!";
    }

    return errors;
  };
  console.log(errors);
  console.log(resetData);
  console.log("error msg", passwordData?.error?.response.data);
  return (
    <div className="secondaryTabContent security">
      {isDesktop && <h4>{secondaryTab}</h4>}
      <div className="passwordFlex">
        <form action="">
          <p className="reset">Reset Password</p>
          <label htmlFor="">
            <input
              placeholder="Old Password"
              onChange={(e) => {
                setResetData({ ...resetData, old_password: e.target.value });
              }}
              type="password"
            />
            {errors?.old_password && <span>{errors.old_password}</span>}
            {passwordData?.error?.response.data.old_password && (
              <span>{passwordData?.error?.response.data.old_password}</span>
            )}
            {/* {errors?.password && (
                            <span>{errors.password}</span>
                        )} */}
          </label>
          <label htmlFor="">
            <input
              placeholder="New Password"
              onChange={(e) => {
                setResetData({ ...resetData, new_password1: e.target.value });
              }}
              type="password"
            />
            {errors?.new_password1 && <span>{errors.new_password1}</span>}
            {passwordData?.error?.response.data.new_password1 && (
              <span>{passwordData?.error?.response.data.new_password1}</span>
            )}
            {/* {errors?.password && (
                            <span>{errors.password}</span>
                        )} */}
          </label>
          <label htmlFor="">
            <input
              placeholder="New Password"
              onChange={(e) => {
                setResetData({ ...resetData, new_password2: e.target.value });
              }}
              type="password"
            />
            {errors?.new_password2 && <span>{errors.new_password2}</span>}
            {passwordData?.error?.response.data.new_password2 && (
              <span>{passwordData?.error?.response.data.new_password2}</span>
            )}
            {/* {errors?.password && (
                            <span>{errors.password}</span>
                        )} */}
          </label>
          <div
            onClick={() => {
              setErrors(validate(resetData));
              !Object.keys(validate(resetData)).length &&
                handlePostPasswordChange(resetData);
            }}
            className="save"
          >
            {!passwordData?.loading ? <span>Save</span> : <Loader />}
          </div>
        </form>
        <div className="acctActions">
          <div className="suspend">
            <span>Suspend account</span>
          </div>
          <div className="deleteAcct">
            <span>Delete account</span>
          </div>
        </div>
      </div>
      {/* <hr /> */}
      {/* <div className="recovery">
                <p>
                    Recovery email
                </p>
                <span>
                    **********ress@gmail.com
                </span>
            </div> */}
    </div>
  );
};
export default SecuritySettings;
