import React, {useEffect, useState} from "react";
import producticon from "../../../assets/explore/box-white.svg";
import locationicon from "../../../assets/explore/location.svg";
import jobicon from "../../../assets/explore/service.png";
import serviceicon from "../../../assets/explore/food.png"
import bag from "../../../assets/img/bag.jpg"

const ExploreCard = (props) => {
	const [itemDiscount, setItemDiscount] = useState(null);

	useEffect(() => {
		if (props?.discount) {
			if (props?.discount?.constant?.percentage > 0) {
				setItemDiscount(props?.discount?.constant?.percentage);
				return;
			}
			if (props?.discount?.constant?.fix_price > 0) {
				setItemDiscount(props?.discount?.constant?.fix_price);
				return;
			}
		}
	}, []);

	const clickAction = (id) => {
		props.action(id)
	}

	return (
		<>
			<div className="list-card">
				<div className="list-body">
					<img src={props.img ? props.img : bag} alt="" className="product-image"/>

					<div className={`card-top ${props.isReferrable === 'false' && props.discount === '' ? 'single-right' : ''}`}>
						{
							props.isReferrable === 'false' && props.discount === '' ?
								null
							:
								<div className="top-left">
									{
										itemDiscount ?
											<div className="discount">
												{
													props?.origin === "AllListings" ?
														<p>{itemDiscount}% off</p>
														:
														<p>{itemDiscount}% off</p>
												}
											</div>
											:
											null
									}

									{
										props.isReferrable === 'true' ?
											<div className="refer-box">
												<div className="refer">
													<img src={props.refericon} alt=""/>
													<p>Refer</p>
												</div>
											</div>
											:
											null
									}
								</div>
						}

						<div className={`listing-category ${props.type==='Product' ? '' : 'bg-yellow'}`}>
							{
								props.type === 'Product' ?
									<img src={producticon} alt=""/>
								:
									null
							}
							{
								props.type === 'Service' ?
									<img src={serviceicon} height='14px' width='20px' alt=""/>
								:
									null
							}
							{
								props.type === 'Job' ?
									<img src={jobicon} height='20px' width='20px' alt=""/>
								:
									null
							}
						</div>
					</div>

					<div className="card-bottom">
						<div className="card-bottom-content">
							<p className="title">{props.title}</p>

							<div className="location">
								<img src={locationicon} alt=""/>
                {
                  props.origin === "AllListings" ?
                    <p className="location-name">{props.location}</p>
                    :
                    <p className="location-name">{props.location}</p>
                }
							</div>
						</div>
					</div>
				</div>

				<div className={`list-footer ${props.type === 'Product' ? 'footer-purple' : 'footer-yellow'} `}>
					<div className="price">
						{
							props.type ==='Job' ?
								<span>
									{props.jobType}
								</span>
							:
                <>
                  {props.price &&
                    <span>
                      ${props.price}
                    </span>
                  }
                </>
						}
					</div>

					{
						props.type === 'Product' ?
							<button className={`btn-footer btn-yellow`} onClick={() => clickAction(props.productId)}>{props.ctaText}</button>
						:
							null
					}
					{
						props.type === 'Service' ?
							<button className={`btn-footer btn-white`} onClick={() => clickAction(props.serviceId)}>{props.ctaText}</button>
						:
							null
					}
					{
						props.type === 'Job' ?
							<button className={`btn-footer btn-white`} onClick={() => clickAction(props.jobPostId)}>{props.ctaText}</button>
						:
							null
					}
				</div>
			</div>
		</>
	)
}

export default ExploreCard