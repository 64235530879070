import { useEffect, useState } from "react";
import Activity from "../../../utils/activity";
import Pen from "../../../components/pen";
import useDeviceType from "../../../hooks/deviceType";
import PrivacyDrop from "./privacyDrop";
import useSettings from "../../../hooks/settings";
import Loader from "../../../components/loader";

const PrivacySettings = ({ secondaryTab, currentUser }) => {
  console.log("current user", currentUser);
  const [privacy, setPrivacy] = useState(
    currentUser.social_settings.privacy["Private Account"]
  );
  const [dropIndex, setDropIndex] = useState();
  const { isMobile, isDesktop } = useDeviceType();
  const { handlePatchPrivacyPreferences, privacyPreferenceData } =
    useSettings();
  const [optionIndex, setOptionIndex] = useState();
  const [selectedOption, setSelectedOption] = useState({
    "Who can see your post":
      currentUser.social_settings.privacy["Who can see your post"],
    "Who can comment on your post":
      currentUser.social_settings.privacy["Who can comment on your post"],
    "Who can see the people, pages and lists you follow":
      currentUser.social_settings.privacy[
        "Who can see the people, pages and lists you follow"
      ],
    "Who can send me a message?":
      currentUser.social_settings.privacy["Who can send me a message?"],
    "Who can tag me": currentUser.social_settings.privacy["Who can tag me"],
  });
  const [selectedCased, setSelectedCased] = useState({
    one:
      selectedOption["Who can see your post"].charAt(0) +
      selectedOption["Who can see your post"].slice(1).toLowerCase(),
    two:
      selectedOption["Who can comment on your post"].charAt(0) +
      selectedOption["Who can comment on your post"].slice(1).toLowerCase(),
    three:
      selectedOption[
        "Who can see the people, pages and lists you follow"
      ].charAt(0) +
      selectedOption["Who can see the people, pages and lists you follow"]
        .slice(1)
        .toLowerCase(),
    four:
      selectedOption["Who can send me a message?"].charAt(0) +
      selectedOption["Who can send me a message?"].slice(1).toLowerCase(),
    five:
      selectedOption["Who can tag me"].charAt(0) +
      selectedOption["Who can tag me"].slice(1).toLowerCase(),
  });
  useEffect(() => {
    setSelectedCased({
      ...selectedCased,
      one: (
        selectedOption["Who can see your post"].charAt(0) +
        selectedOption["Who can see your post"].slice(1).toLowerCase()
      ).replace("_", " "),
      two: (
        selectedOption["Who can comment on your post"].charAt(0) +
        selectedOption["Who can comment on your post"].slice(1).toLowerCase()
      ).replace("_", " "),
      three: (
        selectedOption[
          "Who can see the people, pages and lists you follow"
        ].charAt(0) +
        selectedOption["Who can see the people, pages and lists you follow"]
          .slice(1)
          .toLowerCase()
      ).replace("_", " "),
      four: (
        selectedOption["Who can send me a message?"].charAt(0) +
        selectedOption["Who can send me a message?"].slice(1).toLowerCase()
      ).replace("_", " "),
      five: (
        selectedOption["Who can tag me"].charAt(0) +
        selectedOption["Who can tag me"].slice(1).toLowerCase()
      ).replace("_", " "),
    });
  }, [selectedOption]);
  const handlePrivateAccountChange = () => {
    handlePatchPrivacyPreferences({
      ...selectedOption,
      "Private Account": privacy,
    });
  };
  useEffect(() => {
    handlePrivateAccountChange();
  }, [privacy]);
  console.log(selectedOption);
  return (
    <div className="secondaryTabContent privacy">
      {isDesktop && <h4>{secondaryTab}</h4>}
      <div className="privacySet">
        <div>
          <span>Private Account</span>
          <div
            className={`switch ${!privacy && "off"}`}
            onClick={() => {
              setPrivacy(!privacy);
            }}
          >
            <span className={`${!privacy && "offSpan"}`}></span>
          </div>
        </div>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text ever
          since the 1500s, when an unknown printer took a galley of type and
          scrambled it to make a type specimen book. It has survived not only
          five centuries,
        </p>
      </div>
      <div className="activity">
        <div className="activityTitle">Your activity</div>
        <div className="activitySettings">
          <ul>
            <li
              onClick={() => {
                if (optionIndex === 0) {
                  setOptionIndex();
                } else setOptionIndex(0);
              }}
            >
              <p>Who can see your post</p>
              <div>
                <span>{selectedCased.one}</span>
                <Pen />
                {optionIndex === 0 && (
                  <PrivacyDrop
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    idx={optionIndex}
                    activity={Activity}
                  />
                )}
              </div>
            </li>
            <li
              onClick={() => {
                if (optionIndex === 1) {
                  setOptionIndex();
                } else setOptionIndex(1);
              }}
            >
              <p>Who can comment on your post</p>
              <div>
                <span>{selectedCased.two}</span>
                <Pen />
                {optionIndex === 1 && (
                  <PrivacyDrop
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    idx={optionIndex}
                    activity={Activity}
                  />
                )}
              </div>
            </li>
            <li
              onClick={() => {
                if (optionIndex === 2) {
                  setOptionIndex();
                } else setOptionIndex(2);
              }}
            >
              <p>Who can see the people, pages and lists you follow</p>
              <div>
                <span>{selectedCased.three}</span>
                <Pen />
                {optionIndex === 2 && (
                  <PrivacyDrop
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    idx={optionIndex}
                    activity={Activity}
                  />
                )}
              </div>
            </li>
            <li
              onClick={() => {
                if (optionIndex === 3) {
                  setOptionIndex();
                } else setOptionIndex(3);
              }}
            >
              <p>Who can send me a message?</p>
              <div>
                <span>{selectedCased.four}</span>
                <Pen />
                {optionIndex === 3 && (
                  <PrivacyDrop
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    idx={optionIndex}
                    activity={Activity}
                  />
                )}
              </div>
            </li>
            <li
              onClick={() => {
                if (optionIndex === 4) {
                  setOptionIndex();
                } else setOptionIndex(4);
              }}
            >
              <p>Who can tag me</p>
              <div>
                <span>{selectedCased.five}</span>
                <Pen />
                {optionIndex === 4 && (
                  <PrivacyDrop
                    selectedOption={selectedOption}
                    setSelectedOption={setSelectedOption}
                    idx={optionIndex}
                    activity={Activity}
                  />
                )}
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="save">
        <div
          onClick={() => {
            handlePatchPrivacyPreferences(selectedOption);
          }}
          className="saveButton"
        >
          {!privacyPreferenceData?.loading ? <span>Save</span> : <Loader />}
        </div>
      </div>
    </div>
  );
};
export default PrivacySettings;
