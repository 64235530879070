import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toggleRightBar } from "../../../../../appstore/navigationDisplayToggle/index.action";
import {
  FaPen,
  FaArrowLeft,
} from 'react-icons/fa'
import {BsPen} from "react-icons/bs"
import {TiDeleteOutline} from "react-icons/ti"
import {  FcPlus} from "react-icons/fc"
import {BsThreeDotsVertical} from "react-icons/bs"
import FeedContainer from '../../../../../components/FeedContainer'
import LeftBar from '../../../../../components/LeftManageConnect'
import { FilterOptions } from './ProfileOptions'
import DropdownIndicator from '../../../../../components/cards/jobCards/FontAwesomeIndicator'
import Select from 'react-select';
import greeting from "../../../../../utils/greeting";
import Filter from "../Filter"
import "./index.scss"
import ModalContext from '../../../../../hooks/modalContexts'
import CreateButton from "./CreateButton"
import DotsDropdown from "../../../../../components/DotsDropdown/DotsDropdown"
import ProfileFilter from "../../ProfileFilter/ProfileFilter"
import ProFilters from "./ProfileFilters/ProFilter"
import ToProFilters from "./ProfileFilters/ToProFilter"
import ProfileMenu from "./ProfileFilters/ProfileMenu"
import ThreeDots from "../../../../../assets/svg/threeDots"
import useConnect from "../../../../../hooks/connectHooks";
import ExploreContext  from '../../../../../hooks/exploreContext'
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import GoogleMapReact from "google-map-react";
import config from "../../../../../config/config.json";
import MenuCard from "./MenuCard"
import qs from "query-string";
import usePages from "../../../../../hooks/createPage";
import LoaderPurple from "../../../../../assets/loaders/LoaderPurple"

function CreateProfile() {
  const [requested, setRequested] = useState('recieved')
  const [connect, setConnect] = useState('connected')
  const [addTag, setAddTag] = useState('false')
  const [tags, setTags] = useState([])
  const [addService, setAddService] = useState("false")
  const [locations, setLocations] = useState([])
  const [city, setCity] = useState(null);

  const [serviceLocationForm, setServiceLocationForm] = useState({
    name: "",
    image: "",
    address: city,
    point: {
      type: "Point",
      coordinates: [],
    },
  });

  const {
    handleEditPage,
    handleCreatePage,
    titleForm,
    setTitleForm,
    profileForm,
    setProfileForm,
    pageId,
    setPageId,
    serviceLocationList,
  } = useContext(ExploreContext)

  const { singlePageData, handleGetSinglePage } = usePages();

  const history = useHistory();
  const dispatch = useDispatch()
  const { search } = useLocation();
  const { id } = qs.parse(search);

    useEffect(()=>{
        dispatch(toggleRightBar(false))
    },[])

    useEffect(() => {
        handleGetSinglePage(id)
        // setPageId({
        //   id: id,
        // })
      }, [id]);

  const handleKeyDown = (e) => {
    if(e.key !== "Enter") return
    const value = e.target.value
    if(!value.trim()) return
    setTags([...tags, tagsInput])
    setProfileForm({
      ...profileForm,
      tags:[...tags, tagsInput],
    })
    e.target.value = ""
    setAddTag("false")
    setTagsInput("")
  }

  const handleKeyDownLocation = (e) => {
    // if(e.key !== "Enter") return
    // const value = e.target.value
    // if(!value.trim()) return
    setLocations([...locations, serviceLocationForm])
    setProfileForm({
      ...profileForm,
      service_locations: [...locations, serviceLocationForm]
    })
    // setAddService("false")
    // setServiceInput("")
  }

// useEffect(() => {
//   setProfileForm({
//     ...profileForm,
//     service_locations: locations
//   })
// }, [locations])

  console.log(locations)
  console.log(serviceLocationForm)

  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index))
    setProfileForm({
      ...profileForm,
      tags: profileForm.tags.filter((el, i) => i !== index)
    })
    setAddTag("false")
  }

  const removeTagLocation = (index) => {
    setLocations(locations.filter((el, i) => i !== index))
    setProfileForm({
      ...profileForm,
      service_locations: profileForm.service_locations.filter((el, i) => i !== index)
    })
    setAddService("false")
  }


  const [pop, setPop] = useState(false)
  const dropdownContainer = useRef(null);
  const toggleDropdown = () => setPop(!pop);
  //dropdown click outside
  const handleClickOutside = (event) => {
    if (
      dropdownContainer.current &&
      !dropdownContainer.current.contains(event.target)
    ) {
      setPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });


  const {handleConnectProfileModal, connectProfileModal, connectProfileTitle} = useContext(ModalContext)
  // const [titleData, setTitleData] = connectProfileTitle

  // console.log(titleData)

// const [connectTitleData, setConnectTitleData] = useState(personnelFormList);
// const [titleData, setTitleData] = useState(connectProfileTitle);

  // useEffect(() => {
  //     setProfileForm({
  //       ...profileForm,
  //       title: titleData,
  //     });
  //   }, [titleData]);

  // Form initial input

  // const [profileForm, setProfileForm] = useState(initialFormState);
  const {
    handleGetConnectProfile,
    connectProfileData,
    handleGetSingleProfile
  } = useConnect();

  useEffect(() => {
    handleGetConnectProfile(id);
    console.log(connectProfileData?.data?.results);
    console.log("##############")
  }, []);

  // TitleModal
  {/*const [title, setTitle] = titleList;*/}
  const [title, setTitle] = useState("")
  const {
    categoryData,
    handleCategoryData,
    postProfileData,
    handlePostConnectProfile,
    handleCreateProfilePage,
    createProfilePageData,
  } = useConnect();
  const currentUser = useSelector((state) => state.currentUser.user);

  const [serviceInput, setServiceInput] = useState("")
  const [tagsInput, setTagsInput] = useState("")


  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setProfileForm({ ...profileForm, [name]: value });
  };

  const handleTagsChange = e => {
      setTagsInput(e.currentTarget.value);
      setProfileForm({
        ...profileForm,
        tags:[...tags, tags],
      })
    }

  const handleServiceChange = (e) => {
    setServiceInput(e.currentTarget.value);
    setProfileForm({
      ...profileForm,
      service_locations:[...locations, locations],
    })
  };

  const onAddressChange = (e) => {
    // setServiceInput(e.currentTarget.value);
    setServiceLocationForm({
      ...serviceLocationForm,
      address: e,
    });
  };

  useEffect(() => {
    setProfileForm({
      ...profileForm,
      title: titleForm.title,
      page: pageId.id,
    });
    handleCategoryData();
  }, [title]);

  const submitFormData = (e) => {
    e.preventDefault();
    if(handleCreateProfilePage(profileForm)){
      console.log("=====SUCCESS========");
      
    };
  };

  useEffect(() => {
    if (createProfilePageData.data) {
      // console.log(createProfilePageData.data.title)
      history.push(`/businessseller/show-profile/?id=${pageId.id}`)
    }
  }, [createProfilePageData]);

  const handleLocationInputChange = (idx, e) => {
      const newFormValues = [...locations];
      newFormValues[idx][e.target.name] = e.target.value;
      setLocations(newFormValues);
  };

  // const handleLocationInputChange = (idx, e) => {
  //     const newFormValues = [...locations];
  //     newFormValues[idx][e.target.name] = e.target.value;
  //     setLocations(newFormValues);
  //   };

console.log(profileForm)
console.log("===========TITLE FORM TITLE==========")
    console.log(titleForm.title)

  // if(createProfilePageData?.loading) return <LoaderPurple />
  return (
    <FeedContainer>
      <section className='profile-connect'>
      <div className='welcome-back'>
        <Link
            onClick={history.goBack}
            className='back-btn'
                >
            <FaArrowLeft />
        </Link>
         <h1 className='connect-greeting'>
          {greeting()} <span className="user">{singlePageData?.data?.name?.split(" ")[0]} </span>
        </h1>
      </div>
        <section className='profile-container'>
          <div className='profile-top'>
            <div>
              <h2>Create and manage your connect profile</h2>
              <p>So business that may need your services can find you</p>
            </div>
          </div>
            <section className='profile_section'>
              <section className='profile__flex'>
                <LeftBar
                  connectProfileData={connectProfileData}
                  handleGetSingleProfile={handleGetSingleProfile}
                  profileForm={profileForm}
                  />
                <form className='profile__flex-right' onSubmit={submitFormData}>
                  <div className="top_item">
                    <div className="left_item">
                      <h4>Select your business category</h4>
                      <div className='edit_profile_filter'>
                        <ProFilters
                          profileForm={profileForm}
                          categoryData={categoryData}
                          handleCategoryData={handleCategoryData}
                          setProfileForm={setProfileForm}
                          className="dropdown"
                        />
                      </div>
                    </div>
                    <MenuCard />
                    </div>

                    <div className="tag-container">
                      <h2>Service Location</h2>
                      <div className="tag-input-container">
                        {locations.map((location, index) => (
                          <div className="tag-item" key={index}>
                             <span className="text">{location.address}</span>
                              <span className="close" onClick={() => removeTagLocation(index)}><TiDeleteOutline/></span>
                          </div>
                        ))}
                        {addService === "true" ? null :
                        <FcPlus className="plus"
                          onClick={() =>(
                            setAddService("true")
                          )}
                          />
                        }
                        {addService === "true" &&
                        <div className="tal-set-location">
                          <Autocomplete
                            className="tags-input"
                            onKeyDown={handleKeyDownLocation}
                            apiKey={config["map-key"]}
                            selectProps={{
                              city,
                              onChange: async ({ label }) => {
                                onAddressChange(label);
                                console.log(label);
                                const data = await geocodeByAddress(label);
                                const { lat, lng } = await getLatLng(data[0]);
                                setServiceLocationForm({
                                  ...serviceLocationForm,
                                  name: "",
                                  image: "",
                                  address: label,
                                  point: {
                                    type: "Point",
                                    coordinates: [lat, lng],
                                  },
                                });
                                console.log(data);
                              },
                              setCity,
                            }}
                            onLoadFailed={(error) =>
                              console.error("Could not inject Google script", error)
                            }
                          />
                          <FcPlus className="plus"
                            onClick={() =>(
                              handleKeyDownLocation()
                            )}
                            />
                      </div>
                        }
                      </div>

                    </div>
                    <div className="bus_category">
                      <div className="bus_left">
                        <h3>Select the business category you wish to connect with</h3>
                          <div className='category-select'>
                          {/*<ProFilters className="dropdown"/>*/}
                            <ToProFilters
                              profileForm={profileForm}
                              categoryData={categoryData}
                              handleCategoryData={handleCategoryData}
                              setProfileForm={setProfileForm}
                              className="dropdown"
                            />
                          </div>
                      </div>
                      <div className="bus_right">
                        <h3>What are you looking for</h3>
                        <input
                          name="looking_for"
                          type="text"
                          value={profileForm.looking_for}
                          onChange={handleInputChange}
                          placeholder="Pof"
                          />
                      </div>
                    </div>
                    <div className="describe">
                      <h2>Describe</h2>
                      <textarea
                        name="description"
                        type="text"
                        value={profileForm.description}
                        onChange={handleInputChange}
                        placeholder="is simply dummy text of the printing and typesetting industry.
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and scrambled it to make a type
                        specimen book.">
                      </textarea>
                    </div>
                    <div className="tag-container">
                      <h2>Add tags</h2>
                      <div className="tag-input-container">
                        {tags.map((tag, index) => (
                          <div className="tag-item" key={index}>
                             <span className="text">{tag}</span>
                              <span className="close" onClick={() => removeTag(index)}><TiDeleteOutline/></span>
                          </div>
                        ))}
                        {addTag === "true" ? null :
                        <FcPlus className="plus"
                          onClick={() =>(
                            setAddTag("true")
                          )}
                          />
                        }
                        {addTag === "true" &&
                          <input
                            onChange={handleTagsChange}
                            value={tagsInput}
                            type="text"
                            name="connect_to_category"
                            className="tags-input" onKeyDown={handleKeyDown} placeholder="Add a tag" />
                        }
                      </div>
                    </div>
                       <button type='submit' className="save-button">Save</button>
                  </form>
              </section>
            </section>
        </section>
      </section>
    </FeedContainer>
  )
}

export default CreateProfile
