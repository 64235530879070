import { Link, NavLink, useHistory, useParams } from "react-router-dom";
import { others } from "./others";
import "./userDetail.scss";
import testAvatar from "../../assets/img/Ellipse 267.png";
import { BriefCase, SmallPencil, Verified } from "../../assets/svg/briefCase";
import { useContext, useEffect, useState } from "react";
import { Fragment } from "react";
import { DefaultButton } from "../Button";
import CustomCheckBox from "../customCheckBox";
import avaialblePagesForSelection from "../../utils/availablePagesForSelection";
import { FaPen } from "react-icons/fa";
import { MoreOptionsIcon } from "../../assets/svg/Extras";
import { profileView } from "../../utils/postCardOptions";
import { useSelector, useDispatch } from "react-redux";
import { setModal } from "../../appstore/userModal/index.action";
import useDeviceType from "../../hooks/deviceType";
import UserMatrics from "./userMetrics";
import numberFormat from "format-number";
import MenuBarComponent from "../../assets/svg/MenuBarComponent";
import cx from "classnames";
import Toggle from "../reusableToggle";
import ModalContext from "../../hooks/modalContexts";
import SocialContext from "../../hooks/socialContext";
import useSocialDetails from "../../hooks/socialDetails";
import useExplore from "../../hooks/explore";
import usePages from "../../hooks/createPage";
import TimelinePages from "./tiimeLinePages";
import useAxios from "axios-hooks";
import LoaderPurple from "../../assets/loaders/LoaderPurple";
import NoData from "../NoData";
import useUser from "../../hooks/user";
import LoaderWhite from "../../assets/loaders/LoaderWhite";

//241a8089c9
const UserDetail = () => {
  const { push } = useHistory();
  const { socialDetails, visitor, username } = useContext(SocialContext);
  const [isFollowing, setIsFollowing] = useState(false);
  const { followData, handleFollow } = useUser();
  const { handleGetSocialDetails, handleUpdate, getSocialDetailsData } =
    useSocialDetails();
  const dispatch = useDispatch();
  const [editMode, setEditMode] = useState(false);
  const { handleUserModal } = useContext(ModalContext);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);

  const options = isOptionsVisible && (
    <ul className="option__">
      {profileView.map((opt, idx) => (
        <li>
          {/* some icon */}
          <opt.icon />
          <p> {opt.name}</p>
        </li>
      ))}
    </ul>
  );
  // console.log(user);
  const handleModalDisplay = (payload) => {
    const modalState = {
      type: payload,
      showModal: true,
      loading: true,
      data: [],
    };
    dispatch(setModal(modalState));
  };

  const handleEdit = async () => {
    if (!editMode) {
      setEditMode(!editMode);
      return;
    }

    await saveData();
    setEditMode(!editMode);
  };

  useEffect(() => {
    handleGetSocialDetails(username);
    return () => {
      handleUserModal();
    };
  }, [username]);

  const [userDataToUpdate, setUserDataToUpdate] = useState();
  const saveData = async () => {
    await handleUpdate(userDataToUpdate);
    return true;
  };
  useEffect(() => {
    if (socialDetails[0]?.user.data) {
      setUserDataToUpdate({
        indicate_business_on_profile:
          socialDetails[0].user.data.indicate_business_on_profile,
        bio: socialDetails[0].user.data.bio,
      });
      // console.log(socialDetails[0]);

      setIsFollowing(socialDetails[0].user.data.followed_them);
    }
  }, [socialDetails[0]?.user.data]);

  useEffect(() => {
    if (followData.data) {
      setIsFollowing(followData.data.status);
    }
  }, [followData.data]);

  return getSocialDetailsData.loading ? (
    <LoaderPurple style={{ width: 30, height: 30 }} />
  ) : socialDetails[0] ? (
    <div className="user__detail">
      <div className="initial-front">
        <div className="avatar-section">
          <div className="avatar">
            <img src={testAvatar} alt="" />

            {!visitor && (
              <DefaultButton
                customStyle={{
                  borderRadius: 19,
                  color: "var(--bg-white)",
                  backgroundColor: editMode
                    ? "var(--dark-purple-bg)"
                    : "var(--bg-orange)",
                }}
                className="edit-mode-btn"
                text={editMode ? "Save" : "Edit Mode"}
                onClick={handleEdit}
              />
            )}
          </div>
          <div className="profile-detail">
            <h1 className="name">
              {socialDetails && socialDetails[0]
                ? socialDetails[0].user.data.first_name
                : "--"}
              &nbsp;
              {socialDetails && socialDetails[0]
                ? socialDetails[0].user.data.last_name
                : "--"}
            </h1>
            <span className="username">
              @
              {socialDetails && socialDetails[0]
                ? socialDetails[0].user.data.username
                : "--"}
              {editMode && <FaPen size={13} color="var(--dark-purple-bg)" />}
            </span>
            <div
              className={cx("bio", { editable: editMode })}
              contentEditable={editMode}
              onInput={({ target }) =>
                setUserDataToUpdate((state) => ({
                  ...state,
                  bio: target.innerText,
                }))
              }>
              {socialDetails && socialDetails[0]?.user?.data
                ? userDataToUpdate?.bio
                : "--"}
            </div>
          </div>
        </div>
        <div className="matrix-section">
          {editMode && (
            <div
              className="view-as-visitor"
              onClick={() => push("/feeds/timeline")}>
              View as Visitor
            </div>
          )}
          <ul className="counts">
            <li>
              <p>
                {numberFormat()(
                  socialDetails && socialDetails[0]
                    ? socialDetails[0].user.data.total_post
                    : "0"
                )}
              </p>
              <span>Post</span>
            </li>
            <li
              onClick={(e) =>
                !visitor && handleUserModal("ADD_DATA", 1, "followers")
              }>
              <p>
                {numberFormat()(
                  socialDetails && socialDetails[0]
                    ? socialDetails[0].user.data.followers
                    : "--"
                )}
              </p>
              <span>Followers</span>
            </li>
            <li
              onClick={(e) =>
                !visitor && handleUserModal("ADD_DATA", 1, "following")
              }>
              <p>
                {numberFormat()(
                  socialDetails && socialDetails[0]
                    ? socialDetails[0].user.data.following
                    : "--"
                )}
              </p>
              <span>Following</span>
            </li>
            {/* <li onClick={(e) => handleUserModal("ADD_DATA", 1, "supporting")}>
              <p>
                {numberFormat()(
                  socialDetails && socialDetails[0]
                    ? socialDetails[0].user.data.supporting
                    : "--"
                )}
              </p>
              <span>Supporting</span>
            </li> */}
          </ul>

          <div className={cx("other-pages", { "edit-mode": editMode })}>
            <h5>
              Other Pages{" "}
              {editMode && (
                <span>Tick to select business to display on your profile</span>
              )}
            </h5>
            <TimelinePages
              editMode={editMode}
              owner={socialDetails[0]?.user.data.id}
            />
          </div>

          <div className="view-all-sec">
            <span
              style={{
                cursor: "pointer",
              }}
              className="view-all">
              View all
            </span>
            {!editMode && visitor ? (
              <DefaultButton
                onClick={handleFollow.bind(
                  this,
                  socialDetails[0].user.data.username
                )}
                customStyle={{
                  borderRadius: 20,
                  fontSize: 15,
                  backgroundColor: isFollowing
                    ? "var(--green)"
                    : "var(--bg-orange)",
                }}>
                {followData.loading ? (
                  <LoaderWhite style={{ width: 15, height: 15 }} />
                ) : isFollowing ? (
                  "Following"
                ) : (
                  "Follow"
                )}
              </DefaultButton>
            ) : (
              <div className="toggle-section">
                {userDataToUpdate && editMode && (
                  <>
                    <Toggle
                      defaultState={
                        userDataToUpdate.indicate_business_on_profile
                      }
                      color="var(--dark-purple-bg)"
                      size={25}
                      onChange={({ value }) =>
                        setUserDataToUpdate((state) => ({
                          ...state,
                          indicate_business_on_profile: value,
                        }))
                      }
                    />
                    <span>Turn on business owner indicator on profile</span>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="menu-section">
        <div
          className="user-detail-menu-icon"
          onClick={(e) => setIsOptionsVisible(!isOptionsVisible)}>
          {" "}
          <span></span>
          <span></span>
          <span></span>
        </div>
        {isOptionsVisible && (
          <div className="user-detail-options">some option</div>
        )}
      </div>
    </div>
  ) : (
    <NoData>User Not Found</NoData>
  );
};

export default UserDetail;
