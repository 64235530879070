import React, {  useState, useContext  } from "react";
import VisaCard from "../../../../assets/img/visa-inside-card.png"
import Right from "../../../../assets/svg/arrow-right (1).svg"
import Left from "../../../../assets/svg/arrow-right.svg"
import Edit from "../../../../assets/svg/edit.svg"
import Trash from "../../../../assets/svg/trash.svg"
import "./currentCard.scss"
import Slider from 'react-slick'

const VisaCardEdit = ({handleActiveTabVisa}) => {
  const [activeTab, setActiveTab] = useState("edit");

  const handleEdit = () => {
    setActiveTab("main")
  }

  return(
    <div className="current-card-carousel">
        <div className="current-card visa-card" >
          <div className="visalogo">
              <img src={VisaCard} />
          </div>
          <div className="visa-edit">
            <div className="img-delete">
              <img src={Trash} />
            </div>
            <div className="img-edit"
              onClick={handleActiveTabVisa}
              >
              <img src={Edit} />
            </div>
          </div>
              <div className="card-number number-edit">
                <p className="dots">....</p>
                <p className="dots">....</p>
                <p className="dots">....</p>
                <p className="num">3299</p>
            </div>
              <h3  className="owner-name visa-owner">Howard Pinsky</h3>
        </div>
    </div>
  )
}

export default VisaCardEdit
