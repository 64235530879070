import React, { useEffect, useState } from "react";
import "./style.scss";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const ServicePersonnelTimes = (props) => {
  const [timeArrays, setTimeArrays] = useState([]);
  const [currentArrayPosition, setCurrentArrayPosition] = useState(0);
  const [activeSlot, setActiveSlot] = useState(null);

  const divideIntoChunks = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      const chunk = array.slice(i, i + chunkSize);
      result.push(chunk);
    }
    return result;
  }
  
  useEffect(() => {
    let times = props?.times?.data;

    if (times?.count > 0) {
      setTimeArrays(divideIntoChunks(times.results, 12));
    }
  }, [props]);

  const formatTimeSlot = (slot) => {
    const time = new Date(slot);
    const formattedTime = time.toLocaleString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    });

    return formattedTime;
  }

  const changeCurrentArrayPosition = (action) => {
    let arrayLength = timeArrays.length;
    let arrayPosition = currentArrayPosition+1;

    if (action === "increment" && arrayPosition !== arrayLength) {
      setCurrentArrayPosition(currentArrayPosition+1)
    }

    if (action === "decrement" && arrayPosition !== 1) {
      setCurrentArrayPosition(currentArrayPosition-1)
    }
  }

  const chooseAppTime = (time) => {
    setActiveSlot(time);
    props.chooseTime(time);
  }

  return (
    <>
      {
        props?.times?.data?.count > 0 ?
          <div className="schedule-box">
            <div className="schedule-date">
              <p className="date-title">Wed, 25th May, 2023</p>
            </div>
            <div className="schedule-times">
              {
                timeArrays[currentArrayPosition]?.map((time, id) => {
                  return (
                    <div className={`time-single ${time.opening_time === activeSlot?.opening_time ? "active" : ""}`}
                      key={id + "key"}
                      onClick={() => chooseAppTime(time)}
                    >
                      <p className="time-text">{formatTimeSlot(time?.opening_time)}</p>
                    </div>
                  )
                })
              }

            </div>
            <div className="schedule_carousel">
              <FaAngleLeft 
                onClick={() => changeCurrentArrayPosition("decrement")}
              />
              {
                timeArrays.map((array, id) => {
                  return (
                    <div className={`array_marker ${currentArrayPosition === id ? "active" : ""}`} key={id + "key"}></div>
                  )
                })
              }
              <FaAngleRight 
                onClick={() => changeCurrentArrayPosition("increment")}
              />
            </div>
          </div>
          :
          <>
            {
              props?.times?.data?.count === 0 ?
                <p className="empty-msg">No available times for this day</p>
                :
                null
            }
          </>
      }
    </>
  )
}

export default ServicePersonnelTimes;