import { useState, useEffect } from "react";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";

const Switch = ({ onChange = () => {}, defaultState = false, ...rest }) => {
  const [isToggled, setIsToggled] = useState(defaultState);

  const changeToggle = (e) => {
    setIsToggled(!isToggled);
  };

  console.log(isToggled);

  useEffect(() => {
    onChange({ value: isToggled });
    console.log(isToggled);
  }, [isToggled]);

  return (
    <div onClick={changeToggle}>
      {isToggled ? (
        <FaToggleOn size={40} {...rest} />
      ) : (
        <FaToggleOff size={40} {...rest} />
      )}
    </div>
  );
};

export default Switch;
