import {
  GoogleMap,
  Marker,
  Circle,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
const Map = ({
  currentLocation,
  otherLocation,
  is_seller,
  is_pickup,
  pickup_location,
  delivery_location,
}) => {
  // const google = window.google;
  const [directions, setDirections] = useState();
  const mapRef = useRef();
  // const center = useMemo(() => ({ lat: 9.00667, lng: 7.26333 }), []);
  const options = useMemo(() => ({ disableDefaultUI: true }), []);

  const onLoad = useCallback((map) => {
    return (mapRef.current = map);
  }, []);

  const deliveryLocation = useMemo(() => {
    return is_pickup
      ? {
          lat: pickup_location.point.coordinates[0],
          lng: pickup_location.point.coordinates[1],
        }
      : {
          lat: delivery_location.point.coordinates[0],
          lng: delivery_location.point.coordinates[1],
        };
  }, [pickup_location, delivery_location, is_pickup]);

  const handleDirections = useCallback(
    (l) => {
      // console.log(event);
      const { latitude, longitude } = l;
      const location = { lat: latitude, lng: longitude };
      // setLocation(location);
      mapRef.current?.panTo(location);

      //get directions
      const service = new window.google.maps.DirectionsService();
      service.route(
        {
          origin: location,
          destination: deliveryLocation,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (result, status) => {
          if (status === "OK") {
            console.log(result);
            setDirections(result);
          }
        }
      );
    },
    [deliveryLocation]
  );

  useEffect(() => {
    is_seller && !is_pickup && handleDirections(currentLocation);
    // !is_seller &&
    is_pickup && handleDirections(currentLocation);
  }, [handleDirections, currentLocation, is_pickup, is_seller]);

  return (
    <div
      className="map"
      style={{
        width: "100%",
        height: "100vh",
      }}>
      <GoogleMap
        mapContainerStyle={{
          width: "100%",
          height: "100vh",
        }}
        zoom={18}
        center={deliveryLocation}
        options={options}
        onLoad={onLoad}
        // onClick={handleClick}
      >
        {
          <>
            {directions && <DirectionsRenderer directions={directions} />}
            {otherLocation && (
              <Marker
                position={{
                  lat: otherLocation.latitude,
                  lng: otherLocation.longitude,
                }}
                icon={""}
              />
            )}
            {currentLocation && (
              <Marker
                position={{
                  lat: currentLocation.latitude,
                  lng: currentLocation.longitude,
                }}
                icon={"/image/location.png"}
              />
            )}

            {/* <Circle center={location} radius={1000} /> */}
          </>
        }
      </GoogleMap>
    </div>
  );
};

export default Map;
