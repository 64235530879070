import { useEffect } from "react";
import FeedContainer from "../../components/FeedContainer";
import "./queue.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import { useHistory, Link } from "react-router-dom";
import {
  queue,
  queue_history,
  edit_queue,
  preview_queue,
  queue_request,
  manage_queue,
} from "./index";
import { useLocation } from "react-router-dom";
import qs from "query-string";

const QueueContainer = ({ children }) => {
  const dispatch = useDispatch();
  const { location, goBack } = useHistory();
  const { pathname, search } = useLocation();
  const isPrivateSeller = pathname?.toLowerCase().includes("privateseller");
  const { id } = qs.parse(search);

  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonText = () => {
    switch (location.pathname) {
      // case preview_queue:
      //   return "Edit Queue";
      case queue_request:
        return "Queue Settings";
      case queue:
        return "Queue Settings";
      case queue_history:
        return "Queue Settings";

      default:
        return "Queue Settings";
    }
  };

  const headerText = () => {
    switch (location.pathname) {
      case preview_queue:
        return "Preview Queue";
      case manage_queue:
        return "Manage Queue";
      case queue:
        return "Queue";
      case queue_history:
        return "Queue History";
      case edit_queue:
        return "Edit Queue";
      case queue_request:
        return "Manage Queue";

      default:
        return undefined;
    }
  };

  const history = useHistory();
  const switchTab = (newTab) => {
    console.log("switching");
    let preUrl = isPrivateSeller ? "/privateseller" : "/businessseller";
    let postUrl = `/${newTab}/?id=${id}`;
    history.replace(preUrl+postUrl);
  }

  // const pl = Location.pathname === preview_queue
  return (
    <FeedContainer>
      <div className="queue">
        <div className="greeting">
          {" "}
          {greeting()} <span>Jonathan</span>{" "}
        </div>
        <div className="wrap">
          <div className="head">
            <div className="left__section">
              {headerText() && <h1>{headerText()}</h1>}
              {/* {location.pathname === preview_queue ? <> </> :  */}
              <div className="queue__switch">
                
                  <div
                    className={`queue__list ${location.pathname === "/businessseller/queue-list/" && "selected"}`}
                    onClick={(e) => switchTab("queue-list")}
                  >
                    Queue List
                  </div>
                
                {/* <div className="manage__queue">Manage List</div> */}
                <div
                  className={`${location.pathname === "/businessseller/queue_request/" && "selected"}`}
                  onClick={(e) => switchTab("queue_request")}
                >
                  Request
                </div>

                <div
                  className={`${location.pathname === "/businessseller/queue_history/" && "selected"}`} 
                  onClick={(e) => switchTab("queue_history")}
                >
                  History
                </div>
              </div>
            </div>
            <div className="right__section">
              {buttonText() ? <button onClick = {() => switchTab("edit_queue")}>{buttonText()}</button> : null}
            </div>
          </div>
          {children}
          <div
            className="back"
            style={{
              marginTop: 30,
              color: "var(--dark-purple-bg)",
              fontSize: 20,
              cursor: "pointer",
              marginBottom: 20,
              display: "inline-block"
            }}
            onClick={goBack}
          >
            Back
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};

export default QueueContainer;
