const NewLocation = ({fill}) =>{
    return(
        <svg id="location" xmlns="http://www.w3.org/2000/svg" width="18.606" height="18.606" viewBox="0 0 18.606 18.606">
  <path id="Vector" d="M3,5.993A3,3,0,1,1,6,3,3,3,0,0,1,3,5.993Zm0-4.83A1.837,1.837,0,1,0,4.838,3,1.841,1.841,0,0,0,3,1.163Z" transform="translate(6.303 4.993)" fill={fill || "#490057"}/>
  <path id="Vector-2" data-name="Vector" d="M7.269,16.675a4.629,4.629,0,0,1-3.2-1.295C1.78,13.179-.747,9.667.207,5.489A7.067,7.067,0,0,1,7.269,0h.008a7.066,7.066,0,0,1,7.062,5.5c.946,4.179-1.581,7.683-3.868,9.884A4.629,4.629,0,0,1,7.269,16.675Zm0-15.513A5.855,5.855,0,0,0,1.346,5.745C.509,9.4,2.8,12.543,4.881,14.536a3.431,3.431,0,0,0,4.783,0c2.07-1.992,4.365-5.14,3.543-8.791A5.876,5.876,0,0,0,7.269,1.163Z" transform="translate(2.034 0.969)" fill={fill || "#490057"}/>
  <path id="Vector-3" data-name="Vector" d="M0,0H18.606V18.606H0Z" fill="none" opacity="0"/>
</svg>

    )
}
export default NewLocation