import React, { useState, useEffect,  useContext, } from 'react'
import { useLocation, useHistory, Link  } from "react-router-dom";
import qs from "query-string";
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import {
  FaSortAmountDownAlt,
  FaChevronLeft,
  FaChevronRight,
  FaSearch,
  FaChevronDown,
  FaArrowLeft,
} from 'react-icons/fa'
import FeedContainer from '../../../../components/FeedContainer'
import Recieved from './Requested/Recievedcard'
import Sent from './Requested/Sentcard'
import Favorite from './Requested/Favoritecard'
import SuggestedCard from './Suggested/SuggestedCard'
import LeftBar from '../../../../components/LeftManageConnect/ProfileLeftBar'
import LeftBarPage from '../../../../components/LeftManageConnect/PageLeftBar'
import ConnectedCard from './Connected/ConnectedCard'
import ConnectedCardRecieved from './Connected/ConnectedRecieved'
import './Manage.scss'
import {connectUsers, suggestUsers} from "./ManageData"
import { FilterOptions } from './ConnectOptions'
import DropdownIndicator from '../../../../components/cards/jobCards/FontAwesomeIndicator'
import Select from 'react-select';
import greeting from "../../../../utils/greeting"
import Filter from "./Filter"
import SortBy from "./SortBy"
import SearchBar from "./SearchBar"
import SearchBarReceived from "./SearchBarReceived"
import useConnect from "../../../../hooks/connectHooks"
import usePages from "../../../../hooks/createPage"
import ExploreContext  from '../../../../hooks/exploreContext'
import ModalContext from '../../../../hooks/modalContexts'
import LoaderPurple from "../../../../assets/loaders/LoaderPurple"

function Manage() {
  // const [requested, setRequested] = useState('recieved')
  // const [connect, setConnect] = useState('connected')
  const [currentPage, setCurrentPage] = useState()
  const [leftBarItems, setLeftBarItems] = useState("profile")

  const [connectedPage, setConnectedPage] = useState("recieved")
  const [suggestedPage, setSuggestedPage] = useState("main")

  const history = useHistory();
  const dispatch = useDispatch()
  const { search } = useLocation();
  const { id } = qs.parse(search);
  const {
    singlePageData,
    handleGetSinglePage,
    handleGetPages,
    getPagesData
  } = usePages();

  const {handleSuccessConnectModalCard, handleDeclineConnectModalCard} = useContext(ModalContext)
  
  const {
    handleGetConnectRequest,
    connectRequestData,
    handleGetConnectProfile,
    connectProfileData,
    handleGetSingleProfile,
    singleProfileData,
    handleSendConnectRequest,
    connectProfileDataSuggested,
    handleGetConnectProfileSuggested,
    connectProfileDataFavorite,
    handleGetConnectProfileFavorite,
    handleGetConnectRequestConnected,
    connectRequestDataConnected,
    handleGetConnectRequestReceived,
    connectRequestDataReceived,
    handleGetConnectRequestSent,
    connectRequestDataSent,
    handleLike,
    likeData,
    handleHideProfile,
    hideProfileData,
    handleRespondConnectRequest,
    retractConnectData,
    handleRetractConnect,
    handleGetConnectProfileConnected,
    connectProfileDataConnected,
    handleLikedPages,
    likedPagesData,

    connectRequestReceivedTrueData,
    handleGetConnectRequestReceivedTrue,

    connectRequestConnectedTrueData,
    handleGetConnectRequestConnectedTrue,

    connectRequestProfileConnectedTrueData,
    handleGetConnectRequestProfileConnectedTrue,

    disconnectConnectRequestData,
    sendConnectRequestData,

    respondConnectRequestCardData,
    handleRespondConnectRequestCard,


    retractConnectDataCard,
    handleRetractConnectCard,
  } = useConnect();

  const {
    requested,
    connect,
    setRequested,
    setConnect,
    editForm,
    setEditForm,
    titleForm,
    profileForm,
    setTitleForm,
    currentProfile,
    setCurrentProfile,
    editData,
    setEditData,
    editTitleForm,
    setEditTitleForm,
    modalData,
    setModalData,
    connectFrom,
    setConnectFrom,
    connectTo,
    setConnectTo,
    acceptModalData,
    setAcceptModalData,
    retractModalData,
    setRetractModalData,
    messageData,
    setMessageData,
    rectractedCard,
    acceptedCard,
    setAcceptedCard,
    currentId,
    setCurrentId,
    successData,
    setSuccessData,
    filterParam,
    setFilterParam,
    suggested,
    setSuggested,
    connectedState,
    setConnectedState,
    recievedState,
    setRecievedState,
    sentState,
    setSentState,
    favoriteState,
    setFavoriteState,
    likeSuggestedCard,
    setLikeSuggestedCard,
    declinedCard,
    setDeclinedCard,
    searchInput,
    setSearchInput,
    filteredResults,
    setFilteredResults,

    requestReceived, 
    setRequestReceived,
    requestConnected, 
    setRequestConnected,
    
    setConnectHomeLoad,
    connectHomeLoad,
    setMessageSentData,

    acceptColor, 
    setAcceptColor,
    setRectractedCard,

    connectManageLoad, 
    setConnectManageLoad,

    requestSentFromSuggest, 
    setRequestSentFromSuggest,

    retractSuccessModal, 
    setRetractSuccessModal
  } = useContext(ExploreContext)


  const initialSendRequest = {
    connect_profile_from:"95f3e2eec4",
    connect_profile_to:"060c4a0448",
    note:"Quasi consequatur excepturi commodi quisquam ratione quia. Ad ab voluptatem quo odio eum est deleniti modi accusantium. Non maxime incidunt possimus odit et. Maiores sit accusantium neque eius voluptatem non. Quibusdam vero est et consequuntur necessitatibus et officia. Quis repellat molestiae nobis id."
}
const [sendRequest, setSendRequest] = useState(initialSendRequest)

const handleSubmit = () => {
  handleSendConnectRequest(sendRequest)
}


useEffect(() => {
  handleGetPages({});
  handleGetConnectRequest();
  // handleGetConnectProfileSuggested(currentProfile);
  // handleGetConnectProfileFavorite();
  handleLikedPages(currentProfile)
  handleGetConnectProfile(id);
  handleGetConnectRequestConnected(currentProfile);
  handleGetConnectRequestReceived(id);
  handleGetConnectRequestSent(currentProfile);
  handleGetConnectProfileConnected();
  handleGetConnectRequestReceivedTrue(id);
  handleGetConnectRequestConnectedTrue(id)
  console.log(handleGetConnectProfileSuggested(currentProfile))
  handleGetConnectRequestProfileConnectedTrue(currentProfile)
}, []);

useEffect(() => {
  const currentProId = connectProfileData?.data?.results?.[0]?.id
  if(currentProId){
      setCurrentProfile(currentProId)
      handleGetConnectProfileSuggested(currentProId)
      handleGetConnectRequestConnected(currentProId)
      handleGetConnectRequestSent(currentProId)
      handleGetConnectRequestProfileConnectedTrue(currentProId)
  }
}, [connectProfileData?.data?.results?.[0]?.id])

useEffect(() => {
  const currentPageId = getPagesData?.data?.results?.[0]?.id
  if(currentPageId){
    setCurrentPage(currentPageId)
  }
}, [getPagesData?.data?.results?.[0]?.id])

  useEffect(()=>{
        dispatch(toggleRightBar(false))
    },[])

  useEffect(() => {
      // setSuggested(state => [...state, ...connectProfileDataSuggested.data.results])
      setSuggested(connectProfileDataSuggested?.data?.results)

  }, [connectProfileDataSuggested?.data])

  useEffect(() => {
      // setConnectedState(state => [...state, ...connectRequestDataConnected.data.results])
      setConnectedState(connectRequestProfileConnectedTrueData?.data?.results)
  }, [connectRequestProfileConnectedTrueData?.data])

  useEffect(() => {
      // setRecievedState(state => [...state, ...connectRequestDataReceived.data.results])
      setRecievedState(connectRequestDataReceived?.data?.results);
  }, [connectRequestDataReceived?.data])

  useEffect(() => {
      setSentState(connectRequestDataSent?.data?.results)
  }, [connectRequestDataSent?.data])

  useEffect(() => {
      setFavoriteState(likedPagesData?.data?.results)
  }, [likedPagesData?.data])

  // console.log(suggested)
  useEffect(() => {
    setRequestReceived(connectRequestReceivedTrueData?.data?.results)
  
  }, [connectRequestReceivedTrueData?.data])

  useEffect(() => {
    setRequestConnected(connectRequestConnectedTrueData?.data?.results)
  
  }, [connectRequestConnectedTrueData?.data])

const reloadGet = () => {
  // handleLikedPages();
  // handleGetConnectRequestSent();
  // handleGetConnectRequestReceived();
  // handleGetConnectProfileConnected();
  // handleGetConnectProfileSuggested();
  setSentState(connectRequestDataSent?.data?.results);
}


useEffect(() => {
  if(acceptedCard){
    handleGetConnectRequestReceivedTrue(id);
    setRequestReceived(state => state?.filter(state => state?.id !== acceptedCard?.id && state?.status === "Accept" && true))
  }
}, [acceptedCard])

useEffect(() => {
  if(declinedCard){
    handleGetConnectRequestReceivedTrue(id);
    setRequestReceived(state => state?.filter(state => state?.id !== declinedCard?.id && state?.status === "Decline" && true))

  }
}, [declinedCard])


useEffect(() => {
  if(rectractedCard){
    handleGetConnectRequestSent(currentProfile);
    setSentState(state => state?.filter(state => state?.id !== rectractedCard?.id && state?.is_like === false && true))
  }
}, [rectractedCard])

// 1 Remove card from UI after connect request
useEffect(() => {
  if(requestSentFromSuggest){
    handleGetConnectProfileSuggested(currentProfile);
    setSuggested(state => state?.filter(state => state?.id !== requestSentFromSuggest?.id && true))
  }
}, [requestSentFromSuggest])

// 2 Remove card from UI after connect request
useEffect(() => {
  if(requestSentFromSuggest){
    handleLikedPages(currentProfile)
    setFavoriteState(state => state?.filter(state => state?.id !== requestSentFromSuggest?.id && true))
  }
}, [requestSentFromSuggest])

useEffect(() => {
  if(sendConnectRequestData?.data){
    handleGetConnectProfileSuggested(currentProfile);
    // setSuggested(state => state?.filter(state => state?.id !== sendConnectRequestData?.data?.id && (state.is_like === true || false) && true))
    
  }
}, [sendConnectRequestData?.data])

useEffect(() => {
  if (retractConnectDataCard?.data) {
    // handleGetConnectRequestSent(currentProfile)
    setSentState(state => state?.filter(state => state?.id !== retractConnectDataCard?.data?.id && state.status === "Pending" && true))
  }
}, [retractConnectDataCard?.data]);

console.log("CURRENT ID", currentProfile)

useEffect(() => {
  handleGetConnectProfileSuggested(currentProfile);
  handleLikedPages(currentProfile);

}, [likeData?.data])

useEffect(() => {
  if(successData){
    handleGetConnectRequestSent(currentProfile);
    setSentState(connectRequestDataSent?.data?.results)
  }
}, [successData])

console.log(searchInput)
useEffect(() => {
  if(setConnectManageLoad){
    handleGetConnectProfileSuggested(currentProfile);
    setSuggested(state => state?.filter(state => state?.id !== sendConnectRequestData?.data?.id && state.status === "Pending" && true))
  }

}, [setConnectManageLoad])

useEffect(() => {
  handleGetSinglePage(id);
}, [id]);


// setLeftBarItems to PAGE on recieved page at inital when requested is clicked
useEffect(() => {
  if(requested === 'recieved'){
    setLeftBarItems("page")
  }
}, [leftBarItems])

// useEffect(() => {
  
//     setConnectedPage('recieved')
  
// }, [])

useEffect(() => {
  if(sendConnectRequestData?.data){
    handleGetConnectRequestSent(currentProfile)
  }
}, [sendConnectRequestData?.data])



useEffect(() => {
  if(connectHomeLoad === true){
    // window.location.reload(false);
    handleGetConnectRequestConnectedTrue(id);
    handleGetConnectRequestReceivedTrue(id);
  }
  }, [connectHomeLoad])

  useEffect(() => {
    setConnectHomeLoad(false)
    setRetractSuccessModal(false)
  }, [])

  useEffect(() => {
    if(connectManageLoad === true){
      handleLikedPages(currentProfile)
      handleGetConnectProfileSuggested(currentProfile);
    }
  }, [])

  useEffect(() => {
    setRetractSuccessModal(false)
  }, [])

// useEffect(() => {
//   if(disconnectConnectRequestData?.data) {
//     handleGetConnectRequestConnectedTrue();
//     handleGetConnectRequestProfileConnectedTrue(currentProfile)
//     // history.push("/connect")
//     setConnectedState(state => state?.filter(state => state?.id !== disconnectConnectRequestData?.data?.id && state?.status === true && true))
//     setRequestConnected(state => state?.filter(state => state?.id !== disconnectConnectRequestData?.data?.id && state?.status === true && true))

//   }
// }, [disconnectConnectRequestData?.data]);

// useEffect(() => {
//   // console.log(respondConnectRequestData?.data?.status)
//    if(respondConnectRequestCardData?.data?.status === "Accept"){
//           handleSuccessConnectModalCard('ADD_SUCCESS_CONNECTCARD_TO_DISPLAY', 'true');
//           console.log("=====SUCCESS========");
//           // handleGetConnectRequestReceivedTrue(id);
//           // setRequestReceived(state => state?.filter(state => state?.id !== acceptedCard?.id && state?.status === "Accept" && true))

//        } 
//    if(respondConnectRequestCardData?.data?.status === "Decline"){
//           handleDeclineConnectModalCard('ADD_DECLINE_CONNECTCARD_TO_DISPLAY', 'true');
//           console.log("=====DECLINE=======");
//         } 
        
// }, [respondConnectRequestCardData?.data]);
// console.log(disconnectConnectRequestData?.data?.id)

  return (
    <FeedContainer>
      <section className='manage-connect'>
      <div className='welcome-back'>
        <Link
            onClick={history.goBack}
            className='back-btn'
                >
            <FaArrowLeft />
        </Link>
         <h1 className='manage-connect-greeting'>
          {greeting()} <span className="user">{singlePageData?.data?.name?.split(" ")[0]} </span>
        </h1>
      </div>
       
        <section className='manage-container'>
          <div className='manage-top'>
            <div>
              <h2>Manage Connects</h2>
            </div>
          </div>
          <div className='manage-header'>
            <div className='manage-header-left'>
              <button
                onClick={() => {
                  setConnect('connected')
                  reloadGet()
                }}
                className={`${connect === 'connected' ? 'actd' : ''}`}
              >
                Connected
              </button>
              <button
                onClick={() => {
                  setConnect('requested')
                  handleGetConnectRequestSent(currentProfile)
                  handleLikedPages(currentProfile)
                  handleGetConnectRequestReceivedTrue(id)
                  // setSentState(connectRequestDataSent?.data?.results)


                  // set success modal to false until retract is done
                  setRetractSuccessModal(false)
                }}
                className={`${connect === 'requested' ? 'actd' : ''}`}
              >
                Requested
              </button>
              <button
                onClick={() => {
                  setConnect('suggested')
                  reloadGet()
                  handleGetConnectProfileSuggested(currentProfile)
                }}
                className={`${connect === 'suggested' ? 'actd' : ''}`}
              >
                Suggestion
              </button>
            </div>
          </div>
          {connect === 'connected' && (
            <section className='requested'>
              <div className='request-nav'>
                <ul>
                  <li
                    onClick={() => {
                      setConnectedPage('recieved')
                      setLeftBarItems("page")
                    }}
                    className={`${connectedPage === 'recieved' ? 'reck' : ''}`}
                  >
                    Recieved
                  </li>
                  <li
                    onClick={() => {
                      setConnectedPage('sent')
                      setLeftBarItems("profile")
                    }}
                    className={`${connectedPage === 'sent' ? 'reck' : ''}`}
                  >
                    Sent
                  </li>
                </ul>
              </div>
            <section className='connected-main'>
              <div className='connect__flex'>
                {connectedPage === 'sent' &&
                  <LeftBar
                    connectProfileData={connectProfileData}
                    handleGetSingleProfile={handleGetSingleProfile}
                    profileForm={profileForm}
                    setCurrentProfile={setCurrentProfile}
                    currentProfile={currentProfile}
                    editData={editData}
                    editForm={editForm}
                    currentProfile={currentProfile}
                    handleGetConnectProfile={handleGetConnectProfile}
                    handleGetConnectProfileSuggested={handleGetConnectProfileSuggested}
                    handleGetConnectRequestSent={handleGetConnectRequestSent}
                    handleGetConnectRequestConnected={handleGetConnectRequestConnected}
                    handleGetConnectRequestProfileConnectedTrue={handleGetConnectRequestProfileConnectedTrue}
                    handleLikedPages={handleLikedPages}
                    setRetractSuccessModal={setRetractSuccessModal}
                    id={id}
                    />
                }
                
                
                {connectedPage === 'recieved' && (
                  <div className='connect__flex-right flex_connect_received'>
                    <>
                  <SearchBarReceived
                    suggested={suggested}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    filteredResults={filteredResults}
                    setFilteredResults={setFilteredResults}

                    className="filter_item"
                    />

                  {/* {connectRequestDataConnected.loading && connectRequestDataConnected.data?.count !== 0 && <LoaderPurple />} */}

                      {!connectRequestConnectedTrueData.loading && connectRequestConnectedTrueData.error && (
                        <p>An error</p>
                      )}

                      {searchInput.length > 1 ?
                        filteredResults?.map((connected, index) => (
                          <ConnectedCardRecieved key={connected.id} {...connected}
                            connected={connected}
                            handleHideProfile={handleHideProfile}
                            setMessageData={setMessageData}
                            singleProfileData={singleProfileData}
                            handleGetSingleProfile={handleGetSingleProfile}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            likeData={likeData}
                          />
                      )
                    ) : requestConnected &&
                        !connectRequestConnectedTrueData.error &&
                        requestConnected?.map((connected, idx) => (
                          <ConnectedCardRecieved key={connected?.id} {...connected}
                            connected={connected}
                            handleHideProfile={handleHideProfile}
                            setMessageData={setMessageData}
                            singleProfileData={singleProfileData}
                            handleGetSingleProfile={handleGetSingleProfile}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            likeData={likeData}
                            
                          />
                    ))}
                     </>
                     </div>
                  )}

                {connectedPage === 'sent' && (
                  <div className='connect__flex-right'>
                    <>
                  <SearchBar
                    suggested={suggested}
                    searchInput={searchInput}
                    setSearchInput={setSearchInput}
                    filteredResults={filteredResults}
                    setFilteredResults={setFilteredResults}

                    className="filter_item"
                    />

                  {/* {connectRequestDataConnected.loading && connectRequestDataConnected.data?.count !== 0 && <LoaderPurple />} */}

                      {!connectRequestProfileConnectedTrueData.loading && connectRequestProfileConnectedTrueData.error && (
                        <p>An error</p>
                      )}

                      {searchInput.length > 1 ?
                        filteredResults?.map((connected, index) => (
                          <ConnectedCard key={connected?.id} {...connected}
                            connected={connected}
                            handleHideProfile={handleHideProfile}
                            setMessageData={setMessageData}
                            singleProfileData={singleProfileData}
                            handleGetSingleProfile={handleGetSingleProfile}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            likeData={likeData}
                            setMessageSentData={setMessageSentData}
                          />
                      )
                    ) : connectedState &&
                        !connectRequestProfileConnectedTrueData?.error &&
                        connectedState?.slice().map((connected, idx) => (
                          <ConnectedCard key={connected.id} {...connected}
                            connected={connected}
                            handleHideProfile={handleHideProfile}
                            setMessageData={setMessageData}
                            singleProfileData={singleProfileData}
                            handleGetSingleProfile={handleGetSingleProfile}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            likeData={likeData}
                            setMessageSentData={setMessageSentData}
                          />
                    ))}
                     </>
                     </div>
                  )}

                </div>
            </section>
            </section>
          )}
          {connect === 'requested' && (
            <section className='requested'>
              <div className='request-nav'>
                <ul>
                  <li
                    onClick={() => {
                      setRequested('recieved')
                      setLeftBarItems("page")
                    }}
                    className={`${requested === 'recieved' ? 'reck' : ''}`}
                  >
                    Recieved
                  </li>
                  <li
                    onClick={() => {
                      setRequested('sent')
                      setLeftBarItems("profile")
                    }}
                    className={`${requested === 'sent' ? 'reck' : ''}`}
                  >
                    Sent
                  </li>
                 
                </ul>
              </div>
              <section className='connect__flex'>
                {leftBarItems === "page" && requested === 'recieved' ?
                 ""
                  : leftBarItems === "profile" ?
                  <LeftBar
                    connectProfileData={connectProfileData}
                    handleGetSingleProfile={handleGetSingleProfile}
                    profileForm={profileForm}
                    setCurrentProfile={setCurrentProfile}
                    currentProfile={currentProfile}
                    editData={editData}
                    editForm={editForm}
                    currentProfile={currentProfile}
                    handleGetConnectProfile={handleGetConnectProfile}
                    handleGetConnectProfileSuggested={handleGetConnectProfileSuggested}
                    handleGetConnectRequestSent={handleGetConnectRequestSent}
                    handleGetConnectRequestConnected={handleGetConnectRequestConnected}
                    handleGetConnectRequestProfileConnectedTrue={handleGetConnectRequestProfileConnectedTrue}
                    handleLikedPages={handleLikedPages}
                    setRetractSuccessModal={setRetractSuccessModal}
                    id={id}
                    />
                  : ""
                }

              {requested === 'recieved' && (
                <div className='connect__flex-right flex_connect_received'>
                  {/* {requested === 'recieved' && ( */}
                    <>
                    <SearchBarReceived
                      suggested={suggested}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                      filteredResults={filteredResults}
                      setFilteredResults={setFilteredResults}

                      className="filter_item"
                      />
                        {/* {connectRequestDataReceived.loading && connectRequestDataReceived.data?.count !== 0 && <LoaderPurple />} */}
                        {!connectRequestReceivedTrueData.loading && connectRequestReceivedTrueData.error && (
                          <p>An error</p>
                        )}

                        {searchInput.length > 1 ?
                          filteredResults?.map((recieved, index) => (
                            <Recieved key={recieved.id} {...recieved}
                              recieved={recieved}
                              handleHideProfile={handleHideProfile}
                              hideProfileData={hideProfileData}
                              handleLike={handleLike}
                              id={id}
                              currentProfile={currentProfile}
                              acceptModalData={acceptModalData}
                              setAcceptModalData={setAcceptModalData}
                              handleRespondConnectRequest={handleRespondConnectRequest}
                              currentId={currentId}
                              setCurrentId={setCurrentId}
                              setConnectHomeLoad={setConnectHomeLoad}
                              acceptColor={acceptColor}
                              setAcceptColor={setAcceptColor}
                              />
                            )
                          ) : requestReceived &&
                          !connectRequestReceivedTrueData.error &&
                          requestReceived?.map((recieved) => (
                          <Recieved key={recieved.id} {...recieved}
                            recieved={recieved}
                            handleHideProfile={handleHideProfile}
                            hideProfileData={hideProfileData}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            acceptModalData={acceptModalData}
                            setAcceptModalData={setAcceptModalData}
                            handleRespondConnectRequest={handleRespondConnectRequest}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            acceptColor={acceptColor}
                            setAcceptColor={setAcceptColor}
                            respondConnectRequestCardData={respondConnectRequestCardData}
                            handleRespondConnectRequestCard={handleRespondConnectRequestCard}
                            handleGetConnectRequestConnectedTrue={handleGetConnectRequestConnectedTrue}
                            handleGetConnectRequestReceivedTrue={handleGetConnectRequestReceivedTrue}
                            />
                        ))}
                    </>
                    </div>
                  )}

                  {requested === 'sent' && (
                     <div className='connect__flex-right'>
                    <>
                    <SearchBar
                      suggested={suggested}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                      filteredResults={filteredResults}
                      setFilteredResults={setFilteredResults}

                      className="filter_item"
                      />

                    {/* {connectRequestDataSent.loading && connectRequestDataSent.data?.count !== 0 && <LoaderPurple />} */}
                        {!connectRequestDataSent.loading && connectRequestDataSent.error && (
                          <p>An error</p>
                        )}
                        {searchInput.length > 1 ?
                          filteredResults?.map((sent, index) => (
                            <Sent key={sent.id} {...sent} sent={sent}
                              handleHideProfile={handleHideProfile}
                              hideProfileData={hideProfileData}
                              handleLike={handleLike}
                              id={id}
                              currentProfile={currentProfile}
                              retractModalData={retractModalData}
                              setRetractModalData={setRetractModalData}
                              handleRespondConnectRequest={handleRespondConnectRequest}
                              retractConnectData={retractConnectData}
                              handleRetractConnect={handleRetractConnect}
                              handleGetConnectRequestSent={handleGetConnectRequestSent}
                              currentId={currentId}
                              setCurrentId={setCurrentId}
                              id={id}
                              />
                            )
                          ) : sentState &&
                          !connectRequestDataSent.error &&
                          sentState?.map((sent) => (
                          <Sent key={sent.id} {...sent} sent={sent}
                            handleHideProfile={handleHideProfile}
                            hideProfileData={hideProfileData}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            retractModalData={retractModalData}
                            setRetractModalData={setRetractModalData}
                            handleRespondConnectRequest={handleRespondConnectRequest}
                            retractConnectData={retractConnectData}
                            handleRetractConnect={handleRetractConnect}
                            handleGetConnectRequestSent={handleGetConnectRequestSent}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            setRectractedCard={setRectractedCard}
                            retractConnectDataCard={retractConnectDataCard}
                            handleRetractConnectCard={handleRetractConnectCard}
                            handleGetConnectProfileSuggested={handleGetConnectProfileSuggested}
                            retractSuccessModal={retractSuccessModal}
                            setRetractSuccessModal={setRetractSuccessModal}
                            id={id}
                            />
                        ))}
                    </>
                    </div>
                  )}
                  {requested === 'favorite' && (
                     <div className='connect__flex-right'>
                    <>
                    <SearchBar
                      suggested={suggested}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                      filteredResults={filteredResults}
                      setFilteredResults={setFilteredResults}

                      className="filter_item"
                      />

                        {/* {likedPagesData.loading && likedPagesData.data?.count !== 0 && <LoaderPurple />} */}
                        
                        {!likedPagesData.loading && connectProfileDataFavorite.error && (
                          <p>An error</p>
                        )}

                        {searchInput.length > 1 ?
                          filteredResults?.map((favorite, index) => (
                            <Favorite key={favorite.id} {...favorite}
                              favorite={favorite}
                              handleHideProfile={handleHideProfile}
                              handleGetSingleProfile={handleGetSingleProfile}
                              singleProfileData={singleProfileData}
                              modalData={modalData}
                              setModalData={setModalData}
                              handleLike={handleLike}
                              id={id}
                              currentProfile={currentProfile}
                              likeData={likeData}
                              connectTo={connectTo}
                              setConnectTo={setConnectTo}
                              currentId={currentId}
                              setCurrentId={setCurrentId}
                              setLikeSuggestedCard={setLikeSuggestedCard}
                              />
                            )
                          ) : favoriteState &&
                          !likedPagesData.error &&
                          favoriteState?.map((favorite) => (
                          <Favorite key={favorite.id} {...favorite}
                            favorite={favorite}
                            handleHideProfile={handleHideProfile}
                            handleGetSingleProfile={handleGetSingleProfile}
                            singleProfileData={singleProfileData}
                            modalData={modalData}
                            setModalData={setModalData}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            likeData={likeData}
                            connectTo={connectTo}
                            setConnectTo={setConnectTo}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            setLikeSuggestedCard={setLikeSuggestedCard}
                            />
                        ))}
                    </>
                    </div>
                  )}
              </section>
            </section>
          )}
          {connect === 'suggested' && (
            <section className='requested'>
              <div className='request-nav'>
                <ul>
                <li
                    onClick={() => {
                      // setRequested('favorite')
                      // setLeftBarItems("profile")
                      setSuggestedPage("main")
                    }}
                    className={`${suggestedPage === 'main' ? 'reck' : ''}`}
                  >
                    Suggested
                  </li>
                  <li
                    onClick={() => {
                      // setRequested('favorite')
                      // setLeftBarItems("profile")
                      setSuggestedPage("favorite")
                      handleLikedPages(currentProfile)
                    }}
                    className={`${suggestedPage === 'favorite' ? 'reck' : ''}`}
                  >
                    Favorite
                  </li>
                  
                </ul>
              </div>
              
              <div className='connect__flex'>
                <LeftBar
                  connectProfileData={connectProfileData}
                  handleGetSingleProfile={handleGetSingleProfile}
                  profileForm={profileForm}
                  setCurrentProfile={setCurrentProfile}
                  currentProfile={currentProfile}
                  editData={editData}
                  editForm={editForm}
                  handleGetConnectProfile={handleGetConnectProfile}
                  handleGetConnectProfileSuggested={handleGetConnectProfileSuggested}
                  connectFrom={connectFrom}
                  setConnectFrom={setConnectFrom}
                  handleGetConnectRequestSent={handleGetConnectRequestSent}
                  handleGetConnectRequestConnected={handleGetConnectRequestConnected}
                  handleGetConnectRequestProfileConnectedTrue={handleGetConnectRequestProfileConnectedTrue}
                  handleLikedPages={handleLikedPages}
                  setRetractSuccessModal={setRetractSuccessModal}
                  id={id}
                  />
                
                {suggestedPage === 'main' && (
                <div className='connect__flex-right'>
                    <SearchBar
                      suggested={suggested}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                      filteredResults={filteredResults}
                      setFilteredResults={setFilteredResults}

                      className="filter_item"
                      />
                      {/* {connectProfileDataSuggested.loading && connectProfileDataSuggested.data?.count !== 0 && <LoaderPurple />} */}

                      {!connectProfileDataSuggested.loading && connectProfileDataSuggested.error && (
                        <p>An error</p>
                      )}

                      {searchInput.length > 1 ?
                        filteredResults?.map((suggestedItem, index) => (
                          <SuggestedCard key={suggestedItem?.id} {...suggestedItem}
                            suggested={suggestedItem}
                            handleGetSingleProfile={handleGetSingleProfile}
                            singleProfileData={singleProfileData}
                            modalData={modalData}
                            setModalData={setModalData}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            likeData={likeData}
                            handleHideProfile={handleHideProfile}
                            connectTo={connectTo}
                            setConnectTo={setConnectTo}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            setLikeSuggestedCard={setLikeSuggestedCard}
                            like={() => {
                              const suggestedCopy = [...suggested]
                              suggestedCopy.find((item) => item.id === suggestedItem.id).is_like = !suggestedItem.is_like
                              setSuggested(suggestedCopy)
                            }}
                            />
                          )
                        ) : suggested &&
                        !connectProfileDataSuggested.error &&
                        suggested?.slice().reverse().map((suggestedItem, index) => (
                    <SuggestedCard key={suggestedItem.id} {...suggestedItem}
                      suggested={suggestedItem}
                      handleGetSingleProfile={handleGetSingleProfile}
                      singleProfileData={singleProfileData}
                      modalData={modalData}
                      setModalData={setModalData}
                      handleLike={handleLike}
                      id={id}
                      currentProfile={currentProfile}
                      likeData={likeData}
                      handleHideProfile={handleHideProfile}
                      connectTo={connectTo}
                      setConnectTo={setConnectTo}
                      currentId={currentId}
                      setCurrentId={setCurrentId}
                      setLikeSuggestedCard={setLikeSuggestedCard}
                      handleSendConnectRequest={handleSendConnectRequest}
                      sendConnectRequestData={sendConnectRequestData}
                      like={() => {
                        const suggestedCopy = [...suggested]
                        suggestedCopy.find((item) => item.id === suggestedItem.id).is_like = !suggestedItem.is_like
                        setSuggested(suggestedCopy)
                      }}
                      />
                  ))}
                </div>
                )}
                {suggestedPage === 'favorite' && (
                    <div className='connect__flex-right'>
                      <>
                    <SearchBar
                      suggested={suggested}
                      searchInput={searchInput}
                      setSearchInput={setSearchInput}
                      filteredResults={filteredResults}
                      setFilteredResults={setFilteredResults}

                      className="filter_item"
                      />

                        {/* {likedPagesData.loading && likedPagesData.data?.count !== 0 && <LoaderPurple />} */}
                        
                        {!likedPagesData.loading && connectProfileDataFavorite.error && (
                          <p>An error</p>
                        )}

                        {searchInput.length > 1 ?
                          filteredResults?.map((favorite, index) => (
                            <Favorite key={favorite.id} {...favorite}
                              favorite={favorite}
                              handleHideProfile={handleHideProfile}
                              handleGetSingleProfile={handleGetSingleProfile}
                              singleProfileData={singleProfileData}
                              modalData={modalData}
                              setModalData={setModalData}
                              handleLike={handleLike}
                              id={id}
                              currentProfile={currentProfile}
                              likeData={likeData}
                              connectTo={connectTo}
                              setConnectTo={setConnectTo}
                              currentId={currentId}
                              setCurrentId={setCurrentId}
                              setLikeSuggestedCard={setLikeSuggestedCard}
                              acceptColor={acceptColor}
                              setAcceptColor={setAcceptColor}
                              />
                            )
                          ) : suggested &&
                          !connectProfileDataSuggested.error &&
                          suggested?.slice().reverse().map((favorite) => (
                          <Favorite key={favorite.id} {...favorite}
                            favorite={favorite}
                            handleHideProfile={handleHideProfile}
                            handleGetSingleProfile={handleGetSingleProfile}
                            singleProfileData={singleProfileData}
                            modalData={modalData}
                            setModalData={setModalData}
                            handleLike={handleLike}
                            id={id}
                            currentProfile={currentProfile}
                            likeData={likeData}
                            connectTo={connectTo}
                            setConnectTo={setConnectTo}
                            currentId={currentId}
                            setCurrentId={setCurrentId}
                            setLikeSuggestedCard={setLikeSuggestedCard}
                            acceptColor={acceptColor}
                            setAcceptColor={setAcceptColor}
                            />
                        ))}
                     </>
                    </div>
                  )}
              </div>
            </section>
          )}
        </section>
      </section>
    </FeedContainer>
  )
}

export default Manage
