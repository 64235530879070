const GeneralBusinessNotificationItem = ({generalNotificationItem, dataEntered, setDataEntered, idx}) => {
    console.log(dataEntered)
    return(
        <li>
            <span>{generalNotificationItem.title}</span>
            <div className={`switch ${generalNotificationItem.value === false  && 'off'}`} 
            onClick={()=>{
                // console.log(dataEntered.General)
                setDataEntered({...dataEntered, general:dataEntered.general.map((data, idc) => {
                    if(idc === idx){
                        const newData =  {...data, value: data.value ? false : true};
                        return newData
                    }
                    return data
                })})
                // setDataEntered(dataEntered.General.map((data)=>{
                //     let indexNo = dataEntered.General.indexOf(data)
                //     if(indexNo === idx){
                //         return data.value ? {...data, value: false}: {...data, value: true}
                //     }
                //     console.log(data)
                //     return data
                // }))
            }}>
                <span className={`${generalNotificationItem.value === false && 'offSpan'}`} ></span>
            </div>
        </li>
    )
}
export default GeneralBusinessNotificationItem