import { Save } from "../../assets/svg/PostCardIcon";
import "./suggestedUsers.scss";
import testAvatar from "../../assets/img/Ellipse 267.png";
import suggestedPages from "../../utils/suggestedPages";
import cx from "classnames";
// import VuesaxBoldHeartTickComponent from "../../assets/svg/VuesaxBoldHeartTickComponent";
// import VuesaxOutlineHeartTickComponent from "../../assets/svg/VuesaxOutlineHeartTickComponent";
// import VuesaxLinearArchiveMinusComponent, {
//   DefaultSave,
// } from "../../assets/leftBarIcons/VuesaxLinearArchiveMinusComponent";

import Pages from "./pages";
import { useState } from "react";
import Users from "./users";

const SuggestedUsers = () => {
  const [active, setActive] = useState(1);

  const handleClick = () => {
    setActive(active === 1 ? 0 : 1);
  };
  return (
    <div className="suggested__users">
      <div className="tab">
        <p onClick={handleClick} className={cx({ active: active === 0 })}>
          Suggested Users
        </p>
        <p className={cx({ active: active === 1 })} onClick={handleClick}>
          Suggested pages
        </p>
      </div>
      <div className="__container">
        {active === 1 &&
          suggestedPages.map((page, idx) => (
            <Pages page={{ ...page, avatar: testAvatar }} key={idx} />
          ))}
        {active === 0 &&
          suggestedPages.map((page, idx) => (
            <Users page={{ ...page, avatar: testAvatar }} key={idx} />
          ))}
      </div>
    </div>
  );
};

export default SuggestedUsers;
