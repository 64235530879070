import { useEffect } from "react";
import useBusinessSellerDashboard from "../../../../hooks/businessSellerDashboard";
import Loader from "../../../loader";
import QueueReqItem from "./queueReqItem";
import RequestedItem from "./requestItem";

const Queueings = ({ id }) => {
  const {
    handleGetQueueRequest,
    queueRequestData,
    handleUpdateQueue,
    updateQueueData,
  } = useBusinessSellerDashboard();

  useEffect(() => {
    handleGetQueueRequest(id);
  }, []);
  useEffect(() => {
    updateQueueData?.data && handleGetQueueRequest(id);
  }, [updateQueueData?.data]);
  return (
    <div className="queueings">
      <h6 className="orcb-title"> Queueings </h6>
      {queueRequestData?.data?.results.length === 0 && (
        <h5>You have no Queueing requests</h5>
      )}

      {queueRequestData?.loading && <Loader />}

      {queueRequestData?.data?.results?.map((queueItem, idx) => (
        <QueueReqItem data={queueItem} handleUpdateQueue={handleUpdateQueue} />
      ))}
    </div>
  );
};
export default Queueings;
