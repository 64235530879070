import moment from "moment";
import { useState, useEffect } from "react";
import Loader from "../../components/loader";
import useReferral from "../../hooks/referrals";

const PrivateSellerReferral = ({ data }) => {
  const {
    productAffiliates,
    handleGetProductAffiliates,
    handleGetAffiliateCommissions,
    affiliateCommission,
  } = useReferral();

  const [selected, setSelected] = useState();

  const [selectedAffiliate, setSelectedAffiliate] = useState();

  useEffect(() => {
    setSelected(data[0].id);
  }, [data]);
  useEffect(() => {
    selected && handleGetProductAffiliates({ productId: selected });
  }, [selected]);

  const handleSum = () => {
    let arr = affiliateCommission?.data?.results?.map((data) => {
      return data?.commission;
    });
    return arr.reduce((total, num) => {
      return total + num;
    });
    console.log(arr, "arr");
  };
  return (
    <div className="main__listing">
      <div className="pre">
        <h2>Products</h2>
        <div className="item__scroll">
          {data?.map((dataItem, idx) => (
            <div
              // key={data.id}
              className={`names ${selected === dataItem?.id && "active"}`}
              onClick={(e) => {
                setSelected(dataItem?.id);
                // handleGetProductAffiliates(dataItem?.id);
              }}
            >
              <h5> {dataItem?.title} </h5>
            </div>
          ))}
        </div>
      </div>
      <div className="left">
        {selected && (
          <>
            <h1>Affiliate</h1>
            <div className="item__scroll">
              {productAffiliates?.loading && <Loader />}
              {productAffiliates?.data?.results?.map((data, idx) => (
                // add selected to class to indicate selected item
                <div
                  className={`names names-affliate ${
                    selectedAffiliate === data?.id && "selected"
                  }`}
                  onClick={() => {
                    handleGetAffiliateCommissions(data?.id);
                    setSelectedAffiliate(data?.id);
                  }}
                >
                  <h5> {data?.influencer?.full_name} </h5>
                </div>
              ))}
            </div>
          </>
        )}
      </div>

      {affiliateCommission?.data && (
        <div className="right__contents">
          <div className="total">
            Total commission paid <span>${handleSum()}</span>
          </div>
          <div className="item__scroll">
            {affiliateCommission?.data?.results?.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>S.No</th>
                    <th>Username</th>
                    <th>Quantity</th>
                    <th>Time</th>
                    <th>Commission</th>
                  </tr>
                </thead>
                <tbody>
                  {affiliateCommission?.loading && <Loader />}
                  {affiliateCommission?.data?.results?.map((data, idx) => (
                    <tr key={idx}>
                      <td>{idx + 1}</td>
                      <td>{data?.username}</td>
                      <td>{data?.quantity}</td>
                      <td>{moment(data?.time).format("DD MMM YYYY")}</td>
                      <td>${data?.commission}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No affiliate commission data</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrivateSellerReferral;
