import React from "react";
import { Col, Row } from "reactstrap";
import { DashboardToggle, SuggestToggle } from "../pagetoggle";

class DashboardFeeds extends React.Component {
  render() {
    return (
      <Col xl={10}>
        <Row>
          <Col xl={9}>
            <DashboardToggle />
          </Col>
          <Col xl={2}>
            <p
              className="pt-4"
              style={{ fontWeight: " 500", fontSize: "17px" }}
            >
              Suggested Listings
            </p>
            <div
              className="border-radius-10 mt-4"
              style={{ background: "#9300b125", height: "400px" }}
            >
              <h6>third</h6>
            </div>
            <SuggestToggle />
            <div
              className="mt-3 border-radius-10"
              style={{
                background: "#9300b125",
                width: "100%",
                height: "400px",
              }}
            ></div>
          </Col>
        </Row>
      </Col>
    );
  }
}

export default DashboardFeeds;
