import useAxios from "axios-hooks";
import { useEffect } from "react";
import { setCurrentUser } from "../appstore/userData/index.action";
import SocialContext from "./socialContext";
import { useContext } from "react";
import { useSelector } from "react-redux";

const useSocialDetails = () => {
  const { addSocialDetails } = useContext(SocialContext);

  // Social Details
  const [{ ...getSocialDetailsData }, getSocialDetails] = useAxios({
    method: "GET",
  });
  const handleGetSocialDetails = (id) => {
    getSocialDetails({
      url: `/users/${id || "me"}/`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getSocialDetailsData.data) {
      addSocialDetails(getSocialDetailsData);
      console.log(getSocialDetailsData);
    }
  }, [getSocialDetailsData.data]);

  const [{ updateUserData }, updateUser] = useAxios(
    { method: "patch", url: "/users/me/" },
    { manual: true }
  );

  const handleUpdate = async (data = {}) => {
    return await updateUser({ data });
  };

  return {
    handleGetSocialDetails,
    handleUpdate,
    updateUserData,
    getSocialDetailsData,
  };
};
export default useSocialDetails;
