import { useState, useContext } from "react";
import "./jobsAnalytics.scss";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import SalesGrowthChart from "../../../../components/Analytics/components/salesGrowthJob";
import WalletMoney from "../../../../assets/svg/wallet-money";
import RateIcon from "../../../../assets/svg/rateIcon";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ChartStats from "../../../../components/Analytics/components/chartStats";
import GroupUserIcon from "../../../../assets/svg/groupUserIcon";
import OrderCompletion from "../../../../components/Analytics/components/orderCompletion";
import TaskSquare from "../../../../assets/svg/taskSquare";
import Bag from "../../../../assets/svg/bag";
import BagTick from "../../../../assets/svg/bagTick";
import BagCross from "../../../../assets/svg/bagCross";
import BagHappy from "../../../../assets/svg/bagHappy";
import ModalContext from "../../../../hooks/modalContexts";
import AreaChart from "../../../../components/Analytics/Charts/areaChartJob";
import { Line } from "react-chartjs-2";
import {
  chartData2,
  chartOptions2,
} from "../../../../components/Analytics/chartDetails";

const JobsAnalytics = ({
  jobInteraction,
  jobChart,
  postJobs,
}) => {
  const testArray = [1, 2, 3, 4, 5, 6, 7];

  const { setJobAnalyticModal, setTotalApplicationModal } = useContext(ModalContext);
  const { setReferralOrderModal } = useContext(ModalContext);
  const { setCustomerConversionModal } = useContext(ModalContext);

  return (
    <div className="jobs-analytics-container">
      <div className="jac-card-body">
        <div className="jacb-contents-one">
          <SalesGrowthChart
            image={<WalletMoney />}
            title="Jobs Growth"
            height={250}
            width={900}
            jobChart={jobChart}
          />
        </div>
        {/* <div className="jacb-contents-two">
          <OrderCompletion
            image={<TaskSquare fill={"#000"} />}
            title="Total Job post"
            orderValue={jobInteraction?.total_job_post?.total}
            orderPercent={jobInteraction?.total_job_post?.summary.percentage + "%"}
            bgColor="rgba(220,220,220,0.4)"
            bgColor2="#000"
            onClick={(e) => setJobAnalyticModal(true)}
            {jobInteraction?.total_job_post?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
          />
          <OrderCompletion
            image={<BagHappy />}
            title="Incomplete Queue"
            orderValue="23 of 100"
            orderPercent="2.3%"
            bgColor="rgba(255,156,0,0.4)"
            bgColor2="#fbb03b"
          />
          <OrderCompletion
            image={<BagCross />}
            title="Incomplete Application"
            orderValue="3 of 100"
            orderPercent="2.3%"
            bgColor="rgba(242,81,81,0.4)"
            bgColor2="#c10000"
          />
        </div> */}
      </div>
      <div className="jac-card-body-two">
        <div className="jacbt-contents-one">
          <div className="jco-header">
            <div className="tab-title">Posted Jobs</div>
          </div>
          <div className="jco-body">
            {/* ************ POSTED JOBS ************* */}
            <div className="jco-body">
              <div className="item__scroll">
                {postJobs?.map((data, idx) => (
                  <div className="jcob-details">
                    <div className="details-box-one">
                      <h4>{idx + 1}</h4>
                       <img src={data?.featured_image.file ? data?.featured_image.file : itemImg} alt="" />
                      <div className="details-box-two">
                        {/* <h5> Maria Sponge </h5> */}
                        <h5>@{data?.page_username ? data?.page_username : "Username"} </h5>
                      </div>
                      <div className="details-box-three">
                        <img src={data?.page_featured_image !== null ? data?.page_featured_image : itemImg} alt="" />
                        <h5> {data?.job_title}</h5>
                      </div>
                    </div>

                    <div className={data?.summary.summary.direction === "UP" ? "jcob-chart rate-text-green" : "jcob-chart rate-text-red"}>
                      <p>
                        {data?.summary.summary.percentage.toFixed(1)}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                      </p>
                      <div>
                       <AreaChart data={data}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="dmc-stats">
          <ChartStats
            image={<WalletMoney />}
            title="Total Number of posted Jobs"
            statPercent={jobInteraction?.total_job_post?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={jobInteraction?.total_job_post?.total}
            onClick={(e) => setJobAnalyticModal(true)}
          />
          <ChartStats
            image={<GroupUserIcon fill="#fbb03b" />}
            title="Number of referrals"
            statPercent={jobInteraction?.number_of_referrals?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={jobInteraction?.number_of_referrals?.total}
            onClick={(e) => setReferralOrderModal(true)}
          />
          <ChartStats
            image={<RateIcon />}
            title="Total number of Applicants"
            statPercent={jobInteraction?.total_applicants?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={jobInteraction?.total_applicants?.total}
            onClick={(e) => setTotalApplicationModal(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default JobsAnalytics;
