import { useState } from "react";
import PostCardSingle from "../../components/PostCardsSingle";
import { useLocation, useHistory, useParams } from "react-router-dom";
import qs from "query-string";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import usePosts from "../../hooks/posts";
import FeedContainer from "../../components/FeedContainer";
import "./index.scss";
import BackArrow from "../../assets/svg/backArrow";
import infiniteScroll from "../../hooks/infiniteScroll";
import CommentCard from "../../components/commentCard";
import { useDispatch } from "react-redux";
import { clearSingleComment } from "../../appstore/singleCommentV2/singleCommentV2.actions";
const SingleComments = () => {
  const { commentId } = useParams();
  const dispatch = useDispatch();
  const { handleGetSingleComment, comments, handleGetRepliesComments } =
    usePosts();
  const singleComment = useSelector((state) => state.singleCommentV2);

  const history = useHistory();
  useEffect(() => {
    commentId && handleGetSingleComment(commentId);

    return () => {
      dispatch(clearSingleComment());
    };
  }, [commentId]);

  useEffect(() => {
    singleComment?.id &&
      handleGetRepliesComments(singleComment.id, comments.data?.next);
  }, [singleComment?.id]);

  const handleLoadMore = () => {
    // handleGetComments(singleComment.id, comments.data?.next);
  };

  //clean up on exit
  // useEffect(() => () => dispatch(clearSingleComment()), []);
  console.log(singleComment);
  return (
    <FeedContainer>
      {singleComment && (
        <div
          className="single__post"
          onScroll={(e) =>
            infiniteScroll(e, handleLoadMore, comments.data?.next)
          }>
          <div className="back" onClick={(e) => history.goBack()}>
            <BackArrow />
            Back
          </div>
          <CommentCard {...singleComment} />
        </div>
      )}
    </FeedContainer>
  );
};

export default SingleComments;
