import { useState, useRef, useEffect, createContext } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { Fragment } from "react/cjs/react.production.min";
import middleFeedNav from "../../utils/middleFeedNav";
// import CreateMoment from "../CreateMoment";
import UserDetail from "../UserDetail";
import "./middleFeed.scss";
// import useDeviceType from "../../hooks/deviceType";
// import MobileAbout from "../UserDetail/mobileAout";
import usePosts from "../../hooks/posts";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { resetPosts } from "../../appstore/posts/index.action";
import PostButton from "../customButton";
import { useContext } from "react";
import ModalContext from "../../hooks/modalContexts";
import SocialContext from "../../hooks/socialContext";
import BackArrow from "../../assets/svg/backArrow";

export const DataContext = createContext(null);

const MiddleFeed = ({ children }) => {
  // const history = useHistory();
  const { focus } = useParams();
  const dispatch = useDispatch();
  const { handleGetPosts } = usePosts();
  const { user } = useSelector((state) => state.currentUser);
  // const postCardData = useSelector((state) => state.post);

  const history = useHistory();
  const postsWrap = useRef(null);
  const addActiveToLink = (link) => {
    if (link.includes(focus)) return "active_link";
    return;
  };

  // console.log(getPostsData);
  // const handleComponentRendering = (pathname) => {
  //   setPathname(pathname);
  // };
  // console.log(postCardData.next);
  const { postModalVisibilityStatus } = useContext(ModalContext);
  const { visitor, socialDetails } = useContext(SocialContext);

  useEffect(() => {
    // console.log("calling::", focus);

    focus === "trending" && handleGetPosts("trending");
    focus === "community" && handleGetPosts("community");
    focus === "timeline" &&
      socialDetails[0] &&
      handleGetPosts("timeline", null, 4, null, socialDetails[0].user.data.id);

    return () => {
      dispatch(resetPosts());
    };
  }, [focus, socialDetails[0]]);

  return (
    <div className="center" ref={postsWrap}>
      {visitor && focus?.toLowerCase() === "timeline" ? (
        <div className="timeline-visitor">
          <span
            onClick={() => {
              history.goBack();
            }}>
            <BackArrow /> Back
          </span>
        </div>
      ) : (
        <div className="center__menu">
          {middleFeedNav.map((nav, idx) => (
            <Link
              key={idx}
              to={
                nav.link.includes("timeline")
                  ? `${nav.link}/${user.username}`
                  : nav.link
              }
              className={addActiveToLink(nav.link)}
              // onClick={(e) => handleComponentRendering(nav.link)}
            >
              {nav.name}
            </Link>
          ))}
        </div>
      )}

      {/* user detail card */}
      {focus === "timeline" && (
        <Fragment>
          <UserDetail />
          {!visitor && (
            <PostButton
              style={{ margin: `27px auto` }}
              onClick={(e) => postModalVisibilityStatus[1](true)}
            />
          )}
        </Fragment>
      )}

      <DataContext.Provider value={{ focus }}>{children}</DataContext.Provider>
    </div>
  );
};
export default MiddleFeed;
