import moment from "moment";
import profileImg from "../../../../assets/img/Ellipse 357.png";
import useActivity from "../../../../hooks/activity";
// import PrivateSellerDashboard from "../../../../pages/PrivateSeller/PrivateSellerManagement/dashboard";

const QueueReqItem = ({ data, handleUpdateQueue }) => {
  return (
    <div className="orc-item-box">
      <div className="orc-ib-one">
        <div className="orc-detail-box">
          <img
            style={{
              borderRadius: "50%",
              width: "40px",
              height: "40px",
            }}
            src={
              data?.owner?.profile_image?.file
                ? data?.owner?.profile_image?.file
                : profileImg
            }
            alt=""
          />
          <div className="orc-detail-box-user">
            <h4>{data?.owner?.full_name}</h4>
            <h6>{data?.owner?.username}</h6>
          </div>
        </div>
        <h5>{data?.service?.title}</h5>
        <h6>Booked on {moment(data?.created_at).format("DD MMM YYYY")}</h6>
        <p>Expires on {moment(data?.queue_expire).format("DD MMM YYYY")}</p>
      </div>

      <div className="orc-ib-two">
        <button
          className="btn-accept"
          onClick={() => handleUpdateQueue({ id: data?.id, status: "ACCEPT" })}
        >
          Accept
        </button>
        <button
          className="btn-decline"
          onClick={() => handleUpdateQueue({ id: data?.id, status: "DECLINE" })}
        >
          Decline
        </button>
      </div>
    </div>
  );
};

export default QueueReqItem;
