import "./index.scss";
const Loader = ({ text = "loading", scale = 1, outerStyle }) => {
  return (
    <div
      class="content"
      style={{
        ...(scale && {
          transform: `scale(${scale})`,
          // transformOrigin: `${scale / 1}% ${scale / 1}%`,
        }),
        ...outerStyle,
      }}>
      <div class="loading">
        {!!text && <p>{text}</p>}
        <span></span>
      </div>
    </div>
  );
};

export default Loader;
