import React, { useState } from "react";
import { TabContent, TabPane, Col } from "reactstrap";
import {
  Tabs,
  Tab as ExploreTab,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { colors } from "../../../color";
import GeneralSettings from "./generalSettings";
import PrivacyPage from "./privacy";
import SecurityPage from "./security";
import NotificationPage from "./notification";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabText: {
    color: `${colors.COMPANY_COLOR}`,
    "&focused": {},
    focused: {},
  },
}));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: `${colors.LIGHT_GREY}`,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(13),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      color: `${colors.COMPANY_COLOR}`,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}))((props) => <ExploreTab disableRipple {...props} />);

const SettingsToggle = () => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();

  const handleChange = (e, val) => {
    setActiveTab(val);
  };

  return (
    <div>
      <Tabs
        TabIndicatorProps={{
          style: { background: `${colors.COMPANY_COLOR}`, height: "4px" },
        }}
        onChange={handleChange}
        value={activeTab}
        style={{
          borderBottom: `1px solid ${colors.GREY}`,
          width: "100%",
          marginBottom: "30px",
        }}
      >
        <StyledTab
          className={classes.tabText}
          label={<div>General Account Settings</div>}
        />
        <StyledTab
          className={classes.tabText}
          label={<div>Privacy Settings</div>}
        />
        <StyledTab
          className={classes.tabText}
          label={<div>Security settings</div>}
        />
        <StyledTab
          className={classes.tabText}
          label={<div>Notification Settings</div>}
        />
        <StyledTab className={classes.tabText} label={<div>History</div>} />
      </Tabs>

      {/* Content1 */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <GeneralSettings />
        </TabPane>
      </TabContent>

      {/* Content2 */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <PrivacyPage />
        </TabPane>
      </TabContent>

      {/* Content3 */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={2}>
          <SecurityPage />
        </TabPane>
      </TabContent>

      {/* Content4 */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={3}>
          <NotificationPage />
        </TabPane>
      </TabContent>
    </div>
  );
};

class SettingsPage extends React.Component {
  render() {
    return (
      <Col xl={9}>
        <SettingsToggle />
      </Col>
    );
  }
}

export default SettingsPage;
