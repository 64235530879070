import { Chart as ChartJS } from "chart.js/auto";
import useAnalytics from "../../hooks/Analytics";

let chWidth, chHeight, gradient, chWidth2, chHeight2, gradient2;
function getGradientGreen(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || chWidth !== chartWidth || chHeight !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    chWidth = chartWidth;
    chHeight = chartHeight;
    gradient = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.height);
    gradient.addColorStop(0, "rgba(0,193,58, 0.5)");
    gradient.addColorStop(0.5, "rgba(0,193,58, 0.25)");
    gradient.addColorStop(1, "rgba(0,193,58, 0)");
  }

  return gradient;
}

function getGradientRed(ctx, chartArea) {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient2 || chWidth2 !== chartWidth || chHeight2 !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    chWidth2 = chartWidth;
    chHeight2 = chartHeight;
    gradient2 = ctx.createLinearGradient(0, chartArea.top, 0, chartArea.height);
    gradient2.addColorStop(0, "rgba(206, 0, 0, 0.5)");
    gradient2.addColorStop(0.5, "rgba(206, 0, 0, 0.25)");
    gradient2.addColorStop(1, "rgba(206, 0, 0, 0)");
  }

  return gradient2;
}

export const chartData = {
  labels: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Total Revenue",
      fill: true,
      // fillColor: gradient,
      lineTension: 0.1,
      // backgroundColor: gradient,
      borderColor: "rgba(0,193,58,1.0)",
      backgroundColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          // This case happens on initial chart load
          return;
        }
        return getGradientGreen(ctx, chartArea);
      },
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(0,193,58,1.0)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 0.5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(0,193,58,1.0)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      data: [10, 35, 30, 55, 45, 55, 37],
    },
  ],
};

export const chartOptions = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
      beginAtZero: true,
    },
  },
  //   animation: {
  //     easing: 'easeInOutQuad',
  //     duration: 520
  // },
  animation: {
    x: {
      duration: 3500,
      from: 0,
    },
    y: {
      duration: 2000,
      from: 500,
    },
  },
};
/*
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 *
 **-------------------  RED CHART   *****/
export const chartData2 = {
  labels: ["Mon", "Tues", "Wed", "Thurs", "Fri", "Sat", "Sun"],
  datasets: [
    {
      label: "Total Revenue",
      fill: true,
      lineTension: 0.1,
      // backgroundColor: "rgba(206, 0, 0, 0.4)",
      backgroundColor: function (context) {
        const chart = context.chart;
        const { ctx, chartArea } = chart;

        if (!chartArea) {
          // This case happens on initial chart load
          return;
        }
        return getGradientRed(ctx, chartArea);
      },
      borderColor: "rgba(206, 0, 0, 1)",
      borderCapStyle: "butt",
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: "miter",
      pointBorderColor: "rgba(206, 0, 0, 1)",
      pointBackgroundColor: "#fff",
      pointBorderWidth: 0.5,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: "rgba(206, 0, 0, 1)",
      pointHoverBorderColor: "rgba(220,220,220,1)",
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      pointHitRadius: 10,
      data: [10, 35, 30, 55, 45, 55, 37],
    },
  ],
};

export const chartOptions2 = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    x: {
      display: false,
    },
    y: {
      display: false,
      beginAtZero: true,
    },
  },
  animation: {
    x: {
      duration: 3500,
      from: 0,
    },
    y: {
      duration: 2000,
      from: 500,
    },
  },
};
