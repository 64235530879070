import {
  CREATE_SINGLE_POST_DATA,
  NEW_COMMENT_LIST,
  NEW_REPLY_LIST,
  ADD_COMMENT,
  ADD_REPLY,
  RESET_SINGLE_POST_DATA,
  UPDATE_SINGLE_POST,
  UPDATE_SINGLE_POST_COMMENT,
  UPDATE_COMMENT_LIST,
  UPDATE_COMMENT,
  UPDATE_POST_LIKE,
} from "./index.types";

const defaultData = {
  post: false,
};

const singlePost = (state = defaultData, { type, payload }) => {
  switch (type) {
    case CREATE_SINGLE_POST_DATA:
      return {
        ...state,
        post: payload,
      };
    case RESET_SINGLE_POST_DATA:
      return {
        ...state,
        post: false,
      };
    case UPDATE_SINGLE_POST:
      return state.post && state.post?.id === payload.id
        ? { ...state, post: { ...payload, comments: state.post.comments } }
        : { ...state, post: false };
    case UPDATE_POST_LIKE:
      return state.post && state.post?.id === payload.id
        ? {
            ...state,
            post: {
              ...state.post,
              is_liked: payload.status,
              num_of_likes: payload.count || 0,
            },
          }
        : { ...state, post: false };
    case UPDATE_SINGLE_POST_COMMENT:
      return state.post && state.post?.id === payload.post
        ? {
            ...state,
            post: {
              ...state.post,
              comments: [
                !payload.parent && payload,
                ...state.post.comments,
              ].filter((data) => data && data),
            },
          }
        : state;

    case NEW_COMMENT_LIST:
      return {
        ...state,
        post: {
          ...state.post,
          comments: payload,
        },
      };
    case UPDATE_COMMENT_LIST:
      return {
        ...state,
        post: {
          ...state.post,
          comments: [...state.post.comments, ...payload],
        },
      };
    case ADD_REPLY:
      return payload.parent
        ? {
            ...state,
            post: {
              ...state.post,
              comments: state.post.comments
                .map((comment) => {
                  return comment.id === payload.parent
                    ? {
                        ...comment,
                        recent_replies: [payload, ...comment.recent_replies],
                      }
                    : comment;
                })
                .filter((data) => data && data),
            },
          }
        : state;
    case UPDATE_COMMENT:
      return {
        ...state,
        post: {
          ...state.post,
          comments: state.post.comments.map((item) => {
            return payload.id === item.id ? payload : item;
          }),
        },
      };
    default:
      return state;
  }
};

export default singlePost;
