import { useContext, useEffect } from "react";
import "./summary.scss";
import ModalContext from "../../../hooks/modalContexts";
import Photo from "../../../assets/img/shoe.png";
import profileImg from "../../../assets/img/Ellipse 543@2x.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import { FaPen } from "react-icons/fa";
import CloseBtn from "../../../components/closeBtn";
import moment from "moment";
import { Fragment } from "react/cjs/react.production.min";
import usePages from "../../../hooks/createPage";
import useCreateListings from "../../../hooks/createListings";
import LoaderWhite from "../../../assets/loaders/LoaderWhite";

const BusinessSummaryModal = () => {
  const { handleSummaryModal, businessSummaryModal } = useContext(ModalContext);
  const { data, images, rawImages } = businessSummaryModal;
  console.log(data);
  const { handleGetSinglePage, singlePageData } = usePages();
  const { handleCreateListing, loading, createListingData } =
    useCreateListings();
  useEffect(() => {
    handleGetSinglePage(data.page);
  }, []);
  return (
    <div
      onClick={() => {
        handleSummaryModal("RESET_DATA");
      }}
      className="bsModal">
      <div className="bsModalContainer">
        <div
          onClick={() => {
            handleSummaryModal("RESET_DATA");
          }}
          className="closeIcon">
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="bsModalContainered">
          <div className="bsModal-header">
            <h4> Summary</h4>
            {!loading ? (
              <button
                className="btn-post"
                onClick={handleCreateListing.bind(this, {
                  data,
                  images,
                })}>
                Post
              </button>
            ) : (
              <button className="btn-post">
                <LoaderWhite
                  style={{
                    width: 20,
                    height: "auto",
                  }}
                />
              </button>
            )}
          </div>
          <div className="bsModal-body">
            <div className="summary-container-one">
              <div className="img-sm-box">
                <Slick dots={true} slidesToShow={1}>
                  {rawImages.map((data, idx) => (
                    <img src={URL.createObjectURL(data)} alt="" key={idx} />
                  ))}
                </Slick>
              </div>
              <div className="desc-sm-box">
                <div className="orc-detail-box">
                  {singlePageData.data?.featured_image && (
                    <img
                      src={singlePageData.data.featured_image.file}
                      alt="avatar"
                    />
                  )}
                  <div className="orc-detail-box-user">
                    <h4>{singlePageData.data?.name}</h4>
                    <h6>{singlePageData.data?.location || "No location"}</h6>
                  </div>
                </div>
                <h3> {data.title} </h3>
                <h4> Product Description </h4>
                <p>{data.description}</p>
              </div>
            </div>
            <div className="summary-container-two">
              <div className="sct-box-one">
                <h5> Price detail </h5>
                {data.stocks.map((stock) => {
                  // console.log(stock);
                  const { deals } = stock;
                  return (
                    <div className="sct-price-detail" key={stock.tempId}>
                      <div className="sct-pd-box">
                        <div>
                          <p>
                            {data.sales_option === "BUY_NOW"
                              ? "Buy Now"
                              : "Pre Order"}
                          </p>
                          <h6>
                            Min Purchase Qty:{" "}
                            <span>{stock.min_purchase_quantity}</span>
                          </h6>
                          <h6>
                            Stock Qty: <span>{stock.quantity}</span>
                          </h6>
                        </div>
                        <div>
                          <h6>
                            Price: <span> ${stock.price} </span>
                          </h6>
                          <h6>
                            Deals:{" "}
                            <span>
                              {deals.length &&
                                deals[0].name === "deals" &&
                                deals[0].constant.fix_price &&
                                "$"}
                              {deals.length &&
                                (deals[0].name === "deals"
                                  ? deals[0].constant.fix_price ||
                                    deals[0].constant.percentage
                                  : "nil")}
                              {deals.length &&
                                deals[0].name === "deals" &&
                                deals[0].constant.percentage &&
                                "%"}
                            </span>
                          </h6>
                          <h6>
                            Discount:{" "}
                            <span>
                              {" "}
                              {deals.length &&
                                deals[0].name === "discount" &&
                                deals[0].constant.fix_price &&
                                "$"}
                              {deals.length &&
                                (deals[0].name === "discount"
                                  ? deals[0].constant.fix_price ||
                                    deals[0].constant.percentage
                                  : "nil")}{" "}
                              {deals.length &&
                                deals[0].name === "discount" &&
                                deals[0].constant.percentage &&
                                "%"}
                            </span>
                          </h6>
                        </div>
                      </div>

                      {/* <div className="sctp-footer">
                    <p>
                      Minimum qty of 200 units will be ordered before June 21
                      2012 before order is processed
                    </p>
                  </div> */}
                    </div>
                  );
                })}

                {data.allow_referral && (
                  <>
                    <h5>Referral</h5>
                    {data.who_can_refer === "ANYBODY" ? (
                      <div className="sct-referral">
                        <h6>
                          Anyone: <span> ${data.referrals[0].price_value}</span>
                        </h6>
                      </div>
                    ) : (
                      <div className="sct-influencer">
                        <h6> Influencer </h6>
                        <div className="item__scroll">
                          {data.referrals.map((data, idx) => (
                            <div className="scti-details" key={idx}>
                              <h6>
                                {idx + 1} <span> {data.username}</span>
                              </h6>
                              <p>
                                price: <span>${data.price_value} </span>
                              </p>
                              <p>
                                REf code: <span>{data.code}</span>
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              <div className="sct-box-two">
                <h5>End date</h5>
                <div className="sct-date">
                  <h6>
                    End date:{" "}
                    <span>
                      {moment(data.listing_end_date).format("MMMM DD, YYYY")}{" "}
                    </span>
                  </h6>
                  <h6>
                    Time:{" "}
                    <span>
                      {" "}
                      {moment(data.listing_end_date).format("HH:MMa")}{" "}
                    </span>
                  </h6>
                </div>

                {!!data.accept_order_automatically && (
                  <div className="sct-request">
                    <h6>Request:</h6>
                    <span>Auto Accept </span>
                  </div>
                )}
                {data.delivery_locations?.map((item) => {
                  return (
                    <Fragment key={item.tempId}>
                      <h5> Delivery Details</h5>
                      {/* <div className="sctd-box-one">
                        <h6>
                          Delivery Price: <span>Free</span>
                        </h6>
                      </div> */}
                      <div className="sct-delivery-details">
                        <div className="item__scroll">
                          {item.locations.map((location, idx) => (
                            <div className="sct-dd-container" key={idx}>
                              <div className="sctd-box-two">
                                <div className="box-item-one">
                                  <h5> Location </h5>
                                  <h6>{item.city.address} </h6>
                                </div>
                                <div className="box-item-two">
                                  <h5> Delivery Mode</h5>
                                  <h6>
                                    {" "}
                                    {location.delivery_mode === "PICK_UP"
                                      ? "pick up"
                                      : "door delivery"}
                                  </h6>
                                </div>
                                <div className="box-item-three">
                                  <h5> Delivery Address </h5>
                                  <h6> {location.address} </h6>
                                </div>
                                <div className="box-item-four">
                                  <h5> Delivery Fee </h5>
                                  <h6> {location.delivery_fee} </h6>
                                </div>
                              </div>
                              {/* <div className="sct-dd-footer">
                                <FaPen />
                              </div> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </Fragment>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BusinessSummaryModal;
