import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  FaCartArrowDown,
  FaEnvelope,
  FaStar,
  FaBell,
  FaPlus,
  FaMoneyCheck,
  FaHistory,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import { Link, useLocation } from "react-router-dom";
import { Rating, RatingView } from "react-simple-star-rating";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import profileImg from "../../../assets/img/Ellipse 357.png";
import bannerImg from "../../../assets/img/Rectangle 2096.png";
import greeting from "../../../utils/greeting";
import { useSelector } from "react-redux";
import OrderItem from "./components/order item";
import usePrivateSellerDashboard from "../../../hooks/privateSellerDashboard";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
import ReviewItem from "./components/reviewItem";
import Notifications from "./components/notifications";
import qs from "query-string";
import ReactStars from "react-rating-stars-component";
import Loader from "../../loader";

const DashboardBodyDV = () => {
  const testArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [rating, setRating] = useState(0);

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    //logic
  };
  const { allOrdersData, handleGetAllOrders, handleFetchReviews, allReviews } =
    usePrivateSellerDashboard();

  useEffect(() => {
    setRating(allOrdersData?.data?.results?.[0]?.product?.page?.rating);
  }, [allOrdersData?.data?.results?.[0]?.product?.page?.rating]);

  const rate = allOrdersData?.data?.results?.[0]?.product?.page?.rating;

  const { search } = useLocation();

  const { id } = qs.parse(search);

  useEffect(() => {
    handleGetAllOrders();
    id && handleFetchReviews(id);
  }, []);

  const currentUser = useSelector((state) => state.currentUser);
  console.log(allOrdersData, "REView");
  return (
    <div className="dashboard-body-container">
      <div className="dbc-header">
        <h1 className="welcome-title">
          {greeting()} <span>{currentUser?.user.first_name} </span>
        </h1>
        <div className="cl-btn-box">
          <Link
            to={`/privateseller/createlistings/?id=${id}`}
            className="cl-btn"
          >
            Create Listing
          </Link>
        </div>
      </div>

      <div className="dbc-card">
        <h2> Dashboard </h2>
        <div className="dbc-card-body">
          <div className="dbc-container-one">
            {/* -------- NOTIFICATION CARD --------*/}
            <Notifications />
          </div>
          <div className="dbc-container-four">
            <div className="dbc-container-two">
              {/* -------- ORDER REQUEST CARD --------*/}
              <div className="order-request-card">
                <div className="purple-card-header">
                  <h3>
                    <span>
                      <FaCartArrowDown />
                    </span>
                    Order Request
                  </h3>
                </div>
                <div className="or-card-body">
                  {allOrdersData?.loading && <Loader />}
                  {allOrdersData &&
                    allOrdersData?.data?.results.map((orderItem, idx) => (
                      <OrderItem
                        handleGetAllOrders={handleGetAllOrders}
                        data={orderItem}
                      />
                    ))}
                  <div className="orc-footer">
                    <button className="orc-btn">view all</button>
                  </div>
                </div>
              </div>

              <div className="dbc-container-three">
                {/* -------- RATING CARD --------*/}

                <div className="ratings-card">
                  <div className="purple-card-header">
                    <h3>
                      <span>
                        <FaStar />
                      </span>
                      Ratings
                    </h3>
                  </div>
                  <div className="rt-card-body">
                    <div className="profile-rating-container">
                      <div className="cpb-container">
                        {rating ? (
                          <CircularProgressbarWithChildren
                            value={rating && rating}
                            maxValue={5}
                          >
                            <h4>{`${rating}`}</h4>
                            <p> out of 5 </p>
                          </CircularProgressbarWithChildren>
                        ) : (
                          <p>No ratings made</p>
                        )}
                      </div>
                      <div className="rating-bar-container">
                        <div className="rbc-star">
                          {rating && (
                            <ReactStars
                              count={5}
                              value={rating ? rating : 0}
                              size={24}
                              edit={false}
                              activeColor="orange"
                              emptyColor="gray"
                              className="rating-star"
                            />
                          )}

                          <h6 className="mb-0">{rate}</h6>
                        </div>
                        {allOrdersData?.data?.results?.[0]?.product?.page
                          ?.review_count && (
                          <div className="rbc-review">
                            <p>
                              {
                                allOrdersData?.data?.results?.[0]?.product?.page
                                  ?.review_count
                              }{" "}
                              reviews
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* -------- REVIEWS CARD --------*/}

                <div className="reviews-card">
                  <h3>Reviews</h3>
                  {allReviews?.loading && <Loader />}
                  {allReviews?.data?.results?.map((reviewItem, id) => (
                    <ReviewItem
                      reviewItem={reviewItem}
                      handleFetchReviews={handleFetchReviews}
                    />
                  ))}
                  {allReviews?.data?.results?.length === 0 && (
                    <p>No reviews made</p>
                  )}
                  {allReviews?.data?.results?.length > 10 && (
                    <div className="rvc-footer">
                      <button className="rvcf-btn">view all</button>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* -------- SHORTCUT CARD --------*/}

            <div className="dbc-container-five">
              <div className="dbc-banner-container">
                <div className="dbc-banner-title">
                  <h1>
                    What would you <br />
                    like to do today?
                  </h1>
                </div>
                <img src={bannerImg} alt="" />
              </div>
              <div className="shortcuts-box">
                <>
                  <h6>Shortcuts</h6>
                </>
                <div className="sc-box-items">
                  <div>
                    <Link to={`/privateseller-requested/?id=${id}`}>
                      <FaPlus /> Manage Listing{" "}
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaCartArrowDown /> View Orders{" "}
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaMoneyCheck /> Checkout{" "}
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaHistory /> View History{" "}
                    </Link>
                  </div>

                  <div>
                    <Link to="">
                      <Refferal /> View Refferal{" "}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBodyDV;
