import React, {  useState, useEffect, useContext  } from "react";
import ModalContext from '../../../hooks/modalContexts'
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import "./index.scss"
import Frame from "../../../assets/svg/frame.svg"
import PayPal from "../../../assets/svg/paypal-color-large(1).svg"
import Edit from "../../../assets/svg/edit.svg"
import { RiBankLine} from "react-icons/all"
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import CurrentPayPalCard from "./Paypal/PaypalCard"
import WalletEdit from "./EditWallet/EditPage"

const WalletHistory = () => {
const [activeTab, setActiveTab] = useState("paypal");
const [withDrawalPage, setWithDrawalPage] = useState("general");
const [editPaypalPage, setEditPaypalPage] = useState("false");

// EditPage tabs state
const [activeEditTab, setActiveEditTab] = useState("bank");
const changeEditTabPaypal = () => {
    setActiveEditTab("paypal");
}
const changeEditTabBank = () => {
    setActiveEditTab("bank");
}

// Onclick edit payapal page state (inside withdrawalPage paypal card)
const handlePaypalPage = () => {
  setWithDrawalPage("paypaledit")
  setActiveEditTab("paypal");
}

// Done button on bank and paypal edit (inside editpage)
const handleBackToWithDrawal = () => {
  setWithDrawalPage("general")
}


const {
  meta, getCardNumberProps, getExpiryDateProps, getCVCProps
} = usePaymentInputs();

const {handleWalletModal} = useContext(ModalContext)

  return(
    <>
    {withDrawalPage === "general" &&
    <div className="wallet-cards-cont">
      <div className="top-cards">
        <div className="balance">
          <div className="withdraw-icon">
            <img src={Frame} />
          </div>
          <div className="withdrawal-content">
            <div className="withdraw-text">
              <p>Balance</p>
            </div>
            <h3 className="cash">$1000</h3>
            <h3 className="earning">Total earnings on commission</h3>
          </div>
        </div>

        <div className="balance withdrawable">
          <div className="withdraw-icon">
            <img src={Frame} />
          </div>
          <div className="withdrawal-content">
            <div className="withdraw-text">
              <p>Withdrawable Balance</p>
            </div>
            <h3 className="cash">$1000</h3>
            <h3 className="earning">Withdrawable from commission</h3>
          </div>
        </div>
      </div>

      <div className="wallet-icons">

        <div className="wallet-icons-left">
          <p className="with-to">Withdrawal to</p>
            <div className="img-icons">
              <img
                  className={activeTab === "paypal" ? "active" : "img"}
                  src={PayPal}
                  onClick={() => {
                  setActiveTab("paypal");
                  }}
                />
                <div
                  className={activeTab === "bank" ? "paypal-active" : "paypal-cont"}
                  onClick={() => {
                  setActiveTab("bank");
                  }}
                  >
                  <div className="bank">
                    <RiBankLine />
                    <p className='act'>Bank Account</p>
                  </div>
                </div>
            </div>
        </div>

        <div className="form">
          <p>Enter amount</p>
            <form className="input">
              <input
                type="number"
                placeholder="$1000"
                />
            </form>
        </div>
      </div>

      {activeTab === "paypal" &&
      <div className="card-section">
          <CurrentPayPalCard handlePaypalPage={handlePaypalPage} />
        <div className="details">
          <div className="text-details">
            <h5>Billing Address</h5>
            <p>No. 6 brand new street</p>
          </div>
          <div className="text-details">
            <h5>Town Country</h5>
            <p>No. 6 brand new street</p>
          </div>
          <div className="text-details">
            <h5>Email Address</h5>
            <p>address@email.com</p>
          </div>
        </div>
      </div>
      }
      {activeTab === "bank" &&
        <div className="card-section">
          <div className="bank-section">
          <div className="bank-details">
            <div className="bank-text-details">
              <h5>Bank Name</h5>
              <p>No. 6 brand new street</p>
            </div>
            <div className="bank-text-details">
              <h5>Account Holders Name</h5>
              <p>No. 6 brand new street</p>
            </div>
            <div className="bank-text-details">
              <h5>Transit code</h5>
              <p>address@email.com</p>
            </div>
          </div>

          <div className="bank-details">
            <div className="bank-text-details">
              <h5>BSwift/BIC</h5>
              <p>10945</p>
            </div>
            <div className="bank-text-details">
              <h5>Account Number</h5>
              <p>1034876282</p>
            </div>
            <div className="bank-text-details">
              <h5>Account Number</h5>
              <p>address@email.com</p>
            </div>
          </div>

          <div className="bank-details">
            <div className="bank-text-details">
              <h5>Bank Country/City</h5>
              <p>No. 6 brand new street</p>
            </div>
            <div className="bank-text-details">
              <h5>Home Address</h5>
              <p>No. 6 brand new street</p>
            </div>
            <div className="bank-text-details">
              <h5>Postal Code</h5>
              <p>No. 6 brand new street</p>
            </div>
          </div>
          <div className="img-edit"
            onClick={() => {
            setWithDrawalPage("walletedit")
            setActiveEditTab("bank")
            }}
            >
            <img src={Edit} />
          </div>

        </div>
      </div> }

      <div className="wit-btn">
        <button
          onClick={() =>{
            handleWalletModal('ADD_WALLET_TO_DISPLAY', 'true')
          }}
          >Withdraw</button>
      </div>

    </div>
    }
    {withDrawalPage === "walletedit" && <WalletEdit handleBackToWithDrawal={handleBackToWithDrawal} activeEditTab={activeEditTab} setActiveEditTab={setActiveEditTab} /> }
    {withDrawalPage === "paypaledit" && <WalletEdit handleBackToWithDrawal={handleBackToWithDrawal} activeEditTab={activeEditTab} setActiveEditTab={setActiveEditTab} /> }

    </>
  )
}

export default WalletHistory
