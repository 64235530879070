import React from "react";
import Styled from "styled-components";
import {
  Settings,
  RssFeed,
  PhotoSizeSelectActual,
  Bookmark,
  PeopleOutline,
} from "@material-ui/icons";

import { colors } from "../../color";
import { NavLink } from "react-router-dom";

const Sidebardiv = Styled.div`
    background: ${colors.COMPANY_COLOR};
    height: 300px;
    width: 100%;
    border-radius: 10px;
`;

export const Ul = Styled.ul`
  height: 100%;
  li {
    list-style: none;
    padding-top: 13%;
    color: white;
    font-size: 14px;
    cursor: pointer;
    &:hover {
      color: ${colors.ORANGE};
    }
  }

`;

const style = {
  fontSize: "30px",
  paddingRight: "10px",
};
export const activeClass = "active";
export const Sidelink = Styled((props) => (
  <NavLink {...props} activeClassName={activeClass} />
))`
  color: white;
  font-wight: 500;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  &:hover {
    color: ${colors.ORANGE};
  }
  &.${activeClass} {
     color: ${colors.ORANGE};
  }
`;
const nav_data = [
  {
    name: "Feeds",
    to: "/dashboard/",
    icon: <RssFeed style={style} />,
  },
  {
    name: "Gallery",
    to: "/dashboard/gallery/",
    icon: <PhotoSizeSelectActual style={style} />,
  },
  {
    name: "Settings",
    to: "/dashboard/settings/",
    icon: <Settings style={style} />,
  },
  {
    name: "Referral",
    to: "/dashboard/",
    icon: <PeopleOutline style={style} />,
  },
  {
    name: "Saved",
    to: "/dashboard/",
    icon: <Bookmark style={style} />,
  },
];
class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Sidebardiv className="mt-4">
        <Ul className="pt-1" style={{ flexDirection: "column" }}>
          {nav_data.map((data, idx) => (
            <li key={idx}>
              <Sidelink
                exact={true}
                to={data.to}
                style={{ textDecoration: "none" }}
              >
                {data.icon}
                {data.name}
              </Sidelink>
            </li>
          ))}
        </Ul>
      </Sidebardiv>
    );
  }
}
export default SideBar;
