import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Routes } from "./routes";
import { Provider } from "react-redux";
import "./app.scss";
import { LoadScript } from "@react-google-maps/api";

// store
import store from "./appstore/store";

// protected route
import ProtectedRoute from "./pages/protectedroute";

// persistGate for data persistence
import { PersistGate } from "redux-persist/integration/react";
import HomePage from "../src/components/homepage";
import "bootstrap/dist/css/bootstrap.min.css";
import ErrorBoundary from "./pages/errorBoundary";
import { ExploreProvider } from "./hooks/exploreContext";
import { MessageProvider } from "./hooks/messageContext";
import config from "./config/config.json";
function App() {
  return (
    <ErrorBoundary>
      <LoadScript
        googleMapsApiKey={config["map-key"]}
        libraries={["geometry", "places"]}>
        <Provider store={store().store}>
          <PersistGate loading={null} persistor={store().persiststore}>
            <ExploreProvider>
              <MessageProvider>
                <div className="App">
                  <BrowserRouter>
                    <Switch>
                      {Routes.map((data, i) =>
                        data.public ? (
                          <Route
                            key={i}
                            path={data.path}
                            component={data.component}
                          />
                        ) : (
                          <ProtectedRoute
                            key={i}
                            path={data.path}
                            component={data.component}
                          />
                        )
                      )}
                      <Route exact path="/" component={HomePage} />
                    </Switch>
                  </BrowserRouter>
                </div>
              </MessageProvider>
            </ExploreProvider>
          </PersistGate>
        </Provider>
      </LoadScript>
    </ErrorBoundary>
  );
}

export default App;
