import { useEffect, useState } from "react";

const useGetLocation = () => {
  const [state, setState] = useState();
  const [err, setErr] = useState();

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(setState, setErr);
    } else {
      //   x.innerHTML = "Geolocation is not supported by this browser.";
      console.log("no support for nav");
    }
  }, []);
  return {
    state,
    err,
    setState,
  };
};

export default useGetLocation;
