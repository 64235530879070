import { useState } from "react";
import "./index.scss";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";

const CustomDropdown = ({ items, value, setValue, onChange }) => {
  const [isOpen, setOpen] = useState(false);
  // const [selectedItem, setSelectedItem] = useState(items[0].label);

  const toggleDropdown = (e) => setOpen(!isOpen);
  
  return (
    <div className="dropdown-container">
      <div className="dd-dropdown">
        <div className="dropdown">
          <div
            className="dropdown-header"
            onChange={onChange}
            value={value}
            onClick={toggleDropdown}
          >
            {/* {selectedItem} */}

            {value}

            <FaChevronDown className="dropdown-icon" />
          </div>
        </div>
        {isOpen && (
          <div className="dropdown-body ">
            {items.map((item, idx) => (
              <div
                className="dropdown-item"
                key={idx}
                onClick={(e) => {
                  setValue(item.label);
                  setOpen();
                  //   onChange(item.label);
                }}
              >
                {item.label}
                {/* <div className="dd-icon">{item.icon}</div> */}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomDropdown;
