const BagHappy = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="bag-happy" transform="translate(-172 -188)">
        <path
          id="Vector"
          d="M0,0A3.517,3.517,0,0,0,3.5,3.5,3.517,3.517,0,0,0,7,0"
          transform="translate(180.5 202.25)"
          fill="none"
          stroke="#fbb03b"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M3.62,0,0,3.63"
          transform="translate(177.19 190)"
          fill="none"
          stroke="#fbb03b"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M0,0,3.62,3.63"
          transform="translate(187.19 190)"
          fill="none"
          stroke="#fbb03b"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M0,2C0,.15.99,0,2.22,0H17.78C19.01,0,20,.15,20,2c0,2.15-.99,2-2.22,2H2.22C.99,4,0,4.15,0,2Z"
          transform="translate(174 193.85)"
          fill="none"
          stroke="#fbb03b"
          stroke-width="1.5"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M0,0,1.41,8.64C1.73,10.58,2.5,12,5.36,12h6.03c3.11,0,3.57-1.36,3.93-3.24L17,0"
          transform="translate(175.5 198)"
          fill="none"
          stroke="#fbb03b"
          stroke-linecap="round"
          stroke-width="1.5"
        />
        <path
          id="Vector-6"
          data-name="Vector"
          d="M0,0H24V24H0Z"
          transform="translate(172 188)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};

export default BagHappy;
