import React, { useState, useContext, useEffect } from "react";
import "../index.scss";
import { FaPen, FaUser, FaPlus, FaSave } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/all";
import ModalContext from "../../../hooks/modalContexts";

const BookingPersonnel = ({
  personnel,
  idx,
  removePersonnel,
  userPersonnel,
  handleBookingModal,
  setPersonnelForm,
  editPersonnel,
  handleGetSinglePersonnel,
  singlePersonnelData,
  setIsEditPersonnel,
  setToggleEditPersonnel,
  setToggleEditPersonnelPost,
  isEditPersonnel,
  handleDeletePagePersonnel,
  deletePagePersonnelData,
  setUserPersonnel
}) => {
  const {
     serviceToggleSubmit,
    setServiceToggleSubmit
    // personnelInitialFormState2
  } = useContext(ModalContext);

  const editPersonnelData1 = (id) => {
    let newEditPersonnel = userPersonnel.find((elem) => {
      return elem.id === id && elem;
    });
    setPersonnelForm(newEditPersonnel);
    handleBookingModal("ADD_DATA", "true");
    // setIsEditPersonnel(newEditPersonnel);
    setToggleEditPersonnel(true);
    console.log(newEditPersonnel);
    console.log(isEditPersonnel);
    // console.log(personnelForm);
  };

  const editPersonnelData2 = async (personnel) => {
    await handleGetSinglePersonnel(personnel);
    // setPersonnelForm({ ...singlePersonnelData?.data });
    // setToggleEditPersonnel(true);
    setToggleEditPersonnelPost(true);
    console.log("single personnell", singlePersonnelData?.data);
  };
  const handleDeletePagePersonnel2 = async (personnel) => {
    await handleDeletePagePersonnel(personnel);
    removeFields(idx)
  };

  const removeFields = (idx) => {
    const newPersonnel = [...userPersonnel];
    newPersonnel.splice(idx, 1);
    setUserPersonnel(newPersonnel);
  };

  useEffect(() => {
    if (singlePersonnelData?.data) {
      setPersonnelForm({
        ...singlePersonnelData?.data,
        ["username"]: `${singlePersonnelData?.data?.user?.username}`,
      });
      handleBookingModal("ADD_DATA", singlePersonnelData?.data);
    }
    return () => {};
  }, [singlePersonnelData?.data, singlePersonnelData?.loading]);

  useEffect(() => {
    if (deletePagePersonnelData?.data?.status === true) {
      // removePersonnel()
    }
  }, [deletePagePersonnelData?.data]);

  return (
    <>
      {/*---------  PERSONEL CARD CONTENTS ------------------*/}
      <div className="cbc-cbcc-body" key={idx}>
        <div className="ccb__contents_ps">
          <div className="ps-box-one">
            <FaUser />
            <h3> {personnel.username || personnel.user.username}</h3>
          </div>

          <div className="ps-box-two">
            <h3>{personnel.title}</h3>
            <>
              <FaPen
                className="edit-icon"
                onClick={() => {
                  // serviceToggleSubmit
                  //   ? editPersonnelData1(personnel.id) :
                  editPersonnelData2(personnel.id);
                }}
              />

              <IoMdCloseCircleOutline
                className="close-icon"
                data-index={idx}
                aria-label={`remove personnel ${idx}`}
                onClick={() =>
                  serviceToggleSubmit
                    ? removePersonnel
                    :  handleDeletePagePersonnel2(personnel.id)
                }
              />
            </>
            {/* {serviceToggleSubmit ? (
              <>
                <FaPen
                  className="edit-icon"
                  onClick={() => editPersonnelData1(personnel.id)}
                />

                <IoMdCloseCircleOutline
                  className="close-icon"
                  data-index={idx}
                  aria-label={`remove personnel ${idx}`}
                  onClick={removePersonnel}
                />
              </>
            ) : (
              <>
                <FaPen
                  className="edit-icon"
                  onClick={() => editPersonnelData2(personnel.id)}
                />

                <IoMdCloseCircleOutline
                  className="close-icon"
                  data-index={idx}
                  aria-label={`remove personnel ${idx}`}
                  onClick={removePersonnel}
                />
              </>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
};

export default BookingPersonnel;
