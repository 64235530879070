import Search from "../../../components/Search/Search";
import BusinessLogo from "../../../assets/img/blogo.png";
import Location from "../../../assets/svg/location";
import MapLocation from "../../../assets/svg/mapLocation";
import ArrowUp from "../../../assets/svg/arrowUp";
import ShopImg from "../../../assets/img/shopImg.png";
import People from "../../../assets/svg/people";
import Forward from "../../../assets/svg/forward";
import Bookmark from "../../../assets/svg/bookmark";
import { Eye } from "../../../assets/svg/PostCardIcon";
import CircleIcon from "../../../assets/svg/circleIcon";
import ModalContext from "../../../hooks/modalContexts";
import { useContext, useState } from "react";
import NewLocation from "../../../assets/svg/newLocation";
import StarIcon from "../../../assets/svg/star";
import ThreeDots from "../../../assets/svg/threeDots";
import Refer from "../../../assets/svg/refer.png";
import ReactStars from "react-stars";
import moment from "moment";
import useActivity from "../../../hooks/activity";

const BookingItem = ({ booking, active, handleViewFull }) => {
  const { handleQueueItemModal } = useContext(ModalContext);
  const [pop, setPop] = useState(false);
  const { updateBookingStatusData, handleUpdateBooking } = useActivity();
  return (
    <div
      onClick={() => {
        handleQueueItemModal("ADD_QUEUE_ITEM", booking);
        console.log("clicked");
      }}
      className="queueItem"
    >
      <div className="topPart">
        <div className="profile">
          <img className="logo" src={BusinessLogo} alt="logo" />
          <div>
            <p>{booking?.page?.name}</p>
            <p className="bottom">{booking?.page?.username}</p>
          </div>
        </div>
        <img
          src={
            booking?.page?.featured_image
              ? booking?.page?.featured_image
              : ShopImg
          }
          alt=""
        />
      </div>
      <div className="bottomPart">
        <div onClick={(e) => e.stopPropagation()} className="other">
          <div className="queueItemReview">
            <div className="top">
              <div className="stars">
                <ReactStars
                  count={5}
                  size={17}
                  color2={"#FBB03B"}
                  value={booking?.service?.rating}
                />
              </div>
              <span>{booking?.service?.rating}</span>
            </div>
            <p>{booking?.service?.rating_count} reviews</p>
          </div>
          <div className="location">
            <div className="top">
              <NewLocation />
              <p>{booking?.page?.location?.address}</p>
            </div>
            <p>
              Posted on{" "}
              {moment(booking?.service?.created_at).format("DD MMM YYYY")}
            </p>
          </div>
          <div className="tag">
            <p>{booking?.page?.type} PAGE</p>
          </div>
          <div onClick={() => setPop(!pop)} className="threeDotDivs">
            <ThreeDots fill={"#490057"} height={20} width={8} />
            {pop && (
              <div className="popUp">
                <a href="">
                  <Forward />
                  <span>Share</span>
                </a>
                <a href="">
                  <img src={Refer} alt="" />
                  <span>Refer</span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="queueActions">
          <div className="leftActivity">
            <div className="queueDetails">
              <div className="queueTitle">
                <p className="topTitle">{booking.service.title}</p>
                {active !== "referrals" && (
                  <p className="bottomTitle">
                    Booked on{" "}
                    {moment(booking?.booking_date).format("DD MMMM YYYY")}
                  </p>
                )}
              </div>

              <div className="time">
                {active !== "referrals" && (
                  <>
                    <div className="queueTime">
                      <p>Time</p>
                      <p>{moment(booking?.booking_date).format("hh : ss a")}</p>
                    </div>
                    <div className="price">
                      <p>$ {booking?.service?.booking_fee?.amount}</p>
                    </div>
                    <div
                      className="status"
                      style={{
                        background:
                          (booking.condition === "Incomming request" &&
                            "rgba(6, 108, 186, .2)") ||
                          (booking.condition === "active" &&
                            "rgba(0, 193, 58, .2)") ||
                          (booking.condition === "Pending approval" &&
                            "rgba(251, 176, 59, .2)") ||
                          (booking.condition === "Closed" &&
                            "rgba(193, 0, 0, .2)"),
                      }}
                    >
                      {booking.condition === "Incomming request" && (
                        <CircleIcon fill="#066CBA" />
                      )}
                      {booking.condition === "Active" && (
                        <CircleIcon fill="#00C13A" />
                      )}
                      {booking.condition === "Pending approval" && (
                        <CircleIcon fill={"#FBB03B"} />
                      )}
                      {booking.condition === "Closed" && (
                        <CircleIcon fill={"#C10000"} />
                      )}
                      <p
                        className={
                          (booking.condition === "Incomming request" &&
                            "blue") ||
                          (booking.condition === "closed" && "red") ||
                          (booking.condition === "active" && "green") ||
                          (booking.condition === "Pending Approval" && "carton")
                        }
                      >
                        {booking?.condition}
                      </p>
                    </div>
                  </>
                )}
                {active === "referrals" && (
                  <>
                    <div>
                      <p>Price</p>
                      <p>$20</p>
                    </div>
                    <div>
                      <p>Quantity</p>
                      <p>200</p>
                    </div>
                    <div>
                      <p>Total Commission Earned</p>
                      <p>$20</p>
                    </div>
                    <div>
                      <p>No.Referred</p>
                      <p>20</p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rightActivity">
            <div
              className={`topActions ${
                active === "referrals" && "whenReferrals"
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {/* {!booking.incoming &&<>
                            {active === 'order' || active ==='referrals' && <Eye fill={'#490057'}/>}
                                <People />
                                <Forward />
                                {active !=='referrals' && <Bookmark />}
                            </>} */}
              {active !== "referrals" && booking?.condition === "active" && (
                <div
                  onClick={() => {
                    handleQueueItemModal("ADD_QUEUE_ITEM", booking);
                    console.log("clicked");
                  }}
                  className="bookingCreate"
                >
                  {/* <Eye fill= {'#fbb03b'}/> */}
                  <div className="full">View full</div>
                  <div className="checkout">Checkout</div>
                </div>
              )}
              {active !== "referrals" && booking?.condition === "closed" && (
                <div className="bookingCreate">
                  <div
                    onClick={() => {
                      handleQueueItemModal("ADD_QUEUE_ITEM", booking);
                      console.log("clicked");
                    }}
                    className="disabledFull"
                  >
                    View full
                  </div>
                </div>
              )}
              {/* {!booking.incoming && active !=='referrals' &&<div className="requeue">
                                <span>
                                    Queue again
                                </span>
                            </div>} */}
              {active !== "referrals" && booking.condition === "Closed" && (
                <div className="bookingCreate">
                  <div
                    className="full"
                    onClick={() => {
                      handleQueueItemModal("ADD_QUEUE_ITEM", booking);
                      console.log("clicked");
                    }}
                  >
                    View full
                  </div>
                  <div className="disabledFull">Checkout</div>
                </div>
              )}
              {booking?.condition === "Incomming request" && (
                <div className="permission">
                  <a
                    className="view_full"
                    onClick={() =>
                      handleQueueItemModal("ADD_QUEUE_ITEM", booking)
                    }
                  >
                    View full
                  </a>
                  <a
                    className="accept create"
                    onClick={() =>
                      handleUpdateBooking({ status: "ACCEPT", id: booking?.id })
                    }
                  >
                    Accept
                  </a>
                  <a
                    className="reject"
                    onClick={() =>
                      handleUpdateBooking({
                        status: "DECLINE",
                        id: booking?.id,
                      })
                    }
                  >
                    Decline
                  </a>
                </div>
              )}
            </div>
            <div className="bottomOptions"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BookingItem;
