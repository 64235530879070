const DiscountIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24">
    <g id="discount-shape" transform="translate(-364 -316)">
      <path
        id="Vector"
        d="M1.985,12.635l-1.52-1.52a1.606,1.606,0,0,1,0-2.26l1.52-1.52a1.845,1.845,0,0,0,.47-1.13V4.055a1.6,1.6,0,0,1,1.6-1.6H6.2a1.845,1.845,0,0,0,1.13-.47L8.855.465a1.606,1.606,0,0,1,2.26,0l1.52,1.52a1.845,1.845,0,0,0,1.13.47h2.15a1.6,1.6,0,0,1,1.6,1.6V6.2a1.845,1.845,0,0,0,.47,1.13l1.52,1.52a1.606,1.606,0,0,1,0,2.26l-1.52,1.52a1.845,1.845,0,0,0-.47,1.13v2.15a1.6,1.6,0,0,1-1.6,1.6h-2.15a1.845,1.845,0,0,0-1.13.47l-1.52,1.52a1.606,1.606,0,0,1-2.26,0l-1.52-1.52a1.845,1.845,0,0,0-1.13-.47H4.055a1.6,1.6,0,0,1-1.6-1.6v-2.15A1.8,1.8,0,0,0,1.985,12.635Z"
        transform="translate(366.004 318.025)"
        fill="none"
        stroke="#00c13a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,6,6,0"
        transform="translate(373 325)"
        fill="none"
        stroke="#00c13a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H24V24H0Z"
        transform="translate(364 316)"
        fill="none"
        opacity="0"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(378 330)"
        fill="none"
        stroke="#00c13a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M.495.5H.5"
        transform="translate(373 325)"
        fill="none"
        stroke="#00c13a"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
      />
    </g>
  </svg>
);

export default DiscountIcon;
