import { Bar } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";


const BarChartBooking = ({
  customer,
  bookingChart,
}) => {
  const chartDate = bookingChart?.customer?.chart?.sort(function(a, b){return a.month-b.month})
  const barChartData = {
    labels: chartDate?.map(item => {
      if(item.month === 1){
        return "Jan"
      }
      if(item.month === 2){
        return "Feb"
      }
      if(item.month === 3){
        return "Mar"
      }
      if(item.month === 4){
        return "Apr"
      }
      if(item.month === 5){
        return "May"
      }
      if(item.month === 6){
        return "Jun"
      }
      if(item.month === 7){
        return "Jul"
      }
      if(item.month === 8){
        return "Aug"
      }
      if(item.month === 9){
        return "Sep"
      }
      if(item.month === 10){
        return "Oct"
      }
      if(item.month === 11){
        return "Nov"
      }
      if(item.month === 12){
        return "Dec"
      }
    }),
    
    datasets: [
      {
        label: "Men",
        fill: true,
        // fillColor: gradient,
        lineTension: 0.1,
        backgroundColor: "rgba(251, 176, 59, 1)",
        barPercentage: 0.5,
        barThickness: 15,
        maxBarThickness: 15,
        minBarLength: 20,
        pointStyle: "circle",
        pointRadius: 6,
        data: bookingChart?.customer?.chart?.map(item => item.count), 
      },
      {
        label: "Women",
        fill: true,
        // fillColor: gradient,
        lineTension: 0.1,
        backgroundColor: "rgba(255, 223, 174, 1)",
        barPercentage: 0.5,
        barThickness: 15,
        maxBarThickness: 15,
        minBarLength: 20,
        pointStyle: "circle",
        pointRadius: 6,
        data: customer?.age?.women?.map(item => item), 
      },
      {
        label: "New Custormers",
        fill: true,
        // fillColor: gradient,
        lineTension: 0.1,
        backgroundColor: "rgba(255, 240, 217, 1)",
        barPercentage: 0.5,
        barThickness: 15,
        maxBarThickness: 15,
        minBarLength: 20,
        pointStyle: "circle",
        pointRadius: 6,
        pointBorderWidth: 0.5,
        data: bookingChart?.customer?.chart?.map(item => item.count), 
      },
    ],
  };
  
  const barChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        //   backgroundColor: "rgba(255, 255, 255, 1)",
        //   bodyColor: "rgba(126, 132, 163, 1)",
        callbacks: {},
      },
    },
    scales: {
      x: {
        stacked: true,
        gridLines: {
          display: false,
        },
        grid: {
          offset: false,
        },
        ticks: {
          color: "rgba(73, 0, 87, 1)",
          fontSize: 14,
        },
      },
      y: {
        display: false,
        beginAtZero: true,
      },
    },
  
    animation: {
      easing: "easeOutBack",
  
      y: {
        duration: 3000,
        from: 500,
      },
    },
  };

  
  
  return (
    <div>
      <Bar
        data={barChartData}
        options={barChartOptions}
        height={250}
        width={700}
      />
    </div>
  );
};
export default BarChartBooking;
