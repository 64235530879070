import { useContext, useState, useEffect } from "react";
import "./index.scss";
import ModalContext from "../../../../hooks/modalContexts";
import CloseBtn from "../../../../components/closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import SalesGrowthChart from "../../../Analytics/components/salesGrowthGenLikes";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import { useLocation, useHistory, Link  } from "react-router-dom";
import {
  chartData2,
  chartOptions2,
} from "../../../../components/Analytics/chartDetails";
import LikesAreaChart from "../../../../components/Analytics/Charts/ModalCharts/modalChartGenLikes";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import useAnalytics from "../../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../../hooks/exploreContext'
import LoaderPurple from "../../../../assets/loaders/LoaderPurple"

const LikesAnalyticModal = () => {
  /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const { search } = useLocation();
  const { id } = qs.parse(search);

  const { setLikesAnalyticModal } = useContext(ModalContext);

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)

  const {
    analyticsGeneralData,
    handleGetAnalyticsGeneral,

    // General Chart
    analyticsGeneralChartData,
    handleGetAnalyticsGeneralChart,

     analyticsLikesListingData,
    handleGetAnalyticsLikesListing,

  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsGeneral(id)
    handleGetAnalyticsLikesListing(id)
  }, [])

  // General
  const general = analyticsGeneralData?.data

  const generalLikes =   analyticsLikesListingData?.data?.results

  const testArray = [1, 2, 3, 4, 5, 6, 7];

  return (
    <div
      className="generalAnalyticModal"
      onClick={(e) => setLikesAnalyticModal(false)}
    >
      <div className="generalAnalyticModalContainer">
        <div
          onClick={(e) => setLikesAnalyticModal(false)}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="generalAnalyticModalContainered"
        >
          <div className="generalAnalyticModal-header">
            <h4> Likes </h4>
            <div className="datepicker-container">
            <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsGeneral(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="generalAnalyticModal-body">
            <div className="gam-content-one">
              <div className="item__scroll">
              {analyticsLikesListingData.loading && analyticsLikesListingData.data?.count !== 0 && <LoaderPurple />}
                {generalLikes?.map((data, idx) => (
                  <div className="lcob-details">
                    <div className="details-box-one">
                      <h4>{idx + 1}</h4>
                      <img src={data?.image.map(item => item.file ? item.file : itemImg)} alt="" />
                      <div className="details-box-two">
                        <h5>{data?.name}</h5>
                          <h6>
                            Price: <span>${data?.price}</span>
                          </h6>
                      </div>
                    </div>

                    <div className={data?.summary.summary.direction === "UP" ? "lcob-chart rate-text-green" : "lcob-chart rate-text-red"}>
                       <p>
                        {data?.summary.summary.percentage}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                        </p>
                      <div>
                        <LikesAreaChart data={data}  />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="amb-statistics">
              <SalesGrowthChart title="Likes" general={general} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LikesAnalyticModal;
