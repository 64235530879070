/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxOutlineBoxComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 22.97 22.97" {...props}>
    <defs>
      <style>{".a_outline{fill:#fff;}.b_outline{fill:none;opacity:0;}"}</style>
    </defs>
    <g transform="translate(0)">
      <path
        className="a_outline"
        d="M9.17,6.329a.714.714,0,0,1-.364-.1L.355,1.343A.728.728,0,0,1,.1.357.719.719,0,0,1,1.083.1L9.17,4.779,17.209.128A.728.728,0,0,1,18.2.386a.728.728,0,0,1-.258.986L9.543,6.234A.9.9,0,0,1,9.17,6.329Z"
        transform="translate(2.315 6.4)"
      />
      <path
        className="a_outline"
        d="M.718,10.116A.723.723,0,0,1,0,9.4V.718A.723.723,0,0,1,.718,0a.723.723,0,0,1,.718.718V9.4A.723.723,0,0,1,.718,10.116Z"
        transform="translate(10.767 11.284)"
      />
      <path
        className="a_outline"
        d="M9.915,20.577a4.917,4.917,0,0,1-2.335-.546L2.469,17.189A5.209,5.209,0,0,1,0,12.988V7.58a5.223,5.223,0,0,1,2.469-4.2L7.58.546a5.271,5.271,0,0,1,4.671,0l5.111,2.843a5.209,5.209,0,0,1,2.469,4.2V13a5.223,5.223,0,0,1-2.469,4.2l-5.111,2.833A4.917,4.917,0,0,1,9.915,20.577Zm0-19.142A3.527,3.527,0,0,0,8.279,1.8L3.168,4.642A3.83,3.83,0,0,0,1.436,7.58v5.407a3.863,3.863,0,0,0,1.732,2.948l5.111,2.843a3.841,3.841,0,0,0,3.273,0l5.111-2.843A3.847,3.847,0,0,0,18.4,12.988V7.58a3.863,3.863,0,0,0-1.732-2.948L11.552,1.79A3.65,3.65,0,0,0,9.915,1.436Z"
        transform="translate(1.57 1.196)"
      />
      <path className="b_outline" d="M0,0H22.97V22.97H0Z" />
    </g>
  </svg>
);

export default VuesaxOutlineBoxComponent;
