import { useContext, useState, useEffect } from 'react'
import ModalContext from '../../../../../hooks/modalContexts'
import './index.scss'
import profilePic from '../../../../../assets/img/connectProfilePic.png'
import { Heart } from '../../../../../assets/svg/PostCardIcon'
import MsgIcon from '../../../../../assets/svg/msgIcon'
import Phone from '../../../../../assets/svg/phone'
import { FaTimes, FaHeart} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import {BsThreeDotsVertical} from "react-icons/bs"
import { IoMdClose } from "react-icons/all"
import MessageFilter from "./MessageFilter/MessageFilter"
import ProfileMenu from "./MessageFilter/ProfileMenu"
import ExploreContext  from '../../../../../hooks/exploreContext'
import useConnect from "../../../../../hooks/connectHooks"

const MessageMeModal = () => {
    const {messageModal, handleMessageModal} = useContext(ModalContext)
    const {
          messageData,
          setMessageData,
          currentProfile,
          setConnectedState,
          setRequestConnected,
          setRequestConnectedHome,
        } = useContext(ExploreContext)

        const {
            handleRespondConnectRequest,
            respondConnectRequestData,
            singleProfileData,
            handleDisconnectConnectRequest,
            disconnectConnectRequestData,
            handleGetConnectRequestConnectedTrue,
            handleGetConnectRequestProfileConnectedTrue,
            connectRequestConnectedTrueData,
            connectRequestProfileConnectedTrueData
          } = useConnect();

       const handleDisconnect = () => {
        handleDisconnectConnectRequest(messageData?.id)      
       }

    console.log(disconnectConnectRequestData?.data)

    // Remove disconnected card from the UI
    useEffect(() => {
      if (disconnectConnectRequestData?.data?.status === true) {
        console.log(disconnectConnectRequestData?.data)
        handleGetConnectRequestConnectedTrue();
        handleGetConnectRequestProfileConnectedTrue(currentProfile)
        setConnectedState(state => state?.filter(state => state?.id !== disconnectConnectRequestData?.data?.id && state?.status !== true && true))
        setRequestConnected(state => state?.filter(state => state?.id !== disconnectConnectRequestData?.data?.id && state?.status !== true && true))
        setRequestConnectedHome(state => state?.filter(state => state?.id !== disconnectConnectRequestData?.data?.id && state?.status !== true && true))
    
        // history.push("/connect")
        handleMessageModal('RESET_MESSAGE_TO_DISPLAY')
      }
    }, [disconnectConnectRequestData?.data]);

    useEffect(() => {
      if(messageData){
        handleGetConnectRequestConnectedTrue();
        handleGetConnectRequestProfileConnectedTrue(currentProfile)
      }
    }, [messageData])

    if(!messageData) return <div>Loading</div>
    return(
      <div
        onClick={()=>{handleMessageModal('RESET_MESSAGE_TO_DISPLAY')}}
      className="acceptModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="acceptModalContainer"
       >
          <div
            onClick={()=>{handleMessageModal('RESET_MESSAGE_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
            <div className="profileArea">
                <div className="connectProfile">
                  <img src={
                    messageData?.connect_profile?.page?.featured_image ? messageData?.connect_profile?.page?.featured_image : profilePic                   
                    } alt=''
                    />
                    <div className="name">
                        <h4>
                          {messageData?.connect_profile?.page?.name}
                        </h4>
                        <p>
                            @{messageData?.connect_profile?.page?.username}
                        </p>
                        <p>
                          {messageData?.connect_profile?.page?.description}
                        </p>
                    </div>
                    <div className="icons">
                        <FaHeart className={
                          messageData?.connect_profile?.page?.is_like === true
                          ? 'heart favey'
                          : 'heart'
                        }/>
                        <ProfileMenu className="dropdown" handleDisconnect={handleDisconnect} />
                    </div>
                </div>
                <div className="description">
                    <h4>
                        {messageData?.connect_profile?.title}
                    </h4>
                    <p className='address'>
                        {messageData?.connect_profile?.page?.location?.address}
                    </p>
                    <p>
                      {messageData?.connect_profile?.page?.description}
                    </p>
                </div>

                <div className="contact">
                    <p>
                        <MsgIcon />
                        <span>
                              {messageData?.connect_profile?.page?.email}
                        </span>
                    </p>
                    <p>
                        <Phone />
                        <span>
                        {messageData?.connect_profile?.page?.phone_number}
                        </span>
                    </p>
                </div>
                <div className="locations">
                    <h4>
                        Service Locations:
                    </h4>
                    <ul className="service">
                      {messageData?.connect_profile?.service_locations?.map((location) => (
                      <li>
                        {location?.address}
                      </li>
                    ))
                      }
                    </ul>
                    </div>
                </div>
                <div className="form">
                  <div className="submittedNote">
                      <p>
                          Note
                      </p>
                      <div className="describe-form">
                        <div className="text-content">
                          {messageData?.note}
                        </div>
                      </div>
                  </div>
                  <div className="permissions connect-message">
                        <button className="connect-accept-color"
                          onClick={()=>{handleMessageModal('RESET_MESSAGE_TO_DISPLAY')}}
                          >
                              Message
                        </button>
                  </div>
                </div>
          </div>
      </div>
    )
}
export default MessageMeModal
