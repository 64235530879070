import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import './index.scss';
import { FaDownload } from 'react-icons/fa';
import { MdDeleteSweep } from 'react-icons/md'
import FeedContainer from '../../../../components/FeedContainer';
import JobDown from './jobDown';
import greeting from "../../../../utils/greeting";
import { FilterOptions } from './ReceivedOptions';
import DropdownIndicator from '../../../../components/cards/jobCards/FontAwesomeIndicator';
import Select from 'react-select';
import CustomCheckBox from "./CheckBoxes/CheckBox";
import { showToast } from "../../../../components/ReactToast";
import { Toaster } from "react-hot-toast";

import useExplore from "../../../../hooks/explore";
import ExploreContext from "../../../../hooks/exploreContext";
import Loader from "../../../../components/loader";

const RecievedApplication = ({ image, name, username }) => {
  const history = useHistory();
  const dispatch = useDispatch()
  useEffect(()=>{
    dispatch(toggleRightBar(false))
},[])

const { handleGetJobPosts, handleGetJobPostApplications } = useExplore();
const { allJobPosts, jobPostApplications, jobDeleteComplete, setJobDeleteComplete, jobPostApplicationsLoading } = useContext(ExploreContext);
const [selectedPost, setSelectedPost] = useState();

useEffect(() => {
  handleGetJobPosts({ have_received_application: true });
}, [])

useEffect(() => {
  setSelectedPost(allJobPosts?.results ? allJobPosts?.results[0]?.id : null);
}, [allJobPosts]);

const handleChangeSelectedPost = (id) => {
  setSelectedPost(id);
};

useEffect(() => {
  handleGetJobPostApplications(selectedPost);
}, selectedPost);

useEffect(() => {
  if (jobDeleteComplete === true) {
    showToast('success', "Application Deleted Successfully");
    setTimeout(() => {
      setJobDeleteComplete(false);
      handleGetJobPosts({ have_received_application: true });
    }, 2000);
  }
}, [jobDeleteComplete])

  return (
    <FeedContainer>
      <Toaster />
      <section className='job__manage'>
        <div className='job__manage-greeting'>
          {greeting()} <span>Jonathan</span>
        </div>
        <section className='job__manage-container'>
          <h1>Recieved Applications</h1>
          <div className='job__manage-container-wrap'>
            <div className='job__manage-container-wrap-head'>
              <h2 className="recieved">Jobs</h2>
                <div className="filter">
                  <p>Filter</p>
                    <Select
                        components={{
                          IndicatorSeparator: ()=> null,
                          DropdownIndicator
                        }}
                        options={FilterOptions}
                        className="react-select-container"
                        classNamePrefix="react-select"
                        placeholder={"All"}
                        />
                  </div>
              <h2 className="total_apps">Total Applications 69</h2>
            </div>
            <div className='job__manage-container-wrap-flex'>
              <div className='job__manage-container-wrap-flex-left'>
                <ul>
                  {
                    allJobPosts?.count>0 &&
                    allJobPosts?.results.map((post, index) => (
                      <>
                        <li className={post.id === selectedPost ? `active` : "" } key={index} onClick={() => handleChangeSelectedPost(post.id)}>
                          {post?.title}
                        </li>
                      </>
                    ))
                  } 
                </ul>
              </div>
              <div className='job__manage-container-wrap-flex-right'>
                <div className='job__manage-container-wrap-flex-right-job'>
                  <div className='job__manage-container-wrap-flex-right-job-news'>
                    <div>
                      <h2>New</h2>
                    </div>
                    <div className='job__manage-container-wrap-flex-right-job-news-icon'>
                      <div className='bin'>
                        <MdDeleteSweep />
                      </div>
                      <div className='get'>
                        <FaDownload />
                      </div>
                    </div>
                  </div>
                  <div className='job__manage-container-wrap-flex-right-job-app'>
                    <CustomCheckBox
                      className="custom_checkbox"
                      style={{
                        minWidth: 18,
                        height: 18,
                        borderColor: "var(--text-grey)",
                        }}
                      />
                    <label htmlFor=''>Applicants</label>
                  </div>
                  <div className="job__manage-container-wrap-flex-right-job-unread">
                    {
                      jobPostApplicationsLoading ?
                        <Loader text="Loading..." />
                        :
                        jobPostApplications?.count > 0 &&
                        jobPostApplications?.results.map((application, index) => (
                          <JobDown key={index} application={application}>
                            {' '}
                          </JobDown>
                        ))
                    }
                    {/* {Data.map((user) => {
                      return (
                        <JobDown key={user.id} {...user}>
                          {' '}
                        </JobDown>
                      )
                    })} */}
                  </div>
                  <div className='job__manage-container-wrap-flex-right-job-read'>
                   {/* {ReadData.map((user) => {
                     return (
                       <Readjob key={user.id} {...user}>
                         {' '}
                       </Readjob>
                     )
                   })} */}
                 </div>
                </div>
              </div>
            </div>
          </div>
          <Link
              onClick={history.goBack}
              className='job__manage-container-back-btn'
             >
             <h5>Back</h5>
          </Link>
        </section>
      </section>
    </FeedContainer>
  )
}

export default RecievedApplication
