import Photo from "../../../assets/img/Component 672 –1.PNG";

const Checkbox = ({ id, type, name, handleChange, isChecked, style }) => {
  return (
    <div
      id={id}
      name={name}
      type={type}
      onChange={handleChange}
      checked={isChecked}
      style={{
        display: "inline-block",
        border: "1px solid var(--dark-purple-bg)",
        minWidth: "10px",
        height: "10px",
        borderRadius: 1,
        backgroundColor: isChecked ? "var(--dark-purple-bg)" : "transparent",
        cursor: "pointer",
        ...(style && style),
      }}
      className="customCheckBox"
    ></div>
  );
};

export default Checkbox;

export const catalogues = [
  {
    id: "001",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "002",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "003",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "004",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "005",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "006",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "007",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "008",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "009",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
  {
    id: "010",
    name: "Coperate shirts for men",
    image: Photo,
    date: "Posted on 2nd feb 2021",
    price: "$20",
  },
];
