import { useState, useEffect, useContext } from "react";
import GeneralAnalytics from "./AnalyticsTabs/General/generalAnalytics";
import ListingAnalytics from "./AnalyticsTabs/Listing/listingAnalytics";
import BookingAnalytics from "./AnalyticsTabs/Booking/bookingAnalytics";
import QueueAnalytics from "./AnalyticsTabs/Queue/queueAnalytics";
import JobsAnalytics from "./AnalyticsTabs/Jobs/jobsAnalytics";
import ConnectAnalytics from "./AnalyticsTabs/Connect/connectAnalytics";
import ReferralAnalytics from "./AnalyticsTabs/Referral/referralAnalytics";
import FeedContainer from "../../components/FeedContainer";
import { useLocation, useHistory, Link  } from "react-router-dom";
import "./analytics.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import useAnalytics from "../../hooks/Analytics";
import qs from "query-string";
import usePages from "../../hooks/createPage"
import { Interaction } from "chart.js";
import ExploreContext  from '../../hooks/exploreContext'

const BusinessSellerAnalytics = () => {
  const [activeTab, setActiveTab] = useState("general");
  /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [generalStartDate, setGeneralStartDate] = useState("")
  const [generalEndDate, setGeneralEndDate] = useState("")

  const [AnalyticsOthers, setAnalyticsOthers] = useState()

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)

  console.log("Start Date", startDate)
  console.log("End Date", endDate)
  console.log("Gen Start Date", generalStartDate)
  console.log("Gen End Date", generalEndDate)

// useEffect(() => {
  
//   if(startDate < endDate || startDate > endDate){
//     setGeneralStartDate("")
//   }

// }, [startDate])

  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));  
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  const { search } = useLocation();
  const { id } = qs.parse(search);

  const {
    singlePageData,
    handleGetSinglePage,
  } = usePages();

   useEffect(() => {
    handleGetSinglePage(id);
  }, [id]);

  const {
    analyticsGeneralData,
    handleGetAnalyticsGeneral,

    // Genral Chart
    analyticsGeneralChartData,
    handleGetAnalyticsGeneralChart,

      // Listing Interactions
     analyticsListingInteraction,
    handleGetAnalyticsListingInteraction,

      // Listing Chart
     analyticsListingChartData,
    handleGetAnalyticsListingChart,

    analyticsListingOthersData,
    handleGetAnalyticsListingOthers,

    // Purchase listing
    analyticsPurchaseListingData,
    handleGetAnalyticsPurchaseListing,

// Interacted Listing
    analyticsInteractedListingData,
    handleGetAnalyticsInteractedListing,

// Listing AdConverted 
    analyticsAdConvertedListingData,
    handleGetAnalyticsAdConvertedListing,

    analyticsDemographicListingData,
    handleGetAnalyticsDemographicListing,
    analyticsOrderCompletionListingData,
    handleGetAnalyticsOrderCompletionListing,

    analyticsClicksListingData,
    handleGetAnalyticsClicksListing,

     analyticsLikesListingData,
    handleGetAnalyticsLikesListing,

     analyticsSharesListingData,
    handleGetAnalyticsSharesListing,

    // Booking tab

  // Booking Interaction
    analyticsBookingInteraction,
    handleGetAnalyticsBookingInteraction,

    // Booking Chart
     analyticsBookingChartData,
    handleGetAnalyticsBookingChart,


    analyticsBookingQueueCompletionData,
    handleGetAnalyticsBookingQueueCompletion,
    analyticsBookingOthersData,
    handleGetAnalyticsBookingOthers,
    analyticsBookingCompletionData,
    handleGetAnalyticsBookingCompletion,

    // Booking AdConverted
    analyticsBookingAdConvertedData,
    handleGetAnalyticsBookingAdConverted,

    // Queue Interaction
    analyticsQueueInteraction,
    handleGetAnalyticsQueueInteraction,


    // Queue
    analyticsQueueChartData,
    handleGetAnalyticsQueueChart,


    analyticsQueueAdConvertedData,
    handleGetAnalyticsQueueAdConverted,

    // Job Interaction
    analyticsJobInteraction,
    handleGetAnalyticsJobInteraction,

    // Job Chart
    analyticsJobChartData,
    handleGetAnalyticsJobChart,

      // post job
    analyticsPostJobData,
    handleGetAnalyticsPostJob,

    // Connect
    analyticsConnectInteraction,
    handleGetAnalyticsConnectInteraction,
     
    analyticsConnectChartData,
    handleGetAnalyticsConnectChart,

    analyticsConnectProfileData,
    handleGetAnalyticsConnectProfile,

    analyticsConnectCustomerConvertedData,
    handleGetAnalyticsConnectCustomerConverted,

    // Referral
    analyticsReferralChartData,
    handleGetAnalyticsReferralChart,

    analyticsReferralInteraction,
    handleGetAnalyticsReferralInteraction,

    analyticsQueueServicesData,
    handleGetAnalyticsQueueServices,


    analyticsQueueCompletionData,
    handleGetAnalyticsQueueCompletion,

    analyticsGeneralDateData,
    handleGetAnalyticsGeneralDate,

  } = useAnalytics()


useEffect(() => {
  handleGetAnalyticsGeneral(id)
  handleGetAnalyticsListingOthers(id)

  // Purchase listing
  handleGetAnalyticsPurchaseListing(id)

  // Interacted Listing
  handleGetAnalyticsInteractedListing(id)

// AdConverted
  handleGetAnalyticsAdConvertedListing(id)

  handleGetAnalyticsDemographicListing(id)
  handleGetAnalyticsOrderCompletionListing(id)

  // General Chart
  handleGetAnalyticsGeneralChart(id)

  // Listing Interactions
   handleGetAnalyticsListingInteraction(id)

    // Listing Chart
    handleGetAnalyticsListingChart(id)


  // Booking
  // Booking Interaction
  handleGetAnalyticsBookingInteraction(id)

    handleGetAnalyticsClicksListing(id)
    handleGetAnalyticsLikesListing(id)
    handleGetAnalyticsSharesListing(id)

  // Booking Chart
  handleGetAnalyticsBookingChart(id)

  // Booking AdConv
  handleGetAnalyticsBookingAdConverted(id)

// Queue Chart
  handleGetAnalyticsQueueChart(id)

  // Queue interaction
   handleGetAnalyticsQueueInteraction(id)

  //  Queue AdConv
  handleGetAnalyticsQueueAdConverted(id)

  // JOB Interaction
   handleGetAnalyticsJobInteraction(id)

    // Job Chart
   handleGetAnalyticsJobChart(id)

  //  post job
  handleGetAnalyticsPostJob(id)

  handleGetAnalyticsBookingOthers(id)
  handleGetAnalyticsBookingQueueCompletion(id)

  // Connect
    
    handleGetAnalyticsConnectInteraction(id)
     
    handleGetAnalyticsConnectChart(id)
   
    handleGetAnalyticsConnectProfile(id)

    handleGetAnalyticsConnectCustomerConverted(id)

    // Referral
    handleGetAnalyticsReferralChart(id)
    handleGetAnalyticsReferralInteraction(id)

  // Queue
  // handleGetAnalyticsQueueOthers(id)
  // handleGetAnalyticsQueueCompletion(id)

}, [])

// useEffect(() => {
//   setAnalyticsOthers(analyticsListingOthersData.data?.sales_growth.months)
// }, [analyticsListingOthersData?.data])


console.log(analyticsListingOthersData.data?.sales_growth.months)

// General
const general = analyticsGeneralData?.data
console.log(general)

// Genenral chart
const generalChart = analyticsGeneralChartData?.data

// Listing Interactions
const listingInteractions = analyticsListingInteraction?.data

// Listing Chart
const listingChart = analyticsListingChartData?.data

  // Purchase listing
const purchaseListing = analyticsPurchaseListingData?.data?.results

// Interacted
const interactedListing = analyticsInteractedListingData?.data?.results

// AdConverted
const adConverted = analyticsAdConvertedListingData?.data?.results

// Booking Interaction
const bookingInteraction = analyticsBookingInteraction?.data

// Booking Chart
const bookingChart =  analyticsBookingChartData?.data

// Booking AdConv
const bookingAdConverted = analyticsBookingAdConvertedData?.data?.results

// Queue chart
const queueChart = analyticsQueueChartData?.data

// Queue interaction
const queueInteraction = analyticsQueueInteraction?.data

// Queue adConv
const queueAdConverted = analyticsQueueAdConvertedData?.data?.results

// Job
const jobChart =  analyticsJobChartData?.data

// Job Interaction
const jobInteraction = analyticsJobInteraction?.data

// post jobs
const postJobs = analyticsPostJobData?.data?.results

// connect interaction
const connectInteraction =   analyticsConnectInteraction?.data
const connectProfile =    analyticsConnectProfileData?.data?.results
const connectConverted =    analyticsConnectCustomerConvertedData?.data?.results
const connectChart =  analyticsConnectChartData?.data

const generalClicks = analyticsClicksListingData?.data?.results

const generalLikes =   analyticsLikesListingData?.data?.results

const generalShares =  analyticsSharesListingData?.data?.results


// Referal
const referralInteraction = analyticsReferralInteraction?.data

const referralChart = analyticsReferralChartData?.data

// Sales Growth
const others = analyticsListingOthersData?.data?.sales_growth

// Demography
const demographicListing = analyticsDemographicListingData?.data?.age

const orderCompletion = analyticsOrderCompletionListingData?.data

console.log(demographicListing?.months)

// Booking -======-
const bookingAnalyticsTab = analyticsBookingOthersData?.data
const customer = analyticsBookingQueueCompletionData?.data

// Queue =====
// const queueOthers = analyticsQueueOthersData?.data
// const queueCompletion = analyticsQueueCompletionData?.data

  return (
    <FeedContainer>
      <div className="analytics-container">
        <div className="ay-header">
          <h1 className="welcome-title">
            {greeting()} <span>{singlePageData?.data?.name.split(" ")[0]} </span>
          </h1>
        </div>
        <div className="ay-card">
          <div className="ayc-header">
            <h5 className="sub-title"> Analytics</h5>
          </div>
          <div className="ayc-contents">
            <div className="ayc-select-box">
              <div className="tabs">
                {/* ----------  nav tab -------- */}
                <ul className="nav-tab">
                  <li
                    className={activeTab === "general" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("general");
                    }}
                  >
                    General
                  </li>
                  <li
                    className={activeTab === "listing" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("listing");
                    }}
                  >
                    Listing
                  </li>
                  <li
                    className={activeTab === "booking" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("booking");
                    }}
                  >
                    Booking
                  </li>
                  <li
                    className={activeTab === "queue" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("queue");
                    }}
                  >
                    Queue
                  </li>
                  <li
                    className={activeTab === "jobs" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("jobs");
                    }}
                  >
                    Jobs
                  </li>
                  <li
                    className={activeTab === "connect" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("connect");
                    }}
                  >
                    Connect
                  </li>
                  <li
                    className={activeTab === "referral" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("referral");
                    }}
                  >
                    Referral
                  </li>
                </ul>
              </div>
              {activeTab === "general" && 
              <div className="datepicker-container">
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsGeneral(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
              </div>
              }

            {activeTab === "listing" && 
              <div className="datepicker-container">
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsListingInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
              </div>
              }
              {activeTab === "booking" && 
              <div className="datepicker-container">
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsBookingInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
              </div>
              }

            {activeTab === "queue" && 
              <div className="datepicker-container">
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsQueueInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
              </div>
              }

            {activeTab === "jobs" && 
              <div className="datepicker-container">
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsJobInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
              </div>
              }

            {activeTab === "connect" && 
              <div className="datepicker-container">
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsConnectInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
              </div>
              }

            {activeTab === "referral" && 
              <div className="datepicker-container">
                <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsReferralInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
              </div>
              }

            
            </div>
            <div className="outlet">
              {/* ---------------- tab contents -------------- */}

              {activeTab === "general" &&
              <GeneralAnalytics 
              general={general} 
              generalChart={generalChart}
              generalShares={generalShares}
              generalLikes={generalLikes}
              generalClicks={generalClicks}
              startDate={startDate}
              endDate={endDate}
              
              />
              }

              {activeTab === "listing" && <ListingAnalytics
              AnalyticsOthers={AnalyticsOthers}
              others={others}
              demographicListing={demographicListing}
              orderCompletion={orderCompletion}
              listingInteractions={listingInteractions}
              listingChart={listingChart}
              purchaseListing={purchaseListing}
              interactedListing={interactedListing}
              adConverted={adConverted}

              />
              }

              {activeTab === "booking" && <BookingAnalytics
              bookingAnalyticsTab={bookingAnalyticsTab}
              customer={customer}
              bookingInteraction={bookingInteraction}
              bookingChart={bookingChart}
              bookingAdConverted={bookingAdConverted}
              />

              }

              {activeTab === "queue" && <QueueAnalytics
              // queueOthers={queueOthers}
              // queueCompletion={queueCompletion}
              queueChart={queueChart}
              queueInteraction={queueInteraction}
              queueAdConverted={queueAdConverted}
              />}

              {activeTab === "jobs" && <JobsAnalytics 
              jobInteraction={jobInteraction}
              jobChart={jobChart}
              postJobs={postJobs}
              />}
              {activeTab === "connect" && <ConnectAnalytics 
              connectInteraction={connectInteraction}
              connectProfile={connectProfile}
              connectConverted={connectConverted}
              connectChart={connectChart}
              />}
              {activeTab === "referral" && <ReferralAnalytics 
              referralChart={referralChart}
              referralInteraction={referralInteraction}
              />}

            </div>
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};

export default BusinessSellerAnalytics;
