import { useEffect, useState } from "react";

const CustomCheckBox = ({
  onChange = () => {},
  style,
  size = 10,
  customIsCheckedColor = "var(--dark-purple-bg)",
}) => {
  const [isChecked, setIsChecked] = useState(false);

  // console.log(isChecked);

  // useEffect(() => {
  //   onChange({ value: isChecked });
  // }, [isChecked]);

  return (
    <div
      style={{
        display: "inline-block",
        border: "1px solid var(--dark-purple-bg)",
        minWidth: size,
        height: size,
        borderRadius: 1,
        //  backgroundColor: isChecked ? customIsCheckedColor : "transparent",
        cursor: "pointer",
        ...(style && style),
      }}
      className="customCheckBox"
      onClick={(e) => {
        setIsChecked(!isChecked);
        onChange();
      }}
    ></div>
  );
};

export default CustomCheckBox;
