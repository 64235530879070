import { useCallback, useContext, useState } from "react";
// import LightBox from "react-image-video-lightbox";
import "./style.scss";
import ModalContext from "../../hooks/modalContexts";
// import Slick from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
import LightGallery from "lightgallery/react";
import "lightgallery/scss/lightgallery.scss";
import "lightgallery/scss/lg-zoom.scss";
import classNames from "classnames";
import { useRef } from "react";
const MediaBox = ({ media = [] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const lightGallery = useRef(null);
  const handleSkip = (num) => {
    // lightGallery.current.goToNextSlide();
    if (num) {
      lightGallery.current.slide(num);
      return;
    }
    lightGallery.current.goToNextSlide();
  };
  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);
  return (
    <LightGallery
      onInit={onInit}
      elementClassNames={classNames(`media-box`, {
        two: media.length === 2,
        three: media.length === 3,
        "morethan-three": media.length > 3,
      })}
      download={false}
      onAfterSlide={({ index }) => {
        if (media.length > 3) {
          media.length === index && lightGallery.current.slide(0);
        }
      }}>
      {media?.map((data, idx) => {
        return (
          <div
            data-src={data.file}
            className="main-media-container-wrap"
            key={data.id}>
            {data.type === "image" ? (
              <img src={data.file} alt={""} />
            ) : (
              <video src={data.file} controls />
            )}
          </div>
        );
      })}
      {media.length > 3 && !isOpen && (
        <div
          className="more-photo-indicator"
          style={{ background: `url(${media})` }}
          onClick={(e) => handleSkip(3)}>
          + {media.length - 3}
        </div>
      )}
    </LightGallery>
  );
};

export default MediaBox;
