import itemImg from "../../../../../assets/img/Rectangle 1890.PNG";
import photoPic from "../../../../../assets/img/Ellipse 357.png";
import ViewAll from "./viewAll";
import { useState } from "react";
import moment from "moment";
import "./index.scss";
const PreorderItem = ({ data, idx, processPreOrder, cancelPreOrder }) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div
      className="bkr-cbc-card"
      style={{
        background: data?.pre_order_condition_met
          ? "white"
          : "rgb(254, 246, 234)",
      }}
    >
      <div className="bkr-cbcc-body">
        <div className="item__detail-box">
          <div className="cb-1">
            <label className="check-label" htmlFor="001">
              {idx}
            </label>
          </div>
        </div>
        <div
          style={{
            width: !data?.pre_order_condition_met && "95%!important",
          }}
          className="cbcc-item__box cbcc-item__box2"
        >
          <img
            src={
              data?.product?.gallary?.[0]?.file
                ? data?.product?.gallary?.[0]?.file
                : itemImg
            }
            alt=""
          />
          <div className="item-detail-one item-detail-one2">
            <h4>{data?.title}</h4>
            <h5>{`$${data?.deposit_amount}`}</h5>
          </div>
          <div className="item-detail-pic" onClick={() => setOpen(!isOpen)}>
            {data?.orders[0] && (
              <div>
                <h6>Pre ordered by</h6>
                <div className="pic-box">
                  {data?.orders.map((item, idx) => {
                    if (idx < 3) {
                      // alert("clivk");

                      return (
                        <img
                          src={
                            item?.owner?.profile_image
                              ? item?.owner?.profile_image
                              : photoPic
                          }
                          alt=""
                        />
                      );
                    }
                  })}
                  <p> view all </p>
                </div>
              </div>
            )}
          </div>
          <div className="item-detail-two item-detail-two1">
            <div>
              <h6>Pre order closing time</h6>
              <h5>
                Date:{" "}
                <span>
                  {moment(data?.product?.pre_order_exp).format("DD MMM YYYY")}
                </span>
              </h5>
            </div>
          </div>

          <div className="item-detail-two item-detail-two2">
            <div>
              <h6>Pre order Qty</h6>
              <p>
                {" "}
                {data?.quantity_ordered} of {data?.risk_min_qty}{" "}
              </p>
            </div>
          </div>

          <div className="item-detail-two item-detail-two3">
            <div>
              <h6>Status</h6>
              <p>
                {" "}
                {data?.pre_order_condition_met
                  ? "Criteria met"
                  : "Criteria not met"}{" "}
              </p>
            </div>
          </div>
        </div>
        {data?.pre_order_condition_met && (
          <div className="item-btn-box item-btn-box2">
            <button
              className="btn-accept"
              onClick={() => processPreOrder(data?.id)}
            >
              {" "}
              Process
            </button>
            <button
              className="btn-decline"
              onClick={() => cancelPreOrder(data?.id)}
            >
              {" "}
              Cancel
            </button>
          </div>
        )}
      </div>
      {isOpen && <ViewAll data={data?.orders} />}
    </div>
  );
};
export default PreorderItem;
