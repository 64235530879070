import { useLocation } from "react-router-dom";
import EditQueue from "./editQueue";
import PreviewQueue from "./previewQueue";
import QueueContainer from "./queueContainer";
import QueueHistory from "./queueHistory";
import QueueList from "./queueList";
import QueueRequest from "./queueRequest";

export const queue = "/queue_list";
export const preview_queue = "/preview_queue";
export const edit_queue = "/edit_queue";
export const queue_history = "/queue_history";
export const queue_request = "/queue_request";
export const manage_queue = "/manage_queue";

const Queue = () => {
  const { pathname } = useLocation();
  let endpath = pathname.substring(pathname.lastIndexOf('/'));
  let lastpath = pathname.substring((endpath.lastIndexOf('/'))+1, pathname.lastIndexOf('/'));
  let queuePage = lastpath.substring((lastpath.lastIndexOf('/'))+1);

  return (
    <QueueContainer>
      {queuePage === "queue-list" && <QueueList />}
      {queuePage === "queue_history" && <QueueHistory />}
      {queuePage === "queue_request" && <QueueRequest />}
      {queuePage === "edit_queue" && <EditQueue />}

      {pathname === queue && <QueueList />}
      {pathname === queue_history && <QueueHistory />}
      {pathname === edit_queue && <EditQueue />}
      {pathname === preview_queue && <PreviewQueue />}
      {pathname === queue_request && <QueueRequest />}
    </QueueContainer>
  );
};
export default Queue;
