import { useEffect, useState } from "react";
import qs from "query-string";
import useReferral from "../../hooks/referrals";
import { useLocation } from "react-router";
import moment from "moment";
import Loader from "../../components/loader";

const Bookings = () => {
  const testArray = [
    1, 2, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
  ];
  const {
    handleGetProductAffiliates,
    productAffiliates,
    handleGetAffiliateCommissions,
    affiliateCommission,
  } = useReferral();
  const { search } = useLocation();

  const { id } = qs.parse(search);
  useEffect(() => {
    handleGetProductAffiliates({ pageId: id, serviceId: true });
  }, []);

  const [selectedAffiliate, setSelectedAffiliate] = useState();
  useEffect(() => {
    selectedAffiliate && handleGetAffiliateCommissions(selectedAffiliate);
  }, [selectedAffiliate]);
  const handleSum = () => {
    let arr = affiliateCommission?.data?.results?.map((data) => {
      return data?.commission;
    });
    return arr?.reduce((total, num) => {
      return total + num;
    });
    console.log(arr, "arr");
  };
  return (
    <div className="main">
      {productAffiliates?.data?.results?.length > 0 ? (
        <>
          <div className="left">
            <h1>Affiliate</h1>
            <div className="item__scroll">
              {productAffiliates?.data?.results?.length === 0 && (
                <p
                  style={{
                    padding: "0px 10px",
                    fontSize: "18px",
                  }}
                >
                  No affiliates
                </p>
              )}
              {productAffiliates?.loading && <Loader />}
              {productAffiliates?.data?.results?.map((data, idx) => (
                // add selected to class to indicate selected item
                <div
                  onClick={() => {
                    setSelectedAffiliate(data?.id);
                  }}
                  className={`names names-affliate ${
                    selectedAffiliate === data?.id && "selected"
                  }`}
                  key={idx}
                >
                  <h5>
                    {" "}
                    {data?.influencer?.full_name
                      ? data?.influencer?.full_name
                      : "A null name"}{" "}
                  </h5>
                </div>
              ))}
            </div>
          </div>
          <div className="right__contents">
            <div className="total">
              Total commission paid <span>${handleSum()}</span>
            </div>
            {affiliateCommission?.data?.results && (
              <div className="item__scroll">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Username</th>
                      <th>Quantity</th>
                      <th>Time</th>
                      <th>Commission</th>
                    </tr>
                  </thead>
                  <tbody>
                    {affiliateCommission?.data?.results?.map((data, idx) => (
                      <tr key={idx}>
                        <td>{idx + 1}</td>
                        <td>{data?.username}</td>
                        <td>{data?.quantity}</td>
                        <td>{moment(data?.time).format("DD MMM YYYY")}</td>
                        <td>${data?.commission}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </>
      ) : (
        <p
          style={{
            padding: "10px 0",
            fontSize: "18px",
          }}
        >
          No referral activity
        </p>
      )}
    </div>
  );
};

export default Bookings;
