import { DefaultSave } from "../../assets/leftBarIcons/VuesaxLinearArchiveMinusComponent";
import VuesaxOutlineHeartTickComponent from "../../assets/svg/VuesaxOutlineHeartTickComponent";

const Pages = ({ page, avatar }) => {
  return (
    <div className="item">
      <div className="avatar">
        <img src={page.avatar} alt="avatar" />
      </div>
      <div className="detail">
        <div className="name">{page.name}</div>
        <div className="state">{page.state}</div>
      </div>
      <div className="support">
        {" "}
        <VuesaxOutlineHeartTickComponent />
      </div>
      <div className="save">
        <DefaultSave />
      </div>
    </div>
  );
};

export default Pages;
