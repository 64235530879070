// export const authRoutes = [/interests/, /posts/,  /settings/, /auth\/user/, /preferences\/social/, /auth\/password\/change/, /utils\/types/];
export const authRoutes = [
  /interests/,
  /posts/,
  /settings/,
  /auth\/user/,
  /preferences\/social/,
  /auth\/password\/change/,
  /comments/,
  /products/,
  /users/,
  /uploads/,
  /users/,
  /page\-admins/,
  /pages/,
  /services/,
  /job-posts/,
  /orders/,
  /job-applications/,
  /pages/,
  /categories/,
  /bookings/,
  /orders/,
  /utils\/types/,
  /bookings/,
  /notifications/,
  /job-applications/,
  /personnels/,
  /connect-profile/,
  /connect-requests/,
  /job-applications/,
  /stocks/,
  /referrals/,
  /analytics/,
  /messenger/,
  /listings/,
];
