import React, { useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import JobHistoryBody from "../../../../components/BookingJobHistory/JobHistoryBody";
import FeedContainer from "../../../../components/FeedContainer";
import ModalContext from "../../../../hooks/modalContexts";
import "./JobHistory.scss";
import greeting from "../../../../utils/greeting";
import ExploreContext from "../../../../hooks/exploreContext";
import useExplore from "../../../../hooks/explore";

const JobHistory = () => {
  const history = useHistory();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(toggleRightBar(false));
  }, []);

  const { myJobPosts } = useContext(ExploreContext);
  const { handleGetMyJobPosts } = useExplore();

  useEffect(() => {
    handleGetMyJobPosts();
  }, []);

  return (
    <FeedContainer>
      <div className="job-history-container">
        <div className="bkr-header">
          <h1 className="welcome-title">
            {greeting()} <span>Jonathan </span>
          </h1>
        </div>
        <div className="history-card-container">
          <div className="card_header">
            <h5 className="sub-title"> Job History</h5>
            <div className="bkr-btn-box">
              <Link to="/create-job">
                <button className="create_job">Create Job</button>
              </Link>
            </div>
          </div>
          <JobHistoryBody />
          <Link onClick={history.goBack} className="back-btn">
            <h5>Back</h5>
          </Link>
        </div>
      </div>
    </FeedContainer>
  );
};

export default JobHistory;
