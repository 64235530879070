import Forward from "../../../assets/svg/forward";
import ModalContext from "../../../hooks/modalContexts";
import NewLocation from "../../../assets/svg/newLocation";
import BusinessLogo from "../../../assets/img/blogo.png";
import ShopImg from "../../../assets/img/shopImg.png";
import CircleIcon from "../../../assets/svg/circleIcon";
import { useContext, useState } from "react";
import StarIcon from "../../../assets/svg/star";
import ThreeDots from "../../../assets/svg/threeDots";
import Refer from "../../../assets/svg/refer.png";
import ReactStars from "react-stars";
import moment from "moment";

const JobItem = ({ job, handleViewFull }) => {
  const { handleMyJobItemModal, myJobItemModal } = useContext(ModalContext);
  const [pop, setPop] = useState(false);

  return (
    <div onClick={() => handleViewFull()} className="queueItem">
      <div className="topPart">
        <div className="profile">
          <img
            className="logo"
            src={
              job?.job_post?.page?.featured_image
                ? job?.job_post?.page?.featured_image
                : BusinessLogo
            }
            alt="logo"
          />
          <div>
            <p>{job?.job_post?.page?.name}</p>
            <p className="bottom">{job?.job_post?.page?.username}</p>
          </div>
        </div>
        <img
          src={
            job?.job_post?.gallary?.[0]?.file
              ? job?.job_post?.gallary?.[0]?.file
              : ShopImg
          }
          alt=""
        />
      </div>
      <div className="bottomPart">
        <div onClick={(e) => e.stopPropagation()} className="other">
          <div className="queueItemReview">
            <div className="top">
              <div className="stars">
                <ReactStars
                  count={5}
                  value={job?.job_post?.page?.rating}
                  edit={false}
                />
              </div>
              <span>{job?.job_post?.page?.rating}</span>
            </div>
            <p>{job?.job_post?.page?.review_count} reviews</p>
          </div>
          <div className="location">
            <div className="top">
              <NewLocation />
              <p>{job?.job_post?.location?.address}</p>
            </div>
            <p>
              Posted on{" "}
              {moment(job?.job_post?.created_at).format("DD MMMM YYYY")}
            </p>
          </div>
          <div className="tag">
            <p>{job?.job_post?.page?.type} PAGE</p>
          </div>
          <div onClick={() => setPop(!pop)} className="threeDotDivs">
            <ThreeDots fill={"#490057"} height={20} width={8} />
            {pop && (
              <div className="popUp">
                <a href="">
                  <Forward />
                  <span>Share</span>
                </a>
                <a href="">
                  <img src={Refer} alt="" />
                  <span>Refer</span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="queueActions">
          <div className="leftActivity jobs">
            <div className="queueDetails">
              <div className="queueTitle">
                <p className="topTitle">{job.job_post.title}</p>
                {
                  <p className="bottomTitle">
                    Applied on {moment(job?.applied_on).format("DD MMMM YYYY")}
                  </p>
                }
              </div>

              <div className="time">
                {
                  <>
                    <div className="queueTime">
                      <p>Time</p>
                      <p>{moment(job?.applied_on).format("hh : ss a")}</p>
                    </div>
                    <div className="jobType">
                      <p>Job type</p>
                      <p>{job.job_post?.type}</p>
                    </div>
                  </>
                }
              </div>
            </div>
          </div>
          <div className="rightActivity">
            <div className={`topActions`} onClick={(e) => e.stopPropagation()}>
              {/* {!queue.incoming &&<>
                            {active === 'order' || active ==='referrals' && <Eye fill={'#490057'}/>}
                                <People />
                                <Forward />
                                {active !=='referrals' && <Bookmark />}
                            </>} */}
              {
                <div
                  onClick={() =>
                    handleMyJobItemModal("ADD_JOB_TO_DISPLAY", job)
                  }
                  className="create"
                >
                  {/* <Eye fill= {'#fbb03b'}/> */}
                  <span>View full</span>
                </div>
              }
              {/* {!queue.incoming && active !=='referrals' &&<div className="requeue">
                                <span>
                                    Queue again
                                </span>
                            </div>} */}
              {job.incoming && (
                <div className="permission">
                  <a href="" className="accept create">
                    Accept
                  </a>
                  <a href="" className="reject">
                    Decline
                  </a>
                </div>
              )}
            </div>
            <div className="bottomOptions"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobItem;
