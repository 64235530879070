const BackArrow = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="30.456"
      height="13.975"
      viewBox="0 0 30.456 13.975">
      <path
        id="Vector"
        d="M29.285,5.806H4L7.809,2A1.171,1.171,0,0,0,6.154.34L.359,6.134a1.221,1.221,0,0,0-.266.406.057.057,0,0,1-.016.047A1.145,1.145,0,0,0,.344,7.821l5.81,5.81a1.169,1.169,0,0,0,1.656,0,1.178,1.178,0,0,0,0-1.656L3.983,8.149h25.3a1.171,1.171,0,1,0,0-2.343Z"
        fill="#490057"
      />
    </svg>
  );
};
export default BackArrow;
