import { useContext, useState, useEffect } from "react";
import "./orderModal.scss";
import ModalContext from "../../../../hooks/modalContexts";
import CloseBtn from "../../../../components/closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import TaskSquare from "../../../../assets/svg/taskSquare";
import SalesGrowthChart from "../../../Analytics/components/salesGrowthCancelledOrderModal";
import OrderCompletionModal from "../../../Analytics/components/orderCompletedListingDropdownModal";
import { useLocation } from "react-router-dom";
import useAnalytics from "../../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../../hooks/exploreContext'

const CancelledOrderModal = () => {
  /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const { setCancelledOrderModal } = useContext(ModalContext);

  const { search } = useLocation();
  const { id } = qs.parse(search);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)
  const {
   // Listing Interactions
   analyticsListingInteraction,
   handleGetAnalyticsListingInteraction,
  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsListingInteraction(id)
  }, [])

  const listingInteractions = analyticsListingInteraction?.data

  return (
    <div
      className="orderCompletionModal"
      onClick={(e) => setCancelledOrderModal(false)}
    >
      <div className="orderCompletionModalContainer">
        <div
          onClick={(e) => setCancelledOrderModal(false)}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="orderCompletionModalContainered"
        >
          <div className="orderCompletionModal-header">
            <h4> Cancelled Order </h4>
            <div className="datepicker-container">
              <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsListingInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="orderCompletionModal-body">
            <OrderCompletionModal
              image={<TaskSquare fill={"#C10000"} />}
              title="Cancelled Order"
              statPercent={listingInteractions?.cancel_order?.summary.percentage.toFixed(1) + "%"}
              statTime="in less than a week"
              statTotal={listingInteractions?.cancel_order?.total}
              bgColor="rgba(251,201,201,1.0)"
              bgColor2="rgba(193,0,0,0.7)"
              bgColor3="#C10000"
              upAndDown={listingInteractions?.cancel_order?.summary.direction === "UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
            />

            <div className="amb-statistics">
            <SalesGrowthChart title="Order Statistics" listingInteractions={listingInteractions} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CancelledOrderModal;
