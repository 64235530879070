import {
  UPDATE_COMMENT_LIKE,
  BULK_INSERT_COMMENTS,
  ADD_COMMENT,
  CLEAR_COMMENTS,
  INITIAL_COMMENTS,
  LIKE_COMMENT_REPLIES,
  ADD_REPLIES,
} from "./commentV2.types";

export const updateCommentLike = (payload) => ({
  type: UPDATE_COMMENT_LIKE,
  payload,
});
export const bulkInsertComment = (payload) => ({
  type: BULK_INSERT_COMMENTS,
  payload,
});
export const addComment = (payload) => ({
  type: ADD_COMMENT,
  payload,
});
export const clearComments = (payload) => ({
  type: CLEAR_COMMENTS,
  payload,
});
export const initialComments = (payload) => ({
  type: INITIAL_COMMENTS,
  payload,
});
export const likeCommentReplies = (payload) => ({
  type: LIKE_COMMENT_REPLIES,
  payload,
});
export const addCommentReplies = (payload) => ({
  type: ADD_REPLIES,
  payload,
});
