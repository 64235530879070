export const DefaultButton = ({
  children,
  text,
  customStyle,
  onClick = () => {},
  ...rest
}) => {
  return (
    <button
      style={{
        borderRadius: "15px",
        color: "var(--text-light)",
        backgroundColor: "var(--bg-orange)",
        borderColor: " transparent",
        padding: "5px",
        minWidth: "119px",
        ...customStyle,
      }}
      onClick={onClick}
      {...rest}>
      {text || children || "button"}
    </button>
  );
};
