import React, { createContext, useState, useContext, useEffect  } from "react";
import ModalContext from '../../../../../hooks/modalContexts'
import '../../ConnectCard.scss'
import { FaEye, FaEnvelope } from 'react-icons/fa'
import "../Cards.scss"
import person from '../../../../../assets/img/Ellipse 320.PNG';
import moment from "moment";

const ConnectCard = ({
  sent,
  handleRespondConnectRequest,
  handleHideProfile,
  hideProfileData,
  handleLike,
  retractModalData,
  setRetractModalData,
  retractConnectData,
  handleRetractConnect,
  handleGetConnectRequestSent,
  setCurrentId,
  setRectractedCard,
  retractConnectDataCard,
  handleRetractConnectCard,
  currentProfile,
  handleGetConnectProfileSuggested,
  retractSuccessModal,
  setRetractSuccessModal
}) => {
  const {handleRetractModal, handleRetractSuccessConnectModal} = useContext(ModalContext)


  // useEffect(() => {
  //   // console.log(retractConnectData.data);
  //   if(retractConnectData?.data){
  //     setRectractedCard(retractConnectData?.data)
  //     // handleRetractModal('RESET_RETRACT_TO_DISPLAY');
  //   }
  // }, [retractConnectData.data]);

  useEffect(() => {
    if(retractConnectDataCard?.data) {
      console.log("RETRACT from Card", retractConnectDataCard.data)
      setRetractSuccessModal(true)
  
    }
  }, [retractConnectDataCard?.data]);

  useEffect(() => {
    if(retractSuccessModal === true){
      handleRetractSuccessConnectModal('ADD_RETRACT_SUCCESS_CONNECT_TO_DISPLAY', 'true')  
    }
  }, [retractSuccessModal]);


  useEffect(() => {
    setRetractSuccessModal(false)
  }, [])

  return (
    <>
    {!currentProfile ? "" : 
      <div className='connect-manage-card'
      >
        <div className='connect-manage-card-left'>
          <div className='connect-manage-card-user'>
            <div className='connect-manage-card-user-head'>
              <div>
                <img src={
                    sent?.page?.featured_image === null
                  ? person
                  : sent?.page?.featured_image
                } alt='' />
              </div>
              <div>
                <h2>{sent?.page?.name}</h2>           
                <p>@{sent?.page?.username ? sent?.page?.username : "Username" }</p>
              </div>
            </div>
            <div className='connect-manage-iconss'>
              <div className='connect-manage-eye'
               onClick={() => {
                handleRetractModal('ADD_RETRACT_TO_DISPLAY', 'true');
                setRetractModalData(sent)
                // handleShow();
                 setCurrentId(sent?.id)
                 }}
                >
                <FaEye/>
              </div>
              {/* <div className='connect-manage-envelope'>
                <FaEnvelope />
              </div> */}
            </div>
          </div>
          <div className='connect-manage-card-text'>
            <div className='connect-manage-card-text-wrap'>
              <h1>{sent?.connect_profile?.title}</h1>
              <h3>{sent?.page?.location?.address}</h3>
              <p className='connect-manage-card-p1'>{sent?.page?.description}</p>
            </div>
            <div className='connectcard-button-flex'>
              <p className='connect-manage-card-p2'>
                {/*{moment(sent.expire_on).format("MMMM Do YYYY")}*/}
              </p>
              <div>
                <div className='connect-button-flex'>
                  <button
                    className='connect-retract'
                    onClick={() => {
                      handleRetractConnectCard(sent?.id)
                      setRetractSuccessModal(false)
                      // setRetractSuccessModal(true)
                      // handleShow();
                      //  setCurrentId(sent?.id)
                    }}
                    >
                    Retract
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        }
    </>
  )
}

export default ConnectCard
