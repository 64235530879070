import { useState, useEffect } from "react";
import PreviewBookingBody from "../../components/Bookings/previewBookingBody";
import FeedContainer from "../../components/FeedContainer";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import usePages from "../../hooks/createPage";
import qs from "query-string";

const PreviewBooking = () => {
  const history = useHistory();
  const { singlePageData, handleGetSinglePage, categoryData } = usePages();
  const { search } = useLocation();
  const { id } = qs.parse(search);
  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    handleGetSinglePage(id);

    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  return (
    <FeedContainer>
      <div className="booking-container">
        <div className="bk-header">
          <h1 className="welcome-title">
            {greeting()}{" "}
            <span>{singlePageData?.data?.name?.split(" ")[0]}</span>
          </h1>
        </div>
        <div className="bk-card">
          <div className="bkc-header">
            <h5 className="sub-title"> My Booking</h5>
            {/* <Link className="sub-title-btn"> Edit Booking </Link> */}
          </div>
          <PreviewBookingBody />
          <div className="btn-box-footer">
            {/* <Link className="back-link" 
            onclick={history.goBack}
            >
              Back
            </Link>
            <button className="bk-btn">Done</button> */}
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};

export default PreviewBooking;
