import React, {useContext, useEffect, useState,} from "react";
import "./jobs.scss";
import avatar from "../../../../../assets/img/Ellipse 4.png";
import {
	FaShare,
	FaLocationArrow,
	FaStar,
	FaTimes,
} from "react-icons/fa";
import {MdMail, MdFileUpload} from "react-icons/md";
import job from "../../../../../assets/explore/service.jpg";
import ExploreCard from "../../../../../components/cards/exploreCards";
import location from "../../../../../assets/explore/locationpin.svg"
import saveIcon from "../../../../../assets/explore/save.svg"
import {toggleRightBar} from "../../../../../appstore/navigationDisplayToggle/index.action";
import {useDispatch} from "react-redux";
import ModalContext from "../../../../../hooks/modalContexts";
import ReusableDropDown from "../../../../../components/reusableDropdown";
import ExploreContext from "../../../../../hooks/exploreContext";
import useExplore from "../../../../../hooks/explore";
import {getExtension, toSentenceCase, truncateStringEllipsis} from "../../../../../utils/helpers";
import JobAdditionalFiles from "../../../../../components/JobAdditionalFiles";
import LoaderPurpleBg from "../../../../../assets/loaders/LoaderPurpleBg";
import Loader from "../../../../../components/loader";
import moment from "moment";


const ExploreJobs = (props) => {
	const {
		allJobPosts,
		jobPostsLoading,
		selectedJobPost,
    jobPostDataLoading,
		addJobApplicationFile,
		clearJobApplicationFiles,
		setJobApplicationContactMode,
		setJobApplicationContactModeNumber,
		setJobApplicationContactModeMail,
	} = useContext(ExploreContext);
	const {handleGetJobPosts, handleGetJobPost} = useExplore();

	const { setJobApplyModal } = useContext(ModalContext);
	const dispatch = useDispatch();
	const [isApply, setIsApply] = useState(false);
	const [submitModal, setSubmitModal] = useState(false);

	// const [setMyJobPosts] = useState(false);
	const [resumeFile, setResumeFile] = useState(null);
	const [offerFile, setOfferFile] = useState(null);
	const [selectedContactMode, setSelectedContactMode] = useState(null);
	const [contactModeNumber, setContactModeNumber] = useState(null);
	const [contactModeMail, setContactModeMail] = useState(null);

	const options = [
		"Best Match",
		"Price [Low - High]",
		"Price [High - Low]",
		"Highest Rated]",
	];

	const toggleIsApply = (id) => {
		if (isApply) {
			dispatch(toggleRightBar(true));
			props.action()
		} else {
			dispatch(toggleRightBar(false));
			props.action()
		}
		setIsApply(!isApply);

		handleGetJobPost(id)
	}

	const hiddenFileInput1 = React.useRef(null);

	const handleClickUpload1 = event => {
		hiddenFileInput1.current.click();
	}

	const handleInput1Change = event => {
		const fileUploaded = event.target.files[0];
		selectFile(fileUploaded, 'resume')
	}

	const selectFile = (file, type) => {
		switch(type) {
			case 'resume':
				setResumeFile(file);
				addJobApplicationFile(
					{file: file, type: 'resume'}
				);
				break;
			default:
				setOfferFile(file);
				addJobApplicationFile(
					{file: file, type: 'additional'}
				);
		}
	}

	const handleSelectContactMode = (means) => {
		setSelectedContactMode(means)
		setJobApplicationContactMode(means)
	}

	const handleContactDetailCall = event => {
		setContactModeNumber(event.target.value)
		setJobApplicationContactModeNumber(event.target.value)
	}

	const handleContactDetailMail = event => {
		setContactModeMail(event.target.value)
		setJobApplicationContactModeMail(event.target.value)
	}

	useEffect(() => {
		handleGetJobPosts({});
		// setMyJobPosts(allJobPosts);

		clearJobApplicationFiles();
		setJobApplicationContactMode(null)
		setJobApplicationContactModeNumber(null)
		setJobApplicationContactModeMail(null)

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	
	return (
		<>
			{
				submitModal === true ?
					<div className="job-modal">
						<div className="job-modal-main">
							<div className="close-modal">
								X
							</div>

							<div className="card-head">
								<div className="business">
									<img src={avatar} className="avatar" alt=""/>
									
									<div className="biz-details">
										<div className="biz-name">Name of Business</div>
										<div className="biz-handle">@username</div>
									</div>
								</div>
								
								<div className="biz-rating">
									<div className="star-rating">
										<div className="rating">
											<i className="star-full"><FaStar /></i>
											<i className="star-full"><FaStar /></i>
											<i className="star-full"><FaStar /></i>
											<i className="star-full"><FaStar /></i>
											<i className="star-empty"><FaStar /></i>
										</div>
										
										<div className="rating-value">4.5</div>
									</div>
									
									<div className="rating-number">4.5k reviews</div>
								</div>
								
								<div className="job-data">
									<div className="location"><i className=""><FaLocationArrow /></i> Jamaica</div>
									<div className="post-date">Posted on {moment(selectedJobPost?.data?.created_at)?.format("l")}</div>
									<div className="page-type">Business Page</div>
								</div>
							</div>
						</div>
					</div>
					:
					null
			}
			
      {
        !isApply ?
          <section className="allListing">
            <div className="sort">
              <p className="sort-label">Sort</p>

              <div className="dropdown-container">
                <ReusableDropDown
                  options={options}
                  style={{ minWidth: 180, zIndex: 2 }}
                />
              </div>
            </div>

            <div className="list-cards">

              {
                jobPostsLoading ?
                  <div style={{ gridArea: "1 / 1 / 2 / 4" }}>
                    <LoaderPurpleBg style={{ width: 70, height: 70 }} />
                  </div>
                  :
                  allJobPosts?.count > 0 &&
                  allJobPosts?.results.map((jobPost, index) => {
                    return (
                      <ExploreCard
                        img={jobPost.gallary[0]?.file ? jobPost.gallary[0].file : job}
                        title={jobPost.title}
                        location={jobPost.location ? jobPost.location.address : "Unknown"}
                        type='Job'
                        isReferrable='false'
                        discount=''
                        jobType={toSentenceCase(jobPost.type.replace(/_/g, ' '))}
                        ctaText='Apply'
                        action={toggleIsApply}
                        jobPostId={jobPost.id}
                        key={index}
                      />
                    )
                  })
              }

            </div>
          </section>
          :
          <section className='job-apply'>
            <div className="details-container">
              {
                jobPostDataLoading ?
                  <Loader text="Loading..." />
                  :
                  <>
                    <div className="position-card">
                      <div className="card-body">
                        <div className="card-head">
                          <div className="business">
                            <img src={avatar} className="avatar" alt="" />

                            <div className="biz-details">
                              <div className="biz-name">
                                {selectedJobPost && selectedJobPost.data ? selectedJobPost.data.page.name : "--"}
                              </div>
                              <div className="biz-handle">
                                @{selectedJobPost && selectedJobPost.data ? selectedJobPost.data.page.username : "--"}
                              </div>
                            </div>
                          </div>

                          <div className="biz-page-details">
                            <div className="details-container">
                              <div className="biz-rating">
                                <div className="star-rating">
                                  <div className="rating">
                                    <i className="star-full"><FaStar /></i>
                                    <i className="star-full"><FaStar /></i>
                                    <i className="star-full"><FaStar /></i>
                                    <i className="star-full"><FaStar /></i>
                                    <i className="star-empty"><FaStar /></i>
                                  </div>

                                  <div className="rating-value">
                                    {selectedJobPost && selectedJobPost.data ? selectedJobPost.data.page.rating : "--"}
                                  </div>
                                </div>

                                <div className="rating-number">
                                  {selectedJobPost && selectedJobPost.data ? selectedJobPost.data.page.review_count : "--"} reviews
                                </div>
                              </div>

                              <div className="job-data">
                                <div className="location">
                                  <img src={location} height='22px' alt="" /> &nbsp;
                                  {selectedJobPost?.data?.page?.location?.address}
                                </div>
                                <div className="post-date">Posted on {moment(selectedJobPost?.data?.created_at)?.format("l")}</div>
                              </div>

                              <div className="page-type">
                                <p className="">
                                  {selectedJobPost && selectedJobPost.data ? toSentenceCase(selectedJobPost.data.page.type) : ""} Page
                                </p>
                              </div>
                            </div>

                          </div>

                        </div>

                        <div className="job-details">
                          <div>
                            <div className="position-name">
                              {selectedJobPost && selectedJobPost.data ? selectedJobPost.data.title : "--"}
                            </div>
                            <div className="biz-address">
                              {selectedJobPost?.data?.location?.address}
                            </div>
                          </div>

                          <div className="job-actions">
                            <img src={saveIcon} className='action-icon' alt="" />
                            <i className="action-icon"><FaShare /></i>
                          </div>
                        </div>

                        <div className="job-description">
                          <div className="title">Job Description</div>

                          <div className="description">
                            {selectedJobPost && selectedJobPost.data ? selectedJobPost.data.description : "No description"}
                          </div>
                        </div>

                        <div className="job-extra-box">
                          <div className="job-extra">
                            <div className="extra-label">
                              Job Type: <span className="detail">
                                {selectedJobPost && selectedJobPost.data ? toSentenceCase(selectedJobPost.data.type.replace(/_/g, ' ')) : "--"}
                              </span>
                            </div>

                            <div className="extra-label">
                              Salary Range: <span className="detail">
                                ${selectedJobPost && selectedJobPost.data ? selectedJobPost.data.min_salary : "--"} -
                                ${selectedJobPost && selectedJobPost.data ? selectedJobPost.data.max_salary : "--"}
                              </span>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                    <div className="applicant-card">
                      <div className="card-body">
                        <div className="card-heading-box">
                          <div className="card-heading">Set Preferred Contact Mode: </div>
                        </div>

                        <div className="form-container">
                          <div className="detail-form">
                            <div className="form-section">
                              <div className="contact-input">
                                <label className="contact-label">
                                  <input
                                    type="radio"
                                    name="contact"
                                    className='contact-radio'
                                    onChange={() => handleSelectContactMode('call')}
                                  />
                                  <span className="checkmark"></span>
                                  <p className="label-text">Call</p>
                                  <input type="text" className="detail-input" id='call' onChange={handleContactDetailCall} />
                                </label>
                              </div>

                              <div className="contact-input">
                                <label className="contact-label">
                                  <input
                                    type="radio"
                                    name="contact"
                                    className='contact-radio'
                                    onChange={() => handleSelectContactMode('any')}
                                  />
                                  <span className="checkmark"></span>
                                  <p className="label-text">Any</p>
                                </label>
                              </div>
                            </div>

                            <div className="form-section">
                              <div className="contact-input">
                                <label className="contact-label">
                                  <input
                                    type="radio"
                                    name="contact"
                                    className='contact-radio'
                                    onChange={() => handleSelectContactMode('email')}
                                  />
                                  <span className="checkmark"></span>
                                  <p className="label-text">Email</p>
                                  <input type="text" className="detail-input" id='email' onChange={handleContactDetailMail} />
                                </label>
                              </div>

                              <div className="contact-input">
                                <label className="contact-label">
                                  <input
                                    type="radio"
                                    name="contact"
                                    className='contact-radio'
                                    onChange={() => handleSelectContactMode('message')}
                                  />
                                  <span className="checkmark"></span>
                                  <p className="label-text">Message</p>
                                </label>
                              </div>
                            </div>

                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="actions">
                      <button className="btn-back" onClick={() => toggleIsApply()}>Back</button>
                      <button className="btn-done" onClick={() => setJobApplyModal(true)}>Done</button>
                    </div>
                  </>
              }
            </div>

            <div className="explore-right">
              <button className="message-btn">
                <MdMail />
                Message
              </button>

              <div className="card-title">Upload Files</div>

              <div className="doc-container">
                <div className="doc-title">Resume</div>

                <div className="doc-body">
                  {
                    resumeFile ?
                      <>
                        <div className='doc-content'>
                          <div className="ext-label">{getExtension(resumeFile.name)}</div>

                          <div className="doc-details">
                            <div className="doc-name">{truncateStringEllipsis(resumeFile.name, 16, true)}</div>
                            <div className="last-used">Last Used Today</div>
                          </div>
                        </div>

                        <FaTimes />
                      </>
                      : <div style={{ padding: '8px' }}>No file Selected</div>
                  }
                </div>

                <div className="doc-actions">
                  <button className="reuse-btn">Use Recent</button>
                  <input
                    id="btn-resume"
                    name="btn-resume"
                    style={{ display: 'none' }}
                    type="file"
                    className='upload-btn'
                    ref={hiddenFileInput1}
                    accept={'application/pdf, text/plain, .doc, .docx, .txt'}
                    onChange={handleInput1Change}
                  />

                  <button className="upload-btn" onClick={handleClickUpload1}>
                    <MdFileUpload /> Upload File
                  </button>
                </div>
              </div>

              {
                selectedJobPost?.data?.extra_attachments.length > 0 &&
                selectedJobPost?.data?.extra_attachments.map((attachment, index) => (
                  <JobAdditionalFiles
                    key={index}
                    data={attachment}
                  />
                ))
              }
            </div>
          </section>
      }
			
		</>
	)
};

export default ExploreJobs;