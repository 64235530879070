import React from "react";
import { Card, P } from "./generalSettings";
import { Col, Container } from "reactstrap";

class NotificationPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <div>
        <Card height="500px">
          <Container>
            <Col xl={12}>
              <Col xl={8}>
                <P className="pt-4 pb-3">Notification Settings</P>
              </Col>
            </Col>
          </Container>
        </Card>
      </div>
    );
  }
}

export default NotificationPage;
