import "./postCardSingle.scss";
import { MoreOptionsIcon } from "../../assets/svg/Extras";
import postCardOptions from "../../utils/postCardOptions";
import { useState, useEffect, Fragment, useRef } from "react";
import testAvatarCommentSection from "../../assets/img/Ellipse 357.png";
import PostMatrics from "./postMatrics";
import render from "react-render-html";
import useDeviceType from "../../hooks/deviceType";
import { useDispatch, useSelector } from "react-redux";
import VuesaxBoldHeartComponent from "../../assets/iconsax/VuesaxBoldHeartComponent";
import VuesaxLinearMessageComponent from "../../assets/iconsax/VuesaxLinearMessageComponent";
import VuesaxLinearArchiveMinusComponent from "../../assets/iconsax/VuesaxLinearArchiveMinusComponent";
import VuesaxLinearSendComponent from "../../assets/iconsax/VuesaxLinearSendComponent";
import usePosts from "../../hooks/posts";
import HeartComponent from "../../assets/iconsax/active/HeartComponent";
import ActiveBookmark from "../../assets/iconsax/active/ActiveBookmark";
import { IoIosArrowDropright } from "react-icons/all";
import { formatText, handlePostText } from "../../utils/formatText";
import CommentComponent from "./comments";
import PhotoGrid from "react-facebook-photo-grid";
import MediaBox from "../mediaBox";
import { useLocation, useParams } from "react-router";
import qs from "query-string";
import usePages from "../../hooks/createPage";
import { clearComments } from "../../appstore/commentV2/commentV2.action";
const PostCardSingle = ({
  postData,
  avatar,
  optionVisibility,
  text,
  media,
  num_of_comments,
  num_of_likes,
  is_saved,
  is_liked,
  id,
  tags,
  owner,
  replies,
  created_at,
  modified_on,
  published,
  hidden,
  post_type,
  isOwner,
  page,
  product,
  service,
  job_post: job,
}) => {
  const { search } = useLocation();
  const comments = useSelector((state) => state.commentsV2);
  const { user } = useSelector((state) => state.currentUser);
  const commentBox = useRef(null);
  const [isOptionsVisible, setIsOptionsVisible] = optionVisibility;
  const { isMobile } = useDeviceType();
  const [comment, setComment] = useState("");
  const [parentId, setReply] = useState();
  const [placeholder, setPlaceholder] = useState("Write a comment");
  const { singlePageData, handleGetSinglePage } = usePages();
  // const options =
  const {
    handleLike,
    handleSave,
    handleDelete,
    handleMakeComment,
    makeCommentData,
    handleHidePost,
  } = usePosts();
  //edit post texts for hashtags and mentions

  const handleOptionsClick = (name = "") => {
    if (name.toLowerCase() === "delete post") {
      handleDelete(id);
    }
    if (name.toLowerCase() === "hide") {
      handleHidePost(id);
    }
  };
  const { pageId: currentPage } = useParams();
  const handleComment = () => {
    const pageId =
      currentPage && singlePageData.data?.owner === user.id && currentPage;
    const addMention = `${parentId ? placeholder : ``} ${comment}`;
    if (comment.trim() !== "") {
      handleMakeComment(id, formatText(addMention), parentId, pageId);
    }
  };

  //reset comment box
  useEffect(() => {
    if (makeCommentData.data) {
      commentBox.current.innerHTML = "";
      setComment("");
    }
  }, [makeCommentData.data]);

  const checkIfSingle = () => {
    if (
      (!product?.gallary.length && !media?.length) ||
      (!product?.description && !text)
    )
      return "single";
    return;
  };
  useEffect(() => {
    if (currentPage) {
      handleGetSinglePage(currentPage);
    }
  }, []);
  return (
    <div
      className={`post__card__single ${checkIfSingle()}`}
      onClick={(e) => isOptionsVisible === id && setIsOptionsVisible(null)}>
      {/* {isMobile && <PostMatrics postData={postData} avatar={avatar} />} */}

      <PostMatrics
        {...{
          ...owner,
          likes: num_of_likes,
          comment: num_of_comments,
          time: created_at,
          page,
        }}
      />
      {media?.length ? (
        <div
          style={{
            ...(checkIfSingle() === "single" && { margin: "15px 0" }),
            borderRadius: 15,
            overflow: "hidden",
          }}
          onClick={(e) => {
            // handleMediaBox("CREATE_MEDIA", media);
          }}>
          <MediaBox media={media} />
        </div>
      ) : (
        ""
      )}
      {product?.gallary.length ? (
        <div
          style={{
            ...(checkIfSingle() === "single" && { margin: "15px 0" }),
            borderRadius: 15,
            overflow: "hidden",
          }}
          onClick={(e) => {
            // handleMediaBox("CREATE_MEDIA", media);
          }}>
          <MediaBox media={product.gallary} />
        </div>
      ) : (
        ""
      )}
      <div
        className="section__2"
        style={{
          ...(!media?.length && {
            minWidth: "100%",
          }),
        }}>
        {text ||
          (product && (
            <div
              className="post"
              style={{
                ...(!media?.length && {
                  fontSize: 28,
                  lineHeight: "30px",
                  marginTop: "20px",
                }),
              }}>
              {product && (
                <span
                  style={{
                    display: "block",
                    color: "#490057",
                    fontWeight: 500,
                  }}>
                  {product.title}{" "}
                  <span style={{ fontWeight: 300 }}>
                    ${product.deposit_amount}
                  </span>
                </span>
              )}
              {render(handlePostText(product?.description || text, page))}
            </div>
          ))}
        <div className="post-actions">
          <div className="icon" onClick={(e) => handleLike(id)}>
            {is_liked ? <HeartComponent /> : <VuesaxBoldHeartComponent />}
          </div>
          <div className="icon">
            <VuesaxLinearMessageComponent />
          </div>

          {
            <div
              ref={commentBox}
              id="commentBox"
              contentEditable={true}
              className="comment__box"
              data-placeholder={placeholder}
              onInput={(e) => setComment(e.target.innerHTML)}></div>
          }
          <div className="icon share__" onClick={(e) => handleComment()}>
            <VuesaxLinearSendComponent />
          </div>
        </div>

        {comments.map((comment) => (
          <CommentComponent
            comment={comment}
            commentBox={commentBox}
            postId={id}
            key={comment.id}
            setReply={setReply}
            setPlaceholder={setPlaceholder}
          />
        ))}
      </div>
      <div className="extra__options">
        <div className="more__options" onClick={(e) => setIsOptionsVisible(id)}>
          <MoreOptionsIcon />
        </div>
      </div>
      {!isMobile && isOptionsVisible === id && (
        <ul className="option__">
          {postCardOptions.map((opt, idx) => (
            <Fragment key={idx}>
              {!isOwner && opt.private ? undefined : (
                <li
                  onClick={(e) => handleOptionsClick(opt.name)}
                  style={{
                    ...(isOwner &&
                      (opt.name.toLowerCase() === "hide" ||
                        opt.name.toLowerCase() === "report post") && {
                        display: "none",
                      }),
                  }}>
                  {/* some icon */}
                  <opt.icon />
                  <p> {opt.name}</p>
                </li>
              )}
            </Fragment>
          ))}
          <li onClick={(e) => handleSave(id)}>
            {is_saved ? (
              <ActiveBookmark />
            ) : (
              <VuesaxLinearArchiveMinusComponent />
            )}{" "}
            <p>{is_saved ? "Saved" : "Save"}</p>
          </li>
        </ul>
      )}
    </div>
  );
};

export default PostCardSingle;
