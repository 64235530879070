import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DefaultButton } from "../../Button";
import { BsSlashCircle } from "react-icons/all";
import cx from "classnames";
import useAxios from "axios-hooks";
import { showToast } from "../../ReactToast";
import ModalContext from "../../../hooks/modalContexts";
const CancelCheckout = ({ closeModal }) => {
  const [{ ...cancelObj }, cancel] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleCancel = useCallback(async () => {
    return await cancel({
      url: `/orders/${window.sessionStorage.getItem("orderId")}/cancel/`,
    });
  });
  const placeholder = useMemo(
    () => "This is a space customer can enter why he is canceling this ride",
    []
  );
  const options = useMemo(
    () => [
      {
        text: "Buyer/seller did not arrive",
      },
      {
        text: "Wrong Product",
      },
      {
        text: "Damaged Product",
      },
    ],
    []
  );
  const { checkoutModalV2 } = useContext(ModalContext);
  const [setData] = checkoutModalV2;
  const [selected, setSelected] = useState();
  const [moreInfo, setMoreInfo] = useState("");
  console.log(selected);

  useEffect(() => {
    if (cancelObj.data) {
      showToast("success", "Your order has successfully been cancelled");
      closeModal();
      setData(null);
    }
  }, [cancelObj.data]);
  return (
    <div className="cancel-checkout-wrap">
      <div className="cancel-checkout-box">
        <div className="icon-cancel"></div>
        <div className="reasons">
          {options.map((reason, idx) => (
            <button
              onClick={setSelected.bind(this, idx)}
              key={reason.text}
              className={cx({
                "reason-btn-selected": selected === options.indexOf(reason),
              })}>
              {reason.text}
            </button>
          ))}
        </div>
        <div className="more-reasons">
          <h2>Any other reason?</h2>
          <div
            className="input-reasons"
            contentEditable
            placeholder={placeholder}
          />
        </div>
        <div className="cancel-submit">
          <DefaultButton
            customStyle={{
              backgroundColor: "#C10000",
              fontSize: 12,
              //   padding: "5px 40px",
            }}
            onClick={handleCancel.bind(this)}>
            Cancel
          </DefaultButton>
          <DefaultButton
            customStyle={{
              backgroundColor: "#D9D9D964",
              color: "black",
              fontSize: 12,
              //   padding: "5px 40px",
            }}
            onClick={() => closeModal()}>
            Take me back
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};
export default CancelCheckout;
