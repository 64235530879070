import React, {useEffect, createContext, useState, useContext  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import useAxios from "axios-hooks";
import ModalContext from './modalContexts';
import ExploreContext from "./exploreContext";

const useAnalytics = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.currentUser);


  const {

  } = useContext(ExploreContext)


//   ANALYTICS GENERAL
  const [{ ...analyticsGeneralData}, getAnalyticsGeneral] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );


  const handleGetAnalyticsGeneral = async (page_id, start_date, end_date) => {
    await getAnalyticsGeneral({
      url:`/analytics/general/${page_id}/interactions/`,
      // url:  `/analytics/${page_id}/general/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };


//   ANALYTICS GENERAL
  const [{ ...analyticsGeneralDateData}, getAnalyticsGeneralDate] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );


  const handleGetAnalyticsGeneralDate = async (page_id, start_date, end_date) => {
    await getAnalyticsGeneralDate({
      url:`/analytics/general/${page_id}/interactions/`,
      // url:  `/analytics/${page_id}/general/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };


//   ANALYTICS GENERAL CHART
  const [{ ...analyticsGeneralChartData}, getAnalyticsGeneralChart] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );


  const handleGetAnalyticsGeneralChart = async (page_id, start_date, end_date) => {
    await getAnalyticsGeneralChart({
      url:`/analytics/general/${page_id}/charts/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };



//   ANALYTICS LISTING Interactions
const [{ ...analyticsListingInteraction}, getAnalyticsListingInteraction] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsListingInteraction = async (page_id, start_date, end_date) => {
    await getAnalyticsListingInteraction({
      url:  `/analytics/listing/${page_id}/interactions/`,
      params: {
        start_date: start_date,
        end_date: end_date

      },
    }).catch((error) => console.log(error));
  };


  //   ANALYTICS LISTING CHART
const [{ ...analyticsListingChartData}, getAnalyticsListingChart] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsListingChart = async (page_id, start_date, end_date) => {
    await getAnalyticsListingChart({
      url:  `analytics/listing/${page_id}/charts/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };



//   ANALYTICS LISTING OTHERS
const [{ ...analyticsListingOthersData}, getAnalyticsListingOthers] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsListingOthers = async (page_id) => {
    await getAnalyticsListingOthers({
      url:  `/analytics/${page_id}/listing_others/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };


//   ANALYTICS LISTING PURCHASE LISTING
const [{ ...analyticsPurchaseListingData}, getAnalyticsPurchaseListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsPurchaseListing = async (page_id) => {
    await getAnalyticsPurchaseListing({
      url:  `/analytics/listing/${page_id}/purchased_listing/`, 
      params: {

      },
    }).catch((error) => console.log(error));
  };


//   ANALYTICS LISTING Interacted_Listing

const [{ ...analyticsInteractedListingData}, getAnalyticsInteractedListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsInteractedListing = async (page_id, start_date, end_date) => {
    await getAnalyticsInteractedListing({
      url:  `/analytics/listing/${page_id}/interacted_listing/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };


//   ANALYTICS LISTING Ad_converted

const [{ ...analyticsAdConvertedListingData}, getAnalyticsAdConvertedListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsAdConvertedListing = async (page_id, start_date, end_date) => {
    await getAnalyticsAdConvertedListing({
      url:  `/analytics/listing/${page_id}/ad_converted/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };

//   ANALYTICS LISTING demographic/

const [{ ...analyticsDemographicListingData}, getAnalyticsDemographicListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsDemographicListing = async (page_id) => {
    await getAnalyticsDemographicListing({
      url:  `/analytics/${page_id}/demographic/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };

  //   ANALYTICS LISTING Order-Completion

const [{ ...analyticsOrderCompletionListingData}, getAnalyticsOrderCompletionListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsOrderCompletionListing = async (page_id) => {
    await getAnalyticsOrderCompletionListing({
      url:  `/analytics/${page_id}/order_completion/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };


  //   ANALYTICS LISTING Clicks

const [{ ...analyticsClicksListingData}, getAnalyticsClicksListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsClicksListing = async (page_id, start_date, end_date) => {
    await getAnalyticsClicksListing({
      url:  `/analytics/listing/${page_id}/clicks/`,
      params: {
        start_date: start_date,
        end_date: end_date

      },
    }).catch((error) => console.log(error));
  };

  //   ANALYTICS LISTING Likes

const [{ ...analyticsLikesListingData}, getAnalyticsLikesListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsLikesListing = async (page_id, start_date, end_date) => {
    await getAnalyticsLikesListing({
      url:  `/analytics/listing/${page_id}/likes/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };


    //   ANALYTICS LISTING Shares

const [{ ...analyticsSharesListingData}, getAnalyticsSharesListing] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsSharesListing = async (page_id, start_date, end_date) => {
    await getAnalyticsSharesListing({
      url:  `/analytics/listing/${page_id}/shares/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };


    //   ANALYTICS LISTING REFER

const [{ ...analyticsRefersListingData}, getAnalyticsRefersListing] = useAxios(
  {
    method: "GET",
  },
  {
    manual: true,
  }
);

const handleGetAnalyticsRefersListing = async (page_id, start_date, end_date) => {
  await getAnalyticsRefersListing({
    url:  `/analytics/listing/${page_id}/refer/`,
    params: {
      start_date: start_date,
      end_date: end_date
    },
  }).catch((error) => console.log(error));
};


    //   ANALYTICS LISTING Supporters

    const [{ ...analyticsSupportersListingData}, getAnalyticsSupportersListing] = useAxios(
      {
        method: "GET",
      },
      {
        manual: true,
      }
    );
  
    const handleGetAnalyticsSupportersListing = async (page_id) => {
      await getAnalyticsSupportersListing({
        url:  `/analytics/listing/${page_id}/supported/`,
        params: {
  
        },
      }).catch((error) => console.log(error));
    };

  //   ======  ANALYTICS BOOKING Queue_completion_customers/ =========

const [{ ...analyticsBookingQueueCompletionData}, getAnalyticsBookingQueueCompletion] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsBookingQueueCompletion = async (page_id) => {
    await getAnalyticsBookingQueueCompletion({
      url:  `/analytics/booking/${page_id}/queue_completion_customers/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };


  // Booking -------- Booking Interaction
const [{ ...analyticsBookingInteraction}, getAnalyticsBookingInteraction] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsBookingInteraction = async (page_id, start_date, end_date) => {
    await getAnalyticsBookingInteraction({
      url:  `/analytics/booking/${page_id}/interactions/`,
      params: {
        start_date: start_date,
        end_date: end_date

      },
    }).catch((error) => console.log(error));
  };

   //   ANALYTICS Booking CHART
const [{ ...analyticsBookingChartData}, getAnalyticsBookingChart] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsBookingChart = async (page_id, start_date, end_date) => {
    await getAnalyticsBookingChart({
      url:  `/analytics/booking/${page_id}/charts/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };

//   ANALYTICS BOOKING OTHERS
const [{ ...analyticsBookingOthersData}, getAnalyticsBookingOthers] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsBookingOthers = async (page_id) => {
    await getAnalyticsBookingOthers({
      url:  `/analytics/booking/${page_id}/others/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };

  //   ANALYTICS BOOKING Booking-Completion
const [{ ...analyticsBookingCompletionData}, getAnalyticsBookingCompletion] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsBookingCompletion = async (page_id) => {
    await getAnalyticsBookingCompletion({
      url:  `/analytics/booking/${page_id}/booking_completion/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };

  //   ANALYTICS BOOKING Ad-Converted
const [{ ...analyticsBookingAdConvertedData}, getAnalyticsBookingAdConverted] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsBookingAdConverted = async (page_id) => {
    await getAnalyticsBookingAdConverted({
      url:  `/analytics/booking/${page_id}/ad_converted/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };

// Queue Interaction
const [{ ...analyticsQueueInteraction}, getAnalyticsQueueInteraction] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsQueueInteraction = async (page_id, start_date, end_date) => {
    await getAnalyticsQueueInteraction({
      url:  `/analytics/queue/${page_id}/interactions/`,
      params: {
        start_date: start_date,
        end_date: end_date

      },
    }).catch((error) => console.log(error));
  };


// Queue Modal
const [{ ...analyticsQueueModal}, getAnalyticsQueueModal] = useAxios(
  {
    method: "GET",
  },
  {
    manual: true,
  }
);

const handleGetAnalyticsQueueModal = async (page_id, start_date, end_date) => {
  await getAnalyticsQueueModal({
    url:  `/analytics/queue/${page_id}/queue/`,
    params: {
      start_date: start_date,
      end_date: end_date
    },
  }).catch((error) => console.log(error));
};
  
  //   ANALYTICS QUEUE CHART
const [{ ...analyticsQueueChartData}, getAnalyticsQueueChart] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsQueueChart = async (page_id, start_date, end_date) => {
    await getAnalyticsQueueChart({
      url:  `/analytics/queue/${page_id}/charts/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };

  //   ANALYTICS QUEUE AdConverted
const [{ ...analyticsQueueAdConvertedData}, getAnalyticsQueueAdConverted] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsQueueAdConverted = async (page_id, start_date, end_date) => {
    await getAnalyticsQueueAdConverted({
      url:  `/analytics/queue/${page_id}/ad_converted/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };

   //   ANALYTICS QUEUE Services
const [{ ...analyticsQueueServicesData}, getAnalyticsQueueServices] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsQueueServices = async (page_id) => {
    await getAnalyticsQueueServices({
      url:  `/analytics/queue/${page_id}/services/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };

   //   ANALYTICS QUEUE QueueCompletion
const [{ ...analyticsQueueCompletionData}, getAnalyticsQueueCompletion] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsQueueCompletion = async (page_id) => {
    await getAnalyticsQueueCompletion({
      url:  `/analytics/queue/${page_id}/queue_completion/`,
      params: {

      },
    }).catch((error) => console.log(error));
  };


   //  ========= ANALYTICS JOB CHART ===========
   const [{ ...analyticsJobChartData}, getAnalyticsJobChart] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsJobChart = async (page_id, start_date, end_date) => {
    await getAnalyticsJobChart({
      url:  `/analytics/jobs/${page_id}/charts/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };

  //   ANALYTICS JOB Interactions
const [{ ...analyticsJobInteraction}, getAnalyticsJobInteraction] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsJobInteraction = async (page_id, start_date, end_date) => {
    await getAnalyticsJobInteraction({
      url: `/analytics/jobs/${page_id}/interactions/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };


   //   ANALYTICS JOB PostJob
   const [{ ...analyticsPostJobData}, getAnalyticsPostJob] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsPostJob = async (page_id, start_date, end_date, completed) => {
    await getAnalyticsPostJob({
      url:  `/analytics/jobs/${page_id}/postjobs/`,
      params: {
        // status: completed,
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };


   // ==============  ANALYTICS CONNECT Interaction ===================
   const [{ ...analyticsConnectInteraction}, getAnalyticsConnectInteraction] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsConnectInteraction = async (page_id, start_date, end_date) => {
    await getAnalyticsConnectInteraction({
      url: `/analytics/connect/${page_id}/interactions/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };

  // Connect Chart
   const [{ ...analyticsConnectChartData}, getAnalyticsConnectChart] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsConnectChart = async (page_id, start_date, end_date) => {
    await getAnalyticsConnectChart({
      url:  `/analytics/connect/${page_id}/charts/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };

     //  ANALYTICS CONNECT Profile
     const [{ ...analyticsConnectProfileData}, getAnalyticsConnectProfile] = useAxios(
      {
        method: "GET",
      },
      {
        manual: true,
      }
    );

    const handleGetAnalyticsConnectProfile = async (page_id, Least, start_date, end_date) => {
      await getAnalyticsConnectProfile({
        url:  `/analytics/connect/${page_id}/connectprofile/`,
        params: {
          status: Least,
          start_date: start_date,
          end_date: end_date
        },
      }).catch((error) => console.log(error));
    };


     //  ANALYTICS CONNECT customer_converted
     const [{ ...analyticsConnectCustomerConvertedData}, getAnalyticsConnectCustomerConverted] = useAxios(
      {
        method: "GET",
      },
      {
        manual: true,
      }
    );

    const handleGetAnalyticsConnectCustomerConverted = async (page_id,start_date, end_date) => {
      await getAnalyticsConnectCustomerConverted({
        url:  `/analytics/connect/${page_id}/customer_converted/`,
        params: {
          start_date: start_date,
          end_date: end_date
        },
      }).catch((error) => console.log(error));
    };

 // ==============  ANALYTICS REFERRAL Interaction ===================
 const [{ ...analyticsReferralInteraction}, getAnalyticsReferralInteraction] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetAnalyticsReferralInteraction = async (page_id, start_date, end_date) => {
    await getAnalyticsReferralInteraction({
      url: `/analytics/referral/${page_id}/interactions/`,
      params: {
        start_date: start_date,
        end_date: end_date
      },
    }).catch((error) => console.log(error));
  };
  
  // Referral Charts
 const [{ ...analyticsReferralChartData}, getAnalyticsReferralChart] = useAxios(
  {
    method: "GET",
  },
  {
    manual: true,
  }
);

const handleGetAnalyticsReferralChart = async (page_id, start_date, end_date) => {
  await getAnalyticsReferralChart({
    url:  `/analytics/referral/${page_id}/charts/`,
    params: {
      start_date: start_date,
      end_date: end_date
    },
  }).catch((error) => console.log(error));
};



return {
    analyticsGeneralData,
    handleGetAnalyticsGeneral,
    analyticsListingOthersData,
    handleGetAnalyticsListingOthers,
    analyticsPurchaseListingData,
    handleGetAnalyticsPurchaseListing,
    analyticsInteractedListingData,
    handleGetAnalyticsInteractedListing,
    analyticsAdConvertedListingData,
    handleGetAnalyticsAdConvertedListing,
    analyticsDemographicListingData,
    handleGetAnalyticsDemographicListing,
    analyticsOrderCompletionListingData,
    handleGetAnalyticsOrderCompletionListing,
    analyticsBookingQueueCompletionData,
    handleGetAnalyticsBookingQueueCompletion,
    analyticsBookingOthersData,
    handleGetAnalyticsBookingOthers,
    analyticsBookingCompletionData,
    handleGetAnalyticsBookingCompletion,

    // Booking AdConverted
    analyticsBookingAdConvertedData,
    handleGetAnalyticsBookingAdConverted,


    analyticsQueueChartData,
    handleGetAnalyticsQueueChart,

    analyticsQueueAdConvertedData,
    handleGetAnalyticsQueueAdConverted,

    analyticsQueueServicesData,
    handleGetAnalyticsQueueServices,
    analyticsQueueCompletionData,
    handleGetAnalyticsQueueCompletion,

    analyticsJobChartData,
    handleGetAnalyticsJobChart,

    analyticsPostJobData,
    handleGetAnalyticsPostJob,

    analyticsConnectInteraction,
    handleGetAnalyticsConnectInteraction,

    analyticsConnectChartData,
    handleGetAnalyticsConnectChart,

    analyticsConnectProfileData,
    handleGetAnalyticsConnectProfile,

    analyticsConnectCustomerConvertedData,
    handleGetAnalyticsConnectCustomerConverted,

    analyticsReferralChartData,
    handleGetAnalyticsReferralChart,

    analyticsReferralInteraction,
    handleGetAnalyticsReferralInteraction,


    analyticsGeneralChartData,
    handleGetAnalyticsGeneralChart,

    analyticsListingInteraction,
    handleGetAnalyticsListingInteraction,

    analyticsListingChartData,
    handleGetAnalyticsListingChart,

    analyticsClicksListingData,
    handleGetAnalyticsClicksListing,

     analyticsLikesListingData,
    handleGetAnalyticsLikesListing,

     analyticsSharesListingData,
    handleGetAnalyticsSharesListing,


    analyticsBookingInteraction,
    handleGetAnalyticsBookingInteraction,

    analyticsBookingChartData,
    handleGetAnalyticsBookingChart,

    analyticsQueueInteraction,
    handleGetAnalyticsQueueInteraction,

    analyticsJobInteraction,
    handleGetAnalyticsJobInteraction,

    analyticsRefersListingData,
    handleGetAnalyticsRefersListing,

    analyticsSupportersListingData,
    handleGetAnalyticsSupportersListing,

    analyticsQueueModal,
    handleGetAnalyticsQueueModal,

    analyticsGeneralDateData,
    handleGetAnalyticsGeneralDate,

  };

};

export default useAnalytics
