import React from "react";
import PropTypes from 'prop-types';
import "./pointCard.css";

const PointCard = props => {
    return (
        <div className="PointCard">
            <img className="PointCard__logo" src={props.image} alt="logo" />
            <p className="PointCard__text PointCard__header">{props.header}</p>
            <p className="PointCard__text PointCard__body">{props.body}</p>
        </div>
    );
};

PointCard.propTypes = {
    image: PropTypes.any.isRequired,
    header: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired
};

export default PointCard;