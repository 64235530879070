import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const useInterests = () => {
  const [{ ...interestData }, getInterests] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  //handling skipped interests

  const [{ ...addInterestsData }, addInterests] = useAxios(
    {
      url: "/users/interests/",
      method: "post",
    },
    {
      manual: true,
    }
  );
  const [{ ...getUserInterestsData }, getUserInterests] = useAxios(
    {
      url: "/users/interests/",
      method: "get",
    },
    {
      manual: true,
    }
  );
  const handleAddInterests = async (data = {}) => {
    try {
      await addInterests({
        data,
      });
    } catch (error) {}
  };
  const fetchInterest = async ({ page_size = 10 }) => {
    getInterests({
      url: `/interests?page_size=${page_size}`,
    })
      .then()
      .catch((error) => {});
  };

  //check if user is signed in and has interests
  const { user } = useSelector((state) => state.currentUser);
  const history = useHistory();
  useEffect(() => {
    if (user) {
      getUserInterests();
    }
  }, []);

  useEffect(() => {
    const { data } = getUserInterestsData;
    console.log(data);
    // check for existing interests
    if (data) {
      const keys = Object.keys(data.results);
      const findDataWithValue = keys
        .map((item) => {
          return data[item]?.length ? true : false;
        })
        .filter((data) => data && data);
  
      // !findDataWithValue.length && history.push("/interests");
    }
  }, [getUserInterestsData.data]);

  return {
    fetchInterest,
    interestData,
    handleAddInterests,
    addInterestsData,
    getUserInterestsData,
    getUserInterests,
  };
};

export default useInterests;
