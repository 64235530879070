import { useEffect, useState } from "react";
import "./index.scss";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import CustomDropdown from "../../../customDropdown/customDropdown";
import usePrivateSellerDashboard from "../../../../hooks/privateSellerDashboard";
import CheckoutItem from "../../../PrivateSeller/Listings/components/checkoutItem";
import Loader from "../../../loader";

const BsCheckoutTab = () => {
  const [checkoutTab, setCheckoutTab] = useState("Buy Now");

  // const [isOpen, setOpen] = useState(false);
  // const toggleView = () => setOpen(!isOpen);
  // const testArray = [1, 2];

  const data = [
    { id: 0, label: "All", filter: "" },
    { id: 2, label: "Processing", filter: "Processing", value: true },
    {
      id: 3,
      label: "Out for delivery",
      filter: "Out_for_delivery",
      value: true,
    },
  ];

  const data2 = [
    { id: 0, label: "Processing" },
    { id: 1, label: "Out for delivery" },
  ];
  const { handleGetCustomizedOrders, allOrdersData } =
    usePrivateSellerDashboard();

  useEffect(() => {
    handleGetCustomizedOrders({ tab: "Checkout" });
  }, []);
  return (
    <div className="checkoutTabBody">
      <div className="checkoutTabContents">
        {allOrdersData?.data?.results?.length > 0 && (
          <div className="filter-box">
            <p>Filter</p>
            <div className="dropbox">
              <CustomDropdown
                items={data}
                filter={handleGetCustomizedOrders}
                filter_key={"Checkout"}
              />
            </div>
          </div>
        )}
        {allOrdersData.data &&
          allOrdersData.data.results.map((data, idx) => (
            <CheckoutItem
              data={data}
              handleRefresh={handleGetCustomizedOrders}
              idx={idx}
            />
          ))}
        {allOrdersData?.loading && <Loader />}
        {allOrdersData?.data?.results?.length === 0 && <p>No items</p>}
      </div>
    </div>
  );
};

export default BsCheckoutTab;
