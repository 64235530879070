import "./postCardSingle.scss";
import { useLocation } from "react-router-dom";
import { MoreOptionsIcon } from "../../assets/svg/Extras";
import postCardOptions from "../../utils/postCardOptions";
import { useState, useEffect, Fragment, useRef } from "react";
import qs from "query-string";
import PostMatrics from "./postMatrics";
import render from "react-render-html";
import { useDispatch } from "react-redux";
import VuesaxBoldHeartComponent from "../../assets/iconsax/VuesaxBoldHeartComponent";
import VuesaxLinearSendComponent from "../../assets/iconsax/VuesaxLinearSendComponent";
import usePosts from "../../hooks/posts";
import HeartComponent from "../../assets/iconsax/active/HeartComponent";
import { formatText, handlePostText } from "../../utils/formatText";
// import CommentComponent from "../PostCardsSingle/comments";
import { GiBodySwapping } from "react-icons/gi";
import CommentComponentV2 from "./commentComponentV2";
import { useSelector } from "react-redux";
import { clearReplies } from "../../appstore/repliesV2/repliesV2.action";

const CommentCard = ({
  avatar,
  body,
  media,
  reply_count,
  likes,
  has_like,
  id,
  owner,
  created_at,
  modified_on,
  isOwner,
  post,
  parent,
  ...rest
}) => {
  const replies = useSelector((state) => state.repliesV2);
  const dispatch = useDispatch();
  const commentBox = useRef(null);
  const [comment, setComment] = useState("");
  const [parentId, setReply] = useState(id);
  const [placeholder, setPlaceholder] = useState(`@${owner?.username}`);
  const location = useLocation();
  const { handleLikeCommentV2, handleMakeReplyV2, replyDatav2 } = usePosts();
  //edit post texts for hashtags and mentions

  const handleComment = () => {
    const addMention = `${parentId ? placeholder : ``} ${comment}`;
    if (comment.trim() !== "") {
      handleMakeReplyV2(parentId, formatText(addMention));
    }
  };

  //reset comment box
  useEffect(() => {
    if (replyDatav2.data) {
      commentBox.current.innerHTML = "";
      setComment("");
    }
  }, [replyDatav2.data]);

  const checkIfSingle = () => {
    if (!media?.length || !body) return "single";
    return;
  };
  useEffect(() => {
    return () => dispatch(clearReplies());
  }, []);
  return (
    <div className={`post__card__single ${checkIfSingle()}`}>
      <PostMatrics
        {...{
          ...owner,
          ...rest,
          likes,
          comment: reply_count,
          time: created_at,
        }}
      />

      <div
        className="section__2"
        style={{
          minWidth: "100%",
        }}>
        {GiBodySwapping && (
          <div
            className="post"
            style={{
              fontSize: 28,
              lineHeight: "30px",
              marginTop: "20px",
            }}>
            {render(handlePostText(body, rest.page))}
          </div>
        )}
        <div className="post-actions">
          <div className="icon" onClick={(e) => handleLikeCommentV2(id)}>
            {has_like ? <HeartComponent /> : <VuesaxBoldHeartComponent />}
          </div>
          {/* <div className="icon">
            <VuesaxLinearMessageComponent />
          </div> */}

          <div
            ref={commentBox}
            id="commentBox"
            contentEditable={true}
            className="comment__box"
            data-placeholder={placeholder}
            onInput={(e) => setComment(e.target.innerHTML)}></div>

          <div className="icon share__" onClick={(e) => handleComment()}>
            <VuesaxLinearSendComponent />
          </div>
        </div>
        {replies.map((comment) => (
          <CommentComponentV2
            comment={comment}
            postId={post}
            commentBox={commentBox}
            key={comment.id}
            setReply={setReply}
            setPlaceholder={setPlaceholder}
          />
        ))}
      </div>
      {/* text::: ends here */}
    </div>
  );
};

export default CommentCard;
