import React, { useEffect, useContext, useState } from "react";
import "./allListing.scss";
import ReusableDropDown from "../../../../../components/reusableDropdown";
import AllItems from "./sections/allItems/allItems";
import useExplore from "../../../../../hooks/explore";
import ExploreContext from "../../../../../hooks/exploreContext";
import LoaderPurpleBg from "../../../../../assets/loaders/LoaderPurpleBg";
import SingleProduct from "./sections/productModules/singleProduct";
import SingleService from "./sections/serviceModules/singleService";
import singleJob from "./sections/jobModules/singleJob";
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../../../appstore/navigationDisplayToggle/index.action";
import SingleJob from "./sections/jobModules/singleJob";


const AllListing = (props) => {
	const options = [
		"Best Match",
		"Price [Low - High]",
		"Price [High - Low]",
		"Highest Rated]",
	];

  const {
    handleGetAllListings,
    handleGetProduct,
    handleGetServices,
    handleGetService,
    handleGetPageServices,
    handleGetServiceLocations,
    handleBookService,
    handleGetJobPost,
    handleGetPersonnelDates,
  } = useExplore();
  const { fetchAllListingsLoading, allListings } = useContext(ExploreContext);
  const [currentModule, setCurrentModule] = useState("AllListings");
  const [selectedItem, setSelectedItem] = useState(null);


  useEffect(() => {
    handleGetAllListings();
    handleGetServices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const dispatch = useDispatch();

  const runProductCTA = (product) => {
    setSelectedItem(product);

    // handleGetProduct(product.id);
    dispatch(toggleRightBar(true));
    props.action();

    if (product.data_type === "product") {
      runProductActions(product);
    } else if (product.data_type === "service") {
      runServiceActions(product);
    } else if (product.data_type === "jobpost") {
      runJobActions(product);
    }

  }

  const runProductActions = (product) => {
    if (product?.pre_order) {
      setCurrentModule("PreOrder")

      handleGetProduct(product.id);
      dispatch(toggleRightBar(true));
      props.action();
    } else {
      setCurrentModule("BuyNow")

      handleGetProduct(product.id);
      dispatch(toggleRightBar(true));
      props.action();
    }
  }

  const runServiceActions = (product) => {
    if (product?.service_type === "QUEUE") {
      setCurrentModule("JoinQueue")
    } else {
      handleGetService(product.id);

      handleGetPageServices(product?.data?.page?.id)
      handleGetServiceLocations(product?.data?.page?.id)

      setCurrentModule("BookNow")
    }
  }

  const runJobActions = (product) => {
    handleGetJobPost(product.id);
    setCurrentModule("ApplyNow")
  }

  const showAllListings = () => {
    props.action();
    setCurrentModule("AllListings")
  }

  const handleBookingService = (payload) => {
    console.log(payload);
    handleBookService(payload);
  }

  return (
    <>
      {
        currentModule === "AllListings" &&

        <section className="allListing">
          <div className="sort">
            <p className="sort-label">Sort</p>

            <div className="dropdown-container">
              <ReusableDropDown
                options={options}
                style={{ minWidth: 180, zIndex: 2 }}
              />
            </div>
          </div>

          <div className="list-cards">
            {
              fetchAllListingsLoading ?
                <div style={{ gridArea: "1 / 1 / 2 / 4" }}>
                  <LoaderPurpleBg style={{ width: 70, height: 70 }} />
                </div>
                :
                <AllItems
                  items={allListings}
                  productAction={runProductCTA}
                />
            }
          </div>
        </section>
      }

      {
        currentModule === "BuyNow" &&

        <SingleProduct
          selectedProduct = {selectedItem}
          action = {props.action}
          goBack = {showAllListings}
        />
      }

      {
        currentModule === "BookNow" &&

        <SingleService
          selectedService = {selectedItem}
          action = {props.action}
          serviceAction = {handleBookingService}
          fetchPersonnelDates = { handleGetPersonnelDates }
          goBack = {showAllListings}
        />
      }

      {
        currentModule === "ApplyNow" &&

        <SingleJob
          selectedJob = {selectedItem}
          action = {props.action}
          goBack = {showAllListings}
        />
      }
    </>
	)
}

export default AllListing;