const greeting = () => {
  const date = new Date().getHours();
  if (date < 12) {
    return "Good morning!";
  } else if (date <= 15 && date >= 12) {
    return "Good afternoon!";
  } else {
    return "Good evening!";
  }
};
export default greeting;
