const BigEye = () => {
  return (
    <svg
      id="eye"
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="38"
      viewBox="0 0 38 38"
    >
      <path
        id="Vector"
        d="M11.337,5.668A5.668,5.668,0,1,1,5.668,0,5.663,5.663,0,0,1,11.337,5.668Z"
        transform="translate(13.332 13.332)"
        fill="none"
        stroke="#fbb03b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M15.493,26.2c5.589,0,10.8-3.293,14.424-8.993a8.432,8.432,0,0,0,0-8.218C26.291,3.293,21.082,0,15.493,0S4.695,3.293,1.069,8.993a8.432,8.432,0,0,0,0,8.218C4.695,22.911,9.9,26.2,15.493,26.2Z"
        transform="translate(3.507 5.89)"
        fill="none"
        stroke="#fbb03b"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.5"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H38V38H0Z"
        transform="translate(38 38) rotate(180)"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};
export default BigEye;
