import profileImg from "../../../assets/img/Ellipse 320.PNG";

export const jobData = [
  {
  avatar: profileImg,
  name: "Maria Sponge",
  userName: "@Username",
  active: "Live",
  jobTitle: "Sale, Manager",
  jobStatus: "Full time",
  salary: "$1000 - $2000 Monthly",
  companyDecs: " Compnay name and brief description",
  posted: "Posted, 20th July 2021",
  deadline: "Ends: July 30, 2021",
  Options: [
    { label: "Delete", value: 355 },
    { label: "Postpone", value: 54 },
    { label: "Update", value: 43 },
  ],
},
{
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
active: "Live",
jobTitle: "Sale, Manager",
jobStatus: "Full time",
salary: "$1000 - $2000 Monthly",
companyDecs: " Compnay name and brief description",
posted: "Posted, 20th July 2021",
deadline: "Ends: July 30, 2021",
Options: [
  { label: "Delete", value: 355 },
  { label: "Postpone", value: 54 },
  { label: "Update", value: 43 },
],
},
{
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
active: "Live",
jobTitle: "Sale, Manager",
statusSalary: "Full time $1000 - $2000 Monthly",
jobStatus: "Full time",
salary: "$1000 - $2000 Monthly",
companyDecs: " Compnay name and brief description",
posted: "Posted, 20th July 2021",
deadline: "Ends: July 30, 2021",
Options: [
  { label: "Delete", value: 355 },
  { label: "Postpone", value: 54 },
  { label: "Update", value: 43 },
],
},
{
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
active: "Live",
jobTitle: "Sale, Manager",
statusSalary: "Full time $1000 - $2000 Monthly",
jobStatus: "Full time",
salary: "$1000 - $2000 Monthly",
companyDecs: "Compnay name and brief description",
posted: "Posted, 20th July 2021",
deadline: "Ends: July 30, 2021",
Options: [
  { label: "Delete", value: 355 },
  { label: "Extend Date", value: 355 },
  { label: "Postpone", value: 54 },
  { label: "Update", value: 43 },
],
},
]

export const FilterOptions = [
  { label: "All", value: 355 },
  { label: "Live", value: 355 },
  { label: "Ended", value: 54 },
  { label: "Promoted", value: 43 },
]
