import { useState, useEffect, useContext } from "react";
import FeedContainer from "../../components/FeedContainer";
import "./analytics.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import PrivateSellerAnalyticsBody from "./PrivateSellerAnalytics/psAnalyticsBody";
import usePages from "../../hooks/createPage"
import { useLocation,  } from "react-router-dom";
import qs from "query-string";

const PrivateSellerAnalytics = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const { id } = qs.parse(search);
  //handle needed nav manu
  const {
    singlePageData,
    handleGetSinglePage,
  } = usePages();
  
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  useEffect(() => {
    handleGetSinglePage(id);
  }, [id]);

  return (
    <FeedContainer>
      <div className="analytics-container">
        <div className="ay-header">
          <h1 className="welcome-title">
            {greeting()}{" "}
            <span>{singlePageData?.data?.name.split(" ")[0]} </span>
          </h1>
        </div>
        <div className="ay-card">
          <div className="ayc-header">
            <h5 className="sub-title"> Analytics</h5>
          </div>
          <PrivateSellerAnalyticsBody />
        </div>
      </div>
    </FeedContainer>
  );
};

export default PrivateSellerAnalytics;
