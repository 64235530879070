import useAxios from "axios-hooks";
import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FaBell } from "react-icons/all";
import { useSelector } from "react-redux";
import { NotificationCacheContext } from ".";
const NotificationOnRequest = ({ id }) => {
  const { pendingReq, setPendingReq, newRequest, setNewRequest } = useContext(
    NotificationCacheContext
  );
  const { id: userId } = useSelector((state) => state.currentUser.user);
  const timeOutRef = useRef(null);
  const [{ data }, getRequests] = useAxios(
    {
      url: `/orders/${id}/extend_time_request/`,
    },
    { manual: true }
  );

  const handleRequests = useCallback(async () => {
    const data = await getRequests();
    if (data.status === 200) {
      timeOutRef.current = setTimeout(() => {
        console.log("getting notification again", data);
        handleRequests();
      }, 3000);
    }
  }, []);
  useEffect(() => {
    handleRequests();
    return () => clearInterval(timeOutRef?.current);
  }, []);

  //verify that it is a new notification
  const newNotification = useMemo(() => {
    return data?.results.filter((item) =>
      userId === item.owner ? false : item.status === "Pending" && true
    );
  }, [data?.results]);

  return (
    <div
      className="notification-on-request"
      onClick={() => {
        if (!newNotification.length) return;
        setPendingReq(newNotification[newNotification.length - 1]);
      }}>
      <div className="icon-box">
        {!!newNotification?.length && (
          <div className="has-new-notification"></div>
        )}
        <FaBell size={20} />
      </div>
    </div>
  );
};
export default NotificationOnRequest;
