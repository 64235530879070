import { useRef, useState } from "react";
import {
  FaCalendarAlt,
  IoMdClose,
  IoMdLocate,
  FiPlusCircle,
  FaCheckCircle,
} from "react-icons/all";
import DatePicker from "react-datepicker";
import moment from "moment";

const Dates = ({
  showTimeSelect = false,
  children,
  unavailableDates,
  setUnavailableDates,
  personnelForm,
  date2,
  setDate2
}) => {
  const pickerRef = useRef(null);
  const defaultText = "Add date";
  const [date, setDate] = useState(null);
  // const [date2, setDate2] = useState([]);

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const handleCompleted = () => {
    if (date) {
      const dates = `${moment(date).format("YYYY-MM-DD")}`;
      setUnavailableDates([dates, ...unavailableDates]);
      setDate2([date, ...date2]);
    }
    setDate(null);
  };
  console.log(date2);
  const removeFromList = (date) => {
    const newList = unavailableDates
      .map((item) => item !== date && item)
      .filter((data) => data && data);
    setUnavailableDates(newList);
  };

  const removeFromList2 = (idx) => {
    const newFormValues = [...date2];
    newFormValues.splice(idx, 1);
    setDate2(newFormValues);
  };

  return (
    <div className="dates">
      <FaCalendarAlt />
      <div className="dates-items">
        <div className="date-item selected" style={{ minWidth: "fit-content" }}>
          <div
            className="date-cancel"
            style={{
              width: "fit-content",
              pointerEvents: personnelForm.add_holidays ? "initial" : "none",
            }}
            disabled={personnelForm.add_holidays === true}
          >
            {date ? (
              <FaCheckCircle size={14} onClick={handleCompleted} />
            ) : (
              <FiPlusCircle
                size={14}
                onClick={(e) => {
                  // pickerRef.current._();
                  pickerRef.current.setOpen(true);
                }}
              />
            )}
          </div>
          <DatePicker
            showTimeSelect={showTimeSelect}
            onChange={handleDateChange}
            ref={pickerRef}
            minDate={new Date()}
            excludeDates={date2}
            customInput={<div />}
          />
          <span>
            <span
              onClick={(e) => {
                // pickerRef.current._();
                pickerRef.current.setOpen(true);
              }}
            >
              {date ? `${moment(date).format("MMM DD, YYYY")}` : defaultText}
            </span>
          </span>
        </div>
        {personnelForm.add_holidays === true &&
          unavailableDates.map((date, idx) => {
            return (
              <div className="date-item" key={idx}>
                <div
                  className="date-cancel"
                  onClick={(e) => {
                    removeFromList(date);
                    removeFromList2(idx);
                  }}
                >
                  <IoMdClose />
                </div>
                {moment(date).format("MMM DD, YYYY ")}
              </div>
            );
          })}

        {children}
      </div>
    </div>
  );
};

export default Dates;
