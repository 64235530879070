import axios from "../index";
import { Alert } from "reactstrap";
import { Errortext } from "../../styles";
import { colors } from "../../color";

// Automatically clears the redux state
const clear = (method, regError) => {
  regError ? regError(null) : method("");
};

export const signIn = async (values, loginError, userLogin, activate, user) => {
  try {
    clear(loginError, activate);
    const data = await axios.post("/accounts/api/v1/login/", values);
    data.status === 200 &&
      userLogin(data.data, true) &&
      clear(loginError) &&
      user(data.data.user);
    // console.log(data.data);
    return true;
  } catch (e) {
    e.response.status <= 404
      ? loginError(e.response.data.non_field_errors[0])
      : loginError("Something went wrong.");
    return false;
  }
};

export const register = async (values, userRegister, regError) => {
  try {
    clear(null, regError);
    console.log("Igothere");
    const data = await axios.post("/accounts/api/v1/register/", values);
    data.status === 201 && userRegister(data.data, true);
    return true;
  } catch (e) {
    e.response.status <= 404
      ? regError(e.response.data)
      : regError("An error occured");
    return false;
  }
};

export const passwordReset = async (value, restUserPassword) => {
  const validEmail = value.email.toLowerCase();
  try {
    clear(restUserPassword, null);
    const data = await axios.post("/password/reset/", { email: validEmail });
    data.status === 200 && restUserPassword(data.data.detail);
    return data.status;
  } catch (e) {
    throw "error" && e;
  }
};

export const confirmPasswordReset = async (value, token, uid) => {
  const { new_password1, new_password2 } = value;
  const body = {
    new_password1,
    new_password2,
    token,
    uid,
  };
  try {
    const response = await axios.post(
      "/accounts/api/v1/password/reset/confirm/",
      body
    );
    return response;
  } catch (e) {
    throw e.response.data;
  }
};

export const activate = async (params, userActivated) => {
  try {
    clear(userActivated, null);
    const response = await axios.post("/register/verify-email/", params);
    if (response.status === 200) {
      userActivated("Account activated successfully");
      return true;
    }
  } catch (e) {
    throw false || e;
  }

  return true;
};

export const logOut = async (userLogin) => {
  try {
    const response = await axios.post("/logout/");
    response.status === 200 && userLogin({}, false);
  } catch (e) {
    throw "error" || e;
  }
};

export const validate_email = async (email) => {
  if (email.trim()) {
    try {
      const body = { email: email };
      await axios.get("/check_email/", body);
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return;
  }
};
export const validate_username = async (username) => {
  if (username.trim()) {
    try {
      const body = { username: username };
      await axios.post("/check_username/", body);
      return true;
    } catch (e) {
      return false;
    }
  } else {
    return;
  }
};

export const Error_handler = (Errors, field_type) => {
  var errorMessage;
  if (typeof Errors === "object") {
    if (Errors.hasOwnProperty(field_type)) {
      if (field_type === "email") {
        const data = Errors[field_type].map((data, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {data}
          </Errortext>
        ));
        return data;
      } else if (field_type === "username") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "phone_number") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "password1") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "password2") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "date_of_birth") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "gender") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "language") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "first_name") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "last_name") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "country") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Errortext style={{ color: `${colors.ERROR}` }} key={i}>
            {msg}
          </Errortext>
        ));
        return errorMessage;
      } else if (field_type === "new_password2") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Alert color="danger" key={i}>
            {msg}
          </Alert>
        ));
        return errorMessage;
      } else if (field_type === "token") {
        const data = Errors[field_type].map((data, i) => data);
        errorMessage = data.map((msg, i) => (
          <Alert color="danger" key={i}>
            password reset session is invalid, try forgot password again to get
            a new link
          </Alert>
        ));
        return errorMessage;
      } else {
        return null;
      }
    }
  } else {
    errorMessage = <Alert color="danger">{Errors}</Alert>;
    return errorMessage;
  }
};
