import useAxios from "axios-hooks";

const useUser = () => {
  const [{ ...relatedUsersData }, getRelatedUsers] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetRelatedUsers = async (url, params = {}) => {
    getRelatedUsers({
      url: url || "/users/related/",
      params,
    });
  };

  const [{ ...followingData }, getFollowing] = useAxios(
    {
      method: "get",
    },
    { manual: true }
  );

  const handleGetFollowing = async (url, username, params = {}) => {
    return await getFollowing({
      url: url || `/users/${username}/users_following/`,
      params,
    });
  };

  const [{ ...followersData }, getFollowers] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetFollowers = async (url, username, params = {}) => {
    return await getFollowers({
      url: url || `/users/${username}/users_followers/`,
      params,
    });
  };

  const [{ ...followData }, follow] = useAxios(
    {
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleFollow = async (username) => {
    return await follow({
      url: `/users/follow/${username}/`,
    });
  };
  return {
    relatedUsersData,
    handleGetRelatedUsers,
    handleGetFollowing,
    followingData,
    followersData,
    handleGetFollowers,
    handleFollow,
    followData,
  };
};

export default useUser;
