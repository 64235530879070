import React, { createContext, useState, useContext,  useEffect } from "react"
import ModalContext from '../../../../../hooks/modalContexts'
import { FaEye, FaHeart, FaEnvelope } from 'react-icons/fa'
import person from '../../../../../assets/img/Ellipse 320.PNG';
import moment from "moment";

const ConnectCardReceived = ({
  connected,
  setMessageData,
  messageData,
  singleProfileData,
  handleGetSingleProfile,
  currentId,
  handleLike,
  likeData,
}) => {
  const {handleAcceptModal, handleMessageModal} = useContext(ModalContext)

  console.log(messageData)
  console.log(connected)

  return (
    <>  
      <div className='connect-manage-card'
         onClick={() =>{
          handleMessageModal('ADD_MESSAGE_TO_DISPLAY', 'true')
          setMessageData(connected)
        }}
        >
        <div className='connect-manage-card-left'>
          <div className='connect-manage-card-user'>
            <div className='connect-manage-card-user-head'>
              <div>
                <img src={
                    connected?.connect_profile?.page?.featured_image ? connected?.connect_profile?.page?.featured_image : person
                } alt='' />
              </div>
              <div>
                <h2>{connected?.connect_profile?.page?.name}</h2>
                <p>@{connected?.connect_profile?.page?.username ? connected?.connect_profile?.page?.username : "Username"}</p>
              </div>
            </div>
            <div className='connect-manage-iconss'>
              <div className='connect-manage-envelope'>
                <FaEnvelope />
              </div>
              <div className='connect-manage-heart favey'>
                {/*<FaHeart />*/}
              </div>
            </div>
          </div>
          <div className='connect-manage-card-text'>
            <div className='connect-manage-card-text-wrap'>
              <h1>{connected?.connect_profile?.title}</h1>
              <h3>{connected?.connect_profile?.page?.location?.address}</h3>
              <p className='connect-manage-card-p1'>{connected?.connect_profile?.page?.description}</p>
            </div>
            <div className='connectcard-button-flex'>
              <p className='connect-manage-card-p2'>
                {/*{moment(connected.expire_on).format("MMMM Do YYYY")}*/}
              </p>
              <div>
                <div className='connect-show appear'>
                  <p>Connected</p>
                </div>
              </div> 
            </div>
          </div>
        </div>
      </div>
    
   </>
  )
}

export default ConnectCardReceived
