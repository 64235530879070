import "./index.scss";
import profileImg from "../..//../../../assets/img/Ellipse 357.png";
import { Rating, RatingView } from "react-simple-star-rating";
import moment from "moment";
import { useEffect, useState } from "react";
import usePrivateSellerDashboard from "../../../../../hooks/privateSellerDashboard";
import { useLocation, useParams } from "react-router";
import ReplyItem from "./reply item";
import ReactStars from "react-stars";
import qs from "query-string";

const ReviewItem = ({ reviewItem, handleFetchReviews }) => {
  const [showReply, setShowReply] = useState();

  const { handleReplyReview, replyReviewData } = usePrivateSellerDashboard();

  const [replyText, setReplyText] = useState();

  const { search } = useLocation();

  const { id } = qs.parse(search);

  const [showReplies, setShowReplies] = useState();

  useEffect(() => {
    if (replyReviewData.data) {
      handleFetchReviews(id);
      setShowReply(false);
      setReplyText();
    }
  }, [replyReviewData.data]);

  return (
    <div className="reviews-card-body">
      <div className="rcb-heading">
        <div className="img_holder">
          <img
            src={
              reviewItem?.owner?.profile_image?.file
                ? reviewItem?.owner?.profile_image?.file
                : profileImg
            }
            alt=""
          />
        </div>
        <div className="rcb-star">
          <ReactStars
            count={5}
            value={reviewItem?.rating}
            size={20}
            edit={false}
            activeColor="orange"
            emptyColor="gray"
            className="rating-star"
          />
        </div>
        <h4 className="mb-0">{reviewItem?.owner.full_name}</h4>
        <h6>{moment(reviewItem?.created_at).format("DD MMMM YYYY")}</h6>
      </div>
      <div className="rcb-comments">
        <p> {reviewItem?.body}</p>
        {reviewItem?.replies[0] && (
          <div className="reply_box">
            {
              <button
                onClick={() => setShowReplies(!showReplies)}
                className="place_button"
              >
                {!showReplies
                  ? `See replies (${reviewItem?.reply_count})`
                  : "Hide replies"}
              </button>
            }
            {reviewItem?.replies && showReplies && (
              <div className="reply_container">
                {reviewItem?.replies?.map((replyDataItem, idx) => (
                  <ReplyItem reviewItem={replyDataItem} />
                ))}
              </div>
            )}
          </div>
        )}
        {showReply && (
          <textarea
            type="text"
            className="reply_input"
            onChange={(e) => setReplyText(e.target.value)}
          />
        )}
        <div className="reply_div">
          {!showReply && (
            <p className="reply_btn" onClick={() => setShowReply(true)}>
              Reply
            </p>
          )}
          {showReply && (
            <p
              className="reply_btn"
              onClick={() => handleReplyReview(id, replyText, reviewItem.id)}
            >
              Send
            </p>
          )}
          {showReply && (
            <p className="reply_btn" onClick={() => setShowReply(false)}>
              Cancel
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
export default ReviewItem;
