import { NavLink } from "react-router-dom";
import "./options.scss";
import { useHistory } from "react-router-dom";
import useMenu from "../../hooks/menu";
import cx from "classnames";
import { useSelector } from "react-redux";
const Options = () => {
  const history = useHistory();
  const { accountType } = useSelector((state) => state.currentUser);
  const { menu } = useMenu();
  return (
    <div className="options">
      {menu.map((opt, idx) => {
        // console.log(
        //   history.location.pathname.includes(opt.link),
        //   `/${history.location.pathname.split("/")[1]}`,
        //   opt.link
        // );
        return (
          <NavLink
            key={idx}
            to={opt.link}
            className={cx({
              selected: ["post", "comment"].filter((link) => {
                if (
                  history.location.pathname.includes(link) &&
                  opt.link.includes("feeds")
                )
                  return true;
                return false;
              }).length
                ? true
                : accountType.toLowerCase() !== "business"
                ? opt.link.includes(
                    `/${history.location.pathname.split("/")[1]}`
                  )
                : history.location.pathname.includes(opt.link),
            })}>
            {opt.icon && <opt.icon />}
            <span className="text">{opt.name}</span>
          </NavLink>
        );
      })}
    </div>
  );
};
export default Options;
