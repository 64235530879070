import React, {useEffect, createContext, useState, useContext  } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory, Link  } from "react-router-dom";
// import { useHistory } from "react-router";
import qs from "query-string";
import useAxios from "axios-hooks";
import ModalContext from './modalContexts';
import ExploreContext from "./exploreContext";

const useConnect = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { user } = useSelector((state) => state.currentUser);
  const [profileToBeDeleted, setProfileToBeDeleted] = useState();
  const {
    acceptModal, 
    handleAcceptModal, 
    handleSuccessConnectModal, 
    handleDeclineConnectModal, 
    handleRetractSuccessConnectModal,
    handleRetractModal,
    handleDeclineConnectModalCard,
    handleSuccessConnectModalCard,
    handleSuccessModal,
    handleConnectModal
  } = useContext(ModalContext)

  const { search } = useLocation();
  const { id } = qs.parse(search);

  const {
    setAcceptedCard,
    setDeclinedCard,
    setRecievedState,
    setSentState,
    setRequestReceived,
  } = useContext(ExploreContext)

  //
  //GET CONNECT REQUEST
  const [{ ...connectRequestData }, getConnectRequest] = useAxios(
    {
      url: "/connect-requests/",
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectRequest = async () => {
    await getConnectRequest({
      params: {
        sudo: true
      },
    });
  };

  //GET CONNECT REQUEST - Received true
  const [{ ...connectRequestReceivedTrueData }, getConnectRequestReceivedTrue] = useAxios(
    {
      url: "/connect-requests/",
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectRequestReceivedTrue = async (id) => {
    await getConnectRequestReceivedTrue({
      params: {
        // sudo: true,
        received: true,
        pageId: id,
        status: "Pending"
      },
    });
  };

   //GET CONNECT REQUEST - Connected true
   const [{ ...connectRequestConnectedTrueData }, getConnectRequestConnectedTrue] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectRequestConnectedTrue = async (id) => {
    await getConnectRequestConnectedTrue({
      url: "/connect-requests/",
      params: {
        // sudo: true,
        "connected": true,
        received: true,
        page: id

        // id:id
      },
    }).catch((error) => console.log(error));
  };

  //GET CONNECT REQUEST - Connected true Home
  const [{ ...connectRequestConnectedTrueDataHome }, getConnectRequestConnectedTrueHome] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectRequestConnectedTrueHome = async (id) => {
    await getConnectRequestConnectedTrueHome({
      url: "/connect-requests/",
      params: {
        // sudo: true,
        // "connected": true,
        page: id

        // id:id
      },
    }).catch((error) => console.log(error));
  };
  
  useEffect(() => {
    if (connectRequestConnectedTrueData?.data) {
      console.log(connectRequestConnectedTrueData?.data)
      // history.push("/connect")
    }
  }, [connectRequestConnectedTrueData?.data]);


   //GET CONNECT REQUEST with PROFILE - Connected true
   const [{ ...connectRequestProfileConnectedTrueData }, getConnectRequestProfileConnectedTrue] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectRequestProfileConnectedTrue = async (proId) => {
    await getConnectRequestProfileConnectedTrue({
      url: "/connect-requests/",
      params: {
        "send": true,
        "connected": true,
        // "page": pageId,
        "connect_profile": proId,
        
      },
    }).catch((error) => console.log(error));
  };
  
  useEffect(() => {
    if (connectRequestConnectedTrueData?.data) {
      console.log(connectRequestConnectedTrueData?.data)
      // history.push("/connect")
    }
  }, [connectRequestConnectedTrueData?.data]);

     //GET CONNECT REQUEST - Sent true
     const [{ ...connectRequestSentTrueData }, getConnectRequestSentTrue] = useAxios(
      {
        url: "/connect-requests/",
        method: "GET",
      },
      {
        manual: true,
      }
    );
  
    const handleGetConnectRequestSentTrue = async () => {
      await getConnectRequestSentTrue({
        params: {
          // sudo: true,
          send: true
        },
      });
    };

// CONNECT REQUEST CONNECTED=TRUE
  const [{ ...connectRequestDataConnected }, getConnectRequestConnected] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectRequestConnected = async (id) => {
    await getConnectRequestConnected({
      url: `connect-profiles/${id}/connected_pages/`,
      params: {
        // connected: true,
        // sudo: true
      },
    }).catch((error) => console.log(error));
  };


  // CONNECT REQUEST RECIEVED=TRUE
    const [{ ...connectRequestDataReceived }, getConnectRequestReceived] = useAxios(
      {
        method: "GET",
      },
      {
        manual: true,
      }
    );

    const handleGetConnectRequestReceived = async (page_id) => {
      await getConnectRequestReceived({
        url: `/connect-profiles/${page_id}/received_connect_request/`,
        params: {
          // received: true,
          // sudo: true
        },
      });
    };

    // CONNECT REQUEST SENT=TRUE
      const [{ ...connectRequestDataSent }, getConnectRequestSent] = useAxios(
        {
          method: "GET",
        },
        {
          manual: true,
        }
      );

      const handleGetConnectRequestSent = async (proId) => {
        await getConnectRequestSent({
          url: "/connect-requests/",
          params: {
            send: true,
            status: "Pending",
            // "page": pageId,
            "connect_profile": proId,
          },
        });
      };

      useEffect(() => {
        if(connectRequestDataSent?.data){
          console.log(connectRequestDataSent?.data)
          // handleRetractSuccessConnectModal('RESET_RETRACT_SUCCESS_CONNECT_TO_DISPLAY')
        }
      }, [connectRequestDataSent?.data])

  // GET CONNECT PROFILE
    const [{ ...connectProfileData }, getConnectProfile] = useAxios(
      {
        url: "/connect-profiles/",
        method: "GET",
      },
      {
        manual: true,
      }
    );

    const handleGetConnectProfile = async (id) => {
      await getConnectProfile({
        params: {
          page: id,
          // sudo: true
        },
      });
    };

// GET CONNECT PROFILE SUGGESTED
  const [{ ...connectProfileDataSuggested }, getConnectProfileSuggested] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectProfileSuggested = async (id) => {
    await getConnectProfileSuggested({
      url: `connect-profiles/${id}/suggest_pages/`,
    }).catch((error) => console.log(error));
  };

  // GET CONNECT PROFILE SUGGESTED HOME
    const [{ ...connectProfileDataHome }, getConnectProfileHome] = useAxios(
      {
        method: "GET",
      },
      {
        manual: true,
      }
    );

    const handleGetConnectProfileHome = async (id) => {
      await getConnectProfileHome({
        url: `connect-profiles/`
      }).catch((error) => console.log(error));
    };


// GET CONNECT PROFILE FAVORITE
const [{ ...connectProfileDataFavorite }, getConnectProfileFavorite] = useAxios(
  {
    url: "/connect-profiles/",
    method: "GET",
  },
  {
    manual: true,
  }
);

const handleGetConnectProfileFavorite = async () => {
  await getConnectProfileFavorite({
    params: {
      favorite: true,
      // sudo: true
    },
  });
};

// CONNECT PROFILE CONNECTED=TRUE
  const [{ ...connectProfileDataConnected }, getConnectProfileConnected] = useAxios(
    {
      url: "/connect-profiles/",
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetConnectProfileConnected = async () => {
    await getConnectProfileConnected({
      params: {
        connected: true,
        // sudo: true
      },
    });
  };

  //
  // POST CONNECT REQUEST
  const [{ ...connectRequestDataPost }, getConnectRequestPost] = useAxios(
    {
      url: "/connect-requests/",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handlePostConnectRequest = async () => {
    await getConnectRequestPost({
      params: {
        // sudo: true
      }
    });
  };

  //
  // POST CONNECT PROFILE
  const [{ ...postProfileData }, getConnectProfilePost] = useAxios(
    {
      url: "/connect-profiles/",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handlePostConnectProfile = async () => {
    await getConnectProfilePost();
  };

  // const handlePostConnectProfile = async ({ title, page, service_locations, business_category, connect_to_category, looking_for, description, tags }) => {
  //   getConnectProfilePost({
  //     data: {
  //       title, page, service_locations, business_category, connect_to_category, looking_for, description, tags
  //     },
  //   }).catch((err) => console.log(err));
  // };


  //
  //GET CONNECT REQUEST PARAM CONNECTED

  // const [{ ...connectRequestDataConnected }, getConnectRequestConnected] =
  //   useAxios(
  //     {
  //       method: "GET",
  //     },
  //     {
  //       manual: true,
  //     }
  //   );
  //
  // const handlePostConnectRequestConnected = async ({
  //   connected = true,
  //   created_at = "12-22-2022",
  // }) => {
  //   await getConnectRequestConnected({
  //     url: `/connect-requests/?connected=${connected}/ordering=${created_at}`,
  //   });
  // };

  const [{ ...categoryData }, getCategory] = useAxios(
    {
      url: "/categories",
      method: "GET",
    },
    {
      manual: true,
    }
  );

const handleCategoryData = async () => {
  await getCategory();
}

 //Create a Profile
 const [{ ...createProfilePageData}, createProfilePage] = useAxios({
  method: "POST",
});

const handleCreateProfilePage = async (item) => {
  await createProfilePage({
    url: "/connect-profiles/",
    data: {
      ...item,
    },
  }).catch((error) => console.log(error));
};


// Update Post
const [{ ...updateProfileData }, updateProfile] = useAxios(
  {
    method: "patch",
  },
  {
    manual: true,
  }
);

const handleUpdateProfile = (id, data) => {
  updateProfile({
    url: `/connect-profiles/${id}/`,
    data: data
  }).catch((error) => {});
};

useEffect(() => {
  if(updateProfileData?.data){
    window.location.reload(false);
    console.log("SUCCESS EDIT")
  }
}, [updateProfileData?.data]);


// Retrieve Post
const [{ ...singleProfileData }, getSingleProfile] = useAxios(
  {
    method: "get",
  },
  {
    manual: true,
  }
);

const handleGetSingleProfile = async (id) => {
  try {
    getSingleProfile({
      url: `/connect-profiles/${id}`,
    });
  } catch (error) {}
};

useEffect(() => {
  if (singleProfileData?.data) {
    console.log(singleProfileData.data.title);
    // dispatch(createSingleComment(singleCommentData.data));
  }
}, [singleProfileData?.data]);


// Delete profile
const [{ ...deleteProfileData }, deleteProfile] = useAxios(
  {
    method: "DELETE",
  },
  {
    manual: true,
  }
);

const handleDeleteProfile = (id) => {
  deleteProfile({
    url: `/connect-profiles/${id}/`,
  }).catch((error) => console.log(error));
};

useEffect(() => {
  if(deleteProfileData?.data){
    window.location.reload()
  }
}, [deleteProfileData?.data])


// RETRACT CONNECT
const [{ ...retractConnectData }, retractConnect] = useAxios(
  {
    method: "DELETE",
  },
  {
    manual: true,
  }
);

const handleRetractConnect = (id) => {
  retractConnect({
    url: `/connect-requests/${id}/retract/`,
  }).catch((error) => console.log(error));
};

useEffect(() => {
  console.log(retractConnectData?.data);
  if (retractConnectData?.data) {
    handleRetractSuccessConnectModal('ADD_RETRACT_SUCCESS_CONNECT_TO_DISPLAY', 'true')
    handleRetractModal('RESET_RETRACT_TO_DISPLAY')
    // setSentState(connectRequestDataSent?.data?.results)

    console.log("RETRACT", retractConnectData.data)

  }
}, [retractConnectData?.data]);


// RETRACT CONNECT FROM CARD
const [{ ...retractConnectDataCard }, retractConnectCard] = useAxios(
  {
    method: "DELETE",
  },
  {
    manual: true,
  }
);

const handleRetractConnectCard = (id) => {
  retractConnectCard({
    url: `/connect-requests/${id}/retract/`,
  }).catch((error) => console.log(error));
};



// Hide profile
const [{ ...hideProfileData }, hideProfile] = useAxios(
  {
    method: "POST",
  },
  {
    manual: true,
  }
);

const handleHideProfile = (id) => {
  hideProfile({
    url: `/connect-profiles/${id}/hide/`,
  }).catch((error) => {});
};

useEffect(() => {
  if(hideProfileData?.data){
    console.log(hideProfileData.data)
    // handleGetConnectRequest();
    // handleGetConnectProfile();
  }
}, [hideProfileData?.data]);


// LIKE
const [{ ...likeData }, like] = useAxios(
  {
    method: "POST",
  },
  {
    manual: true,
  }
);
const handleLike = (profile_id, page_id) => {
  like({
    // url: `/pages/${id}/likes/`,    Previous LIke URL
    url: `/connect-profiles/${profile_id}/page_like/${page_id}/`
  }).catch((error) => {});
};

// GET LIKED PAGES

// LIKE
const [{ ...likedPagesData }, likedPages] = useAxios(
  {
    method: "GET",
  },
  {
    manual: true,
  }
);
const handleLikedPages = (profile_id) => {
  likedPages({
    // url: `/users/page_likes/`,
    url: `connect-profiles/${profile_id}/favorite/`
  }).catch((error) => {});
};

useEffect(() => {
  if(likedPagesData?.data){
    console.log(likedPagesData?.data)
  }
}, [likeData?.data]);


// Like profile
// const [{ ...likeProfileData }, likeProfile] = useAxios(
//   {
//     method: "POST",
//   },
//   {
//     manual: true,
//   }
// );
//
// const handleLikeProfile = async (id, item) => {
//   try {
//     await likeProfile({
//       url: `/connect-profiles/${id}/likes/`,
//       params: {
//         id: user.user_id,
//       },
//       data: {
//         ...item,
//       },
//     });
//   } catch (err) {
//     console.log(err);
//   }
// };
// useEffect(() => {
//   if (likeProfileData.data) {
//     //update redux for single comments and replies
//     // dispatch(updateSingleCommentLike(likeCommentDataV2.data)) &&
//     //   dispatch(updateReplyLikes(likeCommentDataV2.data));
//     console.log(likeProfileData.data);
//   }
// }, [likeProfileData.data]);

//Send Connect Request
const [{ ...sendConnectRequestData}, sendConnectRequest] = useAxios({
  method: "POST",
});

const handleSendConnectRequest = async (item) => {
  await sendConnectRequest({
    url: "/connect-requests/",
    data: {
      ...item,
    },
    params: {
      // sudo: true
    },
  }).catch((error) => console.log(error));
};

useEffect(() => {
  console.log(sendConnectRequestData?.data)
  if (sendConnectRequestData?.data) {
    handleSuccessModal('ADD_SUCCESS_TO_DISPLAY', 'true');
    handleConnectModal('RESET_CONNECT_TO_DISPLAY');
    // history.push("/connect")
  }
}, [sendConnectRequestData?.data]);


// Respond Connect Request from Modal
const [{ ...respondConnectRequestData}, respondConnectRequest] = useAxios({
  method: "POST",
});
const handleRespondConnectRequest = async (id, data) => {
  respondConnectRequest({
      url: `/connect-requests/${id}/respond/`,
      data: {
        ...data
      },
      params: {
        // sudo: true
      },
    }).catch((error) => console.log(error));
};

useEffect(() => {
  // console.log(respondConnectRequestData?.data?.status)
   if(respondConnectRequestData?.data?.status === "Accept"){
          handleSuccessConnectModal('ADD_SUCCESS_CONNECT_TO_DISPLAY', 'true');
          handleAcceptModal('RESET_ACCEPT_TO_DISPLAY')
          // setRequestReceived(connectRequestDataReceived?.data?.results);
          console.log(respondConnectRequestData?.data?.status)
          console.log(respondConnectRequestData)
          handleGetConnectRequestConnectedTrue()
          handleGetConnectRequestReceivedTrue(id)

       } 
   if(respondConnectRequestData?.data?.status === "Decline"){
          handleDeclineConnectModal('ADD_DECLINE_CONNECT_TO_DISPLAY', 'true');
          handleAcceptModal('RESET_ACCEPT_TO_DISPLAY')
          // setRequestReceived(connectRequestDataReceived?.data?.results);
          console.log(respondConnectRequestData?.data?.status)
          handleGetConnectRequestConnectedTrue()
          handleGetConnectRequestReceivedTrue(id)
        } 
       
}, [respondConnectRequestData?.data]);


// Respond Connect Request Cards
const [{ ...respondConnectRequestCardData}, respondConnectRequestCard] = useAxios({
  method: "POST",
});
const handleRespondConnectRequestCard = async (id, data) => {
  respondConnectRequestCard({
      url: `/connect-requests/${id}/respond/`,
      data: {
        ...data
      },
      params: {
        // sudo: true
      },
    }).catch((error) => console.log(error));
};


// Disconnect Connect
const [{ ...disconnectConnectRequestData}, disconnectConnectRequest] = useAxios({
  method: "DELETE",
});

const handleDisconnectConnectRequest = async (id) => {
  await disconnectConnectRequest({
    url: `/connect-requests/${id}`,
    params: {
      // sudo: true
    },
  }).catch((error) => console.log(error));
};

useEffect(() => {
  if (disconnectConnectRequestData?.data) {
    console.log(disconnectConnectRequestData?.data)
    // handleGetConnectRequestConnectedTrue();
    // history.push("/connect")
  }
}, [disconnectConnectRequestData?.data]);


  return {
    handleGetConnectRequest,
    connectRequestData,
    handleGetConnectProfile,
    connectProfileData,
    handlePostConnectRequest,
    connectRequestDataPost,
    handlePostConnectProfile,
    postProfileData,
    // handlePostConnectRequestConnected,
    connectRequestDataConnected,
    handleCategoryData,
    categoryData,
    handleCreateProfilePage,
    createProfilePageData,
    singleProfileData,
    handleGetSingleProfile,
    handleUpdateProfile,
    updateProfileData,
    // handleLikeProfile,
    handleLike,
    likeData,
    handleHideProfile,
    hideProfileData,
    handleDeleteProfile,
    handleSendConnectRequest,
    handleRespondConnectRequest,
    sendConnectRequestData,
    connectProfileDataSuggested,
    handleGetConnectProfileSuggested,
    connectProfileDataFavorite,
    handleGetConnectProfileFavorite,
    handleGetConnectRequestConnected,
    connectRequestDataConnected,
    handleGetConnectRequestReceived,
    connectRequestDataReceived,
    handleGetConnectRequestSent,
    connectRequestDataSent,
    retractConnectData,
    handleRetractConnect,
    handleGetConnectProfileConnected,
    connectProfileDataConnected,
    handleLikedPages,
    likedPagesData,
    handleGetConnectProfileHome,
    connectProfileDataHome,

    connectRequestReceivedTrueData,
    handleGetConnectRequestReceivedTrue,
    connectRequestConnectedTrueData,
    handleGetConnectRequestConnectedTrue,
    connectRequestSentTrueData,
    handleGetConnectRequestSentTrue,

    handleDisconnectConnectRequest,
    disconnectConnectRequestData,

    connectRequestProfileConnectedTrueData,
    handleGetConnectRequestProfileConnectedTrue,
  
    respondConnectRequestCardData,
    handleRespondConnectRequestCard,

    retractConnectDataCard,
    handleRetractConnectCard,

    handleGetConnectRequestConnectedTrueHome,
    connectRequestConnectedTrueDataHome


  };
};

export default useConnect;
