/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearFlagComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 15.082 15.082" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <path className="a" d="M0,0V12.568" transform="translate(4.053 1.257)" />
    <path
      className="a"
      d="M0,0,5.09,2.2c2.074.88,2.074,2.388.126,3.393L0,8.169"
      transform="translate(4.367 2.514)"
    />
    <path className="b" d="M0,0H15.082V15.082H0Z" />
  </svg>
);

export default VuesaxLinearFlagComponent;
