import profilePic from '../../../../../assets/img/profilePic.png'
const AdminUserItem = ({activeTab, data, idx, handleSetActiveTab}) => {
    return(
        <li onClick={()=> handleSetActiveTab(idx)} className={`${activeTab === idx && 'active'}`}>
            {
                activeTab === idx && <div className="yellowDiv"></div>
            }
            <div className="adminUser_content">
                <div className="img">
                    <img src={data.admin?.profile_image?.file} alt="" />
                </div>
                <div className="textContent">
                    <h3>
                        {data?.admin?.full_name}
                    </h3>
                    <p>
                        @{data?.admin?.username}
                    </p>
                </div>
            </div>
        </li>
    )
}
export default AdminUserItem