import { useState, useEffect, useContext } from "react";
import CustomDropdown from "../../../components/Analytics/components/customDropdown";
import AreaChart from "../../../components/Analytics/Charts/areaChart";
import { BiInfoCircle, BiUpArrowAlt } from "react-icons/bi";
import { useLocation, useHistory, Link } from "react-router-dom";
import BigEye from "../../../assets/svg/bigEye";
import RateIcon from "../../../assets/svg/rateIcon";
import ArrowNarrowUp from "../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../assets/svg/arrowNarrowDown";
import ChartStats from "../../../components/Analytics/components/chartStats";
import GroupUserIcon from "../../../components/Analytics/components/orderCompletion";
import OrderCompletion from "../../../components/Analytics/components/orderCompletion";
import itemImg from "../../../assets/img/Rectangle 1890.PNG";
import "../analytics.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import SalesGrowthChartPrivate from "../../../components/Analytics/components/salesGrowthPrivate";
import SalesGrowthChart from "../../../components/Analytics/components/salesGrowth";
import WalletMoney from "../../../assets/svg/wallet-money";
import TotalEarningsChartPrivate from "../../../components/Analytics/components/totalEarningsChartPrivate";
import TotalEarningsChart from "../../../components/Analytics/components/totalEarnings";
import Bag from "../../../assets/svg/bag";
import BagTick from "../../../assets/svg/bagTick";
import BagCross from "../../../assets/svg/bagCross";
import BagHappy from "../../../assets/svg/bagHappy";
// import BarChart from "../../../components/Analytics/Charts/barChart";
import ModalContext from "../../../hooks/modalContexts";
import usePrivateAnalytics from "../../../hooks/PrivateAnalytics";
import usePages from "../../../hooks/createPage";
import qs from "query-string";
import AreaChartPrivate from "../../../components/Analytics/Charts/areaChartPrivate";
import BarChart from "../../../components/Analytics/Charts/barChartDemographicListing";
import useAnalytics from "../../../hooks/Analytics";
import ListingAnalytics from "../AnalyticsTabs/Listing/listingAnalytics";
import BarChartPrivate from "../../../components/Analytics/Charts/barChartDemographicListingPrivate";

const PrivateSellerAnalyticsBody = () => {
  const dropdownData = [
    { label: "Page Interaction" },
    { label: "Demographic" },
    { label: "Order completion" },
    // { label: "Ads converted" },
  ];
  const dropdownData2 = [
    { label: "Likes" },
    { label: "Shares" },
    { label: "Refer" },
    { label: "Clicks" },
  ];
  const [activeTab, setActiveTab] = useState("city");
  /* datepicker */
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [value, setValue] = useState(dropdownData[0].label);
  const [value2, setValue2] = useState(dropdownData2[0].label);

  // const [selectedOption, setSelectedOption] = useState(pickupOptions[0]);

  const { search } = useLocation();
  const { id } = qs.parse(search);

  const { singlePageData, handleGetSinglePage } = usePages();

  useEffect(() => {
    handleGetSinglePage(id);
  }, [id]);

  const {
    privatesellerAnalyticsInteractionData,
    handleGetPrivatesellerAnalyticsInteraction,
    privatesellerAnalyticsChartData,
    handleGetPrivatesellerAnalyticsChart,
    privatesellerAnalyticsPageInteractionData,
    handleGetPrivatesellerAnalyticsPageInteraction,
    handleGetPrivatesellerAnalyticsInteractionDate,
    privatesellerAnalyticsInteractionDateData,
  } = usePrivateAnalytics();
  // const { analyticsListingChartData, analyticsListingInteraction } =
  //   useAnalytics();

  const {
    analyticsGeneralData,
    handleGetAnalyticsGeneral,

    // Listing Interactions
    analyticsListingInteraction,
    handleGetAnalyticsListingInteraction,

    // Listing Chart
    analyticsListingChartData,
    handleGetAnalyticsListingChart,

    analyticsListingOthersData,
    handleGetAnalyticsListingOthers,

    // Purchase listing
    analyticsPurchaseListingData,
    handleGetAnalyticsPurchaseListing,

    // Interacted Listing
    analyticsInteractedListingData,
    handleGetAnalyticsInteractedListing,

    // Listing AdConverted
    analyticsAdConvertedListingData,
    handleGetAnalyticsAdConvertedListing,

    analyticsDemographicListingData,
    handleGetAnalyticsDemographicListing,
    analyticsOrderCompletionListingData,
    handleGetAnalyticsOrderCompletionListing,
  } = useAnalytics();

  const pageInteraction =
    privatesellerAnalyticsPageInteractionData?.data?.results;

  // const listingChart = analyticsListingChartData?.data;
  // const listingInteractions = analyticsListingInteraction?.data;

  useEffect(() => {
    handleGetPrivatesellerAnalyticsInteraction(id);
    handleGetPrivatesellerAnalyticsChart(id);
    handleGetPrivatesellerAnalyticsPageInteraction(id);
  }, []);

  useEffect(() => {
    handleGetAnalyticsGeneral(id);
    handleGetAnalyticsListingOthers(id);

    // Purchase listing
    handleGetAnalyticsPurchaseListing(id);

    // Interacted Listing
    handleGetAnalyticsInteractedListing(id);

    // AdConverted
    handleGetAnalyticsAdConvertedListing(id);

    handleGetAnalyticsDemographicListing(id);
    handleGetAnalyticsOrderCompletionListing(id);

    // Listing Interactions
    handleGetAnalyticsListingInteraction(id);

    // Listing Chart
    handleGetAnalyticsListingChart(id);
  }, []);

  const handleChange = () => {
    setValue(dropdownData.label);
  };

  console.log(
    "privatesellerAnalyticsInteractionData",
    privatesellerAnalyticsInteractionData
  );
  console.log(
    "privatesellerAnalyticsPageInteractionData",
    privatesellerAnalyticsPageInteractionData
  );
  console.log(
    "privatesellerAnalyticsChartData",
    privatesellerAnalyticsChartData
  );

  const testArray = [1, 2, 3, 4, 5, 6, 7];
  const { setPageOrderModal } = useContext(ModalContext);
  const { setReferralOrderModal } = useContext(ModalContext);
  const { setPageViewsAnalyticModal } = useContext(ModalContext);
  const { setCustomerConversionModal } = useContext(ModalContext);
  const { setCompletedOrderModal } = useContext(ModalContext);
  const { setInCompleteOrderModal } = useContext(ModalContext);
  const { setCancelledOrderModal } = useContext(ModalContext);
  const { setPreOrderMetModal } = useContext(ModalContext);
  const { setPreOrderNotMetModal } = useContext(ModalContext);

  console.log("startDate", startDate);
  console.log("endDate", endDate);

  // Listing Interactions
  const listingInteractions = analyticsListingInteraction?.data;

  // Listing Chart
  const listingChart = analyticsListingChartData?.data;

  // Purchase listing
  const purchaseListing = analyticsPurchaseListingData?.data?.results;

  // Interacted
  const interactedListing = analyticsInteractedListingData?.data?.results;

  // AdConverted
  const adConverted = analyticsAdConvertedListingData?.data?.results;

  // Sales Growth
  const others = analyticsListingOthersData?.data?.sales_growth;

  // Demography
  const demographicListing = analyticsDemographicListingData?.data?.age;

  const orderCompletion = analyticsOrderCompletionListingData?.data;

  // console.log(demographicListing?.months);
  console.log("interactedListing", interactedListing);
  console.log("pageInteraction", privatesellerAnalyticsPageInteractionData);

  return (
    <div className="ayc-contents">
      <div className="ayc-select-box">
        <h6>Private Seller </h6>
        <div className="datepicker-container">
          <DatePicker
            // selected={startDate}
            startDate={startDate}
            endDate={endDate}
            onChange={(dates) => {
              setStartDate(dates[0]);
              setEndDate(dates[1]);
              handleGetPrivatesellerAnalyticsInteraction(
                id,
                moment(dates[0]).format("YYYY-MM-DD"),
                moment(dates[1]).format("YYYY-MM-DD")
              );
            }}
            selectsRange
            customInput={
              <div className="custom-date">
                {moment(startDate).format("MMM Do, yyyy ")} -{" "}
                {moment(endDate || startDate).format("MMM Do, yyyy ")}{" "}
              </div>
            }
          />
        </div>
      </div>

      <div className="private-seller-analytics-container">
        <div className="psa-card-body">
          <div className="psa-contents-one">
            <SalesGrowthChartPrivate
              image={<WalletMoney />}
              title="listing"
              height={250}
              width={700}
              privatesellerAnalyticsChartData={privatesellerAnalyticsChartData}
              count={
                privatesellerAnalyticsChartData?.data?.listing?.count?.chart
              }
              sum={privatesellerAnalyticsChartData?.data?.listing?.sum?.chart}
              month={
                privatesellerAnalyticsChartData?.data?.listing?.count
              }
              // AnalyticsOthers={AnalyticsOthers}
              // listingInteractions={listingInteractions}
              // listingChart={listingChart}
              // others={others}
            />
          </div>
          <div className="psa-contents-two">
            <TotalEarningsChartPrivate
              title="listing"
              privatesellerAnalyticsInteractionData={
                privatesellerAnalyticsInteractionData
              }
              // listingInteractions={listingInteractions}
            />
            {/* <TotalEarningsChart
              title="Listing"
              listingInteractions={listingInteractions}
            /> */}
          </div>
        </div>
        <div className="psa-card-body-two">
          <div className="psabt-contents-one">
            <div className="psa-header">
              <p>show</p>
              <div className="dropbox">
                <CustomDropdown
                  value={value}
                  setValue={setValue}
                  items={dropdownData}
                  onChange={handleChange}
                />
              </div>
              <div className="tabs">
                {/* ----------  nav tab -------- */}

                <div className="tab-title">{value}</div>
              </div>
            </div>
            <div className="psa-body">
              {/* ************ PAGE INTERACTION ************* */}
              {value === dropdownData[0].label && (
                <div className="psa-body">
                  <div className="dropbox2">
                    <CustomDropdown
                      value={value2}
                      setValue={setValue2}
                      items={dropdownData2}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="item__scroll">
                    {privatesellerAnalyticsPageInteractionData?.data?.results.map(
                      (data, idx) => (
                        <div className="psab-details">
                          <div className="details-box-one">
                            <h4>{idx + 1}</h4>
                            <img
                              src={data?.gallary[0]?.file || itemImg}
                              alt=""
                            />
                            {/* <img src={data?.image.map(item => item.file ? item.file : itemImg)} alt="Listing Image"  /> */}
                            <div className="details-box-two">
                              <h5>{data?.title}</h5>
                              <h6>
                                Price: <span>${data?.price}</span>
                              </h6>
                            </div>
                          </div>
                          <div className="details-value">
                            <p> {data?.order_count} sold</p>
                          </div>
                          <div className="psab-chart">
                            <p>
                              {data?.summary.summary.percentage}%{" "}
                              {data?.summary.summary.direction === "UP" ? (
                                <ArrowNarrowUp />
                              ) : (
                                <ArrowNarrowDown />
                              )}
                            </p>
                            <AreaChart data={data} />
                          </div>
                        </div>
                      )
                    )}
                    {/* {pageInteraction?.map((data, idx) => (
                      <div className="psab-details">
                        <div className="details-box-one">
                          <h4>{idx + 1}</h4>
                          <img src={data?.gallary[0].file || itemImg} alt="" />
                          <div className="details-box-two">
                            <h5>{data?.title}</h5>
                            <h6>
                              Price: <span>${data?.price}</span>
                            </h6>
                          </div>
                        </div>
                        <div className="details-value">
                          <p> 20 likes</p>
                        </div>
                        <div className="psab-chart">
                          <p>
                            {data?.summary.summary.percentage}%{" "}
                            {data?.summary.summary.direction === "UP" ? (
                              <ArrowNarrowUp />
                            ) : (
                              <ArrowNarrowDown />
                            )}{" "}
                          </p>
                          <AreaChartPrivate data={data} />
                        </div>
                      </div>
                    ))} */}
                  </div>
                  <button className="btn-view-more">view more</button>
                </div>
              )}

              {/* ************ DEMOGRAPHIC ************ */}
              {value === dropdownData[1].label && (
                <div className="psa-body">
                  <div className="dmc-card">
                    <div className="dmc-tabs tabs">
                      {/* ----------  nav tab -------- */}
                      <ul className="nav-tab">
                        <li
                          className={activeTab === "city" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("city");
                          }}
                        >
                          City
                        </li>
                        <li
                          className={activeTab === "age" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("age");
                          }}
                        >
                          Age
                        </li>
                      </ul>
                    </div>
                    <div className="tabs">
                      {/* ----------  nav tab -------- */}
                      <ul className="nav-tab">
                        <li
                          className={activeTab === "city" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("city");
                          }}
                        >
                          Canada
                        </li>
                        {/* <li
                          className={activeTab === "age" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("age");
                          }}
                        >
                          Canada
                        </li>
                        <li
                          className={activeTab === "age" ? "active" : ""}
                          onClick={() => {
                            setActiveTab("age");
                          }}
                        >
                          Canada
                        </li> */}
                      </ul>
                    </div>
                    <BarChartPrivate
                      privatesellerAnalyticsChartData={
                        privatesellerAnalyticsChartData
                      }
                      listingChart={listingChart}
                    />
                  </div>
                </div>
              )}

              {/* ************ ORDER COMPLETION ************ */}
              {value === dropdownData[2].label && (
                <div className="orderc-body">
                  <OrderCompletion
                    image={<Bag />}
                    title="Total Order"
                    orderValue={`${privatesellerAnalyticsInteractionData?.data?.total_order?.total_number}`}
                    // orderValue={listingInteractions?.total_order?.total}
                    statPercent={`${privatesellerAnalyticsInteractionData?.data?.total_order?.summary.percentage}%`}
                    orderPercent={
                      privatesellerAnalyticsInteractionData?.data?.total_order
                        ?.summary?.percentage + "%"
                    }
                    bgColor="rgba(220,220,220,0.4)"
                    bgColor2="#000"
                    onClick={(e) => setPageOrderModal(true)}
                    // orderCompletion={orderCompletion}
                    upAndDown={
                      privatesellerAnalyticsInteractionData?.data?.total_order
                        ?.summary?.direction === "UP" ? (
                        <ArrowNarrowUp />
                      ) : (
                        <ArrowNarrowDown />
                      )
                    }
                  />
                  <OrderCompletion
                    image={<BagTick />}
                    title="Completed Order"
                    orderValue={`${privatesellerAnalyticsInteractionData?.data?.completed_order?.summary?.difference} of ${privatesellerAnalyticsInteractionData?.data?.completed_order?.total_number}`}
                    orderPercent={
                      Math.round(
                        `${privatesellerAnalyticsInteractionData?.data?.completed_order?.summary.percentage}`
                      ) + "%"
                    }
                    bgColor="rgba(61,213,152,0.4)"
                    bgColor2="#3dd598"
                    // listingInteractions={listingInteractions}
                    upAndDown={
                      privatesellerAnalyticsInteractionData?.data
                        ?.completed_order?.summary.direction === "UP" ? (
                        <ArrowNarrowUp />
                      ) : (
                        <ArrowNarrowDown />
                      )
                    }
                    onClick={(e) => setCompletedOrderModal(true)}
                  />
                  <OrderCompletion
                    image={<BagHappy />}
                    // title="Order checked out"
                    title="Incomplete order"
                    orderValue={`${privatesellerAnalyticsInteractionData?.data?.incompleted_order?.summary?.difference} of ${privatesellerAnalyticsInteractionData?.data?.incompleted_order?.total_number}`}
                    orderPercent={
                      privatesellerAnalyticsInteractionData?.data
                        ?.incompleted_order?.summary.percentage + "%"
                    }
                    bgColor="rgba(255,156,0,0.4)"
                    bgColor2="#fbb03b"
                    onClick={(e) => setInCompleteOrderModal(true)}
                    // listingInteractions={listingInteractions}
                    upAndDown={
                      privatesellerAnalyticsInteractionData?.data
                        ?.incompleted_order?.summary.direction === "UP" ? (
                        <ArrowNarrowUp />
                      ) : (
                        <ArrowNarrowDown />
                      )
                    }
                  />
                  <OrderCompletion
                    image={<BagCross />}
                    // title="Incomplete order"
                    title="Canceled Order"
                    orderValue={`${privatesellerAnalyticsInteractionData?.data?.incompleted_order?.summary?.difference} of ${privatesellerAnalyticsInteractionData?.data?.incompleted_order?.total_number}`}
                    orderPercent={
                      privatesellerAnalyticsInteractionData?.data
                        ?.incompleted_order?.summary.percentage + "%"
                    }
                    bgColor="rgba(242,81,81,0.4)"
                    bgColor2="#c10000"
                    onClick={(e) => setCancelledOrderModal(true)}
                    // listingInteractions={listingInteractions}
                    upAndDown={
                      privatesellerAnalyticsInteractionData?.data
                        ?.incompleted_order?.summary.direction === "UP" ? (
                        <ArrowNarrowUp />
                      ) : (
                        <ArrowNarrowDown />
                      )
                    }
                  />
                </div>
              )}

              {/* ************ INTERACTED LISTING ************ */}
        

              {/* ************ ADS CONVERTED ************ */}
              {/* {value === dropdownData[3].label && (
                <div className="psa-body">
                  <div className="item__scroll">
                    {testArray.map((data, idx) => (
                      <div className="psab-details">
                        <div className="details-box-one">
                          <h4>001</h4>
                          <img src={itemImg} alt="" />
                          <div className="details-box-two">
                            <h5>Food and carbage</h5>
                            <h6>
                              Price: <span>$10</span>
                            </h6>
                          </div>
                        </div>

                        <div className="psab-chart">
                          <p>
                            2.3% <ArrowNarrowUp />
                          </p>
                          <AreaChart />
                        </div>
                      </div>
                    ))}
                  </div>
                  <button className="btn-view-more">view more</button>
                </div>
              )} */}
            </div>
          </div>
          <div className="dmc-stats">
            <ChartStats
              image={<WalletMoney />}
              title="Total orders made on page"
              statPercent={`${privatesellerAnalyticsInteractionData?.data?.total_order?.summary.percentage}%`}
              // statPercent={
              //   listingInteractions?.total_order?.summary.percentage + "%"
              // }
              statTime="in less than a week"
              statTotal={
                privatesellerAnalyticsInteractionData?.data?.total_order
                  ?.total_number
              }
              // statTotal={listingInteractions?.total_order?.total + "%"}
              onClick={(e) => setPageOrderModal(true)}
            />
            <ChartStats
              image={<BigEye />}
              title="Page visits"
              statPercent={`${privatesellerAnalyticsInteractionData?.data?.page_visit?.summary.percentage}%`}
              // statPercent={
              //   Math.round(
              //     `${listingInteractions?.number_of_referrals?.summary.percentage}`
              //   ) + "%"
              // }
              statTime="in less than a week"
              statTotal={
                privatesellerAnalyticsInteractionData?.data?.page_visit
                  ?.total_number
              }
              // statTotal={listingInteractions?.number_of_referrals?.total + "%"}
              // onClick={(e) => setPageViewsAnalyticModal(true)}
            />
            <ChartStats
              image={<RateIcon />}
              title="Customer conversion rate"
              // statPercent={`${privatesellerAnalyticsInteractionData?.data?.total_commission_paid?.summary.percentage}%`}
              statPercent={`${privatesellerAnalyticsInteractionData?.data?.customer_conversion_rate?.summary.percentage}%`}
              // statPercent={
              //   Math.round(
              //     `${listingInteractions?.conversion_rate?.summary.percentage}`
              //   ) + "%"
              // }
              statTime="in less than a week"
              statTotal={
                privatesellerAnalyticsInteractionData?.data
                  ?.customer_conversion_rate?.total_number
              }
              // statTotal={
              //   Math.round(`${listingInteractions?.conversion_rate?.rate}`) +
              //   "%"
              // }
              // onClick={(e) => setCustomerConversionModal(true)}
            />
          </div>
        </div>
      </div>

      {/* <ListingAnalytics
        // AnalyticsOthers={AnalyticsOthers}
        others={others}
        demographicListing={demographicListing}
        orderCompletion={orderCompletion}
        listingInteractions={listingInteractions}
        listingChart={listingChart}
        purchaseListing={purchaseListing}
        interactedListing={interactedListing}
        adConverted={adConverted}
      /> */}
    </div>
  );
};

export default PrivateSellerAnalyticsBody;
