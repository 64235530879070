import { useState, useContext } from "react";
import "./connectAnalytics.scss";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import SalesGrowthChart from "../../../../components/Analytics/components/salesGrowthConnect";
import TotalEarningsChart from "../../../../components/Analytics/components/totalEarningsConnect";
import CustomDropdown from "../../../../components/Analytics/components/customDropdown";
import AreaChart from "../../../../components/Analytics/Charts/areaChartConnect";
import { BiInfoCircle, BiUpArrowAlt } from "react-icons/bi";
import BigEye from "../../../../assets/svg/bigEye";
import WalletMoney from "../../../../assets/svg/wallet-money";
import RateIcon from "../../../../assets/svg/rateIcon";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ChartStats from "../../../../components/Analytics/components/chartStats";
import GroupUserIcon from "../../../../assets/svg/groupUserIcon";
import OrderCompletion from "../../../../components/Analytics/components/orderCompletion";
import TaskSquare from "../../../../assets/svg/taskSquare";
import Bag from "../../../../assets/svg/bag";
import BagTick from "../../../../assets/svg/bagTick";
import BagCross from "../../../../assets/svg/bagCross";
import BagHappy from "../../../../assets/svg/bagHappy";
import BarChart from "../../../../components/Analytics/Charts/barChart";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import {
  chartData2,
  chartOptions2,
} from "../../../../components/Analytics/chartDetails";
import ModalContext from "../../../../hooks/modalContexts";

const ConnectAnalytics = ({connectChart, connectInteraction, connectProfile}) => {
  const [activeTab, setActiveTab] = useState("most");

  const testArray = [1, 2, 3, 4, 5, 6, 7];
  const { setReferralOrderModal } = useContext(ModalContext);
  const { setCustomerConversionModal } = useContext(ModalContext);
  const { setTotalConnectionsModal, setCustomerConversionConnectModal } = useContext(ModalContext);

  return (
    <div className="connect-analytics-container">
      <div className="cac-card-body">
        <div className="cacb-contents-one">
          <SalesGrowthChart
            image={<WalletMoney />}
            title="Connect Growth"
            height={250}
            width={700}
            connectChart={connectChart}
          />
        </div>
        <div className="cacb-contents-two">
          <TotalEarningsChart title="Connect" connectInteraction={connectInteraction} />
        </div>
      </div>
      <div className="cac-card-body-two">
        <div className="cacbt-contents-one">
          <div className="cco-header">
            <div className="tab-title">Connected profiles</div>
            <div className="tabs">
              {/* ----------  nav tab -------- */}
              <ul className="nav-tab">
                <li
                  className={activeTab === "least" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("least");
                  }}
                >
                  Least
                </li>
                <li
                  className={activeTab === "most" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("most");
                  }}
                >
                  Most
                </li>
                <li
                  className={activeTab === "all" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("all");
                  }}
                >
                  All
                </li>
              </ul>
            </div>
          </div>
          <div className="cco-body">
            {/* ************ CONNECTED PROFILES ************* */}
            <div className="cco-body">
              <div className="item__scroll">
                {connectProfile?.map((data, idx) => (
                  <div className="ccob-details">
                    <div className="details-box-one">
                      <h4>{idx + 1}</h4>
                       <img src={data?.featured_image.file ? data?.featured_image.file : itemImg} alt="" />
                      <div className="details-box-two">
                        {/* <h5> Maria Sponge </h5> */}
                        <h5>{data?.page_username ? data?.page_username : "@Username"} </h5>
                      </div>
                      <div className="details-box-three">
                       <img src={data?.page_featured_image ? data?.page_featured_image : itemImg} alt="" />
                       <h5> {data?.job_title}</h5>
                      </div>
                    </div>

                    <div className={data?.summary.summary.direction === "UP" ? "ccob-chart rate-text-green" : "ccob-chart rate-text-red"}>
                     <p>
                        {data?.summary.summary.percentage.toFixed(1)}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                      </p>
                      <div>
                         <AreaChart data={data}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="dmc-stats">
          <ChartStats
            image={<WalletMoney />}
            title="Total Connections"
            statPercent={connectInteraction?.total_connections?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={connectInteraction?.total_connections?.total}
            onClick={(e) => setTotalConnectionsModal(true)}
          />
          <ChartStats
            image={<GroupUserIcon fill="#fbb03b" />}
            title="Number of referrals"
            statPercent={connectInteraction?.number_of_referrals?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={connectInteraction?.number_of_referrals?.total}
            onClick={(e) => setReferralOrderModal(true)}
          />
          <ChartStats
            image={<RateIcon />}
            title="Customer conversion rate"
            statPercent={connectInteraction?.customer_conversion_rate?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={connectInteraction?.customer_conversion_rate?.total_number}
            onClick={(e) => setCustomerConversionConnectModal(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default ConnectAnalytics;
