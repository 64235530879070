import React, {useState, useContext} from 'react'
import { FaTimes, FaChevronDown, FaUpload } from 'react-icons/fa'
import { AiOutlinePicture, AiOutlineVideoCamera } from 'react-icons/ai'
import { GrLocation } from 'react-icons/gr'
import './index.scss'
// import ModalContext from "../../hooks/modalContexts";
import ModalContext from "../../../hooks/modalContexts"
import Image from '../../../assets/img/Ellipse 358.png'
// import Upload from './FileUpload/Upload'

const JobModal = () => {
    const { setJobModal } = useContext(ModalContext);

  // const [modals, SetModals] = useState('upload')
 const [isActive, setActive] = useState('false')
  //  const handleToggle = () => {
  //    setActive(!isActive)
  //  }

  return (
    <div 
    onClick={(e) => setJobModal(false)}
    >
      <section 
        className="PostModal"
        // onClick={(e) => setJobModal(false)}
        onClick={(e) => setJobModal(false)}
      >
          <section className='PostModal__background'>
            <div className='PostModal__header'>
              <div>
                <h1>Create a post</h1>
                <div className='PostModal__input'>
                  <div className='PostModal__select'>
                    <select name='' id=''>
                      <option value=''>All</option>
                      <option value=''>Make Public</option>
                      <option value=''>My Timeline only</option>
                    </select>
                  </div>
                  <div className='PostModal__icon' >
                    <FaChevronDown />
                  </div>
                </div>
              </div>
              <div className='PostModal__user'  onClick={(e) => setJobModal(false)}>
                <div className='PostModal__name'>
                  <div>
                    <img src={Image} alt='' />
                  </div>
                  <div className='PostModal__details'>
                    <h2>Rose Kooto</h2>
                    <p>Abuja</p>
                  </div>
                </div>
                <div className='PostModal__close' onClick={(e) => setJobModal()}>
                  <FaTimes />
                </div>
              </div>
            </div>
            <div className='PostModal__upload'>
              <form action=''>
                <div className='PostModal__media'>
                  <div className='PostModal__media-close'>
                    <div
                      className='PostModal__media-close-up'
                      // onClick={() => {
                      //   SetModals('caption')
                      // }}
                    >
                      <FaTimes />
                    </div>
                  </div>
                  <div>
                  </div>
                </div>
                <div className='PostModal__caption'>
                  <div>
                    <textarea name='' id='' cols='30' rows='10'></textarea>
                  </div>
                  <div>
                    <button>Post</button>
                  </div>
                </div>
              </form>
            </div>
          </section>
       
      </section>
    </div>
  )
}

export default JobModal
