import React, { useContext } from "react";
import {FaTimes} from "react-icons/fa";
import "./bookSuccessModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import tickIcon from "../../../assets/explore/tick-circle.svg";


export const BookSuccessModal = () => {
  const {setBookSuccessModal, bookSuccessModal} = useContext(ModalContext);

  const swapSuccessModal = () => {
    window.location.reload();
    setBookSuccessModal(false)
  }
  console.log(bookSuccessModal);

  return (
    <>
      <div className="applySuccessModal">
        <div className='applySuccessModalWrap'>
          <div className="modalCloseWrap">
            <div onClick={() => setBookSuccessModal(false)} className="applySuccessModalClose">
              <FaTimes/>
            </div>
          </div>

          <div className="applySuccessModal-body">

            <div className="applySuccessModal-content">
              <img src={tickIcon} className="success-icon" alt=""/>

              <div className="modal-msg-title">Congratulations!</div>
              <div className="modal-msg-body">Service has been booked!</div>

              <button className="modal-cta-btn" onClick={() => swapSuccessModal()}>Done</button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}