import { useContext, useEffect, useState } from "react";
import ModalContext from "../../hooks/modalContexts";
import "./deals.scss";
import DatePicker from "react-datepicker";
import DropDown from "./Dropdown";
import PriceDropdown from "./Dropdown/FixedPrice";
import classNames from "classnames";
import { FaCalendarAlt } from "react-icons/fa";
import {
  IoMdClose,
  RiCheckboxBlankLine,
  RiCheckboxBlankFill,
} from "react-icons/all";
import useTypes from "../../hooks/useTypes";
import { showToast } from "../ReactToast";

const DealsModal = () => {
  const discountOptions = [
    "Next Purchase",
    "Every Purchase After",
    "Total Purchase",
  ];
  const { handleGetTypes, types } = useTypes();
  const { discountModal, handleDiscountModal, setStockDiscount } =
    useContext(ModalContext);
  const [state, setState] = useState({
    expiry: discountModal.expiry || new Date(),
    discount: discountModal.discount || {
      type: "FIXED",
      amount: 0,
    },
    deals: discountModal.deals || {
      quantity_to_buy: 5,
      amount: 0,
      quantity_to_discount: 0,
      type: "FIXED",
      discountTypeOption: discountOptions[0],
    },
  });
  const [discountMode, setDiscountMode] = useState({
    deals: discountModal.deals ? true : false,
    discount: discountModal.discount ? true : false,
  });

  const handleOffDiscount = () => {
    setDiscountMode({
      deals: true,
      discount: false,
    });
  };
  const handleOffDeals = () => {
    setDiscountMode({
      deals: false,
      discount: true,
    });
  };

  console.log(state);
  useEffect(() => {
    handleGetTypes("DealAndDiscountType");
  }, []);
  // const setDealsAndDiscountType = (string) => {
  //   console.log(string);
  //   switch (string.toLowerCase()) {
  //     case "next purchase":
  //       setState({
  //         ...state,
  //         deals: {
  //           ...state.deals,
  //           deal_and_discount_type: state.deals.type
  //             .toLowerCase()
  //             .includes("fixed")
  //             ? "BUY_N_AND_GET_NEXT_X_PURCHASE_FIXED"
  //             : "BUY_N_AND_GET_NEXT_X_PURCHASE_PERCENT",
  //         },
  //       });
  //       break;

  //     case "every purchase after":
  //       setState({
  //         ...state,
  //         deals: {
  //           ...state.deals,
  //           deal_and_discount_type: state.deals.type
  //             .toLowerCase()
  //             .includes("fixed")
  //             ? "BUY_N_AND_GET_EVERY_PURCHASE_AFTER_AT_X_FIXED"
  //             : "BUY_N_AND_GET_EVERY_PURCHASE_AFTER_AT_X_PERCENT",
  //         },
  //       });
  //       break;
  //     case "total purchase":
  //       setState({
  //         ...state,
  //         deals: {
  //           ...state.deals,
  //           deal_and_discount_type: state.deals.type
  //             .toLowerCase()
  //             .includes("fixed")
  //             ? "BUY_N_OR_MORE_AND_GET_EVERY_PURCHASE_AT_X_FIXED"
  //             : "BUY_N_OR_MORE_AND_GET_EVERY_PURCHASE_AT_X_PERCENT",
  //         },
  //       });
  //       break;

  //     default:
  //       break;
  //   }
  // };

  const getDealsAndDiscountType = (string) => {
    switch (string.toLowerCase()) {
      case "next purchase":
        return state.deals.type.toLowerCase().includes("fixed")
          ? "BUY_N_AND_GET_NEXT_X_PURCHASE_FIXED"
          : "BUY_N_AND_GET_NEXT_X_PURCHASE_PERCENT";

      case "every purchase after":
        return state.deals.type.toLowerCase().includes("fixed")
          ? "BUY_N_AND_GET_EVERY_PURCHASE_AFTER_AT_X_FIXED"
          : "BUY_N_AND_GET_EVERY_PURCHASE_AFTER_AT_X_PERCENT";

      case "total purchase":
        return state.deals.type.toLowerCase().includes("fixed")
          ? "BUY_N_OR_MORE_AND_GET_EVERY_PURCHASE_AT_X_FIXED"
          : "BUY_N_OR_MORE_AND_GET_EVERY_PURCHASE_AT_X_PERCENT";

      default:
        break;
    }
  };
  // console.log(state);

  const handleDone = () => {
    try {
      if (discountMode.discount) {
        if (state.discount.amount <= 0) {
          throw "discount value cannot be 0";
        }
        //success - add to stocks
        setStockDiscount({
          deals: [
            {
              name: "discount",
              exp_date: new Date(state.expiry).toISOString(),
              constant: {
                [state.discount.type.toLowerCase().includes("fixed")
                  ? "fix_price"
                  : "percentage"]: state.discount.amount,
              },
              priceType: state.discount.type,
              deal_and_discount_type: state.discount.type
                .toLowerCase()
                .includes("fixed")
                ? "BUY_BEFORE_N_DATE_AND_PAY_X_FIXED"
                : "BUY_BEFORE_N_DATE_AND_PAY_X_PERCENT",
            },
          ],
        });
        handleDiscountModal("RESET_DISCOUNT_TO_DISPLAY");
      }
      if (discountMode.deals) {
        if (state.deals.amount <= 0) {
          throw "discount price value cannot be 0";
        }
        if (state.deals.quantity_to_buy <= 0) {
          throw "quantity to buy cannot be less than 1";
        }
        if (state.deals.quantity_to_discount <= 0) {
          throw "quantity to discount cannot be less than 1";
        }

        setStockDiscount({
          deals: [
            {
              name: "deals",
              deal_and_discount_type: getDealsAndDiscountType(
                state.deals.discountTypeOption
              ),
              priceType: state.deals.type,
              constant: {
                quantity_to_buy: state.deals.quantity_to_buy,
                [state.deals.type.toLowerCase().includes("fixed")
                  ? "fix_price"
                  : "percentage"]: state.deals.amount,
                quantity_to_discount: state.deals.quantity_to_discount,
              },
              exp_date: new Date(state.expiry).toISOString(),
              discountTypeOption: state.deals.discountTypeOption,
            },
          ],
        });

        handleDiscountModal("RESET_DISCOUNT_TO_DISPLAY");
      }
    } catch (err) {
      showToast("error", err);
    }
  };

  return (
    <div
      onClick={handleDiscountModal.bind(this, "RESET_DISCOUNT_TO_DISPLAY")}
      className="deals-modal">
      <div onClick={(e) => e.stopPropagation()} className="deals-container">
        <div
          onClick={handleDiscountModal.bind(this, "RESET_DISCOUNT_TO_DISPLAY")}
          className="closeIcon">
          <span>
            {" "}
            <IoMdClose />{" "}
          </span>
        </div>
        <div className="createlisting-body-container">
          <div className="clbc-card-body">
            <div className="clbc-cb-contents">
              <div className="clbc-cbc-card">
                <div className="clbc-card-header">
                  <div className="toggle-2">
                    <h4 className="deals-header">Deals and Discounts</h4>
                    <div className="toggle-section">
                      <h5 className="expire-date">Expire Date</h5>
                      <div className="picker-container">
                        <FaCalendarAlt />
                        <DatePicker
                          className="custom-date"
                          selected={state.expiry}
                          // onSelect=""
                          onChange={(date) => {
                            console.log(date);
                            setState({
                              ...state,
                              expiry: date,
                            });
                          }}
                          dateFormat="MMM d, yyyy h:mm aa"
                          placeholderText="select date"
                          showTimeInput
                          timeInputLabel="Time"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clbc-cbc-card">
                <div className="clbc-cbcc-body">
                  <div className="discount-header">
                    <h3>Discount</h3>
                  </div>
                  <div className="ccb__contents_price">
                    <div className="price-box-one">
                      <div className="price-check">
                        <span onClick={(e) => handleOffDeals()}>
                          {discountMode.discount ? (
                            <RiCheckboxBlankFill />
                          ) : (
                            <RiCheckboxBlankLine />
                          )}
                        </span>
                      </div>

                      <div
                        className={classNames("ctn-two-on", {
                          "disabled-item": discountMode.deals,
                        })}>
                        <label htmlFor="">Get: </label>
                        <div className="dropbox">
                          <PriceDropdown
                            upIcon={true}
                            current={
                              state.discount.type
                                .toLowerCase()
                                .includes("fixed")
                                ? "Fixed Price"
                                : "Percentage"
                            }
                            onChange={(e) => {
                              setState({
                                ...state,
                                discount: {
                                  ...state.discount,
                                  type: e.toLowerCase().includes("fixed")
                                    ? "FIXED"
                                    : "PERCENTAGE",
                                },
                              });
                            }}
                          />
                        </div>
                        <div className="min-dep-field">
                          <span>
                            {state.discount.type.includes("FIXED") ? "$" : "%"}
                          </span>
                          <input
                            type={"number"}
                            value={state.discount.amount}
                            placeholder="90"
                            onChange={(e) => {
                              setState({
                                ...state,
                                discount: {
                                  ...state.discount,
                                  amount: e.target.value,
                                },
                              });
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="clbc-cbc-card">
                <div className="clbc-cbcc-body">
                  <div className="deal-header">
                    <h3>Deals</h3>
                  </div>
                  <div className="ccb__contents_price">
                    <div className="price-box-one">
                      <div className="price-check">
                        <span
                          // onClick={(e) => handleDiscountMode("deals")}
                          onClick={(e) => handleOffDiscount()}>
                          {discountMode.deals ? (
                            <RiCheckboxBlankFill />
                          ) : (
                            <RiCheckboxBlankLine />
                          )}
                        </span>
                      </div>

                      <div
                        // className="input-group  ctn-three three-off"
                        className={classNames(
                          "input-group  ctn-three three-off",
                          {
                            "disabled-item": discountMode.discount,
                          }
                        )}>
                        <div className="how-many">
                          <div className="how-many-items">
                            <span className="buy">Buy</span>
                            <div className="input-container">
                              <input
                                type={"number"}
                                value={state.deals.quantity_to_buy}
                                placeholder="2"
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    deals: {
                                      ...state.deals,
                                      quantity_to_buy: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                            <span>Qty</span>
                          </div>
                        </div>
                        <div className="first-dropdown ">
                          <h4>Offer:</h4>
                          <div className="dropbox-first">
                            <DropDown
                              options={discountOptions}
                              upIcon={true}
                              current={state.deals.discountTypeOption}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  deals: {
                                    ...state.deals,
                                    discountTypeOption: e,
                                  },
                                });
                              }}
                            />
                            <div className="input-container">
                              <input
                                type={"number"}
                                value={state.deals.quantity_to_discount}
                                placeholder="2"
                                onChange={(e) => {
                                  setState({
                                    ...state,
                                    deals: {
                                      ...state.deals,
                                      quantity_to_discount: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="right-dropdown">
                          <h5 className="right-label" htmlFor="">
                            Get:{" "}
                          </h5>
                          <div className="dropbox">
                            <PriceDropdown
                              upIcon={true}
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  deals: {
                                    ...state.deals,
                                    type: e.toLowerCase().includes("fixed")
                                      ? "FIXED"
                                      : "PERCENTAGE",
                                  },
                                });
                              }}
                            />
                          </div>
                          <div className="min-dep-field">
                            <span>
                              {state.deals.type.includes("FIXED") ? "$" : "%"}
                            </span>
                            <input
                              type={"number"}
                              value={state.deals.amount}
                              placeholder="90"
                              onChange={(e) => {
                                setState({
                                  ...state,
                                  deals: {
                                    ...state.deals,
                                    amount: e.target.value,
                                  },
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {discountMode.deals && (
                <div className="clbc-cbc-card">
                  <div className="bottom-section">
                    <div className="buttom-deals-container">
                      <div className="deals-bg">
                        <h3>Deals</h3>
                      </div>
                      <span>
                        {" "}
                        Buy {state.deals.quantity_to_buy} get{" "}
                        {state.deals.type.includes("FIXED") && "$"}
                        {state.deals.amount}
                        {!state.deals.type.includes("FIXED") && "%"} off
                      </span>
                      <button
                        className="deals-button"
                        onClick={handleDone.bind()}>
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {discountMode.discount && (
                <div className="clbc-cbc-card">
                  <div className="bottom-section">
                    <div className="buttom-deals-container">
                      <div className="discount-bg">
                        <h3>Discount</h3>
                      </div>
                      <span>
                        {" "}
                        Get {state.discount.type.includes("FIXED") && "$"}
                        {state.discount.amount}
                        {!state.discount.type.includes("FIXED") && "%"} off
                      </span>
                      <button
                        className="discount-button"
                        onClick={handleDone.bind()}>
                        Done
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default DealsModal;
