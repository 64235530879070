const ArrowNarrowUp = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19.636"
      height="19.636"
      viewBox="0 0 19.636 19.636"
    >
      <g
        id="ic-arrow-narrow-up"
        transform="translate(6.909 47.206) rotate(180)"
      >
        <path
          id="Path_397"
          data-name="Path 397"
          d="M0,19.636H19.636V0H0Z"
          transform="translate(-12.727 27.57)"
          fill="none"
        />
        <line
          id="Line_23"
          data-name="Line 23"
          y1="12.427"
          transform="translate(-2.831 31.252)"
          fill="none"
          stroke="#00c13a"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_24"
          data-name="Line 24"
          x1="3.682"
          y2="3.682"
          transform="translate(-2.813 39.963)"
          fill="none"
          stroke="#00c13a"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
        <line
          id="Line_25"
          data-name="Line 25"
          x2="3.682"
          y2="3.682"
          transform="translate(-6.531 39.963)"
          fill="none"
          stroke="#00c13a"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
        />
      </g>
    </svg>
  );
};
export default ArrowNarrowUp;
