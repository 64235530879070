import { Formik } from "formik";
import { Link } from "react-router-dom";

import { FormLabel, Input, Button, Errortext } from "../../styles";
import {
  sign_in_schema,
  forgot_password_schema,
  confirm_password_schema,
} from "../validation/index";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { colors } from "../../color";

import "./index.css";
import React from "react";

export const LoginForm = (formSubmit, type, toggle) => {
  const initialValues = {
    username: "",
    password: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={sign_in_schema}
      onSubmit={formSubmit}
      validateOnBlur={false}
      validateOnChange={false}>
      {({ errors, handleChange, handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormLabel>Email or Username</FormLabel>
            <br />
            <Input
              value={values.username}
              name="username"
              onChange={handleChange}
              style={{ height: "51px" }}
              className="rounded pr-3"
              type="text"
            />
            {errors.username && <Errortext>{errors.username}</Errortext>}

            <FormLabel className="mt-20">Password</FormLabel>
            <br />
            <div className="password-div rounded" style={{ height: "51px" }}>
              <Input
                value={values.password}
                name="password"
                onChange={handleChange}
                className="rounded pr-3"
                type={type}
                style={{ background: "none", fontSize: "14px" }}
              />
              <br />
              {type === "password" ? (
                <VisibilityOff
                  onClick={() => toggle("text")}
                  className="mr-3 icon"
                />
              ) : (
                <Visibility
                  onClick={() => toggle("password")}
                  className="mr-3 icon"
                />
              )}
            </div>
            {errors.password && <Errortext>{errors.password}</Errortext>}
            <div className="mt-3">
              <Link
                to="/forgotpassword"
                style={{
                  color: `${colors.COMPANY_COLOR}`,
                  fontWeight: "bold",
                  fontSize: "12px",
                  textDecoration: "none",
                }}>
                Forgot password?
              </Link>
              <br />
            </div>
            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                style={{ background: `${colors.ORANGE}` }}
                className="signup-btn rounded-pill">
                Sign in
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export const forgotPassword = (formSubmit) => {
  const initialValues = {
    email: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={forgot_password_schema}
      onSubmit={formSubmit}
      validateOnBlur={false}
      validateOnChange={false}>
      {({ errors, handleChange, handleSubmit, values }) => {
        return (
          <form className="mt-4" onSubmit={handleSubmit}>
            <FormLabel>Enter your email</FormLabel>
            <Input
              name="email"
              value={values.email}
              className="rounded forgot-password-input pr-3"
              onChange={handleChange}
              type="email"
            />
            {errors.email && <Errortext>{errors.email}</Errortext>}
            <Button
              className="rounded-pill mt-3 mb-4"
              type="submit"
              style={{
                background: `${colors.ORANGE}`,
                width: "180px",
                fontSize: "14px",
              }}>
              Send Reset Instructions
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export const ChangePassword = (formSubmit, type, type2, toggle, toggle2) => {
  const initialValues = {
    new_password1: "",
    new_password2: "",
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={formSubmit}
      validationSchema={confirm_password_schema}
      validateOnBlur={false}
      validateOnChange={false}>
      {({ handleChange, errors, handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormLabel>Enter New Password</FormLabel>
            <div className="rounded password-div">
              <Input
                name="new_password1"
                value={values.new_password1}
                style={{ background: "none", fontSize: "14px" }}
                onChange={handleChange}
                className="rounded"
                type={type}
              />
              {type === "password" ? (
                <VisibilityOff
                  onClick={() => toggle("text")}
                  className="mr-3 icon"
                />
              ) : (
                <Visibility
                  onClick={() => toggle("password")}
                  className="mr-3 icon"
                />
              )}
            </div>
            {errors.new_password1 && (
              <Errortext>{errors.new_password1}</Errortext>
            )}
            <FormLabel>Confirm</FormLabel>
            <div className="rounded password-div">
              <Input
                name="new_password2"
                value={values.new_password2}
                style={{ background: "none", fontSize: "14px" }}
                onChange={handleChange}
                className="rounded"
                type={type2}
              />
              {type2 === "password" ? (
                <VisibilityOff
                  onClick={() => toggle2("text")}
                  className="mr-3 icon"
                />
              ) : (
                <Visibility
                  onClick={() => toggle2("password")}
                  className="mr-3 icon"
                />
              )}
            </div>
            {errors.new_password2 && (
              <Errortext>{errors.new_password2}</Errortext>
            )}

            <Button
              type="submit"
              className="mb-4 mt-5 rounded-pill"
              style={{ background: `${colors.ORANGE}`, width: "55%" }}>
              Reset
            </Button>
          </form>
        );
      }}
    </Formik>
  );
};

export const set_new_password = () => {
  const initialValues = {
    old_password: "",
    new_password: "",
    confirm_password: "",
    username: "",
  };
  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}>
      {({ errors }) => {
        return (
          <form>
            <FormLabel>Old Password</FormLabel>
            <Input />
            <FormLabel>New Password</FormLabel>
            <Input />
            <FormLabel>Confirm Password</FormLabel>
            <Input />
            <FormLabel>Username</FormLabel>
            <Input />
          </form>
        );
      }}
    </Formik>
  );
};
