const RateIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39.35"
      height="39.35"
      viewBox="0 0 39.35 39.35"
    >
      <g id="group" transform="translate(-684 -252)">
        <path
          id="Vector"
          d="M0,11.473a.748.748,0,0,1-.53-.22.75.75,0,0,1,0-1.061L10.192-.53a.75.75,0,0,1,1.061,0,.75.75,0,0,1,0,1.061L.53,11.253A.748.748,0,0,1,0,11.473Z"
          transform="translate(698.051 266.313)"
          fill="#fbb03b"
        />
        <path
          id="Vector-2"
          data-name="Vector"
          d="M2.017,4.783A2.767,2.767,0,1,1,4.783,2.017,2.77,2.77,0,0,1,2.017,4.783Zm0-4.033A1.267,1.267,0,1,0,3.283,2.017,1.268,1.268,0,0,0,2.017.75Z"
          transform="translate(696.707 264.969)"
          fill="#fbb03b"
        />
        <path
          id="Vector-3"
          data-name="Vector"
          d="M2.017-.75A2.767,2.767,0,1,1-.75,2.017,2.77,2.77,0,0,1,2.017-.75Zm0,4.033A1.267,1.267,0,1,0,.75,2.017,1.268,1.268,0,0,0,2.017,3.283Z"
          transform="translate(707.429 274.347)"
          fill="#fbb03b"
        />
        <path
          id="Vector-4"
          data-name="Vector"
          d="M16.4-.75A17.141,17.141,0,0,1,28.519,28.519,17.146,17.146,0,1,1,9.722.6,17.039,17.039,0,0,1,16.4-.75Zm0,32.791A15.646,15.646,0,0,0,27.459,5.333,15.646,15.646,0,1,0,5.333,27.459,15.543,15.543,0,0,0,16.4,32.041Z"
          transform="translate(687.279 255.279)"
          fill="#fbb03b"
        />
        <path
          id="Vector-5"
          data-name="Vector"
          d="M0,0H39.35V39.35H0Z"
          transform="translate(684 252)"
          fill="none"
          opacity="0"
        />
      </g>
    </svg>
  );
};
export default RateIcon;
