import { useEffect } from "react";
import useDeviceType from "../../../hooks/deviceType";
import ProfileBodyDV from "../../../components/PrivateSeller/Profile/profileDV";
import ProfileBodyMV from "../../../components/PrivateSeller/Profile/profileMV";
import FeedContainer from "../../../components/FeedContainer";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../../appstore/navigationDisplayToggle/index.action";
// import { useHistory } from "react-router-dom";

const PrivateSellerProfile = () => {
  const { isMobile, isDesktop } = useDeviceType();

  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  return (
    <FeedContainer>
      <div className="ps-profile-container">
        {/*----- Desktop view ------*/}
        {isDesktop && <ProfileBodyDV />}

        {/*----- Mobile view ------*/}

        {isMobile && <ProfileBodyMV />}
      </div>
    </FeedContainer>
  );
};

export default PrivateSellerProfile;
