import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import FeedContainer from '../../../../components/FeedContainer'
import { FaUserAlt, FaBars, FaChevronDown, FaAngleDown, FaAngleUp } from 'react-icons/fa'
import { AiOutlinePlusCircle, AiOutlineInfoCircle } from 'react-icons/ai'
import greeting from "../../../../utils/greeting";
import './CreateJob.scss'
import AddPhotos from "./AddPhotos"
import CreateJobFilter from "./Filter/Filter"
import CheckBox from "./CheckBox/CheckBox";
import useExplore from "../../../../hooks/explore";
import ExploreContext from "../../../../hooks/exploreContext";
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { showToast } from "../../../../components/ReactToast";
import { Toaster } from "react-hot-toast";
import { useLocation, useParams } from "react-router";
import qs from "query-string";

const CreateJob = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  useEffect(()=>{
      dispatch(toggleRightBar(false))
  },[])

  const {handleGetJobTypes, handleCreateJobPosts} = useExplore();
  const {jobTypes, createJobLoading, jobCreateComplete} = useContext(ExploreContext);

  const [jobTitle, setJobTitle] = useState('')
  const [jobDescription, setJobDescription] = useState('')
  const [jobLocation, setJobLocation] = useState('')
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [allowAttachments, setAllowAttachments] = useState(false);
  const [minSalary, setMinSalary] = useState();
  const [maxSalary, setMaxSalary] = useState();
  const [endDate, setEndDate] = useState(null);
  const [payDuration, setPayDuration] = useState(null);
  const [thisUser, setThisUser] = useState(null);
  const [status, setStatus] = useState(null);
  const [type, setType] = useState(null);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [attachmentFields, setAttachmentFields] = useState([{name: ''}]);
  const [geoCode, setGeoCode] = useState(null);
  const [jobPhoto, setJobPhoto] = useState(false);

  const { search } = useLocation();

  const { id } = qs.parse(search);
  const { pageId } = useParams();

  const selectJobPhoto = () => {
    setJobPhoto(true);
  }

  const handleAttachmentFormChange = (event, index) => {
    let data = [...attachmentFields];
    data[index][event.target.name] = event.target.value;
    setAttachmentFields(data);
  }

  const addAttachmentFields = () => {
    if (attachmentFields.length < 4) {
      let fieldObject = {name: ''};
      setAttachmentFields([...attachmentFields, fieldObject])
    } else {
      console.log(attachmentFields);
    }
  }

  const toggleAllowAttachments = () => {
    setAllowAttachments(!allowAttachments);
  }

  const {jobPostImage} = useContext(ExploreContext);

  const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;

  useEffect(() => {
    if (selectedLocation?.value?.description) {
      getGeocodeFromAddress();
    }
  }, [selectedLocation]);
  
  const getGeocodeFromAddress = () => {  
    let locationPoint = {};

    geocodeByAddress(selectedLocation?.value?.description)
      .then(results => getLatLng(results[0]))
      .then(({ lat, lng }) => 
      {
        console.log('Successfully got latitude and longitude', { lat, lng });
        locationPoint.lat = lat;
        locationPoint.long = lng;
        setGeoCode(locationPoint);
        console.log(geoCode);
      });
  }

  let payload = {};

  const handleCreateJobPost = async(event) => {
    if (!jobTitle) {
      showToast('error', 'Please enter a job title');
				return;
    }

    if (!jobDescription) {
      showToast('error', 'Please enter a job description');
				return;
    }

    if (!selectedLocation) {
      showToast('error', 'Please select a location for the job');
				return;
    }

    if (!jobPhoto) {
      showToast('error', 'Please choose an image for the job');
				return;
    }

    if (!endDate) {
      showToast('error', 'Please select an end date');
				return;
    }

    if (!minSalary) {
      showToast('error', 'Please enter a minimum salary');
				return;
    }

    if (!maxSalary) {
      showToast('error', 'Please enter a maximum salary');
				return;
    }

    if (!payDuration) {
      showToast('error', 'Please choose a pay duration');
				return;
    }

    if (!type) {
      showToast('error', 'Please select a job type');
				return;
    }

    if (!status) {
      showToast('error', 'Please select a status for this job');
				return;
    }

    if (maxSalary < minSalary) {
      showToast('error', 'Maximum salary cannot be less than minimum salary');
				return;
    }

    payload.title = jobTitle;
    if (pageId) {
      payload.page = pageId
    } else if (id) {payload.page = id}
    // payload.page = pageId;
    payload.position = jobTitle;
    payload.min_salary = minSalary;
    payload.max_salary = maxSalary;
    payload.description = jobDescription;
    payload.pay_duration = payDuration.toUpperCase();
    payload.currency = "USD";

    payload.gallary = [];
    payload.gallary.push(jobPostImage);

    payload.type = type;
    payload.status = status;

    payload.category = {};
    payload.category.name = status;

    payload.location = {};
    payload.location.address = selectedLocation.value.description;
    payload.location.point = {};
    payload.location.point.type = "Point";
    payload.location.point.coordinates = [];
    payload.location.point.coordinates.push(geoCode.long);
    payload.location.point.coordinates.push(geoCode.lat);

    payload.allow_extra_attachment = allowAttachments;
    if (allowAttachments) {
      payload.extra_attachments = [];

      for (let i=0; i<attachmentFields.length; i++) {
        payload.extra_attachments.push(Object.values(attachmentFields[i])[0]);
      }
    }

    payload.end_on  = endDate;

    handleCreateJobPosts(payload);
  }

  useEffect(() => {
    if (jobCreateComplete) {
      setJobTitle('');
      setJobDescription('');
      setSelectedLocation(null);
      setAllowAttachments(false);
      setMinSalary(0);
      setMaxSalary(0);
      setPayDuration(null);
      setEndDate(null);
      setType(null);
      setStatus(null);
      setAttachmentFields([{name: ''}]);

      payload = {};
    }
  }, [jobCreateComplete])

  const getAllTypes = async () => {
    await handleGetJobTypes(); 
  }

  const selectJobStatus = (option) => {
    setStatus(option.value)
  }

  const selectJobType = (option) => {
    setType(option.value)
  }

  useEffect (() => {
    let localUser = JSON.parse(localStorage.getItem('persist:root'))
    let loggedInUser = JSON.parse(localUser.currentUser).user;
    setThisUser(loggedInUser);
    console.log(loggedInUser);

    getAllTypes();
  }, [])

  return (
    <FeedContainer>
      <Toaster />
      <section className='create__job'>
        <div className='create__job-greeting'>
          {greeting()} <span>{thisUser?.first_name} </span>
        </div>
        <section className='create__job-container'>
          <h1>Create a Job</h1>
          <div className='create__job-container_wrap'>
            <form action='' className='create__job-container_wrap-form'>
              <section className='create__job-container_wrap-form-left'>
                <h2>Job Detail</h2>
                <div className='create__job-container_wrap-form-left-detail'>
                  <label htmlFor=''>Job Title:</label>
                  <input
                    type='text'
                    className='create__job-container_wrap-form-left-text input1' 
                    value={jobTitle}
                    onChange={(e) => setJobTitle(e.target.value)}
                  />
                </div>
                <div className='create__job-container_wrap-form-left-detail add'>
                  <div>
                    <label htmlFor=''>Description:</label>
                  </div>
                  <textarea
                    className='create__job-container_wrap-form-left-text input1'
                    name=''
                    id=''
                    cols='30'
                    rows='5'
                    value={jobDescription}
                    onChange={(e) => setJobDescription(e.target.value)}
                  ></textarea>
                </div>
                <div className='create__job-container_wrap-form-left-detail'>
                  <label htmlFor=''>Set Location:</label>

                  <div className="create__job-container_wrap-form-left-text input3">
                    <GooglePlacesAutocomplete
                      apiKey = {googleApiKey}
                      selectProps={{
                        selectedLocation,
                        onChange: setSelectedLocation,
                      }}
                    />
                  </div>
                  
                </div>

                <div className="add_photos">
                  <AddPhotos
                    addedPhoto={selectJobPhoto}
                   />
                </div>
              </section>

              <section className='create__job-container_wrap-form-right'>
                <h2>More Job Settings</h2>
                  <div className="create__job-container_wrap-form-right-options">
                    <div>
                      <FaUserAlt />
                      <CreateJobFilter 
                        options={jobTypes.JobPostStatus}
                        placeholder="Job Post Status"
                        action={selectJobStatus}
                      />
                    </div>
                    <div className="create__job-container_wrap-form-right-options-option2">
                      <FaBars />
                      <CreateJobFilter 
                        options={jobTypes.JobType}
                        placeholder="Job Type"
                        action={selectJobType}
                      />
                    </div>
                  </div>
                <div className='create__job-container_wrap-form-right-salary'>
                  <div>
                    <h3>Job salary</h3>
                    <div className='create__job-container_wrap-form-right-salary-box'>
                      <label className="radio-control" onClick={() => setPayDuration("Hourly")}>
                        <input type="radio" name="pay_duration" checked = {payDuration === "Hourly" ? true : false} />
                        Hourly
                      </label>
                      
                      <label className="radio-control" onClick={() => setPayDuration("Monthly")}>
                        <input type="radio" name="pay_duration" checked = {payDuration === "Monthly" ? true : false} />
                        Monthly
                      </label>
                      
                      <label className="radio-control" onClick={() => setPayDuration("Yearly")}>
                        <input type="radio" name="pay_duration" checked = {payDuration === "Yearly" ? true : false} />
                        Yearly
                      </label>
                    </div>
                  </div>
                  <div className='create__job-container_wrap-form-right-salary-range'>
                    <h4>Set salary range</h4>
                    <div className="create__job-container_wrap-form-right-salary-range-input">
                      <div className='create__job-container_wrap-form-right-salary-range-fromOne'>
                        <b>From</b> $ <input type='number' value={minSalary} onChange={(e) => setMinSalary(e.target.value)}/>{' '}
                      </div>
                      <div className='create__job-container_wrap-form-right-salary-range-fromTwo'>
                        <b>to</b> $ <input type='number' value={maxSalary} onChange={(e) => setMaxSalary(e.target.value)}/>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='create__job-container_wrap-form-right-attach'>
                  <h3>Attachment</h3>
                  <div className='create__job-container_wrap-form-right-attach-swi'>
                    <p>Allow Extra Attachments Uploads</p>
                    <label className='switch'>
                      <input className='switch-input' type='checkbox' value={allowAttachments} onChange={() => toggleAllowAttachments()} />
                      <span className='switch-label'></span>
                      <span className='switch-handle'></span>
                    </label>
                  </div>
                  <div className='create__job-container_wrap-form-right-attach-cover'>
                    <form>
                      {
                        attachmentFields.map((form, index) => {
                          return (
                            <div key={index}>
                              <input type='text' name='name' placeholder='Attachment title' value={form.name}
                              onChange={(event) => handleAttachmentFormChange(event, index)}
                              />
                            </div>
                          )
                        })
                      }
                    </form>

                    <div className='create__job-container_wrap-form-right-attach-cover-icon'>
                      <AiOutlinePlusCircle onClick={addAttachmentFields}/>
                    </div>
                  </div>
                </div>
                <div className='create__job-container_wrap-form-right-date'>
                  <h3>Set Ending Date</h3>
                  <div>
                    <div>
                      <input type='datetime-local' onChange={(e) => setEndDate(e.target.value)} />{' '}
                      <AiOutlineInfoCircle />
                    </div>
                  </div>
                </div>
                <div className='create__job-container_wrap-form-right-button'>
                  <button type="button" onClick={(event) => handleCreateJobPost(event)} className={createJobLoading ? "disabled" : ""} disabled={createJobLoading}>Create</button>
                </div>
              </section>
            </form>
          </div>
          
          <Link
            onClick={history.goBack}
            className='create__job-container-back'
          >
            <h5>Back</h5>
          </Link>
        </section>
      </section>
    </FeedContainer>
  )
}

export default CreateJob
