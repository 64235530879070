import { useEffect, useState } from "react";
import { FaToggleOff, FaToggleOn, FaPen } from "react-icons/fa";
const Toggle = ({ onChange = () => {}, defaultState = false, ...rest }) => {
  const [state, setState] = useState(defaultState);

  useEffect(() => {
    onChange({ value: state });
  }, [state]);
  return (
    <div className="toggle-wrap" onClick={(e) => setState(!state)}>
      {state ? <FaToggleOn {...rest} /> : <FaToggleOff {...rest} />}
    </div>
  );
};

export default Toggle;
