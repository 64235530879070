const BusinessNotificationItem = ({businessNotificationDataItem, idx, setBusinessNotificationData, businessNotificationData}) => {
    return(
        <li>
            <span>{businessNotificationDataItem.title}</span>
            <div className={`switch ${businessNotificationDataItem.value === false  && 'off'}`} 
            onClick={()=>{
                
                setBusinessNotificationData(businessNotificationData.map((data)=>{
                    let indexNo = businessNotificationData.indexOf(data)
                    if(indexNo === idx){
                        return data.value ? {...data, value: false}: {...data, value: true}
                    }
                    return data
                }))
            }}>
                <span className={`${businessNotificationDataItem.value === false && 'offSpan'}`} ></span>
            </div>
        </li>
    )
}
export default BusinessNotificationItem 