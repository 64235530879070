import Styled from "styled-components";
import { colors } from "./color";
import media from "./responsive";
import ReactPhoneInput from "react-phone-number-input";
import { CountryDropdown } from "react-country-region-selector";
import {
  withStyles,
  Radio,
  Checkbox,
  Tab as DashboardTab,
} from "@material-ui/core";
import { CheckBoxOutlineBlank, FavoriteBorder, Favorite } from "@material-ui/icons";
import image from "../src/assets/icons/dropdown1.png";
import "react-datepicker/dist/react-datepicker.css";

export const FormCard = Styled.div`
    width: 570px;
    border-radius: 15px;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 9px 15px;
`;
export const Header = Styled.h4`
    color: ${colors.ORANGE};
    font-size: 30px;
    font-weight: bold;
    ${media.mobile`
        font-size: 26px;
    `}
`;
export const GenderDiv = Styled.label`
    background: #efefef;
    width: 100%;
    height: 45px;
    cursor: pointer;
    margin-bottom: 0px;
    ${media.mobile`
     margin-top: 10px;
    `}
`;
export const Button = Styled.button`
    color: ${colors.WHITE};
    background: linear-gradient(to right, ${colors.COMPANY_COLOR}, ${colors.COMPANY_COLOR_LITE});
    border: none;
    width: 130px;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
`;
export const FormLabel = Styled.label`
    color: ${colors.COMPANY_COLOR};
    font-size: 12px;
    font-weight: bolder;
    margin-top: 10px;
    margin-bottom: 10px;
`;
export const Input = Styled.input`
    background: #efefef;
    color: ${colors.COMPANY_COLOR};
    border: none;
    height: 45px;
    padding-left: 15px; 
    padding-right: 15px;
    width: 100%;
    outline: none;
    ::-webkit-calendar-picker-indicator {
        font-size: 18px;
        cursor: pointer;
    }
    &:focus {
        background: #efefef;
    }
    ${media.mobile`
        height: 30px;
        padding-left: 15px;
    `}
`;
export const ImageDiv = Styled.div`
    position: absolute;
    right: 8%;
    ${media.mobile`
        display: none;
    `}
    ${media.tablet`
        display: none;
    `}
`;
export const Image = Styled.img`
    max-width:80%;
    height: ;
`;
export const Text = Styled.p`
    color: ${colors.COMPANY_COLOR};
    font-size: 12px;
    font-weight: 500;
    line-height: 0.2rem;
`;
export const Errortext = Styled.p`
    color: ${colors.ORANGE};
    font-size: 14px;
    font-weight: 500;
    transition: all ease 0.3s;
`;

export const PhoneInput = Styled(ReactPhoneInput)`
    background: #efefef;
    color: ${colors.COMPANY_COLOR};
    border: none;
    height: 45px;
    padding-left: 15px; 
    width: 100%;
    outline: none;
    ${media.mobile`
    height: 30px;
    `}
`;

export const Country = Styled(CountryDropdown)`
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: top 16px right 10px;
    background-color: #efefef;
    color: ${colors.COMPANY_COLOR};
    border: none;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    outline: none;
    ${media.mobile`
        height: 30px;
    `}
`;

export const Select = Styled.select`
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: top 16px right 10px;
    background-color: #efefef;
    color: ${colors.COMPANY_COLOR};
    border: none;
    height: 45px;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    outline: none;
    ${media.mobile`
        height: 30px;
    `}
`;

export const LikeIcon = withStyles({
  root: {
    height: "30px",
    width: "30px",
    "&$checked": {
      color: "red",
    },
  },
  checked: {},
})((props) => (
  <Checkbox
    icon={
      <FavoriteBorder
        style={{ color: `${colors.COMPANY_COLOR}` }}
        fontSize="default"
      />
    }
    checkedIcon={<Favorite fontSize="default" style={{ color: "#CE0000" }} />}
    {...props}
  />
));

export const TermsCheckbox = withStyles({
  root: {
    color: `${colors.WHITE}`,
    height: "10px",
    width: "10px",
    "&$checked": {
      color: `${colors.COMPANY_COLOR}`,
    },
  },
  checked: {},
})((props) => (
  <Checkbox
    icon={
      <CheckBoxOutlineBlank
        style={{
          border: `2px solid ${colors.COMPANY_COLOR}`,
          background: `${colors.WHITE}`,
          borderRadius: "5px",
        }}
      />
    }
    checkedIcon={
      <CheckBoxOutlineBlank
        style={{ background: `${colors.COMPANY_COLOR}`, borderRadius: "5px" }}
      />
    }
    color="default"
    {...props}
  />
));
export const FormCheckbox = withStyles({
  root: {
    margin: "0px",
    padding: "5px",
    color: `${colors.WHITE}`,
    "&$checked": {
      color: `${colors.COMPANY_COLOR}`,
    },
  },
  checked: {},
})((props) => (
  <Radio
    icon={
      <CheckBoxOutlineBlank
        style={{
          border: `2px solid ${colors.COMPANY_COLOR}`,
          fontSize: "18px",
          background: `${colors.WHITE}`,
          borderRadius: "5px",
        }}
      />
    }
    checkedIcon={
      <CheckBoxOutlineBlank
        style={{
          background: `${colors.COMPANY_COLOR}`,
          fontSize: "18px",
          borderRadius: "5px",
        }}
      />
    }
    color="default"
    {...props}
  />
));

export const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: `${colors.LIGHT_GREY}`,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      color: `${colors.COMPANY_COLOR}`,
    },
  },
}))((props) => <DashboardTab disableRipple {...props} />);

