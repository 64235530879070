import { useState } from "react";
import "./progress.scss";

const Progress = ({ done }) => {
  const [style, setStyle] = useState({});

  setTimeout(() => {
    const newStyle = {
      opacity: 1,
      width: `${done}%`,
    };

    setStyle(newStyle);
  }, 200);

  return (
    <div className="custom-progress">
      <div className="custom-progress-done" style={style}>
        {/* {done} */}
      </div>
    </div>
  );
};

export default Progress;
