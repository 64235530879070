import { useContext, useState, useEffect } from "react";
import "./analyticsModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import { useLocation } from "react-router-dom";
import Photo from "../../../assets/img/Component 672 –1.PNG";
import { FaPen, FaSearch } from "react-icons/fa";
import CustomCheckBox from "../../../components/customCheckBox";
import { Search as Icon } from "../../../assets/svg/NavBarIcons";
import CloseBtn from "../../../components/closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowNarrowUp from "../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../assets/svg/arrowNarrowDown";
import GenViewsModal from "../../Analytics/components/generalViewsModalChart";
import SalesGrowthChart from "../../Analytics/components/salesGrowthGenViewsModal";
import useAnalytics from "../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../hooks/exploreContext'


const PageViewsAnalyticModal = () => {
  // /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const { setPageViewsAnalyticModal } = useContext(ModalContext);
  const { search } = useLocation();
  const { id } = qs.parse(search);

  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)

  const {
    analyticsGeneralData,
    handleGetAnalyticsGeneral,
  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsGeneral(id)
  }, [])

  const general = analyticsGeneralData?.data


  return (
    <div
      onClick={() => {
        setPageViewsAnalyticModal(false);
      }}
      className="analyticsModal"
    >
      <div className="analyticsModalContainer">
        <div
          onClick={() => {
            setPageViewsAnalyticModal(false);
        }}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="analyticsModalContainered"
        >
          <div className="analyticsModal-header">
            <h4> Views on page </h4>
            <div className="datepicker-container">
            <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsGeneral(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="analyticsModal-body">
            <div className="amb-box-contents">
              <div className="amb-section-one">
                <h3>$ {general?.views.total_number}</h3>
                <h6>
                {general?.views?.summary.percentage}%{" "}
                  <span>
                  {general?.views?.summary.direction === "UP" ?  <ArrowNarrowUp /> : <ArrowNarrowDown />}
                  </span>
                </h6>
                <p>in less than one week</p>
              </div>
              <div className="amb-section-two">
              <GenViewsModal general={general} />
              </div>
            </div>
            <div className="amb-statistics">
              <SalesGrowthChart title="Revenue Statistics" general={general} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PageViewsAnalyticModal;
