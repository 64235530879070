import React, {  useState, useEffect, useContext  } from "react";
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom'
import FeedContainer from "../../../../components/FeedContainer";
import { toggleRightBar, toggleLeftBar } from "../../../../appstore/navigationDisplayToggle/index.action";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/shopImg.png";
import Back from "../../../../assets/svg/arrow-right (2).svg"
import "./history.scss"
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import MainHistoryData from "./Data"

const WalletHistory = () => {
  const dispatch = useDispatch();

  //toggle right bar off
  useEffect(() => {
    dispatch(toggleRightBar(false));
    return () => dispatch(toggleRightBar(true));
  }, []);

  useEffect(() => {
    dispatch(toggleLeftBar(false));
    return () => dispatch(toggleLeftBar(true));
  }, []);

  return(
    <FeedContainer>
      <div className="wallet-hist">
          <div className="wallet-p-header">
            <h5>My Wallet</h5>
              <Link to='/wallet'>
                <p> <img className="visa" src={Back} /> Back </p>
              </Link>
          </div>
          <div className="history-cont">
              <h2  className="hist-txt">History</h2>
              <div className="wlt-history">
                {MainHistoryData.map(data => (
                  <div className="wlt-container">
                    <div className="user">
                      <img src={data.avatar} alt="" />
                      <p>{data.name}</p>
                    </div>

                    <div className="date">
                      <p> <span>Date: </span>{data.date}</p>
                    </div>

                    <div className="ref">
                      <p>Ref: {data.ref}</p>
                    </div>

                    <div className="sent">
                        <p>Sent to  <span>{data.sent}</span> </p>
                    </div>

                    <div className="amount ">
                      <p>Amount</p>
                      <div className="amount-price witdrw">
                        {data.amount}
                      </div>
                    </div>
                  </div>
                ))}
                {MainHistoryData.map(data => (
                  <div className="wlt-container">
                    <div className="user">
                      <img src={data.avatar} alt="" />
                      <p>{data.name}</p>
                    </div>

                    <div className="date">
                      <p> <span>Date: </span>{data.date}</p>
                    </div>

                    <div className="ref">
                      <p>Ref: {data.ref}</p>
                    </div>

                    <div className="sent">
                        <p>Sent to  <span>{data.sent}</span> </p>
                    </div>

                    <div className="amount">
                      <p>Amount</p>
                      <div className="amount-price">
                        {data.amount}
                      </div>
                    </div>
                  </div>
                ))}
            </div>
        </div>
      </div>
  </FeedContainer>
  )
}

export default WalletHistory
