import React, {useEffect, createContext, useState, useContext  } from "react";
import ModalContext from '../../../hooks/modalContexts'
import { Link } from 'react-router-dom'
import { FaEye, FaHeart, FaEnvelope } from 'react-icons/fa'
import person from '../../../assets/img/Ellipse 320.PNG'
import moment from "moment"
import useConnect from "../../../hooks/connectHooks";

const ConnectedCards = ({
  connected,
  handleHideProfile,
  handleLike,
  modalData,
  setModalData,
  handleGetSingleProfile,
  singleProfileData,
  currentId,
  setCurrentId,
  likeData,
  setMessageData,
}) => {
  const [isOpened, setOpened] = useState('false')
  const [isActive, setActive] = useState('false')
  const {handleConnectModal} = useContext(ModalContext)
  const {handleMessageModal} = useContext(ModalContext)
  const { handleGetConnectProfile, connectProfileData} = useConnect();

  const handleShowAndModal = () => {
    handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
    handleShow();
  }
  const handleShow = () => {
    setOpened(!isOpened)
  }

  const handleToggle = () => {
    setActive(!isActive)
  }

  useEffect(() => {
    handleGetConnectProfile();
    console.log(connectProfileData?.data?.results);
    console.log("##############")
  }, []);

  useEffect(() => {
    if (singleProfileData?.data) {
      console.log(singleProfileData?.data?.page?.name);
      // dispatch(createSingleComment(singleCommentData.data));
      setModalData(singleProfileData?.data)
    }
  }, [singleProfileData.data]);

console.log(likeData?.data)

  return (
      <div className='connect-manage-card favorite-suggested-cards'
      onClick={() =>{
        handleMessageModal('ADD_MESSAGE_TO_DISPLAY', 'true')
        setMessageData(connected)
      }}
      >
          <div className='connect-manage-card-left'>
              <div className='connect-manage-card-user'>
                <div className='connect-manage-card-user-head'>
                  <div>
                    <img src={
                      connected?.connect_profile?.page?.featured_image ? connected?.connect_profile?.page?.featured_image : person
                      } alt='' />
                  </div>
                  <div>
                    <h2>{connected?.connect_profile?.page?.name}</h2>
                    <p>@{connected?.connect_profile?.page?.username ? connected?.connect_profile?.page?.username : "Username"}</p>
                  </div>
                </div>
                <div className='connect-manage-iconss'>
                <div className='connect-manage-envelope'>
                  <FaEnvelope />
                </div>
                  {/* <div className='connect-manage-eye'
                    onClick={() =>{
                      handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
                    }}
                    >
                    <FaEye />
                  </div> */}
                  {/* <div
                    onClick={() =>{
                      handleLike(connected?.id);
                      setCurrentId(connected?.id)
                    }}
                    className={
                      connected?.is_like === false
                        ? 'connect-manage-heart favey'
                        : 'connect-manage-heart'
                    }
                  >
                    <FaHeart />
                  </div> */}
                </div>
              </div>
              <div className='connect-manage-card-text'>
                <div className='connect-manage-card-text-wrap'>
                  <h1>{connected?.connect_profile?.title}</h1>
                  <h3>{connected?.connect_profile?.page?.location?.address}</h3>
                  <p className='connect-manage-card-p1'>{connected?.connect_profile?.page?.description}</p>
                </div>
                <div className='connectcard-button-flex'>
                  <p></p>
                  <div>
                    {/* <div
                      className={isOpened ? 'connect-button-flex' : 'connect-hide'}
                    >
                      <button
                        // onClick={handleShow}
                        className='connect-connect'
                        onClick={() =>{
                          handleConnectModal('ADD_CONNECT_TO_DISPLAY', 'true');
                          // handleShow();
                          handleGetSingleProfile(connected?.id)
                        }}
                        >
                        Connect
                      </button>
                    </div> */}
                    <div
                      className='connect-show appear'
                    >
                      <p>Connected</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
      </div>
  )
}

export default ConnectedCards
