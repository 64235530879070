/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearEyeComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 15.082 15.082" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <path
          className="a"
          d="M4.5,2.25A2.25,2.25,0,1,1,2.25,0,2.247,2.247,0,0,1,4.5,2.25Z"
          transform="translate(5.291 5.291)"
        />
        <path
          className="a"
          d="M6.149,10.4a6.923,6.923,0,0,0,5.725-3.569,3.347,3.347,0,0,0,0-3.261A6.923,6.923,0,0,0,6.149,0,6.923,6.923,0,0,0,.424,3.569a3.347,3.347,0,0,0,0,3.261A6.923,6.923,0,0,0,6.149,10.4Z"
          transform="translate(1.392 2.338)"
        />
        <path
          className="b"
          d="M0,0H15.082V15.082H0Z"
          transform="translate(15.082 15.082) rotate(180)"
        />
      </g>
    </g>
  </svg>
);

export default VuesaxLinearEyeComponent;
