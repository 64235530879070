import { useState, useContext, useRef } from "react";
import { FaAngleDown, FaTimes } from "react-icons/fa";
import { FiInfo } from "react-icons/fi";
import RevealOptionComponent from "../../../assets/svg/RevealOptionComponent";
import { DefaultButton } from "../../../components/Button";
import CustomCheckBox from "../../../components/customCheckBox";
import Switch from "../../../components/switch";
import "./editQueue.scss";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import itemImg from "../../../assets/img/Rectangle 1890.PNG";
import JobImage1 from "../../../assets/img/JobImage1.jpeg";
import Edit from "../../../assets/icons/edit.svg";
import ModalContext from "../../../hooks/modalContexts";
import ExploreContext from "../../../hooks/exploreContext";
import { IoMdCloseCircleOutline } from "react-icons/all";
import { MdFileUpload } from "react-icons/md";
import { FaPencilAlt  } from 'react-icons/fa';
import { showToast } from "../../../components/ReactToast";
import { Toaster } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import useExplore from "../../../hooks/explore";
import QueueTimer from "../../../components/QueueTimer/queueTimer";
import { useHistory } from "react-router-dom";

const EditQueue = () => {
  const [daily, setDaily] = useState();
  const [joinAuto, setJoinAuto] = useState(false);
  const [linkListing, setLinkListing] = useState(false);
  const [optionVisible, setOptionVisible] = useState();
  const [unlimited, setUnlimited] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [scheduleArray, setScheduleArray] = useState([]);
  const [description, setDescription] = useState();
  const [maxSlot, setMaxSlot] = useState();
  const [queueTitle, setQueueTitle] = useState();
  const { location } = useHistory();
  const pageId = location.search.substring(location.search.indexOf('=') + 1);

  const { handleQueueListingsModal } = useContext(ModalContext);
  const { newQueueListings, setNewQueueListings, newQueueImage, setNewQueueImage } = useContext(ExploreContext);
  const { handleCreateNewQueue } = useExplore();
  
  const thisUser = useSelector(state => state.currentUser.user);

  const [formValues, setFormValues] = useState("");
  const [todos, setTodos] = useState([
    {
      text: "Veegan foods food and food. Veegan foods",
    },
    {
      text: "Veegan foods food and food. Veegan foods",
    },
    {
      text: "Veegan foods food and food. Veegan foods",
    },
    {
      text: "Veegan foods food and food. Veegan foods",
    },
  ]);

  const addForm = (text) => {
    const newTodos = [{ text }, ...todos];
    setTodos(newTodos);
  };

  const removeForm = (e) => {
    // let newFormValues = [...formValues];
    // newFormValues.splice(e, 1);
    // setFormValues(newFormValues);
    e.stopPropagation();
    const index = e.currentTarget.getAttribute("data-index");
    const newTodos = [...todos];
    newTodos.splice(index, 1);
    setTodos(newTodos);
  };

  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
  };

  const testArray = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const testArray2 = [1, 2, 3, 4];

  const Images = [
    {
      id: 1,
      image: JobImage1,
    },
  ];

  const [workSchedule, setWorkSchedule] = useState([]);

  const setSelectedWeekDay = (day) => {
    console.log(day);
  }

  const setDateStart = (date, day) => {
    console.log(date);
    console.log(day);

    let scheduleObject = {};
    scheduleObject.day = day;
    let timeISO = date.toISOString();
    scheduleObject.opening_time = timeISO.substring(timeISO.indexOf('T') + 1)

    let newArray = scheduleArray;

    if (newArray.length>0) {
      for (let i=0; i<newArray.length; i++) {
        if (newArray[i].day === scheduleObject.day) {
          newArray[i].opening_time = scheduleObject.opening_time;
        }
      }
      if (!newArray.some(e => e.day === scheduleObject.day)) {
        newArray.push(scheduleObject)
        setScheduleArray(newArray);
        console.log(newArray);
      }
    } else {
      newArray.push(scheduleObject);
      setScheduleArray(newArray);
      console.log(newArray);
    }
  }
  
  const setDateEnd = (date, day) => {
    let scheduleObject = {};
    scheduleObject.day = day;
    let timeISO = date.toISOString();
    scheduleObject.closing_time = timeISO.substring(timeISO.indexOf('T') + 1)

    let newArray = scheduleArray;

    if (newArray.length>0) {
      for (let i=0; i<newArray.length; i++) {
        if (newArray[i].day === scheduleObject.day) {
          newArray[i].closing_time = scheduleObject.closing_time;
        }
      }
      if (!newArray.some(e => e.day === scheduleObject.day)) {
        newArray.push(scheduleObject);
        setScheduleArray(newArray);
      }
    } else {
      newArray.push(scheduleObject);
      setScheduleArray(newArray);
    }
  }
  
  const setBreakStart = (date, day) => {
    let scheduleObject = {};
    scheduleObject.day = day;
    let timeISO = date.toISOString();
    scheduleObject.break_start = timeISO.substring(timeISO.indexOf('T') + 1)

    let newArray = scheduleArray;

    if (newArray.length>0) {
      for (let i=0; i<newArray.length; i++) {
        if (newArray[i].day === scheduleObject.day) {
          newArray[i].break_start = scheduleObject.break_start;
        }
      }
      if (!newArray.some(e => e.day === scheduleObject.day)) {
        newArray.push(scheduleObject);
        setScheduleArray(newArray);
      }
    } else {
      newArray.push(scheduleObject);
      setScheduleArray(newArray);
    }
  }
  
  const setBreakEnd = (date, day) => {
    let scheduleObject = {};
    scheduleObject.day = day;
    let timeISO = date.toISOString();
    scheduleObject.break_end = timeISO.substring(timeISO.indexOf('T') + 1)

    let newArray = scheduleArray;

    if (newArray.length>0) {
      for (let i=0; i<newArray.length; i++) {
        if (newArray[i].day === scheduleObject.day) {
          newArray[i].break_end = scheduleObject.break_end;
        }
      }
      if (!newArray.some(e => e.day === scheduleObject.day)) {
        newArray.push(scheduleObject)
        setScheduleArray(newArray);
      }
    } else {
      newArray.push(scheduleObject)
      setScheduleArray(newArray);
    }
  }

  const removeListing = (listing) => {
    console.log(listing);
    let tempList = newQueueListings;
    console.log(tempList);
    for (let i=0; i<tempList.length; i++) {
      if (tempList[i].id === listing.id) {
        tempList.splice(i, 1);
        setNewQueueListings(tempList);
      }
    }
  }

  const hiddenImgInput = useRef(null);
  const handleClickUploadImg = event => {
    hiddenImgInput.current.click();
    // props.addedPhoto();
  }
  const handleInputChange = event => {
    const fileUploaded = event.target.files[0];
    setNewQueueImage(fileUploaded);
  }

  let payload = {};
  const handleCreateQueue = () => {

    if (!queueTitle) {
      showToast('error', 'Please enter a title');
				return;
    }

    if (!description) {
      showToast('error', 'Please enter a description');
				return;
    }

    if (!startDate) {
      showToast('error', 'Please enter a starting date');
				return;
    }

    if (!endDate) {
      showToast('error', 'Please enter an end date');
				return;
    }

    if (scheduleArray.length === 0) {
      showToast('error', "Please set at least one day's schedule");
      return;
    }

    if (newQueueImage.length === 0) {
      showToast('error', "Please select an image for this queue");
      return;
    }

    payload.page = pageId;
    payload.title = queueTitle;
    payload.description = description;
    
    if (startDate) payload.start = startDate;
    if (endDate) payload.end = endDate;

    payload.gallery = [];
    payload.gallery.push(newQueueImage);
    
    payload.availability = scheduleArray;
    payload.service_type = "QUEUE";
    
    if (linkListing) {
      payload.linked_products = newQueueListings.map(a => a.id);
    }
    payload.max_queue_slot = maxSlot;

    handleCreateNewQueue(payload)
  }


  return (
    <div className="edit__queue">
      <Toaster />
      <div className="date__time__section">
        <p>Set Date</p>
        {/* <label htmlFor="date">
          <input type="date" />
          <FaAngleDown />
        </label> */}
        <div className="datepicker-container">
          <DatePicker
            className="custom-date"
            selected={startDate}
            onChange={(date) => setStartDate(date)}
          />
          {/* <DatePicker
            className="custom-date"
            selected={startDate}
            onSelect=""
            onChange={(date) => setEndDate(date)}
            dateFormat="MMM d, yyyy"
            placeholderText="select date"
            // showTimeInput
            timeInputLabel="Time"
          /> */}
        </div>
        <span>To</span>
        <div className="datepicker-container">
          <DatePicker
            className="custom-date"
            selected={endDate}
            onChange={(date) => setEndDate(date)}
          />
        </div>
        {/* <label htmlFor="date">
          <input type="date" />
          <FaAngleDown />
        </label>
         <div className="daily">
          <p>Daily</p>
          <CustomCheckBox
            getValue={setDaily}
            style={{ minWidth: 18, height: 18 }}
          />
          <FiInfo />
        </div> */}
        <div className="join__auto">
          <p>Join queue automatically</p>
          {/* <div className="toggle" onClick={(e) => setJoinAuto(!joinAuto)}>
            <Switch getState={joinAuto} />
          </div> */}

          <div className={`switch ${!joinAuto && 'off'}`} onClick={()=>{
              setJoinAuto(!joinAuto)
              }}>
              <span className={`${!joinAuto && 'offSpan'}`} ></span>
          </div>

          <FiInfo />
        </div>
        <div
          className="edit__options"
          onClick={(e) => setOptionVisible(!optionVisible)}
        >
          <RevealOptionComponent />
          {optionVisible && (
            <ul className="option__items">
              <li>Pause queue</li>
              <li>Delete queue</li>
            </ul>
          )}
        </div>
      </div>

      <div className="title__price">
        <div className="title-box">
          <h3>Queue Title</h3>
          <input type="text" className="queue-title" value={queueTitle} placeholder="Enter a title" onChange={(event) => setQueueTitle(event.target.value)}/>
        </div>
      </div>

      <div className="description">
        <h3>Description</h3>
        <textarea className="content" contentEditable={true}
        rows="2" placeholder="Enter a short description." value={description} onChange={(event) => setDescription(event.target.value)}
        />
      </div>
      <div className="body__section">
        <div className="header__section">
          <h3>Days</h3>
          <h3>Open Time</h3>
          <h3>Break Period</h3>
        </div>
        <div className="body__content">
          {testArray.map((data, idx) => (
            <div className="group" key={idx}>
              
              <div className="days"> 
                <CustomCheckBox
                  getValue={setUnlimited}
                  style={{ width: 18.5, height: 18.5 }}
                /> &nbsp;
                {data}
              </div>
              <div className="open__time">
                <div className="time__group">
                  <p>from</p> 
                  {/* <input type={'text'} day={data} contentEditable={true} placeholder='10am' onChange={(event) => setDateStart(event)}/> */}

                  <QueueTimer 
                    day={data}
                    setDate={setDateStart}
                  />

                </div>{" "}
                <div className="time__group">
                  <p>to</p> 
                  {/* <input type={'text'} day={data} contentEditable={true} placeholder='6pm'  onChange={(event) => setDateEnd(event)}/> */}
                  
                  <QueueTimer 
                    day={data}
                    setDate={setDateEnd}
                  />
                </div>
              </div>
              <div className="break__period">
                {" "}
                <div className="time__group">
                  <p>from</p> 
                  {/* <input type={'text'} day={data} contentEditable={true} placeholder='10am'  onChange={(event) => setBreakStart(event)}/> */}

                  <QueueTimer 
                    day={data}
                    setDate={setBreakStart}
                  />
                </div>{" "}
                <div className="time__group">
                  <p>to</p> 
                  {/* <input type={'text'} day={data} contentEditable={true} placeholder='6pm'  onChange={(event) => setBreakEnd(event)}/> */}

                  <QueueTimer 
                    day={data}
                    setDate={setBreakEnd}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="maximum__queue">
        <div className="slot">
          Maximum queue slot: <input type={'number'} value={maxSlot} onChange={(event) => setMaxSlot(event.target.value)} />
        </div>
        <div className="unlimited">
          Unlimited{" "}
          <CustomCheckBox
            getValue={setUnlimited}
            style={{ minWidth: 18.5, height: 18.5 }}
          />
        </div>
      </div>
      <div className="link__listing">
        <p>Link Listing</p>

        <div className={`switch ${!linkListing && 'off'}`} onClick={()=>{
            setLinkListing(!linkListing)
            }}>
            <span className={`${!linkListing && 'offSpan'}`} ></span>
        </div>

        <div className="info">
          <FiInfo />
        </div>
        <span>
          Buyer will be required to make purchase of any of the listing to join
          queue
        </span>
        {/* <div className="multiple__selection">Multiple selection</div> */}
        <div
          className="view"
          onClick={() => handleQueueListingsModal("ADD_DATA", "true")}
        >
          Add Listing
        </div>
      </div>
      <div className="qmb-card-body">
        {
          newQueueListings?.length>0 &&
          newQueueListings.map((listing, id) => (
            <div className="qmbc-item-box" id={id}>
              <div className="orc-detail-box">
                <div className="cbcc-item__box">
                  <div className="cbcc-img-holder">
                    <img src={listing.gallary[0]?.file} alt="" />
                  </div>
                  <div className="cbcc-item-detail-one">
                    <h4>{listing.title}</h4>
                  </div>
                </div>
                <div className="btn-box">
                  <IoMdCloseCircleOutline
                    className="close-icon"
                    data-index={id}
                    aria-label={`remove todo ${id}`}
                    onClick={() => removeListing(listing)}
                  />
                </div>
              </div>
            </div>
          ))
        }
        
      </div>
      {/*---------  MEDIA CARD CONTENTS ------------------*/}

      {/* <div className="qclbc-cbc-card">
        <div className="qclbc-cbcc-body">
          <div className="qccb__contents_media">
            <div className="media-container">
              <div className="mediaTabContent">
                <div className="media-title-details">
                  <h5> Create thumbnail </h5>
                  <p>This photo displays on the queue listing thumbnail</p>
                </div>
                <div className="img-details">
                  <button className="edit-btn" onClick="">
                    <EditIcon />
                  </button>
                  <div className="box box-img">
                    <img src={cushionImg} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="add_media_container">
        <section className="add-image-section">
          <div className="add-photo-card">
            <div className="right_text">
              <p className="add-photo-header">Create thumbnail</p>
              <p className="add-photo-sub-header">
                The photo display on the job listing thumbnail
              </p>
            </div>
          </div>

          <div className="add_slide_images">
            {/* <div className="edit_bg">
              <img src={Edit} alt="" className="edit_icon" />
            </div> */}
            {/* <div className="loop_slide_images">
              {Images.map((image) => {
                return (
                  <div className="loop_slide_images">
                    <img key={image.id} alt="" src={image.image} />
                    <div className="delete_icon_bg">
                      <div className="delete_icon">
                        <span>
                          {" "}
                          <FaTimes />{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div> */}

            <input
              id="btn-resume"
              name="btn-resume"
              style={{ display: 'none' }}
              type="file"
              className='upload-btn'
              ref={hiddenImgInput}
              accept={'image/png, image/jpeg, .image/svg'}
              onChange={handleInputChange}
            />

            {
              newQueueImage?.size ? 
                <>
                  {/* <div className="edit_bg">
                    <img src={Edit} className="edit_icon"/>
                  </div> */}
                  <div className="loop_slide_images">
                    
                    <div className="loop_slide_images">
                      <img alt="" src={newQueueImage.size ? URL.createObjectURL(newQueueImage) : null} />
                      <div className="delete_icon_bg" onClick={handleClickUploadImg}>
                        <div className="delete_icon">
                          <span> <FaPencilAlt /> </span>
                        </div>
                      </div>
                    </div>
                      
                  </div>
                </>
                :
                <div className="thumbnail_input">
                  <button type="button" className="upload-btn" onClick={handleClickUploadImg}>
                    <MdFileUpload /> Upload File
                  </button>
                </div>
              }
          </div>

        </section>
      </div>

      <DefaultButton
        text={"Save"}
        customStyle={{
          marginLeft: "auto",
          display: "block",
          borderRadius: 30,
          marginTop: 20,
        }}
        onClick={() => handleCreateQueue()}
      />
    </div>
  );
};

export default EditQueue;
