import { useState, useEffect } from "react";
import { FaToggleOff, FaToggleOn } from "react-icons/fa";

const Switch = ({ initialState, state, onChange = () => {}, ...rest }) => {
  // const [state, setState] = useState(initialState);

  const changeState = () => {
    // setState(!state)
    onChange();
  };

  // useEffect(() => {
  //   getState && getState(state);
  // }, [state]);
  return state ? (
    <FaToggleOn size={40} onClick={changeState} {...rest} />
  ) : (
    <FaToggleOff size={40} onClick={changeState} {...rest} />
  );
};

export default Switch;
