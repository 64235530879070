import { BiUpArrowAlt, BiInfoCircle } from "react-icons/bi";
import "./index.scss";
import ArrowNarrowUp from "../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../assets/svg/arrowNarrowDown";

const OrderCompletionModal = ({
  image,
  title,
  subTitle,
  statPercent,
  statTime,
  bgColor,
  bgColor2,
  bgColor3,
  statTotal,
  general,
  upAndDown,
}) => {
  return (
    <div className="ocm-stats-container" style={{ backgroundColor: bgColor }}>
      <div className="ocm-box-one">
        <div className="ocm-stats-box">{image}</div>

        <div className="ocm-stats-box-contents">
          <h4 style={{ backgroundColor: bgColor2 }}> {title}</h4>
          <h5>{subTitle}</h5>
          <h6>
            {statPercent} {upAndDown}
          </h6>
          <p>{statTime}</p>
        </div>
      </div>

      <div className="ocm-box-two">
        <div className="ocm-value" style={{ backgroundColor: bgColor3 }}>
          {statTotal}
        </div>
        {/* <BiInfoCircle className="info-icon" /> */}
      </div>
    </div>
  );
};
export default OrderCompletionModal;
