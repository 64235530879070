import { cdn } from "../../../config/config.json";
import CustomCheckBox from "../../../components/customCheckBox";
const ModalItem = () => {
  return (
    <div className="item">
      <div className="first">
        <CustomCheckBox
          style={{
            minWidth: 18,
            height: 18,
            borderColor: "var(--text-grey)",
          }}
        />
        <div className="num">001</div>
        <div className="image">
          <img src={`${cdn}/img/Component 672 –1.PNG`} alt="" />
        </div>
      </div>
      <div className="second">
        Coporate shirts for men
        <span>Purchased on 2nd Feb 2021</span>
      </div>
      <div className="price">
        Price <span>$20</span>
      </div>
    </div>
  );
};

export default ModalItem;
