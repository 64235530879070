const Activity = [
    {
        activityTitle: 'Who can see your post',
        activityOptions: [
            {
                title:'Public',
                value: 'PUBLIC'
            },
            {
                title: 'Only me',
                value: 'ONLY_ME'
            },
            {
                title: 'Followers',
                value: 'FOLLOWERS'
            },
        ]
    },
    {
        activityTitle: 'Who can comment on your post',
        activityOptions: [
            {
                title:'Public',
                value: 'PUBLIC'
            },
            {
                title: 'Only me',
                value: 'ONLY_ME'
            },
            {
                title: 'Followers',
                value: 'FOLLOWERS'
            },
        ]
    },
    {
        activityTitle: 'who can see your followers',
        activityOptions: [
            {
                title:'Public',
                value: 'PUBLIC'
            },
            {
                title: 'Only me',
                value: 'ONLY_ME'
            },
            {
                title: 'Followers',
                value: 'FOLLOWERS'
            },
        ]
    },
    {
        activityTitle: 'Who can send me a message?',
        activityOptions: [
            {
                title:'Public',
                value: 'PUBLIC'
            },
            {
                title: 'Followers',
                value: 'FOLLOWERS'
            },
        ]
    },
    {
        activityTitle: 'Who can tag me ',
        activityOptions: [
            {
                title:'Public',
                value: 'PUBLIC'
            },
            {
                title: 'Followers',
                value: 'FOLLOWERS'
            },
        ]
    }
]
export default Activity