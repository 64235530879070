import {
  USER_LOGIN,
  USER_REGISTER,
  LOGIN_ERROR,
  PASSWORD_RESET,
  REG_ERROR,
  USER_ACTIVATE,
  TOGGLE,
} from "./index";
import { setCurrentUser } from "../userData/index.action";

export const userLogin = (data, isSignedIn) => (dispatch) => {
  dispatch({
    type: USER_LOGIN,
    payload: data,
    isSignedIn: isSignedIn,
  });
  return { data, isSignedIn };
};
export const userActivated = (data) => (dispatch) => {
  dispatch({
    type: USER_ACTIVATE,
    payload: data,
  });
};

export const loginError = (data) => (dispatch) => {
  dispatch({
    type: LOGIN_ERROR,
    payload: data,
  });
};
export const userRegister = (data, isRegistered) => (dispatch) => {
  dispatch({
    type: USER_REGISTER,
    payLoad: data,
    isRegistered: isRegistered,
  });
};

export const registrationError = (data) => (dispatch) => {
  dispatch({
    type: REG_ERROR,
    payload: data,
  });
};
export const resetUserPassword = (response) => (dispatch) => {
  dispatch({
    type: PASSWORD_RESET,
    payload: response,
  });
};

export const toggle = (params) => (dispatch) => {
  dispatch({
    type: TOGGLE,
    payload: params,
  });
};
export const currentUser = (user) => (dispatch) => {
  dispatch(setCurrentUser(user));
};
