import React from 'react'
import "./index.scss";
import { components } from 'react-select';
import {
  FaAngleDown,
} from "react-icons/fa";

// fontAwesome to replace react-select Select indicator
const CaretDownIcon = () => {
  return <FaAngleDown color="var(--bg-orange)" className="fav fa-lg"/>
}

const DropdownIndicator = props => {
  return(
    <components.DropdownIndicator {...props} >
    <CaretDownIcon />
    </components.DropdownIndicator>
  )
}

export default DropdownIndicator;
