import { BiInfoCircle, BiUpArrowAlt } from "react-icons/bi";
import "./index.scss";

const ChartStats = ({
  image,
  title,
  statPercent,
  statTime,
  statTotal,
  onClick = () => {},
}) => {
  return (
    <div
      className="chart-stats-container"
      onClick={(e) => {
        onClick();
      }}
    >
      <div className="stats-box">
        {image}
        <div className="stats-box-contents">
          <h5> {title}</h5>
          <h6>
            {statPercent} <span>{statTime}</span>
          </h6>
        </div>
        <div className="sbc-value">{statTotal}</div>
        <BiInfoCircle className="info-icon" />
      </div>
    </div>
  );
};
export default ChartStats;
