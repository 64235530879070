/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxBoldHeartComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      {/* <style>{".a{fill:#c10000;}.b{fill:none;opacity:0;}"}</style> */}
    </defs>
    <g transform="translate(-236 -188)">
      <path
        className="a"
        d="M14.44,0A5.549,5.549,0,0,0,10,2.23,5.552,5.552,0,0,0,0,5.59,10.678,10.678,0,0,0,.52,8.9c1.58,5,6.45,7.99,8.86,8.81a2.181,2.181,0,0,0,1.24,0c2.41-.82,7.28-3.81,8.86-8.81A10.678,10.678,0,0,0,20,5.59,5.574,5.574,0,0,0,14.44,0Z"
        transform="translate(238 191.1)"
      />
      <path
        className="b"
        d="M0,0H24V24H0Z"
        transform="translate(260 212) rotate(180)"
      />
    </g>
  </svg>
);

export default VuesaxBoldHeartComponent;
