import React from "react";
import Styled from "styled-components";
import {
  Col,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FiMessageCircle } from "react-icons/fi";
import { IoMdShareAlt } from "react-icons/io";
import { GiPin } from "react-icons/gi";
import { colors } from "../../color";
import { LikeIcon } from "../../styles";
import {
  BookmarkBorderOutlined,
  MoreHoriz,
  OpenInNew,
  DeleteSweep,
  Visibility,
  Report,
} from "@material-ui/icons";

// images
import happy from "../../assets/icons/happy-people.png";

const Body = Styled.div`
    height: 345px;
    background: white;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 9px 15px;
    border-radius: 10px;
`;
const PostImage = Styled.div`
    width: 95%;
    height: 325px;
    display: flex;
    padding-top: 15px;
`;
const Image = Styled.img`
    height: 100%;
    width: 100%;
    object-fit: cover;
`;
const Avatar = Styled.div`
    height: 30px;
    width: 30px;
    border: 2px solid #A303A0;
`;
const Username = Styled.p`
    color: ${colors.COMPANY_COLOR};
    font-size: 15px;
    font-weight: 700;
`;
const PostText = Styled.p`
    font-size: 10px;
    color:  #787878;
`;
export const ShareBtn = Styled.button`
    background: none;
    outline: none;
    border: none;
    cursor: pointer;
`;

const CommentAvatar = Styled.div`
    height: 25px;
    width: 25px;
`;
export const Elipses = Styled(Dropdown)`
  background: transparent;
  border: none;
  outline: none;
`;
export const Item = Styled(DropdownItem)`
  color: ${colors.COMPANY_COLOR};
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  width: 100%;
  border-top: 1px solid #7070702d;
`;
export const style = {
  color: `${colors.COMPANY_COLOR}`,
  cursor: "pointer",
  fontSize: "22px",
};

const iconStyle = {
  fontSize: "20px",
  marginRight: "10px",
  fontWeight: "500",
};
class PostBody extends React.Component {
  constructor(props) {
    super(props);
    this.state = { dropdown: false };
  }

  toggle = () => {
    this.setState({ dropdown: !this.state.dropdown });
  };

  comment_section = () => {
    return (
      <div className="d-flex" style={{ width: "100%" }}>
        <div className="d-flex justify-content-between pr-2">
          <CommentAvatar className="bg-dark rounded-circle"></CommentAvatar>
        </div>
        <div className="m-auto" style={{ width: "93%" }}>
          <Username style={{ fontSize: "15px" }}>Rose Kooto</Username>
          <PostText style={{ fontSize: "10px" }}>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s, when an unknown printer took a galley of type and scrambled
            it to make a type specimen book.{" "}
          </PostText>{" "}
        </div>
      </div>
    );
  };
  render() {
    return (
      <Body className="mt-4 mb-4">
        <Col xl={12}>
          <div className="d-flex justify-content-between">
            <div style={{ width: "53%" }}>
              <PostImage className="mr-5">
                <Image className="rounded" src={happy} alt="" />
              </PostImage>
            </div>
            <div style={{ width: "46%" }}>
              <div style={{ width: "100%" }}>
                <div
                  className="d-flex justify-content-between"
                  style={{ width: "100%" }}
                >
                  <div
                    className="d-flex justify-content-between align-items-center"
                    style={{ width: "50%" }}
                  >
                    <Avatar className="rounded-circle bg-dark" />
                    <div>
                      <Username className="pt-3">Makinde Akinola</Username>
                    </div>
                  </div>
                  <Elipses
                    direction="down"
                    isOpen={this.state.dropdown}
                    toggle={this.toggle}
                    style={{ width: "60px !important" }}
                  >
                    <DropdownToggle
                      style={{
                        background: "transparent",
                        border: "none",
                        outline: "none",
                      }}
                      outline={true}
                    >
                      <MoreHoriz
                        style={{ color: `${colors.LIGHT_GREY}` }}
                        className="mt-2"
                      />
                    </DropdownToggle>
                    <DropdownMenu className="dropdown">
                      <Item>
                        <Visibility style={iconStyle} />
                        Hide
                      </Item>
                      <Item>
                        <OpenInNew style={iconStyle} />
                        Copy Link
                      </Item>
                      <Item>
                        <GiPin style={iconStyle} />
                        Pin
                      </Item>
                      <Item>
                        <Report style={iconStyle} />
                        Report Post
                      </Item>
                      <Item>
                        {" "}
                        <DeleteSweep style={iconStyle} />
                        Delete Post
                      </Item>
                    </DropdownMenu>
                  </Elipses>
                </div>
                <div className="mt-1">
                  <PostText>
                    is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy
                    text ever since the 1500s, when an unknown printer took a
                    galley of type and scrambled it to make a type specimen book
                  </PostText>
                </div>
                <div>
                  <PostText className="pt-2 font-weight-bold">
                    View all comments
                  </PostText>
                  {this.comment_section()}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex align-items-center justify-content-between"
                    style={{ width: "30%" }}
                  >
                    <LikeIcon />
                    <FiMessageCircle style={style} />
                    <BookmarkBorderOutlined style={style} />
                  </div>
                  <div className="d-flex justify-content-end">
                    <ShareBtn>
                      <IoMdShareAlt style={style} />
                    </ShareBtn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Col>
      </Body>
    );
  }
}

export default PostBody;
