export const ADD_NEW_POSTS = "ADD_NEW_POSTS",
  STORE_FRESH_POST_DATA = "STORE_FRESH_POST_DATA",
  DELETE_POST = "DELETE_POST",
  UPDATE_POST = "UPDATE_POST",
  UPDATE_NEXT = "UPDATE_NEXT",
  UPDATE_PREV = "UPDATE_PREV",
  CREATED_NEW_POST = "CREATED_NEW_POST",
  RESET_POSTS = "RESET_POSTS",
  UPDATE_LOADING = "UPDATE_LOADING",
  NEW_POST_LOADING = "NEW_POST_LOADING",
  UPDATE_LAST_COMMENT = "UPDATE_LAST_COMMENT",
  UPDATE_POST_COMMENT = "UPDATE_POST_COMMENT",
  UPDATE_REPLIES = "UPDATE_REPLIES",
  CREATE_NEW_COMMENT = "CREATE_NEW_COMMENT",
  CREATE_NEW_REPLY = "CREATE_NEW_REPLY",
  UPDATE_POST_LIKE = "UPDATE_POST_LIKE",
  UPDATE_COMMENT_LIKE = "UPDATE_COMMENT_LIKE";
