/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearSmsComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 36.623 36.623" {...props}>
    <defs>
      <style>
        {
          ".a-sms-icon,.b-sms-icon{fill:none;}.a-sms-icon{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}.b-sms-icon{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(0 0)">
      <g transform="translate(0 0)">
        <path
          className="a-sms-icon"
          d="M22.889,25.941H7.63c-4.578,0-7.63-2.289-7.63-7.63V7.63C0,2.289,3.052,0,7.63,0h15.26c4.578,0,7.63,2.289,7.63,7.63V18.311C30.519,23.652,27.467,25.941,22.889,25.941Z"
          transform="translate(3.052 5.341)"
        />
        <path
          className="a-sms-icon"
          d="M15.26,0,10.483,3.815a4.831,4.831,0,0,1-5.722,0L0,0"
          transform="translate(10.682 13.734)"
        />
        <path className="b-sms-icon" d="M0,0H36.623V36.623H0Z" />
      </g>
    </g>
  </svg>
);

export default VuesaxLinearSmsComponent;
