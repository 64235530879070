import { DefaultSave } from "../../assets/leftBarIcons/VuesaxLinearArchiveMinusComponent";
import VuesaxOutlineHeartTickComponent from "../../assets/svg/VuesaxOutlineHeartTickComponent";

const Users = ({ page, avatar }) => {
  return (
    <div className="item">
      <div className="avatar">
        <img src={page.avatar} alt="avatar" />
      </div>
      <div className="detail">
        <div className="name">{page.name}</div>
        <div className="state">{page.state}</div>
      </div>
      <div className="follow">Follow</div>
    </div>
  );
};

export default Users;
