import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import "./pageSetup.scss";
import Person from "../../assets/svg/person";
import logowhite from "../../assets/icons/meeney__white-logo.png";
import ReactFlagsSelect from "react-flags-select";
import useCreatePage from "../../hooks/createPage";
import Loader from "../../components/loader";
import LoaderWhite from "../../assets/loaders/LoaderWhite";

const PageSetup = () => {
  const { user, handlePageCreation, loading } = useCreatePage();
  const [file, setFile] = useState(null);
  const imageRef = useRef(null);
  const [selected, setSelected] = useState("");
  const [formValues, setFormValues] = useState({
    first_name: user.first_name,
    type: "PRIVATE",
    last_name: user.last_name,
    phone_number: user.phone_number || "",
    country: user.country || "US",
    name: user.full_name,
  });
  console.log(user);
  const handlePhoneInput = (value) => {
    if (!value) {
      return null;
    }
    setFormValues({
      ...formValues,
      phone_number: value,
    });
  };
  const handleSubmission = (e) => {
    e.preventDefault();
    if (formValues.first_name.trim() === "") {
      return;
    }
    if (formValues.last_name.trim() === "") {
      return;
    }
    if (formValues.country.trim() === "") {
      return;
    }
    if (formValues.phone_number.trim() === "") {
      return;
    }
    //handle file upload if exist before final request

    handlePageCreation({ data: formValues, file });
  };
  const handleInputChange = (input) => {
    // console.log(input.target.name);
    setFormValues({
      ...formValues,
      [input.target.name]: input.target.value,
    });
  };

  useEffect(() => {}, []);
  return (
    <div className="pageSetup-container">
      <div className="wp-top-banner">
        <img className="img-logo" alt="meeney logo" src={logowhite} />
      </div>
      <div className="ps-left-container">
        <div className="top-ps-header">
          <h2> You are almost done setting up.</h2>
          <p>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard
          </p>
        </div>

        <div className="psl-photo-container">
          <div className="circle_ph">
            <Person />
          </div>
          <div className="link-box">
            <p>
              Upload a Photo to <br /> complete the page set up
            </p>
          </div>
        </div>

        <div className="ps-lc-form">
          <form onSubmit={handleSubmission}>
            <div className="input-group">
              {/* <label htmlFor="">First name </label> */}
              <input
                placeholder="First name"
                className="form-input"
                type="text"
                name="first_name"
                disabled
                value={formValues.first_name}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group">
              {/* <label htmlFor="">Last name </label> */}
              <input
                placeholder="Last name"
                className="form-input"
                type="text"
                name="last_name"
                disabled
                value={formValues.last_name}
                onChange={handleInputChange}
              />
            </div>

            <div className="input-group">
              {/* <label htmlFor="">Phone</label> */}
              <PhoneInput
                international
                defaultCountry="US"
                placeholder="(201) 555-55555"
                value={formValues.phone_number}
                onChange={(value) => handlePhoneInput(value)}
              />
            </div>
            <div className="input-group">
              {/* <label htmlFor="">Location</label> */}
              <ReactFlagsSelect
                selected={formValues.country}
                onSelect={(code) => {
                  setSelected(code);
                  setFormValues({ ...formValues, country: code });
                }}
                placeholder="Tronto"
                className="form-input fi-location"
              />
            </div>

            <div className="link-opt pb-100">
              <Link
                exact
                to="/welcomepage"
                className="btn-back-link"
                onClick="">
                Back
              </Link>
              <button
                exact
                // to="/privateseller/dashboard"
                type="submit"
                className="btn-start-link"
                // onClick=""
              >
                {!loading ? (
                  "Get me started"
                ) : (
                  <LoaderWhite width={20} height={20} />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="ps-right-container">
        {/* <div className="circle__A"></div>
        <div className="circle__B"></div> */}
        <div className="circle__C"></div>
        <img className="img-logo" alt="meeney logo" src={logowhite} />
        <div className="ps-rc-title">
          <h3> {user.full_name} </h3>
        </div>
        <div className="photo-container">
          <div
            className="circle_p"
            style={{
              overflow: "hidden",
            }}>
            {!file ? (
              <Person />
            ) : (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={{
                  width: "auto",
                  height: "100%",
                }}
              />
            )}
          </div>
          <div className="link-box">
            <span
              className="btn-link"
              onClick={(e) => imageRef.current.click()}>
              Upload an image
            </span>
            <input
              type={"file"}
              accept={"image/*"}
              style={{ display: "none" }}
              ref={imageRef}
              onChange={(e) => {
                if (e.target.files[0]) {
                  setFile(e.target.files[0]);
                } else {
                  setFile(null);
                }
              }}
            />
            <p>Upload a Photo to complete the page set up</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageSetup;
