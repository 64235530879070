import { useState, useEffect, useRef } from "react";
import { MoreOptionsIcon } from "../../../assets/svg/Extras";
import "./card.scss";
import { FaEllipsisV } from "react-icons/fa";

const dropdownData = [{ label: "Delete" }, { label: "Resend" }];

const AnnouncementCard = () => {
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(dropdownData);
  const announcementCard = useRef(null);

  const toggleDropdown = () => setOpen(!isOpen);

  //dropdown click outside
  const handleClickOutside = (event) => {
    if (
      announcementCard.current &&
      !announcementCard.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div className="announcement-card" ref={announcementCard}>
      <div className="ac-card-top">
        <h5>
          Today <span> 10am </span>
        </h5>
        <div className="live-box">
          <div className="active">Live</div>
          <div className="options__wrap">
            <FaEllipsisV className="dropdown-icon" onClick={toggleDropdown} />
            {isOpen && (
              <div className={`dropdown-body ${isOpen && "open"}`}>
                {items.map((item) => (
                  <div className="dropdown-item">{item.label}</div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <p>
        is simply dummy text of the printing and typesetting industry. Lorem
        Ipsum has been the industry's standard"
        <button> Read More</button>
      </p>
    </div>
  );
};

export default AnnouncementCard;
