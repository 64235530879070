const People = ({fill}) =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="15.047" height="15.937" viewBox="0 0 15.047 15.937">
  <g id="Group_1223" data-name="Group 1223" transform="translate(-1084.016 -972.121)">
    <path id="Path_225" data-name="Path 225" d="M4.675,40.7a5.99,5.99,0,0,1-3.135-.91,1.654,1.654,0,0,1-.76-1.115l-.753-4.23A1.649,1.649,0,0,1,1.644,32.51H7.819a1.647,1.647,0,0,1,1.618,1.978L8.589,38.71a1.667,1.667,0,0,1-.75,1.079,6,6,0,0,1-3.056.911Zm-3.027-7.29a.746.746,0,0,0-.735.877l.751,4.244a.757.757,0,0,0,.349.509,5.124,5.124,0,0,0,2.763.768,5.089,5.089,0,0,0,2.6-.782.759.759,0,0,0,.334-.494l.847-4.23a.746.746,0,0,0-.733-.9Z" transform="translate(1084.015 945.455)" fill={fill || "#490057"}/>
    <path id="Path_226" data-name="Path 226" d="M13.51,5.627a2.813,2.813,0,1,1,2.817-2.813A2.813,2.813,0,0,1,13.51,5.627ZM13.51.9a1.915,1.915,0,1,0,1.918,1.915A1.915,1.915,0,0,0,13.51.9Z" transform="translate(1075.239 972.121)" fill={fill || "#490057"}/>
    <path id="Path_227" data-name="Path 227" d="M52.941,11.553a2.6,2.6,0,1,1,2.6-2.6A2.6,2.6,0,0,1,52.941,11.553Zm0-4.372a1.768,1.768,0,1,1,0,.005Z" transform="translate(1042.726 966.913)" fill={fill || "#490057"}/>
    <path id="Path_228" data-name="Path 228" d="M34.818,57.232h-.487a.049.049,0,0,0-.047.036,5.382,5.382,0,0,1-7.712,3.4.049.049,0,0,1,.032-.092,5.477,5.477,0,0,0,.992.092A5.377,5.377,0,0,0,32.587,57.3a.05.05,0,0,0-.047-.068H31.323a.05.05,0,0,1-.034-.085l1.684-1.693.162-.164L34.4,56.663l.449.485a.05.05,0,0,1-.036.083Z" transform="translate(1062.248 926.771)" fill={fill || "#490057"}/>
    <path id="Path_229" data-name="Path 229" d="M61.67,38.343l-.608,3.036a1.177,1.177,0,0,1-.2.462l-.458-.487a.616.616,0,0,0,.031-.1l.608-3.035a.539.539,0,0,0-.527-.642H56.87l.115-.645H60.51a1.181,1.181,0,0,1,1.16,1.413Z" transform="translate(1037.37 941.83)" fill={fill || "#490057"}/>
  </g>
</svg>

    )
}

export default People