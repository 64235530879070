import { Line } from "react-chartjs-2";
// import { Chart as ChartJS, CategoryScale } from "chart.js";
import { Chart as ChartJS } from "chart.js/auto";
import WalletMoney from "../../../assets/svg/wallet-money";
import "./index.scss";
import useAnalytics from "../../../hooks/Analytics";



const SalesGrowthChart = ({ image, title, width, height, AnalyticsOthers, others, listingInteractions, listingChart}) => {
  const chartDate = listingChart?.sales_growth.sum.chart?.sort(function(a, b) {return a.month-b.month})
  const barChartData = {   
    labels: chartDate?.map(item => {
      if(item.month === 1){
        return "Jan"
      }
      if(item.month === 2){
        return "Feb"
      }
      if(item.month === 3){
        return "Mar"
      }
      if(item.month === 4){
        return "Apr"
      }
      if(item.month === 5){
        return "May"
      }
      if(item.month === 6){
        return "Jun"
      }
      if(item.month === 7){
        return "Jul"
      }
      if(item.month === 8){
        return "Aug"
      }
      if(item.month === 9){
        return "Sep"
      }
      if(item.month === 10){
        return "Oct"
      }
      if(item.month === 11){
        return "Nov"
      }
      if(item.month === 12){
        return "Dec"
      }
    }),
    datasets: [
      {
        label: "listing",
        fill: false,
        // backgroundColor: ,
        borderColor: "rgba(33, 213, 155, 1)",
        borderCapStyle: "round",
        borderJoinStyle: "miter",
        tension: 0.4,
        pointBorderColor: "rgba(73, 0, 87, 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 0.5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(73, 0, 87, 1)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        // data: [200, 180, 300, 250, 450, 500, 320, 400, 350, 450, 600, 800],
        data: listingChart?.sales_growth.sum.chart.map(item => item.total),
      },
       {
        label: "listing",
        fill: false,
        // backgroundColor: ,
        borderColor: "rgba(33, 213, 155, 1)",
        borderCapStyle: "round",
        borderJoinStyle: "miter",
        tension: 0.4,
        pointBorderColor: "rgba(73, 0, 87, 1)",
        pointBackgroundColor: "#fff",
        pointBorderWidth: 0.5,
        pointHoverRadius: 8,
        pointHoverBackgroundColor: "#fff",
        pointHoverBorderColor: "rgba(73, 0, 87, 1)",
        pointHoverBorderWidth: 2,
        pointRadius: 0,
        pointHitRadius: 10,
        // data: [200, 180, 300, 250, 450, 500, 320, 400, 350, 450, 600, 800],
        data: listingChart?.sales_growth.count.chart.map(item => item.total),
      },
      
    ],
  };
  
  const barChartOptions = {
    responsive: true,
    layout: {
      padding: 20,
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        usePointStyle: true,
        //   backgroundColor: "rgba(255, 255, 255, 1)",
        //   bodyColor: "rgba(126, 132, 163, 1)",
        callbacks: {},
      },
    },
    scales: {
      x: {
        type: "category",
        // labels: ["Jan", "Jun", "Dec"],
  
        ticks: {
          color: "rgba(73, 0, 87, 1)",
          fontSize: 14,
        },
        grid: {
          lineWidth: 1,
          color: "rgba(217, 217, 217, 1)",
        },
      },
      y: {
        display: true,
        beginAtZero: true,
        grid: {
          display: false,
        },
        ticks: {
          color: "rgba(126, 132, 163, 1)",
          fontSize: 14,
        },
      },
    },
  
    animation: {
      easing: "linear",
  
      y: {
        duration: 2000,
        from: 500,
      },
    },
  };
  
 console.log(chartDate)
  
  return (
    <div className="sales-growth-container">
      <h5>
        <span>{image}</span>
        {title}
      </h5>
      <Line
        data={barChartData}
        options={barChartOptions}
        height={height}
        width={width}
      />
    </div>
  );
};
export default SalesGrowthChart;
