import "./queueHistory.scss";
import { FaAngleDown } from "react-icons/fa";
import { useState, useRef, useEffect, useContext } from "react";
import CustomDropdown from "../../../components/customDropdown/customDropdown";
import {
  FaSortAmountDownAlt,
  FaCalendarAlt,
  FaChevronDown,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useExplore from "../../../hooks/explore";
import ExploreContext from "../../../hooks/exploreContext";
import moment from "moment";

const QueueHistory = () => {
  const { handleGetQueueHistory } = useExplore();
  const { queueHistory } = useContext(ExploreContext);

  useEffect(() => {
    handleGetQueueHistory();
  }, []);

  console.log(queueHistory);

  const dateInput = useRef(null);
  const testArray = ["Completed", "Completed", "Incomplete"];

  const testArray2 = [1, 2, 3, 4];

  const data1 = [
    { label: "All", icon: "." },
    { label: "Completed", icon: "." },
    { label: "Incomplete", icon: "." },
  ];

  const [startDate, setStartDate] = useState(new Date());

  return (
    <div className="queue__history">
      <div className="history__filter">
        {/* <div className="date">
          <h3>Date</h3>
          <label htmlFor="date">
            <input type="date" ref={dateInput} />
            <FaAngleDown
              onClick={(e) => {
                dateInput.current.focus();
                dateInput.current.click();
              }}
            />
          </label>
        </div> */}

        <div className="status">
          <h3>Date</h3>
          <div className="datepicker-container">
            {/* <FaCalendarAlt /> */}

            <DatePicker
              className="custom-date"
              selected={startDate}
              onSelect=""
              onChange={(date) => setStartDate(date)}
              dateFormat="MMM d, yyyy"
              placeholderText="select date"
              // showTimeInput
              timeInputLabel="Time"
            />
            <FaChevronDown />
          </div>
        </div>
        <div className="status">
          <h3>Status</h3>
          {/* <div className="select__wrap">
            <label htmlFor="list">
              <p>show status</p>
              <select name="" id="" defaultValue={"all"}>
                <option value="all"> All</option>
                <option value={"pending"}>Pending</option>
                <option value="completed">Completed</option>
                <option value="incomplete">Incomplete</option>
                <option value="canceled">Canceled</option>
                <option value="declined">Declined</option>
              </select>
            </label>
          </div> */}
          <div className="sort-box">
            <div className="sort-input">
              <span> Show status </span>
              <div className="dropbox2">
                <CustomDropdown items={data1} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="timing">
        <p>
          Average Waiting Time: <span>20Mins</span>
        </p>
        <p>
          Average Service Time: <span>20Mins</span>
        </p>
        <p>
          Completed Service: <span>20</span>
        </p>
      </div>
      <div className="main__items">
        {
          queueHistory?.data?.count > 0 &&
          queueHistory?.data.map((item, index) => (
            <div className="item">
              <div>
                Maria Sponge <span>@username</span>
              </div>
              <div>Joined: 10:00am</div>
              <div>Left: 1:00pm</div>
              {/* <div className={data.toLowerCase()}>{data}</div> */}
            </div>
          ))
        }

        {testArray.map((data, idx) => (
          <div className="item">
            <div>
              Maria Sponge <span>@username</span>
            </div>
            <div>Joined: 10:00am</div>
            <div>Left: 1:00pm</div>
            <div className={data.toLowerCase()}>{data}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QueueHistory;
