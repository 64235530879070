import { useContext, useEffect, useState, useRef, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import "./createbookingModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import CustomDropdown from "../../customDropdown/customDropdown";
import CustomCheckBox from "../../Bookings/createBooking/checkBox";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "../../switch";
import { BiInfoCircle } from "react-icons/bi";
import { FaChevronDown, FaPlus } from "react-icons/fa";
import CloseBtn from "../../../components/closeBtn";
import Dates from "./bookingDates";
import Mins from "./mins";
import AvailabilityHours from "./availabilityHours";
import SpecificDates from "./specificDates";
import moment from "moment";
import { showToast } from "../../ReactToast";
import toast, { Toaster } from "react-hot-toast";
import useBooking from "../../Bookings/useBooking";
import qs from "query-string";

const data = [
  { label: "Reoccuring Schedule" },
  { label: "Select specific dates" },
];

const CreateBookingModal = () => {
  const {
    handleBookingModal,
    personnelFormList,
    personnelFormValue,
    postPersonnel,
    setPostPersonnel,
    toggleEditPersonnel,
    setToggleEditPersonnel,
    isEditPersonnel,
    setIsEditPersonnel,
    toggleEditPersonnelPost,
    setToggleEditPersonnelPost,
    bookingModal,
    businessHours,
    bookingPSform,
    setBookingPSform,
    setBusinessHours,
    serviceToggleSubmit,
    setServiceToggleSubmit,
  } = useContext(ModalContext);

  const {
    handlePostPagePersonnels,
    postPagePersonnelData,
    handleUpdatePagePersonnel,
    updatePagePersonnelData,
    singlePersonnelData,
    singlePageData,
    handleGetSinglePage,
    handlePersonnelHours,
    personnelHoursData,
    handleUpdatePersonnelAvailability,
    pageWorkingHoursData,
    handleGetpageWorkingHours,
    handleRemovePersonnelAvailability,
    handleAddPersonnelAvailability,
    addPersonnelAvailabilityData,
    handlePostPersonnels,
    postPersonnelData,
  } = useBooking();

  const [userPersonnel, setUserPersonnel] = personnelFormList;

  const [isOpen, setOpen] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [selectedItem, setSelectedItem] = useState(data[0].label);
  const [unavailableDates, setUnavailableDates] = useState([]);
  const [number, setNumber] = useState(12);
  const [startTime, setStartTime] = useState("09:00");
  const [endTime, setEndTime] = useState(new Date());
  const [breakStartTime, setBreakStartTime] = useState(new Date());
  const [breakEndTime, setBreakEndTime] = useState(new Date());
  const { search } = useLocation();
  const { id } = qs.parse(search);

  const validate = (values) => {
    let errors = {};

    if (!values.username) {
      errors.username = "please enter personnel username";
    }

    if (!values.title) {
      errors.title = "please enter personnel title";
    }

    if (values.add_holidays && !values.unavailable_dates.length) {
      errors.add_holidays = "please add unavailable date or uncheck holiday";
      console.log("please add unavailable date or uncheck holiday");
    }

    return errors;
  };

  // const handleEditStock = (tempId) => {
  //   const data = body.stocks.find((item) => item.tempId === tempId && item);
  //   setInitialStock(data);
  //   setBody({
  //     ...body,
  //     stocks: body.stocks.filter((item) => item.tempId !== tempId && item),
  //   });
  //   setAddStock(true);
  // };

  const handleSlotChange = (e) => {
    const { name, value } = e.target;
    setPersonnelForm({ ...personnelForm, [name]: Number(value) });
  };
  const toggleDropdown = (e) => setOpen(!isOpen);

  /* datepicker */
  const [reoccuringStartDate, setReoccuringStartDate] = useState(new Date());
  const [reoccuringEndDate, setReoccuringEndDate] = useState(new Date());
  const [mDate, setMDate] = useState(new Date());
  const [mDate2, setMDate2] = useState(new Date());
  const [date2, setDate2] = useState([]);

  //useMemo
  const acceptedTimeRange = useMemo(() => {
    // return {
    //   start: "09:15",
    //   end: "18:30",
    // };
    // if (businessHours?.length) {
    return [
      {
        opening_time:
          businessHours?.length > 0
            ? `${businessHours[0]?.start_time}`
            : "09:00",
        closing_time:
          businessHours?.length > 0
            ? `${businessHours[0]?.closing_time}`
            : "18:00",
      },
      {
        opening_time:
          businessHours?.length > 0
            ? `${businessHours[1]?.start_time}`
            : "09:00",
        closing_time:
          businessHours?.length > 0
            ? `${businessHours[1]?.closing_time}`
            : "18:00",
      },
      {
        opening_time:
          businessHours?.length > 0
            ? `${businessHours[2]?.start_time}`
            : "09:00",
        closing_time:
          businessHours?.length > 0
            ? `${businessHours[2]?.closing_time}`
            : "18:00",
      },
      {
        opening_time:
          businessHours?.length > 0
            ? `${businessHours[3]?.start_time}`
            : "09:00",
        closing_time:
          businessHours?.length > 0
            ? `${businessHours[3]?.closing_time}`
            : "18:00",
      },
      {
        opening_time:
          businessHours?.length > 0
            ? `${businessHours[4]?.start_time}`
            : "09:00",
        closing_time:
          businessHours?.length > 0
            ? `${businessHours[4]?.closing_time}`
            : "18:00",
      },
      {
        opening_time:
          businessHours?.length > 0
            ? `${businessHours[5]?.start_time}`
            : "09:00",
        closing_time:
          businessHours?.length > 0
            ? `${businessHours[5]?.closing_time}`
            : "18:00",
      },
      {
        opening_time:
          businessHours?.length > 0
            ? `${businessHours[6]?.start_time}`
            : "09:00",
        closing_time:
          businessHours?.length > 0
            ? `${businessHours[6]?.closing_time}`
            : "14:00",
      },
    ];
    // }
  }, []);
  const daysArray = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [availabilityForm, setAvailabilityForm] = useState([
    {
      mDate: mDate2,
      date: mDate,
      day: 0,
      opening_time: "",
      closing_time: "",
      break_start: "",
      break_end: "",
      publish: true,
    },
  ]);

  const addSpecificDatesFields = () => {
    setAvailabilityForm([
      ...availabilityForm,
      {
        mDate: mDate2,
        date: `${moment(mDate).format("YYYY-MM-DD")}`,
        day: 0,
        opening_time: "",
        closing_time: "",
        break_start: "",
        break_end: "",
        publish: true,
      },
    ]);
  };
  const addSpecificDatesFields2 = () => {
    setAvailabilityForm([
      ...availabilityForm,
      {
        mDate: mDate2,
        date: `${moment(mDate).format("YYYY-MM-DD")}`,
        day: 0,
        opening_time: "09:00",
        closing_time: "15:00",
        break_start: "12:00",
        break_end: "13:00",
        publish: true,
      },
    ]);
    const newarr = availabilityForm.pop();
    handleAddPersonnelAvailability(personnelForm.id, newarr);
    //    setAvailabilityForm([
    //   ...availabilityForm,
    //   {
    //     mDate: mDate2,
    //     date: `${moment(mDate).format("YYYY-MM-DD")}`,
    //     day: 0,
    //     opening_time: "",
    //     closing_time: "",
    //     break_start: "",
    //     break_end: "",
    //     publish: true,
    //   },
    // ]);
    console.log(newarr);
  };

  const [availabilityForm2, setAvailabilityForm2] = useState([
    {
      publish: false,
      // date: null,
      day: 0,
      name: "Monday",
      opening_time: acceptedTimeRange[0]?.opening_time,
      closing_time: `${acceptedTimeRange[0]?.closing_time}`,
      break_start: "12:00",
      break_end: "13:00",
      isDisabled: false,
    },
    {
      publish: false,
      // date: null,
      day: 1,
      name: "Tuesday",
      opening_time: acceptedTimeRange[1]?.opening_time,
      closing_time: `${acceptedTimeRange[1]?.closing_time}`,
      break_start: "12:00",
      break_end: "13:00",
      isDisabled: false,
    },
    {
      publish: false,
      // date: null,
      day: 2,
      name: "Wednesday",
      opening_time: acceptedTimeRange[2]?.opening_time,
      closing_time: `${acceptedTimeRange[2]?.closing_time}`,
      break_start: "12:00",
      break_end: "13:00",
      isDisabled: false,
    },
    {
      publish: false,
      // date: null,
      day: 3,
      name: "Thursday",
      opening_time: acceptedTimeRange[3]?.opening_time,
      closing_time: `${acceptedTimeRange[3]?.closing_time}`,
      break_start: "12:00",
      break_end: "13:00",
      isDisabled: false,
    },
    {
      publish: false,
      // date: null,
      day: 4,
      name: "Friday",
      opening_time: acceptedTimeRange[4]?.opening_time,
      closing_time: `${acceptedTimeRange[4]?.closing_time}`,
      break_start: "12:00",
      break_end: "13:00",
      isDisabled: false,
    },
    {
      publish: false,
      // date: null,
      day: 5,
      name: "Saturday",
      opening_time: acceptedTimeRange[5]?.opening_time,
      closing_time: `${acceptedTimeRange[5]?.closing_time}`,
      break_start: "12:00",
      break_end: "13:00",
      isDisabled: false,
    },
    {
      publish: false,
      // date: null,
      day: 6,
      name: "Sunday",
      opening_time: acceptedTimeRange[6]?.opening_time,
      closing_time: `${acceptedTimeRange[6]?.closing_time}`,
      break_start: "12:00",
      break_end: "13:00",
      isDisabled: false,
    },
  ]);

  console.log("aVailability 1", availabilityForm);
  console.log("aVailability 2", availabilityForm2);

  const initialFormState = {
    // id: new Date().getTime().toString(),
    page: id,
    username: "",
    title: "",
    schedule_type: "Reocurring schedule",
    // availability: availabilityForm2,
    availability: availabilityForm2,
    available_from: `${moment(reoccuringStartDate).format("YYYY-MM-DD")}`,
    available_to: `${moment(reoccuringEndDate).format("YYYY-MM-DD")}`,
    add_holidays: false,
    // unavailable_dates: unavailableDates,
    max_per_slot: 1,
    unlimited_slot: false,
    appointment_duration: {
      period: "minutes",
      every: 12,
    },
  };

  console.log("THis is booking modal", bookingModal);

  // const personnelFormValue = useState(initialFormState)
  // const [personnelForm, setPersonnelForm] = formState;
  const [personnelForm, setPersonnelForm] = personnelFormValue;

  console.log("Personnel form context", personnelForm);
  console.log("Personnel edit personnel form", isEditPersonnel);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setPersonnelForm({ ...personnelForm, [name]: value });
    // setPersonnelForm(event.currentTarget.value);
  };

  const business_opening_hours = pageWorkingHoursData?.data?.results.map(
    (item) => {
      return item?.weekdays;
    }
  );
  const business_opening_hours2 = pageWorkingHoursData?.data?.results.map(
    (item) => {
      return item;
    }
  );

  console.log("business_opening_hours2", business_opening_hours2);
  useEffect(() => {
    handleGetSinglePage(id);
    handleGetpageWorkingHours(id);
  }, []);

  // useEffect(() => {
  //   if (toggleSubmit) {
  //     testBusinessHours();
  //   }
  //   if (!toggleEditPersonnel) {
  //     testBusinessHours();
  //   }
  // }, [singlePageData.response]);

  const personnel_availability = availabilityForm2?.map((item) => {
    return item?.day;
  });

  console.log("opening hours1", business_opening_hours);
  console.log("opening hours1aaa", personnel_availability);

  const testBusinessHours = () => {
    const newState = [...availabilityForm2];
    if (business_opening_hours?.includes("0") === false) {
      newState[0].isDisabled = true;
    }
    if (business_opening_hours?.includes("1") === false) {
      newState[1].isDisabled = true;
    }
    if (business_opening_hours?.includes("2") === false) {
      newState[2].isDisabled = true;
    }
    if (business_opening_hours?.includes("3") === false) {
      newState[3].isDisabled = true;
    }
    if (business_opening_hours?.includes("4") === false) {
      newState[4].isDisabled = true;
    }
    if (business_opening_hours?.includes("5") === false) {
      newState[5].isDisabled = true;
    }
    if (business_opening_hours?.includes("6") === false) {
      newState[6].isDisabled = true;
    }
    return setAvailabilityForm2(newState);
  };

  console.log("ddddddd", personnelHoursData?.response?.response?.status);

  const handlePersonnelCheckbox = (input, value) => {
    setPersonnelForm((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleAppointmentChange = (input, e) => {
    // const value = event.target;
    setPersonnelForm({
      ...personnelForm,
      appointment_duration: {
        period: "minutes",
        [input]: e.value,
      },
    });
    // setPersonnelForm(event.currentTarget.value);
  };

  const handlePersonnelFormSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(personnelForm));

    //if (userPersonnel)
    !Object.keys(validate(personnelForm)).length &&
      setUserPersonnel([...userPersonnel, personnelForm]);
  };

  const handlePersonnelFormSubmit2 = (e) => {
    e.preventDefault();
    setFormErrors(validate(personnelForm));
    if (!personnelForm.username && personnelForm.title) {
      showToast("error", "please enter personnel username");
    }
    if (personnelForm.username && !personnelForm.title) {
      showToast("error", "please enter personnel title");
    }
    if (!personnelForm.username && !personnelForm.title) {
      showToast("error", "please enter personnel username and title");
    }
    if (personnelForm.add_holidays && !personnelForm.unavailable_dates.length) {
      showToast("error", "please add unavailable date or uncheck holiday");
    }

    if (!Object.keys(validate(personnelForm)).length) {
      if (serviceToggleSubmit) {
        handlePostPagePersonnels(personnelForm);
      } else {
        handlePostPersonnels(personnelForm, bookingPSform);
      }
    }
  };
  console.log(bookingPSform);

  useEffect(() => {
    if (updatePagePersonnelData?.response?.status === 200) {
      // userPersonnel.push(postPagePersonnelData?.data);
      setUserPersonnel(
        userPersonnel.map((user) =>
          user.id === personnelForm.id ? personnelForm : user
        )
      );
    }
  }, [updatePagePersonnelData?.response?.status]);

  useEffect(() => {
    if (postPagePersonnelData?.response?.response?.status === 400) {
      showToast(
        "error",
        "An error occured, please check personnel availability"
      );
    }
  }, [postPagePersonnelData?.response]);

  useEffect(() => {
    if (postPagePersonnelData?.response?.status === 200) {
      // userPersonnel.push(postPagePersonnelData?.data);
      if (!postPagePersonnelData?.data?.add_holidays) {
        delete postPagePersonnelData?.data?.unavailable_dates;
      }
      setUserPersonnel([
        ...userPersonnel,
        {
          ...postPagePersonnelData.data,
          ["username"]: `${postPagePersonnelData?.data?.user?.username}`,
        },
      ]);
      handleBookingModal("RESET_DATA");
      setPersonnelForm(initialFormState);
    }
  }, [postPagePersonnelData?.response?.status]);

  useEffect(() => {
    if (postPersonnelData?.response?.status === 200) {
      if (!postPersonnelData?.data?.result[0]?.add_holidays) {
        delete postPersonnelData?.data?.result[0]?.unavailable_dates;
      }
      setUserPersonnel([
        ...userPersonnel,
        {
          ...postPersonnelData?.data?.result[0],
          // ["username"]: `${postPersonnelData?.data?.user?.username}`,
        },
      ]);
      handleBookingModal("RESET_DATA");
      setPersonnelForm(initialFormState);
    }
  }, [postPersonnelData?.response?.status]);

  // useEffect(() => {
  //   if (updatePagePersonnelData?.response?.status === 200) {
  //     showToast("success", "update successful");
  //   }
  // }, [postPagePersonnelData?.response?.status]);

  console.log(postPagePersonnelData);

  // useEffect(() => {
  //   if (singleServiceData?.response?.status === 200) {
  //     setBookingForm({ ...singleServiceData?.data });
  //   }

  //   return () => {};
  // }, [singleServiceData?.response?.status]);
  const handleUpdatePersonnelFormSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(personnelForm));
    // if (availabilityForm.date.length !== new Set(availabilityForm.date).size){
    //   return true
    // }
    // return false

    if (toggleEditPersonnelPost) {
      if (!personnelForm.add_holidays) {
        delete personnelForm.unavailable_dates &&
          !Object.keys(validate(personnelForm)).length &&
          handleUpdatePagePersonnel(personnelForm.id, personnelForm);
      } else
        !Object.keys(validate(personnelForm)).length &&
          handleUpdatePagePersonnel(personnelForm.id, personnelForm);
      // setUserPersonnel(
      //   userPersonnel.map((user) =>
      //     user.id === personnelForm.id ? personnelForm : user
      //   )
      // );
      // handleBookingModal("RESET_DATA");
    } else {
      !Object.keys(validate(personnelForm)).length &&
        setUserPersonnel(
          userPersonnel.map((user) =>
            user.id === personnelForm.id ? personnelForm : user
          )
        );
      handleBookingModal("RESET_DATA");
    }
  };
  // console.log("cccccccc", handleUpdatePersonnelFormSubmit())

  useEffect(() => {
    if (toggleEditPersonnel) {
      // setPersonnelForm({ ...personnelForm });
    } else if (toggleEditPersonnelPost) {
    } else {
      setPersonnelForm(initialFormState);
    }
  }, []);

  useEffect(() => {
    if (toggleEditPersonnelPost) {
      if (bookingModal?.id) {
        if (bookingModal?.schedule_type === "Reocurring schedule") {
          setAvailabilityForm2(bookingModal.availability);
        }
        if (bookingModal?.schedule_type === "Select specific dates") {
          setAvailabilityForm(bookingModal.availability);
        }
        if (
          // personnelForm?.add_holidays === true &&
          bookingModal?.unavailable_dates?.length
        ) {
          setUnavailableDates(bookingModal?.unavailable_dates);
          const newArr = bookingModal?.unavailable_dates?.map(
            (element, idx) => {
              return new Date(element);
            }
          );
          setDate2(newArr);
        }
        if (personnelForm?.add_holidays === true) {
          setUnavailableDates(bookingModal?.unavailable_dates);
        }

        if (bookingModal?.appointment_duration) {
          setNumber(bookingModal?.appointment_duration?.every);
        }
      }
      if (bookingModal?.available_from) {
        setReoccuringStartDate(new Date(bookingModal?.available_from));
      }
      if (bookingModal?.available_to) {
        setReoccuringEndDate(new Date(bookingModal?.available_to));
      }
    }
  }, [bookingModal?.availability]);

  useEffect(() => {
    if (toggleEditPersonnelPost) {
      // if (bookingModal?.id) {
      if (bookingModal?.schedule_type === "Select specific dates") {
        setSelectedItem(data[1].label);
      }
      // }
    }
  }, [bookingModal?.schedule_type, toggleEditPersonnelPost]);

  useEffect(() => {
    if (!toggleEditPersonnel) {
      !personnelForm.add_holidays
        ? delete personnelForm.unavailable_dates
        : (personnelForm.unavailable_dates = unavailableDates);
    }
    // if (!personnelForm.add_holidays) {
    //   delete personnelForm.unavailable_dates;
    // }
    // else {
    //   personnelForm.unavailable_dates = unavailableDates;
    // }
  }, [personnelForm.add_holidays, unavailableDates]);

  useEffect(
    () => {
      if (!personnelForm.add_holidays) {
        delete personnelForm.unavailable_dates;
        // : (personnelForm.unavailable_dates = unavailableDates);
      }
      if (personnelForm?.schedule_type === "Select specific dates") {
        delete personnelForm.unavailable_dates;
      }
      // else {
      //   personnelForm.unavailable_dates = unavailableDates;
      // }
    },
    [
      // personnelForm.add_holidays,
      // personnelForm.unavailable_dates,
      // personnelForm?.schedule_type,
    ]
  );

  useEffect(() => {
    if (personnelForm?.schedule_type !== "Reocurring schedule") {
      delete personnelForm.unavailable_dates;
      setPersonnelForm((prevState) => ({
        ...prevState,
        add_holidays: false,
      }));
    }
  }, [personnelForm?.schedule_type, personnelForm.unavailable_dates]);

  useEffect(() => {
    if (!toggleEditPersonnel)
      setPersonnelForm((prevState) => ({
        ...prevState,
        // id: new Date().getTime().toString(),
        availability:
          personnelForm?.schedule_type === "Reocurring schedule"
            ? availabilityForm2
            : availabilityForm,
        // unavailable_dates: unavailableDates,
        available_from: `${moment(reoccuringStartDate).format("YYYY-MM-DD")}`,
        available_to: `${moment(reoccuringEndDate).format("YYYY-MM-DD")}`,
        appointment_duration: {
          period: "minutes",
          every: number,
        },
      }));
  }, [
    reoccuringStartDate,
    reoccuringEndDate,
    personnelForm?.schedule_type,
    availabilityForm2,
    availabilityForm,
  ]);

  useEffect(() => {
    setPersonnelForm((prevState) => ({
      ...prevState,
      appointment_duration: {
        period: "minutes",
        every: number,
      },
    }));
  }, [number]);

  useEffect(() => {
    if (toggleEditPersonnel) {
      setPersonnelForm((prevState) => ({
        ...prevState,
        availability:
          personnelForm?.schedule_type === "Reocurring schedule"
            ? availabilityForm2
            : availabilityForm,
        unavailable_dates: unavailableDates,
        available_from: `${moment(reoccuringStartDate).format("YYYY-MM-DD")}`,
        available_to: `${moment(reoccuringEndDate).format("YYYY-MM-DD")}`,
      }));
    }
  }, [
    reoccuringEndDate,
    unavailableDates,
    reoccuringStartDate,
    availabilityForm,
  ]);
  console.log(unavailableDates);

  useEffect(() => {
    if (toggleEditPersonnel) {
      if (personnelForm?.schedule_type === "Reocurring schedule") {
        setAvailabilityForm2(personnelForm.availability);
      }
      if (personnelForm?.schedule_type === "Select specific dates") {
        setAvailabilityForm(personnelForm.availability);
      }
      if (
        // personnelForm?.add_holidays === true &&
        personnelForm?.unavailable_dates?.length
      ) {
        setUnavailableDates(personnelForm?.unavailable_dates);
      }

      if (personnelForm?.appointment_duration) {
        setNumber(personnelForm?.appointment_duration?.every);
      }

      if (personnelForm?.available_from) {
        setReoccuringStartDate(new Date(personnelForm?.available_from));
      }
      if (personnelForm?.available_to) {
        setReoccuringEndDate(new Date(personnelForm?.available_to));
      }

      // console.log("dddddddddddddddddddcccc", reoccuringEndDate);
      //   setReoccuringEndDate(new Date(isEditPersonnel.available_to));
      setPersonnelForm((prevState) => ({
        ...prevState,
        unavailable_dates: unavailableDates,
        // available_from: `${moment(reoccuringStartDate).format("YYYY-MM-DD")}`,
        available_to: `${moment(reoccuringEndDate).format("YYYY-MM-DD")}`,
        appointment_duration: {
          period: "minutes",
          every: number,
        },
      }));
      console.log(
        "mssssssssssssssssssssssss",
        personnelForm?.appointment_duration?.every
      );
    }
  }, []);

  useEffect(() => {
    if (Object.keys(formErrors).length) {
      setTimeout(() => {
        setFormErrors({});
      }, 3000);
    }
  }, [formErrors]);

  return (
    <div
      onClick={() => {
        handleBookingModal("RESET_DATA");
        setPostPersonnel(false);
        setPersonnelForm(initialFormState);
        setToggleEditPersonnel(false);
        setToggleEditPersonnelPost(false);
      }}
      className="cbModalContainer"
    >
      <div className="cbModal-content">
        <div
          onClick={() => {
            handleBookingModal("RESET_DATA");
            setPostPersonnel(false);
            setPersonnelForm(initialFormState);
            setToggleEditPersonnel(false);
            setToggleEditPersonnelPost(false);
          }}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="cbModalContainered"
        >
          <div className="cbModal-header">
            <h4> Personnel Details </h4>
            <Toaster />
            <button
              type="submit"
              onClick={
                toggleEditPersonnelPost
                  ? handleUpdatePersonnelFormSubmit
                  : handlePersonnelFormSubmit2
              }
              className="btn-post"
              disabled={postPagePersonnelData.loading}
            >
              {/* {postPagePersonnelData.loading && "loading..."} */}
              {toggleEditPersonnelPost ? "update" : "save"}
            </button>
            {/* {postPersonnel ? (
              <button
                type="submit"
                onClick={handlePersonnelFormSubmit}
                className="btn-post"
              >
                Save
              </button>
            ) : (
            
            )} */}
          </div>
          <div className="cbModal-body">
            {/* {formErrors.add_holidays && (
              <small className="err">{formErrors.add_holidays}</small>
            )} */}
            <form
            // onSubmit={handlePersonnelFormSubmit}
            >
              <div className="item__content_form">
                <div className="detail-one">
                  <div className="input-group">
                    <label htmlFor="">Name: </label>
                    <div style={{ width: "100%" }}>
                      <input
                        className="form-input"
                        type="text"
                        name="username"
                        value={
                          personnelForm?.username ||
                          personnelForm?.user?.username
                        }
                        onChange={handleInputChange}
                      />
                      <br />
                      {/* {formErrors.username && (
                        <small className="error">{formErrors.username}</small>
                      )} */}
                    </div>
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Title: </label>
                    <div style={{ width: "100%" }}>
                      <input
                        className="form-input"
                        type="text"
                        name="title"
                        value={personnelForm.title}
                        onChange={handleInputChange}
                      />{" "}
                      <br />
                      {/* {formErrors.title && (
                        <small className="error">{formErrors.title}</small>
                      )} */}
                    </div>
                  </div>
                </div>
                <div className="detail-two">
                  <div className="input-group">
                    <label htmlFor="">Phone: </label>
                    <input
                      className="form-input"
                      type="text"
                      name=""
                      disabled
                    />
                  </div>
                  <div className="input-group">
                    <label htmlFor="">Email: </label>
                    <input
                      className="form-input"
                      type="email"
                      name=""
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div className="dropbox">
                <div className="dropdown-container">
                  <div className="dd-dropdown">
                    <div className="dropdown">
                      <div className="dropdown-header" onClick={toggleDropdown}>
                        {selectedItem}

                        <FaChevronDown className="dropdown-icon" />
                      </div>
                    </div>
                    {isOpen && (
                      <div className="dropdown-body ">
                        <div
                          className="dropdown-item"
                          onClick={(e) => {
                            setSelectedItem(data[0].label);
                            setPersonnelForm({
                              ...personnelForm,
                              schedule_type: "Reocurring schedule",
                              availability: availabilityForm2,
                            });
                            setOpen();
                          }}
                        >
                          Reoccuring Schedule
                        </div>
                        <div
                          className="dropdown-item"
                          onClick={(e) => {
                            setSelectedItem(data[1].label);
                            setPersonnelForm({
                              ...personnelForm,
                              availability: availabilityForm,
                              schedule_type: "Select specific dates",
                            });
                            setOpen();
                          }}
                        >
                          Select specific dates
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/*---------  REOCCURING SCHEDULE ------------------*/}
              {selectedItem === data[0].label && (
                // personnelForm.schedule_type === "Reocurring schedule" &&
                <div className="reoccuring-schedule-container">
                  <div className="rsc-box-one">
                    <div className="body__content">
                      {availabilityForm2
                        .sort((a, b) => a.day - b.day)
                        .map((item, idx) => (
                          // item.name &&
                          <AvailabilityHours
                            item={item}
                            key={idx}
                            idx={idx}
                            handleInputChange={handleInputChange}
                            personnelForm={personnelForm}
                            setPersonnelForm={setPersonnelForm}
                            daysArray={daysArray}
                            availabilityForm2={availabilityForm2}
                            setAvailabilityForm2={setAvailabilityForm2}
                            startTime={startTime}
                            setStartTime={setStartTime}
                            endTime={endTime}
                            setEndTime={setEndTime}
                            breakStartTime={breakStartTime}
                            setBreakStartTime={setBreakStartTime}
                            breakEndTime={breakEndTime}
                            setBreakEndTime={setBreakEndTime}
                            handlePersonnelHours={handlePersonnelHours}
                            id={id}
                            personnelHoursData={personnelHoursData}
                            business_opening_hours2={business_opening_hours2}
                            handleUpdatePersonnelAvailability={
                              handleUpdatePersonnelAvailability
                            }
                            toggleEditPersonnelPost={toggleEditPersonnelPost}
                            businessHours={businessHours}
                            acceptedTimeRange={acceptedTimeRange}
                          />
                        ))}
                    </div>
                  </div>

                  <div className="rsc-box-two">
                    <div className="rscb-two-contents">
                      <div className="unad-dates">
                        <h5> Availability Dates</h5>
                        <div className="dates-container">
                          <p>from</p>
                          <div className="datepicker-container">
                            <DatePicker
                              className="custom-date"
                              showTimeSelect={false}
                              minDate={new Date()}
                              selected={reoccuringStartDate}
                              // onChange={(date) =>
                              //   setPersonnelForm({
                              //     ...personnelForm,
                              //     available_from: date,
                              //   })
                              // }
                              onChange={(date) => {
                                // setPersonnelForm((prevState) => ({
                                //   ...prevState,
                                //   available_from: `${moment(
                                //     reoccuringStartDate
                                //   ).format("YYYY-MM-DD")}`,
                                // }));
                                setReoccuringStartDate(date);
                              }}
                              dateFormat="MMM d, yyyy"
                              placeholderText="select date"
                            />
                            <FaChevronDown />
                          </div>
                          <p>to</p>
                          <div className="datepicker-container">
                            <DatePicker
                              minDate={new Date()}
                              className="custom-date"
                              selected={reoccuringEndDate}
                              onChange={(date) => setReoccuringEndDate(date)}
                              dateFormat="MMM d, yyyy"
                              placeholderText="select date"
                            />
                            <FaChevronDown />
                          </div>
                        </div>
                      </div>

                      <div className="una-dates">
                        <h5> Unavailabile Dates</h5>

                        <div className="cb-1">
                          <label className="check-label" htmlFor="002">
                            <CustomCheckBox
                              style={{
                                minWidth: 10,
                                height: 10,
                                borderColor: "var(--dark-purple-bg)",
                                marginLeft: 5,
                                border: "solid",
                                borderWidth: 1,
                                backgroundColor: personnelForm.add_holidays
                                  ? "var(--dark-purple-bg)"
                                  : "transparent",
                              }}
                              onChange={(e) => {
                                personnelForm.add_holidays
                                  ? setPersonnelForm((prevState) => ({
                                      ...prevState,
                                      add_holidays: false,
                                    }))
                                  : handlePersonnelCheckbox(
                                      "add_holidays",
                                      true
                                    );
                              }}
                            />
                            Add Holidays
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="clndr-box">
                      <div className="item__scroll">
                        <div className="una-dates-list">
                          {/* <button className="add-service-btn">
                              <FaPlusCircle /> Add date
                            </button>
                            {unavailableDates.map((item, idx) => (
                              <div className="undl-box">
                                <IoMdCloseCircleOutline />
                                <>{item}</>
                                <span>Jul 26th 2021</span>
                              </div>
                            ))} */}

                          <Dates
                            personnelForm={personnelForm}
                            unavailableDates={unavailableDates}
                            setUnavailableDates={setUnavailableDates}
                            date2={date2}
                            setDate2={setDate2}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/*---------  SELECT SEPECIFIC DATES ------------------*/}
              {selectedItem === data[1].label && (
                // personnelForm.schedule_type === "Select specific dates" &&
                <>
                  <div className="specific-dates-container">
                    <h5> Availability</h5>
                    <div className="item__scroll">
                      {availabilityForm.map((item, idx) => (
                        <SpecificDates
                          item={item}
                          // key={idx}
                          idx={idx}
                          availabilityForm={availabilityForm}
                          setAvailabilityForm={setAvailabilityForm}
                          setMDate={setMDate}
                          mDate={mDate}
                          setMDate2={setMDate2}
                          mDate2={mDate2}
                          handlePersonnelHours={handlePersonnelHours}
                          id={id}
                          personnelHoursData={personnelHoursData}
                          business_opening_hours2={business_opening_hours2}
                          acceptedTimeRange={acceptedTimeRange}
                          toggleEditPersonnelPost={toggleEditPersonnelPost}
                          handleUpdatePersonnelAvailability={
                            handleUpdatePersonnelAvailability
                          }
                          personnelForm={personnelForm}
                          handleRemovePersonnelAvailability={
                            handleRemovePersonnelAvailability
                          }
                          handleAddPersonnelAvailability={
                            handleAddPersonnelAvailability
                          }
                          addPersonnelAvailabilityData={
                            addPersonnelAvailabilityData
                          }
                        />
                      ))}
                    </div>
                    <button
                      className="add-service-btn"
                      type="button"
                      onClick={() => {
                        // addSpecificDatesFields();
                        toggleEditPersonnelPost
                          ? addSpecificDatesFields()
                          : addSpecificDatesFields();
                      }}
                    >
                      <FaPlus /> Add
                    </button>
                  </div>
                </>
              )}

              <div className="other-content-footer">
                <h5>
                  Capacity Per slot <BiInfoCircle />
                </h5>
                <div className="item__content_capacity">
                  <div className="input-group">
                    <label htmlFor="">Set maximum per slot: </label>
                    <input
                      className="form-input-sm"
                      // className={`form-input-sm ${personnelForm.unlimited_slot === true ? "form-input-sm-disabled" : ""}`}
                      name="max_per_slot"
                      type="number"
                      min="1"
                      disabled={personnelForm.unlimited_slot === true}
                      value={Number(personnelForm.max_per_slot)}
                      onChange={handleSlotChange}
                    />
                  </div>
                  <div className="cb-1">
                    <label className="check-label" htmlFor="002">
                      <CustomCheckBox
                        style={{
                          minWidth: 14,
                          height: 14,
                          borderColor: "var(--dark-purple-bg)",
                          marginLeft: 10,
                          border: "solid",
                          borderWidth: 1,
                          backgroundColor: personnelForm.unlimited_slot
                            ? "var(--dark-purple-bg)"
                            : "transparent",
                        }}
                        onChange={(e) => {
                          personnelForm.unlimited_slot
                            ? setPersonnelForm((prevState) => ({
                                ...prevState,
                                unlimited_slot: false,
                              }))
                            : // : handlePersonnelCheckbox("unlimited_slot", true);
                              setPersonnelForm((prevState) => ({
                                ...prevState,
                                unlimited_slot: true,
                                max_per_slot: 0,
                              }));
                        }}
                      />
                      Unlimited
                    </label>
                  </div>
                </div>
                <h5> Set appointment duration </h5>
                <div className="item__content_capacity">
                  <div className="input-group">
                    <label htmlFor="">For every </label>
                    <div className="dropbox">
                      <Mins
                        number={number}
                        setNumber={setNumber}
                        onChange={(e) => {
                          setPersonnelForm({
                            ...personnelForm,
                            appointment_duration: {
                              ...personnelForm.appointment_duration,
                              every: e,
                            },
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CreateBookingModal;
