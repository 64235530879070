import React, {useEffect, createContext, useState, useContext  } from "react";
import ModalContext from '../../../../../hooks/modalContexts'
import './index.scss'
import profilePic from '../../../../../assets/img/connectProfilePic.png'
import { Heart } from '../../../../../assets/svg/PostCardIcon'
import MsgIcon from '../../../../../assets/svg/msgIcon'
import Phone from '../../../../../assets/svg/phone'
import { FaTimes, FaHeart} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import {BsThreeDotsVertical} from "react-icons/bs"
import { IoMdClose } from "react-icons/all"
import MessageFilter from "./MessageFilter/MessageFilter"
import ProfileMenu from "./MessageFilter/ProfileMenu"
import ExploreContext  from '../../../../../hooks/exploreContext'
import useConnect from "../../../../../hooks/connectHooks"

const RetractModal = () => {
    const {retractModal, handleRetractModal} = useContext(ModalContext)
    const {
      retractModalData,
      setRetractModalData,
      rectractedCard,
      setRectractedCard,
      setSuggested,
      currentProfile,
      setConnectManageLoad,
    } = useContext(ExploreContext)

    const {
        handleRespondConnectRequest,
        respondConnectRequestData,
        singleProfileData,
        retractConnectData,
        handleRetractConnect,
        handleGetConnectRequestSent,
        handleGetConnectProfileSuggested,
      } = useConnect();

      // remove retracted card from UI
      useEffect(() => {
        // console.log(retractConnectData.data);
        if(retractConnectData?.data){
          setRectractedCard(retractConnectData.data)
          handleGetConnectProfileSuggested(currentProfile);
          setConnectManageLoad(true)
          console.log("retracting........")
        }
      }, [retractConnectData?.data]);


if(!retractModalData) return <div>Loading</div>
    return(
      <div
        onClick={()=>{handleRetractModal('RESET_RETRACT_TO_DISPLAY')}}
      className="acceptModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="acceptModalContainer"
       >
          <div
            onClick={()=>{handleRetractModal('RESET_RETRACT_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
            <div className="profileArea">
                <div className="connectProfile">
                  <img
                    src={retractModalData?.page?.featured_image ? retractModalData?.page?.featured_image : profilePic
                    } alt=""
                    />
                    <div className="name">
                        <h4>
                            {retractModalData?.page?.name}
                        </h4>
                        <p>
                            @{retractModalData?.page?.username}
                        </p>
                    </div>
                    <div className="icons">
                        <FaHeart className={
                          retractModalData?.connect_profile?.page?.is_like === true
                          ? 'heart favey'
                          : 'heart'
                        }/>
                        {/* <ProfileMenu className="dropdown" /> */}
                    </div>
                </div>
                <div className="description">
                    <h4>
                        {retractModalData?.connect_profile?.title}
                    </h4>
                    <p className='address'>
                        {retractModalData?.page?.location?.address}
                    </p>
                    <p>
                      {retractModalData?.page?.description}
                    </p>
                </div>

                <div className="contact">
                    <p>
                        <MsgIcon />
                        <span>
                            {retractModalData?.page?.email}
                        </span>
                    </p>
                    <p>
                        <Phone />
                        <span>
                          {retractModalData?.page?.phone_number}
                        </span>
                    </p>
                </div>
                <div className="locations">
                    <h4>
                        Service Locations:
                    </h4>
                    <ul className="service">
                      {retractModalData?.connect_profile?.service_locations.map((location) => (
                      <li>
                        {location.address}
                      </li>
                    ))
                      }
                    </ul>
                    </div>
                </div>
                <div className="form">
                  <div className="submittedNote">
                      <p>
                          Note
                      </p>
                      <div className="describe-form">
                        <div className="text-content">
                          {retractModalData?.note}
                        </div>
                      </div>
                  </div>
                  <div className="permissions retract">
                        <button
                          className="retract-button connect-accept-color"
                          onClick={()=>{
                            handleRetractConnect(retractModalData?.id)
                          }}
                          >
                              Retract
                        </button>
                  </div>
              </div>
          </div>
      </div>
    )
}
export default RetractModal
