import { useState, useContext } from "react";
import "./queueAnalytics.scss";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import SalesGrowthChart from "../../../../components/Analytics/components/salesGrowthQueue";
import TotalEarningsChart from "../../../../components/Analytics/components/totalEarningsQueue";
import CustomDropdown from "../../../../components/Analytics/components/customDropdown";
import AreaChart from "../../../../components/Analytics/Charts/areaChartQueAdConverted";
import { BiInfoCircle, BiUpArrowAlt } from "react-icons/bi";
import BigEye from "../../../../assets/svg/bigEye";
import WalletMoney from "../../../../assets/svg/wallet-money";
import RateIcon from "../../../../assets/svg/rateIcon";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ChartStats from "../../../../components/Analytics/components/chartStats";
import GroupUserIcon from "../../../../assets/svg/groupUserIcon";
import OrderCompletion from "../../../../components/Analytics/components/orderCompletion";
import TaskSquare from "../../../../assets/svg/taskSquare";
import Bag from "../../../../assets/svg/bag";
import BagTick from "../../../../assets/svg/bagTick";
import BagCross from "../../../../assets/svg/bagCross";
import BagHappy from "../../../../assets/svg/bagHappy";
import BarChart from "../../../../components/Analytics/Charts/barChartQueue";
import BarChartDemographics from "../../../../components/Analytics/Charts/barChartQueDemogra";
import ModalContext from "../../../../hooks/modalContexts";
import moment from "moment"

const QueueAnalytics = ({
  queueOthers,
  queueCompletion,
  
  // optimised
  queueChart,
  queueInteraction,
  queueAdConverted,
}) => {
  const dropdownData = [
    { label: "Customers" },
    { label: "Queue completion" },
    { label: "Ads converted" },
    { label: "Demographic" },
  ];
  const [activeTab, setActiveTab] = useState("most");
  const [activeTab2, setActiveTab2] = useState("city");
  const [activeTab3, setActiveTab3] = useState("city1");
  const [value, setValue] = useState(dropdownData[0].label);

  const handleChange = () => {
    setValue(dropdownData.label);
  };
  const testArray = [1, 2, 3, 4, 5, 6, 7];
  const { setQueueAnalyticModal } = useContext(ModalContext);
  const { setReferralOrderModal } = useContext(ModalContext);
  const { setCustomerConversionModal } = useContext(ModalContext);

  return (
    <div className="queue-analytics-container">
      <div className="qac-card-body">
        <div className="qacb-contents-one">
          <SalesGrowthChart
            image={<WalletMoney />}
            title="Queue Growth"
            height={250}
            width={700}
            queueChart={queueChart}
          />
        </div>
        <div className="qacb-contents-two">
          <TotalEarningsChart title="Queue"  queueInteraction={queueInteraction}/>
        </div>
      </div>
      <div className="qac-card-body-two">
        <div className="qacbt-contents-one">
          <div className="qco-header">
            <p>show</p>
            <div className="dropbox">
              <CustomDropdown
                value={value}
                setValue={setValue}
                items={dropdownData}
                onChange={handleChange}
              />
            </div>
            <div className="tabs">
              {/* ----------  nav tab -------- */}
              {value === dropdownData[0].label && (
                <ul className="nav-tab">
                  <li
                    className={activeTab === "least" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("least");
                    }}
                  >
                    Least
                  </li>
                  <li
                    className={activeTab === "most" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("most");
                    }}
                  >
                    Most
                  </li>
                  <li
                    className={activeTab === "all" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("all");
                    }}
                  >
                    All
                  </li>
                </ul>
              )}
              {value === dropdownData[2].label && (
                <ul className="nav-tab">
                  <li
                    className={activeTab === "least" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("least");
                    }}
                  >
                    Least
                  </li>
                  <li
                    className={activeTab === "most" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("most");
                    }}
                  >
                    Most
                  </li>
                  <li
                    className={activeTab === "all" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("all");
                    }}
                  >
                    All
                  </li>
                </ul>
              )}
              {value === dropdownData[3].label && (
                <ul className="nav-tab">
                  <li
                    className={activeTab2 === "city" ? "active" : ""}
                    onClick={() => {
                      setActiveTab2("city");
                    }}
                  >
                    City
                  </li>
                  <li
                    className={activeTab2 === "age" ? "active" : ""}
                    onClick={() => {
                      setActiveTab2("age");
                    }}
                  >
                    Age
                  </li>
                </ul>
              )}
              <div className="tab-title">{value}</div>
            </div>
          </div>
          <div className="qco-body">
            {/* ************ CUSTOMERS ************* */}
            {value === dropdownData[0].label && (
              <div className="qco-body">
                <BarChart queueChart={queueChart} />
              </div>
            )}

            {/* ************ QUEUE COMPLETION ************ */}
            {value === dropdownData[1].label && (
              <div className="orderc-body">
                <OrderCompletion
                  image={<BagTick />}
                  title="Completed Queue"
                  orderValue={`${queueInteraction?.completed_queue?.completed} of ${queueInteraction?.completed_queue?.total}`}
                  orderPercent={queueInteraction?.completed_queue?.summary.percentage.toFixed(1) + "%"}
                  bgColor="rgba(61,213,152,0.4)"
                  bgColor2="#3dd598"
                  upAndDown={queueInteraction?.completed_queue?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                />
                <OrderCompletion
                  image={<BagHappy />}
                  title="Incomplete Queue"
                  orderValue={`${queueInteraction?.incomplete_queue?.incompleted} of ${queueInteraction?.incomplete_queue?.total}`}
                  orderPercent={queueInteraction?.incomplete_queue?.summary.percentage.toFixed(1) + "%"}
                  bgColor="rgba(255,156,0,0.4)"
                  bgColor2="#fbb03b"
                  upAndDown={queueInteraction?.incomplete_queue?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                />
                {/* <OrderCompletion
                  image={<BagCross />}
                  title="Incomplete Appointment"
                  orderValue="3 of 100"
                  orderPercent="2.3%"
                  bgColor="rgba(242,81,81,0.4)"
                  bgColor2="#c10000"
                /> */}
              </div>
            )}

            {/* ************ ADS CONVERTED ************ */}
            {value === dropdownData[2].label && (
              <div className="qco-body">
                <div className="item__scroll">
                  {queueAdConverted?.map((data, idx) => (
                    <div className="qcob-details">
                      <div className="details-box-one">
                        <img src={itemImg} alt="" />
                        <div className="details-box-two">
                          <h5>{data?.title}</h5>
                        </div>
                        <h6>
                          Date <p>{moment(data?.expiring_date).format("MMMM Do YYYY")}</p>
                        </h6>
                        <div className="expired-content">
                          Expired in 4hrs 12mins{" "}
                        </div>
                      </div>

                      <div className={data?.summary.summary.direction === "UP" ? "qcob-chart rate-text-green" : "qcob-chart rate-text-red"}>
                        <p>
                          {data?.summary.summary.percentage.toFixed(1)}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                        </p>
                        <AreaChart data={data} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* ************ DEMOGRAPHIC ************ */}
            {value === dropdownData[3].label && (
              <div className="qco-body">
                <div className="dmc-card">
                  {activeTab2 === "city" && (
                    <div className="tabs2">
                      {/* ----------  nav tab -------- */}
                      <ul className="nav-tab">
                        <li
                          className={activeTab3 === "city1" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("city1");
                          }}
                        >
                          Canada
                        </li>
                        <li
                          className={activeTab3 === "city2" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("city2");
                          }}
                        >
                          Canada
                        </li>
                        <li
                          className={activeTab3 === "city3" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("city3");
                          }}
                        >
                          Canada
                        </li>
                      </ul>
                    </div>
                  )}
                  <BarChartDemographics queueChart={queueChart} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="dmc-stats">
          <ChartStats
            image={<WalletMoney />}
            title="Total Queue made on page"
            statPercent={queueInteraction?.total_queue?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={queueInteraction?.total_queue?.total}
            onClick={(e) => setQueueAnalyticModal(true)}
          />
          <ChartStats
            image={<GroupUserIcon fill="#fbb03b" />}
            title="Number of referrals"
            statPercent={queueInteraction?.number_of_referrals?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={queueInteraction?.number_of_referrals?.total}
            onClick={(e) => setReferralOrderModal(true)}
          />
          <ChartStats
            image={<RateIcon />}
            title="Customer conversion rate"
            statPercent={queueInteraction?.customer_conversion_rate?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={queueInteraction?.customer_conversion_rate?.total}
            onClick={(e) => setCustomerConversionModal(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default QueueAnalytics;
