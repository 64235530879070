/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearSendComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-300 -316)">
      <path
        className="a"
        d="M4.283,3.2l8.49-2.83c3.81-1.27,5.88.81,4.62,4.62l-2.83,8.49c-1.9,5.71-5.02,5.71-6.92,0l-.84-2.52-2.52-.84C-1.427,8.223-1.427,5.113,4.283,3.2Z"
        transform="translate(303.117 319.117)"
      />
      <path
        className="a"
        d="M0,3.59,3.58,0"
        transform="translate(310.11 326.06)"
      />
      <path className="b" d="M0,0H24V24H0Z" transform="translate(300 316)" />
    </g>
  </svg>
);

export default VuesaxLinearSendComponent;
