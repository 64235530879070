import React, { useEffect } from "react";
import { Link } from 'react-router-dom'
import { useDispatch } from "react-redux";
import { toggleRightBar } from "../../../appstore/navigationDisplayToggle/index.action";
import "./Job.scss";
import "../../../app.scss";
import FeedContainer from "../../../components/FeedContainer";
import JobCards from "../../../components/cards/jobCards/Cards"
import Application from "./applications/Application";
import Notifications from "../../../components/NotificationContainer/notification"
import greeting from "../../../utils/greeting";
import useExplore from "../../../hooks/explore";
import { useLocation, useParams } from "react-router";
import qs from "query-string";

const Jobs = () => {
  const dispatch = useDispatch();
    useEffect(()=>{
        dispatch(toggleRightBar(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

	const {handleGetJobNotifications, handleGetReceivedApplications, handleGetMyJobPosts} = useExplore();
  const { search } = useLocation();

  const { id } = qs.parse(search);
  const { pageId } = useParams();
  console.log(pageId);

  useEffect(() => {
    handleGetJobNotifications();
    handleGetReceivedApplications();
    handleGetMyJobPosts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <FeedContainer>
      {/* <BookingContainer> */}
      <section className="job">
        <div className="greeting">
          {greeting()} <span className="user">Jonathan</span>
        </div>
        <div className="jobs_container">
          <div className="job_container_heading">
            <h3 className="title">Job</h3>
          </div>
          <div className="job_dashboard">
            <div className="notification">
              <Notifications />
            </div>
            <div className="jobs">
              <div className="heading">
                <Link to='/all-jobs'>
                  <h5 className="title">Manage Jobs</h5>
                </Link>

                <div className="heading-links">
                  <Link to='/job-history' className='history'>View History</Link>
                  <Link to={`/create-job/?id=${pageId || id}`}>
                    <button className="create_job">Create Job</button>
                  </Link>
                </div>
              </div>

              <JobCards />
              <Application />
            </div>
          </div>
        </div>
      </section>
      {/* </BookingContainer> */}
    </FeedContainer>
  );
};

export default Jobs;
