import CircleIcon from "../../../assets/svg/circleIcon";
import SortIcon from "../../../assets/svg/sortIcon";
import Search from "../../../components/Search/Search";
import BusinessLogo from "../../../assets/img/blogo.png";
import Location from "../../../assets/svg/location";
import MapLocation from "../../../assets/svg/mapLocation";
import ArrowUp from "../../../assets/svg/arrowUp";
import ShopImg from "../../../assets/img/shopImg.png";
import People from "../../../assets/svg/people";
import Forward from "../../../assets/svg/forward";
import Bookmark from "../../../assets/svg/bookmark";
import { Eye } from "../../../assets/svg/PostCardIcon";
import NewLocation from "../../../assets/svg/newLocation";
import StarIcon from "../../../assets/svg/star";
import ThreeDots from "../../../assets/svg/threeDots";
import Refer from "../../../assets/svg/refer.png";
import { useContext, useEffect, useState } from "react";
import ReactStars from "react-stars";
import moment from "moment";
import usePrivateSellerDashboard from "../../../hooks/privateSellerDashboard";

const OrderItem = ({
  handleOrderItemModal,
  orderItem,
  handleViewFull,
  active,
  handler,
}) => {
  const [pop, setPop] = useState(false);
  const { handleUpdateOrderStatus, updateOrderStatusData } =
    usePrivateSellerDashboard();
  useEffect(() => {
    updateOrderStatusData.data && handler();
  }, [updateOrderStatusData.data]);
  return (
    <div
      onClick={() => handleOrderItemModal("ADD_TO_DISPLAY_ORDER", orderItem)}
      className="queueItem"
    >
      <div className="topPart">
        <div className="profile">
          <img
            className="logo"
            src={
              orderItem?.product?.page?.featured_image
                ? orderItem?.product?.page?.featured_image
                : BusinessLogo
            }
            alt="logo"
          />
          <div>
            <p>{orderItem?.product?.page?.name}</p>
            <p className="bottom">{orderItem?.product?.page?.username}</p>
          </div>
        </div>
        <img
          src={
            orderItem?.product?.gallary?.[0]?.file
              ? orderItem?.product?.gallary?.[0]?.file
              : ShopImg
          }
          alt=""
        />
      </div>
      <div className="bottomPart">
        <div onClick={(e) => e.stopPropagation()} className="other">
          <div className="queueItemReview">
            <div className="top">
              <div className="stars">
                <ReactStars
                  value={orderItem?.product?.page?.rating}
                  count={5}
                  edit={false}
                />
              </div>
              <span>{orderItem?.product?.page?.rating}</span>
            </div>
            <p>{orderItem?.product?.page?.review_count} reviews</p>
          </div>
          <div className="location">
            <div className="top">
              <NewLocation />
              <p>{orderItem?.product?.page?.location?.address}</p>
            </div>
            <p>
              Posted on{" "}
              {moment(orderItem?.product?.created_at).format("DD MMMM YYYY")}
            </p>
          </div>
          <div className="tag">
            <p>{orderItem?.product?.page?.type} PAGE</p>
          </div>
          <div onClick={() => setPop(!pop)} className="threeDotDivs">
            <ThreeDots fill={"#490057"} height={20} width={8} />
            {pop && (
              <div className="popUp">
                <a href="">
                  <Forward />
                  <span>Share</span>
                </a>
                <a href="">
                  <img src={Refer} alt="" />
                  <span>Refer</span>
                </a>
              </div>
            )}
          </div>
        </div>
        <div className="queueActions">
          <div className="leftActivity">
            <div className="queueDetails">
              <div className="queueTitle">
                <p className="topTitle">{orderItem?.product?.title}</p>
                {active !== "referrals" && (
                  <p className="bottomTitle">
                    Ordered on{" "}
                    {moment(orderItem?.order_date).format("DD MMMM YYYY")}
                  </p>
                )}
              </div>

              <div className="time">
                {active !== "referrals" && (
                  <>
                    <div className="queueTime">
                      <p>Time</p>
                      <p>{moment(orderItem?.order_date).format("hh : ss a")}</p>
                    </div>
                    <div className="price">
                      <p>$ {orderItem?.price}</p>
                    </div>
                    <div
                      style={{
                        background:
                          (orderItem?.condition === "Out for Delivery" &&
                            "rgba(0, 193, 68, .2)") ||
                          (orderItem?.condition === "Processing" &&
                            "rgba(251, 176, 59, .2)") ||
                          (orderItem?.condition === "Canceled" &&
                            "rgba(193, 0, 0, .2)") ||
                          (orderItem?.condition === "Delivered" &&
                            "rgba(0, 0, 0, .2)") ||
                          (orderItem?.condition === "Pending Approval" &&
                            "rgba(6, 108, 186, .2)"),
                      }}
                      className="status"
                    >
                      {orderItem?.condition === "Out for Delivery" && (
                        <CircleIcon fill={"rgba(0, 193, 68, 1)"} />
                      )}
                      {orderItem?.condition === "Processing" && (
                        <CircleIcon fill={"rgba(251, 176, 59, 1)"} />
                      )}
                      {orderItem?.condition === "Canceled" && (
                        <CircleIcon fill={"rgba(193, 0, 0, 1)"} />
                      )}
                      {orderItem?.condition === "Delivered" && (
                        <CircleIcon fill={"rgba(0, 0, 0, 1)"} />
                      )}
                      {orderItem?.condition === "Pending Approval" && (
                        <CircleIcon fill={"rgba(6, 108, 186, 1)"} />
                      )}
                      <p
                        style={{
                          color:
                            (orderItem?.condition === "Out for Delivery" &&
                              "rgba(0, 193, 68, 1)") ||
                            (orderItem?.condition === "Processing" &&
                              "rgba(251, 176, 59, 1)") ||
                            (orderItem?.condition === "Canceled" &&
                              "rgba(193, 0, 0, 1)") ||
                            (orderItem?.condition === "Delivered" &&
                              "rgba(0, 0, 0, 1)") ||
                            (orderItem?.condition === "Pending Approval" &&
                              "rgba(6, 108, 186, 1)"),
                        }}
                        className={orderItem.incoming && "blue"}
                      >
                        {orderItem?.condition}
                      </p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="rightActivity">
            <div
              className={`topActions ${
                active === "referrals" && "whenReferrals"
              }`}
              onClick={(e) => e.stopPropagation()}
            >
              {/* {!queue.incoming &&<>
                            {active === 'order' || active ==='referrals' && <Eye fill={'#490057'}/>}
                                <People />
                                <Forward />
                                {active !=='referrals' && <Bookmark />}
                            </>} */}
              {active !== "referrals" && (
                <div
                  onClick={() =>
                    handleOrderItemModal("ADD_TO_DISPLAY_ORDER", orderItem)
                  }
                  className="create"
                >
                  {/* <Eye fill= {'#fbb03b'}/> */}
                  <span>View full</span>
                </div>
              )}

              {orderItem?.condition === "Out for delivery" ||
                (orderItem?.condition === "Delivered" && (
                  <div
                    style={{
                      background:
                        orderItem?.condition === "Out for delivery" ||
                        orderItem?.condition === "Delivered"
                          ? "#00C13A"
                          : "#D9D9D9",
                    }}
                    onClick={() => handleViewFull()}
                    className="create"
                  >
                    {/* <Eye fill= {'#fbb03b'}/> */}
                    <span>Checkout</span>
                  </div>
                ))}
              {/* {orderItem?.condition === "Out for Delivery" && (
                <div className="requeue">
                  <span>Queue again</span>
                </div>
              )}
              {orderItem?.condition === "Delivered" && (
                <div className="requeue">
                  <span>Queue again</span>
                </div>
              )} */}
              {orderItem?.condition === "Pending Approval" && (
                <div className="permission">
                  <a
                    onClick={() => {
                      handleUpdateOrderStatus(orderItem?.id, "ACCEPT");
                    }}
                    className="accept create"
                  >
                    Accept
                  </a>
                  <a
                    onClick={() => {
                      handleUpdateOrderStatus(orderItem?.id, "DECLINE");
                    }}
                    className="reject"
                  >
                    Decline
                  </a>
                </div>
              )}
            </div>
            <div className="bottomOptions"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderItem;
