import { MdRoomService, FaMapMarkerAlt } from "react-icons/all";
import { BriefCase, Verified } from "../../assets/svg/briefCase";
import testImage from "../../assets/img/refferal_img1.png";
import { Comment, Save, Share } from "../../assets/svg/PostCardIcon";
import { DefaultButton } from "../../components/Button";
import useDeviceType from "../../hooks/deviceType";
import VuesaxOutlineBoxComponent from "../../assets/svg/VuesaxOutlineBoxComponent";
const ReferralsData = ({ data }) => {
  const { isMobile } = useDeviceType();
  return (
    <div className="referrals">
      <div className="item-head">
        <div className="referred-by">Referred by</div>
        <div className="referrer">
          <div className="img">
            <img src="" alt="" />
          </div>
          <div className="name">
            <p className="full-name">{data.user.name}</p>
            <small className="username">{data.user.username}</small>
          </div>
        </div>
      </div>
      <div className="ref-card-main">
        <div className="ref-card-body">
          <div className="off-and-icon">
            <DefaultButton
              customStyle={{
                backgroundColor: "rgba(0, 0, 0, 0.18)",
                color: "var(--bg-white)",
                padding: "8px 12px",
                borderRadius: 20,
                fontSize: 15,
                cursor: "auto",
              }}>
              20% off
            </DefaultButton>{" "}
            <div
              className="icon"
              style={{
                ...(data.type.toLowerCase() === "product" && {
                  backgroundColor: "var(--dark-purple-bg)",
                }),
              }}>
              {data.type.toLowerCase() === "product" ? (
                <VuesaxOutlineBoxComponent />
              ) : (
                <MdRoomService />
              )}
            </div>
          </div>
          <div className="img">
            <img src={testImage} alt="" />
          </div>
          <div className="description">
            <div className="name">Coperate shirts for men</div>
            <div className="location">
              <FaMapMarkerAlt /> Jamaica
            </div>
          </div>
        </div>
        <div
          className="ref-card-footer"
          style={{
            ...(data.type.toLowerCase() === "product" && {
              backgroundColor: "var(--dark-purple-bg)",
            }),
          }}>
          <div className="price">$20</div>
          <DefaultButton
            customStyle={{
              backgroundColor:
                data.type.toLowerCase() === "product"
                  ? "var(--bg-orange)"
                  : "white",
              padding: "8px 12px",
              borderRadius: 20,
              color:
                data.type.toLowerCase() === "product"
                  ? "var(--bg-white)"
                  : "var(--dark-purple-bg)",
            }}>
            Book now
          </DefaultButton>
        </div>
      </div>
    </div>
  );
};

export default ReferralsData;
