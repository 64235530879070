import React, { useState, useEffect, useContext, useRef } from 'react'
import { Link,  useHistory } from 'react-router-dom'
import ModalContext from '../../../../hooks/modalContexts'
import './profile.scss'
import profilePic from '../../../../assets/img/connectProfilePic.png'
import { Heart } from '../../../../assets/svg/PostCardIcon'
import MsgIcon from '../../../../assets/svg/msgIcon'
import Phone from '../../../../assets/svg/phone'
import { FaTimes,} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all";
import ExploreContext  from '../../../../hooks/exploreContext'
import useConnect from "../../../../hooks/connectHooks";

const EditProfileModal = () => {
    const {editProfileModal, handleEditProfileModal, editProfileTitle } = useContext(ModalContext)

    const {
      handleEditPage,
      handleInputChange,
      titleForm,
      setTitleForm,
      editData,
      setEditData,
      setEditForm,
      handleEditInputChange,
      currentProfile,
      setCurrentProfile,
      editTitleSave, 
      setEditTitleSave
    } = useContext(ExploreContext)

    const {
      singleProfileData,
      handleGetSingleProfile,
      categoryData,
      handleCategoryData,
      handleUpdateProfile,
      updateProfileData,
      connectProfileData,   
    } = useConnect();

    const [formErrors, setFormErrors] = useState({});
    const [titleData, setTitleData] = editProfileTitle

    let history = useHistory();

    useEffect(() => {
      const currentProId = connectProfileData?.data?.results?.[0]?.id
      if(currentProId){
          setCurrentProfile(currentProId)
          handleGetSingleProfile(currentProId)
      }
    }, [])
   
    const submitFormData = (e) => {
      e.preventDefault();
      handleUpdateProfile(singleProfileData.data.id, editData)
        
    };

console.log(connectProfileData?.data?.results?.[0]?.id)
console.log(singleProfileData)

    // const submitFormData = (e) => {
    //   e.preventDefault();
    //   handleUpdateProfile(connectProfileData?.data?.results?.[0]?.id, editData)
    //   console.log(connectProfileData?.data?.results?.[0]?.id)
    // };

    return(
      <div
      onClick={()=>{handleEditProfileModal('RESET_EDIT_PROFILE_TO_DISPLAY')}}
      className="profileModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="profileModalContainer"
       >
          <div
          onClick={()=>{handleEditProfileModal('RESET_EDIT_PROFILE_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
          <p className='enter-profile'> Rename connect profile title </p>
          <div className="enter-profile-form">
            <form className="form" onSubmit={submitFormData}>
              <input
                name="title"
                type="text"
                value={editData.title}
                onChange={handleEditInputChange}
                placeholder="Meat dealer"
                />
                <button
                  onClick={()=>{
                    handleEditProfileModal('RESET_EDIT_PROFILE_TO_DISPLAY')
                    setEditTitleSave(editData?.title)
                  }
                }
                    className='button-edit'
                    type='submit'>Save
                </button>
            </form>
          </div>
          </div>
      </div>
    )
}
export default EditProfileModal
