import React, { useState, useContext, useEffect } from "react"
import "./Index.scss";
import {applicationData} from "./applicationData";
import ExploreContext from "../../../hooks/exploreContext";
import moment from "moment";
import { useLocation, useParams } from "react-router";
import qs from "query-string";

const Index = () => {
  const {receivedApplications} = useContext(ExploreContext);
  const [cardApplications, setCardApplications] = useState();

  const { search } = useLocation();

  const { id } = qs.parse(search);
  const { pageId } = useParams();

  useEffect(() => {
    let applications = receivedApplications?.data?.results;
    setCardApplications(applications?.slice(0, 8));
  }, [receivedApplications]);

    return (
      <div className="aplication_container">
        {
          cardApplications?.length>0 &&
          cardApplications.map((application, index) => (
            <div className="applicant" key={index}>
              <div className="user">
                <div className="avatar">
                    <img src={application?.owner?.profile_image?.file} alt="" />
                </div>
                <div className="user_desc">
                  <h4 className="name">{application?.owner?.full_name}</h4>
                  <p><span className="userName">@{application?.owner?.username}</span></p>
                </div>
              </div>

              <div className="date_recieved">
                <span>
                  <strong>Recieved on</strong>
                </span>
                <p className="date">{moment(application?.created_on).format("LL")}</p>
              </div>
            </div>
          ))
        }
      </div>
    );
}

export default Index
