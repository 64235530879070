import PostCard from "../PostCards";
import "./posts.scss";
// import postCardData from "../../utils/postCardData";
import { Fragment } from "react/cjs/react.production.min";
import postCardOptions from "../../utils/postCardOptions";
import { useState } from "react";
import { useEffect } from "react";
// import SinglePost from "../SinglePost";
import useDeviceType from "../../hooks/deviceType";
import useSkeletonLoader from "../../hooks/FeedSkeletonLoader/index";
import usePosts from "../../hooks/posts";
import { useDispatch, useSelector } from "react-redux";
import { resetPosts } from "../../appstore/posts/index.action";
import { useRef } from "react";
import usePages from "../../hooks/createPage";
import qs from "query-string";
import { useLocation, useParams } from "react-router";
// import
// import {VSend} from "react-icons/all"

const BusinessPost = () => {
  const dispatch = useDispatch();
  const { singlePageData, handleGetSinglePage } = usePages();
  const [isOptionVisible, setIsOptionVisible] = useState(false);
  const { user } = useSelector((state) => state.currentUser);
  const postCardData = useSelector((state) => state.post);
  const [animatedClass, setAnimatedClass] = useState("");
  const { isMobile } = useDeviceType();
  const { MultipleFeedPosts, FeedPost } = useSkeletonLoader();
  const { handleGetPosts } = usePosts();
  const postBottom = useRef(null);
  const { pageId } = useParams();
  useEffect(() => {
    if (isOptionVisible) {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setAnimatedClass("reveal__mobile__more__options");
      }, 200);
    } else {
      setAnimatedClass("");
      document.body.style.overflow = "inherit";
    }
  }, [isOptionVisible]);

  useEffect(() => {
    handleGetSinglePage(pageId);
    handleGetPosts(undefined, undefined, undefined, true, undefined, pageId);
    return () => {
      dispatch(resetPosts());
    };
  }, [pageId]);

  //observer
  useEffect(() => {
    let observer;
    if (postCardData.next && postBottom) {
      observer = new IntersectionObserver(
        (entries) => {
          const { isIntersecting } = entries[0];
          if (isIntersecting) {
            handleGetPosts(null, postCardData.next, 2);
          }
        },
        {
          root: null,
          rootMargin: "0px 0px 600px 0px",
          threshold: 0.5,
        }
      );
      observer.observe(postBottom.current);
    }
    return () => observer?.disconnect();
  }, [postCardData, postBottom]);
  return (
    <Fragment>
      <div className="posts business-posts">
        {!!postCardData.newPostLoading && <FeedPost />}
        {postCardData.postData.map((data, idx) => {
          data.isOwner = data.owner.username === user.username;
          return (
            <Fragment>
              {!!!data.hidden && (
                <PostCard
                  pageData={singlePageData.data}
                  key={idx}
                  postData={data}
                  optionVisibility={[isOptionVisible, setIsOptionVisible]}
                  {...data}
                />
              )}
            </Fragment>
          );
        })}
        {!!postCardData.loading && <MultipleFeedPosts />}
        {postCardData.next && (
          <div
            className="post-field-bottom"
            ref={postBottom}
            style={{
              padding: 20,
            }}></div>
        )}
      </div>
      {isOptionVisible && isMobile && (
        <div
          className={`mobile__more__options ${animatedClass}`}
          onClick={(e) => setIsOptionVisible(false)}>
          <ul onClick={(e) => e.stopPropagation()}>
            {postCardOptions.map((item, idx) => {
              return (
                <li>
                  <item.icon width={"20px"} /> <span>{item.name}</span>
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </Fragment>
  );
};

export default BusinessPost;
