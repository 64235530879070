const ThreeDots = ({ fill, height, width }) => {
  return (
    <svg
      className="threeDots"
      xmlns="http://www.w3.org/2000/svg"
      width={width || "4"}
      height={height || "16"}
      viewBox="0 0 4 16">
      <g id="Group_95" data-name="Group 95" transform="translate(-720 -723)">
        <circle
          id="Ellipse_88"
          data-name="Ellipse 88"
          cx="2"
          cy="2"
          r="2"
          transform="translate(720 723)"
          fill={fill || "#fff"}
        />
        <circle
          id="Ellipse_89"
          data-name="Ellipse 89"
          cx="2"
          cy="2"
          r="2"
          transform="translate(720 729)"
          fill={fill || "#fff"}
        />
        <circle
          id="Ellipse_90"
          data-name="Ellipse 90"
          cx="2"
          cy="2"
          r="2"
          transform="translate(720 735)"
          fill={fill || "#fff"}
        />
      </g>
    </svg>
  );
};
export default ThreeDots;
