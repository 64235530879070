import { useState, useEffect } from "react";
import "./paymentContents.scss";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "../../switch/switch";
import { BiInfoCircle } from "react-icons/bi";
import CustomDropdown from "../../customDropdown/customDropdown";
import { showToast } from "../../ReactToast";

const PaymentContents = ({ heading = true, bodyState = [] }) => {
  /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  const [body, setBody] = bodyState;
  const data = [{ label: "Fixed price $" }, { label: "Percentage %" }];
  return (
    <div className="payment-contents-container">
      <div className="pyc-cbc-card cbg-light ccp-0">
        {heading && (
          <div className="py-top">
            <h5 className="ps-title">Payment</h5>
          </div>
        )}
        <div className="cbc-cbcc-body">
          <div className="ccb__contents_payment">
            <div className="py-box">
              <div className="depo-box">
                {body.allow_deposit !== undefined && (
                  <Switch
                    defaultState={body.allow_deposit}
                    onChange={(e) => {
                      console.log(e);
                      setBody({
                        ...body,
                        allow_deposit: !body.allow_deposit,
                      });
                    }}
                  />
                )}
                <label htmlFor="switch" className="">
                  Deposit
                </label>

                <div className="dropbox">
                  <CustomDropdown
                    items={data}
                    onChange={(e) =>
                      setBody({
                        ...body,
                        deposit_type: e
                          .toUpperCase()
                          .replace("$", "")
                          .replace("%", "")
                          .replace("PRICE", "")
                          .trim(),
                      })
                    }
                  />
                </div>
                <input
                  className="form-input-sm "
                  type="text"
                  placeholder="$ 90"
                  value={body.deposit_amount}
                  onChange={(e) => {
                    const value = Number(e.target.value);
                    if (!isNaN(value)) {
                      console.log("val is not nan");
                      setBody({
                        ...body,
                        deposit_amount: value,
                      });
                    }
                  }}
                />
              </div>
              <div className="cancel-date">
                <BiInfoCircle />
                <h6> Free Cancelation Date: </h6>

                <div className="datepicker-container">
                  <FaCalendarAlt />

                  <DatePicker
                    className="custom-date"
                    selected={
                      new Date(
                        body.free_cancelation_date || new Date().toString()
                      )
                    }
                    onChange={(date) => {
                      if (new Date(date) >= Date.now()) {
                        setBody({ ...body, free_cancelation_date: date });
                      } else {
                        showToast(
                          "error",
                          "date can only be greater or equal today"
                        );
                      }
                    }}
                    dateFormat="MMM d, yyyy h:mm aa"
                    placeholderText="select date"
                    showTimeInput
                    timeInputLabel="Time"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentContents;
