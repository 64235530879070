import { CLEAR_USER_MODAL, ADD_DATA, SET_USER_MODAL } from "./index.types";
const initialData = {
  loading: false,
  showModal: false,
  data: [],
};

const userModal = (state = initialData, { type, payload }) => {
  switch (type) {
    case CLEAR_USER_MODAL:
      return initialData;
    case ADD_DATA:
      return {
        ...state,
        data: [...state.data, ...payload],
      };
    case SET_USER_MODAL:
      return payload;
    default:
      return initialData;
  }
};

export default userModal;
