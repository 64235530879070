const Refer = () =>{
    return(
        <svg xmlns="http://www.w3.org/2000/svg" width="23.673" height="23.917" viewBox="0 0 23.673 23.917">
  <g id="Group_2103" data-name="Group 2103" transform="translate(-1375.922 -1168.258)">
    <g id="Group" transform="translate(1391.558 1168.758)">
      <path id="Vector" d="M3.032,5.86a.687.687,0,0,0-.216,0,2.936,2.936,0,1,1,.216,0Z" transform="translate(0 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Vector-2" data-name="Vector" d="M.034,5.61a6.4,6.4,0,0,0,4.474-.818,2.171,2.171,0,0,0,0-3.884A6.439,6.439,0,0,0,0,.1" transform="translate(1.828 8.517)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
    <g id="Group-2" data-name="Group" transform="translate(1376.422 1168.758)">
      <path id="Vector-3" data-name="Vector" d="M2.828,5.86a.687.687,0,0,1,.216,0,2.936,2.936,0,1,0-.216,0Z" transform="translate(1.678 0)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Vector-4" data-name="Vector" d="M5.675,5.61A6.4,6.4,0,0,1,1.2,4.792,2.171,2.171,0,0,1,1.2.909,6.439,6.439,0,0,1,5.709.1" transform="translate(0 8.517)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
    <g id="Group-3" data-name="Group" transform="translate(1383.271 1177.241)">
      <path id="Vector-5" data-name="Vector" d="M3.032,5.86a.687.687,0,0,0-.216,0,2.936,2.936,0,1,1,.216,0Z" transform="translate(1.473)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
      <path id="Vector-6" data-name="Vector" d="M1.2.9a2.171,2.171,0,0,0,0,3.884,6.447,6.447,0,0,0,6.609,0A2.171,2.171,0,0,0,7.81.9,6.5,6.5,0,0,0,1.2.9Z" transform="translate(0 8.534)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
    </g>
  </g>
</svg>

    )
}
export default Refer