import React, {useState, useContext, useEffect} from "react";
import {FaTimes} from "react-icons/fa";
import EditIcon from '@mui/icons-material/Edit';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import "./jobApplyModal.scss";
import ModalContext from "../../../hooks/modalContexts";
import logo from "../../../assets/explore/companylogo.png";
import locationicondark from "../../../assets/explore/locationdark.svg";
import phoneicon from "../../../assets/explore/callcontact.svg";
import mailicon from "../../../assets/explore/smscontact.svg";
import ExploreContext from "../../../hooks/exploreContext";
import useExplore from "../../../hooks/explore";
import { showToast } from "../../ReactToast";
import { Toaster } from "react-hot-toast";

export const JobApplyModal = () => {
	const { setJobApplyModal } = useContext(ModalContext);
	const { setApplySuccessModal } = useContext(ModalContext);
	const {handleCreateJobApplication} = useExplore();
	const {
		selectedJobPost,
		jobApplyLoading,
		setJobApplicationFiles,
		jobApplicationFiles,
		jobApplicationContactMode,
		jobApplicationContactModeNumber,
		jobApplicationContactModeMail,
	} = useContext(ExploreContext);

	// useEffect(() => {
	// 	setJobApplicationFiles([]);
	// }, [])

	const [thisUser, setThisUser] = useState(null)
	
	const swapSuccessModal = () => {
		setJobApplyModal(false)
		setApplySuccessModal(true)
	}

	const submitApplication = () => {
		
		if (!jobApplicationContactMode) {
			showToast('error', 'Please Select a contact mode');
			return;
		}
		if (!selectedJobPost) {
			showToast('error', 'No Job Post selected');
			return;
		}
		if (!jobApplicationFiles.length) {
			showToast('error', 'No File Uploaded');
			return
		}

		switch(jobApplicationContactMode) {
			case 'call':
				if (!jobApplicationContactModeNumber) {
					showToast('error', 'Please provide a number for the "call" contact mode');
					return;
				}
				break;
			case 'email':
				if (!jobApplicationContactModeMail) {
					showToast('error', 'Please provide an email address for the "email" contact mode');
					return;
				}
				break;
			default:
		}

		let payload = {};

		payload.job_post = selectedJobPost.data.id
		payload.files = jobApplicationFiles;
		payload.contact_mode = [];
		payload.contact_mode.push(jobApplicationContactMode.toUpperCase());

		switch(jobApplicationContactMode) {
			case 'call':
				payload.contact_details = {'CALL': jobApplicationContactModeNumber}
				break;
			case 'cover':
				payload.contact_details = {'EMAIL': jobApplicationContactModeMail}
				break;
			default:
				break;
		}

		handleCreateJobApplication(payload);
	}

	useEffect(() => {
		console.log(selectedJobPost);

		let localUser = JSON.parse(localStorage.getItem('persist:root'))
		let loggedInUser = JSON.parse(localUser.currentUser).user;
		console.log(JSON.parse(localUser.currentUser).user)
		setThisUser(loggedInUser)
	}, [])

	return (
		<>
			<Toaster />
			<div className="applyModal">
				<div className='applyModalWrap'>
					<div className="modalCloseWrap">
						<div onClick={() => setJobApplyModal(false)} className="applyModalClose">
							<FaTimes />
						</div>
					</div>

					<div className="applyModal-body">

						<div className="apply-modal-head">
							<div className="head-position">
								<img
									src={selectedJobPost?.data?.page?.featured_image ?
										selectedJobPost?.data?.page?.featured_image
										:
										logo} className='company-logo'
									alt=""
								/>
								<div className="apply-position-title">
									<div className="position">
										{selectedJobPost?.data?.title}
									</div>
									<div className="company-location">
										@{selectedJobPost?.data?.page?.username} /&nbsp;
										<span className="location"><img src={locationicondark} alt=""/>
											{selectedJobPost?.data?.page?.location?.address} 
										</span>
									</div>
								</div>
							</div>

							<div className="applicant-contact">
								<div className="applicant">
									<div className="applicant-name">{thisUser && thisUser.full_name}</div>
									<div className="applicant-username">@{thisUser && thisUser.username}</div>
								</div>

								<div className="applicant-contacts">
									<div className="applicant-phone">
										<img src={phoneicon} alt=""/> {thisUser && thisUser.phone_number}
									</div>

									<div className="applicant-mail">
										<img src={mailicon} alt=""/> {thisUser && thisUser.email}
									</div>
								</div>
							</div>
						</div>


						<div className="applyModal-content">

							<div className="applicant-details">

								<div className="applicant-files">
									<div className="file-group">
										<div className="group-title">Uploaded files</div>

										{
											jobApplicationFiles && jobApplicationFiles.length ?
											jobApplicationFiles.map((file, index) => {
												return file.type === 'resume' &&
													(
														<div className="file">
															<div className="filename">
																{file && file.file.name}
															</div>

															<div className="file-actions">
																<div className="edit-icon"
																	onClick={() => setJobApplyModal(false)} ><EditIcon /></div>
																<div className="delete-icon"><DeleteSweepIcon /></div>
															</div>
														</div>
													)
											})
												: <div className='small'>No uploaded file...</div>
										}
									</div>


									<div className="file-group">
										<div className="group-title">Additional files</div>

										{
											jobApplicationFiles && jobApplicationFiles.length ?
											jobApplicationFiles.map((file, index) => {
												return file.type === 'additional' &&
													(
														<div className="file">
															<div className="filename">
																{file && file.file.name}
															</div>

															<div className="file-actions">
																<div className="edit-icon"
																	onClick={() => setJobApplyModal(false)} ><EditIcon /></div>
																<div className="delete-icon"><DeleteSweepIcon /></div>
															</div>
														</div>
													)
											})
												: <div className='small'>No uploaded file...</div>
										}
									</div>
								</div>
							</div>

							<div className="modal-actions">
								<button className={`modal-btn ${jobApplyLoading ? "disabled" : ""}`} disabled={jobApplyLoading} onClick={() => submitApplication()}>Apply</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}