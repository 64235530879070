import React, { useState, useContext, useEffect } from "react";
import "../index.scss";
import { FaPen, FaUser, FaPlus, FaSave } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/all";
import ModalContext from "../../../hooks/modalContexts";
import DeliveryIcon from "../../../assets/svg/deliveryIcon";

const BookingLocation = ({
  serviceLocations,
  setServiceLocations,
  setBookingLocationModal,
}) => {
  const removeServiceLocation = (idx) => {
    const newFormValues = [...serviceLocations];
    newFormValues.splice(idx, 1);
    setServiceLocations(newFormValues);
  };

  return (
    <>
      {/*---------  LOCATION CARD CONTENTS ------------------*/}
      <div className="cbc_sl_delivery">
        <div
          className="delivery_box delivery_active"
          onClick={(e) => setBookingLocationModal(true)}
        >
          <DeliveryIcon className="delivery-icon" />
          <h5>Add Location</h5>
        </div>
        {serviceLocations.map((list, idx) => (
          <div className="delivery_box">
            <DeliveryIcon className="delivery-icon" />
            <h5>{list.address}</h5>
            <div className="opt-icons">
              <FaPen />
              <IoMdCloseCircleOutline
                onClick={() => removeServiceLocation(idx)}
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default BookingLocation;
