import React, { useEffect, useState } from "react";
import { FilterOptions } from './CreateJobOptions'
import DropdownIndicator from './CreateFontAwesomeIndicator'
import Select from 'react-select';
import "./index.scss"
import {
  FaSortAmountDownAlt,
  FaChevronDown,
} from 'react-icons/fa'
import {toSentenceCase} from "../../../../../utils/helpers"

const CreateJobFilter = (props) => {
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (props.options) {
      setOptions([])
      for (let i=0; i<props.options.length; i++) {
        let newOption = {};
        newOption.label = toSentenceCase(props.options[i].name.replace(/_/g, " "));
        newOption.value = props.options[i].value;

        let oldOptions = options;
        oldOptions.push(newOption); 

        setOptions(oldOptions); 
      }

    }
  }, [props.options])

  return(
    <div className="job_filter">
      <div className="filter_sort_head">
        <div className="filter">
            <Select
                components={{
                  IndicatorSeparator: ()=> null,
                  DropdownIndicator
                }}
                options={options}
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder={props.placeholder}
                onChange={(option) => props.action(option)}
                />
          </div>
        </div>
    </div>
  )
}

export default CreateJobFilter
