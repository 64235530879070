import {
  UPDATE_SINGLE_COMMENT_LIKE,
  INCREMENT_REPLY_COUNT,
  CLEAR_SINGLE_COMMENT,
  CREATE_SINGLE_COMMENT,
} from "./singleCommentV2.types";

const defaultState = null;
const singleCommentV2Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case CREATE_SINGLE_COMMENT:
      return payload;
    case CLEAR_SINGLE_COMMENT:
      return null;
    case INCREMENT_REPLY_COUNT:
      return (
        state && {
          ...state,
          reply_count: state.reply_count + 1,
        }
      );
    case UPDATE_SINGLE_COMMENT_LIKE:
      return payload.id === state.id
        ? {
            ...state,
            likes: payload.count,
            has_like: payload.status,
          }
        : state;
    default:
      return state;
  }
};

export default singleCommentV2Reducer;
