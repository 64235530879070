import "../index.scss";
import ModalContext from "../../../hooks/modalContexts";
import { useContext, useEffect, useState } from "react";
import barbImg from "../../../assets/img/barb.png";
import CircleIcon from "../../../assets/svg/circleIcon";
import User from "../../../assets/svg/user";
import MsgIcon from "../../../assets/svg/msgIcon";
import Phone from "../../../assets/svg/phone";
import StarIcon from "../../../assets/svg/star";
import Review from "./review";
import CloseBtn from "../../../components/closeBtn";
import moment from "moment";

import { Tooltip } from "@mui/material";
import useActivity from "../../../hooks/activity";

const ItemModal = () => {
  const { handleQueueItemModal, queueItemModal } = useContext(ModalContext);
  const [adjustCarousel, setAdjustCarousel] = useState(0);
  const [index, setIndex] = useState(1);

  const [status, setStatus] = useState("closed");
  const { updateBookingStatusData, handleUpdateBooking } = useActivity();

  console.log(queueItemModal, "THE");
  return (
    <div
      onClick={() => handleQueueItemModal("RESET_QUEUE_ITEM")}
      className="item-modal"
    >
      <div className="item-modal-container">
        <CloseBtn
          onClick={() => handleQueueItemModal("RESET_QUEUE_ITEM")}
          className="closeIcon"
        ></CloseBtn>
        <div onClick={(e) => e.stopPropagation()} className="contain">
          <div className="title">
            <h5 className="">{queueItemModal?.service?.title}</h5>
            <div
              className={`${queueItemModal?.condition === "Closed" && "red"} `}
            >
              <span>{status}</span>
            </div>
          </div>

          <p className="description">{queueItemModal?.service?.description}</p>

          <div className="bookingFee">
            <div className="fee">
              <p>${queueItemModal?.service?.booking_fee?.amount}</p>
            </div>
            <div className="location">
              <div className="p">
                <p>Service Address</p>
                <p>{queueItemModal?.page?.location?.address}</p>
              </div>
              <div className="p">
                <h6>Date</h6>
                <p>
                  {moment(queueItemModal?.booking_date).format("DD MMMM YYYY")}
                </p>
              </div>
              <div className="p">
                <h6>Time</h6>
                <p>
                  {moment(queueItemModal?.booking_date).format("hh : ss a")}
                </p>
              </div>
            </div>
          </div>

          <div className="contact">
            <h6>Contact Info</h6>
            <div className="info">
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",

                    flexBasis: "100%",
                  }}
                >
                  <User />
                </div>
                <p>{queueItemModal?.page?.name}</p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexBasis: "100%",
                  }}
                >
                  <MsgIcon />
                </div>

                <p data-tip data-for="happyFace">
                  {queueItemModal?.page?.email}
                </p>
              </div>
              <div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexBasis: "100%",
                  }}
                >
                  <Phone />
                </div>

                <p>{queueItemModal?.page?.phone_number}</p>
              </div>
            </div>
          </div>
          {queueItemModal?.condition === "Closed" && (
            <Review tab={"bookings"} id={queueItemModal?.id} />
          )}
          {queueItemModal?.condition === "Active" && (
            <div className="itemModalActions">
              <p
                onClick={() =>
                  handleUpdateBooking({
                    status: "DECLINE",
                    id: queueItemModal?.id,
                  })
                }
              >
                Cancel appointment
              </p>
              {/* <div>
                        <span>
                            Checkout
                        </span>
                    </div> */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ItemModal;
