import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setAccountType } from "../../appstore/userData/index.action";
import "./switchAccounts.scss";
import { useHistory } from "react-router-dom";
const SwitchAccounts = () => {
  const { accountType, user } = useSelector((state) => state.currentUser);
  const history = useHistory();
  const dispatch = useDispatch();
  const setType = (payload) => {
    dispatch(setAccountType(payload));
    payload === "business"
      ? history.push("/explore")
      : history.push(`/feeds/timeline/${user.username}`);
  };

  // const { isMobile } = useDeviceType();
  console.log(accountType);
  return (
    <div className="switch__accounts">
      {/* {isMobile && (
        <div className="avatar">
          <img src={testAvatar} alt="" width={"100%"} />
        </div>
      )} */}
      <h2>Switch Account</h2>
      <div className="switch__wrap">
        <div className="types">
          <div
            className={`social ${accountType === "social" && "selected"}`}
            onClick={(e) => setType("social")}>
            <p>Social</p>
            <small className="count">50+</small>
          </div>
          <div
            className={`business ${accountType !== "social" && "selected"}`}
            onClick={(e) => setType("business")}>
            <p>Business</p>
            <small className="count">50+</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SwitchAccounts;
