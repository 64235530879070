import { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import "./pageSetup.scss";
import Person from "../../assets/svg/person";
import logowhite from "../../assets/icons/meeney__white-logo.png";
import ReactFlagsSelect from "react-flags-select";
import CustomDropdown from "../../components/customDropdown/customDropdown";
import { FaPlusCircle } from "react-icons/fa";
import OpeningHours from "./openingHours";
import useCreatePage from "../../hooks/createPage";
import LoaderWhite from "../../assets/loaders/LoaderWhite";
import { Toaster } from "react-hot-toast";
import { showToast } from "../../components/ReactToast";

const SecondBusinessPageSetup = ({
  setPage,
  mainFormData,
  setMainFormData,
}) => {
  const { user, handlePageCreation, createPageData, loading, categoryData } =
    useCreatePage();
  const imageRef = useRef(null);
  // console.log(mainFormData);
  const [formData, setFormData] = useState({
    opening_hours: mainFormData.opening_hours?.length
      ? mainFormData.opening_hours
      : [],
    category: mainFormData.category || "",
    business_address: mainFormData.business_address || "",
    country: mainFormData.country || "NG",
    type: "BUSINESS",
  });
  const [file, setFile] = useState(null);
  useEffect(() => {
    setMainFormData({ ...mainFormData, ...formData });
  }, [formData]);

  useEffect(() => {
    if (createPageData.response?.response?.data) {
      const err = createPageData.response.response.data;
      const keys = Object.keys(createPageData.response.response.data);
      for (let key of keys) {
        showToast("error", err[key]);
      }
    }
  }, [createPageData.response]);
  //handle submission
  const handleSubmit = useCallback(
    (event) => {
      try {
        event.preventDefault();
        if (mainFormData.category?.trim() === "") {
          throw "category cannot be left empty";
        }

        if (mainFormData.type?.trim() === "") {
          return;
        }

        if (mainFormData.business_address?.trim() === "") {
          throw "Business address cannot be left empty";
        }

        if (mainFormData.country?.trim() === "") {
          throw "Please select a country";
        }
        console.log(mainFormData.opening_hours);
        if (!mainFormData.opening_hours?.length) {
          throw "choose at least one opening day";
        }
        mainFormData.opening_hours.forEach((day) => {
          const [startHour, startMin] = String(day.start_time).split(":");

          //get mins
          const startTime = 60 * Number(startHour) + Number(startMin);
          const [endHour, endMin] = String(day.closing_time).split(":");

          //get mins
          const endTime = 60 * Number(endHour) + Number(endMin);

          console.log(startTime, endTime, startHour, endHour);
          if (startTime > endTime) {
            // console.log(day.end, day.start);
            throw `(${day.name}) closure time cannot be less than opening time`;
          }
        });
        const data = mainFormData;
        const { opening_hours } = mainFormData;
        // data.opening_hours = null;
        // console.log(opening_hours);
        handlePageCreation({
          data,
          file,
          availability: opening_hours,
        });
      } catch (err) {
        showToast("error", err);
      }
    },
    [formData, mainFormData]
  );
  const handleOpeningHoursChange = useCallback((data) => {
    setFormData((state) => {
      return {
        ...state,
        opening_hours: data.daysArray
          .map((days) => {
            return data.selected.includes(days.value)
              ? {
                  name: days.day,
                  start_time: days.from,
                  closing_time: days.to,
                  weekdays: days.value,
                }
              : false;
          })
          .filter((data) => data && data),
      };
    });
  }, []);
  // console.log(formData.opening_hours);
  return (
    <div className="pageSetup-container">
      <Toaster />
      <div className="wp-top-banner">
        <img className="img-logo" alt="meeney logo" src={logowhite} />
      </div>
      <div className="ps-left-container">
        <div className="top-ps-header">
          <h2> You are almost done setting up.</h2>
          <p>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard
          </p>
        </div>

        <div className="psl-photo-container">
          <div className="circle_ph">
            <Person />
          </div>
          <div className="link-box">
            <p>
              Upload a Photo to <br /> complete the page set up
            </p>
          </div>
        </div>

        <div className="ps-lc-form pmt-0">
          <form onSubmit={handleSubmit}>
            <div className="input-group">
              <label htmlFor="">Select business category </label>
              <div className="dropbox">
                {categoryData.data?.results && (
                  <CustomDropdown
                    items={categoryData.data?.results.map((item) => {
                      // console.log(item.name);
                      return { ...item, label: item.name };
                    })}
                    onChange={(e) =>
                      setFormData({ ...formData, category: e.id })
                    }
                  />
                )}
              </div>
            </div>

            <div className="input-group">
              <ReactFlagsSelect
                selected={formData.country}
                onSelect={(code) => setFormData({ ...formData, country: code })}
                placeholder="Tronto"
                className="form-input fi-location"
              />
            </div>

            <div className="input-group">
              <label htmlFor=""> Business address </label>
              <textarea
                className="form-input"
                name=""
                id=""
                cols="100"
                rows="4"
                placeholder="Business Address"
                // value={}
                onChange={(e) =>
                  setFormData({ ...formData, business_address: e.target.value })
                }>
                {formData.business_address}
              </textarea>
            </div>

            <div className="input-group">
              <label htmlFor=""> Opening Hours </label>
              <OpeningHours onChange={handleOpeningHoursChange} />
            </div>

            {/* <button
              className="add-btn"
              type="button"
              onClick={() => addFormFields()}>
              <FaPlusCircle /> Add
            </button> */}

            <div className="link-opt pmt-0">
              <button className="btn-back-link" onClick={(e) => setPage(1)}>
                Back
              </button>
              <button
                className="btn-start-link"
                // onClick={(e) => {}}
                type="submit">
                {!loading ? (
                  "Get me started"
                ) : (
                  <LoaderWhite
                    style={{
                      width: 20,
                      height: 20,
                    }}
                  />
                )}
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className="ps-right-container v-height">
        {/* <div className="circle__A"></div>
        <div className="circle__B"></div> */}
        <div className="circle__C"></div>
        <img className="img-logo" alt="meeney logo" src={logowhite} />
        <div className="ps-rc-title">
          <h3> {user.full_name} </h3>
        </div>
        <div className="photo-container">
          <div className="circle_p" style={{ overflow: "hidden" }}>
            {!file ? (
              <Person />
            ) : (
              <img
                src={URL.createObjectURL(file)}
                alt={file.name}
                style={{
                  width: "auto",
                  height: "100%",
                }}
              />
            )}
          </div>
          <div className="link-box">
            <button
              className="btn-link"
              onClick={(e) => imageRef.current.click()}>
              Upload an image
            </button>
            <input
              ref={imageRef}
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={(e) => {
                if (e.target.files) {
                  setFile(e.target.files[0]);
                } else setFile(null);
              }}
            />
            <p>Upload a Photo to complete the page set up</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondBusinessPageSetup;
