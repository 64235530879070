import AddDeliveryIcon from "./addDeliveryIcon"

const AddDeliveryCard = () => {
    return(
        <div className="addDeliveryCard">
            <div className="circle">
                <AddDeliveryIcon />
            </div>
        </div>
    )
}
export default AddDeliveryCard