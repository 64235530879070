import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import FeedContainer from "../../components/FeedContainer";
import greeting from "../../utils/greeting";
import "./payment.scss";
import { toggleRightBar, toggleLeftBar } from "../../appstore/navigationDisplayToggle/index.action";
import { Search } from "../../assets/svg/NavBarIcons";
import PaymentCard from "./PaymentCard/PaymentCards";
import PaymentHistory from "./PaymentHistory/PaymentHistory";
import VisaCardSummary from "./PaymentCard/VisaCardSummary"
import { useHistory } from "react-router-dom";
import { FaDownload, FaInfoCircle } from "react-icons/fa";

const Payment = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [location, setLocation] = useState("bookings");
  //toggle right bar off
  useEffect(() => {
    dispatch(toggleRightBar(false));
    return () => dispatch(toggleRightBar(true));
  }, []);

  useEffect(() => {
    dispatch(toggleLeftBar(false));
    return () => dispatch(toggleLeftBar(true));
  }, []);

  return (
    <FeedContainer>
      <div className="payment">
        <div className="wrap">
          <div className="header__wrap">
            <p>Payment</p>
          </div>
          <div className="body__section">
            <div className="tab-download">
              <div className="tab">
                <div
                  className={`booking ${location === "bookings" && "active"}`}
                  onClick={(e) => setLocation("bookings")}>
                  My Cards
                </div>
                <div
                  className={`listing ${location === "listing" && "active"}`}
                  onClick={(e) => setLocation("listing")}>
                  History
                </div>
              </div>
            </div>
            {location === "bookings" ? <PaymentCard />:  <PaymentHistory/>}
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};
export default Payment;
