/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearArchiveMinusComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-364 -188)">
      <path className="a" d="M5,0H0" transform="translate(373.5 198.65)" />
      <path
        className="a"
        d="M13.5,0H3.86A3.869,3.869,0,0,0,0,3.86V17.95c0,1.8,1.29,2.56,2.87,1.69l4.88-2.71a2.118,2.118,0,0,1,1.87,0l4.88,2.71c1.58.88,2.87.12,2.87-1.69V3.86A3.884,3.884,0,0,0,13.5,0Z"
        transform="translate(367.32 190)"
      />
      <path className="b" d="M0,0H24V24H0Z" transform="translate(364 188)" />
    </g>
  </svg>
);
export const DefaultSave = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21.371"
      height="21.371"
      viewBox="0 0 21.371 21.371">
      <g
        id="vuesax_linear_archive-minus"
        data-name="vuesax/linear/archive-minus"
        transform="translate(-364 -188)">
        <g id="archive-minus" transform="translate(364 188)">
          <path
            id="Vector"
            d="M4.452,0H0"
            transform="translate(8.459 9.483)"
            fill="none"
            stroke="#490057"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
          />
          <path
            id="Vector-2"
            data-name="Vector"
            d="M12.021,0H3.437A3.445,3.445,0,0,0,0,3.437V15.984c0,1.6,1.149,2.28,2.556,1.5L6.9,15.076a1.886,1.886,0,0,1,1.665,0l4.345,2.413c1.407.784,2.556.107,2.556-1.5V3.437A3.458,3.458,0,0,0,12.021,0Z"
            transform="translate(2.956 1.781)"
            fill="none"
            stroke="#490057"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.3"
          />
          <path
            id="Vector-3"
            data-name="Vector"
            d="M0,0H21.371V21.371H0Z"
            fill="none"
            opacity="0"
          />
        </g>
      </g>
    </svg>
  );
};
export default VuesaxLinearArchiveMinusComponent;
