const CalendarIcon = () => {
    return(
        <svg id="calendar" xmlns="http://www.w3.org/2000/svg" width="19.549" height="19.549" viewBox="0 0 19.549 19.549">
  <path id="Vector" d="M.611,3.665A.615.615,0,0,1,0,3.055V.611A.615.615,0,0,1,.611,0a.615.615,0,0,1,.611.611V3.055A.615.615,0,0,1,.611,3.665Z" transform="translate(5.905 1.018)" fill="#fbb03b"/>
  <path id="Vector-2" data-name="Vector" d="M.611,3.665A.615.615,0,0,1,0,3.055V.611A.615.615,0,0,1,.611,0a.615.615,0,0,1,.611.611V3.055A.615.615,0,0,1,.611,3.665Z" transform="translate(12.422 1.018)" fill="#fbb03b"/>
  <path id="Vector-3" data-name="Vector" d="M.815,1.625a.811.811,0,0,1-.31-.065.841.841,0,0,1-.269-.171A.841.841,0,0,1,0,.81.811.811,0,0,1,.065.5.941.941,0,0,1,.236.232.841.841,0,0,1,.505.061a.832.832,0,0,1,.888.171A.856.856,0,0,1,1.629.81a1.224,1.224,0,0,1-.016.163.518.518,0,0,1-.049.147.617.617,0,0,1-.073.147,1.283,1.283,0,0,1-.1.122A.857.857,0,0,1,.815,1.625Z" transform="translate(6.109 10.186)" fill="#fbb03b"/>
  <path id="Vector-4" data-name="Vector" d="M.815,1.628a.811.811,0,0,1-.31-.065.841.841,0,0,1-.269-.171A.841.841,0,0,1,0,.814.811.811,0,0,1,.065.5.941.941,0,0,1,.236.235.841.841,0,0,1,.505.064a.816.816,0,0,1,.888.171.856.856,0,0,1,.236.578,1.224,1.224,0,0,1-.016.163.518.518,0,0,1-.049.147.617.617,0,0,1-.073.147,1.283,1.283,0,0,1-.1.122A.857.857,0,0,1,.815,1.628Z" transform="translate(8.96 10.183)" fill="#fbb03b"/>
  <path id="Vector-5" data-name="Vector" d="M.815,1.628a.811.811,0,0,1-.31-.065.841.841,0,0,1-.269-.171l-.1-.122a.617.617,0,0,1-.073-.147A.518.518,0,0,1,.016.977,1.224,1.224,0,0,1,0,.814.856.856,0,0,1,.236.235.841.841,0,0,1,.505.064a.814.814,0,0,1,.888.171.856.856,0,0,1,.236.578,1.224,1.224,0,0,1-.016.163.518.518,0,0,1-.049.147.617.617,0,0,1-.073.147,1.283,1.283,0,0,1-.1.122A.857.857,0,0,1,.815,1.628Z" transform="translate(11.811 10.183)" fill="#fbb03b"/>
  <path id="Vector-6" data-name="Vector" d="M.815,1.619a.811.811,0,0,1-.31-.065.941.941,0,0,1-.269-.171A.856.856,0,0,1,0,.8.811.811,0,0,1,.065.495.76.76,0,0,1,.236.226a.853.853,0,0,1,1.157,0A.856.856,0,0,1,1.629.8a.856.856,0,0,1-.236.578A.857.857,0,0,1,.815,1.619Z" transform="translate(6.109 13.043)" fill="#fbb03b"/>
  <path id="Vector-7" data-name="Vector" d="M.815,1.619a.857.857,0,0,1-.578-.236A.856.856,0,0,1,0,.8.811.811,0,0,1,.065.495.76.76,0,0,1,.236.226a.853.853,0,0,1,1.157,0,.76.76,0,0,1,.171.269A.811.811,0,0,1,1.629.8a.856.856,0,0,1-.236.578A.857.857,0,0,1,.815,1.619Z" transform="translate(8.96 13.043)" fill="#fbb03b"/>
  <path id="Vector-8" data-name="Vector" d="M.815,1.63a.857.857,0,0,1-.578-.236.76.76,0,0,1-.171-.269A.811.811,0,0,1,0,.815.811.811,0,0,1,.065.506.76.76,0,0,1,.236.237.813.813,0,0,1,.969.017a.491.491,0,0,1,.155.049.617.617,0,0,1,.147.073,1.283,1.283,0,0,1,.122.1.856.856,0,0,1,.236.578.856.856,0,0,1-.236.578A.857.857,0,0,1,.815,1.63Z" transform="translate(11.811 13.032)" fill="#fbb03b"/>
  <path id="Vector-9" data-name="Vector" d="M14.458,1.222H.611A.615.615,0,0,1,0,.611.615.615,0,0,1,.611,0H14.458a.615.615,0,0,1,.611.611A.615.615,0,0,1,14.458,1.222Z" transform="translate(2.24 6.793)" fill="#fbb03b"/>
  <path id="Vector-10" data-name="Vector" d="M11.2,16.291H4.684C1.711,16.291,0,14.58,0,11.607V4.684C0,1.711,1.711,0,4.684,0H11.2c2.973,0,4.684,1.711,4.684,4.684v6.924C15.883,14.58,14.173,16.291,11.2,16.291ZM4.684,1.222c-2.33,0-3.462,1.132-3.462,3.462v6.924c0,2.33,1.132,3.462,3.462,3.462H11.2c2.33,0,3.462-1.132,3.462-3.462V4.684c0-2.33-1.132-3.462-3.462-3.462Z" transform="translate(1.833 2.24)" fill="#fbb03b"/>
  <path id="Vector-11" data-name="Vector" d="M0,0H19.549V19.549H0Z" fill="none" opacity="0"/>
</svg>

    )
}
export default CalendarIcon