import { useContext, useEffect, useState } from "react";
import ThreeDots from "../../../../../assets/svg/threeDots";
import { BusinessPagePageUtils } from "../../../utils/businessPagePageUtils";
import PrivateSellerDeliverySettings from "../priavateSellerComponents/privateSellerDeliverySetting";
import AdminAddIcon from "./adminAddIcon";
import AdminUserItem from "./adminUserItem";
import BookingAdminSettingItem from "./bookingAdminSettingItem";
import GeneralPageSettings from "./generalPageSettings";
import JobsAdminSettingsItem from "./jobAdminSettingsItem";
import ListingsAdminSettingsItem from "./listingsAdminSettingsItem";
import QueueAdminSettingsItem from "./queueAdminSettingsItem";
import TimelineAdminSettingItem from "./timelineAdminSettingItem";
import GeneralPageSettingsItem from "./generalPageSettingsItem";
import useSettings from "../../../../../hooks/settings";
import RebuildObj from "../priavateSellerComponents/rebuildObj";
import rebuild from "../priavateSellerComponents/rebuild";
import { useDispatch, useSelector } from "react-redux";
import RebuildArr from "../priavateSellerComponents/rebuildArr";
import { setCurrentUser } from "../../../../../appstore/userData/index.action";
import ModObj from "../priavateSellerComponents/modObj";
import Loader from "../../../../../components/loader";
import ModalContext from "../../../../../hooks/modalContexts";

const BusinessPagePage = ({
  businessCount,
  pageAdmins,
  currentUserDataForGet,
  handleGetPageAdmins,
}) => {
  const dispatch = useDispatch();

  const [active, setActive] = useState(1);

  const [dataEntered, setDataEntered] = useState(BusinessPagePageUtils);

  const [activeTab, setActiveTab] = useState(0);

  const handleSetActiveTab = (idx) => {
    setActiveTab(idx);
  };
  const [drop, setDrop] = useState(false);

  const [adminsData, setAdminData] = useState(pageAdmins?.data?.results);

  const [specificData, setSpecificData] = useState(adminsData[activeTab]);

  const [mappedData, setMappedData] = useState({});

  const [mappedDataToSend, setMappedDataToSend] = useState();

  const [currentData, setCurrentData] = useState();

  useEffect(() => {
    setCurrentData(pageAdmins?.data?.results[activeTab]);
  }, [activeTab]);

  useEffect(() => {
    setMappedData({
      ...mappedData,
      Timeline: rebuild(
        pageAdmins?.data?.results[activeTab]?.page_admin_settings?.general
          .Timeline
      ),
      Listings: rebuild(
        pageAdmins?.data?.results[activeTab]?.page_admin_settings?.general
          .Listings
      ),
      Booking: rebuild(
        pageAdmins?.data?.results[activeTab]?.page_admin_settings?.general
          .Booking
      ),
      Queue: rebuild(
        pageAdmins?.data?.results[activeTab]?.page_admin_settings?.general.Queue
      ),
      Jobs: rebuild(
        pageAdmins?.data?.results[activeTab]?.page_admin_settings?.general.Jobs
      ),
    });
  }, [
    pageAdmins?.data?.results,
    pageAdmins?.data?.results[activeTab],
    pageAdmins,
  ]);

  useEffect(() => {
    mappedData?.Timeline &&
      setMappedDataToSend({
        ...mappedDataToSend,
        Timeline: RebuildArr(mappedData?.Timeline),
        Listings: RebuildArr(mappedData?.Listings),
        Booking: RebuildArr(mappedData?.Booking),
        Queue: RebuildArr(mappedData?.Queue),
        Jobs: RebuildArr(mappedData?.Jobs),
      });
  }, [mappedData]);

  console.log(mappedDataToSend, "ehhh");

  console.log(mappedData, "deehhh");

  const currentUserData = useSelector(
    (state) =>
      state.currentUser?.user?.pages[businessCount].business_settings.general
  );
  const currentUserId = useSelector(
    (state) => state.currentUser?.user?.pages[businessCount]?.id
  );
  const [currentUserDataMappedData, setCurrentUserDataMappedData] = useState();

  const {
    handlePrivateSellerNotificationPatchData,
    privateSellerNotificationPatchData,
    handlePatchPageAdminSettings,
    pageAdminSettings,
    handleDeletePageAdmin,
    deletedPageAdmin,
    handleSuspendPageAdmin,
    suspendedPageAdmin,
    // handleGetPageAdmins,
  } = useSettings();

  useEffect(() => {
    setCurrentUserDataMappedData(rebuild(currentUserData));
  }, [currentUserData]);

  useEffect(() => {
    if (pageAdminSettings?.data) handleGetPageAdmins(currentUserDataForGet);
  }, pageAdminSettings?.data);
  console.log("what i needaaa", pageAdmins);

  const [generalDataToSend, setGeneralDataToSend] = useState();

  useEffect(() => {
    currentUserDataMappedData &&
      setGeneralDataToSend(RebuildArr(currentUserDataMappedData));
  }, [currentUserDataMappedData]);

  console.log(mappedData, "them man");

  const currentUser = useSelector((state) => state.currentUser.user.pages);

  const currentForMe = useSelector((state) => state.currentUser);

  useEffect(() => {
    console.log("here");
    if (privateSellerNotificationPatchData.data) {
      let mod = currentUser.map((item) => {
        if (currentUser.indexOf(item) === businessCount) {
          return {
            ...item,
            business_settings: {
              ...item.business_settings,
              general: privateSellerNotificationPatchData?.data?.general,
            },
          };
        } else {
          return item;
        }
      });
      dispatch(
        setCurrentUser({
          ...currentForMe,
          user: { ...currentForMe.user, pages: mod },
        })
      );
      console.log(mod, "mod");
    }
  }, [privateSellerNotificationPatchData?.data]);

  const { adminModal, setAdminModal } = useContext(ModalContext);
  useEffect(() => {
    !adminModal && handleGetPageAdmins(currentUserDataForGet);
  }, [adminModal, deletedPageAdmin?.data, suspendedPageAdmin?.data]);
  console.log(mappedDataToSend, "map");
  return (
    <div className="businessPage_pageSettings">
      <div className="tabs">
        <p
          onClick={() => setActive(1)}
          className={`${active === 1 && "active"}`}
        >
          Admin Settings
        </p>
        <p
          onClick={() => {
            setActive(2);
          }}
          className={`${active === 2 && "active"}`}
        >
          Delivery Settings
        </p>
        <p
          onClick={() => setActive(3)}
          className={`${active === 3 && "active"}`}
        >
          General Page Settings
        </p>
      </div>
      {active === 1 && (
        <div className="tabContent">
          <div className="adminSection">
            <div className="adminSection_header">
              <p>Admins</p>
              <AdminAddIcon onClick={() => setAdminModal(currentUserId)} />
            </div>
            <ul className="adminUser_tab">
              {pageAdmins?.data?.results?.map((data, idx) => (
                <AdminUserItem
                  activeTab={activeTab}
                  handleSetActiveTab={handleSetActiveTab}
                  data={data}
                  idx={idx}
                />
              ))}
            </ul>
          </div>
          {mappedData?.Timeline?.length > 0 && (
            <div className="adminSettings">
              <div className="privilege">
                <div className="text">
                  <h3>Edit admin privilege</h3>
                  <p>Grant admin permission to perform these actions.</p>
                </div>
                <div onClick={() => setDrop(!drop)} className="threeDots">
                  <ThreeDots fill={"#490057"} />
                  {drop && (
                    <div className="dropshow">
                      <a
                        onClick={() =>
                          handleSuspendPageAdmin(
                            pageAdmins?.data?.results?.[activeTab]?.id
                          )
                        }
                      >
                        Suspend admin
                      </a>
                      <a
                        onClick={() =>
                          handleDeletePageAdmin(
                            pageAdmins?.data?.results?.[activeTab]?.id
                          )
                        }
                      >
                        Delete admin
                      </a>
                    </div>
                  )}
                </div>
              </div>
              <div className="adminSettings_toggleables">
                <h4>Timeline</h4>
                <ul>
                  {mappedData?.Timeline?.map((timelineAdminSetting, idx) => {
                    return (
                      <TimelineAdminSettingItem
                        timelineAdminSetting={timelineAdminSetting}
                        activeTab={activeTab}
                        specificData={mappedData}
                        setSpecificData={setMappedData}
                        idx={idx}
                      />
                    );
                  })}
                </ul>
              </div>
              <div className="adminSettings_toggleables">
                <h4>Jobs</h4>
                <ul>
                  {mappedData?.Jobs?.map((jobsAdminSetting, idx) => {
                    return (
                      <JobsAdminSettingsItem
                        jobsAdminSetting={jobsAdminSetting}
                        activeTab={activeTab}
                        specificData={mappedData}
                        setSpecificData={setMappedData}
                        idx={idx}
                      />
                    );
                  })}
                </ul>
              </div>
              <div className="adminSettings_toggleables">
                <h4>Listings</h4>
                <ul>
                  {mappedData?.Listings?.map((listingsAdminSetting, idx) => {
                    return (
                      <ListingsAdminSettingsItem
                        listingsAdminSetting={listingsAdminSetting}
                        activeTab={activeTab}
                        specificData={mappedData}
                        setSpecificData={setMappedData}
                        idx={idx}
                      />
                    );
                  })}
                </ul>
              </div>

              <div className="adminSettings_toggleables">
                <h4>Booking</h4>
                <ul>
                  {mappedData?.Booking?.map((bookingAdminSetting, idx) => {
                    return (
                      <BookingAdminSettingItem
                        bookingAdminSetting={bookingAdminSetting}
                        activeTab={activeTab}
                        specificData={mappedData}
                        setSpecificData={setMappedData}
                        idx={idx}
                      />
                    );
                  })}
                </ul>
              </div>
              <div className="adminSettings_toggleables">
                <h4>Queue</h4>
                <ul>
                  {mappedData?.Queue?.map((queueAdminSetting, idx) => {
                    return (
                      <QueueAdminSettingsItem
                        queueAdminSetting={queueAdminSetting}
                        activeTab={activeTab}
                        specificData={mappedData}
                        setSpecificData={setMappedData}
                        idx={idx}
                      />
                    );
                  })}
                </ul>
              </div>
              <div
                className="save"
                onClick={() =>
                  handlePatchPageAdminSettings(
                    currentData?.id,
                    mappedDataToSend
                  )
                }
              >
                <span className="saveButton">
                  {pageAdminSettings?.loading ? <Loader /> : <span>Save</span>}
                </span>
              </div>
            </div>
          )}
        </div>
      )}
      {active === 2 && (
        <PrivateSellerDeliverySettings businessCount={businessCount} />
      )}
      {active === 3 && (
        <div className="GeneralPageSettings">
          <div className="title">
            <h3>Features</h3>
            <p>
              is simply dummy text of the printing and typesetting industry.
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has
            </p>
          </div>
          <div className="toggleables">
            <ul>
              {currentUserDataMappedData?.map((generalItem, idx) => {
                if (idx !== 0)
                  return (
                    <GeneralPageSettingsItem
                      generalItem={generalItem}
                      setSpecificData={setCurrentUserDataMappedData}
                      specificData={currentUserDataMappedData}
                      idx={idx}
                    />
                  );
              })}
            </ul>
          </div>
          <p>Choose the minimum price a buyer can purchase on this page</p>
          <p className="lowestPrice">
            <span>Price($)</span>
            <div
              contentEditable="true"
              onInput={(e) => {
                setCurrentUserDataMappedData(
                  currentUserDataMappedData.map((item, idx) => {
                    if (
                      item.title ===
                      "Choose the minimum price a buyer can purchase on this page"
                    ) {
                      return { ...item, value: e.target.innerText };
                    }
                    return item;
                  })
                );
              }}
            >
              {/* <span style={{paddingRight: '5px'}}>$</span> */}
              {currentUserDataMappedData?.[0]?.value}
            </div>
          </p>
          <div
            className="save"
            onClick={() => {
              handlePrivateSellerNotificationPatchData({
                id: currentUserDataForGet,
                data: generalDataToSend,
                type: "business_general",
              });
            }}
          >
            <span className="saveButton">
              {privateSellerNotificationPatchData.loading ? (
                <Loader />
              ) : (
                <span>Save</span>
              )}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
export default BusinessPagePage;
