import {
  RESET_SINGLE_POST_DATA,
  ADD_COMMENT,
  ADD_REPLY,
  NEW_COMMENT_LIST,
  NEW_REPLY_LIST,
  CREATE_SINGLE_POST_DATA,
  UPDATE_SINGLE_POST,
  UPDATE_SINGLE_POST_COMMENT,
  UPDATE_COMMENT_LIST,
  UPDATE_COMMENT,
  UPDATE_POST_LIKE,
} from "./index.types";

export const resetSinglePostData = (payload) => ({
  type: RESET_SINGLE_POST_DATA,
  payload,
});

export const newSinglePostCommentList = (payload) => ({
  type: NEW_COMMENT_LIST,
  payload,
});

export const createSinglePostData = (payload) => ({
  type: CREATE_SINGLE_POST_DATA,
  payload,
});

export const newReplyList = (payload) => ({
  type: NEW_REPLY_LIST,
  payload,
});

export const updateSinglePosts = (payload) => ({
  type: UPDATE_SINGLE_POST,
  payload,
});

export const updateSinglePostComment = (payload) => ({
  type: UPDATE_SINGLE_POST_COMMENT,
  payload,
});

export const addReply = (payload) => ({
  type: ADD_REPLY,
  payload,
});

export const updateCommentList = (payload) => ({
  type: UPDATE_COMMENT_LIST,
  payload,
});

export const updateComment = (payload) => ({
  type: UPDATE_COMMENT,
  payload,
});
export const updateSinglePostLike = (payload) => ({
  type: UPDATE_POST_LIKE,
  payload,
});
