import render from "react-render-html";
import { useLocation, useHistory } from "react-router-dom";
import { handlePostText } from "../../utils/formatText";
import CaretAdjust from "contenteditablecaret";
import HeartComponent from "../../assets/iconsax/active/HeartComponent";
import VuesaxBoldHeartComponent from "../../assets/iconsax/VuesaxBoldHeartComponent";
import usePosts from "../../hooks/posts";
const CommentComponentV2 = ({
  showReplyButton = true,
  comment,
  commentBox,
  setReply = () => {},
  setPlaceholder = () => {},
}) => {
  const handleClickReply = () => {
    commentBox.current.scrollIntoView(false);
    // commentBox.current.innerHTML = `@${comment.owner.username} `;
    commentBox.current.focus();

    // CaretAdjust(commentBox.current, `@${comment.owner.username} `, true, false);
    setPlaceholder(`@${comment.owner.username}`);
    setReply(comment.id);
  };
  const history = useHistory();
  const { handleLikeCommentV2 } = usePosts();

  const handleLikeIconClick = () => {
    handleLikeCommentV2(comment.id);
  };
  return (
    <div
      className="comment"
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/comment/${comment.id}`);
      }}>
      {/* <h5>View all comments</h5> */}
      {/* <h5>reply</h5> */}
      <div className="section__">
        <div className="avatar" onClick={(e) => e.stopPropagation()}>
          <img
            src={comment.page?.name || comment.owner.profile_image?.file}
            alt="user"
          />
        </div>
        <div className="detail" onClick={(e) => e.stopPropagation()}>
          <div
            className="name"
            onClick={(e) => {
              comment.page
                ? history.push(
                    `/${
                      comment.page.type.toLowerCase() === "business"
                        ? "businessseller"
                        : "privateseller"
                    }/profile/${comment.page.id}`
                  )
                : history.push(`/feeds/timeline/${comment.owner.username}`);
            }}>
            {comment.owner.full_name}
          </div>
          {/* <small className="last__seen">Last Seen {comment.lastSeen}</small> */}
          <div className="main__comment">
            {render(handlePostText(comment.body, comment.page))}
            {showReplyButton && (
              <span className="reply" onClick={handleClickReply}>
                Reply
              </span>
            )}
          </div>
        </div>
        <div
          className="like__icon"
          onClick={(e) => {
            e.stopPropagation();
            handleLikeIconClick();
          }}>
          {comment.has_like ? (
            <HeartComponent width={"15.5px"} />
          ) : (
            <VuesaxBoldHeartComponent />
          )}
        </div>
      </div>
      <div className="replies">
        {/* {console.log(replies)} */}
        {comment.replies?.map((data) => (
          <CommentComponentV2
            comment={data}
            commentBox={commentBox}
            key={data.id}
            setReply={setReply}
            setPlaceholder={setPlaceholder}
            showReplyButton={false}
          />
        ))}
      </div>
    </div>
  );
};
export default CommentComponentV2;
