import React, { useState, useContext, useEffect } from "react";
import Index from "../../../../components/cards/appliationCards/Index";
import "./Application.scss";
import {Link} from 'react-router-dom';
import ExploreContext from "../../../../hooks/exploreContext";
import { useLocation, useParams } from "react-router";
import qs from "query-string";

const Application = () => {

  const {receivedApplications} = useContext(ExploreContext);
  const [cardApplications, setCardApplications] = useState();

  const { search } = useLocation();
  const { id } = qs.parse(search);
  const { pageId } = useParams();

  useEffect(() => {
    let applications = receivedApplications?.data?.results;
    setCardApplications(applications?.slice(0, 8));
  }, [receivedApplications]);

  return (
    <div className="app">
        <Link to={`/received_applications/?id=${pageId || id}`}>
          <h3 className="heading">Received Applications</h3>
        </Link>
          <div className="app_cards">
              <Index/>
          </div>
          <Link to={`/received_applications/?id=${pageId || id}`}>
              <p className='view'>View all</p>
          </Link>
    </div>
  );
};

export default Application;
