import { useContext, useState, useEffect } from "react";
import "./jobAnalyticModal.scss";
import ModalContext from "../../../../hooks/modalContexts";
import CloseBtn from "../../../../components/closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import SalesGrowthChart from "../../../Analytics/components/salesGrowthJobApplication";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import {
  chartData2,
  chartOptions2,
} from "../../../../components/Analytics/chartDetails";
import AreaChart from "../../../../components/Analytics/Charts/areaChartJob";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import BarChart from "../../../Analytics/Charts/barChartJobDemogra";
import { useLocation } from "react-router-dom";
import useAnalytics from "../../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../../hooks/exploreContext'
import LoaderPurple from "../../../../assets/loaders/LoaderPurple"

const JobAnalyticModal = () => {
  // /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());
  const [activeTab, setActiveTab] = useState("all");
  const [activeTab2, setActiveTab2] = useState("application insight");
  const [activeTab3, setActiveTab3] = useState("canada1");

  const { setJobAnalyticModal } = useContext(ModalContext);
  const testArray = [1, 2, 3, 4, 5, 6, 7];

  const { search } = useLocation();
  const { id } = qs.parse(search);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)

  const {
       // post job
     analyticsPostJobData,
     handleGetAnalyticsPostJob,

      // Job Chart
    analyticsJobChartData,
    handleGetAnalyticsJobChart,
  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsPostJob(id)
    handleGetAnalyticsJobChart(id)
  }, [])

  // post jobs
const postJobs = analyticsPostJobData?.data?.results
// Job
const jobChart =  analyticsJobChartData?.data

  return (
    <div
      className="jobAnalyticModal"
      onClick={(e) => setJobAnalyticModal(false)}
    >
      <div className="jobAnalyticModalContainer">
        <div onClick={(e) => setJobAnalyticModal(false)} className="closeIcon">
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="jobAnalyticModalContainered"
        >
          <div className="jobAnalyticModal-header">
            <h4> Job Post </h4>
            <div className="tabs">
              {/* ----------  nav tab -------- */}
              <ul className="nav-tab">
                <li
                  className={activeTab === "all" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("all");
                  }}
                >
                  All
                </li>
                <li
                  className={activeTab === "completed" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("completed");
                  }}
                >
                  Completed
                </li>
                <li
                  className={activeTab === "incomplete" ? "active" : ""}
                  onClick={() => {
                    setActiveTab("incomplete");
                  }}
                >
                  Incomplete
                </li>
              </ul>
            </div>
            <div className="datepicker-container">
            <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsPostJob(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="jobAnalyticModal-body">
            <div className="gam-content-one">
              <div className="item__scroll">
                 {analyticsPostJobData?.loading && analyticsPostJobData?.data?.count !== 0 && <LoaderPurple />}
                {postJobs?.map((data, idx) => (
                  <div className="jcob-details">
                    <div className="details-box-one">
                      <h4>{idx + 1}</h4>
                      <img src={data?.featured_image.file ? data?.featured_image.file : itemImg} alt="" />
                      <div className="details-box-two">
                        {/* <h5> Maria Sponge </h5> */}
                        <h5>@{data?.page_username ? data?.page_username : "Username"} </h5>
                      </div>
                      <div className="details-box-three">
                      <img src={data?.page_featured_image !== null ? data?.page_featured_image : itemImg} alt="" />
                        <h5> {data?.job_title}</h5>
                      </div>
                    </div>

                    <div className="jcob-chart">
                     <p>
                        {data?.summary.summary.percentage}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                      </p>
                      <div>
                       <AreaChart data={data}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="amb-statistics">
              <div className="tabs">
                {/* ----------  nav tab -------- */}
                <ul className="nav-tab">
                  <li
                    className={
                      activeTab2 === "application insight" ? "active" : ""
                    }
                    onClick={() => {
                      setActiveTab2("application insight");
                    }}
                  >
                    Application insight
                  </li>
                  <li
                    className={activeTab2 === "demographic" ? "active" : ""}
                    onClick={() => {
                      setActiveTab2("demographic");
                    }}
                  >
                    Demographic
                  </li>
                </ul>
              </div>
              <div className="jam-tab-box">
                {activeTab2 === "application insight" && (
                  <SalesGrowthChart
                    title="Cleaning Job"
                    height={250}
                    width={700}
                    jobChart={jobChart} 
                  />
                )}

                {activeTab2 === "demographic" && (
                  <div className="jam-bar-box">
                    <div className="tabs">
                      {/* ----------  nav tab -------- */}
                      <ul className="nav-tab">
                        <li
                          className={activeTab3 === "canada1" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("canada1");
                          }}
                        >
                          Canada
                        </li>
                        <li
                          className={activeTab3 === "canada2" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("canada2");
                          }}
                        >
                          Canada
                        </li>
                        <li
                          className={activeTab3 === "canada3" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("canada3");
                          }}
                        >
                          Canada
                        </li>
                      </ul>
                    </div>
                    <BarChart jobChart={jobChart} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default JobAnalyticModal;
