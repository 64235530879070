/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/
import pathToFile from "./activeBookmark.png";
import React from "react";

const ActiveBookmark = (props) => (
  <img
    src={pathToFile}
    {...props}
    alt=""
    style={{
      width: props.width || "1em",
    }}
  />
);

export default ActiveBookmark;
