import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Fragment } from "react/cjs/react.production.min";
import "./style.scss";
const useSkeletenLoader = () => {
  const FeedPost = () => {
    return (
      <div className="feed__post__skeleton">
        <Skeleton height={"100%"} borderRadius={20} />
        <Skeleton count={10} height={20} />
      </div>
    );
  };

  const MultipleFeedPosts = () => {
    return (
      <Fragment>
        <FeedPost />
        <FeedPost />
      </Fragment>
    );
  };
  return {
    FeedPost,
    MultipleFeedPosts,
  };
};
export default useSkeletenLoader;
