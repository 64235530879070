import { useState, useEffect } from "react";
import useDeviceType from "../../../hooks/deviceType";
import CreateListingBody from "../../../components/PrivateSeller/Listings/createListingBody";
import FeedContainer from "../../../components/FeedContainer";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../../appstore/navigationDisplayToggle/index.action";
import { useHistory, useLocation, useParams } from "react-router-dom";
import greeting from "../../../utils/greeting";
import usePages from "../../../hooks/createPage";
import qs from "query-string";
import EditListingBody from "../../../components/PrivateSeller/Listings/editListingBody";

const EditListing = () => {
  // const { isMobile, isDesktop } = useDeviceType();
  const { pageId } = useParams();
  const { singlePageData, handleGetSinglePage, categoryData } = usePages();
  const dispatch = useDispatch();
  const { data } = singlePageData;

  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    handleGetSinglePage(pageId)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, [pageId]);

  return (
    <FeedContainer>
      {singlePageData.data ? (
        <div className="ps-createlistings-container">
          <div className="lbc-header">
            <h1 className="welcome-title">
              {greeting()} <span>{data.name.split(" ")[0]} </span>
            </h1>
          </div>
          <div className="lbc-card">
            <h5 className="sub-title"> Edit Listing</h5>
            <EditListingBody
              {...data}
              category={categoryData.data}
              pageId={pageId}
            />
          </div>
        </div>
      ) : (
        <div />
      )}
    </FeedContainer>
  );
};

export default EditListing;
