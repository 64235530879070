import useAxios from "axios-hooks";
const useUploads = () => {
  const [{ ...uploadData }, upload] = useAxios(
    {
      method: "post",
      url: "/uploads/",
    },
    { manual: true }
  );

  const handleUploads = async (files) => {
    if (files) {
      console.log(files.values());
      return await upload({ data: files });
    } else {
      return false;
    }
  };

  const [{ ...deletedData }, deleteData] = useAxios();

  const handleDeleteData = (id) => {
    deleteData(
      {
        method: "delete",
        url: `/uploads/${id}`,
      },
      {
        manual: true
      }
      
    )
  }

  return {
    uploadData,
    handleUploads,
    handleDeleteData,
    deletedData
  };
};
export default useUploads;
