const suggestedPages = [
  {
    name: "Rose Kooto",
    state: "Abuja",
  },
  {
    name: "Kachi Kooto",
    state: "Abuja",
  },
  {
    name: "Rose Kooto",
    state: "Abuja",
  },
  {
    name: "Rose Kooto",
    state: "Abuja",
  },
  {
    name: "Rose Human",
    state: "Abuja",
  },
  {
    name: "Human Beign",
    state: "Abuja",
  },
];

export default suggestedPages;
