import useAxios from "axios-hooks";

const useReferral = () => {
  // Get all products tied to a page

  const [{ ...allProducts }, getAllProducts] = useAxios();

  const handleGetAllProducts = (page) => {
    getAllProducts({
      method: "get",
      url: "/products/",
      params: {
        page: page,
      },
    });
  };

  // fetching affiliates tied to a particular product or particular page bookings

  const [{ ...productAffiliates }, getProductAffiliates] = useAxios(
    {
      method: "get",
      url: "/referrals/",
    },
    {
      manual: true,
    }
  );

  const handleGetProductAffiliates = async ({
    productId,
    pageId,
    serviceId,
  }) => {
    return await getProductAffiliates({
      params: {
        product: productId && productId,
        page: pageId && pageId,
        service: serviceId && serviceId,
      },
    });
  };

  // fetching commissions earned by each affiliate

  const [{ ...affiliateCommission }, getAffiliateCommissions] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetAffiliateCommissions = async (id) => {
    return await getAffiliateCommissions({
      url: `/users/${id}/referral_commissions/`,
    });
  };
  return {
    allProducts,
    handleGetAllProducts,
    productAffiliates,
    handleGetProductAffiliates,
    handleGetAffiliateCommissions,
    affiliateCommission,
  };
};
export default useReferral;
