import { useContext, useState } from 'react'
import ModalContext from '../../../../../hooks/modalContexts'
import './index.scss'
import { FaTimes,} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all";
import SuccessCheck from "../../../../../assets/svg/successThick"
import useConnect from "../../../../../hooks/connectHooks"
import ExploreContext  from '../../../../../hooks/exploreContext'

const RetractConnectModal = () => {
    const {handleRetractSuccessConnectModal, retractSuccessConnectModal} = useContext(ModalContext)

    const {
        handleGetConnectProfileSuggested,
      } = useConnect();

      const {
        currentProfile,
        successData,
        setSuccessData,
        acceptSuccessModal, 
        setAcceptSuccessModal,
      } = useContext(ExploreContext)

    return(
      <div
      onClick={()=>{handleRetractSuccessConnectModal('RESET_RETRACT_SUCCESS_CONNECT_TO_DISPLAY')}}
      className="successModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="successModalContainer"
       >
          <div
          onClick={()=>{handleRetractSuccessConnectModal('RESET_RETRACT_SUCCESS_CONNECT_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
          <div className="enter-success">
            <SuccessCheck/>
          </div>
          <div className="success-text">
            You have retracted the connect
          </div>
          <div className="success-text">Successfully </div>

            <div className='success-save-button'>
              <button
                onClick={()=>{
                  handleRetractSuccessConnectModal('RESET_RETRACT_SUCCESS_CONNECT_TO_DISPLAY');
                //   setSuccessData(currentProfile);
                  console.log(successData)
                }}
              >Done</button>
            </div>
          </div>
      </div>
    )
}
export default RetractConnectModal
