const BookingBusinessNotificationItem = ({bookingNotificationItem, dataEntered, setDataEntered,idx}) => {
    return(
        <li>
            <span>{bookingNotificationItem.title}</span>
            <div className={`switch ${bookingNotificationItem.value === false  && 'off'}`} 
            onClick={()=>{
                
                setDataEntered({...dataEntered, booking:dataEntered.booking.map((data)=>{
                    let indexNo = dataEntered.booking.indexOf(data)
                    if(indexNo === idx){
                        return data.value ? {...data, value: false}: {...data, value: true}
                    }
                    return data
                })})
            }}>
                <span className={`${bookingNotificationItem.value === false && 'offSpan'}`} ></span>
            </div>
        </li>
    )
}
export default BookingBusinessNotificationItem