/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearTrashComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 15.082 15.082" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.3px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <path
      className="a"
      d="M11.311.314C9.219.107,7.114,0,5.015,0A37.119,37.119,0,0,0,1.282.189L0,.314"
      transform="translate(1.885 3.444)"
    />
    <path
      className="a"
      d="M0,1.866l.138-.823C.239.446.314,0,1.376,0H3.023C4.085,0,4.166.471,4.261,1.049l.138.817"
      transform="translate(5.341 1.257)"
    />
    <path
      className="a"
      d="M8.609,0,8.2,6.328c-.069.987-.126,1.753-1.879,1.753H2.287C.534,8.081.478,7.315.408,6.328L0,0"
      transform="translate(3.236 5.744)"
    />
    <path className="a" d="M0,0H2.093" transform="translate(6.491 10.369)" />
    <path className="a" d="M0,0H3.142" transform="translate(5.97 7.855)" />
    <path className="b" d="M0,0H15.082V15.082H0Z" />
  </svg>
);

export default VuesaxLinearTrashComponent;
