import React, {useState} from "react";
import "./contactCard.scss";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import menu from '../../assets/messaging/ellipsis.svg';

import starredMenu from '../../assets/messaging/filled/star-menu.svg';
import read from '../../assets/messaging/filled/tick-circle.svg';
import trash from '../../assets/messaging/filled/trash.svg';
import flaggedMenu from '../../assets/messaging/filled/flag-menu.svg';
import { truncateWordEllipsis } from "../../utils/helpers";

const ContactCard = (props) => {

	const handleContactMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(false);
	};


	const [anchorEl, setAnchorEl] = useState(false);
	const open = Boolean(anchorEl);

    return (
        <>
            <div className={`chat-single ${props.active ? 'active' : ''}`} onClick={props.clickAction}>
                <img alt="" src={props.avatar}/>

                <div className="chat-details">
                    <div className="username">{props.username}</div>
                    <div className="snippet">{truncateWordEllipsis(props.lastMsg, 27, true)}</div>

                    <div className="chat-options">
                        <div className="date">{props.msgDate}</div>

                        <img src={menu}
                            alt=""
                            aria-controls={open ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                            onClick={handleContactMenu}
                        />

                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                'aria-labelledby': 'basic-button',
                            }}
                        >
                            <MenuItem onClick={handleClose}>
                                <img alt="" src={starredMenu} className='menu-icon'/>&nbsp;
                                <p className="menu-text">Star</p>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <img alt="" src={read} className='menu-icon'/>&nbsp;
                                <p className="menu-text">Mark as read</p>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <img alt="" src={flaggedMenu} className='menu-icon'/>&nbsp;
                                <p className="menu-text">Flag</p>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <img alt="" src={trash} className='menu-icon'/>&nbsp;
                                <p className="menu-text">Delete</p>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ContactCard;