// import barb from "../../../assets/img/barb.png";
import { IoCloseCircleOutline, IoAddCircleOutline } from "react-icons/all";
import { useRef, useState } from "react";
import { useEffect } from "react";
const MediaContentsMultiple = ({ onChange = () => {} }) => {
  const [images, setImages] = useState([]);
  const imagesSelector = useRef(null);
  const handleChange = (files = []) => {
    // const { length } = files;
    FileList.prototype.map = Array.prototype.map;
    setImages([
      ...images,
      ...files.map((data) => {
        return data;
      }),
    ]);
  };

  useEffect(() => {
    onChange(images);
  }, [images]);
  return (
    <div className="multi-media-container">
      <div className="media-contents-multiple scroll-style-visible">
        <div
          className="add-image"
          onClick={(e) => imagesSelector.current.click()}>
          <IoAddCircleOutline />
        </div>
        {images?.map((img, idx) => {
          // console.log(img);
          return (
            <div className="images-container" key={idx}>
              <img src={URL.createObjectURL(img)} alt={idx} />
              <div
                className="remove-image"
                onClick={(e) => {
                  setImages(
                    images
                      .map((data, id) => id !== idx && data)
                      .filter((data) => data && data)
                  );
                }}>
                <IoCloseCircleOutline />
              </div>
            </div>
          );
        })}
        <input
          ref={imagesSelector}
          type="file"
          accept="image/*"
          style={{ display: "none" }}
          multiple
          onChange={(e) => handleChange(e.target.files)}
        />
      </div>
    </div>
  );
};

export default MediaContentsMultiple;
