const middleFeedNav = [
  {
    name: "Community",
    link: "/feeds/community",
  },
  {
    name: "See What's Trending",
    link: "/feeds/trending",
  },
  {
    name: "My Timeline",
    link: "/feeds/timeline",
  },
];

export default middleFeedNav;
