import Pen from "../../../components/pen";
import Select from "react-select";
import testAvatar from "../../../assets/img/Ellipse 4_.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useMemo, useState, useEffect, useRef } from "react";
import countryList from "react-select-country-list";
import useDeviceType from "../../../hooks/deviceType";
import { setModal } from "../../../appstore/userModal/index.action";
import useSettings from "../../../hooks/settings";
import { useDispatch } from "react-redux";
// import { setCurrentUser } from "../../../appstore/userData/index.action";
import "react-phone-number-input/style.css";
import CountryDropdown from "country-dropdown-with-flags-for-react";
import Loader from "../../../components/loader";

const GeneralSettings = ({ currentUser, secondaryTab }) => {
  const {
    fetchSettings,
    settingsData,
    handlePatchGeneralUserData,
    generalUserData,
    handlePatchProfilePic,
  } = useSettings();
  const options = useMemo(() => countryList().getData(), []);
  const [checked, setChecked] = useState(currentUser?.gender);
  const [landing, setLanding] = useState(currentUser?.default_landing_page);
  const { isDesktop, isMobile } = useDeviceType();
  const [modal, setModal] = useState(false);
  // const handleSetNuber= () =>{
  //     setFormData({...formdata, country: va})
  // }
  const [formdata, setFormData] = useState({
    first_name: currentUser?.first_name,
    last_name: currentUser?.last_name,
    phone_number: currentUser?.phone_number,
    country: currentUser?.country,
    gender: checked,
    bio: currentUser?.bio,
    default_landing_page: landing,
  });
  useEffect(() => {
    setFormData({
      ...formdata,
      gender: checked,
      default_landing_page: landing,
    });
  }, [checked, landing]);
  const [defaultCountry, setDefaultCountry] = useState(
    countryList().getLabel(currentUser?.country)
  );
  //   const dispatch = useDispatch()

  console.log(generalUserData.data);
  const ref = useRef();
  const handleFileInput = () => {
    ref.current.click();
  };
  const [profilePic, setProfilePic] = useState();

  console.log("current user", currentUser);
  useEffect(() => {
    if (profilePic) {
      const profilePicFormData = new FormData();
      profilePicFormData.append("file", profilePic);
      handlePatchProfilePic(profilePicFormData);
    }
  }, [profilePic]);
  return (
    <div className="secondaryTabContent flex">
      <div className="left">
        {isDesktop && <h4>{secondaryTab}</h4>}

        <form action="">
          <div className="name">
            <label htmlFor="">
              <div>
                <input
                  defaultValue={currentUser?.first_name}
                  onChange={(e) => {
                    setFormData({ ...formdata, first_name: e.target.value });
                  }}
                  type="text"
                />
                <Pen />
              </div>
            </label>
            <label htmlFor="">
              <div>
                <input
                  defaultValue={currentUser?.last_name}
                  onChange={(e) =>
                    setFormData({ ...formdata, last_name: e.target.value })
                  }
                  type="text"
                />
                <Pen />
              </div>
            </label>
          </div>
          <div className="phone">
            <label htmlFor="">
              <div className="phoneBottom">
                <PhoneInput
                  international
                  defaultCountry="US"
                  placeholder="(201) 555-55555"
                  onChange={(e) =>
                    setFormData({ ...formdata, phone_number: e })
                  }
                  value={currentUser?.phone_number}
                />
                <Pen />
              </div>
            </label>
          </div>
          {/* <div className="country">
                        <label htmlFor="">
                            
                            <Select options={options} 
                            onChange={e => {
                                setFormData({...formdata, country: e.value})
                                console.log(e)
                            }}
                            placeholder={defaultCountry}
                            />
                        </label>
                    </div> */}
          <div className="country">
            <label htmlFor="">
              <CountryDropdown
                id="UNIQUE_ID"
                className="countryDropdown"
                value={defaultCountry}
                handleChange={(e) => {
                  setFormData({ ...formdata, country: e.value });
                  console.log(e);
                }}
              ></CountryDropdown>
            </label>
          </div>
          <div className="gender">
            <label htmlFor="">
              <div className="genders">
                <div
                  className={`${checked === "MALE" && "checked"} box`}
                  onClick={() => {
                    setFormData({ ...formdata, gender: "MALE" });
                    setChecked("MALE");
                  }}
                >
                  <span>Male</span>
                  <div></div>
                </div>
                <div
                  className={`${checked === "FEMALE" && "checked"} box`}
                  onClick={() => {
                    setFormData({ ...formdata, gender: "FEMALE" });
                    setChecked("FEMALE");
                  }}
                >
                  <span>Female</span>
                  <div></div>
                </div>
                <div
                  className={`${checked === "OTHER" && "checked"} box`}
                  onClick={() => {
                    setFormData({ ...formdata, gender: "OTHER" });
                    setChecked("OTHER");
                  }}
                >
                  <span>Other</span>
                  <div></div>
                </div>
              </div>
            </label>
          </div>
          <div className="bio">
            <label htmlFor="">
              <div
                onInput={(e) => {
                  setFormData({ ...formdata, bio: e.target.innerText });
                }}
                contentEditable
              >
                {currentUser?.bio}
              </div>
            </label>
          </div>
          <div className="landingPage">
            <label htmlFor="">
              <p>Default landing page</p>
              <div className="landingOptions">
                <div className="social">
                  <span>Social Page</span>
                  <div
                    className={landing === "SOCIAL" && "checked"}
                    onClick={() => setLanding("SOCIAL")}
                  ></div>
                </div>
                <div className="business">
                  <span>Business Page</span>
                  <div
                    className={landing === "BUSINESS" && "checked"}
                    onClick={() => setLanding("BUSINESS")}
                  ></div>
                </div>
                <div className="save">Save</div>
              </div>
            </label>
          </div>
        </form>
      </div>
      {isDesktop && (
        <div className="settings-right">
          <div className="rightTop">
            <div className="centerDiv">
              <div className="avatar">
                <img
                  src={currentUser?.profile_image?.file || testAvatar}
                  alt=""
                />
              </div>
            </div>
            <div className="profilePic">
              <div onClick={() => handleFileInput()} className="change">
                Change Profile Image
              </div>
              <input
                onChange={(e) => {
                  setProfilePic(e.target.files[0]);
                }}
                ref={ref}
                style={{ display: "none" }}
                type="file"
                accept="image/png, image/jpeg"
              />
              <div
                onClick={() => {
                  setFormData({ ...formdata, profile_image: null });
                  handlePatchProfilePic(formdata);
                }}
                className="delete"
              >
                Delete Image
              </div>
            </div>
            <div className="userDetails">
              <p className="">{`@${currentUser.username}`}</p>
              <p className="">{currentUser.email}</p>
            </div>
          </div>
          <div
            onClick={() => {
              handlePatchGeneralUserData(formdata);
            }}
            className="rightSubmitBtn"
          >
            {!generalUserData?.loading ? <span>Save</span> : <Loader />}
          </div>
        </div>
      )}
      {isMobile && modal && (
        <div className="modalSocial">
          <div onClick={() => setModal(!modal)} className="overlay"></div>
          <div className="right">
            <div className="rightTop">
              <div className="centerDiv">
                <div className="avatar">
                  <img src={testAvatar} alt="" />
                </div>
              </div>
              <div className="profilePic">
                <div className="change">Change Profile Picture</div>
                <div className="delete">Delete Profile Picture</div>
              </div>
              <div className="userDetails">
                <p className="">@username</p>
                <p className="">email@gmail.com</p>
              </div>
            </div>
            <div className="rightSubmitBtn">Save</div>
          </div>
        </div>
      )}
    </div>
  );
};
export default GeneralSettings;
