import { BiUpArrowAlt, BiInfoCircle } from "react-icons/bi";
import ArrowNarrowUp from "../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../assets/svg/arrowNarrowDown";

import "./index.scss";

const OrderCompletion = ({
  image,
  title,
  subTitle,
  bgColor,
  bgColor2,
  orderValue,
  orderPercent,
  upAndDown,
  listingInteractions,
  onClick = () => {},
}) => {
  return (
    <div
      className="order-completion-container"
      style={{ backgroundColor: bgColor }}
      onClick={(e) => {
        onClick();
      }}
    >
      <div className="order-box">
        <div className="occ-box">{image}</div>
        <div className="order-box-contents">
          <h5> {title}</h5>
          <p>{subTitle}</p>
          <h6>{orderValue}</h6>
        </div>
        <div className="obc-box">
          <div className="obc-value" style={{ backgroundColor: bgColor2 }}>
            {orderPercent} {upAndDown}
          </div>
          {/* <button className="btn-view">view</button> */}
        </div>
        <BiInfoCircle className="info-icon" />
      </div>
    </div>
  );
};
export default OrderCompletion;
