import { useContext, useState, useEffect } from "react";
import "./orderModal.scss";
import ModalContext from "../../../../hooks/modalContexts";
import CloseBtn from "../../../closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import SalesGrowthChart from "../../../Analytics/components/salesGrowthRefPageVisit";
import OrderCompletionModal from "../../../Analytics/components/orderCompletedListingDropdownModal";
import WalletMoney from "../../../../assets/svg/wallet-money";
import { useLocation } from "react-router-dom";
import useAnalytics from "../../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../../hooks/exploreContext'

const TotalPageVisitModal = () => {
  /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const { setTotalPageVisitModal } = useContext(ModalContext);
  const { search } = useLocation();
  const { id } = qs.parse(search);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)

  const {
  // Referral
  analyticsReferralInteraction,
  handleGetAnalyticsReferralInteraction,

  // Referral
  analyticsReferralChartData,
  handleGetAnalyticsReferralChart,

  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsReferralInteraction(id)
    handleGetAnalyticsReferralChart(id)
  }, [])

  // Referal
const referralInteraction = analyticsReferralInteraction?.data

// const referralChart = analyticsReferralChartData?.data

  return (
    <div
      className="orderCompletionModal"
      onClick={(e) => setTotalPageVisitModal(false)}
    >
      <div className="orderCompletionModalContainer">
        <div
          onClick={(e) => setTotalPageVisitModal(false)}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="orderCompletionModalContainered"
        >
          <div className="orderCompletionModal-header">
            <h4> Total Page Visits </h4>
            <div className="datepicker-container">
               <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                    handleGetAnalyticsReferralInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="orderCompletionModal-body">
            <OrderCompletionModal
              image={<WalletMoney />}
              title="Total page visits with link"
              statPercent={referralInteraction?.total_page_visit_with_links?.summary?.percentage.toFixed(1) + "%"}
              statTime="in less than a week"
              statTotal={referralInteraction?.total_page_visit_with_links?.total}
              bgColor="rgba(73,0,87,1.0)"
              bgColor2="#580068"
              bgColor3="#6A017E"
              upAndDown={referralInteraction?.total_page_visit_with_links?.summary.direction === "UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
            />

            <div className="amb-statistics">
              <SalesGrowthChart title="Order Statistics" referralInteraction={referralInteraction} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default TotalPageVisitModal;
