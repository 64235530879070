import useAxios from "axios-hooks";
import { useState } from "react";
import { useEffect } from "react";
import LoaderPurple from "../../assets/loaders/LoaderPurple";
import usePages from "../../hooks/createPage";
import CustomCheckBox from "../customCheckBox";
import cx from "classnames";
const TimelinePages = ({ owner, type, editMode = false }) => {
  const [limit] = useState(4);
  const [{ ...pagesData }, getPages] = useAxios(
    {
      method: "get",
      url: "/pages",
      params: {
        owner,
        display_on_profile: true,
        limit,
      },
    },
    {
      manual: true,
    }
  );
  const [{ ...allPagesData }, getCurrentPages] = useAxios(
    {
      method: "get",
      url: "/pages",
      params: {
        owner,
        type,
        display_on_profile: false,
        limit,
      },
    },
    {
      manual: true,
    }
  );
  const {
    handleAddPagesToDisplay,
    pagesToProfileDisplay,
    removedPagesData,
    handleRemovePagesToDisplay,
  } = usePages();

  useEffect(() => {
    if (editMode) {
      getCurrentPages();
    }
    return () => {
      editMode && getPages();
    };
  }, [editMode]);

  const [updating, setUpdating] = useState();

  console.log(pagesData.data);

  useEffect(() => {
    // console.log(pagesToProfileDisplay.data, removedPagesData.data);
    !pagesToProfileDisplay.loading &&
      !removedPagesData.loading &&
      setUpdating();
  }, [
    pagesToProfileDisplay.data,
    removedPagesData.data,
    updating,
    removedPagesData.loading,
    pagesToProfileDisplay.loading,
  ]);
  useEffect(() => {
    getPages();
  }, [owner]);
  return (editMode && allPagesData.data && !allPagesData.loading) ||
    (!editMode && !pagesData.loading && !allPagesData.loading) ? (
    <ul>
      {(editMode ? allPagesData.data?.results : pagesData.data?.results)?.map(
        (page) => {
          return (
            <li
              className={cx({
                "page-profile-updating": updating === page.id,
              })}>
              {editMode &&
                allPagesData?.data &&
                !allPagesData.loading &&
                pagesData.data && (
                  <CustomCheckBox
                    defaultValue={pagesData.data.results
                      .map((data) => data.id)
                      .includes(page.id)}
                    onClick={async (e) => {
                      //set how update happens
                      if (!updating) {
                        setUpdating(page.id);
                        e
                          ? handleAddPagesToDisplay([page.id])
                          : handleRemovePagesToDisplay([page.id]);
                      }
                    }}
                  />
                )}{" "}
              {page.name}
            </li>
          );
        }
      )}
    </ul>
  ) : (
    <LoaderPurple style={{ width: 10, height: 10 }} />
  );
};

export default TimelinePages;
