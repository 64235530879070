import "./home.scss";
import Posts from "../../components/Posts";
import MiddleFeed from "../../components/MiddleFeed";
import FeedContainer from "../../components/FeedContainer";
import { SocialProvider } from "../../hooks/socialContext";

const Home = () => {
  return (
    <FeedContainer>
      <SocialProvider>
        <MiddleFeed>
          <Posts />
        </MiddleFeed>
      </SocialProvider>
    </FeedContainer>
  );
};
export default Home;
