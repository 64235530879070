import React from "react";
import { Link, useHistory } from "react-router-dom";
import logowhite from "../../assets/icons/meeney__white-logo.png";
import "./welcomePage.scss";
import CaseBrief from "../../assets/svg/caseBrief";
import Person from "../../assets/svg/person";
import { FaArrowLeft } from "react-icons/fa";

const WelcomePage = () => {
  const history = useHistory();

  return (
    <div className="wp-container">
      <div className="wp-top-banner">
        <img className="img-logo" alt="meeney logo" src={logowhite} />
        <div className="circle__A"></div>
        <div className="circle__B"></div>
        <div className="circle__C"></div>
      </div>
      <div className="main-content">
        <div className="top-heading-box">
          <h1>Create a Page</h1>
          <h6>
            is simply dummy text of the printing and typesetting industry. Lorem
            Ipsum has been the industry's standard dummy text ever since the
            1500s,
          </h6>
        </div>
        <div className="center-content">
          <div className="cc-private-seller">
            <div className="cc-ps-card">
              <div className="circle-v">
                <Person className="cb-style" />
              </div>
              <h5>Private Seller</h5>
              <p>
                is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. 
              </p>
            </div>
            <Link exact to="/pagesetup" className="btn-link" onClick="">
              Create Now
            </Link>
          </div>
          <div className="cc-private-seller ">
            <div className="cc-ps-card">
              <div className="circle-v">
                <CaseBrief className="cb-style" />
              </div>
              <h5>Business Page</h5>
              <p>
                is simply dummy text of the printing and typesetting industry.
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer took a galley of type
                and scrambled it to make a type specimen book. 
              </p>
            </div>
            <Link exact to="/businesspagesetup" className="btn-link" onClick="">
              Create Now
            </Link>
          </div>
        </div>
      </div>
      <div className="wp-footer">
        <Link className="back-link" onClick={history.goBack}>
          <FaArrowLeft /> Back
        </Link>
      </div>
    </div>
  );
};

export default WelcomePage;
