import axios from "axios";
import useAxios from "axios-hooks";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";

const usePrivateSellerDashboard = () => {
  const { pageId: id } = useParams();
  console.log(id, "id");
  const [{ ...allOrdersData }, getAllOrders] = useAxios();

  const handleGetAllOrders = () => {
    getAllOrders({
      url: `/orders/?status=PENDING`,
      method: "get",
    });
  };
  const handleGetCustomizedOrders = ({
    tab,
    processing,
    out_for_delivery,
    referred,
    history_condition,
    ordering,
    search,
    created_at__gte,
    created_at__lte,
    page,
    pre_order,
  }) => {
    getAllOrders({
      url: `/orders/`,
      method: "get",
      params: {
        tab: tab,
        processing: processing,
        out_for_delivery: out_for_delivery,
        referred: referred,
        history_condition: history_condition,
        ordering: ordering,
        search: search,
        created_at__gte: created_at__gte,
        created_at__lte: created_at__lte,
        pre_order: pre_order,
        page: id,
        sudo: "true",
      },
    });
  };

  const [{ ...updateOrderStatusData }, updateOrderStatus] = useAxios();

  const handleUpdateOrderStatus = (id, statusToUpdate) => {
    updateOrderStatus({
      method: "post",
      url: `/orders/${id}/update_order_status/`,
      data: {
        status: statusToUpdate,
      },
    });
  };

  const [{ ...bulkUpdateOrderStatusData }, bulkUpdateOrderStatus] = useAxios();

  const handleBulkUpdate = ({ ids, status }) => {
    bulkUpdateOrderStatus({
      method: "patch",
      url: `/orders/bulk/`,
      data: {
        ids: ids,
        status: status,
      },
    });
  };
  const [{ ...allReviews }, fetchReviews] = useAxios();

  const handleFetchReviews = (id) => {
    fetchReviews({
      method: "get",
      url: `/pages/${id}/reviews/`,
      // params: {
      //   page: id,
      // },
    });
  };

  const [{ ...replyReviewData }, replyReview] = useAxios();

  const handleReplyReview = (id, data, reviewId) => {
    replyReview({
      method: "post",
      url: `/pages/reviews/`,
      params: {
        page: id,
      },
      data: {
        body: data,
        reply_to: reviewId,
      },
    });
  };

  const [{ ...toggleDeliveryStatusData }, toggleDeliveryStatus] = useAxios();

  const handleToggleDeliveryStatus = (id) => {
    toggleDeliveryStatus({
      method: "post",
      url: `/orders/${id}/out_for_delivery/`,
    });
  };

  const [{ ...singleOrderData }, getSingleOrder] = useAxios(
    {
      method: "get",
    },
    { manual: true }
  );

  const handleGetSingleOrder = async (id, sudo = false) => {
    return await getSingleOrder({
      url: `/orders/${id}/`,
      params: {
        sudo,
      },
    });
  };

  return {
    allOrdersData,
    handleGetAllOrders,
    handleGetCustomizedOrders,
    handleUpdateOrderStatus,
    updateOrderStatusData,
    allReviews,
    handleFetchReviews,
    replyReviewData,
    handleReplyReview,
    handleToggleDeliveryStatus,
    toggleDeliveryStatusData,
    handleBulkUpdate,
    bulkUpdateOrderStatusData,
    handleGetSingleOrder,
    singleOrderData,
  };
};
export default usePrivateSellerDashboard;
