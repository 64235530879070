import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import "./Allcard.scss";
import { jobData } from "./jobData";
import Select from "react-select";
import DropdownIndicator from "./FontAwesomeIndicator";
import ExploreContext from "../../../../../hooks/exploreContext";
import { toSentenceCaseUnderscore } from "../../../../../utils/helpers";
import moment from "moment";

const AllJobCards = () => {
  const [isOpened, setOpened] = useState("false");
  const handleShow = () => {
    setOpened(!isOpened);
  };
  const { myJobPosts } = useContext(ExploreContext);

  const options = [
    { label: "Extend", value: 355 },
    { label: "Edit", value: 54 },
    { label: "Promote", value: 43 },
  ];

  return (
    <section className="all_card_container">
      {myJobPosts?.data?.results?.length > 0 &&
        myJobPosts.data.results.map((job, index) => (
          <div className="job-card" key={index}>
            <div className="job-card-left">
              <div className="job-card-user">
                <div className="job-card-user-head">
                  <div>
                    <img src={job?.gallary[0]?.file} alt="" />
                  </div>
                  <div>
                    <h2>{job?.page?.name}</h2>
                    <p>@{job?.page?.username}</p>
                  </div>
                  <p className="active_status">
                    {toSentenceCaseUnderscore(job?.status)}
                  </p>
                </div>
              </div>
              <div className="job-card-text">
                <h1>{job?.title}</h1>
                <h3 className="status">
                  <strong> {toSentenceCaseUnderscore(job?.type)}: </strong>{" "}
                  <span className="salary">
                    {job?.min_salary} - {job?.max_salary}{" "}
                    {toSentenceCaseUnderscore(job?.payment_interval)}
                  </span>{" "}
                </h3>
                <p className="job-card-p1">{job?.page?.description}</p>
                <p className="job-card-p2">
                  Posted: {moment(job?.created_on).format("LL")}
                </p>
                <p className="job-card-p3">
                  Ends: {moment(job?.end_on).format("LL")}
                </p>
              </div>
            </div>
            <div className="job-card-right">
              <div></div>
              <div className="crud_op">
                <Select
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator,
                  }}
                  options={options}
                  className="react-select-container"
                  classNamePrefix="react-select"
                  placeholder={"Delete"}
                />
              </div>
            </div>
          </div>
        ))}
    </section>
  );
};

export default AllJobCards;
