const AdminAddIcon = ({ onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22.5"
      height="22.5"
      viewBox="0 0 22.5 22.5"
      onClick={onClick}
    >
      <g
        id="Group_2710"
        data-name="Group 2710"
        transform="translate(-1035.726 -529.669)"
      >
        <path
          id="Path_640"
          data-name="Path 640"
          d="M24,13.5A10.5,10.5,0,1,1,13.5,3,10.5,10.5,0,0,1,24,13.5Z"
          transform="translate(1033.476 527.419)"
          fill="none"
          stroke="#fff"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        />
        <g
          id="Group_2417"
          data-name="Group 2417"
          transform="translate(1041.058 535.002)"
        >
          <path
            id="Path_642"
            data-name="Path 642"
            d="M12,18H23.835"
            transform="translate(-12 -12.083)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
          <path
            id="Path_745"
            data-name="Path 745"
            d="M0,0H11.835"
            transform="translate(5.917 0) rotate(90)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="1.5"
          />
        </g>
      </g>
    </svg>
  );
};
export default AdminAddIcon;
