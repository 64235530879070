import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import NoData from "../../components/NoData";
import PostCard from "../../components/PostCards";
import useSaved from "../../hooks/saved";
import useSkeletenLoader from "../../hooks/FeedSkeletonLoader";
const Social = () => {
  const { MultipleFeedPosts } = useSkeletenLoader();
  const { postData } = useSelector((state) => state.savedPost);
  const { user } = useSelector((state) => state.currentUser);
  const optionVisibility = useState(false);
  const { hanldeGetSaved, savedData } = useSaved();
  useEffect(() => {
    hanldeGetSaved();
  }, []);
  return (
    <div className="social-saved">
      {!savedData.loading &&
        postData?.map((item) => {
          item.isOwner = item.owner.username === user.username;
          return (
            <PostCard
              key={item.id}
              {...item}
              optionVisibility={optionVisibility}
            />
          );
        })}
      {!postData.length && !savedData.loading && <NoData>No Saved Post</NoData>}
      {savedData.loading && <MultipleFeedPosts />}
    </div>
  );
};

export default Social;
