import React, { useState, useEffect, useRef } from "react";
import "./index.scss";
import {
  FaPause,
  FaPen,
  FaTrash,
  FaDollarSign,
  FaShare,
  FaEllipsisV,
  FaClock,
  FaPlay,
} from "react-icons/fa";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import qs from "query-string";

const DropdownMenu = ({
  handleShareListing,
  handleDeleteListing,
  id,
  deletedListingData,
  shareListingData,
  format = () => {},
  pauseListing = () => {},
  ...rest
}) => {
  const { pathname, search } = useLocation();
  const { user } = useSelector((state) => state.currentUser);
  const [isPage] = useState(
    rest.page.owner === user.id &&
      (pathname.toLowerCase().includes("business") ||
        pathname.toLowerCase().includes("private")) &&
      qs.parse(search).id
  );
  const data = [
    {
      id: 0,
      label: "Delete",
      icon: <FaTrash />,
      function: handleDeleteListing?.bind(this, id, { pause: "all" }),
    },
    { id: 1, label: "Edit", icon: <FaPen />, function: format },
    {
      id: 2,
      label: !rest.pause ? "Pause" : "Continue",
      icon: !rest.pause ? <FaPause /> : <FaPlay />,
      function: pauseListing.bind(
        this,
        { id, pause: !rest.pause },
        { pause: "all" }
      ),
    },
    // {
    //   id: 3,
    //   label: "Promote",
    //   icon: <FaDollarSign />,
    //   function: (() => {}).bind(),
    // },
    {
      id: 4,
      label: "Share to social",
      icon: <FaShare />,
      function: handleShareListing?.bind(this, id, isPage),
    },
    // {
    //   id: 5,
    //   label: "Extend time",
    //   icon: <FaClock />,
    //   function: (() => {}).bind(),
    // },
  ];
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const dropdownContainer = useRef(null);

  const toggleDropdown = () => setOpen(!isOpen);

  useEffect(() => {
    setOpen(false);
  }, [deletedListingData?.data, shareListingData?.data]);
  //dropdown click outside
  const handleClickOutside = (event) => {
    if (
      dropdownContainer.current &&
      !dropdownContainer.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={dropdownContainer}>
      <div className="dd-dropdown">
        <div className="dropdown">
          <FaEllipsisV className="dropdown-icon" onClick={toggleDropdown} />
        </div>
        {isOpen && (
          <div className={`dropdown-body ${isOpen && "open"}`}>
            {items.map((item) => (
              <div
                className="dropdown-item"
                key={item.id}
                onClick={item.function}>
                {item.icon}
                {item.label}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default DropdownMenu;
