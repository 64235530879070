import React, { useState, useEffect, useContext, useRef } from 'react'
import { useLocation, useHistory, Link  } from "react-router-dom";
import qs from "query-string";
import { useDispatch, useSelector  } from "react-redux"
import { toggleRightBar } from "../../../../../appstore/navigationDisplayToggle/index.action"
import {
  FaPen,
  FaArrowLeft,
} from 'react-icons/fa'
import {BsPen} from "react-icons/bs"
import {TiDeleteOutline} from "react-icons/ti"
import { FcPlus} from "react-icons/fc"
import FeedContainer from '../../../../../components/FeedContainer'
import LeftBar from '../../../../../components/LeftManageConnect'
import { FilterOptions } from './ProfileOptions'
import DropdownIndicator from '../../../../../components/cards/jobCards/FontAwesomeIndicator'
import Select from 'react-select';
import greeting from "../../../../../utils/greeting"
import ThreeDots from "../../../../../assets/svg/threeDots"
import Filter from "../Filter"
import "./index.scss"
import ModalContext from '../../../../../hooks/modalContexts'
import ExploreContext  from '../../../../../hooks/exploreContext'
import useConnect from "../../../../../hooks/connectHooks";
import usePages from "../../../../../hooks/createPage";
import CreateButton from "./CreateButton"
import MenuCard from "./MenuCard"
import CreateProfile from "./CreateProfile"
import EditProfile from "./EditProfile"
import ProFilters from "./showFilters/ProFilter"
import ToProFilters from "./showFilters/ToProFilter"
import Autocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import GoogleMapReact from "google-map-react";
import config from "../../../../../config/config.json";
import LoaderPurple from "../../../../../assets/loaders/LoaderPurple"


function ShowProfile() {
  // const [createPage, setCreatePage] = useState("create")
  const [requested, setRequested] = useState('recieved')
  const [connect, setConnect] = useState('connected')
  const [edit, setEdit] = useState('edited')
  const [toggleState, setToggleState] = useState('false')
  const [showProfile, setShowProfile] = useState("default")

// Edit Function
  const [addTag, setAddTag] = useState('false')
  const [tags, setTags] = useState([])
  const [addService, setAddService] = useState("false")
  const [locations, setLocations] = useState([])
  // const [currentProfile, setCurrentProfile] = useState()
  // const [editData, setEditData] = useState()
  const [isSubmit, setIsSubmit] = useState("false")
  const [city, setCity] = useState(null);

  
  const [serviceLocationForm, setServiceLocationForm] = useState({
    name: "",
    image: "",
    address: city,
    point: {
      type: "Point",
      coordinates: [],
    },
  });

  const [deleteLocation, setDeleteLocation] = useState()

  const {
    showPage,
    setShowPage,
    handleShowPage,
    handleCreatePage,
    handleEditPage,
    titleForm,
    setTitleForm,
    profileForm,
    setProfileForm,
    editData,
    setEditData,
    handleInputChange,
    handleEditInputChange,
    editForm,
    setEditForm,
    currentProfile,
    setCurrentProfile,
    editTitleForm,
    setEditTitleForm,
    pageId,
    setPageId,
    editSuccess,
    setEditSuccess,
    profileState,
    setProfileState,
    setLeftbarFormTitle,
  } = useContext(ExploreContext)
const { singlePageData, handleGetSinglePage } = usePages();
  const {
    createProfilePageData,
    handleGetConnectProfile,
    connectProfileData,
    singleProfileData,
    handleGetSingleProfile,
    categoryData,
    handleCategoryData,
    handleUpdateProfile,
    updateProfileData,
    handleDeleteProfile,
  } = useConnect();

  const { search } = useLocation();
  const { id } = qs.parse(search);

  const handleEditSection = () => {
    setShowProfile("editProfile")
  }

  // useEffect(() => {
  //   handleGetConnectProfile();
  //   console.log(connectProfileData?.data?.results);
  //   console.log("##############")
  // }, []);

  useEffect(() => {
    handleGetConnectProfile(id);
  }, []);

  
  useEffect(() => {
    const currentProId = connectProfileData?.data?.results?.[0]?.id
    if(currentProId){
        setCurrentProfile(currentProId)
        handleGetSingleProfile(currentProId)
        console.log(currentProId)
    }
    
  }, [connectProfileData?.data?.results?.[0]?.id])


  var initialSingleProfile = connectProfileData?.data?.results[0]
  console.log(initialSingleProfile)

//   const [eachProfile, setEachProfile] = useState(initialSingleProfile)
console.log(editForm)
console.log("=============================")
console.log(editData)

// useEffect(() => {
//   setEactProfile(initialSingleProfile)
// }, [eachProfile])
// console.log(eachProfile)

  // const handleSingleProfile = () => {
  //   initialSingleProfile = singleProfileData.data
  //     // console.log(singleProfileData?.data)
  // }


const [changeProfileData, setChangeProfileData] = useState({

})

const handleSingleProfile = () => {
    setChangeProfileData(singleProfileData.data)
}
console.log(changeProfileData)
  // useEffect(() => {
  //   setEactProfile({
  //     ...eachProfile,
  //   });
  //   handleGetConnectProfile();
  // }, [eachProfile]);

  const history = useHistory()
  const dispatch = useDispatch()
    useEffect(()=>{
        dispatch(toggleRightBar(false))
    },[])

    useEffect(() => {
        handleGetSinglePage(id)
        setPageId({
          id: id,
        })
      }, [id]);

    const toggleTab = (index) => {
      setToggleState(index)
    };
  const { handleConnectProfileModal } = useContext(ModalContext)

  const onAddressChange = (e) => {
    // setServiceInput(e.currentTarget.value);
    setServiceLocationForm({
      ...serviceLocationForm,
      address: e,
    });
  };

  const handleKeyDown = (e) => {
    if(e.key !== "Enter") return
    const value = e.target.value
    if(!value.trim()) return
    // setTags([...tags, value])
    setEditData({
      ...editData,
      tags: [...editData.tags, value]
    })
    setAddTag("false")
  }

  const handleKeyDownLocation = (e) => {
    // if(e.key !== "Enter") return
    // const value = e.target.value
    // if(!value.trim()) return
    // setLocations([...locations, serviceLocationForm])
  setEditData({
      ...editData,
      service_locations: [...editData.service_locations, serviceLocationForm]
    })

    // setEditData({
    //   ...editData,
    //   service_locations: [...editData.service_locations, serviceLocationForm]
    // })

    // setAddService("false")
    // setServiceInput("")
  }

  const removeTag = (index) => {
    setTags(tags.filter((el, i) => i !== index))
    // setTags(editData?.tags.filter((el, i) => i !== index))
    setAddTag("false")
  }

  const removeTagLocation = (index) => {
    setLocations(locations.filter((el, i) => i !== index))
    // setLocations(editData?.service_locations.filter((el, i) => i !== index))
    setAddService("false")
  }

const handleDeleteLocation = (index) => {
  setEditData({
    ...editData,
    service_locations: editData.service_locations.filter((el, i) => i !== index)
  })
}

const handleDeleteTags = (index) => {
  setEditData({
    ...editData,
    tags: editData.tags.filter((el, i) => i !== index)
  })

}


  console.log("EditData Location", editData?.service_locations)
  console.log(locations)

  console.log("EditData Tags", editData?.tags)
  console.log(tags)


  const [pop, setPop] = useState(false)
  const dropdownContainer = useRef(null);
  const toggleDropdown = () => setPop(!pop);
  //dropdown click outside
  const handleClickOutside = (event) => {
    if (
      dropdownContainer.current &&
      !dropdownContainer.current.contains(event.target)
    ) {
      setPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });


    useEffect(() => {
      if (createProfilePageData.data) {
        console.log("==============Profile Data=========")
        console.log(createProfilePageData?.data)
      }
    }, [createProfilePageData]);

  // useEffect(()=>{
  //   console.log("==============Profile Data=========")
  //   console.log(createProfilePageData?.data?.results)
  // },[])

  useEffect(() => {
    if (singleProfileData.data) {
      // console.log(singleProfileData.data.title);
      setEditForm({
        ...editForm,
        title: titleForm.title,
        page: id,
        service_locations:[
          "",
          "",
          {
              name:"Rustic Soft Bacon",
              image:"",
              address:"229 Bogan Turnpike",
              point:{
                  type: "Point",
                  coordinates: [
                  -82.8583,
                  56.0887
                  ]
              }
          }
        ],
        business_category:{
            name:""
        },
        connect_to_category:{
          name:""
        },
        looking_for: singleProfileData?.data?.looking_for,
        description: singleProfileData?.data?.description,
        tags: singleProfileData?.data?.tags
      })
    }
  }, [singleProfileData.data]);


  console.log(connectProfileData?.data?.results);
  console.log("##############")

  // useEffect(() => {
  //   if(connectProfileData?.data?.results.length){
  //     setProfileState(profile => [...profile, ...connectProfileData?.data?.results])
  //   }
  // }, [connectProfileData.data])

  console.log("profile form ========", profileForm)
  // console.log("singleProfileData?.data?.connect_to_category", singleProfileData?.data?.connect_to_category)
  // TitleModal
  {/*const [title, setTitle] = titleList;*/}
  const [title, setTitle] = useState("")
  const {
    postProfileData,
    handlePostConnectProfile,
    handleCreateProfilePage,
  } = useConnect();
  const { user } = useSelector((state) => state.currentUser);

  const [serviceInput, setServiceInput] = useState("")
  const [tagsInput, setTagsInput] = useState("")


  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setEditForm({ ...editForm, [name]: value });
  //   setEditData({ ...editData, [name]: value });
  // };

  const handleTagsChange = e => {
      setTagsInput(e.currentTarget.value);

    }

  const handleServiceChange = (e) => {
    setServiceInput(e.currentTarget.value);
    setEditForm({
      ...editForm,
      service_locations:[...locations, locations],
    })
  };

  useEffect(() => {
    setEditForm({
      ...editForm,
      title: titleForm.title,
    });
    handleCategoryData();
  }, [title]);

  useEffect(() => {
  console.log(singleProfileData)
  }, []);

  useEffect(() => {
  if(singleProfileData?.data){
    setEditData({
      business_category: {
          name: singleProfileData?.data?.business_category?.name,
      },
      connect_to_category:{
        name: singleProfileData?.data?.connect_to_category?.name,
      },
      // connect_to_category:"",
      looking_for: singleProfileData?.data?.looking_for,
      description: singleProfileData?.data?.description,
      tags: singleProfileData?.data?.tags,
      service_locations: singleProfileData?.data?.service_locations,
      title: singleProfileData?.data?.title,
    })
  }
  console.log(editData)

}, [singleProfileData?.data])

useEffect(() => {
  setEditData()

}, [currentProfile])

  const submitFormData = (e) => {
    e.preventDefault();
    handleUpdateProfile(singleProfileData?.data.id, editData)

    // setEditSuccess(updateProfileData.data)
  };

// useEffect(() => {
//   if(updateProfileData.data && currentProfile){
//     setShowProfile("default")
//     setCurrentProfile(connectProfileData?.data?.results?.[0]?.id)
//   }
//
// }, [updateProfileData.data])
const handleDelete = () => {
  handleDeleteProfile(singleProfileData.data.id)

}

  const handleLocationInputChange = (idx, e) => {
      const newFormValues = [...locations];
      newFormValues[idx][e.target.name] = e.target.value;
      setLocations(newFormValues);
  };

// useEffect(() => {
//   showProfile("default")
// }, [editSuccess])

// useEffect(() => {
//   console.log("CURRENT USER")
//   console.log(user)
// }, [])


// INITIAL LOCATION MAPPING

// {locations.map((location, index) => (
//   <div className="tag-item" key={index}>
//     <span className="text">{location.address}</span>
//     <span className="close" onClick={() => removeTagLocation(index)}><TiDeleteOutline/></span>
//   </div>
// ))}

// Setting The Left Bar Title to False (LeftbarForm Title is before submitting)
useEffect(() => {
  setLeftbarFormTitle(false)
}, [currentProfile])

  return (
    <>
    {showPage === "show" &&
    <FeedContainer>
      <section className='profile-connect'>
      <div className='welcome-back'>
        <Link
            onClick={history.goBack}
            className='back-btn'
                >
            <FaArrowLeft />
        </Link>
         <h1 className='connect-greeting'>
          {greeting()} <span className="user">{singlePageData?.data?.name?.split(" ")[0]} </span>
        </h1>
      </div>
        <section className='profile-container'>
          <div className='profile-top'>
            <div>
              <h2>Create and manage your connect profile</h2>
              <p>So business that may need your services can find you</p>
            </div>
            <div>
                <CreateButton />
            </div>
          </div>
            <section className='profile_section'>
              <section className='profile__flex'>
                <LeftBar
                  handleSingleProfile={handleSingleProfile}
                  connectProfileData={connectProfileData}
                  handleGetSingleProfile={handleGetSingleProfile}
                  singleProfileData={singleProfileData}
                  profileForm={profileForm}
                  setCurrentProfile={setCurrentProfile}
                  currentProfile={currentProfile}
                  editData={editData}
                  editForm={editForm}
                  titleForm={titleForm}
                  />

                {showProfile === "default" &&
                <div className='profile__flex-right'>
                  {editData ?
                    <>
                  <div className="top_item">
                    <div className="left_item">
                      <h4
                        >Business category</h4>
                      <div className='profile_filter'>
                      <h5> {singleProfileData?.data?.title ? singleProfileData?.data?.title :
                          initialSingleProfile ? initialSingleProfile.title : ""} </h5>
                      </div>
                    </div>
                    <div onClick={handleEditSection}>
                       <FaPen className="pen"/>
                    </div>
                    </div>
                    <div className="service_location">
                      <h2>Service Locations</h2>
                        <ul className="service">
                          {singleProfileData?.data?.service_locations ? singleProfileData?.data?.service_locations?.map((location) => (
                          <li>
                            {location.address}
                          </li>
                        )) : initialSingleProfile ? initialSingleProfile.service_locations.map((initLocation) => (
                          <li>
                            {initLocation.address}
                          </li>
                        )) : ""
                          }
                        </ul>

                    </div>
                    <div className="bus_category">
                      <div className="bus_left">
                        <h3>Select the business category you wish to connect with</h3>
                        <p>{singleProfileData?.data?.connect_to_category?.name ? singleProfileData?.data?.connect_to_category?.name :
                            initialSingleProfile ? initialSingleProfile.connect_to_category?.name : ""}</p>
                      </div>
                      <div className="bus_right">
                        <h3>What are you looking for</h3>
                        <p>{singleProfileData?.data?.looking_for ? singleProfileData?.data?.looking_for :
                            initialSingleProfile ? initialSingleProfile.looking_for : ""}</p>
                      </div>
                    </div>
                    <div className="describe">
                      <h2>Description</h2>
                      <div>
                        {singleProfileData?.data?.description ? singleProfileData?.data?.description :
                            initialSingleProfile ? initialSingleProfile.description : ""}
                      </div>
                    </div>
                    <div className="service_location">
                      <h2>Tags</h2>
                        <ul className="locations">
                          {singleProfileData?.data?.tags ? singleProfileData?.data?.tags?.map((tag) => (
                          <li>
                            {tag}
                          </li>
                        )) : initialSingleProfile ? initialSingleProfile.tags.map((initag) => (
                          <li>
                            {initag}
                          </li>
                        )) : ""
                          }
                        </ul>
                     </div>
                      </>
                      : !editData ? "" : <LoaderPurple />
                     }
                    </div>

                  }

                  {showProfile === "editProfile" && editData &&
                    <form className='profile__flex-right' onSubmit={submitFormData} >
                      <div className="top_item">
                        <div className="left_item">
                          <h4>Select your business category</h4>
                          <div className='edit_profile_filter'>
                            <ProFilters
                              profileForm={profileForm}
                              categoryData={categoryData}
                              handleCategoryData={handleCategoryData}
                              setProfileForm={setProfileForm}
                              singleProfileData={singleProfileData}
                              initialSingleProfile={initialSingleProfile}
                              editData={editData}
                              setEditData={setEditData}
                              className="dropdown"
                            />
                          </div>
                        </div>
                        <MenuCard
                          handleDelete={handleDelete}
                          handleClickOutside={handleClickOutside}
                          dropdownContainer={dropdownContainer}
                          toggleDropdown={toggleDropdown}
                          pop={pop}
                          setPop={setPop}
                          />
                        </div>
                        <div className="tag-container">
                          <h2>Service Locations</h2>
                          <div className="tag-input-container">
                            {editData?.service_locations?.map((location, index) => (
                              <div className="tag-item" key={index}>
                                 <span className="text">{location.address}</span>
                                  <span className="close"
                                    onClick={() =>{
                                      handleDeleteLocation(index);
                                      removeTagLocation(index);
                                    }}
                                    >
                                    <TiDeleteOutline/></span>
                              </div>
                            ))}
                            </div>
                            <div className="more-location">
                              {/*<h2>Add more locations</h2>*/}
                            {addService === "true" ? null :
                            <FcPlus className="plus"
                              onClick={() =>(
                                setAddService("true")
                              )}
                              />
                            }
                            </div>
                            {addService === "true" &&
                              <div className="tal-set-location">
                                <Autocomplete
                                  className="tags-input"
                                  onKeyDown={handleKeyDownLocation}
                                  apiKey={config["map-key"]}
                                  selectProps={{
                                    city,
                                    onChange: async ({ label }) => {
                                      onAddressChange(label);
                                      console.log(label);
                                      const data = await geocodeByAddress(label);
                                      const { lat, lng } = await getLatLng(data[0]);
                                      setServiceLocationForm({
                                        ...serviceLocationForm,
                                        name: "",
                                        image: "",
                                        address: label,
                                        point: {
                                          type: "Point",
                                          coordinates: [lat, lng],
                                        },
                                      });
                                      console.log(data);
                                    },
                                    setCity,
                                  }}
                                  onLoadFailed={(error) =>
                                    console.error("Could not inject Google script", error)
                                  }
                                />
                                <FcPlus className="plus"
                                  onClick={() =>(
                                    handleKeyDownLocation()
                                  )}
                                  />
                            </div>
                              // <input
                              //   onChange={handleServiceChange}
                              //   value={serviceInput}
                              //   name="service_locations"
                              //    type="text"
                              //    className="tags-input"
                              //    onKeyDown={handleKeyDownLocation}
                              //    placeholder="Add a service location" />
                            }
                          </div>


                        <div className="bus_category">
                          <div className="bus_left">
                            <h3>Select the business category you wish to connect with</h3>
                              <div className='category-select'>
                              {/*<ProFilters className="dropdown"/>*/}
                                <ToProFilters
                                  profileForm={profileForm}
                                  categoryData={categoryData}
                                  handleCategoryData={handleCategoryData}
                                  setProfileForm={setProfileForm}
                                  singleProfileData={singleProfileData}
                                  initialSingleProfile={initialSingleProfile}
                                  editData={editData}
                                  setEditData={setEditData}
                                  className="dropdown"
                                />
                              </div>
                          </div>
                          <div className="bus_right">
                            <h3>What are you looking for</h3>
                            <input
                              name="looking_for"
                              type="text"
                              value={editData.looking_for}
                              onChange={handleEditInputChange}
                              placeholder="Pof"
                              />
                          </div>
                        </div>
                        <div className="describe">
                          <h2>Describe</h2>
                          <textarea
                            name="description"
                            type="text"
                            value={editData.description}
                            onChange={handleEditInputChange}
                            placeholder="is simply dummy text of the printing and typesetting industry.
                            Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                            when an unknown printer took a galley of type and scrambled it to make a type
                            specimen book.">
                          </textarea>
                        </div>
                        <div className="tag-container">
                            <div className="service_location">
                              <h2>Tags</h2>
                                <ul className="service">
                                  {editData?.tags?.map((tag, index) => (
                                  <li>
                                    {tag}
                                    <span className="close"
                                      onClick={() => {
                                        removeTag(index)
                                        handleDeleteTags(index)
                                      }}
                                      >
                                      <TiDeleteOutline/></span>
                                  </li>
                                ))}
                                </ul>
                             </div>
                               {/*<h2>Add more tags</h2>*/}
                          <div className="tag-input-container">

                            {addTag === "true" ? null :
                            <FcPlus className="plus"
                              onClick={() =>(
                                setAddTag("true")
                              )}
                              />
                            }
                            {addTag === "true" &&
                              <input
                                onChange={handleTagsChange}
                                value={tagsInput}
                                type="text"
                                name="connect_to_category"
                                className="tags-input" onKeyDown={handleKeyDown} placeholder="Add a tag" />
                            }
                          </div>
                        </div>
                           <button type='submit' className="save-button">Save</button>
                      </form>
                    }
                </section>
              </section>
            </section>
          </section>
        </FeedContainer>
       }
    </>
  )
}

export default ShowProfile
