import React, {useEffect, useState, useRef} from "react";
import "./exploreDropdown.scss";

const ExploreDropdown = (props) =>  {
    const [showMenu, setShowMenu] = useState(false);
    const ref = useRef();

    useEffect(() => {
        const checkIfClickedOutside = e => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (showMenu && ref.current && !ref.current.contains(e.target)) {
                setShowMenu(false)
            }
        }

        document.addEventListener("mousedown", checkIfClickedOutside)

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside)
        }
    }, [showMenu])

    return (
        <>
            <div className="dropdown-box" ref={ref}>
                <div className="trigger-div" onClick={() => setShowMenu(!showMenu)}>
                    {props.headerContent}

                    {
                        showMenu &&
                        <div className='list-box'>
                            {
                                showMenu &&
                                props.menuContent
                            }
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default ExploreDropdown;