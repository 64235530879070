import useAxios from "axios-hooks";
import { useDispatch } from "react-redux";
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";

const useBookingRequest = () => {
  // const [allBookings, setAllBookings] = useState([]);
  // const [fetchedData, setFetchedData] = useState([]);
  // useEffect(() => {
  //   const getData = async () => {
  //     const data = await axios.get("/bookings");
  //     setFetchedData(data);
  //   };
  //   getData();
  // }, []);

  const [allBookings, setAllBookings] = useState([]);
  const addBookings = (service) => {
    setAllBookings((prevState) => [...prevState, { service }]);
  };

  //
  //
  //
  //GET Bookings
  const [{ ...bookingsData }, getBookings] = useAxios(
    {
      url: `/bookings/`,
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetBookings = async () => {
    await getBookings({
      params: {
        sudo: true,
      },
    });
  };

  //
  //
  //
  //
  //
  // PUT update booking status Accept
  const [{ ...updateBookingStatusData }, updateBookingStatus] = useAxios(
    {
      method: "PUT",
    },
    {
      manual: true,
    }
  );

  const handleUpdateBookingAcceptStatus = async (id) => {
    await updateBookingStatus({
      url: `/bookings/${id}/update_booking_status/`,
      data: {
        status: "ACCEPT",
      },
    }).catch((error) => console.log(error));
  };

  // PUT update booking status Declined
  const handleUpdateBookingDeclineStatus = async (id) => {
    await updateBookingStatus({
      url: `/bookings/${id}/update_booking_status/`,
      data: {
        status: "DECLIEND",
      },
    }).catch((error) => console.log(error));
  };

  //
  //
  //
  //GET Booking Request
  const [{ ...bookingRequestData }, getBookingRequest] = useAxios(
    {
      url: `/bookings/get_booking_request`,
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetBookingRequest = async () => {
    await getBookingRequest();
  };

  // useEffect(() => {
  //   if (bookingsData.data) {
  //     addBookings(bookingsData);
  //     console.log(bookingsData.data.results);
  //   }
  //   // console.log(bookingsData.data);
  // }, [bookingsData.data]);

  /////////////////////BOOKING////////////////////
  //
  //
  //
  //
  //POST CREATE BOOKING REQUEST
  const [{ ...createBookingRequestData }, createBookingRequest] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleCreateBookingRequest = async (data) => {
    try {
      await createBookingRequest({
        url: "/bookings/create_booking_request/",
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //PATCH ACCEPT BOOKING REQUEST
  const [{ ...BookingRequestData }, BookingRequest] = useAxios(
    {
      // url: "/services",
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleAcceptBookingRequest = async (id) => {
    await BookingRequest({
      url: `/bookings/${id}/update_booking_request/`,
      data: {
        status: "ACCEPT",
      },
    }).catch((error) => console.log(error));
  };

  //
  //
  //PATCH DECLINE BOOKING REQUEST
  const handleDeclineBookingRequest = async (id) => {
    await BookingRequest({
      url: `/bookings/${id}/update_booking_request/`,
      data: {
        status: "DECLINE",
      },
    }).catch((error) => console.log(error));
  };

  return {
    handleGetBookings,
    bookingsData,
    allBookings,
    BookingRequestData,
    handleAcceptBookingRequest,
    handleDeclineBookingRequest,
    handleCreateBookingRequest,
    createBookingRequestData,
    handleUpdateBookingAcceptStatus,
    updateBookingStatusData,
    handleGetBookingRequest,
    bookingRequestData,
    handleUpdateBookingDeclineStatus,
  };
};

export default useBookingRequest;
