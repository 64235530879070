import {
  UPDATE_REPLY_LIKES,
  INIT_REPLIES,
  ADD_REPLIES,
  BULK_INSERT_REPLIES,
  ADD_DIRECT_REPLIES_TO_COMMENT,
  CLEAR_REPLIES,
} from "./repliesV2.types";

const defaultReplies = [];

const repliesV2 = (state = defaultReplies, { type, payload }) => {
  switch (type) {
    case INIT_REPLIES:
      return payload;
    case UPDATE_REPLY_LIKES:
      return state.map((reply) => {
        // return reply.id === payload.id
        //   ? {
        //       ...reply,
        //       likes: payload.count,
        //       has_like: payload.status,
        //     }
        //   : reply;

        //check which payload belong to, both for child and direct replies to comments
        return {
          ...reply,
          likes: payload.id === reply.id ? payload.count : reply.likes,
          has_like: payload.id === reply.id ? payload.status : reply.has_like,
          replies: reply.replies.map((child) => {
            return child.id === payload.id
              ? {
                  ...child,
                  likes: payload.count,
                  has_like: payload.status,
                }
              : child;
          }),
        };
      });
    case ADD_REPLIES:
      //check if reply contains id = reply_to of payload and either make child of reply or make a fresh reply
      return state.map((reply) => {
        if (reply.id === payload.reply_to) {
          return {
            ...reply,
            replies: [payload, ...reply.replies],
          };
        } else return reply;
      });
    case ADD_DIRECT_REPLIES_TO_COMMENT:
      const hasChild = state.filter(
        (reply) => reply.id === payload.reply_to && reply
      );
      return hasChild.length ? state : [payload, ...state];
    // return [payload, ...state];
    case BULK_INSERT_REPLIES:
      return [...payload, ...state];
    case CLEAR_REPLIES:
      return [];
    default:
      return state;
  }
};

export default repliesV2;
