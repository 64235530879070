import React from "react";
import { dummyData, Sort } from "./products";
import { Row, Col } from "reactstrap";
import CustomCard from "./constants";
import { colors } from "../../../color";
import { DropDownBtn } from "./products";
import { ExpandMore } from "@material-ui/icons";
import { DropdownToggle } from "reactstrap";

class Jobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Col>
        <Col xl={5}>
          <div className="d-flex justify-content-between align-items-cener">
            <Sort>Sort</Sort>
            <DropDownBtn>
              <DropdownToggle
                style={{
                  width: "100%",
                  background: "transparent",
                  height: "100%",
                }}
                className="d-flex justify-content-between align-items-center"
              >
                <p style={{ color: `${colors.COMPANY_COLOR}`, margin: "auto" }}>
                  Price Low-High
                </p>{" "}
                <ExpandMore style={{ color: `${colors.ORANGE}` }} />
              </DropdownToggle>
            </DropDownBtn>
          </div>
        </Col>
        <Row>
          {dummyData.map((data, i) =>
            data.type === "job" ? (
              <Col className="mt-4" xl={4} key={i}>
                <CustomCard
                  picture={data.picture}
                  seller={data.seller}
                  type={data.type}
                  discount={data.discount}
                  price={data.price}
                  description={data.description}
                  location={data.location}
                />
              </Col>
            ) : null
          )}
        </Row>
      </Col>
    );
  }
}

export default Jobs;
