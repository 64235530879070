import useAxios from "axios-hooks";
import { useState } from "react";
import useUploads from "./uploads";

const useCreateListings = () => {
  const { handleUploads } = useUploads();
  const [loading, setLoading] = useState();
  const [{ ...createListingData }, createListing] = useAxios(
    {
      url: "/products/",
      method: "post",
    },
    {
      manual: true,
    }
  );

  const handleCreateListing = async ({ data, images }) => {
    setLoading(true);
    const body = data;
    // console.log(data);
    try {
      const { data } = await handleUploads(images);
      const { results } = data;
      const gallary = results.map((data) => data.id);

      createListing({
        data: {
          ...body,
          gallary,
        },
      });

      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const [{ ...updateListingData }, updateListing] = useAxios(
    {
      method: "patch",
    },
    {
      manual: true,
    }
  );

  const handleUpdateListing = async (data, param = {}) => {
    updateListing({
      url: `/products/${data.id}/`,
      data,
      params: {
        ...param,
      },
    });
  };
  const [{ ...updateStockData }, updateStock] = useAxios(
    {
      method: "patch",
    },
    {
      manual: true,
    }
  );

  const handleUpdateStock = async (data) => {
    return await updateStock({
      url: `/stocks/${data.tempId}/`,
      data,
    });
  };

  const [{ ...createStockData }, createStock] = useAxios(
    {
      method: "post",
      url: "/stocks/",
    },
    {
      manual: true,
    }
  );

  const handleCreateStock = async (data) => {
    return await createStock({
      data,
    });
  };
  return {
    handleCreateListing,
    loading,
    createListingData,
    handleUpdateListing,
    updateListingData,
    handleUpdateStock,
    updateStockData,
    handleCreateStock,
    createStockData,
  };
};
export default useCreateListings;
