import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../hooks/modalContexts'
import './index.scss'
import { FaTimes,} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all";
import SuccessCheck from "../../../../../assets/svg/successThick"
import useConnect from "../../../../../hooks/connectHooks"
import ExploreContext  from '../../../../../hooks/exploreContext'

const DeclineConnectModalCard = () => {
    const {handleDeclineConnectModalCard} = useContext(ModalContext)

    const {
        handleGetConnectProfileSuggested,
        handleGetConnectRequestConnectedTrue,
        handleGetConnectRequestReceivedTrue,
      } = useConnect();

      const {
        currentProfile,
        successData,
        setSuccessData,
        acceptSuccessModal, 
        setAcceptSuccessModal,
        connectHomeLoad,
        setConnectHomeLoad,
        acceptColor, 
        setAcceptColor,
      } = useContext(ExploreContext)

      // useEffect(() => {
      //   if(connectHomeLoad === true){
      //     // window.location.reload(false);
      //     handleGetConnectRequestConnectedTrue();
      //     handleGetConnectRequestReceivedTrue();
      //   }
      //   }, [connectHomeLoad])
    
        useEffect(() => {
          setConnectHomeLoad(false)
        }, [])

    return(
      <div
      onClick={()=>{handleDeclineConnectModalCard('RESET_DECLINE_CONNECTCARD_TO_DISPLAY')}}
      className="successModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="successModalContainer"
       >
          <div
          onClick={()=>{handleDeclineConnectModalCard('RESET_DECLINE_CONNECTCARD_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
          <div className="enter-success">
            <SuccessCheck/>
          </div>
          <div className="success-text">
            You have declined the connect
          </div>
          <div className="success-text">Successfully </div>

            <div className='success-save-button'>
              <button
                onClick={()=>{
                  handleDeclineConnectModalCard('RESET_DECLINE_CONNECTCARD_TO_DISPLAY');
                //   setSuccessData(currentProfile);
                  console.log(successData)
                }}
              >Done</button>
            </div>
          </div>
      </div>
    )
}
export default DeclineConnectModalCard
