import useAxios from "axios-hooks";

const useTypes = () => {
  const [{ ...types }, getTypes] = useAxios({ method: "GET" });

  const handleGetTypes = async (type) => {
    return await getTypes({
      url: `/utils/types/${type}`,
    });
  };

  return {
    handleGetTypes,
    types,
  };
};
export default useTypes;
