import { createContext, useState } from "react";

const ExploreContext = createContext();

export function ExploreProvider({children}) {
  const [allListings, setAllListings] = useState([]);

  const [resetAllListings, setResetAllListings] = useState(false);

  const [personnelTimesLoading, setPersonnelTimesLoading] = useState(false);

  const [allProducts, setAllProducts] = useState([]);
  const addProducts = (product) => {
      setAllProducts((prevState => [...prevState, {product}]));
  };

  const [selectedProduct, setSelectedProduct] = useState([]);
  const addSelectedProduct = (product) => {
      setSelectedProduct(product);
  }

  const [allServices, setAllServices] = useState([]);
  const addServices = (service) => {
      setAllServices((prevState => [...prevState, {service}]));
  };

  const [selectedService, setSelectedService] = useState([]);
  const addSelectedService = (service) => {
      setSelectedService(service);
  }
  const [serviceDataLoading, setServiceDataLoading] = useState(null);

  const [pageServices, setPageServices] = useState([]);

  const [serviceLocations, setServiceLocations] = useState([]);
  const addServiceLocations = (locations) => {
      setServiceLocations(locations);
  }

  const [personnelDates, setPersonnelDates] = useState([]);

  const [allJobPosts, setAllJobPosts] = useState([]);
  const addJobPosts = (jobPost) => {
      console.log(jobPost);
      setAllJobPosts((prevState => [...prevState, {jobPost}]));
  };
  const [jobPostsLoading, setJobPostsLoading] = useState(false);

  const [selectedJobPost, setSelectedJobPost] = useState([]);
  const addSelectedJobPost = (jobPost) => {
      setSelectedJobPost(jobPost);
  };
  const [jobPostDataLoading, setJobPostDataLoading] = useState(false);

  const [jobApplicationFiles, setJobApplicationFiles] = useState([]);
  const addJobApplicationFile = (file) => {
      let oldFiles = jobApplicationFiles;
      oldFiles.push(file);
      setJobApplicationFiles(oldFiles)
  }
  const clearJobApplicationFiles = () => {
      setJobApplicationFiles([]);
  }
  const [createJobLoading, setCreateJobLoading] = useState(false);
  const [jobApplyLoading, setJobApplyLoading] = useState(false);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobPostApplicationsLoading, setJobPostApplicationsLoading] = useState(false);
  const [applicationSaveLoading, setApplicationSaveLoading] = useState(false);
  const [fetchAllListingsLoading, setFetchAllListingsLoading] = useState(false);

  const [jobPostImage, setJobPostImage] = useState([]);

  const [jobApplicationContactMode, setJobApplicationContactMode] = useState(null);
  const [jobApplicationContactModeNumber, setJobApplicationContactModeNumber] = useState(null);
  const [jobApplicationContactModeMail, setJobApplicationContactModeMail] = useState(null);

  const [jobTypes, setJobTypes] = useState([]);
  const addJobTypes = (types) =>  {
      setJobTypes(types.data);
  }

  const [jobNotifications, setJobNotifications] = useState([]);
  const addJobNotifications = (notifications) => {
      setJobNotifications(notifications);
  }

  const [myJobPosts, setMyJobPosts] = useState([]);
  const addMyJobPosts = (posts) => {
      setMyJobPosts(posts);
  }

  const [receivedApplications, setReceivedApplications] = useState([]);

  const [jobPostApplications, setAllJobPostApplications] = useState();

  const [jobDeleteComplete, setJobDeleteComplete] = useState(false);

  const [jobCreateComplete, setJobCreateComplete] = useState(false);

  const [requestedQueue, setRequestedQueue] = useState();

  const [queueCheckout, setQueueCheckout] = useState();

  const [queueOrders, setQueueOrders] = useState();

  const [queueRequests, setQueueRequests] = useState();

  const [queueHistory, setQueueHistory] = useState();

  const [createQueueLoading, setCreateQueueLoading] = useState();

  const [newQueueListings, setNewQueueListings] = useState();

  const [newQueueImage, setNewQueueImage] = useState([]);

  const [queueJoined, setQueueJoined] = useState(false);


  // Create Profile Page
  const [showPage, setShowPage] = useState("show")

  const handleShowPage = () => {
    setShowPage("show")
  }

  const handleEditPage = () => {
    setShowPage("edit")
  }
  const handleCreatePage = () => {
    setShowPage("create")
  }

  //   Title Modal
  const initialTitleState = {
    title: ""
  }
  const initialPageId = {
    id: ""
  }
  const [titleForm, setTitleForm] = useState(initialTitleState)
  const [editTitleForm, setEditTitleForm] = useState(initialTitleState)

  const [editData, setEditData] = useState()

  const initialEditForm = {
    title: titleForm.title,
    page: "",
    service_locations:[],
    business_category:{
        name:""
    },
    connect_to_category:{
      name:""
    },
    looking_for: "",
    description: "",
    tags:[]
  }
  const [editForm, setEditForm] = useState(initialEditForm);
  const [pageId, setPageId] = useState(initialPageId)

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setTitleForm({ ...titleForm, [name]: value });
    setEditData({ ...editData, [name]: value });
  };

  const handleEditInputChange = (event) => {
    const { name, value } = event.target;
    setTitleForm({ ...titleForm, [name]: value });
    setEditForm({ ...editForm, [name]: value });
    setEditData({ ...editData, [name]: value });
  };
  //   Profile Form State

  const initialFormState = {
    title: titleForm.title,
    page: pageId.id,
    service_locations:[],
    business_category:{
        name:""
    },
    connect_to_category:{
      name:""
    },
    looking_for:"",
    description:"",
    tags:[]
  }

  const [getQueueListComplete, setGetQueueListComplete] = useState(false);

  const [profileForm, setProfileForm] = useState(initialFormState);
  const [requested, setRequested] = useState('recieved')
  const [connect, setConnect] = useState('connected')
  const [currentProfile, setCurrentProfile] = useState()
  const [modalData, setModalData] = useState()
  const [acceptModalData, setAcceptModalData] = useState()
  const [connectFrom, setConnectFrom] = useState()
  const [connectTo, setConnectTo] = useState()
  const [retractModalData, setRetractModalData] = useState()
  const serviceLocationList = useState([]);
  const [messageData, setMessageData] = useState()
  const [rectractedCard, setRectractedCard] = useState()
  const [acceptedCard, setAcceptedCard] = useState()
  const [currentId, setCurrentId] = useState('false')
  const [successData, setSuccessData] = useState()
  const [editSuccess, setEditSuccess] = useState()
  const [acceptSuccessModal, setAcceptSuccessModal] = useState()

  // Filter this.state
  const [filterParam, setFilterParam] = useState(["All"]);

  // Manage States
  const [suggested, setSuggested] = useState([])
  const [connectedState, setConnectedState] = useState([])
  const [recievedState, setRecievedState] = useState([])
  const [sentState, setSentState] = useState([])
  const [favoriteState, setFavoriteState] = useState([])
  const [profileState, setProfileState] = useState()
  const [likeSuggestedCard, setLikeSuggestedCard] = useState()
  const [declinedCard, setDeclinedCard] = useState()
  const [searchInput, setSearchInput] = useState('')
  const [filteredResults, setFilteredResults] = useState([])
  const [leftbarFormTitle, setLeftbarFormTitle] = useState(false)
  const [editTitleSave, setEditTitleSave] = useState()

   const [startDate, setStartDate] = useState(new Date());
   const [endDate, setEndDate] = useState(new Date());

   const [requestReceived, setRequestReceived] = useState()
   const [requestConnectedHome, setRequestConnectedHome] = useState()
   const [requestConnected, setRequestConnected] = useState([])
   const [requestSent, setRequestSent] = useState()
   const [connectHomeLoad, setConnectHomeLoad] = useState(false)
   const [connectManageLoad, setConnectManageLoad] = useState(false)
   const [messageSentData, setMessageSentData] = useState()
   const [acceptColor, setAcceptColor] = useState("connect-accept")

   const initialSendConnectState = {
     connect_profile: currentProfile,
     page: connectTo,
     note: ""
 }
 const [sendConnect, setSendConnect] = useState(initialSendConnectState)
 const [requestSentFromSuggest, setRequestSentFromSuggest] = useState()
 const [retractSuccessModal, setRetractSuccessModal] = useState(false)

  return (
    <ExploreContext.Provider value={{

      allListings,
      setAllListings,
      resetAllListings,
      setResetAllListings,
      personnelTimesLoading,
      setPersonnelTimesLoading,
      allProducts,
      addProducts,
      selectedProduct,
      addSelectedProduct,
      allServices,
      addServices,
      selectedService,
      addSelectedService,
      serviceDataLoading,
      setServiceDataLoading,
      allJobPosts,
      setAllJobPosts,
      addJobPosts,
      jobPostsLoading,
      setJobPostsLoading,
      jobPostDataLoading,
      setJobPostDataLoading,
      jobApplyLoading,
      setJobApplyLoading,
      jobsLoading,
      setJobsLoading,
      jobPostApplicationsLoading,
      setJobPostApplicationsLoading,
      applicationSaveLoading,
      setApplicationSaveLoading,
      fetchAllListingsLoading,
      setFetchAllListingsLoading,
      selectedJobPost,
      addSelectedJobPost,
      jobApplicationFiles,
      addJobApplicationFile,
      clearJobApplicationFiles,
      jobApplicationContactMode,
      setJobApplicationContactMode,
      jobApplicationContactModeNumber,
      setJobApplicationContactModeNumber,
      jobApplicationContactModeMail,
      setJobApplicationContactModeMail,
      jobPostImage,
      setJobPostImage,
      jobTypes,
      addJobTypes,
      pageServices,
      setPageServices,
      serviceLocations,
      addServiceLocations,
      personnelDates,
      setPersonnelDates,
      jobNotifications,
      addJobNotifications,
      myJobPosts,
      addMyJobPosts,
      receivedApplications,
      setReceivedApplications,
      jobPostApplications,
      setAllJobPostApplications,
      createJobLoading,
      setCreateJobLoading,
      jobDeleteComplete,
      setJobDeleteComplete,
      jobCreateComplete,
      setJobCreateComplete,
      showPage,
      setShowPage,
      handleShowPage,
      handleCreatePage,
      handleEditPage,
      handleInputChange,
      titleForm,
      setTitleForm,
      profileForm,
      setProfileForm,
      requested,
      connect,
      setRequested,
      setConnect,
      editData,
      setEditData,
      editForm,
      setEditForm,
      handleEditInputChange,
      currentProfile,
      setCurrentProfile,
      editTitleForm,
      setEditTitleForm,
      modalData,
      setModalData,
      pageId,
      setPageId,
      acceptModalData,
      setAcceptModalData,
      connectFrom,
      setConnectFrom,
      connectTo,
      setConnectTo,
      retractModalData,
      setRetractModalData,
      serviceLocationList,
      messageData,
      setMessageData,
      rectractedCard,
      setRectractedCard,
      acceptedCard,
      setAcceptedCard,
      currentId,
      setCurrentId,
      successData,
      setSuccessData,
      editSuccess,
      setEditSuccess,
      filterParam,
      setFilterParam,
      suggested,
      setSuggested,
      connectedState,
      setConnectedState,
      recievedState,
      setRecievedState,
      sentState,
      setSentState,
      favoriteState,
      setFavoriteState,
      profileState,
      setProfileState,
      likeSuggestedCard,
      setLikeSuggestedCard,
      declinedCard,
      setDeclinedCard,
      searchInput,
      setSearchInput,
      filteredResults,
      setFilteredResults,
      leftbarFormTitle,
      setLeftbarFormTitle,
      editTitleSave, 
      setEditTitleSave,
      getQueueListComplete,
      setGetQueueListComplete,

      requestedQueue, 
      setRequestedQueue,
      queueCheckout,
      setQueueCheckout,
      queueOrders,
      setQueueOrders,
      queueRequests,
      setQueueRequests,
      queueHistory,
      setQueueHistory,
      createQueueLoading,
      setCreateQueueLoading,
      newQueueListings,
      setNewQueueListings,
      newQueueImage,
      setNewQueueImage,
      queueJoined,
      setQueueJoined,

        acceptSuccessModal, 
        setAcceptSuccessModal,
        startDate, 
        setStartDate,
        endDate, 
        setEndDate,
        requestReceived, 
        setRequestReceived,

        requestConnectedHome, 
        setRequestConnectedHome,

        requestConnected, 
        setRequestConnected,
        requestSent, 
        setRequestSent,
        connectHomeLoad,
        setConnectHomeLoad,
        messageSentData,
        setMessageSentData,
        acceptColor, 
        setAcceptColor,

        sendConnect,
        setSendConnect,

        connectManageLoad, 
        setConnectManageLoad,

        requestSentFromSuggest, 
        setRequestSentFromSuggest,

        retractSuccessModal, 
        setRetractSuccessModal

    }}>{children}</ExploreContext.Provider>
  )
}

export default ExploreContext;
