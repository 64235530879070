import React, { useContext, useState } from "react";
import ExploreContext from "../../hooks/exploreContext";
import { getExtension, truncateStringEllipsis } from "../../utils/helpers";
import { FaTimes } from "react-icons/fa";
import { MdFileUpload } from "react-icons/md";

const JobAdditionalFiles = (props) => {
	const { addJobApplicationFile } = useContext(ExploreContext);

	const [additionalFile, setAdditionalFile] = useState(null);

	const hiddenFileInput = React.useRef(null);

	const handleClickUpload = event => {
		hiddenFileInput.current.click();
	}

	const handleFileChange = event => {
		const fileUploaded = event.target.files[0];
		selectFile(fileUploaded, 'additional')
	}

	const selectFile = (file, type) => {
		setAdditionalFile(file);
		addJobApplicationFile( {file: file, type: type} );
	}

	return (
		<>
			<div className="doc-container">
				<div className="doc-title">{props.data}</div>

				<div className="doc-body">
					{
						additionalFile ?
							<>
								<div className='doc-content'>
									<div className="ext-label">{getExtension(additionalFile.name)}</div>

									<div className="doc-details">
										<div className="doc-name">{truncateStringEllipsis(additionalFile.name, 16, true)}</div>
										<div className="last-used">Last Used Today</div>
									</div>
								</div>

								<FaTimes />
							</>
							: <div style={{padding: '8px'}}>No file Selected</div>
					}
				</div>

				<div className="doc-actions">
					<button className="reuse-btn">Use Recent</button>
					<input
						id="btn-resume"
						name="btn-resume"
						style={{ display: 'none' }}
						type="file"
						className='upload-btn'
						ref={hiddenFileInput}
						accept={'application/pdf, text/plain, .doc, .docx, .txt'}
						onChange={handleFileChange}
					/>

					<button className="upload-btn" onClick={handleClickUpload}>
						<MdFileUpload /> Upload File
					</button>
				</div>
			</div>
		</>
	)
};

export default JobAdditionalFiles;