import { combineReducers } from "redux";
import navToggle from "../navigationDisplayToggle/index.reducer";
import { reducer } from "./reducer";
import currentUser from "../userData/index.reducer";
import userModal from "../userModal/index.reducer";
import singlePost from "../singlPostData/index.reducer";
import post from "../posts/index.reducer";
import singleComment from "../singleComment/index.reducer";
import singlePostV2Reducer from "../singlePostV2/singlePostV2.reducers";
import commentsReducerV2 from "../commentV2/commentV2.reducers";
import singleCommentV2Reducer from "../singleCommentV2/singleCommentV2.reducer";
import repliesV2 from "../repliesV2/repliesV2.reducer";
import savedPost from "../savedPosts/index.reducer";
import toBeEdited from "../editListings/index.reducer";
const appReducer = combineReducers({
  user: reducer,
  navToggle,
  currentUser,
  userModal,
  singlePost,
  post,
  singleComment,
  singlePostV2: singlePostV2Reducer,
  commentsV2: commentsReducerV2,
  singleCommentV2: singleCommentV2Reducer,
  repliesV2,
  savedPost,
  toBeEdited,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;
