import { useContext, useState, useEffect } from "react";
import CreateBookingBody from "../../components/Bookings/createBookingBody";
import FeedContainer from "../../components/FeedContainer";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import "./index.scss";
import ModalContext from "../../hooks/modalContexts";
import PreviewBookingBody from "../../components/Bookings/previewBookingBody";
import usePages from "../../hooks/createPage";
import qs from "query-string";

const CreateBooking = (props) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.currentUser);
  const { search } = useLocation();
  const { id } = qs.parse(search);

  const { singlePageData, handleGetSinglePage, categoryData } = usePages();
  const [discountDuration, setDiscountDuration] = useState(new Date());

  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    handleGetSinglePage(id)
      .then((res) => console.log(res))
      .catch((err) => console.log(err));

    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, [id]);

  const initialFormState = {
    page: id,
    title: "",
    category: "booking",
    booking_fee: {
      amount: 1,
      type: "FIXED",
    },
    accept_order_automatically: true,
    payment: false,
    description: "",
    allow_discount: false,
    discount: {
      name: "discount",
      systemType: "BOOKING",
      dealAndDiscountType: "BOOK_BEFORE_N_DATE_AND_PAY_X_FIXED",
      constant: {
        fix_price: 1,
        percentage: 1,
      },
      exp_date: discountDuration,
    },
    allow_referral: false,
    referrals: [],
    price_value_anybody: 1,
    allow_deposit: false,
    // location_to_use: "",
    service_locations: [],
    personnel: [],
    gallary: [],
    file: null
  };

  const [bookingForm, setBookingForm] = useState(initialFormState);
  const [step, setStep] = useState(1);

  useEffect(() => {
    setBookingForm({
      ...bookingForm,
      discount: {
        ...bookingForm.discount,
        exp_date: discountDuration,
      },
    });
  }, [discountDuration]);

  // Proceed to next step
  const nextStep = () => {
    setStep(step + 1);
  };

  // Go back to prev step
  const prevStep = () => {
    setStep(step - 1);
  };

  const [isOpen, setOpen] = useState(false);
  const toggleService = () => {
    setOpen(!isOpen);
    // setToggleSubmit(true);
  };

  const PageDisplay = () => {
    switch (step) {
      case 1:
        return (
          <CreateBookingBody
            bookingForm={bookingForm}
            setBookingForm={setBookingForm}
            nextStep={nextStep}
            handleFormData={handleInputData}
            values={bookingForm}
            handleNumberChange={handleNumberChange}
            handleBookingFeeInputData={handleBookingFeeInputData}
            handleDiscountInputData={handleDiscountInputData}
            handleBookingCheckbox={handleBookingCheckbox}
            discountDuration={discountDuration}
            setDiscountDuration={setDiscountDuration}
            handleAnybodyInputData={handleAnybodyInputData}
            initialFormState={initialFormState}
            isOpen={isOpen}
            setOpen={setOpen}         
            toggleService={toggleService}          
          />
        );

      case 2:
        return (
          <PreviewBookingBody
            // bookingForm={bookingForm}
            // setBookingForm={setBookingForm}
            // nextStep={nextStep}
            prevStep={prevStep}
            values={bookingForm}
            toggleService={toggleService}
          />
        );

      // <Success />;
      default:
    }
  };

  // handling form input data by taking onchange value and
  //  updating our previous form data state
  const handleInputData = (input) => (e) => {
    // input value from the form
    const { value } = e.target;

    //updating for data state taking previous state and
    // then adding new value to create new object
    setBookingForm((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleBookingFeeInputData = (input) => (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBookingForm((prevState) => ({
      ...prevState,
      booking_fee: {
        ...bookingForm.booking_fee,
        [input]: Number(value),
      },
    }));
  };

  const handleDiscountInputData = (input) => (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBookingForm((prevState) => ({
      ...prevState,
      discount: {
        ...bookingForm.discount,
        constant: {
          ...bookingForm.discount.constant,
          [input]: Number(value),
        },
      },
    }));
  };

  const handleAnybodyInputData = (input) => (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBookingForm((prevState) => ({
      ...prevState,
      [input]: Number(value),
    }));
  };

  const handleBookingCheckbox = (input, value) => {
    setBookingForm((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  const handleNumberChange = (input) => (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setBookingForm((prevState) => ({
      ...prevState,
      [input]: value,
    }));
  };

  return (
    <FeedContainer>
      <div className="booking-container">
        {/* {singlePageData.data ? ( */}
        <>
          <div className="bk-header">
            <h1 className="welcome-title">
              {greeting()}
              <span>
                {/* {`${user?.first_name} `}  */}
                {singlePageData?.data?.name?.split(" ")[0]}
              </span>
            </h1>
          </div>
          <div className="bk-card">
            <div className="bkc-header">
              <h5 className="sub-title"> Create Booking</h5>
              <Link className="sub-title-btn" to={`/bookingrequest/?id=${id}`}>
                View History
              </Link>
            </div>
            {PageDisplay()}
            {/* <CreateBookingBody
              bookingForm={bookingForm}
              setBookingForm={setBookingForm}
              nextStep={nextStep}
              handleFormData={handleInputData}
              values={bookingForm}
              handleNumberChange={handleNumberChange}
              handleBookingFeeInputData={handleBookingFeeInputData}
              handleDiscountInputData={handleDiscountInputData}
              handleBookingCheckbox={handleBookingCheckbox}
              discountDuration={discountDuration}
              setDiscountDuration={setDiscountDuration}
              handleAnybodyInputData={handleAnybodyInputData}
              initialFormState={initialFormState}
              // userPersonnel={userPersonnel}
              // setUserPersonnel={setUserPersonnel}
              // serviceLocations={serviceLocations}
              // setServiceLocations={setServiceLocations}
            /> */}
          </div>
        </>
        {/* // ) : (
        //   <h2 className="page-err"> No Page Selected or Invalid Page Id</h2>
        // )} */}
      </div>
    </FeedContainer>
  );
};

export default CreateBooking;
