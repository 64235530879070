const Bag = () => {
  return (
    <svg
      id="bag"
      xmlns="http://www.w3.org/2000/svg"
      width="16.439"
      height="16.439"
      viewBox="0 0 16.439 16.439"
    >
      <path
        id="Vector"
        d="M2.48,0,0,2.486"
        transform="translate(3.555 1.37)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M0,0,2.48,2.486"
        transform="translate(10.404 1.37)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,1.37C0,.1.678,0,1.521,0H12.178C13.021,0,13.7.1,13.7,1.37c0,1.473-.678,1.37-1.521,1.37H1.521C.678,2.74,0,2.843,0,1.37Z"
        transform="translate(1.37 4.007)"
        fill="none"
        stroke="#000"
        stroke-width="1"
      />
      <path
        id="Vector-4"
        data-name="Vector"
        d="M0,0V2.432"
        transform="translate(6.685 9.589)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="1"
      />
      <path
        id="Vector-5"
        data-name="Vector"
        d="M0,0V2.432"
        transform="translate(9.836 9.589)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="1"
      />
      <path
        id="Vector-6"
        data-name="Vector"
        d="M0,0,.966,5.918a2.354,2.354,0,0,0,2.706,2.3H7.8c2.13,0,2.445-.932,2.692-2.219l1.151-6"
        transform="translate(2.397 6.849)"
        fill="none"
        stroke="#000"
        stroke-linecap="round"
        stroke-width="1"
      />
      <path
        id="Vector-7"
        data-name="Vector"
        d="M0,0H16.439V16.439H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};

export default Bag;
