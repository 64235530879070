import DropdownMenu from "../../PrivateSeller/Profile/dropdownMenu";
import IconawesomeBriefcaseComponent from "../../../assets/svg/IconawesomeBriefcaseComponent";
import VuesaxLinearPeopleComponent from "../../../assets/leftBarIcons/VuesaxLinearPeopleComponent";
import { useEffect, useState } from "react";
import useExplore from "../../../hooks/explore";
import moment from "moment";
import useListings from "../../../hooks/listings";
import { useHistory } from "react-router";
import useCreateListings from "../../../hooks/createListings";

const ListingTab = ({ pageID }) => {
  const {
    handleDeleteListing,
    handleShareListing,
    shareListingData,
    deletedListingData,
  } = useListings();
  const { handleGetProducts, getProductsData } = useExplore();
  const [products, setProducts] = useState([]);
  const { handleUpdateListing, updateListingData } = useCreateListings();

  useEffect(() => {
    handleGetProducts({ page: pageID, rest: { pause: "all" } });
  }, [pageID]);
  console.log(getProductsData.data);
  const history = useHistory();

  //adding products to state
  useEffect(() => {
    getProductsData.data?.results.length &&
      setProducts((state) => [...state, ...getProductsData.data.results]);
  }, [getProductsData.data]);

  //for deleted products
  useEffect(() => {
    if (deletedListingData.data) {
      const { data } = deletedListingData;
      setProducts((state) => state.filter((product) => product.id !== data.id));
    }
  }, [deletedListingData.data]);

  //paused products or resumed products
  useEffect(() => {
    if (updateListingData.data) {
      const { data } = updateListingData;
      setProducts((state) =>
        state.map((product) => (data.id === product.id ? data : product))
      );
    }
  }, [updateListingData.data]);
  return (
    <div>
      {/*----------------SELLER ITEMS ----------------------*/}
      <div className="seller-items-container">
        {getProductsData.data &&
          products.map((data) => (
            <div className="si-card-holder" key={data.id}>
              <div className="si-card">
                <div className="si-card-body">
                  <img
                    className="seller-img"
                    src={data.gallary[0]?.file}
                    alt=""
                  />

                  <div className="top-item-details">
                    {!data.discount ? (
                      <div className="detail-top-icon br-3">
                        <VuesaxLinearPeopleComponent /> Refer
                      </div>
                    ) : (
                      <div className="top-detail-title">
                        <p>20% Off</p>
                      </div>
                    )}

                    <div className="detail-top-icon">
                      <IconawesomeBriefcaseComponent />
                    </div>
                  </div>
                  <div className="seller-item-details">
                    <div className="listing-inner">
                      <div className="detail-title">
                        <p>{data.title}</p>
                      </div>

                      <div className="detail-info">
                        <div className="abs-order">
                          <p className="txt-red"> {data.status} </p>
                        </div>
                        <button className="order-btn">
                          {" "}
                          {data.pre_order ? "Pre Order" : "Buy Now"}{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="si-card-footer">
                  <div className="sicf-price-container">
                    <p className="sicf-price">${data.deposit_amount}</p>
                  </div>

                  <div className="sic-footer-card">
                    <p className="txt-grey">
                      {moment(data.listing_end_date).fromNow()}
                    </p>
                    <DropdownMenu
                      {...{
                        handleDeleteListing,
                        handleShareListing,
                        id: data.id,
                        shareListingData,
                        deletedListingData,
                        pauseListing: handleUpdateListing,
                        format: () =>
                          history.push(
                            `/${
                              data.page.type.toLowerCase().includes("business")
                                ? "businessseller"
                                : "privateseller"
                            }/edit-listing/${pageID}/${data.id}`
                          ),
                        ...data,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ListingTab;
