import { useEffect } from "react";
import { useState } from "react";
import "./index.scss";
import { FaAngleDown } from "react-icons/fa";
const GallerySortDropdown = ({
  options = [{ name: "default" }],
  getData = () => {},
}) => {
  const [selected, setSelected] = useState(options[0].name);
  const [state, setState] = useState();

  useEffect(() => {
    getData(selected);
  }, [selected]);

  return (
    <div className="custom__select__dropdown">
      <div className="selected__option" onClick={(e) => setState(!state)}>
        {selected}
      </div>
      {state && (
        <div className="options__container">
          {options.map((data, idx) => (
            <div
              className="opt__custom"
              key={idx}
              onClick={(e) => {
                setSelected(data?.name);
                setState();
              }}>
              {data?.name}
              <div className="img">{data.img && <data.img />}</div>
            </div>
          ))}
        </div>
      )}
      <div className="angleDown" onClick={(e) => setState(!state)}>
        <FaAngleDown />
      </div>
    </div>
  );
};
export default GallerySortDropdown;
