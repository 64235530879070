import React from "react";
import {
  BookmarkOutlined,
  MoreVert,
  LocationOn,
  BusinessCenter,
  RoomService,
} from "@material-ui/icons";
import { IoIosShareAlt } from "react-icons/io";
import { FiBox } from "react-icons/fi";
import Styled from "styled-components";
import { colors } from "../../../color";
import propTypes from "prop-types";

export const Card = Styled.div`
  height: 320px;
  width: 100%;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.5) 0px 9px 15px;
`;

export const CardHeader = Styled.div`
    height: 52px;
    width: 100%;
    background: #efefef;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius:  12px 12px 0px 0px;
    box-shadow: 0px -3px 10px  rgba(149, 157, 165, 0.5);
`;
export const AvatarDiv = Styled.div`

`;

export const Sort = Styled.p`
  color: ${colors.COMPANY_COLOR};
  font-weight: 700;
  font-size: 14px;
`;
export const DiscountTag = Styled.div`
  height: 30px;
  width: 45px;
  background: ${colors.ORANGE};
  color: white;
  position: absolute;
  border-radius: 7px;
  right: 82%;
  top: 25%;
  font-size: 10px;
  font-weight: 600;
  text-align: center;
  line-height: 10px;
  display: flex;
  align-items: center;
`;
export const DescriptionDiv = Styled.div`
    background: transparent;
    position: relative;
    top: 82%;
    width: 100%;
    height: 42px;
    display: flex;
    align-items: center;
    padding-right: 4px;
    padding-left: 10px;
    h5 {
        color: ${colors.WHITE};
        font-size: 12px;
        font-weight: 500;
        height: 10px;
    }
    p {
        font-size: 9px;
        font-weight: 600;
        color: ${colors.WHITE};
    }
`;

export const Btn = Styled.button`
    border: none;
    border-radius: 7px;
    background: ${(props) => props.bg};
    color: ${(props) => props.color};
    height: 30px;
    width: 85px;
    font-weight: 600;
    font-size: 12px;
`;
export const Price = Styled.p`
    color: ${colors.WHITE};
    font-weight: 500;
    font-size: 14px;

`;
export const CardBody = Styled.div`
    height: 218px;
    width: 100%;
    background:  linear-gradient(#77757523, #2e2c2cbb), url(${(props) =>
      props.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;
export const Avatar = Styled.div`
    height: 25px;
    width: 25px;
    border-radius: 50%;
    background: url(${(props) => props.img});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
`;

export const Tag = Styled.div`
    background: ${(props) => props.bg};
    color: ${(props) => props.color};
    height: 25px;
    width: 79px;
    border-radius: 10px 0px 0px 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 10px;
    p {
        margin-top: 13px;
        font-size: 12px;
        text-transform: capitalize;
    }
`;

export const CardBottom = Styled.div`
    height: 50px;
    background: ${(props) => props.color};
    border-radius:  0px 0px 12px 12px;
`;

export const iconStyle = {
  color: `${colors.WHITE}`,
  fontSize: "20px",
};

export const jobType = [
  "Full time",
  "Part time",
  "Internship",
  "Volunteer",
  "Contract",
];

export const jobCategory = [
  "Accounting & Finance",
  "Admin & Office",
  "Art & Design",
  "Science & Engineering",
  "Management",
  "Other",
];

class CustomCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Card>
        <CardHeader className="pl-2">
          <div
            className="d-flex justify-content-between"
            style={{ width: "50%" }}
          >
            <Avatar img={this.props.picture}></Avatar>
            <div>
              <p
                style={{
                  color: `${colors.COMPANY_COLOR}`,
                  fontSize: "12px",
                  fontWeight: "700",
                }}
              >
                {this.props.seller}
              </p>
            </div>
          </div>
          <Tag
            color={colors.WHITE}
            bg={
              this.props.type === "product"
                ? colors.COMPANY_COLOR
                : colors.ORANGE
            }
          >
            {this.props.type === "product" ? (
              <FiBox className="pr-1" />
            ) : this.props.type === "job" ? (
              <BusinessCenter className="pr-1" />
            ) : (
              <RoomService className="pr-1" />
            )}
            <p>{this.props.type}</p>
          </Tag>
        </CardHeader>
        <CardBody img={this.props.picture}>
          {!!this.props.discount && (
            <DiscountTag>{this.props.discount}</DiscountTag>
          )}
          <DescriptionDiv>
            <div style={{ width: "72%" }}>
              <h5>{this.props.description}</h5>
              <p className="d-flex align-items-center">
                <LocationOn style={{ fontSize: "12px" }} />
                {this.props.location}
              </p>
            </div>
            <div style={{ width: "30%" }} className="d-flex align-items-center">
              <BookmarkOutlined style={iconStyle} />
              <IoIosShareAlt style={iconStyle} />
              <MoreVert style={iconStyle} />
            </div>
          </DescriptionDiv>
        </CardBody>
        <CardBottom
          className="ml-auto d-flex justify-content-between align-items-center pl-2 pr-2"
          color={
            this.props.type === "product" ? colors.COMPANY_COLOR : colors.ORANGE
          }
        >
          <Price className="pt-3">{this.props.price}</Price>
          <Btn
            bg={this.props.type === "product" ? colors.ORANGE : colors.WHITE}
            color={
              this.props.type === "product"
                ? colors.WHITE
                : colors.COMPANY_COLOR
            }
          >
            {" "}
            {this.props.type === "product"
              ? "Buy Now"
              : this.props.type === "job"
              ? "Apply"
              : "Order"}
          </Btn>
        </CardBottom>
      </Card>
    );
  }
}

CustomCard.propTypes = {
  picture: propTypes.string.isRequired,
  seller: propTypes.string.isRequired,
  type: propTypes.string.isRequired,
  discount: propTypes.string,
  description: propTypes.string.isRequired,
  location: propTypes.string.isRequired,
  price: propTypes.string.isRequired,
};
export default CustomCard;
