import {
  SET_ACCOUNT_TYPE,
  SET_CURRENT_USER,
  SET_USER_TOKEN,
} from "./index.type";
const initialData = {
  accountType: "business",
  token: false,
};

//relevant user data
const currentUser = (state = initialData, { type, payload }) => {
  switch (type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        ...payload,
      };
    case SET_ACCOUNT_TYPE:
      return {
        ...state,
        accountType: payload,
      };
    // case SET_USER_TOKEN:
    //   return {
    //     ...state,
    //     token: payload,
    //   };
    default:
      return state;
  }
};

export default currentUser;
