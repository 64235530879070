import Header from "../Header";
import LeftSectionFeed from "../LeftSectionFeed";
import RightFeed from "../RightFeed";
import { useSelector, useDispatch } from "react-redux";
import UserModal from "../Modals/userModal.jsx";
import { useState } from "react";
import { clearModal } from "../../appstore/userModal/index.action";
import { useEffect } from "react";
import EditQueueModal from "../../pages/queue/editQueue/modal";
import ModalContext, { useModalContext } from "../../hooks/modalContexts";
import Checkout from "../../pages/Checkout";
import ItemModal from "../../pages/Activity/components/itemModal";
import MyJobModal from "../../pages/Activity/components/myJobModal";
import OrderItemModal from "../../pages/Activity/components/orderItemModal";
import useInterests from "../../hooks/interests";
// import GalleryModal from "../../pages/Gallery/galleryModal";
import JobModal from "../Modals/jobModal/JobModal";
import JobItemModal from "../BookingJobHistory/JobModal";
import PostModal from "../PreviewModal/PostModal";
import InfoItemModal from "../../pages/Dashboard/Jobs/RecievedApplications/modal/InfoModal";
import CreateBookingModal from "../Modals/bookingModals/createbookingModal";
// import BusinessSummaryModal from "../Modals/businessSeller/summary";
import SendAsMessage from "../Modals/sendAsMessage";
import ProfileModal from "../../pages/Dashboard/Connect/ConnectModals/ProfileModal";
import AcceptModal from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/AcceptModals";
import ConnectMeModal from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/ConnectMeModals";
import ConnectMessageModal from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/MessageModal";
import ConnectSentModal from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/MessageSentConnectModal";
import SuccessModal from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/SuccessModal";
import DeliveryDetail from "../Modals/deliveryDetails";
import { JobApplyModal } from "../Modals/jobApplyModal/jobApplyModal";
import { ApplySuccessModal } from "../Modals/successModal/applySuccessModal";
import { BookSuccessModal } from "../Modals/serviceModals/bookSuccessModal";
import { ProductSuccessModal } from "../Modals/productModals/productSuccessModal";
import { UpdateSuccessModal } from "../Modals/successModal/updateSuccessModal";
import { UpdateFailModal } from "../Modals/successModal/updateFailModal";
import BusinessSellerDelivery from "../Modals/businessSellerDelivery";
import BusinessSummaryModal from "../Modals/businessSellerSummaryModals/summary";
import BusinessSummaryModal2 from "../Modals/businessSellerSummaryModals/summary2";
import QueueListingsModal from "../Modals/queueModal/queueListingsModal";
import PrivateSellerSummaryModal from "../Modals/privateSellerModals/psSummaryModal";
import BookingListModal from "../Modals/bookingModals/bookingrequestModal";
import ReviewModal from "../../pages/Activity/components/reviewModal";
// import {JobApplyModal} from "../Modals/jobApplyModal/jobApplyModal";
import ReferModal from "../Modals/referModal/referModal";
import RetractModal from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/RetractModal";
import IncomingModal from "../PrivateSellerModals/AcceptedIncoming";
import AcceptedModal from "../PrivateSellerModals/AcceptModal";
import AutoAcceptModal from "../PrivateSellerModals/AutoAccept";
import DiscountModal from "../PrivateSellerModals/DealsDiscount";
import { ExploreProvider } from "../../hooks/exploreContext";
import MediaBox from "../mediaBox";
import TotalRevenueModal from "../Modals/analyticsModals/totalRevenueModal";
import CompletedOrderModal from "../Modals/analyticsModals/orderCompletionModals/completedOrderModal";
import IncompleteOrderModal from "../Modals/analyticsModals/orderCompletionModals/inCompleteOrderModal";
import CancelledOrderModal from "../Modals/analyticsModals/orderCompletionModals/cancelledOrderModal";
import PreOrderMetModal from "../Modals/analyticsModals/orderCompletionModals/preOrderMetModal";
import PreOrderNotMetModal from "../Modals/analyticsModals/orderCompletionModals/preOrderNotMetModal";
import ClicksAnalyticModal from "../Modals/analyticsModals/generalAnalyticsModals/clicksAnalyticModal";
import LikesAnalyticModal from "../Modals/analyticsModals/generalAnalyticsModals/likesAnalyticModal";
import SharesAnalyticModal from "../Modals/analyticsModals/generalAnalyticsModals/sharesAnalyticModal";
import ReferAnalyticModal from "../Modals/analyticsModals/generalAnalyticsModals/referAnalyticModal";
import SupportAnalyticModal from "../Modals/analyticsModals/generalAnalyticsModals/supportAnalyticsModal";
import PageOrderModal from "../Modals/analyticsModals/orderCompletionModals/pageOrderModal";
import ReferralOrderModal from "../Modals/analyticsModals/orderCompletionModals/referralOrderModal";
import JobAnalyticModal from "../Modals/analyticsModals/jobAnalyticsModals/jobAnalyticModal";
import QueueAnalyticModal from "../Modals/analyticsModals/queueAnalyticsModals/queueAnalyticModal";
import PageViewsAnalyticModal from "../Modals/analyticsModals/pageViewsAnalyticModal";
import CustomerConversionModal from "../Modals/analyticsModals/generalAnalyticsModals/customerConversion";
import TotalConnectionsModal from "../Modals/analyticsModals/orderCompletionModals/totalConnectionsModal";
import TotalAppointmentModal from "../Modals/analyticsModals/orderCompletionModals/totalAppointmentModal";
import TotalPageVisitModal from "../Modals/analyticsModals/orderCompletionModals/totalPageVisitModal";
import TotalCommissionsModal from "../Modals/analyticsModals/orderCompletionModals/totalCommissionsModal";
import WalletModal from "../../pages/Payments/Wallet/SuccessModal/WalletModal";
import SocketContext, { useSocketContext } from "../../hooks/socketContexts";
import BookingLocationModal from "../Modals/bookingModals/bookingLocationModal";
import CheckoutModal, { NotificationWrapper } from "../Modals/checkout";
import CheckoutModalV2 from "../Modals/checkout";
import EditProfileModal from "../../pages/Dashboard/Connect/ConnectModals/EditProfileModal";
import SuccessConnect from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/SuccessConnectModal";
import DeclineConnectCard from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/DeclineConnectCard";
import SuccessConnectCard from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/SuccessConnectCard";
import DeclineConnect from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/DeclineConnectModal";
import RetractSuccessConnect from "../../pages/Dashboard/Connect/ConnectModals/AcceptDeclineModal/RetractSuccessConnect";
import TotalApplicationModal from "../Modals/analyticsModals/orderCompletionModals/totalApplicationsModal ";
import CustomerConversionConnectModal from "../Modals/analyticsModals/generalAnalyticsModals/customerConversionConnect";
import ReferralTotalModal from "../Modals/analyticsModals/orderCompletionModals/totalReferralModal";
import AdminModal from "../../pages/Settings/component/Business/businessSellerComponents/adminModal";

const FeedContainer = ({ children }) => {
  // const dispatch = useDispatch();
  useInterests();
  const modalContextData = useModalContext();
  const { leftBar, rightBar, suggestedUsers, suggestedListings, myPages } =
    useSelector((state) => state.navToggle);
  console.log(leftBar, rightBar, suggestedListings, suggestedUsers);

  // const closeModal = () => dispatch(clearModal());
  const [isMobileMenuVisble, setIsMobileMenuVisible] = useState();

  const socketContext = useSocketContext();
  // console.log(modalContextData.checkoutModal);

  return (
    <SocketContext.Provider value={socketContext}>
      <ModalContext.Provider value={modalContextData}>
        <div className="home">
          <Header
            menuVisibility={[isMobileMenuVisble, setIsMobileMenuVisible]}
          />
          <div className="default__container">
            <div className="home__body">
              {leftBar && (
                <LeftSectionFeed
                  viewContent={{ myPages }}
                  menuVisibility={[isMobileMenuVisble, setIsMobileMenuVisible]}
                />
              )}
              {/*<ExploreProvider>{children}</ExploreProvider>*/}
              {children}
              {rightBar && (
                <RightFeed
                  viewContents={{ suggestedListings, suggestedUsers }}
                />
              )}
            </div>
          </div>

          {/* single post and usermodal will be moved to modal context in due time, reminder */}

          {/* {modalContextData.checkoutModal && <Checkout />} */}
          {modalContextData.userModal.data.length && <UserModal />}
          {modalContextData.queueListingModal.length ? (
            <EditQueueModal />
          ) : undefined}
          {modalContextData.queueItemModal && <ItemModal />}
          {modalContextData.myJobItemModal && <MyJobModal />}
          {modalContextData.orderItemModal && <OrderItemModal />}
          {/* {modalContextData.galleryModal && <GalleryModal />} */}
          {modalContextData.jobModal && <JobModal />}
          {modalContextData.jobItemModal && <JobItemModal />}
          {modalContextData.postModalVisibilityStatus[0] && <PostModal />}
          {modalContextData.infoItemModal && <InfoItemModal />}
          {modalContextData.bookingModal && <CreateBookingModal />}
          {modalContextData.businessSummaryModal && <BusinessSummaryModal />}
          {modalContextData.sendAsMessage && <SendAsMessage />}
          {modalContextData.connectProfileModal && <ProfileModal />}
          {modalContextData.acceptModal && <AcceptModal />}
          {modalContextData.connectModal && <ConnectMeModal />}
          {modalContextData.messageModal && <ConnectMessageModal />}
          {modalContextData.successModal && <SuccessModal />}
          {modalContextData.privateSellerDelivery && <DeliveryDetail />}
          {modalContextData.jobApplyModal && <JobApplyModal />}
          {modalContextData.applySuccessModal && <ApplySuccessModal />}
          {modalContextData.bookSuccessModal && <BookSuccessModal />}
          {modalContextData.productSuccessModal && <ProductSuccessModal />}
          {modalContextData.updateSuccessModal && <UpdateSuccessModal />}
          {modalContextData.updateFailModal && <UpdateFailModal />}
          {modalContextData.businessSellerDelivery && (
            <BusinessSellerDelivery />
          )}
          {modalContextData.businessSummary2Modal && <BusinessSummaryModal2 />}
          {modalContextData.queueListingsModal && <QueueListingsModal />}
          {modalContextData.privateSellerSummaryModal && (
            <PrivateSellerSummaryModal />
          )}
          {modalContextData.bookingListModal && <BookingListModal />}
          {modalContextData.reviewModal && <ReviewModal />}
          {modalContextData.referModal && <ReferModal />}
          {modalContextData.retractModal && <RetractModal />}
          {modalContextData.incomingModal && <IncomingModal />}
          {modalContextData.acceptedModal && <AcceptedModal />}
          {modalContextData.autoAcceptModal && <AutoAcceptModal />}
          {modalContextData.discountModal && <DiscountModal />}
          {modalContextData.totalRevenueModal && <TotalRevenueModal />}
          {modalContextData.completedOrderModal && <CompletedOrderModal />}
          {modalContextData.incompleteOrderModal && <IncompleteOrderModal />}
          {modalContextData.cancelledOrderModal && <CancelledOrderModal />}
          {modalContextData.preOrderMetModal && <PreOrderMetModal />}
          {modalContextData.preOrderNotMetModal && <PreOrderNotMetModal />}
          {modalContextData.clicksAnalyticModal && <ClicksAnalyticModal />}
          {modalContextData.likesAnalyticModal && <LikesAnalyticModal />}
          {modalContextData.sharesAnalyticModal && <SharesAnalyticModal />}
          {modalContextData.referAnalyticModal && <ReferAnalyticModal />}
          {modalContextData.supportAnalyticModal && <SupportAnalyticModal />}
          {modalContextData.pageOrderModal && <PageOrderModal />}
          {modalContextData.referralOrderModal && <ReferralOrderModal />}
          {modalContextData.jobAnalyticModal && <JobAnalyticModal />}
          {modalContextData.queueAnalyticModal && <QueueAnalyticModal />}
          {modalContextData.pageViewsAnalyticModal && (
            <PageViewsAnalyticModal />
          )}
          {modalContextData.customerConversionModal && (
            <CustomerConversionModal />
          )}
          {modalContextData.totalConnectionsModal && <TotalConnectionsModal />}
          {modalContextData.totalAppointmentModal && <TotalAppointmentModal />}
          {modalContextData.totalPageVisitModal && <TotalPageVisitModal />}
          {modalContextData.totalCommissionsModal && <TotalCommissionsModal />}
          {modalContextData.walletModal && <WalletModal />}
          {modalContextData.bookingLocationModal && <BookingLocationModal />}
          {modalContextData.checkoutModalV2[0] && (
            <NotificationWrapper>
              <CheckoutModalV2 />
            </NotificationWrapper>
          )}
          {modalContextData.editProfileModal && <EditProfileModal />}
          {modalContextData.declineConnectModal && <DeclineConnect />}
          {modalContextData.declineConnectModalCard && <DeclineConnectCard />}
          {modalContextData.retractSuccessConnectModal && (
            <RetractSuccessConnect />
          )}
          {modalContextData.successConnectModal && <SuccessConnect />}
          {modalContextData.successConnectModalCard && <SuccessConnectCard />}
          {modalContextData.totalApplicationModal && <TotalApplicationModal />}
          {modalContextData.customerConversionConnectModal && (
            <CustomerConversionConnectModal />
          )}
          {modalContextData.referralTotalModal && <ReferralTotalModal />}
          {modalContextData.messageSentModal && <ConnectSentModal />}

          {
            //add admin modal for business settings page settings

            modalContextData?.adminModal && <AdminModal />
          }
        </div>
      </ModalContext.Provider>
    </SocketContext.Provider>
  );
};

export default FeedContainer;
