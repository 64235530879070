import { useState, useEffect } from "react";
import useDeviceType from "../../hooks/deviceType";
import DashboardBodyDV from "../../components/BusinessSeller/Dashboard/dashboardDV";
import FeedContainer from "../../components/FeedContainer";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";

const BusinessSellerDashboard = () => {
  const dispatch = useDispatch();
  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  return (
    <FeedContainer>
      <div className="bs-dashboard-container">
        <DashboardBodyDV />
      </div>
    </FeedContainer>
  );
};

export default BusinessSellerDashboard;
