const LoaderWhite = ({ style, ...rest }) => (
  <svg
    style={{
      margin: "auto",
      // background: " rgb(255, 255, 255) none repeat scroll 0% 0%",
      display: " block",
      shapeRendering: "auto",
      ...(style && { ...style }),
    }}
    width="100px"
    height="100px"
    viewBox="0 0 100 100"
    preserveAspectRatio="xMidYMid"
    {...rest}>
    <circle
      cx="50"
      cy="50"
      fill="none"
      stroke="#ffffff"
      stroke-width="10"
      r="35"
      stroke-dasharray="164.93361431346415 56.97787143782138">
      <animateTransform
        attributeName="transform"
        type="rotate"
        repeatCount="indefinite"
        dur="1s"
        values="0 50 50;360 50 50"
        keyTimes="0;1"></animateTransform>
    </circle>
  </svg>
);

export default LoaderWhite;
