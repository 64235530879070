import { useState, useContext } from "react";
import "./listingAnalytics.scss";
import ModalContext from "../../../../hooks/modalContexts";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import SalesGrowthChart from "../../../../components/Analytics/components/salesGrowth";
import TotalEarningsChart from "../../../../components/Analytics/components/totalEarnings";
import CustomDropdown from "../../../../components/Analytics/components/customDropdown";
import AreaChart from "../../../../components/Analytics/Charts/areaChartPurchaseListing";
import InteractedAreaChart from "../../../../components/Analytics/Charts/areaChartInteracted";
import ConvertedAreaChart from "../../../../components/Analytics/Charts/areaChartAdConverted";
import { BiInfoCircle, BiUpArrowAlt } from "react-icons/bi";
import BigEye from "../../../../assets/svg/bigEye";
import WalletMoney from "../../../../assets/svg/wallet-money";
import RateIcon from "../../../../assets/svg/rateIcon";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ChartStats from "../../../../components/Analytics/components/chartStats";
import GroupUserIcon from "../../../../assets/svg/groupUserIcon";
import OrderCompletion from "../../../../components/Analytics/components/orderCompletion";
import TaskSquare from "../../../../assets/svg/taskSquare";
import BarChart from "../../../../components/Analytics/Charts/barChartDemographicListing";


const dropdownData = [
  { label: "Purchased listing" },
  { label: "Interacted listing" },
  { label: "Order completion" },
  { label: "Ads converted" },
  { label: "Demographic" },
];
const ListingAnalytics = ({
  AnalyticsOthers,
  others,
  demographicListing,
  orderCompletion,

  // optimised
  listingInteractions,
  listingChart,
  purchaseListing,
  interactedListing,
  adConverted,
}) => {
  const [activeTab, setActiveTab] = useState("most");
  const [activeTab2, setActiveTab2] = useState("city");
  const [activeTab3, setActiveTab3] = useState("city1");
  const [value, setValue] = useState(dropdownData[0].label);
  // const [selectedOption, setSelectedOption] = useState(pickupOptions[0]);

  const handleChange = () => {
    setValue(dropdownData.label);
  };

  const viewList = [
    dropdownData[0].label,
    dropdownData[1].label,
    dropdownData[2].label,
  ];

  const testArray = [1, 2, 3, 4, 5, 6, 7];
  const { setCompletedOrderModal } = useContext(ModalContext);
  const { setInCompleteOrderModal } = useContext(ModalContext);
  const { setCancelledOrderModal } = useContext(ModalContext);
  const { setPreOrderMetModal } = useContext(ModalContext);
  const { setPreOrderNotMetModal } = useContext(ModalContext);
  const { setPageOrderModal } = useContext(ModalContext);
  const { setReferralOrderModal } = useContext(ModalContext);
  const { setCustomerConversionModal } = useContext(ModalContext);

  return (
    <div className="listing-analytics-container">
      <div className="lac-card-body">
        <div className="lacb-contents-one">
          <SalesGrowthChart
            image={<WalletMoney />}
            title="Sales Growth"
            height={250}
            width={700}
            AnalyticsOthers={AnalyticsOthers}
            listingInteractions={listingInteractions}
            listingChart={listingChart}
            others={others}
          />
        </div>
        <div className="lacb-contents-two">
          <TotalEarningsChart title="Listing" listingInteractions={listingInteractions} />
        </div>
      </div>
      <div className="lac-card-body-two">
        <div className="lacbt-contents-one">
          <div className="lco-header">
            <p>show</p>
            <div className="dropbox">
              <CustomDropdown
                value={value}
                setValue={setValue}
                items={dropdownData}
                onChange={handleChange}
              />
            </div>
            <div className="tabs">
              {/* ----------  nav tab -------- */}
              {value === dropdownData[0].label && (
                <ul className="nav-tab">
                  <li
                    className={activeTab === "least" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("least");
                    }}
                  >
                    Least
                  </li>
                  <li
                    className={activeTab === "most" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("most");
                    }}
                  >
                    Most
                  </li>
                  <li
                    className={activeTab === "all" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("all");
                    }}
                  >
                    All
                  </li>
                </ul>
              )}
              {value === dropdownData[1].label && (
                <ul className="nav-tab">
                  <li
                    className={activeTab === "least" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("least");
                    }}
                  >
                    Least
                  </li>
                  <li
                    className={activeTab === "most" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("most");
                    }}
                  >
                    Most
                  </li>
                  <li
                    className={activeTab === "all" ? "active" : ""}
                    onClick={() => {
                      setActiveTab("all");
                    }}
                  >
                    All
                  </li>
                </ul>
              )}
              {value === dropdownData[4].label && (
                <ul className="nav-tab">
                  <li
                    className={activeTab2 === "city" ? "active" : ""}
                    onClick={() => {
                      setActiveTab2("city");
                    }}
                  >
                    City
                  </li>
                  <li
                    className={activeTab2 === "age" ? "active" : ""}
                    onClick={() => {
                      setActiveTab2("age");
                    }}
                  >
                    Age
                  </li>
                </ul>
              )}
              <div className="tab-title">{value}</div>
            </div>
          </div>
          <div className="lco-body">
            {/* ************ PURCHASED LISTING ************* */}
            {value === dropdownData[0].label && (
              <div className="lco-body">
                <div className="item__scroll">
                  {purchaseListing?.map((data, idx) => (
                    <div className="lcob-details">
                      <div className="details-box-one">
                        <h4>{idx + 1}</h4>
                        <img src={data?.image.map(item => item.file ? item.file : itemImg)} alt="Listing"  />
                        <div className="details-box-two">
                          <h5>{data?.name}</h5>
                          <h6>
                            Price: <span>${data?.price}</span>
                          </h6>
                        </div>
                      </div>
                      <div className="details-value">
                        <p> {data?.summary.summary.difference} sold</p>
                      </div>
                      <div className={data?.summary.summary.direction === "UP" ? "lcob-chart rate-text-green" : "lcob-chart rate-text-red"}>
                        <p>
                          {data?.summary.summary.percentage.toFixed(1)}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                        </p>
                        <AreaChart data={data}/>
                      </div>
                    </div>
                  ))}
                </div>
                {/* <button className="btn-view-more">view more</button> */}
              </div>
            )}

            {/* ************ INTERACTED LISTING ************ */}
            {value === dropdownData[1].label && (
              <div className="lco-body">
                <div className="item__scroll">
                  {interactedListing?.map((data, idx) => (
                    <div className="lcob-details">
                      <div className="details-box-one">
                        <h4>{idx + 1}</h4>
                        <img src={data?.image.map(item => item.file ? item.file : itemImg)} alt="" />
                        <div className="details-box-two">
                          <h5>{data?.name}</h5>
                          <h6>
                            Price: <span>${data?.price}</span>
                          </h6>
                        </div>
                      </div>

                      <div className={data?.summary.summary.direction === "UP" ? "lcob-chart rate-text-green" : "lcob-chart rate-text-red"}>
                        <p>
                          {data?.summary.summary.percentage.toFixed(1)}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                        </p>
                        <InteractedAreaChart data={data} />
                      </div>
                    </div>
                  ))}
                </div>
                {/* <button className="btn-view-more">view more</button> */}
              </div>
            )}

            {/* ************ ORDER COMPLETION ************ */}
            {value === dropdownData[2].label && (
              <div className="orderc-body">
                <OrderCompletion
                  image={<TaskSquare fill={"#000"} />}
                  title="Total Order"
                  orderValue={listingInteractions?.total_order?.total}
                  orderPercent={listingInteractions?.total_order?.summary.percentage.toFixed(1) + "%"}
                  bgColor="rgba(220,220,220,0.4)"
                  bgColor2="#000"
                  onClick={(e) => setPageOrderModal(true)}
                  orderCompletion={orderCompletion}
                  upAndDown={listingInteractions?.total_order?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                  
                />
                <OrderCompletion
                  image={<TaskSquare fill={"#3dd598"} />}
                  title="Completed Order"
                  orderValue= {`${listingInteractions?.completed_order?.completed} of ${listingInteractions?.completed_order?.total}`}
                  orderPercent={listingInteractions?.completed_order?.summary.percentage.toFixed(1) + "%"}
                  bgColor="rgba(61,213,152,0.4)"
                  bgColor2="#3dd598"
                  onClick={(e) => setCompletedOrderModal(true)}
                  listingInteractions={listingInteractions}
                  upAndDown={listingInteractions?.completed_order?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                />
                <OrderCompletion
                  image={<TaskSquare fill={"#fbb03b"} />}
                  title="Incomplete Order"
                  orderValue={`${listingInteractions?.incomplete_order?.incomplete} of ${listingInteractions?.incomplete_order?.total}`}
                  orderPercent={listingInteractions?.incomplete_order?.summary.percentage.toFixed(1)  + "%"}
                  bgColor="rgba(255,156,0,0.4)"
                  bgColor2="#fbb03b"
                  onClick={(e) => setInCompleteOrderModal(true)}
                  listingInteractions={listingInteractions}
                  upAndDown={listingInteractions?.incomplete_order?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                />
                <OrderCompletion
                  image={<TaskSquare fill={"#c10000"} />}
                  title="Canceled Order"
                  orderValue={`${listingInteractions?.cancel_order?.cancel} of ${listingInteractions?.cancel_order?.total}`}
                  orderPercent={listingInteractions?.cancel_order?.summary.percentage.toFixed(1) + "%"}
                  bgColor="rgba(242,81,81,0.4)"
                  bgColor2="#c10000"
                  onClick={(e) => setCancelledOrderModal(true)}
                  listingInteractions={listingInteractions}
                   upAndDown={listingInteractions?.cancel_order?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                />
                <OrderCompletion
                  image={<TaskSquare fill={"#066cba"} />}
                  title="Pre Order"
                  subTitle="Criteria met"
                  orderValue={`${listingInteractions?.pre_order_criteria_met?.pre_order_met} of ${listingInteractions?.pre_order_criteria_met?.total}`}
                  orderPercent={listingInteractions?.pre_order_criteria_met?.summary.percentage.toFixed(1) + "%"}
                  bgColor="rgba(6,108,186,0.4)"
                  bgColor2="#066cba"
                  onClick={(e) => setPreOrderMetModal(true)}
                   upAndDown={listingInteractions?.pre_order_criteria_met?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                />
                <OrderCompletion
                  image={<TaskSquare fill={"#787878"} />}
                  title="Pre Order"
                  subTitle="Criteria not met"
                  orderValue={`${listingInteractions?.pre_order_criteria_not_met?.pre_order_not_met} of ${listingInteractions?.pre_order_criteria_not_met?.total}`}
                  orderPercent={listingInteractions?.pre_order_criteria_not_met?.summary.percentage.toFixed(1) + "%"}
                  bgColor="rgba(155,155,155,0.4)"
                  bgColor2="#787878"
                  onClick={(e) => setPreOrderNotMetModal(true)}
                   upAndDown={listingInteractions?.pre_order_criteria_not_met?.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                />
              </div>
            )}

            {/* ************ ADS CONVERTED ************ */}
            {value === dropdownData[3].label && (
              <div className="lco-body">
                <div className="item__scroll">
                  {adConverted?.map((data, idx) => (
                    <div className="lcob-details">
                      <div className="details-box-one">
                        <h4>{idx + 1}</h4>
                        <img src={data?.image.map(item => item.file ? item.file : itemImg)} alt="" />
                        <div className="details-box-two">
                          <h5>{data?.name}</h5>
                          <h6>
                            Price: <span>${data?.price}</span>
                          </h6>
                        </div>
                      </div>

                      <div className={data?.summary.summary.direction === "UP" ? "lcob-chart rate-text-green" : "lcob-chart rate-text-red"}>
                        <p>
                           {data?.summary.summary.percentage.toFixed(1)}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                        </p>
                        <ConvertedAreaChart data={data} />
                      </div>
                    </div>
                  ))}
                </div>
                {/* <button className="btn-view-more">view more</button> */}
              </div>
            )}

            {/* ************ DEMOGRAPHIC ************ */}
            {value === dropdownData[4].label && (
              <div className="lco-body">
                <div className="dmc-card">
                  {activeTab2 === "city" && (
                    <div className="tabs2">
                      {/* ----------  nav tab -------- */}
                      <ul className="nav-tab">
                        <li
                          className={activeTab3 === "city1" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("city1");
                          }}
                        >
                          Canada
                        </li>
                        <li
                          className={activeTab3 === "city2" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("city2");
                          }}
                        >
                          Canada
                        </li>
                        <li
                          className={activeTab3 === "city3" ? "active" : ""}
                          onClick={() => {
                            setActiveTab3("city3");
                          }}
                        >
                          Canada
                        </li>
                      </ul>
                    </div>
                  )}
                  <BarChart
                  listingChart={listingChart}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="dmc-stats">
          <ChartStats
            image={<WalletMoney />}
            title="Total orders made on page"
            statPercent={listingInteractions?.total_order?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={listingInteractions?.total_order?.total}
            onClick={(e) => setPageOrderModal(true)}
          />
          <ChartStats
            image={<GroupUserIcon fill="#fbb03b" />}
            title="Number of referrals"
            statPercent={listingInteractions?.number_of_referrals?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={listingInteractions?.number_of_referrals?.total}
            onClick={(e) => setReferralOrderModal(true)}
          />
          <ChartStats
            image={<RateIcon />}
            title="Customer conversion rate"
            statPercent={listingInteractions?.conversion_rate?.summary.percentage.toFixed(1) + "%"}
            statTime="in less than a week"
            statTotal={listingInteractions?.conversion_rate?.rate.toFixed(1)}
            onClick={(e) => setCustomerConversionModal(true)}
          />
        </div>
      </div>
    </div>
  );
};

export default ListingAnalytics;
