import SignIn from "./components/forms/signin";
import SignUP from "./components/forms/signup";
// import ForgotPassword from "./components/forms/forgotpassword";
import ForgotPassword from "./pages/Recovery/ForgotPassword";
import ResetPassword from "./pages/Recovery/ResetPassword";
// import ConfirmPasswordReset from "./components/forms/confirmPasswordReset";
import ConfirmPasswordReset from "./pages/Recovery/ResetPassword";
import HelpPage from "./pages/help";
import Logout from "./pages/logout";
import AccountActivation from "./pages/accountactivation";
import Dashboard from "../src/components/dashboard/dashboard";
import Explore from "./pages/Dashboard/Business/explore";

// Dashboard Components
import DashboardFeeds from "./components/dashboard/feedCard";
import SettingsPage from "./components/dashboard/accountmanagement/settings";
import ExplorePage from "./pages/explore";
import Home from "./pages/Home";
import Interests from "../src/components/interests/index";
import Gallery from "./pages/Gallery/gallery.jsx";
import Settings from "./pages/Settings";
import Messaging from "./pages/Messaging";
import Referral from "./pages/Reffferal/inddex";
import Queue from "./pages/queue";
import QueueBody from "./pages/queue/queueBody/queueBody";
import PreviewBooking from "./pages/Bookings/previewBooking";

import WelcomePage from "./pages/PrivateSeller/welcomePage";
import PageSetup from "./pages/PrivateSeller/pageSetup";
import PrivateSellerProfile from "./pages/PrivateSeller/PrivateSellerManagement/profile";
import PrivateSellerDashboard from "./pages/PrivateSeller/PrivateSellerManagement/dashboard";
import PrivateSellerListings from "./pages/PrivateSeller/PrivateSellerManagement/listings";
import CreateListing from "./pages/PrivateSeller/PrivateSellerManagement/createListing";
import Booking from "./pages/Bookings/booking";
import BookingRequest from "./pages/Bookings/bookingRequest";
import CreateBooking from "./pages/Bookings/createBooking";
import BusinessSellerDashboard from "./pages/BusinessSeller/dashboard";
import BusinessSellerProfile from "./pages/BusinessSeller/profile";
import BusinessSellerListings from "./pages/BusinessSeller/listing";
import BusinessSellerCreateListings from "./pages/BusinessSeller/createListing";
import BusinessSellerManageListings from "./pages/BusinessSeller/manageListing";
import Refer from "./pages/refer";
import MyActivityPage from "./pages/Activity";

// Jobs and Connects
import Jobs from "./pages/Dashboard/Jobs/Job";
import JobHistory from "./pages/Dashboard/Jobs/jobHistory/JobHistory";
import AllJobs from "./pages/Dashboard/Jobs/AllJobs/ManageAllJobs";
import CreateJob from "./pages/Dashboard/Jobs/createJob/CreateJob";
import RecievedApplication from "./pages/Dashboard/Jobs/RecievedApplications/RecievedApplications";
import Connect from "./pages/Dashboard/Connect/Connect";
import ManageConnect from "./pages/Dashboard/Connect/ManageConnects/Manage";
// import Manage157 from "./pages/Dashboard/Connect/ManageConnects/Manage157";
import SinglePost from "./pages/singlePost";
import SingleComments from "./pages/singleComments";
import Saved from "./pages/Saved";
import ConnectModal from "./pages/Dashboard/Connect/ConnectModals/connectModal";
import ShowProfile from "./pages/Dashboard/Connect/ManageConnects/createProfile/ShowProfile";
import CreateProfile from "./pages/Dashboard/Connect/ManageConnects/createProfile/CreateProfile";
import EditProfile from "./pages/Dashboard/Connect/ManageConnects/createProfile/EditProfile";
import UploadJobImage from "./pages/Dashboard/Jobs/createJob/imageForm";
import TransactionHistory from "./pages/Dashboard/Connect/TransactionHistory/TransactionHistory";
import Payment from "./pages/Payments/Payment";
import BusinessSellerAnalytics from "./pages/Analytics/BusinessAnalytics";
import PrivateSellerAnalytics from "./pages/Analytics/PrivateSellerAnalytics";
// import Analytics from "./pages/Analytics/analytics";
import Cart from "./pages/Payments/Cart/CartPage";
import CartPayment from "./pages/Payments/Cart/CartPayment/PaymentPage";
import MyWallet from "./pages/Payments/Wallet/MyWallet";
import WalletHistory from "./pages/Payments/Wallet/WalletHistory";
import GroupBusinessSetup from "./pages/PrivateSeller/pageGroup";
import BusinessSellerEditListing from "./pages/BusinessSeller/businessSellerEditListing";
import EditListing from "./pages/PrivateSeller/PrivateSellerManagement/editListing";

export const Routes = [
  {
    path: "/jobimage",
    name: "Job Image",
    component: UploadJobImage,
    public: true,
  },
  { path: "/help", name: "Help", component: HelpPage, public: true },
  { path: "/signin", name: "Sign in", component: SignIn, public: true },
  { path: "/signup", name: "Sign Up", component: SignUP, public: true },
  { path: "/gallery", name: "Gallery", component: Gallery, public: true },

  {
    path: "/welcomepage",
    name: "Welcome Page",
    component: WelcomePage,
    public: true,
  },
  {
    path: "/activity",
    name: "My Activity",
    component: MyActivityPage,
    public: true,
  },
  {
    path: "/pagesetup",
    name: "Page Setup",
    component: PageSetup,
    public: false,
  },
  {
    path: "/privateseller/profile/:pageId?",
    name: "PrivateSeller Profle",
    component: PrivateSellerProfile,
    public: true,
  },
  {
    path: "/privateseller/dashboard/",
    name: "PrivateSeller Dashboard",
    component: PrivateSellerDashboard,
    public: false,
  },
  {
    path: "/privateseller/listings/:pageId/:focus?",
    name: "PrivateSeller Requested",
    component: PrivateSellerListings,
    public: true,
  },
  {
    path: "/privateseller-checkout",
    name: "PrivateSeller Checkout",
    component: PrivateSellerListings,
    public: true,
  },
  {
    path: "/privateseller-history",
    name: "PrivateSeller History",
    component: PrivateSellerListings,
    public: true,
  },
  {
    path: "/privateseller/createlistings",
    name: "PrivateSeller Create Listing",
    component: CreateListing,
    public: false,
  },
  {
    path: "/privateseller/edit-listing/:pageId/:productId",
    name: "BusinessSeller Create Listings",
    component: EditListing,
    public: false,
  },
  { path: "/booking", name: "Booking", component: Booking, public: true },
  {
    path: "/bookingrequest",
    name: "Booking Request",
    component: BookingRequest,
    public: true,
  },
  {
    path: "/createbooking",
    name: "Create Booking",
    component: CreateBooking,
    public: true,
  },
  {
    path: "/preview_booking",
    name: "Preview Booking",
    component: PreviewBooking,
    public: true,
  },

  {
    path: "/businesspagesetup/",
    name: "Business Page Setup",
    component: GroupBusinessSetup,
    public: false,
  },

  {
    path: "/businessseller/dashboard",
    name: "BusinessSeller Dashboard",
    component: BusinessSellerDashboard,
    public: false,
  },

  {
    path: "/businessseller/profile/:pageId",
    name: "BusinessSeller Profile",
    component: BusinessSellerProfile,
    public: true,
  },

  {
    path: "/businessseller/listings/:pageId/:focus?",
    name: "BusinessSeller Listings",
    // component: BusinessSellerListings,
    component: BusinessSellerManageListings,
    public: true,
  },

  {
    path: "/businessseller/createlistings",
    name: "BusinessSeller Create Listings",
    component: BusinessSellerCreateListings,
    public: false,
  },
  {
    path: "/businessseller/edit-listing/:pageId/:productId",
    name: "BusinessSeller Create Listings",
    component: BusinessSellerEditListing,
    public: false,
  },

  {
    path: "/businessseller/requested/:pageId",
    name: "BusinessSeller Requested",
    component: BusinessSellerManageListings,
    public: true,
  },

  {
    path: "/businessseller-checkout",
    name: "BusinessSeller Checkout",
    component: BusinessSellerManageListings,
    public: true,
  },

  {
    path: "/businessseller-history",
    name: "BusinessSeller History",
    component: BusinessSellerManageListings,
    public: true,
  },

  {
    path: "/businessseller/queue-list",
    name: "BusinessSeller Queue List",
    component: Queue,
    public: false,
  },

  {
    path: "/businessseller/queue_request",
    name: "BusinessSeller Queue Request",
    component: Queue,
    public: false,
  },

  {
    path: "/businessseller/queue_history",
    name: "BusinessSeller Queue History",
    component: Queue,
    public: false,
  },

  {
    path: "/businessseller/edit_queue",
    name: "BusinessSeller Create Queue",
    component: Queue,
    public: false,
  },

  {
    path: "/businessseller/Job",
    name: "BusinessSeller Jobs",
    component: Jobs,
    public: false,
  },

  {
    path: "/businessseller/job-history",
    name: "BusinessSeller Job History",
    component: Jobs,
    public: false,
  },

  { path: "/ref", name: "Referral", component: Referral, public: true },
  { path: "/queue_list", name: "Queue", component: Queue, public: true },
  { path: "/queue_history", name: "Queue", component: Queue, public: true },
  { path: "/edit_queue", name: "Queue", component: Queue, public: true },
  { path: "/preview_queue", name: "Queue", component: Queue, public: true },
  { path: "/queue_request", name: "Queue", component: Queue, public: true },
  { path: "/queue", name: "Queue Body", component: QueueBody, public: true },

  {
    path: "/forgotpassword",
    name: "Forgot password",
    component: ForgotPassword,
    public: true,
  },
  {
    path: "/resetpassword",
    name: "Reset password",
    component: ResetPassword,
    public: true,
  },
  { path: "/messages", name: "Messaging", component: Messaging, public: true },
  {
    path: "/feeds/:focus/:username?",
    name: "feeds",
    component: Home,
    public: false,
  },
  {
    path: "/post/:postId/:pageId?",
    name: "single feed",
    component: SinglePost,
    public: false,
  },
  {
    path: "/comment/:commentId",
    name: "comment",
    component: SingleComments,
    public: false,
  },
  {
    path: "/saved/",
    name: "saved",
    component: Saved,
    public: true,
  },
  // {
  //   path: "/dashboard",
  //   name: "gallery",
  //   component: Gallery,
  //   public: true,
  // },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    public: false,
  },
  {
    path: "/activate/email/:id",
    name: "activate",
    component: AccountActivation,
    public: true,
  },
  { path: "/logout", name: "logout", component: Logout, public: true },
  // {
  //   path: "/reset/password/:uid/:id",
  //   name: "passwordReset",
  //   component: ConfirmPasswordReset,
  //   public: true,
  // },

  {
    path: "/explore",
    name: "Explore",
    component: Explore,
    public: true,
  },
  {
    path: "/interests",
    name: "interests",
    component: Interests,
    public: true,
  },
  {
    path: "/business/refer",
    name: "Refer",
    component: Refer,
    public: true,
  },
  {
    path: "/jobs",
    name: "Booking Jobs",
    component: Jobs,
    public: true,
  },
  {
    path: "/job-history",
    name: "Booking Job History",
    component: JobHistory,
    public: true,
  },
  {
    path: "/received_applications",
    name: "Received Applications Job",
    component: RecievedApplication,
    public: true,
  },
  {
    path: "/create-job",
    name: "Create a Booking Job",
    component: CreateJob,
    public: true,
  },
  {
    path: "/all-jobs",
    name: "All Job",
    component: AllJobs,
    public: true,
  },
  {
    path: "/businessseller/connect",
    name: "Connect",
    component: Connect,
    public: true,
  },
  {
    path: "/businessseller/manage-connect",
    name: "Manage Connect",
    component: ManageConnect,
    public: true,
  },
  {
    path: "/businessseller/show-profile",
    name: "Show Profile",
    component: ShowProfile,
    public: true,
  },
  {
    path: "/businessseller/create-profile",
    name: "Create Profile",
    component: CreateProfile,
    public: true,
  },
  {
    path: "/edit-profile",
    name: "Edit Profile",
    component: EditProfile,
    public: true,
  },
  {
    path: "/businessseller/transaction-history",
    name: "Transaction History",
    component: TransactionHistory,
    public: true,
  },
  {
    path: "/businessseller/analytics",
    name: "Business Seller Analytics",
    component: BusinessSellerAnalytics,
    public: true,
  },
  {
    path: "/privateseller/analytics",
    name: "Private Seller Analytics",
    component: PrivateSellerAnalytics,
    public: true,
  },
  {
    path: "/payment",
    name: "Payment",
    component: Payment,
    public: true,
  },
  {
    path: "/carts",
    name: "Cart",
    component: Cart,
    public: true,
  },
  {
    path: "/cart-payment",
    name: "CartPayment",
    component: CartPayment,
    public: true,
  },
  {
    path: "/wallet",
    name: "MyWallet",
    component: MyWallet,
    public: true,
  },
  {
    path: "/wallet-history",
    name: "Wallet History",
    component: WalletHistory,
    public: true,
  },
  {
    path: "/privateseller-referral",
    name: "Refer",
    component: Refer,
    public: true,
  },
];

export const DashboardRoutes = [
  {
    path: "/dashboard",
    name: "Feeds",
    component: DashboardFeeds,
    public: true,
  },
  {
    path: "/dashboard/settings",
    name: "Settings",
    component: SettingsPage,
    public: true,
  },
  {
    path: "/dashboard/messages",
    name: "Messages",
    component: Messaging,
    public: true,
  },
];
