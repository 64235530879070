import { useContext, useState } from 'react'
import ModalContext from '../../hooks/modalContexts'
import './index.scss'
import profilePic from '../../assets/img/connectProfilePic.png'
import { Heart } from '../../assets/svg/PostCardIcon'
import MsgIcon from '../../assets/svg/msgIcon'
import Phone from '../../assets/svg/phone'
import { FaTimes, FaHeart, FaCircle} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { Suggested } from "./UserData"
import { IoMdClose } from "react-icons/all"

const AcceptedModal = () => {
    const {acceptedModal, handleAcceptedModal} = useContext(ModalContext)
    return(
      <div
        onClick={()=>{handleAcceptedModal('RESET_ACCEPTED_TO_DISPLAY')}}
      className="incoming-modal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="incoming-container"
       >
          <div
            onClick={()=>{handleAcceptedModal('RESET_ACCEPTED_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
            <div className="profileArea">
                <div className="connectProfile">
                    <img src={profilePic} alt="" />

                    <div className="name">
                        <h4>
                            Maria Sponge
                        </h4>
                        <p>
                            @Username
                        </p>
                    </div>
                    <div className="accepted">
                      <span> <FaCircle/></span>
                      <p>Accepted</p>
                    </div>
                </div>
                <div className="description">
                  <div className='connect-suggestion-wrap'>
                      {Suggested.map((suggest) => (
                        <div key={suggest.id} className='connect-suggestion-card'>
                          <div className='connect-suggestion-user'>
                            <div>
                              <img src={suggest.Image} alt='' />
                            </div>
                            <div>
                              <h2>{suggest.Item}</h2>
                              <div className="user-items">
                                <div className="items">
                                  {suggest.OrderDate}
                                  <span>11 Oct 2021, 10am</span>
                                </div>
                                <div className="items">
                                  {suggest.Qty}
                                  <span>80</span>
                                </div>
                                <div className="items">
                                  {suggest.TotalAmount}
                                  <span>$1200</span>
                                </div>
                            </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                <div className="delivery">
                  <div className="delivery-header">
                    <h2>Delivery Details</h2>
                  </div>
                  <div className="details-items">
                    <div className="items">
                      Delivery Mode
                      <span>11 Oct 2021, 10am</span>
                    </div>
                    <div className="items">
                      Delivery Date
                      <span>80</span>
                    </div>
                    <div className="items">
                      Delivery Fee
                      <span>$1200</span>
                    </div>
                </div>
                <div className="details-items">
                  <div className="items">
                    Address
                    <span>No. 29 Thames avenue New york city, 200129</span>
                  </div>
              </div>
                </div>

          </div>
        </div>
      </div>
    )
}
export default AcceptedModal
