import { useDispatch } from "react-redux";
import FeedContainer from "../../components/FeedContainer";
import greeting from "../../utils/greeting";
import "./refer.scss";
import { toggleRightBar } from "../../appstore/navigationDisplayToggle/index.action";
import { useEffect, useState } from "react";
import { Search } from "../../assets/svg/NavBarIcons";
import Bookings from "./bookings";
import Listings from "./Listings";
import { useHistory, useLocation } from "react-router-dom";
import PrivateSellerReferral from "./privateSellerRefer";
import useActivity from "../../hooks/activity";
import useReferral from "../../hooks/referrals";
import qs from "query-string";
import Loader from "../../components/loader";
const Refer = () => {
  const { history, location } = useHistory();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("bookings");
  //toggle right bar off
  useEffect(() => {
    dispatch(toggleRightBar(false));
    return () => dispatch(toggleRightBar(true));
  }, []);

  const { pathname } = useLocation();

  const businessRefer = "/business/refer";
  const privateSellerReferral = "/privateseller-referral";

  const headerText = () => {
    if (location.pathname.includes(businessRefer)) {
      return "Refer";
    } else return "Referrals";
  };

  const { search } = useLocation();

  const { id } = qs.parse(search);
  const { handleGetAllProducts, allProducts } = useReferral();
  useEffect(() => {
    pathname.includes(privateSellerReferral) && handleGetAllProducts(id);
  }, []);
  console.log(headerText(), "head");
  return (
    <FeedContainer>
      <div className="refer">
        <p>
          {greeting()} <span>Jonathan</span>
        </p>
        <div className="wrap">
          <div className="header__wrap">
            <p> {headerText()}</p>
          </div>
          <div className="body__section">
            {pathname.includes(businessRefer) && (
              <>
                <div className="tab-header">
                  <div className="tab">
                    <div
                      className={`booking ${
                        activeTab === "bookings" && "active"
                      }`}
                      onClick={(e) => setActiveTab("bookings")}
                    >
                      Bookings
                    </div>
                    <div
                      className={`listing ${
                        activeTab === "listing" && "active"
                      }`}
                      onClick={(e) => setActiveTab("listing")}
                    >
                      Listing
                    </div>
                  </div>
                  <form className="my__pages__search_tab">
                    {/* icon to be placed */}
                    <div className="search__icon">
                      <Search
                        width="14px"
                        height="14px"
                        fill="var(--dark-purple-bg)"
                      />
                    </div>
                    <input type="text" placeholder="Search" />
                  </form>
                </div>
                {activeTab === "bookings" ? <Bookings /> : <Listings />}
              </>
            )}
            {pathname.includes(privateSellerReferral) && (
              <>
                <div className="tab-header tab-header2">
                  <form className="my__pages__search_tab">
                    {/* icon to be placed */}
                    <div className="search__icon">
                      <Search
                        width="14px"
                        height="14px"
                        fill="var(--dark-purple-bg)"
                      />
                    </div>
                    <input type="text" placeholder="Searchy" />
                  </form>
                </div>
                {allProducts.loading && <Loader />}
                {allProducts.data?.results.length ? (
                  <PrivateSellerReferral data={allProducts.data.results} />
                ) : (
                  "No referral items"
                )}
              </>
            )}
            <div
              style={{
                cursor: "pointer",
              }}
              className="back"
              onClick={(e) => history.goBack()}
            >
              Back
            </div>
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};
export default Refer;
