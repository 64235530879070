import React, { useState, useEffect, useContext, useRef } from 'react'
import ThreeDots from "../../../../assets/svg/threeDots"
import "./index.scss"

const PaymentDropMenu = () => {

  const [pop, setPop] = useState(false)
  const dropdownContainer = useRef(null);
  const toggleDropdown = () => setPop(!pop);
  //dropdown click outside
  const handleClickOutside = (event) => {
    if (
      dropdownContainer.current &&
      !dropdownContainer.current.contains(event.target)
    ) {
      setPop(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

return(
  <div className="dropdown">
    <div className="menu-dropdown-card" ref={dropdownContainer}>
        <div onClick={toggleDropdown} className="threeDotDivs">
            <ThreeDots fill={'#787878'} height={18} width={8}/>
            {pop && <div className="popUp">
                <a href="#">
                    <span>
                        Download
                    </span>
                </a>
                <a href="#">
                    <span>
                        Delete
                    </span>
                </a>
            </div>}
        </div>
      </div>
    </div>
  )

}

export default PaymentDropMenu
