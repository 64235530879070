import useAxios from "axios-hooks";
import { useDispatch } from "react-redux";
import { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import useUploads from "../../hooks/uploads";
import { showToast } from "../ReactToast";

const useBooking = () => {
  const { handleUploads } = useUploads();
  const [loading, setLoading] = useState(false);

  const [allServices, setAllServices] = useState([]);
  const addServices = (service) => {
    setAllServices((prevState) => [...prevState, { service }]);
  };
  //////////////////SERVICES//////////
  //
  //
  //
  //
  //
  //GET Services
  const [{ ...pageServicesData }, getPageServices] = useAxios(
    {
      url: `/services/`,
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetPageServices = async (id) => {
    await getPageServices({
      params: {
        sudo: true,
        page: `${id}`,
      },
    });
  };

  //
  //GET Services
  const [{ ...servicesData }, getServices] = useAxios(
    {
      url: `/services/`,
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetServices = async () => {
    await getServices({
      params: {
        sudo: true,
        // page: `${id}`,
      },
    });
  };

  useEffect(() => {
    if (servicesData.data) {
      addServices(servicesData);
      console.log(servicesData.data?.results);
    }
  }, [servicesData.data]);

  //
  //
  //POST Services
  const [{ ...createServiceData }, createService] = useAxios(
    {
      // url: "/services",
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleCreateService = async ({ data, file }) => {
    setLoading(true);
    const newUploaded = [];
    // const upload = async () => {
    //   const formData = new FormData();
    //   formData.append("files", file);
    //   return (await handleUploads(formData)).data?.results[0].id;
    // };
    const formData = new FormData();
    formData.append("files", file);
    const uploadedData = await handleUploads(formData);

    newUploaded.push(uploadedData.data?.results[0]?.id);
    await createService({
      url: "/services/",
      data: {
        ...data,
        // ...(file && { gallary: await upload() }),
        ...(newUploaded?.length && {
          gallary: newUploaded,
        }),
      },
    });
    // console.log("upload file", upload())
    setLoading(false);
    console.log("handle create service", createServiceData);
    if (createServiceData?.response?.response?.status === 400) {
      // history.push(`/explore`);
      showToast("error", "An error occured");
      console.log(     " showToast error "   )
    }
  };

  useEffect(() => {
    if (createServiceData?.response?.response?.status === 400) {
      // history.push(`/explore`);
      showToast("error", "An error occured");
    }
  }, [createServiceData?.response]);

  //
  //
  //
  //RETRIEVE A SERVICE
  const [{ ...singleServiceData }, getSingleService] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetSingleService = async (id) => {
    try {
      await getSingleService({
        url: `/services/${id}/`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //DELETE A SERVICE
  const [{ ...deleteServiceData }, deleteService] = useAxios(
    {
      // url: "/services/:id/",
      method: "Delete",
    },
    {
      manual: true,
    }
  );

  const handleDeleteService = async (id) => {
    try {
      await deleteService({
        url: `/services/${id}/`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //UPDATE A SERVICE
  const [{ ...updateServiceData }, updateService] = useAxios(
    {
      // url: "/services/:id/",
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdateService = async (id,  data) => {
    try {
      await updateService({
        url: `/services/${id}/`,
        data
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //GET SERVICE LOCATIONS
  const [{ ...serviceLocationsData }, getServiceLocatiions] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetServiceLocations = async (id) => {
    try {
      await getServiceLocatiions({
        url: `/services/${id}/service_locations`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  // HANDLE GET PAGES
  const [{ ...getPagesData }, getPages] = useAxios(
    {
      url: "/pages/?sudo=false/",
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetPages = () => {
    getPages();
  };

  ///////////////////////PERSONNELS////////////////////
  //
  //
  //
  //
  //
  //GET PERSONNELS
  const [{ ...personnelData }, getPersonnel] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetPersonnels = async (id) => {
    try {
      await getPersonnel({
        url: `/personnels/?service=${id}`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //PATCH UPDATE PERSONNEL
  const [{ ...updatePagePersonnelData }, updatePagePersonnel] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdatePagePersonnel = async (id, data) => {
    try {
      await updatePagePersonnel({
        url: `/personnels/${id}/`,
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //PATCH UPDATE PERSONNEL  Availability
  const [{ ...updatePersonnelAvailabilityData }, updatePersonnelAvailability] = useAxios(
    {
      method: "PATCH",
    },
    {
      manual: true,
    }
  );

  const handleUpdatePersonnelAvailability = async (id, id2, data) => {
    try {
      await updatePersonnelAvailability({
        url: `/personnels/${id}/availability/${id2}/`,
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //POST ADD PERSONNEL  Availability
  const [{ ...addPersonnelAvailabilityData }, addPersonnelAvailability] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handleAddPersonnelAvailability = async (id, data) => {
    try {
      await addPersonnelAvailability({
        url: `/personnels/${id}/availability/`,
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //DELETE REMOVE PERSONNEL  Availability
  const [{ ...removePersonnelAvailabilityData }, removePersonnelAvailability] = useAxios(
    {
      method: "DELETE",
    },
    {
      manual: true,
    }
  );

  const handleRemovePersonnelAvailability = async (id, data) => {
    try {
      await removePersonnelAvailability({
        url: `/personnels/${id}/availability/`,
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //POST PERSONNELS    //POST create page personnel
  const [{ ...postPagePersonnelData }, postPagePersonnel] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handlePostPagePersonnels = async (data) => {
    try {
      await postPagePersonnel({
        url: `/personnels/`,
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //POST PERSONNELS    // POST add personnel to service
  const [{ ...postPersonnelData }, postPersonnel] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handlePostPersonnels = async (data, id) => {
    try {
      await postPersonnel({
        url: `/services/${id}/personnels/`,
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //DELETE A PERSONNEL
  const [{ ...deletePersonnelData }, deletePersonnel] = useAxios(
    {
      method: "Delete",
    },
    {
      manual: true,
    }
  );

  const handleDeletePersonnel = async (id) => {
    try {
      await deletePersonnel({
        url: `/services/${id}/personnels/`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  //
  //
  //DELETE A Page PERSONNEL
  const [{ ...deletePagePersonnelData }, deletePagePersonnel] = useAxios(
    {
      method: "Delete",
    },
    {
      manual: true,
    }
  );

  const handleDeletePagePersonnel = async (id) => {
    try {
      await deletePagePersonnel({
        url: `/personnels/${id}/`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //GET PERSONNEL TIME SLOT
  const [{ ...personnelTimeSlotData }, getPersonnelTimeSlot] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetPersonnelTimeSlot = async (id, date) => {
    try {
      await getPersonnelTimeSlot({
        url: `personnels/${id}/timeslots/${date}/`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //
  //RETRIEVE A personnels
  const [{ ...singlePersonnelData }, getSinglePersonnel] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetSinglePersonnel = async (id) => {
    try {
      await getSinglePersonnel({
        url: `/personnels/${id}/`,
      });
    } catch (err) {
      console.log(err);
    }
  };


    //
  //
  //
  //RETRIEVE A SERVICE personnels
  const [{ ...servicePersonnelData }, getServicePersonnel] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetServicePersonnel = async (id) => {
    try {
      await getServicePersonnel({
        url: `/services/${id}/personnels/`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  //
  //
  //
  // POST add personnel to service

  //
  //
  //RETRIEVE A PAGE FOR LOCATION
  const [{ ...singlePageData }, getSinglePage] = useAxios(
    {
      method: "get",
    },
    {
      manual: true,
    }
  );

  const handleGetSinglePage = async (id) => {
    getSinglePage({
      url: `/pages/${id}`,
    });
  };

  //
  //
  // POST duration_within_working_hours
  const [{ ...personnelHoursData }, personnelHours] = useAxios(
    {
      method: "POST",
    },
    {
      manual: true,
    }
  );

  const handlePersonnelHours = async (id, data) => {
    try {
      await personnelHours({
        url: `/pages/${id}/duration_within_working_hours/`,
        data: {
          ...data,
        },
      });
    } catch (err) {
      console.log(err);
    }
  };

    //
  //
  //
  //RETRIEVE A PAGE OPENING AND CLOSING HOURS
  const [{ ...pageWorkingHoursData }, pageWorkingHours] = useAxios(
    {
      method: "GET",
    },
    {
      manual: true,
    }
  );

  const handleGetpageWorkingHours = async (id) => {
    try {
      await pageWorkingHours({
        url: `/pages/availability/${id}/`,
      });
    } catch (err) {
      console.log(err);
    }
  };

  return {
    handleGetServices,
    servicesData,
    allServices,
    handleCreateService,
    createServiceData,
    handleGetPages,
    getPagesData,
    personnelData,
    handleGetPersonnels,
    serviceLocationsData,
    handleGetServiceLocations,
    loading,
    deleteService,
    handleDeleteService,
    singleServiceData,
    handleGetSingleService,
    personnelTimeSlotData,
    handleGetPersonnelTimeSlot,
    deletePersonnel,
    handleDeletePersonnel,
    pageServicesData,
    handleGetPageServices,
    singlePageData,
    handleGetSinglePage,
    postPersonnelData,
    handlePostPersonnels,
    handleGetSinglePersonnel,
    singlePersonnelData,
    updateServiceData,
    handleUpdateService,
    postPagePersonnelData,
    handlePostPagePersonnels,
    handleDeletePagePersonnel,
    handleUpdatePagePersonnel,
    updatePagePersonnelData,
    personnelHoursData,
    handlePersonnelHours,
    updatePersonnelAvailabilityData,
    handleUpdatePersonnelAvailability,
    pageWorkingHoursData,
    handleGetpageWorkingHours,
    addPersonnelAvailabilityData,
    handleAddPersonnelAvailability,
    removePersonnelAvailabilityData,
    handleRemovePersonnelAvailability,
    deletePagePersonnelData,
    servicePersonnelData,
    handleGetServicePersonnel
  };
};

export default useBooking;
