import "./refferal.scss";
import { FaSortAmountDownAlt } from "react-icons/fa";
import FeedContainer from "../../components/FeedContainer";
import ReferralsData from "./referralsData";
import ReusableDropDown from "../../components/reusableDropdown";
const Referral = () => {
  const test = [
    {
      type: "product",
      user: { name: "Sam Dock", username: "@james100" },
    },
    {
      type: "service",
      user: { name: "Sam Dock", username: "@james100" },
    },
    {
      type: "service",
      user: { name: "Sam Dock", username: "@james100" },
    },
    {
      type: "product",
      user: { name: "Sam Dock", username: "@james100" },
    },
    {
      type: "service",
      user: { name: "Sam Dock", username: "@james100" },
    },
    {
      type: "product",
      user: { name: "Sam Dock", username: "@james100" },
    },
  ];

  const options = ["All", "Products", "Services", "Jobs"];
  return (
    <FeedContainer>
      <div className="referral">
        <div className="wrap">
          <section className="head">
            <h1>Referral</h1>
            <div className="show">
              <p>Show</p>
              <ReusableDropDown
                options={options}
                style={{ zIndex: 1, borderColor: "transparent" }}
              />
            </div>
            <div className="sort">
              <p>Sort By</p>
              <div className="input">
                Earliest to Latest{" "}
                <FaSortAmountDownAlt color="var(--bg-orange)" />
              </div>
            </div>
          </section>
          <div className="referrals__wrap">
            {test.map((data, idx) => (
              <ReferralsData data={data} key={idx} />
            ))}
          </div>
        </div>
      </div>
    </FeedContainer>
  );
};

export default Referral;
