import { useState, useEffect, useContext } from "react";
import "./previewBooking.scss";
import { FaCalendarAlt } from "react-icons/fa";
import Person from "../../assets/svg/person";
import { Link, useLocation, useHistory } from "react-router-dom";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import useBooking from "./useBooking";
import qs from "query-string";
import moment from "moment";
import { showToast } from "../../components/ReactToast";
import { Toaster } from "react-hot-toast";
import ModalContext from "../../hooks/modalContexts";

const PreviewBookingBody = ({
  nextStep,
  prevStep,
  values,
}) => {
  const [activeTab, setActiveTab] = useState(null);
  const [activeTab2, setActiveTab2] = useState(0);
  const history = useHistory();

  const {
    handleGetServices,
    servicesData,
    personnelData,
    handleGetPersonnels,
    singleServiceData,
    handleGetSingleService,
    personnelTimeSlotData,
    handleGetPersonnelTimeSlot,
    pageServicesData,
    handleGetPageServices,
    handleGetSinglePersonnel,
    singlePersonnelData,
    handleCreateService,
    createServiceData,
    handleUpdateService,
    updateServiceData,
  } = useBooking();

  const { personnelFormList, serviceToggleSubmit, setServiceToggleSubmit } =
  useContext(ModalContext);

  const testArray = [1, 2, 3];
  // const personnelId = personnelData?.data?.results;
  const [personnelId, setPersonnelId] = useState("");
  const [personnel2, setPersonnel2] = useState(false);
  const [date, setDate] = useState(new Date());
  console.log("this is a personnel Id", personnelId);

  // console.log(personnelId[0]?.id);
  // console.log("THis is personnel time", personnelTIme);

  const continueBtn = (e) => {
    e.preventDefault();
    nextStep();
  };

  const backBtn = (e) => {
    e.preventDefault();
    prevStep();
  };

  const submitFormData = (e) => {
    e.preventDefault();

    handleCreateService({ data: values, file });
    // handleCreateService(values);
    console.log(createServiceData);
    if (createServiceData?.response?.status === 200) {
      // history.push(`/explore`);
      // showToast("success", "created successfully");
    }
    if (createServiceData?.response?.response.status === 400) {
      // history.push(`/explore`);
      showToast("error", "An error occured");
    }
  };

  const updateFormData = (e) => {
    e.preventDefault();
    if (values?.personnel) {
      delete values.personnel;
      handleUpdateService(values.id, values);
    }
  };

  useEffect(() => {
    if (createServiceData?.response?.status === 200) {
      // history.push(`/explore`);
      showToast("success", "Service created successfully");
    }
  }, [createServiceData?.response]);

  useEffect(() => {
    if (updateServiceData?.response?.status === 200) {
      showToast("success", "Service updated successfully");
    }
  }, [updateServiceData?.response]);

  // useEffect(() => {
  //   if (createServiceData?.response?.response?.status === 400) {
  //     // history.push(`/explore`);
  //     showToast("error", "An error occured");
  //     console.log(     " showToast error -----"   )

  //   }
  // }, [createServiceData?.response?.response?.status]);

  useEffect(() => {
    handleGetPageServices(id);
    // initialTitle();
  }, []);

  const { title, description, personnel, file } = values;
  const { search } = useLocation();
  const { id } = qs.parse(search);

  console.log(pageServicesData);
  console.log("Single personnel data", singlePersonnelData?.data);

  return (
    <div className="previewbooking-body-container">
      <div className="pbc-card-body">
        <div className="pbc-cbc-card">
          <div className="pbc-cbcc-body">
            <div className="pb-top-header">
              <Toaster />
              <h4 className="sub-title"> Manage Booking</h4>
              {/* <Link className="sub-title-btn" to={`/createbooking/?id=${id}`}>
                  Edit Booking
                </Link> */}
              <button className="sub-title-btn" onClick={backBtn}>
                Edit Booking
              </button>
            </div>
            <div className="content-items">
              <h6> {title} </h6>
              <p>{description} </p>
              {/* <h6> {singleServiceData?.data?.title} </h6>
                <p>{singleServiceData?.data?.description} </p> */}
              {/* <p> {price_value_anybody} </p> */}
            </div>
            <div className="pb-preview-details">
              <div className="preview-sp-container">
                <div className="pb-services-details">
                  <div className="pb-purple-header">
                    <h6> Services </h6>
                  </div>
                  <div className="pb-services-info">
                    <div
                      className={!personnel2 ? "active" : "services-items"}
                      onClick={() => {
                        setPersonnel2(false);
                        setActiveTab(null);
                      }}
                    >
                      <h5>{title}</h5>
                    </div>
                    {!pageServicesData.loading &&
                      !pageServicesData.error &&
                      pageServicesData?.data?.results
                        ?.slice(0, 10)
                        .map((service, idx) => (
                          <div
                            className={
                              activeTab === idx
                                ? "active services-items"
                                : "services-items"
                            }
                            onClick={() => {
                              setActiveTab(idx);
                              handleGetPersonnels(service?.id);
                              handleGetSingleService(service?.id);
                              setPersonnel2(true);
                            }}
                            key={idx}
                            onChange={() => {
                              handleGetPersonnels(service?.id);
                              handleGetSingleService(service?.id);
                              console.log(service.id);
                            }}
                          >
                            <h5>{service.title}</h5>
                          </div>
                        ))}
                    {pageServicesData.loading && <p>Loading..</p>}
                    {!pageServicesData.loading && pageServicesData.error && (
                      <h5> An Error Occured Loading services</h5>
                    )}
                  </div>
                </div>

                <div className="pb-personnel-details">
                  <div className="pb-purple-header">
                    <div className="circle_ph">
                      <Person />
                    </div>
                    <h6> Personnel </h6>
                  </div>
                  <div className="pb-personnel-info">
                    {!personnel2 &&
                      personnel?.map((personnel, idx) => (
                        <div
                          className={
                            activeTab2 === idx
                              ? "active personnel-items"
                              : "personnel-items"
                          }
                          onClick={() => {
                            // setPersonnelTime(personnelTIme[idx]);
                            handleGetPersonnelTimeSlot(
                              personnel.id,
                              personnel.available_from
                            );
                            handleGetSinglePersonnel(personnel.id);
                            setPersonnelId(personnel.id);
                            setActiveTab2(idx);
                          }}
                          key={idx}
                        >
                          <div className="pi-box">
                            <span>{idx + 1}</span>
                            <div className="pi-box-details">
                              <h5> {personnel?.username}</h5>
                              <h6> {personnel.title} </h6>
                            </div>
                          </div>
                        </div>
                      ))}

                    {!personnelData.loading &&
                      !personnelData.error &&
                      personnel2 &&
                      personnelData?.data?.results
                        ?.slice(0, 10)
                        .map((personnel, idx) => (
                          <div
                            className={
                              activeTab2 === idx
                                ? "active personnel-items"
                                : "personnel-items"
                            }
                            onClick={() => {
                              // setPersonnelTime(personnelTIme[idx]);
                              handleGetPersonnelTimeSlot(
                                personnel.id,
                                personnel.available_from
                              );
                              handleGetSinglePersonnel(personnel.id);
                              setPersonnelId(personnel.id);
                              console.log(
                                personnel.available_from,
                                personnel.id
                              );
                              setActiveTab2(idx);
                            }}
                            key={idx}
                          >
                            <div className="pi-box">
                              <span>{idx + 1}</span>
                              <div className="pi-box-details">
                                <h5> {personnel.user.username}</h5>
                                <h6> {personnel.title} </h6>
                              </div>
                            </div>
                          </div>
                        ))}
                  </div>
                </div>
              </div>

              <div className="preview-calendar-container">
                <h3>
                  <FaCalendarAlt /> Appointment Schedule
                </h3>
                <div className="calendar">
                  <Calendar
                    onChange={(date) => {
                      setDate(date);
                      // let newEditPersonnel = userPersonnel.find((elem, idx) => {
                      //   return idx === id;
                      // });
                      handleGetPersonnelTimeSlot(
                        personnelId,
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                    value={date}
                  />
                </div>
                <div className="time">
                  <div className="time-header">
                    <h4>{`${moment(date).format("dddd")}`} </h4>
                    {/* <h5>09:00am - 6:00pm</h5>  */}
                  </div>
                  <div className="slide-container">
                    {!personnelTimeSlotData.loading &&
                      !personnelTimeSlotData.error &&
                      personnelTimeSlotData?.data?.count === 0 && (
                        <h3>Personnel is not available</h3>
                      )}
                    <div className="item__scroll">
                      <div className="time-body">
                        {!personnelTimeSlotData.loading &&
                          !personnelTimeSlotData.error &&
                          personnelTimeSlotData?.data?.results?.map(
                            (personnel, idx) => (
                              <>
                                <h6 className="active" key={idx}>
                                  {`${moment
                                    .utc(personnel.opening_time)
                                    .format("h:mm a")}`}
                                </h6>
                              </>
                            )
                          )}
                      </div>
                    </div>
                    <Slick dots={true} slidesToShow={1}></Slick>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="btn-box-footer">
        <button
          className="back-link"
          // type="submit"
          // to={`/createbooking/?id=${id}`}
          onClick={backBtn}
        >
          Back
        </button>
        <button
          className="bk-btn"
          type="submit"
          disabled={
            serviceToggleSubmit ? createServiceData.loading : updateServiceData.loading
          }
          onClick={serviceToggleSubmit ? submitFormData : updateFormData}
        >
          {/* <Link className="bk-btn" to="/explore"> */}
          {createServiceData.loading ? "Loading..." : "Done"}
        </button>
      </div>
    </div>
  );
};

export default PreviewBookingBody;
