import React, { useContext, useEffect, useState } from "react";
import { FaSearch, FaTimes } from "react-icons/fa";
import "./modalstyle.scss";
import userimg from "../../assets/img/Ellipse 96.png";
import ModalContext from "../../hooks/modalContexts";
import { useHistory, useParams } from "react-router";
import useUser from "../../hooks/user";
import NoData from "../NoData";
import LoaderPurple from "../../assets/loaders/LoaderPurple";
import { useMemo } from "react";

const UserModal = (props) => {
  const history = useHistory();
  const { userModal, ...context } = useContext(ModalContext);
  const {
    handleGetFollowing,
    followingData,
    handleGetFollowers,
    followersData,
  } = useUser();
  const { username } = useParams();
  const [users, setUsers] = useState([]);
  const count = useMemo(() => {
    if (userModal.type === "following") return followingData.data?.count;
    if (userModal.type === "followers") return followersData.data?.count;
    if (userModal.type === "supporting") return;
  }, [followersData.data?.count, followingData.data?.count]);
  const [search, setSearch] = useState();
  /* Add following code to Parent layout for the modal */

  // const [showModal, setShowModal] = useState(false);

  /* Button to trigger Modal */

  /*  <button className="btn-modal" onClick={() => setShowModal(true)}>
  Show modal
</button>

<UserModal
  showModal={showModal}
  onClose={() => setShowModal(false)}
/>

*/

  useEffect(() => {
    username && userModal.type === "following"
      ? handleGetFollowing(null, username, { limit: 12 })
      : userModal.type === "followers" &&
        handleGetFollowers(null, username, { limit: 12 });

    //if followers

    return () => {
      setUsers([]);
    };
  }, [username]);

  useEffect(() => {
    if (followersData.data || followingData.data) {
      if (userModal.type === "following") {
        setUsers((state) => [...state, ...followingData.data.results]);
      }
      if (userModal.type === "followers") {
        setUsers((state) => [...state, ...followersData.data.results]);
      }
    }
  }, [followingData.data, followersData.data]);

  useEffect(() => {
    const delayedReq = setTimeout(async () => {
      if (search && search?.trim() !== "") {
        setUsers([]);
        userModal.type === "following" &&
          (await handleGetFollowing(null, username, { limit: 12, search }));
        userModal.type === "followers" &&
          (await handleGetFollowers(null, username, { limit: 12, search }));
      }
    }, 500);
    return () => {
      clearTimeout(delayedReq);
    };
  }, [search]);
  return (
    <div
      className={`modalContainer show`}
      onClick={(e) => context.handleUserModal()}>
      <div className="userModal-content" onClick={(e) => e.stopPropagation()}>
        <div className="userModal-header">
          <div className="userModal-title-box">
            <h4 className="userModal-title">
              {props.title}
              {userModal.type}
              <span className="userModal-subTitle">{count}</span>
            </h4>
          </div>
          <div className="has-search">
            <div className="l-searchBox">
              <span className="">
                <FaSearch className="search-icon" />
              </span>
              <form className="">
                <input
                  className=" l-search"
                  type="search"
                  placeholder="search"
                  aria-label="Search"
                  onChange={(e) => setSearch(e.target.value)}
                />
              </form>
            </div>
            <div className="close" onClick={(e) => context.handleUserModal()}>
              <FaTimes className="close-modal cm-2" />
            </div>
          </div>
        </div>
        {users.length ? (
          <div className="userModal-body">
            {props.children}
            {users.map((data, idx) => {
              return (
                <div
                  className="usf-box"
                  key={idx}
                  onClick={() =>
                    history.push(`/feeds/timeline/${data.username}`)
                  }>
                  <img className="user-img" src={userimg} alt="" />
                  <div className="modal-flex">
                    <div className="usd-box">
                      <h5 className="usd-box-title">{data.full_name}</h5>
                      <h6 className="usd-box-subtitle">@{data.username}</h6>
                    </div>
                    {((userModal.type === "followers" && data.followed_them) ||
                      (userModal.type === "following" &&
                        data.followed_you)) && (
                      <div className="ff-box">
                        <button className="ffw-btn">
                          {userModal.type === "followers"
                            ? "Following"
                            : "Follower"}
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        ) : followersData.loading || followingData.loading ? (
          <LoaderPurple style={{ width: 20, height: 20 }} />
        ) : (
          <NoData>Empty</NoData>
        )}

        <div className="userModal-footer"></div>
      </div>
    </div>
  );
};

export default UserModal;
