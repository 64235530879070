import profileImg from "../../assets/img/Ellipse 320.PNG";
import itemImg from "../../assets/img/Rectangle 1890.PNG";

const JobHistoryData = [
  {
  list: "001",
  avatar: profileImg,
  name: "Maria Sponge",
  userName: "@Username",
  jobTitle: "Job title: Sale, Manager",
  jobType: "Job type: ",
  jobTime: "Full time",
  salary: "Job Salary: ",
  jobMoney: "$1000 - $2000 Monthly",
  deadline: "Posted 20th July, 2021 ",
  jobEnd: "Ends in 3 days",
  viewFull: 'View Full'
},
{
list: "002",
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
jobTitle: "Job title: Sale, Manager",
jobManager: "Sale, Manager",
jobType: "Job type: ",
jobTime: "Full time",
salary: "Job Salary: ",
jobMoney: "$1000 - $2000 Monthly",
deadline: "Posted 20th July, 2021 ",
jobEnd: "Ends in 3 days",
viewFull: 'View Full'
},
{
list: "003",
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
jobTitle: "Job title: Sale, Manager",
jobManager: "Sale, Manager",
jobType: "Job type: ",
jobTime: "Full time",
salary: "Job Salary: ",
jobMoney: "$1000 - $2000 Monthly",
deadline: "Posted 20th July, 2021 ",
jobEnd: "Ends in 3 days",
viewFull: 'View Full'
},
{
list: "004",
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
jobTitle: "Job title: ",
jobManager: "Sale, Manager",
jobType: "Job type: ",
jobTime: "Full time",
salary: "Job Salary: ",
jobMoney: "$1000 - $2000 Monthly",
deadline: "Posted 20th July, 2021 ",
jobEnd: "Ends in 3 days",
viewFull: 'View Full'
},
{
list: "005",
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
jobTitle: "Job title: ",
jobManager: "Sale, Manager",
jobType: "Job type: ",
jobTime: "Full time",
salary: "Job Salary: ",
jobMoney: "$1000 - $2000 Monthly",
deadline: "Posted 20th July, 2021 ",
jobEnd: "Ends in 3 days",
viewFull: 'View Full'
},
{
list: "006",
avatar: profileImg,
name: "Maria Sponge",
userName: "@Username",
jobTitle: "Job title: ",
jobManager: "Sale, Manager",
jobType: "Job type: ",
jobTime: "Full time",
salary: "Job Salary: ",
jobMoney: "$1000 - $2000 Monthly",
deadline: "Posted 20th July, 2021 ",
jobEnd: "Ends in 3 days",
viewFull: 'View Full'
},
]

export const Options = [
  { label: "All", value: 355 },
  { label: "Live", value: 355 },
  { label: "Ended", value: 54 },
  { label: "Promoted", value: 43 },
]

export default JobHistoryData
