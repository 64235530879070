export const formatText = (text = "") => {
  const changeBreaks = text.replace(/<\/div>/gi, " \n ");
  const removeOtherTags = changeBreaks.replace(/<.+?>/g, ``);
  return removeOtherTags;
};

export const handlePostText = (text = "", page) => {
  const hashtagsReplaced = text.replace(/#\w*/g, (word) => {
    return `<a href="somepathtohashtag/${word.replace(
      "#",
      ``
    )}" class="hashtag">${word}</a>`;
  });

  //filter posts for usernames
  const usernameReplaced = hashtagsReplaced.replace(/@\w*/g, (username) => {
    const removedMentionSymbol = username.replace("@", ``);
    return `<a href="${
      page
        ? `/${
            page.type.toLowerCase() === "business"
              ? "businessseller"
              : "privateseller"
          }/profile/${page.id}`
        : `/feeds/timeline/${removedMentionSymbol}`
    }" class="username__post__text">${removedMentionSymbol}</a>`;
  });
  return usernameReplaced;
};
