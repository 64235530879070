/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const IconawesomeBriefcaseComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 27.429 24" {...props}>
    <defs>
      <style>{".a{fill:#fff;}"}</style>
    </defs>
    <path
      className="a"
      d="M17.143,18.536a.857.857,0,0,1-.857.857H11.143a.857.857,0,0,1-.857-.857V15.964H0v7.714A2.637,2.637,0,0,0,2.571,26.25H24.857a2.636,2.636,0,0,0,2.571-2.571V15.964H17.143ZM24.857,7.393H20.571V4.821A2.636,2.636,0,0,0,18,2.25H9.429A2.636,2.636,0,0,0,6.857,4.821V7.393H2.571A2.636,2.636,0,0,0,0,9.964V14.25H27.429V9.964A2.636,2.636,0,0,0,24.857,7.393Zm-7.714,0H10.286V5.679h6.857Z"
      transform="translate(0 -2.25)"
    />
  </svg>
);

export default IconawesomeBriefcaseComponent;
