import CustomCheckBox from "../../../customCheckBox";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import { date } from "yup";
import CircleIcon from "../../../../assets/svg/circleIcon";
import moment from "moment";
import Time from "../../../MinsComponent";
import RebuildArr from "../../../../pages/Settings/component/Business/priavateSellerComponents/rebuildArr";
import rebuild from "../../../../pages/Settings/component/Business/priavateSellerComponents/rebuild";
import usePrivateSellerDashboard from "../../../../hooks/privateSellerDashboard";
import { useEffect, useState } from "react";
import findValue from "../helper/findValue";

const RequestedItem = ({ data, idx, handleGetCustomizedOrders }) => {
  const date = new Date();
  // date.setTime(data?.delivery_date?.opening_time)
  console.log(date, "DATE");

  data && console.log(findValue(data.delivery_date), "FUNCTTTTTTT");

  const { handleUpdateOrderStatus, updateOrderStatusData } =
    usePrivateSellerDashboard();
  const [opening_time, setOpening_time] = useState();
  const [closing_time, setClosing_time] = useState();
  const cutTime = (data) => {
    const splits = data?.split(":");
    return `${splits?.[0]}:${splits?.[1]}`;
  };
  useEffect(() => {
    if (data) {
      setOpening_time(cutTime(data.delivery_date?.opening_time));
      setClosing_time(cutTime(data.delivery_date?.closing_time));
    }
  }, [data]);

  useEffect(() => {
    updateOrderStatusData.data &&
      handleGetCustomizedOrders({ tab: "Requested", pre_order: "false" });
  }, [updateOrderStatusData.data]);

  return (
    <div className="bkr-cbc-card">
      <div className="bkr-cbcc-body">
        <div className="item__detail-box">
          {/* <div className="cb-1">
            <label className="check-label" htmlFor="001">
              <CustomCheckBox
                style={{
                  minWidth: 18,
                  height: 18,
                  borderColor: "var(--text-grey)",
                  borderRadius: 5,
                  marginRight: 10,
                }}
              />
              {idx + 1}
            </label>
          </div> */}
          <div className="orc-detail-box">
            <img src={data?.owner?.profile_image?.file} alt="" />
            <div className="orc-detail-box-user">
              <h4>{data?.owner?.full_name}</h4>
              <h6>@{data?.owner?.username}</h6>
            </div>
          </div>
        </div>
        <div className="cbcc-item__box">
          <img src={data?.product?.gallary?.[0]?.file} alt="" />
          <div className="item-detail-one">
            <h4>{data?.product?.title}</h4>
          </div>
          <div className="item-detail-two">
            <div>
              <h5>Price</h5>
              <p> ${data?.price}</p>
            </div>
          </div>
          <div className="item-detail-two">
            <div>
              <h6>Delivery schedule</h6>
              <h5>
                Date:{" "}
                <span>
                  {" "}
                  {opening_time} - {closing_time}
                </span>
                <br />
                <span
                  style={{
                    marginLeft: "5px",
                  }}
                >
                  {moment(data?.order_date).format("DD MMMM YYYY")}
                </span>
              </h5>
            </div>
          </div>
        </div>
        {data?.status === "PENDING" ? (
          <div className="item-btn-box">
            <button
              className="btn-accept"
              onClick={() => handleUpdateOrderStatus(data?.id, "ACCEPT")}
            >
              Accept
            </button>
            <button
              className="btn-decline"
              onClick={() => handleUpdateOrderStatus(data?.id, "DECLINED")}
            >
              Decline
            </button>
          </div>
        ) : (
          <div className="item-btn-box">
            <button className="btn-order btn-green-bg">
              <CircleIcon fill="#00C13A" />
              Auto accept
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default RequestedItem;
