import "./index.scss";
import { ImCancelCircle } from "react-icons/all";
const LocationAddress = ({ address, id, locationRemover = () => {} }) => {
  return (
    <div className="location-address-wrap">
      <div className="header-section">
        <div>Location {id}</div>
        <div className="icon" onClick={locationRemover.bind()}>
          <ImCancelCircle size={13} />
        </div>
      </div>
      <p className="text">{address}</p>
    </div>
  );
};

export default LocationAddress;
