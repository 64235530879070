const SocialSettings = [
    {
        name: 'General Account Settings'
    },
    {
        name: 'Privacy Settings'
    },
    {
        name: 'Security Settings'
    },
    {
        name: 'Notification Settings'
    },
    
]
export default SocialSettings