import React, {useState} from "react";
import "./allListing.scss";
// import "../../../../app.scss";
import avatar from "../../../assets/img/Ellipse 4.png";
import listing from "../../../assets/icons/image8.png";
import product from "../../../assets/explore/product.jpg";
import job from "../../../assets/explore/service.jpg";
import food from "../../../assets/explore/food.jpg";
import haircut from "../../../assets/explore/haircut.jpg";
import producticon from "../../../assets/explore/box-white.svg"
import locationicon from "../../../assets/explore/location.svg"
import serviceicon from "../../../assets/explore/food.png"
import jobicon from "../../../assets/explore/service.png"
import refericon from "../../../assets/explore/people.svg"
import {FaEnvelope, FaBookmark, FaShare, FaEllipsisV, FaLocationArrow, FaUsers} from "react-icons/fa";
import { IoMdClose } from "react-icons/all"
import ExploreCard from '../../../components/cards/exploreCards'
import ReusableDropDown from "../../../components/reusableDropdown";


const AllListing = () => {
	const options = [
		"Best Match",
		"Price [Low - High]",
		"Price [High - Low]",
		"Highest Rated]",
	];

	return (
		<>
			<section className="allListing">
				<div className="search-bar">
					<input
						className="search-bar"
						type="text"
						placeholder="Search "
						onChange=""
					/>					
				</div>
				<div className="closeIcon">
					<span> <IoMdClose /> </span>
				</div>

				<div className="list-cards">
					<ExploreCard
						img={product}
						title='Corporate Shirts for Men'
						location='Jamaica'
						type='Product'
						isReferrable= 'true'
						discount= '20'
						ctaText='Buy Now'
						price='500'
					/>

					<ExploreCard
						img={haircut}
						title='Neat Haircut'
						location='Kingston'
						type='Service'
						isReferrable= 'false'
						discount= ''
						ctaText='Book Now'
						price='20'
					/>

					<ExploreCard
						img={job}
						title='Cleaners Needed'
						location='Jainero'
						type='Job'
						isReferrable= 'false'
						discount= ''
						ctaText='Apply'
						jobType= 'Part Time'
					/>

					<ExploreCard
						img={product}
						title='Corporate Shirts for Men'
						location='Jamaica'
						type='Product'
						isReferrable= 'true'
						discount= '20'
						ctaText='Buy Now'
						price='500'
					/>

					<ExploreCard
						img={product}
						title='Corporate Shirts for Men'
						location='Jamaica'
						type='Product'
						isReferrable= 'false'
						discount= '12'
						ctaText='Buy Now'
						price='500'
					/>

				</div>
			</section>
		</>
	)
}

export default AllListing;