import { useState, useEffect } from "react";
import { FaCalendarAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "../../switch/index";
import { BiInfoCircle } from "react-icons/bi";
import CustomDropdown from "../../customDropdown/customDropdown";

const BookingPayment = ({
  heading = true,
  bookingForm,
  setBookingForm,
  handleBookingCheckbox,
}) => {
  /* datepicker */
  const [cancelationDate, setCancelationDate] = useState(new Date());
  // console.log(cancelationDate);
  const priceType = [{ label: "Fixed price $" }, { label: "Percentage %" }];
  const [paymentValue, setPaymentValue] = useState(priceType[0].label);

  return (
    <div className="payment-contents-container">
      <div className="pyc-cbc-card cbg-light ccp-0">
        {heading && (
          <div className="py-top">
            <h5 className="ps-title">Payment</h5>
          </div>
        )}
        <div className="cbc-cbcc-body">
          <div className="ccb__contents_payment">
            <div className="py-box">
              <div className="depo-box">
                <Switch
                  onChange={(e) => {
                    bookingForm.payment
                      ? setBookingForm((prevState) => ({
                          ...prevState,
                          payment: false,
                        }))
                      : handleBookingCheckbox("payment", true);
                  }}
                  state={bookingForm.payment}
                />
                <label htmlFor="switch" className="">
                  Deposit
                </label>

                <div className="dropbox">
                  <CustomDropdown
                    items={priceType}
                    value={paymentValue}
                    setValue={setPaymentValue}
                  />
                </div>
                <input
                  className="form-input-sm "
                  type="text"
                  placeholder="$ 90"
                />
              </div>
              <div className="cancel-date">
                <BiInfoCircle />
                <h6> Free Cancelation Date: </h6>

                <div className="datepicker-container">
                  <FaCalendarAlt />

                  <DatePicker
                    className="custom-date"
                    selected={cancelationDate}
                    onChange={(date) => setCancelationDate(date)}
                    dateFormat="MMM d, yyyy h:mm aa"
                    placeholderText="select date"
                    showTimeInput
                    timeInputLabel="Time"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BookingPayment;
