import react from "react";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { activate } from "../services/auth/index";
import LoadingOverlay from "react-loading-overlay";
import { userActivated } from "../appstore/actions/action";
import { CustomModal } from "../components/modal/customsignupmodal";
import cancel from "../assets/icons/error.png";
import { colors } from "../color";

class AccountActivation extends react.Component {
  constructor(props) {
    super(props);
    this.state = { isLoading: true, isActivated: false, visible: false };
  }

  componentDidMount = async () => {
    const { id } = this.props.match.params;
    const body = { key: id };

    try {
      const response = await activate(body, this.props.userActivated);
      response &&
        this.setState({
          isLoading: false,
          isActivated: "auth",
        });
    } catch (e) {
      e &&
        this.setState({
          isLoading: false,
          isActivated: "unauth",
          visible: true,
        });
    }
  };

  is_token_error = () => {
    return (
      <CustomModal
        text="Invalid token provided"
        color={colors.ERROR}
        src={cancel}
        visible={this.state.visible}
      />
    );
  };

  render() {
    return (
      <LoadingOverlay active={this.state.isLoading} spinner>
        <div style={{ height: "100vh" }}>
          {this.state.isActivated === "auth" ? (
            <Redirect to={{ pathname: "/signin" }} />
          ) : this.state.isActivated === "unauth" ? (
            this.is_token_error()
          ) : (
            ""
          )}
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(null, { userActivated })(AccountActivation);
