import { useMemo, useState } from "react";
import PostCardSingle from "../../components/PostCardsSingle";
import { useLocation, useHistory, useParams } from "react-router-dom";
import qs from "query-string";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePosts from "../../hooks/posts";
import FeedContainer from "../../components/FeedContainer";
import "./index.scss";
import BackArrow from "../../assets/svg/backArrow";
import infiniteScroll from "../../hooks/infiniteScroll";
import { clearComments } from "../../appstore/commentV2/commentV2.action";
import { clearSingleComment } from "../../appstore/singleCommentV2/singleCommentV2.actions";
import LoaderPurple from "../../assets/loaders/LoaderPurple";

const SinglePost = () => {
  const { postId } = useParams();
  const { handleGetSinglePost, comments, handleGetComments, singlePostData } =
    usePosts();
  const singlePost = useSelector((state) => state.singlePostV2);
  const { user } = useSelector((state) => state.currentUser);
  const [optionsVisibility, setOptionsVisibility] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    postId && handleGetSinglePost({ id: postId });

    return () => {
      dispatch(clearComments());
      dispatch(clearSingleComment());
    };
  }, [postId]);

  useEffect(() => {
    singlePost?.id && handleGetComments(singlePost.id);
    return () => {
      dispatch(clearComments());
    };
  }, [singlePost?.id]);

  const handleLoadMore = () => {
    handleGetComments(singlePost.id, comments.data?.next);
  };

  return (
    <FeedContainer>
      {singlePostData.loading ? (
        <div className="loader-cover">
          {" "}
          <LoaderPurple style={{ width: 30, height: 30, margin: "auto" }} />
        </div>
      ) : (
        singlePost && (
          <div
            className="single__post"
            onScroll={(e) =>
              infiniteScroll(e, handleLoadMore, comments.data?.next)
            }>
            <div className="back" onClick={(e) => history.goBack()}>
              <BackArrow />
              Back
            </div>
            {/* {console.log(singlePost, "testing here")} */}
            <PostCardSingle
              optionVisibility={[optionsVisibility, setOptionsVisibility]}
              {...singlePost}
              isOwner={singlePost.owner?.username === user.username}
            />
          </div>
        )
      )}
    </FeedContainer>
  );
};

export default SinglePost;
