import React, {  useState, useContext  } from "react";
import "./index.scss"
import Visa from "../../../../assets/svg/visa-color_large.svg"
import Master from "../../../../assets/svg/mastercard-light-large.svg"
import PayPal from "../../../../assets/svg/paypal-color-large.svg"
import Back from "../../../../assets/svg/arrow-right (2).svg"
import { FaPlus } from 'react-icons/fa'
import { MdReport } from 'react-icons/all'
import Slick from "react-slick"
import Slider from "react-slick"
import Right from "../../../../assets/svg/arrow-right (1).svg"
import Left from "../../../../assets/svg/arrow-right.svg"
import Cards from 'react-credit-cards'
import { css } from 'styled-components';
import { usePaymentInputs, PaymentInputsWrapper } from 'react-payment-inputs';
import CurrentPayPalCard from "./PaypalCard"

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={Left}
      className={className}
      style={{ ...style, display: "block", width: "30px", height: "30px", right: "20px", top: "100px" }}
      onClick={onClick}
      />
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
  <img
    src={Right}
    className={className}
    style={{ ...style, display: "block", width: "30px", height: "30px", left: "-20px", top: "100px" }}
    onClick={onClick}
    />
  );
}


const PaypalEdit = () => {
  const carouselSettings = {
    dots: false,
    infinite: false,
    // speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
     prevArrow: <PrevArrow />
  };
  const {
    meta, getCardNumberProps, getExpiryDateProps, getCVCProps
  } = usePaymentInputs();

  return(
    <div>
      <div className="cards-container">
          <div className="new-card-cont">
            <div className="new-card">
              <div className="add">
                <FaPlus />
              </div>
            </div>
          </div>

          <div className="current-card-cont">
            <div className="slick-crs">
              <Slick {...carouselSettings} className="slick">
                <CurrentPayPalCard />
                <CurrentPayPalCard />
              </Slick>
            </div>
          </div>
        </div>

        <div className="paypal-input">
            <div className="number-name">
                <div className="name">
                  <h5>Bank Name</h5>
                  <form className="input">
                    <input type="text" placeholder="Chase Bank" />
                  </form>
                </div>
                <div className="name">
                  <h5>Town County</h5>
                  <form className="input">
                    <input type="text" placeholder="Bank Name" />
                  </form>
                </div>
              </div>

                <div className="number-name">
                    <div className="name">
                      <h5>Account Holders First Name</h5>
                      <form className="input">
                        <input type="text" placeholder="Samson" />
                      </form>
                    </div>
                    <div className="name">
                      <h5>Account Holders Last Name</h5>
                      <form className="input">
                        <input type="text" placeholder="Matthew" />
                      </form>
                    </div>
              </div>
        </div>
      </div>
  )
}

export default PaypalEdit
