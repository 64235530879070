import React from "react";
import FormSignUp from "./formComponents/FormSignUp";
import "./SignUp.scss";
import Navbar from "../../components/layout/navbar";

function SignUp() {
  return (
    <div className="signUpContainer">
      <div id="roundedCircleTop"></div>

      <Navbar />
      <FormSignUp />
    </div>
  );
}

export default SignUp;
