import cx from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import Time, { Mins } from "../../MinsComponent";
import Dates from "./dates";
import CustomCheckBox from "../../customCheckBox";
// import GooglePlacesAutocomplete from "react-google-autocomplete";
import config from "../../../config/config.json";
import GooglePlacesAutocomplete, {
  getLatLng,
  geocodeByAddress,
} from "react-google-places-autocomplete";
import moment from "moment";
import { showToast } from "../../ReactToast";
import useDeliveryLocationSort from "../../../hooks/delivveryLocationSort";
import ModalContext from "../../../hooks/modalContexts";
// import Places from "../../googlePlaces";
const EditDeliveryInput = ({
  selectedOption = "",
  // setSelectedOption,
  // privateSeller = false,
  handleChange,
  deliveryState = [],
  deliveryListState = [],
  // deliveryMode = {},
  editState = [],
  defaultState = {},
  locationsState,
  city,
}) => {
  const { createListingData } = useContext(ModalContext);
  const [body] = createListingData;
  const [edit, setEdit] = editState;
  const [delivery, setDelivery] = deliveryState;
  const custom = selectedOption.toLowerCase() === "custom date & time";
  const { defaultDelivery } = defaultState;
  const [locations, setLocations] = locationsState;
  const [daysOfTheWeek, setTime] = useState([
    { day: "Monday", opening_time: "12am", closing_time: "12am" },
    { day: "Tuesday", opening_time: "12am", closing_time: "12am" },
    { day: "Wednesday", opening_time: "12am", closing_time: "12am" },
    { day: "Thursday", opening_time: "12am", closing_time: "12am" },
    { day: "Friday", opening_time: "12am", closing_time: "12am" },
    { day: "Saturday", opening_time: "12am", closing_time: "12am" },
    { day: "Sunday", opening_time: "12am", closing_time: "12am" },
  ]);
  const [selectedDay, setSelectedDay] = useState([]);
  // const [deliveryList, setDeliveryList] = deliveryListState;
  // const [times, setTimes] = useState({});

  // const handleSelectedDay = (idx) => {
  //   // console.log("called for some reason");
  //   const dayOfIdx = daysOfTheWeek.find((item, id) => id === idx && item).day;

  //   if (selectedDay.includes(idx)) {
  //     //remove
  //     setSelectedDay(selectedDay.filter((id) => idx !== id && id));

  //     const { custom_dates } = delivery;
  //     delete custom_dates[dayOfIdx];
  //     setDelivery({
  //       ...delivery,
  //       custom_dates,
  //     });
  //   } else {
  //     //add
  //     setSelectedDay([...selectedDay, idx]);
  //     setDelivery({
  //       ...delivery,
  //       custom_dates: {
  //         ...delivery.custom_dates,
  //         [dayOfIdx]: {
  //           from: times[dayOfIdx]?.from || "12am",
  //           to: times[dayOfIdx]?.to || "12am",
  //         },
  //       },
  //     });
  //   }
  // };

  // useEffect(() => {
  //   setDelivery({ ...delivery, free_delivery: freeDelivery });
  // }, [freeDelivery]);

  const handleDoneInputingData = () => {
    //make sure all required fields have been filled!
    try {
      if (!delivery.free_delivery) {
        if (Number(delivery.delivery_fee) <= 0) {
          // throw new Error(
          //   JSON.stringify({
          //     delivery_fee: "Cannot be $0 or less",
          //   })
          // );
          // delivery.free_delivery = true;
        }
      }
      if (delivery.address.trim() === "") {
        throw new Error(
          JSON.stringify({
            address: "address cannot be empty!",
          })
        );
      }
      if (!delivery.dates.length && !custom) {
        throw new Error(JSON.stringify({ dates: "dates cannot be empty!" }));
      }
      if (
        !delivery.point.coordinates.length &&
        !delivery.geom.coordinates.length
      ) {
        throw new Error(
          JSON.stringify({
            address: "There appears to be a problem with the selected address!",
          })
        );
      }
      if (custom) {
        if (!selectedDay.length) {
          throw new Error(
            JSON.stringify({
              custom_time: "Custom time must have at least a day set",
            })
          );
        }
      }
      setLocations([...locations, delivery]);
      setDelivery(defaultDelivery);
      setEdit(!edit);
    } catch (err) {
      console.log(JSON.parse(err.message));
      showToast(
        "error",
        JSON.parse(err.message)[Object.keys(JSON.parse(err.message))[0]]
      );
    }
  };

  const handleSelectedDay = (idx) => {
    //check if current idx exists
    const dateExists = selectedDay.find((date) => {
      return date.day === idx;
    });
    if (dateExists) {
      setSelectedDay(
        selectedDay.filter((day) => {
          return day.day !== idx;
        })
      );
    } else {
      setSelectedDay([
        ...selectedDay,
        {
          ...daysOfTheWeek[idx],
          day: idx,
        },
      ]);
    }
  };
  // console.log(moment(new Date().setHours(0)).minutes(0).format("hh:mm a"));
  useEffect(() => {
    const possiblyUpdated = selectedDay.map((sDay) => {
      const data = daysOfTheWeek.find((day, idx) => sDay.day === idx);
      return {
        ...data,
        day: sDay.day,
      };
    });
    setSelectedDay(possiblyUpdated);
  }, [daysOfTheWeek]);
  useEffect(() => {
    setDelivery({
      ...delivery,
      dates: selectedDay.map((data) => {
        return {
          ...data,
          opening_time: formatAmAndPm(data.opening_time),

          closing_time: formatAmAndPm(data.closing_time),
        };
      }),
    });
  }, [selectedDay]);
  const formatAmAndPm = (time) => {
    if (time.includes("am")) {
      let hour = Number(time.replace("am", ""));
      console.log(hour);
      hour = hour === 12 ? 0 : hour;
      console.log(hour);
      const justHour = moment().hours(hour).minutes(0).format("hh:mm");
      return justHour;
    }
    if (time.includes("pm")) {
      let hour = Number(time.replace("pm", ""));
      hour = hour === 12 ? hour - 12 : hour;
      const justHour = moment()
        .hours(hour + 12)
        .minutes(0)
        .format("hh:mm");
      return justHour;
    }
  };
  const { compareCity } = useDeliveryLocationSort();
  return (
    <>
      {custom && edit && (
        <div className="input-section custom">
          <span>Enter Address</span>
          <GooglePlacesAutocomplete
            type="text"
            // className="input"
            selectProps={{
              // value: delivery.address,
              defaultInputValue: delivery.address,
              onChange: async (e) => {
                const data = await geocodeByAddress(e.label);
                const { lat, lng } = await getLatLng(data[0]);
                // console.log(geoLocation);

                //set the delivery locations

                setDelivery({
                  ...delivery,
                  address: e.label,
                  point: {
                    ...delivery.point,
                    coordinates: [lng, lat],
                  },
                });
              },
            }}
            name="address"
            // onChange={(e) => console.log(e)}
            apiKey={config["map-key"]}
          />
          {/* <div className="add-more">Add more pickup locations</div> */}
        </div>
      )}

      <div className="edit-delivery-wrap">
        {edit && (
          <div className={cx("pickup-location", { custom })}>
            <div className="pickup-save-input" onClick={handleDoneInputingData}>
              Done
            </div>
            {!custom ? (
              <>
                <div className="set-date-address">
                  <div className="date">
                    <p className="set-fee">Set Delivery Fee</p>
                    <div className="fee">
                      ${" "}
                      <input
                        type="text"
                        className="input"
                        readOnly={delivery.free_delivery}
                        onChange={handleChange}
                        name="delivery_fee"
                        value={
                          delivery.free_delivery ? "" : delivery.delivery_fee
                        }
                      />
                    </div>
                    <p className="free">
                      Free{" "}
                      <CustomCheckBox
                        defaultValue={delivery.free_delivery}
                        onChange={(e) =>
                          setDelivery({ ...delivery, free_delivery: e.value })
                        }
                      />
                    </p>
                  </div>
                  <div className="address">
                    {/* <p>
                  Add Address <ReusableDropDown />
                </p> */}
                    <div className="input-section map-input">
                      <span>Enter Address</span>
                      {/* <Places
                        className="input"
                        
                      /> */}
                      <GooglePlacesAutocomplete
                        type="text"
                        // className="input"
                        selectProps={{
                          // value: delivery.address,
                          defaultInputValue: delivery.address,
                          onChange: async (e) => {
                            try {
                              const data = await geocodeByAddress(e.label);
                              const { lat, lng } = await getLatLng(data[0]);
                              // console.log(geoLocation);

                              if (!compareCity(e.label, city.address))
                                throw "select a location within the selected city";
                              //set the delivery locations

                              setDelivery({
                                ...delivery,
                                address: e.label,
                                point: {
                                  ...delivery.point,
                                  coordinates: [lng, lat],
                                },
                              });
                            } catch (err) {
                              showToast("error", err);
                            }
                          },
                        }}
                        name="address"
                        // onChange={(e) => console.log(e)}
                        // apiKey={config["map-key"]}
                      />
                    </div>
                  </div>
                </div>
                <p className="set-date">Set Date</p>
                {/* <div className="icon-date">
              <FaCalendarAlt />
              <div className="date-text">Jul 26 2012 10:00am</div>
            </div> */}
                <Dates deliveryState={deliveryState} body={body} />
              </>
            ) : (
              <div className="delivery-time">
                <h1>Delivery Time</h1>
                <div className="delivery-time-wrap">
                  <div className="average-time">
                    <span>
                      Average <br /> pickup time
                    </span>
                    <Mins
                      onChange={(e) => {
                        setDelivery({
                          ...delivery,
                          average_time: e,
                        });
                      }}
                    />
                    {/* <ReusableDropDown
                    options={mins}
                    style={{ maxWidth: 100, minWidth: 100 }}
                  /> */}
                  </div>
                  {daysOfTheWeek.map((data, idx) => {
                    // console.log(idx, selectedDay);
                    return (
                      <div
                        className={cx("time-box", {
                          selected: selectedDay.find((day) => day.day === idx),
                        })}
                        key={idx}>
                        <div className="day">
                          <CustomCheckBox
                            onClick={(e) => handleSelectedDay(idx)}
                            defaultValue={
                              selectedDay.find((day) => day.day === idx)
                                ? true
                                : false
                            }
                            customIsCheckedColor={"var(--bg-orange)"}
                          />
                          <span>{data.day}</span>
                        </div>
                        <div className="time">
                          <div className="from-time">
                            From{" "}
                            <span>
                              <Time
                                onChange={(e) => {
                                  const newDate = daysOfTheWeek.map(
                                    (date, index) => {
                                      if (index === idx) {
                                        return {
                                          ...date,
                                          opening_time: e,
                                        };
                                      } else return date;
                                    }
                                  );
                                  setTime(newDate);
                                }}
                              />
                            </span>
                          </div>

                          <div className="to-time">
                            To{" "}
                            <span>
                              <Time
                                onChange={(e) => {
                                  const newDate = daysOfTheWeek.map(
                                    (date, index) => {
                                      if (index === idx) {
                                        return {
                                          ...date,
                                          closing_time: e,
                                        };
                                      } else return date;
                                    }
                                  );
                                  setTime(newDate);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
        <div className="add-more" onClick={(e) => setEdit(true)}>
          Add more pickup locations
        </div>
      </div>
    </>
  );
};

export default EditDeliveryInput;
