const JobBusinessNotificationItem = ({jobNotificationItem, dataEntered, setDataEntered, idx}) => {
    return (
        <li>
            <span>{jobNotificationItem.title}</span>
            <div className={`switch ${jobNotificationItem.value === false  && 'off'}`} 
            onClick={()=>{
                
                setDataEntered({...dataEntered, job:dataEntered.job.map((data)=>{
                    let indexNo = dataEntered.job.indexOf(data)
                    if(indexNo === idx){
                        return data.value ? {...data, value: false}: {...data, value: true}
                    }
                    return data
                })})
            }}>
                <span className={`${jobNotificationItem.value === false && 'offSpan'}`} ></span>
            </div>
        </li>
    )
}
export default JobBusinessNotificationItem