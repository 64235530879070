import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useDeviceType from "../../../hooks/deviceType";
import PsCheckoutTab from "../../../components/PrivateSeller/Listings/PsCheckoutTab";
import PsRequestedTab from "../../../components/PrivateSeller/Listings/PsRequestedTab";
import FeedContainer from "../../../components/FeedContainer";
import ListingsHistory from "../../../components/PrivateSeller/Listings/listingsHistory";
import "./index.scss";
import { useDispatch } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../../appstore/navigationDisplayToggle/index.action";
import { useHistory, useLocation } from "react-router-dom";
import greeting from "../../../utils/greeting";

// export const PsRequested = "/privateseller-requested";
// export const PsHistory = "/privateseller-history";
// export const PsCheckout = "/privateseller-checkout";
import qs from "query-string";
import BackArrow from "../../../assets/svg/backArrow";
import usePages from "../../../hooks/createPage";

const PrivateSellerListings = () => {
  const { handleGetSinglePage, singlePageData } = usePages();

  const { pageId: id, focus } = useParams();
  // const PsRequested = `/privateseller-requested/?id=${id}`;
  // const PsHistory = `/privateseller-history/?id=${id}`;
  // const PsCheckout = `/privateseller-checkout/?id=${id}`;
  // const { isMobile, isDesktop } = useDeviceType();
  // const [activeTab, setActiveTab] = useState("tab1");

  // const handleTab1 = () => {
  //   // update the state to tab1
  //   setActiveTab("tab1");
  // };
  // const handleTab2 = () => {
  //   // update the state to tab2
  //   setActiveTab("tab2");
  // };
  // const handleTab3 = () => {
  //   // update the state to tab2
  //   setActiveTab("tab3");
  // };

  // const dispatch = useDispatch();
  // //handle needed nav manu
  // useEffect(() => {
  //   dispatch(toggleRightBar(false));
  //   dispatch(toggleMyPages(true));
  //   return () => {
  //     dispatch(toggleRightBar(true));
  //     dispatch(toggleMyPages(false));
  //   };
  // }, []);

  // const { pathname } = useLocation();
  const { location, goBack, push } = useHistory();
  useEffect(() => {
    handleGetSinglePage(id);
  }, []);
  return (
    <FeedContainer>
      {singlePageData.loading ? (
        <p>Please wait...</p>
      ) : (
        singlePageData.data && (
          <div className="ps-listings-container">
            <div className="lbc-header">
              <h1 className="welcome-title">
                <span
                  onClick={goBack}
                  style={{ cursor: "pointer", marginRight: "20px" }}>
                  <BackArrow />
                </span>
                {"   "}
                {greeting()}{" "}
                <span>{singlePageData.data.name.split(" ")[0]} </span>
              </h1>
              <div className="cl-btn-box">
                <Link
                  to={`/privateseller/createlistings/?id=${id}`}
                  className="cl-btn">
                  Create Listing
                </Link>
              </div>
            </div>
            <div className="lbc-card">
              <div className="lbc-select-box">
                <div className="tabs">
                  {/*----------  nav tab --------*/}
                  <ul className="nav-tab">
                    <li
                      className={`${!focus && "active"}`}
                      onClick={(e) => push(`/privateseller/listings/${id}`)}>
                      Requested
                    </li>
                    <li
                      className={`${
                        location.pathname.includes("checkout") && "active"
                      }`}
                      onClick={(e) =>
                        push(`/privateseller/listings/${id}/checkout`)
                      }>
                      Checkout
                    </li>
                    <li
                      // className={activeTab === "tab3" ? "active" : ""}
                      // onClick={handleTab3}
                      className={`${
                        location.pathname.includes("history") && "active"
                      }`}
                      onClick={(e) =>
                        push(`/privateseller/listings/${id}/history`)
                      }>
                      History
                    </li>
                  </ul>
                </div>
              </div>
              <div className="outlet">
                {/*---------------- tab contents --------------*/}
                {/* {activeTab === "tab1" ? <ListingsBodyDV /> : <ListingsHistory />} */}
                {/* {activeTab === "tab1" && <PsRequestedTab />}
            {activeTab === "tab2" && <PsCheckoutTab />}
            {activeTab === "tab3" && <ListingsHistory />} */}

                {!focus && <PsRequestedTab />}
                {location.pathname.includes("checkout") && <PsCheckoutTab />}
                {location.pathname.includes("history") && <ListingsHistory />}
              </div>

              {/* <ListingsHistory /> */}
            </div>
          </div>
        )
      )}
    </FeedContainer>
  );
};

export default PrivateSellerListings;
