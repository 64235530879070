import useAxios from "axios-hooks";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { storeNewPost } from "../appstore/savedPosts/index.action";

const useSaved = () => {
  const dispatch = useDispatch();
  const [{ ...savedData }, getSaved] = useAxios(
    {
      url: "/users/save_posts/",
      method: "get",
    },
    {
      manual: true,
    }
  );
  const hanldeGetSaved = async () => {
    await getSaved();
  };
  useEffect(() => {
    if (savedData.data) {
      dispatch(storeNewPost(savedData.data));
    }
    console.log(savedData.data);
  }, [savedData.data]);
  return {
    hanldeGetSaved,
    savedData,
  };
};

export default useSaved;
