import React, {useEffect, createContext, useState, useContext  } from "react";
import { Link, useHistory } from 'react-router-dom'
import './style.scss'
import useConnect from "../../hooks/connectHooks";
import ExploreContext  from '../../hooks/exploreContext'

function Index({
  connectProfileData,
  handleGetSingleProfile,
  singleProfileData,
  profileForm,
  setCurrentProfile,
  currentProfile,
  editData,
  editForm,
  titleForm,
}) {

  const {
    leftbarFormTitle,
    setLeftbarFormTitle,
    editTitleSave, 
    setEditTitleSave
  } = useContext(ExploreContext)

  const history = useHistory();

  // {index === 0 && (profileForm?.title || editForm?.title) ? (profileForm?.title || editForm?.title) : user.title}

  return (
    <div className="left-container">
    <div className='left-connect-bar'>
      <div>
        <h1>Connect Profile</h1>
        <ul>
          {leftbarFormTitle === false ? " " :
          <div className="top-p">
            <p
              className={
                (profileForm?.title || editForm?.title) && !currentProfile ? "active" : ""
              }
              >
              {(profileForm?.title || editForm?.title) ? (profileForm?.title || editForm?.title) : ""}
            </p>
          </div>
          }

          {connectProfileData?.data?.results
            ?.slice(0, 8)
            .map((user, index) => (
            <li key={user.id} 
            onClick={() =>{
              handleGetSingleProfile(user?.id)
              setCurrentProfile(user?.id)
            }}
            >
              <p
                  className={
                    user.id === currentProfile ? "active" : ""
                  }
                  >
                  {editTitleSave && currentProfile === user.id ? editTitleSave : user.title}
                </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
    <Link
        onClick={history.goBack}
         className='back-btn'
            >
        <h5>Back</h5>
    </Link>
  </div>
  )
}

export default Index
