import moment from "moment";
import {
  FaCartArrowDown,
  FaEnvelope,
  FaStar,
  FaBell,
  FaPlus,
  FaMoneyCheck,
  FaHistory,
} from "react-icons/fa";

const NotificationItem = ({ data }) => {
  console.log(data, "DATAA");
  return (
    <div className={`ncc-item-box ${data.read ? "read" : ""}`}>
      <FaEnvelope />
      <div className="ncc-ib-one">
        <h5>{data.type}</h5>
        <h6>{data.body}</h6>
      </div>
      <div className="ncc-ib-two">
        <h6>{moment(data.created_at).format("DD MMM YYYY")}</h6>
        <h6>{moment(data.created_at).format("h:mm a")}</h6>
      </div>
    </div>
  );
};
export default NotificationItem;
