import useAxios from "axios-hooks";
import { createContext } from "react";

export const ActivityContext = createContext();

const useActivity = () => {
  const [
    { loading: activityQueueLoading, data: activityQueue, error },

    getActivityQueue,
  ] = useAxios();

  const handleGetActivityQueueAndBooking = ({
    tab,
    ordering,
    created_at,
    created_at__gt,
    created_at__gte,
    created_at__lt,
    created_at__lte,
    condition,
  }) => {
    getActivityQueue(
      {
        url: `/bookings/${tab}/`,
        method: "get",
        params: {
          ordering: ordering,
          created_at: created_at,
          created_at__gt: created_at__gt,
          created_at__gte: created_at__gte,
          created_at__lt: created_at__lt,
          created_at__lte: created_at__lte,
          condition: condition,
        },
      },
      {
        manual: true,
      }
    );
  };

  const [{ ...jobsData }, getJobsData] = useAxios();

  const handleGetJobsData = ({
    ordering,
    created_at,
    created_at__gt,
    created_at__gte,
    created_at__lt,
    created_at__lte,
    condition,
  }) => {
    getJobsData({
      url: `/job-applications/myjob/`,
      method: "get",
      params: {
        ordering: ordering,
        created_at: created_at,
        created_at__gt: created_at__gt,
        created_at__gte: created_at__gte,
        created_at__lt: created_at__lt,
        created_at__lte: created_at__lte,
        condition: condition,
      },
    });
  };

  const [{ ...myOrders }, getMyOrders] = useAxios();

  const handleGetMyOrders = ({
    ordering,
    created_at,
    created_at__gt,
    created_at__gte,
    created_at__lt,
    created_at__lte,
    condition,
  }) => {
    getMyOrders({
      url: `/orders/myorder/`,
      method: "get",
      params: {
        ordering: ordering,
        created_at: created_at,
        created_at__gt: created_at__gt,
        created_at__gte: created_at__gte,
        created_at__lt: created_at__lt,
        created_at__lte: created_at__lte,
        condition: condition,
      },
    });
  };

  const [{ ...preOrderdata }, getPreOrder] = useAxios();
  const handleGetPreOrder = ({ id }) => {
    getPreOrder({
      url: "/products/pre_order/",
      method: "get",
      params: {
        page: id,
      },
    });
  };

  const [{ ...myReferrals }, getMyReferrals] = useAxios();
  const handleGetMyReferrals = ({
    ordering,
    created_at,
    created_at__gt,
    created_at__gte,
    created_at__lt,
    created_at__lte,
    condition,
  }) => {
    getMyReferrals({
      url: `/users/myreferrals/`,
      method: "get",
      params: {
        ordering: ordering,
        created_at: created_at,
        created_at__gt: created_at__gt,
        created_at__gte: created_at__gte,
        created_at__lt: created_at__lt,
        created_at__lte: created_at__lte,
        condition: condition,
      },
    });
  };

  const [{ ...makeReviewData }, makeReview] = useAxios();
  const handleMakeReview = ({
    tab: tab,
    id: id,
    body: body,
    rating: rating,
  }) => {
    makeReview({
      url: `${tab}/${id}/reviews/`,
      method: "post",
      data: {
        body: body,
        rating: rating,
      },
    });
  };

  const [{ ...updateBookingStatusData }, updateBookingStatus] = useAxios();
  const handleUpdateBooking = ({ status: status, id: id }) => {
    updateBookingStatus({
      url: `bookings/${id}/update_booking_request/`,
      method: "patch",
      data: {
        status: status,
      },
    });
  };

  const [{ ...cancelOrderData }, cancelOrder] = useAxios();

  const handleCancelOrder = (id) => {
    cancelOrder({
      url: `orders/${id}/cancel/`,
      method: "post",
    });
  };
  const [{ ...cancelPreOrderData }, cancelPreOrder] = useAxios();

  const handleCancelPreOrder = (id) => {
    cancelPreOrder({
      url: `products/${id}/cancel_pre_order/`,
      method: "post",
    });
  };
  const [{ ...processPreOrderData }, processPreOrder] = useAxios();

  const handleProcessPreOrder = (id) => {
    processPreOrder({
      url: `products/${id}/process_pre_order/`,
      method: "post",
    });
  };

  const [{ ...referralCommissions }, getReferralCommissions] = useAxios();
  const handleGetReferralCommission = (id) => {
    getReferralCommissions({
      url: `/users/${id}/referral_commissions/`,
      method: "get",
      // params: {
      //   product_id: id,
      // },
    });
  };
  return {
    handleGetActivityQueueAndBooking,
    activityQueue,
    activityQueueLoading,
    handleGetJobsData,
    jobsData,
    myOrders,
    handleGetMyOrders,
    myReferrals,
    handleGetMyReferrals,
    makeReviewData,
    handleMakeReview,
    updateBookingStatusData,
    handleUpdateBooking,
    cancelOrderData,
    handleCancelOrder,
    referralCommissions,
    handleGetReferralCommission,
    preOrderdata,
    handleGetPreOrder,
    cancelPreOrderData,
    handleCancelPreOrder,
    handleProcessPreOrder,
    processPreOrderData,
  };
};

export default useActivity;
