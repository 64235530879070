import { useState } from "react";

const CustomCheckBox = () => {
  const [isChecked, setIsChecked] = useState(false);
  return (
    <div
      style={{
        display: "inline-block",
        border: "1px solid var(--dark-purple-bg)",
        marginRight: "10px",
        marginBottom: "-2px",
        minWidth: "15px",
        height: "15px",
        borderRadius: 2,
        backgroundColor: isChecked ? "var(--dark-purple-bg)" : "transparent",
        cursor: "pointer",
      }}
      className="customCheckBox"
      onClick={(e) => setIsChecked(!isChecked)}></div>
  );
};

export default CustomCheckBox;
