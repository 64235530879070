import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../../../../appstore/userData/index.action";
import Loader from "../../../../../components/loader";
import useSettings from "../../../../../hooks/settings";
import { privateSellerUtils } from "../../../utils/privateSellerUtils";
import PrivateSellerNotificationItem from "./privateSellerNotificationItem";
import rebuild from "./rebuild";

const PrivateSellerNotificationSettings = ({ active }) => {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.currentUser.user.pages);

  const current = useSelector((state) => state.currentUser.user);

  const currentForMe = useSelector((state) => state.currentUser);

  const [pageIndex, setPageIndex] = useState();

  // use effect to filter and declare initial data

  const [currentPageData, setCurrentPageData] = useState();

  useEffect(() => {
    const data = Array.from(currentUser)
      .map((currentPage) => {
        if (currentPage.type === "PRIVATE") {
          setCurrentPageData(currentPage);
          setPageIndex(currentUser.indexOf(currentPage));
          return currentPage.business_settings.notification.Private;
        }
      })
      .filter((data) => data && data)[0];

    setPrivateSellerNotificationData(rebuild(data));
  }, [active]);

  console.log("page", current, pageIndex);

  // initial data that'll be set by useEffect

  const [privateSellerNotificationData, setPrivateSellerNotificationData] =
    useState([]);

  // state to define data to send

  const [dataToSend, setDataToSend] = useState();

  console.log("data", dataToSend);

  //use effect to modify data to send

  useEffect(() => {
    const arr = {};
    privateSellerNotificationData?.map((privateSellerNotificationDataItem) => {
      arr[privateSellerNotificationDataItem.title] =
        privateSellerNotificationDataItem.value;
    });
    setDataToSend(arr);
  }, [privateSellerNotificationData]);

  const {
    handlePrivateSellerNotificationPatchData,
    privateSellerNotificationPatchData,
  } = useSettings();

  console.log("my data", privateSellerNotificationPatchData);

  // useEffect to update redux

  useEffect(() => {
    console.log("here");
    if (privateSellerNotificationPatchData.data) {
      let mod = currentUser.map((item) => {
        if (currentUser.indexOf(item) === pageIndex) {
          return {
            ...item,
            business_settings: {
              ...item.business_settings,
              notification: {
                ...item.business_settings.notification,
                Private:
                  privateSellerNotificationPatchData?.data.notification.Private,
              },
            },
          };
        } else {
          return item;
        }
      });
      dispatch(
        setCurrentUser({
          ...currentForMe,
          user: { ...currentForMe.user, pages: mod },
        })
      );
      console.log(mod, "mod");
    }
  }, [privateSellerNotificationPatchData?.data]);
  return (
    <>
      <ul className="privateSeller_notification">
        {privateSellerNotificationData.map(
          (privateSellerNotificationDataItem, idx) => {
            return (
              <PrivateSellerNotificationItem
                privateSellerNotificationDataItem={
                  privateSellerNotificationDataItem
                }
                privateSellerNotificationData={privateSellerNotificationData}
                setPrivateSellerNotificationData={
                  setPrivateSellerNotificationData
                }
                idx={idx}
              />
            );
          }
        )}
      </ul>
      <div className="save">
        <div
          onClick={() => {
            handlePrivateSellerNotificationPatchData({
              id: currentPageData?.id,
              data: dataToSend,
              type: "private",
            });
          }}
          className="saveButton"
        >
          {!privateSellerNotificationPatchData?.loading ? (
            <span>Save</span>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </>
  );
};
export default PrivateSellerNotificationSettings;
