const ArchiveIcon = () => {
  return (
    <svg
      id="archive-minus"
      xmlns="http://www.w3.org/2000/svg"
      width="23.461"
      height="23.461"
      viewBox="0 0 23.461 23.461"
    >
      <path
        id="Vector"
        d="M3.414,0H0"
        transform="translate(10.023 10.411)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.3"
      />
      <path
        id="Vector-2"
        data-name="Vector"
        d="M14.715,0H4.208A4.117,4.117,0,0,0,0,4V18.6c0,1.865,1.406,2.653,3.128,1.751l5.319-2.809a2.417,2.417,0,0,1,2.038,0l5.319,2.809c1.722.912,3.128.124,3.128-1.751V4A4.134,4.134,0,0,0,14.715,0Z"
        transform="translate(2.267 1.366)"
        fill="none"
        stroke="#fff"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.3"
      />
      <path
        id="Vector-3"
        data-name="Vector"
        d="M0,0H23.461V23.461H0Z"
        fill="none"
        opacity="0"
      />
    </svg>
  );
};
export default ArchiveIcon;
