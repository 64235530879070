import React from "react";
import { Row, Col, ButtonGroup, Button, Badge } from "reactstrap";
import { Switch, Route } from "react-router-dom";

import LoadingOverlay from "react-loading-overlay";
import Styled from "styled-components";

// pages
import SettingsPage from "../accountmanagement/settings";
import DashboardFeeds from "./feeds";
import Gallery, { Input } from "../gallery/gallery";
import SideBar from "../sideBar";
import { colors } from "../../../color";
import { AddCircleOutline, Search } from "@material-ui/icons";

export const Main = Styled.div`
    background: #efefef;
    overflow: hidden;
    background-size: cover;
    height: 100%;
`;

const P = Styled.p`
    color: ${colors.COMPANY_COLOR};
    font-size: 14px;
    text-align: center;
`;
export const Postbtn = Styled.button`
    border: none;
    background: ${colors.COMPANY_COLOR};
    outline: none;
    height: 45px;
    width: 112px;
    font-size: 19px;
    color: #e3c7e9;
    border-radius: 10px;
`;

const ToggleBtn = Styled(Button)`
    background: ${colors.COMPANY_COLOR};
    width: 82px;
    height:  33px;
    outline: none;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;
`;

const Accounttoggle = Styled.div`
    height: 95px;
    background: white;
    width: 100%;
    border-radius: 10px;
    margin: 0;
    padding: 0;
`;
const Mypages = Styled.div`
  background: white;
  height: 300px;
  width: 100%;
  border-radius: 10px;
  margin-top: 80px;
`;
const ToggleBadge = Styled(Badge)`
    background: ${colors.ORANGE};
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const btn_Group = () => {
  return (
    <Accounttoggle>
      <P className="pt-2" style={{ fontWeight: "500" }}>
        Switch Account
      </P>
      <div className="d-flex justify-content-center">
        <ButtonGroup className="mt-2">
          <div>
            <ToggleBtn>
              <ToggleBadge
                className="rounded-circle"
                style={{
                  position: "absolute",
                  top: "-15%",
                  right: "90%",
                }}
              >
                0
              </ToggleBadge>
              Business
            </ToggleBtn>
          </div>
          <div>
            <ToggleBtn>
              <ToggleBadge
                className="rounded-circle"
                style={{
                  position: "absolute",
                  top: "-15%",
                  left: "90%",
                }}
              >
                0
              </ToggleBadge>
              Social
            </ToggleBtn>
          </div>
        </ButtonGroup>
      </div>
    </Accounttoggle>
  );
};

class UserDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LoadingOverlay
        active={this.state.isLoading}
        styles={{
          spinner: (base) => ({
            ...base,
            width: "200px",
            "& svg circle": {
              stroke: `${colors.COMPANY_COLOR}`,
            },
          }),
        }}
        spinner
      >
        <Main>
          <Col xl={11} style={{ margin: "30px auto" }}>
            <Row>
              <Col xl={2}>
                {btn_Group()}
                <SideBar />
                {/* Second div belowside bar... */}
                <Mypages>
                  <div className="d-flex justify-content-between pt-3 pr-2 pl-2">
                    <P style={{ fontSize: "12px", fontWeight: "700" }}>
                      My Pages
                    </P>
                    <Postbtn
                      className="d-flex justify-content-between rounded align-items-center"
                      style={{
                        height: "17px",
                        width: "65px",
                        fontSize: "7px",
                        color: "white",
                      }}
                    >
                      <AddCircleOutline
                        style={{ color: `${colors.ORANGE}`, fontSize: "11px" }}
                      />
                      Create new
                    </Postbtn>
                  </div>
                  <div
                    className="password-div rounded-pill mr-2 ml-2"
                    style={{ height: "30px" }}
                  >
                    <Search className="ml-2" style={{ fontSize: "17px" }} />
                    <Input
                      className="pl-3"
                      style={{ width: "60%" }}
                      placeholder="Search Pages"
                    />
                  </div>
                </Mypages>
              </Col>
              <Switch>
                <Route exact path="/dashboard/" component={DashboardFeeds} />
                <Route
                  exact
                  path="/dashboard/settings/"
                  component={SettingsPage}
                />
                <Route exact path="/dashboard/gallery/" component={Gallery} />
              </Switch>
            </Row>
          </Col>
        </Main>
      </LoadingOverlay>
    );
  }
}

export default UserDashboard;
