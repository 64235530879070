import useAxios from "axios-hooks";
import {useEffect, useState} from "react";
import { setCurrentUser } from "../appstore/userData/index.action";
import ExploreContext from "./exploreContext";
import { useContext } from "react";
import { useSelector } from "react-redux";
import useUploads from "./uploads";
import ModalContext from "./modalContexts";
import { showToast } from "../components/ReactToast";
import { base64toBlob } from "../utils/helpers";

const useExplore = () => {
  const {
    setAllListings,
    setResetAllListings,
    addProducts,
    addSelectedProduct,
    addServices,
    addSelectedService,
    setPersonnelTimesLoading,
    setServiceDataLoading,
    addJobPosts,
    setAllJobPosts,
    addSelectedJobPost,
    setJobPostsLoading,
    setJobPostDataLoading,
    setJobsLoading,
    setApplicationSaveLoading,
    setFetchAllListingsLoading,
    addJobTypes,
    setPageServices,
    addServiceLocations,
    setPersonnelDates,
    addJobNotifications,
    addMyJobPosts,
    setReceivedApplications,
    setAllJobPostApplications,
    setJobPostApplicationsLoading,
    setCreateJobLoading,
    setJobApplyLoading,
    setJobDeleteComplete,
    setJobCreateComplete,
    setRequestedQueue,
    setQueueCheckout,
    setQueueOrders,
    setQueueRequests,
    setQueueHistory,
    setCreateQueueLoading,
    setQueueJoined,
    setGetQueueListComplete,
  } = useContext(ExploreContext);

  const { setJobApplyModal, setApplySuccessModal, setUpdateFailModal, bookSuccessModal, setBookSuccessModal, setProductSuccessModal } =
    useContext(ModalContext);

  const { handleUploads } = useUploads();

  // ======= All listings =======
  const [{ ...getAllListingsData }, getAllListings] = useAxios({
    method: "GET"
  });

  const handleGetAllListings = () => {
    setFetchAllListingsLoading(true);
    getAllListings({
      url: `/listings/?ordering=price`,
    }).catch((error) => console.log(error));
  };

  useEffect(() => {
    if (getAllListingsData.data) {
      setFetchAllListingsLoading(false);
      setAllListings(getAllListingsData.data);
    }
  }, [getAllListingsData]);


  // ======= Products ======= 
  const [{ ...getProductsData }, getProducts] = useAxios({
    method: "GET",
  });
  const handleGetProducts = ({ page, rest }) => {
    getProducts({
      url: `/products`,
      params: {
        page,
        ...rest,
      },
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getProductsData.data) {
      addProducts(getProductsData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getProductsData.data]);


  // ======= Single Product ======= 
  const [{ ...getProductData }, getProduct] = useAxios({
    method: "GET",
  });
  const handleGetProduct = (id, param = {}) => {
    getProduct({
      url: `/products/${id}`,
      params: {
        ...param,
      },
    }).catch((error) => console.log(error));
  };

  useEffect(() => {
    if (getProductData.data) {
      addSelectedProduct(getProductData);
    }
  }, [getProductData.data]);


  // ======= Add Product to Cart ======= 
  const [{ ...addProductToCartData }, addProductToCart] = useAxios({
    method: "POST",
  });

  const handleAddProductToCart = async (item) => {
    await addProductToCart({
      url: "/orders/",
      data: {
        ...item,
      },
    })
    .then((res) => {
      if (res.status === 200) {
        // showToast("success", "Product purchased successfully!");

        // setTimeout(() => {
        //   setResetAllListings(true);  
        // }, 2000);
        
        // setTimeout(() => {
        //   setResetAllListings(false);
        // }, 4000);

        setProductSuccessModal(true);
      }
    })
    .catch((error) => console.log(error));
  };

  useEffect(() => {
    if (addProductToCartData.data) {
      // console.log(addProductToCartData)
    }
  }, [addProductToCartData]);


  // ======= Services ======= 
  const [{ ...getServicesData }, getServices] = useAxios({
    method: "GET",
  });
  const handleGetServices = () => {
    getServices({
      url: `/services/`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getServicesData.data) {
      addServices(getServicesData);
    }
  }, [getServicesData.data]);


  // ======= Single Service ======= 
  const [{ ...getServiceData }, getService] = useAxios({
    method: "GET",
  });
  const handleGetService = (id) => {
    setServiceDataLoading(true);
    getService({
      url: `/services/${id}/`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getServiceData.data) {
      setServiceDataLoading(false);
      addSelectedService(getServiceData);
    }
  }, [getServiceData.data]);

  // Page Services
  const [{ ...getPageServicesData }, getPageServices] = useAxios({
    method: "GET",
  });
  const handleGetPageServices = (id) => {
    getPageServices({
      url: `/services?sudo=true&page=${id}`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getPageServicesData.data) {
      setPageServices(getPageServicesData);
    }
  }, [getPageServicesData.data]);

  // Service Locations
  const [{ ...getServiceLocationsData }, getServiceLocations] = useAxios({
    method: "GET",
  });
  const handleGetServiceLocations = (id) => {
    getServiceLocations({
      url: `/services/${id}/service_locations`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getServiceLocationsData.data) {
      addServiceLocations(getServiceLocationsData);
    }
  }, [getServiceLocationsData.data]);

  // Service Personnel availability
  const [{ ...getPersonnelDatesData }, getPersonnelDates] = useAxios({
    method: "GET",
  });
  const handleGetPersonnelDates = (id, date) => {
    setPersonnelTimesLoading(true)
    getPersonnelDates({
      url: `/personnels/${id}/timeslots/${date}`,
    })
    .then((res) => {
      setPersonnelTimesLoading(false);
    })
    .catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getPersonnelDatesData.data) {
      console.log(getPersonnelDatesData);
      setPersonnelDates(getPersonnelDatesData);
    }
  }, [getPersonnelDatesData.data]);


  // Book a service
  const [{ ...bookServiceData }, bookService] = useAxios({
    method: "POST",
  });
  const [bookServiceLoading, setBookServiceLoading] = useState(false);
  const handleBookService = async (item) => {
    console.log('booking service...');
    if (!bookServiceLoading) {
      setBookServiceLoading(true);
      await bookService({
        url: "/bookings/",
        data: {
          ...item,
        },
      })
        .then((res) => {
          console.log(res.status);
          if (res.status !== 200) {
            showToast("error", "Something went wrong. Please try again later.");
          } else {
            setBookSuccessModal(true);
          }
        })
        .catch((error) => console.log(error));
    }
  };
  useEffect(() => {

    if (bookServiceData.data) {
      console.log(bookServiceData)
     }

    if (bookServiceData?.response?.status === 200) {
      setBookServiceLoading(false);
      bookServiceData.response = null;
    }
  }, [bookServiceData]);

  // Job Posts
  const [{ ...getJobPostsData }, getJobPosts] = useAxios({
    method: "GET",
  });
  const handleGetJobPosts = ({ page, have_received_application }) => {
    setJobPostsLoading(true);
    getJobPosts({
      url: `/job-posts/`,
      params: {
        page,
        have_received_application,
      },
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getJobPostsData.data) {
      setJobPostsLoading(false);
      setAllJobPosts(getJobPostsData.data);
    }
  }, [getJobPostsData.data]);

  // jobTypes
  const [{ ...getJobTypesData }, getJobTypes] = useAxios({
    method: "GET",
  });
  const handleGetJobTypes = async () => {
    getJobTypes({
      url: "/utils/types",
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getJobTypesData.data) {
      addJobTypes(getJobTypesData);
    }
  }, [getJobTypesData.data]);

  // Job Applications by Post
  const [{ ...getJobPostApplicationsData }, getJobPostApplications] = useAxios({
    method: "GET",
  });
  const handleGetJobPostApplications = (postId) => {
    if (postId !== null) {
      setJobPostApplicationsLoading(true);
      getJobPostApplications({
        url: `/job-applications/?job_post=${postId}&sudo=true`,
      }).catch((error) => console.log(error));
    }
  };
  useEffect(() => {
    if (getJobPostApplicationsData.data) {
      setJobPostApplicationsLoading(false);
      setAllJobPostApplications(getJobPostApplicationsData.data);
    }
  }, [getJobPostApplicationsData.data]);

  // Single Job Post
  const [{ ...getJobPostData }, getJobPost] = useAxios({
    method: "GET",
  });
  const handleGetJobPost = (id) => {
    setJobPostDataLoading(true);
    getJobPost({
      url: `/job-posts/${id}/`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getJobPostData.data) {
      setJobPostDataLoading(false);
      addSelectedJobPost(getJobPostData);
    }
  }, [getJobPostData.data]);

  // Job Application
  const [{ ...createJobApplicationData }, createJobApplication] = useAxios({
    method: "POST",
  });
  const handleCreateJobApplication = async (item) => {
    setJobApplyLoading(true);
    const newUploaded = [];
    if (item.files && item.files.length) {
      // const formData = new FormData();
      for (let i = 0; i < item.files.length; i++) {
        const formData = new FormData();
        let attachment = {};
        switch (item.files[i].type) {
          case "resume":
            attachment.name = item.files[i].file.name;
            attachment.type = "RESUME";
            break;
          case "cover":
            attachment.name = item.files[i].file.name;
            attachment.type = "RESUME";
            break;
          case "offer":
            attachment.name = item.files[i].file.name;
            attachment.type = "RESUME";
            break;
          case "additional":
            attachment.name = item.files[i].file.name;
            attachment.type = "RESUME";
            break;
          default:
            break;
        }

        formData.append("files", item.files[i].file);
        const uploadedData = await handleUploads(formData);
        attachment.files = [];
        attachment.files.push(uploadedData.data.results[0].id);
        newUploaded.push(attachment);
      }

      // let files = {};
      // files.files = item.files;
      // console.log(formData)
      // const { data: uploadedData } = await handleUploads(item.files);
      // const uploadedData = await handleUploads(formData);
      // console.log(uploadedData);
      // console.log(uploadedData.data.results);
      // newUploaded.results = uploadedData.data.results;
    }

    delete item.files;
    createJobApplication({
      url: "/job-applications/",
      data: {
        ...item,
        ...(newUploaded?.length && {
          attachments: newUploaded,
        }),
      },
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (createJobApplicationData.data) {
      // console.log(createJobApplicationData)
    }

    if (createJobApplicationData?.response?.status === 200) {
      setJobApplyLoading(false);
      setJobApplyModal(false);
      setApplySuccessModal(true);
      createJobApplicationData.response = null;
    }
    // if (createJobApplicationData.response !== null && createJobApplicationData.response.status !== 200) {
    //     console.log(createJobApplicationData.response)
    //     setJobApplyModal(false)
    //     setUpdateFailModal(true)
    //     createJobApplicationData.response = null;
    // }
  }, [createJobApplicationData]);


  // createJobPost
  const [{ ...createJobPostData }, createJobPost] = useAxios({
    method: "POST",
  });

  const handleCreateJobPosts = async (payload) => {
    setCreateJobLoading(true);
    const newUploaded = [];
    if (payload?.gallary?.length) {
      for (let i = 0; i < payload.gallary.length; i++) {
        const formData = new FormData();
        let attachment = [];

        formData.append("files", payload.gallary[i]);
        const uploadedData = await handleUploads(formData);

        console.log(uploadedData.data);
        // return;
        newUploaded.push(uploadedData.data?.results[0]?.id);
        // newUploaded.push(attachment);
      }
    }
    delete payload.gallary;

    createJobPost({
      url: "/job-posts/",
      data: {
        ...payload,
        ...(newUploaded?.length && {
          gallary: newUploaded,
        }),
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          showToast("success", "Job Post created successfully");
          setJobCreateComplete(true);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setCreateJobLoading(false);
      });
  };

  useEffect(() => {
    if (createJobPostData) {
      // console.log(createJobPostData);
    }
  }, [createJobPostData]);

  // getJobNotifications
  const [{ ...getJobNotificationsData }, getJobNotifications] = useAxios({
    method: "GET",
  });
  const handleGetJobNotifications = async () => {
    getJobNotifications({
      url: "/notifications",
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getJobNotificationsData.data) {
      addJobNotifications(getJobNotificationsData);
    }
  }, [getJobNotificationsData.data]);

  // getMyobPosts
  const [{ ...getMyJobPostsData }, getMyJobPosts] = useAxios({
    method: "GET",
  });
  const handleGetMyJobPosts = async () => {
    setJobsLoading(true);
    getMyJobPosts({
      url: "/job-posts/?ordering=rating",
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getMyJobPostsData.data) {
      setJobsLoading(false);
      addMyJobPosts(getMyJobPostsData);
    }
  }, [getMyJobPostsData.data]);

  // getReceivedApplications
  const [{ ...getReceivedApplicationsData }, getReceivedApplications] =
    useAxios({
      method: "GET",
    });
  const handleGetReceivedApplications = async () => {
    getReceivedApplications({
      url: "job-applications/?job_post=&condition=Live/?received=true",
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getReceivedApplicationsData.data) {
      setReceivedApplications(getReceivedApplicationsData);
    }
  }, [getReceivedApplicationsData.data]);

  // downloadApplicationDocs
  const [{ ...downloadApplicationDocData }, downloadApplicationDoc] = useAxios({
    method: "GET",
    responseType: "blob",
  });
  const handleDownloadApplicationDocument = (id) => {
    if (id) {
      setApplicationSaveLoading(true);
      downloadApplicationDoc({
        url: `job-applications/${id}/download?sudo=true`,
      }).catch((error) => console.log(error));
    }
  };
  useEffect(() => {
    setApplicationSaveLoading(false);
    if (downloadApplicationDocData.data) {
      const file = new Blob([downloadApplicationDocData.data], {
        type: "application/pdf",
      });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    }

  }, [downloadApplicationDocData.data]);

  // delete application
  const [{ ...deleteApplicationData }, deleteApplication] = useAxios({
    method: "DELETE",
  });
  const handleDeleteApplication = (id) => {
    deleteApplication({
      url: `job-applications/${id}`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (deleteApplicationData.data) {
      setJobDeleteComplete(true);
    }
  }, [deleteApplicationData.data]);

  // queue
  const [{ ...getRequestedQueueData }, getRequestedQueue] = useAxios({
    method: "GET",
  });
  const handleGetRequestedQueue = (id) => {
    getRequestedQueue({
      url: `bookings/?is_queue=1&condition=active&owner=${id}&queue_tab=requested`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (getRequestedQueueData.data) {
      setRequestedQueue(getRequestedQueueData);
    }
  }, [getRequestedQueueData.data]);

  // queueCheckout
  const [{ ...getQueueCheckoutData }, getQueueCheckout] = useAxios({
    method: "GET",
  });
  const handleGetQueueCheckout = (id) => {
    let today = new Date().toISOString();
    getQueueCheckout({
      url: `/bookings/?is_queue=1&queue_tab=checkout&ordering=-created_at&created_at=${today}&owner=${id}`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    setQueueCheckout(getQueueCheckoutData);
  }, [getQueueCheckoutData.data]);

  // listQueueOrders
  const [{ ...getQueueOrdersData }, getQueueOrders] = useAxios({
    method: "GET",
  });
  const handleGetQueueOrders = (page) => {
    getQueueOrders({
      url: `/bookings/?is_queue=1&service__page=${page}`,
    })
    .then((res) => {
      console.log(res);
      setGetQueueListComplete(true);
    })
    .catch((error) => console.log(error));
  };
  useEffect(() => {
    setQueueOrders(getQueueOrdersData);
  }, [getQueueOrdersData.data]);

  // getQueueRequests
  const [{ ...getQueueRequestsData }, getQueueRequests] = useAxios({
    method: "GET",
  });
  const handleGetQueueRequests = () => {
    getQueueRequests({
      url: `bookings/?is_queue=1&condition=active&queue_tab=requested`,
    })
    .then((res) => {
      console.log(res);
    })
    .catch((error) => console.log(error));
  };
  useEffect(() => {
    setQueueRequests(getQueueRequestsData);
  }, [getQueueRequestsData.data]);

  // update queue status
  const [{ ...updateQueueStatusData }, updateQueueStatus] = useAxios({
    method: "PATCH",
  });
  const handleUpdateQueueStatus = (id, status) => {
    updateQueueStatus({
      url: `bookings/${id}/update_queue_status/`,
      data: {
        "queue_status": status
      }
    }).catch((error) => console.log(error));
  };
  useEffect(() => {

  }, [updateQueueStatusData.data]);


  // getQueueHistory
  const [{ ...getQueueHistoryData }, getQueueHistory] = useAxios({
    method: "GET",
  });
  const handleGetQueueHistory = () => {
    getQueueHistory({
      url: `bookings/?is_queue=1&condition=active&queue_tab=history`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    setQueueHistory(getQueueHistoryData);
  }, [getQueueHistoryData.data]);

  // createQueue
  const [{ ...createQueueData }, createQueue] = useAxios({
    method: "POST",
  });
  const handleCreateNewQueue = async (payload) => {
    setCreateQueueLoading(true);
    const newUploaded = [];
    if (payload?.gallery?.length) {
      for (let i = 0; i < payload.gallery.length; i++) {
        const formData = new FormData();
        let attachment = [];

        formData.append("files", payload.gallery[i]);
        const uploadedData = await handleUploads(formData);

        console.log(uploadedData.data);
        // return;
        newUploaded.push(uploadedData.data?.results[0]?.id);
        // newUploaded.push(attachment);
      }
    }
    delete payload.gallery;

    createQueue({
      url: "/services/",
      data: {
        ...payload,
        ...(newUploaded?.length && {
          gallery: newUploaded,
        }),
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          showToast("success", "Queue created successfully");
        }
      })
      .catch((error) => console.log(error))
      .finally(() => {
        setCreateQueueLoading(false);
      });
  };
  useEffect(() => {
    if (createQueueData) {
      // console.log(createQueueData);
    }
  }, [createQueueData]);

  // joinQueue
  const [{ ...joinQueueData }, joinQueue] = useAxios({ method: "POST" });
  const handleJoinQueue = (payload) => {
    joinQueue({
      url: `/bookings/`,
      data: {
        ...payload,
      },
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (joinQueueData?.response?.status === 200) {
      setQueueJoined(true);
    }
  }, [joinQueueData.data]);

  // buyerCheckIfInQueue
  const [{ ...customerInQueueData }, customerInQueue] = useAxios({
    method: "GET",
  });
  const handleCheckCustomerInQueue = (id) => {
    customerInQueue({
      url: `/services/${id}/user_in_queue/`,
    }).catch((error) => console.log(error));
  };
  useEffect(() => {
    if (
      customerInQueueData?.response?.status === 200 &&
      customerInQueueData?.data?.status === true
    ) {
      setQueueJoined(true);
    }
  }, [customerInQueueData.data]);

  return {
    handleGetAllListings,
    handleGetProducts,
    handleGetProduct,
    handleAddProductToCart,
    handleGetServices,
    handleGetService,
    handleGetJobPosts,
    handleGetJobPost,
    handleCreateJobApplication,
    handleGetJobTypes,
    handleCreateJobPosts,
    handleBookService,
    handleGetPageServices,
    handleGetServiceLocations,
    handleGetPersonnelDates,
    handleGetReceivedApplications,
    handleGetMyJobPosts,
    handleGetJobPostApplications,
    handleGetJobNotifications,
    handleDownloadApplicationDocument,
    handleDeleteApplication,
    handleGetRequestedQueue,
    handleGetQueueCheckout,
    handleGetQueueOrders,
    handleGetQueueRequests,
    handleUpdateQueueStatus,
    handleGetQueueHistory,
    handleCreateNewQueue,
    handleJoinQueue,
    handleCheckCustomerInQueue,
    getProductsData,
    getProductData,
  };
};

export default useExplore;
