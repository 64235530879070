export const languages = [
  {
    language: "English",
    countryCode: "US",
  },
  {
    language: "French",
    countryCode: "FR",
  },
  {
    language: "Chinese",
    countryCode: "CN",
  },
  {
    language: "Russian",
    countryCode: "RU",
  },
  {
    language: "Arabic",
    countryCode: "AE",
  },
];
