import React, { useState, useEffect, useRef } from "react";
import "./menu.scss"
import {
  FaEllipsisV,
  FaClock,
} from "react-icons/fa";
import {BsThreeDotsVertical} from "react-icons/bs"

const data = [
  { id: 0, label: "Disconnect" },
  { id: 1, label: "Report"},
];

const MessageMenu = ({handleDisconnect}) => {
  const [isOpen, setOpen] = useState(false);
  const [items, setItem] = useState(data);
  const menuContainer = useRef(null);

  const togglemenu = () => setOpen(!isOpen);

  //menu click outside
  const handleClickOutside = (event) => {
    if (
      menuContainer.current &&
      !menuContainer.current.contains(event.target)
    ) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      //cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <div ref={menuContainer}>
      <div className="dd-menu">
        <div className="menu">
          <FaEllipsisV className="menu-icon" onClick={togglemenu} />
        </div>
        {isOpen && (
          <div className={`menu-body ${isOpen && "open"}`}>
              <div className="menu-item"  
               onClick={() =>{
                handleDisconnect();
                }} >
                 Disconnect
              </div>
              <div className="menu-item">
                 Report
              </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default MessageMenu;
