import { useContext } from "react"
import StarIcon from "../../../assets/svg/star"
import ModalContext from "../../../hooks/modalContexts"
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ReactStars from 'react-stars'
import { useState } from "react";
import Down from "../../../assets/svg/Down";
import { Checkbox } from "@material-ui/core";
import profilePicture from '../../../assets/img/profilePic.png'
import ReviewItem from "./reviewItem";
import CloseBtn from "../../../components/closeBtn";
const ReviewModal = () => {
    const {handleReviewModal} = useContext(ModalContext)
    const ratingChanged = (newValue) => {
        setRatingValue(newValue)
    }
    const [ratingValue, setRatingValue] = useState(4)
    const [sortByHistory, setSortByHistory] = useState('Oldest')
    const [sortByHistoryDrop, setSortByHistoryDrop] = useState(false)
    const [starDrop, setStarDrop] = useState(false)
    const reviewData = [
        {
            name: 'Rose Kotoo',
            date: 'Oct 20 2021',
            stars: 4,
            profilePic: profilePicture,
        },
        {
            name: 'Rose Kotoo',
            date: 'Oct 20 2021',
            stars: 4,
            profilePic: profilePicture,
        },
        {
            name: 'Rose Kotoo',
            date: 'Oct 20 2021',
            stars: 4,
            profilePic: profilePicture,
        },
        {
            name: 'Rose Kotoo',
            date: 'Oct 20 2021',
            stars: 4,
            profilePic: profilePicture,
        },
    ]
    return(
        <div className="reviewModalContainer">
            <div className="reviewModal">
                <CloseBtn onClick={()=> handleReviewModal('ADD_DATA', false)}/>
                <div className="ratingScore">
                    <div className="title">
                        <StarIcon />
                        <h4>
                            Ratings
                        </h4>
                    </div>
                    <div className="score">
                        <div className="centerScore_div">
                            <div className="ratingScore_div">
                                <CircularProgressbarWithChildren  maxValue={5} value={ratingValue} counterClockwise='true' styles={{
                                    path: {
                                        stroke: '#FBB03B'
                                    }
                                    
                                }}>
                                    <h4>
                                        {ratingValue}
                                    </h4>
                                    <p>
                                        Out of 5
                                    </p>
                                </CircularProgressbarWithChildren>
                            </div>
                            <div className="ratingDetails">
                                <div className="rate">
                                    <ReactStars count={5}
                                        onChange={(e)=> ratingChanged(e)}
                                        size={18}
                                        color2={'#FBB03B'} 
                                        value={ratingValue}
                                        // char={<StarIcon />}
                                    />
                                    <span className="no">
                                        {ratingValue}
                                    </span>
                                </div>
                                <p>
                                    1.4k reviews
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="ratingComments">
                    <div className="headings">
                        <h3>
                            Reviews
                        </h3>
                        <div className="sortActions">
                            <h4>
                                Sort
                            </h4>
                            <div onClick={()=> setSortByHistoryDrop(!sortByHistoryDrop)} className="dropControls">
                                <span className="textSpan">
                                    {sortByHistory}
                                </span>
                                <Down width={13} height={13}/>
                                {sortByHistoryDrop && <div className="dropDown">
                                    <p onClick={()=> setSortByHistory('Oldest')}>
                                        Oldest
                                    </p>
                                    <p onClick={()=> setSortByHistory('Newest')}>
                                        Newest
                                    </p>
                                </div>}
                            </div>
                            <div onClick={()=> setStarDrop(!starDrop)} className="dropControls">
                                <span className="textSpan">
                                    <StarIcon width={13} height={13}/>
                                    <span>
                                        Rating
                                    </span>
                                </span>
                                <Down width={13} height={13}/>
                                {starDrop && <div className="dropDown">
                                    <div className="starSort">
                                        <Checkbox  
                                        size="small"
                                        style={{
                                            padding: 0,
                                            color: '#FBB03B',
                                            marginRight: 6,
                                        }}
                                        />
                                        <ReactStars 
                                            count={5}
                                            
                                            size={17}
                                            color2={'#FBB03B'} 
                                            value={5}
                                        />
                                    </div>
                                    <div className="starSort">
                                        <Checkbox width={'13px'} 
                                        size="small"
                                        style={{
                                            padding: 0,
                                            color: '#FBB03B',
                                            marginRight: 6,
                                        }}
                                        />
                                        <ReactStars 
                                            count={5}
                                            
                                            size={17}
                                            color2={'#FBB03B'} 
                                            value={4}
                                        />
                                    </div>
                                    <div className="starSort">
                                        <Checkbox width={'13px'} 
                                        size="small"
                                        style={{
                                            padding: 0,
                                            color: '#FBB03B',
                                            marginRight: 6,
                                        }}
                                        />
                                        <ReactStars 
                                            count={5}
                                            
                                            size={17}
                                            color2={'#FBB03B'} 
                                            value={3}
                                        />
                                    </div>
                                    <div className="starSort">
                                        <Checkbox width={'13px'} 
                                        size="small"
                                        style={{
                                            padding: 0,
                                            color: '#FBB03B',
                                            marginRight: 6,
                                        }}
                                        />
                                        <ReactStars 
                                            count={5}
                                            
                                            size={17}
                                            color2={'#FBB03B'} 
                                            value={2}
                                        />
                                    </div>
                                    <div className="starSort">
                                        <Checkbox width={'13px'} 
                                        size="small"
                                        style={{
                                            padding: 0,
                                            color: '#FBB03B',
                                            marginRight: 6,
                                        }}
                                        />
                                        <ReactStars 
                                            count={5}
                                            
                                            size={17}
                                            color2={'#FBB03B'} 
                                            value={1}
                                        />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                    <div className="reviewsTexts">
                        {reviewData.map((reviewDataItem, idx)=>{
                            return <ReviewItem reviewDataItem={reviewDataItem}/>
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ReviewModal