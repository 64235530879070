import React, { useState, useContext,  useEffect } from "react"
import { FilterOptions } from './ConnectOptions';
import DropdownIndicator from '../../../../components/cards/jobCards/FontAwesomeIndicator';
import Select from 'react-select';
import "./Manage.scss";
import {
  FaSortAmountDownAlt,
  FaChevronDown,
  FaSearch,
} from 'react-icons/fa';

const Search = ({
suggested,
searchInput,
setSearchInput,
filteredResults,
setFilteredResults
}) => {


  const searchItems = (searchValue) => {
        setSearchInput(searchValue)
         if (searchInput !== '') {
        const filteredData = suggested.filter((item) => {
            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
        })
        setFilteredResults(filteredData)
      }
      else{
          setFilteredResults(suggested)
      }
  }



  return(
    <div className="filter_sort">
      <div className="filter_sort_head">
        <div className="filter">
          <p className="search-text">Search</p>
            <FaSearch className="search-icon"/>
            <input
              className="search-bar"
              type="text"
              placeholder="Search by location"
              onChange={(e) => searchItems(e.target.value)}
              />
          </div>
          <div className="sort">
            <p>Sort by</p>
            <div className="input">
                Earliest to Latest<FaSortAmountDownAlt color="var(--bg-orange)" />
            </div>
          </div>
        </div>
    </div>
  )
}

export default Search
