/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearMessageComponent = props => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b,.c{fill:none;}.a,.b{stroke:#490057;stroke-linecap:round;stroke-linejoin:round;}.a{stroke-width:1.5px;}.b{stroke-width:2px;}.c{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-684 -186)">
      <path
        className="a"
        d="M6.5,17H6c-4,0-6-1-6-6V6Q0,0,6,0h8q6,0,6,6v5q0,6-6,6h-.5a1.014,1.014,0,0,0-.8.4l-1.5,2a1.421,1.421,0,0,1-2.4,0l-1.5-2A1.13,1.13,0,0,0,6.5,17Z"
        transform="translate(686 188)"
      />
      <path className="b" d="M.495.5H.5" transform="translate(699.502 196.5)" />
      <path className="b" d="M.495.5H.5" transform="translate(695.501 196.5)" />
      <path className="b" d="M.495.5H.5" transform="translate(691.5 196.5)" />
      <path className="c" d="M0,0H24V24H0Z" transform="translate(684 186)" />
    </g>
  </svg>
);

export default VuesaxLinearMessageComponent;
