import { useContext, useState, useEffect } from "react";
import "./queueAnalyticModal.scss";
import ModalContext from "../../../../hooks/modalContexts";
import CloseBtn from "../../../../components/closeBtn";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ArrowNarrowDown from "../../../../assets/svg/arrowNarrowDown";
import ArrowNarrowUp from "../../../../assets/svg/arrowNarrowUp";
import TaskSquare from "../../../../assets/svg/taskSquare";
import SalesGrowthChart from "../../../Analytics/components/salesGrowthTotalQueModal";
import AreaChart from "../../../../components/Analytics/Charts/ModalCharts/queueTotalModal";
import OrderCompletionModal from "../../../Analytics/components/orderCompletionModal";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS } from "chart.js/auto";
import {
  chartData2,
  chartOptions2,
} from "../../../../components/Analytics/chartDetails";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import BarChart from "../../../Analytics/Charts/barChart";
import OrderCompletion from "../../../Analytics/components/orderCompletion";
import { useLocation } from "react-router-dom";
import useAnalytics from "../../../../hooks/Analytics";
import qs from "query-string";
import ExploreContext  from '../../../../hooks/exploreContext'

const QueueAnalyticModal = () => {
  /* datepicker */
  // const [startDate, setStartDate] = useState(new Date());
  // const [endDate, setEndDate] = useState(new Date());

  const { setQueueAnalyticModal } = useContext(ModalContext);
  const testArray = [1, 2, 3, 4, 5, 6, 7];

  const { search } = useLocation();
  const { id } = qs.parse(search);
  const {
    startDate,
    endDate,
    setStartDate,
    setEndDate,
  }= useContext(ExploreContext)

  const {
   analyticsQueueModal,
    handleGetAnalyticsQueueModal,

    analyticsQueueInteraction,
    handleGetAnalyticsQueueInteraction,
  } = useAnalytics()

   useEffect(() => { 
    handleGetAnalyticsQueueModal(id)
    handleGetAnalyticsQueueInteraction(id)
  }, [])

  const queueModal = analyticsQueueModal?.data?.results
  const queueInteraction = analyticsQueueInteraction?.data


  console.log(queueModal)


  return (
    <div
      className="queueAnalyticModal"
      onClick={(e) => setQueueAnalyticModal(false)}
    >
      <div className="queueAnalyticModalContainer">
        <div
          onClick={(e) => setQueueAnalyticModal(false)}
          className="closeIcon"
        >
          <CloseBtn />
        </div>

        <div
          onClick={(e) => e.stopPropagation()}
          className="queueAnalyticModalContainered"
        >
          <div className="queueAnalyticModal-header">
            <h4> Queue </h4>

            <div className="datepicker-container">
            <DatePicker
                  // selected={startDate}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(dates) => {
                    setStartDate(dates[0]);
                    setEndDate(dates[1]);
                    // setGeneralStartDate(moment(startDate).format("YYYY-MM-DD"))
                    // setGeneralEndDate(moment(endDate).format("YYYY-MM-DD"))
                     handleGetAnalyticsQueueInteraction(
                      id, 
                      moment(dates[0]).format("YYYY-MM-DD"),
                      moment(dates[1]).format("YYYY-MM-DD")
                      )
                    //  console.log(analyticsGeneralData)
                  }}
                  selectsRange
                  customInput={
                    <div className="custom-date">
                      {moment(startDate).format("MMM Do YY")} -{" "}
                      {moment(endDate).format("MMM Do YY")}{" "}
                    </div>
                  }
                  // dateFormat={"MMM do yyyy"}
                  // value={moment(date1).format("MMM Do YYYY")}
                />
            </div>
          </div>
          <div className="queueAnalyticModal-body">
            <div className="gam-content-one">
              <div className="item__scroll">
                {queueModal?.map((data, idx) => (
                  <div className="jcob-details">
                    <div className="details-box-one">
                      <h4>{idx + 1}</h4>
                      <img src={data?.page_image ? data?.page_image : itemImg} alt="" />
                      <div className="details-box-two">
                        <h5> {data?.page_name}</h5>
                        <h6>@{data?.page_username ? data?.page_username  : "Username"}</h6>
                      </div>
                      <div className="details-box-three">
                        <img src={data?.service_image ? data?.service_image : itemImg} alt="" />
                        <h5> {data?.title} </h5>
                      </div>
                    </div>
                    <div className="jcob-chart">
                      <p>
                      {data?.summary.summary.percentage}% {data?.summary.summary.direction ==="UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
                      </p>
                      <div>
                        <AreaChart data={data}/>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="gam-content-two">
              <OrderCompletion
                image={<TaskSquare fill={"#3dd598"} />}
                title="Joined"
                orderValue={`${queueInteraction?.join?.joined} of ${queueInteraction?.join?.total}`}
                orderPercent={queueInteraction?.join?.summary.percentage.toFixed(1) + "%"}
                bgColor="rgba(61,213,152,0.4)"
                bgColor2="#3dd598"
                upAndDown={queueInteraction?.join?.summary.direction === "UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
              />
              <OrderCompletion
                image={<TaskSquare fill={"#fbb03b"} />}
                title="Left"
                orderValue={`${queueInteraction?.left?.left} of ${queueInteraction?.join?.total}`}
                orderPercent={queueInteraction?.left?.summary.percentage.toFixed(1) + "%"}
                bgColor="rgba(255,156,0,0.4)"
                bgColor2="#fbb03b"
                upAndDown={queueInteraction?.left?.summary.direction === "UP" ? <ArrowNarrowUp /> : <ArrowNarrowDown />}
              />
            </div>
            <div className="amb-statistics">
              <SalesGrowthChart title="Queue Growth" queueInteraction={queueInteraction} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default QueueAnalyticModal;
