import React, {  useState, useContext, useEffect  } from "react";
import ModalContext from '../../../../../hooks/modalContexts'
import ExploreContext  from '../../../../../hooks/exploreContext'
import { FaEnvelope, FaEye } from 'react-icons/fa'
import '../../ConnectCard.scss'
import person from '../../../../../assets/img/Ellipse 320.PNG';
import moment from "moment";

const RecievedCard = ({
  recieved,
  handleRespondConnectRequest,
  handleHideProfile,
  hideProfileData,
  handleLike,
  acceptModalData,
  setAcceptModalData,
  setAcceptSuccessModal,
  setCurrentId,
  acceptColor,
  setAcceptColor,
  handleRespondConnectRequestCard,
  respondConnectRequestCardData,
  handleGetConnectRequestConnectedTrue,
  handleGetConnectRequestReceivedTrue,
  id

}) => {
  const [isOpened, setOpened] = useState('false')
  const [isShown, setIsShown] = useState(false);
  
  const handleShow = () => {
    setOpened(!isOpened)
  }

  const {
    connectHomeLoad,
    setConnectHomeLoad,
    setMessageData,
    setRequestReceived,
  } = useContext(ExploreContext)

  const {handleAcceptModal, handleSuccessConnectModalCard, handleDeclineConnectModalCard} = useContext(ModalContext)
  console.log(connectHomeLoad)

  const initialAcceptConnectState = {
    status:"Accept"
  }

  const initialDeclineConnectState = {
    status:"Decline"
  }

  const [respondConnect, setRespondConnect] = useState(initialAcceptConnectState)
  const [declineConnect, setDeclinedConnect] = useState(initialDeclineConnectState)
  
  const requestAccept = () => {
    handleRespondConnectRequestCard(recieved?.id, respondConnect)

    // Next action UseEffect from connectectHook 
    console.log("=====SUCCESS========");
  };


  const requestDecline = () => {
    handleRespondConnectRequestCard(recieved?.id, declineConnect);
    console.log("=====DECLINED========");
  };

  useEffect(() => {
    // console.log(respondConnectRequestData?.data?.status)
     if(respondConnectRequestCardData?.data?.status === "Accept"){
            handleSuccessConnectModalCard('ADD_SUCCESS_CONNECTCARD_TO_DISPLAY', 'true');
            console.log("=====SUCCESS========");
            setConnectHomeLoad(true)
            // setRequestReceived(state => state?.filter(state => state?.id !== acceptedCard?.id && state?.status === "Accept" && true))
         } 
     if(respondConnectRequestCardData?.data?.status === "Decline"){
            handleDeclineConnectModalCard('ADD_DECLINE_CONNECTCARD_TO_DISPLAY', 'true');
            setConnectHomeLoad(true)
            console.log(respondConnectRequestCardData?.data?.status);
            // setRequestReceived(state => state?.filter(state => state?.id !== acceptedCard?.id && state?.status === "Accept" && true))
          } 
          
  }, [respondConnectRequestCardData?.data]);

  return (
    <>
    {/* {recieved?.active === true && */}
      <div className='connect-manage-card'
        >
        <div className='connect-manage-card-left'>
          <div className='connect-manage-card-user'>
            <div className='connect-manage-card-user-head'>
              <div>
                <img src={
                    recieved?.connect_profile?.page?.featured_image ? recieved?.connect_profile?.page?.featured_image: person
                } alt='' />
              </div>
              <div>
                <h2>{recieved?.connect_profile?.page?.name}</h2>
                <p>@{recieved?.connect_profile?.page?.username ? recieved?.connect_profile?.page?.username : "Username" }</p>
              </div>
            </div>
            <div className='connect-manage-iconss'>
              <div className='connect-manage-eye'
                 onClick={() => {
                  setConnectHomeLoad(false)
                  handleAcceptModal("ADD_ACCEPT_TO_DISPLAY", "true");
                  // handleSuccessConnectModal('ADD_SUCCESS_CONNECT_TO_DISPLAY', 'true');
                  setAcceptModalData(recieved)
                   setCurrentId(recieved?.id)
                }}
                >
                <FaEye />
              </div>
              {/* <div className='connect-manage-envelope'>
                <FaEnvelope  onClick={() =>{
                  handleMessageModal('ADD_MESSAGE_TO_DISPLAY', 'true')
                  setMessageData(recieved)
                }}/>
              </div> */}
            </div>
          </div>
          <div className='connect-manage-card-text'>
            <div className='connect-manage-card-text-wrap'>
              <h1>{recieved?.connect_profile?.title}</h1>
              <h3>{recieved?.connect_profile?.page?.location?.address}</h3>
              <p className='connect-manage-card-p1'>{recieved?.connect_profile?.page?.description}</p>
            </div>
            <div className='connectcard-button-flex'>
              <p className='connect-manage-card-p2'>
                {/*{moment(recieved.expire_on).format("MMMM Do YYYY")}*/}
              </p>
              <div>
                <div
                  className={isOpened ? 'connect-button-flex' : 'connect-hide'}
                >
                  <button
                    onClick={() => {
                      requestAccept();
                      setAcceptColor("connect-accept")
                      setConnectHomeLoad(false)
                      // console.log(acceptColor)

                      
                    }}
                    className='connect-accept'
                    >
                    Accept
                  </button>
                  <button
                    onClick={() => {
                      requestDecline();
                       setAcceptColor("connect-decline")
                       setConnectHomeLoad(false)
                      //  console.log(acceptColor)
                    }}
                     className='connect-decline'
                     >
                     Decline
                   </button>
                </div>
                <div
                  className={isOpened ? 'connect-show' : 'connect-show appear'}
                >
                  <p>Connected</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* } */}
    </>
  )
}

export default RecievedCard
