import React from "react";
import Styled from "styled-components";
import { Container, Col, Row } from "reactstrap";
import { Create } from "@material-ui/icons";
import {
  Input,
  FormLabel,
  PhoneInput,
  FormCheckbox,
  GenderDiv,
  Country,
} from "../../../styles";
import { colors } from "../../../color";

export const Card = Styled.div`
    height: ${(props) => props.height};
    width: 100%;
    background: ${(props) => props.bg || "white"};
    box-shadow: rgba(149, 157, 165, 0.5) 0px 9px 15px;
    border-radius: 10px;
`;

const Biocard = Styled.div`
  height: 150px;
  background: #efefef;
  width: 100%;
  border-radius: 10px;
`;
export const P = Styled.p`
  color: ${colors.COMPANY_COLOR};
  font-size: 24px;
  font-weight: bold;
`;

const iconStyle = {
  color: `${colors.COMPANY_COLOR}`,
  fontSize: "20px",
};
class GeneralSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Card height="650px">
        <Container>
          <Col xl={12}>
            <Col xl={8}>
              <P className="pt-4 pb-3">General Account Settings</P>
              <Row>
                <Col xl={6}>
                  <FormLabel>First name</FormLabel>
                  <br />
                  <div className="password-div rounded">
                    <Input />
                    <Create style={iconStyle} />
                  </div>
                </Col>
                <Col xl={6}>
                  <FormLabel>Last name</FormLabel>
                  <br />
                  <div className="password-div rounded">
                    <Input />
                    <Create style={iconStyle} />
                  </div>
                </Col>
              </Row>
              <FormLabel>Phone</FormLabel>
              <br />
              <PhoneInput
                onChange={(val) => console.log(val)}
                className="rounded"
              />
              <FormLabel>Change country</FormLabel>
              <br />
              <Country className="rounded" />
              <FormLabel>Gender</FormLabel>
              <br />
              <Row>
                <Col xl={4} sm={4} xs={6}>
                  <GenderDiv htmlFor="Male" className="rounded">
                    <div
                      className="rounded d-flex justify-content-between align-items-center m-auto"
                      style={{
                        width: "90%",
                        height: "100%",
                      }}
                    >
                      <FormLabel htmlFor="Male" style={{ fontSize: "13px" }}>
                        Male
                      </FormLabel>
                      <FormCheckbox
                        value="Male"
                        id="Male"
                        name="gender"
                        // checked={gender === "Male"}
                        // onChange={handleChange}
                        size="small"
                      />
                    </div>
                  </GenderDiv>
                </Col>
                <Col xl={4} sm={4} xs={6}>
                  <GenderDiv htmlFor="Female" className="rounded">
                    <div
                      className="rounded d-flex justify-content-between align-items-center m-auto"
                      style={{
                        width: "90%",
                        height: "100%",
                      }}
                    >
                      <FormLabel htmlFor="Female" style={{ fontSize: "13px" }}>
                        Female
                      </FormLabel>
                      <FormCheckbox
                        value="Female"
                        id="Female"
                        // checked={values.gender === "Female"}
                        // onChange={handleChange}
                        name="gender"
                        size="small"
                      />
                    </div>
                  </GenderDiv>
                </Col>
                <Col xl={4} sm={4} xs={6}>
                  <GenderDiv htmlFor="Other" className="rounded">
                    <div
                      className="rounded d-flex justify-content-between align-items-center m-auto"
                      style={{
                        width: "90%",
                        height: "100%",
                      }}
                    >
                      <FormLabel htmlFor="Other" style={{ fontSize: "13px" }}>
                        Other
                      </FormLabel>
                      <FormCheckbox
                        value="Other"
                        id="Other"
                        // checked={values.gender === "Female"}
                        // onChange={handleChange}
                        name="gender"
                        size="small"
                      />
                    </div>
                  </GenderDiv>
                </Col>
              </Row>
              <FormLabel className="pt-2" style={{ fontSize: "13px" }}>
                Edit Bio
              </FormLabel>
              <Biocard></Biocard>
            </Col>
          </Col>
        </Container>
      </Card>
    );
  }
}

export default GeneralSettings;
