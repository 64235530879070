import React, { useContext, useState, useEffect, useRef } from "react";
import moment from "moment";
import { roundTo2DP, toSentenceCase } from "../../../../../../../utils/helpers";
import NumberInput from "../../../../../../../components/BusinessSeller/Listings/numberComponent";
import ReusableDropDown from "../../../../../../../components/reusableDropdown";
import ExploreDropdown from "../../../../../../../components/exploreDropdown/exploreDropdown";

import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { FaShare,FaStar } from "react-icons/fa";
import avt from "../../../../../../../assets/img/avatar.png";
import location from "../../../../../../../assets/explore/locationpin.svg";
import { FaAngleDown } from "react-icons/fa";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import refericon from "../../../../../../../assets/explore/people.svg";
import saveIcon from "../../../../../../../assets/explore/save.svg";

import ModalContext from "../../../../../../../hooks/modalContexts";
import ExploreContext from "../../../../../../../hooks/exploreContext";
import useExplore from "../../../../../../../hooks/explore";
import { toggleRightBar } from "../../../../../../../appstore/navigationDisplayToggle/index.action";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../../../../components/ReactToast";
import { Toaster } from "react-hot-toast";

const SingleProduct = (props) => {

  const { selectedProduct, resetAllListings } = useContext(ExploreContext);
  const { handleGetProducts, handleGetProduct, handleAddProductToCart } = useExplore();
  const { setReferModal } = useContext(ModalContext);
  const [showCustomOrder, setShowCustomOrder] = useState(false);
  const [buyProduct, setBuyProduct] = useState(false);
  const dispatch = useDispatch();
  const [preOrder, setPreOrder] = useState(false);
  const [orderPoint, setOrderPoint] = useState("order");
  const[ deliveryMethods, setDeliveryMethods] = useState(["Select Mode"]);
  const [deliveryMode, setDeliveryMode] = useState("");
  const [showLocations, setShowLocations] = useState(false);

  const [deliveryAddresses, setDeliveryAddresses] = useState(["Select Location"]);
  const [deliverySchedule, setDeliverySchedule] = useState([]);
  const [locationDates, setLocationDates] = useState([]);
  const [deliveryLocationId, setDeliveryLocationId] = useState([]);
  const [selectAddress, setSelectAddress] = useState(null);
  const [selectAddressId, setSelectAddressId] = useState(null);
  const [selectPickupTime, setSelectPickupTime] = useState(false);
  const [selectedPickupDates, setSelectedPickupDates] = useState(null);
  const [productQty, setProductQty] = useState("");
  const [selectTime, setSelectTime] = useState(null);
  const [selectPickupDate, setSelectPickupDate] = useState(false);
  const [deliveryLocation, setDeliveryLocation] = useState("");

  const [quantity, setQuantity] = useState({});

  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryDateId, setDeliveryDateId] = useState("");
  const [pickupDate, setPickupDate] = useState(null);
  const [thisUser, setThisUser] = useState(null);
  const [stockId, setStockId] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [addressLandmark, setAddressLandmark] = useState("");

  const [pageLoading, setPageLoading] = useState(false);
  const [buyerAddress, setBuyerAddress] = useState("");
  const [selectedAddress, setSelectedAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  // const [myProducts, setMyProducts] = useState([]);
  const ref = useRef();
  const dateRef = useRef();

  const pickUpTimes = [
    "9:00 - 9:30AM",
    "9:30 - 10:00AM",
    "10:00 - 10:30AM",
    "10:30 - 11:00AM",
    "11:00 - 11:30AM",
    "11:30 - 12:00AM",
    "12:00 - 12:30AM",
    "12:30 - 01:00PM",
  ];

  const handleSelectTime = (event) => {
    setSelectTime(event.target.value);
  };

  useEffect(() => {
    if (resetAllListings) props.goBack();
  }, [resetAllListings]);

  useEffect(() => {
    setStockId(selectedProduct?.data?.stocks[0]?.id)
  }, [selectedProduct]);

  const handleChooseDate = (date) => {
    const dateStart = date.slice(0, date.indexOf(" "));

    const locations = selectedProduct?.data?.delivery_locations[0]?.locations;
    const locationObject = locations.find(item => item.dates[0].date === dateStart);

    setDeliveryDate(locationObject);
    setDeliveryDateId(locationObject?.dates[0].id);
  }

  const handleSelectDate = (event) => {
    setPickupDate(event.target.value);
  };

  const findSelectedTimeWithId = (id) => {
    let allPickupAddresses = selectedProduct.data.pickup_locations;
    for (let i = 0; i < allPickupAddresses.length; i++) {
      if (id === allPickupAddresses[i].id) {
        setSelectedPickupDates(allPickupAddresses[i].dates);
      }
    }
  };

  const handleSelectAddress = (event) => {
    setSelectedPickupDates(null);
    setSelectAddress(event.target.value);
    setSelectAddressId(event.target.id);
    findSelectedTimeWithId(event.target.id);
  };

  const toggleBuyProduct = (id) => {
    if (buyProduct) {
      dispatch(toggleRightBar(true));
      props.action();
    } else {
      dispatch(toggleRightBar(false));
      props.action();
    }

    if (preOrder) {
      setPreOrder(false);
    }
    setBuyProduct(!buyProduct);

    handleGetProduct(id);
  };

  const addProductToCart = () => {
    if (!selectedProduct || !quantity || !deliveryMode || !deliveryLocationId || !buyerAddress || !postalCode || !addressLandmark) {
      showToast("error", "Please complete form before submitting.");
      return;
    }

    let data = [];
    data.product = selectedProduct.data.id;
    data.status = "CART";
    data.quantity = quantity.stockQty;
    
    data.stock = stockId;
    if (deliveryMode === "Pick up") {
      data.delivery_mode = "PICK_UP";
      data.pickup_locations = selectAddressId;
    }
    console.log(deliveryLocationId);
    if (deliveryMode === "Door Delivery") {
      data.delivery_mode = "DOOR_DELIVERY";
      data.delivery_location = deliveryLocationId;
    }
    data.date_or_weekday = deliveryDateId;
    data.phone_number = phoneNumber;

    data.buyer_location = {address: selectedAddress};

    data.postal_code = postalCode;
    data.landmark = addressLandmark;

    handleAddProductToCart(data);
  };

  useEffect(() => {
    if (selectedProduct?.data?.delivery_locations?.length > 0) {
      const locations = selectedProduct?.data?.delivery_locations[0]?.locations.map(obj => obj.address);
      
      setDeliveryAddresses(deliveryAddresses.concat(locations));
    }

  }, [selectedProduct]);

  useEffect(() => {
    const locations = selectedProduct?.data?.delivery_locations[0]?.locations.map(obj => obj);
    const place = locations?.find(obj => obj.address === deliveryLocation);
    const placeTimes = place?.dates?.map(obj => obj.date + " " + obj.opening_time)
    
    const initialSchedule = ["Select Time"];
    setDeliverySchedule(initialSchedule.concat(placeTimes));
    // setLocationDates(place?.dates);
    // setDeliveryLocationId(selectedProduct?.data?.delivery_locations[0]?.id);
  }, [deliveryAddresses])

  useEffect(() => {
    if (selectedProduct?.data?.pickup_locations?.length > 0) {
      setDeliveryMethods(prev => [...prev, "Pick Up"]);
    }
  }, [selectedProduct]);
 
  useEffect(() => {
    if (selectedProduct?.data?.delivery_locations) {
      setDeliveryMethods(prev => [...prev, "Door Delivery"]);
    }
  }, [selectedProduct]);

  const changeDeliveryLocation = (event) => {
    console.log(event);
    setSelectedAddress(event);
    const locations = selectedProduct?.data?.delivery_locations[0]?.locations;
    const locationObject = locations.find(item => item.address === event);
    // setDeliveryLocation()
    // setDeliveryLocationId(locationObject?.id);
    setDeliveryLocationId(selectedProduct?.data?.delivery_locations[0]?.id);
    console.log(selectedProduct?.data?.delivery_locations[0]);
    setLocationDates(locationObject?.dates);

    const placeTimes = locationObject?.dates[0].date + " - " + locationObject?.dates[0].opening_time
    const initialSchedule = ["Select Time"];
    setDeliverySchedule(initialSchedule.concat(placeTimes));
    console.log(selectedProduct);

    // setDeliveryLocation
  }

  const changeBuyerAddress = (event) => {
    setBuyerAddress(event.target.value);
  }

  const changeOrderPoint = (point) => {
    if (point === "summary") {
      if (!selectedProduct || !quantity || !deliveryMode || !deliveryLocationId || !buyerAddress || !postalCode || !addressLandmark) {
        showToast("error", "Please complete form before proceeding.");
        return;
      }
      setOrderPoint("summary");
    }
    if (point === "order") {
      setOrderPoint("order");
    }
  }

  useEffect(() => {
    setPageLoading(true);
    handleGetProducts({});
    // setMyProducts(allProducts);

    let localUser = JSON.parse(localStorage.getItem("persist:root"));
    let loggedInUser = JSON.parse(localUser.currentUser).user;
    setThisUser(loggedInUser);
  }, []);


  return (
    <>
    <Toaster
        position={"top-right"}
        reverseOrder={false}
      />
      
    <section className="position-section">
      <div className="details-section">
        <div className="position-card">
          <div className="card-body">
            <div className="card-head">
              <div className="business">
                <img
                  src={
                    selectedProduct?.data?.page?.featured_image
                      ? selectedProduct?.data?.page?.featured_image
                        .file
                      : avt
                  }
                  className="avatar"
                  alt=""
                />

                <div className="biz-details">
                  <div className="biz-name">
                    {selectedProduct?.data
                      ? selectedProduct?.data?.page?.name
                      : ""}
                  </div>
                  <div className="biz-handle">
                    @
                    {selectedProduct?.data
                      ? selectedProduct?.data?.page?.username
                      : "@"}
                  </div>
                </div>
              </div>

              <div className="biz-page-details">
                <div className="details-container">
                  <div className="biz-rating">
                    <div className="star-rating">
                      <div className="rating">
                        <i className="star-full">
                          <FaStar />
                        </i>
                        <i className="star-full">
                          <FaStar />
                        </i>
                        <i className="star-full">
                          <FaStar />
                        </i>
                        <i className="star-full">
                          <FaStar />
                        </i>
                        <i className="star-empty">
                          <FaStar />
                        </i>
                      </div>

                      <div className="rating-value">
                        {selectedProduct?.data
                          ? selectedProduct?.data?.page?.rating
                          : 0}
                      </div>
                    </div>

                    <div className="rating-number">
                      {selectedProduct?.data
                        ? selectedProduct?.data?.page?.review_count
                        : avt}{" "}
                      reviews
                    </div>
                  </div>

                  <div className="job-data">
                    <div className="location">
                      <img src={location} height="22px" alt="" />
                      {selectedProduct?.data?.page?.location?.address ? selectedProduct?.data?.page?.location?.address
                        : "Unknown"}
                    </div>
                    <div className="post-date">
                      Posted on{" "}
                      {selectedProduct?.data
                        ? moment(selectedProduct?.data?.created_at).format(
                          "L"
                        )
                        : ""}
                    </div>
                  </div>

                  <div className="page-type">
                    <p className="">
                      {selectedProduct?.data
                        ? toSentenceCase(selectedProduct?.data?.page?.type)
                        : ""}{" "}
                      Page
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="contact-details">
              <div className="contact-phone">
                <LocalPhoneIcon />{" "}
                {selectedProduct?.data
                  ? selectedProduct?.data?.page?.phone_number
                  : ""}
              </div>

              <div className="contact-mail">
                <MailIcon />{" "}
                {selectedProduct?.data
                  ? selectedProduct?.data?.page?.email
                  : ""}
              </div>

              <div className="contact-site">
                <LanguageIcon />{" "}
                {selectedProduct?.data
                  ? selectedProduct?.data?.page?.website
                  : ""}
              </div>

              <button
                className="biz-refer"
                onClick={() => setReferModal(true)}>
                <img src={refericon} alt="" />
                Refer
              </button>

              <div className="job-actions">
                <img src={saveIcon} className="action-icon" alt="" />
                <i className="action-icon">
                  <FaShare />
                </i>
              </div>
            </div>

            <div className="product-deals">
              <div className="deal-single">
                <div className="title">Price: </div>
                <span className="deal-content text-bold color-secondary">
                  $
                  {selectedProduct?.data
                    ? selectedProduct?.data?.deposit_amount
                    : "0"}
                </span>
              </div>

              <div className="deal-single">
                <div className="title">Deals:</div>

                <div className="deal-content">
                  Buy one get one free
                  <small>(End May 20th 2022)</small>
                </div>
              </div>

              <div className="deal-discount">
                <div className="amount">
                  Discount:&nbsp;
                  <span className="discount-pct">
                    $
                    {selectedProduct?.data
                      ? selectedProduct?.data?.discount?.constant?.percentage
                      : "0"}
                  </span>
                </div>

                <div className="deal-content">
                  <small>
                    (Ends:&nbsp;
                    {selectedProduct?.data
                      ? moment(selectedProduct?.data?.discount?.exp_date).format(
                        "L"
                      )
                      : "Null"}
                    )
                  </small>
                </div>
              </div>
            </div>

            <div className="job-description">
              <div className="title">Product Description</div>

              <div className="description">
                {selectedProduct?.data
                  ? selectedProduct?.data?.description
                  : "0"}
              </div>

              <div className="product-images">
                {selectedProduct?.data
                  ? selectedProduct?.data?.gallary &&
                  selectedProduct?.data?.gallary.map((pic, id) => {
                    return (
                      <>
                        <img
                          src={pic.file}
                          className="product"
                          key={id}
                          alt=""
                        />
                      </>
                    );
                  })
                  : null}
              </div>
            </div>
          </div>
        </div>

        <div className="actions">
          <button className="btn-back" onClick={() => props.goBack()}>
            Back
          </button>
        </div>
      </div>

      <div className="product-right">
        {showCustomOrder && (
          <>
            <div className="product-info">
              <div className="info-icon">
                <InfoOutlinedIcon />
              </div>

              <div className="info-content">
                <div className="info-title">Accepts Custom order Only</div>

                <div className="info-body">
                  Message seller to make Purchase.
                </div>
              </div>
            </div>

            <button
              className="message-btn"
              onClick={() => setShowCustomOrder(false)}>
              <MailIcon />
              Contact seller
            </button>
          </>
        )}
        {!showCustomOrder && (
          <>
            <button
              className="message-btn"
              onClick={() => setShowCustomOrder(true)}>
              <MailIcon />
              Message
            </button>

            {preOrder && (
              <div className="product-info">
                <div className="info-icon">
                  <InfoOutlinedIcon />
                </div>

                <div className="info-content">
                  <div className="info-title">5 Orders left</div>

                  <div className="info-body">
                    Seller requires 10 by April 20 before order is
                    processed.
                  </div>
                </div>
              </div>
            )}

            {orderPoint === "order" && (
              <>
                <div className="price-qty">
                  <div className="qty">
                    {/* <div className="price-product">
                      <div className="price-qty-label">Options&nbsp;</div>
                      <select className="custom-drop">
                        <option>Medium &nbsp; $12</option>
                        <option>20kg &nbsp; $22</option>
                        <option>30kg &nbsp; $40</option>
                      </select>
                    </div> */}

                    <div className="qty-icon">
                      <div className="price-qty-label">Quantity</div>
                      <div className="number-input-box">
                        <NumberInput
                          onChange={(e) =>
                            setQuantity({ ...quantity, stockQty: e })
                          }
                        />
                      </div>

                      <div className="price-line">
                        <div className="info-icon">
                          <InfoOutlinedIcon />
                        </div>

                        <div className="price">
                          Price: $
                          {quantity?.stockQty && selectedProduct?.data
                            ? roundTo2DP(
                              quantity.stockQty *
                              selectedProduct?.data?.deposit_amount
                            )
                            : selectedProduct?.data
                              ? selectedProduct?.data?.deposit_amount
                              : "--"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="delivery-mode">
                  <div className="mode-label">Delivery Mode</div>

                  <div className="mode">
                    <ReusableDropDown
                      options={deliveryMethods}
                      style={{
                        minWidth: 180,
                        zIndex: 2010,
                        fontSize: "14px",
                      }}
                      onChange={setDeliveryMode}
                    />
                  </div>
                </div>

                {deliveryMode === "Pick up" && (
                  <>
                    <div className="location-label">Pickup Location</div>

                    <ExploreDropdown
                      headerContent={
                        <>
                          <div
                            className="dropdown-trigger"
                            onClick={() =>
                              setShowLocations(!showLocations)
                            }>
                            {selectAddress
                              ? selectAddress
                              : "Pickup Location"}
                            <FaAngleDown className="select-angle" />
                          </div>
                        </>
                      }
                      menuContent={
                        <>
                          <div className="pickup-addresses">
                            <div className="address-box">
                              <RadioGroup
                                value={selectAddress}
                                onChange={handleSelectAddress}>
                                {selectedProduct?.data?.pickup_locations
                                  ? selectedProduct?.data?.pickup_locations?.map(
                                    (location, index) => {
                                      return (
                                        <div
                                          className="address-single"
                                          key={index}>
                                          <Radio
                                            value={location.address}
                                            id={location.id}
                                            sx={{
                                              color: "#FBB03B",
                                              "&.Mui-checked": {
                                                color: "#FBB03B",
                                              },
                                              marginRight: "4px",
                                            }}
                                          />
                                          <div className="address-date">
                                            <div className="single-address">
                                              {location.address}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )
                                  : null}
                              </RadioGroup>
                            </div>
                          </div>
                        </>
                      }
                    />

                    <div className="datetime-pickup">
                      <div className="">
                        <ExploreDropdown
                          headerContent={
                            <>
                              <div
                                className="dropdown-trigger"
                                onClick={() =>
                                  setSelectPickupDate(!selectPickupDate)
                                }>
                                {pickupDate ? pickupDate : "Select Date"}
                                <FaAngleDown className="select-angle" />
                              </div>
                            </>
                          }
                          menuContent={
                            <>
                              <div className="pickupTimes">
                                <RadioGroup
                                  value={pickupDate}
                                  onChange={handleSelectDate}>
                                  {selectedPickupDates &&
                                    selectedPickupDates.length
                                    ? selectedPickupDates.map(
                                      (date, index) => {
                                        return (
                                          <div
                                            className="time-single"
                                            key={index}>
                                            <Radio
                                              value={moment(
                                                date.start
                                              ).format("LL")}
                                              sx={{
                                                color: "#FBB03B",
                                                "&.Mui-checked": {
                                                  color: "#FBB03B",
                                                },
                                                marginRight: "4px",
                                              }}
                                            />
                                            <div className="address-date">
                                              <div className="single-address">
                                                {moment(
                                                  date.start
                                                ).format("LL")}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                    : null}
                                </RadioGroup>
                              </div>
                            </>
                          }
                        />

                        <ExploreDropdown
                          headerContent={
                            <>
                              <div
                                className="dropdown-trigger"
                                onClick={() =>
                                  setSelectPickupDate(!selectPickupTime)
                                }>
                                {selectTime ? selectTime : "Select Time"}
                                <FaAngleDown className="select-angle" />
                              </div>
                            </>
                          }
                          menuContent={
                            <>
                              <div className="pickupTimes">
                                <RadioGroup
                                  value={selectTime}
                                  onChange={handleSelectTime}>
                                  {selectedPickupDates &&
                                    selectedPickupDates.length
                                    ? selectedPickupDates.map(
                                      (time, index) => {
                                        return (
                                          <div
                                            className="time-single"
                                            key={index}>
                                            <Radio
                                              value={moment(
                                                time.start
                                              ).format("LT")}
                                              sx={{
                                                color: "#FBB03B",
                                                "&.Mui-checked": {
                                                  color: "#FBB03B",
                                                },
                                                marginRight: "4px",
                                              }}
                                            />
                                            <div className="address-date">
                                              <div className="single-address">
                                                {moment(
                                                  time.start
                                                ).format("LT")}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )
                                    : null}
                                </RadioGroup>
                              </div>
                            </>
                          }
                        />
                      </div>

                      <div className="pickup-datetime" ref={dateRef}>
                        {/*<div className="pickup-date">*/}
                        {/*	<div className="dropdown-trigger"*/}
                        {/*		 onClick={() => setSelectPickupDate(!selectPickupDate)}>*/}
                        {/*		{pickupDate ? pickupDate : 'Select Date'}*/}
                        {/*		<FaAngleDown className='select-angle'/>*/}
                        {/*	</div>*/}

                        {/*</div>*/}

                        {/*<div className="pickup-time">*/}
                        {/*	<div className="dropdown-trigger"*/}
                        {/*		 onClick={() => setSelectPickupTime(!selectPickupTime)}>*/}
                        {/*		{selectTime ? selectTime : 'Select Time'}*/}
                        {/*		<FaAngleDown className='select-angle'/>*/}
                        {/*	</div>*/}
                        {/*</div>*/}
                      </div>

                      {selectPickupTime && (
                        <div class="pickupTimes">
                          <RadioGroup
                            // aria-labelledby="demo-controlled-radio-buttons-group"
                            // name="controlled-radio-buttons-group"
                            value={selectTime}
                            onChange={handleSelectTime}>
                            <div className="time-single">
                              <Radio
                                value={pickUpTimes[0]}
                                sx={{
                                  color: "#FBB03B",
                                  "&.Mui-checked": {
                                    color: "#FBB03B",
                                  },
                                  marginRight: "4px",
                                }}
                              />
                              <div className="address-date">
                                <div className="single-address">
                                  {pickUpTimes[0]}
                                </div>
                              </div>
                            </div>

                            <div className="time-single">
                              <Radio
                                value={pickUpTimes[1]}
                                sx={{
                                  color: "#FBB03B",
                                  "&.Mui-checked": {
                                    color: "#FBB03B",
                                  },
                                  marginRight: "4px",
                                }}
                              />
                              <div className="address-date">
                                <div className="single-address">
                                  {pickUpTimes[1]}
                                </div>
                              </div>
                            </div>

                            <div className="time-single">
                              <Radio
                                value={pickUpTimes[2]}
                                sx={{
                                  color: "#FBB03B",
                                  "&.Mui-checked": {
                                    color: "#FBB03B",
                                  },
                                  marginRight: "4px",
                                }}
                              />
                              <div className="address-date">
                                <div className="single-address">
                                  {pickUpTimes[2]}
                                </div>
                              </div>
                            </div>

                            <div className="time-single">
                              <Radio
                                value={pickUpTimes[3]}
                                sx={{
                                  color: "#FBB03B",
                                  "&.Mui-checked": {
                                    color: "#FBB03B",
                                  },
                                  marginRight: "4px",
                                }}
                              />
                              <div className="address-date">
                                <div className="single-address">
                                  {pickUpTimes[3]}
                                </div>
                              </div>
                            </div>
                          </RadioGroup>
                        </div>
                      )}
                    </div>
                  </>
                )}

                {deliveryMode === "Door Delivery" && (
                  <>
                    <div className="delivery-location">
                      <div className="mode-label">Delivery Location</div>

                      <div className="mode">
                        <ReusableDropDown
                          options={deliveryAddresses}
                          style={{
                            minWidth: 180,
                            // zIndex: 2010,
                            fontSize: "14px",
                          }}
                          onChange={e => changeDeliveryLocation(e)}
                        />
                      </div>
                    </div>

                    <div className="delivery-schedule">
                      <div className="mode-label">Delivery Date/Time</div>

                      <div className="mode">
                        <ReusableDropDown
                          options={deliverySchedule}
                          style={{
                            minWidth: 180,
                            // zIndex: 2010,
                            fontSize: "14px",
                          }}
                          onChange={(e) => handleChooseDate(e)}
                        />
                      </div>
                    </div>

                    <div className="customer-address">
                      <div className="customer-address-title">
                        Enter Address
                      </div>

                      <textarea
                        cols="30"
                        rows="3"
                        placeholder="Enter your address"
                        className="customer-contact-input"
                        onChange={(e) => changeBuyerAddress(e)}>
                      </textarea>

                      <input
                        type="text"
                        className="customer-contact-input"
                        placeholder="Postal Code"
                        onChange={(e) => setPostalCode(e.target.value)}
                      />

                      <input
                        type="text"
                        className="customer-contact-input"
                        placeholder="Enter landmark"
                        onChange={(e) => setAddressLandmark(e.target.value)}
                      />

                      <input
                        type="text"
                        className="customer-contact-input"
                        placeholder="Phone number"
                        onChange={(e) => setPhoneNumber(e.target.value)}
                      />
                    </div>
                  </>
                )}

                <div className="action-container">
                  <button
                    className="action-btn"
                    onClick={() => changeOrderPoint("summary")}>
                    Continue
                  </button>
                </div>
              </>
            )}

            {orderPoint === "summary" && (
              <>
                <div className="buyer-details">
                  <div className="buyer-name">
                    <div className="name-title">{thisUser.full_name}</div>
                    <div className="name-username">{thisUser.username}</div>
                  </div>

                  <div className="buyer-contact">
                    <div className="buyer-number">
                      {thisUser.phone_number}
                    </div>
                    <div className="buyer-mail">{thisUser.email}</div>
                  </div>
                </div>

                <div className="purchase-details">
                  <div className="detail-single">
                    <div className="detail-label">Quantity:</div>
                    <div className="detail-value">
                      {productQty ? productQty : 1}
                    </div>
                  </div>

                  <div className="detail-single">
                    <div className="detail-label">Price:</div>
                    <div className="detail-value">
                      $
                      {quantity?.stockQty && selectedProduct?.data
                        ? roundTo2DP(
                          quantity.stockQty *
                          selectedProduct?.data?.deposit_amount
                        )
                        : selectedProduct?.data
                          ? selectedProduct?.data?.deposit_amount
                          : ""}
                    </div>
                  </div>

                  {
                    selectedProduct?.data?.discount &&
                    <div className="detail-single">
                      <div className="detail-label">Discount:</div>
                      <div className="detail-value">
                        ${selectedProduct?.data?.discount?.title}
                      </div>
                    </div>
                  }

                  <div className="detail-single">
                    <div className="detail-label">Deal:</div>
                    <div className="detail-value">Nil</div>
                  </div>

                  <div className="detail-single">
                    <div className="detail-label">Location:</div>
                    <div className="detail-value">
                      {selectedProduct?.data?.page?.location?.address}
                    </div>
                  </div>

                  <div className="detail-single">
                    <div className="detail-label">Delivery Mode:</div>
                    <div className="detail-value">{deliveryMode}</div>
                  </div>

                  {deliveryMode === "Pick up" && (
                    <div className="detail-single">
                      <div className="detail-label">Pickup Details:</div>
                      <div className="detail-value">{selectAddress}</div>
                    </div>
                  )}

                  {deliveryMode === "Door Delivery" && (
                    <>
                      <div className="detail-single">
                        <div className="detail-label">
                          Delivery Date/Time:
                        </div>
                        <div className="detail-value">
                          {moment(deliveryDate?.dates[0]?.date).format(
                            "ll"
                          ) + " - " + deliveryDate?.dates[0]?.opening_time} 
                        </div>
                      </div>

                      <div className="detail-single">
                        <div className="detail-label">Delivery Charge:</div>
                        <div className="detail-value">$0</div>
                      </div>
                    </>
                  )}
                </div>

                <div className="footnote">
                  <div className="footnote-title">
                    Free Cancelation date:
                  </div>
                  <div className="footnote-value">
                    {moment(selectedProduct?.data?.free_cancelation_date).format(
                      "ll"
                    )}
                  </div>
                </div>

                <div className="summary-action-container">
                  <div
                    className="back-btn"
                    onClick={() => setOrderPoint("order")}>
                    Back
                  </div>

                  <button
                    className="add-btn"
                    onClick={() => addProductToCart()}>
                    Add to Cart
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </section>
    </>
  )
}

export default SingleProduct;