import React, { useEffect, useState } from "react";
import "./index.scss";
import {
  FaCartArrowDown,
  FaEnvelope,
  FaStar,
  FaBell,
  FaPlus,
  FaRss,
  FaCog,
  FaClock,
  FaMoneyCheck,
  FaHistory,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import { Link, useLocation } from "react-router-dom";
import { Rating, RatingView } from "react-simple-star-rating";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import profileImg from "../../../assets/img/Ellipse 357.png";
import bannerImg from "../../../assets/img/Rectangle 2096.png";
import greeting from "../../../utils/greeting";
import NotificationContainer from "../../NotificationContainer/notification";
import { useSelector } from "react-redux";
import RequestedItem from "./components/requestItem";
import Listings from "./components/listings";
import Bookings from "./components/bookings";
import Queueings from "./components/queueings";
import qs from "query-string";
import useBusinessSellerDashboard from "../../../hooks/businessSellerDashboard";
import useSettings from "../../../hooks/settings";
import ReactStars from "react-stars";
import usePrivateSellerDashboard from "../../../hooks/privateSellerDashboard";
import ReviewItem from "../../PrivateSeller/Dashboard/components/reviewItem";
import Notifications from "../../PrivateSeller/Dashboard/components/notifications";

const DashboardBodyDV = () => {
  const [rating, setRating] = useState(0);
  const rate = 4.5;

  // Catch Rating value
  const handleRating = (rate) => {
    setRating(rate);

    //logic
  };

  const testArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const currentUser = useSelector((state) => state.currentUser);

  const [currentReqTab, setCurrentReqTab] = useState(1);
  const { search } = useLocation();

  const { id } = qs.parse(search);
  const { pageSpecificData, handleGetPageSpecificData } = useSettings();
  const { handleFetchReviews, allReviews } = usePrivateSellerDashboard();
  useEffect(() => {
    handleGetPageSpecificData(id);
    handleFetchReviews(id);
  }, []);
  return (
    <div className="bs-dashboard-body-container">
      <div className="dbc-header">
        <h1 className="welcome-title">
          {greeting()} <span>{currentUser?.user.first_name} </span>
        </h1>
        <div className="cl-btn-box">
          <Link
            to={`/businessseller/createlistings/?id=${id}`}
            className="cl-btn">
            Create Listing
          </Link>
        </div>
      </div>

      <div className="dbc-card">
        <div className="dbc-card-body">
          <div className="dbc-container-one">
            {/* -------- NOTIFICATION CARD --------*/}
            {/* <NotificationContainer />
             */}
            <Notifications />
          </div>
          <div className="dbc-container-four">
            <div className="dbc-container-two">
              {/* -------- ORDER REQUEST CARD --------*/}
              <div className="order-request-card">
                <div className="purple-card-header">
                  <h3>
                    <span>
                      <FaRss />
                    </span>
                    Requests
                  </h3>
                </div>
                <div className="or-card-body">
                  {currentReqTab === 1 && <Listings id={id} />}
                  {currentReqTab === 2 && <Bookings id={id} />}
                  {currentReqTab === 3 && <Queueings id={id} />}
                  {/* <div className="orc-item-box">
                    <div className="orc-ib-one">
                      <div className="orc-detail-box">
                        <img src={profileImg} alt="" />
                        <div className="orc-detail-box-user">
                          <h4>Rose Kooto</h4>
                          <h6>@username</h6>
                        </div>
                      </div>
                      <h5>Barbing and Shaving</h5>
                      <h6>Ordered on 2nd Feb 2021 10:00pm</h6>
                      <p>Expire in 40 days</p>
                    </div>

                    <div className="orc-ib-two">
                      <button className="btn-accept">Accept</button>
                      <button className="btn-decline">Decline</button>
                    </div>
                  </div> */}
                  <div className="orc-footer">
                    <button className="orc-btn">View all</button>
                    <div>
                      <button
                        className={`orc-btn ${currentReqTab === 1 && "active"}`}
                        onClick={() => {
                          setCurrentReqTab(1);
                        }}>
                        Listings
                      </button>
                      <button
                        className={`orc-btn ${currentReqTab === 2 && "active"}`}
                        onClick={() => {
                          setCurrentReqTab(2);
                        }}>
                        Bookings
                      </button>
                      <button
                        className={`orc-btn ${currentReqTab === 3 && "active"}`}
                        onClick={() => {
                          setCurrentReqTab(3);
                        }}>
                        Queueing
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="dbc-container-three">
                {/* -------- RATING CARD --------*/}

                <div className="ratings-card">
                  <div className="purple-card-header">
                    <h3>
                      <span>
                        <FaStar />
                      </span>
                      Ratings
                    </h3>
                  </div>
                  <div className="rt-card-body">
                    {pageSpecificData?.data?.rating ? (
                      <div className="profile-rating-container">
                        <div className="cpb-container">
                          <CircularProgressbarWithChildren
                            value={pageSpecificData?.data?.rating}
                            maxValue={5}>
                            <h4>{pageSpecificData?.data?.rating}</h4>
                            <p> out of 5 </p>
                          </CircularProgressbarWithChildren>
                        </div>
                        <div className="rating-bar-container">
                          <div className="rbc-star">
                            <ReactStars
                              edit={false}
                              value={pageSpecificData?.data?.rating}
                              size={20}
                              label
                              transition
                              fillColor="orange"
                              emptyColor="gray"
                              className="rating-star"
                            />

                            <h6>{pageSpecificData?.data?.rating}</h6>
                          </div>
                          <div className="rbc-review">
                            <p>
                              {pageSpecificData?.data?.review_count}{" "}
                              {pageSpecificData?.data?.review_count > 1
                                ? "Reviews"
                                : "Review"}
                            </p>
                          </div>
                          : <p></p>
                        </div>
                      </div>
                    ) : (
                      <p>No ratings yet</p>
                    )}
                  </div>
                </div>

                {/* -------- REVIEWS CARD --------*/}

                <div className="reviews-card">
                  <h3>Reviews</h3>
                  {allReviews?.data?.results?.map((reviewItem, id) => (
                    <ReviewItem
                      reviewItem={reviewItem}
                      handleFetchReviews={handleFetchReviews}
                    />
                  ))}

                  {allReviews?.data?.results?.length > 10 && (
                    <div className="rvc-footer">
                      <button className="rvcf-btn">view all</button>
                    </div>
                  )}
                  {allReviews?.data?.results?.length === 0 && (
                    <p>No reviews made</p>
                  )}
                </div>
              </div>
            </div>

            {/* -------- SHORTCUT CARD --------*/}

            <div className="dbc-container-five">
              <div className="dbc-banner-container">
                <div className="dbc-banner-title">
                  <h1>
                    What would you <br />
                    like to do today?
                  </h1>
                </div>
                <img src={bannerImg} alt="" />
              </div>
              <div className="shortcuts-box">
                <>
                  <h6>Shortcuts</h6>
                </>
                <div className="sc-box-items">
                  <div>
                    <Link to={`/businessseller/requested/${id}`}>
                      <FaPlus /> Manage Listing
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaCartArrowDown /> View Orders
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaMoneyCheck /> Checkout
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaHistory /> View History
                    </Link>
                  </div>
                </div>
                <div className="sc-box-items">
                  <div>
                    <Link to="">
                      <FaPlus /> Create Jobs
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaClock /> My Timeline
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaMoneyCheck /> Bookings
                    </Link>
                  </div>
                  <div>
                    <Link to="">
                      <FaCog /> Settings
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardBodyDV;
