import { useState, useEffect } from "react";
import BookingBody from "../../components/Bookings/bookingBody";
import FeedContainer from "../../components/FeedContainer";
import { Link, useLocation } from "react-router-dom";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleRightBar,
  toggleMyPages,
} from "../../appstore/navigationDisplayToggle/index.action";
import greeting from "../../utils/greeting";
import qs from "query-string";

const Booking = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.currentUser);
  const { search } = useLocation();
  const { id } = qs.parse(search);

  //handle needed nav manu
  useEffect(() => {
    dispatch(toggleRightBar(false));
    dispatch(toggleMyPages(true));
    return () => {
      dispatch(toggleRightBar(true));
      dispatch(toggleMyPages(false));
    };
  }, []);

  return (
    <FeedContainer>
      <div className="booking-container">
        <div className="bk-header">
          <h1 className="welcome-title">
            {greeting()} <span> {`${user.first_name} `} </span>
          </h1>
          {/* <div className="bk-btn-box">
            <Link to="/booking" className="bk-btn">
              Edit Booking
            </Link>
          </div> */}
        </div>
        <div className="bk-card">
          <div className="bkc-header">
            <h5 className="sub-title"> Booking</h5>
            <Link className="sub-title-btn" to={`/bookingrequest/?id=${id}`}>
              View History
            </Link>
          </div>
          <BookingBody />
        </div>
      </div>
    </FeedContainer>
  );
};

export default Booking;
