import { useEffect, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import "./index.scss";
import cx from "classnames";

/**
 *
 * @param {Object} options
 * @argument {Function} onChange - a call back function to get value
 * @argument {String} current - Either set a default value or current value
 * @argument {Boolean} dropDownIcon - set to show dropdown icon or not
 * @argument {Array} options - a list of dropdown options
 */
const ReusableDropDown = ({
  initState = true,
  current,
  options = ["default"],
  dropDownIcon = true,
  onChange = (e) => {},
  upIcon = false,
  ...rest
}) => {
  const [selected, setSelected] = useState(current || options[0]);
  const [state, setState] = useState();
  return (
    <div className="custom__select__dropdown" {...rest}>
      <div className="selected__option" onClick={(e) => setState(!state)}>
        {selected}
      </div>
      {state && (
        <div className="options__container">
          {options.map((data, idx) => (
            <div
              className="opt__custom"
              key={idx}
              onClick={(e) => {
                setSelected(data);
                onChange(data);
                setState();
              }}>
              {data}
            </div>
          ))}
        </div>
      )}
      {dropDownIcon && (
        <div
          className={cx("angleDown", { "angle-up": upIcon })}
          onClick={(e) => setState(!state)}>
          {upIcon && <FaAngleUp />}
          <FaAngleDown />
        </div>
      )}
    </div>
  );
};
export default ReusableDropDown;
