const rebuildForSubmission = (data = {}) => {
  const newObject = {};

  const keys = Object.keys(data);

  keys?.map((key) => {
    const individualObj = {};

    for (let i = 0; i < data[key]?.length; i++) {
      const objKey = data[key]?.[i]?.title;
      const objValue = data[key]?.[i]?.value;
      individualObj[objKey] = objValue;
    }
    newObject[key] = individualObj;
  });
  return newObject;
};

export default rebuildForSubmission;
