import {createContext, useState} from "react"

const WalletContext = createContext();

export function WalletProvider({children}) {

  const [activeEditTab, setActiveEditTab] = useState("bank");
    const changeEditTab = () => {
        setActiveEditTab("paypal");
    }

    return (
        <WalletContext.Provider value={{
            activeEditTab,
            changeEditTab
        }}>{children}</WalletContext.Provider>
    )
}

export default WalletContext;
