import { useContext, useEffect, useState } from "react";
import { useLocation, useParams } from "react-router";
import NotificationItem from "./notification item";
import {
  FaCartArrowDown,
  FaEnvelope,
  FaStar,
  FaBell,
  FaPlus,
  FaMoneyCheck,
  FaHistory,
} from "react-icons/fa";
import SocketContext from "../../../../../hooks/socketContexts";
import qs from "query-string";
import { useStateManager } from "react-select";
import "./index.scss";
import Loader from "../../../../loader";
const Notifications = () => {
  const testArray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const token = localStorage.getItem("token");
  const { search } = useLocation();
  const [page, setPage] = useState();
  const [notifications, setNotifications] = useState();
  useEffect(() => {
    const ws = new WebSocket(
      `wss://dev.api.meeney.com/v1/ws/?token=${token}${
        qs.parse(search).id ? "&page=" + qs.parse(search).id : ""
      }`
    );
    const notificationGet = {
      event: "notification.fetch",
      params: {
        limit: 10,
      },
    };
    ws.onopen = () => {
      console.log("connection established");
      ws?.send(JSON.stringify(notificationGet));
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log(data, page, "EVENT");
      if (data?.event === "notification.fetch.result") {
        setNotifications(data?.data?.results);
      }
    };
  }, []);

  console.log(notifications, "NOTI");
  // const ws = new WebSocket(`wss://dev.api.meeney.com/v1/ws/?token=${token}/`)
  // // const ws = new WebSocket(`wss://ws.bitstamp.net`)
  // console.log(ws, 'ws');
  // ws.onopen = (e) => {
  //     console.log("[open] Connection established");
  //     console.log("Sending to server");

  // };
  //     const getAllNotifications = {
  //         event: "bts:subscribe",
  // data: { channel: "order_book_btcusd" },
  //     }

  //     ws.onmessage((event)=>{
  //         console.log(event, 'EVENT');
  //         const json = JSON.parse(event.data);
  //         console.log(`[message] Data received from server: ${json}`);
  //     })

  return (
    <div className="notification-card">
      <div className="purple-card-header">
        <span>
          <FaBell />
        </span>
        <h3>Notification</h3>
      </div>
      <div className="nc-card-body">
        {!notifications && <Loader />}
        {notifications?.length === 0 && <p>No notfification</p>}
        {notifications?.map((data, idx) => (
          <NotificationItem data={data} />
        ))}
        {notifications?.length > 10 && (
          <div className="ncc-footer">
            <button className="ncc-btn">view all</button>
          </div>
        )}
      </div>
    </div>
  );
};
export default Notifications;
