import React, { useEffect, useState } from "react";
import "./history.scss";
import {
  FaSearch,
  FaChevronDown,
  FaChevronRight,
  FaChevronLeft,
  FaSortAmountUpAlt,
} from "react-icons/fa";
import { Refferal } from "../../../assets/svg/FeedOptionsIcon";
import { Link } from "react-router-dom";
import profileImg from "../../../assets/img/Ellipse 357.png";
import itemImg from "../../../assets/img/Rectangle 1890.PNG";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaSortAmountDownAlt, FaCalendarAlt } from "react-icons/fa";
import CustomDropdown from "../../customDropdown/customDropdown";
import CustomCheckBox from "../../customCheckBox";
import moment from "moment";
import usePrivateSellerDashboard from "../../../hooks/privateSellerDashboard";
import HistoryItem from "./components/historyItem";
import Loader from "../../loader";

const ListingsHistory = () => {
  const data = [
    { label: "All", icon: ".", key: "all" },
    { label: "Active", icon: ".", key: "active" },
    { label: "Completed", icon: ".", key: "completed" },
    { label: "Uncompleted", icon: ".", key: "uncompleted" },
    { label: "Cancelled", icon: ".", key: "canceled" },
  ];
  /* datepicker */
  // const [dateRange, setDateRange] = useState([null, null]);
  // const [startDate, endDate] = dateRange;

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  // console.log(startDate, endDate, "Dateeeee");

  /* paginate */
  // const [pageCount, setPageCount] = useState(1);
  // const [currentPage, setCurrentPage] = useState(1);

  // const handlePageChange = (selectedObject) => {
  //   setCurrentPage(selectedObject.selected);
  // };
  const { handleGetCustomizedOrders, allOrdersData } =
    usePrivateSellerDashboard();

  useEffect(() => {
    handleGetCustomizedOrders({ tab: "history" });
  }, []);

  const hanndleSortReferred = ({ value }) => {
    if (value) {
      handleGetCustomizedOrders({ tab: "history", referred: value });
    } else handleGetCustomizedOrders({ tab: "history" });
  };
  const [order, setOrder] = useState();
  useEffect(() => {
    handleGetCustomizedOrders({
      tab: "history",
      ordering: order ? "-created_at" : "created_at",
    });
  }, [order]);

  const [searchInput, setSearchInput] = useState();

  const handleSubmitSearch = () => {
    handleGetCustomizedOrders({
      tab: "history",
      search: searchInput,
    });
    setSearchInput();
  };
  useEffect(() => {
    if (startDate && endDate) {
      handleGetCustomizedOrders({
        tab: "history",
        created_at__gte: startDate,
        created_at__lte: endDate,
      });
    }
  }, [startDate, endDate]);
  console.log(allOrdersData, "MAINNNNNNN");
  return (
    <div className="history-body-container">
      <div className="hbc-card-body">
        {/*----------- HISTORY HEADER ------------------*/}

        {allOrdersData?.data?.results?.length > 0 && (
          <div className="hbc-cb-header">
            <div className="gh-card">
              <div className="gh-cardBody">
                <div className="status-box">
                  <p className="gh-cb-Title">Show status</p>
                  <div className="dropbox">
                    <CustomDropdown
                      filter={handleGetCustomizedOrders}
                      filter_key={"history"}
                      items={data}
                      history_condition={true}
                    />
                  </div>
                  <CustomCheckBox
                    style={{
                      minWidth: 14,
                      height: 14,
                      borderColor: "var(--text-grey)",
                      marginRight: 10,
                    }}
                    onChange={hanndleSortReferred}
                  />
                  <p className="gh-cb-Title">Referred</p>
                </div>

                <div className="date-search-box">
                  <p className="gh-cb-Title">Show Date</p>
                  {/* <p className="gh-cb-SubTitle">from</p> */}

                  <div className="datepicker-container">
                    {/* <DatePicker
                    className="custom-date"
                    // onChange={(update) => {
                    //   setDateRange(update);
                    // }}
                    onChange={onChange}
                    selectsRange
                    selected={startDate}
                    startDate={startDate}
                    endDate={endDate}
                    dateFormat="MMM d, yyyy"
                    // placeholderText="select date"
                  /> */}

                    <DatePicker
                      // selected={startDate}
                      startDate={startDate}
                      endDate={endDate}
                      // onChange={(dates) => {
                      //   console.log(dates);
                      //   setStartDate(dates[0]);
                      //   setEndDate(dates[1]);
                      // }}
                      onChange={onChange}
                      selectsRange
                      customInput={
                        <div className="custom-date">
                          {moment(startDate).format("DD MMM YYYY")} -{" "}
                          {moment(endDate || startDate).format("DD MMM YYYY")}{" "}
                        </div>
                      }
                      // dateFormat={"MMM do yyyy"}
                      // value={moment(date1).format("MMM Do YYYY")}
                    />

                    <FaChevronDown className="dropdown-icon" />
                  </div>

                  <div className="l-searchBox">
                    <span className="">
                      <FaSearch className="search-icon" />
                    </span>
                    <form
                      className=""
                      onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmitSearch();
                      }}>
                      <input
                        className=" l-search"
                        type="search"
                        placeholder="search bookings"
                        aria-label="Search"
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <div className="sort-box">
              <p>Sort By</p>
              {order ? (
                <div onClick={() => setOrder(!order)} className="sort-input">
                  <span> Earliest to Latest </span>
                  <FaSortAmountDownAlt color="var(--bg-orange)" />
                </div>
              ) : (
                <div onClick={() => setOrder(!order)} className="sort-input">
                  <span> Latest to Earliest</span>
                  <FaSortAmountUpAlt color="var(--bg-orange)" />
                </div>
              )}
            </div>
          </div>
        )}
        {!allOrdersData?.loading &&
          allOrdersData?.data?.results?.length === 0 && <p>No items</p>}
        <div className="hbc-cb-contents">
          {allOrdersData.data &&
            allOrdersData.data.results.map((data, idx) => {
              return <HistoryItem data={data} />;
            })}
        </div>
        {allOrdersData?.loading && <Loader />}
        {/* <div className="paginate-container">
          <ReactPaginate
            previousLabel={<FaChevronLeft />}
            nextLabel={<FaChevronRight />}
            pageCount={pageCount}
            pageRange={2}
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            onPageChange={handlePageChange}
            containerClassname={"pg-holder"}
            previousLinkClassName={"page"}
            breakClassName={"pg-page"}
            nextLinkClassName={"pg-page"}
            pageClassName={"pg-page"}
            disabledClassName={"disabled"}
            activeClassName={"active"}
          />
        </div> */}
      </div>
    </div>
  );
};

export default ListingsHistory;
