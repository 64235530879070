/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const RevealOptionComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 5 25" {...props}>
    <defs>
      <style>{".a{fill:#9b9b9b;}"}</style>
    </defs>
    <g transform="translate(-1645 -546)">
      <circle
        className="a"
        cx={2.5}
        cy={2.5}
        r={2.5}
        transform="translate(1645 546)"
      />
      <circle
        className="a"
        cx={2.5}
        cy={2.5}
        r={2.5}
        transform="translate(1645 556)"
      />
      <circle
        className="a"
        cx={2.5}
        cy={2.5}
        r={2.5}
        transform="translate(1645 566)"
      />
    </g>
  </svg>
);

export default RevealOptionComponent;
