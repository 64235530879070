import { IoIosShareAlt, MdDeleteSweep } from "react-icons/all";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import classnames from "classnames";
import { useContext, useEffect, useRef, useState } from "react";
import ModalContext from "../../hooks/modalContexts";
import VuesaxOutlineSendComponent from "../../assets/svg/VuesaxOutlineSendComponent";
import moment from "moment";
import useGallery from "../../hooks/gallery";
import usePosts from "../../hooks/posts";
const GallerySlide = ({ data, removeData = () => {} }) => {
  const { setSendAsMessage } = useContext(ModalContext);
  const { handleMakingPost, postsData } = usePosts();
  const [selected, setSelected] = useState([]);
  const { deleteMedia, deleteMediaData } = useGallery();
  const handleSelect = (id) => {
    if (selected.includes(id)) {
      const newData = selected
        .map((data) => data !== id && data)
        .filter((data) => data && data);
      setSelected(newData);
    } else setSelected([...selected, id]);
  };

  const getSlidesToShow = (num) => {
    if (num <= 2) return 1;
    if (num >= 3) return 3;
  };

  useEffect(() => {
    if (deleteMediaData.response?.status === 200) {
      removeData(selected);
      setSelected([]);
    }
  }, [deleteMediaData.response?.status, deleteMediaData.loading]);
  useEffect(() => {
    if (postsData.data) {
      setSelected([]);
      //do something later about a proper messaging
    }
  }, [postsData.data]);
  return (
    !!data.data.results.length && (
      <div className="gallery__wrap second">
        <div className="main__section__header">
          {/* <h1>Dec 2021</h1> */}
          <h1>{moment(data.date).format("MMM YYYY")}</h1>
          {!!selected.length && (
            <div className="gallery__slide_actions">
              <div
                className="share"
                onClick={(e) => {
                  handleMakingPost(new FormData(), { media: selected });
                }}>
                <IoIosShareAlt /> Share
              </div>
              <div className="send" onClick={(e) => setSendAsMessage(selected)}>
                <VuesaxOutlineSendComponent /> Send
              </div>
              <div
                className="delete"
                onClick={(e) => deleteMedia({ data: { ids: selected } })}>
                <MdDeleteSweep /> Delete
              </div>
            </div>
          )}
        </div>
        <div className="carousel__container">
          <Slick
            dots={true}
            slidesToShow={getSlidesToShow(data.data.results.length)}>
            {data.data.results.map((data) => (
              <div className="image-container" key={data.id}>
                <div className="gallery__img" key={data.id}>
                  {data.type === "image" ? (
                    <img src={data.file} alt={data.id} />
                  ) : (
                    <video src={data.file} />
                  )}
                  <div
                    className={classnames("image__selection__for__edit", {
                      active: selected.includes(data.id),
                    })}
                    onClick={(e) => handleSelect(data.id)}
                  />
                </div>
                <div className="date-posted">
                  Posted {moment(data.created_at).format("MMM DD YYYY")}
                </div>
              </div>
            ))}
          </Slick>
        </div>
      </div>
    )
  );
};

export default GallerySlide;
