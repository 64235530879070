import React, { useState } from "react";
import {
  Tabs,
  Tab as ExploreTab,
  makeStyles,
  withStyles,
} from "@material-ui/core";
import { TabContent, TabPane, Col, Container } from "reactstrap";
import { connect } from "react-redux";
import Styled from "styled-components";
import { colors } from "../../color";
import FeedCard from "./feedCard";
import { AiFillTag } from "react-icons/ai";
import { PhotoLibraryOutlined, Videocam, LocationOn } from "@material-ui/icons";
import PostBody, { ShareBtn, style } from "./postBody";

// Business dashboard components
import Jobs from "./business/jobs";
import Products from "./business/products";
import AllListings from "./business/allListings";
import Services from "./business/services";

export const Postbtn = Styled.button`
    border: none;
    background: ${colors.COMPANY_COLOR};
    outline: none;
    height: 50px;
    width: 90px;
    font-size: 19px;
    color: #e3c7e9;
    border-radius: 10px;
`;
const P = Styled.p`
    color: ${colors.COMPANY_COLOR};
    font-size: 14px;
    text-align: center;
`;
const Createpost = Styled.div`
    height: 133px;
    width: 100%;
    background: white;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 9px 15px;
    border-radius: 10px;
`;

const Input = Styled.textarea`
    height: 50px;
    border: 1px solid #efefef;
    width: 545px;
    outline: none;
    overflow: hidden;
    padding: 15px;
    color: black;
    border-radius: 10px;
    font-size: 15px;
    resize: none;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  padding: {
    padding: theme.spacing(3),
  },
  tabText: {
    display: "flex",
    justifyContent: "space between",
    color: `${colors.COMPANY_COLOR}`,
    textAlign: "end",
    "&focused": {},
    focused: {},
  },
}));

const useStyles2 = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  tabText: {
    display: "flex",
    justifyContent: "center",
    textAlign: "center",
    color: `${colors.COMPANY_COLOR}`,
    minWidth: "50%",
    "&focused": {},
    focused: {},
  },
}));

const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: `${colors.LIGHT_GREY}`,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(16),
    marginRight: theme.spacing(1),
    "&:focus": {
      opacity: 1,
      color: `${colors.COMPANY_COLOR}`,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}))((props) => <ExploreTab disableRipple {...props} />);

const StyledTab2 = withStyles((theme) => ({
  root: {
    textTransform: "none",
    color: `${colors.LIGHT_GREY}`,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(12),
    padding: "0",
    "&:focus": {
      opacity: 1,
      color: `${colors.COMPANY_COLOR}`,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}))((props) => <ExploreTab disableRipple {...props} />);

// Dasboard Toggle
export const DashboardToggle = () => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();

  const handleChange = (e, val) => {
    setActiveTab(val);
  };

  return (
    <div>
      <Tabs
        TabIndicatorProps={{
          style: { background: `${colors.COMPANY_COLOR}`, height: "4px" },
        }}
        onChange={handleChange}
        value={activeTab}
        style={{
          borderBottom: `1px solid ${colors.GREY}`,
          width: "100%",
          marginBottom: "25px",
          padding: "0",
        }}>
        <StyledTab className={classes.tabText} label={<div>Community</div>} />
        <StyledTab
          style={{ marginLeft: "113px" }}
          className={classes.tabText}
          label={<div>See What's Trending</div>}
        />
        <StyledTab
          style={{ marginLeft: "113px" }}
          className={classes.tabText}
          label={<div>My page</div>}
        />
      </Tabs>

      {/* First tab starts here........ */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <h1>Hello</h1>
        </TabPane>
      </TabContent>
      {/* First tab ends here.......... */}

      {/* Second tab starts here..... */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <PostBody />
        </TabPane>
      </TabContent>
      {/* Second tab end here......... */}

      {/* third Tab starts here..........  */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={2}>
          <Col xl={12}>
            <FeedCard />
            <Createpost className="mt-4">
              <Container className="pl-4 pt-4">
                <div className="d-flex justify-content-between align-items-center">
                  <Input type="text" placeholder="Share your Moments" />
                  <Postbtn>Post</Postbtn>
                </div>
                <div
                  className="d-flex align-items-center justify-content-between mt-4"
                  style={{ width: "50%" }}>
                  <ShareBtn className="d-flex">
                    <PhotoLibraryOutlined className="pr-1" style={style} />
                    <P>Image</P>
                  </ShareBtn>
                  <ShareBtn className="d-flex">
                    <Videocam className="pr-1" style={style} />
                    <P>Videos</P>
                  </ShareBtn>
                  <ShareBtn className="d-flex">
                    <LocationOn className="pr-1" style={style} />
                    <P>Location</P>
                  </ShareBtn>
                  <ShareBtn className="d-flex">
                    <AiFillTag className="pr-1" style={style} />
                    <P>Tags</P>
                  </ShareBtn>
                </div>
              </Container>
            </Createpost>
            <PostBody />
          </Col>
        </TabPane>
      </TabContent>
      {/* Third Tab ends here............... */}
    </div>
  );
};

// Business page toggle
const BusinessPageToggle = () => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles();

  const handleChange = (e, val) => {
    setActiveTab(val);
  };

  return (
    <div>
      <Tabs
        TabIndicatorProps={{
          style: { background: `${colors.COMPANY_COLOR}`, height: "4px" },
        }}
        onChange={handleChange}
        value={activeTab}
        style={{
          borderBottom: `1px solid ${colors.GREY}`,
          width: "100%",
          marginBottom: "25px",
          padding: "0",
        }}>
        <StyledTab
          className={classes.tabText}
          label={<div>All listings</div>}
        />
        <StyledTab
          style={{ marginLeft: "25px" }}
          className={classes.tabText}
          label={<div>Products</div>}
        />
        <StyledTab
          style={{ marginLeft: "25px" }}
          className={classes.tabText}
          label={<div>Services</div>}
        />
        <StyledTab
          // onClick={() => alert("hello")}
          style={{ marginLeft: "25px" }}
          className={classes.tabText}
          label={<div>Jobs</div>}
        />
      </Tabs>

      {/* All listings */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <AllListings />
        </TabPane>
      </TabContent>

      {/* Products */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={1}>
          <Products />
        </TabPane>
      </TabContent>

      {/* Sevices */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={2}>
          <Services />
        </TabPane>
      </TabContent>

      {/* JOBS */}
      <TabContent activeTab={activeTab}>
        <TabPane tabId={3}>
          <Jobs />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default connect()(BusinessPageToggle);

// Suggest section toggle
export const SuggestToggle = () => {
  const [activeTab, setActiveTab] = useState(0);
  const classes = useStyles2();

  const handleChange = (e, val) => {
    setActiveTab(val);
  };

  return (
    <Tabs
      TabIndicatorProps={{
        style: {
          background: `${colors.COMPANY_COLOR}`,
          height: "4px",
          width: "50%",
        },
      }}
      variant="standard"
      onChange={handleChange}
      value={activeTab}
      style={{
        borderBottom: `1px solid ${colors.GREY}`,
        marginTop: "30px",
        marginBottom: "30px",
      }}>
      <StyledTab2
        className={classes.tabText}
        label={<div>Suggested Users</div>}
      />
      <StyledTab2
        className={classes.tabText}
        label={<div>Suggested Pages</div>}
      />
    </Tabs>
  );
};
