import { DefaultButton } from "../../Button";
import CloseBtn from "../../closeBtn";
import { Mins } from "../../MinsComponent";
import "./styles.scss";
import Map from "./map";
import MinRequest from "./MinRequest";
import { useContext, useLayoutEffect, useState } from "react";
import CancelCheckout from "./cancelCheckout";
import ModalContext from "../../../hooks/modalContexts";
import { useEffect } from "react";
import usePrivateSellerDashboard from "../../../hooks/privateSellerDashboard";
import { useMemo } from "react";
import LoaderPurple from "../../../assets/loaders/LoaderPurple";
import NoData from "../../NoData";
import moment from "moment";
import { useSelector } from "react-redux";
import { useGeolocated } from "react-geolocated";
import { Toaster } from "react-hot-toast";
import RightSection from "./rightSection";
import NotificationOnRequest from "./NotificationOnRequest";
import { createContext } from "react";

const CheckoutModalV2 = () => {
  const { pendingReq, setPendingReq, newRequest, setNewRequest } = useContext(
    NotificationCacheContext
  );
  // console.log(pendingReq);
  // const [showMinReq, setShowMinReq] = useState();
  const [showCancelCheckout, setShowCancelCheckout] = useState();
  const { checkoutModalV2 } = useContext(ModalContext);
  const [data, setData] = checkoutModalV2;
  const { id } = data;
  const { user } = useSelector((state) => state.currentUser);
  const { handleGetSingleOrder, singleOrderData } = usePrivateSellerDashboard();
  const {
    // isGeolocationAvailable,
    // isGeolocationEnabled,
    // getPosition,
    // positionError,
    coords,
  } = useGeolocated({
    watchPosition: true,
    geolocationProvider: window.navigator.geolocation,
    positionOptions: {
      enableHighAccuracy: true,
      // timeout: 10,
      maximumAge: 1000 * 5,
    },
  });
  const {
    product,
    owner,
    delivery_location,
    delivery_mode,
    price,
    delivery_fee,
    buyer_location,
    delivery_date,
    is_seller,
    seller_id,
    out_for_delivery,
    ...order
  } = useMemo(() => {
    return singleOrderData.data
      ? {
          ...singleOrderData.data,
          is_seller:
            user.id === singleOrderData.data.product.page.owner ? true : false,
          seller_id: singleOrderData.data.product.page.owner,
        }
      : {};
  }, [singleOrderData.data]);

  useEffect(() => {
    if (singleOrderData.response?.response?.status === 404) {
      handleGetSingleOrder(id, true);
    }
    if (!singleOrderData.response) {
      handleGetSingleOrder(id);
    }
  }, [singleOrderData.response?.response?.status]);

  //when ever there is an id, set to session storage for use in cancelling of orders
  useEffect(() => {
    if (singleOrderData.data) {
      window.sessionStorage.setItem("orderId", singleOrderData.data.id);
    }
  }, [singleOrderData.data]);

  const [mins, setMins] = useState();
  const [hasArrived, setHasArrived] = useState();
  // const location = useMemo(() => {
  //   return;
  // }, []);
  return (
    <div className="c-container">
      <Toaster />
      <div className="c-box">
        <CloseBtn onClick={setData.bind(this, null)} />
        {singleOrderData.loading ? (
          <div className="center-loader">
            <LoaderPurple style={{ width: 40, height: 40 }} />
          </div>
        ) : singleOrderData.data ? (
          <>
            <div className="c-box-left">
              <div className="c-top">
                <div className="c-top-header">
                  <h2>{product.title}</h2>
                  <h3>
                    Price: <span>${price}</span>
                  </h3>
                </div>
                <div className="c-detail-sec">
                  <div className="profile">
                    <div className="photo">
                      <img src="" alt="" />
                    </div>
                    <div className="name">
                      <div className="full-name">{owner.full_name}</div>
                      <div className="location">@{owner.username}</div>
                    </div>
                  </div>
                  <div className="location">
                    <h2>Delivery Location</h2>
                    <p>{buyer_location.address}</p>
                  </div>
                  <div className="delivery-date">
                    <h2>Delivery date</h2>
                    <p>
                      {moment(delivery_date || undefined).format("MMM Do YYYY")}
                    </p>
                  </div>
                  <div className="c-message">
                    <div className="icon"></div>
                    <p>Message {is_seller ? "buyer" : "seller"}</p>
                  </div>
                </div>
              </div>
              <div className="c-center">
                <p>{is_seller ? "buyer" : "Seller"} will arrive in 10 mins</p>
                <div className="time-req">
                  <p>Request for more time</p>
                  <span>
                    <Mins
                      onChange={(value) => {
                        setMins(value);
                      }}
                      defaultMin={10}
                    />
                  </span>
                  <DefaultButton
                    customStyle={{
                      fontSize: 12,
                    }}
                    text="Send"
                    onClick={() => {
                      if (!mins) return;

                      setNewRequest(true);
                    }}
                  />
                </div>
              </div>
              {hasArrived && out_for_delivery && (
                <div className="c-bottom">
                  {coords && (
                    <Map
                      currentLocation={coords}
                      is_pickup={
                        delivery_mode.toLowerCase() === "pickup_location"
                          ? true
                          : false
                      }
                      is_seller={is_seller}
                      pickup_location={delivery_location.locations[0]}
                      delivery_location={buyer_location}
                    />
                  )}
                </div>
              )}
            </div>
            <RightSection
              setShowCancelCheckout={setShowCancelCheckout}
              id={id}
              is_seller={is_seller}
            />
            <NotificationOnRequest {...{ id }} />
          </>
        ) : (
          <NoData>Error with getting order</NoData>
        )}
        {(pendingReq || newRequest) && (
          <MinRequest
            min={mins}
            id={id}
            isRequest={pendingReq ? true : false}
            is_seller={is_seller}
            pendingReq={pendingReq}
          />
        )}
      </div>
      {showCancelCheckout && (
        <CancelCheckout closeModal={setShowCancelCheckout} />
      )}
    </div>
  );
};

export const NotificationCacheContext = createContext();

export const NotificationWrapper = ({ children }) => {
  const [pendingReq, setP] = useState();
  const [newRequest, setN] = useState();

  const setPendingReq = (data, fn = setN) => {
    fn();
    setP(data);
  };
  const setNewRequest = (data, fn = setP) => {
    fn();
    setN(data);
  };

  return (
    <NotificationCacheContext.Provider
      value={{ pendingReq, setPendingReq, newRequest, setNewRequest }}>
      {children}
    </NotificationCacheContext.Provider>
  );
};

export default CheckoutModalV2;
