import { useContext, useEffect, useState } from 'react'
import ModalContext from '../../../../../hooks/modalContexts'
import './index.scss'
import { FaTimes,} from 'react-icons/fa'
import {BiCopy} from "react-icons/bi"
import { IoMdClose } from "react-icons/all";
import SuccessCheck from "../../../../../assets/svg/successThick"
import useConnect from "../../../../../hooks/connectHooks"
import ExploreContext  from '../../../../../hooks/exploreContext'

const SuccessConnectModal = () => {
    const {handleSuccessConnectModal, successConnectModal} = useContext(ModalContext)

    const {
        handleGetConnectProfileSuggested,
        handleGetConnectRequestConnectedTrue,
        handleGetConnectRequestReceivedTrue,

        respondConnectRequestData,
        
      } = useConnect();

      const {
        currentProfile,
        successData,
        setSuccessData,
        acceptSuccessModal, 
        setAcceptSuccessModal,
        setCurrentId,
        connectHomeLoad,
        setConnectHomeLoad,
      } = useContext(ExploreContext)

      // useEffect(() => {
      //   if(successConnectModal){
      //     setConnectHomeLoad(true)
      //     window.location.reload(false);
      //   }

      // }, [successConnectModal])

      useEffect(() => {
        // console.log(respondConnectRequestData?.data?.status)
         if(respondConnectRequestData?.response?.response?.status === 200){
          // setConnectHomeLoad(true)
          // handleGetConnectRequestConnectedTrue()
          // handleGetConnectRequestReceivedTrue()
          // window.location.reload(false);
          console.log(respondConnectRequestData)
              
             } 
             
      }, [respondConnectRequestData?.response]);

      console.log(connectHomeLoad)

      useEffect(() => {
          setConnectHomeLoad(true)
      }, [connectHomeLoad])

    return(
      <div
      onClick={()=>{handleSuccessConnectModal('RESET_SUCCESS_CONNECT_TO_DISPLAY')}}
      className="successModal"
      >
       <div
       onClick={(e)=> e.stopPropagation()}
       className="successModalContainer"
       >
          <div
          onClick={()=>{handleSuccessConnectModal('RESET_SUCCESS_CONNECT_TO_DISPLAY')}}
          className="closeIcon"
          >
           <span> <IoMdClose /> </span>
          </div>
          <div className="enter-success">
            <SuccessCheck/>
          </div>
          <div className="success-text">
            You have been connected
          </div>
          <div className="success-text">Successfully </div>

            <div className='success-save-button'>
              <button
                onClick={()=>{
                  setConnectHomeLoad(true)
                  handleSuccessConnectModal('RESET_SUCCESS_CONNECT_TO_DISPLAY')
                //   setSuccessData(currentProfile);
                  console.log(connectHomeLoad)
                  // handleGetConnectRequestConnectedTrue()
                  // handleGetConnectRequestReceivedTrue()
                 
                }}
              >Done</button>
            </div>
          </div>
      </div>
    )
}
export default SuccessConnectModal
