import {
  TOGGLE_FULL_LEFT_BAR,
  TOGGLE_FULL_RIGHT_BAR,
  TOGGLE_MY_PAGES,
  TOGGLE_SUGGESTED_LISTINGS,
  TOGGLE_SUGGESTED_USERS,
} from "./index.types";

export const toggleLeftBar = (payload) => ({
  type: TOGGLE_FULL_LEFT_BAR,
  payload,
});

export const toggleRightBar = (payload) => ({
  type: TOGGLE_FULL_RIGHT_BAR,
  payload,
});

export const toggleSuggestedListings = (payload) => ({
  type: TOGGLE_SUGGESTED_LISTINGS,
  payload,
});

export const toggleSuggestedUsers = (payload) => ({
  type: TOGGLE_SUGGESTED_USERS,
  payload,
});
export const toggleMyPages = (payload) => ({
  type: TOGGLE_MY_PAGES,
  payload,
});
