import { useEffect, useState } from "react";
import "./style.scss";
const CustomSelect = ({
  color = "transparent",
  defaultState = false,
  onChange = (e) => {},
  onClick = () => {},
  controlled = false,
  selectedStateColor = "#552881",
}) => {
  const [state, setState] = useState(defaultState);
  const handleClick = () => {
    if (!controlled) {
      setState(!state);
      onClick(!state);
    } else {
      onClick(!state);
    }
  };

  useEffect(() => {
    !controlled && onChange(state);
  }, [state]);

  // if comtrolled, setting defaultstate will update state in component
  useEffect(() => {
    controlled && setState(defaultState);
  }, [defaultState, controlled]);

  return (
    <div
      className="meeney-custom-select"
      style={{
        ...(state && {
          color: selectedStateColor,
          border: `2px solid ${selectedStateColor}`,
          backgroundColor: selectedStateColor,
        }),
      }}
      onClick={handleClick}></div>
  );
};

export default CustomSelect;
