import moment from "moment";
import { FaCalendarAlt, FaPencilAlt } from "react-icons/fa";
import { ImCheckboxChecked } from "react-icons/im";
import { IoMdCloseCircleOutline } from "react-icons/io";

const CompletedDeliveryInput = ({
  locationsState,
  data,
  id,
  editState = [],
  deliveryState = [],
  doorDeliveryState = [],
  city,
}) => {
  const [locations, setLocations] = locationsState;
  const [doorDelivery, setDoorDelivery] = doorDeliveryState;
  const [edit, setEdit] = editState;
  const [delivery, setDelivery] = deliveryState;
  console.log(data);
  return (
    <div className="completed-wrap">
      <div className="pickup-location">
        <div className="inner-wrap">
          <div className="completed-icons">
            <div
              className="delivery-cancel"
              onClick={(e) => {
                setLocations(
                  locations
                    .map((data, idx) => idx !== id && data)
                    .filter((item) => item && item)
                );
              }}>
              <IoMdCloseCircleOutline />
            </div>
            <div
              className="edit-icon"
              onClick={(e) => {
                //set edit to true then transfer data to editable state and delete from list
                // console.log(data.delivery_mode);
                setLocations(
                  locations
                    .map((data, idx) => idx !== id && data)
                    .filter((item) => item && item)
                );
                if (data.delivery_mode !== "DOOR_DELIVERY") {
                  setDelivery(data);
                  setEdit(true);
                } else {
                  setDoorDelivery(data);
                }
              }}>
              <FaPencilAlt />
            </div>
          </div>
          <div className="top-section">
            <div className="completed-delivery-fee">
              <h3>Delivery Fee</h3>
              <p>${!data.free_delivery ? data.delivery_fee : 0}</p>
            </div>
            <div className="completed-address">
              <h4>Address</h4>
              <p>{data.address || city.address}</p>
            </div>
          </div>
          <div className="completed-date-section">
            <h4>Date</h4>
            <div className="completed-dates-items">
              {data.dates.map((data, idx) => {
                console.log(data);
                return (
                  <div className="completed-date" key={idx}>
                    {data.start && moment(data.start).format("LL hh:mm a")}
                    {data.opening_time &&
                      moment()
                        .hours(getHours(data.opening_time))
                        .minutes(0)
                        .format("hh:mm a")}
                    {" - "}
                    {data.end && moment(data.end).format("LL hh:mm a")}{" "}
                    {data.closing_time &&
                      moment()
                        .hours(getHours(data.closing_time))
                        .minutes(0)
                        .format("hh:mm a")}
                    {/* <span>10:00am</span> */}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getHours = (time) => {
  if (Number(time.split(":")[0]) === 12) {
    return 0;
  } else if (Number(time.split(":")[0]) === 24) {
    return 12;
  } else return Number(time.split(":")[0]);
};
export default CompletedDeliveryInput;
