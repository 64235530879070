import {
  CREATE_NEW_COMMENT,
  UPDATE_LOADING,
  UPDATE_POST_LIKE,
} from "./index.types";
import { RESET_POSTS } from "./index.types";
import {
  ADD_NEW_POSTS,
  STORE_FRESH_POST_DATA,
  DELETE_POST,
  UPDATE_POST,
  UPDATE_NEXT,
  UPDATE_PREV,
  CREATED_NEW_POST,
  NEW_POST_LOADING,
  UPDATE_LAST_COMMENT,
} from "./index.types";

const initialState = {
  postData: [],
  next: false,
  previous: false,
};

const posts = (state = initialState, { type, payload }) => {
  switch (type) {
    case STORE_FRESH_POST_DATA:
      return {
        ...payload,
        postData: [...payload.results],
        results: null,
      };

    case ADD_NEW_POSTS:
      return {
        ...state,
        ...payload,
        postData: [...state.postData, ...payload.results],
        results: null,
      };

    case DELETE_POST:
      return {
        ...state,
        postData: state.postData
          .map((data) => data.id !== payload && data)
          .filter((data) => data && data),
      };
    case CREATED_NEW_POST:
      return {
        ...state,
        postData: [payload, ...state.postData],
      };
    case UPDATE_POST:
      return {
        ...state,
        postData: [
          ...state.postData
            .map((data) => {
              if (data.id !== payload.id) {
                return data;
              } else {
                return {
                  ...data,
                  ...payload,
                };
              }
            })
            .filter((data) => data && data),
        ],
      };
    case UPDATE_POST_LIKE:
      return {
        ...state,
        postData: [
          ...state.postData.map((data) => {
            if (data.id === payload.id) {
              return {
                ...data,
                is_liked: payload.status,
                num_of_likes: payload.count || 0,
              };
            } else return data;
          }),
        ],
      };
    case UPDATE_LAST_COMMENT:
      return {
        ...state,
        postData: state.postData.map((data) => {
          if (payload.post === data.id) {
            return {
              ...data,
              comments: [...data.comments, payload],
            };
          } else {
            return data;
          }
        }),
      };
    case UPDATE_PREV:
      return {
        ...state,
        previous: payload,
      };
    case UPDATE_NEXT:
      return {
        ...state,
        next: payload,
      };
    case UPDATE_LOADING:
      return {
        ...state,
        loading: payload,
      };
    case NEW_POST_LOADING:
      return {
        ...state,
        newPostLoading: payload,
      };
    case RESET_POSTS:
      return initialState;
    //work on updating individual posts when there is a plan
    default:
      return state;
  }
};

export default posts;
