import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { useSelector } from "react-redux";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { access_token } = useSelector((state) => state.currentUser);
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.isSignedIn || access_token ? (
          <Component {...props} />
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};
const mapStateToProps = (state) => ({
  isSignedIn: state.user.signIn.isSignedIn,
  isRegistered: state.user.isRegistered,
});

export default connect(mapStateToProps)(ProtectedRoute);
