import React, { useState, useContext, useEffect } from "react";
import "./index.scss";
import { FaCalendarAlt, FaPlus, FaSave } from "react-icons/fa";
import { IoMdCloseCircleOutline } from "react-icons/all";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "../switch/index";
import { BiInfoCircle } from "react-icons/bi";
import CustomDropdown from "../customDropdown/customDropdown";
import CustomCheckBox from "./createBooking/checkBox";
import DeliveryIcon from "../../assets/svg/deliveryIcon";
import ModalContext from "../../hooks/modalContexts";
import useBooking from "./useBooking";
import BookingInfluencer from "./createBooking/bookingInfluencer";
import useInfluencers from "./useInfluencers";
import qs from "query-string";
import ListServices from "./BookingService/listServices";
import BookingPersonnel from "./createBooking/bookingPersonnel";
import BookingPayment from "./createBooking/bookingPayment";
import BookingLocation from "./createBooking/bookingLocation";
import BookingImage from "./createBooking/bookingMedia";
import moment from "moment";

const CreateBookingBody = ({
  bookingForm,
  setBookingForm,
  nextStep,
  handleFormData,
  handleNumberChange,
  handleBookingFeeInputData,
  handleDiscountInputData,
  handleBookingCheckbox,
  handleInfluencerInputChange,
  addInfluencerFormFields,
  discountDuration,
  setDiscountDuration,
  handleAnybodyInputData,
  initialFormState,
  isOpen,
  setOpen,
  toggleService,
  // serviceToggleSubmit,
  // userPersonnel,
  // setUserPersonnel,
  // serviceLocations,
  // setServiceLocations,
}) => {
  const priceType = [{ label: "Fixed price $" }, { label: "Percentage %" }];
  const {
    handleCreateService,
    createServiceData,
    loading,
    handleGetSinglePage,
    singlePageData,
    handleGetSingleService,
    singleServiceData,
    handleGetSinglePersonnel,
    singlePersonnelData,
    updateServiceData,
    handleUpdateService,
    pageWorkingHoursData,
    handleGetpageWorkingHours,
    deletePagePersonnelData,
    handleDeletePagePersonnel,
  } = useBooking();

  const {
    handleBookingModal,
    personnelFormList,
    setBookingLocationModal,
    serviceLocationList,
    personnelFormValue,
    setPostPersonnel,
    postPersonnel,
    toggleEditPersonnel,
    setToggleEditPersonnel,
    setToggleEditPersonnelPost,
    isEditPersonnel,
    setIsEditPersonnel,
    businessHours,
    setBusinessHours,
    serviceToggleSubmit,
    setServiceToggleSubmit
    // personnelInitialFormState2
  } = useContext(ModalContext);

  const [userPersonnel, setUserPersonnel] = personnelFormList;
  // const [personnelInitialFormState22, setPersonnelInitialFormState2] = personnelInitialFormState2;
  const [personnelForm, setPersonnelForm] = personnelFormValue;
  const [serviceLocations, setServiceLocations] = serviceLocationList;
  const [influencer, setInfluencer] = useState([]);
  // const [isEditPersonnel, setIsEditPersonnel] = useState(null);
  const [updateBookingService, setUpdateBookingService] = useState([]);
  console.log("updateBookingService", updateBookingService);

  const editPersonnel = singleServiceData?.data?.personnel;
  console.log("edit personnel", editPersonnel);
  const myAddress = `${singlePageData?.data?.location?.address}`;
  const [address, setAddress] = useState(myAddress);

  const history = useHistory();
  const { search } = useLocation();
  const { id } = qs.parse(search);

  useEffect(() => {
    handleGetSinglePage(id);
    handleGetpageWorkingHours(id);
  }, []);

  useEffect(() => {
    setBookingForm({
      ...bookingForm,
      personnel: userPersonnel,
      service_locations: serviceLocations,
      referrals: influencer,
    });
  }, [userPersonnel, serviceLocations, influencer]);

  const business_opening_hours_time = pageWorkingHoursData?.data?.results.map(
    (item) => {
      return item;
    }
  );
  console.log("business_opening_hours_time", business_opening_hours_time);
  console.log("business_opening______hours", businessHours);

  useEffect(() => {
    if (business_opening_hours_time)
      setBusinessHours(business_opening_hours_time);
  }, [pageWorkingHoursData?.response]);

  useEffect(() => {
    if (!serviceToggleSubmit) {
      // setUserPersonnel(bookingForm.personnel);
      setServiceLocations(bookingForm.service_locations);
    }
  }, [bookingForm.service_locations]);

  const removePersonnel = (e) => {
    // let newFormValues = [...formValues];
    // newFormValues.splice(e, 1);
    // setFormValues(newFormValues);
    e.stopPropagation();
    const idx = e.currentTarget.getAttribute("data-index");
    const newPersonnel = [...userPersonnel];
    newPersonnel.splice(idx, 1);
    setUserPersonnel(newPersonnel);
  };

  console.log("This is user personnel array", userPersonnel);
  console.log("This page data", singlePageData?.data);

  const [radio, setRadio] = useState("0");
  const [radio2, setRadio2] = useState("anyone");
  const [anyoneValue, setAnyoneValue] = useState(priceType[0].label);
  const [discountValue, setDiscountValue] = useState(priceType[0].label);

  const [editBooking, setEditBooking] = useState(false);
  const [file, setFile] = useState(null);
  const [fileImage, setFileImage] = useState(null);
  console.log("this is a file", file);

  const handleAnyoneChange = () => {
    setAnyoneValue(priceType.label);
  };
  const handleDiscountChange = () => {
    setDiscountValue(priceType.label);
  };

  //
  //
  //CRUD for create bookings
  const [bookings, setBookings] = useState();
  const addService = (bookingForm) => {
    setBookings([...bookings, bookingForm]);
  };
  const deleteBooking = (id) => {
    setBookings(bookings.filter((bookingForm) => bookingForm.id !== id));
  };
  const editBookingRow = (bookingForm) => {
    setEditBooking(true);
  };
  //CRUD end

  const handleBookingFormSubmit = (event) => {
    event.preventDefault();
    if (!bookingForm.title || !bookingForm.fee) return;
    addService(bookingForm);
    // setBookingForm(initialFormState);
    toggleService();
    // alert(JSON.stringify(formValues));
  };
  const [formError, setFormError] = useState("");

  const submitFormData = (e) => {
    e.preventDefault();
    setFormError("");
    if (bookingForm.title === "") {
      setFormError("Enter Service Title");
    } else if (bookingForm.booking_fee.amount === 0) {
      setFormError("Enter Service Fee");
    } else nextStep();

    // handleCreateService({ data: bookingForm, file });
    // handleCreateService(bookingForm);
    // history.push(`/preview_booking/?id=${id}`)
    // console.log(createServiceData);
    // if (createServiceData?.response?.status === 200) {
    //   history.push(`/preview_booking/?id=${id}`);
    // }
  };

  useEffect(() => {
    if (createServiceData?.response?.status === 200) {
      history.push(`/preview_booking/?id=${id}`);
    }
  }, [createServiceData?.response?.status]);

  const updateFormData = (e) => {
    e.preventDefault();
    setFormError("");
    // handleUpdateService(bookingForm.id, bookingForm);
    // if (handleUpdateService?.response?.status === 200) {
    //   // history.push(`/explore`);
    //   // showToast("success", "created successfully");
    // }
    nextStep();
  };

  // POST END

  console.log("All service data to submit", bookingForm);
  const { servicesData } = useBooking();
  console.log("llllll", deletePagePersonnelData);
  return (
    <div className="createbooking-body-container">
      <div className="cbc-card-body">
        {/*--------- CARD CONTENTS ------------------*/}
        <div className="cbc-cb-contents">
          <ListServices
            id={id}
            setBookingForm={setBookingForm}
            toggleService={toggleService}
            handleGetSingleService={handleGetSingleService}
            singleServiceData={singleServiceData}
            setUpdateBookingService={setUpdateBookingService}
            updateBookingService={updateBookingService}
            bookingForm={bookingForm}
            setRadio={setRadio}
          />

          {/*---------ADD SERVICES CARD CONTENTS ------------------*/}

          <div className="add-servicebox">
            <button
              className="add-service-btn"
              onClick={() => {
                setOpen(!isOpen);
                setServiceToggleSubmit(true);
                setBookingForm(initialFormState);
                setUserPersonnel([]);
                setServiceLocations([]);
              }}
            >
              <FaPlus /> Add Service
            </button>
          </div>

          {!servicesData.loading &&
            !servicesData.error &&
            servicesData.data?.count === 0 && (
              <h5 className="no-booking"> No Booking</h5>
            )}

          {isOpen && (
            <form onSubmit={serviceToggleSubmit ? submitFormData : updateFormData}>
              <div className="cb-service">
                <small className="error-view">{formError}</small>
                <div className="cbc-cbc-card cbg-light">
                  <div className="aca-contents">
                    <div className="input-group">
                      <label htmlFor="">Title: </label>
                      <input
                        className="form-input"
                        type="text"
                        name="title"
                        onChange={handleFormData("title")}
                        value={bookingForm.title}
                        // onChange={handleInputChange}
                        //required
                      />
                      <label htmlFor="">Set booking fee: </label>
                      <input
                        className="form-input-sm"
                        type="text"
                        name=""
                        onChange={handleBookingFeeInputData("amount")}
                        value={Number(bookingForm?.booking_fee?.amount) || ""}
                        // onChange={handleInputChange()}
                        // required
                      />
                    </div>

                    <div className="accept-box">
                      <label htmlFor="switch" className="">
                        Accept order automatically
                      </label>
                      <Switch
                        // onChange={() => setAutoAccept(!autoAccept.value)}
                        onChange={(e) => {
                          bookingForm.accept_order_automatically
                            ? setBookingForm((prevState) => ({
                                ...prevState,
                                accept_order_automatically: false,
                              }))
                            : handleBookingCheckbox(
                                "accept_order_automatically",
                                true
                              );
                        }}
                        state={bookingForm.accept_order_automatically}
                      />
                      <BiInfoCircle />
                    </div>
                  </div>
                </div>

                <div className="cbc-desc-card">
                  <h5 className="ps-title">Description</h5>
                  <div className="input-group desc-container">
                    <textarea
                      className="form-input"
                      name="description"
                      placeholder="Description"
                      cols="100"
                      rows="4"
                      onChange={handleFormData("description")}
                      value={bookingForm.description}
                    ></textarea>
                  </div>
                </div>

                <div className="cbc-cbc-card cbg-light">
                  <div className="cbc-cbcc-body">
                    <div className="ccb__contents_discount">
                      <div className="discount__topbox">
                        <div className="cb-1">
                          <label className="check-label" htmlFor="001">
                            <CustomCheckBox
                              style={{
                                minWidth: 18,
                                height: 18,
                                borderColor: "var(--dark-purple-bg)",
                                marginRight: 10,
                                backgroundColor: bookingForm.allow_discount
                                  ? "var(--dark-purple-bg)"
                                  : "transparent",
                              }}
                              onChange={(e) => {
                                bookingForm.allow_discount
                                  ? setBookingForm((prevState) => ({
                                      ...prevState,
                                      allow_discount: false,
                                    }))
                                  : handleBookingCheckbox(
                                      "allow_discount",
                                      true
                                    );
                              }}
                            />
                            Discount
                          </label>
                        </div>
                      </div>
                      <div className="price-box-one">
                        <div className="input-group  ">
                          <label htmlFor="">Discount: </label>
                          <div className="dropbox">
                            <CustomDropdown
                              items={priceType}
                              value={discountValue}
                              setValue={setDiscountValue}
                              onChange={handleDiscountChange}
                            />
                          </div>

                          <input
                            className="form-input-sm"
                            type="text"
                            min="1"
                            placeholder={
                              discountValue === priceType[0].label
                                ? "$90"
                                : "10%"
                            }
                            onChange={
                              discountValue === priceType[0].label
                                ? handleDiscountInputData("fix_price")
                                : handleDiscountInputData("percentage")
                            }
                            value={
                              discountValue === priceType[0].label
                                ? bookingForm?.discount?.constant?.fix_price
                                : bookingForm?.discount?.constant.percentage ||
                                  bookingForm?.discount?.constant?.fix_price
                            }
                          />
                        </div>
                        <div className="input-group ctn-three">
                          <label htmlFor="">Duration: </label>
                          <div className="datepicker-container">
                            <FaCalendarAlt />

                            <DatePicker
                              className="custom-date"
                              selected={discountDuration}
                              onChange={(date) => setDiscountDuration(date)}
                              dateFormat="MMM d, yyyy h:mm aa"
                              placeholderText="select date"
                              showTimeInput
                              timeInputLabel="Time"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/*---------  REFERRAL CARD CONTENTS ------------------*/}

                <div className="ref-contents-container">
                  <div className="cbc-cbc-card cbg-light">
                    <div className="cbc-cbcc-body">
                      <div className="ccb__contents_ref">
                        <div className="ref__topbox">
                          <div className="cb-1">
                            <label className="check-label" htmlFor="001">
                              <CustomCheckBox
                                style={{
                                  minWidth: 18,
                                  height: 18,
                                  borderColor: "var(--dark-purple-bg)",
                                  marginRight: 10,
                                  backgroundColor: bookingForm.allow_referral
                                    ? "var(--dark-purple-bg)"
                                    : "transparent",
                                }}
                                onChange={(e) => {
                                  bookingForm.allow_referral
                                    ? setBookingForm((prevState) => ({
                                        ...prevState,
                                        allow_referral: false,
                                      }))
                                    : handleBookingCheckbox(
                                        "allow_referral",
                                        true
                                      );
                                }}
                              />
                              Referral
                            </label>
                          </div>
                        </div>
                        <div className="ref-box-one">
                          <h5> Who can refer: </h5>
                          <div className="input-group">
                            <div className="rf-radio-one">
                              <label className="rfr-label">
                                <input
                                  type="radio"
                                  name="refer"
                                  value="influencer"
                                  onChange={(e) => setRadio2(e.target.value)}
                                />
                                <span className="checkmark"></span>
                                Influencer
                              </label>
                            </div>
                            <div className="rf-radio-two">
                              <label className="rfr-label">
                                <input
                                  type="radio"
                                  name="refer"
                                  value="anyone"
                                  checked={radio2 === "anyone"}
                                  onChange={(e) => {
                                    setRadio2(e.target.value);
                                  }}
                                />
                                <span className="checkmark"></span>
                                Anyone
                              </label>
                            </div>
                          </div>
                        </div>
                        {radio2 === "anyone" && (
                          <div className="ref-box-two">
                            <div className="input-group ">
                              <label htmlFor="">Anyone: </label>
                              <div className="dropbox">
                                <CustomDropdown
                                  items={priceType}
                                  value={anyoneValue}
                                  setValue={setAnyoneValue}
                                  onChange={handleAnyoneChange}
                                />
                              </div>
                              <input
                                className="form-input-sm"
                                type="number"
                                min="1"
                                max={bookingForm?.booking_fee?.amount - 1}
                                placeholder={
                                  anyoneValue === priceType[0].label
                                    ? "$90"
                                    : "10%"
                                }
                                onChange={handleAnybodyInputData(
                                  "price_value_anybody"
                                )}
                                value={bookingForm.price_value_anybody}
                              />
                            </div>
                          </div>
                        )}

                        {radio2 === "influencer" && (
                          <BookingInfluencer
                            influencer={influencer}
                            setInfluencer={setInfluencer}
                            bookingForm={bookingForm}
                            setBookingForm={setBookingForm}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*---------  PAYMENT CARD CONTENTS ------------------*/}
                <BookingPayment
                  bookingForm={bookingForm}
                  setBookingForm={setBookingForm}
                  handleBookingCheckbox={handleBookingCheckbox}
                />
                {/*---------  SERVICE LOCATION CARD CONTENTS ------------------*/}
                <div className="cbc-cbc-card cbg-light">
                  <h5 className="ps-title"> Service Location</h5>
                  <div className="cbc-cbcc-body">
                    <div className="ccb__contents_sl">
                      <div className="sl-radio-one">
                        <label className="slr-label">
                          <input
                            type="radio"
                            name="service location"
                            checked={radio === "0"}
                            value="0"
                            onChange={(e) => setRadio(e.target.value)}
                          />
                          <span className="checkmark"></span>
                          Allow customer to input location
                        </label>
                      </div>
                      <div className="sl-radio-two">
                        <label className="slr-label">
                          <input
                            type="radio"
                            name="service location"
                            value="1"
                            // checked={radio === "1"}
                            onChange={(e) => {
                              setRadio(e.target.value);
                              setBookingForm({
                                ...bookingForm,
                                location_to_use: "Use my business address",
                              });
                            }}
                          />
                          <span className="checkmark"></span>
                          Use my business address
                        </label>
                      </div>
                    </div>
                    <div className="ccb__contents_sld">
                      {radio === "1" && (
                        <>
                          <div className="cbc_sl_box">
                            {singlePageData?.data?.location?.address || "No address found"}
                          </div>
                          {singlePageData?.data?.location?.city && (
                            <div className="cbc_sl_box">
                              {singlePageData?.data?.location?.city}
                            </div>
                          )}
                        </>
                      )}
                      {radio === "0" && (
                        <div className="item__scroll2">
                          <BookingLocation
                            serviceLocations={serviceLocations}
                            setServiceLocations={setServiceLocations}
                            setBookingLocationModal={setBookingLocationModal}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/*---------  PERSONEL CARD CONTENTS ------------------*/}
                <div className="cbc-cbc-card cbg-light">
                  <h5 className="ps-title">Personnel</h5>
                  <div className="item__scroll3">
                    <>
                      {userPersonnel?.map((personnel, idx) => (
                        <BookingPersonnel
                          personnel={personnel}
                          key={idx}
                          idx={idx}
                          removePersonnel={removePersonnel}
                          userPersonnel={userPersonnel}
                          handleBookingModal={handleBookingModal}
                          setPersonnelForm={setPersonnelForm}
                          isEditPersonnel={isEditPersonnel}
                          setIsEditPersonnel={setIsEditPersonnel}
                          setToggleEditPersonnel={setToggleEditPersonnel}
                          setToggleEditPersonnelPost={
                            setToggleEditPersonnelPost
                          }
                          handleGetSinglePersonnel={handleGetSinglePersonnel}
                          singlePersonnelData={singlePersonnelData}
                          handleDeletePagePersonnel={handleDeletePagePersonnel}
                          deletePagePersonnelData={deletePagePersonnelData}
                          setUserPersonnel={setUserPersonnel}
                        />
                      ))}
                    </>
                    {/* {serviceToggleSubmit ? (
                      <>
                        {userPersonnel?.map((personnel, idx) => (
                          <BookingPersonnel
                            personnel={personnel}
                            key={idx}
                            idx={idx}
                            removePersonnel={removePersonnel}
                            userPersonnel={userPersonnel}
                            handleBookingModal={handleBookingModal}
                            setPersonnelForm={setPersonnelForm}
                            isEditPersonnel={isEditPersonnel}
                            setIsEditPersonnel={setIsEditPersonnel}
                            setToggleEditPersonnel={setToggleEditPersonnel}
                            // handleGetSinglePersonnel={handleGetSinglePersonnel}
                            // singlePersonnelData={singlePersonnelData}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {editPersonnel?.map((personnel, idx) => (
                          <BookingPersonnel
                            personnel={personnel}
                            idx={idx}
                            key={idx}
                            removePersonnel={removePersonnel}
                            editPersonnel={editPersonnel}
                            handleBookingModal={handleBookingModal}
                            setPersonnelForm={setPersonnelForm}
                            handleGetSinglePersonnel={handleGetSinglePersonnel}
                            singlePersonnelData={singlePersonnelData}
                            setToggleEditPersonnel={setToggleEditPersonnel}

                          />
                        ))}
                      </>
                    )} */}
                  </div>
                  <div className="ps-box-footer">
                    <button className="use-saved-btn">
                      <FaSave /> Use Saved
                    </button>
                    {serviceToggleSubmit ? (
                      <button
                        className="add-service-btn"
                        type="button"
                        onClick={() => {
                          handleBookingModal("ADD_DATA", "true");
                          // setPersonnelForm(personnelInitialFormState2);
                          setToggleEditPersonnel(false);
                        }}
                      >
                        <FaPlus /> Add Personel
                      </button>
                    ) : (
                      <button
                        className="add-service-btn"
                        type="button"
                        id="post-pbtn"
                        onClick={(e) => {
                          handleBookingModal("ADD_DATA", "true");
                          // setPersonnelForm({});
                          setPostPersonnel(true);
                          setToggleEditPersonnel(false);
                        }}
                      >
                        <FaPlus /> Add Personnel
                      </button>
                    )}
                  </div>
                </div>

                {/*---------  MEDIA CARD CONTENTS ------------------*/}
                <BookingImage
                  bookingForm={bookingForm}
                  setBookingForm={setBookingForm}
                  serviceToggleSubmit={serviceToggleSubmit}
                />
                <div className="bk-btn-box">
                  {/* <button className="bk-btn" type="submit">
                    Done
                  </button> */}
                  {serviceToggleSubmit ? (
                    <button
                      className="bk-btn"
                      type="submit"
                      disabled={createServiceData.loading}
                    >
                      {createServiceData.loading ? "Loading..." : "Done"}
                    </button>
                  ) : (
                    <button
                      className="bk-btn"
                      type="submit"
                      disabled={updateServiceData.loading}
                    >
                      {updateServiceData.loading ? "Loading..." : "Update"}
                    </button>
                  )}
                </div>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateBookingBody;
