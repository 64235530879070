import { useEffect, useState } from "react";
import "./index.scss";
import profileImg from "../../../../assets/img/Ellipse 320.PNG";
import itemImg from "../../../../assets/img/Rectangle 1890.PNG";
import CustomDropdown from "../../../customDropdown/customDropdown";
import CustomCheckBox from "../../../customCheckBox";
import CircleIcon from "../../../../assets/svg/circleIcon";
import photoPic from "../../../../assets/img/Ellipse 357.png";
import { BiInfoCircle } from "react-icons/bi";
import PreOrderRequest from "./components/preOrderRequest";
import usePrivateSellerDashboard from "../../../../hooks/privateSellerDashboard";
import RequestedItem from "../../../PrivateSeller/Listings/components/requestedItem";
import { useLocation, useParams } from "react-router";
import qs from "query-string";
import PreorderItem from "./components/preorderItem";
import Loader from "../../../loader";
import useActivity from "../../../../hooks/activity";

const BsRequestedTab = () => {
  const { pageId: id } = useParams();
  const {
    cancelPreOrderData,
    handleCancelPreOrder,
    processPreOrderData,
    handleProcessPreOrder,
  } = useActivity();

  useEffect(() => {
    cancelPreOrderData?.data &&
      handleGetPreOrder({
        id: id,
      });
    processPreOrderData?.data &&
      handleGetPreOrder({
        id: id,
      });
  }, [cancelPreOrderData?.data, processPreOrderData?.data]);
  const [requestedTab, setrequestedTab] = useState("Buy Now");

  // const [isOpen, setOpen] = useState(false);
  // const toggleView = () => setOpen(!isOpen);
  // const testArray = [1, 2];
  const [multiSelect, setMultiSelect] = useState([]);

  const {
    allOrdersData,
    handleGetCustomizedOrders,
    handleBulkUpdate,
    bulkUpdateOrderStatusData,
  } = usePrivateSellerDashboard();

  const handleMultiSelect = ({ value }) => {
    if (value) {
      const arr = allOrdersData?.data?.results?.map((data) => data.id);
      setMultiSelect([...arr]);
      console.log("arr", arr);
    }
  };

  useEffect(() => {
    requestedTab === "Buy Now"
      ? handleGetCustomizedOrders({
          tab: "Requested",
          page: id,
          pre_order: "false",
        })
      : handleGetPreOrder({
          id: id,
        });
  }, []);

  useEffect(() => {
    bulkUpdateOrderStatusData?.data &&
      handleGetCustomizedOrders({
        tab: "Requested",
        pre_order: "false",
      });
  }, [bulkUpdateOrderStatusData?.data]);

  const { preOrderdata, handleGetPreOrder } = useActivity();

  return (
    <div className="requestedTabBody">
      <div className="requestedTabs">
        <p
          className={`${requestedTab === "Buy Now" ? "active" : ""}`}
          onClick={() => {
            setrequestedTab("Buy Now");
            handleGetCustomizedOrders({
              tab: "Requested",
              page: id,
              pre_order: "false",
            });
          }}>
          Buy Now
        </p>
        <p
          className={`${requestedTab === "Pre Order" ? "active" : ""}`}
          onClick={() => {
            setrequestedTab("Pre Order");
            handleGetPreOrder({ id: id });
          }}>
          Pre Order
        </p>
      </div>
      <div className="tab-content">
        {/* {requestedTab === "tab1" ? (<div> Buy Now <div/>) : (<div> Buy Now <div/>)} */}
        {requestedTab === "Buy Now" && (
          <div className="buynow-tab">
            {/* <div className="filter-box">
              <p>Filter</p>
              <div className="dropbox">
                <CustomDropdown items={data} />
              </div>
            </div> */}

            {allOrdersData?.data?.results?.length > 0 ? (
              <div className="bkr-top-card">
                <div className="tc-box-one">
                  <div className="cb-1">
                    <label className="check-label" htmlFor="000">
                      <CustomCheckBox
                        style={{
                          minWidth: 18,
                          height: 18,
                          borderColor: "var(--text-grey)",
                          borderRadius: 5,
                          marginRight: 10,
                        }}
                        onChange={handleMultiSelect}
                      />
                      Select all
                    </label>
                  </div>
                </div>
                <div className="item-btn-box">
                  <button
                    className="btn-accept"
                    onClick={() =>
                      handleBulkUpdate({
                        ids: multiSelect && multiSelect,
                        status: "ACCEPT",
                      })
                    }>
                    Accept
                  </button>
                  <button
                    className="btn-decline"
                    onClick={() =>
                      handleBulkUpdate({
                        ids: multiSelect && multiSelect,
                        status: "DECLINED",
                      })
                    }>
                    Decline
                  </button>
                </div>
              </div>
            ) : (
              <p>No requested items</p>
            )}
            {allOrdersData?.data &&
              allOrdersData?.data?.results?.map((data, idx) => (
                <RequestedItem
                  handleGetCustomizedOrders={handleGetCustomizedOrders}
                  data={data}
                  idx={idx}
                />
              ))}
            {allOrdersData?.loading && <Loader />}
          </div>
        )}
        {/* ----------PRE-ORDER------- */}
        {requestedTab === "Pre Order" && (
          <div className="preorder-tab">
            {/* <div className="filter-box">
              <p>Filter</p>
              <div className="dropbox">
                <CustomDropdown items={data2} />
              </div>
            </div> */}
            {preOrderdata.data &&
              preOrderdata.data.results.map((data, idx) => {
                return (
                  <PreorderItem
                    processPreOrder={handleProcessPreOrder}
                    cancelPreOrder={handleCancelPreOrder}
                    data={data}
                    idx={idx + 1}
                  />
                );
              })}
            {allOrdersData?.loading && <Loader />}
            {allOrdersData?.data?.results?.length === 0 && (
              <p>No preordered items</p>
            )}
            {/* {testArray.map((data, idx) => (
              <PreOrderRequest />
            ))} */}
          </div>
        )}
      </div>
    </div>
  );
};

export default BsRequestedTab;
