const infiniteScroll = (
  container,
  functionToBeCalled = () => {},
  condition
) => {
  const { scrollTop, scrollHeight, clientHeight } = container.target;
  if (scrollTop + clientHeight >= scrollHeight - 10 && condition) {
    functionToBeCalled();
  }
};

export default infiniteScroll;
