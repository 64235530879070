/**
 *
 * This file was generated with Adobe XD React Exporter
 * Exporter for Adobe XD is written by: Johannes Pichler <j.pichler@webpixels.at>
 *
 **/

import React from "react";

const VuesaxLinearGalleryComponent = (props) => (
  <svg width="1em" height="1em" viewBox="0 0 24 24" {...props}>
    <defs>
      <style>
        {
          ".a,.b{fill:none;}.a{stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px;}.b{opacity:0;}"
        }
      </style>
    </defs>
    <g transform="translate(-364 -252)">
      <path
        className="a"
        d="M7,20h6c5,0,7-2,7-7V7c0-5-2-7-7-7H7C2,0,0,2,0,7v6C0,18,2,20,7,20Z"
        transform="translate(366 254)"
      />
      <path
        className="a"
        d="M4,2A2,2,0,1,1,2,0,2,2,0,0,1,4,2Z"
        transform="translate(371 258)"
      />
      <path
        className="a"
        d="M0,6.953l4.93-3.31a2.253,2.253,0,0,1,2.64.14l.33.29a2.229,2.229,0,0,0,2.82,0L14.88.5A2.229,2.229,0,0,1,17.7.5l1.63,1.4"
        transform="translate(366.67 263.997)"
      />
      <path className="b" d="M0,0H24V24H0Z" transform="translate(364 252)" />
    </g>
  </svg>
);

export default VuesaxLinearGalleryComponent;
